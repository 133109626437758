<form [formGroup]="$any(_bf.bigForm)">
	<div class="image-card">
		<div><img id="image" height="45px" [src]="thumbnail" /></div>
		<div class="image-name" formControlName="imagename">{{ image_name }}</div>
		<div class="image-size" formControlName="imagesize">{{ image_size }}</div>
		<div class="purpose-selector">
			<flx-dropdown [itemsOption]="purposeOptions" placeHolder="Add purpose tag" [displayOptions]="displayConfig" formControlName="imagepurpose"></flx-dropdown>
			<!-- <flx-select-list [options$]="purposeOptions$" [canFilter]="false" isCollapsed="true"></flx-select-list> -->
		</div>

		<div class="action-icon"><flx-icon [type]="'check-circle-1'"></flx-icon></div>
	</div>
</form>
