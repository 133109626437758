import { Flow_0_0_2, getFullItemOne, getSelectedItemOne } from '@flexus/core';
import { map, switchMap, skipWhile, take } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';

export const BUI_ADD_MAVEN_CLAIM_NUMBER: Flow_0_0_2 = {
	id: 'AddMavenClaimNumber',
	itemType: 'flow',
	name: 'Add Maven Claim Number',
	excludeForOnlyStates: [169, 8, 17, 172],
	excludeForOnlyRoles: [18],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Add Maven Claim Number  :  ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return '';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'AddMavenClaimNumber',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		AddMavenClaimNumber: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				formControls: {
					0: { label: 'Enter Maven claim number', inputType: 'input', formControlName: 'maven_claim_number' }
				},
				containerWidth: '400px',
				centered: true
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				let claim_number;
				bf.bigForm.addControl('maven_claim_number', new UntypedFormControl('', Validators.required));
				store
					.select(getSelectedItemOne)
					.pipe(
						skipWhile(data => !data),
						take(1)
					)
					.subscribe(data => {
						claim_number = data?.mid;
						if (claim_number !== undefined || claim_number !== null || claim_number !== '') {
							bf.bigForm.patchValue({ maven_claim_number: claim_number });
						}
					});
			},
			checkValidityForFields: ['maven_claim_number'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						response: {
							errorMessage: 'Claim not updated with Maven claim number',
							serviceVariable: 'buiService',
							functionName: 'updateClaim'
						}
					},
					color: 'primary'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		maven_claim_number: ['mid', 'loan_information.mavenclaimnumber']
	}
};
