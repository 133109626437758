import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ActionPanelState } from './action-panel.reducer';

const getActionPanelState = createFeatureSelector<ActionPanelState>('actionPanel');

export const getActionPanelExpanded = createSelector(getActionPanelState, state => state && state.actionPanelExpanded);

export const getActionPanelVisible = createSelector(getActionPanelState, state => state && state.actionPanelVisible);

export const getAdditionalActionPanelExpanded = createSelector(getActionPanelState, state => state && state.additionalActionPanelExpanded);

export const getAdditionalActionPanelVisible = createSelector(getActionPanelState, state => state && state.additionalActionPanelVisible);

export const getActionPanelComponentToLoad = createSelector(getActionPanelState, state => state.componentToLoad);
