import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
// import { getCurrentUser } from '../../identity';
// import { IndexedDbService } from '../../storage';
import { skipWhile } from 'rxjs/operators';
import { GetJobResponses } from './sp-job-pings.actions';
import { IndexedDbService } from '../services';
import { getCurrentUser } from '../identity';

export enum INTEREST {
	DECLINED = -1,
	IGNORED = 0,
	INTERESTED = 1
}

@Injectable({
	providedIn: 'root'
})
export class SPJobPingsService {
	constructor(private store$: Store<any>, private indexedDbService: IndexedDbService) {}


	pingSound() {
		const sound = new Audio();
		sound.src = '../../../../../assets/sound/ping_audio.wav';
		sound.setAttribute('preload', 'auto');
		sound.setAttribute('controls', 'none');
		sound.style.display = 'none';
		document.body.appendChild(sound);
		sound.play();
	}

	async addJobResponsesToIDB(shownInterests: any[]) {
		this.indexedDbService.open();

		const tx = this.indexedDbService
			.transaction('rw', this.indexedDbService.job_responses, () => {
				for (const interestShown of shownInterests) {
					if (Array.isArray(interestShown)) {
						interestShown.forEach(is =>
							this.indexedDbService.job_responses.put({
								id: is.job.id,
								job: is.job,
								interest: is.interested,
								time_of_response: is.time_of_response
							})
						);
					} else {
						this.indexedDbService.job_responses.put({
							id: interestShown.job_id,
							job: interestShown.job,
							interest: interestShown.interested,
							time_of_response: interestShown.time_of_response
						});
					}
				}
			})
			.catch(function (e) {
				console.log('There was a problem adding response to IndexDB', e);
			});

		return tx.then(() => {
			this.store$.dispatch(new GetJobResponses());
			return { success: true, message: 'Job response successfully saved', jobResponses: shownInterests };
		});
	}

	removeJobResponsesFromIDB(jobs: any[]) {
		this.indexedDbService.open();

		const tx = this.indexedDbService
			.transaction('rw', this.indexedDbService.job_responses, () => {
				for (const job of jobs) {
					this.indexedDbService.job_responses.delete(job);
				}
			})
			.catch(function (e) {
				console.log('There was a problem removing response from IndexDB', e);
			});

		tx.catch();
		return of({ success: true, message: 'Job response/s removed successfully', jobResponsesRemoved: jobs });
	}

	checkToPing(available_jobs: any, job_responses: any) {
		let availableJobPings: any[] = [];
		if (available_jobs !== null && available_jobs.job) {
			const jobPacket = available_jobs.job;
			if (jobPacket instanceof Array) {
				availableJobPings = jobPacket;
			} else {
				availableJobPings.push(jobPacket);
			}
		}

		const filteredJobs = availableJobPings.filter((job: any) => {
			const jobFound = job_responses.find((response: any) => response.job_id === job?.id);
			return !jobFound;
		});

		if (Array.isArray(filteredJobs) && filteredJobs.length > 0) {
			this.store$
				.select(getCurrentUser)
				.pipe(skipWhile(user => !user))
				.subscribe(currentUser => {
					if (!currentUser?.user?.roles?.some((role: number) => [13].includes(role))) {
						this.pingSound();
					}
				});
		}

		return { availableJobPings, filteredJobs };
	}
}
