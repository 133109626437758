import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { getIdentitySubmitting, getLoginError } from '@flexus/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { Password } from '@flexus/models';

@Component({
	selector: 'flx-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
	resetForm: UntypedFormGroup;
	isSubmitting$: Observable<boolean>;
	sub: any;
	token: any;
	isSuccess = false;
	message: string;
	error$: Observable<any>;
	logo_url: string;
	byline: string;
	isOnline: boolean;
	networkSub: Subscription;

	constructor(private _fb: UntypedFormBuilder, private _router: Router, private _route: ActivatedRoute, private _http: HttpClient, private _store: Store<any>) {
		this.resetForm = this._fb.group({
			newPassword: ['', Validators.required],
			confirmPassword: ['', Validators.required]
		});
	}

	ngOnInit() {
		this.sub = this._route.params.subscribe(params => {
			this.token = params['token'];
		});

		if (environment.branding) {
			this.logo_url = environment.branding.logo_url;
			this.byline = environment.branding.byline;
		} else {
			this.logo_url = 'assets/images/4-sure-logo.svg';
		}

		this.error$ = this._store.select(getLoginError);
		this.isSubmitting$ = this._store.select(getIdentitySubmitting);
	}

	resetPassword() {
		const body: Password = {
			password: this.resetForm.get('newPassword')?.value,
			token: this.token
		};

		this._http.post('forgot_password/change-password', body).subscribe((res: HttpResponse<any>) => {
			if (res?.status === 200) {
				this._router.navigate(['auth/login']);
			}
		});
	}
}
