import { BigFormService, Flow_0_0_2, ManifestController, ModalService, getAllInfo, getCurrentUser, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { getInterstateComments } from '../reusable/dataManipulations';
import { UntypedFormControl, Validators } from '@angular/forms';
import { combineLatest, EMPTY, forkJoin, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { filter, map, skipWhile, switchMap, take } from 'rxjs/operators';
import moment from 'moment';
import { gql } from 'graphql-tag';
import { KVLHeading } from '@flexus/ui-elements';
import { environment } from 'apps/studio/src/environments/environment';
import { addObjectProperty } from '@flexus/utilities';

export const MUL_299: Flow_0_0_2 = {
	id: '299',
	name: 'no_photos',
	itemType: 'flow',

	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'installation-summary']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any, bf: any) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo?.claim) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Upload Missing Photos : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Upload Missing Photos';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Upload Missing Photos'
		},
		viewRoles: {
			0: 'Upload Missing Photos'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService', //is this the correct serviceVariable
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		}
	},
	startNode: 'InstallationSummaryNoPhotosNotice',
	nodes: {
		InstallationSummaryNoPhotosNotice: {
			initFormFields: (_bf: BigFormService, _item: any, _instance: any, _storeQuery: any, _store: Store) => {
				_bf.addControl('new_state', new UntypedFormControl(26, [Validators.required]));
				_bf.addControl('bypass_photos', new UntypedFormControl(null, [Validators.required]));
				combineLatest([_store.select(getCurrentUser).pipe(filter(x => !!x, take(1))), _store.select(getFullItemTwo).pipe(filter(x => !!x, take(1)))])
					.pipe(take(1))
					.subscribe(([author, job]) => {
						_bf.patchValues({ author: author.user.id });
						_bf.patchValues({ author_name: author.user.full_name });
						_bf.patchValues({ currentState: job?.state });
					});
				_bf.addControl('no_photos_message', new UntypedFormControl('', [Validators.required]));
			},
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => EMPTY
			},
			serverCalls: {
				important_information: {
					errorMessage: `Couldn't get the important information`,
					directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
						return _store.select(getFullItemTwo).pipe(
							skipWhile((res: any) => !res),
							take(1),
							map((result: any) => {
								const { interstate_comments } = result?.job_information;
								const list: any[] = [];
								list.push({
									'Message from previous state': 'No instruction n'
									//'Message from previous state': `${interstate_comments?.message}`,
									//'Where did this come from': `${interstate_comments?.author_name}`
								});
								return list;
							})
						);
					}
				},
				installationKeyValues: {
					errorMessage: "Couldn't return installation info",
					directCall: (_http: HttpClient, _store: Store, _sq: any) => {
						return forkJoin([
							_store.select(getFullItemTwo).pipe(
								filter(x => !!x),
								take(1),
								map(res => res as any)
							),
							_store.select(getAllInfo).pipe(
								filter(x => !!x),
								take(1),
								map(res => res as any)
							)
						]).pipe(
							map(([job, info]) => {
								const { appointment, job_information, claim } = job;
								const { skills, appointment_types } = info;
								const skillid = job?.skill;
								let installationtype, skill, streetaddress, suburb, appointmentdate, appointmenttype, appointmentname;

								installationtype = claim?.type;
								for (let i = 0; i < skills?.length; i++) {
									if (skillid === skills[i]?.id) {
										skill = skills[i]?.name;
									}
								}
								streetaddress = claim?.loan_information.propertystreetaddress;
								suburb = claim?.loan_information.propertysuburb;
								appointmentdate = appointment[0]?.range_start;
								appointmenttype = appointment[0]?.appointment_type;
								for (let i = 0; i < appointment_types?.length; i++) {
									if (appointmenttype === appointment_types[i]?.id) {
										appointmentname = appointment_types[i]?.name;
									}
								}
								const installationdetails = {
									'Installation Type': installationtype,
									Skill: skill,
									Address: `${streetaddress}, ${suburb}`,
									'Appointment Date': `${moment(appointmentdate).format(moment.HTML5_FMT.DATE)}`,
									'Appointment Time': `${appointmentname} ${moment(appointmentdate).format(moment.HTML5_FMT.TIME)}`
								};
								return [installationdetails];
							})
						);
					}
				},
				teamleaderKeyValues: {
					errorMessage: `Couldn't get the team leader detail.`,
					directCall: (_http: HttpClient, _store: Store, _sq: any) => {
						return _sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											team_leader {
												full_name
												contact_number
												sp {
													name
												}
											}
										}
									}
								`,
								_store.select(getFullItemTwo).pipe(
									skipWhile((fit: any) => !fit),
									take(1),
									map((res: any) => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((query_data: any) => {
									const full_name = query_data?.full_name ?? 'No name';
									const companyname = query_data?.name ?? 'No company name';
									const contactnumber = query_data?.contact_number ?? 'No contact number';
									const emailaddress = query_data?.email_address ?? 'No email address';
									const installerobj = {
										'Full Name': `${full_name}`,
										'Company Name': `${companyname}`,
										'Contact Number': `${contactnumber}`,
										'Email Address': `${emailaddress}`
									};
									return [installerobj];
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent', //remove title and replace with Job Information
						inputs: {
							title: 'Confirm Customer Activation',
							instructions: ['We have not received the customer activation pack.', 'Confirm on VMS that the customer has been activated.']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							data$: 'important_information',
							color: 'secondary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Details',
							data$: 'installationKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Team Leader Details',
							data$: 'teamleaderKeyValues'
						}
					}
				]
			},
			navs: [
				{
					text: 'no photos',
					color: 'default',
					location: 'center',
					nextNode: 'SubmissionSuccess',
					linkType: 'portal',
					serverCalls: {
						response: {
							errorMessage: 'Could not accept without photos',
							directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
								_bf.patchValues({ new_state: 298, bypass_photos: _bf.getControl('no_photos_message') });
								_bf.addControl('bypass_photos', new UntypedFormControl(null, [Validators.required]));
								return _store.select(getFullItemTwo).pipe(
									skipWhile((data: any) => !data),
									take(1),
									switchMap((data: any) => {
										const job_id = data?.id;
										const { job_information } = data;
										const jobinfo = JSON.parse(JSON.stringify(job_information));
										const bypass_photos = _bf.getControl('bypass_photos').value;
										const jobinformation = addObjectProperty('bypass_photos', bypass_photos, jobinfo);
										const new_state = _bf.getControl('new_state').value;
										const requestPayload = {
											job_id: job_id,
											new_state: new_state,
											job_information: jobinformation
										};
										return _http.post(`${environment.api_url}v1/job_action/update_job/`, requestPayload);
									})
								);
							}
						}
					}
				},
				{
					text: 'send to scheduler',
					color: 'default',
					location: 'right',
					nextNode: 'SendToScheduler'
				},
				{
					text: 'upload photos',
					color: 'secondary',
					location: 'right',
					nextNode: 'UploadMissingPhotos'
				}
			]
		},
		SendToScheduler: {
			checkValidityForFields: ['no_photos_message'],
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('no_photos_message', new UntypedFormControl('', [Validators.required, Validators.minLength(10)]));
			},
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				containerWidth: '50vw',
				heading: 'No Job Photos',
				formControls: {
					0: {
						inputType: 'textarea',
						containerWidth: '40vw',
						rows: 5,
						label: 'Query SP about missing photos',
						formControlName: 'no_photos_message'
					}
				}
			},
			navs: [
				{
					text: 'Send to Scheduler',
					optIntoValidation: true,
					color: 'default',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Could not send to scheduler',
							directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
								_bf.patchValues({ new_state: 298, no_photos_message: _bf.getControl('no_photos_message') });
								return _store.select(getFullItemTwo).pipe(
									skipWhile((data: any) => !data),
									take(1),
									switchMap((data: any) => {
										const job_id = data?.id;
										const { job_information } = data;
										const jobinfo = JSON.parse(JSON.stringify(job_information));
										const no_photos_message = _bf.getControl('no_photos_message').value;
										const jobinformation = addObjectProperty('no_photos_message', no_photos_message, jobinfo);
										const new_state = _bf.getControl('new_state').value;
										const requestPayload = {
											job_id: job_id,
											new_state: new_state,
											job_information: jobinformation
										};
										return _http.post(`${environment.api_url}v1/job_action/update_job/`, requestPayload);
									})
								);
							}
						}
					}
				}
			]
		},
		UploadMissingPhotos: {
			checkValidityForFields: ['purpose', 'fileQueue'],
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('purpose', new UntypedFormControl('photos'));
				_bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
				_bf.patchValues({ new_state: 26 }); //new_state: 26
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Upload Missing Photos'
						}
					},
					{
						component: 'FLXFileUploadWithPreviewComponent',
						inputs: {
							purposes: [{ display: 'Missing photos', value: 'Missing photos' }],
							multipleUploads: false,
							allowedFileTypes: ['pdf', 'image'],
							containerWidth: '50vw',
							maxWidth: '50vw'
						}
					}
				]
			},
			navs: [
				{
					text: 'Next',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'portal', //submit option
					serverCalls: {
						uploadPhoto: {
							errorMessage: 'Missing photos could not be uploaded',
							directCall: (_http: HttpClient, store: Store, sq, bf: BigFormService, _ctrl: ManifestController<any>, _modal: ModalService) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const filenom = fileQueue[0] ? fileQueue[0]?.file.nativeFile.name : '';

								const fileupload_request: object = {
									data: fileQueue[0] ? fileQueue[0]?.file.base64 : '',
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: filenom
								};

								const jobupdate_request = {
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									new_state: 26 //to what state
								};

								let jobServerActions = [];

								const fileuploadpromise = _http
									.post(`${environment.api_url}v1/file_action/upload_file/`, fileupload_request, {
										observe: 'events',
										reportProgress: true
									})
									.toPromise();

								jobServerActions.push(fileuploadpromise);

								const jobupdatepromise = _http.post(`${environment.api_url}v1/job_action/update_job`, jobupdate_request).toPromise();

								jobServerActions.push(jobupdatepromise);

								console.log('JOB SERVER ACTIONS', jobServerActions);
								// return of({});

								return of(
									Promise.all(jobServerActions)
										.then(result => console.log('job server actions resolved', result))
										.catch(err => console.warn('PROMISE ERROR', err))
								);
							}
						}
					}
				}
			]
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		message: [(message, storeObj, bf) => getInterstateComments(message, storeObj, bf), 'job_information.interstate_comments'],
		new_state: 'new_state'
		// bypass_photos: 'job_information.bypass_photos'
	}
};
