<flx-flex-container alignItems="center" maxWidth="50vw">
	<ng-container *ngIf="canReopen$ | async as reopen">
		<ng-container *ngIf="reopen?.result; else cantReopen">
			<flx-heading size="small">Re-open Complete or closed claim</flx-heading>
			<ng-container *ngIf="reopenReasons$ | async as reopenReasons">
				<flx-label class="form-label">Please indicate why the claim is being re-opened</flx-label>
				<form [formGroup]="reopen_data">
					<flx-dropdown
						formControlName="reopen_reason"
						[displayOptions]="{ displayKey: 'description', valueKey: 'id' }"
						[itemsOption]="reopenReasons"
						placeHolder="Select reason for re-opening claim"
						[searchEnabled]="false"
					>
					</flx-dropdown>
				</form>
			</ng-container>
		</ng-container>

		<ng-template #cantReopen>
			<flx-heading size="small"> {{ reopen?.message }} </flx-heading>
		</ng-template>
	</ng-container>
</flx-flex-container>
