import * as actions from '../actions/pagination.action';

export interface State {
	currentPage: number;
}

const initialState: State = {
	currentPage: 1
};

export function reducer(state = initialState, action): State {
	switch (action.type) {
		case actions.SET_CURRENT_PAGE: {
			const currentPage = action.payload;
			return {
				...state,
				currentPage
			};
		}
		case 'LOGOUT_SUCCESS': {
			return initialState;
		}
		default:
			return state;
	}
}
