import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { take, skipWhile, map } from 'rxjs/operators';
import { CustomValidators } from '@flexus/utilities';
import { BigFormService } from '@flexus/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { ModalService } from '@flexus/core';
import { AVSResultFlag } from '../bank-details-template/models';

@Component({
	selector: 'flx-bet-bank-details',
	templateUrl: './bet-bank-details.component.html',
	styleUrls: ['./bet-bank-details.component.scss']
})
export class BetBankDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
	// ===========================================  Variables ===========================================================
	// Vars for the company toggle
	name: string;
	individual: boolean;
	identification: string;
	bankDetailsForm: UntypedFormGroup = new UntypedFormGroup({});

	bankNamesSub: Subscription;
	bankNameChangeSub: Subscription;
	currentBankDetailsSub: Subscription;
	bankNames;

	// ============================================= Inputs =============================================================
	@Input() accountTypes$: Observable<any[]>; // an array of account types to be used in the drop down
	@Input() bankNames$: Observable<any>;
	@Input() bankCodeLookupEvent: Function;
	@Input() jobID$: Observable<any> = null;
	@Input() currBankDetails$: Observable<any>;
	// ============================================ Constructor =========================================================
	constructor(private _store: Store<any>, private cd: ChangeDetectorRef, public bf: BigFormService, public _toaster: ToastrService, private modalsService: ModalService) {}

	// ============================================= Life cycle Methods ============================================================
	ngOnInit() {
		this.appendBankDetailsToFormControl(this.bankDetailsForm);
		this.jobID$.pipe(take(1)).subscribe(id => {
			if (id !== undefined) {
				// if appending to a job
				const jobs = this.bf.bigForm.get('jobs') as UntypedFormArray;
				let jobIndex = -1;
				for (let i = 0; i < jobs?.value?.length; i++) {
					if (jobs.value[i]?.id === id) {
						jobIndex = i;
					}
				}
				if (jobIndex !== -1) {
					// bank details to each require job
					const currentJob = jobs.at(jobIndex) as UntypedFormGroup;
					if (!currentJob.get('bank_details')) {
						currentJob.addControl('bank_details', this.bankDetailsForm);
					} else {
						this.bankDetailsForm.patchValue(currentJob.get('bank_details')?.value);
					}
				} else {
					if (!this.bf.bigForm.get('bank_details')) {
						this.bf.bigForm.addControl('bank_details', this.bankDetailsForm);
					} else {
						this.bankDetailsForm.patchValue(this.bf.bigForm.get('bank_details')?.value);
					}
				}
			} else {
				// else add to root level
				if (!this.bf.bigForm.get('bank_details')) {
					this.bf.bigForm.addControl('bank_details', this.bankDetailsForm);
				} else {
					this.bankDetailsForm.patchValue(this.bf.bigForm.get('bank_details')?.value);
				}
			}
		});
		this.intialiseInformation();
		this.bankNamesSub = this.bankNames$
			.pipe(
				skipWhile(f => !f),
				take(1),
				map(({ payload }) => payload)
			)
			.subscribe(_bankNames => {
				this.bankNames = [..._bankNames];
			});
	}

	ngAfterViewInit() {
		if (this.bankCodeLookupEvent && typeof this.bankCodeLookupEvent === 'function') {
			this.bankNameChangeSub = this.bankDetailsForm.get('bank_name').valueChanges.subscribe(bankName => {
				if (this.bankNames) {
					const selectedBank = this.bankNames.find(_bankName => _bankName.name.includes(bankName));
					if (selectedBank) {
						this.bankCodeLookupEvent(selectedBank.id, this._store, this.bf);
					}
				}
			});
		}

		this.currentBankDetailsSub = this.currBankDetails$
			.pipe(
				skipWhile(val => !val),
				take(1)
			)
			.subscribe(vals => {
				if (vals !== null && vals !== undefined) {
					this.bankDetailsForm.patchValue(vals, { emitEvent: false, onlySelf: true });
				}
			});
	}

	ngOnDestroy() {
		if (this.bankNamesSub) this.bankNamesSub.unsubscribe();
		if (this.bankNameChangeSub) this.bankNameChangeSub.unsubscribe();
		if (this.currentBankDetailsSub) this.currentBankDetailsSub.unsubscribe();
		if (this.bf.bigForm.get('avs_data')) {
			this.bf.bigForm.removeControl('avs_data');
		}
	}

	// ============================================================ Initial Methods ============================================================
	intialiseInformation() {
		this.individual = true;
		this.name = 'Surname';
		this.identification = 'ID/Passport Number';

		if (this.bankDetailsForm !== undefined && this.bankDetailsForm.value.account_use === 'Company') {
			this.individual = false;
			this.name = 'Company Name';
			this.identification = 'Company Reg';
		}
	}

	private appendBankDetailsToFormControl(form: UntypedFormGroup) {
		// These controls will always be added to the form as you will always need access to the values of the form. You take what you need in the manifest file
		form.addControl('account_holder_name', new UntypedFormControl('', [CustomValidators.alphaNumericWithSpacesDotDash, CustomValidators.hardMaxLength(30)]));
		form.addControl('bank_name', new UntypedFormControl('', { validators: [CustomValidators.hardMaxLength(45), Validators.minLength(3)] }));
		form.addControl(
			'account_number',
			new UntypedFormControl('', {
				validators: [CustomValidators.hardMaxLength(30), CustomValidators.numeric, Validators.minLength(6)]
			})
		);
		form.addControl(
			'identification_number',
			new UntypedFormControl('', {
				validators: [CustomValidators.hardMaxLength(15), CustomValidators.numeric, Validators.minLength(6)]
			})
		);
		form.addControl('initials', new UntypedFormControl('', { validators: [CustomValidators.hardMaxLength(4), CustomValidators.alphaNumeric] }));
		form.addControl('branch_code', new UntypedFormControl('', { validators: [CustomValidators.hardMaxLength(8)] }));
		form.addControl('branch', new UntypedFormControl('', { validators: [CustomValidators.hardMaxLength(30), Validators.minLength(3)] }));
		form.addControl('account_type', new UntypedFormControl('ChequeAccount', { validators: [CustomValidators.hardMaxLength(30)] }));
		form.addControl('account_use', new UntypedFormControl('Individual'));

		form.addControl('avsSuccess', new UntypedFormControl(AVSResultFlag.do_later));
		form.addControl('avsFailedReasons', new UntypedFormControl(''));

		form.addControl('avs_check_switch', new UntypedFormControl('auto', { validators: [] }));
		form.addControl('acc_type', new UntypedFormControl(null, []));
		form.addControl('acc_type_desc', new UntypedFormControl(null, []));
	}

	toggleBankAccountType() {
		this.individual = !this.individual;
		if (this.individual) {
			this.name = 'Surname';
			this.identification = 'ID/Passport Number';
		} else {
			this.name = 'Company Name';
			this.identification = 'Company Reg';
		}
	}
}
