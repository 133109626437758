import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { map } from 'rxjs/operators';
import { DATA_CALLS } from '../../reusable/DATA_CALLS';

export const BET_VIEW_CLAIM_EXCESS: Flow_0_0_2 = {
	id: 'ViewClaimExcessOnClaim',
	itemType: 'flow',
	fetchLevel1And2: true,
	name: 'View Claim Excess On Claim',
	excludeForOnlyStates: [169],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne && itemOne?.applicant && itemOne?.applicant?.information) {
						return `View Claim Excess : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'View Claim Excess';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ViewClaimExcessOnClaim',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},

	serverCalls: {
		excess_on_claim: {
			...DATA_CALLS.excess_on_claim
		}
	},
	nodes: {
		ViewClaimExcessOnClaim: {
			component: 'FLXExcessDetailsOnClaimComponent',
			inputs: {
				location: 'screen'
			},
			name: 'View Claim Excess'
			// ...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE.nodes.checkExcess,
		}
	}
};
