<flx-heading size="medium" align="center" weight="extra-light" type="creation">{{ title }}</flx-heading>
<div class="container">
	<ng-container *ngIf="!isOffline; else offline">
		<flx-glow-line size="100%" *ngIf="filteredClaimHistory$ | async"></flx-glow-line>
		<div class="list-container">
			<ul *ngIf="filteredClaimHistory$ | async as claimsArray; else noClaims">
				<li *ngFor="let claim of claimsArray">
					<span class="li-emphasis"> {{ claim?.CLAIMTYPE }} </span> <span> {{ claim?.STATUS }}</span> <span>{{ formatDate(claim.LOSSDATE) | date: 'yyyy/MM/dd' }}</span>
					<span>{{ claim?.CLAIMCOST | currency: 'R':'symbol':'1.2-2' }} </span>
				</li>
			</ul>
			<ng-template #noClaims> <flx-heading size="small" align="center">There is no history to display</flx-heading> </ng-template>
		</div>
		<flx-glow-line size="100%" *ngIf="filteredClaimHistory$ | async"></flx-glow-line>
	</ng-container>
	<ng-template #offline> <flx-alert type="warning" [message]="offlineMessage"></flx-alert> </ng-template>
</div>
