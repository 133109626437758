import { Flow_0_0_2, getSelectedItemOne, getFullItemOne, MakeServerCall, SetNextNode } from '@flexus/core';
import { UntypedFormControl } from '@angular/forms';
import { take, map, filter, switchMap, skipWhile } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { environment } from 'apps/studio/src/environments/environment';
import { Subscription } from 'rxjs';
import { getErrors } from '@flexus/error-handler';

export const BET_75: Flow_0_0_2 = {
	id: '75',
	name: 'claim_managed_on_maven',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Maven Managed Claim : ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Maven Managed Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'time-stamp', 'documents'], { filesFunctionName: 'getAllClaimFiles' }),
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Maven Managed Claim'
		},
		viewRoles: {
			0: 'Maven Managed Claim'
		}
	},
	events: {},
	serverCalls: {
		claimMid: {
			serviceVariable: 'beService',
			functionName: 'getClaimMid',
			errorMessage: 'No Maven claim number returned'
		}
	},
	startNode: 'MavenManagedClaim',
	nodes: {
		MavenManagedClaim: {
			component: 'FileViewWithExtraComponent',
			mockContextData: {},
			checkValidityForFields: [],
			initFormFields: (bf, item, instance, storeQuery) => {
				bf.addControl('new_state', new UntypedFormControl(''));
			},
			serverCalls: {
				keyValueList: {
					errorMessage: `Couldn't retrieve claim/claimand details`,
					directCall: (http, store) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map((res: any) => {
								const arrobj = {
									Claimant: res && res?.applicant ? res?.applicant?.first_name + ' ' + res?.applicant?.surname : '',
									'Claim Type': res && res?.loan_information ? res?.loan_information.claimtype : '',
									Address:
										res && res?.loan_information
											? res?.loan_information.propertystreetaddress + ', ' + res?.loan_information.propertysuburb + ', ' + res?.loan_information.propertycity
											: '',
									'Contact Number': res && res?.loan_information ? res?.loan_information.contactnumber : '',
									'Cell Number': res && res?.loan_information ? res?.loan_information.cellnumber : '',
									'What Matters': res && res?.loan_information ? res?.loan_information.whatmatters : ''
								};
								return [arrobj];
							})
						);
					}
				},
				copyText: {
					errorMessage: 'Could not return Maven claim number',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map((res: any) => {
								return { key: 'MAVEN CLAIM NO: ', value: res?.mid };
							})
						);
					}
				}
			},
			inputs: {
				inputHeading: 'Maven Managed Claim',
				subHeading: 'Copy claim number and use in Maven',
				instructions: [
					['Manually allocate this claim to your user name on Maven'],
					['Complete the claim manually on Maven'],
					['Once the claim is done, mark as complete on 4-Sure']
				]
			},
			navs: [
				{
					text: 'Continue',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance, store) => {
							instance.setMessage(['This claim must be managed on Maven', 'Have you manually completed on Maven?']);
							instance.type = 'info';
							instance.navButtons = [
								{
									text: 'Back',
									color: 'alert',
									linkType: 'close'
								},
								{
									text: 'Yes, move to rating',
									clickHandler: event => {
										store.dispatch(
											new MakeServerCall({
												errorMessage: `Couldn't move to rating`,
												dataKey: 'response',
												directCall: (http, stor) => {
													return stor
														.select(getFullItemOne)
														.pipe(
															filter(x => !!x && Object.keys(x).length !== 0),
															take(1),
															map(res => res as any)
														)
														.pipe(
															switchMap(claim => {
																const claim_id = claim?.id;
																const data = {
																	claim_id: claim_id,
																	new_state: 12
																};
																return http.post(`${environment.api_url}v1/claim_action/update_claim`, data);
															})
														);
												}
											})
										);
									},
									color: 'primary',
									linkType: 'nextNode',
									nextNode: 'Decision',
									serverFirst: true
								}
							];
						}
					}
				}
			]
		},
		Decision: {
			nodeType: 'decision',
			decisions: {
				isAllocationSuccess: (navs, store, modal, controller) => {
					let errorSub, successSub: Subscription;

					errorSub = store
						.select(getErrors)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.subscribe(err => {
							controller.dispatch(new SetNextNode('ManuallyManagedClaim'));
							if (successSub) {
								successSub.unsubscribe();
							}
						});

					successSub = store
						.select(getErrors)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.subscribe(err => {
							controller.dispatch(new SetNextNode('SubmissionSuccess'));
							if (errorSub) {
								errorSub.unsubscribe();
							}
						});
				}
			},
			navs: []
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		claim_id: 'claim_id'
	}
};
