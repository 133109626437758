<div class="invoice-summary">
	<ng-container *ngIf="editing; else noEdit">
		<form [formGroup]="bf.bigForm" class="invoice-summary__notes">
			<flx-label>Notes</flx-label>
			<br />
			<flx-textarea formControlName="invoiceNotes" height="8rem"></flx-textarea>
		</form>
		<div class="invoice-summary__totals">
			<div class="invoice-summary__totals-descriptors">
				<div>Subtotal</div>
				<div *ngIf="vat">Add: VAT &#64; {{ vat || 0 }}%</div>
				<div *ngIf="client !== 'pinggo'">Less Excess</div>
				<div *ngIf="client !== 'pinggo'">Balance Due</div>
				<div *ngIf="client === 'pinggo'">Total Due</div>
			</div>
			<div class="invoice-summary__totals-amounts">
				<div class="right-margined">
					<ng-container> R {{ subTotal || 0 | number: '1.2-2' }} </ng-container>
				</div>
				<div class="right-margined">
					<ng-container *ngIf="vat">
						<ng-container> R {{ vatAmount || 0 | number: '1.2-2' }} </ng-container>
					</ng-container>
				</div>
				<div class="right-margined" *ngIf="client !== 'pinggo'">
					<ng-container>R {{ excessAmount || 0 | number: '1.2-2' }}</ng-container>
				</div>
				<div class="right-margined">
					<ng-container> R {{ balanceDue || 0 | number: '1.2-2' }} </ng-container>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-template #noEdit>
		<div class="invoice-summary__notes">
			<flx-label>Material Notes</flx-label><br />
			<p class="invoice-summary__notes--description-colour" *ngIf="bf.bigForm.get('invoiceNotes').value">{{ bf.bigForm.get('invoiceNotes').value }}</p>
			<p class="invoice-summary__notes--description-colour" *ngIf="bf.bigForm.get('invoiceNotes').value?.length === 0">no notes</p>
		</div>
		<div class="invoice-summary__totals">
			<div class="invoice-summary__totals-descriptors">
				<div>Subtotal</div>
				<div *ngIf="vat">Add: VAT &#64; {{ vat || 0 }}%</div>
				<div *ngIf="vat">Total</div>
				<div>Less Excess</div>
				<div>Total Due</div>
			</div>
			<div class="invoice-summary__totals-amounts">
				<div>R{{ subTotal || 0 | number: '1.2-2' }}</div>
				<div *ngIf="vat">R{{ vatAmount || 0 | number: '1.2-2' }}</div>
				<div *ngIf="vat">R{{ subTotal - vatAmount || 0 | number: '1.2-2' }}</div>
				<ng-container>R {{ vatAmount || 0 | number: '1.2-2' }}</ng-container>
				<div>R{{ excessAmount || 0 | number: '1.2-2' }}</div>
				<div>R{{ balanceDue || 0 | number: '1.2-2' }}</div>
			</div>
		</div>
	</ng-template>
</div>
