import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamleadLookupCompositeComponent } from './teamlead-lookup-composite.component';
import {
	FLXAlertsModule,
	FLXButtonsModule,
	FLXReactiveFormsErrorDisplayModule,
	FLXHeadingsModule,
	FLXPanelsModule,
	FLXFormsModule,
	FLXSelectListModule,
	FLXLoaderModule
} from '@flexus/ui-elements';

@NgModule({
	imports: [
		CommonModule,
		// FLXHeadingWithInstructionsModule,
		// FLXYesNoModule,
		// FLXGlowLineModule,
		// FLXKeyValueListModule,
		FLXButtonsModule,
		FLXLoaderModule,
		FLXFormsModule,
		FLXSelectListModule,
		FLXHeadingsModule,
		FLXPanelsModule,
		FLXReactiveFormsErrorDisplayModule,
		FLXAlertsModule
	],
	declarations: [TeamleadLookupCompositeComponent],
	exports: [TeamleadLookupCompositeComponent]
})
export class FLXTeamleadLookupCompositeModule {}
