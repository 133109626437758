import { Flow_0_0_2, getFullItemTwo, getAllInfo, getSelectedItemTwo, getCurrentUser, getSubmissionData } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { forkJoin, of, combineLatest } from 'rxjs';
import { skipWhile, take, map, pluck, filter, switchMap, mergeMap, skip } from 'rxjs/operators';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import moment from 'moment';
import { convertDateTimeToTimeStamp } from '@flexus/utilities';
import { KVLHeading } from '@flexus/ui-elements';

export const MUL_42: Flow_0_0_2 = {
	id: '42',
	name: 'rework-job',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: 'Change Team Leader',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'mulSpService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		jobLocationData: {
			errorMessage: 'The job location was not returned!',
			serviceVariable: 'mulSpService',
			functionName: 'getJobLocation',
			responseSlice: 'payload'
		},
		teamleaderLocations: {
			errorMessage: 'Team leader locations not returned!',
			directCall: (http, store, sq, bf) => {
				return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
					pluck('payload'),
					filter(x => !!x),
					map((teamleaders: any[]) => {
						console.log('NEW TEAM LEADERS', teamleaders);
						return teamleaders;
					})
				);
			}
		},
		current_team_leader: {
			errorMessage: 'Current team leader could not be determined',
			directCall: (http, store, sq, bf) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					),
					store.select(getAllInfo).pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					),
					http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
						skipWhile(x => !x),
						take(1),
						map(res => res['payload'])
					)
				]).pipe(
					map(([itemTwo, info, teamleaders]) => {
						const tleads = teamleaders as any;
						let teamleadname;
						for (let i = 0; i < tleads?.length; i++) {
							if (tleads[i]?.id === itemTwo?.team_leader) {
								teamleadname = tleads[i]?.full_name;
							}
						}

						const list3 = {
							// 'Allocated Team Leader  ': `${itemTwo.team_leader}`,
							'Currently Allocated Team Leader  ': teamleadname
						};
						return [list3];
					})
				);
			}
		}
	},
	instructions: {
		editRoles: {
			0: 'Start job rework'
		},
		viewRoles: {
			0: 'Wait for SP to start job rework'
		}
	},
	startNode: 'ReworkJobSummary',
	nodes: {
		ReworkJobSummary: {
			initFormFields: (bf, item, instance, storeQuery, store) => {},
			serverCalls: {
				importantKeyValues: {
					errorMessage: 'Something went wrong with info',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									let message;
									let timestamp;
									let fromwho;
									const interstatecomments = job?.job_information?.interstate_comments;

									if (!interstatecomments) {
										message = 'No comments between states exist';
										timestamp = 'No time stamp';
										fromwho = 'Job not commented';
									} else if (interstatecomments?.length === 0) {
										message = 'No comments between states exist';
										timestamp = 'Mo time stamp';
										fromwho = 'Job not commented';
									} else {
										message = interstatecomments[interstatecomments.length - 1]?.message;
										timestamp = interstatecomments[interstatecomments.length - 1]?.timestamp;
										fromwho = interstatecomments[interstatecomments.length - 1]?.author_name;
									}
									const importantinfo = {
										'Message from previous state': message,
										Timestamp: `${moment(timestamp)?.format(moment.HTML5_FMT.DATE)} at ${moment(timestamp)?.format(moment.HTML5_FMT.TIME)}`,
										'Who did this come from?': fromwho
									};

									return [importantinfo];
								})
							);
					}
				},
				teamleadKV: {
					errorMessage: "Couldn't find installer technician info",
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								filter(x => !!x),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([job, teamleaders]) => {
								const leads = teamleaders as any;
								let teamleadname;

								for (let i = 0; i < teamleaders?.length; i++) {
									if (!job.team_leader) {
										teamleadname = 'Team leader not assigned yet';
									} else if (teamleaders[i]?.id === job?.team_leader.id) {
										teamleadname = teamleaders[i]?.full_name;
									}
								}

								const installer = {
									'Current Team Leader': teamleadname
								};
								return [installer];
							})
						);
					}
				},
				installationKeyValues: {
					errorMessage: "Couldn't return installation info",
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								filter(x => !!x),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								filter(x => !!x),
								take(1),
								map(res => res as any)
							)
						]).pipe(
							map(([job, info]) => {
								const { appointment, job_information, claim } = job;
								const { skills, appointment_types } = info;
								const skillid = job?.skill;
								let installationtype, skill, streetaddress, suburb, appointmentdate, appointmenttype, appointmentname;

								installationtype = claim?.type;
								for (let i = 0; i < skills?.length; i++) {
									if (skillid === skills[i]?.id) {
										skill = skills[i]?.name;
									}
								}
								streetaddress = claim?.loan_information.propertystreetaddress;
								suburb = claim?.loan_information.propertysuburb;
								appointmentdate = appointment[0]?.range_start;
								appointmenttype = appointment[0]?.appointment_type;
								for (let i = 0; i < appointment_types?.length; i++) {
									if (appointmenttype === appointment_types[i]?.id) {
										appointmentname = appointment_types[i]?.name;
									}
								}
								const installationdetails = {
									'Installation Type': installationtype,
									Skill: skill,
									Address: `${streetaddress}, ${suburb}`,
									'Appointment Date': `${moment(appointmentdate)?.format(moment.HTML5_FMT.DATE)}`,
									'Appointment Time': `${appointmentname} ${moment(appointmentdate)?.format(moment.HTML5_FMT.TIME)}`
								};
								return [installationdetails];
							})
						);
					}
				},
				contactKeyValues: {
					errorMessage: "Couldn't return contact details",
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								filter(x => !!x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									const { claim, identities } = job;
									const contactperson = claim?.applicant;
									const loan_information = claim?.loan_information;
									const subscriber_no = identities?.subscriber_number;
									let firstname, surname, contactnumber, whatmatters;
									if (!contactperson) {
										firstname = 'John';
										surname = 'Doe';
										contactnumber = 'No number entered for contanct persron';
									} else {
										firstname = contactperson.first_name;
										surname = contactperson.surname;
										contactnumber = contactperson.contact_number;
									}
									if (!loan_information) {
										whatmatters = `No 'what matters' returned`;
									} else {
										whatmatters = loan_information?.whatmatters;
									}
									const contactdetails = {
										'Contact Person': `${firstname} ${surname}`,
										'Subscriber Number': `${subscriber_no}`,
										'Contact Number': contactnumber,
										Notes: whatmatters
									};

									return [contactdetails];
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							color: 'secondary',
							data$: 'importantKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Team Leader',
							data$: 'teamleadKV',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Details',
							data$: 'installationKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Contact Details',
							data$: 'contactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Set Appointment',
					linkType: 'portal',
					nextNode: 'SetAppointment',
					color: 'primary'
				}
			]
		},
		AssignTeamLeader: {
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: (bf, item, instance, sq, store) => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl('', Validators.required));
				store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(fullItemTwo => {
						bf.addControl('assign_teamleader_id', new UntypedFormControl('', Validators.required));
						if (fullItemTwo.team_leader.id) {
							bf.bigForm.get('assign_teamleader_id').patchValue([fullItemTwo.team_leader.id]);
						}
					});
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getSelectedItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map((res: any) => res)
							)
							.pipe(
								switchMap(job => {
									const { id } = job;
									const job_id = id;
									return http.post(`${environment.api_url}v1/sp_action/manage_list_staff/`, { role_id: 10, image: true, job_id: job_id }).pipe(
										pluck('payload'),
										filter(x => !!x),
										map((teamleaders: any[]) =>
											teamleaders.map(teamLeader => {
												const namestringarr = teamLeader.full_name?.split(' ');
												let mono1, mono2;
												if (!namestringarr[0]) {
													mono1 = ' ';
												} else {
													mono1 = namestringarr[0]?.slice(0, 1);
												}
												if (!namestringarr[1]) {
													mono2 = '';
												} else {
													mono2 = namestringarr[1]?.slice(0, 1);
												}
												const monogram = mono1 + mono2;
												return {
													display: teamLeader.full_name,
													value: teamLeader.id,
													image: teamLeader.image,
													monogram: monogram,
													teamLeader,
													shouldHaveImage: true
												};
											})
										)
									);
								})
							);
					}
				}
			},
			component: 'AssignTLComponent',
			inputs: {
				disableOptionWhen: {
					message: 'Upload profile picture to select team leader',
					evaluationCriteria: {
						property: 'image',
						operator: '!'
					}
				},
				includeUserDisplay: true,
				joblocation$: 'jobLocationData',
				teamleadersPositions$: 'teamleaderLocations'
			},
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't update job!",
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getCurrentUser).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									of(bf.bigForm.get('assign_teamleader_id')?.value).pipe(
										skipWhile(x => !x),
										take(1),
										map(rest => rest)
									)
								]).pipe(
									switchMap(([j, uid, lead]) => {
										const job = j as any;
										const user = uid as any;
										const job_id = job?.id;
										const staffmember = user.id;
										const team = lead[0];

										const data = {
											job_id: job_id,
											staffmember: staffmember,
											team_id: team
											// new_state: 22
										};
										return http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
									})
								);
							}
						}
					},
					location: 'right'
				}
			]
		},
		ReworkReasonEntry: {
			showTabs: false,
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.patchValues({ new_state: 22 });
				forkJoin([store.select(getCurrentUser).pipe(filter(x => !!x, take(1))), store.select(getFullItemTwo).pipe(filter(x => !!x, take(1)))])
					.pipe(take(1))
					.subscribe(([author, job]) => {
						bf.patchValues({ author: author.user.id });
						bf.patchValues({ auhor_name: author.user.full_name });
						bf.patchValues({ current_state: job?.state });
					});
				bf.addControl('rework_reason', new UntypedFormControl('', []));
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Rework Required',
							formControls: {
								0: {
									label: 'Please indicate what needs to be reworked and why',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'rework_reason'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Assign Team Leader',
					// linkType: 'portal',
					nextNode: 'AssignTeamLeader',
					color: 'primary',
					serverCalls: {
						response: {
							errorMessage: 'Rework reason not submitted',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submitdata]) => {
										const job_id = job?.id;
										const new_state = 22;
										const rework_reason = submitdata?.job_information?.rework_reason;
										const interstate_comments = {
											...job?.job_information?.interstate_comments,
											rework_reason
										};
										const job_information = { ...job.job_information, interstate_comments };
										const data = {
											job_id: job_id,
											job_information: job_information
										};
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			checkValidityForFields: ['appointmentData'],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, Validators.required),
						appointmentTime: new UntypedFormControl(null, Validators.required),
						appointmentTimePicker: new UntypedFormControl(null, Validators.required),
						appointmentDateType: new UntypedFormControl(null, Validators.required),
						skill: new UntypedFormControl('Dish Installation')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				bf.patchValues({ new_state: 22 });
			},
			serverCalls: {
				claimaintKeyValues: {
					errorMessage: 'Claimant detail could not be retrieved',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									const claimantinfo = {
										'Customer Details ': {
											'Full name ': `${job.claim?.applicant?.first_name} ` + `${job.claim?.applicant?.surname}`,
											'Contact Number': job?.claim?.loan_information.ContactNumber,
											'Mobile Number ': job?.claim?.loan_information.cellnumber,
											'On-site contact name ': job?.claim?.loan_information.onsiteperson
										}
									};
									return [claimantinfo];
								})
							);
					}
				},
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													ContactNumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									return [
										{
											'Client Name': `${qdat.first_name} ${qdat.surname}`
										},
										{ 'Contact Number': `${qdat.ContactNumber}` },
										{ 'Mobile Number': `${qdat.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onSiteContact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onSiteContact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Continue',
					optIntoValidation: true,
					linkType: 'portal',
					color: 'primary',
					nextNode: 'ReworkReasonEntry',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submit]) => {
										const job_id = job?.id;
										const current_state = job?.state;
										const appointment = submit.appointment;
										const data = {
											job_id: job_id,
											current_state: current_state,
											appointment: appointment,
											new_state: 42
										};
										return data;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			],
			component: 'FLXJobAppointmentComponent'
		},
		SubmissionSuccessTL: {
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'Appointment succesfully set.' },
			navs: [
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccessContinue'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		rework_reason: [
			(raison, storobj, bf) => {
				const rework_reasons = [];
				const date = new Date();
				const newinfo = {
					reason: raison,
					author_id: bf.author,
					author_name: bf.author_name,
					currentState: bf.current_state,
					nextState: bf.new_state,
					timeStamp: convertDateTimeToTimeStamp(date.toDateString())
				};
				const d = storobj['selectedContext']?.fullItemTwo?.job_information?.rework_reasons;
				if (!d) {
					rework_reasons.push(newinfo);
				}
				if (Array.isArray(d)) {
					if (d?.length === 0) {
						rework_reasons.push(newinfo);
					} else {
						rework_reasons.push(...d, newinfo);
					}
				}
				return rework_reasons;
			},
			'job_information.rework_reason'
		],
		assign_teamleader_id: [
			[
				tlid => {
					if (tlid) {
						let id;
						tlid = tlid && Array.isArray(tlid) ? tlid : [];
						for (const lead of tlid) {
							id = parseInt(lead, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.team_leader'
			]
		],
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					const appointment_formatted = {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
					return appointment_formatted;
				}
			},
			'appointment'
		],
		new_state: 'new_state'
	}
};
