<flx-flex-container alignItems="center">
	<div class="dynamic-component claim-details-container">
		<!-- add job -->
		<div class="claim-details-jobs">
			<flx-heading size="medium" align="center" type="creation" weight="extra-light">Create job card</flx-heading>

			<form [formGroup]="bf.bigForm">
				<!-- Current jobs -->
				<ng-container *ngIf="showCurrentJobs && currentJobList">
					<ng-container formArrayName="currentJobs" *ngFor="let currentJob of $any(currentJobList); let i = index">
						<div class="job-card-section job-card-section__current-job" [formGroupName]="i">
							<div class="job-card-input">
								<!-- category -->
								<flx-dropdown [isItemSelected]="true" placeHolder="{{ currentJob.skillcatagory }}" [isDisabled]="true"> </flx-dropdown>
							</div>
							<div class="job-card-input">
								<!-- skill -->
								<flx-dropdown [isItemSelected]="true" placeHolder="{{ currentJob.skill }}" [isDisabled]="true"> </flx-dropdown>
							</div>
							<div class="job-card-input">
								<!-- provider -->
								<flx-dropdown [isItemSelected]="true" placeHolder="{{ currentJob.providertype }}" [isDisabled]="true"> </flx-dropdown>
							</div>
						</div>
					</ng-container>
					<flx-glow-line *ngIf="currentJobList && currentJobList.length > 0" margin="0.5rem 0"></flx-glow-line>
				</ng-container>

				<!-- New job -->
				<ng-container formArrayName="jobs" *ngFor="let job of $any(jobList); let i = index">
					<div class="job-card-section" [formGroupName]="i">
						<div class="job-card-input">
							<!-- category -->
							<flx-dropdown
								formControlName="skillcatagory_id"
								[displayOptions]="displayConfig"
								[itemsOption]="selectGroupData$ | async"
								placeHolder="Skill Group"
								[searchEnabled]="false"
								[isDisabled]="selectedClaimClass <= 0 || selectedClaimClass === null"
								[autoOpen]="job.skillcatagory_id === null"
								(itemsSelected)="goSkillGroupAction($event, i)"
							>
							</flx-dropdown>
						</div>
						<div class="job-card-input">
							<!-- skill -->
							<flx-dropdown
								formControlName="skill_id"
								[displayOptions]="displayConfig"
								[itemsOption]="getSkills(job.skillcatagory_id)"
								placeHolder="Skill"
								[searchEnabled]="getSkills(job.skillcatagory_id).length >= 10"
								[isDisabled]="job.skillcatagory_id === null"
								[autoOpen]="job.skill_id === null"
								(itemsSelected)="goSkillAction($event, i)"
							></flx-dropdown>
						</div>
						<div class="job-card-input">
							<!-- provider -->
							<flx-dropdown
								formControlName="providertype_id"
								[displayOptions]="displayConfig"
								[itemsOption]="providerTypes"
								placeHolder="Provider Type"
								[searchEnabled]="false"
								[isDisabled]="job.skill_id === null"
								[autoOpen]="job.providertype_id === null"
								(itemsSelected)="goProviderTypeAction($event, i)"
							></flx-dropdown>
						</div>
						<div class="add-another-job">
							<!-- remove job -->
							<flx-icon *ngIf="jobList.length !== 1" (click)="removeJob(i)" [type]="'subtraction'"></flx-icon>
							<!-- edit off panel or cash in lieu -->
							<flx-icon [type]="'edit'" *ngIf="job.providertype_id === 2 || job?.providertype_id === 3" (click)="goProviderTypeAction(job.providertype_id, i)"></flx-icon>
							<!-- add another job -->
							<flx-icon *ngIf="i === jobList.length - 1" (click)="incJobCount(i + 1)" [type]="'addition'"></flx-icon>
						</div>
						<flx-glow-line *ngIf="jobList.length > 1" margin="0.5rem 0"></flx-glow-line>
					</div>
				</ng-container>
				<!-- Provide reason for appointing IA -->
				<div class="ia-reasons-select" *ngIf="iaRequested">
					<form [formGroup]="bf.bigForm">
						<flx-heading size="extra-small" align="left" margin="0">Select a reason for appointing IA</flx-heading>
						<div>
							<flx-dropdown
								[displayOptions]="displayConfig"
								[itemsOption]="IAreasons$ | async"
								[searchEnabled]="false"
								formControlName="ia_request_reason"
								placeHolder="IA Appointment Reason"
							></flx-dropdown>
						</div>
					</form>
				</div>
			</form>
		</div>
	</div>
</flx-flex-container>
