<div *ngIf="this.currentFrameData as frameData">
	<div [ngSwitch]="frameData.currentFrame">
		<div *ngSwitchCase="0">
			<!-- frame0 -->
			<flx-button color="primary" display="block" class="filter-inputs button" (click)="handleFrameEvent(frameData.currentFrame, $event)">ADD FILTER</flx-button>
		</div>
		<div *ngSwitchCase="1">
			<!-- frame 1-->
			<form [formGroup]="frameData.formGroup">
				<div class="filter-form"><flx-heading type="page" size="medium">Filter Workflow</flx-heading></div>
				<!-- <p>{{ currentFrameData.framePrompt}}</p> -->
				<flx-select-list height="50vh" formControlName="initialSelect" [options$]="frameData.frameData" [canFilter]="false"> </flx-select-list>
			</form>
		</div>
		<div *ngSwitchCase="2">
			<!-- frame 2 , aka an actual form -->
			<!-- <div (click)="resetSelection()">x</div> -->
			<div [ngSwitch]="frameData.frameType">
				<div *ngSwitchCase="'numericRange'">
					<form class="filter-info" [formGroup]="frameData.formGroup">
						<div class="filter-form">
							<flx-heading class="filter" size="medium">{{ currentFrameData.framePrompt }}</flx-heading>
							<flx-icon class="filter" (click)="handleFrameEvent(0, null)" [type]="'remove'"></flx-icon>
						</div>

						<flx-input-text class="filter-inputs" formControlName="min" placeholder="Minimum value"> </flx-input-text>
						<flx-input-text class="filter-inputs" formControlName="max" placeholder="Maximum value"> </flx-input-text>
						<!--  the button bellow functions as a listener for the form -->
						<flx-button display="block" class="filter-inputs button" color="primary" (click)="handleEnterKey()"> Continue </flx-button>
					</form>
				</div>
				<div *ngSwitchCase="'select'">
					<form [formGroup]="frameData.formGroup">
						<div class="filter-form">
							<flx-heading size="medium">{{ currentFrameData.framePrompt }}</flx-heading>
							<flx-icon class="filter" (click)="handleFrameEvent(0, null)" [type]="'remove'"></flx-icon>
						</div>
						<flx-select-list height="80vh" formControlName="select" [options$]="currentFrameData.frameData" [canFilter]="true"> </flx-select-list>
					</form>
				</div>
				<div *ngSwitchCase="'dateRange'">
					<form class="filter-info" [formGroup]="frameData.formGroup">
						<div class="filter-form">
							<flx-heading class="filter" size="medium">{{ currentFrameData.framePrompt }}</flx-heading>
							<flx-icon class="filter" (click)="handleFrameEvent(0, null)" [type]="'remove'"></flx-icon>
						</div>
						<flx-glow-line></flx-glow-line>
						<div class="close-dates">
							<div class="filter-button" (click)="handleTodayTomorrowSelection($event)">Today</div>
							<div class="filter-button" (click)="handleTodayTomorrowSelection($event)">Tomorrow</div>
						</div>

						<flx-glow-line margin="0 auto 30px auto"></flx-glow-line>
						<flx-heading color="secondary" align="left" size="small" margin="-8px 0 10px 0">Custom</flx-heading>
						<input formControlName="startDate" [flxDatepicker]="myDatePicker" id="dateOutput" autocomplete="off" placeholder="Start date" />
						<flx-datepicker #myDatePicker></flx-datepicker>
						<input formControlName="endDate" [flxDatepicker]="myDatePicker2" id="dateOutput" autocomplete="off" placeholder="End date" />
						<flx-datepicker #myDatePicker2></flx-datepicker>
						<flx-button display="block" class="filter-inputs button" color="primary" (click)="handleEnterKey()"> Continue </flx-button>
					</form>
				</div>
				<div *ngSwitchCase="'input'">
					<form [formGroup]="frameData.formGroup">
						<div class="filter-form">
							<flx-heading class="filter-inputs" size="medium">{{ currentFrameData.framePrompt }}</flx-heading>
							<flx-icon class="filter" (click)="handleFrameEvent(0, null)" [type]="'remove'"></flx-icon>
						</div>
						<flx-input-text class="filter-inputs" (keyup.enter)="handleEnterKey()" formControlName="control"> </flx-input-text>
						<flx-button class="filter-inputs button" color="primary" (click)="handleEnterKey()">Add Filter</flx-button>
						<!--  the button bellow functions as a listener for the form -->
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
