import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXTimePickerComponent } from './timepicker.component';
import { FLXFormsModule } from '../forms/forms.module';
import { FLXIconModule } from '../inline-icons';
import { FLXButtonsModule } from '../buttons';
import { FLXPopupOverlayPickerModule } from '../popup-overlay-picker';

@NgModule({
	imports: [CommonModule, FLXFormsModule, FLXButtonsModule, FLXPopupOverlayPickerModule, FLXIconModule],
	declarations: [FLXTimePickerComponent],
	exports: [FLXTimePickerComponent]
})
export class FLXTimePickerModule {}
