<flx-heading size="medium" align="center" weight="extra-light">Set New Appointment</flx-heading>
<flx-flex-container maxWidth="600px" class="appointment-details">
	<flx-heading size="small" color="secondary" align="center">Customer Details</flx-heading>
	<div class="details-holder">
		<div class="details-col">
			<flx-key-value-list align="center" [data$]="customer_details$" margin="0 0 0 0"></flx-key-value-list>

			<flx-key-value-list align="center" [data$]="onsite_details$"></flx-key-value-list>
		</div>
	</div>
	<div *ngIf="!showExistingAppointment" style="text-align: center">
		<flx-heading size="small" align="center">Appointment Details</flx-heading>
		<flx-key-value-list align="center" [data$]="existing_appointment$"></flx-key-value-list>
	</div>
</flx-flex-container>
<flx-flex-container maxWidth="50vw" class="appointment-details">
	<flx-set-appointment
		*ngIf="appointmentTypes$ | async"
		[mustIncludeSkillDetails]="mustIncludeSkillDetails"
		[appointmentTypes]="appointmentTypes$ | async"
		[appointmentTypestoExclude]="appointmentTypesToExclude"
		[(appointmentData)]="appointmentData"
		[minDate]="minDate"
		[maxDate]="maxDate"
		[minHour]="minHour"
		[maxHour]="maxHour"
		[minuteInterval]="minuteInterval"
		(appointmentTimeChange)="checkForAfterHours($event, bf.bigForm.value?.itemTwo?.skill)"
	></flx-set-appointment>
</flx-flex-container>
<flx-flex-container *ngIf="showExistingAppointment">
	<div style="margin: auto; width: 65vw">
		<flx-key-value-list [data$]="existing_appointment$" align="center" heading="Current Appointment Details"></flx-key-value-list>
	</div>
</flx-flex-container>
