import { ModuleWithProviders, NgModule } from '@angular/core';
import { FLXAlertsModule } from './alerts';
import { FLXButtonsModule } from './buttons';
import { FLXTextareaModule } from './forms/textarea';
import { FLXYesNoModule } from './yes-no';
import { FLXFormsModule } from './forms';
import { FLXHeadingsModule } from './headings';
import { FLXLayoutsModule } from './layouts';
import { FLXProgressModule } from './progress';
import { FLXIconModule } from './inline-icons';
import { FLXRatingModule } from './rating';
import { FLXReactiveFormsErrorDisplayModule } from './reactive-form-error-display';
import { FLXPanelsModule } from './panels';
import { FLXLoadingSpinnerModule } from './loading-spinner';
import { FLXLoaderModule } from './loader';
import { FLXLabelsModule } from './labels';
import { FLXLocationPickerModule } from './location-picker';
import { FLXDatepickerModule } from './datepicker';
import { DateTimeConfigurableModule } from './date-time-configurable-picker';
import { FLXTimePickerModule } from './timepicker';
import { FLXGlowLineModule } from './glow-line';
import { FileHandlerModule } from './file-handler/file-handler.module';
import { FLXTabsModule } from './tabs';
import { FLXFileViewerModule } from './files/file-viewer';
import { FLXKeyValueListModule } from './key-value-display';
import { FLXContextMenuModule } from './context-menu';
import { FLXIndicatorModule } from './indicator';
import { FLXListsModule } from './lists';
import { FLXCardsModule } from './cards';
import { FLXSuccessTickModule } from './success-tick/success-tick.module';
import { FLXNestedObjectKeyValueListModule } from './nested-object-key-value-list/nested-object-key-value-list.module';
import { FLXTableModule } from './tables';
import { FLXModalsModule } from './modals';
import { FLXNoResultsModule } from './no-results/no-results.module';
import { FLXNotesModule } from './notes/notes.module';
import { FLXScrollContainerModule } from './containers/scroll-container/scroll-container.module';
import { FLXSelectListModule } from './select-list';
import { FLXLoadingSpinnerInlineModule } from './loading-spinner-inline';
import { FLXTeamleadPickerModule } from './teamlead-picker';
import { AccountLockoutModule } from './account-lockout';
import { FLXCallLogModule } from './call-log';
import { CircleIndicatorModule } from './circle-indicator';


@NgModule({
	imports: [
    FLXModalsModule,
    FLXAlertsModule,
    FLXContextMenuModule,
    FLXScrollContainerModule,
    FLXLocationPickerModule,
    FLXButtonsModule,
    FLXFormsModule,
    FLXHeadingsModule,
    FLXIconModule,
    FLXKeyValueListModule,
    FLXLabelsModule,
    FLXLayoutsModule,
    FLXLoaderModule,
    FLXLoadingSpinnerModule,
    FLXLoadingSpinnerInlineModule,
    FLXPanelsModule,
    FLXProgressModule,
    FLXRatingModule,
    FLXSelectListModule,
    FLXTextareaModule,
    FLXYesNoModule,
    FLXReactiveFormsErrorDisplayModule,
    DateTimeConfigurableModule,
    FLXDatepickerModule,
    FLXTimePickerModule,
    FLXSuccessTickModule,
    FLXGlowLineModule,
    FLXTabsModule,
    FileHandlerModule,
    FLXFileViewerModule,
    FLXIndicatorModule,
    FLXListsModule,
    FLXCardsModule,
    FLXNestedObjectKeyValueListModule,
    FLXTableModule,
    FLXNoResultsModule,
    FLXNotesModule,
    FLXScrollContainerModule,
    FLXTeamleadPickerModule,
    FLXScrollContainerModule,
    AccountLockoutModule,
    FLXCallLogModule,
    CircleIndicatorModule
  ],
	exports: [
    FLXModalsModule,
    FLXAlertsModule,
    FLXContextMenuModule,
    FLXScrollContainerModule,
    FLXLocationPickerModule,
    FLXButtonsModule,
    FLXFormsModule,
    FLXHeadingsModule,
    FLXIconModule,
    FLXKeyValueListModule,
    FLXLabelsModule,
    FLXLayoutsModule,
    FLXLoaderModule,
    FLXLoadingSpinnerModule,
    FLXLoadingSpinnerInlineModule,
    FLXPanelsModule,
    FLXProgressModule,
    FLXRatingModule,
    FLXSelectListModule,
    FLXTextareaModule,
    FLXYesNoModule,
    FLXReactiveFormsErrorDisplayModule,
    DateTimeConfigurableModule,
    FLXDatepickerModule,
    FLXTimePickerModule,
    FLXSuccessTickModule,
    FLXGlowLineModule,
    FLXTabsModule,
    FileHandlerModule,
    FLXFileViewerModule,
    FLXIndicatorModule,
    FLXListsModule,
    FLXCardsModule,
    FLXNestedObjectKeyValueListModule,
    FLXTableModule,
    FLXNoResultsModule,
    FLXNotesModule,
    FLXScrollContainerModule,
    FLXTeamleadPickerModule,
    FLXScrollContainerModule,
    AccountLockoutModule,
    FLXCallLogModule,
    CircleIndicatorModule
  ]
})
export class UIElementsModule {
	static forRoot(config): ModuleWithProviders<UIElementsModule> {
		return {
			ngModule: UIElementsModule,
			providers: [{ provide: 'environment', useValue: config.environment }]
		};
	}
}
