<ng-container *ngIf="formattedData$ | async as vari">
	<flx-heading *ngIf="heading !== null && !(heading$ | async)" align="center" [weight]="'bold'" size="small"> {{ heading }} </flx-heading>

	<flx-heading *ngIf="heading$ | async as headingStr" align="center" [weight]="'bold'" size="small"> {{ headingStr }} </flx-heading>
	<flx-heading *ngIf="colouredHeading !== null && !(heading$ | async)" align="center" [weight]="'bold'" size="small" [color]="colouredHeading?.headingColour || ''">{{
		colouredHeading?.headingString
	}}</flx-heading>

	<ng-container *ngFor="let list of $any(vari); index as i">
		<div class="key-value-list" [ngClass]="arrClasses" [style.grid-template-columns]="width" [style.margin]="itemMargin">
			<ng-container *ngFor="let key of list | fsObjectKeys">
				<ng-container [ngSwitch]="checkProp(list[key])">
					<ng-container *ngSwitchCase="'object'">
						<flx-heading align="right" size="small" [weight]="'extra-light'"> {{ key | fsRealEnglish }} </flx-heading>
						<p></p>
						<ng-container *ngFor="let subkey of list[key] | fsObjectKeys">
							<div class="key-value-list__row__left-col">
								<b>{{ numbering ? i + 1 + '.' : '' }} {{ subkey | fsRealEnglish }}</b>
							</div>
							<div class="key-value-list__row__right-col">
								<ng-container *ngIf="checkProp(list[key][subkey]) === 'object'; else default">
									<!---->
									<div style="margin-top: 1rem">
										<ng-container *ngFor="let subKeyNested of list[key][subkey] | fsObjectKeys">
											<b>{{ numbering ? i + 1 + '.' : '' }} {{ subKeyNested | fsRealEnglish }}</b>
											&nbsp;:&nbsp;
											{{ list[key][subkey][subKeyNested] || '-' }}<br />
										</ng-container>
									</div>
									<!---->
								</ng-container>
								<ng-template #default>
									{{ list[key][subkey] || '-' }}
								</ng-template>
							</div>
						</ng-container>
						<span style="margin: 0 0 20px 0"></span><span></span>
					</ng-container>

					<ng-container *ngSwitchCase="'array'">
						<flx-heading align="right" size="small" [weight]="'extra-light'"> {{ key | fsRealEnglish }} </flx-heading>
						<p></p>
						<ng-container *ngFor="let akey of list[key]">
							<ng-container *ngIf="checkingObject(akey)">
								<ng-container *ngFor="let subkey of akey | fsObjectKeys">
									<div class="key-value-list__row__left-col">
										<b>{{ numbering ? i + 1 + '.' : '' }} {{ subkey | fsRealEnglish }}</b>
									</div>
									<div class="key-value-list__row__right-col">{{ akey[subkey] || '-' }}</div>
								</ng-container>
							</ng-container>
						</ng-container>
						<span style="margin: 0 0 20px 0"></span><span></span>
					</ng-container>

					<ng-container *ngSwitchDefault>
						<div class="key-value-list__row__left-col">
							<b>{{ numbering ? i + 1 + '.' : '' }} {{ key | fsRealEnglish }}</b>
						</div>
						<div class="key-value-list__row__right-col">{{ list[key] || '-' }}</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</ng-container>
	<flx-heading *ngIf="strapline !== null" align="center" [size]="'small'"> {{ strapline }} </flx-heading>
</ng-container>

<!-- margin="40px 0 0 0" -->
