<flx-heading size="medium" [align]="'center'" weight="extra-light" type="creation">{{ title }}</flx-heading>
<div style="text-align: center">Current Team Leader: {{ currentTeamLeader$ | async }}</div>

<form [formGroup]="bf.bigForm">
	<div class="teamLeadInfoContainer">
		<div style="text-align: center">Enter the company to retrieve the team leaders</div>
		<div class="search-container">
			<flx-input-text
				*ngIf="bf.bigForm.get('team_lead_lookup')"
				size="medium"
				type="text"
				[noEndingSpace]="true"
				[formControlName]="'team_lead_lookup'"
				class="search-team-lead"
				autocomplete="false"
				[required]="true"
				radius="4px 0 0 4px"
				[maxLength]="15"
				(keyup.enter)="lookup()"
			></flx-input-text>
			<flx-icon
				[ngClass]="bf.bigForm.get('team_lead_lookup').invalid ? 'disabled' : ''"
				type="search"
				use="button"
				color="button"
				hover="container"
				layout="right"
				(click)="lookup()"
			></flx-icon>
		</div>
		<flx-reactive-form-error-display style="text-align: center" [control]="$any(bf.bigForm)?.controls['team_lead_lookup']" class="error"> </flx-reactive-form-error-display>

		<br /><br />

		<div class="spanner">
			<div style="text-align: center">Select the Team Lead you want to allocate the job against</div>
			<br />
			<!--			<ng-container *ngIf="isLoading$ | async"> <flx-loader-component></flx-loader-component> </ng-container>-->
			<ng-container *ngIf="teamLeads?.length < 1 && hasDoneLookup"> <flx-alert type="info" [message]="errorMessage"></flx-alert> </ng-container>
			<ng-container>
				<flx-panel alignItems="center">
					<ng-container>
						<div
							*ngFor="let teamLead of teamLeads"
							[class.selected]="bf.bigForm.value?.assign_teamleader_id === teamLead.id"
							(click)="selectTeamLead(teamLead?.id, teamLead.full_name)"
							class="team-lead-result-container"
						>
							<div class="team-lead-description">
								<span>{{ teamLead.full_name }}</span>
							</div>
						</div>
					</ng-container>
				</flx-panel>
			</ng-container>
		</div>
	</div>
</form>
