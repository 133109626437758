import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { FLXAddReminderComponent } from '@flexus/ui-composites';
import { ModalService, ReminderService, getCurrentReminders, updateReminder, deleteReminder, addActiveReminder, BigFormService, getReminders, addReminder } from '@flexus/core';
import { Subscription } from 'rxjs';
import { cleanUpSub } from '@flexus/utilities';
import { LoadActionPanelComponent } from '../../../app-shell-features';
import { Reminder } from '@flexus/models';

@Component({
	selector: 'flx-reminders',
	templateUrl: './reminders.component.html',
	styleUrls: ['./reminders.component.scss']
})
export class FLXRemindersComponent implements OnInit, OnDestroy {
	// ====================================================== VARIABLES ======================================================
	reminders = [];
	remindersSub: Subscription;
	currentSubs: Subscription[] = [];
	@ViewChild(FLXAddReminderComponent) addRem: FLXAddReminderComponent;

	// ====================================================== LIFE CYCLE METHODS ======================================================
	constructor(private cdr: ChangeDetectorRef, private modal: ModalService, private reminderService: ReminderService, private store: Store<any>, private bf: BigFormService) {}

	ngOnInit() {
		this.getReminders();
		this.cdr.detectChanges();
	}

	ngOnDestroy(): void {
		cleanUpSub(this.remindersSub);
	}

	getReminders() {
    this.store.dispatch(getReminders());
		this.remindersSub = this.store.select(getCurrentReminders)?.subscribe(reminders => {
			this.reminders = this.reminderService.getCurrentReminders(reminders)?.sort(this.reminderService.sortByDateAndTime);
		});
	}

	// ====================================================== METHODS ======================================================
	addReminder(reminder: Reminder) {
    console.log({ reminder });
		this.store.dispatch(addReminder({ reminder }));
		this.cdr.detectChanges();
	}

	updateReminder(reminder: Reminder) {
		this.store.dispatch(updateReminder({ reminder }));
		this.cdr.detectChanges();
	}

	reminderCardCommandHandler(action: any) {
		switch (action.action) {
			case 'edit':
				this.addRem.openInEditMode(this.findReminder(action.id));
				break;
			case 'open':
				this.addRem.openInEditMode(this.findReminder(action.id));
				break;
			case 'openToClaim':
				this.store.dispatch(new LoadActionPanelComponent('SearchComponent'));
				this.bf?.bigForm?.get('search')?.setValue({ checkClosed: false, inSearch: action.claimNo });
				this.reminderService?.doClaimSearch();
				break;
			case 'delete':
				const currRem = this.findReminder(action.id);
				this.modal.openModalDirectly(instance => {
					instance.type = 'warning';
					instance.heading = 'Delete Reminder: ' + currRem.title + '?';
					instance.message = 'Reminder Message: ' + currRem.message;
					instance.navButtons = [
						{
							text: 'Cancel',
							linkType: 'close',
							color: 'default'
						},
						{
							text: 'Delete',
							clickHandler: event => {
								this.store.dispatch(deleteReminder({ id: action.id }));
							},
							color: 'secondary',
							linkType: 'close'
						}
					];
				});
				break;
			case 'deleteSilently':
				this.store.dispatch(deleteReminder({ id: action.id }));
				break;
			case 'showNotification':
				this.store.dispatch(addActiveReminder({ reminder: action.reminder }));
				break;
		}
	}

	findReminder(id: any) {
		return this.reminders.find(x => x.id === id);
	}
}
