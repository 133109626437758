import { Flow_0_0_2 } from '@flexus/core';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { voucherInfoNode } from '../reusable/VOUCHER_INFO_NODE';
import { convertDateTimeToTimeStamp } from '@flexus/utilities';
import { setActionPanelItems } from '../../../../app-shell-features';
import { REWORK_NODE } from '../reusable/REWORK_NODE';
import { requestQANode } from '../reusable/REQUEST_QA_NODE';
import { resolvedProblemNode } from '../reusable/RESOLVED_PROBLEM_NODE';
import { GENERIC_BFTSMAPPER } from '../reusable/GENERIC_BFTSMAPPER';

export const MUL_183: Flow_0_0_2 = {
	id: '183',
	name: 'request_for_qa',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Installation Above Voucher Price',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Installation Above Voucher Price'
		},
		viewRoles: {
			0: 'Installation Above Voucher Price'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		}
	},
	startNode: 'jobInformation',
	nodes: {
		voucherData: {
			...voucherInfoNode(183)
		},
		jobInformation: {
			showTabs: true,
			...jobInfoNode(183)
		},
		requestQA: {
			...requestQANode(183)
		},
		resolvedProblem: {
			...resolvedProblemNode(183)
		},
		rework: {
			...REWORK_NODE
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		...GENERIC_BFTSMAPPER
	}
};
