import { createSelector, createFeatureSelector } from '@ngrx/store';

export const _getAllInfo = createFeatureSelector('allInfo');

export const getAllInfo = createSelector(_getAllInfo, (state: any) => {
	if (Array.isArray(state)) {
		if (state.length > 0) {
			return state[0];
		}
		return {};
	}
	return state;
});

export const getAllInfoAdditional = createSelector(_getAllInfo, (state: any) => {
	if (Array.isArray(state) && state.length > 1) {
		let allInfoAdditional = {};
		for (let i = 1; i < state.length; i++) {
			allInfoAdditional = { ...state[i] };
		}
		return allInfoAdditional as any;
	}
	return {};
});

export const getClaimTypes = createSelector(getAllInfo, (state: any) => state.claim_types);

export const getAppointmentTypes = createSelector(getAllInfo, (state: any) => state.appointment_types as any[]);

export const getSkills = createSelector(getAllInfo, (state: any) => state.skills);

export const getReleaseNoteVersion = createSelector(getAllInfo, (state: any) => state.config_options?.desktop?.web_release_notes_version ?? undefined);
export const getReleaseNoteBody = createSelector(getAllInfo, (state: any) => state.config_options?.desktop?.web_release_notes_body ?? undefined);
export const getReleaseNote = createSelector(getReleaseNoteVersion, getReleaseNoteBody, (version, body) => ({ web_release_notes_version: version, web_release_notes_body: body }));
