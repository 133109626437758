import { createFeatureSelector, createSelector } from '@ngrx/store';

const getGlobalGState = createFeatureSelector('global');

export const getLoadingState = createSelector(getGlobalGState, (state: any) => state.loading);

export const getLoadedState = createSelector(getGlobalGState, (state: any) => state.loaded);

export const getActionPanelLoadingState = createSelector(getGlobalGState, (state: any) => state.loadingIDs.includes('action-panel'));

export const getActionPanelLoadedState = createSelector(getGlobalGState, (state: any) => state.loadedIDs.includes('action-panel'));

export const getLoadingIDsState = createSelector(getGlobalGState, (state: any) => state.loadingIDs);

export const getLoadedIDsState = createSelector(getGlobalGState, (state: any) => state.loadedIDs);
