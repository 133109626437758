import { Component, Input } from '@angular/core';
import { GoToAzureADPage } from '@flexus/core';
import { Store } from '@ngrx/store';
import { environment } from 'apps/studio/src/environments/environment';

@Component({
	selector: 'azure-login',
	templateUrl: 'azure-login.component.html',
	styleUrls: ['azure-login.component.scss']
})
export class AzureLoginComponent {
	@Input() disabled: boolean;
	@Input() azureLoginUrl: string;
	disableBackground = true;
	isSubmittingWithAzure: boolean;

	constructor(private store: Store<any>) {}

	loginWithAzure() {
		this.isSubmittingWithAzure = true;
		this.store.dispatch(new GoToAzureADPage());
		location.href = `${environment.api_url}${environment['azure']['loginUrl']}`;
	}
}
