!function (e, n) {
  "object" == typeof exports && "object" == typeof module ? module.exports = n() : "function" == typeof define && define.amd ? define("dinoql", [], n) : "object" == typeof exports ? exports.dinoql = n() : e.dinoql = n();
}(this, function () {
  return function (e) {
    var n = {};
    function t(r) {
      if (n[r]) return n[r].exports;
      var o = n[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return e[r].call(o.exports, o, o.exports, t), o.l = !0, o.exports;
    }
    return t.m = e, t.c = n, t.d = function (e, n, r) {
      t.o(e, n) || Object.defineProperty(e, n, {
        enumerable: !0,
        get: r
      });
    }, t.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, t.t = function (e, n) {
      if (1 & n && (e = t(e)), 8 & n) return e;
      if (4 & n && "object" == typeof e && e && e.__esModule) return e;
      var r = Object.create(null);
      if (t.r(r), Object.defineProperty(r, "default", {
        enumerable: !0,
        value: e
      }), 2 & n && "string" != typeof e) for (var o in e) t.d(r, o, function (n) {
        return e[n];
      }.bind(null, o));
      return r;
    }, t.n = function (e) {
      var n = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return t.d(n, "a", n), n;
    }, t.o = function (e, n) {
      return Object.prototype.hasOwnProperty.call(e, n);
    }, t.p = "", t(t.s = 30);
  }([function (e, n, t) {
    var r = t(1),
      o = t(8);
    e.exports = function (e) {
      return function n(t, i) {
        switch (arguments.length) {
          case 0:
            return n;
          case 1:
            return o(t) ? n : r(function (n) {
              return e(t, n);
            });
          default:
            return o(t) && o(i) ? n : o(t) ? r(function (n) {
              return e(n, i);
            }) : o(i) ? r(function (n) {
              return e(t, n);
            }) : e(t, i);
        }
      };
    };
  }, function (e, n, t) {
    var r = t(8);
    e.exports = function (e) {
      return function n(t) {
        return 0 === arguments.length || r(t) ? n : e.apply(this, arguments);
      };
    };
  }, function (e, n, t) {
    var r = t(1),
      o = t(0),
      i = t(8);
    e.exports = function (e) {
      return function n(t, a, u) {
        switch (arguments.length) {
          case 0:
            return n;
          case 1:
            return i(t) ? n : o(function (n, r) {
              return e(t, n, r);
            });
          case 2:
            return i(t) && i(a) ? n : i(t) ? o(function (n, t) {
              return e(n, a, t);
            }) : i(a) ? o(function (n, r) {
              return e(t, n, r);
            }) : r(function (n) {
              return e(t, a, n);
            });
          default:
            return i(t) && i(a) && i(u) ? n : i(t) && i(a) ? o(function (n, t) {
              return e(n, t, u);
            }) : i(t) && i(u) ? o(function (n, t) {
              return e(n, a, t);
            }) : i(a) && i(u) ? o(function (n, r) {
              return e(t, n, r);
            }) : i(t) ? r(function (n) {
              return e(n, a, u);
            }) : i(a) ? r(function (n) {
              return e(t, n, u);
            }) : i(u) ? r(function (n) {
              return e(t, a, n);
            }) : e(t, a, u);
        }
      };
    };
  }, function (e, n, t) {
    function r(e) {
      return (r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      })(e);
    }
    function o(e) {
      for (var n = 1; n < arguments.length; n++) {
        var t = null != arguments[n] ? arguments[n] : {},
          r = Object.keys(t);
        "function" == typeof Object.getOwnPropertySymbols && (r = r.concat(Object.getOwnPropertySymbols(t).filter(function (e) {
          return Object.getOwnPropertyDescriptor(t, e).enumerable;
        }))), r.forEach(function (n) {
          i(e, n, t[n]);
        });
      }
      return e;
    }
    function i(e, n, t) {
      return n in e ? Object.defineProperty(e, n, {
        value: t,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : e[n] = t, e;
    }
    function a(e, n) {
      if (null == e) return {};
      var t,
        r,
        o = function (e, n) {
          if (null == e) return {};
          var t,
            r,
            o = {},
            i = Object.keys(e);
          for (r = 0; r < i.length; r++) t = i[r], n.indexOf(t) >= 0 || (o[t] = e[t]);
          return o;
        }(e, n);
      if (Object.getOwnPropertySymbols) {
        var i = Object.getOwnPropertySymbols(e);
        for (r = 0; r < i.length; r++) t = i[r], n.indexOf(t) >= 0 || Object.prototype.propertyIsEnumerable.call(e, t) && (o[t] = e[t]);
      }
      return o;
    }
    function u(e) {
      var n = function (e, n) {
        if ("object" !== r(e) || null === e) return e;
        var t = e[Symbol.toPrimitive];
        if (void 0 !== t) {
          var o = t.call(e, n || "default");
          if ("object" !== r(o)) return o;
          throw new TypeError("@@toPrimitive must return a primitive value.");
        }
        return ("string" === n ? String : Number)(e);
      }(e, "string");
      return "symbol" === r(n) ? n : String(n);
    }
    var c = {
        prop: t(32),
        pathEq: t(33),
        propOr: t(40),
        path: t(9),
        pathOr: t(20),
        last: t(42),
        isNil: t(21),
        pick: t(44),
        project: t(45),
        ifElse: t(50),
        cond: t(51),
        map: t(23),
        always: t(58),
        isEmpty: t(59),
        assoc: t(26),
        dissoc: t(61),
        identity: t(22),
        find: t(62),
        assocPath: t(65),
        init: t(67),
        concat: t(68),
        pipe: t(81),
        toPairs: t(84),
        fromPairs: t(85),
        equals: t(10),
        of: t(86),
        is: t(88),
        T: t(89)
      },
      s = {
        getValue: c.path(["value", "value"]),
        getAlias: c.path(["alias", "value"]),
        getName: c.path(["name", "value"]),
        getAllProps: function (e) {
          var n = c.path(["alias", "value"], e),
            t = c.path(["name", "value"], e);
          return {
            nodeValue: c.path(["value", "value"], e),
            nodeAlias: n,
            oldNodeName: t,
            nodeName: n || t
          };
        }
      };
    e.exports = o({}, c, {
      ast: s,
      renameProp: function (e, n, t) {
        var r = t[e],
          c = a(t, [e].map(u));
        return n ? o(i({}, n, r), c) : t;
      }
    });
  }, function (e, n) {
    e.exports = Array.isArray || function (e) {
      return null != e && e.length >= 0 && "[object Array]" === Object.prototype.toString.call(e);
    };
  }, function (e, n) {
    e.exports = function (e, n) {
      return Object.prototype.hasOwnProperty.call(n, e);
    };
  }, function (e, n) {
    e.exports = function (e, n) {
      switch (e) {
        case 0:
          return function () {
            return n.apply(this, arguments);
          };
        case 1:
          return function (e) {
            return n.apply(this, arguments);
          };
        case 2:
          return function (e, t) {
            return n.apply(this, arguments);
          };
        case 3:
          return function (e, t, r) {
            return n.apply(this, arguments);
          };
        case 4:
          return function (e, t, r, o) {
            return n.apply(this, arguments);
          };
        case 5:
          return function (e, t, r, o, i) {
            return n.apply(this, arguments);
          };
        case 6:
          return function (e, t, r, o, i, a) {
            return n.apply(this, arguments);
          };
        case 7:
          return function (e, t, r, o, i, a, u) {
            return n.apply(this, arguments);
          };
        case 8:
          return function (e, t, r, o, i, a, u, c) {
            return n.apply(this, arguments);
          };
        case 9:
          return function (e, t, r, o, i, a, u, c, s) {
            return n.apply(this, arguments);
          };
        case 10:
          return function (e, t, r, o, i, a, u, c, s, f) {
            return n.apply(this, arguments);
          };
        default:
          throw new Error("First argument to _arity must be a non-negative integer no greater than ten");
      }
    };
  }, function (e, n, t) {
    "use strict";

    var r = "function" == typeof Symbol ? Symbol.for("nodejs.util.inspect.custom") : void 0;
    n.a = r;
  }, function (e, n) {
    e.exports = function (e) {
      return null != e && "object" == typeof e && !0 === e["@@functional/placeholder"];
    };
  }, function (e, n, t) {
    var r = t(0)(function (e, n) {
      for (var t = n, r = 0; r < e.length;) {
        if (null == t) return;
        t = t[e[r]], r += 1;
      }
      return t;
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(0),
      o = t(34),
      i = r(function (e, n) {
        return o(e, n, [], []);
      });
    e.exports = i;
  }, function (e, n, t) {
    var r = t(1),
      o = t(5),
      i = t(19),
      a = !{
        toString: null
      }.propertyIsEnumerable("toString"),
      u = ["constructor", "valueOf", "isPrototypeOf", "toString", "propertyIsEnumerable", "hasOwnProperty", "toLocaleString"],
      c = function () {
        "use strict";

        return arguments.propertyIsEnumerable("length");
      }(),
      s = function (e, n) {
        for (var t = 0; t < e.length;) {
          if (e[t] === n) return !0;
          t += 1;
        }
        return !1;
      },
      f = "function" != typeof Object.keys || c ? r(function (e) {
        if (Object(e) !== e) return [];
        var n,
          t,
          r = [],
          f = c && i(e);
        for (n in e) !o(n, e) || f && "length" === n || (r[r.length] = n);
        if (a) for (t = u.length - 1; t >= 0;) o(n = u[t], e) && !s(r, n) && (r[r.length] = n), t -= 1;
        return r;
      }) : r(function (e) {
        return Object(e) !== e ? [] : Object.keys(e);
      });
    e.exports = f;
  }, function (e, n) {
    e.exports = function (e) {
      return "[object String]" === Object.prototype.toString.call(e);
    };
  }, function (e, n, t) {
    "use strict";

    t.d(n, "a", function () {
      return u;
    });
    var r = t(7);
    function o(e) {
      return (o = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      })(e);
    }
    var i = 10,
      a = 2;
    function u(e) {
      return c(e, []);
    }
    function c(e, n) {
      switch (o(e)) {
        case "string":
          return JSON.stringify(e);
        case "function":
          return e.name ? "[function ".concat(e.name, "]") : "[function]";
        case "object":
          return function (e, n) {
            if (-1 !== n.indexOf(e)) return "[Circular]";
            var t = [].concat(n, [e]);
            if (e) {
              var o = function (e) {
                var n = e[String(r.a)];
                if ("function" == typeof n) return n;
                if ("function" == typeof e.inspect) return e.inspect;
              }(e);
              if (o) {
                var u = o.call(e);
                if (u !== e) return "string" == typeof u ? u : c(u, t);
              } else if (Array.isArray(e)) return function (e, n) {
                if (0 === e.length) return "[]";
                if (n.length > a) return "[Array]";
                for (var t = Math.min(i, e.length), r = e.length - t, o = [], u = 0; u < t; ++u) o.push(c(e[u], n));
                1 === r ? o.push("... 1 more item") : r > 1 && o.push("... ".concat(r, " more items"));
                return "[" + o.join(", ") + "]";
              }(e, t);
              return function (e, n) {
                var t = Object.keys(e);
                if (0 === t.length) return "{}";
                if (n.length > a) return "[" + function (e) {
                  var n = Object.prototype.toString.call(e).replace(/^\[object /, "").replace(/]$/, "");
                  if ("Object" === n && "function" == typeof e.constructor) {
                    var t = e.constructor.name;
                    if ("string" == typeof t) return t;
                  }
                  return n;
                }(e) + "]";
                return "{ " + t.map(function (t) {
                  var r = c(e[t], n);
                  return t + ": " + r;
                }).join(", ") + " }";
              }(e, t);
            }
            return String(e);
          }(e, n);
        default:
          return String(e);
      }
    }
  }, function (e, n) {
    e.exports = function (e, n) {
      for (var t = 0, r = n.length, o = Array(r); t < r;) o[t] = e(n[t]), t += 1;
      return o;
    };
  }, function (e, n, t) {
    var r = t(6),
      o = t(1),
      i = t(0),
      a = t(49),
      u = i(function (e, n) {
        return 1 === e ? o(n) : r(e, a(e, [], n));
      });
    e.exports = u;
  }, function (e, n, t) {
    var r = t(4),
      o = t(52);
    e.exports = function (e, n, t) {
      return function () {
        if (0 === arguments.length) return t();
        var i = Array.prototype.slice.call(arguments, 0),
          a = i.pop();
        if (!r(a)) {
          for (var u = 0; u < e.length;) {
            if ("function" == typeof a[e[u]]) return a[e[u]].apply(a, i);
            u += 1;
          }
          if (o(a)) return n.apply(null, i)(a);
        }
        return t.apply(this, arguments);
      };
    };
  }, function (e, n, t) {
    var r = t(53),
      o = t(54),
      i = t(55);
    function a(e, n, t) {
      for (var r = t.next(); !r.done;) {
        if ((n = e["@@transducer/step"](n, r.value)) && n["@@transducer/reduced"]) {
          n = n["@@transducer/value"];
          break;
        }
        r = t.next();
      }
      return e["@@transducer/result"](n);
    }
    function u(e, n, t, r) {
      return e["@@transducer/result"](t[r](i(e["@@transducer/step"], e), n));
    }
    var c = "undefined" != typeof Symbol ? Symbol.iterator : "@@iterator";
    e.exports = function (e, n, t) {
      if ("function" == typeof e && (e = o(e)), r(t)) return function (e, n, t) {
        for (var r = 0, o = t.length; r < o;) {
          if ((n = e["@@transducer/step"](n, t[r])) && n["@@transducer/reduced"]) {
            n = n["@@transducer/value"];
            break;
          }
          r += 1;
        }
        return e["@@transducer/result"](n);
      }(e, n, t);
      if ("function" == typeof t["fantasy-land/reduce"]) return u(e, n, t, "fantasy-land/reduce");
      if (null != t[c]) return a(e, n, t[c]());
      if ("function" == typeof t.next) return a(e, n, t);
      if ("function" == typeof t.reduce) return u(e, n, t, "reduce");
      throw new TypeError("reduce: list must be array or iterable");
    };
  }, function (e, n) {
    e.exports = {
      init: function () {
        return this.xf["@@transducer/init"]();
      },
      result: function (e) {
        return this.xf["@@transducer/result"](e);
      }
    };
  }, function (e, n, t) {
    var r = t(5),
      o = Object.prototype.toString,
      i = function () {
        return "[object Arguments]" === o.call(arguments) ? function (e) {
          return "[object Arguments]" === o.call(e);
        } : function (e) {
          return r("callee", e);
        };
      }();
    e.exports = i;
  }, function (e, n, t) {
    var r = t(2),
      o = t(41),
      i = t(9),
      a = r(function (e, n, t) {
        return o(e, i(n, t));
      });
    e.exports = a;
  }, function (e, n, t) {
    var r = t(1)(function (e) {
      return null == e;
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(1)(t(46));
    e.exports = r;
  }, function (e, n, t) {
    var r = t(0),
      o = t(16),
      i = t(14),
      a = t(17),
      u = t(56),
      c = t(15),
      s = t(11),
      f = r(o(["fantasy-land/map", "map"], u, function (e, n) {
        switch (Object.prototype.toString.call(n)) {
          case "[object Function]":
            return c(n.length, function () {
              return e.call(this, n.apply(this, arguments));
            });
          case "[object Object]":
            return a(function (t, r) {
              return t[r] = e(n[r]), t;
            }, {}, s(n));
          default:
            return i(e, n);
        }
      }));
    e.exports = f;
  }, function (e, n, t) {
    var r = t(2)(t(17));
    e.exports = r;
  }, function (e, n) {
    e.exports = function (e) {
      return "[object Object]" === Object.prototype.toString.call(e);
    };
  }, function (e, n, t) {
    var r = t(2)(function (e, n, t) {
      var r = {};
      for (var o in t) r[o] = t[o];
      return r[e] = n, r;
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(28),
      o = t(2)(r("slice", function (e, n, t) {
        return Array.prototype.slice.call(t, e, n);
      }));
    e.exports = o;
  }, function (e, n, t) {
    var r = t(4);
    e.exports = function (e, n) {
      return function () {
        var t = arguments.length;
        if (0 === t) return n();
        var o = arguments[t - 1];
        return r(o) || "function" != typeof o[e] ? n.apply(this, arguments) : o[e].apply(o, Array.prototype.slice.call(arguments, 0, t - 1));
      };
    };
  }, function (e, n, t) {
    "use strict";

    t.r(n), t.d(n, "QueryDocumentKeys", function () {
      return o;
    }), t.d(n, "BREAK", function () {
      return i;
    }), t.d(n, "visit", function () {
      return a;
    }), t.d(n, "visitInParallel", function () {
      return c;
    }), t.d(n, "visitWithTypeInfo", function () {
      return s;
    }), t.d(n, "getVisitFn", function () {
      return f;
    });
    var r = t(13),
      o = {
        Name: [],
        Document: ["definitions"],
        OperationDefinition: ["name", "variableDefinitions", "directives", "selectionSet"],
        VariableDefinition: ["variable", "type", "defaultValue", "directives"],
        Variable: ["name"],
        SelectionSet: ["selections"],
        Field: ["alias", "name", "arguments", "directives", "selectionSet"],
        Argument: ["name", "value"],
        FragmentSpread: ["name", "directives"],
        InlineFragment: ["typeCondition", "directives", "selectionSet"],
        FragmentDefinition: ["name", "variableDefinitions", "typeCondition", "directives", "selectionSet"],
        IntValue: [],
        FloatValue: [],
        StringValue: [],
        BooleanValue: [],
        NullValue: [],
        EnumValue: [],
        ListValue: ["values"],
        ObjectValue: ["fields"],
        ObjectField: ["name", "value"],
        Directive: ["name", "arguments"],
        NamedType: ["name"],
        ListType: ["type"],
        NonNullType: ["type"],
        SchemaDefinition: ["directives", "operationTypes"],
        OperationTypeDefinition: ["type"],
        ScalarTypeDefinition: ["description", "name", "directives"],
        ObjectTypeDefinition: ["description", "name", "interfaces", "directives", "fields"],
        FieldDefinition: ["description", "name", "arguments", "type", "directives"],
        InputValueDefinition: ["description", "name", "type", "defaultValue", "directives"],
        InterfaceTypeDefinition: ["description", "name", "directives", "fields"],
        UnionTypeDefinition: ["description", "name", "directives", "types"],
        EnumTypeDefinition: ["description", "name", "directives", "values"],
        EnumValueDefinition: ["description", "name", "directives"],
        InputObjectTypeDefinition: ["description", "name", "directives", "fields"],
        DirectiveDefinition: ["description", "name", "arguments", "locations"],
        SchemaExtension: ["directives", "operationTypes"],
        ScalarTypeExtension: ["name", "directives"],
        ObjectTypeExtension: ["name", "interfaces", "directives", "fields"],
        InterfaceTypeExtension: ["name", "directives", "fields"],
        UnionTypeExtension: ["name", "directives", "types"],
        EnumTypeExtension: ["name", "directives", "values"],
        InputObjectTypeExtension: ["name", "directives", "fields"]
      },
      i = {};
    function a(e, n) {
      var t = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : o,
        a = void 0,
        c = Array.isArray(e),
        s = [e],
        l = -1,
        p = [],
        v = void 0,
        d = void 0,
        y = void 0,
        h = [],
        E = [],
        N = e;
      do {
        var m = ++l === s.length,
          g = m && 0 !== p.length;
        if (m) {
          if (d = 0 === E.length ? void 0 : h[h.length - 1], v = y, y = E.pop(), g) {
            if (c) v = v.slice();else {
              for (var O = {}, A = Object.keys(v), I = 0; I < A.length; I++) {
                var T = A[I];
                O[T] = v[T];
              }
              v = O;
            }
            for (var b = 0, S = 0; S < p.length; S++) {
              var k = p[S][0],
                _ = p[S][1];
              c && (k -= b), c && null === _ ? (v.splice(k, 1), b++) : v[k] = _;
            }
          }
          l = a.index, s = a.keys, p = a.edits, c = a.inArray, a = a.prev;
        } else {
          if (d = y ? c ? l : s[l] : void 0, null == (v = y ? y[d] : N)) continue;
          y && h.push(d);
        }
        var x = void 0;
        if (!Array.isArray(v)) {
          if (!u(v)) throw new Error("Invalid AST Node: " + Object(r.a)(v));
          var C = f(n, v.kind, m);
          if (C) {
            if ((x = C.call(n, v, d, y, h, E)) === i) break;
            if (!1 === x) {
              if (!m) {
                h.pop();
                continue;
              }
            } else if (void 0 !== x && (p.push([d, x]), !m)) {
              if (!u(x)) {
                h.pop();
                continue;
              }
              v = x;
            }
          }
        }
        void 0 === x && g && p.push([d, v]), m ? h.pop() : (a = {
          inArray: c,
          index: l,
          keys: s,
          edits: p,
          prev: a
        }, s = (c = Array.isArray(v)) ? v : t[v.kind] || [], l = -1, p = [], y && E.push(y), y = v);
      } while (void 0 !== a);
      return 0 !== p.length && (N = p[p.length - 1][1]), N;
    }
    function u(e) {
      return Boolean(e && "string" == typeof e.kind);
    }
    function c(e) {
      var n = new Array(e.length);
      return {
        enter: function (t) {
          for (var r = 0; r < e.length; r++) if (!n[r]) {
            var o = f(e[r], t.kind, !1);
            if (o) {
              var a = o.apply(e[r], arguments);
              if (!1 === a) n[r] = t;else if (a === i) n[r] = i;else if (void 0 !== a) return a;
            }
          }
        },
        leave: function (t) {
          for (var r = 0; r < e.length; r++) if (n[r]) n[r] === t && (n[r] = null);else {
            var o = f(e[r], t.kind, !0);
            if (o) {
              var a = o.apply(e[r], arguments);
              if (a === i) n[r] = i;else if (void 0 !== a && !1 !== a) return a;
            }
          }
        }
      };
    }
    function s(e, n) {
      return {
        enter: function (t) {
          e.enter(t);
          var r = f(n, t.kind, !1);
          if (r) {
            var o = r.apply(n, arguments);
            return void 0 !== o && (e.leave(t), u(o) && e.enter(o)), o;
          }
        },
        leave: function (t) {
          var r,
            o = f(n, t.kind, !0);
          return o && (r = o.apply(n, arguments)), e.leave(t), r;
        }
      };
    }
    function f(e, n, t) {
      var r = e[n];
      if (r) {
        if (!t && "function" == typeof r) return r;
        var o = t ? r.leave : r.enter;
        if ("function" == typeof o) return o;
      } else {
        var i = t ? e.leave : e.enter;
        if (i) {
          if ("function" == typeof i) return i;
          var a = i[n];
          if ("function" == typeof a) return a;
        }
      }
    }
  }, function (e, n, t) {
    function r(e, n, t) {
      return n in e ? Object.defineProperty(e, n, {
        value: t,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : e[n] = t, e;
    }
    var o = t(3),
      i = t(90),
      a = t(93),
      u = {},
      c = {};
    e.exports = function (e) {
      var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {
        keep: !1
      };
      return function (t) {
        var s = JSON.stringify({
          data: e,
          query: t,
          options: n
        });
        if (s in c) return c[s];
        var f = i(t, n),
          l = o.path(["definitions", 0], f),
          p = o.ast.getName(l),
          v = (0, a(n, u).getQueryResolved)(l, r({}, p, e));
        if (n.keep || Array.isArray(e)) {
          var d = o.prop(0, Object.keys(v));
          return o.prop(d, v);
        }
        return c[s] = v, v;
      };
    }, e.exports.addResolvers = function (e) {
      if (!o.is(Object, e)) throw new Error("Invalid parameter: must be an object.");
      u = function (e) {
        for (var n = 1; n < arguments.length; n++) {
          var t = null != arguments[n] ? arguments[n] : {},
            o = Object.keys(t);
          "function" == typeof Object.getOwnPropertySymbols && (o = o.concat(Object.getOwnPropertySymbols(t).filter(function (e) {
            return Object.getOwnPropertyDescriptor(t, e).enumerable;
          }))), o.forEach(function (n) {
            r(e, n, t[n]);
          });
        }
        return e;
      }({}, e, u);
    };
  },, function (e, n, t) {
    var r = t(0),
      o = t(9),
      i = r(function (e, n) {
        return o([e], n);
      });
    e.exports = i;
  }, function (e, n, t) {
    var r = t(2),
      o = t(10),
      i = t(9),
      a = r(function (e, n, t) {
        return o(i(e, t), n);
      });
    e.exports = a;
  }, function (e, n, t) {
    var r = t(35),
      o = t(36),
      i = t(37),
      a = t(5),
      u = t(38),
      c = t(11),
      s = t(39);
    function f(e, n, t, i) {
      var a = r(e),
        u = r(n);
      function c(e, n) {
        return l(e, n, t.slice(), i.slice());
      }
      return !o(function (e, n) {
        return !o(c, n, e);
      }, u, a);
    }
    function l(e, n, t, r) {
      if (u(e, n)) return !0;
      var o = s(e);
      if (o !== s(n)) return !1;
      if (null == e || null == n) return !1;
      if ("function" == typeof e["fantasy-land/equals"] || "function" == typeof n["fantasy-land/equals"]) return "function" == typeof e["fantasy-land/equals"] && e["fantasy-land/equals"](n) && "function" == typeof n["fantasy-land/equals"] && n["fantasy-land/equals"](e);
      if ("function" == typeof e.equals || "function" == typeof n.equals) return "function" == typeof e.equals && e.equals(n) && "function" == typeof n.equals && n.equals(e);
      switch (o) {
        case "Arguments":
        case "Array":
        case "Object":
          if ("function" == typeof e.constructor && "Promise" === i(e.constructor)) return e === n;
          break;
        case "Boolean":
        case "Number":
        case "String":
          if (typeof e != typeof n || !u(e.valueOf(), n.valueOf())) return !1;
          break;
        case "Date":
          if (!u(e.valueOf(), n.valueOf())) return !1;
          break;
        case "Error":
          return e.name === n.name && e.message === n.message;
        case "RegExp":
          if (e.source !== n.source || e.global !== n.global || e.ignoreCase !== n.ignoreCase || e.multiline !== n.multiline || e.sticky !== n.sticky || e.unicode !== n.unicode) return !1;
      }
      for (var p = t.length - 1; p >= 0;) {
        if (t[p] === e) return r[p] === n;
        p -= 1;
      }
      switch (o) {
        case "Map":
          return e.size === n.size && f(e.entries(), n.entries(), t.concat([e]), r.concat([n]));
        case "Set":
          return e.size === n.size && f(e.values(), n.values(), t.concat([e]), r.concat([n]));
        case "Arguments":
        case "Array":
        case "Object":
        case "Boolean":
        case "Number":
        case "String":
        case "Date":
        case "Error":
        case "RegExp":
        case "Int8Array":
        case "Uint8Array":
        case "Uint8ClampedArray":
        case "Int16Array":
        case "Uint16Array":
        case "Int32Array":
        case "Uint32Array":
        case "Float32Array":
        case "Float64Array":
        case "ArrayBuffer":
          break;
        default:
          return !1;
      }
      var v = c(e);
      if (v.length !== c(n).length) return !1;
      var d = t.concat([e]),
        y = r.concat([n]);
      for (p = v.length - 1; p >= 0;) {
        var h = v[p];
        if (!a(h, n) || !l(n[h], e[h], d, y)) return !1;
        p -= 1;
      }
      return !0;
    }
    e.exports = l;
  }, function (e, n) {
    e.exports = function (e) {
      for (var n, t = []; !(n = e.next()).done;) t.push(n.value);
      return t;
    };
  }, function (e, n) {
    e.exports = function (e, n, t) {
      for (var r = 0, o = t.length; r < o;) {
        if (e(n, t[r])) return !0;
        r += 1;
      }
      return !1;
    };
  }, function (e, n) {
    e.exports = function (e) {
      var n = String(e).match(/^function (\w*)/);
      return null == n ? "" : n[1];
    };
  }, function (e, n) {
    e.exports = "function" == typeof Object.is ? Object.is : function (e, n) {
      return e === n ? 0 !== e || 1 / e == 1 / n : e != e && n != n;
    };
  }, function (e, n, t) {
    var r = t(1)(function (e) {
      return null === e ? "Null" : void 0 === e ? "Undefined" : Object.prototype.toString.call(e).slice(8, -1);
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(2),
      o = t(20),
      i = r(function (e, n, t) {
        return o(e, [n], t);
      });
    e.exports = i;
  }, function (e, n, t) {
    var r = t(0)(function (e, n) {
      return null == n || n != n ? e : n;
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(43)(-1);
    e.exports = r;
  }, function (e, n, t) {
    var r = t(0),
      o = t(12),
      i = r(function (e, n) {
        var t = e < 0 ? n.length + e : e;
        return o(n) ? n.charAt(t) : n[t];
      });
    e.exports = i;
  }, function (e, n, t) {
    var r = t(0)(function (e, n) {
      for (var t = {}, r = 0; r < e.length;) e[r] in n && (t[e[r]] = n[e[r]]), r += 1;
      return t;
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(14),
      o = t(22),
      i = t(47),
      a = t(48)(r, [i, o]);
    e.exports = a;
  }, function (e, n) {
    e.exports = function (e) {
      return e;
    };
  }, function (e, n, t) {
    var r = t(0)(function (e, n) {
      for (var t = {}, r = 0, o = e.length; r < o;) {
        var i = e[r];
        t[i] = n[i], r += 1;
      }
      return t;
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(0),
      o = t(15),
      i = r(function (e, n) {
        return o(n.length, function () {
          for (var t = [], r = 0; r < n.length;) t.push(n[r].call(this, arguments[r])), r += 1;
          return e.apply(this, t.concat(Array.prototype.slice.call(arguments, n.length)));
        });
      });
    e.exports = i;
  }, function (e, n, t) {
    var r = t(6),
      o = t(8);
    e.exports = function e(n, t, i) {
      return function () {
        for (var a = [], u = 0, c = n, s = 0; s < t.length || u < arguments.length;) {
          var f;
          s < t.length && (!o(t[s]) || u >= arguments.length) ? f = t[s] : (f = arguments[u], u += 1), a[s] = f, o(f) || (c -= 1), s += 1;
        }
        return c <= 0 ? i.apply(this, a) : r(c, e(n, a, i));
      };
    };
  }, function (e, n, t) {
    var r = t(2),
      o = t(15),
      i = r(function (e, n, t) {
        return o(Math.max(e.length, n.length, t.length), function () {
          return e.apply(this, arguments) ? n.apply(this, arguments) : t.apply(this, arguments);
        });
      });
    e.exports = i;
  }, function (e, n, t) {
    var r = t(6),
      o = t(1),
      i = t(23),
      a = t(57),
      u = t(24),
      c = o(function (e) {
        var n = u(a, 0, i(function (e) {
          return e[0].length;
        }, e));
        return r(n, function () {
          for (var n = 0; n < e.length;) {
            if (e[n][0].apply(this, arguments)) return e[n][1].apply(this, arguments);
            n += 1;
          }
        });
      });
    e.exports = c;
  }, function (e, n) {
    e.exports = function (e) {
      return null != e && "function" == typeof e["@@transducer/step"];
    };
  }, function (e, n, t) {
    var r = t(1),
      o = t(4),
      i = t(12),
      a = r(function (e) {
        return !!o(e) || !!e && "object" == typeof e && !i(e) && (1 === e.nodeType ? !!e.length : 0 === e.length || e.length > 0 && e.hasOwnProperty(0) && e.hasOwnProperty(e.length - 1));
      });
    e.exports = a;
  }, function (e, n) {
    var t = function () {
      function e(e) {
        this.f = e;
      }
      return e.prototype["@@transducer/init"] = function () {
        throw new Error("init not implemented on XWrap");
      }, e.prototype["@@transducer/result"] = function (e) {
        return e;
      }, e.prototype["@@transducer/step"] = function (e, n) {
        return this.f(e, n);
      }, e;
    }();
    e.exports = function (e) {
      return new t(e);
    };
  }, function (e, n, t) {
    var r = t(6),
      o = t(0)(function (e, n) {
        return r(e.length, function () {
          return e.apply(n, arguments);
        });
      });
    e.exports = o;
  }, function (e, n, t) {
    var r = t(0),
      o = t(18),
      i = function () {
        function e(e, n) {
          this.xf = n, this.f = e;
        }
        return e.prototype["@@transducer/init"] = o.init, e.prototype["@@transducer/result"] = o.result, e.prototype["@@transducer/step"] = function (e, n) {
          return this.xf["@@transducer/step"](e, this.f(n));
        }, e;
      }(),
      a = r(function (e, n) {
        return new i(e, n);
      });
    e.exports = a;
  }, function (e, n, t) {
    var r = t(0)(function (e, n) {
      return n > e ? n : e;
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(1)(function (e) {
      return function () {
        return e;
      };
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(1),
      o = t(60),
      i = t(10),
      a = r(function (e) {
        return null != e && i(e, o(e));
      });
    e.exports = a;
  }, function (e, n, t) {
    var r = t(1),
      o = t(19),
      i = t(4),
      a = t(25),
      u = t(12),
      c = r(function (e) {
        return null != e && "function" == typeof e["fantasy-land/empty"] ? e["fantasy-land/empty"]() : null != e && null != e.constructor && "function" == typeof e.constructor["fantasy-land/empty"] ? e.constructor["fantasy-land/empty"]() : null != e && "function" == typeof e.empty ? e.empty() : null != e && null != e.constructor && "function" == typeof e.constructor.empty ? e.constructor.empty() : i(e) ? [] : u(e) ? "" : a(e) ? {} : o(e) ? function () {
          return arguments;
        }() : void 0;
      });
    e.exports = c;
  }, function (e, n, t) {
    var r = t(0)(function (e, n) {
      var t = {};
      for (var r in n) t[r] = n[r];
      return delete t[e], t;
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(0)(t(16)(["find"], t(63), function (e, n) {
      for (var t = 0, r = n.length; t < r;) {
        if (e(n[t])) return n[t];
        t += 1;
      }
    }));
    e.exports = r;
  }, function (e, n, t) {
    var r = t(0),
      o = t(64),
      i = t(18),
      a = function () {
        function e(e, n) {
          this.xf = n, this.f = e, this.found = !1;
        }
        return e.prototype["@@transducer/init"] = i.init, e.prototype["@@transducer/result"] = function (e) {
          return this.found || (e = this.xf["@@transducer/step"](e, void 0)), this.xf["@@transducer/result"](e);
        }, e.prototype["@@transducer/step"] = function (e, n) {
          return this.f(n) && (this.found = !0, e = o(this.xf["@@transducer/step"](e, n))), e;
        }, e;
      }(),
      u = r(function (e, n) {
        return new a(e, n);
      });
    e.exports = u;
  }, function (e, n) {
    e.exports = function (e) {
      return e && e["@@transducer/reduced"] ? e : {
        "@@transducer/value": e,
        "@@transducer/reduced": !0
      };
    };
  }, function (e, n, t) {
    var r = t(2),
      o = t(5),
      i = t(4),
      a = t(66),
      u = t(26),
      c = t(21),
      s = r(function e(n, t, r) {
        if (0 === n.length) return t;
        var s = n[0];
        if (n.length > 1) {
          var f = !c(r) && o(s, r) ? r[s] : a(n[1]) ? [] : {};
          t = e(Array.prototype.slice.call(n, 1), t, f);
        }
        if (a(s) && i(r)) {
          var l = [].concat(r);
          return l[s] = t, l;
        }
        return u(s, t, r);
      });
    e.exports = s;
  }, function (e, n) {
    e.exports = Number.isInteger || function (e) {
      return e << 0 === e;
    };
  }, function (e, n, t) {
    var r = t(27)(0, -1);
    e.exports = r;
  }, function (e, n, t) {
    var r = t(0),
      o = t(4),
      i = t(69),
      a = t(12),
      u = t(70),
      c = r(function (e, n) {
        if (o(e)) {
          if (o(n)) return e.concat(n);
          throw new TypeError(u(n) + " is not an array");
        }
        if (a(e)) {
          if (a(n)) return e + n;
          throw new TypeError(u(n) + " is not a string");
        }
        if (null != e && i(e["fantasy-land/concat"])) return e["fantasy-land/concat"](n);
        if (null != e && i(e.concat)) return e.concat(n);
        throw new TypeError(u(e) + ' does not have a method named "concat" or "fantasy-land/concat"');
      });
    e.exports = c;
  }, function (e, n) {
    e.exports = function (e) {
      return "[object Function]" === Object.prototype.toString.call(e);
    };
  }, function (e, n, t) {
    var r = t(1),
      o = t(71),
      i = r(function (e) {
        return o(e, []);
      });
    e.exports = i;
  }, function (e, n, t) {
    var r = t(72),
      o = t(14),
      i = t(74),
      a = t(75),
      u = t(11),
      c = t(76);
    e.exports = function e(n, t) {
      var s = function (o) {
          var i = t.concat([n]);
          return r(o, i) ? "<Circular>" : e(o, i);
        },
        f = function (e, n) {
          return o(function (n) {
            return i(n) + ": " + s(e[n]);
          }, n.slice().sort());
        };
      switch (Object.prototype.toString.call(n)) {
        case "[object Arguments]":
          return "(function() { return arguments; }(" + o(s, n).join(", ") + "))";
        case "[object Array]":
          return "[" + o(s, n).concat(f(n, c(function (e) {
            return /^\d+$/.test(e);
          }, u(n)))).join(", ") + "]";
        case "[object Boolean]":
          return "object" == typeof n ? "new Boolean(" + s(n.valueOf()) + ")" : n.toString();
        case "[object Date]":
          return "new Date(" + (isNaN(n.valueOf()) ? s(NaN) : i(a(n))) + ")";
        case "[object Null]":
          return "null";
        case "[object Number]":
          return "object" == typeof n ? "new Number(" + s(n.valueOf()) + ")" : 1 / n == -1 / 0 ? "-0" : n.toString(10);
        case "[object String]":
          return "object" == typeof n ? "new String(" + s(n.valueOf()) + ")" : i(n);
        case "[object Undefined]":
          return "undefined";
        default:
          if ("function" == typeof n.toString) {
            var l = n.toString();
            if ("[object Object]" !== l) return l;
          }
          return "{" + f(n, u(n)).join(", ") + "}";
      }
    };
  }, function (e, n, t) {
    var r = t(73);
    e.exports = function (e, n) {
      return r(n, e, 0) >= 0;
    };
  }, function (e, n, t) {
    var r = t(10);
    e.exports = function (e, n, t) {
      var o, i;
      if ("function" == typeof e.indexOf) switch (typeof n) {
        case "number":
          if (0 === n) {
            for (o = 1 / n; t < e.length;) {
              if (0 === (i = e[t]) && 1 / i === o) return t;
              t += 1;
            }
            return -1;
          }
          if (n != n) {
            for (; t < e.length;) {
              if ("number" == typeof (i = e[t]) && i != i) return t;
              t += 1;
            }
            return -1;
          }
          return e.indexOf(n, t);
        case "string":
        case "boolean":
        case "function":
        case "undefined":
          return e.indexOf(n, t);
        case "object":
          if (null === n) return e.indexOf(n, t);
      }
      for (; t < e.length;) {
        if (r(e[t], n)) return t;
        t += 1;
      }
      return -1;
    };
  }, function (e, n) {
    e.exports = function (e) {
      return '"' + e.replace(/\\/g, "\\\\").replace(/[\b]/g, "\\b").replace(/\f/g, "\\f").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t").replace(/\v/g, "\\v").replace(/\0/g, "\\0").replace(/"/g, '\\"') + '"';
    };
  }, function (e, n) {
    var t = function (e) {
        return (e < 10 ? "0" : "") + e;
      },
      r = "function" == typeof Date.prototype.toISOString ? function (e) {
        return e.toISOString();
      } : function (e) {
        return e.getUTCFullYear() + "-" + t(e.getUTCMonth() + 1) + "-" + t(e.getUTCDate()) + "T" + t(e.getUTCHours()) + ":" + t(e.getUTCMinutes()) + ":" + t(e.getUTCSeconds()) + "." + (e.getUTCMilliseconds() / 1e3).toFixed(3).slice(2, 5) + "Z";
      };
    e.exports = r;
  }, function (e, n, t) {
    var r = t(77),
      o = t(0),
      i = t(78),
      a = o(function (e, n) {
        return i(r(e), n);
      });
    e.exports = a;
  }, function (e, n) {
    e.exports = function (e) {
      return function () {
        return !e.apply(this, arguments);
      };
    };
  }, function (e, n, t) {
    var r = t(0),
      o = t(16),
      i = t(79),
      a = t(25),
      u = t(17),
      c = t(80),
      s = t(11),
      f = r(o(["filter"], c, function (e, n) {
        return a(n) ? u(function (t, r) {
          return e(n[r]) && (t[r] = n[r]), t;
        }, {}, s(n)) : i(e, n);
      }));
    e.exports = f;
  }, function (e, n) {
    e.exports = function (e, n) {
      for (var t = 0, r = n.length, o = []; t < r;) e(n[t]) && (o[o.length] = n[t]), t += 1;
      return o;
    };
  }, function (e, n, t) {
    var r = t(0),
      o = t(18),
      i = function () {
        function e(e, n) {
          this.xf = n, this.f = e;
        }
        return e.prototype["@@transducer/init"] = o.init, e.prototype["@@transducer/result"] = o.result, e.prototype["@@transducer/step"] = function (e, n) {
          return this.f(n) ? this.xf["@@transducer/step"](e, n) : e;
        }, e;
      }(),
      a = r(function (e, n) {
        return new i(e, n);
      });
    e.exports = a;
  }, function (e, n, t) {
    var r = t(6),
      o = t(82),
      i = t(24),
      a = t(83);
    e.exports = function () {
      if (0 === arguments.length) throw new Error("pipe requires at least one argument");
      return r(arguments[0].length, i(o, arguments[0], a(arguments)));
    };
  }, function (e, n) {
    e.exports = function (e, n) {
      return function () {
        return n.call(this, e.apply(this, arguments));
      };
    };
  }, function (e, n, t) {
    var r = t(28),
      o = t(1)(r("tail", t(27)(1, 1 / 0)));
    e.exports = o;
  }, function (e, n, t) {
    var r = t(1),
      o = t(5),
      i = r(function (e) {
        var n = [];
        for (var t in e) o(t, e) && (n[n.length] = [t, e[t]]);
        return n;
      });
    e.exports = i;
  }, function (e, n, t) {
    var r = t(1)(function (e) {
      for (var n = {}, t = 0; t < e.length;) n[e[t][0]] = e[t][1], t += 1;
      return n;
    });
    e.exports = r;
  }, function (e, n, t) {
    var r = t(1)(t(87));
    e.exports = r;
  }, function (e, n) {
    e.exports = function (e) {
      return [e];
    };
  }, function (e, n, t) {
    var r = t(0)(function (e, n) {
      return null != n && n.constructor === e || n instanceof e;
    });
    e.exports = r;
  }, function (e, n) {
    e.exports = function () {
      return !0;
    };
  }, function (e, n, t) {
    var r = t(3),
      o = t(95).parse,
      i = t(91),
      a = t(92);
    e.exports = function (e, n) {
      var t = r.prop(0, e);
      if (!r.is(String, t)) return a(i.mergeFragments(e), n);
      var u = " \n    query MyQuery {\n      ".concat(t, "\n    }\n  ");
      return a(o(u), n);
    };
  }, function (e, n, t) {
    var r = t(29).visit,
      o = t(3);
    e.exports = {
      mergeFragments: function (e) {
        var n = o.prop("definitions", e),
          t = null;
        return r(e, {
          FragmentSpread: function (r, i, a, u) {
            var c = o.ast.getAllProps(r).nodeName,
              s = o.find(o.pathEq(["name", "value"], c), n),
              f = o.path(["selectionSet", "selections"], s),
              l = o.init(u),
              p = o.path(l, t || e),
              v = o.concat(p, f);
            return t = o.assocPath(l, v, e), null;
          }
        }), t || e;
      }
    };
  }, function (e, n, t) {
    var r = t(29).visit,
      o = t(3);
    e.exports = function (e, n) {
      var t = o.prop("variables", n);
      return r(e, {
        Variable: function (e) {
          var n = o.ast.getName(e);
          return {
            value: o.prop(n, t)
          };
        }
      });
    };
  }, function (e, n, t) {
    function r(e, n, t) {
      return n in e ? Object.defineProperty(e, n, {
        value: t,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : e[n] = t, e;
    }
    var o = t(94),
      i = t(3);
    e.exports = function (e, n) {
      var t = {},
        a = function e(t) {
          var r = t.data,
            a = t.nodeName;
          return function (t) {
            var u = i.prop(a, r),
              c = t.reduce(function (e, t) {
                var r = i.ast.getAllProps(t),
                  a = r.nodeValue,
                  u = r.nodeName;
                return i.propOr(o.filterKey(u, n), u, o)(e, a);
              }, u);
            return Array.isArray(r) ? r.map(function (n) {
              return e({
                data: n,
                nodeName: a
              })(t);
            }) : i.assoc(a, c, r);
          };
        },
        u = null,
        c = function (e) {
          var n = e.data,
            o = e.ast,
            a = e.nodeName,
            c = t;
          t = {};
          var s = n.reduce(function (e, n) {
            var t = l(o, n),
              u = i.prop(a, n);
            if (!i.isNil(u)) {
              var c = i.dissoc(a, n);
              return e.concat(function (e) {
                for (var n = 1; n < arguments.length; n++) {
                  var t = null != arguments[n] ? arguments[n] : {},
                    o = Object.keys(t);
                  "function" == typeof Object.getOwnPropertySymbols && (o = o.concat(Object.getOwnPropertySymbols(t).filter(function (e) {
                    return Object.getOwnPropertyDescriptor(t, e).enumerable;
                  }))), o.forEach(function (n) {
                    r(e, n, t[n]);
                  });
                }
                return e;
              }({}, c, t));
            }
            return e.concat(n);
          }, []);
          return t = c, u = s, s;
        },
        s = !1,
        f = function (n) {
          var o = n.nodeValue,
            a = n.nodeName,
            c = n.selections,
            f = n.data,
            p = n.props,
            v = n.hasKeep,
            d = i.cond([[Array.isArray, i.project(p)], [i.is(Object), i.pick(p)], [i.T, i.identity]])(o || []),
            y = null;
          return u = null, c.reduce(function (n, o) {
            var c;
            if (e.keep || v) return c = l(o, y || d), s = !0, y = c, i.assoc(a, c, n);
            c = l(o, u || d);
            var f = i.ast.getAllProps(o).nodeName,
              p = i.prop(f, c);
            return Array.isArray(c) && c.length ? t[a] = c : s && i.is(Object, p) ? t = r({}, f, p) : o.selectionSet || i.isNil(p) || (t[f] = p), i.equals(c, y) || (y = null), i.equals(c, u) || (u = null), t;
          }, f);
        };
      function l(e) {
        var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
          t = i.ast.getAllProps(e),
          r = t.nodeAlias,
          o = t.oldNodeName,
          u = t.nodeName,
          s = i.pathOr([], ["selectionSet", "selections"], e),
          l = i.map(i.ast.getName, s),
          p = i.propOr([], "arguments", e),
          v = i.renameProp(o, r, n),
          d = i.find(function (e) {
            return "keep" === i.ast.getName(e);
          }, p),
          y = i.ifElse(i.isEmpty, i.always(v), a({
            data: v,
            nodeName: u
          }))(p),
          h = i.prop(u, y);
        return Array.isArray(y) ? c({
          nodeName: u,
          props: l,
          data: y,
          ast: e
        }) : f({
          data: y,
          selections: s,
          nodeValue: h,
          nodeName: u,
          props: l,
          hasKeep: d
        });
      }
      return {
        getQueryResolved: l
      };
    };
  }, function (e, n, t) {
    function r(e, n, t) {
      return n in e ? Object.defineProperty(e, n, {
        value: t,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : e[n] = t, e;
    }
    function o(e) {
      return function (e) {
        if (Array.isArray(e)) {
          for (var n = 0, t = new Array(e.length); n < e.length; n++) t[n] = e[n];
          return t;
        }
      }(e) || function (e) {
        if (Symbol.iterator in Object(e) || "[object Arguments]" === Object.prototype.toString.call(e)) return Array.from(e);
      }(e) || function () {
        throw new TypeError("Invalid attempt to spread non-iterable instance");
      }();
    }
    var i = t(3);
    var a = i.pipe(i.toPairs, i.map(i.pipe(i.of, i.fromPairs))),
      u = i.ifElse(i.is(Object), Object.values, i.identity);
    e.exports = {
      last: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
        return i.last(e);
      },
      first: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
        return i.prop(0, e);
      },
      merge: function (e, n) {
        return Array.isArray(e) && Array.isArray(n) ? [].concat(o(e), o(n)) : i.is(Object, e) && i.is(Object, n) ? function (e) {
          for (var n = 1; n < arguments.length; n++) {
            var t = null != arguments[n] ? arguments[n] : {},
              o = Object.keys(t);
            "function" == typeof Object.getOwnPropertySymbols && (o = o.concat(Object.getOwnPropertySymbols(t).filter(function (e) {
              return Object.getOwnPropertyDescriptor(t, e).enumerable;
            }))), o.forEach(function (n) {
              r(e, n, t[n]);
            });
          }
          return e;
        }({}, e, n) : e;
      },
      unless: function (e, n) {
        try {
          return JSON.parse(n) ? null : e;
        } catch (e) {
          throw new Error("Resolver 'if' needs to receive a boolean type.");
        }
      },
      orderBy: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [],
          n = arguments.length > 1 ? arguments[1] : void 0;
        return e.sort(function (e, t) {
          return parseFloat(e[n]) - parseFloat(t[n]);
        });
      },
      toArray: a,
      getProp: function (e, n) {
        return i.prop(n, e);
      },
      getPath: function (e, n) {
        var t = n.split(".");
        return i.path(t, e);
      },
      toNumber: function (e) {
        return Number(e) || e;
      },
      filterKey: function (e, n) {
        return function (t, r) {
          var o = i.prop(e, n);
          if (o) return o(t, r);
          if (!Array.isArray(t)) throw new Error('Resolver "'.concat(e, '" does not exist.'));
          return t.filter(function (n) {
            return i.prop(e, n) == r;
          });
        };
      },
      if: function (e, n) {
        try {
          return JSON.parse(n) ? e : null;
        } catch (e) {
          throw new Error("Resolver 'if' needs to receive a boolean type.");
        }
      },
      defaultValue: function (e, n) {
        var t = Number(n),
          r = t || n;
        return i.isNil(e) ? r : e;
      },
      getObjectValues: u,
      keep: i.identity
    };
  }, function (e, n, t) {
    "use strict";

    t.r(n);
    var r = t(13),
      o = t(7);
    function i(e) {
      var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : e.prototype.toString;
      e.prototype.toJSON = n, e.prototype.inspect = n, o.a && (e.prototype[o.a] = n);
    }
    function a(e, n) {
      if (!e) throw new Error(n);
    }
    var u,
      c = function (e, n, t) {
        this.body = e, this.name = n || "GraphQL request", this.locationOffset = t || {
          line: 1,
          column: 1
        }, this.locationOffset.line > 0 || a(0, "line in locationOffset is 1-indexed and must be positive"), this.locationOffset.column > 0 || a(0, "column in locationOffset is 1-indexed and must be positive");
      };
    function s(e, n) {
      for (var t, r = /\r\n|[\n\r]/g, o = 1, i = n + 1; (t = r.exec(e.body)) && t.index < n;) o += 1, i = n + 1 - (t.index + t[0].length);
      return {
        line: o,
        column: i
      };
    }
    function f(e, n) {
      var t = e.locationOffset.column - 1,
        r = l(t) + e.body,
        o = n.line - 1,
        i = e.locationOffset.line - 1,
        a = n.line + i,
        u = 1 === n.line ? t : 0,
        c = n.column + u,
        s = r.split(/\r\n|[\n\r]/g);
      return "".concat(e.name, " (").concat(a, ":").concat(c, ")\n") + function (e) {
        var n = e.filter(function (e) {
            e[0];
            var n = e[1];
            return void 0 !== n;
          }),
          t = 0,
          r = !0,
          o = !1,
          i = void 0;
        try {
          for (var a, u = n[Symbol.iterator](); !(r = (a = u.next()).done); r = !0) {
            var c = a.value,
              s = c[0];
            t = Math.max(t, s.length);
          }
        } catch (e) {
          o = !0, i = e;
        } finally {
          try {
            r || null == u.return || u.return();
          } finally {
            if (o) throw i;
          }
        }
        return n.map(function (e) {
          var n,
            r = e[0],
            o = e[1];
          return l(t - (n = r).length) + n + o;
        }).join("\n");
      }([["".concat(a - 1, ": "), s[o - 1]], ["".concat(a, ": "), s[o]], ["", l(c - 1) + "^"], ["".concat(a + 1, ": "), s[o + 1]]]);
    }
    function l(e) {
      return Array(e + 1).join(" ");
    }
    function p(e, n, t, r, o, i, a) {
      var u = Array.isArray(n) ? 0 !== n.length ? n : void 0 : n ? [n] : void 0,
        c = t;
      if (!c && u) {
        var f = u[0];
        c = f && f.loc && f.loc.source;
      }
      var l,
        v = r;
      !v && u && (v = u.reduce(function (e, n) {
        return n.loc && e.push(n.loc.start), e;
      }, [])), v && 0 === v.length && (v = void 0), r && t ? l = r.map(function (e) {
        return s(t, e);
      }) : u && (l = u.reduce(function (e, n) {
        return n.loc && e.push(s(n.loc.source, n.loc.start)), e;
      }, []));
      var d = a || i && i.extensions;
      Object.defineProperties(this, {
        message: {
          value: e,
          enumerable: !0,
          writable: !0
        },
        locations: {
          value: l || void 0,
          enumerable: Boolean(l)
        },
        path: {
          value: o || void 0,
          enumerable: Boolean(o)
        },
        nodes: {
          value: u || void 0
        },
        source: {
          value: c || void 0
        },
        positions: {
          value: v || void 0
        },
        originalError: {
          value: i
        },
        extensions: {
          value: d || void 0,
          enumerable: Boolean(d)
        }
      }), i && i.stack ? Object.defineProperty(this, "stack", {
        value: i.stack,
        writable: !0,
        configurable: !0
      }) : Error.captureStackTrace ? Error.captureStackTrace(this, p) : Object.defineProperty(this, "stack", {
        value: Error().stack,
        writable: !0,
        configurable: !0
      });
    }
    function v(e, n, t) {
      return new p("Syntax Error: ".concat(t), void 0, e, [n]);
    }
    function d(e) {
      for (var n = e.split(/\r\n|[\n\r]/g), t = null, r = 1; r < n.length; r++) {
        var o = n[r],
          i = y(o);
        if (i < o.length && (null === t || i < t) && 0 === (t = i)) break;
      }
      if (t) for (var a = 1; a < n.length; a++) n[a] = n[a].slice(t);
      for (; n.length > 0 && h(n[0]);) n.shift();
      for (; n.length > 0 && h(n[n.length - 1]);) n.pop();
      return n.join("\n");
    }
    function y(e) {
      for (var n = 0; n < e.length && (" " === e[n] || "\t" === e[n]);) n++;
      return n;
    }
    function h(e) {
      return y(e) === e.length;
    }
    function E(e, n) {
      var t = new A(g.SOF, 0, 0, 0, 0, null);
      return {
        source: e,
        options: n,
        lastToken: t,
        token: t,
        line: 1,
        lineStart: 0,
        advance: N,
        lookahead: m
      };
    }
    function N() {
      return this.lastToken = this.token, this.token = this.lookahead();
    }
    function m() {
      var e = this.token;
      if (e.kind !== g.EOF) do {
        e = e.next || (e.next = T(this, e));
      } while (e.kind === g.COMMENT);
      return e;
    }
    u = c, "function" == typeof Symbol && Symbol.toStringTag && Object.defineProperty(u.prototype, Symbol.toStringTag, {
      get: function () {
        return this.constructor.name;
      }
    }), p.prototype = Object.create(Error.prototype, {
      constructor: {
        value: p
      },
      name: {
        value: "GraphQLError"
      },
      toString: {
        value: function () {
          return function (e) {
            var n = [];
            if (e.nodes) {
              var t = !0,
                r = !1,
                o = void 0;
              try {
                for (var i, a = e.nodes[Symbol.iterator](); !(t = (i = a.next()).done); t = !0) {
                  var u = i.value;
                  u.loc && n.push(f(u.loc.source, s(u.loc.source, u.loc.start)));
                }
              } catch (e) {
                r = !0, o = e;
              } finally {
                try {
                  t || null == a.return || a.return();
                } finally {
                  if (r) throw o;
                }
              }
            } else if (e.source && e.locations) {
              var c = e.source,
                l = !0,
                p = !1,
                v = void 0;
              try {
                for (var d, y = e.locations[Symbol.iterator](); !(l = (d = y.next()).done); l = !0) {
                  var h = d.value;
                  n.push(f(c, h));
                }
              } catch (e) {
                p = !0, v = e;
              } finally {
                try {
                  l || null == y.return || y.return();
                } finally {
                  if (p) throw v;
                }
              }
            }
            return 0 === n.length ? e.message : [e.message].concat(n).join("\n\n") + "\n";
          }(this);
        }
      }
    });
    var g = Object.freeze({
      SOF: "<SOF>",
      EOF: "<EOF>",
      BANG: "!",
      DOLLAR: "$",
      AMP: "&",
      PAREN_L: "(",
      PAREN_R: ")",
      SPREAD: "...",
      COLON: ":",
      EQUALS: "=",
      AT: "@",
      BRACKET_L: "[",
      BRACKET_R: "]",
      BRACE_L: "{",
      PIPE: "|",
      BRACE_R: "}",
      NAME: "Name",
      INT: "Int",
      FLOAT: "Float",
      STRING: "String",
      BLOCK_STRING: "BlockString",
      COMMENT: "Comment"
    });
    function O(e) {
      var n = e.value;
      return n ? "".concat(e.kind, ' "').concat(n, '"') : e.kind;
    }
    function A(e, n, t, r, o, i, a) {
      this.kind = e, this.start = n, this.end = t, this.line = r, this.column = o, this.value = a, this.prev = i, this.next = null;
    }
    function I(e) {
      return isNaN(e) ? g.EOF : e < 127 ? JSON.stringify(String.fromCharCode(e)) : '"\\u'.concat(("00" + e.toString(16).toUpperCase()).slice(-4), '"');
    }
    function T(e, n) {
      var t = e.source,
        r = t.body,
        o = r.length,
        i = function (e, n, t) {
          var r = e.length,
            o = n;
          for (; o < r;) {
            var i = e.charCodeAt(o);
            if (9 === i || 32 === i || 44 === i || 65279 === i) ++o;else if (10 === i) ++o, ++t.line, t.lineStart = o;else {
              if (13 !== i) break;
              10 === e.charCodeAt(o + 1) ? o += 2 : ++o, ++t.line, t.lineStart = o;
            }
          }
          return o;
        }(r, n.end, e),
        a = e.line,
        u = 1 + i - e.lineStart;
      if (i >= o) return new A(g.EOF, o, o, a, u, n);
      var c = r.charCodeAt(i);
      switch (c) {
        case 33:
          return new A(g.BANG, i, i + 1, a, u, n);
        case 35:
          return function (e, n, t, r, o) {
            var i,
              a = e.body,
              u = n;
            do {
              i = a.charCodeAt(++u);
            } while (!isNaN(i) && (i > 31 || 9 === i));
            return new A(g.COMMENT, n, u, t, r, o, a.slice(n + 1, u));
          }(t, i, a, u, n);
        case 36:
          return new A(g.DOLLAR, i, i + 1, a, u, n);
        case 38:
          return new A(g.AMP, i, i + 1, a, u, n);
        case 40:
          return new A(g.PAREN_L, i, i + 1, a, u, n);
        case 41:
          return new A(g.PAREN_R, i, i + 1, a, u, n);
        case 46:
          if (46 === r.charCodeAt(i + 1) && 46 === r.charCodeAt(i + 2)) return new A(g.SPREAD, i, i + 3, a, u, n);
          break;
        case 58:
          return new A(g.COLON, i, i + 1, a, u, n);
        case 61:
          return new A(g.EQUALS, i, i + 1, a, u, n);
        case 64:
          return new A(g.AT, i, i + 1, a, u, n);
        case 91:
          return new A(g.BRACKET_L, i, i + 1, a, u, n);
        case 93:
          return new A(g.BRACKET_R, i, i + 1, a, u, n);
        case 123:
          return new A(g.BRACE_L, i, i + 1, a, u, n);
        case 124:
          return new A(g.PIPE, i, i + 1, a, u, n);
        case 125:
          return new A(g.BRACE_R, i, i + 1, a, u, n);
        case 65:
        case 66:
        case 67:
        case 68:
        case 69:
        case 70:
        case 71:
        case 72:
        case 73:
        case 74:
        case 75:
        case 76:
        case 77:
        case 78:
        case 79:
        case 80:
        case 81:
        case 82:
        case 83:
        case 84:
        case 85:
        case 86:
        case 87:
        case 88:
        case 89:
        case 90:
        case 95:
        case 97:
        case 98:
        case 99:
        case 100:
        case 101:
        case 102:
        case 103:
        case 104:
        case 105:
        case 106:
        case 107:
        case 108:
        case 109:
        case 110:
        case 111:
        case 112:
        case 113:
        case 114:
        case 115:
        case 116:
        case 117:
        case 118:
        case 119:
        case 120:
        case 121:
        case 122:
          return function (e, n, t, r, o) {
            var i = e.body,
              a = i.length,
              u = n + 1,
              c = 0;
            for (; u !== a && !isNaN(c = i.charCodeAt(u)) && (95 === c || c >= 48 && c <= 57 || c >= 65 && c <= 90 || c >= 97 && c <= 122);) ++u;
            return new A(g.NAME, n, u, t, r, o, i.slice(n, u));
          }(t, i, a, u, n);
        case 45:
        case 48:
        case 49:
        case 50:
        case 51:
        case 52:
        case 53:
        case 54:
        case 55:
        case 56:
        case 57:
          return function (e, n, t, r, o, i) {
            var a = e.body,
              u = t,
              c = n,
              s = !1;
            45 === u && (u = a.charCodeAt(++c));
            if (48 === u) {
              if ((u = a.charCodeAt(++c)) >= 48 && u <= 57) throw v(e, c, "Invalid number, unexpected digit after 0: ".concat(I(u), "."));
            } else c = b(e, c, u), u = a.charCodeAt(c);
            46 === u && (s = !0, u = a.charCodeAt(++c), c = b(e, c, u), u = a.charCodeAt(c));
            69 !== u && 101 !== u || (s = !0, 43 !== (u = a.charCodeAt(++c)) && 45 !== u || (u = a.charCodeAt(++c)), c = b(e, c, u));
            return new A(s ? g.FLOAT : g.INT, n, c, r, o, i, a.slice(n, c));
          }(t, i, c, a, u, n);
        case 34:
          return 34 === r.charCodeAt(i + 1) && 34 === r.charCodeAt(i + 2) ? function (e, n, t, r, o, i) {
            var a = e.body,
              u = n + 3,
              c = u,
              s = 0,
              f = "";
            for (; u < a.length && !isNaN(s = a.charCodeAt(u));) {
              if (34 === s && 34 === a.charCodeAt(u + 1) && 34 === a.charCodeAt(u + 2)) return f += a.slice(c, u), new A(g.BLOCK_STRING, n, u + 3, t, r, o, d(f));
              if (s < 32 && 9 !== s && 10 !== s && 13 !== s) throw v(e, u, "Invalid character within String: ".concat(I(s), "."));
              10 === s ? (++u, ++i.line, i.lineStart = u) : 13 === s ? (10 === a.charCodeAt(u + 1) ? u += 2 : ++u, ++i.line, i.lineStart = u) : 92 === s && 34 === a.charCodeAt(u + 1) && 34 === a.charCodeAt(u + 2) && 34 === a.charCodeAt(u + 3) ? (f += a.slice(c, u) + '"""', c = u += 4) : ++u;
            }
            throw v(e, u, "Unterminated string.");
          }(t, i, a, u, n, e) : function (e, n, t, r, o) {
            var i = e.body,
              a = n + 1,
              u = a,
              c = 0,
              s = "";
            for (; a < i.length && !isNaN(c = i.charCodeAt(a)) && 10 !== c && 13 !== c;) {
              if (34 === c) return s += i.slice(u, a), new A(g.STRING, n, a + 1, t, r, o, s);
              if (c < 32 && 9 !== c) throw v(e, a, "Invalid character within String: ".concat(I(c), "."));
              if (++a, 92 === c) {
                switch (s += i.slice(u, a - 1), c = i.charCodeAt(a)) {
                  case 34:
                    s += '"';
                    break;
                  case 47:
                    s += "/";
                    break;
                  case 92:
                    s += "\\";
                    break;
                  case 98:
                    s += "\b";
                    break;
                  case 102:
                    s += "\f";
                    break;
                  case 110:
                    s += "\n";
                    break;
                  case 114:
                    s += "\r";
                    break;
                  case 116:
                    s += "\t";
                    break;
                  case 117:
                    var f = (l = i.charCodeAt(a + 1), p = i.charCodeAt(a + 2), d = i.charCodeAt(a + 3), y = i.charCodeAt(a + 4), S(l) << 12 | S(p) << 8 | S(d) << 4 | S(y));
                    if (f < 0) throw v(e, a, "Invalid character escape sequence: " + "\\u".concat(i.slice(a + 1, a + 5), "."));
                    s += String.fromCharCode(f), a += 4;
                    break;
                  default:
                    throw v(e, a, "Invalid character escape sequence: \\".concat(String.fromCharCode(c), "."));
                }
                u = ++a;
              }
            }
            var l, p, d, y;
            throw v(e, a, "Unterminated string.");
          }(t, i, a, u, n);
      }
      throw v(t, i, function (e) {
        if (e < 32 && 9 !== e && 10 !== e && 13 !== e) return "Cannot contain the invalid character ".concat(I(e), ".");
        if (39 === e) return "Unexpected single quote character ('), did you mean to use a double quote (\")?";
        return "Cannot parse the unexpected character ".concat(I(e), ".");
      }(c));
    }
    function b(e, n, t) {
      var r = e.body,
        o = n,
        i = t;
      if (i >= 48 && i <= 57) {
        do {
          i = r.charCodeAt(++o);
        } while (i >= 48 && i <= 57);
        return o;
      }
      throw v(e, o, "Invalid number, expected digit but got: ".concat(I(i), "."));
    }
    function S(e) {
      return e >= 48 && e <= 57 ? e - 48 : e >= 65 && e <= 70 ? e - 55 : e >= 97 && e <= 102 ? e - 87 : -1;
    }
    i(A, function () {
      return {
        kind: this.kind,
        value: this.value,
        line: this.line,
        column: this.column
      };
    });
    var k = Object.freeze({
        NAME: "Name",
        DOCUMENT: "Document",
        OPERATION_DEFINITION: "OperationDefinition",
        VARIABLE_DEFINITION: "VariableDefinition",
        SELECTION_SET: "SelectionSet",
        FIELD: "Field",
        ARGUMENT: "Argument",
        FRAGMENT_SPREAD: "FragmentSpread",
        INLINE_FRAGMENT: "InlineFragment",
        FRAGMENT_DEFINITION: "FragmentDefinition",
        VARIABLE: "Variable",
        INT: "IntValue",
        FLOAT: "FloatValue",
        STRING: "StringValue",
        BOOLEAN: "BooleanValue",
        NULL: "NullValue",
        ENUM: "EnumValue",
        LIST: "ListValue",
        OBJECT: "ObjectValue",
        OBJECT_FIELD: "ObjectField",
        DIRECTIVE: "Directive",
        NAMED_TYPE: "NamedType",
        LIST_TYPE: "ListType",
        NON_NULL_TYPE: "NonNullType",
        SCHEMA_DEFINITION: "SchemaDefinition",
        OPERATION_TYPE_DEFINITION: "OperationTypeDefinition",
        SCALAR_TYPE_DEFINITION: "ScalarTypeDefinition",
        OBJECT_TYPE_DEFINITION: "ObjectTypeDefinition",
        FIELD_DEFINITION: "FieldDefinition",
        INPUT_VALUE_DEFINITION: "InputValueDefinition",
        INTERFACE_TYPE_DEFINITION: "InterfaceTypeDefinition",
        UNION_TYPE_DEFINITION: "UnionTypeDefinition",
        ENUM_TYPE_DEFINITION: "EnumTypeDefinition",
        ENUM_VALUE_DEFINITION: "EnumValueDefinition",
        INPUT_OBJECT_TYPE_DEFINITION: "InputObjectTypeDefinition",
        DIRECTIVE_DEFINITION: "DirectiveDefinition",
        SCHEMA_EXTENSION: "SchemaExtension",
        SCALAR_TYPE_EXTENSION: "ScalarTypeExtension",
        OBJECT_TYPE_EXTENSION: "ObjectTypeExtension",
        INTERFACE_TYPE_EXTENSION: "InterfaceTypeExtension",
        UNION_TYPE_EXTENSION: "UnionTypeExtension",
        ENUM_TYPE_EXTENSION: "EnumTypeExtension",
        INPUT_OBJECT_TYPE_EXTENSION: "InputObjectTypeExtension"
      }),
      _ = Object.freeze({
        QUERY: "QUERY",
        MUTATION: "MUTATION",
        SUBSCRIPTION: "SUBSCRIPTION",
        FIELD: "FIELD",
        FRAGMENT_DEFINITION: "FRAGMENT_DEFINITION",
        FRAGMENT_SPREAD: "FRAGMENT_SPREAD",
        INLINE_FRAGMENT: "INLINE_FRAGMENT",
        VARIABLE_DEFINITION: "VARIABLE_DEFINITION",
        SCHEMA: "SCHEMA",
        SCALAR: "SCALAR",
        OBJECT: "OBJECT",
        FIELD_DEFINITION: "FIELD_DEFINITION",
        ARGUMENT_DEFINITION: "ARGUMENT_DEFINITION",
        INTERFACE: "INTERFACE",
        UNION: "UNION",
        ENUM: "ENUM",
        ENUM_VALUE: "ENUM_VALUE",
        INPUT_OBJECT: "INPUT_OBJECT",
        INPUT_FIELD_DEFINITION: "INPUT_FIELD_DEFINITION"
      });
    function x(e, n) {
      var t = "string" == typeof e ? new c(e) : e;
      if (!(t instanceof c)) throw new TypeError("Must provide Source. Received: ".concat(Object(r.a)(t)));
      return function (e) {
        var n = e.token;
        return {
          kind: k.DOCUMENT,
          definitions: Ie(e, g.SOF, P, g.EOF),
          loc: de(e, n)
        };
      }(E(t, n || {}));
    }
    function C(e, n) {
      var t = E("string" == typeof e ? new c(e) : e, n || {});
      Ee(t, g.SOF);
      var r = K(t, !1);
      return Ee(t, g.EOF), r;
    }
    function w(e, n) {
      var t = E("string" == typeof e ? new c(e) : e, n || {});
      Ee(t, g.SOF);
      var r = W(t);
      return Ee(t, g.EOF), r;
    }
    function R(e) {
      var n = Ee(e, g.NAME);
      return {
        kind: k.NAME,
        value: n.value,
        loc: de(e, n)
      };
    }
    function P(e) {
      if (he(e, g.NAME)) switch (e.token.value) {
        case "query":
        case "mutation":
        case "subscription":
        case "fragment":
          return j(e);
        case "schema":
        case "scalar":
        case "type":
        case "interface":
        case "union":
        case "enum":
        case "input":
        case "directive":
          return ee(e);
        case "extend":
          return function (e) {
            var n = e.lookahead();
            if (n.kind === g.NAME) switch (n.value) {
              case "schema":
                return function (e) {
                  var n = e.token;
                  me(e, "extend"), me(e, "schema");
                  var t = H(e, !0),
                    r = he(e, g.BRACE_L) ? Ie(e, g.BRACE_L, re, g.BRACE_R) : [];
                  if (0 === t.length && 0 === r.length) throw Oe(e);
                  return {
                    kind: k.SCHEMA_EXTENSION,
                    directives: t,
                    operationTypes: r,
                    loc: de(e, n)
                  };
                }(e);
              case "scalar":
                return function (e) {
                  var n = e.token;
                  me(e, "extend"), me(e, "scalar");
                  var t = R(e),
                    r = H(e, !0);
                  if (0 === r.length) throw Oe(e);
                  return {
                    kind: k.SCALAR_TYPE_EXTENSION,
                    name: t,
                    directives: r,
                    loc: de(e, n)
                  };
                }(e);
              case "type":
                return function (e) {
                  var n = e.token;
                  me(e, "extend"), me(e, "type");
                  var t = R(e),
                    r = oe(e),
                    o = H(e, !0),
                    i = ie(e);
                  if (0 === r.length && 0 === o.length && 0 === i.length) throw Oe(e);
                  return {
                    kind: k.OBJECT_TYPE_EXTENSION,
                    name: t,
                    interfaces: r,
                    directives: o,
                    fields: i,
                    loc: de(e, n)
                  };
                }(e);
              case "interface":
                return function (e) {
                  var n = e.token;
                  me(e, "extend"), me(e, "interface");
                  var t = R(e),
                    r = H(e, !0),
                    o = ie(e);
                  if (0 === r.length && 0 === o.length) throw Oe(e);
                  return {
                    kind: k.INTERFACE_TYPE_EXTENSION,
                    name: t,
                    directives: r,
                    fields: o,
                    loc: de(e, n)
                  };
                }(e);
              case "union":
                return function (e) {
                  var n = e.token;
                  me(e, "extend"), me(e, "union");
                  var t = R(e),
                    r = H(e, !0),
                    o = se(e);
                  if (0 === r.length && 0 === o.length) throw Oe(e);
                  return {
                    kind: k.UNION_TYPE_EXTENSION,
                    name: t,
                    directives: r,
                    types: o,
                    loc: de(e, n)
                  };
                }(e);
              case "enum":
                return function (e) {
                  var n = e.token;
                  me(e, "extend"), me(e, "enum");
                  var t = R(e),
                    r = H(e, !0),
                    o = fe(e);
                  if (0 === r.length && 0 === o.length) throw Oe(e);
                  return {
                    kind: k.ENUM_TYPE_EXTENSION,
                    name: t,
                    directives: r,
                    values: o,
                    loc: de(e, n)
                  };
                }(e);
              case "input":
                return function (e) {
                  var n = e.token;
                  me(e, "extend"), me(e, "input");
                  var t = R(e),
                    r = H(e, !0),
                    o = pe(e);
                  if (0 === r.length && 0 === o.length) throw Oe(e);
                  return {
                    kind: k.INPUT_OBJECT_TYPE_EXTENSION,
                    name: t,
                    directives: r,
                    fields: o,
                    loc: de(e, n)
                  };
                }(e);
            }
            throw Oe(e, n);
          }(e);
      } else {
        if (he(e, g.BRACE_L)) return j(e);
        if (ne(e)) return ee(e);
      }
      throw Oe(e);
    }
    function j(e) {
      if (he(e, g.NAME)) switch (e.token.value) {
        case "query":
        case "mutation":
        case "subscription":
          return D(e);
        case "fragment":
          return function (e) {
            var n = e.token;
            if (me(e, "fragment"), e.options.experimentalFragmentVariables) return {
              kind: k.FRAGMENT_DEFINITION,
              name: J(e),
              variableDefinitions: F(e),
              typeCondition: (me(e, "on"), Z(e)),
              directives: H(e, !1),
              selectionSet: U(e),
              loc: de(e, n)
            };
            return {
              kind: k.FRAGMENT_DEFINITION,
              name: J(e),
              typeCondition: (me(e, "on"), Z(e)),
              directives: H(e, !1),
              selectionSet: U(e),
              loc: de(e, n)
            };
          }(e);
      } else if (he(e, g.BRACE_L)) return D(e);
      throw Oe(e);
    }
    function D(e) {
      var n = e.token;
      if (he(e, g.BRACE_L)) return {
        kind: k.OPERATION_DEFINITION,
        operation: "query",
        name: void 0,
        variableDefinitions: [],
        directives: [],
        selectionSet: U(e),
        loc: de(e, n)
      };
      var t,
        r = L(e);
      return he(e, g.NAME) && (t = R(e)), {
        kind: k.OPERATION_DEFINITION,
        operation: r,
        name: t,
        variableDefinitions: F(e),
        directives: H(e, !1),
        selectionSet: U(e),
        loc: de(e, n)
      };
    }
    function L(e) {
      var n = Ee(e, g.NAME);
      switch (n.value) {
        case "query":
          return "query";
        case "mutation":
          return "mutation";
        case "subscription":
          return "subscription";
      }
      throw Oe(e, n);
    }
    function F(e) {
      return he(e, g.PAREN_L) ? Ie(e, g.PAREN_L, B, g.PAREN_R) : [];
    }
    function B(e) {
      var n = e.token;
      return {
        kind: k.VARIABLE_DEFINITION,
        variable: M(e),
        type: (Ee(e, g.COLON), W(e)),
        defaultValue: Ne(e, g.EQUALS) ? K(e, !0) : void 0,
        directives: H(e, !0),
        loc: de(e, n)
      };
    }
    function M(e) {
      var n = e.token;
      return Ee(e, g.DOLLAR), {
        kind: k.VARIABLE,
        name: R(e),
        loc: de(e, n)
      };
    }
    function U(e) {
      var n = e.token;
      return {
        kind: k.SELECTION_SET,
        selections: Ie(e, g.BRACE_L, V, g.BRACE_R),
        loc: de(e, n)
      };
    }
    function V(e) {
      return he(e, g.SPREAD) ? function (e) {
        var n = e.token;
        Ee(e, g.SPREAD);
        var t = ge(e, "on");
        if (!t && he(e, g.NAME)) return {
          kind: k.FRAGMENT_SPREAD,
          name: J(e),
          directives: H(e, !1),
          loc: de(e, n)
        };
        return {
          kind: k.INLINE_FRAGMENT,
          typeCondition: t ? Z(e) : void 0,
          directives: H(e, !1),
          selectionSet: U(e),
          loc: de(e, n)
        };
      }(e) : function (e) {
        var n,
          t,
          r = e.token,
          o = R(e);
        Ne(e, g.COLON) ? (n = o, t = R(e)) : t = o;
        return {
          kind: k.FIELD,
          alias: n,
          name: t,
          arguments: Y(e, !1),
          directives: H(e, !1),
          selectionSet: he(e, g.BRACE_L) ? U(e) : void 0,
          loc: de(e, r)
        };
      }(e);
    }
    function Y(e, n) {
      var t = n ? q : G;
      return he(e, g.PAREN_L) ? Ie(e, g.PAREN_L, t, g.PAREN_R) : [];
    }
    function G(e) {
      var n = e.token,
        t = R(e);
      return Ee(e, g.COLON), {
        kind: k.ARGUMENT,
        name: t,
        value: K(e, !1),
        loc: de(e, n)
      };
    }
    function q(e) {
      var n = e.token;
      return {
        kind: k.ARGUMENT,
        name: R(e),
        value: (Ee(e, g.COLON), Q(e)),
        loc: de(e, n)
      };
    }
    function J(e) {
      if ("on" === e.token.value) throw Oe(e);
      return R(e);
    }
    function K(e, n) {
      var t = e.token;
      switch (t.kind) {
        case g.BRACKET_L:
          return function (e, n) {
            var t = e.token,
              r = n ? Q : z;
            return {
              kind: k.LIST,
              values: Ae(e, g.BRACKET_L, r, g.BRACKET_R),
              loc: de(e, t)
            };
          }(e, n);
        case g.BRACE_L:
          return function (e, n) {
            var t = e.token;
            return {
              kind: k.OBJECT,
              fields: Ae(e, g.BRACE_L, function () {
                return function (e, n) {
                  var t = e.token,
                    r = R(e);
                  return Ee(e, g.COLON), {
                    kind: k.OBJECT_FIELD,
                    name: r,
                    value: K(e, n),
                    loc: de(e, t)
                  };
                }(e, n);
              }, g.BRACE_R),
              loc: de(e, t)
            };
          }(e, n);
        case g.INT:
          return e.advance(), {
            kind: k.INT,
            value: t.value,
            loc: de(e, t)
          };
        case g.FLOAT:
          return e.advance(), {
            kind: k.FLOAT,
            value: t.value,
            loc: de(e, t)
          };
        case g.STRING:
        case g.BLOCK_STRING:
          return X(e);
        case g.NAME:
          return "true" === t.value || "false" === t.value ? (e.advance(), {
            kind: k.BOOLEAN,
            value: "true" === t.value,
            loc: de(e, t)
          }) : "null" === t.value ? (e.advance(), {
            kind: k.NULL,
            loc: de(e, t)
          }) : (e.advance(), {
            kind: k.ENUM,
            value: t.value,
            loc: de(e, t)
          });
        case g.DOLLAR:
          if (!n) return M(e);
      }
      throw Oe(e);
    }
    function X(e) {
      var n = e.token;
      return e.advance(), {
        kind: k.STRING,
        value: n.value,
        block: n.kind === g.BLOCK_STRING,
        loc: de(e, n)
      };
    }
    function Q(e) {
      return K(e, !0);
    }
    function z(e) {
      return K(e, !1);
    }
    function H(e, n) {
      for (var t = []; he(e, g.AT);) t.push($(e, n));
      return t;
    }
    function $(e, n) {
      var t = e.token;
      return Ee(e, g.AT), {
        kind: k.DIRECTIVE,
        name: R(e),
        arguments: Y(e, n),
        loc: de(e, t)
      };
    }
    function W(e) {
      var n,
        t = e.token;
      return Ne(e, g.BRACKET_L) ? (n = W(e), Ee(e, g.BRACKET_R), n = {
        kind: k.LIST_TYPE,
        type: n,
        loc: de(e, t)
      }) : n = Z(e), Ne(e, g.BANG) ? {
        kind: k.NON_NULL_TYPE,
        type: n,
        loc: de(e, t)
      } : n;
    }
    function Z(e) {
      var n = e.token;
      return {
        kind: k.NAMED_TYPE,
        name: R(e),
        loc: de(e, n)
      };
    }
    function ee(e) {
      var n = ne(e) ? e.lookahead() : e.token;
      if (n.kind === g.NAME) switch (n.value) {
        case "schema":
          return function (e) {
            var n = e.token;
            me(e, "schema");
            var t = H(e, !0),
              r = Ie(e, g.BRACE_L, re, g.BRACE_R);
            return {
              kind: k.SCHEMA_DEFINITION,
              directives: t,
              operationTypes: r,
              loc: de(e, n)
            };
          }(e);
        case "scalar":
          return function (e) {
            var n = e.token,
              t = te(e);
            me(e, "scalar");
            var r = R(e),
              o = H(e, !0);
            return {
              kind: k.SCALAR_TYPE_DEFINITION,
              description: t,
              name: r,
              directives: o,
              loc: de(e, n)
            };
          }(e);
        case "type":
          return function (e) {
            var n = e.token,
              t = te(e);
            me(e, "type");
            var r = R(e),
              o = oe(e),
              i = H(e, !0),
              a = ie(e);
            return {
              kind: k.OBJECT_TYPE_DEFINITION,
              description: t,
              name: r,
              interfaces: o,
              directives: i,
              fields: a,
              loc: de(e, n)
            };
          }(e);
        case "interface":
          return function (e) {
            var n = e.token,
              t = te(e);
            me(e, "interface");
            var r = R(e),
              o = H(e, !0),
              i = ie(e);
            return {
              kind: k.INTERFACE_TYPE_DEFINITION,
              description: t,
              name: r,
              directives: o,
              fields: i,
              loc: de(e, n)
            };
          }(e);
        case "union":
          return function (e) {
            var n = e.token,
              t = te(e);
            me(e, "union");
            var r = R(e),
              o = H(e, !0),
              i = se(e);
            return {
              kind: k.UNION_TYPE_DEFINITION,
              description: t,
              name: r,
              directives: o,
              types: i,
              loc: de(e, n)
            };
          }(e);
        case "enum":
          return function (e) {
            var n = e.token,
              t = te(e);
            me(e, "enum");
            var r = R(e),
              o = H(e, !0),
              i = fe(e);
            return {
              kind: k.ENUM_TYPE_DEFINITION,
              description: t,
              name: r,
              directives: o,
              values: i,
              loc: de(e, n)
            };
          }(e);
        case "input":
          return function (e) {
            var n = e.token,
              t = te(e);
            me(e, "input");
            var r = R(e),
              o = H(e, !0),
              i = pe(e);
            return {
              kind: k.INPUT_OBJECT_TYPE_DEFINITION,
              description: t,
              name: r,
              directives: o,
              fields: i,
              loc: de(e, n)
            };
          }(e);
        case "directive":
          return function (e) {
            var n = e.token,
              t = te(e);
            me(e, "directive"), Ee(e, g.AT);
            var r = R(e),
              o = ue(e);
            me(e, "on");
            var i = function (e) {
              Ne(e, g.PIPE);
              var n = [];
              do {
                n.push(ve(e));
              } while (Ne(e, g.PIPE));
              return n;
            }(e);
            return {
              kind: k.DIRECTIVE_DEFINITION,
              description: t,
              name: r,
              arguments: o,
              locations: i,
              loc: de(e, n)
            };
          }(e);
      }
      throw Oe(e, n);
    }
    function ne(e) {
      return he(e, g.STRING) || he(e, g.BLOCK_STRING);
    }
    function te(e) {
      if (ne(e)) return X(e);
    }
    function re(e) {
      var n = e.token,
        t = L(e);
      Ee(e, g.COLON);
      var r = Z(e);
      return {
        kind: k.OPERATION_TYPE_DEFINITION,
        operation: t,
        type: r,
        loc: de(e, n)
      };
    }
    function oe(e) {
      var n = [];
      if (ge(e, "implements")) {
        Ne(e, g.AMP);
        do {
          n.push(Z(e));
        } while (Ne(e, g.AMP) || e.options.allowLegacySDLImplementsInterfaces && he(e, g.NAME));
      }
      return n;
    }
    function ie(e) {
      return e.options.allowLegacySDLEmptyFields && he(e, g.BRACE_L) && e.lookahead().kind === g.BRACE_R ? (e.advance(), e.advance(), []) : he(e, g.BRACE_L) ? Ie(e, g.BRACE_L, ae, g.BRACE_R) : [];
    }
    function ae(e) {
      var n = e.token,
        t = te(e),
        r = R(e),
        o = ue(e);
      Ee(e, g.COLON);
      var i = W(e),
        a = H(e, !0);
      return {
        kind: k.FIELD_DEFINITION,
        description: t,
        name: r,
        arguments: o,
        type: i,
        directives: a,
        loc: de(e, n)
      };
    }
    function ue(e) {
      return he(e, g.PAREN_L) ? Ie(e, g.PAREN_L, ce, g.PAREN_R) : [];
    }
    function ce(e) {
      var n = e.token,
        t = te(e),
        r = R(e);
      Ee(e, g.COLON);
      var o,
        i = W(e);
      Ne(e, g.EQUALS) && (o = Q(e));
      var a = H(e, !0);
      return {
        kind: k.INPUT_VALUE_DEFINITION,
        description: t,
        name: r,
        type: i,
        defaultValue: o,
        directives: a,
        loc: de(e, n)
      };
    }
    function se(e) {
      var n = [];
      if (Ne(e, g.EQUALS)) {
        Ne(e, g.PIPE);
        do {
          n.push(Z(e));
        } while (Ne(e, g.PIPE));
      }
      return n;
    }
    function fe(e) {
      return he(e, g.BRACE_L) ? Ie(e, g.BRACE_L, le, g.BRACE_R) : [];
    }
    function le(e) {
      var n = e.token,
        t = te(e),
        r = R(e),
        o = H(e, !0);
      return {
        kind: k.ENUM_VALUE_DEFINITION,
        description: t,
        name: r,
        directives: o,
        loc: de(e, n)
      };
    }
    function pe(e) {
      return he(e, g.BRACE_L) ? Ie(e, g.BRACE_L, ce, g.BRACE_R) : [];
    }
    function ve(e) {
      var n = e.token,
        t = R(e);
      if (_.hasOwnProperty(t.value)) return t;
      throw Oe(e, n);
    }
    function de(e, n) {
      if (!e.options.noLocation) return new ye(n, e.lastToken, e.source);
    }
    function ye(e, n, t) {
      this.start = e.start, this.end = n.end, this.startToken = e, this.endToken = n, this.source = t;
    }
    function he(e, n) {
      return e.token.kind === n;
    }
    function Ee(e, n) {
      var t = e.token;
      if (t.kind === n) return e.advance(), t;
      throw v(e.source, t.start, "Expected ".concat(n, ", found ").concat(O(t)));
    }
    function Ne(e, n) {
      var t = e.token;
      if (t.kind === n) return e.advance(), t;
    }
    function me(e, n) {
      var t = e.token;
      if (t.kind === g.NAME && t.value === n) return e.advance(), t;
      throw v(e.source, t.start, 'Expected "'.concat(n, '", found ').concat(O(t)));
    }
    function ge(e, n) {
      var t = e.token;
      if (t.kind === g.NAME && t.value === n) return e.advance(), t;
    }
    function Oe(e, n) {
      var t = n || e.token;
      return v(e.source, t.start, "Unexpected ".concat(O(t)));
    }
    function Ae(e, n, t, r) {
      Ee(e, n);
      for (var o = []; !Ne(e, r);) o.push(t(e));
      return o;
    }
    function Ie(e, n, t, r) {
      Ee(e, n);
      for (var o = [t(e)]; !Ne(e, r);) o.push(t(e));
      return o;
    }
    t.d(n, "parse", function () {
      return x;
    }), t.d(n, "parseValue", function () {
      return C;
    }), t.d(n, "parseType", function () {
      return w;
    }), t.d(n, "parseConstValue", function () {
      return Q;
    }), t.d(n, "parseTypeReference", function () {
      return W;
    }), t.d(n, "parseNamedType", function () {
      return Z;
    }), i(ye, function () {
      return {
        start: this.start,
        end: this.end
      };
    });
  }]);
});