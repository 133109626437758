import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { TabsData } from '@flexus/ui-elements';
import { filter, take } from 'rxjs/operators';

@Component({
	selector: 'flx-comments',
	templateUrl: './comments.component.html',
	styleUrls: ['./comments.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXCommentsComponent implements OnInit {
	constructor(private cdr: ChangeDetectorRef) {}
	currentTab$: Observable<any>;
	tabs: TabsData[] = [];
	displayData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
	allComments: any;
	@Input() comments$: Observable<any>;

	ngOnInit() {
		this.comments$
			.pipe(
				filter(x => !!x),
				take(1)
			)
			.subscribe(comments => {
				this.currentTab$ = of(this.initialTab(comments[0]));
				this.populateTabs(comments);
				this.allComments = comments;
				this.cdr.detectChanges();
			});
	}

	handleTab(tabInfo) {
		this.currentTab$ = of(tabInfo?.target);
		const displayInfo = JSON.parse(JSON.stringify(this.allComments.find(x => `${x?.tabName}-${x?.id}` === tabInfo?.target)));
		const { tabName, id, ...rest } = displayInfo;
		this.displayData$.next([rest]);
	}

	populateTabs(tabs) {
		for (const tab of tabs) {
			this.tabs.push({
				display: tab?.tabName,
				targetId: `${tab?.tabName}-${tab?.id}`,
				show: true
			});
		}
	}

	initialTab(tab) {
		const displayInfo = JSON.parse(JSON.stringify(tab));
		const { tabName, id, ...rest } = displayInfo;
		this.displayData$?.next([rest]);
		return `${tab?.tabName}-${tab?.id}`;
	}
}
