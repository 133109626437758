<header [ngClass]="{ 'module-header': !_sp_invoicing, 'sp-invoicing-module-header': _sp_invoicing }">
	<!-- <div class="waffle"><flx-icon type="workflow-waffle"></flx-icon></div> -->
	<div *ngIf="settings?.addAppMenu" class="hamburger-menu" (click)="toggleMenu()" [ngClass]="{ active: openAppMenu$ | async }" [class.disableMenu]="disableMenu">
		<flx-icon [type]="'app-menu-hamburger'"></flx-icon>
	</div>

	<div class="current-module">
		<ng-content select=".component-title"></ng-content>
		{{ title$ | async }}
		<!-- <div *ngIf="_sp_invoicing" class="invoice-range"><flx-invoice-daterange-picker></flx-invoice-daterange-picker></div> -->
	</div>

	<div class="module-actions">
		<flx-header-actions *ngIf="settings?.addHeaderActions" [manifestItem]="manifestItem"></flx-header-actions>
		<!-- <ng-container [ngComponentOutlet]="headerManifest?.actionsComponent"> </ng-container> -->
	</div>
</header>
<!-- <flx-app-menu *ngIf="settings?.addAppMenu" [opened]="openAppMenu$ | async"></flx-app-menu> -->
<!-- <flx-glow-line [color]="isOffline ? 'danger' : 'success'" size="100%"></flx-glow-line> -->
<flx-glow-line [color]="!isOffline && showMessage ? 'success' : isOffline ? 'danger' : 'default'" size="100%"></flx-glow-line>
