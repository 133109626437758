<flx-flex-container alignItems="center">
	<br />
	<flx-heading size="medium" align="center" type="creation" weight="extra-light" margin="0 0 1rem">Current Setup</flx-heading>

	<!-- <form [formGroup]="($any(bf.bigForm)?.controls)?.['current_environment']"> -->
	<!-- <ng-container formGroupName="current_environment"> -->
	<!-- <form *ngIf="bf.bigForm" [formGroup]="($any(bf.bigForm)?.controls)?.['current_environment']"> -->
	<form *ngIf="bf.bigForm" [formGroup]="($any(bf.bigForm)?.controls)?.['current_environment']">
		<div [ngClass]="is_sp_job_card === true ? 'current-setup-container-sp' : 'current-setup-container'">
			<div>
				<ng-container>
					<div class="job-card-input">
						<flx-dropdown
							(click)="typeofTVDecisionHandler($event)"
							formControlName="no_of_tvs"
							[displayOptions]="displayConfig"
							[itemsOption]="noOfTVs?.options"
							[placeHolder]="noOfTVs?.description"
							[searchEnabled]="false"
							(itemsSelected)="setItem($event, 'no_of_tvs', 'noOfTVs')"
						>
						</flx-dropdown>
					</div>
				</ng-container>
			</div>

			<div>
				<ng-container>
					<div class="job-card-input">
						<flx-dropdown
							formControlName="type_of_tvs"
							[displayOptions]="displayConfig"
							[itemsOption]="typeOfTVs?.options"
							[placeHolder]="typeOfTVs?.description"
							[searchEnabled]="false"
							(itemsSelected)="setItem($event, 'type_of_tvs', 'typeOfTVs')"
						>
						</flx-dropdown>
					</div>
				</ng-container>
			</div>

			<div>
				<ng-container>
					<div class="job-card-input">
						<flx-dropdown
							formControlName="no_view_environments"
							[displayOptions]="displayConfig"
							[itemsOption]="noOfViews?.options"
							[placeHolder]="noOfViews?.description"
							[searchEnabled]="false"
							(itemsSelected)="setItem($event, 'no_view_environments', 'noOfViews')"
						>
						</flx-dropdown>
					</div>
				</ng-container>
			</div>

			<div>
				<ng-container>
					<div class="job-card-input">
						<flx-dropdown
							formControlName="dwelling_type"
							[displayOptions]="displayConfig"
							[itemsOption]="dwellingType?.options"
							[placeHolder]="dwellingType?.description"
							[searchEnabled]="false"
							(itemsSelected)="setItem($event, 'dwelling_type', 'dwellingType')"
						>
						</flx-dropdown>
					</div>
				</ng-container>
			</div>
		</div>
		<div *ngIf="is_sp_job_card === true" class="mounted-installation">
			<flx-heading size="small" weight="extra-light" align="'center'">Is a mounted installation allowed?</flx-heading>
			<flx-dropdown
				formControlName="mounted_installation"
				[displayOptions]="displayConfig"
				[itemsOption]="mountedInstallation?.options"
				[placeHolder]="mountedInstallation?.description"
				[searchEnabled]="false"
				(itemsSelected)="setItem($event, 'mounted_installation', 'mountedInstallation')"
			>
			</flx-dropdown>
		</div>
	</form>
	<!-- </ng-container> -->
</flx-flex-container>
