import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { map, skipWhile, filter, take, pluck, switchMap } from 'rxjs/operators';
import { iif, of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';

export const BUI_VIEW_CLAIM_SUMMARY: Flow_0_0_2 = {
	id: 'ClaimSummary',
	itemType: 'flow',
	name: 'View Claim Summary',
	excludeForOnlyStates: [169],
	// excludeForOnlyRoles: [18],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(x => !x),
				map(itemOne => {
					if (itemOne && itemOne?.applicant && itemOne?.applicant?.information) {
						return `Summary for this claim on policy - ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
						// return `Claim Summary : ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Claim Summary';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ClaimSummary',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		// ClaimSummary: {
		//   component: 'ClaimSummaryComponent',
		//   navs: [],
		//   inputs: {
		//     title: 'Claim Information'
		//   }
		// },
		ClaimSummary: {
			component: {
				children: [
					{
						component: 'ClaimSummaryComponent',
						inputs: { title: 'Claim Information', assessorName$: 'assessorName' }
					}
				]
			},
			navs: [],
			serverCalls: {
				assessorName: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x),
							take(1),
							pluck('assessor'),
							switchMap(assessor => {
								return iif(
									() => assessor,
									http
										.post(environment.api_url + 'v1/staff_action/get_staff_member_names', {
											ids: [assessor]
										})
										.pipe(
											map((data: any) => {
												const namesMapper = data.payload;
												return namesMapper[assessor];
											})
										),
									of('Not Assigned')
								);
							})
						);
					}
				}
			}
		}
	}
};
