<form [formGroup]="bf.bigForm">
	<ng-container *ngIf="setCompanyAndTeam">
		<flx-teamlead-lookup-composite
			[propagatedFailReasons$]="$any(fail_reasons_array)"
			[sps$]="serviceProviders$"
			(continueEvent)="receiveTeamleadFailReason($event)"
			[currentTeamLeader$]=""
		></flx-teamlead-lookup-composite>
	</ng-container>
	<ng-container *ngIf="isNoTeamleadNameFailure">
		<flx-heading-with-instructions title="Re-allocate Installer Technician" color="primary"></flx-heading-with-instructions>
		<flx-key-value-list heading="Installation Details" [data$]="installationInfo$"></flx-key-value-list>
		<div class="center-input"><flx-input-text width="40vw" placeholder="Enter team leader name" formControlName="teamlead_name"></flx-input-text></div>
		<div class="center-input">
			<flx-button color="primary" type="button" [disabled]="bf.bigForm.get('teamlead_name')?.invalid" (click)="submitAndSetSPStaffNameFixSummaryNode()"
				>add team lead name</flx-button
			>
		</div>
	</ng-container>
	<ng-container *ngIf="isNoTeamleadAccreditationFailure">
		<flx-heading-with-instructions
			title="Correct the Installer Technician Accreditation Number"
			color="primary"
			[instructions]="['Please contact the company to obtain the installer technician accreditation number']"
		></flx-heading-with-instructions>
		<div class="installation-details">
			<flx-key-value-list heading="Installation Details" color="warning" [data$]="installationInfo$"></flx-key-value-list>
		</div>
		<div class="installer-important-information">
			<flx-heading color="alert" size="small" align="center" weight="bold">Important Information</flx-heading>
			<flx-key-value-list [data$]="importantTLInfo$" color="secondary"></flx-key-value-list>
		</div>
		<div class="center-input"><flx-input-text width="40vw" placeholder="Installer Accreditation Number" formControlName="teamlead_accreditation_number"></flx-input-text></div>
		<div class="center-input">
			<flx-button color="primary" type="button" [disabled]="bf.bigForm.get('teamlead_accreditation_number')?.invalid" (click)="submitAndSetSPStaffAccreditationFixSummaryNode()"
				>Change Accreditation Number</flx-button
			>
		</div>
	</ng-container>
	<ng-container *ngIf="isCompanyAccreditationFailure">
		<flx-heading-with-instructions
			title="Re-sumbit Company Accreditation Number"
			color="primary"
			[instructions]="['Please contact the company to obtain the company accreditation number']"
		></flx-heading-with-instructions>
		<div class="installation-details"><flx-key-value-list heading="Installation Details" [data$]="companyKeyValueListData$"></flx-key-value-list></div>
		<div class="center-input" style="margin-top: 40px">
			<flx-input-text width="40vw" placeholder="Company Accreditation Number" formControlName="company_accreditation_number"></flx-input-text>
		</div>
		<div class="center-input">
			<flx-button color="primary" type="button" [disabled]="bf.bigForm.get('company_accreditation_number')?.invalid" (click)="submitAndSetSPFixSummaryNode()"
				>Change Accreditation Number</flx-button
			>
		</div>
	</ng-container>

	<!-- isNoTeamleadAccreditationFailure -->
</form>
