import { Action } from '@ngrx/store';

// export const CHECK_NETWORK_CONNECTION = '[Network] Check Network Connection';
// export const UPDATE_NETWORK_CONNECTION = '[Network] Update Network Connection';

// export const CHECK_SOCKET_ENDPOINT = '[Network] Check Socket Endpoint';

export enum actionTypes {
	CHECK_NETWORK_CONNECTION = '[Network] Check Network Connection',
	UPDATE_NETWORK_CONNECTION = '[Network] Update Network Connection',
	CHECK_SOCKET_ENDPOINT = '[Network] Check Socket Endpoint'
}

export class CheckNetwork implements Action {
	readonly type = actionTypes.CHECK_NETWORK_CONNECTION;
  constructor(public payload: any) {}
}

export class UpdateNetworkConnection implements Action {
	readonly type = actionTypes.UPDATE_NETWORK_CONNECTION;
	constructor(public payload: boolean) {}
}

// export class CheckSocketEndpoint implements Action {
// 	readonly type = actionTypes.CHECK_SOCKET_ENDPOINT;
// 	constructor(public payload: boolean) {}
// }

export type offlineActions = CheckNetwork | UpdateNetworkConnection;
