import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXInvoiceDaterangePickerComponent } from './invoice-daterange-picker.component';
import { FLXFormsModule, FLXDatepickerModule, FLXButtonsModule } from '@flexus/ui-elements';

@NgModule({
	declarations: [FLXInvoiceDaterangePickerComponent],
	imports: [CommonModule, FLXFormsModule, FLXDatepickerModule, FLXButtonsModule],
	exports: [FLXInvoiceDaterangePickerComponent]
})
export class FLXInvoiceDaterangePickerModule {}
