import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { map } from 'rxjs/operators';

export const PGG_SP_DETAILS: Flow_0_0_2 = {
	id: 'SPDetails',
	itemType: 'flow',
	name: 'SP Details',
	excludeForOnlyStates: [169, 20],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo) {
						return `SP Details : ${itemTwo?.claim?.loan_information?.voucher_key} - ${itemTwo?.claim?.applicant?.first_name}`;
					} else {
						return 'SP Details';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'SPDetails',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		SPDetails: {
			component: 'FLXSPDetailsJobContextComponent',
			navs: [],
			inputs: {
				title: 'SP Details'
			}
		}
	}
};
