import { Flow_0_0_2, getFullItemOne, getCurrentUser } from '@flexus/core';
import { map, take, skipWhile, pluck } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';

export const BET_CLAIM_REALLOCATION: Flow_0_0_2 = {
	id: 'Claim_Reallocation',
	itemType: 'flow',
	name: 'Reallocate Claim Handler',
	excludeForOnlyStates: [7, 9, 169, 197, 198],
	excludeForOnlyRoles: [18],
	header: {
		title: store =>
			store.select(getFullItemOne).pipe(
				map((itemOne: any) => {
					if (itemOne) return `Reallocate Claim:  ${itemOne?.mid}  -  ${itemOne?.applicant?.surname}`;
					return 'Reallocate Claim';
				})
			),
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'assignHandler',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: _i => () => [{}],
	serverCalls: {
		authorisers: {
			serviceVariable: 'silService',
			functionName: 'getClaimsHandlers',
			errorMessage: 'No claim handlers can be found!'
		},
		isTeamLeader: {
			errorMessage: 'Cannot get users role',
			directCall: (_h, store, _s, _b, _c, modal) =>
				store.select(getCurrentUser).pipe(
					skipWhile(f => !f),
					take(1),
					pluck('user', 'is_team_leader'),
					map(res => {
						if (!res) {
							modal.openModalDirectly(instance => {
								instance.type = 'warning';
								instance.subheading = 'Only Team Leaders can reallocate a claim';
								instance.message = 'Please speak to your Team Leader to get this claim reallocated.';
								instance.closeButton = false;
								instance.backgroundClose = false;
								instance.navButtons = [
									{
										text: 'Back to workflow',
										clickHandler: _e => {
											instance.router.navigate(['/workflow']);
										},
										linkType: 'close',
										color: 'alert'
									}
								];
							});
						}
						return res;
					})
				)
		}
	},
	nodes: {
		assignHandler: {
			component: 'FLXAuthoriserSelectComponent',
			inputs: {
				heading: 'Reallocate Claim Handler',
				subheading: 'Select a claim handler to take over this claim',
				canFilter: true,
				maxWidth: '540px',
				reason: true
			},
			initFormFields: bf => {
				bf.patchValues({ nextApprovalStaffName: null });
				bf.addControl('reason', new UntypedFormControl('', Validators.required));
				bf.bigForm.get('nextApprovalStaffName')?.setValidators([Validators.required]);
			},
			checkValidityForFields: ['nextApprovalStaffName', 'reason'],
			navs: [
				{
					text: 'Assign Claim',
					serverFirst: true,
					color: 'primary',
					linkType: 'submit',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'changeClaimHandler',
							errorMessage: 'Could not assign handler!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		'itemOne.id': 'claim_id',
		nextApprovalStaffName: [name => (!!name ? name[0] : null), 'staff_id'],
		reason: 'reason'
	}
};
