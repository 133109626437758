<div style="margin: auto; width: 85vw">
	<ng-container *ngIf="!isOffline; else offline">
		<div class="flex-container">
			<div class="flex-left">
				<div *ngIf="client === 'multichoice'">
					<flx-heading size="small" weight="bold" align="center">Installation Details</flx-heading>
					<flx-key-value-list [data$]="installationDetail$" align="center"></flx-key-value-list>
				</div>
				<div [formGroup]="bf.bigForm">
					<flx-select-list
						heading="Select Registered Team Leaders"
						subheading="{{ subHeading }}"
						[canFilter]="true"
						[includeUserDisplay]="includeUserDisplay"
						[disableOptionWhen]="disableOptionWhen"
						[collapseOnSelect]="false"
						(selectOptionFunc)="selectionHandler()"
						*ngIf="tlListData$ && client === 'multichoice'"
						[options$]="tlListData$"
						formControlName="assign_teamleader_id"
						height="40vw"
					></flx-select-list>
					<flx-select-list
						heading="Select Registered Team Leaders"
						subheading="{{ subHeading }}"
						[canFilter]="true"
						[includeUserDisplay]="includeUserDisplay"
						[disableOptionWhen]="disableOptionWhen"
						[collapseOnSelect]="false"
						*ngIf="tlListData$ && client !== 'multichoice'"
						[options$]="tlListData$"
						formControlName="assign_teamleader_id"
					></flx-select-list>
				</div>
			</div>
			<div class="flex-right"><flx-teamlead-picker [joblocation$]="jobLocationData$" [teamleadersPositions$]="teamleaderLocations$"></flx-teamlead-picker></div>
		</div>
		<!-- <div *ngIf="displayCollectorMessage$ | async" style="margin: 25px auto">{{ multichoiceCollectorMessage }}</div>-->
	</ng-container>
	<ng-template #offline><flx-alert type="danger" [message]="offlineMessage"></flx-alert></ng-template>
</div>
