<div class="job-excess-details">
	<div class="excess" [formGroup]="excessData">
		<div class="job-description">
			<span>{{ skillString }}</span>
		</div>

		<!-- <div class="payment-details-container__item__details"> -->
		<div *ngIf="!jobCard?.is_cancelled" class="amount">
			<span class="no-select">R&nbsp;&nbsp;</span>
			<flx-input-text formControlName="amount" type="currency" placeholder="0" [decimal]="0" margin="0"></flx-input-text>
		</div>

		<div *ngIf="!jobCard?.is_cancelled" class="collection">
			<flx-dropdown
				#who_collects_picker
				[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
				[itemsOption]="whoPaysExcessOptions"
				formControlName="who_collects"
				[placeHolder]="'Select who collects'"
				[searchEnabled]="false"
			></flx-dropdown>
		</div>

		<div *ngIf="!jobCard?.is_cancelled" class="method">
			<flx-dropdown
				#payment_method_picker
				[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
				[itemsOption]="howExcessIsPaidOptions"
				formControlName="payment_method"
				[placeHolder]="'Payment method'"
				[searchEnabled]="false"
			></flx-dropdown>
		</div>

		<div *ngIf="jobCard?.is_cancelled" class="waiver no-select"><span>Excess Waived</span></div>

		<div class="void-icon">
			<flx-icon
				*ngIf="canRemove || (jobCard?.skill !== 'Loss Adjusting' && jobCard?.skill !== 'Internal Assessor')"
				class="payment-details-container__item__icon--cancel"
				[ngClass]="{ 'has-cancelled': jobCard?.is_cancelled }"
				[type]="'remove'"
			>
				<!-- (click)="toggleCancellation(jobCard)" add back at a later stage-->
			</flx-icon>
		</div>
		<flx-glow-line *ngIf="!!jobCard?.value" margin="0.5rem 0"></flx-glow-line>
	</div>
</div>
