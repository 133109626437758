import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { UXReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { ThemeEffects } from './effects';

@NgModule({
	imports: [StoreModule.forFeature('ux', UXReducers), EffectsModule.forFeature([ThemeEffects])]
})
export class UXStoreModule {}
