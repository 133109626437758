import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, from, empty } from 'rxjs';

import { take, map, tap } from 'rxjs/operators';
import { OfflineService } from '@flexus/core';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class ClaimService {
	id = Math.random();
	constructor(private http: HttpClient, private offlineService: OfflineService) {
		// this.offlineService.createClaim({ tempKey: `${this.id}`, jobs: [], applicant: { first_name: `Tom${this.id}`, surname: 'Amanu' } });
	}

	getAllClaims(): Observable<any> {
		if (window.navigator.onLine) {
			return this.http.get<any[]>(
				environment.api_url + 'v1/staff_action/get_summary/'
				// { params: { page: '1' } }
			);
		} else {
			return this.offlineService.getAllClaims();
		}
	}

	updateClaim(claim): Observable<any> {
		return this.http.post(`${environment.api_url}v1/claim_action/update_claim/`, claim);
	}

	getClaimAppointments(params: { claim_id: number }): Observable<any> {
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim_appointments`, {
			claim_id: params.claim_id
		});
	}

	getFullClaim(claim_id: number): Observable<any> {
		// return this.http.get<any[]>(environment.api_url + `v1/claim/${claimId}/`);
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim/`, { claim_id, full: false });
	}

	getFullClaimWithJobs(claim_id: number): Observable<any> {
		// return this.http.get<any[]>(environment.api_url + `v1/claim/${claimId}/`);
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim/`, { claim_id, full: true });
	}

	createClaim(claim) {
		return this.http.post(`${environment.api_url}v1/claim_action/create_claim/`, claim);
	}

	cancelClaim(data: any) {
		return this.http.post(`${environment.api_url}v1/claim_action/cancel_claim`, data);
	}

	reopenClaim(data: any) {
		return this.http.post(`${environment.api_url}v1/claim_action/reopen_claim`, data);
	}

	getClaimExtra(claimId: number): Observable<any> {
		const body = { claim_id: claimId };
		return this.http.post(environment.api_url + `v1/claim_action/get_summary_extras/`, body).pipe(
			take(1),
			map((extras: any) => extras.payload)
		);
	}

	getCloseClaimInfo(params: any): Observable<any> {
		return this.http.post(environment.api_url + `v1/claim_action/can_finalise_claim/`, params);
	}
	finaliseClaim(params: { claim_id: number }): Observable<any> {
		return this.http.post(environment.api_url + `v1/claim_action/finalise_claim/`, params);
	}

	policyHistoryLookup(data: { claim_id: string }) {
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim_history/`, {
			search: '',
			claim_id: data?.claim_id
		});
	}

	searchClaims(params: { search: string; active: boolean }) {
		return this.http.post<any>(environment.api_url + `v1/claim_action/search_claims/`, { ...params });
	}

	checkCatastrophe(param: { claim_type: number; postal_code: number; loss_date: string }) {
		return this.http.post(environment.api_url + `v1/cat_action/check_catastrophe/`, param);
	}

	getExcessDetailsService(data: { claim_id: number }) {
		return this.http.post<any>(`${environment.api_url}v1/claim_action/get_excess_details/`, data);
	}
}
