import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, UntypedFormControl, Validators } from '@angular/forms';
import { BigFormService, StoreQuery, getSelectedItemTwo } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, skip, skipWhile, take } from 'rxjs/operators';

@Component({
	selector: 'flx-billing',
	templateUrl: 'billing.component.html',
	styleUrls: ['billing.component.scss']
})
export class BillingComponent implements OnInit, OnDestroy {
	@Input() docTitle: string;
	@Input() boqLogo: string;
	@Input() boqLogoAlt: string;
	@Input() clientName = 'SIL';
	@Input() saveDraft = false;
	@Input() showLineItemGenerator: boolean;
	@Input() canEditLineItems: boolean;
	@Input() rejectBoqLineItems: boolean;

	@Input() docType: 'invoice' | 'quote';
	@Input() numberLabel: string;
	@Input() currentState: number;
	@Input() newState: number;
	@Input() valueChanges: (...args: any[]) => void;
	actualLineItemsFormArray: FormArray<any>;
	subscription: Subscription;

	constructor(public bf: BigFormService, private http: HttpClient, private store: Store<any>, private sq: StoreQuery) {}

	ngOnDestroy(): void {
		this.subscription && this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.bf.bigForm.addControl('invoiceDate', new UntypedFormControl(new Date(), Validators.required));
		this.bf.bigForm.addControl('invoiceNumber', new UntypedFormControl('', Validators.required));
		this.bf.bigForm.addControl('currentState', new UntypedFormControl(''));
		this.bf.bigForm.addControl('invoiceNotes', new UntypedFormControl(''));
		this.bf.bigForm.addControl('new_state', new UntypedFormControl(this.newState));
		this.bf.bigForm.addControl('vatPercentage', new UntypedFormControl(0));
		this.bf.bigForm.addControl('hasBoq', new UntypedFormControl(false));
		this.bf.bigForm.addControl('actualLineItemsFormArray', new FormArray([], [Validators.required]));

		this.store
			.select(getSelectedItemTwo)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe((job: any) => {
				const localDraft = JSON.parse(localStorage.getItem(`DRAFT_${job.id}`));

				if (localDraft) {
					this.bf.bigForm.patchValue({
						actualLineItemsFormArray: localDraft.actualLineItemsFormArray,
						invoiceDate: localDraft.date,
						invoiceNotes: localDraft.notes,
						invoiceNumber: localDraft.invoiceNumber,
						vatPercentage: localDraft.vatPercentage,
						hasBoqItem: localDraft.hasBoqItem
					});
				}
			});

		this.actualLineItemsFormArray = this.bf.bigForm.get('actualLineItemsFormArray') as FormArray;
		this.valueChanges &&
			(this.subscription = this.bf.bigForm
				.get('actualLineItemsFormArray')
				.valueChanges.pipe(
					skip(1),
					distinctUntilChanged((prevValue, currValue) => {
						// Use deep comparison to check for equality of objects and arrays
						return JSON.stringify(prevValue) === JSON.stringify(currValue);
					})
				)
				.subscribe((lineItems: any[]) => this.valueChanges(this.http, this.store, this.sq, lineItems)));
	}
}
