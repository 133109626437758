import { UntypedFormControl } from '@angular/forms';
import { getSelectedItemTwo, NodePoint_0_0_2, SetNextNode } from '@flexus/core';
import { environment } from 'apps/studio/src/environments/environment';
import { skipWhile, take, switchMap, map } from 'rxjs/operators';

export const SAVE_TO_DESKTOP: NodePoint_0_0_2 = {
	nodeType: 'decision',
	initFormFields: (bf, item, instance, sq, store) => {
		bf.addControl('invoice_data', new UntypedFormControl(null));
	},
	serverCalls: {
		getInvoice: {
			errorMessage: `Could not get invoice`,
			directCall: (http, store, sg, bf) => {
				return store
					.select(getSelectedItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1),
						// map((data: any) => data),
						switchMap(itemTwo => {
							return http.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
								job_id: itemTwo?.id,
								return_type: 1
							});
						})
					)
					.pipe(
						map(payload => {
							console.log('INVOICEDATA', payload);
							bf.bigForm.get('invoice_data').patchValue(payload?.payload);
							return payload?.payload;
						})
					);
			}
		}
	},
	decisions: {
		saveToDesktop: (navs, store, modal, controller) => {
			modal.openModalDirectly((instance, stor, bf) => {
				(instance.heading = 'Save this on your computer?'),
					(instance.type = 'warning'),
					(instance.message = `This invoice will be saved in 'Downloads?`),
					(instance.navButtons = [
						{
							text: 'NO',
							clickHandler: event => {
								instance.close();
								controller.dispatch(new SetNextNode('SubmissionSuccess'));
							},
							linkType: 'close',
							color: 'alert'
						},
						{
							text: 'YES',
							clickHandler: event => {
								controller.dispatch(new SetNextNode('DynamicAnchor'));
								instance.close();
							},
							color: 'alert'
						}
					]);
			});
		}
	},
	navs: [
		{
			text: ''
		}
	]
};
