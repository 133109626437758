import { OverlayRef } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
export class FileOverlayControl {
	backdropClickSub: Subscription;
	constructor(private overlayRef: OverlayRef) {
		this.backdropClickSub = overlayRef.backdropClick().subscribe(_ => this.close());
		this.disableScrolling();
	}

	close(): void {
		this.enableScrolling();
		this.overlayRef.dispose();
	}

	disableScrolling() {
		const x = window.scrollX;
		const y = window.scrollY;
		window.onscroll = () => {
			window.scrollTo(x, y);
		};
	}

	enableScrolling() {
		window.onscroll = () => {};
	}
}
