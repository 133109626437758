<div class="dynamic-component">
	<ng-template #loader> <flx-loader-component></flx-loader-component> </ng-template>

	<div class="content">
		<flx-heading size="medium" align="center" weight="extra-light">{{ heading }}</flx-heading>

		<div id="photos" contents="photos">
			<ng-container *ngIf="photos$ | async as photos; else loader">
				<flx-file-browser
					[showFilters]="false"
					paginationId="photos"
					*ngIf="photos?.length > 0; else noPhotos"
					[files$]="photos$"
					display="image-grid"
					[location]="location"
				></flx-file-browser>
			</ng-container>
			<ng-template #noPhotos> <flx-alert type="no-info" missingItem="photos"></flx-alert> </ng-template>
		</div>
	</div>
</div>
