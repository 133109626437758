import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayContains',
})
export class ArrayContainsPipe implements PipeTransform {
  transform(sourceArray: Record<string, unknown>[], searchArray: Record<string, unknown>[]): boolean {
    if (!sourceArray || !searchArray) {
      return false;
    }

    for (const searchItem of searchArray) {
      if (sourceArray.some((item) => this.#isEqual(item, searchItem))) {
        return true;
      }
    }

    return false;
  }

  #isEqual = (
    obj1: Record<string, unknown>,
    obj2: Record<string, unknown>
  ): boolean => JSON.stringify(obj1) === JSON.stringify(obj2);
}
