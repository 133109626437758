<flx-base-invoice-and-credit-note-numbers></flx-base-invoice-and-credit-note-numbers>
<div class="line-items-table">
	<div class="line-items-table__heading">
		<div class="line-items-table__heading__item line-items-table__heading__item--description">Installation Type
		</div>
		<div class="line-items-table__heading__item">Voucher Number</div>
		<div class="line-items-table__heading__item">{{ isNonVatSP ? 'Amount' : 'Amount Excl Vat' }}</div>
		<div *ngIf="!isNonVatSP" class="line-items-table__heading__item">Vat Amount</div>
	</div>
	<ng-container>
		<div class="line-items-table__item">
			<!-- edit -->
			<ng-container *ngFor="let lineItemFormGroup of actualLineItemsFormArray.controls; let i = index">
				<flx-credit-note-line-item-modify [isNonVatSP]="isNonVatSP" [index]="i"
					[lineItemFormGroup]="convertControlToFormGroup(lineItemFormGroup)"
					[actualLineItemsFormArray]="actualLineItemsFormArray" (addItem)="addLineItem()"
					(removeItem)="removeLineItem(i)"></flx-credit-note-line-item-modify>
			</ng-container>
		</div>
	</ng-container>
</div>