import { Action } from '@ngrx/store';

export const GET_INVOICES_BY_DATE = '[MC Autopay] Get Invoices by Date';
export const GET_INVOICES_BY_DATE_SUCCESS = '[MC Autopay] Get Invoices by Date Success';
export const GET_INVOICES_BY_DATE_FAIL = '[MC Autopay] Get Invoices by Date Fail';
export const GET_INVOICES_LOADED_STATE = '[MC Autopay] Get Invoices Loaded State';
export const GET_INVOICES_LOADED_STATE_SUCCESS = '[MC Autopay] Get Invoices Loaded State Success';
export const GET_INVOICES_LOADED_STATE_FAIL = '[MC Autopay] Get Invoices Loaded State Fail';
export const GET_INVOICE_BY_NUMBER = '[MC Autopay] Get Invoice By Number';
export const GET_INVOICE_BY_NUMBER_SUCCESS = '[MC Autopay] Get Invoice By Number Success';
export const GET_INVOICE_BY_NUMBER_FAIL = '[MC Autopay] Get Invoice By Number Fail';
export const DOWNLOAD_INVOICE = '[MC Autopay] Download Invoice';
export const DOWNLOAD_INVOICE_SUCCESS = '[MC Autopay] Download Invoice Success';
export const POST_DOWNLOAD_INVOICE_SUCCESS = '[MC Autopay] Post Download Invoice Success';
export const DOWNLOAD_INVOICE_FAIL = '[MC Autopay] Download Invoice Fail';
export const SET_NUMBERED_INVOICE = '[MC Autopay] Set Numbered Invoice';
export const SET_NUMBERED_INVOICE_FAIL = '[MC Autopay] Set Numbered Invoice Fail';
export const GET_NUMBERED_DOWNLOADED_INVOICE_STATE = '[MC Autopay] Get Numbered Invoice';

export class GetInvoicesByDate implements Action {
	readonly type = GET_INVOICES_BY_DATE;
	constructor(public payload: any) {}
}
export class GetInvoicesByDateSuccess implements Action {
	readonly type = GET_INVOICES_BY_DATE_SUCCESS;
	constructor(public payload) {}
}
export class GetInvoicesByDateFail implements Action {
	readonly type = GET_INVOICES_BY_DATE_SUCCESS;
	constructor(public payload: any) {}
}
export class GetInvoicesLoadedState implements Action {
	readonly type = GET_INVOICES_LOADED_STATE;
	constructor(public payload: any) {}
}
export class GetInvoiceByNumber implements Action {
	readonly type = GET_INVOICE_BY_NUMBER;
	constructor(public payload: any) {}
}
export class GetInvoiceByNumberSuccess implements Action {
	readonly type = GET_INVOICE_BY_NUMBER_SUCCESS;
	constructor(public payload: any) {}
}
export class GetInvoiceByNumberFail implements Action {
	readonly type = GET_INVOICE_BY_NUMBER_FAIL;
	constructor(public payload: any) {}
}
export class DownloadInvoice implements Action {
	readonly type = DOWNLOAD_INVOICE;
	constructor(public payload: any) {}
}
export class DownloadInvoiceSuccess implements Action {
	readonly type = DOWNLOAD_INVOICE_SUCCESS;
	constructor(public payload: any) {}
}
export class PostDownloadInvoiceSuccess implements Action {
	readonly type = POST_DOWNLOAD_INVOICE_SUCCESS;
	constructor() {}
}
export class DownloadInvoiceFail implements Action {
	readonly type = DOWNLOAD_INVOICE_FAIL;
	constructor(public payload: any) {}
}
export class GetNumberedDownloadedInvoiceState implements Action {
	readonly type = GET_NUMBERED_DOWNLOADED_INVOICE_STATE;
	constructor(public payload: any) {}
}
export class SetNumberedInvoiceNull implements Action {
	readonly type = SET_NUMBERED_INVOICE;
	constructor(public payload: any) {}
}
export class SetNumberedInvoiceNullFail implements Action {
	readonly type = SET_NUMBERED_INVOICE_FAIL;
	constructor(public payload: any) {}
}

export type MCAutopayInvoicingActions =
	| GetInvoicesByDate
	| GetInvoicesByDateSuccess
	| GetInvoicesByDateFail
	| GetInvoicesLoadedState
	| GetInvoiceByNumber
	| GetInvoiceByNumberSuccess
	| GetInvoiceByNumberFail
	| DownloadInvoice
	| DownloadInvoiceSuccess
	| PostDownloadInvoiceSuccess
	| DownloadInvoiceFail
	| SetNumberedInvoiceNull
	| GetNumberedDownloadedInvoiceState;
