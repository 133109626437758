import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Inject, OnChanges } from '@angular/core';
import { FileOverlayControl } from './file-overlay-control';
import { FILE_OVERLAY_DATA } from './file-overlay.token';

export interface InjectedFile {
	file: any /* this is a file with base64source, url, filename  ectc */;
	mimeType: string;
	containerHeight: string;
}
@Component({
	selector: 'flx-file-overlay',
	templateUrl: './file-overlay.component.html',
	styleUrls: ['./file-overlay.component.scss']
})
export class FLXFileOverlayComponent implements OnInit, OnChanges {
	source;
	base64Source: string;
	containerHeight = '400px'; // default height
	mimeType: string;
	styles: { [id: string]: string };
	displayArrows = false;
	filename: string;

	constructor(public dialogRef: FileOverlayControl, @Inject(FILE_OVERLAY_DATA) public injectedFile: InjectedFile, private _sanitizer: DomSanitizer) {
		this.source = injectedFile.file.url;
		this.base64Source = injectedFile.file.base64Source;
		this.containerHeight = injectedFile.containerHeight;
		this.mimeType = injectedFile.mimeType;
		this.filename = injectedFile.file.filename;

		this.updateFileDetails();
	}
	ngOnInit() {}

	ngOnChanges(): void {
		this.updateFileDetails();
	}

	updateFileDetails(): void {
		const blob = this.base64StringtoBlob(this.base64Source, 'image/jpg');
		this.source = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
	}

	closePreview() {
		this.dialogRef.close();
	}

	createDownload(dSource: string) {
		let temp = null;
		if (dSource != null) {
			const blob = this.base64StringtoBlob(dSource, 'application/pdf');
			temp = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
		}
		return temp;
	}

	base64StringtoBlob(b64Data, contentType = '', sliceSize = 512): Blob {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}
}
