import { HttpClient } from '@angular/common/http';
import { BigFormService, GetInvoiceByNumber, GetInvoicesByDate, GetInvoicesService, ModalService } from '@flexus/core';
import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Subscription, } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
	selector: 'flx-invoice-daterange-picker',
	templateUrl: './invoice-daterange-picker.component.html',
	styleUrls: ['./invoice-daterange-picker.component.scss']
})
export class FLXInvoiceDaterangePickerComponent implements OnInit, AfterViewInit {
	displayConfig = { displayKey: 'display', valueKey: 'value' };
	datesub: Subscription;
	showSearchButton = false;
	invoicenumberchangesub: Subscription;

	constructor(
		private _http: HttpClient,
		private _store: Store<any>,
		public _bf: BigFormService,
		private _modal: ModalService,
		private _invoice_service: GetInvoicesService,
		private _render: Renderer2
	) {}
	@Input() invoiceRangeOptions: any[];
	// @Input() invoiceRangeOptions$: Observable<any>;

	@ViewChildren('datePicker1') datePicker1: any;
	@ViewChildren('datePicker2') datePicker2: any;
	@ViewChild('htmlinput', { static: true }) htmlinput: ElementRef;

	monthName: string;
	monthNamesArray = [];
	oneMonthAgo;
	twoMonthsAgo;
	threeMonthsAgo;
	fiveMonthsAgo;
	thismonthname;
	oneMonthAgoMonthName;
	twoMonthsAgoMonthName;
	threeMonthsAgoMonthName;
	fiveMonthsAgoMonthName;
	lastDateOfPreviousMonth;
	firstDateOfPreviousMonth;
	currentDate = moment();
	custom_daterange = false;

	ngOnInit(): void {
		// console.log('# INVOICE NUM', this.invoiceNUM.inputContainer.nativeElement);
		this._bf.addControl(
			'dates',
			new UntypedFormGroup({
				startDate_picker: new UntypedFormControl('', [Validators.required]),
				endDate_picker: new UntypedFormControl('', [Validators.required])
			})
		);
		this._bf.addControl(
			'numbersearch',
			new UntypedFormGroup({
				invoiceNumber: new UntypedFormControl('', [Validators.required])
			})
		);

		this.invoiceRangeOptions = [
			{ display: 'THIS MONTH', value: 1 },
			{ display: '2 MONTHS', value: 2 },
			{ display: '3 MONTHS', value: 3 },
			{ display: '6 MONTHS', value: 4 },
			{ display: 'ENTER PERIOD', value: 5 }
		];
	}

	getInvoiceDateRangeFromPeriodSelection(e: any) {
		const currentDate = moment();
		let startDateString;
		let endDateString;
		this.thismonthname = this.currentDate.format('MMMM');
		const today = moment();
		const formatted_today = today.format('YYYY-MM-DD');
		switch (e) {
			case 1:
				this._invoice_service.setUILoadingStatus(true);
				// this._store.dispatch(new GetInvoicesLoadedState(false));
				this.custom_daterange = false;
				this.monthName = this.currentDate.format('MMMM');

				const yesterdayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
				const firstDateOfCurrentMonth = currentDate.startOf('month');
				let formattedFirstDayOfCurrentMonth = firstDateOfCurrentMonth.format('YYYY-MM-DD');
				// console.log('FORMATTED TODAY', formattedFirstDayOfCurrentMonth, formatted_today);

				this._store.dispatch(new GetInvoicesByDate([formattedFirstDayOfCurrentMonth, formatted_today]));
				break;
			case 2:
				this._invoice_service.setUILoadingStatus(true);
				// this._store.dispatch(new GetInvoicesLoadedState(false));
				this.custom_daterange = false;

				this.twoMonthsAgo = this.currentDate.clone().subtract(2, 'months');
				this.oneMonthAgo = this.currentDate.clone().subtract(1, 'months');
				// this.twoMonthsAgoMonthName = this.twoMonthsAgo.format('MMMM');
				this.oneMonthAgoMonthName = this.oneMonthAgo.format('MMMM');
				this.monthNamesArray.push(this.oneMonthAgoMonthName);
				this.monthNamesArray.push(this.thismonthname);
				this.monthName = this.monthNamesArray.join(' and ');
				this.monthNamesArray = [];
				this.firstDateOfPreviousMonth = currentDate.clone().subtract(1, 'months').startOf('month');
				let firstDateOfTwoMontshBefore = currentDate.clone().subtract(2, 'months').startOf('month');
				let formattedfirstDateOfPreviousMonth = this.firstDateOfPreviousMonth.format('YYYY-MM-DD');
				let formattedfirstDateOfTwoMontshBefore = firstDateOfTwoMontshBefore.format('YYYY-MM-DD');
				this._store.dispatch(new GetInvoicesByDate([formattedfirstDateOfPreviousMonth, formatted_today]));
				break;
			case 3:
				this._invoice_service.setUILoadingStatus(true);

				// this._store.dispatch(new GetInvoicesLoadedState(false));
				this.custom_daterange = false;
				// this.threeMonthsAgo = this.currentDate.clone().subtract(3, 'months');
				this.twoMonthsAgo = this.currentDate.clone().subtract(2, 'months');
				this.oneMonthAgo = this.currentDate.clone().subtract(1, 'months');
				// this.threeMonthsAgoMonthName = this.threeMonthsAgo.format('MMMM');
				// this.monthNamesArray.push(this.threeMonthsAgoMonthName);
				this.twoMonthsAgoMonthName = this.twoMonthsAgo.format('MMMM');
				this.monthNamesArray.push(this.twoMonthsAgoMonthName);
				this.oneMonthAgoMonthName = this.oneMonthAgo.format('MMMM');
				this.monthNamesArray.push(this.oneMonthAgoMonthName);
				this.monthNamesArray.push(this.thismonthname);

				this.monthName = this.monthNamesArray[0] + ', ' + this.monthNamesArray[1] + ' and ' + this.monthNamesArray[2];
				this.monthNamesArray = [];
				let firstDateOfTwoMonthsAgo = this.twoMonthsAgo.startOf('months').format('YYYY-MM-DD');
				// this.lastDateOfPreviousMonth = this.oneMonthAgo.endOf('months').format('YYYY-MM-DD');
				this._store.dispatch(new GetInvoicesByDate([firstDateOfTwoMonthsAgo, formatted_today]));

				break;
			case 4:
				this._invoice_service.setUILoadingStatus(true);

				// this._store.dispatch(new GetInvoicesLoadedState(false));
				this.custom_daterange = false;
				this.fiveMonthsAgo = this.currentDate.clone().subtract(5, 'months');
				this.fiveMonthsAgoMonthName = this.fiveMonthsAgo.format('MMMM');
				this.oneMonthAgo = this.currentDate.clone().subtract(1, 'months');
				this.oneMonthAgoMonthName = this.oneMonthAgo.format('MMMM');
				this.monthName = this.fiveMonthsAgoMonthName + ' - ' + this.thismonthname;
				// this.lastDateOfPreviousMonth = this.oneMonthAgo.endOf('months').format('YYYY-MM-DD');
				let firstDateOfFiveMonthsAgo = this.fiveMonthsAgo.startOf('months').format('YYYY-MM-DD');

				this._store.dispatch(new GetInvoicesByDate([firstDateOfFiveMonthsAgo, formatted_today]));
				break;
			case 5:
				// this._store.dispatch(new GetInvoicesLoadedState(false));
				// this._invoice_service.setUILoadingStatus(true);
				console.log('BOOM BOOM BOOM');
				this.custom_daterange = true;
				const dates_control = this._bf.getControl('dates');
				this.datesub = dates_control?.valueChanges.subscribe(value => {
					startDateString = value.startDate_picker;
					endDateString = value.endDate_picker;
					if (startDateString && endDateString) {
						let startDateObj = new Date(startDateString);
						let endDateObj = new Date(endDateString);
						let startDateYear = startDateObj.getFullYear();
						let endDateYear = endDateObj.getFullYear();
						let startDateMonth = (startDateObj.getMonth() + 1).toString().padStart(2, '0');
						let endDateMonth = (endDateObj.getMonth() + 1).toString().padStart(2, '0');
						let startDateDay = startDateObj.getDate().toString().padStart(2, '0');
						let endDateDay = endDateObj.getDate().toString().padStart(2, '0');
						let startDate = startDateYear + '-' + startDateMonth + '-' + startDateDay;
						let endDate = endDateYear + '-' + endDateMonth + '-' + endDateDay;
						this._store.dispatch(new GetInvoicesByDate([startDate, endDate]));
						this._invoice_service.setUILoadingStatus(true);
					} else {
						console.log('you need to add another date');
					}
				});
		}
	}
	toggleSearchButtonVisibility() {
		this.custom_daterange = false;
		const invoiceNumber = this._bf.bigForm.get('dates.invoiceNumber')?.value;
		this.showSearchButton = !!invoiceNumber; // Show button if there's input
	}
	searchInvoices() {
		this._store.dispatch(new GetInvoiceByNumber(this._bf.getControl('numbersearch.invoiceNumber').value));
	}
	ngAfterViewInit() {
		const invoicecontrol = this._bf.getControl('numbersearch');
		this.invoicenumberchangesub = invoicecontrol.valueChanges.subscribe((value: any) => {
			if (value.invoiceNumber !== '') {
				this.showSearchButton = true;
				console.log('BANG BANG');
			} else if (value.invoiceNumber === '') {
				this.showSearchButton = false;
			}
		});
		this.setupPasteListener();
	}
	setupPasteListener() {
		// const inputControl = this.invoiceNUM.inputContainer.nativeElement;
		// this._render.listen('htmlinput', 'paste', (event: ClipboardEvent) => {
		// 	this.handlePaste(event);
		// });
		console.log('setting hup paste listener');
	}
	handlePaste(event: ClipboardEvent) {
		const clipboarddata = event.clipboardData || (window as any).clipboardData;
		const parsedClipboardData = clipboarddata.getData('text/plain');

		// event.preventDefault();
		console.log('HANDLING PASTE', parsedClipboardData);
	}
}
