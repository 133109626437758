import { Observable, Subscription, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/**
 * helper function for unsubscribing subscriptions if they are not already closed
 * @param obs Subscription to be checked and cleaned
 */
export function cleanUpSub(obs: Subscription) {
	if (obs && !obs.closed) {
		obs.unsubscribe();
	}
}

/**
 * Function to add a property to an object. It always returns a new object with the new property added.
 * @param propName The name of the propery to add to the object
 * @param value The value assigned to the property in the object.
 * @param obj The object in context, to which the new property is added.
 */
export function addObjectProperty<T>(propName: string, value: any, obj: Partial<T>): Partial<T> {
	const cloned = JSON.parse(JSON.stringify(obj));
	const objWithProp = { ...cloned, [propName]: value };
	return objWithProp;
}

export const genericRetryStrategy =
	({
		maxRetryAttempts = 5,
		scalingDuration = 1000,
		excludedErrorReasons = [],
		includeErrorReasons = []
	}: {
		maxRetryAttempts?: number;
		scalingDuration?: number;
		excludedErrorReasons?: string[];
		includeErrorReasons?: string[];
	} = {}) =>
	(attempts: Observable<any>) => {
		return attempts.pipe(
			mergeMap((error, i) => {
				const retryAttempt = i + 1;
				let shouldRety = true;
				// if maximum number of retries have been met
				// or response is a status code we don't wish to retry, throw error
				if (retryAttempt > maxRetryAttempts) {
					shouldRety = false;
				}

				if (excludedErrorReasons && excludedErrorReasons.length > 0 && excludedErrorReasons.find(e => e === error.error.reason)) {
					shouldRety = false;
				}

				if (includeErrorReasons && includeErrorReasons.length > 0 && includeErrorReasons.find(e => e !== error.error.reason)) {
					shouldRety = false;
				}

				if (!shouldRety) {
					return throwError(error);
				}

				// retry after 1s, 2s, etc...
				return timer(retryAttempt * scalingDuration);
			})
		);
	};
