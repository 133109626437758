import { Flow_0_0_2 } from '@flexus/core';

export const MulSpDashboard: Flow_0_0_2 = {
    id: 'sp_dashboard',
    name: 'sp dashboard',
    startNode: 'SPDashboard',
    itemType: 'flow',
    instructions: {
        editRoles: {
            0: 'Placeholder'
        },
        viewRoles: {
            0: 'Placeholder'
        }
    },
    header: {
        title: 'SP Mini Dashboard',
        controls: () => () => []
    },
    footer: {
        type: 'node_nav'
    },
    nodes: {
        SPDashboard: {
            name: 'SP Mini Dashboard',
            component: 'SPMiniDashboardComponent'
        },
    }
}
