<flx-search-banner></flx-search-banner>
<flx-sp-ping-window></flx-sp-ping-window>
<flx-sp-job-notification-window></flx-sp-job-notification-window>

<div
	class="workflow-shell"
	[ngClass]="{
		'local-workflow-push': opened,
		'search-panel-padding': hasSearchValues
	}"
>
	<flx-alert *ngIf="workflowItemsLength === 0" type="info" message="No claims found"></flx-alert>
	<ng-container> <flx-dynamic-loader dynamicLoaderHost></flx-dynamic-loader> </ng-container>
</div>
