import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { mul } from '../mul.manifest';
import { environment } from 'apps/studio/src/environments/environment';
import { LoadManifest, GetLoggedInUser, GetAllInfo, ManifestController } from '@flexus/core';

const companies = { mul };

@Injectable({
	providedIn: 'root'
})
export class LoadMulManifestService {
	constructor(private store: Store<any>, private controller: ManifestController<any>) {}

	load() {
		this.store.dispatch(new GetLoggedInUser(`${environment.api_url}v2/auth/user/`));
		// this.store.dispatch(new GetAllInfo(`${environment.api_url}v1/all_info/`));
		this.controller.dispatch(new LoadManifest(mul));
	}
}
