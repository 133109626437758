import { Subscription } from 'rxjs';
import { Filter, FilterEvent, DefaultFilter, FillField } from '@flexus/models';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { getByIndex, returnOrDefault, checkNullUndefined } from '@flexus/utilities';

/**
 * This is for Filtering
 */
@Component({
	selector: 'flx-filter-bar',
	templateUrl: './filter-bar.component.html',
	styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit, OnDestroy {
	// -----------------------------------------Private Variables-----------------------------------------

	/**
	 * This is the subscription used for the automatic entry in the quickfilter
	 * as well as the debounce of the values in the text box creating the quick filter
	 */
	private inputSubscription: Subscription;
	private _disabled: boolean;

	/**
	 * this is used to config the filter bar, so if you only want one type of the filter it is possible
	 * for you to output it and separate it logically.
	 */
	@Input()
	mode: {
		full?: boolean;
		quickFilter?: boolean;
		valueFilter?: boolean;
		fieldFilter?: boolean;
	} = { full: true };

	/**
	 * this is used to store the filters this object has created
	 */
	filters: Filter[] = [DefaultFilter];

	// -----------------------------------------Public Variables------------------------------------------

	/**
	 * the control that is used and displayed as a bar for the quickfilter
	 */
	showFilters = false;
	quickFilter: UntypedFormControl;
	fieldFilterForm: UntypedFormGroup;

	/**
	 * the array of options for fillfield supplied to this component, it should be related to fill fields as well
	 */
	fieldOptions: string[][];

	filterSubs: Subscription[] = [];

	/**
	 * this is the subject used for the automatic conversion of data into filters as well as the debounce of the values
	 * in the text box creating the quick filter
	 */
	inputData$: Subject<string> = new Subject<string>();

	// -----------------------------------------Outputs---------------------------------------------------

	@Output() filterEvents: EventEmitter<FilterEvent>;

	// -----------------------------------------Intputs---------------------------------------------------
	@Input()
	set disabled(val: boolean) {
		this._disabled = val;
		if (val === true) {
			// disabled true
			if (this.fieldFilterForm) {
				this.fieldFilterForm.disable();
				this.quickFilter.disable();
			}
		} else {
			// disabled false
			if (this.fieldFilterForm) {
				this.fieldFilterForm.enable();
				this.quickFilter.enable();
			}
		}
	}

	get disabled() {
		return returnOrDefault(this._disabled, false);
	}

	@Input() defaultfilterText: string;

	/**
	 * this is used to specify the fields this component must populate autofills for
	 */
	@Input() fields: FillField[] = [];

	/**
	 * @param sample this should be the array that will be filtered by this component
	 */
	@Input('unfilteredArray')
	set unfilteredArray(sample: any[]) {
		if (this.fields.length > 0 && this.fieldFilterForm) {
			this.fieldOptions = [];
			this.filterSubs.forEach(sub => sub.unsubscribe());
			this.filterSubs = [];
			this.fields.forEach(filterField => {
				const arrT: string[] = [];
				sample.forEach(s => {
					//compose array of option
					if (!arrT.includes(getByIndex(s, filterField.path))) {
						arrT.push(getByIndex(s, filterField.path));
					}
				});

				//push option array in over arching array
				this.fieldOptions.push(arrT);
				// adding in a control
				this.fieldFilterForm.addControl(filterField.displayName, this._fb.control(''));
				this.filterSubs.push(
					this.fieldFilterForm.controls[filterField.displayName]?.valueChanges
						.pipe(debounceTime(200), distinctUntilChanged())
						.subscribe(value => this.processValueFilter(value, filterField))
				);
				// this.fieldFilterForm.controls[
				//   filterField.displayName
				// ].valueChanges.subscribe(x => console.log(this.fieldFilterForm));
			});
			if (this.disabled === true) {
				this.fieldFilterForm.disable();
			} else {
				this.fieldFilterForm.enable();
			}
		} else {
		}
	}

	// -------------------------------------------- Static Methods -----------------------------------------

	// --------------------------------------------Constructor ------------------------------------------

	constructor(private _fb: UntypedFormBuilder) {
		this.filterEvents = new EventEmitter<FilterEvent>();
	}

	// --------------------------------------------Life Cycle Hooks -------------------------------------
	ngOnInit() {
		// Set default text for quickFilter
		this.quickFilter = this._fb.control(this.defaultfilterText);
		if (this.defaultfilterText) {
			// trigger filtering with default value
			this.processQuickFilter(this.quickFilter.value);
		}

		this.fieldFilterForm = this._fb.group({});
		if (this.disabled === true) {
			this.fieldFilterForm.disable();
			this.quickFilter.disable();
		} else {
			this.fieldFilterForm.enable();
			this.quickFilter.enable();
		}
		this.inputSubscription = this.quickFilter?.valueChanges?.pipe(debounceTime(200), distinctUntilChanged()).subscribe(value => {
			this.processQuickFilter(value);
		});
	}

	ngOnDestroy() {
		this.inputData$.complete();
		this.inputSubscription.unsubscribe();
	}

	// --------------------------------------------internal methods -------------------------------------
	/**
	 * This method is used to handle the process around creating and clearing a quick filter
	 * @param e the event passed to the method, should be used to extract the data that has been entered into the form
	 */
	processQuickFilter(value: string) {
		if (this.disabled === true) {
			return;
		}

		if (value.length > 0) {
			// create a custom quick filter
			const regVal = RegExp(`(${value?.replace(/\s+?/, '|')})`, 'ig');
			const x = (obj: object) => {
				return Object.keys(obj).reduce((exi, key) => {
					if (!Array.isArray(obj[key]) && obj[key] !== null) {
						if (typeof obj[key] === 'object') return exi || Object.keys(obj[key]).reduce((ex, subKey) => ex || regVal.test(JSON.stringify(obj[key][subKey])), false);
						else {
							return exi || regVal.test(JSON.stringify(obj[key]));
						}
					} else {
						return exi;
					}
				}, false);
			};

			this.filters[0] = {
				description: `Quick Filter, used to search through all fields for ${value}`,
				filterValue: regVal,
				field: 'quickFilter',
				filt: x
			};

			this.filterEvents.emit({
				filterText: value,
				type: 'quickFilter',
				filterList: this.filters,
				filter: { ...this.filters[0] }
			});
		} else {
			this.filters[0] = DefaultFilter;
			this.filterEvents.emit({
				type: 'quickFilterCleared',
				filterList: this.filters,
				filter: DefaultFilter
			});
		}
	}

	/**
	 * this is a factory function
	 * @param matchValue A regular expression against which the value existing at path will be matched against
	 * @param path the path to the value one wishes to test
	 */
	createFilter(matchValue: RegExp, path: string) {
		/**
		 * returns a function that will match a value to a regexp t/f
		 */
		return obj => {
			const val = getByIndex(obj, path);
			const res = matchValue.test(val + '');
			// if (res) {
			//   console.log({
			//     object: obj,
			//     value: val,
			//     regex: matchValue,
			//     res
			//   });
			// }
			return res;
		};
	}

	/**
	 *
	 * @param value the value to be deconstructed into a regexp
	 * @param fillField The details around the processing and the path to the method
	 */
	processValueFilter(value: string, fillField: FillField) {
		// console.log(value);
		// console.log('test ', value);
		// check if value is empty or filled
		if (checkNullUndefined(value) || value === '') {
			// If value isnt filled
			// clear out this particular filter
			this.removeFilter(this.fields.findIndex(val => val.displayName === fillField.displayName));
		} else {
			// if value is filled
			// check if the filter exists
			const filterIdx = this.filters.findIndex(filter => filter.field === fillField.displayName);
			const filt = this.createFilter(this.createRegex(value), fillField.path);
			if (filterIdx === -1) {
				// console.log(`filter doesn't exist adding it`);
				this.addFilter(fillField.displayName, filt, `Custom Value Filter on the ${fillField.displayName} at the path : ${fillField.path}`, this.createRegex(value));
			} else {
				// console.log('filter exists so updating');
				this.updateFilter(fillField.displayName, filt, this.createRegex(value), filterIdx);
			}
		}
	}

	resetFormField(form: UntypedFormGroup, fieldIndex: string[] | string) {
		if (this.disabled === true) {
			return;
		}
		form.get(fieldIndex)?.setValue('');
	}

	/**
	 * A factory function to create a regexp from a string input that will replace blanks
	 * with regexp or's and do matching
	 * @param sIn a string to be searched for
	 */
	createRegex(sIn: string): RegExp {
		return new RegExp(`^(${sIn?.replace(/ +\b/g, '|')}$)`?.trim());
	}

	updateFilter(filterDisplayName: string, newFilter: (obj) => boolean, filterValue, idx: number = null) {
		//  if index was passed then no need to search for it
		const filterLocation = idx ? idx : this.filters.findIndex(filter => filterDisplayName === filterDisplayName);

		// now do a sanity check
		if (filterLocation === -1 || filterLocation === null) {
		} else {
			this.filters[filterLocation].filt = newFilter;

			// and finally emit the results

			this.filterEvents.emit({
				filterList: this.filters,
				type: 'filterUpdated',
				filter: this.filters[filterLocation]
			});
		}
	}

	addFilter(filterDisplayName: string, filt: (obj) => boolean, description = '', filterValue = /./) {
		// use supplied values to create a filter function
		const filter = {
			field: filterDisplayName,
			description,
			filt,
			filterValue
		};
		this.filters.push(filter);

		this.filterEvents.emit({
			filterList: this.filters,
			type: 'filterAdded',
			filter
		});
	}

	removeFilter(field: number) {
		// find supplied filter
		const f = this.fields[field];
		const arrNewFilters = [];

		const filterTobeRemoved = this.filters[this.filters.findIndex(val => val.field === f.displayName)];
		// produce new filter set
		this.filters.forEach(filt => {
			if (filt.field !== f.displayName) {
				arrNewFilters.push(filt);
			}
		});
		//overwrite origional array

		this.filters = arrNewFilters;
		this.filterEvents.emit({
			filterList: this.filters,
			filter: filterTobeRemoved,
			type: 'filterRemoved'
		});
	}

	resetFilter() {
		if (this.disabled === true) {
			return;
		}
		this.quickFilter?.setValue('');
	}

	resetFilters() {
		this.resetFilter();

		this.filters = [DefaultFilter];

		this.filterEvents.emit({
			filterList: this.filters,
			type: 'filtersReset',
			filter: null
		});
	}

	toggleFilter() {
		this.showFilters = !this.showFilters;
	}
}
