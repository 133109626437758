import { Flow_0_0_2, getFullItemTwo, getAllInfo } from '@flexus/core';
import { take, map, filter, pluck } from 'rxjs/operators';
import { Validators, UntypedFormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { photoNode } from '../reusable/PHOTO_NODE';
import { findName } from '@flexus/utilities';
import { setActionPanelItems } from '../../../../app-shell-features';

export const MUL_180: Flow_0_0_2 = {
	id: '180',
	name: 'request_for_regional_qa',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Request for Regional QA',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Request for Regional QA'
		},
		viewRoles: {
			0: 'Request for Regional QA'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		selectOptions: {
			errorMessage: 'No authorizers could be found!',
			directCall: (http, store, sq, bf) => {
				return http.get(`${environment.api_url}v1/claim_action/get_claim_handler/`, { params: { roles: '21' } }).pipe(
					pluck('payload'),
					filter(x => !!x),
					map(val => ({ assign: val })),
					take(1)
				);
			}
		}
	},
	startNode: 'jobInformation',
	nodes: {
		jobInformation: {
			showTabs: true,
			...jobInfoNode(180, 181)
		},
		photos: {
			...photoNode(),
			navs: [
				{
					text: 'Continue',
					nextNode: 'inFiledTechSummary'
				}
			]
		},
		inFiledTechSummary: {
			hideTabItem: true,
			component: {
				children: [
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'list', heading: 'Please select an in-field technician for the job' }
					}
				]
			},
			serverCalls: {
				list: {
					errorMessage: 'Cannot get in-field technician summary',
					directCall: (http, store, sq, bf, controller) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								filter(x => !!x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								filter(x => !!x),
								take(1)
							)
						]).pipe(
							take(1),
							map(([info, allInfo]) => {
								const { appointment, claim, team_leader } = info;
								const list: any = [];
								const date = appointment[0]?.range_start?.split('T');
								const skill = findName(info.skill, allInfo['skills']).name;
								list.push({
									job_summary: {
										installation_type: claim?.type,
										skill,
										address: claim?.address,
										appointment_date: date[0],
										appointment_time: date[1]
									}
								});
								list.push({
									team_leader_details: {
										contact_person: team_leader.full_name,
										contact_number: team_leader.contact_number,
										notes: ''
									}
								});
								return list;
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'assignRegionalQa',
					color: 'primary'
				}
			]
		},
		assignRegionalQa: {
			name: 'Assign Regional QA',
			component: 'FLXFlatDynamicFormComponent',
			hideTabItem: true,

			inputs: {
				heading: 'Select an In-Field Technician',
				subheading: 'Scroll and select a name',
				canFilter: true,
				formControls: {
					0: {
						label: 'Select an in-field technician',
						inputType: 'select',
						maxWidth: '500px',
						selectConfig: {
							displayOptions: { displayKey: 'full_name', valueKey: 'id' },
							itemsOption: 'assign',
							searchEnabled: false,
							placeHolder: 'Select in field technician'
						},
						formControlName: 'assign_assessor_id'
					}
				}
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('assign_assessor_id', new UntypedFormControl(null, [Validators.required]));
			},
			checkValidityForFields: ['assign_assessor_id'],
			navs: [
				{
					text: 'Move to in-field technician',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						appointInfieldTech: {
							serviceVariable: 'mulService',
							functionName: 'assignAssessor',
							errorMessage: 'An error occurred while assigning an in-field technician',
							followUpSuccessCalls: {
								updateJob: {
									serviceVariable: 'mulService',
									functionName: 'updateJobNewState',
									errorMessage: 'An error occurred while updating the job state'
								}
							}
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemTwo.id': 'job_id',
		assign_assessor_id: [
			(val, storeObj, formValue) => {
				return formValue.assign_assessor_id ? parseInt(formValue.assign_assessor_id, 10) : null;
			},
			'assign_assessor_id'
		],
		'itemTwo.state': 'current_state'
	}
};
