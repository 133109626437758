import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, forkJoin } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { pluck, take } from 'rxjs/operators';
import { BigFormService, getManifestSearchData, getActiveOrganization, ManifestController } from '@flexus/core';
import { SetFilterDataField, SearchOnServer, ResetServerSearch, getFilterData } from '@flexus/core';

@Component({
	selector: 'flx-act-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
	// ======================== private vars ========================
	private _searchFormSubscription: Subscription;
	private _configSubscription: Subscription;
	private _searchStoreSubscription: Subscription;
	private _activeOrg;
	private _configuration = {} as any;
	public minLength;

	// ======================== constructor ========================
	constructor(private store: Store<any>, private controller: ManifestController<any>, public bf: BigFormService) {}

	// ======================== life-cycle functions ========================
	ngOnInit(): void {
		this.bf.addControl('search', new UntypedFormControl({ checkClosed: false, search: '' }));

		this.controller
			.select(getActiveOrganization)
			.pipe(take(1))
			.subscribe(org => (this._activeOrg = org));

		this._configSubscription = forkJoin([
			this.controller.select(getManifestSearchData)?.pipe(take(1)),
			this.store.pipe(take(1)),
			this.controller.stateObservable.pipe(take(1))
		]).subscribe(([searchFunc, store, controller]: any) => {
			this._configuration = searchFunc(store, controller);
			this.minLength = this._configuration.options?.minLength;
		});

		this._searchStoreSubscription = this.store
			.select(getFilterData)
			.pipe(pluck('searchField'))
			.subscribe((searchField: any) => {
				console.log('YEP', searchField);
				if (searchField !== undefined && searchField.inSearch !== undefined && searchField.checkClosed !== undefined) {
					this.bf.bigForm.patchValue({ search: searchField });
				}
			});
	}

	ngOnDestroy(): void {
		if (this._configSubscription) this._configSubscription.unsubscribe();
		if (this._searchFormSubscription) this._searchFormSubscription.unsubscribe();
		if (this._searchStoreSubscription) this._searchStoreSubscription.unsubscribe();
	}

	// ======================== public functions ========================
	doSearch() {
		if (this.minLength ? this.bf.bigForm?.value?.search?.inSearch?.length > this.minLength - 1 : this.bf.bigForm?.value?.search?.inSearch?.length > 3) {
			const { inSearch, checkClosed } = this.bf.bigForm?.value?.search;
			this.store.dispatch(new SetFilterDataField({ key: 'searchField', data: { inSearch, checkClosed } }));
			this.store.dispatch(
				new SearchOnServer({
					endpoint: this._configuration.searchEndpoint,
					active: !checkClosed,
					search: inSearch,
					virtualStatesFunction: this._activeOrg.virtualStatesFunction
				})
			);
		}
	}

	clearSearch() {
		this.store.dispatch(new SetFilterDataField({ key: 'searchField', data: {} }));
		this.bf.bigForm.get('search')?.setValue({ checkClosed: false, inSearch: '' });
		this.store.dispatch(new ResetServerSearch());
	}
}
