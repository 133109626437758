import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { SlideNavigationOrganism } from './components/slide-navigation/slide-navigation.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { SortBarComponent } from './components/sort-bar/sort-bar.component';
import { FilterPipe } from './pipes/filter.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { FilterStoreModule } from './store';
import { FLXFormsModule, FLXButtonsModule, FLXIconModule, FLXHeadingsModule, FLXSelectListModule, FLXDatepickerModule, FLXGlowLineModule } from '@flexus/ui-elements';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		InlineSVGModule,
		ReactiveFormsModule,
		FLXFormsModule,
		FLXButtonsModule,
		FLXIconModule,
		FLXHeadingsModule,
		FilterStoreModule,
		FLXSelectListModule,
		FLXDatepickerModule,
		FLXGlowLineModule
	],
	providers: [FilterPipe],
	declarations: [FilterBarComponent, SlideNavigationOrganism, SortBarComponent, FilterPipe, SortPipe],
	exports: [FilterBarComponent, SortBarComponent, FilterPipe, SlideNavigationOrganism, SortPipe]
})
export class FLXDynamicFilterModule {}
