import { Flow_0_0_2 } from '@flexus/core';

export const BUI_67: Flow_0_0_2 = {
	id: '67',
	name: 'no_work_warranty',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'No work - Warranty'
		},
		viewRoles: {
			0: 'No work - Warranty'
		}
	},
	startNode: '',
	nodes: {}
};
