import {
	Component,
	OnInit,
	Input,
	ViewChild,
	OnDestroy,
	AfterViewInit,
	Renderer2,
	ElementRef,
	SimpleChanges,
	OnChanges,
	ChangeDetectionStrategy,
	NgZone,
	HostListener
} from '@angular/core';
import { dropLast } from 'ramda';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { skipWhile, take, map, filter } from 'rxjs/operators';
import { concat, forkJoin, Observable, Subscription } from 'rxjs';
import { getOrgKey, SelectItemTwo, GetFullItemTwo, SetActiveManifestItem, ManifestController, getCurrentUser, StoreQuery, getFullItemTwo, Converters } from '@flexus/core';
import { FLXContextMenuComponent, FLXIndicatorComponent } from '@flexus/ui-elements';
import { environment } from 'apps/studio/src/environments/environment';
import * as stringify from 'json-stringify-safe';
import cryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelper = new JwtHelperService();
@Component({
	selector: 'flx-sp-list-job-card',
	templateUrl: './sp-list-job-card.component.html',
	styleUrls: ['./sp-list-job-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpListJobCardComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
	@Input() itemTwo: any;
	@Input() itemOne: any;
	@Input() itemTwoContextMenuList = [];
	@Input() user: any;
	@Input() itemTwoPermissions = [];
	@Input() activeOrg;
	@Input() allInfo: any;
	@Input() isOnline: boolean;

	sub: Subscription[] = [];
	usersub!: Subscription;
	user$!: Observable<any>;
	claimtype$: Observable<any>;
	claimtypesub!: Subscription;
	userclient!: string;
	itemPrefix!: string;
	claimtype!: string;
	commandArgs = {};

	@ViewChild(FLXIndicatorComponent, { static: true }) indicator!: FLXIndicatorComponent;
	@ViewChild(FLXContextMenuComponent) contextMenu!: FLXContextMenuComponent;
	client: any;

	constructor(
		private _store: Store<any>,
		private controller: ManifestController<any>,
		private router: Router,
		private route: ActivatedRoute,
		private renderer: Renderer2,
		private el: ElementRef,
		private ngZone: NgZone,
		private _storQuery: StoreQuery,
		private http: HttpClient
	) {
		this.client = environment.client;

		switch (this.client) {
			case 'pgg_sp':
				this.itemPrefix = 'Installation';
				break;
			case 'mul_sp':
				this.itemPrefix = 'Installation';
				break;
			default:
				this.itemPrefix = 'MID';
		}
	}

	ngOnInit() {
		this.client = environment.client;
		const claimtypesarray: any[] = this.allInfo?.claim_types;
		const claimtype = this.itemTwo?.claim_type_id;
		let claimtypeObj: any;
		claimtypesarray?.forEach(el => {
			claimtypeObj = claimtypesarray.find(element => element.id === claimtype);
			this.claimtype = claimtypeObj?.name;
			if (this.claimtype == 'Pricelock Installer Own Stock') {
				this.claimtype = 'Installer Own Stock';
			} else {
				this.claimtype = this.claimtype;
			}
		});
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes['itemTwoPermissions']) {
			this.sub.forEach(s => {
				if (s) s.unsubscribe();
			});
			if (this.itemTwo && this.user && this.activeOrg && this.allInfo) {
				this.itemTwoPermissions.forEach(fn => {
					const res = fn(this.itemTwo, null, this.user, this.renderer, this.el.nativeElement, this.activeOrg, this.allInfo, this);
					if (res) {
						this.sub.push(res);
					}
				});
			}
		}
	}

	ngAfterViewInit() {}

	@HostListener('mouseenter')
	onMouseOver() {
		const state = this.itemTwo.state;
		const editRoles = this.user && this.user.user && this.user.user.edit_states;

		const claimCardElement = this.el.nativeElement.querySelector('.job');
		if (editRoles && editRoles.includes(state)) {
			this.renderer.setStyle(claimCardElement, 'cursor', 'pointer');
		} else {
			this.renderer.setStyle(claimCardElement, 'cursor', 'default');
		}
	}

	takeAction(itemTwo) {
		// if (this.user.user.edit_states.includes(itemTwo.state)) {
		if (this.isOnline) {
			this.router.navigate(['/workflow/detail']);
			// this.selectedItemTwo = itemTwo;

			this.controller
				.select(getOrgKey)
				.pipe(take(1))
				.subscribe(key => {
					this.controller.dispatch(
						new SetActiveManifestItem({
							pathToFlows: ['manifestItems'],
							orgKey: key,
							itemId: itemTwo?.state,
							// itemOne: this.itemOne,
							itemTwo: this.itemTwo
						})
					);
				});
		}
		// }
	}

	selectMenuItem(itemTwo, menuItem) {
		this.router.navigate(['/workflow/detail']);
		// this._store.dispatch(new GetFullItemTwo({ id: itemTwo?.id }));
		this._store.dispatch(new SelectItemTwo({ itemOne: this.itemOne, itemTwo: this.itemTwo }));
		this.controller
			.select(getOrgKey)
			.pipe(take(1))
			.subscribe(key => {
				this.controller.dispatch(
					new SetActiveManifestItem({
						pathToFlows: ['manifestItems', 'workflow', 'contextMenu', 'itemTwo'],
						orgKey: key,
						itemId: menuItem.key,
						itemOne: this.itemOne,
						itemTwo: this.itemTwo
					})
				);
			});
		if (this.contextMenu) {
			this.contextMenu.close();
		}
	}

	ngOnDestroy() {
		this.sub.forEach((s: Subscription) => s.unsubscribe());
		if (this.usersub) {
			this.usersub.unsubscribe();
		}
	}

	async encryptData(obj: any) {
		const objectString = JSON.stringify(obj);
		const objectBytes = Converters.stringToByteArray(objectString);
		const iv = new Uint8Array(16);
		const key = await window.crypto.subtle.importKey('raw', new Uint8Array(16), { name: 'AES-CBC', length: 256 }, false, ['encrypt']);
		const buf = await window.crypto.subtle.encrypt({ name: 'AES-CBC', iv }, key, objectBytes);
		const result = Converters.byteArrayToBase64(buf);
		return result;
	}

	encrypt(obj: any) {
		const str = JSON.stringify(obj);
		return cryptoJS.AES.encrypt(str, 'shenanigans').toString();
	}

	// decrypt(ciphertext: string) {
	// 	var bytes = cryptoJS.AES.decrypt(ciphertext, 'shenanigans');
	// 	var originalText = bytes.toString(cryptoJS.enc.Utf8);
	// 	return originalText;
	// }

	getExcessHow(num: string | number) {
		const mode = {
			1: 'Cash',
			2: 'EFT',
			3: 'Off Invoice',
			4: 'Access Bond',
			5: 'Credit Card',
			6: 'Debit Card',
			7: 'Transaction Account',
			8: 'Bond Account'
		};
		return num ? mode[num] : '';
	}

	transformClaimDescription = job => {
		const desc: string = job?.claim?.loan_information?.claimdescription || '';
		if (desc?.endsWith('.')) {
			return (dropLast(1, desc.split('')) as string[]).join('').replace(new RegExp('\n', 'g'), '<br />');
		} else {
			return desc.replace(new RegExp('\n', 'g'), '<br />');
		}
	};

	extractDateTime = datetimeString => {
		const dateTime = new Date(datetimeString);
		const year = dateTime.getFullYear();
		const month = String(dateTime.getMonth() + 1).padStart(2, '0');
		const day = String(dateTime.getDate()).padStart(2, '0');
		const date = `${year}-${month}-${day}`;
		const time = dateTime.toLocaleTimeString([], { hour12: false });
		return { date, time };
	};

	appointmentTypes = {
		1: 'A.S.A.P',
		2: 'Any Time',
		3: 'Between',
		4: 'After',
		5: 'Before',
		6: 'At'
	};

	// MENUITEM COMMAND FUNCTIONS //
	getPdfJobDetails = (job: any) => {
		const getCellNumber = (str: string): string | undefined => (str && str !== '' ? str : undefined);

		let appointmentDate = '',
			appointmentTime = '';
		if (job?.office_use?.requestedappointmentdate || job?.office_use?.requestedappointmenttime) {
			appointmentDate = job?.office_use?.requestedappointmentdate;
			appointmentTime = `${job?.office_use?.appointment_type} ${job?.office_use?.requestedappointmenttime}`;
		} else if (job?.appointment && job?.appointment[0]) {
			const latestAppointment = job.appointment[0];
			const datetime = this.extractDateTime(latestAppointment.range_start);
			appointmentDate = datetime.date;
			appointmentTime = `${this.appointmentTypes[latestAppointment.appointment_type]} ${datetime.time}`;
		}
		return {
			//voucher info
			voucher_type: job?.claim?.loan_information?.voucher_type,
			voucher_address: job?.claim?.address,
			voucher_phone: job?.claim?.applicant?.contact_number,
			special_instructions: job?.claim?.loan_information?.whatmatters,
			claim_id: job?.claim?.id,

			claim_type: job?.claim?.type,
			handler: job?.handler_details?.full_name ?? job?.claim?.loan_information?.handler,
			policy_number: job?.claim?.loan_information?.policy_number,
			handlercontact: job?.handler_details?.contact_number ?? job?.claim?.loan_information?.handlercontact,
			mid: job?.claim?.mid,
			id: job?.id,
			handleremail: job?.handler_details?.email_address ?? job.claim.loan_information.handleremail,
			what_matters: job?.claim?.loan_information?.whatmatters,
			details: this.transformClaimDescription(job),
			appointment: {
				date: appointmentDate,
				time: appointmentTime,
				onsiteperson: job?.claim?.loan_information?.onsiteperson,
				contact_number: job?.claim?.loan_information?.onsitecontact,
				onsitenotes: job?.claim.loan_information.onsitenotes
			},
			insured_address: {
				street: job?.claim?.loan_information?.propertystreetaddress,
				suburb: job?.claim?.loan_information?.propertysuburb,
				city: job?.claim?.loan_information?.propertycity,
				province: job?.claim?.loan_information?.claimprovince,
				gps_coordinates: transformCoordinates(job?.claim?.location)
			},
			property_details: job?.claim.loan_information.suminsured,
			insured_details: {
				name: `${job?.claim?.applicant?.first_name} ${job?.claim?.applicant?.surname}`,
				cellnumber:
					getCellNumber(job?.claim?.applicant?.contact_number) ??
					getCellNumber(job?.claim?.loan_information?.contactnumber) ??
					getCellNumber(job?.claim?.loan_information?.cellnumber) ??
					getCellNumber(job?.claim?.loan_information?.onsitecontact) ??
					'N/A'
			},
			excess_details: {
				standard_excess: job?.excess[0]?.amount,
				excess_how: this.getExcessHow(job?.excess[0]?.how_collect)
			}
		};
	};

	viewPDF = () => {
		const decodedToken = jwtHelper.decodeToken(localStorage.getItem('flexus.web.jwtToken'));
		this.http
			.post(`${environment.api_url}v1/job_action/get_job`, { job_id: this.itemTwo.id })
			.pipe(map((res: any) => res.payload))
			.subscribe(job => {
				const encryptedData = this.encrypt({ client: decodedToken.client, job: this.getPdfJobDetails(job) });
				console.log({ encryptedData });
				window.open(`${environment.pdf_url}templates/${environment?.pdf_key || 'job-card'}/${encodeURIComponent(encryptedData)}`, '_blank');
				// location.href = `${environment.pdf_url}templates/job-card/${encodeURIComponent(encryptedData)}?redirect_uri=${window.location.href}`;
			});
	};
}

function transformCoordinates(location: string) {
	const [x, y] = location.split(',').map(pt => parseFloat(pt));
	return `${x} , ${y}`;
}
