<flx-heading *ngIf="docTitle" [title]="docTitle"></flx-heading>
<flx-billing-header [docType]="docType"></flx-billing-header>

<flx-invoice-number-date-edit [docType]="docType" [numberLabel]="numberLabel" [currentState]="currentState"></flx-invoice-number-date-edit>

<flx-line-items
	[clientName]="clientName"
	[boqLogo]="boqLogo"
	[boqLogoAlt]="boqLogoAlt"
	[saveDraft]="saveDraft"
	[actualLineItemsFormArray]="actualLineItemsFormArray"
	[showLineItemGenerator]="showLineItemGenerator"
	[canEditLineItems]="canEditLineItems"
	[currentState]="currentState"
	[rejectBoqLineItems]="rejectBoqLineItems"
></flx-line-items>

<flx-invoice-summary [editing]="true" [actualLineItemsFormArray]="actualLineItemsFormArray"></flx-invoice-summary>

<!-- callOutStates = [25, 65, 66, 67, 69, 80, 94, 105, 171, 206, 207];
	invoicingStates = [26, 46, 47]; -->
