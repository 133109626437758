import { Flow_0_0_2 } from '@flexus/core';

export const BET_31: Flow_0_0_2 = {
	id: '31',
	name: 'all_jobs_complete',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: '',
	nodes: {}
};
