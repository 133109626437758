import { Flow_0_0_2, getAllInfo, getFullItemTwo, getSubmissionData } from '@flexus/core';
import { setActionPanelItems, CollapseActionPanel } from 'apps/studio/src/app/app-shell-features';
import { forkJoin, of } from 'rxjs';
import { skipWhile, map, take, filter, switchMap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { addObjectProperty } from '@flexus/utilities';
import moment from 'moment';

export const MUL_280: Flow_0_0_2 = {
	id: '280',
	name: 'stock-purchase_book-appointment',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any, bf: any) => {
			return store.select(getFullItemTwo)?.pipe(
				map((itemTwo: any) => {
					if (itemTwo?.claim) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Stock Purchased - Book Appointment : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Stock Purchased - Book Appointment';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'mulSpService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		files: {
			serviceVariable: 'mulSpService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'NoStockNotification',
	nodes: {
		NoStockNotification: {
			initFormFields: (bf: any) => {
				bf.addControl('new_state', new UntypedFormControl('279', [Validators.required]));
			},
			serverCalls: {
				installationKeyValues: {
					errorMessage: 'Could not return installation info',
					directCall: (http: any, store: any) => {
						return forkJoin([
							store.select(getFullItemTwo)?.pipe(
								skipWhile((itt: any) => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo)?.pipe(
								skipWhile((ai: any) => !ai),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
								skipWhile((x: any) => !x),
								take(1),
								map((res: any) => res['payload'])
							)
						])?.pipe(
							map(([itemTwo, info, teamleaders]: any) => {
								const sp = itemTwo?.sp;
								const sps = info.sps;
								let companyname, teamleadname;
								for (let i = 0; i < sps.length; i++) {
									if (sp === sps[i]?.id) {
										companyname = sps[i]?.name;
									}
								}
								const tleads = teamleaders as any;
								const templead = itemTwo?.team_leader;
								if (templead) {
									for (let i = 0; i < tleads.length; i++) {
										if (tleads[i]?.id === templead?.id) {
											teamleadname = tleads[i]?.full_name;
										}
									}
								} else {
									teamleadname = 'No team leader appointed';
								}
								const accreditation_number = itemTwo?.team_leader?.sp?.accreditation_number ?? '--';
								const qualification_number = itemTwo?.team_leader?.qualification_number ?? '--';
								const installerinfo = {
									'Company name ': companyname,
									'Accreditation number ': accreditation_number,
									'Installation technician qualification number': qualification_number,
									'Installer/Technician name ': teamleadname
								};
								return [installerinfo];
							})
						);
					}
				},
				contactKeyValues: {
					errorMessage: "Couldn't return customer contact details",
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								filter((x: any) => !!x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map((job: any) => {
									const { claim, identities } = job;
									const contactperson = claim?.applicant;
									const loan_information = claim?.loan_information;
									const installationaddress: string = job?.address ?? 'No address returned';
									const emailaddress: string = loan_information?.email ?? 'No email address returned';
									const gpscoordinates: string = claim?.location ?? 'No location returned';
									const firstname: string = contactperson?.first_name;
									const surname: string = contactperson?.surname;
									const fullname = `${firstname} ${surname}` ?? 'no name returned';
									const contactnumber: string = loan_information?.contactnumnber ?? 'No contact number returned';
									const contactdetails = {
										'Full Name': `${fullname}`,
										'Installation Adress': `${installationaddress}`,
										'Email address': `${emailaddress}`,
										'Contact Number': `${contactnumber}`,
										'GPS Coordinates': `${gpscoordinates}`
									};

									return [contactdetails];
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							instructions: ['The Team Leader/Collector has indicated that they have successfully acquired stock.', 'Please contact the customer and schedule a new appointment.'],
							title: 'Stock Purchased - Book Appointment',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Details',
							data$: 'installationKeyValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Contact Details',
							data$: 'contactKeyValues',
							itemMargin: '0 0 15px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'set appointment',
					color: 'primary',
					nextNode: 'SetAppointment'
				}
			]
		},
		SetAppointment: {
			hideTabItem: true,
			inputs: {
				minDate: new Date()
			},
			checkValidityForFields: ['appointmentData'],
			initFormFields: bf => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentDateType: new UntypedFormControl(null, [Validators.required]),
						skill: new UntypedFormControl('Pinggo Installation')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(fulljob => {
									const { claim } = fulljob;
									const { loan_information } = claim;
									const claimtype = claim?.type;
									const name = `${claim?.applicant?.first_name} ` + `${claim?.applicant?.surname}`;
									let number;
									let mobile;
									if (claimtype !== 'Pricelock Installation') {
										number = loan_information?.contactnumber;
										mobile = loan_information?.cellnumber;
									} else {
										number = loan_information?.ContactNumber;
										mobile = loan_information?.cellnumber;
									}

									return [{ 'Client name': name }, { 'Contact number': number }, { 'Mobile number': mobile }];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(fulljob => {
									const { claim } = fulljob;
									const { loan_information } = claim;
									const claimtype = claim?.type;
									let onsitenumber;
									if (claimtype !== 'Pricelock Installation') {
										onsitenumber = loan_information?.onsitecontact;
									} else {
										onsitenumber = loan_information?.onsitecontact_c;
									}
									return [{ 'On-site contact name': loan_information?.onsiteperson }, { 'On-site contact number': onsitenumber }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					optIntoValidation: true,
					text: 'Set Appointment',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo)?.pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData)?.pipe(
										skipWhile(x => !x),
										take(1)
									),
									of(bf.bigForm.get('return_tl_reason').value)
								])?.pipe(
									map(([job, submit, returnreason]) => {
										const { job_information, id } = job;
										const jobinfo = JSON.parse(JSON.stringify(job_information));
										const current_state = job?.state;
										const new_state = submit.new_state;
										const appointment = submit.appointment;
										const return_tl_reason = returnreason;
										const jobinformation = addObjectProperty('return_tl_reason', return_tl_reason, jobinfo);
										return {
											job_id: id,
											current_state: current_state,
											new_state: new_state,
											appointment: appointment,
											job_information: jobinformation
										};
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			],

			component: 'FLXJobAppointmentComponent'
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					const appointment_formatted = {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
					return appointment_formatted;
				}
			},
			'appointment'
		]
	}
};
