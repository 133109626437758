import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MultiFileLoadingHandlerService } from '../multi-file-loading-handler.service';
import { BigFormService } from '@flexus/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'flx-multi-file-upload-viewer',
	templateUrl: 'multi-file-upload-viewer.component.html',
	styleUrls: ['multi-file-upload-viewer.component.scss']
})
export class FLXMultiFileUploadViewerComponent implements OnInit, AfterViewInit {
	// variables
	purposeOptions = [
		{ display: 'Bracket QR code', value: 'Bracket QR code', disabled: false },
		{ display: 'Dish QR code', value: 'Dish QR code', disabled: true },
		{ display: 'LNB QR code', value: 'LNB QR code', disabled: false },
		{ display: 'Switch QR code', value: 'Switch QR code', disabled: false },
		{ display: 'Internal cable route', value: 'Internal cable route', disabled: false },
		{ display: 'External cable route', value: 'External cable route', disabled: false },
		{ display: 'Coaxial cabling', value: 'Coaxial cabling', disabled: false },
		{ display: 'Decoder serial number', value: 'Decoder serial number', disabled: false },
		{ display: 'Decoder installation', value: 'Decoder installation', disabled: false },
		{ display: 'Information Central', value: 'Information Central', disabled: false }
	];

	displayConfig = { displayKey: 'display', valueKey: 'value' };

	@Input() images$: Observable<any>;
	images;

	constructor(
		private _data: MultiFileLoadingHandlerService,
		public _bf: BigFormService,
		private _cd: ChangeDetectorRef,
		public _sanitiser: DomSanitizer,
		private _fB: FormBuilder
	) {}

	ngOnInit() {
		this._bf.addControl('imageAndPurposeCard', new UntypedFormGroup({}));
		this._data.currentImages.subscribe((images: any) => {
			this.images$ = images;
			this.images = images;
			return images;
		});
	}

	ngAfterViewInit() {
		this._cd.detectChanges();
	}

}
