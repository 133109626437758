import { BuiService } from './bui/services/bui.service';
import { BuiManifestModule } from './bui/bui-manifest.module';
import { SilManifestModule } from './sil/sil-manifest.module';
import { SilService } from './sil/services';
import { MulService } from './multichoice/services';
import { BuildersStockManagerManifestModule } from './builders-stock-manager/builders-stock-manager-manifest.module';
import { BuildersStockManagerService } from './builders-stock-manager/services';
import { MulManifestModule } from './multichoice/mul-manifest.module';
import { MulSpManifestModule } from './mul-sp/mul-sp-manifest.module';
import { SilSpManifestModule } from './sil-sp/sil-sp-manifest.module';
import { AmpManifestModule } from './amp/amp-manifest.module';
import { AmpService } from './amp/services';
import { MulSpService } from './mul-sp/services';
import { BetManifestModule } from './bettersure-hoc/bet-manifest.module';
import { BetService } from './bettersure-hoc/services';
import { BetSpManifestModule } from './bet-sp/bet-sp-manifest.module';
import { PinggoSpManifestModule } from './pinggo-sp/pgg-sp-manifest.module';
import { PggManifestModule } from './pinggo/pgg-manifest.module';
import { PggService } from './pinggo/services';
import { SpService } from '../sp_globals/services';

export const registeredClients = {
	pgg: PggManifestModule,
	bui: BuiManifestModule,
	sil: SilManifestModule,
	mul: MulManifestModule,
	mul_sp: MulSpManifestModule,
	sil_sp: SilSpManifestModule,
	amp: AmpManifestModule,
	bet: BetManifestModule,
	bet_sp: BetSpManifestModule,
	pgg_sp: PinggoSpManifestModule,
	builders_stock_manager: BuildersStockManagerManifestModule
};

export const registeredServices = {
	pgg: PggService,
	bui: BuiService,
	sil: SilService,
	mul: MulService,
	mul_sp: MulSpService,
	sil_sp: SpService,
	amp: AmpService,
	bet: BetService,
	bet_sp: SpService,
	pgg_sp: SpService,
	builders_stock_manager: BuildersStockManagerService
};
