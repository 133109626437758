import { InjectionToken } from '@angular/core';

export type DateFormats = {
	parse: {
		dateInput: any;
	};
	display: {
		dateInput: any;
		monthYearLabel: any;
		dateA11yLabel: any;
		monthYearA11yLabel: any;
	};
};

export const DATE_FORMATS = new InjectionToken<DateFormats>('flx-date-formats');
