import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, forkJoin } from 'rxjs';
import { getCurrentUser, BigFormService, getFullItemOne } from '@flexus/core';
import { skipWhile, take, map } from 'rxjs/operators';

@Component({
	selector: 'flx-ap-reopen-claim',
	templateUrl: './reopen-claim.component.html',
	styleUrls: ['./reopen-claim.component.scss']
})
export class FLXReopenClaimComponent implements OnInit, OnDestroy {
	isTeamLead$: Observable<boolean>;
	public reopen_data;

	@Input() reopenReasons$: Observable<any>;

	canReopen$: Observable<any> = null;

	constructor(private _store: Store<any>, private bf: BigFormService) {}

	ngOnInit() {
		this.canReopen$ = forkJoin([
			this._store.select(getCurrentUser).pipe(
				skipWhile(res => !res),
				take(1)
			),
			this._store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1)
			)
		]).pipe(
			map(([user, claim]: any) => {
				console.log({ user });
				let result = true;
				let message = 'success';
				if (claim?.claim_type === 28) {
					console.log('Home Assist Claim');
					result = false;
					message = 'Cannot re-open a Home Assist claim.';
				} else if (!user?.user?.is_team_leader) {
					result = false;
					message = 'Only Team Leaders can reopen a claim. Please speak to your Team Leader to get this claim reopened.';
				}
				return { result, message };
			})
		);

		this.reopen_data = this.bf.bigForm.get('reopen_data');
		// this._store
		//   .select(getCurrentUser)
		//   .pipe(
		//     skipWhile(res => !res),
		//     take(1)
		//   )
		//   .subscribe((res: any) => {
		//     this.isTeamLead$ = of(res?.user.is_team_leader);
		//   });
	}

	ngOnDestroy(): void {}
}
