<div class="indicator" [ngClass]="arrClasses" #indicator>
	<svg class="indicator-svg" viewBox="0 0 6 36">
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g class="indicator-svg" fill="" stroke="">
				<path
					d="M0.5,5.23418747 L0.5,30.7949873 L5.5,34.9365877 L5.5,1.06752081 L0.5,5.23418747 Z"
					id="Rectangle"
					transform="translate(3.000000, 18.000000) scale(-1, 1) translate(-3.000000, -18.000000) "
				></path>
			</g>
		</g>
	</svg>
</div>
