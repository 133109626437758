import { forkJoin } from 'rxjs';
import { getAllInfo, getFullItemOne, getSelectedItemTwo } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { findName } from '@flexus/utilities';

export const DATA_CALLS = {
	excess_on_job: {
		errorMessage: 'Could not retrieve excess',
		directCall: (http, store) => {
			return forkJoin([
				store.select(getFullItemOne).pipe(
					skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
					take(1)
				),
				store.select(getSelectedItemTwo).pipe(
					skipWhile((itemTwo: any) => itemTwo === null || itemTwo === undefined),
					take(1)
				),
				store.select(getAllInfo).pipe(take(1))
			]).pipe(
				map(([fullItemOne, selectedItemTwo, allInfo]: any) => {
					let excesses = {};

					const jobs = fullItemOne.jobs;
					const currentJobId = selectedItemTwo.id;

					const { excess_who, excess_how, skills } = allInfo;

					const composeExcess = excess => {
						if (excess === undefined) {
							return [];
						}

						if (Array.isArray(excess)) {
							excess = excess.map(e => {
								return {
									amount: e.amount,
									how_collect: findName(e.how_collect, excess_how).name,
									who_collects: findName(e.who_collects, excess_who).name,
									reason: e.reason
								};
							});
						}
						return excess;
					};

					const formatJob = job => {
						let { skill, excess } = job;

						skill = findName(skill, skills).name;
						excess = composeExcess(excess);

						return {
							skill,
							excess
						};
					};

					if (Array.isArray(jobs) && jobs.length > 0) {
						let currentJob = jobs.filter(job => job?.id === currentJobId);
						let otherJobs = jobs.filter(job => job?.id !== currentJobId);

						currentJob = currentJob.map(x => {
							return formatJob(x);
						});

						otherJobs = otherJobs.map(j => {
							return formatJob(j);
						});

						excesses = { currentJob, otherJobs };
					}
					return excesses;
				})
			);
		}
	},
	excess_on_claim: {
		errorMessage: 'Could not retrieve excess',
		directCall: (http, store) => {
			return forkJoin([
				store.select(getFullItemOne).pipe(
					skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
					take(1)
				),
				store.select(getAllInfo).pipe(take(1))
			]).pipe(
				map(([fullItemOne, allInfo]: any) => {
					let excesses = {};

					let jobs = fullItemOne.jobs;

					const { excess_who, excess_how, skills } = allInfo;

					const composeExcess = excess => {
						if (excess === undefined) {
							return [];
						}

						if (Array.isArray(excess)) {
							excess = excess.map(e => {
								return {
									amount: e.amount,
									how_collect: findName(e.how_collect, excess_how).name,
									who_collects: findName(e.who_collects, excess_who).name,
									reason: e.reason
								};
							});
						}
						return excess;
					};

					const formatJob = job => {
						let { skill, excess } = job;

						skill = findName(skill, skills).name;
						excess = composeExcess(excess);

						return {
							skill,
							excess
						};
					};

					if (Array.isArray(jobs) && jobs.length > 0) {
						jobs = jobs.map(x => {
							return formatJob(x);
						});

						excesses = { jobs };
					}
					return excesses;
				})
			);
		}
	},
	getAllJobFiles: {
		serviceVariable: 'betService',
		functionName: 'getJobAllFiles',
		responseSlice: 'payload',
		errorMessage: 'Could not get files from server!'
	},
	getAllClaimFiles: {
		serviceVariable: 'betService',
		functionName: 'getAllClaimFiles',
		responseSlice: 'payload',
		errorMessage: 'Could not get files from server!'
	},
	logs: {
		serviceVariable: 'betService',
		functionName: 'getCallLogs',
		errorMessage: 'No call logs could be found!'
	}
};

export const CONTEXT_DATA_CALLS = {
	excess_on_job: {
		...DATA_CALLS.excess_on_job
	},
	files: {
		...DATA_CALLS.getAllClaimFiles
	}
};
