<flx-panel maxWidth="40vw" margin="auto">
	<flx-heading size="medium" weight="extra-light" [align]="headingOptions.alignment" margin="1rem 0 0 0"> Claim Excess</flx-heading>
	<ng-container *ngIf="excess_on_job$ | async as excessDetails">
		<div>
			<ng-container *ngIf="excessDetails.currentJob?.length > 0; else noJob">
				<flx-heading size="extra-small" color="secondary">Current job</flx-heading>
				<ng-container *ngFor="let cj of excessDetails.currentJob">
					<div class="excess-container">
						<flx-heading size="small" weight="bold" margin="0 0 0.5rem 0">{{ cj.skill }}</flx-heading>
						<div class="table" *ngIf="cj.excess.length > 0">
							<ng-container *ngIf="location === 'screen'">
								<div [class]="gridOptions.row">
									<div [class]="gridOptions.th"><span>Reason</span> <span>Collected by</span> <span>Collection method</span> <span>Amount</span></div>
									<div [class]="gridOptions.tr" *ngFor="let e of cj.excess">
										<span>{{ e.reason || '-' }}</span> <span>{{ e.who_collects || '-' }}</span> <span>{{ e.how_collect || '-' }}</span> <span>{{ e.amount || '-' }}</span>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="location === 'action-panel'">
								<div *ngFor="let e of cj.excess">
									<div [class]="gridOptions.row">
										<div [class]="gridOptions.th"><span>Reason</span> <span>Collected by</span> <span>Collection method</span> <span>Amount</span></div>
										<div [class]="gridOptions.tr">
											<span>{{ e.reason || '-' }}</span> <span>{{ e.who_collects || '-' }}</span> <span>{{ e.how_collect || '-' }}</span> <span>{{ e.amount || '-' }}</span>
										</div>
									</div>
									<flx-glow-line margin="0.5rem 0" size="100%" *ngIf="cj.excess.length > 1"></flx-glow-line>
								</div>
							</ng-container>
						</div>
						<div *ngIf="cj.excess.length < 1" class="no-excess">No Excess</div>
					</div>
				</ng-container>
			</ng-container>
			<ng-template #noJob> <flx-alert type="no-info" missingItem="job"></flx-alert> </ng-template>
			<ng-container *ngIf="excessDetails.otherJobs?.length > 0">
				<flx-heading size="extra-small" color="secondary">Other jobs on this claim</flx-heading>
				<ng-container *ngFor="let oj of excessDetails.otherJobs">
					<div class="excess-container">
						<flx-heading size="small" weight="bold" margin="0 0 0.5rem 0">{{ oj.skill }}</flx-heading>
						<div class="table" *ngIf="oj.excess.length > 0">
							<ng-container *ngIf="location === 'screen'">
								<div [class]="gridOptions.row">
									<div [class]="gridOptions.th"><span>Reason</span> <span>Collected by</span> <span>Collection method</span> <span>Amount</span></div>
									<div [class]="gridOptions.tr" *ngFor="let e of oj.excess">
										<span>{{ e.reason || '-' }}</span> <span>{{ e.who_collects || '-' }}</span> <span>{{ e.how_collect || '-' }}</span> <span>{{ e.amount || '-' }}</span>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="location === 'action-panel'">
								<div *ngFor="let e of oj.excess">
									<div [class]="gridOptions.row">
										<div [class]="gridOptions.th"><span>Reason</span> <span>Collected by</span> <span>Collection method</span> <span>Amount</span></div>
										<div [class]="gridOptions.tr">
											<span>{{ e.reason || '-' }}</span> <span>{{ e.who_collects || '-' }}</span> <span>{{ e.how_collect || '-' }}</span> <span>{{ e.amount || '-' }}</span>
										</div>
									</div>
									<flx-glow-line *ngIf="oj.excess.length > 1"></flx-glow-line>
								</div>
							</ng-container>
						</div>
						<div *ngIf="oj.excess.length < 1" class="no-excess"><flx-alert type="no-info" missingItem="excess"></flx-alert></div>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</ng-container>
</flx-panel>
