import { BigFormService, Flow_0_0_2, getFullItemOne, getFullItemTwo, getSelectedItem, getSelectedItemTwo, ManifestController, ModalService, SetNextNode } from '@flexus/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, EMPTY, of } from 'rxjs';
import { take, map, skipWhile, filter, first, mergeMap, switchMap } from 'rxjs/operators';
import gql from 'graphql-tag';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';

import { findName } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';

export const MUL_20: Flow_0_0_2 = {
	id: '20',
	name: 'manual_allocation',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Manually Allocate SP : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Manually Allocate SP';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Allocate an SP to the job'
		},
		viewRoles: {
			0: 'Awaiting SP allocation'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		notes: {
			serviceVariable: 'mulService',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No claim notes could be found!'
		},
		customer_details: {
			errorMessage: 'No customer details were found!',
			directCall: (_http: HttpClient, _store: Store, sq: any) => {
				return _store
					.select(getFullItemTwo)
					.pipe(
						skipWhile((x: any) => !x),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map((fulljob: any) => {
							const { claim } = fulljob;
							const { loan_information } = claim;
							const claimtype: number = claim?.claim_type;
							const name = `${claim?.applicant?.first_name} ` + `${claim?.applicant?.surname}`;
							let number;
							let mobile;
							if (claimtype !== 26) {
								number = loan_information?.contactnumber;
								mobile = loan_information?.cellnumber;
							} else {
								number = loan_information?.ContactNumber;
								mobile = loan_information?.cellnumber;
							}

							return [{ 'Client name': name }, { 'Contact number': number }, { 'Mobile number': mobile }];
						})
					);
			}
		},
		onsite_details: {
			errorMessage: 'Onsite details not found!',
			directCall: (_http: HttpClient, _store: Store, sq: any) => {
				return _store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(fulljob => {
							const { claim } = fulljob;
							const { loan_information } = claim;
							const claimtype: number = +claim?.claim_type;
							let onsitenumber;
							if (claimtype === 26) {
								onsitenumber = loan_information?.onsitecontact_c;
							} else if (claimtype === 37) {
								onsitenumber = loan_information?.onsitecontact_c;
							} else {
								onsitenumber = loan_information?.onsitecontact;
							}
							return [{ 'On-site contact name': loan_information?.onsiteperson }, { 'On-site contact number': onsitenumber }];
						})
					);
			}
		},
		appointmentTypes: {
			serviceVariable: 'service',
			functionName: 'getAppointmentTypes',
			errorMessage: 'No Appointment Types could be found!'
		}
	},
	startNode: 'SpReplyList',
	nodes: {
		SpReplyList: {
			component: 'FLXSpReplyListComponent',
			inputs: {
				assignSpStateList: [20],
				selectSPNavigateToNode: 'AllocateSPSummary'
			},
			serverCalls: {
				interestedParties: {
					serviceVariable: 'mulService',
					functionName: 'getInterestedParties',
					responseSlice: 'payload',
					errorMessage: 'An error occurred getting the interested parties'
				},
				replyListData: {
					errorMessage: 'An error occurred getting the SP Data',
					serviceVariable: 'mulService',
					functionName: 'getSPReplyListData'
				}
			},
			navs: [
				{
					text: 'Reping SP Request',
					color: 'primary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'warning';
							instance.setMessage([
								'You are about to reping all the service providers that are able to do this job',
								'',
								'Please ensure you understand the implications of this decision'
							]);
							instance.navButtons = [
								{ text: 'Take me back', color: 'alert', linkType: 'close' },
								{
									text: 'I understand',
									color: 'alert',
									linkType: 'nextNode',
									nextNode: 'SetAppointment'
								}
							];
						}
					}
				}
			]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									skill
								}
							}
							allInfo {
								skills
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res || !res?.skill || !res?.skills),
						take(1)
					)
					.subscribe(values => {
						const skillName = findName(values.skill, values.skills).name;
						bf.addControl(
							'appointmentData',
							new UntypedFormGroup({
								appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
								appointmentTime: new UntypedFormControl(null, [Validators.required]),
								appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
								appointmentdatetype: new UntypedFormControl(null),
								skill: new UntypedFormControl(skillName)
								// skillcatagory: new FormControl(values.skillcatagory)
							})
						);
					});
			},
			component: 'FLXJobAppointmentComponent',
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'EndSummary',
					serverFirst: true,
					serverCalls: {
						repingSP: {
							errorMessage: 'An error occurred while repinging the Service Provider',
							serviceVariable: 'mulService',
							functionName: 'repingSP'
						}
					}
				}
			]
		},
		AppointmentDecision: {
			hideTabItem: true,
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => EMPTY
			},
			decisions: {
				checkAppointment: (_navs: any, _store: Store, _modal: ModalService, _ctrl: ManifestController<any>) => {
					return _store
						.select(getSelectedItem)
						.pipe(
							skipWhile((response: any) => !response),
							take(1),
							map((res: any) => {
								const now = moment();
								const STRING_APPOINTMENT = res?.appointment?.range_start;
								const appointmentType = res?.appointment?.appointment_type;
								const appointmentHour = new Date(STRING_APPOINTMENT).getUTCHours() + 2;
								const appointmentMinutes = new Date(STRING_APPOINTMENT).getUTCMinutes();
								let modifiedMinutes;
								if (appointmentMinutes < 10) {
									modifiedMinutes = `0${appointmentMinutes}`;
								} else {
									modifiedMinutes = appointmentMinutes;
								}
								const appointment = moment(STRING_APPOINTMENT);
								const hourTimeDifference = appointment.diff(now, 'hours');
								const minuteTimeDifference = appointment.diff(now, 'minutes');
								switch (true) {
									case hourTimeDifference < 0:
										_modal.openModalDirectly((_inst: any, _store: Store, _bf: BigFormService) => {
											_inst.type = 'warning';
											_inst.setMessage([`The appointment time has passed.`, 'Please proceed to set appointment.', '']);
											_inst.navButtons = [
												{
													text: 'back',
													clickHandler: () => {
														// _inst.router.navigate(['/workflow']);
														_inst.controller.dispatch(new SetNextNode('AllocateSPSummary'));
													},
													linkType: 'close',
													color: 'alert'
												},
												{
													text: 'proceed',
													clickHandler: () => {
														_inst.controller.dispatch(new SetNextNode('SetAppointmentForManual'));
													},
													linkType: 'close',
													color: 'alert'
												}
											];
										});
										break;
									case hourTimeDifference == 0:
										if (minuteTimeDifference <= 60 && appointmentType !== 4) {
											_modal.openModalDirectly((_inst: any, _store: Store, _bf: BigFormService) => {
												const sp = _bf.bigForm.get('sp_selected_item')?.value;
												const spContact = sp?.contact_primary;
												_inst.type = 'warning';
												_inst.setMessage([
													`The appointment time is at <strong>${appointmentHour}:${modifiedMinutes}</strong>`,
													'Pleas confirm with the SP that they can meet the appointment',
													'',
													`<strong>SP Contact:</strong> ${spContact} `,
													'',
													''
												]);
												_inst.navButtons = [
													{
														text: 'use existing appointment',
														clickHandler: () => {
															_bf.bigForm.patchValue({ sp_selected_item: sp });
															_inst.controller.dispatch(new SetNextNode('EndSummary'));
														},
														linkType: 'close',
														color: 'alert'
													},
													{
														text: 'set new appointment',
														clickHandler: () => {
															_inst.controller.dispatch(new SetNextNode('SetAppointmentForManual'));
														},
														linkType: 'close',
														color: 'alert'
													}
												];
											});
										} else {
											_ctrl.dispatch(new SetNextNode('EndSummary'));
										}
										break;
									default:
										_ctrl.dispatch(new SetNextNode('EndSummary'));
								}
							})
						)
						.subscribe();
				}
			},
			navs: []
		},
		SetAppointmentForManual: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									skill
								}
							}
							allInfo {
								skills
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res || !res?.skill || !res?.skills),
						take(1)
					)
					.subscribe(values => {
						const skillName = findName(values.skill, values.skills).name;
						bf.addControl(
							'appointmentData',
							new UntypedFormGroup({
								appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
								appointmentTime: new UntypedFormControl(null, [Validators.required]),
								appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
								appointmentdatetype: new UntypedFormControl(null),
								skill: new UntypedFormControl(skillName)
								// skillcatagory: new FormControl(values.skillcatagory)
							})
						);
					});
			},
			component: 'FLXJobAppointmentComponent',
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'EndSummary',
					serverFirst: true,
					serverCalls: {
						new_appointment: {
							errorMessage: 'Could not create new appointment',
							directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService) => {
								return sq
									.queryObject(
										gql`
											{
												selectedItemTwo {
													id
												}
											}
										`,
										_store.select(getSelectedItemTwo).pipe(
											skipWhile((sit: any) => !sit),
											take(1),
											map((result: any) => ({ selectedItemTwo: result }))
										)
									)
									.pipe(
										map((queryData: any) => {
											const job_id: number = queryData?.id;
											const appointment = _bf.bigForm.get('appointmentData')?.value;
											const date = moment(appointment.appointmentDatePicker);
											date.hour(appointment.appointmentTimePicker.hour);
											date.minutes(appointment.appointmentTimePicker.minutes);
											const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
											const reason: string = 'Set new appointment for missed in 20';
											const appointment_formatted = {
												job_id: job_id,
												appointment: {
													range_start: date_formatted,
													reason: reason,
													appointment_type: appointment.appointmentTime
												}
											};
											return appointment_formatted;
										}),
										switchMap((appointment_request: any) => {
											return _http.post(`${environment.api_url}v1/job_action/new_appointment/`, appointment_request);
										})
									);
							}
							// followUpSuccessCalls: {
							// 	appointSP: {
							// 		directCall: (_http: HttpClient, _store: Store, _sq: any, _bf) => {
							// 			return forkJoin([
							// 				_sq.queryObject(
							// 					gql`
							// 						{
							// 							selectedItemTwo {
							// 								id
							// 							}
							// 						}
							// 					`,
							// 					_store.select(getSelectedItemTwo).pipe(
							// 						skipWhile((sit: any) => !sit),
							// 						take(1),
							// 						map((result: any) => ({ selectedItemTwo: result }))
							// 					)
							// 				),
							// 				of(_bf.bigForm.get('sp_selected_item')?.value)
							// 			]).pipe(
							// 				switchMap(([queryData, selectedsp]: any) => {
							// 					console.log('QUERY DATA', queryData, selectedsp);
							// 					const sp = selectedsp as any;
							// 					const job_id = queryData?.id;
							// 					const sp_id = sp?.id;
							// 					return _http.post(`${environment.api_url}v1/job_action/appoint_sp/`, { job_id: job_id, sp_id: sp_id });
							// 				})
							// 			);
							// 		},
							// 		errorMessage: 'Could not appoint SP'
							// 	}
							// }
						}
					}
				}
			]
		},
		AllocateSPSummary: {
			component: 'FLXKeyValueDisplayWithInstructionsComponent',
			inputs: {
				instructions: ['Please double check the details of the selected service provider'],
				title: 'Details for '
			},
			serverCalls: {
				title: {
					errorMessage: 'Could not get ',
					directCall: (_http: any, _store: any, sq: any, _bf: BigFormService) => {
						const selected_sp = _bf.bigForm.get('sp_selected_item')?.value;
						return of(selected_sp?.name);
					}
					// serviceVariable: 'service',
					// functionName: 'getJobSP',
					// responseSlice: 'sp.name',
					// errorMessage: ''
				},
				keyValueList: {
					serviceVariable: 'service',
					functionName: 'getAppointSPSummary',
					errorMessage: 'data for keyvaluelist not returned',
					directCall: (http, store, sq, bf) => {
						return store.select(getSelectedItemTwo).pipe(
							skipWhile(x => !x ?? !!x),
							take(1),
							mergeMap(selectedItem => {
								const job = selectedItem as any;
								return forkJoin([
									http.post(`${environment.api_url}v1/job_action/get_interested_parties/`, { job_id: job?.id }).pipe(
										skipWhile(x => !x ?? !!x),
										map(res => res as any)
									),
									of(bf.bigForm.get('sp_selected_item')?.value)
								]).pipe(
									map(([interested, bigformdata]) => {
										const interestedParties = interested?.payload;
										const selectedSP = bigformdata as any;
										const interestedParty = { ...interestedParties.find(ip => ip.sp.id === selectedSP?.id) };
										let interest;
										switch (interestedParty?.new_interested) {
											case -1: {
												interest = 'Rejected';
												break;
											}
											case 0: {
												interest = 'Ignored';
												break;
											}
											case 1: {
												interest = 'Accepted';
												break;
											}
											case null: {
												interest = 'No-Reply';
												break;
											}
											default:
												break;
										}
										return [
											{ 'Primary Contact': selectedSP.contact_person },
											{ 'Primary Contact Number': selectedSP.contact_primary },
											{ maven_id: selectedSP.mid !== null ? selectedSP.mid : 'Not On Maven' },
											{
												'response to job': interest
											},
											{ 'Currently Awarded Jobs': interestedParty?.awarded_today }
										];
									})
								);
							})
						);
					}
				}
			},
			navs: [
				{
					disableOnFirstClick: true,
					text: 'Continue',
					color: 'primary',
					nextNode: 'AppointmentDecision',
					serverFirst: true,
					serverCalls: {
						appointSP: {
							errorMessage: 'An error occurred while manually allocating a service provider',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x ?? !!x),
										take(1)
									),
									of(bf.bigForm.get('sp_selected_item')?.value)
								]).pipe(
									switchMap(([selectedjob, selectedsp]) => {
										const job = selectedjob as any;
										const sp = selectedsp as any;
										const job_id = selectedjob?.id;
										const sp_id = selectedsp?.id;
										return http.post(`${environment.api_url}v1/job_action/appoint_sp/`, { job_id: job_id, sp_id: sp_id });
									})
								);
							}
						}
					}
				}
			]
		},
		EndSummary: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: { 'sp_selected_item.id': 'sp' }
};
