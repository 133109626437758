import { Renderer2 } from '@angular/core';
import { Manifest_0_0_2 } from '@flexus/core';
import { ItemTwo_Permission_0_0_2 } from '@flexus/core';
import { jobStateLookupTable } from '../../models/indicator-lookup.model';

export const jobPermissions: ItemTwo_Permission_0_0_2 = {
	show_default_button: (job: any, claim: any, currentUser: any, renderer: Renderer2, domElement: HTMLElement, activeOrg: Manifest_0_0_2, allInfo, instance) => {
		if (job) {
			const stateFlow = activeOrg?.manifestItems[job.state];
			const instructions = stateFlow?.itemType === 'flow' && stateFlow?.instructions;
			const editStates = currentUser?.user?.edit_states;
			const userCanEdit = editStates?.includes(job.state);
			const userRole = currentUser?.user?.roles[0];

			const instructionText = userCanEdit
				? (instructions && instructions?.editRoles[userRole]) || (instructions && instructions?.editRoles[0]) || ''
				: (instructions && instructions?.viewRoles[userRole]) || (instructions && instructions?.viewRoles[0]) || '';
			const instructionHolder = domElement.getElementsByClassName('take-action').item(0);
			const span = renderer.createElement('span');
			// renderer.addClass(but, buttonHtmlClassLookup[job.state]);
			// renderer.setStyle(but, 'margin-right', '-50px');
			const spanText = renderer.createText(instructionText);
			// if (!userCanEdit) {
			//   renderer.setAttribute(span, 'hidden', 'true');
			// }
			renderer.appendChild(span, spanText);
			renderer.appendChild(instructionHolder, span);
		}
	},
	get_indicator_color: (job: any, claim: any, currentUser: any, renderer: Renderer2, domElement: HTMLElement, activeOrg: Manifest_0_0_2, allInfo, instance) => {
		const claimState = claim?.state;
		const jobState = job?.state;
		const editRoles = currentUser?.user?.edit_states;
		let color = 'grey';
		switch (true) {
			case claimState === 109 && jobState === 97:
				break;
			case editRoles?.includes(jobState):
				color = jobStateLookupTable[job.state];
				break;
		}

		instance.indicator.color = color;
	}
};
