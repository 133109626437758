import { Action } from '@ngrx/store';

export const SET_OWNJOB_CLAIMTYPE = '[SP Own Job] Set Claim Type for Now Appointment';
export const GET_OWNJOB_CLAIMTYPE = '[SP Own Job] Get Own Job Claim Type';
export const GET_OWNJOB_CLAIMTYPE_SUCCESS = '[SP Own Job] Get Own Job Claim Type Success';
export const GET_OWNJOB_CLAIMTYPE_FAIL = '[SP Own Job] Get Own Job Claim Type Fail';
export const GET_OWNJOB_STAFF_TEAM_LEADERS = '[SP Own Job] Get Team Leader Staff';
export const GET_OWNJOB_STAFF_TEAM_LEADERS_SUCCESS = '[SP Own Job] Get Team Leader Staff Success';
export const GET_OWNJOB_STAFF_TEAM_LEADERS_FAIL = '[SP Own Job] Get Team Leader Staff Fail';
export const GET_OWNJOB_TEAM_POSITIONS = '[SP Own Job] Get Team Positions';
export const GET_OWNJOB_TEAM_POSITIONS_SUCCESS = '[SP Own Job] Get Team Positions Success';
export const GET_OWNJOB_TEAM_POSITIONS_FAIL = '[SP Own Job] Get Team Positions Fail';

export class SetSPOwnJobClaimType implements Action {
	readonly type = SET_OWNJOB_CLAIMTYPE;
	constructor(public payload: any) {}
}

export class GetSPOwnJobClaimType implements Action {
	readonly type = GET_OWNJOB_CLAIMTYPE;
	constructor() {}
}

export class GetSPOwnJobClaimTypeSuccess implements Action {
	readonly type = GET_OWNJOB_CLAIMTYPE_SUCCESS;
	constructor(public payload: any) {}
}
export class GetSPOwnJobClaimTypeFail implements Action {
	readonly type = GET_OWNJOB_CLAIMTYPE_FAIL;
	constructor(public payload: any) {}
}
export class GetSPOwnJobTeamLeaderStaff implements Action {
	readonly type = GET_OWNJOB_STAFF_TEAM_LEADERS;
}
export class GetSPOwnJobTeamLeaderStaffSuccess implements Action {
	readonly type = GET_OWNJOB_STAFF_TEAM_LEADERS_SUCCESS;
	constructor(public payload: any) {}
}
export class GetSPOwnJobTeamLeaderStaffFail implements Action {
	readonly type = GET_OWNJOB_STAFF_TEAM_LEADERS_FAIL;
	constructor(public payload: any) {}
}
export class GetSPOwnJobTeamPositions implements Action {
	readonly type = GET_OWNJOB_TEAM_POSITIONS;
}
export class GetSPOwnJobTeamPositionsSuccess implements Action {
	readonly type = GET_OWNJOB_TEAM_POSITIONS_SUCCESS;
	constructor(public payload: any) {}
}
export class GetSPOwnJobTeamPositionsFail implements Action {
	readonly type = GET_OWNJOB_TEAM_POSITIONS_FAIL;
	constructor(public payload: any) {}
}
export type SPOwnJobActions =
	| SetSPOwnJobClaimType
	| GetSPOwnJobClaimType
	| GetSPOwnJobClaimTypeSuccess
	| GetSPOwnJobClaimTypeFail
	| GetSPOwnJobTeamLeaderStaff
	| GetSPOwnJobTeamLeaderStaffSuccess
	| GetSPOwnJobTeamLeaderStaffFail
	| GetSPOwnJobTeamPositions
	| GetSPOwnJobTeamPositionsSuccess
	| GetSPOwnJobTeamPositionsFail;
