import { Flow_0_0_2, getData, getFullItemTwo, getSelectedItemOne, getAllInfo, getCurrentUser } from '@flexus/core';
import { map, take, filter, skipWhile, first, pluck } from 'rxjs/operators';
import gql from 'graphql-tag';
import { SIL_27 } from './SIL_27';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { MISCELLANEOUS_CLAIMS_PAYMENT_CHECKS } from './miscellaneous_claims_payment_checks';
import { PAYMENT_CHECKS } from './payment_checks';

export const SIL_39: Flow_0_0_2 = {
	id: '39',
	name: 'job_invoiced_invalid_claim',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				filter(x => !!x),
				skipWhile((itemTwo: any) => {
					return itemTwo === null || itemTwo === undefined;
				}),
				first(itemTwo => itemTwo !== null || itemTwo !== undefined),
				map((itemTwo: any) => {
					if (itemTwo) {
						return `Job Invoiced - Invalid Claim: ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Job Invoiced - Invalid Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Approve call-out fee invoice'
		},
		viewRoles: {
			0: '--'
		}
	},

	events: {
		invalidateQueryDataIfVatRateIsSet: {
			triggerOn: 'vat_rate',
			triggerWhen: rate => !!rate,
			dataMutations: bf => {
				bf.patchValues({
					query: null,
					new_state: 50
				});
			}
		},

		invalidatePaymentDataIfQueryIsSet: {
			triggerOn: 'query',
			triggerWhen: qr => !!qr,
			dataMutations: bf => {
				bf.patchValues({
					claim_value: null,
					update_excess: null,
					invoice_number: null,
					vat_rate: null,
					nextApprovalStaffName: null,
					new_state: 47
				});
			}
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	fetchLevel1And2: true,
	serverCalls: {
		dataFiles: {
			serviceVariable: 'silService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},

		files: {
			serviceVariable: 'silService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		notes: {
			serviceVariable: 'silService',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No claim notes could be found!'
		}
	},

	startNode: 'MiscellaneousClass',

	nodes: {
		...MISCELLANEOUS_CLAIMS_PAYMENT_CHECKS?.nodes,
		...PAYMENT_CHECKS?.nodes,
		...SIL_27.nodes
	},

	bigFormToStoreMapper: {
		...SIL_27.bigFormToStoreMapper
	}
};
