import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ERIconComponent } from './icon.component';

@NgModule({
	declarations: [ERIconComponent],
	imports: [CommonModule],
	exports: [ERIconComponent]
})
export class FLXIconModule {}
