import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, ModalService } from '@flexus/core';
import { FLXModalComponent, ModalNavButton } from '@flexus/ui-elements';
import { Observable, Subscription } from 'rxjs';
import { filter, pluck, take } from 'rxjs/operators';

@Component({
	selector: 'flx-excess-management',
	templateUrl: './excess-management.component.html',
	styleUrls: ['./excess-management.component.scss']
})
export class FLXExcessManagementComponent implements OnInit, OnDestroy {
	constructor(private cdr: ChangeDetectorRef, public bf: BigFormService, private fb: UntypedFormBuilder, private modalsService: ModalService) {}

	public excessForm!: UntypedFormGroup;
	public addExcessForm!: UntypedFormGroup;

	@Input() excess_options$!: Observable<any>;
	@Input() excess_on_jobs$!: Observable<any>;
	@Input() excludeStates!: Array<any>;
	@Input() jobs_on_claim$!: Observable<any[]>;

	@ViewChild(FLXModalComponent) modal!: FLXModalComponent;

	modalButtons!: ModalNavButton[];

	paymentMethod$!: Observable<any>;
	excessType$!: Observable<any>;
	excessStatus$!: Observable<any>;
	excessFormSub!: Subscription;
	formName: number = 0;
	group: any = {};
	displayExcess: Record<string, any>[] = [];
	deleteExcess: any[] = [];

	ngOnInit() {
		this.excessOnClaim();
		this.setExcessOptions();
		this.addExcessForm = new UntypedFormGroup({
			who_collects: new UntypedFormControl(null, [Validators.required]),
			how_collect: new UntypedFormControl(null, [Validators.required]),
			amount: new UntypedFormControl('', [Validators.required]),
			job_id: new UntypedFormControl('', [Validators.required])
		});
	}

	ngOnDestroy() {
		if (!!this.excessFormSub) this.excessFormSub.unsubscribe();
	}

	excessOnClaim() {
		this.excess_on_jobs$
			.pipe(
				filter(x => !!x),
				take(1)
			)
			.subscribe(value => {
				value.forEach((job: Record<string, any>) => {
					let newJob: Record<string, any> = JSON.parse(JSON.stringify(job));
					let disabled = this.excludeStates.length ? this.excludeStates.some(x => x === newJob?.state?.id) : false;

					if (newJob.excess.length > 0) {
						newJob.excess = newJob.excess.map((excess: any) => {
							this.formName += 1;
							excess = { ...excess, who_collects: excess?.who_collects?.id !== '' ? excess.who_collects : undefined };
							this.setForm(excess, disabled);
							return { ...excess, controls: { ...this.nameControler() }, disabled };
						});
					} else {
						this.formName += 1;
						const excess = {
							skill_id: null,
							excess_amount: null,
							excess_description: null,
							who_collects_excess: null,
							who_collects_excess_desc: null,
							excess_payment_method: null,
							excess_payment_method_desc: null,
							controls: { ...this.nameControler() },
							disabled
						};
						this.setForm(excess, disabled);
						newJob.excess = [excess];
					}
					this.displayExcess.push(newJob);
				});

				this.excessForm = new UntypedFormGroup(this.group);
				this.setFormUpdates();
				this.bf.bigForm.addControl('excessForm', this.excessForm);
				this.cdr.detectChanges();
				this.submissionData();
			});
	}

	setExcessOptions() {
		this.paymentMethod$ = this.excess_options$.pipe(
			filter((x: any) => !!x),
			pluck('excess_how'),
			take(1)
		);

		this.excessType$ = this.excess_options$.pipe(
			filter((x: any) => !!x),
			pluck('excess_who'),
			take(1)
		);
	}

	setForm(job: any, disabled: boolean) {
		this.group[`${this.formName}-type`] = new UntypedFormControl({ value: job?.who_collects?.id ? job?.who_collects?.id : job?.who_collects, disabled }, [Validators.required]);
		this.group[`${this.formName}-amount`] = new UntypedFormControl({ value: job?.amount ?? '', disabled }, [Validators.required]);
		this.group[`${this.formName}-method`] = new UntypedFormControl({ value: job?.how_collect?.id ? job?.how_collect?.id : job?.how_collect, disabled }, [Validators.required]);
	}

	setFormUpdates() {
		this.excessFormSub = this.excessForm.valueChanges.subscribe(x => {
			this.submissionData();
		});
	}

	nameControler(): object {
		return { ftype: `${this.formName}-type`, famount: `${this.formName}-amount`, fmethod: `${this.formName}-method` };
	}

	addExcess() {
		this.modalButtons = [
			{
				text: 'Cancel',
				linkType: 'close'
			},
			{
				text: 'Add Excess',
				clickHandler: () => {
					if (this.addExcessForm.valid) {
						this.displayExcess.forEach(jobs => {
							if (jobs.id === this.addExcessForm.value.job_id) {
								this.formName += 1;
								this.setForm(this.addExcessForm.value, true);
								jobs.excess.push({ ...this.addExcessForm.value, new: true, controls: { ...this.nameControler() } });
							}
						});
						this.excessForm = new UntypedFormGroup(this.group);
						this.bf.addControl('excessForm', this.excessForm);
						this.submissionData();
						this.modal.close();
					}
				}
			}
		];
		this.modal.open();
	}

	removeExcess(excess: Record<string, any>) {
		if (!excess?.disabled) {
			if (excess?.excessId) this.deleteExcess.push(excess?.excessId);
			this.displayExcess.forEach(jobs => {
				jobs.excess = jobs.excess.filter((x: Record<string, any>) => x?.controls?.ftype !== excess?.controls?.ftype);
			});
		}

		this.submissionData();
	}

	drop(event: any) {
		if (event?.item?.data?.excessId) {
			this.deleteExcess.push(event?.item?.data?.excessId);
		}
		event.item.data['new'] = true;
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
		}
		this.submissionData();
	}

	submissionData() {
		let subData: Record<string, any> = {};
		let dataArr: Record<string, any>[] = [];
		const order = this.excessForm.controls;
		this.displayExcess.forEach(job => {
			job?.excess.forEach((excess: Record<string, any>) => {
				const fId = excess?.controls?.ftype.charAt(0);
				let jobExcessObj: Record<string, any> = {};
				jobExcessObj['job_id'] = job?.id;
				jobExcessObj['reason'] = excess?.reason;
				jobExcessObj['new'] = excess?.new ? excess?.new : false;
				jobExcessObj['excess_id'] = excess?.excessId;

				Object.entries(order).forEach(([key, value]) => {
					if (key.charAt(0) === fId) {
						jobExcessObj[key] = value.value;
					}
				});
				dataArr.push(jobExcessObj);
			});
		});
		subData['data'] = dataArr;
		this.bf.patchValues({ subData, removeExcess: this.deleteExcess });
	}
}
