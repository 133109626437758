import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'flx-sp-with-distances',
	templateUrl: 'sp-with-distance-list.component.html',
	styleUrls: ['sp-with-distance-list.component.scss']
})
export class FLXSpWithDistancesComponent implements AfterViewInit {
	@Input() suitableSPs$: Observable<any>;
	constructor(private _change_detect: ChangeDetectorRef) {}
	ngAfterViewInit() {
		this._change_detect.detectChanges();
	}
}
