import { Component, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { AccordionGroupComponent } from './accordion-template-group.component';

@Component({
	selector: 'flx-accordion-template',
	template: ` <ng-content></ng-content> `,
	styleUrls: ['./accordion-template.component.scss']
})
export class AccordionTemplateComponent implements AfterContentInit {
	@ContentChildren(AccordionGroupComponent)
	groups: QueryList<AccordionGroupComponent>;

	/**
	 * Invoked when all children (groups) are ready
	 */
	ngAfterContentInit() {
		// Set active to first element
		setTimeout(() => {
			if (this.groups.toArray().length > 0) {
				this.groups.toArray()[0].opened = true;
			}
			// Loop through all Groups
			this.groups.toArray().forEach(group => {
				// when title bar is clicked
				// (toggle is an @output event of Group)
				group.toggle.subscribe(groupShow => {
					// Open the group
					this.toggleGroup(group, groupShow);
				});
				/*t.toggle.subscribe((group) => {
        // Open the group
        this.openGroup(group);
      });*/
			});
		}, 0);
	}

	/**
	 * Open an accordion group
	 * @param group   Group instance
	 */
	toggleGroup(group, show) {
		// close other groups
		this.groups.toArray().forEach(t => (t.opened = false));
		// open current group
		group.opened = show;
	}
}
