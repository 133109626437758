import { Inject, Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { switchMap, map, catchError, filter, withLatestFrom, skipWhile, take } from 'rxjs/operators';
import { forkJoin, from, of } from 'rxjs';

import * as JobPingsActions from './sp-job-pings.actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { getCheckAvailablityDetails, getFullAvailableJobsList, getJobResponses, getAvailableJobs } from './sp-job-pings.selectors';
import { SPJobPingsService } from './sp-job-pings.service';
import { JOB_INTEREST } from '@flexus/utilities';
import { AllInfoService } from '../services/all-info.service';
import { IndexedDbService } from '../services/storage-services';
import { ENVIRONMENT } from '../services/engine.constants';
import { getCurrentUser } from '../identity/identity.selector';

@Injectable()
export class SPJobPingsEffects {
	constructor(
		private store$: Store<any>,
		private actions$: Actions,
		private _http: HttpClient,
		private availableJobService: AllInfoService,
		private pingService: SPJobPingsService,
		private indexedDBService: IndexedDbService,
		@Inject(ENVIRONMENT) private environment: any
	) {}

	// Check Availablility ////////////////////////////////////////////////////////////////////////////////////////////

	checkAvailablity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.CHECK_AVAILABILITY),
			switchMap((action: JobPingsActions.CheckAvailability) => this.store$.select(getCurrentUser)?.pipe(skipWhile(user => !user))),
			switchMap((currentUser: any) => {
				const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

				return this._http.post(
					`${this.environment.api_url}v1/job_action/check_available/`,
					JSON.stringify({
						areas: currentUser?.user?.sp?.regions
							?.toString()
							.split(',')
							.map(x => +x),
						skills: currentUser?.user?.sp?.skills
							?.toString()
							.split(',')
							.map(x => +x),
						sp_id: currentUser?.user?.sp?.id
					}),
					{ headers, responseType: 'text' }
				);
			}),
			map((data: any) => new JobPingsActions.CheckAvailabilitySuccess(data)),
			catchError((error: any) => {
				return of(new JobPingsActions.CheckAvailabilityFail(error));
			})
		)
	);

	checkAvailablitySuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.CHECK_AVAILABILITY_SUCCESS),
			map((action: JobPingsActions.CheckAvailabilitySuccess) => action.payload),
			withLatestFrom(this.store$.select(getCheckAvailablityDetails)),
			map(([payload, AvailablityDetails]) => {
				let force_update;
				const checkArray = payload.split('|');
				const serverJobCount = checkArray[1];
				const jobsMeetingCriteria = checkArray[0];
				let timestampCheck = null;
				if (checkArray.length > 2) {
					timestampCheck = checkArray[2];
				}

				if (parseInt(checkArray[1], 10) > 0) {
					if (+jobsMeetingCriteria < 1) {
						force_update = false;
					}

					if (AvailablityDetails.serverJobCount === serverJobCount) {
						if (AvailablityDetails.timestampCheck !== timestampCheck) {
							force_update = true;
						}

						if (AvailablityDetails.jobsMeetingCriteria !== jobsMeetingCriteria) {
							force_update = true;
						}
					} else {
						force_update = true;
					}
				}

				if (force_update) {
					this.store$.dispatch(new JobPingsActions.GetAvailableJobs());
				}

				return new JobPingsActions.CheckAvailabilityStoreResults({
					serverJobCount: serverJobCount,
					jobsMeetingCriteria: jobsMeetingCriteria,
					timestampCheck: timestampCheck
				});
			}),
			catchError((error: any) => {
				return of(new JobPingsActions.CheckAvailabilityFail(error));
			})
		)
	);

	// Get Filtered Jobs ////////////////////////////////////////////////////////////////////////////////////////////

	GetFilteredJobs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.GET_FILTERED_JOBS),
			switchMap(() => {
				return this.store$.select(getFullAvailableJobsList)?.pipe(
					skipWhile(res => !res),
					withLatestFrom(this.indexedDBService.job_responses.toArray())
				);
			}),
			map(([available_jobs, job_responses]) => {
				const filteredJobs = available_jobs.filter((job: any) => {
					const jobFound = job_responses.find((response: any) => response.id === job?.id);
					return !jobFound;
				});

				return new JobPingsActions.GetFilteredJobsSuccess({
					availableJobPings: available_jobs,
					filteredJobs: filteredJobs
				});
			}),
			catchError((error: any) => of(new JobPingsActions.GetFilteredJobsFail(error)))
		)
	);

	// Get Available Jobs ////////////////////////////////////////////////////////////////////////////////////////////

	getAvailableJobs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.GET_AVAILABLE_JOBS),
			switchMap(() => this.store$.select(getCurrentUser)?.pipe(skipWhile(user => !user))),
			switchMap((currentUser: any) => {
				return this.availableJobService
					.getInfo(`${this.environment.api_url}v1/job_action/get_available_summary/`, {
						areas: currentUser?.user?.sp?.regions?.toString(),
						skills: currentUser?.user?.sp?.skills?.toString(),
						sp_id: currentUser?.user?.sp?.id
					})
					.pipe(
						map((data: any) => {
							if (data?.payload?.job) {
								return data?.payload;
							} else {
								const job = [...data?.payload];
								const modpayload = {
									job: job
								};
								return modpayload;
							}
						}),
						withLatestFrom(
							this.store$.select(getJobResponses)?.pipe(
								skipWhile(res => !res),
								take(1)
							)
						),
						map(([available_jobs, job_responses]) => {
							return this.pingService.checkToPing(available_jobs, job_responses);
						})
					);
			}),
			map((data: any) => {
				return new JobPingsActions.GetAvailableJobsSuccess(data);
			}),
			catchError((error: any) => of(new JobPingsActions.GetAvailableJobsFail(error)))
		)
	);

	// Get Job Responses ////////////////////////////////////////////////////////////////////////////////////////////

	getJobResponses$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.GET_JOB_RESPONSES),
			switchMap(() => from(this.indexedDBService.job_responses.toArray())),
			map((data: any) => {
				return new JobPingsActions.GetJobResponsesSuccess(data);
			}),
			catchError((error: any) => of(new JobPingsActions.GetJobResponsesFail(error)))
		)
	);

	getJobResponsesSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.GET_JOB_RESPONSES_SUCCESS),
			map((action: JobPingsActions.GetJobResponsesSuccess) => action.payload),
			withLatestFrom(this.store$.select(getJobResponses)),
			map(() => {
				return new JobPingsActions.GetFilteredJobs();
			})
		)
	);

	// Respond To Job ////////////////////////////////////////////////////////////////////////////////////////////

	respondToJob$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.RESPOND_TO_JOB),
			map((action: JobPingsActions.RespondToJob) => action.payload),
			withLatestFrom(this.store$.select(getCurrentUser)?.pipe(skipWhile(user => !user))),
			map(([action, currentUser]) => ({ action: action, currentUser })),
			switchMap(({ action, currentUser }) => {
				console.log('here');
				return this.availableJobService
					.getInfo(`${this.environment.api_url}v1/job_action/state_job_interest`, {
						job_id: action.job.id,
						spm: currentUser.id,
						interested: action.interested
					})
					.pipe(
						filter((x: any) => !!x),
						map((res: any) => ({ res: res?.reason, payload: action }))
					);
			}),
			map((data: any) => {
				return new JobPingsActions.RespondToJobSuccess(data?.payload);
			}),
			catchError((error: any) => of(new JobPingsActions.RespondToJobFail(error)))
		)
	);

	respondToJobSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.RESPOND_TO_JOB_SUCCESS),
			switchMap((action: JobPingsActions.RespondToJobSuccess) => {
				return this.pingService.addJobResponsesToIDB([action.payload]);
			}),
			switchMap(() => {
				return from(this.indexedDBService.job_responses.toArray())?.pipe(
					skipWhile(jr => !jr),
					take(1),
					withLatestFrom(
						this.store$.select(getFullAvailableJobsList)?.pipe(
							skipWhile(ajl => !ajl),
							take(1)
						)
					)
				);
			}),
			map(([job_responses, available_jobs]) => {
				const filteredJobs = available_jobs.filter((job: any) => {
					const jobFound = job_responses.find((response: any) => response.id === job?.id);
					return !jobFound;
				});

				return new JobPingsActions.GetFilteredJobsSuccess({
					availableJobPings: available_jobs,
					filteredJobs: filteredJobs
				});
			}),
			catchError((error: any) => of(new JobPingsActions.RespondToJobFail(error)))
		)
	);

	// Respond To Jobs ////////////////////////////////////////////////////////////////////////////////////////////

	respondToJobs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.RESPOND_TO_JOBS),
			map((action: JobPingsActions.RespondToJobs) => action.payload),
			withLatestFrom(this.store$.select(getCurrentUser)?.pipe(skipWhile((user: any) => !user))),
			map(([action, currentUser]: any) => ({ action: action, currentUser })),
			switchMap(({ action, currentUser }) => {
				return forkJoin(this.joinRespondedJobs(currentUser, action));
				// return of({});
			}),
			map((data: any) => {
				return new JobPingsActions.RespondToJobSuccess(data);
			}),
			catchError((error: any) => of(new JobPingsActions.RespondToJobsFail(error)))
		)
	);

	RemoveJobResponse$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.REMOVE_JOB_RESPONSE),
			map((action: JobPingsActions.RemoveJobResponse) => action.payload),
			switchMap((data: any) => {
				return this.pingService.removeJobResponsesFromIDB([data]);
			}),
			map((data: any) => {
				return new JobPingsActions.RemoveJobResponseSuccess(data.jobResponsesRemoved);
			}),
			catchError((error: any) => of(new JobPingsActions.RemoveJobResponseFail(error)))
		)
	);

	RemoveAllJobResponses$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.REMOVE_ALL_JOB_RESPONSES),
			switchMap((action: JobPingsActions.RemoveAllJobResponses) => {
				return this.store$.select(getJobResponses)?.pipe(
					skipWhile((res: any) => !res),
					map((res: any[]) => res?.map((resp: any) => resp.id)),
					take(1)
				);
			}),
			switchMap(jobResponses => {
				return this.pingService.removeJobResponsesFromIDB(jobResponses);
			}),
			map((data: any) => {
				return new JobPingsActions.RemoveJobResponseSuccess(data.jobResponsesRemoved);
			}),
			catchError((error: any) => of(new JobPingsActions.RemoveAllJobResponsesFail(error)))
		)
	);

	checkForAwardedJobs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.CHECK_FOR_JOBS_AWARDED),
			switchMap((action: JobPingsActions.CheckForJobsAwarded) => {
				return this.store$.select(getJobResponses)?.pipe(
					skipWhile((res: any) => !res),
					take(1),
					map((job_responses: any) => {
						return { all_awarded_job_ids: action.allawardedjobs as any[], job_responses };
					})
				);
			}),
			map(({ all_awarded_job_ids: all_job_ids, job_responses }) => {
				const job_response_ids = job_responses.map(({ id }) => Number(id));
				const awarded_job_ids = all_job_ids.filter((id: any) => job_response_ids.includes(id));
				const awarded_jobs = awarded_job_ids.length > 0 ? job_responses.filter((job: any) => awarded_job_ids.includes(Number(job.id))) : [];
				return new JobPingsActions.CheckForJobsAwardedSuccess(awarded_jobs);
			}),
			catchError((error: any) => of(new JobPingsActions.CheckForJobsAwardedFail(error)))
		)
	);

	checkForJobsLost$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobPingsActions.CHECK_FOR_JOBS_LOST),
			switchMap((action: JobPingsActions.CheckForJobsLost) => {
				return forkJoin([
					this.store$.select(getJobResponses)?.pipe(
						skipWhile((res: any) => !res),
						take(1)
					),
					this.store$.select(getAvailableJobs)?.pipe(
						skipWhile((res: any) => !res),
						take(1)
					)
				])?.pipe(map(([job_responses, available_jobs]: any) => ({ job_responses, available_jobs, all_job_ids: action.allawardedjobs as any[] })));
			}),
			map(({ job_responses, available_jobs, all_job_ids }: any) => {
				const job_response_ids = job_responses.map(({ id }) => id);
				const responses_no_longer_available = job_response_ids.filter((jr: any) => !available_jobs.map((job: any) => job?.id).includes(jr));
				const lost_job_ids = responses_no_longer_available.filter((id: any) => !all_job_ids.includes(Number(id)));
				const lost_jobs = lost_job_ids.length > 0 ? job_responses.filter((job: any) => lost_job_ids.includes(job.id)) : [];
				return new JobPingsActions.CheckForJobsLostSuccess(lost_jobs);
			}),
			catchError((error: any) => of(new JobPingsActions.CheckForJobsLostFail(error)))
		)
	);

	joinRespondedJobs = (currentUser: any, interestShown: any[]) => {
		return interestShown.map((jobIntrest: any) => {
			if (jobIntrest?.interest !== JOB_INTEREST.IGNORED) {
				this.availableJobService
					.getInfo(`${this.environment.api_url}v1/job_action/state_job_interest`, {
						job_id: jobIntrest.id,
						spm: currentUser.id,
						interested: jobIntrest.interest,
						time_of_response: jobIntrest?.time_of_response
					})
					.pipe(
						filter((x: any) => !!x),
						map((res: any) => ({ job: jobIntrest, interested: jobIntrest.interested, time_of_response: jobIntrest?.time_of_response }))
					);
			} else {
				return of({
					job: jobIntrest.job,
					interested: jobIntrest.interest,
					time_of_response: jobIntrest?.time_of_response
				});
			}
		});
	};
}
