import { Flow_0_0_2, getFullItemTwo, getData,} from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { forkJoin, of } from 'rxjs';
import { skipWhile, take, map, pluck, filter, switchMap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { KVLHeading } from '@flexus/ui-elements';
import { GetDecoderSerialNumberDisplay } from '../reusable/dataManipulations';

export const MUL_208: Flow_0_0_2 = {
	id: '208',
	name: 'awaiting-replacement-decoder',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: 'Awaiting Replacement Decoder',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'mulSpService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		voucher: {
			serviceVariable: 'mulSpService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Cannot get voucher summary'
		}
	},
	instructions: {
		editRoles: {
			0: 'Proceed to installer'
		},
		viewRoles: {
			0: 'Wait for installer to complete'
		}
	},
	startNode: 'ReplacementDecoderNotification',
	nodes: {
		ReplacementDecoderNotification: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							color: 'secondary',
							data$: 'multichoiceSupportKV',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Decoder Information',
							data$: 'decoderInfoKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Contact Details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			serverCalls: {
				multichoiceSupportKV: {
					errorMessage: "Couldn't display support email",
					directCall: () => {
						return of([{ 'Multichoice Support Email': 'installeradmin@multichoice.co.za' }]);
					}
				},
				decoderInfoKeyValues: {
					errorMessage: 'Something went wrong with decoder info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getData).pipe(
								skipWhile(v => !v.voucher),
								pluck('voucher'),
								take(1)
							)
						]).pipe(
							map(([job, voucher]) => {
								const decoderDetails = GetDecoderSerialNumberDisplay(voucher?.decoder_number);
								const decoderInfo = {
									...decoderDetails,
									'Voucher Code': job?.job_information?.vouchers?.voucher_code ?? 'No additional decoder information',
									'Additional Decoder Info ': job?.job_information?.vouchers?.title ?? 'No additional decoder information'
								};
								return [decoderInfo];
							})
						);
					}
				},
				customerContactKeyValues: {
					errorMessage: 'Something went wrong with customer info',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									let joblocation;
									const { identities } = itemTwo;
									if (!itemTwo?.claim?.loan_information) {
										joblocation = 'The customer misses data';
									}
									if (!itemTwo?.claim?.loan_information?.claimlatitude || !itemTwo?.claim?.loan_information?.claimlongitude) {
										joblocation = 'At least one coordinate was not supplied!';
									}
									if (itemTwo?.claim?.loan_information?.claimlatitude && itemTwo?.claim?.loan_information?.claimlongitude) {
										const latitude = itemTwo?.claim?.loan_information?.claimlatitude;
										const longitude = itemTwo?.claim?.loan_information?.claimlongitude;
										joblocation = `${latitude}, ${longitude}`;
									}
									const subscriber_no = identities?.subscriber_number;
									const customerInfo = {
										'Full name ': `${itemTwo?.claim?.applicant?.first_name} ` + `${itemTwo?.claim?.applicant?.surname}`,
										'Subscriber Number': `${subscriber_no}`,
										'Installation Address':
											`${itemTwo?.claim?.loan_information?.propertystreetaddress}` +
											' ' +
											`${itemTwo?.claim?.loan_information?.propertystreetaddress_2},` +
											' ' +
											`${itemTwo?.claim?.loan_information?.propertysuburb}`,
										'Email address ': itemTwo?.claim?.loan_information?.Email ?? 'No email address returned',
										'Contact number ': `${itemTwo?.claim?.loan_information?.ContactNumber}`,
										'GPS Coordinates ': joblocation
									};
									return [customerInfo];
								})
							);
					}
				}
			},
			navs: [
				{
					text: 'Confirm Received',
					nextNode: 'SubmissionSuccess',
					linkType: 'submit',
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'mulSpService',
							errorMessage: 'Job was not updated!',
							ignoreFalseError: true,
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									)
								]).pipe(
									switchMap(([job]) => {
										// console.log('BBBBB => ', job);
										const job_id: number = job?.id;
										const new_state = 21;
										const current_state = 208;

										const data = {
											job_id: job_id,
											new_state: new_state,
											current_state: current_state
										};

										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
