import { Store } from '@ngrx/store';


export class NewJobUtilities {
	static getSkillObjectByProperty(allSkills, property, propertyValue) {
		const skillFound: any = Object.values(allSkills).find((s: any) => s[property] === propertyValue?.replace(/,/gi, ''));

		return {
			skill: skillFound.name,
			skill_id: skillFound.id
		};
	}
}

export interface NewJobPayload {
	claim: number;
	new_claim_type?: number;
	application: any;
	team?: number;
	area?: string;
	jobcardrequest: JobCardRequest[];
}

export interface JobCardRequest {
	skillrequested: string;
	skillcatagory: string;
	skill: string;
	skill_id: string;
	providertype: string; //get from base job or job office_use providertype
	providertype_id: string; //get from base job or job office_use providertype
	appointmentdatetype: string;
	requestedappointmentdate?: string;
	appointment_type: string;
	appointment_type_id: string;
	requestedappointmenttime?: string;
	excess?: JobCardRequestExcess;
}

export interface JobCardRequestExcess {
	excess_amount: string;
	excess_description: string;
	who_collects_excess: string;
	who_collects_excess_desc: string;
	excess_payment_method: string;
	excess_payment_method_desc: string;
}
