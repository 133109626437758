import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, forkJoin } from 'rxjs';
import { map, take, skipWhile, mergeMap } from 'rxjs/operators';
import { getSelectedItemTwo } from '@flexus/core';

export class JobLocation {
	constructor(public lat: number, public lng: number) {}
}
@Injectable()
export class TeamleadPickerService {
	constructor(@Inject('environment') private _environment: any, private _http: HttpClient, private _store: Store<any>) {}

	returnJobLocation(): Observable<JobLocation> {
		return this._store.select(getSelectedItemTwo).pipe(
			skipWhile(x => !x),
			take(1),
			mergeMap(j => {
				const job = j as any;
				return forkJoin([
					this._http.post(`${this._environment.api_url}v1/job_action/get_job`, { job_id: job?.id }).pipe(
						skipWhile(x => !x),
						map(res => res as any)
					)
				]).pipe(
					map(([jobloc]) => {
						const locationString = jobloc['payload']?.claim?.location;
						const locArray = locationString?.split(',');
						const latitude = +locArray[0];
						const longitude = +locArray[1];
						return new JobLocation(latitude, longitude);
					})
				);
			})
		);
	}
}
