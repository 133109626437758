import { Flow_0_0_2, getFullItemOne, getAllInfo } from '@flexus/core';
import { map, skipWhile, filter, take } from 'rxjs/operators';
import { findName } from '@flexus/utilities';
import { forkJoin } from 'rxjs';

export const SIL_VIEW_CLAIM_SUMMARY: Flow_0_0_2 = {
	id: 'ClaimSummary',
	itemType: 'flow',
	name: 'View Claim Summary',
	excludeForOnlyStates: [169],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(x => !x),
				map(itemOne => {
					if (itemOne && itemOne?.applicant && itemOne?.applicant?.information) {
						return `Summary for installation claim - ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Claim Summary';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ClaimSummary',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	nodes: {
		ClaimSummary: {
			component: {
				children: [
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList', heading: 'Summary for installation claim' }
					}
				]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemOne).pipe(
								filter(x => !!x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								filter(x => !!x),
								take(1)
							)
						]).pipe(
							map(([items, allInfo]) => {
								let information = JSON.parse(JSON.stringify(items.loan_information));
								let applicant = JSON.parse(JSON.stringify(items.applicant));
								let vouchers = information.vouchers ? JSON.parse(JSON.stringify(information.vouchers)) : null;

								applicant = { ...applicant, ['customer_number']: applicant?.id_number ? applicant?.id_number : '' };
								vouchers = { ...vouchers, ['skill']: findName(parseInt(information?.vouchers?.skill), allInfo['skills'])?.name };
								information = { ...information, ['skills']: findName(parseInt(information?.skills), allInfo['skills'])?.name };

								const {
									jobcardrequest,
									whocollectsexcess,
									excess,
									dateofloss,
									excessinstruction,
									whorecoversexcess,
									generated,
									ClaimType_id,
									claim_type_id,
									current_environment,
									...purgedInfo
								} = information;

								let fixedCE = current_environment;
								if (current_environment?.dwelling_type?.id) {
									for (let item of Object.entries(current_environment)) {
										fixedCE[item[0]] = item[1]['description'];
									}
								}

								const { id_number, ['information']: deleted, ...purgedApplicant } = applicant;
								const { appointment, ...purgedVouchers } = vouchers;
								return [{ ...purgedInfo, vouchers: purgedVouchers?.skill ? purgedVouchers : null, current_environment: fixedCE }, purgedApplicant];
							})
						);
					}
				}
			},

			navs: []
		}
	}
};
