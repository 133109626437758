import { Flow_0_0_2 } from '@flexus/core';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { SATPAL_INFO_NODE } from '../reusable/SATPAL_INFO_NODE';
import { MUL_183 } from './MUL_183';
import { photoNode } from '../reusable/PHOTO_NODE';
import { setActionPanelItems } from '../../../../app-shell-features';
import { REWORK_NODE } from '../reusable/REWORK_NODE';
import { requestQANode } from '../reusable/REQUEST_QA_NODE';
import { resolvedProblemNode } from '../reusable/RESOLVED_PROBLEM_NODE';
import { GENERIC_BFTSMAPPER } from '../reusable/GENERIC_BFTSMAPPER';

export const MUL_184: Flow_0_0_2 = {
	id: '184',
	name: 'installation_needs_attention',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Installation Needs Attention',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Installation Needs Attention'
		},
		viewRoles: {
			0: 'Installation Needs Attention'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'jobInformation',
	nodes: {
		jobInformation: {
			showTabs: true,
			...jobInfoNode(184)
		},
		saptal: {
			...SATPAL_INFO_NODE
		},
		photos: {
			...photoNode()
		},
		requestQA: {
			...requestQANode()
		},
		resolvedProblem: {
			...resolvedProblemNode()
		},
		rework: {
			...REWORK_NODE
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},

	bigFormToStoreMapper: {
		...GENERIC_BFTSMAPPER
	}
};
