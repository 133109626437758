import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

export const BET_WARRANTY_VOID: Flow_0_0_2 = {
	id: 'WarrantyVoid',
	itemType: 'flow',
	// includeForOnlyStates: ['all'],
	name: 'Warranty Void',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo) {
						return `Warranty Void : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Warranty Void';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'WarrantVoid',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	serverCalls: {
		warrantyVoidData: {
			errorMessage: 'No warranty information returned',
			directCall: (http, store, sq) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile((itemTwo: any) => itemTwo === null || itemTwo === undefined),
						take(1)
					),
					store.pipe(
						// skipWhile((stor) => !stor),
						take(1)
					)
				]).pipe(
					map(([job, stor]: any) => {
						return { job, stor };
					})
				);
			}
		}
	},
	nodes: {
		WarrantVoid: {
			component: 'FLXWarrantyVoidComponent',
			inputs: {
				heading: 'The Warranty is Void',
				subtitle: 'The manufacturer has declared the warranty void. Please update the job so the Service Provider can return to the site.'
			},
			initFormFields: (bf, item, instance) => {
				bf.addControl('new_state', new UntypedFormControl('170'));
			},
			navs: [
				{
					text: 'UPDATE JOB',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							serviceVariable: 'betService',
							functionName: 'updateJob'
						}
					},
					color: 'secondary',
					location: 'right'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
