import * as fromAppMenu from './app-menu.actions';

export interface AppMenuState {
	appMenuOpened: boolean;
}

export const appMenuInitialState: AppMenuState = {
	appMenuOpened: false
};

export function appMenuReducer(state = appMenuInitialState, action: fromAppMenu.AppMenuActions): AppMenuState {
	switch (action.type) {
		// Open App Menu
		case fromAppMenu.appMenuActionTypes.OPEN_APP_MENU: {
			return {
				...state,
				appMenuOpened: true
			};
		}

		// Close App Menu
		case fromAppMenu.appMenuActionTypes.CLOSE_APP_MENU: {
			return {
				...state,
				appMenuOpened: false
			};
		}
		default: {
			return { ...state };
		}
	}
}
