import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
	constructor() {
		Sentry.init({
			dsn: environment['dsn'],
			beforeSend(event, hint) {
				const isNonErrorException =
					event.exception.values[0]?.value?.startsWith('Non-Error exception captured') || hint.originalException['message']?.startsWith('Non-Error exception captured');

				if (isNonErrorException) {
					// We want to ignore those kind of errors
					return null;
				}
				return event;
			}
		});
	}
	handleError(error) {
		const eventId = Sentry.captureException(error.originalError || error);
		// Sentry.showReportDialog({ eventId });
	}
}
