import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXFormFieldComponent } from './form-field.component';

@NgModule({
	imports: [CommonModule],
	exports: [FLXFormFieldComponent],
	declarations: [FLXFormFieldComponent],
	providers: []
})
export class FLXFormFieldModule {}
