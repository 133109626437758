import { NodePoint_0_0_2, getFullItemTwo, getAllInfo } from '@flexus/core';
import { skipWhile, take, map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { getPropertyCaseInsensitive, humaniseDate } from '@flexus/utilities';
import moment from 'moment';

export const JOB_INFO_NODE: NodePoint_0_0_2 = {
	showTabs: true,
	name: 'Overview',
	initFormFields: () => {},
	component: {
		children: [
			{
				component: 'FLXHeadingWithInstructionsComponent',
				inputs: {
					title: 'Job Allocation',
					itemMargin: '0 0 35px 0'
				}
			},
			{
				component: 'FLXKeyValueListComponent',
				inputs: {
					heading: 'Claim Details',
					data$: 'claimDetailKeyValues',
					itemMargin: '0 0 35px 0'
				}
			},
			{
				component: 'FLXKeyValueListComponent',
				inputs: {
					heading: 'On-site details',
					data$: 'onsiteKeyValues',
					itemMargin: '0 0 35px 0'
				}
			},
			{
				component: 'FLXKeyValueListComponent',
				inputs: {
					heading: 'Customer Details',
					data$: 'customerContactKeyValues',
					itemMargin: '0 0 35px 0'
				}
			}
		]
	},
	serverCalls: {
		claimDetailKeyValues: {
			errorMessage: 'Something went wrong claim details',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							// let appointment;
							let office_use;
							// if (itemTwo?.appointment && itemTwo.appointment.length > 0) {
							// 	appointment = itemTwo.appointment[itemTwo.appointment.length - 1];
							// }
							if (itemTwo?.office_use) {
								office_use = itemTwo.office_use;
							}

							const addArr = [];
							if (itemTwo?.propertycomplex) addArr.push(`${itemTwo?.propertycomplexunitnumber} ${itemTwo?.propertycomplex}`);
							if (itemTwo?.address) addArr.push(itemTwo?.address);
							if (itemTwo?.suburb) addArr.push(itemTwo?.suburb);
							if (itemTwo?.postal_code) addArr.push(itemTwo?.postal_code);

              const appointmentDateTime = `${office_use?.requestedappointmentdate}T${office_use?.requestedappointmenttime}:00`;

							const claimdetaillist = {
								'Claim Type ': itemTwo?.claim?.type ?? '',
								'Skill required ': itemTwo?.office_use?.skill ?? '',
								'Address ': addArr.join(', '),
								'Appointment Date ': office_use?.requestedappointmentdate ? `${humaniseDate(appointmentDateTime)}` : 'No appointment date set',
								'Appointment Time ': office_use?.requestedappointmenttime
									? `${office_use.appointment_type} ${moment(appointmentDateTime)?.format('HH:mm')}`
									: 'Appointment time not retrieved'
							};
							return [claimdetaillist];
						})
					);
			}
		},
		onsiteKeyValues: {
			errorMessage: "Couldn' retrieve on-site info",
			directCall: (http, store) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							const onsitelist = {
								'On site contact person ': itemTwo?.claim?.loan_information?.onsiteperson ?? 'Not retrieved',
								'Contact number ': itemTwo?.claim?.loan_information?.onsitecontact ?? 'On-site contact number not available',
								'Notes ': itemTwo?.claim?.loan_information?.onsitenotes ?? 'Notes Not Retrieved'
							};
							return [onsitelist];
						})
					);
			}
		},
		customerContactKeyValues: {
			errorMessage: 'Customer contact information could not be retrieved',
			directCall: (http, store, sq, bf) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile(f => !f),
						take(1),
						map(res => res as any)
					),
					store.select(getAllInfo).pipe(
						skipWhile(i => !i),
						take(1),
						map(res => res as any)
					)
				]).pipe(
					map(([itemTwo, info]) => {
						const job_id = itemTwo?.id;
						const job_excess = itemTwo?.excess;
						let excess_collection: string;
						let excess_amount: string;
						let excess_method: string;
						const excess_whoArr: any = info.excess_who;
						const excess_howArr: any = info.excess_how;
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_amount = job_excess[i]?.amount;
							}
						}
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_whoArr.forEach(element => {
									if (element.id === job_excess[i]?.who_collects) {
										excess_collection = element.name;
									}
								});
							}
						}
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_howArr.forEach(element => {
									if (element.id === job_excess[i]?.how_collect) {
										excess_method = element.name;
									}
								});
							}
						}
						console.table(itemTwo?.claim?.loan_information);
						const customercontactlist = {
							'Customer ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : 'Customer name not available',
							'Client Cell ': getPropertyCaseInsensitive(itemTwo?.claim?.loan_information, 'cellnumber') ?? 'Cellular number not on record',
							'Client Alternative No.': getPropertyCaseInsensitive(itemTwo?.claim?.loan_information, 'contactnumber'),
							'Excess Collection ': excess_collection ? `${excess_collection} to collect` : '-',
							'Excess Amount ': excess_amount ? `R${excess_amount}` : '-',
							'Excess payment ': excess_method
						};
						return [customercontactlist];
					})
				);
			}
		}
	}
};
