import { InjectionToken, LOCALE_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export const FLX_DATE_LOCALE = new InjectionToken<string>('FLX_DATE_LOCALE');

export const FLX_DATE_LOCALE_PROVIDER = { provide: FLX_DATE_LOCALE, useExisting: LOCALE_ID };

/** Adapts type `D` to be usable as a date by cdk-based components that work with dates. */
export abstract class DateAdapter<D> {
	/** The locale to use for all dates. */
	protected locale: any;

	/** A stream that emits when the locale changes. */
	get localeChanges(): Observable<void> {
		return this._localeChanges;
	}

	protected _localeChanges = new Subject<void>();

	abstract getYear(date: D): number;

	abstract getMonth(date: D): number;

	abstract getDate(date: D): number;

	abstract getDayOfWeek(date: D): number;

	abstract getMonthNames(style: 'long' | 'short' | 'narrow'): string[];

	abstract getDateNames(): string[];

	abstract getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[];

	abstract getYearName(date: D): string;

	abstract getFirstDayOfWeek(): number;

	abstract getNumDaysInMonth(date: D): number;

	abstract clone(date: D): D;

	abstract createDate(year: number, month: number, date: number): D;

	abstract today(): D;

	abstract parse(value: any, parseFormat: any): D | null;

	abstract format(date: D, displayFormat: any): string;

	abstract addCalendarYears(date: D, years: number): D;

	abstract addCalendarMonths(date: D, months: number): D;

	abstract addCalendarDays(date: D, days: number): D;

	abstract toIso8601(date: D): string;

	abstract isDateInstance(obj: any): boolean;

	abstract isValid(date: D): boolean;

	abstract invalid(): D;

	deserialize(value: any): D | null {
		if (value == null || (this.isDateInstance(value) && this.isValid(value))) {
			return value;
		}
		return this.invalid();
	}

	setLocale(locale: any) {
		this.locale = locale;
		this._localeChanges.next();
	}

	compareDate(first: D, second: D): number {
		return this.getYear(first) - this.getYear(second) || this.getMonth(first) - this.getMonth(second) || this.getDate(first) - this.getDate(second);
	}

	sameDate(first: D | null, second: D | null): boolean {
		if (first && second) {
			const firstValid = this.isValid(first);
			const secondValid = this.isValid(second);
			if (firstValid && secondValid) {
				return !this.compareDate(first, second);
			}
			return firstValid === secondValid;
		}

		return first === second;
	}

	clampDate(date: D, min?: D | null, max?: D | null): D {
		if (min && this.compareDate(date, min) < 0) {
			return min;
		}
		if (max && this.compareDate(date, max) > 0) {
			return max;
		}

		return date;
	}
}
