import { createSelector, createFeatureSelector } from '@ngrx/store';

export const getJobPingsContext = createFeatureSelector('jobPingsContext');

export const getCheckAvailablityDetails = createSelector(getJobPingsContext, (state: any) => state.AvailabilityChecks);

export const getFullAvailableJobsList = createSelector(getJobPingsContext, (state: any) => state.AvailableJobPings);

export const getAvailableJobs = createSelector(getJobPingsContext, (state: any) => state.filteredJobPings);

export const getJobsAwarded = createSelector(getJobPingsContext, (state: any) => state.JobsAwarded);

export const getJobsLost = createSelector(getJobPingsContext, (state: any) => state.JobsLost);

export const getSelectedJob = createSelector(getJobPingsContext, (state: any) => state.SelectedJobPing);

export const getJobResponses = createSelector(getJobPingsContext, (state: any) => state.JobResponses);

export const getForcePingModalShow = createSelector(getJobPingsContext, (state: any) => state.ForcePingModalShow);

export const getForceJobNotificationModalShow = createSelector(getJobPingsContext, (state: any) => state.ForceJobNotificationModalShow);
