import { Flow_0_0_2, getSelectedItemOne, StopLoader } from '@flexus/core';
import { Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { skipWhile, take } from 'rxjs/operators';
import { BuiEditClaim } from '../create-claim/bui-edit-claim';
import { UUID } from 'angular2-uuid';
import { CustomValidators, generateRange } from '@flexus/utilities';

export const BUI_169: Flow_0_0_2 = {
	// From create Claim
	...BuiEditClaim,

	id: '169',
	name: 'edit local draft',

	header: {
		...BuiEditClaim.header,
		title: 'Edit Local Draft'
	},
	instructions: {
		editRoles: {
			0: 'Edit draft'
		},
		viewRoles: {
			0: '--'
		}
	},
	valuesToBigForm: (bf, store) => {
		store
			.select(getSelectedItemOne)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe(itemOne => {
				// Initialize values
				const applicant = itemOne?.applicant;
				const loan_information = itemOne?.loan_information;
				const jobcardrequest = loan_information?.jobcardrequest;
				const createJob = (job?) => {
					if (job) {
						let jobExcess;
						if (Array.isArray(job.excess)) {
							jobExcess = job?.excess.find(j => j?.excess_description === 'Standard Excess');
						} else {
							jobExcess = job?.excess;
						}
						return bf.fb.group({
							id: UUID.UUID(),
							skillrequested: job?.skillrequested,
							skillcatagory_id: job?.skillcatagory_id,
							skillcatagory: job?.skillcatagory, // string name
							skill_id: job?.skill_id,
							skill: job?.skill, // string name
							providertype_id: job?.providertype_id,
							providertype: job?.providertype, // string name
							appointmentDatePicker: new Date(job.requestedappointmentdate),
							appointmentTimePicker: {
								hour: job?.requestedappointmenttime?.split(':')[0] ?? '',
								minutes: job?.requestedappointmenttime?.split(':')[1] ?? ''
							},
							appointmentTime: job?.appointment_type_id,
							appointmentDateType: job?.appointmentdatetype,
							amount: [
								jobExcess?.excess_amount,
								[Validators.required, Validators.minLength(4), CustomValidators.hardMaxLength(13), CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])]
							],
							who_collects: jobExcess?.who_collects_excess,
							who_collects_description: jobExcess?.who_collects_excess_desc,
							is_cancelled: false,
							excess_description: jobExcess?.excess_description,
							payment_method: jobExcess?.excess_payment_method,
							payment_description: jobExcess?.excess_payment_method_desc
						});
					} else {
						return bf.fb.group({
							id: UUID.UUID(),
							skillrequested: [1, [Validators.required]], // signal to the server to create job
							skillcatagory_id: [null, [Validators.required]],
							skillcatagory: null, // string name
							skill_id: [null, [Validators.required]],
							skill: null, // string name
							providertype_id: [null, [Validators.required]],
							providertype: null, // string name
							appointmentDatePicker: null,
							appointmentTimePicker: null,
							appointmentTime: null,
							amount: [
								null,
								[Validators.required, Validators.minLength(4), CustomValidators.hardMaxLength(13), CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])]
							],
							who_collects: [null, [Validators.required]],
							who_collects_description: null,
							is_cancelled: false,
							excess_description: 'Standard Excess',
							payment_method: [null, [Validators.required]],
							payment_description: null
						});
					}
				};

				const mandatoryExcess = jobcardrequest?.[0]?.excess && Array.isArray(jobcardrequest[0]?.excess) && jobcardrequest[0]?.excess[0];

				const jobArray = jobcardrequest?.length
					? jobcardrequest.map(job => {
							const theJob = createJob(job);
							return theJob;
					  })
					: [createJob()];

				// Form binding
				bf.addControl('jobs', new UntypedFormArray(jobArray));
				bf.addControl(
					'address_details',
					new UntypedFormControl({
						latitude: +loan_information.claimlatitude || 0,
						longitude: +loan_information.claimlongitude || 0
					})
				);

				bf.patchValues({
					new_state: 1,
					// Policy Lookup
					policy_lookup: applicant && applicant.claimantpoliceynum,
					// Policy Details
					policy_number: applicant && applicant.claimantpoliceynum ? applicant.claimantpoliceynum : '',
					client_details: {
						surname: (applicant && applicant.surname) || '',
						id_number: (applicant && applicant.id_number) || '',
						first_name: (applicant && applicant.first_name) || '',
						policy_number: (applicant && applicant.claimantpoliceynum) || '',
						bond_number: (applicant && applicant.bondnumber) || ''
					},
					contact_details: {
						contact_number: loan_information?.contactnumber || '',
						cell_number: loan_information?.cellnumber || '',
						email: loan_information?.email || '',
						preferred_com_method: loan_information?.preferedcommethod || ''
					},
					insured_address: {
						complex: loan_information?.propertycomplex || '',
						complex_unit_number: loan_information?.propertycomplexunitnumber || '',
						street_address: loan_information?.propertystreetaddress || '',
						suburb: loan_information?.propertysuburb || '',
						city: loan_information?.propertycity || ''
					},

					handler: loan_information?.handler || '',
					handlercontact: loan_information?.handlercontact || '',
					handleremail: loan_information?.handleremail || '',

					// Policy Details
					market_segment_key: loan_information?.market_segment_key || '',
					local_file: loan_information?.local_file || '',
					is_joint_account: loan_information?.isjointaccount || '',
					clientcode: loan_information?.clientcode || '',

					// Address confirmation
					suburb_code: loan_information?.suburbcode || '',
					addressconfirmation: loan_information?.addressconfirmation || '',
					area_code: loan_information?.areacode || '',

					// Claim details
					claim_class: loan_information?.claimtype_id || '',
					claim_class_description: loan_information?.claimtype || '',

					// Claim Appointment
					on_site_contact: loan_information?.onsitecontact || '',
					on_site_notes: loan_information?.onsitenotes || '',
					on_site_person: loan_information?.onsiteperson || '',

					//Payment Details
					reinsuranceexcess: loan_information?.reinsuranceexcess || '',
					specialclause: loan_information?.specialclause || '',
					voluntaryexcess: loan_information?.voluntaryexcess || '',
					additionalexcess: loan_information?.additionalexcess,
					underwriter: loan_information?.underwriter || '',
					sum_insured: loan_information?.suminsured || '',
					// additionalexcess: Number(additionalexcess),
					// reinsuranceexcess: Number(reinsuranceexcess),
					// voluntaryexcess: Number(voluntaryexcess),
					// specialclause: Number(specialclause),
					policy_excess: {
						is_cancelled: false,
						excess_description: mandatoryExcess ? mandatoryExcess?.excess_description : '',
						amount: Number(loan_information.additionalexcess) + Number(loan_information.reinsuranceexcess) + Number(loan_information.voluntaryexcess),
						who_collects: mandatoryExcess ? mandatoryExcess.who_collects_excess : '',
						who_collects_description: mandatoryExcess ? mandatoryExcess.who_collects_excess_desc : '',
						payment_method: mandatoryExcess ? mandatoryExcess.excess_payment_method : '',
						payment_method_description: mandatoryExcess ? mandatoryExcess.excess_payment_method_desc : ''
					},

					// // Other
					upfrontrepudiation: loan_information?.upfrontrepudiation || '',
					upfrontrepudiationreason: loan_information?.upfrontrepudiationreason || '',
					dontpingsp: loan_information?.dontpingsp || '',

					// What Matters Action Panel
					date_of_loss: loan_information?.dateofloss || '',
					whatmatters: loan_information?.whatmatters,
					claimdescription: loan_information?.claimdescription || '',

					// Claim Details context actions
					_3rdpartyinsurancecompany: loan_information?._3rdpartyinsurancecompany || '',
					_3rdpartyvechilereg: loan_information?._3rdpartyvechilereg || '',
					_3rdpartyvechilemake: loan_information?._3rdpartyvechilemake || '',
					_3rdpartydriverid: loan_information?._3rdpartydriverid || '',
					_3rdpartydrivercell: loan_information?._3rdpartydrivercell || '',
					_3rdpartydrivername: loan_information?._3rdpartydrivername || '',
					firedamageextent: loan_information?.firedamageextent || '',
					recomendedadjuster: loan_information?.recomendedadjuster || '',
					policeclaimnumber: loan_information?.policeclaimnumber || ''
				});
				store.dispatch(new StopLoader());
			});
	},
	// startNode: 'PolicyDetails',
	dontLoadItemOneFromServer: true,
	nodes: {
		PolicyLookup: {
			...BuiEditClaim?.nodes?.PolicyLookup
		},
		PolicyDetails: {
			...BuiEditClaim?.nodes?.PolicyDetails
		},
		AddressConfirmation: {
			...BuiEditClaim?.nodes?.AddressConfirmation
		},
		ClaimDetails: {
			...BuiEditClaim?.nodes?.ClaimDetails
		},
		ClaimAppointments: {
			...BuiEditClaim?.nodes?.ClaimAppointments
		},
		ClaimPaymentDetails: {
			...BuiEditClaim?.nodes?.ClaimPaymentDetails
		},
		ClaimHistory: {
			...BuiEditClaim?.nodes?.ClaimHistory
		},
		ClaimSubmissionSummary: {
			...BuiEditClaim?.nodes?.ClaimSubmissionSummary,
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'Save as Draft',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						saveAsDraft: {
							errorMessage: 'Could not save the draft!',
							serviceVariable: 'buiService',
							functionName: 'checkNetworkAndUpdateDraft',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'buiService',
									functionName: 'deleteCurrentEditableItem'
								},
								deleteLocalDraft: {
									errorMessage: 'Could not delete the existing local draft.',
									serviceVariable: 'buiService',
									functionName: 'deleteLocalDraft'
								}
							}
						}
					}
				},
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'This claim could not be created!',
							serviceVariable: 'buiService',
							functionName: 'createClaim',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'buiService',
									functionName: 'deleteCurrentEditableItem'
								},
								deleteLocalDraft: {
									errorMessage: 'Could not delete the existing local draft.',
									serviceVariable: 'buiService',
									functionName: 'deleteLocalDraft'
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			...BuiEditClaim?.nodes?.SubmissionSuccess
		}
	}
};
