import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PopupService {
	#isMinimizedSubject = new BehaviorSubject<boolean>(false);
	#isPopupVisibleSubject = new BehaviorSubject<boolean>(false);
	#formInputsSubject = new BehaviorSubject<any>(null);
	#keyValueListSubject = new BehaviorSubject<any>(null);
	#onClosePopupSubject = new BehaviorSubject<boolean>(null);

	get formControlContent() {
		return this.#formInputsSubject.value;
	}

	get formControlContent$() {
		return this.#formInputsSubject.asObservable();
	}

	set formControlContent(formInputs: any) {
		this.#formInputsSubject.next(formInputs);
	}

	get keyValueList() {
		return this.#keyValueListSubject.value;
	}

	get keyValueList$() {
		return this.#keyValueListSubject.asObservable();
	}

	set keyValueList(keyValueList$: Observable<any>) {
		keyValueList$.pipe(take(1)).subscribe(data => {
			this.#keyValueListSubject.next(data);
		});
	}

	get isMinimized() {
		return this.#isMinimizedSubject.value;
	}

	get isMinimized$() {
		return this.#isMinimizedSubject.asObservable();
	}

	set isMinimized(value) {
		this.#isMinimizedSubject.next(value);
	}

	get isPopupVisible() {
		return this.#isPopupVisibleSubject.value;
	}

	get isPopupVisible$() {
		return this.#isPopupVisibleSubject.asObservable();
	}

	set isPopupVisible(value) {
		this.#isPopupVisibleSubject.next(value);
	}

	get onClosePopup$() {
		return this.#onClosePopupSubject.asObservable();
	}

	showPopup() {
		this.#onClosePopupSubject.next(false);
		this.#isPopupVisibleSubject.next(true);
	}

	toggleMinimize() {
		this.#isMinimizedSubject.next(!this.#isMinimizedSubject.value);
	}

	onSubmitClosePopup() {
		this.#onClosePopupSubject.next(true);
		this.#isPopupVisibleSubject.next(false);
		this.#isMinimizedSubject.next(false);
	}

	closePopUp() {
		this.#isPopupVisibleSubject.next(false);
		this.#isMinimizedSubject.next(false);
	}
}
