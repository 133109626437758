<!-- (mouseover)="isItemHover = true" (mouseout)="isItemHover = false"-->
<ng-container *ngIf="lineItemFormGroup">
	<div class="line-item-modify" [formGroup]="lineItemFormGroup" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
		<div class="line-item-modify__item line-item-modify__item--flex-grow">
			<div
				class="line-item-modify__item__active-zone line-item-modify__item__active-zone--description line-item-modify__item__active-zone--left"
				[ngClass]="{ hover: isItemHover || isItemActive }"
			>
				<div id="search_{{ lineItemFormGroup?.value?.id }}" (click)="openDropdown()" class="line-item-modify__item__active-zone--description__label input">
					{{ lineItemFormGroup?.value?.description }}
				</div>
				<div class="line-item-modify__item__active-zone__icon">
					<ng-container *ngIf="!lineItemFormGroup?.value?.item?.is_custom_items">
						<img *ngFor="let icon of icons" src="{{ icon?.src }}" height="20px" data-title="{{ icon?.alt }}" />
					</ng-container>
				</div>
				<div
					*ngIf="showDropDown && lineItemFormGroup.value.can_edit"
					class="active-zone__search-drop-down"
					clickOutside
					(clickOutside)="showDropDown = false; isItemActive = false"
				>
					<input [disabled]="!lineItemFormGroup.value.can_edit" class="input--active" autocomplete="off" placeholder="Type to filter" [formControl]="searchField" tabindex="-1" />
					<div class="active-zone__search-drop-down__result">
						<div
							*ngFor="let item of availableLineItems | searchFilter: searchField.value:'name'"
							class="active-zone__search-drop-down__result__item"
							(click)="updateActualLineItem(item)"
							tabindex="-1"
						>
							<span>{{ item.name }}</span>
							<div tabindex="-1" class="active-zone__search-drop-down__result__item__icons">
								<flx-icon
									class="active-zone__search-drop-down__result__item__icon__item"
									size="small"
									[type]="'edit'"
									(click)="updateCustomItem($event, item)"
									*ngIf="item.is_custom_items"
									tabindex="-1"
								></flx-icon>
								<img
									class="active-zone__search-drop-down__result__item__icon__item"
									*ngFor="let icon of item.icons"
									src="{{ icon?.src }}"
									height="20px"
									data-title="{{ icon?.alt }}"
								/>
							</div>
						</div>
					</div>
					<div class="active-zone__search-drop-down__create-new" *ngIf="allow_custom_items">
						<flx-button tabindex="-1" (click)="createCustomItem($event)" color="alert">Create Custom Item</flx-button>
					</div>
				</div>
			</div>
		</div>
		<div class="line-item-modify__item">
			<div *ngIf="lineItemFormGroup.value.can_edit; else unEditableQuantity" class="line-item-modify__item__active-zone" [ngClass]="{ hover: isItemHover || isItemActive }">
				<input
					min="0.1"
					allowOnlyAbsoluteNumbers
					class="text-center"
					type="number"
					step="0.1"
					formControlName="quantity"
					(focus)="isItemActive = true"
					(blur)="isItemActive = false"
				/>
			</div>

			<ng-template #unEditableQuantity>
				<div class="line-item-modify__item__active-zone" [ngClass]="{ hover: isItemHover || isItemActive }">
					<span> {{ lineItemFormGroup?.value?.quantity }} </span>
				</div>
			</ng-template>
		</div>
		<div class="line-item-modify__item">
			<div class="line-item-modify__item__active-zone" [ngClass]="{ hover: isItemHover || isItemActive }">
				<span> R{{ lineItemFormGroup?.value?.unitPrice | number: '1.2-2' }} </span>
			</div>
		</div>
		<div class="line-item-modify__item">
			<div class="line-item-modify__item__active-zone line-item-modify__item__active-zone--right" [ngClass]="{ hover: isItemHover || isItemActive }">
				<span> R{{ lineItemFormGroup?.value?.quantity * lineItemFormGroup?.value?.unitPrice | number: '1.2-2' }} </span>
			</div>
		</div>

		<span *ngIf="client !== 'pgg_sp'" class="line-item-modify__remove-btn"> <flx-icon [type]="'remove'" color="warning" (click)="removeItem.emit()"></flx-icon> </span>
	</div>
	<div *ngIf="lineItemFormGroup?.get('quantity').errors" class="line-item-modify">
		<div class="line-item-modify__item line-item-modify__item--flex-grow">&nbsp;</div>
		<div class="line-item-modify__item">
			<flx-reactive-form-error-display [control]="quantityUntypedFormControl" class="error"></flx-reactive-form-error-display>
		</div>
		<div class="line-item-modify__item">&nbsp;</div>
		<div class="line-item-modify__item">&nbsp;</div>
	</div>
</ng-container>
