<div class="container">
	<flx-heading size="small" color="default" align="center">Edit Additional Equipment</flx-heading>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Diplexer</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('diplexer')">down </flx-icon></div>
			<div class="quantity">{{ diplexer_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('diplexer')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">RF Modulator</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('rfmodulator')">down </flx-icon></div>
			<div class="quantity">{{ rfmodulator_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('rfmodulator')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Wi-Fi Connector</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('wificonnector')">down </flx-icon></div>
			<div class="quantity">{{ wificonnector_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('wificonnector')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Dish Plate / Steel</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('dishplate_steel')">down </flx-icon></div>
			<div class="quantity">{{ dishplate_steel_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('dishplate_steel')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Dishplate / Fibre</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('dishplate_fibre')">down </flx-icon></div>
			<div class="quantity">{{ dishplate_fibre_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('dishplate_fibre')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Dishplate / Aluminium</div>
			<div class="chevron-down" #dishplate_aluminium><flx-icon [type]="'chevron-down'" (click)="decrementNumber('dishplate_aluminium')">down </flx-icon></div>
			<div class="quantity">{{ dishplate_alum_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('dishplate_aluminium')">up</flx-icon></div>
		</div>
	</div>
</div>
