<div class="action-panel-container">
	<aside
		class="action-panel action-panel__padding"
		#actionPanel
		[ngClass]="{
			'action-panel-expand': expandActionPanel$ | async,
			'action-panel-closed': !(expandActionPanel$ | async),
			'additional-panel-expanded': expandAdditionalActionPanel$ | async
		}"
	>
		<flx-dynamic-loader dynamicLoaderHost></flx-dynamic-loader>
		<flx-loader-component *ngIf="loading$ | async"></flx-loader-component>
	</aside>
	<aside class="action-panel additional-panel" #actionPanel [ngClass]="(expandAdditionalActionPanel$ | async) ? 'action-panel-expand' : 'action-panel-closed'">
		<flx-dynamic-loader #additionalContent dynamicLoaderHost></flx-dynamic-loader>
	</aside>
	<nav class="action-panel-navigation">
		<div class="top-icons">
			<div class="avatar-container">
				<div class="avatar">{{ initials }}</div>
			</div>
			<flx-icon
				*ngFor="let action of panelActions"
				[type]="action?.icon"
				[isActive]="clickedAction?.id === action?.id"
				active="primary"
				class="action-panel-button"
				use="action-panel"
				hover="primary"
				[instruction]="action?.instruction"
				(click)="onClick(action)"
			>
				{{ action?.icon }}
			</flx-icon>
		</div>
		<div class="bottom-icons">
			<flx-icon
				*ngIf="isSp"
				id="job-request-ping"
				[type]="'job-request-ping'"
				active="primary"
				class="action-panel-button"
				use="action-panel"
				hover="primary"
				instruction="Available Jobs"
				clickedAction="null;"
				[isActive]="clickedAction?.id === 'job-request-ping'"
				(click)="onClickJobRequests()"
			>
			</flx-icon>
			<!-- Reminders -->
			<flx-icon
				id="reminders"
				[type]="'reminder'"
				active="primary"
				class="action-panel-button"
				use="action-panel"
				hover="primary"
				instruction="Reminders"
				[isActive]="clickedAction?.id === 'reminders'"
				(click)="onClickReminders()"
			></flx-icon>

			<!-- Scratch Pad -->
			<flx-icon
				id="scratch-pad"
				[type]="'scratch-pad'"
				[isActive]="clickedAdditionalAction?.id === 'scratch-pad'"
				active="secondary"
				class="action-panel-button"
				use="action-panel"
				hover="secondary"
				(click)="onClickSketchPad()"
				instruction="Scratch Pad"
			></flx-icon>
		</div>
	</nav>
</div>
