import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountLockoutComponent } from './account-lockout.component';
import { AccountLockoutService } from './account-lockout.service';
import { FLXModalsModule } from '../modals';
import { FLXHeadingsModule } from '../headings';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { FLXAlertsModule } from '../alerts';
import { FLXButtonsModule } from '../buttons';

@NgModule({
	imports: [CommonModule, FLXModalsModule, FLXHeadingsModule, PortalModule, OverlayModule, FLXAlertsModule, FLXButtonsModule],
	declarations: [AccountLockoutComponent],
	exports: [AccountLockoutComponent],
	providers: [AccountLockoutService]
})
export class AccountLockoutModule {}
