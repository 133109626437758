import { HttpClientModule } from '@angular/common/http';
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// import { FLXDialogModule } from '../dialog/dialog-module';

import { CalendarComponent } from './calendar/calendar';
import { CalendarBodyComponent } from './calendar/calendar-body/calendar-body';
import { DATEPICKER_SCROLL_STRATEGY_PROVIDER, FLXDatepickerComponent } from './datepicker.component';

import { DatepickerInputDirective } from './datepicker-input.directive';
import { DatepickerToggleComponent } from './datepicker-toggle/datepicker-toggle';
import { FLXMonthViewComponent } from './month-view/month-view';
import { FLXMultiYearViewComponent } from './multi-year-view/multi-year-view';
import { FLXYearViewComponent } from './year-view/year-view';
import { NativeDateModule, NativeDateAdapter, DatepickerIntl } from './helpers';
import { DatePickerContentComponent } from './datepicker-content/datepicker-content';
import { DatepickerToggleIconDirective } from './datepicker-toggle/datepicker-toggle-icon.directive';
import { InlineSVGModule } from 'ng-inline-svg';
import { FLXIconModule } from '../inline-icons';
import { FLXDialogModule } from '../dialog/dialog.module';
// import { FLXDialogModule } from '../actions-and-effects/dialogue/dialog/dialog.module';
// import { FLXIconModule } from '@foursure-platform/ui';

@NgModule({
	imports: [CommonModule, FLXDialogModule, OverlayModule, A11yModule, NativeDateModule, InlineSVGModule, HttpClientModule, FLXIconModule],
	exports: [
		CalendarComponent,
		CalendarBodyComponent,
		FLXDatepickerComponent,
		DatePickerContentComponent,
		DatepickerInputDirective,
		DatepickerToggleComponent,
		DatepickerToggleIconDirective,
		FLXMonthViewComponent,
		FLXYearViewComponent,
		FLXMultiYearViewComponent
	],
	declarations: [
		CalendarComponent,
		CalendarBodyComponent,
		FLXDatepickerComponent,
		DatePickerContentComponent,
		DatepickerInputDirective,
		DatepickerToggleComponent,
		DatepickerToggleIconDirective,
		FLXMonthViewComponent,
		FLXYearViewComponent,
		FLXMultiYearViewComponent
	],
	providers: [NativeDateAdapter, DatepickerIntl, DATEPICKER_SCROLL_STRATEGY_PROVIDER]
})
export class FLXDatepickerModule {}
