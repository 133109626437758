import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormGeneratorComponent } from './dynamic-form-generator.component';
import { FLXRadioComponent } from './atoms/radio';
import { FileComponent } from './atoms/file';
import { CheckBoxComponent } from './atoms/checkbox';
import { DropDownComponent } from './atoms/dropdown';
import { TextBoxComponent } from './atoms/textbox';
import { FieldGroupGeneratorComponent } from './field-group-generator/field-group-generator.component';
import { FieldGeneratorComponent } from './field-generator/field-generator.component';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule],
	declarations: [
		DynamicFormGeneratorComponent,
		FieldGeneratorComponent,
		FieldGroupGeneratorComponent,
		TextBoxComponent,
		DropDownComponent,
		CheckBoxComponent,
		FileComponent,
		FLXRadioComponent
	],
	providers: [],
	exports: [DynamicFormGeneratorComponent]
})
export class FormGeneratorModule {}
