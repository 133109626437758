import * as fromActions from './sp-own-job.actions';

export interface SPOwnJobContextState {
	claimtype: number | string;
	isOwnJob: boolean;
	teamleaders: string[];
	teampositions: string[];
}

const initialState: SPOwnJobContextState = {
	claimtype: '',
	isOwnJob: false,
	teamleaders: [],
	teampositions: []
};

export function spOwnJobReducer(state: SPOwnJobContextState = initialState, action: fromActions.SPOwnJobActions) {
	switch (action.type) {
		case fromActions.SET_OWNJOB_CLAIMTYPE: {
			const data = action.payload;

			return {
				...state,
				claimtype: data.claimtype,
				isOwnJob: data.isOwnJob
			};
		}
		case fromActions.GET_OWNJOB_CLAIMTYPE: {
			return {
				...state
			};
		}
		case fromActions.GET_OWNJOB_CLAIMTYPE_SUCCESS: {
			const data = action.payload;
			return {
				...state
			};
		}
		case fromActions.GET_OWNJOB_STAFF_TEAM_LEADERS: {
			// const data = action.payload;
			return {
				...state
			};
		}
		case fromActions.GET_OWNJOB_STAFF_TEAM_LEADERS_SUCCESS: {
			const data = action.payload;
			return {
				...state,
				teamleaders: data
			};
		}
		case fromActions.GET_OWNJOB_TEAM_POSITIONS: {
			return {
				...state
			};
		}
		case fromActions.GET_OWNJOB_TEAM_POSITIONS_SUCCESS: {
			const data = action.payload;
			return {
				...state,
				teampositions: data
			};
		}
		default:
			return state;
	}
}

export const getSPOwnJobState = (state: SPOwnJobContextState) => state.claimtype;
