import { AfterContentInit, ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';

import { CalendarComponent } from '../calendar/calendar';
import { NativeDateAdapter } from '../helpers';
import { FLXDatepickerComponent } from '../datepicker.component';

@Component({
	selector: 'flx-datepicker-content',
	templateUrl: './datepicker-content.html',
	styleUrls: ['datepicker-content.scss'],
	host: {
		class: 'flx-datepicker-content',
		'[class.flx-datepicker-content-touch]': 'datepicker.touchUi'
	},
	exportAs: 'flxDatepickerContent',
	encapsulation: ViewEncapsulation.None,
	preserveWhitespaces: false,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NativeDateAdapter]
})
export class DatePickerContentComponent<D> implements AfterContentInit {
	datepicker: FLXDatepickerComponent<D>;

	@ViewChild(CalendarComponent, { static: true }) _calendar: CalendarComponent<D>;

	ngAfterContentInit() {
		this._calendar._focusActiveCell();
	}
}
