<div class="invoice-preview" *ngIf="invoice$ | async as invoice">
	<flx-heading size="small">{{ invoice.description }}</flx-heading>
	<flx-file-viewer [base64Source]="invoice?.data" mimeType="pdf" margin="0 0 1rem 0"></flx-file-viewer>
</div>
<flx-heading weight="light" align="center" size="medium"> Enter the items covered for this claim. These will appear on the Agreement of Loss Letter. </flx-heading>
<div class="agreement-container">
	<div class="agreement-controls">
		<form [formGroup]="bf.bigForm" *ngIf="agreementOfLossDetailDynamic">
			<div class="grid-header">
				<flx-heading size="extra-small" align="center" weight="light">Item Description</flx-heading>
				<span style="margin-right: 40px"> <flx-heading size="extra-small" align="center" weight="light">Amount (including Excess)</flx-heading> </span>
			</div>
			<ng-container formArrayName="agreementOfLossDetail" *ngFor="let item of agreementOfLossDetailDynamic.controls; let i = index">
				<div class="grid" [formGroupName]="i">
					<div class="item-description" *ngIf="item['controls']?.item">
						<flx-input-text [id]="'' + i" type="text" formControlName="item" size="normal"></flx-input-text>
						<flx-reactive-form-error-display [control]="item['controls']?.item"></flx-reactive-form-error-display>
					</div>

					<div class="right-container">
						<div class="item-price" *ngIf="item['controls']?.price">
							<flx-input-text [id]="'' + i" type="currency" [decimal]="2" formControlName="price" size="normal"> </flx-input-text>
							<flx-reactive-form-error-display [control]="item['controls']?.price"></flx-reactive-form-error-display>
						</div>
						<div class="table-actions">
							<flx-icon [type]="'subtraction'" (click)="removeRow(i)" *ngIf="agreementOfLossDetailDynamic.length > 1"> </flx-icon>
							<flx-icon [type]="'circle-add'" *ngIf="i + 1 === agreementOfLossDetailDynamic.controls.length" (click)="addRow(null, null)"> </flx-icon>
						</div>
					</div>
				</div>
			</ng-container>
		</form>
	</div>
	<div class="final-calc">
		<flx-heading size="small" class="columns col-7 col-mx-auto" margin="0">Final Payment Calculation:</flx-heading>
		<div class="columns col-7 col-mx-auto">R {{ bf.bigForm.get('total')?.value }} Invoice Amount</div>
		<div class="columns col-7 col-mx-auto">R {{ bf.bigForm.get('excess')?.value }} Less Excess</div>
		<div class="columns col-7 col-mx-auto">--------------------------------</div>
		<div class="columns col-7 col-mx-auto">R {{ bf.bigForm.get('total')?.value - bf.bigForm.get('excess')?.value }} Amount to pay</div>
	</div>
</div>
