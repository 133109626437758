<flx-flex-container alignItems="center">
	<br />
	<flx-heading size="medium" align="center" type="creation" weight="extra-light" margin="0 0 1rem">Device Details</flx-heading>

	<div class="dynamic-component device-details-container">
		<form [formGroup]="bf.bigForm">
			<!-- Current devices -->
			<ng-container *ngIf="showCurrentJobs && bf.bigForm.get('currentJobs')">
				<div class="devices" formArrayName="currentJobs">
					<div class="devices-section" *ngFor="let currentJob of currentJobControls?.controls; let i = index" [formGroupName]="i">
						<div class="device-input">
							<flx-dropdown formControlName="replacement_device_type_id" [isDisabled]="true"> </flx-dropdown>
						</div>
						<div class="device-input">
							<flx-input-text [isDisabled]="true" formControlName="serial_number" type="text" size="normal" placeholder="Device/Modem Number"></flx-input-text>
							<flx-reactive-form-error-display [control]="currentJob.get('serial_number')"></flx-reactive-form-error-display>
						</div>

						<br />
					</div>
					<flx-glow-line *ngIf="(bf.bigForm?.value?.currentJobs | fsIsArray) && bf.bigForm.value?.currentJobs.length !== 1" margin="0.5rem 0"></flx-glow-line>
				</div>
			</ng-container>

			<div class="device-details-jobs" *ngIf="bf.bigForm.get('jobs'); else noJobs">
				<ng-container *ngIf="(bf.bigForm?.value?.jobs | fsIsArray) && bf.bigForm?.value?.jobs.length > 0; else noJobs">
					<div formArrayName="jobs">
						<div class="devices-section" *ngFor="let item of getJobsArray()?.controls; let i = index" [formGroupName]="i">
							<div class="device-input">
								<p>
									{{ item.get('skill').value }}
								</p>
							</div>

							<div formArrayName="device_details" class="devices-container">
								<div class="device-holder" *ngFor="let device of getDevicesArray(item)?.controls; let j = index" [formGroupName]="j">
									<div formGroupName="insured_device">
										<div class="device-input">
											<flx-label>Old Device Type</flx-label>
											<flx-input-text formControlName="serial_number" type="text" size="normal" placeholder="Old Device Type"></flx-input-text>
											<flx-reactive-form-error-display [control]="$any(device).get('serial_number')"></flx-reactive-form-error-display>
										</div>
									</div>

									<div class="device-input" formGroupName="replacement_device">
										<flx-label>Replacement Device Type</flx-label>
										<flx-dropdown
											formControlName="name"
											[displayOptions]="displayConfig"
											[itemsOption]="replacementDeviceTypes$ | async"
											placeHolder="Replacement Device Type"
											[searchEnabled]="false"
											(itemsSelected)="setDeviceType($event, i, j)"
										>
										</flx-dropdown>
									</div>

									<div class="add-another-device">
										<!-- remove job -->
										<flx-icon *ngIf="item.get('device_details')?.value?.length !== 1" (click)="removeDevice(item, j)" [type]="'subtraction'"></flx-icon>

										<!-- add another device -->
										<flx-icon *ngIf="item.get('device_details')?.value?.length - 1 === j" (click)="incDeviceCount(item)" [type]="'addition'"></flx-icon>
									</div>
								</div>
							</div>

							<flx-glow-line *ngIf="(bf.bigForm.value?.jobs | fsIsArray) && bf.bigForm.value?.jobs.length > 1" margin="0.5rem 0"></flx-glow-line>
						</div>
					</div>
				</ng-container>
			</div>
		</form>
	</div>

	<ng-template #noJobs>
		<flx-heading size="extra-small" align="center" weight="extra-light" margin="0 0 1rem"> Please create a Job Card before setting device details </flx-heading>
	</ng-template>
</flx-flex-container>
