<flx-flex-container alignItems="center">
	<flx-panel maxWidth="50vw" margin="auto">
		<flx-heading [size]="'medium'" [align]="'center'" [weight]="'extra-light'">What Matters</flx-heading>

		<form [formGroup]="bf.bigForm" class="what-matters-form">
			<div class="input_in">
				<flx-label for="dateOutput">What matters to the customer</flx-label><br />
				<flx-textarea formControlName="whatmatters" id="whatmatters" [rows]="5"></flx-textarea>
			</div>
		</form>
	</flx-panel>
	<!-- <ng-container *ngIf="(whatMattersData$ | async) as data">
    <span> CLAIM ID : {{ data.claim.id }}</span>
  </ng-container> -->
</flx-flex-container>
