import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, Subscription, SubscriptionLike, } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';
import {
	DownloadInvoice,
	getMCAutopayInvoicesByDate,
  SetNumberedInvoiceNull,
  GetInvoicesService,
	getMCAutopayNumberedInvoiceState
} from '@flexus/core';

@Component({
	selector: 'flexus-installer-payment-list',
	templateUrl: './installer-payment-list.component.html',
	styleUrls: ['./installer-payment-list.component.scss']
})
export class InstallerPaymentListComponent implements OnInit, OnDestroy, OnChanges {
	@Input() invoices$: Observable<any>;
	@Input() invoice$: Observable<any>;
	@Input() numberedInvoiceExists$: Observable<any>;
	thereis_numbered_invoice;
	// @Input() invoicesLoadedStatus$: Observable<any>;
	invoice;
	invoiceSubscription: Subscription;
	invoicesLoadedSubscription: SubscriptionLike;
	isLoading = false;
	invoicesLoaded = false;

	paginationVariables = {
		pageSize: 8,
		currentPage: 0
	};
	numberedInvoiceSubscription: Subscription;

	constructor(private _store: Store<any>, private _http: HttpClient, private _invoice_service: GetInvoicesService, private _CD: ChangeDetectorRef) {
		this._invoice_service.setUILoadingStatus(false);
		this.invoice$ = null;
		this.invoices$ = null;
	}
	page(event: any) {
		this.paginationVariables.currentPage = event;
	}
	ngOnInit() {
		this._invoice_service.invoicesLoading$.subscribe(data => {
			this.isLoading = data;
			// this.invoicesLoaded = data;
		});

		this.invoices$ = this._store.select(getMCAutopayInvoicesByDate).pipe(
			filter(invoicedata => !!invoicedata),
			map(result => {
				if (Array.isArray(result)) {
					this._store.dispatch(new SetNumberedInvoiceNull(false));

					console.log('INVOICES LIST', result);
					return result;
				}
				if (result.CreationDate) {
					this._store.dispatch(new SetNumberedInvoiceNull(true));
					this.invoice$ = result;
					this.invoice = result;
					console.log('INVOICES SINGLE', this.invoice);
					return result;
				}
			})
		);
		this.invoiceSubscription = this.invoices$.subscribe();

		this.numberedInvoiceExists$ = this._store.select(getMCAutopayNumberedInvoiceState).pipe(
			skipWhile(invoicedata => !invoicedata),
			take(1),
			map(result => {
				this.thereis_numbered_invoice = result;
				console.log('NUMBERED', result);
				return result;
			})
		);
		this.numberedInvoiceSubscription = this.numberedInvoiceExists$.subscribe();
	}
	ngAfterViewInit(): void {
		this._CD.detectChanges();
		this._invoice_service.invoicesLoading$.subscribe(data => {
			this.isLoading = data;
			// this.invoicesLoaded = data;
		});
	}
	ngOnChanges(changes: SimpleChanges) {}
	download_invoice(invoice_number) {
		this._invoice_service.setUILoadingStatus(true);
		this._store.dispatch(new DownloadInvoice(invoice_number));
	}
	save_invoice() {
		console.log('SAVING INVOICE', this.invoice);
		const base64BinaryString = this.invoice?.Content;
		const byteCharacters = atob(base64BinaryString);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		// Create a Blob from the byte array
		const blob = new Blob([byteArray], { type: 'application/pdf' });

		// Create a Blob URL for the Blob
		const blobUrl = URL.createObjectURL(blob);

		// Create a link in your HTML that points to the Blob URL
		const link = document.createElement('a');
		link.href = blobUrl;
		link.target = '_blank';
		const filenamestring = this.invoice?.FileName;
		const dotindex = filenamestring.indexOf('.');
		const filename = filenamestring.substring(0, dotindex);
		link.download = `INVOICE__${filename}`;
		link.click();

		this._invoice_service.setUILoadingStatus(true);
	}

	ngOnDestroy(): void {
		if (this.invoiceSubscription) {
			this.invoiceSubscription.unsubscribe();
		}
	}
}
