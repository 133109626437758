import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { getForgotPasswordError, getForgotPasswordMessage, ForgotPassword } from '@flexus/core';

@Component({
	selector: 'flx-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	forgotForm: UntypedFormGroup;
	isSubmitting = false;
	message$: Observable<any>;
	error$: Observable<any>;
	logo_url: string;
	byline: string;

	constructor(private _fb: UntypedFormBuilder, private _router: Router, private _store: Store<any>) {
		this.forgotForm = this._fb.group({
			email: ['', Validators.required]
		});
	}

	ngOnInit() {
		this.logo_url = environment.branding.logo_url || 'assets/images/4-sure-logo.svg';
		this.byline = environment.branding.byline;
		this.message$ = this._store.select(getForgotPasswordMessage);
		this.error$ = this._store.select(getForgotPasswordError);
	}

	sendResetLink() {
		this.isSubmitting = true;
		const email = this.forgotForm.value;
		this._store.dispatch(new ForgotPassword(email));
		this.isSubmitting = false;
	}
}
