import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { BigFormService, getFullItemTwo, ManifestController, SetNextNode } from '@flexus/core';
import { Store } from '@ngrx/store';
import { of, } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

enum TriggerFailReasons {
	TeamleadNameFail = 'TL has empty name',
	CompanyAccreditationFail = 'SP has empty accreditation number',
	TeamleadAccreditationFail = 'TL has empty qualification number',
	NoVoucherLinkedFail = 'Job has no voucher linked',
	NonVoucheredDecoderLinkedFail = 'Job has non-vouchered decoder linked',
	MultipleValidDecodersFail = 'Job has multiple valid decoders'
}
@Component({
	selector: 'flx-item-verify-from-image',
	templateUrl: 'staff-issue-fixer.component.html',
	styleUrls: ['staff-issue-fixer.component.scss']
})
export class FLXStaffIssueFixerComponent implements OnInit, OnDestroy {
	private _selected_job_and_info$;
	private _selected_job_id;
	private _sp_id;
	private _tl_id;
	private _sp_name;
	private _sp_number;
	@Input() importantTLInfo$;
	@Input() installationInfo$;
	@Input() serviceProviders$;
	trigger_fail_reasons$;
	public fail_reasons_array: any[] = [];
	private issuesToFix = 0;
	setCompanyAndTeam = false;
	isNoTeamleadNameFailure = false;
	isCompanyAccreditationFailure = false;
	isNoTeamleadAccreditationFailure = false;
	isNoVoucherFailure = false;
	isNonVoucheredDecoderFailure = false;
	isMultipleValidDecoderFailure = false;
	instructions;
	companyKeyValueListData$;
	currentDecoderKeyValue$;
	decoderSerialNoImage$;

	company_accreditation_number;
	installer_accreditation_number;
	installer_name;

	teamleadfailreason: any;
	set() {}
	// get() {}
	constructor(private _store: Store<any>, @Inject('environment') private environment: any, public bf: BigFormService, public http: HttpClient, public controller: ManifestController<any>) {
		this._selected_job_and_info$ = this._store
			.select(getFullItemTwo)
			.pipe(
				skipWhile(x => !x),
				take(1),
				map((res: any) => res)
			)
			.pipe(
				map(job => {
					this._selected_job_id = job?.id;
					this._sp_id = job?.team_leader?.sp?.id;
					this._sp_name = job?.team_leader?.sp?.name;
					this._sp_number = job?.team_leader?.sp?.contact_primary;
					this._tl_id = job?.team_leader?.id;
				})
			)
			.subscribe();
	}

	ngOnInit() {
		this.companyKeyValueListData$ = of([{ company_name: this._sp_name }, { company_number: this._sp_number }]);
		this.getTriggerFailReasons();
		this.handleTriggerFailReasons(this.fail_reasons_array);
	}

	getTriggerFailReasons() {
		this.trigger_fail_reasons$ = this._store
			.select(getFullItemTwo)
			.pipe(
				skipWhile(x => !x),
				take(1),
				map(result => result as any)
			)
			.pipe(
				map(job => {
					const failreasonsstring = job?.job_information.state_trigger_problems;
					const failreasonsArray = job?.job_information.state_trigger_problems.split(', ');
					this.fail_reasons_array = [...failreasonsArray];
					this.issuesToFix = this.fail_reasons_array.length;
					console.log('FAILURES STRING', failreasonsstring);
					return job;
				})
			)
			.subscribe();
	}
	handleTriggerFailReasons(reasons: any[]) {
		// let workingReasonsArray = [...reasons];
		for (let i = 0; i <= reasons?.length - 1; i++) {
			const failreason: string = reasons[i];
			switch (failreason) {
				case TriggerFailReasons.TeamleadNameFail:
					this.setCompanyAndTeam = true;
					break;
				case TriggerFailReasons.CompanyAccreditationFail:
					this.isCompanyAccreditationFailure = true;
					break;
				case TriggerFailReasons.TeamleadAccreditationFail:
					this.setCompanyAndTeam = true;
					break;
				default:
					break;
			}
		}
		console.log('[handling issues.............]', reasons);
	}

	receiveTeamleadFailReason($event) {
		// this.teamleadfailreason = $event;
		if ($event === TriggerFailReasons.TeamleadNameFail) {
			this.setCompanyAndTeam = false;
			this.isNoTeamleadNameFailure = true;
		}
		if ($event === TriggerFailReasons.TeamleadAccreditationFail) {
			this.setCompanyAndTeam = false;
			this.isNoTeamleadAccreditationFailure = true;
		}
	}

	submitAndSetSPFixSummaryNode() {
		this.company_accreditation_number = this.bf.bigForm.get('company_accreditation_number').value;
		this.controller.dispatch(new SetNextNode('FixedStaffReasonsSummary'));
		return this.http
			.post(`${this.environment.api_url}v1/sp_action/update_mc_installer_details/`, { sp_id: this._sp_id, accreditation_number: this.company_accreditation_number })
			.subscribe(flexi => {
				console.log('flexisub', flexi);
			});
	}
	submitAndSetSPStaffNameFixSummaryNode() {
		this.installer_name = this.bf.bigForm.get('teamlead_name').value;
		console.log('teamlead name', this.installer_name);

		this.controller.dispatch(new SetNextNode('FixedStaffReasonsSummary'));
		return this.http.post(`${this.environment.api_url}v1/sp_action/update_mc_installer_details/`, { tl_id: this._tl_id, tl_name: this.installer_name }).subscribe(flexi => {
			console.log('flexisub', flexi);
		});
	}
	submitAndSetSPStaffAccreditationFixSummaryNode() {
		this.installer_accreditation_number = this.bf.bigForm.get('teamlead_accreditation_number').value;
		console.log('teamlead accreditation', this.installer_accreditation_number);

		this.controller.dispatch(new SetNextNode('FixedStaffReasonsSummary'));
		return this.http
			.post(`${this.environment.api_url}v1/sp_action/update_mc_installer_details/`, { tl_id: this._tl_id, qualification_number: this.installer_accreditation_number })
			.subscribe(flexi => {
				console.log('flexisub', flexi);
			});
	}
	ngOnDestroy() {}
}
