<flx-heading type="page" size="medium">{{ title }}</flx-heading>
<ng-container *ngIf="teams$ | async as teams; else notTeamMember">
	<ng-container *ngFor="let team of teams">
		<div>
			<flx-heading size="small"
				>Team name: <strong>{{ team?.name }}</strong></flx-heading
			>
			<flx-heading size="small"
				>Team leader: <strong>{{ team?.leader?.full_name }}</strong></flx-heading
			>
			<flx-heading size="small">Members:</flx-heading>
			<div style="padding-left: 20px">
				<ng-container *ngFor="let member of team?.member">
					<p>{{ member?.full_name }}</p>
				</ng-container>
			</div>
		</div>
	</ng-container>
</ng-container>
<ng-template #notTeamMember> <p>No team members to display.</p> </ng-template>
