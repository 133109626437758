import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Component({
	selector: 'iframe-comp',
	template: ` <iframe width="100%" height="100%" [frameBorder]="0" title="pdf template" [src]="url$ | async"> </iframe> `
})
export class IframeComponent implements OnInit {
	@Input() iframeUrl$: Observable<string>;
	url$;
	constructor(private sanitze: DomSanitizer) {}
	ngOnInit(): void {
		this.url$ = this.iframeUrl$.pipe(
			skipWhile(url => !url),
			map(url => {
				console.log({ url });
				return this.sanitze.bypassSecurityTrustResourceUrl(url);
			})
		);
	}
}
