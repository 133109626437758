import { BehaviorSubject, of, Subscription } from 'rxjs';

export class MultiFileLoadingHandlerService {
	private imageHandler = new BehaviorSubject<any>({});
	currentImages = this.imageHandler.asObservable();

	constructor() {}

	passImageData(images: any) {
		const tempImages: any[] = [];
		const sub: Subscription = images.subscribe((images: any) => {
			for (let i = 0; i < images.length; i++) {
				const imageobj: any = {
					base64: images[i]?.file?.base64,
					name: images[i]?.file?.nativeFile?.name,
					type: images[i]?.file?.type,
					size: (images[i]?.file?.nativeFile?.size / 1024 ** 2).toFixed(2),
					thumbnail: `data:${images[i]?.file?.type};base64, ${images[i]?.file?.base64}`
				};

				tempImages.push(imageobj);
			}
		});
		this.imageHandler.next(of(tempImages));
	}
}
