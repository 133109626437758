import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import moment from 'moment';
import { BigFormService } from '@flexus/core';
import { KeyValueListData, TabsData } from '@flexus/ui-elements';
import { map, skipWhile, take } from 'rxjs/operators';

@Component({
	selector: 'flx-internal-assessor-overview',
	templateUrl: './internal-assessor-overview.component.html'
})
export class FLXInternalAssessorOverviewComponent implements OnInit, OnDestroy {
	@Input() claimSummary$: Observable<any>;
	@Input() allJobs$: Observable<any[]>;
	@Input() allInfo$: Observable<any[]>;

	currentTab$: Observable<any>;
	shellTabs: TabsData[] = [
		{
			display: 'Claim Details',
			targetId: 'claim-overview',
			show: true
		}
	];
	claimDetails$: Observable<KeyValueListData>;
	claimAddress$: Observable<KeyValueListData>;
	jobInformation: any;
	jobObject$: BehaviorSubject<any> = new BehaviorSubject<any>({});

	constructor(public bf: BigFormService) {}

	ngOnInit(): void {
		this.claimSummary$
			.pipe(
				skipWhile(res => !res),
				take(1)
			)
			.subscribe(res => {
				this.claimDetails$ = of(res?.claim_details);
				this.claimAddress$ = of(res?.address);
			});

		this.currentTab$ = of('claim_information');
		const fixedJobs = [];
		forkJoin([
			this.allJobs$.pipe(
				skipWhile(res => !res),
				take(1)
			),
			this.allInfo$.pipe(
				skipWhile(res => !res),
				take(1)
			)
		])
			.pipe(
				map(([jobs, allInfo]) => {
					return { jobs, allInfo };
				})
			)
			.subscribe(({ jobs, allInfo }) => {
				jobs.forEach((job: any) => {
					fixedJobs.push(this.getRequiredJobInfo(job, allInfo));
				});

				this.jobInformation = fixedJobs;
				this.populateTabs(fixedJobs);
			});
	}

	populateTabs(jobs) {
		this.shellTabs = [
			{
				display: 'Claim Details',
				targetId: 'claim_information',
				show: true
			}
		];

		for (const job of jobs) {
			this.shellTabs.push({
				display: `${job.skill} -  (${job.id})`,
				targetId: `job-${job.id}`,
				show: true
			});
		}
	}

	getViewJobID(job) {
		return `job-${job.id}`;
	}

	getRequiredJobInfo(jobInfo, allInfo): any {
		const { token, area, claim, ping_count, job_creator, ...job } = jobInfo;
		const { states, skills, sps, supplier_type, appointment_types } = allInfo;

		const clonedJob = JSON.parse(JSON.stringify(job));
		Object.keys(job).forEach(key => {
			if (key === 'state') {
				if (typeof job?.state === 'number') {
					clonedJob.state = states.find(state => state.id === job?.state)?.description;
				}
			} else if (key === 'skill') {
				if (typeof job?.skill === 'number') {
					clonedJob.skill = skills.find(skill => skill.id === job?.skill)?.name;
				}
			} else if (key === 'sp') {
				if (typeof job?.sp === 'number') {
					clonedJob.sp = sps.find(sp => sp.id === job?.sp)?.name;
				}
			} else if (key === 'supplier_type') {
				if (typeof job?.supplier_type === 'number') {
					clonedJob.supplier_type = supplier_type.find(st => st.id === job?.supplier_type)?.name;
				}
			}
		});

		if (typeof job?.appointment.appointment_type === 'number') {
			clonedJob.appointment.appointment_type = appointment_types[job.appointment.appointment_type - 1]?.name;
		} else if (job.appointment.appointment_type instanceof Object) {
			clonedJob.appointment.appointment_type = job?.appointment.appointment_type.name;
		} else {
			clonedJob.appointment.appointment_type = null;
		}

		clonedJob.appointment.state = typeof job?.appointment.state === 'number' ? states[job.appointment.state]['description'] : job?.appointment.state;

		const { appointment, excess, ...clonedJobWithoutAppointment } = clonedJob;
		const clonedJobInfo = job?.job_information ? job?.job_information : {};
		const clonedJobOfficeUse = job?.office_use ? job?.office_use : {};
		const { ia_query, to_job, ...job_information } = clonedJobInfo;
		const { ['excess']: removed, ...office_use } = clonedJobOfficeUse;

		return { ...clonedJobWithoutAppointment, job_information, office_use };
	}

	handleTab(tabInfo) {
		this.currentTab$ = of(tabInfo.target);

		if (tabInfo.target !== 'claim_information' && this.jobInformation) {
			this.jobObject$.next(this.jobInformation.find(x => `job-${x.id}` === tabInfo.target));
		}
	}

	formatDate(date: string): Date {
		return moment(date, 'MM-D-YYYY h mm ss a').toDate();
	}

	// get isAssessorJobCard$(): Observable<boolean> {
	//   return this.selectedItemTwo$.pipe(
	//     map((itemTwo) => {
	//       return itemTwo.state === 96 || itemTwo.state === 92;
	//     }),
	//   );
	// }

	ngOnDestroy(): void {}
}
