<flx-calendar
	cdkTrapFocus
	[id]="datepicker.id"
	[ngClass]="datepicker.panelClass"
	[startAt]="datepicker.startAt"
	[startView]="datepicker.startView"
	[minDate]="datepicker._minDate"
	[maxDate]="datepicker._maxDate"
	[dateFilter]="datepicker._dateFilter"
	[selected]="datepicker._selected"
	(selectedChange)="datepicker._select($event)"
	(_userSelection)="datepicker.close()"
>
</flx-calendar>
