import { Component, ViewChild, ElementRef, Input, Renderer2, OnChanges, AfterViewInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'flx-file-preview',
	templateUrl: 'file-preview.component.html',
	styleUrls: ['file-preview.component.scss']
})
export class FLXFilePreviewComponent implements OnChanges, AfterViewInit {
	@Input() source;
	@Input() base64Source: string;
	@Input() containerHeight = '100%'; // default height
	@Input() mimeType: string;
	@Input() styles: { [id: string]: string };
	@Input() displayArrows = false;

	@ViewChild('file', { read: ElementRef })
	file: ElementRef;

	@ViewChild('fileContainer', { read: ElementRef })
	fileContainer: ElementRef;

	@Output() fseGoToNext = new EventEmitter();
	@Output() fseGoToPrevious = new EventEmitter();

	constructor(private renderer: Renderer2, private _sanitizer: DomSanitizer) {}

	ngOnDestroy() {}
	ngOnInit() {}

	ngOnChanges(): void {
		this.updateChanges();
	}

	ngAfterViewInit(): void {
		this.updateChanges();
	}

	updateFileDetails(): void {
		const blob = this.base64StringtoBlob(this.base64Source, this.mimeType.includes('pdf') ? 'application/pdf' : 'image/jpg');
		if (this.mimeType === 'pdf') {
			this.source = URL.createObjectURL(blob);
			this.initiatePDF();
		} else {
			this.source = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
		}
	}

	updateChanges() {
		switch (this.mimeType) {
			case 'application/pdf':
				this.base64Source = this.base64Source?.replace(/^data:.*?;base64,/, '');
				this.mimeType = 'pdf';
				break;
			case 'image/tiff':
				this.mimeType = 'tiff';
				break;
			case 'image/jpeg' || 'image/jpg' || 'image/png':
				this.source = this.base64Source;
				this.mimeType = 'image';
				break;
			default:
				break;
		}

		if (this.base64Source && this.file) {
			this.updateFileDetails();
		} else if (this.base64Source && this.mimeType !== 'pdf') {
			this.updateFileDetails();
		}
	}

	goToPrevious(): void {
		this.fseGoToPrevious.emit();
	}

	goToNext(): void {
		this.fseGoToNext.emit();
	}

	initiatePDF() {
		if (this.source && this.mimeType === 'pdf') {
			const fileElement: HTMLObjectElement = this.file.nativeElement;
			const fileContainerElement: HTMLDivElement = this.fileContainer.nativeElement;

			fileElement.data = this.source;

			if (this.styles) {
				Object.keys(this.styles).map(key => {
					this.renderer.setStyle(fileContainerElement, key, this.styles[key]);
				});
			}
		}
	}

	base64StringtoBlob(b64Data, contentType = '', sliceSize = 512): Blob {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}
}
