<flx-flex-container alignItems="center">
	<div [formGroup]="bf.bigForm">
		<flx-heading size="medium" [align]="'center'" weight="extra-light" type="creation">{{ title }}</flx-heading>
		<flx-panel density="compact" alignItems="center" width="50vw">
			<div class="mandatory-excess" *ngIf="showAdditionalExcess">
				<!-- <div>Please select the required skills from the 'Claim Details' tab</div> -->
				<div class="excesses">
					<div>Voluntary excess: {{ voluntaryexcess }}</div>
					<div>Additional excess: {{ additionalexcess }}</div>
					<div>Reinsurance excess: {{ reinsuranceexcess }}</div>
				</div>
				<div>Special clause:</div>
				<div class="special-clause" style="height: 80px; overflow-y: auto">{{ specialclause }}</div>
			</div>
			<div class="policy-excess-details" formGroupName="policy_excess">
				<div class="mandatory" [ngClass]="{ 'has-no-mandatory-excess': bf.bigForm?.value.policy_excess?.is_cancelled || !policyExcessFlag }">{{ mandatoryTitle }}</div>
				<div *ngIf="!bf.bigForm?.value?.policy_excess?.is_cancelled" class="policy-input">
					<span class="no-select" [ngClass]="{ 'has-no-mandatory-excess': bf.bigForm?.value.policy_excess?.is_cancelled || !policyExcessFlag }">R&nbsp;&nbsp;</span>
					<flx-input-text formControlName="amount" [readonly]="true" placeholder="0" [decimal]="0" margin="0" width="100%" [isDisabled]="!policyExcessFlag"></flx-input-text>
				</div>

				<div *ngIf="!bf.bigForm?.value?.policy_excess?.is_cancelled" class="policy-input">
					<flx-dropdown
						[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
						[itemsOption]="paymentMethodList$ | async"
						formControlName="payment_method"
						[placeHolder]="'Payment method'"
						[searchEnabled]="false"
						[isDisabled]="!policyExcessFlag"
					></flx-dropdown>
				</div>

				<div *ngIf="!bf.bigForm?.value?.policy_excess?.is_cancelled" class="policy-input">
					<ng-container *ngIf="env.client === 'sil'; else serviceProvider">
						<flx-dropdown
							[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
							[itemsOption]="skillsList$ | async"
							formControlName="skill_id"
							[placeHolder]="'Job skill'"
							[searchEnabled]="false"
							[autoOpen]="bf.bigForm?.value?.policy_excess?.skill_id == null && bf.bigForm?.value?.policy_excess?.payment_method !== null"
							[isDisabled]="!policyExcessFlag"
						></flx-dropdown>
					</ng-container>
					<ng-template #serviceProvider>
						<flx-dropdown
							[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
							[itemsOption]="serviceProviderList$ | async"
							formControlName="who_collects"
							[placeHolder]="'Select who collects'"
							[searchEnabled]="false"
							[isDisabled]="!policyExcessFlag"
						></flx-dropdown>
					</ng-template>
				</div>

				<div *ngIf="bf.bigForm?.value?.policy_excess?.is_cancelled" class="payment-details-container__item__details--waived fixed-padding no-select">
					<span>Excess Waived</span>
				</div>

				<div class="policy-input void-icon" *ngIf="policyExcessFlag">
					<flx-icon
						[type]="'remove'"
						[ngClass]="{ 'has-cancelled': bf.bigForm?.value.policy_excess?.is_cancelled }"
						(click)="toggleCancellation(bf.bigForm, 'policy_excess')"
					></flx-icon>
				</div>
			</div>
		</flx-panel>
		<flx-glow-line *ngIf="showJobExcess || showCurrentJobs" size="100%"></flx-glow-line>

		<flx-heading *ngIf="showJobExcess || showCurrentJobs" size="medium" [align]="'center'" weight="extra-light" type="creation">Job Excess</flx-heading>

		<ng-container *ngIf="bf.bigForm.get('currentJobs') && bf.bigForm.get('currentJobs')?.value?.length > 0 && showCurrentJobs">
			<flx-panel density="compact" alignItems="center" width="50vw">
				<div class="job-excess-details" *ngFor="let currentJob of bf.bigForm?.value?.currentJobs; let index = index" formArrayName="currentJobs">
					<div class="form" [formGroupName]="index" *ngIf="!currentJob?.is_cancelled">
						<div class="job-description">
							<span>{{ currentJob?.skillcatagory + ' - ' + currentJob?.skill }}</span>
						</div>

						<!-- <div class="payment-details-container__item__details"> -->
						<div class="amount">
							<span class="no-select">R&nbsp;&nbsp;</span>
							<flx-input-text formControlName="amount" placeholder="0" [decimal]="0" margin="0" [disabled]="true"> </flx-input-text>
						</div>

						<div class="method">
							<flx-dropdown
								[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
								[itemsOption]="paymentMethodList$ | async"
								formControlName="payment_method"
								[placeHolder]="'Payment method'"
								[searchEnabled]="false"
								[isDisabled]="true"
							></flx-dropdown>
						</div>

						<div class="collection">
							<ng-container *ngIf="env.client === 'sil'; else serviceProvider">
								<flx-dropdown
									[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
									[itemsOption]="skillsList$ | async"
									formControlName="skill_id"
									[placeHolder]="'Job skill'"
									[searchEnabled]="false"
									[autoOpen]="bf.bigForm?.value?.policy_excess?.skill_id == null && bf.bigForm?.value?.policy_excess?.payment_method !== null"
									[isDisabled]="true"
								></flx-dropdown>
							</ng-container>
							<ng-template #serviceProvider>
								<flx-dropdown
									[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
									[itemsOption]="serviceProviderList$ | async"
									formControlName="who_collects"
									[placeHolder]="'Select who collects'"
									[searchEnabled]="false"
									[isDisabled]="true"
								></flx-dropdown>
							</ng-template>
						</div>
					</div>
					<br />
				</div>
			</flx-panel>
			<flx-glow-line margin="0.5rem 0"></flx-glow-line>
		</ng-container>

		<ng-container *ngIf="showJobExcess">
			<ng-container *ngIf="bf.bigForm.get('jobs') && bf.bigForm.value?.jobs.length > 0; else noJobs">
				<flx-panel density="compact" alignItems="center" width="50vw">
					<div class="job-excess-details" *ngFor="let jobCardRequestCtrl of $any(bf.bigForm.get('jobs'))?.controls; let index = index" formArrayName="jobs">
						<div class="form" [formGroupName]="index">
							<div class="job-description">
								<span>Mandatory Excess {{ index + 1 }}</span>
							</div>

							<!-- <div class="payment-details-container__item__details"> -->
							<div *ngIf="!jobCardRequestCtrl?.value?.is_cancelled" class="amount">
								<span class="no-select">R&nbsp;&nbsp;</span>
								<div>
									<flx-input-text formControlName="amount" placeholder="0" [decimal]="0" margin="0"></flx-input-text>
									<flx-reactive-form-error-display [control]="$any(bf.bigForm.get('jobs'))?.controls[index]?.controls?.amount"> </flx-reactive-form-error-display>
								</div>
							</div>

							<div *ngIf="!jobCardRequestCtrl?.value?.is_cancelled" class="method">
								<flx-dropdown
									[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
									[itemsOption]="paymentMethodList$ | async"
									formControlName="payment_method"
									[placeHolder]="'Payment method'"
									[searchEnabled]="false"
								></flx-dropdown>
							</div>

							<div *ngIf="!jobCardRequestCtrl?.value?.is_cancelled" class="collection">
								<ng-container *ngIf="env.client === 'sil'; else serviceProvider">
									<flx-dropdown
										[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
										[itemsOption]="skillsList$ | async"
										formControlName="skill_id"
										[placeHolder]="'Job skill'"
										[autoOpen]="bf.bigForm?.value?.policy_excess?.skill_id == null && bf.bigForm?.value?.policy_excess?.payment_method !== null"
										[searchEnabled]="false"
										[isDisabled]="true"
									></flx-dropdown>
								</ng-container>
								<ng-template #serviceProvider>
									<flx-dropdown
										[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
										[itemsOption]="serviceProviderList$ | async"
										formControlName="who_collects"
										[placeHolder]="'Select who collects'"
										[searchEnabled]="false"
									></flx-dropdown>
								</ng-template>
							</div>

							<div *ngIf="jobCardRequestCtrl?.value?.is_cancelled" class="waiver no-select"><span>Excess Waived</span></div>

							<div class="void-icon">
								<flx-icon
									class="payment-details-container__item__icon--cancel"
									[ngClass]="{ 'has-cancelled': jobCardRequestCtrl?.value?.is_cancelled }"
									(click)="toggleCancellation(jobCardRequestCtrl)"
									[type]="'remove'"
								>
								</flx-icon>
							</div>
							<flx-glow-line *ngIf="!!jobCardRequestCtrl?.value" margin="0.5rem 0"></flx-glow-line>
						</div>
					</div>
				</flx-panel>
			</ng-container>
		</ng-container>
	</div>
	<ng-template #noJobs>
		<flx-heading size="extra-small" align="center" weight="extra-light" margin="0 0 1rem"> Please create a Job Card before setting claim excess </flx-heading>
	</ng-template>
</flx-flex-container>
