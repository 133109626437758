import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, Renderer2, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';

import { of, Observable } from 'rxjs';
import { skipWhile, take, map } from 'rxjs/operators';
import { sort, compose, reduce, pluck, descend, uniqBy } from 'ramda';

@Component({
	selector: 'flx-file-browser',
	templateUrl: './file-browser.component.html',
	styleUrls: ['./file-browser.component.scss']
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FLXFileBrowserComponent implements OnInit, AfterViewInit {
	filterValue = '';
	private _thumbSize: string;
	private _display: string;

	locationClass = 'container';

	@Input() location: string;
	@Input() showMeta: boolean;

	@Input() heading: string;
	@Input() paginationId: string;
	@Input() files$: Observable<any>;
	@Input() defaultFile: any;
	@Input() showFilters = true;
	@Input() documentsSelectable = false;
	@Input() imagesSelectable = false;

	@Input()
	set thumbSize(val: string) {
		this._thumbSize = val;
	}

	get thumbSize() {
		return this._thumbSize || '100px';
	}

	@Input()
	set display(val: string) {
		this._display = val;
	}

	get display() {
		return this._display;
	}

	@ViewChild('buttonGroup', { static: true })
	buttonGroup: ElementRef;

	@Output() fsSelectFile = new EventEmitter();

	constructor(private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit() {
		// Sort files in descending order
		this.files$ = this.files$.pipe(
			skipWhile(x => !x),
			map(files =>
				sort(
					descend((f: any) => new Date(f.created)),
					files || []
				)
			)
		);

		switch (this.location) {
			case 'action-panel':
				this.locationClass = 'container-ap';
				break;
			case 'context-menu':
				this.locationClass = 'container-cm';
				break;
			case 'flow-photos':
				this.locationClass = 'container-fl';
        break;
			default:
				break;
		}
	}

	ngAfterViewInit() {
		const getUniqueButtonsFunc = compose(
			reduce((acc, purpose) => [...acc, { text: purpose, filterText: purpose }], []),
			pluck('purpose'),
			uniqBy((f: any) => f.purpose)
		);

		// Create filter buttons
		of(this.buttonGroup)
			.pipe(
				skipWhile(e => !e),
				take(1)
			)
			.subscribe(ele => {
				this.createButton(ele.nativeElement, { text: 'All', filterText: 'all' }, this.renderer);
				const filterButtons = getUniqueButtonsFunc(this.files$ as any);
				filterButtons.forEach((button: any) => {
					this.createButton(ele.nativeElement, button, this.renderer);
				});
			});
	}

	createButton(parentElement: HTMLDivElement, button: any, renderer: Renderer2) {
		if (button.filterText) {
			const buttonEl: HTMLButtonElement = renderer.createElement('button');
			buttonEl.textContent = button.text;
			renderer.addClass(buttonEl, 'btn');
			renderer.addClass(buttonEl, 'btn-default');
			renderer.addClass(buttonEl, 'filter-button');
			renderer.listen(buttonEl, 'click', () => {
				this.setFilterValue(button.filterText);
			});
			renderer.appendChild(parentElement, buttonEl);
		}
	}

	setFilterValue(value) {
		this.filterValue = value;
		this.changeDetectorRef.detectChanges();
	}

	getSelectedFiles(data) {
		this.fsSelectFile.emit(data);
	}
}
