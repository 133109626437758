import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getAllInfo, getCurrentUser, getFullItemTwo } from '@flexus/core';
import gql from 'graphql-tag';
import { combineLatest, EMPTY, forkJoin } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';
import { getInterstateComments } from '../reusable/DATA_MANIPULATION';
import { SINGLE_FILE_UPLOAD_SINGLE_PURPOSE } from './single_file_upload_single_purpose';
import { KVLHeading } from '@flexus/ui-elements';

export const SIL_275: Flow_0_0_2 = {
	id: '275',
	name: 'cash_in_lieu_query_reply',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Cash In Lieu Query Reply'
		},
		viewRoles: {
			0: 'Cash In Lieu - Query'
		}
	},
	serverCalls: {
		dataFiles: {
			serviceVariable: 'silService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		files: {
			serviceVariable: 'silService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		notes: {
			serviceVariable: 'silService',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No claim notes could be found!'
		}
	},

	startNode: 'JobSummary',
	nodes: {
		JobSummary: {
			name: 'Job summary',
			component: {
				children: [
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'important_infor',
							heading: 'Job Summary',
							color: 'secondary',
							colouredHeading: new KVLHeading('Cash in lieu authorisation query', 'secondary')
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'customerDetails', heading: 'Customer Details' }
					}
				]
			},
			showTabs: true,
			serverCalls: {
				important_infor: {
					errorMessage: 'Cannot get job info summary',
					directCall: (http, store) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								skipWhile(x => !x),
								take(1)
							)
						]).pipe(
							take(1),
							map(([job, allinfo]) => {
								const { job_information } = job;
								const list: any = [];

								if (job_information.interstate_comments) {
									const comments = Array.isArray(job_information.interstate_comments) ? [...job_information.interstate_comments] : [job_information.interstate_comments];
									const arr = comments.filter(comment => comment.nextState && parseInt(comment.nextState, 10) === 275);
									const obj = arr[arr?.length - 1];

									if (obj) {
										const message = obj.message ? obj.message : '';

										list.push({
											'Message from previous state': message
										});
									}
								}

								return list;
							})
						);
					}
				},
				customerDetails: {
					errorMessage: 'Cannot get job info summary',
					directCall: (http, store) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								take(1),
								map(info => {
									const { claim } = info;
									const list: any = [];
									// Customer details
									if (claim?.applicant) {
										list.push({
											full_name: claim?.applicant?.first_name + ' ' + claim?.applicant?.surname,
											email_address: claim?.loan_information?.email ?? claim?.loan_information?.Email ?? '',
											contact_number: claim?.applicant?.contact_number
										});
									}
									return list;
								})
							);
					}
				}
			},
			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ new_state: 107, authorised: 'Y' });
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									id
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(job => {
						bf.patchValues({ job_id: job?.id });
					});
			},
			navs: [
				{ text: 'Upload Invoice', nextNode: 'UploadDocument' },
				{ text: 'Respond to Query', nextNode: 'QueryResponse', color: 'alert' }
			]
		},

		Invoices: {
			name: 'Invoices',
			showTabs: true,
			component: 'ViewDocumentsComponent',
			inputs: {
				heading: 'View Documents',
				location: 'context-menu'
			},
			initFormFields: (bf, item, instance) => {},
			navs: [
				{ text: 'Upload Invoice', nextNode: 'UploadDocument' },
				{ text: 'Respond to Query', nextNode: 'QueryResponse', color: 'alert' }
			]
		},

		QueryResponse: {
			hideTabItem: true,

			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				heading: '',
				formControls: {
					0: {
						label: 'Enter notes on your query response',
						inputType: 'textarea',
						width: '48%',
						formControlName: 'query_notes'
					}
				},
				containerWidth: '300px'
			},

			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},

			checkValidityForFields: ['query_notes'],

			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ new_state: 276 });
				bf.bigForm.addControl('query_notes', new UntypedFormControl('', [Validators.minLength(3)]));
				combineLatest([
					store.select(getCurrentUser).pipe(filter(x => !!x, take(1))),
					store.select(getFullItemTwo).pipe(
						filter(x => !!x),
						take(1)
					)
				])
					.pipe(take(1))
					.subscribe(([user, job]) => {
						bf.patchValues({
							author: user?.user?.id,
							author_name: user?.user?.full_name,
							currentState: job?.state,
							nextState: 276
						});
					});
			},

			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		UploadDocument: {
			hideTabItem: true,
			...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes.UploadDocument
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		new_state: 'new_state',
		query_notes: [(message, storeObj, bf) => getInterstateComments(message, storeObj, bf), 'job_information.interstate_comments'],
		job_id: 'job_id'
	}
};
