import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, map, pluck, skipWhile, filter, tap, catchError } from 'rxjs/operators';
import { combineLatest, Observable, from, forkJoin, empty, of, throwError, EMPTY } from 'rxjs';
import moment from 'moment';
import { environment } from 'apps/studio/src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClaimService } from './child-services/claim.service';
import { JobService } from './child-services/job.service';
import {
	getSelectedItemOne,
	getSelectedItemTwo,
	getAllInfo,
	getFullItemOne,
	BigFormService,
	getSubmissionData,
	getFullItemTwo,
	getCurrentUser,
	getAppointmentTypes,
	IndexedDbService,
	getData,
	ModalService,
} from '@flexus/core';
import { NewJobPayload } from '../models/new-job.model';
import { inlineRecursiveMerge, transformCallLog, findName, getAllInfoIndex } from '@flexus/utilities';
import { UUID } from 'angular2-uuid';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { getBOQ } from '@flexus/core';

@Injectable()
export class BetService {
	internalAssessorJobStates = [
		96, // internal_assessment
		97 // internal_assessor_job_report_uploaded
	];

	constructor(
		private store: Store<any>,
		private http: HttpClient,
		private claimService: ClaimService,
		private jobService: JobService,
		private bf: BigFormService,
		private indexedDbService: IndexedDbService,
		private modalsService: ModalService,
		private router: Router
	) {}

	addCustomerRating() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(submissionData => this.claimService.addCustomerRating({ ...submissionData }))
		);
	}

	getSelectedItemOne() {
		return this.store.select(getSelectedItemOne).pipe(
			skipWhile(x => !x),
			take(1)
		);
	}

	getSelectedItemTwo() {
		return this.store.select(getSelectedItemTwo).pipe(
			skipWhile(x => !x),
			take(1)
		);
	}

	getJobSP() {
		return this.getSelectedItemTwo().pipe(
			pluck('sp'),
			switchMap(sp => {
				return this.getInterestedParties().pipe(
					pluck('payload'),
					map((interested_parties: any[]) => ({ ...interested_parties.find(ip => ip.sp.id === sp) }))
				);
			}),
			take(1)
		);
	}

	getAppointSPSummary() {
		return this.getJobSP().pipe(
			map(interestedParty => {
				let res;
				switch (interestedParty.new_interested) {
					case -1: {
						res = 'Rejected';
						break;
					}
					case 0: {
						res = 'Ignored';
						break;
					}
					case 1: {
						res = 'Accepted';
						break;
					}
					case null: {
						res = 'No-Reply';
						break;
					}
				}
				return [
					{ 'Primary Contact': interestedParty.sp.contact_person },
					{ 'Primary Contact Number': interestedParty.sp.contact_primary },
					{
						'response to job': res
					},
					{ 'Currently Awarded Jobs': interestedParty.awarded_today }
				];
			}),
			take(1)
		);
	}

	appointSP() {
		return this.getSelectedItemTwo().pipe(
			switchMap(jobItem => {
				return this.jobService.manuallyAllocateJob({ jobId: jobItem.id, spId: jobItem.sp });
			})
		);
	}

	repingSP() {
		const appointment = this.bf.bigForm.get('appointmentData')?.value;
		const date = moment(appointment.appointmentDatePicker);
		date.hour(appointment.appointmentTimePicker.hour);
		date.minutes(appointment.appointmentTimePicker.minutes);

		const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
		const appointment_formatted = [{ range_start: date_formatted, range_end: date_formatted, appointment_type_id: appointment.appointmentTime }];

		return this.getSelectedItemTwo().pipe(
			pluck('id'),
			switchMap(job_id => {
				return this.jobService.repingJob({ appointments: appointment_formatted, job_id: job_id });
			})
		);
	}

	getAllClaims() {
		return this.claimService.getAllClaims();
	}

	getClaimAppointments() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.claimService.getClaimAppointments({ claim_id: claim?.id });
			})
		);
	}

	getCurrentFullItemOne() {
		return this.getSelectedItemOne().pipe(
			take(1),
			switchMap(item => this.getFullItemOne(item?.id))
		);
	}

	getFullItemOne(id) {
		return this.claimService.getFullClaim(id);
		// return this.getSelectedItemOne().pipe(
		//   switchMap((claim) => {
		//   }),
		// );
	}

	getFullItemOneWithItemTwo(id) {
		return this.claimService.getFullClaimWithJobs(id);
	}

	getFullItemTwo(id) {
		return this.jobService.getFullJob(id);
	}

	getInterestedParties() {
		return this.getSelectedItemTwo().pipe(
			skipWhile(x => !x),
			pluck('id'),
			switchMap(jobId => {
				return this.jobService.getInterestedParties({ job_id: jobId });
			})
		);
	}

	dealOutOfApp() {
		return this.getSelectedItemTwo().pipe(switchMap(itemTwo => this.jobService.updateJob({ job_id: itemTwo?.id, new_state: 76 })));
	}

	cancelJob() {
		return this.getSelectedItemTwo().pipe(
			switchMap(job => {
				return this.jobService.cancelJob({ job_id: job?.id, reason: this.bf.bigForm?.value?.cancelclaim_reason });
			})
		);
	}

	newJob(jobData: NewJobPayload) {
		return this.jobService.newJob(jobData);
	}

	assessorVisitSite() {
		return forkJoin([
			this.store.select(getFullItemTwo).pipe(
				skipWhile(x => !x),
				take(1),
				map(res => res as any)
			),
			this.store.select(getAllInfo).pipe(
				skipWhile(x => !x),
				take(1),
				pluck('appointment_types'),
				map(res => res as any[])
			)
		]).pipe(
			switchMap(([job, appointment_types]) => {
				let new_state;
				let return_to_state;

				switch (job.state) {
					case 106:
					case 32:
						new_state = job?.state;
						return_to_state = 90;
						break;
					case 90:
						new_state = 90;
						return_to_state = job?.job_information?.return_to_state;
						break;
					default:
						new_state = 90;
						return_to_state = job?.state;
				}

				const appointmentData: any = this.bf.bigForm.get('appointmentData')?.value;
				const date = appointmentData.appointmentDatePicker ? moment(appointmentData.appointmentDatePicker) : null;

				const range_start = moment(
					new Date(date.year(), date.month(), date.date(), appointmentData.appointmentTimePicker.hour, appointmentData.appointmentTimePicker.minutes)
				).format('YYYY-MM-DDTHH:mm:ss');

				const data = {
					appointment: {
						appointment_type: appointmentData.appointmentTime ? appointment_types.find(type => type.id === appointmentData.appointmentTime).id : null,
						range_start: range_start,
						reason: ''
					},
					current_state: job?.state,
					job_id: job?.id,
					job_information: {
						return_to_state: return_to_state
					},
					new_state: new_state
				};

				return this.jobService.updateJob(data);
			})
		);
	}

	updateUnmanagedMavenJob() {
		return forkJoin([
			this.store.select(getSubmissionData).pipe(
				skipWhile(x => !x || !x.job_information),
				take(1)
			),
			this.store.select(getFullItemTwo).pipe(take(1))
		]).pipe(
			map(([submissionData, job]) => {
				const job_information = { ...job.job_information, ...submissionData.job_information };
				if (!job_information.OutAppFlowSPid && !job_information.OutAppFlowSPMID) {
					job_information.OutAppFlowSPid = 0;
					job_information.OutAppFlowSPMID = 0;
				}
				return { job_id: job?.id, new_state: submissionData.new_state, job_information: job_information };
			}),
			switchMap(data => {
				return this.jobService.updateJob(data);
			})
		);
	}

	generateBOQReport() {
		return this.store
			.select(getFullItemTwo)
			.pipe(take(1))
			.pipe(
				switchMap(response => {
					const { id } = response;
					return this.jobService.generateBOQReport(id);
				})
			);
	}

	updateJob() {
		return forkJoin([this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemTwo).pipe(take(1))]).pipe(
			map(([submissionData, job]) => {
				const { job_information } = job;
				const { job_information: ji, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(job_information ?? {}));
				inlineRecursiveMerge(result, ji);

				const response = {
					job_information: result,
					...rest,
					job
				};
				return response;
			}),
			switchMap(response => {
				const { job, ...data } = response;
				return this.jobService.updateJob({ job_id: job?.id, ...data });
			})
		);
		// return this.store.select(getSubmissionData).pipe(
		//   take(1),
		//   switchMap((serverData) => {
		//     return this.getSelectedItemTwo().pipe(
		//       switchMap((job) => {
		//         return this.jobService.updateJob({ job_id: job?.id, ...serverData });
		//       }),
		//     );
		//   }),
		// );
	}

	updateClaim() {
		return forkJoin(this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemOne).pipe(take(1))).pipe(
			map(([submissionData, claim]) => {
				const { loan_information } = claim;
				const { loan_information: ci, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(loan_information));
				inlineRecursiveMerge(result, ci);

				const response = {
					loan_information: result,
					...rest,
					claim
				};
				return response;
			}),
			switchMap(response => {
				const { claim, ...data } = response;
				return this.claimService.updateClaim({ claim_id: claim?.id, ...data });
			})
		);

		// return this.store.select(getSubmissionData).pipe(
		//   take(1),
		//   switchMap((serverData) => {
		//     return this.getSelectedItemOne().pipe(
		//       switchMap((claim) => {
		//         return this.claimService.updateClaim({ claim_id: claim?.id, ...serverData });
		//       }),
		//     );
		//   }),
		// );
	}

	createClaim() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(claim => {
				const propertycomplex = claim.loan_information.propertycomplex;
				const propertycomplexunitnumber = claim.loan_information.propertycomplexunitnumber;
				const propertycomplexblock = claim.loan_information.propertycomplexblock;
				const propertystreetnumber = claim.loan_information.propertystreetnumber;
				const propertystreetname = claim.loan_information.propertystreetname;
				const propertysuburb = claim.loan_information.propertysuburb;
				const propertycity = claim.loan_information.propertycity;
				const postal_code_input = claim.loan_information.postal_code_input;

				const { suburb, address, postal_code, ...rest_claim } = claim;

				return this.claimService.createClaim({
					propertycomplex,
					propertycomplexunitnumber,
					propertycomplexblock,
					propertystreetnumber,
					propertystreetname,
					propertysuburb,
					propertycity,
					postal_code: postal_code_input,
					...rest_claim
				});
			})
		);
	}

	cancelClaim() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.claimService.cancelClaim({
					claim_id: claim?.id,
					reason: this.bf.bigForm?.value?.cancel_reason
				});
				// return this.claimService.cancelClaim({claim_id: claim?.id, reason: this.bf.bigForm.value.cancel_reason})
			})
		);
	}

	reopenClaim(data: any) {
		return this.store
			.select(getFullItemOne)
			.pipe(
				skipWhile(x => !x),
				take(1),
				map(res => res as any)
			)
			.pipe(
				map(claim => {
					const claim_id = (claim as any).id;
					const reason = this.bf.bigForm.get('reopen_data');

					return {
						claim_id: claim_id,
						reopen_code: reason?.value?.reopen_reason
					};
				}),
				switchMap(response => {
					return this.claimService.reopenClaim(response);
				})
			);
	}

	saveAsServerDraft() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(claim => {
				return this.claimService.createClaim({ ...claim, new_state: 8 });
				// return throwError({ success: false, payload: '' });
			})
			// catchError((error) => {
			//   return of(error);
			// }),
		);
	}

	checkNetworkAndUpdateDraft() {
		if (window.navigator.onLine) {
			// update server draft
			return this.saveAsServerDraft();
		} else {
			return this.saveAsLocalDraft();
		}
	}

	updateServerDraft(new_state) {
		return forkJoin(this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemOne).pipe(take(1))).pipe(
			map(([submissionData, claim]) => {
				const { loan_information } = claim;
				const { loan_information: ci, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(loan_information));
				inlineRecursiveMerge(result, ci);

				const response = {
					loan_information: result,
					...rest,
					claim
				};
				return response;
			}),
			switchMap(response => {
				const { claim, ...data } = response;
				return this.claimService.updateClaim({ claim_id: claim?.id, ...data, new_state });
			})
		);
	}

	updateServerDraftAsSubmitted() {
		return forkJoin(this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemOne).pipe(take(1))).pipe(
			map(([submissionData, claim]) => {
				const { loan_information } = claim;
				const { loan_information: ci, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(loan_information));
				inlineRecursiveMerge(result, ci);

				const response = {
					loan_information: result,
					...rest,
					claim
				};
				return response;
			}),
			switchMap(response => {
				const { claim, ...data } = response;
				return this.claimService.updateClaim({ claim_id: claim?.id, ...data, new_state: 1 });
			})
		);
	}

	saveAsLocalDraft() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(item => {
				const localDraft = { tempKey: UUID.UUID(), ...item, state: 169 };
				return from(this.indexedDbService.claimInDraft.put(localDraft));
			})
		);
	}

	checkNetworkAndSaveDraft() {
		if (window.navigator.onLine) {
			return this.saveAsServerDraft();
		} else {
			return this.saveAsLocalDraft();
		}
	}

	deleteCurrentEditableItem() {
		return from(this.indexedDbService.currentItem.delete('currentItem')).pipe(map(res => ({ success: true, payload: res })));
	}

	deleteLocalDraft() {
		return this.getSelectedItemOne().pipe(switchMap(itemOne => from(this.indexedDbService.claimInDraft.delete(itemOne.tempKey))));
	}

	navigateToWorkflow() {
		return from(this.router.navigate(['/workflow']));
	}

	policyLookup(data: { search: string; lookup_type: string }) {
		return this.http.post(`${environment.api_url}v1/claim_action/get_policies/`, {
			search: data?.search,
			search_type: data?.lookup_type
		});
	}

	policyLookupDetails(data: { transactionNumber: string }) {
		return this.http.post(`${environment.api_url}v1/claim_action/get_policy_details/`, {
			policy_number: data?.transactionNumber
		});
	}

	policyCheckPremiumStatus(data: { policy_number: string; date_of_loss: string; product_code: string; product_option_name: string }) {
		return this.http.post(`${environment.api_url}v1/claim_action/check_premium_status/`, data);
	}

	policyHistorySearch(data: { search: string }) {
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim_history/`, {
			search: data?.search
		});
	}

	getItemOneExtra() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.claimService.getClaimExtra(claim?.id);
			})
		);
	}

	getCloseClaimInfo() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.getCloseClaimInfoAPI({ claim_id: claim?.id }).pipe(
					map(res => {
						return res;
					})
				);
			})
		);
	}

	// getJobInvoice() {
	//   return this.getSelectedItemTwo().pipe(
	//     switchMap((item) => {
	//       return this.paymentService.getJobInvoice(item.id).pipe(map((res: any) => res?.payload));
	//     }),
	//   );
	// }

	getSkills() {
		return this.store.select(getAllInfo).pipe(
			skipWhile((allInfo: any) => {
				return allInfo.states === null || allInfo.states[1] === undefined;
			})
		);
	}

	getAppointmentTypes() {
		return this.store.select(getAppointmentTypes).pipe(
			skipWhile((appointmentTypes: any) => {
				return appointmentTypes === null || appointmentTypes === undefined;
			})
		);
	}

	getActiveReions() {
		return this.store.select(this.getActiveReions).pipe(
			skipWhile((activeReions: any) => {
				return activeReions === null || activeReions === undefined;
			})
		);
	}

	getJobInvoice() {
		return this.getSelectedItemTwo().pipe(
			skipWhile(x => !x),
			take(1),
			switchMap((itemTwo: any) => {
				return this.http
					.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
						job_id: itemTwo?.id,
						return_type: 1
					})
					.pipe(map(payload => payload.payload));
			})
		);
	}

	getJobReport() {
		return this.getSelectedItemTwo().pipe(
			skipWhile(x => !x),
			take(1),
			switchMap((itemTwo: any) => {
				return this.http
					.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
						job_id: itemTwo?.id,
						return_type: 1
					})
					.pipe(map(payload => payload.payload));
			})
		);
	}

	getServiceProvidersOnClaim() {
		return combineLatest(this.getSelectedItemOne(), this.store.select(getAllInfo).pipe(map((allInfo: any) => allInfo.sps))).pipe(
			map(([claim, serviceProviders]) => {
				const jobs = claim?.jobs.filter(job => job?.state !== 45 || job?.state !== 72);
				return jobs
					.filter(job => !!job.sp)
					.map(job => ({
						jobId: job?.id,
						skill: job?.skill,
						spName: findName(job.sp, serviceProviders).name
					}));
			})
		);
	}

	getClaimMid() {
		return this.store.select(getFullItemOne).pipe(
			skipWhile(x => !x),
			pluck('mid'),
			take(1),
			map(data => {
				return data;
			})
		);
	}

	getPaymentAuthorizationList() {
		// return this.store.select(getFullItemTwo).pipe(
		//   switchMap((item) => {
		//     return this.paymentService.getPaymentAuthorizationList().pipe(
		//       map((data: any) => data.payload),
		//       map((persons: any) => {
		//         switch (item.state) {
		//           // case 27: {
		//           //   return persons.filter((p) => p.max_auth >= activeFlowJobInfo.claim_value);
		//           // }
		//           case 50:
		//           case 51: {
		//             return persons.filter((p) => p.max_auth >= item.claim_value);
		//           }
		//           default: {
		//             return persons;
		//           }
		//         }
		//       }),
		//     );
		//   }),
		// );
		// return combineLatest(
		//   this.store.select(getActiveFlowItemData).pipe(map((data) => data.tieredInformation.job.job_information)),
		//   this.store.select(getFullItemTwo),
		// ).pipe(
		//   takeWhile((value) => !!value && !!value[0] && !!value[1] && !!value[2]),
		//   map((value) => {
		//     const persons = value[0].payload;
		//     const activeFlowJobInfo = value[1];
		//     const fullJob = value[2];
		//     switch (fullJob.state) {
		//       case 27: {
		//         return persons.filter((p) => p.max_auth >= activeFlowJobInfo.claim_value);
		//       }
		//       case 50:
		//       case 51: {
		//         return persons.filter((p) => p.max_auth >= fullJob.claim_value);
		//       }
		//       default: {
		//         return persons;
		//       }
		//     }
		//   }),
		// );
	}

	getAllClaimFiles() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				return this.getAllFilesApi(null, itemOne?.id, null);
			})
		);
	}

	getAllJobFiles() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				if (itemTwo) {
					return this.getAllFilesApi(itemTwo.id, null);
				} else {
					return empty();
				}
			})
		);
	}

	getAllIAJobPhotos() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				if (itemTwo) {
					return this.getAllJobsFilesOnClaim().pipe(
						map((files: any[]) => {
							return files.filter(file => file.job !== itemTwo.id && file.thumbnail);
						})
					);
				} else {
					return EMPTY;
				}
			})
		);
	}

	getAllSPJobPhotos() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				if (itemTwo) {
					return this.getAllJobsFilesOnClaim().pipe(
						map((files: any[]) => {
							return files.filter(file => file.job === itemTwo.id && file.thumbnail);
						})
					);
				} else {
					return EMPTY;
				}
			})
		);
	}

	getAllJobsFilesOnClaim() {
		return this.getSelectedItemOne().pipe(
			map(itemOne => {
				const STATES = {
					JobCompleted: 28,
					JobCancelled: 45
				};

				return itemOne.jobs.filter(job => ![STATES.JobCompleted, STATES.JobCancelled].includes(job.state));
			}),
			switchMap((jobs: any[]) => {
				const jobsResponse$ = jobs.map(job => this.getAllFilesApi(job.id, null).pipe(map(response => response?.payload ?? [])));

				return forkJoin(jobsResponse$).pipe(map(responses => [].concat(...responses)));
			})
		);
	}

	getNotes() {
		return combineLatest([this.getSelectedItemTwo(), this.getInternalAssessorJob()]).pipe(
			switchMap(([itemTwo, iaJob]: any) => {
				if (itemTwo && iaJob && itemTwo?.id === iaJob.id) {
					return this.getjobAssessmentNotes();
				} else {
					return this.getJobNotes();
				}
			})
		);
	}

	getJobNotes() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				if (itemTwo) {
					return this.getJobNotesApi(itemTwo.id);
				} else {
					return empty();
				}
			})
		);
	}

	getjobAssessmentNotes() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				return this.getjobAssessmentNotesApi(itemTwo.id).pipe(
					skipWhile(x => !x),
					pluck('payload'),
					map(iaNotes => iaNotes.map(iaNote => ({ timestamp: iaNote.created, message: iaNote.note })))
				);
			})
		);
	}

	getInternalAssessorJob() {
		return this.store.select(getSelectedItemOne).pipe(
			take(1),
			map(itemOne => {
				if (itemOne) {
					const iaJob = this.getinternalAssessorJobCard(itemOne.jobs);
					return iaJob ? iaJob : { id: 0 };
				}
			})
		);
	}

	// getPolicyHistory() {
	//   return this.policyHistoryLookup({ search: this.bf.bigForm.value.policy_number }).pipe(
	//     pluck('payload', 'claims'),
	//     map((claims) => {
	//       if (claims) {
	//         return (claims as any[]).map((claim) => [
	//           { title: 'Loss Date', value: this.formatDate(claim.LOSSDATE) },
	//           { title: 'Claim Type', value: claim?.CLAIMTYPE },
	//           [{ title: 'Status', value: claim?.STATUS }, { title: 'Claim Cost', value: `R${claim.CLAIMCOST}` }],
	//           { title: 'Comment', value: claim?.COMMENTS },
	//         ]);
	//       }
	//       return claims;
	//     }),
	//     map((claims: any[]) => {
	//       if (claims) {
	//         return { claims: claims.sort((a, b) => moment.utc(b.date.value)?.diff(a.date.value)) };
	//       }
	//       return { claims: claims };
	//     }),
	//     take(1),
	//     map((res: any) => res?.claims),
	//   );
	// }

	getPolicyHistory() {
		return this.store.select(getSelectedItemOne).pipe(
			skipWhile(x => !x),
			take(1),
			switchMap(itemOne => {
				if (itemOne) {
					return this.policyHistoryLookup({
						claim_id: itemOne?.id,
						search: this.bf.bigForm?.value?.policy_number
					}).pipe(
						pluck('payload', 'claims'),
						map(claims => {
							if (claims) {
								return (claims as any[]).map(claim => {
									const obj = {
										date: { title: 'Loss Date', value: this.formatDate(claim.LOSSDATE) },
										eventType: { title: 'Claim Type', value: claim?.CLAIMTYPE },
										additionalItems: [
											{ title: 'Status', value: claim?.STATUS },
											{ title: 'Claim Cost', value: `R${claim.CLAIMCOST}` }
										],
										description: { title: 'Comment', value: claim?.COMMENTS }
									};
									return obj;
								});
							}
							return claims;
						}),
						map((claims: any[]) => {
							if (claims) {
								return { claims: claims.sort((a, b) => moment.utc(b.date.value)?.diff(a.date.value)) };
							}
							return { claims: claims };
						}),
						take(1)
					);
				} else {
					return empty();
				}
			}),
			map(res => res?.claims)
		);
	}

	formatDate(date: string): Date {
		return moment(date, 'MM-D-YYYY h mm ss a').toDate();
	}

	// TODO CLAIM SERVICES

	getCloseClaimInfoAPI(params: any): Observable<any> {
		return this.http.post(environment.api_url + `v1/claim_action/can_finalise_claim/`, params);
	}

	finaliseClaim(params: { claim_id: number }): Observable<any> {
		return this.http.post(environment.api_url + `v1/claim_action/finalise_claim/`, params);
	}

	policyHistoryLookup(data: { claim_id: string; search: string }) {
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim_history/`, {
			search: data?.search || '',
			claim_id: data?.claim_id
		});
	}

	searchClaims(params: { search: string; active: boolean }) {
		return this.http.post<any>(environment.api_url + `v1/claim_action/search_claims/`, { ...params });
	}

	checkCatastrophe(param: { claim_type: number; postal_code: number; loss_date: string }) {
		return this.http.post(environment.api_url + `v1/cat_action/check_catastrophe/`, param);
	}

	getExcessDetailsService(data: { claim_id: number }) {
		return this.http.post<any>(`${environment.api_url}v1/claim_action/get_excess_details/`, data);
	}

	// INTERNAL ASSESSOR SERVICES

	isInternalAssessorState(state: number) {
		return this.internalAssessorJobStates.includes(state);
	}

	getinternalAssessorJobCard(jobs: any[]) {
		return jobs.find(j => [89, 90, 28, 88, 37, 97]?.includes(j.state));
	}

	internalAssessorJobHasReport(state: number) {
		return state === 97; // internal_assessor_job_report_uploaded;
	}

	getAssessorDetails() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo =>
				this.http.post<any>(`${environment.api_url}v1/job_action/get_assessor_details/`, {
					job_id: itemTwo?.id
				})
			)
		);
	}

	getAssessorDetailsformatted() {
		return this.getAssessorDetails().pipe(
			map(assessorDetails => ({
				accessor_details: {
					'Full Name': assessorDetails.payload.full_name,
					Email: assessorDetails.payload.email_address,
					'Contact Number': assessorDetails.payload.contact_number
				}
			}))
		);
	}

	getClaimDetailsFormatted() {
		return this.store.select(getFullItemOne).pipe(
			skipWhile(x => !x),
			take(1),
			map(fullItemOne => ({
				claim_details: {
					'Claim Type': fullItemOne.loan_information.claimtype,
					'Date Of Loss': fullItemOne.loan_information.dateofloss,
					"Assessor's Appointment Reason": fullItemOne.loan_information.ia_requested_reason
				}
			}))
		);
	}

	getAllFilesApi(jobId?: number, claimId?: number, purpose?: string) {
		const data: any = {};
		if (jobId) {
			data.job_id = jobId;
		}

		if (claimId) {
			data.claim_id = claimId;
		}

		if (purpose) {
			data.purpose = purpose;
		}

		return this.http.post<any>(environment.api_url + 'v1/file_action/get_files', data);
	}

	addAssessmentNoteApi() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo =>
				this.http.post<any>(environment.api_url + 'v1/job_action/add_assessment_note', {
					job_id: itemTwo?.id,
					note: this.bf.bigForm.get('ia_message')?.value
				})
			)
		);
	}

	getjobAssessmentNotesApi(job_id: number) {
		const data = { job_id, job_only: true };
		return this.http.post<any>(environment.api_url + 'v1/job_action/get_assessment_notes', data);
	}

	getAssessmentNotesForAllJobsApi(job_id: number) {
		const data = { job_id, job_only: false };
		return this.http.post<any>(environment.api_url + 'v1/job_action/get_assessment_notes', data);
	}

	getJobNotesApi(job_id: number) {
		const data = { job_id };
		return this.http.post<any>(environment.api_url + 'v1/job_action/get_notes', data);
	}

	getAuthorizers() {
		return this.http.get(environment.api_url + 'v1/staff_action/get_authorizers').pipe(map(response => response['payload']));
	}

	// ----------------------------- AVS and Branch look up services -----------------------------
	getAvsCheckBS(avsData: any) {
		return this.http.post<any>(`${environment.api_url}v1/claim_action/check_account_details/`, avsData);
	}

	getBranchLookupResults(params: { bank: string; branch: string }) {
		return this.http.post<any>(`${environment.api_url}v1/claim_action/get_branch_code/`, { ...params });
	}

	getSPReplyListData() {
		return forkJoin([
			this.store.select(getSelectedItemTwo).pipe(
				skipWhile(x => !x),
				take(1)
			),
			this.store.select(getCurrentUser).pipe(
				skipWhile(x => !x),
				take(1)
			),
			this.store.select(getAllInfo).pipe(
				take(1),
				map((allInfo: any) => allInfo.sps),
				map((sps: any[]) => sps?.reduce((acc, sp: any) => ({ ...acc, [sp.id]: sp }), {}))
			),
			this.store.select(getSelectedItemTwo).pipe(
				skipWhile(x => !x),
				pluck('id'),
				take(1),
				switchMap(jobId =>
					this.http.post(`${environment.api_url}v1/job_action/get_interested_parties/`, {
						job_id: jobId
					})
				),
				map((res: any) => res?.payload)
			)
		]).pipe(
			map(([job, user, sps, parties]: any) => {
				if (!!job && !!user && !!sps && !!parties) {
					const isTeamLeader = user?.user?.is_team_leader === true;
					const templateTitle = `The Currently assigned SP is : ${job.sp ? sps[job.sp]?.name : 'none'}`;
					const listConfig = { alwaysEmitEvent: true };

					const entityCardState = {
						// info: ' entity-card--info',
						// warning: 'entity-card--warning',
						'-1': { state: 'entity-card--danger', blurb: 'Declined' },
						1: { state: 'entity-card--success', blurb: 'Accepted' },
						null: { state: 'entity-card--default', blurb: 'No-Reply' },
						0: { state: 'entity-card--default', blurb: 'Ignored' },
						currentSP: { state: 'entity-card--info', blurb: 'Current SP' }
					};
					const responseOrderWeightMap = {
						'No-Reply': 3,
						Accepted: 2,
						Ignored: 4,
						Declined: 5,
						'Current SP': 0
					};

					const data = parties
						.map((party: any) => {
							let lookupValue = party.new_interested;
							lookupValue = job && job?.sp === party.sp.id ? 'currentSP' : lookupValue;

							const cardStateInfo = entityCardState[lookupValue];
							return {
								entityId: party.sp.id,
								cardStateInfo,
								columns: [
									// col1 ~ sp details
									{
										title: party.sp.name,
										subTitle: party.sp.contact_person,
										description: party.sp.contact_primary
									},
									// col2 ~ need to know
									{
										title: cardStateInfo.blurb,
										description: `Number of assignments: ${party.awarded_today}`
									}
								]
							};
						})
						.sort((a, b) => responseOrderWeightMap[a.cardStateInfo.blurb] - responseOrderWeightMap[b.cardStateInfo.blurb]);
					return {
						isTeamLeader,
						templateTitle,
						listConfig,
						cardData: data || [],
						loadingMessage: 'loading',
						noCardsMessage: 'There are no records to display'
					};
				}
				return {
					templateTitle: '',
					cardData: []
				};
			})
		);
	}

	retryOnMaven() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				return this.http.post(`${environment.api_url}v1/job_action/retry_upload_invoice_to_maven/`, { job_id: itemTwo?.id }).pipe(
					filter(x => !!x),
					take(1)
				);
			})
		);
	}

	getCallLogs() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				return this.http.post<any>(`${environment.api_url}v1/claim_action/get_call_logs/`, { claim_id: itemOne?.id }).pipe(
					filter(x => !!x),
					map((data: any) => {
						const arr = [];
						data?.payload.forEach(log => {
							const formattedLog = transformCallLog(log);
							arr.push(formattedLog);
						});

						return arr;
					})
				);
			})
		);
	}

	addCallLog() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				const data = {
					claim_id: itemOne?.id,
					message: this.bf.bigForm.get('message')?.value,
					channel: this.bf.bigForm.get('channel')?.value,
					reason: this.bf.bigForm.get('reason')?.value,
					direction: this.bf.bigForm.get('direction')?.value
				};
				return this.http.post(`${environment.api_url}v1/claim_action/add_call_log/`, { ...data }).pipe(
					switchMap((x: any) => {
						if (x.success) {
							const addedLog = transformCallLog(x.payload);

							this.bf.bigForm.patchValue({
								message: '',
								channel: null,
								reason: null,
								direction: null
							});

							return this.store.select(getData).pipe(
								map((contextData: any) => [...[addedLog], ...contextData.logs]),
								take(1)
							);
						}
					})
				);
			})
		);
	}

	getClaimNotes() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				return this.getClaimNotesApi(itemOne.id);
			})
		);
	}

	getClaimNotesApi(claim_id: number) {
		const data = { claim_id };
		return this.http.post<any>(environment.api_url + 'v1/claim_action/get_notes', data).pipe(
			filter(x => !!x),
			map((notes: any) => {
				const arr = [];
				notes.payload.forEach(note => {
					note.date = moment(note['timestamp']).format(moment.HTML5_FMT.DATE);
					note.time = moment(note['timestamp']).format(moment.HTML5_FMT.TIME);
					arr.push(note);
				});
				return arr;
			})
		);
	}

	addJobNote() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				const data = {
					job_id: itemTwo?.id,
					message: this.bf.bigForm.get('message')?.value
				};
				return this.http.post(`${environment.api_url}v1/job_action/add_note/`, { ...data }).pipe(map(response => response['payload']));
			})
		);
	}

	addClaimNote() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				const data = {
					claim_id: itemOne?.id,
					message: this.bf.bigForm.get('message')?.value
				};

				return this.http.post(`${environment.api_url}v1/claim_action/add_note/`, { ...data }).pipe(map(response => response['payload']));
			})
		);
	}

	updateJobNewState() {
		return forkJoin([
			this.getSelectedItemTwo().pipe(
				pluck('id'),
				skipWhile(x => !x),
				take(1)
			),
			this.store.select(getSubmissionData).pipe(
				pluck('new_state'),
				skipWhile(x => !x),
				take(1)
			)
		]).pipe(
			switchMap(([job_id, new_state]) => {
				return this.jobService.updateJob({ job_id: job_id, new_state: new_state });
			})
		);
	}

	assignAssessor() {
		return forkJoin([
			this.getSelectedItemTwo().pipe(
				pluck('id'),
				skipWhile(x => !x),
				take(1)
			),
			this.store.select(getSubmissionData).pipe(
				pluck('assign_assessor_id'),
				skipWhile(x => !x),
				take(1)
			)
		]).pipe(
			switchMap(([jobId, assessorId]) => {
				return this.jobService.assignAssessor({ job_id: jobId, assessor_id: assessorId });
			})
		);
	}

	AssessorReportUploadedUpdateJob() {
		return this.store.select(getFullItemTwo).pipe(
			skipWhile(x => !x),
			take(1),
			switchMap((job: any) => {
				let { job_information } = job;
				let newState, return_to_state;

				switch (job.state) {
					case 89:
						newState = 97;
						break;
					case 90:
					case 32:
					case 106:
						newState = job?.state;
						return_to_state = 97;
						break;
					default:
						newState = job?.state;
						break;
				}

				const data = {
					job_id: job?.id,
					current_state: job?.state,
					new_state: newState
				};

				let toReturn;
				if (return_to_state) {
					if (job_information) {
						inlineRecursiveMerge(job_information, { return_to_state });
					} else {
						job_information = { return_to_state };
					}

					toReturn = { job_information, ...data };
				} else {
					toReturn = data;
				}
				return this.jobService.updateJob({ ...toReturn, upload_report: this.bf.bigForm.value.uploadReport });
			})
		);
	}

	changeJobAppointmentFromContextMenu() {
		const appointment = this.bf.bigForm.get('appointmentData')?.value;
		let range_start: moment.Moment;
		let to_time: moment.Moment;

    const date = moment(appointment.appointmentDatePicker);

		if (appointment.appointmentTime === 3) {
			range_start = date.set({
				hour: appointment.from_time.hour,
				minute: appointment.from_time.minutes
			});

			to_time = date.set({
				hour: appointment.to_time.hour,
				minute: appointment.to_time.minutes
			});
		} else {
			range_start = date.set({
				hour: appointment.appointmentTimePicker.hour,
				minute: appointment.appointmentTimePicker.minutes
			});
		}

    console.log('range_start', range_start);

		const appointment_formatted: any = {
			range_start: range_start.format('YYYY-MM-DDTHH:mm:ss'),
			appointment_type: appointment.appointmentTime,
			reason: ''
		};

		if (to_time) {
			appointment_formatted.from_time = range_start.format('YYYY-MM-DDTHH:mm:ss');
			appointment_formatted.to_time = to_time.format('YYYY-MM-DDTHH:mm:ss');
		}

		return forkJoin([this.store.pipe(take(1)), this.store.select(getFullItemTwo).pipe(take(1))]).pipe(
			switchMap(([store, fullItemTwo]) => {
				const office_use = {
					...fullItemTwo.office_use,
					appointment_type: appointment.appointmentTime ? getAllInfoIndex('appointment_types', 'id', 'name', appointment.appointmentTime, store) : null,
					appointment_type_id: appointment.appointmentTime,
					appointmentdatetype: appointment.appointmentDateType,
					requestedappointmentdate: moment(appointment_formatted.range_start).format('YYYY-MM-DD'),
					requestedappointmenttime: moment(appointment_formatted.range_start).format('HH:mm')
				};

				return this.jobService
					.changeAppointment({
						appointment: appointment_formatted,
						job_id: fullItemTwo.id,
						job_information: { ...fullItemTwo.job_information, return_to_state: fullItemTwo.state },
						office_use
					})
					.pipe(take(1));
			})
		);
	}

	recommendCil() {
		return this.store
			.select(getSubmissionData)
			.pipe(take(1))
			.pipe(
				switchMap(submissionData => {
					const { cil_state, effected_jobs, job_id } = submissionData;
					const data = { cil_state, effected_jobs, job_id };
					return this.http.post<any>(environment.api_url + 'v1/job_action/move_to_cil/', data);
				})
			);
	}

	getClaimsHandlers() {
		return this.http.get(environment.api_url + 'v1/staff_action/get_claim_handler', { params: { roles: '1' } }).pipe(
			take(1),
			pluck('payload'),
			map((arr: any) => arr.filter(x => x.authorised)),
			map((obj: { [key: string]: { id: number; full_name: string; is_team_leader: boolean } }) =>
				Object.values(obj).map(entry => ({
					display: entry?.full_name,
					value: entry?.id,
					is_team_leader: entry?.is_team_leader
				}))
			)
		);
	}

	changeClaimHandler() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(submissionData =>
				this.http.post<any>(environment.api_url + 'v1/claim_action/change_claim_handler/', {
					claim_id: submissionData.claim_id,
					reason: submissionData.reason,
					staff_id: submissionData.staff_id
				})
			)
		);
	}

	///////////// NEW SERVICE CALLS ///////////////
	generateBOQ() {
		const quoteNumber = this.bf.bigForm.get('invoiceNumber')?.value;
		const note = this.bf.bigForm.get('invoiceNotes')?.value;
		const vat_rate = this.bf.bigForm.get('vatPercentage')?.value;
		return this.store
			.select(getFullItemTwo)
			.pipe(take(1))
			.pipe(
				switchMap(job => {
					return this.http.get<any>(environment.api_url + `v2/boq_action/generate_boq_quote?job_id=${job.id}&quote_number=${quoteNumber}&notes=${note}&vat_rate=${vat_rate}`).pipe(
						switchMap(response => {
							const currentState = +this.bf.bigForm.get('workflow_current_state').value;
							return this.jobService.updateJob({ job_id: job?.id, job_information: job?.job_information, new_state: currentState }); // currentState === 97 ? 210 : currentState
						})
					);
				})
			);
	}

	createOrUpdateBOQ() {
		// this.loaderService.setLoading(true);
		const hasBoq = this.bf.bigForm.get('hasBoq')?.value;
		const newVals = [];
		this.bf.bigForm.get('actualLineItemsFormArray')?.value?.forEach(lineItem => {
			let newObj = { ...lineItem, id: lineItem.item?.id };
			if (lineItem.item_id === undefined || lineItem.item_id === null) {
				newObj = { ...newObj, item_id: lineItem.item?.id };
			}
			newVals.push(newObj);
		});
		this.bf.bigForm.get('actualLineItemsFormArray')?.patchValue(newVals, { onlySelf: true, emitEvent: false });

		return this.getSelectedItemTwo().pipe(
			switchMap(job => {
				if (hasBoq) {
					const updatePromise = this.updateBOQ(job.id).toPromise();
					return updatePromise.then();
				}
				return this.createBOQ(job.id);
			})
			// tap(() => this.loaderService.setLoading(false))
		);
	}

	updateBOQ(job_id: number): Observable<any> {
		const postData = { job_id, items: this.bf.bigForm.get('actualLineItemsFormArray')?.value };
		return this.http.post(environment.api_url + 'v2/boq_action/update_boq', postData);
	}

	createBOQ(job_id: number): Observable<any> {
		const postData = { job_id, items: this.bf.bigForm.get('actualLineItemsFormArray')?.value };
		return this.http.post(environment.api_url + 'v2/boq_action/create_boq', postData);
	}

	generateBoqQuoteOrInvoice() {
		// this.loaderService.setLoading(true);
		const callOutStates = [25, 65, 66, 67, 69, 80, 94, 105, 171, 206, 207];
		const invoiceOnlyStates = [26, 46, 47];
		const date = this.bf.bigForm.get('invoiceDate')?.value;
		const invoiceNumber = this.bf.bigForm.get('invoiceNumber')?.value;
		const notes = this.bf.bigForm.get('invoiceNotes')?.value;
		const vatPercentage = this.bf.bigForm.get('vatPercentage')?.value;
		this.bf.addControl('job_quoted', new UntypedFormControl(1, [Validators.required]));

		return combineLatest([this.getSelectedItemTwo(), this.store.select(getBOQ)]).pipe(
			skipWhile(([job, boq]) => !job || !boq),
			take(1),
			switchMap(([job, boq]) => {
				const hasQuotation = !!boq?.category?.includes('quotation');
				const isInvoiceOnlyState = callOutStates.indexOf(job.state) !== -1 || invoiceOnlyStates.indexOf(job.state) !== -1;

				if (isInvoiceOnlyState) {
					if (!hasQuotation) {
						return this.generateBOQQuote(job.id, invoiceNumber, notes, vatPercentage).pipe(
							take(1),
							switchMap(_ => {
								return this.postInvoice(Number(job.id), invoiceNumber, date).pipe(switchMap(() => this.updateJob()));
							})
						);
					}

					return this.postInvoice(Number(job.id), invoiceNumber, date).pipe(switchMap(() => this.updateJob()));
				}

				return this.generateBOQQuote(job.id, invoiceNumber, notes, vatPercentage).pipe(switchMap(() => this.updateJob()));
			})
			// tap(() => this.loaderService.setLoading(false))
		);
	}

	public generateBOQQuote(jobId: number, quoteNumber: string, note: string, vat_rate: number, draft = false): Observable<any> {
		let params = new HttpParams()
			.set('job_id', jobId.toString())
			.set('quote_number', quoteNumber)
			.set('notes', note)
			.set('vat_rate', vat_rate.toString())
			.set('draft', draft.toString());
		return this.http.get(environment.api_url + `v2/invoicing_action/generate_quote`, { params });
	}

	postInvoice(job_id: number, invoice_number: string, invoice_date: any): Observable<any> {
		return this.http.post(environment.api_url + `v2/invoicing_action/generate_invoice`, {
			job_id: job_id,
			invoice_number: invoice_number,
			invoice_date: invoice_date
		});
	}
}
