import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import gql from 'graphql-tag';
import moment from 'moment';
import { map, skipWhile, take } from 'rxjs/operators';

export const MUL_227: Flow_0_0_2 = {
	id: '227',
	name: 'Set Appointment',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo) {
						return `Set appointment : ${itemTwo?.claim?.loan_information?.mavenclaimnumber}  -  ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Set appointment';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Set appointment on the job'
		},
		viewRoles: {
			0: 'Awaiting appointment on the job'
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	startNode: 'SetAppointment',
	nodes: {
		SetAppointment: {
			component: 'FLXJobAppointmentComponent',
			inputs: {
				minDate: new Date(),
				minHour: 0,
				maxHour: 23,
				checkAfterHours: true,
				afterHoursEndpoint: 'v1/claim_action/is_after_hours'
			},
			checkValidityForFields: ['appointmentData'],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(null),
						skillcatagory: new UntypedFormControl(null)
					})
				);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skillcatagory
										skill
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile((x: any) => !x || !x.skill || !x.skillcatagory),
						take(1)
					)
					.subscribe(({ skill, skillcatagory }) => {
						const apd = bf.getControl('appointmentData');
						if (apd) {
							apd.get('skill')?.setValue(skill);
							apd.get('skillcatagory')?.setValue(skillcatagory);
						}
					});
				bf.addControl('new_state', new UntypedFormControl(77));
			},
			serverCalls: {
				claimaintKeyValues: {
					errorMessage: 'Claimant detail could not be retrieved',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile((x: any) => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map((job: any) => {
									const claimantinfo = {
										'Customer Details ': {
											'Full name ': `${job.claim?.applicant?.first_name} ` + `${job.claim?.applicant?.surname}`,
											'Contact Number': job?.claim?.loan_information.ContactNumber,
											'Mobile Number ': job?.claim?.loan_information.cellnumber,
											'On-site contact name ': job?.claim?.loan_information.onsiteperson
										}
									};
									return [claimantinfo];
								})
							);
					}
				},
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile((f: any) => !f || !f.state),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						repingSP: {
							errorMessage: 'An error occurred while changing the job appointment',
							serviceVariable: 'mulService',
							functionName: 'setJobAppointment'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					const appointment_formatted = {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
					return appointment_formatted;
				}
			},
			'appointment'
		],
		new_state: 'new_state'
	}
};
