import { createFeatureSelector, createSelector } from '@ngrx/store';
import { iBilling, iState } from './billing.reducer';

const billingState = createFeatureSelector<iState>('billing');

export const getBillingData = createSelector(billingState, (state: iState) => state && state);

export const getAvailableTemplates = createSelector(billingState, (state: iState) => state && state.availableTemplates);

export const getSelectedContext = createSelector(billingState, (state: iState) => state && state.selectedContext);

export const getCustomTypesList = createSelector(getSelectedContext, (state: iBilling) => state && state.customTypesList);

export const getBOQ = createSelector(getSelectedContext, (state: iBilling) => state && state.boq);

export const getExcess = createSelector(getSelectedContext, (state: iBilling) => state && state.excess);

export const getCustomYearsList = createSelector(getSelectedContext, (state: iBilling) => state && state.customYearsList);

export const getInvoice = createSelector(getSelectedContext, (state: iBilling) => state && state.invoice);

export const getAvailableLineItems = createSelector(getSelectedContext, (state: iBilling) => state && state.lineItems);

export const getSkillCategories = createSelector(getSelectedContext, (state: iBilling) => state && state.skillCategories);

export const getSkillSubCategories = createSelector(getSelectedContext, (state: iBilling) => state && state.skillSubCategories);

export const getSP = createSelector(getSelectedContext, (state: iBilling) => state && state.sp);

export const getUnitsOfMeasurement = createSelector(getSelectedContext, (state: iBilling) => state && state.unitsOfMeasurement);

export const getBanks = createSelector(getSelectedContext, (state: iBilling) => state && state.banks);

export const getTravelDetails = createSelector(getSelectedContext, (state: iBilling) => state && state.travelDetails);

export const getExcessChangeDetails = createSelector(getSelectedContext, (state: iBilling) => state && state.excessChangeDetails);
