<div class="line-item-modify" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
	<div class="line-item-modify__item line-item-modify__item--flex-grow">
		<div
			class="line-item-modify__item__active-zone line-item-modify__item__active-zone--description line-item-modify__item__active-zone--left"
			[ngClass]="{ hover: isItemHover || isItemActive }"
		>
			<div id="search_" (click)="openDropdown()" class="line-item-modify__item__active-zone--description__label input" [ngClass]="'text-info'">
				{{ 'Search billable items...' }}
			</div>
			<div class="line-item-modify__item__active-zone__icon"><img *ngFor="let icon of icons" src="{{ icon?.src }}" height="20px" data-title="{{ icon?.alt }}" /></div>
			<div *ngIf="showDropDown" class="active-zone__search-drop-down" clickOutside (clickOutside)="showDropDown = false; isItemActive = false">
				<input class="input--active" autocomplete="off" placeholder="Type to filter" [formControl]="searchField" tabindex="-1" />
				<div class="active-zone__search-drop-down__result">
					<div
						class="active-zone__search-drop-down__result__item"
						(click)="addToActualLineItems(item)"
						*ngFor="let item of availableLineItems | searchFilter: searchField.value:'name'"
						tabindex="-1"
					>
						<span>{{ item.name }}</span>
						<div tabindex="-1" class="active-zone__search-drop-down__result__item__icons">
							<flx-icon
								class="active-zone__search-drop-down__result__item__icon__item"
								size="small"
								[type]="'edit'"
								(click)="updateCustomItem($event, item)"
								*ngIf="item.is_custom_items"
								tabindex="-1"
							></flx-icon>
							<img
								class="active-zone__search-drop-down__result__item__icon__item"
								*ngFor="let icon of item.icons"
								src="{{ icon?.src }}"
								height="20px"
								data-title="{{ icon?.alt }}"
							/>
						</div>
					</div>
				</div>
				<div class="active-zone__search-drop-down__create-new" *ngIf="allow_custom_items">
					<flx-button tabindex="-1" (click)="createCustomItem($event)" color="alert">Create Custom Item</flx-button>
				</div>
			</div>
		</div>
	</div>
	<div class="line-item-modify__item">
		<div class="line-item-modify__item__active-zone" [ngClass]="{ hover: isItemHover || isItemActive }"></div>
	</div>
	<div class="line-item-modify__item">
		<div class="line-item-modify__item__active-zone" [ngClass]="{ hover: isItemHover || isItemActive }"></div>
	</div>
	<div class="line-item-modify__item">
		<div class="line-item-modify__item__active-zone line-item-modify__item__active-zone--right" [ngClass]="{ hover: isItemHover || isItemActive }"></div>
	</div>
</div>
