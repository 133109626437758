import { DELETE_LOCAL_DRAFT, SET_VIEW, SET_VIEW_FAIL, SET_VIEW_SUCCESS } from '../manifest';
import {
	MAKE_SERVER_CALL_WITH_LOADER,
	SELECT_ITEM_ONE_SUCCESS,
	SELECT_ITEM_TWO_SUCCESS,
	SELECT_ITEM_ONE,
	SELECT_ITEM_TWO,
	GET_FULL_ITEM_ONE_SUCCESS,
	GET_FULL_ITEM_TWO_SUCCESS,
	MAKE_SERVER_CALL_SUCCESS
} from '../selected-context/selected-context.actions';
import { STOP_LOADER, STOP_LOADING } from './global.action';

enum FilterActionTypes {
	SET_RANDOM_FIELD = '[FILTER] SET_RANDOM_FIELD',
	SET_FILTER_SET = '[FILTER] SET_FILTER_SET',
	RESET_FILTERS = '[FILTER] RESET_FILTERS',
	RESET_STATE = '[FILTER] RESET_STATE',
	SEARCH_ON_SERVER = '[] SEARCH_ON_SERVER',
	SEARCH_ON_SERVER_SUCCESS = '[] SEARCH_ON_SERVER_SUCCESS',
	SEARCH_ON_SERVER_FAIL = '[] SEARCH_ON_SERVER_FAIL',
	RESET_SERVER_SEARCH = '[] RESET SERVER SEARCH'
}

export interface GlobalState {
	loading: boolean;
	loaded: boolean;
	loadingIDs: string[];
	loadedIDs: string[];
}

const initialState: GlobalState = {
	loading: false,
	loaded: false,
	loadingIDs: [],
	loadedIDs: []
};

export function globalReducer(state = initialState, action) {
	switch (action.type) {
		case SELECT_ITEM_ONE:
		case SELECT_ITEM_TWO:
		case MAKE_SERVER_CALL_WITH_LOADER:
		case '[BILLING] MAKE SERVER CALL WITH LOADER':
		case DELETE_LOCAL_DRAFT:
		case FilterActionTypes.SEARCH_ON_SERVER:
		case SET_VIEW: {
			const loaderID = action?.payload?.loaderID;
			if (action?.payload && loaderID && !state?.loadingIDs.includes(loaderID)) {
				const loadingIDs = [...state?.loadingIDs, loaderID];
				let loadedIDs = [...state.loadedIDs];

				if (loadedIDs.includes(loaderID)) {
					loadedIDs = loadedIDs.filter(ld => ld !== loaderID);
				}
				return {
					...state,
					loadingIDs,
					loadedIDs
				};
			}
			return {
				...state,
				loading: true,
				loaded: false
			};
		}
		case SELECT_ITEM_ONE_SUCCESS:
		case SELECT_ITEM_TWO_SUCCESS:
		case MAKE_SERVER_CALL_SUCCESS:
		case '[BILLING] MAKE SERVER CALL SUCCESS':
		case STOP_LOADING:
		case STOP_LOADER:
		case SET_VIEW_SUCCESS:
		case GET_FULL_ITEM_ONE_SUCCESS:
		case GET_FULL_ITEM_TWO_SUCCESS:
		case FilterActionTypes.SEARCH_ON_SERVER_SUCCESS: {
			const loaderID = action?.payload?.loaderID;
			if (action.payload && loaderID && !state?.loadedIDs.includes(loaderID)) {
				let loadingIDs = [...state?.loadingIDs];
				const loadedIDs = [...state?.loadedIDs, loaderID];

				if (loadingIDs.includes(loaderID)) {
					loadingIDs = loadingIDs.filter(ld => ld !== loaderID);
				}
				return {
					...state,
					loadingIDs,
					loadedIDs
				};
			}
			return {
				...state,
				loading: false,
				loaded: true
			};
		}
		case SET_VIEW_FAIL:
		case FilterActionTypes.SEARCH_ON_SERVER_FAIL: {
			// case MAKE_SERVER_CALL_FAIL:
			const loaderID = action.payload?.loaderID;
			if (action.payload && loaderID) {
				let loadingIDs = [...state?.loadingIDs];
				let loadedIDs = [...state?.loadedIDs];

				if (loadingIDs.includes(loaderID)) {
					loadingIDs = loadingIDs.filter(ld => ld !== loaderID);
				}

				if (loadedIDs.includes(loaderID)) {
					loadedIDs = loadedIDs.filter(ld => ld !== loaderID);
				}
				return {
					...state,
					loadingIDs,
					loadedIDs
				};
			}
			return {
				...state,
				loading: false,
				loaded: false
			};
		}
		case 'GO_TO_AZURE_AD_PAGE':
		case 'LOGIN': {
			return {
				...state,
				loading: true,
				loaded: false
			};
		}
		case 'LOGIN_FAIL':
		case 'LOGOUT_SUCCESS': {
			return initialState;
		}
		default:
			return state;
	}
}
