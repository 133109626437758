import { Injectable, } from '@angular/core';

// import { WebSocketService } from '../../services/web-socket.service';

@Injectable()
export class OfflineEffects {


	// @Effect({ dispatch: false })
	// saveToOfflineStore$ = this.actions$.pipe(
	//   map((action: any) => {
	//     if (window.navigator.onLine) {
	//       this.store.pipe(take(1)).subscribe(store => {
	//         if (action.payload && this.indexedDbService.isOpen()) {
	//           // this.indexedDbService.data_store.put(store, 'store');
	//           this.cryptoService.encryptStateStore(this.CryptoBlobKey, store);
	//         }
	//       });
	//     }
	//   })
	// );
	// @Effect({ dispatch: false })
	// saveToOfflineStore$ = this.actions$.pipe(
	//   ofType('SET VIEW SUCCESS', '[All Info] Get All Info Success'),
	//   map((action: any) => {
	//     if (window.navigator.onLine) {
	//       this.store.pipe(take(1)).subscribe((store) => {
	//         const storeClone = clone(store);
	//         if (action.type === 'SET VIEW SUCCESS') {
	//           action.payload.localDrafts = [];
	//           console.log({ claimSuccess: action.payload }, storeClone);
	//           // const onlyServerClaims = storeClone.outlet.workflowList && storeClone.outlet.workflowList.entities.filter((claim) => !!claim.id);
	//           //uncomment storeClone.outlet.workflowList.entities = onlyServerClaims;
	//         }
	//         if (action.payload) {
	//           // console.log({ payload: action.payload });
	//           // console.log({ storeClone });
	//           this.cryptoService.encryptStateStore(this.CryptoBlobKey, storeClone);
	//           // this.indexedDbService.data_store.put({ id: 'store', ...store }).then(() => {
	//           // });
	//         }
	//       });
	//     }
	//   }),
	// );

	// @Effect({ dispatch: false })
	// saveToOfflineStore2$ = this.actions$.pipe(
	//   switchMap((action) => {
	//     return this.networkService.isOnline.pipe(map((online) => [action, online]));
	//   }),
	//   map(([action, online]: any) => {
	//     if (!online) {
	//       this.store.pipe(take(1)).subscribe((store) => {
	//         const storeClone = clone(store);
	//         if (action.type === 'SET VIEW SUCCESS') {
	//           action.payload.localDrafts = [];
	//           console.log({ claimSuccess: action.payload }, storeClone);
	//           // const onlyServerClaims = storeClone.outlet.workflowList && storeClone.outlet.workflowList.entities.filter((claim) => !!claim.id);
	//           //uncomment storeClone.outlet.workflowList.entities = onlyServerClaims;
	//         }
	//         if (action.payload) {
	//           // console.log({ payload: action.payload });
	//           this.cryptoService.encryptStateStore(this.CryptoBlobKey, storeClone);
	//           // this.indexedDbService.data_store.put({ id: 'store', ...store }).then(() => {
	//           // });
	//         }
	//         console.log({ storeClone, action });
	//       });
	//     }
	//   }),
	// );
}
