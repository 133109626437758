export interface BankBranchData {
	branch_name?: string;
	bank_name?: string;
	branch_code?: string;
}

export interface BranchLookupData {
	branch: string;
	bank: string;
}

export enum AVSResultFlag {
	not_tested = 2,
	success = 1,
	do_later = 0,
	fail = -1,
	fail_force_payment = -2
}
