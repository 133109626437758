import {
	Component,
	ComponentRef,
	ElementRef,
	EmbeddedViewRef,
	EventEmitter,
	Inject,
	Optional,
	ChangeDetectorRef,
	ViewChild,
	ViewEncapsulation,
	ChangeDetectionStrategy
} from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { AnimationEvent } from '@angular/animations';

import { BasePortalOutlet, ComponentPortal, CdkPortalOutlet, TemplatePortal } from '@angular/cdk/portal';

import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';

import { fsDialogAnimations } from '../helpers/dialog-animations';

import { DialogConfig } from '../helpers/dialog-config';

export function throwFLXDialogContentAlreadyAttachedError() {
	throw Error('Attempting to attach dialog content after content is already attached');
}

@Component({
	// moduleId: module.id,
	selector: 'flx-dialog-container',
	templateUrl: './dialog-container.html',
	styleUrls: ['../styles/dialog.scss'],
	encapsulation: ViewEncapsulation.None,
	preserveWhitespaces: false,
	// Using OnPush for dialogs caused some G3 sync issues. Disabled until we can track them down.
	// tslint:disable-next-line:validate-decorators
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [fsDialogAnimations.slideDialog],
	host: {
		class: 'flx-dialog-container',
		tabindex: '-1',
		'[attr.id]': '_id',
		'[attr.role]': '_config?.role',
		'[attr.aria-labelledby]': '_config?.ariaLabel ? null : _ariaLabelledBy',
		'[attr.aria-label]': '_config?.ariaLabel',
		'[attr.aria-describedby]': '_config?.ariaDescribedBy || null',
		'[@slideDialog]': '_state',
		'(@slideDialog.start)': '_onAnimationStart($event)',
		'(@slideDialog.done)': '_onAnimationDone($event)'
	}
})
export class FLXDialogContainerComponent extends BasePortalOutlet {
	/** The portal outlet inside of this container into which the dialog content will be loaded. */
	@ViewChild(CdkPortalOutlet, { static: true }) _portalOutlet: CdkPortalOutlet;

	/** The class that traps and manages focus within the dialog. */
	private _focusTrap: FocusTrap;

	/** Element that was focused before the dialog was opened. Save this to restore upon close. */
	private _elementFocusedBeforeDialogWasOpened: HTMLElement | null = null;

	/** The dialog configuration. */
	_config: DialogConfig;

	/** State of the dialog animation. */
	_state: 'void' | 'enter' | 'exit' = 'enter';

	/** Emits when an animation state changes. */
	_animationStateChanged = new EventEmitter<AnimationEvent>();

	/** ID of the element that should be considered as the dialog's label. */
	_ariaLabelledBy: string | null = null;

	/** ID for the container DOM element. */
	_id: string;

	constructor(
		private _elementRef: ElementRef,
		private _focusTrapFactory: FocusTrapFactory,
		private _changeDetectorRef: ChangeDetectorRef,
		@Optional()
		@Inject(DOCUMENT)
		private _document: any
	) {
		super();
	}

	/**
	 * Attach a ComponentPortal as content to this dialog container.
	 * @param portal Portal to be attached as the dialog content.
	 */
	attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
		if (this._portalOutlet.hasAttached()) {
			throwFLXDialogContentAlreadyAttachedError();
		}

		this._savePreviouslyFocusedElement();
		return this._portalOutlet.attachComponentPortal(portal);
	}

	/**
	 * Attach a TemplatePortal as content to this dialog container.
	 * @param portal Portal to be attached as the dialog content.
	 */
	attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
		if (this._portalOutlet.hasAttached()) {
			throwFLXDialogContentAlreadyAttachedError();
		}

		this._savePreviouslyFocusedElement();
		return this._portalOutlet.attachTemplatePortal(portal);
	}

	/** Moves the focus inside the focus trap. */
	private _trapFocus() {
		if (!this._focusTrap) {
			this._focusTrap = this._focusTrapFactory.create(this._elementRef.nativeElement);
		}

		if (this._config.autoFocus) {
			this._focusTrap.focusInitialElementWhenReady();
		}
	}

	/** Restores focus to the element that was focused before the dialog opened. */
	private _restoreFocus() {
		const toFocus = this._elementFocusedBeforeDialogWasOpened;

		// We need the extra check, because IE can set the `activeElement` to null in some cases.
		if (toFocus && typeof toFocus.focus === 'function') {
			toFocus.focus();
		}

		if (this._focusTrap) {
			this._focusTrap.destroy();
		}
	}

	/** Saves a reference to the element that was focused before the dialog was opened. */
	private _savePreviouslyFocusedElement() {
		if (this._document) {
			this._elementFocusedBeforeDialogWasOpened = this._document.activeElement as HTMLElement;

			// Move focus onto the dialog immediately in order to prevent the user from accidentally
			// opening multiple dialogs at the same time. Needs to be async, because the element
			// may not be focus-able immediately.
			Promise.resolve().then(() => this._elementRef.nativeElement.focus());
		}
	}

	/** Callback, invoked whenever an animation on the host completes. */
	_onAnimationDone(event: AnimationEvent) {
		if (event.toState === 'enter') {
			this._trapFocus();
		} else if (event.toState === 'exit') {
			this._restoreFocus();
		}

		this._animationStateChanged.emit(event);
	}

	/** Callback, invoked when an animation on the host starts. */
	_onAnimationStart(event: AnimationEvent) {
		this._animationStateChanged.emit(event);
	}

	/** Starts the dialog exit animation. */
	_startExitAnimation(): void {
		this._state = 'exit';

		this._changeDetectorRef.markForCheck();
	}
}
