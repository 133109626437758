import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIElementsModule } from '@flexus/ui-elements';
import { FLXUserCardViewComponent } from './user-card-view.component';

@NgModule({
	imports: [CommonModule, UIElementsModule],
	declarations: [FLXUserCardViewComponent],
	exports: [FLXUserCardViewComponent]
})
export class FLXUserCardViewModule {}
