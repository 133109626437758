import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';


@Component({
	// tslint:disable-next-line: component-selector
	selector: 'flx-alert-messages',
	templateUrl: './alert-messages.component.html',
	styleUrls: ['./alert-messages.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXAlertMessagesComponent {
	@Input() alertMessages$: Observable<any>;

	constructor() {}

}
