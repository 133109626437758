import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { BigFormService } from '../services/big-form.service';

@Injectable()
export class GlobalEffects {
	constructor(private actions$: Actions, private bf: BigFormService) {}

	// @Effect({ dispatch: false })
	// resetBigForm = this.actions$.pipe(
	//   ofType('ROUTER_NAVIGATED'),
	//   tap(() => this.bf.bigForm.reset()),
	// );
}
