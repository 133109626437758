<flx-heading type="page" size="medium">SP Details</flx-heading>
<flx-flex-container maxWidth="50vw" *ngIf="sp; else nospdetail">
	<flx-panel>
		<div class="spdetails">
			<span class="sp-name">{{ spname }}</span
			><br />
			<span class="sp-email" hoverClickCopy>{{ spemail }}</span
			><br />
			<span>{{ spcontact }}</span
			>&nbsp;&nbsp;&nbsp;&nbsp; <span>{{ spcontactnumber }}</span>
		</div>
	</flx-panel>
</flx-flex-container>
<ng-template #nospdetail> <flx-alert type="no-info" missingItem="SP details"></flx-alert> </ng-template>
