import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, MakeServerCall, NavButton_0_0_2, SetNextNode, getAllInfo, getCurrentUser, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { convertDateTimeToTimeStamp } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { EMPTY, forkJoin, of } from 'rxjs';
import { filter, map, pluck, skipWhile, switchMap, take, tap } from 'rxjs/operators';

const COMMON_NAVS: NavButton_0_0_2[] = [
	{
		text: 'Query',
		color: 'default',
		linkType: 'submit',
		serverCalls: {
			captureQuery: {
				errorMessage: '',
				directCall: (http, store, sq, bf, controller, modal, popup) => {
					popup.formControlContent = {
						0: {
							inputType: 'textarea',
							rows: 3,
							formControlName: 'ia_query'
						}
					};
					popup.showPopup();
					popup.onClosePopup$
						.pipe(
							filter(status => status),
							take(1)
						)
						.subscribe(res => {
							if (res) {
								controller.dispatch(new SetNextNode('SubmissionSuccess'));
							}
						});
					return of([]);
				}
			}
		}
	},
	{
		text: 'Recommend CIL',
		color: 'default',
		nextNode: 'RecommendCIL'
	},
	{
		text: 'Recommend Rejection',
		color: 'default',
		nextNode: 'RecommendRejection'
	},
	{
		text: 'Recommend Loss Adjuster',
		color: 'default',
		nextNode: 'RecommendLossAdjuster'
	},
	{
		text: 'Approve',
		color: 'default',
		linkType: 'submit',
		serverCalls: {
			approve: {
				errorMessage: '',
				directCall: (http, store, sq, bf, controller, modal) => {
					modal.openModalDirectly(inst => {
						inst.closeButton = true;
						inst.setMessage(['Are you sure you want to approve this job?']);
						inst.type = 'warning';
						inst.navButtons = [
							{ text: 'NO', linkType: 'close', color: 'alert' },
							{
								text: 'YES',
								linkType: 'close',
								color: 'alert',
								clickHandler: () => {
									store.dispatch(
										new MakeServerCall({
											serviceVariable: 'betService',
											errorMessage: `No data returned for InternalAssessorJob'`,
											nextNode: 'SubmissionSuccess',
											directCall: (http, store, sq, bf, controller) => {
												return forkJoin([
													store.select(getFullItemTwo).pipe(
														skipWhile(x => !x),
														take(1)
													),
													store.select(getCurrentUser).pipe(
														skipWhile(y => !y),
														take(1)
													)
												]).pipe(
													take(1),
													map(([job, user]: any) => {
														bf?.patchValues({
															job_id: job?.id,
															current_state: job?.state
														});

														const new_comments = [];
														const date = new Date();
														const newInfo = {
															message: 'approve',
															author_id: user?.user?.id,
															author_name: user?.user?.full_name,
															nextState: 86,
															timeStamp: convertDateTimeToTimeStamp(date.toDateString())
														};
														if (job?.job_information?.interstate_comments) {
															const interstate_comments = job?.job_information?.interstate_comments;

															Array.isArray(interstate_comments) ? new_comments.push(...interstate_comments, newInfo) : new_comments.push(interstate_comments, newInfo);
														} else {
															new_comments.push(newInfo);
														}

														return {
															job_id: job?.id,
															new_state: 86,
															job_information: {
																...job.job_information,
																interstate_comments: new_comments,
																quote_approved_by: `${user?.user?.username},${user?.user?.full_name}`
															},
															Reason: `Quotation approved by:{${user?.user?.username}, ${user?.user?.full_name}}`
														};
													}),
													switchMap((dataSub: any) => {
														bf.addControl('interstateComment', new UntypedFormControl('approve'));
														const { current_state, ...rest } = dataSub;
														return http.post(`${environment.api_url}v1/job_action/update_job/`, rest);
													})
												);
											}
										})
									);
								}
							}
						];
					});
					return of([]);
				}
			}
		}
	}
];

export const BET_96: Flow_0_0_2 = {
	id: '96',
	name: 'awaiting assessor_review',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					if (itemOne) {
						let title = 'Awaiting Assessor Review';
						if (itemOne?.loan_information?.mavenclaimnumber) {
							title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
						}
						if (itemOne.applicant?.surname) {
							title += ` - ${itemOne.applicant?.surname}`;
						}

						return title;
					} else {
						return 'Awaiting Assessor Review';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Review'
		},
		viewRoles: {
			0: 'Wait for Assessor to review'
		}
	},
	serverCalls: {
		dataFile: {
			serviceVariable: '',
			functionName: '',
			errorMessage: 'No file could be found!',
			directCall: (http, store, sq) => {
				return sq
					.queryStore(
						gql`
							{
								selectedContext {
									latestQuotation {
										id
									}
								}
							}
						`
					)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1),
						switchMap(quotation => {
							return http
								.post<any>(environment.api_url + `v1/file_action/get_file/`, {
									file_id: quotation.id,
									return_type: 1
								})
								.pipe(
									filter(x => !!x && Object.keys(x).length !== 0),
									take(1),
									map(value => {
										return value.payload;
									})
								);
						})
					);
			}
		},
		skills: {
			serviceVariable: 'betService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills could be found!'
		},
		iaReason: {
			serviceVariable: '',
			functionName: '',
			errorMessage: 'No ia reason found',
			directCall: (http, store, sq) => {
				return store.select(getFullItemOne).pipe(
					skipWhile((x: any) => !x || !x.loan_information),
					take(1),
					map((claim: any) => {
						return claim?.loan_information.ia_requested_reason || 'No IA requested reason found!';
					})
				);
			}
		},
		claimSummary: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return sq
					.queryObject(
						gql`
							{
								fullItemOne {
									loan_information {
										claimtype
										dateofloss
									}
								}
							}
						`,
						store.select(getFullItemOne).pipe(
							skipWhile(f => !f),
							take(1),
							map(res => {
								return { fullItemOne: res };
							})
						)
					)
					.pipe(
						map((res: any) => {
							return {
								claim_details: [
									{
										claim_type: res?.claimtype,
										date_of_loss: res?.dateofloss
									}
								]
							};
						})
					);
			}
		},
		files: {
			serviceVariable: 'betService',
			functionName: 'getAllJobsFilesOnClaim',
			errorMessage: 'Could not get files from server!'
		},
		queryHistory: {
			errorMessage: 'No query history could be found!',
			directCall: (http, store, sq) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile((job: any) => !job || !job.job_information),
					take(1),
					map((data: any) => {
						const ia_query = data?.job_information?.ia_query;

						return Array.isArray(ia_query) ? ia_query : ia_query ? [ia_query] : [];
					})
				);
			}
		}
	},
	startNode: 'InternalAssessorJobReason',
	nodes: {
		InternalAssessorJobReason: {
			name: 'Reason',
			showTabs: true,
			serverCalls: {
				appointmentReason: {
					errorMessage: 'error retrieving claim details',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											loan_information {
												ia_requested_reason
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((res: any) => {
									let data = res;

									if (!!res && !!res?.loan_information && !res?.claimtype && !res?.dateofloss) {
										data = res?.loan_information;
									}

									return {
										ia_appointment_reason: !!data?.ia_requested_reason ? data?.ia_requested_reason : 'No appointment reason has been set.',
										claim_type: data?.claimtype,
										date_of_loss: data?.dateofloss,
										recent_actions: '-'
									};
								})
							);
					}
				},
				claimDetails: {
					errorMessage: 'error retrieving claim details',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											loan_information {
												claimtype
												dateofloss
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((res: any) => {
									let data = res;

									if (!!res && !!res?.loan_information && !res?.claimtype && !res?.dateofloss) {
										data = res?.loan_information;
									}

									return {
										claim_type: data?.claimtype,
										date_of_loss: data?.dateofloss
									};
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Reason for IA Appointment:',
							headingConfig: { color: 'secondary', size: 'small', align: 'center' },
							instructions: ['Automatic Request: Quotation for Building job exceeds mandates. (job:15062)']
						}
					},

					{
						component: 'FLXKeyValueDisplayWithInstructionsComponent',
						inputs: {
							title: 'Claim Details',
							headingConfig: { color: 'secondary', size: 'small', align: 'center' },
							keyValueList$: 'claimDetails'
						}
					}
				]
			},
			navs: COMMON_NAVS
		},
		InternalAssessorReport: {
			name: 'Report',
			showTabs: true,
			serverCalls: {
				spReport: {
					serviceVariable: '',
					functionName: '',
					errorMessage: 'No file could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryStore(
								gql`
									{
										selectedContext {
											files
										}
									}
								`
							)
							.pipe(
								skipWhile(result => !result),
								take(1),
								switchMap(result => {
									const spReport = result.files?.find(report => report.purpose === 'SP Report' || report.purpose === 'SP Invalid Work Report');
									if (spReport) {
										return http
											.post(`${environment.api_url}v1/file_action/get_file/`, {
												file_id: spReport?.id,
												return_type: 1
											})
											.pipe(
												skipWhile(report => !report),
												take(1),
												map((value: any) => {
													return value?.payload?.data;
												})
											);
									}

									//Returning of empty object as when returning empty the loader isn't being removed - tried EMPTY and it does not work either.
									return EMPTY;
								})
							);
					}
				},
				iaReport: {
					serviceVariable: '',
					functionName: '',
					errorMessage: 'No file could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryStore(
								gql`
									{
										selectedContext {
											files
										}
									}
								`
							)
							.pipe(
								skipWhile(result => !result),
								take(2),
								switchMap(result => {
									const internalAssessorReport = result.files?.find(report => report.purpose === 'Internal Assessor Report');

									if (internalAssessorReport) {
										return http
											.post(`${environment.api_url}v1/file_action/get_file/`, {
												file_id: internalAssessorReport?.id,
												return_type: 1
											})
											.pipe(
												skipWhile(report => !report),
												take(1),
												map((value: any) => {
													return value?.payload?.data;
												})
											);
									}

									//Returning of empty object as when returning empty the loader isn't being removed - tried EMPTY and it does not work either.
									return EMPTY;
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Service Provider Report'
						}
					},
					{ component: 'FLXFileViewerComponent', inputs: { base64Source$: 'spReport', mimeType: 'pdf' } },
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Internal Assessor Report'
						}
					},
					{ component: 'FLXFileViewerComponent', inputs: { base64Source$: 'iaReport', mimeType: 'pdf' } }
				]
			},
			navs: COMMON_NAVS
		},
		InternalAssessorJobQuote: {
			name: 'Quote',
			showTabs: true,
			serverCalls: {
				spQuote: {
					serviceVariable: '',
					functionName: '',
					errorMessage: 'No file could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryStore(
								gql`
									{
										selectedContext {
											files
										}
									}
								`
							)
							.pipe(
								skipWhile(result => !result),
								take(1),
								switchMap(result => {
									const spQuote = result.files?.find(quote => quote.purpose === 'Quotation');
									if (spQuote) {
										return http
											.post(`${environment.api_url}v1/file_action/get_file/`, {
												file_id: spQuote?.id,
												return_type: 1
											})
											.pipe(
												skipWhile(report => !report),
												take(1),
												map((value: any) => {
													return value?.payload?.data;
												})
											);
									}

									//Returning of empty object as when returning empty the loader isn't being removed - tried EMPTY and it does not work either.
									return EMPTY;
								})
							);
					}
				},
				iaQuote: {
					serviceVariable: '',
					functionName: '',
					errorMessage: 'No file could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryStore(
								gql`
									{
										selectedContext {
											files
										}
									}
								`
							)
							.pipe(
								skipWhile(result => !result),
								take(1),
								switchMap(result => {
									const internalAssessorQuote = result.files?.find(quote => quote.purpose === 'Assesor BOQ Quote'); // TODO: put actual purpose for IA Quote

									if (internalAssessorQuote) {
										return http
											.post(`${environment.api_url}v1/file_action/get_file/`, {
												file_id: internalAssessorQuote?.id,
												return_type: 1
											})
											.pipe(
												skipWhile(report => !report),
												take(1),
												map((value: any) => {
													return value?.payload.data;
												})
											);
									}

									//Returning of empty object as when returning empty the loader isn't being removed - tried EMPTY and it does not work either.
									return EMPTY;
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Service Provider Quote'
						}
					},
					{ component: 'FLXFileViewerComponent', inputs: { base64Source$: 'spQuote', mimeType: 'pdf' } },
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Quote Report'
						}
					},
					{ component: 'FLXFileViewerComponent', inputs: { base64Source$: 'iaQuote', mimeType: 'pdf' } }
				]
			},
			navs: COMMON_NAVS
		},
		InternalAssessorJobPhotos: {
			name: 'Photos',
			showTabs: true,
			serverCalls: {
				iaPhotos: {
					serviceVariable: 'betService',
					functionName: 'getAllIAJobPhotos',
					errorMessage: 'Could not get files from server!'
				},
				spPhotos: {
					serviceVariable: 'betService',
					functionName: 'getAllSPJobPhotos',
					errorMessage: 'Could not get files from server!'
				}
			},
			component: {
				children: [
					{
						component: 'FLXViewPhotosComponent',
						inputs: {
							heading: 'Service Provider Photos',
							location: 'context-menu',
							photos$: 'spPhotos'
						}
					},
					{
						component: 'FLXViewPhotosComponent',
						inputs: {
							heading: 'Internal Assessor Photos',
							location: 'context-menu',
							photos$: 'iaPhotos'
						}
					}
				]
			},
			navs: COMMON_NAVS
		},
		InternalAssessorJobQueryHistory: {
			name: 'Query History',
			showTabs: true,
			component: 'FLXQueryHistoryComponent',
			inputs: {
				queryHistory: 'queryHistory',
				location: 'screen',
				title: 'Previous Queries'
			},
			navs: COMMON_NAVS
		},
		RecommendCIL: {
			inputs: {
				header: 'Recommend Cash in Lieu',
				instruction: 'Select other jobs in this claim that you want the same recommendation to apply to'
			},
			component: 'InternalAssessorSelectOtherJobsComponent',
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('selectedJobs', new UntypedFormControl(''));
				bf.patchValues({ cil_state: 93 });
				bf.patchValues({ new_state: 105 });
				bf.patchValues({
					effected_jobs: [[...bf.bigForm.get('selectedJobs')?.value, bf.bigForm.get('itemTwo')?.value?.id]]
				});
				bf.addControl('interstateComment', new UntypedFormControl('recommendCIL'));
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'recommendCil',
							errorMessage: 'Job could not be moved to cil!'
						}
					}
				}
			]
		},
		RecommendRejection: {
			checkValidityForFields: ['reasonField'],
			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ reasonField: bf.getControl('reasonField')?.value || '' });
				bf.bigForm.get('reasonField')?.setValidators([Validators.required, Validators.minLength(3)]);
				store
					.select(getAllInfo)
					.pipe(
						pluck('config_options', 'general'),
						skipWhile(general => !general),
						take(1)
					)
					.subscribe(general => {
						// If config option is on: route to Desktop assessor 213; else continue normal SIL path
						bf.patchValues({ new_state: general.recommend_rejection_to_desktop_assessor ? 213 : 94, isValid: 0 });
					});
				bf.addControl('interstateComment', new UntypedFormControl('recommendRejection'));
			},
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							inputHeading: 'Recommend Rejection',
							subHeading: 'Please provide a reason for rejection in the text box below',
							dataFiles$: 'dataFile'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: { formControlName: 'reasonField', inputType: 'textarea', label: 'Rejection reason:' }
							}
						}
					}
				]
			},
			navs: [
				{ text: 'Other Jobs', nextNode: 'RecommendCIL' },
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		RecommendLossAdjuster: {
			checkValidityForFields: ['recommendLossAdjusterReason'],
			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({
					recommendLossAdjusterReason: bf.getControl('recommendLossAdjusterReason')?.value || ''
				});
				bf.patchValues({ new_state: '99' });
				bf.bigForm.get('recommendLossAdjusterReason')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							inputHeading: 'Recommend Loss Adjuster',
							subHeading: 'Please provide a reason for recommending a loss adjuster in the text box below',
							dataFiles$: 'dataFile'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'recommendLossAdjusterReason',
									inputType: 'textarea',
									label: 'Recommend Loss Adjuster Reason:'
								}
							}
						}
					}
				]
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		current_state: 'current_state',
		new_state: 'new_state',
		effected_jobs: 'effected_jobs',
		cil_state: 'cil_state',
		reasonField: 'job_information.diagnosis',
		recommendLossAdjusterReason: 'Reason',
		isValid: 'job_information.is_valid',
		query: [
			(query, storeObj) => {
				const fullItemTwo = storeObj['selectedContext']?.fullItemTwo;

				const queries = fullItemTwo?.job_information?.ia_query;

				const ia_query = Array.isArray(queries) ? [...queries] : queries && typeof queries === 'object' && Object.keys(queries).length > 0 ? [{ ...queries }] : [];

				return [...ia_query, { id: ia_query.length + 1, query, query_datetime: new Date().toISOString(), queryreply: '', queryreply_datetime: '' }];
			},
			'job_information.ia_query'
		],
		interstateComment: [
			(comment, storObj, bf) => {
				if (comment) {
					const new_comments = [];
					const date = new Date();
					const newInfo = {
						message: comment,
						author_id: storObj?.identity?.currentUser?.user.id,
						author_name: storObj?.identity?.currentUser?.user.full_name,
						currentState: bf?.current_state,
						nextState: bf?.new_state,
						timeStamp: convertDateTimeToTimeStamp(date.toDateString())
					};
					if (storObj['selectedContext']?.fullItemTwo?.job_information?.interstate_comments) {
						const interstate_comments = storObj['selectedContext']?.fullItemTwo?.job_information.interstate_comments;

						Array.isArray(interstate_comments) ? new_comments?.push(...interstate_comments, newInfo) : new_comments?.push(interstate_comments, newInfo);
					} else {
						new_comments.push(newInfo);
					}
					return new_comments;
				} else {
					return storObj['selectedContext']?.fullItemTwo?.job_information?.interstate_comments;
				}
			},
			'job_information.interstate_comments'
		]
	},
	onStateDestroy: instance => {
		instance.popupService && instance.popupService?.closePopUp();
	}
};
