import { Flow_0_0_2, MakeServerCall, getFullItemTwo, getSelectedItemTwo, getSubmissionData } from '@flexus/core';
import { filter, map, mergeMap, skipWhile, switchMap, take } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import gql from 'graphql-tag';

export const BET_101: Flow_0_0_2 = {
	id: '101',
	name: 'awaiting_loss_adjuster_decision',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},

	startNode: 'ViewJobDocuments',
	nodes: {
		ViewJobDocuments: {
			component: 'FLXFileBrowserComponent',
			showTabs: true,
			name: 'Documents',
			inputs: {
				heading: 'View support documents for Loss Adjuster Job',
				showFilters: false,
				paginationId: 'reports',
				files: 'dataFile',
				display: 'image-grid'
				// location: 'context-menu',
			},
			serverCalls: {
				files: {
					serviceVariable: 'spService',
					functionName: 'getAllJobFiles',
					responseSlice: 'payload',
					errorMessage: 'Could not get files from server!'
				}
			}
		},

		ViewJobNotes: {
			name: 'notes',
			component: 'NotesComponent',
			inputs: {
				title: 'Add Job Note',
				formInputs: {
					0: { formControlName: 'message', inputType: 'textarea', label: 'Message' }
				},
				submitFunction: instance => {
					instance._store.dispatch(
						new MakeServerCall({
							dataKey: 'notes',
							serviceVariable: '',
							functionName: 'addJobNote',
							errorMessage: 'Could not add note'
						})
					);
					instance?.bf?.bigForm?.patchValue({ message: '' });
					instance?.bf?.bigForm?.markAsPristine();
				}
			},
			showTabs: true,
			initFormFields: bf => {
				bf.bigForm.addControl('message', new UntypedFormControl('', Validators.required));
			},
			serverCalls: {
				notes: {
					serviceVariable: '',
					functionName: 'getNotes',
					responseSlice: 'payload',
					errorMessage: 'No claim notes could be found!'
				}
			}
		},

		ViewDecision: {
			name: 'Decision',
			component: 'FLXHeadingWithInstructionsComponent',
			inputs: {
				title: 'Approve or decline the job',
				itemMargin: '0 0 35px 0'
			},
			showTabs: true,
			navs: [
				{
					text: 'Decline',
					color: 'danger',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						declineJob: {
							errorMessage: 'Could not decline job!',
							directCall: (http, store) =>
								store.select(getFullItemTwo)?.pipe(
									filter(job => !!job),
									take(1),
									switchMap(job =>
										http.post(`${environment.api_url}v1/job_action/update_job/`, {
											job_information: job.job_information,
											job_id: job?.id,
											new_state: 94
										})
									)
								)
						}
					}
				},
				{
					text: 'Approve',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						declineJob: {
							errorMessage: 'Could not approve job!',
							directCall: (http, store) =>
								store.select(getFullItemTwo)?.pipe(
									filter(job => !!job),
									take(1),
									switchMap(job =>
										http.post(`${environment.api_url}v1/job_action/update_job/`, {
											job_information: job.job_information,
											job_id: job?.id,
											new_state: 86
										})
									)
								)
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	}
};
