import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

export interface DynamicFormData {
	label?: string;
	formControlName: string;
	defaultValue?: any;
	inputType?: 'textarea' | 'input' | 'radio' | 'select' | 'checkbox'; // Can be extended to add other input types
	value?: string; // TODO: Needs to be checked based on what input type
	width?: string; // width of the input
	maxWidth?: string; // to be used with flx-form-field
	size?: string;
	styles?: string[]; // TODO additional styles needed to be applied to the form input
	inputPosition?: 'bottom' | 'inline'; // TODO specify the position you want the input to be in relation to the question
	inputGroup?: string; // TODO Group inputs together
	validators?: ValidatorFn[];
	selectConfig?: SelectConfig; // Used for select only
	disabled?: boolean;
	rows?: number;
	placeholder?: string;
	autocomplete?: boolean;
	maxDate?: any;
}

export interface DynamicFormConfig {
	data: DynamicFormInputs;
	parentForm?: UntypedFormGroup; // Gain access to the elements data via parent form
	formLayout?: 'two-column' | 'three-column' | 'flex' | 'stack' | string; // TODO: Style of the form
	containerWidth?: 'auto';
}

export interface DynamicFormInputs {
	[key: number]: DynamicFormData;
}

export interface SelectConfig {
	displayOptions: { displayKey: string; valueKey: string }; // Points to the key value pair
	itemsOption: any[] | string; // The key value pairs used to display
	disabled?: boolean;
	placeHolder?: string;
	searchEnabled?: boolean;
	autoOpen?: boolean;
	form?: string;
	id?: string;
	multiple?: boolean;
	styles?: any;
	upperItemCountLimit?: number;
	addConditionalForm?: boolean;
}

export const dynamicFormDefaultConfig: DynamicFormConfig = {
	data: {
		0: {
			label: 'What did we do well?',
			inputType: 'textarea',
			width: '48%',
			defaultValue: '',
			inputPosition: 'bottom',
			formControlName: 'how_we_did'
		},
		1: {
			label: 'What can we improve on?',
			inputType: 'textarea',
			defaultValue: '',
			width: '48%',
			inputPosition: 'bottom',
			formControlName: 'how_can_do'
		}
	},
	formLayout: 'flex'
};
