import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXKeyValueDisplayWithInstructionsComponent } from './key-value-display-with-instructions.component';
import { FLXKeyValueListModule, FLXHeadingsModule } from '@flexus/ui-elements';
import { FLXHeadingWithInstructionsModule } from '../heading-with-instructions';

@NgModule({
	imports: [CommonModule, FLXKeyValueListModule, FLXHeadingsModule, FLXHeadingWithInstructionsModule],
	declarations: [FLXKeyValueDisplayWithInstructionsComponent],
	exports: [FLXKeyValueDisplayWithInstructionsComponent]
})
export class FLXKeyValueDisplayWithInstructionsModule {}
