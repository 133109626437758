import { NodePoint_0_0_2, getFullItemTwo, getAllInfo, getData, BigFormService, getSelectedItem } from '@flexus/core';
import { skipWhile, take, map, pluck, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { GetDecoderSerialNumberDisplay } from './dataManipulations';
import { KVLHeading } from '@flexus/ui-elements';
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';

export const JOB_INFO_NODE = (state, new_state?): NodePoint_0_0_2 => {
	return {
		name: 'Job Information',
		showTabs: true,
		initFormFields: (bf, item, instance, sq, store) => {
			if (new_state) {
				bf.patchValues({ new_state });
			}
		},
		component: {
			children: [
				...(state === 339
					? [
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: 'Payment Declined',
									color: 'default'
								}
							},
							// {
							// 	component: 'FLXHeadingWithInstructionsComponent',
							// 	inputs: {
							// 		title: 'Your payment was declined. Please fix the issues listed below and resubmit for payment.',
							// 		headingConfig: {
							// 			size: 'small',
							// 			color: 'alert',
							// 			weight: 'bold'
							// 		}
							// 	}
							// },
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									data$: 'important_info',
									color: 'secondary',
									colouredHeading: new KVLHeading('Your payment was declined. Please fix the issues listed below and resubmit for payment.', 'secondary')
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Installation Details',
									data$: 'installer_details',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Contact Details',
									data$: 'customer_details',
									itemMargin: '0 0 35px 0'
								}
							}
					  ]
					: []),
				...(state === 333 || state === 336
					? [
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('Important Information', 'secondary'),
									color: 'secondary',
									data$: 'important_info',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Installation Details',
									data$: 'installer_details',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Contact Details',
									data$: 'customer_details',
									itemMargin: '0 0 35px 0'
								}
							}
					  ]
					: []),
				...(state === 26
					? [
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: 'Job Summary'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('It is no longer necessary to claim on the installer portal', 'secondary'),
									color: 'secondary',
									data$: 'important_info',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Installation Details',
									data$: 'installer_details',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Contact Details',
									data$: 'customer_details',
									itemMargin: '0 0 35px 0'
								}
							}
					  ]
					: [])
			]
		},
		navs: [
			...(state === 339
				? [
						// TODO: Possible future implementation : what does SP do if he cannot fix
						// {
						// 	text: 'unable to fix',
						// 	color: 'secondary',
						// 	nextNode: 'SubmissionSuccess',
						// 	serverCalls: {
						// 		response: {
						// 			errorMessage: `Couldn't re-submit for auto payment`,
						// 			directCall: (_http: HttpClient, _store: Store, _sq, _bf: BigFormService) => {
						// 				_bf.addControl('new_state', new UntypedFormControl('', []));
						// 				return _store
						// 					.select(getSelectedItem)
						// 					.pipe(
						// 						skipWhile(x => !x),
						// 						take(1),
						// 						map((res: any) => res)
						// 					)
						// 					.pipe(
						// 						switchMap((response: any) => {
						// 							const job_id = response?.id;
						// 							const new_state = _bf.getControl('new_state').value;
						// 							const subdata = {
						// 								job_id: job_id,
						// 								new_state: new_state
						// 							};
						// 							// TODO: Determine/find out validation agent state this needs to go to
						// 							console.log('resubmitting for payment', subdata);
						// 							// _http.post(`${environment.api_url}v1/job_action/update_job/`, subdata);
						// 							return of({});
						// 						})
						// 					);
						// 			}
						// 		}
						// 	}
						// },
						// {
						// 	text: 'resubmit for payment',
						// 	color: 'primary',
						// 	nextNode: 'SubmissionSuccess',
						// 	serverCalls: {
						// 		response: {
						// 			errorMessage: `Couldn't re-submit for auto payment`,
						// 			directCall: (_http: HttpClient, _store: Store, _sq, _bf: BigFormService) => {
						// 				_bf.addControl('new_state', new UntypedFormControl(341, []));
						// 				return _store
						// 					.select(getSelectedItem)
						// 					.pipe(
						// 						skipWhile(x => !x),
						// 						take(1),
						// 						map((res: any) => res)
						// 					)
						// 					.pipe(
						// 						switchMap((response: any) => {
						// 							const job_id = response?.id;
						// 							const new_state = _bf.getControl('new_state').value;
						// 							const subdata = {
						// 								job_id: job_id,
						// 								new_state: new_state
						// 							};
						// 							_http.post(`${environment.api_url}v1/job_action/update_job/`, subdata);
						// 							return of({});
						// 						})
						// 					);
						// 			}
						// 		}
						// 	}
						// }
				  ]
				: state === 333 || state === 336
				? [
						{
							text: 'upload file',
							// linkType: 'portal',
							color: 'primary',
							nextNode: 'UploadMissingFile',
							serverCalls: {}
						},
						{
							text: 'reply with comment',
							// linkType: 'portal',
							color: 'primary',
							nextNode: 'PaymentDeferredQuery',
							serverCalls: {}
						}
				  ]
				: state === 26
				? [
						{
							text: 'return to team leader',
							// linkType: 'portal',
							color: 'secondary',
							nextNode: 'ReturnToTLReasonEntry',
							serverCalls: {}
						},
						{
							text: 'submit for payment',
							color: 'primary',
							serverFirst: true,
							nextNode: 'SubmissionSuccess',
							serverCalls: {
								updateJob: {
									serviceVariable: 'mulSpService',
									functionName: 'updateJob',
									errorMessage: 'Could not update job.'
								}
							}
						}
				  ]
				: [])
		],
		serverCalls: {
			important_info: {
				errorMessage: `Important information couldn't be returned.`,
				directCall: (_http: HttpClient, _store: Store) => {
					return _store.select(getFullItemTwo).pipe(
						skipWhile(x => !x),
						take(1),
						map((response: any) => {
							console.log('JI_RESPONSE', response);
							let autopay_fail_reason = response?.office_use?.claim_status;
							let validationquery;
							const list = [];
							const { job_information } = response;
							const { autopay_validation_queries } = job_information;
							if (autopay_validation_queries) {
								if (Array.isArray(autopay_validation_queries)) {
									validationquery = autopay_validation_queries[autopay_validation_queries.length - 1].query;
								} else {
									validationquery = autopay_validation_queries.query;
								}
								const importantreason = {
									'Reason for query': `${validationquery}`
								};
								if (state === 333 || state === 336) {
									list.push(importantreason);
								}
								if (state === 339) {
									autopay_fail_reason = autopay_fail_reason ? autopay_fail_reason : 'Not returned';

									list.push({ 'Reason for decline': `${autopay_fail_reason}` });
								}
							}
							if (!autopay_validation_queries) {
								if (state === 26) {
									autopay_fail_reason = autopay_fail_reason ? autopay_fail_reason : 'Not returned';
									list.push({ 'Reason for decline': `${autopay_fail_reason}` });
								}
								if (state === 339) {
									autopay_fail_reason = autopay_fail_reason ? autopay_fail_reason : 'Not returned';

									list.push({ 'Reason for decline': `${autopay_fail_reason}` });
								}
							}
							return list;
						})
					);
				}
			},
			installer_details: {
				errorMessage: 'Cannot get job info summary',
				directCall: (http, store) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.pipe(
							take(1),
							map(info => {
								const { claim, team_leader } = info;
								const list: any = [];
								// Installer details

								list.push({
									company_name: team_leader?.sp?.name ?? '',
									accreditation_number: team_leader?.sp?.accreditation_number ?? '',
									'Installation technician qualification number': team_leader?.qualification_number ?? '',
									'Installer/Technician name': team_leader?.full_name ?? ''
								});
								//

								return list;
							})
						);
				}
			},
			customer_details: {
				errorMessage: 'Cannot get job info summary',
				directCall: (http, store) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.pipe(
							take(1),
							map(info => {
								const { claim, team_leader, identities } = info;
								const list: any = [];
								// Customer details
								if (claim?.applicant) {
									list.push({
										full_name: claim?.applicant?.first_name + ' ' + claim?.applicant?.surname,
										// ...(state !== 186 ? { customer_id: claim?.applicant?.id_number ?? '', subscriber_number: identities?.subscriber_number ?? '' } : {}),
										installation_address:
											state === 186
												? claim?.address
												: `${claim?.loan_information?.propertystreetaddress ?? ''} ${claim?.loan_information?.propertystreetaddress_2 ?? ''} ${
														claim?.loan_information?.propertysuburb ?? ''
												  } ${claim?.loan_information?.propertycity ?? ''}, ${claim?.loan_information?.suburbcode ?? ''}`,
										email_address: claim?.loan_information?.email ?? claim?.loan_information?.Email ?? '',
										contact_number: claim?.applicant?.contact_number,
										GPS_Coordinates: claim?.location
									});
								}
								return list;
							})
						);
				}
			}
		}
	};
};

// NodePoint_0_0_2 = {
// 	showTabs: true,
// 	name: 'Job Information',
// 	initFormFields: (bf, item, instance, storeQuery, store) => {},
// 	component: {
// 		children: [
// 			{
// 				component: 'FLXHeadingWithInstructionsComponent',
// 				inputs: {
// 					title: 'Job Summary',
// 					itemMargin: '0 0 15px 0'
// 				}
// 			},
// 			{
// 				component: 'FLXKeyValueListComponent',
// 				inputs: {
// 					colouredHeading: new KVLHeading('Installation Details', 'secondary'),
// 					data$: 'installerKeyValues',
// 					itemMargin: '0 0 15px 0'
// 				}
// 			},
// 			{
// 				component: 'FLXKeyValueListComponent',
// 				inputs: {
// 					colouredHeading: new KVLHeading('Contact Details', 'secondary'),
// 					data$: 'customerContactKeyValues',
// 					itemMargin: '0 0 15px 0'
// 				}
// 			},
// 			{
// 				component: 'FLXKeyValueListComponent',
// 				inputs: { data$: 'siteDetails', colouredHeading: new KVLHeading('Site Details', 'secondary') }
// 			}
// 		]
// 	},
// 	serverCalls: {
// 		installerKeyValues: {
// 			errorMessage: 'Something went wrong with installation info',
// 			directCall: (http, store, sq) => {
// 				return forkJoin([
// 					store.select(getFullItemTwo).pipe(
// 						skipWhile(itt => !itt),
// 						take(1),
// 						map(res => res as any)
// 					),
// 					store.select(getAllInfo).pipe(
// 						skipWhile(ai => !ai),
// 						take(1),
// 						map(res => res as any)
// 					),
// 					http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
// 						skipWhile(x => !x),
// 						take(1),
// 						map(res => res['payload'])
// 					)
// 				]).pipe(
// 					map(([itemTwo, info, teamleaders]) => {
// 						const sp = itemTwo?.sp;
// 						const sps = info.sps;
// 						let companyname;
// 						let accreditation_number;
// 						let qualification_number;
// 						for (let i = 0; i < sps.length; i++) {
// 							if (sp === sps[i]?.id) {
// 								companyname = sps[i]?.name;
// 							}
// 						}
// 						const tleads = teamleaders as any;
// 						let teamleadname = 'Team leader not assigned yet';
// 						for (let i = 0; i < tleads?.length; i++) {
// 							if (!itemTwo.team_leader) {
// 								teamleadname = 'Team leader not assigned yet';
// 							} else if (tleads[i]?.id === itemTwo?.team_leader.id) {
// 								teamleadname = tleads[i]?.full_name;
// 							}
// 						}
// 						// const accreditation_number =
// 						if (!itemTwo.team_leader || !itemTwo.team_leader.sp) {
// 							accreditation_number = 'There is no accreditation number available';
// 							qualification_number = 'No qualification number for the technician';
// 						} else {
// 							accreditation_number = itemTwo?.team_leader.sp.accreditation_number;
// 							qualification_number = itemTwo?.team_leader.qualification_number;
// 						}

// 						const installerinfo = {
// 							'Company name ': companyname,
// 							'Accreditation number ': accreditation_number,
// 							'Installation technician qualification number': qualification_number,
// 							'Installer/Technician name ': teamleadname
// 						};
// 						return [installerinfo];
// 					})
// 				);
// 			}
// 		},
// 		customerContactKeyValues: {
// 			errorMessage: 'Something went wrong customer contact info',
// 			directCall: (http, store, sq) => {
// 				return forkJoin([
// 					store.select(getFullItemTwo).pipe(
// 						skipWhile(itt => !itt),
// 						take(1),
// 						map(res => res as any)
// 					),
// 					http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
// 						skipWhile(x => !x),
// 						take(1),
// 						map(res => res['payload'])
// 					)
// 				]).pipe(
// 					map(([itemTwo, teamleaders]) => {
// 						let claimtype = itemTwo?.claim?.type;
// 						let joblocation = 'The customer misses data';
// 						let newAddress;

// 						const {
// 							identities: { policy_number, subscriber_number },
// 							claim: {
// 								address,
// 								applicant,
// 								loan_information: {
// 									ContactNumber,
// 									Email,
// 									claimlatitude,
// 									claimlongitude,
// 									contactnumber,
// 									email,
// 									onSiteContact,
// 									cellnumber,
// 									onsitecontact,
// 									onsitecontact_c,
// 									onsitecontact_w,
// 									propertystreetaddress_2,
// 									propertysuburb,
// 									whatmatters
// 								},
// 								suburb
// 							},
// 							team_leader
// 						} = itemTwo;

// 						if (!itemTwo?.claim?.loan_information) {
// 							joblocation = 'The customer misses data';
// 						}
// 						if (!claimlatitude || !claimlongitude) {
// 							joblocation = 'At least one coordinate not supplied!';
// 						}
// 						if (claimlatitude && claimlongitude) {
// 							joblocation = `${claimlatitude}, ${claimlongitude}`;
// 						}

// 						if (claimtype !== 'Pricelock Installation') {
// 							newAddress = `${address}, ` + `${suburb}`;
// 						} else {
// 							newAddress = `${propertystreetaddress_2}, ` + `${propertysuburb}`;
// 						}

// 						const contacts = [onSiteContact, onsitecontact_c, onsitecontact_w, onsitecontact];
// 						const alt = contacts.filter(x => x && x !== ContactNumber);
// 						const altno = alt.length > 0 ? alt.join(', ') : ContactNumber;

// 						const customerContactData = {
// 							'Full name ': `${applicant?.first_name} ` + `${applicant?.surname}`,
// 							'Subscriber Number': subscriber_number ? subscriber_number : policy_number ? policy_number : 'No subscriber number found!',
// 							'Installation Address': newAddress,
// 							'Email address ': Email ? Email : email ? email : 'No email address returned!',
// 							'Contact number ': ContactNumber ? ContactNumber : contactnumber ? contactnumber : 'No contact number provided/available',
// 							'Cell number ': cellnumber ?? 'No cell number provided/available',
// 							'Alternate contact numbers': altno ?? 'No alternative numbers found',
// 							// 'GPS Coordinates ': itemTwo ? itemTwo?.location : '--'
// 							'GPS Coordinates ': joblocation,
// 							'What matters to the customer': whatmatters
// 						};
// 						return [customerContactData];
// 					})
// 				);
// 			}
// 		},
// 		// voucher: {
// 		// 	serviceVariable: 'mulSpService',
// 		// 	functionName: 'getVoucherInfo',
// 		// 	responseSlice: 'payload',
// 		// 	errorMessage: 'Could not get voucher from server!'
// 		// },
// 		// decoderInfoKeyValues: {
// 		// 	errorMessage: 'unable to add voucher to itemTwo',
// 		// 	directCall: (http, store) => {
// 		// 		return forkJoin([
// 		// 			store.select(getFullItemTwo).pipe(
// 		// 				skipWhile(itt => !itt),
// 		// 				take(1),
// 		// 				map(res => res as any)
// 		// 			),
// 		// 			store.select(getData).pipe(
// 		// 				skipWhile(v => !v.voucher),
// 		// 				pluck('voucher'),
// 		// 				take(1)
// 		// 			)
// 		// 		]).pipe(
// 		// 			map(([job, voucher]) => {
// 		// 				console.log('HERE');
// 		// 				debugger;
// 		// 				const claimtype = job?.claim?.claim_type;
// 		// 				const pricelockclaimtypes = [26, 37];
// 		// 				const insuranceclaimtypes = [20, 10, 8, 7, 32, 34, 35];
// 		// 				const customerscheduledclaimtypes = [36];
// 		// 				const requiredDevice = job?.office_use?.device_details?.replacement_device ? job?.office_use?.device_details?.replacement_device : 'No replacement device captured';
// 		// 				const requiredDeviceName = requiredDevice?.name;
// 		// 				const requireDeviceSerNo = requiredDevice?.serial_number;
// 		// 				const decoderDetails = GetDecoderSerialNumberDisplay(voucher?.decoder_number);
// 		// 				if (pricelockclaimtypes.includes(claimtype) === true) {
// 		// 					return [
// 		// 						{
// 		// 							...decoderDetails,
// 		// 							'Voucher Code': job?.job_information?.vouchers?.voucher_code ?? 'No additional decoder information',
// 		// 							'Additional Decoder Info ': job?.job_information?.vouchers?.title ?? 'No additional decoder information'
// 		// 						}
// 		// 					];
// 		// 				}
// 		// 				if (insuranceclaimtypes.includes(claimtype) === true) {
// 		// 					return [
// 		// 						{
// 		// 							...decoderDetails,
// 		// 							'Replacement Device Name': requiredDeviceName,
// 		// 							'Replacement Device Serial No.': requireDeviceSerNo
// 		// 						}
// 		// 					];
// 		// 				}
// 		// 				if (customerscheduledclaimtypes.includes(claimtype) === true) {
// 		// 					return [
// 		// 						{
// 		// 							...decoderDetails,
// 		// 							'Voucher Code': job?.job_information?.vouchers?.voucher_code ?? 'No additional decoder information',
// 		// 							'Additional Decoder Info ': job?.job_information?.vouchers?.title ?? 'No additional decoder information'
// 		// 						}
// 		// 					];
// 		// 				}
// 		// 			})
// 		// 		);
// 		// 	}
// 		// },
// 		siteDetails: {
// 			errorMessage: `Couldn't retrieve decoder type`,
// 			directCall: (_h: any, _store: any, _sq: any, _bf: BigFormService) => {
// 				return _store.select(getFullItemTwo).pipe(
// 					skipWhile((item: any) => !item),
// 					take(1),
// 					switchMap((result: any) => {
// 						const claimtype = result?.claim?.claim_type;
// 						let current_environment;
// 						const insuranceclaimtypes = [20, 10, 8, 7, 32, 34, 35];
// 						let device_type;
// 						let tv_type;
// 						let dwelling_type;
// 						let existing_decoders;
// 						let no_of_tvs;
// 						let wall_type;
// 						let teescees;
// 						let siteDateObj;

// 						if (claimtype === 36) {
// 							current_environment = result?.office_use?.current_environment;
// 							// teescees = result?.office_use?.device_details?.replacement_device?.description;
// 							device_type = result?.office_use?.device_details?.customer_device?.cust_device_type;
// 							tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs : 'Data not available';
// 							dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type : 'Data not available';
// 							wall_type = result?.claim?.loan_information?.current_environment?.wall_type ?? 'Data not avaiable';
// 							existing_decoders = current_environment?.existing_decoder === undefined ? 'No' : 'Yes';
// 							no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs : 'Data not available';
// 							siteDateObj = {
// 								'Decoder Installation': `${device_type}`,
// 								'Kind of TVs': `${tv_type}`,
// 								'Home Type': `${dwelling_type}`,
// 								'Existing Decoder?': `${existing_decoders}`,
// 								'Number of TVs': `${no_of_tvs}`,
// 								'Wall Type': `${wall_type}`
// 							};
// 						} else if (claimtype === 37) {
// 							current_environment = result?.office_use?.current_environment;
// 							teescees = result?.office_use?.device_details?.replacement_device?.description;
// 							device_type = result?.office_use?.office_use?.device_details?.customer_device?.cust_device_type;
// 							tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs : 'Data not available';
// 							dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type : 'Data not available';
// 							wall_type = current_environment?.wall_type ? current_environment?.wall_type : 'Data not avaiable';
// 							existing_decoders = current_environment?.existing_decoder === undefined ? 'No' : 'Yes';
// 							no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs : 'Data not available';
// 							const device_details = result?.office_use?.device_details;
// 							if (device_details instanceof Array) {
// 								const decodersArray: string[] = [];
// 								for (let i = 0; i < device_details.length; i++) {
// 									const decodername: string = device_details[i].replacement_device?.type;
// 									decodersArray.push(decodername);
// 								}
// 								if (decodersArray.length === 2) {
// 									device_type = decodersArray.join(' and ');
// 								} else if (decodersArray.length > 2) {
// 									const last_in_list = decodersArray.pop();
// 									const others_in_list: string[] = [];
// 									for (let i = 0; i < device_details.length - 1; i++) {
// 										const decodername: string = device_details[i].replacement_device?.type;
// 										others_in_list.push(decodername);
// 									}
// 									const prelist: string = others_in_list.join(', ');
// 									device_type = prelist.concat(' and ', last_in_list);
// 								}
// 							} else {
// 								device_type = result?.office_use?.device_details?.replacement_device?.type;
// 							}
// 							siteDateObj = {
// 								'Decoder Installation': `${device_type}`,
// 								'Ts & Cs': `${teescees}`,
// 								'Kind of TVs': `${tv_type}`,
// 								'Home Type': `${dwelling_type}`,
// 								'Existing Decoder?': `${existing_decoders}`,
// 								'Number of TVs': `${no_of_tvs}`,
// 								'Wall Type': `${wall_type}`
// 							};
// 						} else if (insuranceclaimtypes.includes(claimtype)) {
// 							device_type = result?.office_use?.device_details?.replacement_device?.name;
// 							// teescees = '-';
// 							current_environment = result?.claim?.loan_information?.current_environment;
// 							tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs?.description : 'Data not available';
// 							dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type?.description : 'Data not available';
// 							wall_type = 'Data not avaiable';
// 							existing_decoders = 'Data not avaiable';
// 							no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs?.description : 'Data not available';
// 							siteDateObj = {
// 								'Decoder Installation': `${device_type}`,
// 								'Kind of TVs': `${tv_type}`,
// 								'Home Type': `${dwelling_type}`,
// 								'Existing Decoder?': `${existing_decoders}`,
// 								'Number of TVs': `${no_of_tvs}`,
// 								'Wall Type': `${wall_type}`
// 							};
// 						} else if (claimtype === 26) {
// 							device_type = result?.job_information?.vouchers?.title;
// 							// teescees = result?.claim?.loan_information?.vouchers?.terms;
// 							current_environment = result?.claim?.loan_information?.current_environment;
// 							tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs : 'Data not available';
// 							dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type : 'Data not available';
// 							wall_type = 'Data not avaiable';
// 							existing_decoders = 'Data not avaiable';
// 							no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs : 'Data not available';
// 							siteDateObj = {
// 								'Decoder Installation': `${device_type}`,
// 								'Kind of TVs': `${tv_type}`,
// 								'Home Type': `${dwelling_type}`,
// 								'Existing Decoder?': `${existing_decoders}`,
// 								'Number of TVs': `${no_of_tvs}`,
// 								'Wall Type': `${wall_type}`
// 							};
// 						} else if (claimtype === 40) {
// 							// const arrayed: any = [...result];
// 							// teescees = '-';
// 							current_environment = result?.office_use?.current_environment;
// 							device_type = result?.office_use?.device_details?.replacement_device?.type;
// 							tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs?.description : 'Data not available';
// 							dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type?.description : 'Data not available';
// 							wall_type = result?.claim?.loan_information?.current_environment?.wall_type ?? 'Data not avaiable';
// 							existing_decoders = current_environment?.existing_decoder === undefined ? 'No' : 'Yes';
// 							no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs?.description : 'Data not available';
// 							siteDateObj = {
// 								'Decoder Installation': `${device_type}`,
// 								'Kind of TVs': `${tv_type}`,
// 								'Home Type': `${dwelling_type}`,
// 								'Existing Decoder?': `${existing_decoders}`,
// 								'Number of TVs': `${no_of_tvs}`,
// 								'Wall Type': `${wall_type}`
// 							};
// 						} else if (claimtype === 39) {
// 							// teescees = '-';
// 							current_environment = result?.claim?.loan_information?.current_environment;
// 							device_type = result?.office_use?.device_details?.replacement_device?.name;
// 							tv_type = current_environment?.type_of_tvs?.description;
// 							no_of_tvs = current_environment?.no_of_tvs?.description;
// 							dwelling_type = current_environment?.dwelling_type?.description;
// 							wall_type = '-';
// 							teescees = '-';
// 							existing_decoders = '-';
// 							siteDateObj = {
// 								'Decoder Installation': `${device_type}`,
// 								'Kind of TVs': `${tv_type}`,
// 								'Home Type': `${dwelling_type}`,
// 								'Existing Decoder?': `${existing_decoders}`,
// 								'Number of TVs': `${no_of_tvs}`,
// 								'Wall Type': `${wall_type}`
// 							};
// 						}

// 						console.log('SITE', siteDateObj);

// 						return [siteDateObj];
// 					})
// 				);
// 			}
// 		}
// 	}
// };
