import { BigFormService, Flow_0_0_2, getAllInfo, getCurrentUser, getFullItemTwo, MakeServerCall, ManifestController, ModalService, SetNextNode } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { combineLatest, EMPTY, of, forkJoin } from 'rxjs';
import { filter, map, skipWhile, switchMap, take } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { JOB_INFO_NODE } from '../reusable/JOB_INFO_NODE';
import { convertDateTimeToTimeStamp, CustomValidators, getExtension } from '@flexus/utilities';
import { PHOTO_NODE } from '../../../../sp_globals/configs/reusable/PHOTO_NODE';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { environment } from 'apps/studio/src/environments/environment';
import { KVLHeading } from '@flexus/ui-elements';
// import { environment } from 'apps/studio/src/environments/environment';

export const MUL_26: Flow_0_0_2 = {
	id: '26',
	name: 'work_complete',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),

	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: 'TL Work Complete',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'mulSpService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		files: {
			serviceVariable: 'mulSpService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	instructions: {
		editRoles: {
			0: 'Process job for payment'
		},
		viewRoles: {
			0: 'Wait for SP to start job'
		}
	},
	startNode: 'Decision',
	nodes: {
		Decision: {
			hideTabItem: true,
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => EMPTY
			},
			decisions: {
				selectInstallationFlow: (navs: any, store: any) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile((x: any) => !x),
							take(1),
							map((res: any) => {
								const fulljob = res as any;
								// const claimtype: string = fulljob?.claim?.type; *  not sure why this; number for type extracted below
								const claimtype_id: number = +fulljob?.claim?.claim_type;
								const skillOnJob: number = +fulljob?.skill;
								const customerScheduledSkillsForCustomerBilling: number[] = [95, 97, 99, 100];
								const customerScheduledSkillsForValidation: number[] = [96, 101];
								const insuranceClaimyTypes: number[] = [20, 10, 8, 7, 32, 34, 35, 39];

								if (claimtype_id === 26) {
									// console.log('old pricelock');
									store.dispatch(
										new MakeServerCall({
											errorMessage: '',
											directCall: (http: HttpClient, store: Store, sq: any, bf: BigFormService, controller: ManifestController<any>) => {
												controller.dispatch(new SetNextNode('PriceLockFlow'));
												return of({});
											}
										})
									);
								} else if (claimtype_id === 37) {
									// console.log('straight installer own stock');
									store.dispatch(
										new MakeServerCall({
											errorMessage: '',
											directCall: (http: HttpClient, store: Store, sq: any, bf: BigFormService, controller: ManifestController<any>) => {
												controller.dispatch(new SetNextNode('PriceLockFlow'));
												return of({});
											}
										})
									);
								}
								if (claimtype_id === 40 && customerScheduledSkillsForCustomerBilling.includes(skillOnJob)) {
									store.dispatch(
										new MakeServerCall({
											errorMessage: '',
											directCall: (http: HttpClient, store: Store, sq: any, bf: BigFormService, controller: ManifestController<any>) => {
												controller.dispatch(new SetNextNode('CustomerSettledAccount'));
												return of({});
											}
										})
									);
								}
								if (claimtype_id === 40 && customerScheduledSkillsForValidation.includes(skillOnJob)) {
									// console.log('This one is vouchered');
									store.dispatch(
										new MakeServerCall({
											errorMessage: '',
											directCall: (http, store, sq, bf, controller) => {
												controller.dispatch(new SetNextNode('PriceLockFlow'));
												return of({});
											}
										})
									);
								}
								if (claimtype_id === 36 && customerScheduledSkillsForCustomerBilling.includes(skillOnJob)) {
									// console.log('The customer pays this one...');
									store.dispatch(
										new MakeServerCall({
											errorMessage: '',
											directCall: (http: HttpClient, store: Store, sq: any, bf: BigFormService, controller: ManifestController<any>) => {
												controller.dispatch(new SetNextNode('CustomerSettledAccount'));
												return of({});
											}
										})
									);
								}
								if (claimtype_id === 36 && customerScheduledSkillsForValidation.includes(skillOnJob)) {
									// console.log('This one is vouchered');
									store.dispatch(
										new MakeServerCall({
											errorMessage: '',
											directCall: (http, store, sq, bf, controller) => {
												controller.dispatch(new SetNextNode('PriceLockFlow'));
												return of({});
											}
										})
									);
								}
								if (insuranceClaimyTypes.includes(claimtype_id)) {
									console.log('insurance claim');
									store.dispatch(
										new MakeServerCall({
											errorMessage: '',
											directCall: (http: HttpClient, store: Store, sq: any, bf: BigFormService, controller: ManifestController<any>) => {
												controller.dispatch(new SetNextNode('InsuranceFlow_InvoiceSelect'));
												return of({});
											}
										})
									);
								}
							})
						)
						.subscribe();
				}
			},
			navs: []
		},
		// JobInformationNode: {
		// 	...JOB_INFO_NODE(26)
		// },
		JobPhotosNode: {
			...PHOTO_NODE,
			navs: [
				{
					text: 'return to team leader',
					linkType: 'portal',
					color: 'secondary',
					nextNode: 'ReturnToTLReasonEntry',
					serverCalls: {}
				}
			]
		},
		PriceLockFlow: {
			showTabs: true,
			// name: 'Job Information',
			hideTabItem: true,
			serverCalls: {
				installerKeyValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http: HttpClient, store: Store) => {
						return forkJoin([
							store.select(getFullItemTwo)?.pipe(
								skipWhile((itt: any) => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo)?.pipe(
								skipWhile((ai: any) => !ai),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
								skipWhile((x: any) => !x),
								take(1),
								map((res: any) => res['payload'])
							)
						])?.pipe(
							map(([itemTwo, info, teamleaders]: any) => {
								const sp: any = itemTwo?.sp;
								const sps: any = info.sps;
								let companyname: string;
								let accreditation_number: any;
								let qualification_number: any;
								for (let i = 0; i < sps.length; i++) {
									if (sp === sps[i]?.id) {
										companyname = sps[i]?.name;
									}
								}
								const tleads = teamleaders as any;
								let teamleadname = 'Team leader not assigned yet';
								for (let i: any = 0; i < tleads?.length; i++) {
									if (!itemTwo.team_leader) {
										teamleadname = 'Team leader not assigned yet';
									} else if (tleads[i]?.id === itemTwo?.team_leader.id) {
										teamleadname = tleads[i]?.full_name;
									}
								}
								// const accreditation_number =
								if (!itemTwo.team_leader || !itemTwo.team_leader.sp) {
									accreditation_number = 'There is no accreditation number available';
									qualification_number = 'No qualification number for the technician';
								} else {
									accreditation_number = itemTwo?.team_leader?.sp?.accreditation_number;
									qualification_number = itemTwo?.team_leader?.qualification_number;
								}

								const installerinfo = {
									'Company name ': companyname,
									'Accreditation number ': accreditation_number,
									'Installation technician qualification number': qualification_number,
									'Installer/Technician name ': teamleadname
								};
								return [installerinfo];
							})
						);
					}
				},
				customerContactKeyValues: {
					errorMessage: 'Something went wrong customer contact info',
					directCall: (http: HttpClient, store: Store) => {
						return forkJoin([
							store.select(getFullItemTwo)?.pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						])?.pipe(
							map(([itemTwo]) => {
								let joblocation = 'The customer misses data';
								let newAddress;

								const {
									identities: { policy_number, subscriber_number },
									claim: {
										address,
										applicant,
										claim_type,
										loan_information: {
											ContactNumber,
											Email,
											claimlatitude,
											claimlongitude,
											contactnumber,
											email,
											onSiteContact,
											onsitecontact,
											onsitecontact_c,
											onsitecontact_w,
											propertystreetaddress_2,
											propertysuburb
										},
										suburb
									},
								} = itemTwo;

								if (!claimlatitude || !claimlongitude) {
									joblocation = 'At least one coordinate not supplied!';
								}
								if (claimlatitude && claimlongitude) {
									joblocation = `${claimlatitude}, ${claimlongitude}`;
								}
								if (Number(claim_type) !== 26) {
									newAddress = `${address}, ` + `${suburb}`;
								} else {
									newAddress = `${propertystreetaddress_2}, ` + `${propertysuburb}`;
								}

								const contacts = [onSiteContact, onsitecontact_c, onsitecontact_w, onsitecontact];
								const alt = contacts.filter(x => x && x !== ContactNumber);
								const altno = alt.length > 0 ? alt.join(', ') : ContactNumber;

								const customerContactData = {
									'Full name ': `${applicant?.first_name} ` + `${applicant?.surname}`,
									'Subscriber Number': subscriber_number ? subscriber_number : policy_number ? policy_number : 'No subscriber number found!',
									'Installation Address': newAddress,
									'Email address ': Email ? Email : email ? email : 'No email address returned!',
									'Contact number ': ContactNumber ? ContactNumber : contactnumber ? contactnumber : 'No contact number provided/available',
									'Alternate contact numbers': altno ?? 'No alternative numbers found',
									// 'GPS Coordinates ': itemTwo ? itemTwo?.location : '--'
									'GPS Coordinates ': joblocation
								};
								return [customerContactData];
							})
						);
					}
				},
				siteDetailKeyValues: {
					errorMessage: `Could not get the site detail`,
					directCall: (_http: HttpClient, _store: any) => {
						return _store
							.select(getFullItemTwo)
							.pipe(
								skipWhile((res: any) => !res),
								take(1),
								map((result: any) => result)
							)
							.pipe(
								map((fulljob: any) => {
									const claimtype = fulljob?.claim?.claim_type;
									const current_environment = fulljob?.office_use?.current_environment;
									let device_type: string;
									if (claimtype !== 37) {
										return [{ Site_detail_data: 'this data not available' }];
										return null;
									} else {
										const device_details = fulljob?.office_use?.device_details;
										if (device_details instanceof Array) {
											const decodersArray: string[] = [];
											for (let i = 0; i < device_details.length; i++) {
												const decodername: string = device_details[i].replacement_device?.type;
												decodersArray.push(decodername);
											}
											if (decodersArray.length === 2) {
												device_type = decodersArray.join(' and ');
											} else if (decodersArray.length > 2) {
												const last_in_list = decodersArray.pop();
												const others_in_list: string[] = [];
												for (let i = 0; i < device_details.length - 1; i++) {
													const decodername: string = device_details[i].replacement_device?.type;
													others_in_list.push(decodername);
												}
												const prelist: string = others_in_list.join(', ');
												device_type = prelist.concat(' and ', last_in_list);
											}
										} else {
											device_type = device_details?.replacement_device?.type;
										}

										const siteObj = {
											'Decoder Installation': `${device_type}`,
											'Kind of TVs': `${current_environment.type_of_tvs ? current_environment.type_of_tvs : 'Data not available'} `,
											'Home Type': `${current_environment.dwelling_type ? current_environment.dwelling_type : 'Data not available'}`,
											'Existing Decoder': `${current_environment?.existing_decoder === undefined ? 'No' : 'Yes'}`,
											'Number of TVs': `${current_environment?.no_of_tvs ? current_environment?.no_of_tvs : 'Data not available'}`,
											'Wall Type': `${current_environment?.wall_type ? current_environment?.wall_type : 'Data not avaiable'}`
										};
										return [siteObj];
									}
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Installation Details', 'secondary'),
							data$: 'installerKeyValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Contact Details', 'secondary'),
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Site Details', 'secondary'),
							data$: 'siteDetailKeyValues'
							// itemMargin: '0  0 15px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Return to Team Leader',
					linkType: 'portal',
					color: 'secondary',
					nextNode: 'ReturnToTLReasonEntry',
					serverCalls: {}
				},
				{
					text: 'Problem claiming voucher',
					linkType: 'portal',
					color: 'secondary',
					nextNode: 'VoucherClaimIssueEntry'
				},
				{
					text: 'Voucher claimed',
					linkType: 'portal',
					color: 'primary',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.messageOnly = false;
							instance.hasCheck = true;
							(instance.type = 'warning'), (instance.heading = 'Please confirm that you have successfully claimed the voucher on the installer portal');
							instance.setMessage([]);
							instance.navButtons = [
								{
									text: 'CANCEL',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'PROBLEM CLAIMING VOUCHER',
									color: 'secondary',
									location: 'right',
									linkType: 'close',
									nextNode: 'VoucherClaimIssueEntry'
								},
								{
									text: 'yes, voucher claimed',
									color: 'primary',
									linkType: 'submitThenNext',
									disabled: true,
									optIntoValidation: true,
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										response: {
											errorMessage: 'Job could not be submitted to Invoicing',
											directCall: (http, store) => {
												return store
													.select(getFullItemTwo)
													.pipe(
														skipWhile(x => !x),
														take(1),
														map(res => res as any)
													)
													.pipe(
														map(j => {
															const job = j as any;
															const job_id: number = job?.id;
															const new_state = job?.job_information?.unable_to_activate_reason === 'Activation failed' ? 186 : 176;
															return {
																job_id: job_id,
																new_state: new_state
															};
														}),
														switchMap(data => {
															return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
														})
													);
											}
										}
									}
								}
							];
						}
					}
				}
			]
		},
		ReturnToTLReasonEntry: {
			checkValidityForFields: ['return_tl_reason'],
			showTabs: false,
			hideTabItem: true,
			initFormFields: (_bf: any) => {
				_bf.addControl('return_tl_reason', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Return to Team Leader',
							formControls: {
								0: {
									label: 'Please enter the reason for returning this to the team leader',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'return_tl_reason'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Set Appointment',
					optIntoValidation: true,
					// linkType: 'portal',
					nextNode: 'SetAppointment',
					color: 'primary'
				}
			]
		},
		VoucherClaimIssueEntry: {
			showTabs: false,
			hideTabItem: true,
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.patchValues({ new_state: 176 });
				combineLatest([store.select(getCurrentUser)?.pipe(filter(x => !!x, take(1))), store.select(getFullItemTwo)?.pipe(filter(x => !!x, take(1)))])
					.pipe(take(1))
					.subscribe(([author, job]) => {
						bf.patchValues({ author: author.user.id });
						bf.patchValues({ author_name: author.user.full_name });
						bf.patchValues({ current_state: job.state });
					});
				bf.addControl('voucherclaim_fail_reason', new UntypedFormControl(''));
				bf.addControl('voucherclaim_fail_comments', new UntypedFormControl(''));
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: `Reason voucher couldn't be claimed`,
							formControls: {
								0: {
									label: 'Select the reason for claim failure',
									inputType: 'select',
									selectConfig: {
										displayOptions: { displayKey: 'display', valueKey: 'value' },
										itemsOption: [
											{ display: 'Job not completed, claim rejected', value: 'Job not completed, claim rejected' },
											{ display: 'Job already completed, claim rejected', value: 'Job already completed, claim ejected' },
											{ display: 'Other', value: 'Other' }
										],
										placeholder: 'Reason for cancellation'
									},
									formControlName: 'voucherclaim_fail_reason'
								},
								1: {
									label: 'Enter any comments you have',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'voucherclaim_fail_comments'
								}
							},
							formLayout: 'stacked',
							containerWidth: '30vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			checkValidityForFields: ['voucherclaim_fail_reason', 'voucherclaim_fail_comments'],
			navs: [
				{
					text: 'Submit',
					optIntoValidation: true,
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not submit comment to support agent',
							serviceVariable: 'mulSpService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
			SetAppointment: {
				hideTabItem: true,
				inputs: {
					minDate: new Date()
				},
				checkValidityForFields: ['appointmentData'],
				initFormFields: (_bf: BigFormService, it: any, instance: any, sq: any, _store: Store) => {
					_bf.patchValues({ new_state: 205 });
					combineLatest([_store.select(getCurrentUser).pipe(filter(x => !!x, take(1))), _store.select(getFullItemTwo).pipe(filter(x => !!x, take(1)))])
						.pipe(take(1))
						.subscribe(([author, job]) => {
							_bf.patchValues({ author: author.user.id });
							_bf.patchValues({ author_name: author.user.full_name });
							_bf.patchValues({ current_state: job?.state });
						});
					_bf.addControl(
						'appointmentData',
						new UntypedFormGroup({
							appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
							appointmentTime: new UntypedFormControl(null, [Validators.required]),
							appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
							appointmentDateType: new UntypedFormControl(null, [Validators.required]),
							skill: new UntypedFormControl('Dish Installation')
						})
					);
					_bf.patchValues({
						appointmentData: _bf.getControl('appointmentData')?.value || ''
					});
				},
				serverCalls: {
					customer_details: {
						errorMessage: 'No customer details were found!',
						directCall: (http, store,) => {
							return store
								.select(getFullItemTwo)
								.pipe(
									skipWhile(x => !x),
									take(1),
									map(res => res as any)
								)
								.pipe(
									map(fulljob => {
										const { claim } = fulljob;
										const { loan_information } = claim;
										const claimtype: number = claim?.claim_type;
										const name = `${claim?.applicant?.first_name} ` + `${claim?.applicant?.surname}`;
										let number;
										let mobile;
										if (claimtype !== 26) {
											number = loan_information?.contactnumber;
											mobile = loan_information?.cellnumber;
										} else {
											number = loan_information?.ContactNumber;
											mobile = loan_information?.cellnumber;
										}

										return [{ 'Client name': name }, { 'Contact number': number }, { 'Mobile number': mobile }];
									})
								);
						}
					},
					onsite_details: {
						errorMessage: 'Onsite details not found!',
						directCall: (http, store) => {
							return store
								.select(getFullItemTwo)
								.pipe(
									skipWhile(x => !x),
									take(1),
									map(res => res as any)
								)
								.pipe(
									map(fulljob => {
										const { claim } = fulljob;
										const { loan_information } = claim;
										const claimtype: number = claim?.claim_type;
										let onsitenumber;
										if (claimtype !== 26) {
											onsitenumber = loan_information?.onsitecontact;
										} else {
											onsitenumber = loan_information?.onsitecontact_c;
										}
										return [{ 'On-site contact name': loan_information?.onsiteperson }, { 'On-site contact number': onsitenumber }];
									})
								);
						}
					},
					appointmentTypes: {
						serviceVariable: 'service',
						functionName: 'getAppointmentTypes',
						errorMessage: 'No Appointment Types could be found!'
					}
				},
				navs: [
					{
						optIntoValidation: true,
						text: 'Set Appointment',
						linkType: 'submit',
						color: 'primary',
						nextNode: 'SubmissionSuccess',
						serverFirst: true,
						serverCalls: {
							response: {
								errorMessage: 'Appointment not set!',
								serviceVariable: 'mulSpService',
								functionName: 'updateJob'
								// directCall: (http: any, store: any, sq: any, bf: any) => {
								// 	return forkJoin([
								// 		store.select(getFullItemTwo).pipe(
								// 			skipWhile(x => !x),
								// 			take(1)
								// 		),
								// 		store.select(getSubmissionData).pipe(
								// 			skipWhile(x => !x),
								// 			take(1)
								// 		)
								//     // ,
								// 		// of(bf.bigForm.get('return_tl_reason').value)
								// 	]).pipe(
								// 		map(([job, submit]: any) => {
								// 			const { job_information, id } = job;
								// 			const jobinfo = JSON.parse(JSON.stringify(job_information));
								// 			const current_state = job?.state;
								// 			const new_state = submit.new_state;
								// 			const appointment = submit.appointment;
								// 			// const return_tl_reason = returnreason;
								// 			const jobinformation = addObjectProperty('return_tl_reason', return_tl_reason, jobinfo);
								// 			return {
								// 				job_id: id,
								// 				current_state: current_state,
								// 				new_state: new_state,
								// 				appointment: appointment,
								// 				job_information: jobinformation
								// 			};
								// 		}),
								// 		switchMap(data => {
								// 			return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
								// 		})
								// 	);
								// }
							}
						}
					}
				],

				component: 'FLXJobAppointmentComponent'
			},
		SubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			navs: []
		},
		InsuranceFlow_InvoiceSelect: {
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'manual'
			},
			showTabs: true,
			hideTabItem: true,
			initFormFields: (bf) => {
				bf.bigForm.addControl('purpose', new UntypedFormControl('invoice'));
				bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
			},
			checkValidityForFields: ['purpose', 'fileQueue'],
			component: {
				children: [
					{
						component: 'FLXFileUploadWithPreviewComponent',
						inputs: {
							purposes: [{ display: 'Invoice', value: 'invoice' }],
							heading: 'Select the invoice to attach',
							multipleUploads: false,
							allowedFileTypes: ['pdf'],
							containerWidth: '50vw',
							maxWidth: '50vw'
						}
					}
				]
			},
			navs: [
				{
					text: 'return to team leader',
					linkType: 'portal',
					color: 'secondary',
					nextNode: 'ReturnToTLReasonEntry',
					serverCalls: {}
				},
				{
					text: 'Upload Invoice',
					// nextNode: 'InsuranceInvoice_SaveTotal',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit',
					serverFirst: true,
					serverCalls: {
						// errorHandler: ,
						response: {
							errorMessage: 'Invoice could not be uploaded',
							directCall: (http, store, sq, bf, _controller: ManifestController<any>, _modal: ModalService) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0] ? fileQueue[0]?.file.base64 : '',
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								const promise = new Promise(() => {
									const uploadfile = http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
										observe: 'events',
										reportProgress: true
									});

									uploadfile
										.toPromise()
										.then(() => {
											_controller.dispatch(new SetNextNode('InsuranceInvoice_SaveTotal'));
										})
										.catch(result => {
											_modal.openModalDirectly(instance => {
												instance.heading = 'File Upload Error';
												instance.type = 'warning';
												instance.message = `${result?.error?.reason}, please try again.`;
												instance.navButtons = [
													{
														text: `Close`,
														linkType: 'close',
														color: 'warn'
													}
												];
											});
										});
								});

								return of(promise);
							}
						}
					}
				}
			]
		},
		// FileSubmissionSuccess1: {
		// 	hideTabItem: true,
		// 	component: 'FLXSuccessTickComponent',
		// 	inputs: { autoClose: false, heading: 'File Added to Upload Queue' },
		// 	navs: [
		// 		{
		// 			text: 'Continue',
		// 			nextNode: 'InsuranceInvoice_SaveTotal'
		// 		}
		// 	]
		// },
		InsuranceInvoice_SaveTotal: {
			serverCalls: {
				invoice: {
					serviceVariable: 'mulSpService',
					functionName: 'getJobInvoice',
					errorMessage: 'Could not get files from server!'
				}
			},
			checkValidityForFields: ['job_total'],
			hideTabItem: true,
			showTabs: false,
			initFormFields: (bf) => {
				bf.patchValues({ new_state: 253 });
				bf.bigForm.addControl('job_total', new UntypedFormControl('', [Validators.required, CustomValidators.noSpaces]));
			},
			component: {
				children: [
					{
						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: {
							dataFiles$: 'invoice',
							inputHeading: 'Confirm Invoice Amount',
							formInputs: {
								0: {
									formControlName: 'job_total',
									inputType: 'currency',
									label: 'Enter invoice total.'
								}
							},
							formLayout: 'three-columnm',
							containerWidth: '20vw'
						}
					}
				]
			},
			navs: [
				{
					text: 'return to team leader',
					linkType: 'portal',
					color: 'secondary',
					nextNode: 'ReturnToTLReasonEntry',
					serverCalls: {}
				},
				{
					text: 'upload invoice',
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'mulSpService',
							functionName: 'updateJob',
							errorMessage: 'Could not update job!'
						}
					},
					optIntoValidation: true,
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		// FileSubmissionSuccess: {
		// 	hideTabItem: true,
		// 	component: 'FLXSuccessTickComponent',
		// 	inputs: { autoClose: false, heading: 'File Successfully Uploaded' },
		// 	navs: [
		// 		{
		// 			text: 'Continue',
		// 			serverCalls: {
		// 				response: {
		// 					serviceVariable: 'mulSpService',
		// 					functionName: 'updateJob',
		// 					errorMessage: 'Could not update job!'
		// 				}
		// 			},
		// 			nextNode: 'SubmissionSuccess'
		// 		}
		// 	]
		// },
		SubmissionSucccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent'
		},
		CustomerSettledAccount: {
			initFormFields: (bf: any, item, instance, sq: any, _store: Store) => {
				console.log('initi formn filfi');
				_store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1),
						map(mapped => mapped)
					)
					.pipe(map(() => console.log('INIT FORM FIELDS')))
					.subscribe();
				bf.patchValues({ new_state: 28 });
				bf.patchValues({ new_claim_state: 7 });
			},
			component: 'FLXHeadingWithInstructionsComponent',
			inputs: {
				title: 'Settle this account with the customer',
				instructions: [`Click 'Close Job' to remove this job from your workflow`]
			},
			navs: [
				{
					text: 'Close Job',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Couldn't update this job?.`,
							directCall: (http: any, store: any, sq: any, bf: any) => {
								return store.select(getFullItemTwo)?.pipe(
									skipWhile((data: any) => !data),
									take(1),
									map((result: any) => result?.id),
									switchMap((id: any) => {
										const new_state = bf.getControl('new_state').value;
										const new_claim_state = bf.getControl('new_claim_state').value;

										const data = {
											job_id: id,
											new_state: new_state,
											new_claim_state: new_claim_state
										};
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			]
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		current_state: [() => 26, 'current_state'],
		job_total: [(ttl) => ttl, 'job_information.claim_value'],
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					return {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
				}
			},
			'appointment'
		],
		assign_teamleader_id: [
			tlid => {
				let id;
				tlid = tlid && Array.isArray(tlid) ? tlid : [];
				for (const lead of tlid) {
					id = parseInt(lead, 10);
				}
				return id;
			},
			'job_information.team_leader'
		],
		return_tl_reason: [
			(_reason: string, _storeobj: any) => {
				const return_tl_reasons = _storeobj['selectedContext']?.fullItemTwo?.job_information?.return_tl_reasons;
				if (_reason) {
					const return_reasons = [];
					const date = new Date();
					const new_reason = {
						reason: _reason,
						author_name: _storeobj?.identity?.currentUser?.user?.full_name,
						time_stamp: convertDateTimeToTimeStamp(date.toDateString())
					};
					if (return_tl_reasons) {
						Array.isArray(return_tl_reasons) ? return_reasons?.push(...return_tl_reasons, new_reason) : return_reasons?.push(return_tl_reasons, new_reason);
					} else {
						return_reasons.push(new_reason);
					}
					return return_reasons;
				} else {
					return return_tl_reasons;
				}
			},
			'job_information.return_tl_reasons'
		],
		voucherclaim_fail_reason: ['job_information.voucherclaim_fail_reason'],
		voucherclaim_fail_comments: [
			(comment, storeObj, bf) => {
				let voucherfailcomments = [];
				const date = new Date();
				const newReason = {
					reason: comment,
					author_id: bf?.author,
					author_name: bf?.author_name,
					currentState: bf?.current_state,
					nextState: bf?.new_state,
					timeStamp: convertDateTimeToTimeStamp(date.toDateString())
				};
				const tempcomments = storeObj?.['selectedContext']?.fullItemTwo?.job_information?.voucher_failreasons;
				if (tempcomments) {
					if (Array.isArray(tempcomments)) {
						voucherfailcomments = tempcomments;
					} else {
						voucherfailcomments = [
							{
								...tempcomments
							}
						];
					}
				}
				const voucherfailreason = [...voucherfailcomments, newReason];
				return voucherfailreason;
			},
			'job_information.voucherclaim_fail_comments'
		]
	}
};
