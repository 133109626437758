import { ModuleWithProviders, NgModule } from '@angular/core';
import { FLXCompositeFormsModule } from './forms';
import { FLXListAndKeysInfoModule } from './list-and-keys-info-screen';
import { FLXKeyValueDisplayWithInstructionsModule } from './key-value-display-with-instructions';
import { FLXSetAppointmentModule } from './set-appointment';
import { FLXAccordionModule } from './accordion-template';
import { FLXFileUploadWithPreviewModule } from './file-upload-with-preview';
import { FLXAlertMessagesModule } from './alert-messages';
import { FLXNoteListModule } from './note-list/note-list.module';
import { FLXHeadingWithInstructionsModule } from './heading-with-instructions/heading-with-instructions.module';
import { FLXButtonListWithInstructionsModule } from './button-list-with-instructions';
import { FLXSearchBarModule } from './search-bar';
import { FLXDynamicFilterModule } from './dynamic-filter';
import { FLXSetExcessModule } from './set-excess';
import { FLXRemindersModule } from './reminders';
import { FLXViewPhotosModule } from './view-photos';
import { SpUserCardsModule } from './sp-user-cards';
import { FLXSpPingWindowModule } from './sp-ping-window';
import { FLXDecoderInstallationJobFailCorrectorModule } from './item-verify-from-image';
import { FLXSpJobNotificationsWindowModule } from './sp-job-notifications';
import { CSVPaymentItemModule } from './csv-payment-item';
import { FLXMultiFileUploadViewerModule } from './multi-file-upload-viewer';
import { FLXImageUploadCardModule } from './image-upload-card/image-upload-card.module';
import { FLXHeadingWithULModule } from './heading-with-ul';
import { FLXPopupsModule } from './popups';
import { FLXInvoiceDaterangePickerModule } from './invoice-daterange-picker';
import { RemindersCoreModule } from './reminders-core';
import { FLXDynamicChartModule } from './dynamic-chart';

export const UIModules: any[] = [
  RemindersCoreModule,
	FLXAccordionModule,
	FLXCompositeFormsModule,
	FLXHeadingWithInstructionsModule,
	FLXKeyValueDisplayWithInstructionsModule,
	FLXSetAppointmentModule,
	FLXListAndKeysInfoModule,
	FLXFileUploadWithPreviewModule,
	FLXAlertMessagesModule,
	FLXNoteListModule,
	FLXButtonListWithInstructionsModule,
	FLXSearchBarModule,
	FLXDynamicFilterModule,
	FLXSetExcessModule,
	FLXViewPhotosModule,
	FLXRemindersModule,
	SpUserCardsModule,
	FLXSpPingWindowModule,
	FLXDecoderInstallationJobFailCorrectorModule,
	FLXSpJobNotificationsWindowModule,
	CSVPaymentItemModule,
	FLXMultiFileUploadViewerModule,
	FLXImageUploadCardModule,
	FLXHeadingWithULModule,
	FLXPopupsModule,
	FLXInvoiceDaterangePickerModule
];

@NgModule({
	declarations: [],
	imports: [
    RemindersCoreModule,
    FLXAccordionModule,
    FLXCompositeFormsModule,
    FLXHeadingWithInstructionsModule,
    FLXKeyValueDisplayWithInstructionsModule,
    FLXSetAppointmentModule,
    FLXListAndKeysInfoModule,
    FLXFileUploadWithPreviewModule,
    FLXAlertMessagesModule,
    FLXNoteListModule,
    FLXButtonListWithInstructionsModule,
    FLXSearchBarModule,
    FLXDynamicFilterModule,
    FLXSetExcessModule,
    FLXViewPhotosModule,
    FLXRemindersModule,
    SpUserCardsModule,
    FLXSpPingWindowModule,
    FLXDecoderInstallationJobFailCorrectorModule,
    FLXSpJobNotificationsWindowModule,
    CSVPaymentItemModule,
    FLXMultiFileUploadViewerModule,
    FLXImageUploadCardModule,
    FLXHeadingWithULModule,
    FLXPopupsModule,
    FLXInvoiceDaterangePickerModule
  ],
	exports: [
    RemindersCoreModule,
    FLXAccordionModule,
    FLXCompositeFormsModule,
    FLXHeadingWithInstructionsModule,
    FLXKeyValueDisplayWithInstructionsModule,
    FLXSetAppointmentModule,
    FLXListAndKeysInfoModule,
    FLXFileUploadWithPreviewModule,
    FLXAlertMessagesModule,
    FLXNoteListModule,
    FLXButtonListWithInstructionsModule,
    FLXSearchBarModule,
    FLXDynamicFilterModule,
    FLXSetExcessModule,
    FLXViewPhotosModule,
    FLXRemindersModule,
    SpUserCardsModule,
    FLXSpPingWindowModule,
    FLXDecoderInstallationJobFailCorrectorModule,
    FLXSpJobNotificationsWindowModule,
    CSVPaymentItemModule,
    FLXMultiFileUploadViewerModule,
    FLXImageUploadCardModule,
    FLXHeadingWithULModule,
    FLXPopupsModule,
    FLXInvoiceDaterangePickerModule,
    FLXDynamicChartModule
  ]
})
export class UICompositesModule {
	static forRoot(env: any): ModuleWithProviders<UICompositesModule> {
		return {
			ngModule: UICompositesModule,
			providers: [{ provide: 'environment', useValue: env }]
		};
	}
}
