import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';

@Component({
	selector: 'flx-close-claim',
	templateUrl: './close-claim.component.html',
	styleUrls: ['./close-claim.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseClaimComponent implements OnInit, OnDestroy {
	@Input() closeClaimSummary$: Observable<any>;
	@Input() canCloseClaim$: Observable<any>;
	@Input() paymentsExist$: Observable<any>;
	@Input() title: string;
	@Input() subtitle: string;
	@Input() maven_claim_stateArray: any[];
	@Input() reasonsArray: any[];
	closeClaimSubsription: Subscription;
	closeClaimInfo: any;
	maven_paid: boolean;
	maven_state_description: any;
	reasons = [];

	isChecked(value) {
		if (value === false) {
			return false;
		} else {
			return true;
		}
	}
	isConsignmentChecked(value) {
		if (value === 1) {
			return true;
		} else {
			return false;
		}
	}

	getCloseClaimInfo() {
		this.closeClaimSubsription = this.closeClaimSummary$
			.pipe(
				skipWhile(x => !x),
				take(1),
				map(closingData => closingData)
			)
			.subscribe(closeClaim => {
				if (closeClaim) {
					const { reason, ...rest } = closeClaim;
					const {
						payload: { maven_claim_state }
					} = rest;
					this.maven_paid = closeClaim.payload.maven_paid;
					if (reason[0] !== undefined) {
						for (let i = 0; i <= reason.length - 1; i++) {
							if (reason[i] === this.reasonsArray.findIndex((res: any) => res?.id === reason[i])) {
								this.reasons.push(this.reasonsArray[reason[i]].description);
							}
						}
					}
					if (maven_claim_state !== undefined) {
						this.maven_state_description = this.maven_claim_stateArray.find(val => val.id === maven_claim_state)?.description;
					}
				}
			});
	}

	ngOnInit() {
		this.getCloseClaimInfo();
	}

	ngOnDestroy() {
		if (this.closeClaimSubsription) {
			this.closeClaimSubsription.unsubscribe();
		}
	}
}
