import { Action } from '@ngrx/store';

export enum ThemeActionTypes {
	SET_THEME = '[Theme] Toggle Theme',
	GET_ACTIVE_THEME = '[Theme] Get Active Theme'
}

export class SetTheme implements Action {
	readonly type = ThemeActionTypes.SET_THEME;
	constructor(public payload: { name: string }) {}
}

export class GetActiveTheme implements Action {
	readonly type = ThemeActionTypes.GET_ACTIVE_THEME;
}

export type ThemeActions = SetTheme | GetActiveTheme;
