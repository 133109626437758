import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';

import { skipWhile, map, take } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AUTO_SELECT_TEMPLATE_DECISION, BILLING_INVOICE, billingServerCalls, SELECT_CALLOUT_TEMPLATE } from './BILLING';
import { environment } from 'apps/studio/src/environments/environment';

export const SP_GLOBAL_67: Flow_0_0_2 = {
	id: '67',
	name: 'no_work_warranty_claim',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant) {
							return `No work - Warranty : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'No work - Warranty';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		...billingServerCalls
	},
	instructions: {
		editRoles: {
			0: 'Contact Manufacturer for Warranty Replacement'
		},
		viewRoles: {
			0: 'SP getting Warranty Replacement'
		}
	},
	startNode: 'SmallJobNotification',
	nodes: {
		SmallJobNotification: {
			initFormFields: (bf, item, instance, storeQuery, store) => {
				let manufacturercontact, manufacturerwarrantyref, manufacturercalldate;

				if (bf.bigForm.get('new_state')) {
					bf.bigForm.get('new_state')?.patchValue(53);
				} else {
					bf.bigForm.addControl('new_state', new UntypedFormControl(53));
				}

				bf.bigForm.addControl('manufacturercontact', new UntypedFormControl('', Validators.required));
				bf.bigForm.addControl('manufacturerwarrantyref', new UntypedFormControl('', Validators.required));
				bf.bigForm.addControl('manufacturercalldate', new UntypedFormControl('', Validators.required));
				store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(data => !data),
						take(1)
					)
					.subscribe(data => {
						manufacturercontact = data?.job_information?.geyser_warranty.manufacturercontact || '';
						manufacturerwarrantyref = data?.job_information?.geyser_warranty.manufacturerwarrantyref || '';
						manufacturercalldate = data?.job_information?.geyser_warranty.manufacturercalldate || '';
						bf.bigForm.patchValue({ manufacturercontact, manufacturerwarrantyref, manufacturercalldate });
					});
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'No job data could be retrieved',
					directCall: (http, store) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const list = {
										'Claim Type : ': itemTwo?.claim?.type ?? '',
										'Client : ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : '',
										'Contact Number : ': itemTwo?.claim?.applicant?.contact_number ?? '',
										'Cell Number : ': itemTwo?.claim?.loan_information?.cellnumber ?? '',
										'Address : ': itemTwo ? itemTwo?.address : ''
									};
									return [list];
								})
							);
					}
				},
				keyValueList2: {
					errorMessage: 'No geyser information returned',
					directCall: (_h, store) =>
						store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									return [
										{
											'Geyser Information : ': {
												'Make : ': itemTwo?.job_information?.geyser_original?.make ?? '',
												'Size : ': itemTwo?.job_information?.geyser_original?.size ?? '',
												'Serial Number : ': itemTwo?.job_information?.geyser_original?.code ?? ''
											}
										}
									];
								})
							)
				}
			},
			inputs: {
				title: 'No work required - Invoice call-out fee',
				instructions: ['No work or minor work or assessor required', 'Invoice a call-out fee']
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Warranty Claim',
							instructions: ['Contact the the geyser manufacturer to arrange for geyser replacement under warranty']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList2' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'manufacturercontact',
									inputType: 'input',
									label: 'Manufacturer Contact Person'
								},
								1: {
									formControlName: 'manufacturerwarrantyref',
									inputType: 'input',
									label: 'Manufacturer Reference'
								},
								2: {
									formControlName: 'manufacturercalldate',
									inputType: 'date',
									label: 'Date of Call',
									autocomplete: 'off'
								}
							},
							containerWidth: '250px'
						}
					}
				]
			},
			checkValidityForFields: ['manufacturercontact', 'manufacturerwarrantyref', 'manufacturercalldate'],
			navs: [
				{
					text: 'Continue',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
					// TODO: Was Not In BET LOGIC:::: serverCalls: {
					// 	response: {
					// 		serviceVariable: 'spService',
					// 		functionName: 'updateJob',
					// 		errorMessage: 'Job could not be updated!!'
					// 	}
					// },
					// serverFirst: true,
					optIntoValidation: true
				}
			]
		},
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'invoice',
				numberLabel: 'Invoice',
				currentState: 67,
				newState: 27,
				showLineItemGenerator: true,
				canEditLineItems: true,
				boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
				boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Standard Bank Insurance Limited',
				clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL'
			},
			navs: [
				{
					text: 'Generate Invoice',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		manufacturercontact: 'itemTwo?.job_information?.gyeser_warranty.manufacturercontact',
		manufacturerwarrantyref: 'itemTwo?.job_information?.gyeser_warranty.manufacturerwarrantyref',
		new_state: [
			(state, storeObj, formValue, bf) => {
				return 70;
			},
			'new_state'
		],
		total: [
			(a, b, bf) => {
				return bf.total + bf.vatRate;
			},
			'job_information.quote_amount'
		],
		invoiceNumber: 'job_information.quote_number'
	}
};
