import { Flow_0_0_2, getAllInfo, getFullItemTwo } from '@flexus/core';
import { map, pluck, skipWhile, switchMap, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';
import { environment } from '../../../../environments/environment';
import { SP_GLOBAL_21 } from './SP_GLOBAL_21';
import moment from 'moment';
import { findName } from '@flexus/utilities';

export const SP_GLOBAL_82: Flow_0_0_2 = {
	id: '82',
	name: 'new_job_on_existing_claim_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Job created - Allocated : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Job created - allocated';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Create a new job on existing claim'
		}
	},
	serverCalls: {
		...SP_GLOBAL_21.serverCalls
	},
	startNode: 'Summary',
	nodes: {
		...SP_GLOBAL_21.nodes,
		Summary: {
			serverCalls: {
				claimDetailKeyValues: {
					errorMessage: 'Something went wrong claim details',
					directCall: (_h, store) =>
						forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile((allInfo: any) => !allInfo),
								pluck('appointment_types'),
								take(1)
							)
						]).pipe(
							map(([itemTwo, appointmentTypes]) => {
								const appointment = [...itemTwo.appointment]?.sort((item1, item2) => item2?.id - item1?.id);
								const date = appointment[0]?.range_start ? moment(appointment[0]?.range_start) : undefined;

								const claimdetaillist = {
									'Claim Type ': itemTwo?.claim?.type ?? '',
									'Skill required ': itemTwo?.office_use?.skillcatagory ?? '',
									'Address ': itemTwo?.address ?? '',
									'Appointment Date ': date?.format('YYYY-MM-DD') ?? 'No appointment date set',
									'Appointment Time ': date
										? `${findName(appointment[0]?.appointment_type, appointmentTypes)?.name ?? ''} ${date?.format('HH:mm')}${
												appointment[0]?.range_end ? ' - ' + moment(appointment[0]?.range_end).format('HH:mm') : ''
										  }`
										: 'Appointment time not retrieved'
								};
								return [claimdetaillist];
							})
						)
				},
				customerContactKeyValues: {
					errorMessage: 'Customer contact information could not be retrieved',
					directCall: (http, store, sq, bf) => {
						return forkJoin([
							store.select(getFullItemTwo)?.pipe(
								skipWhile(f => !f),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo)?.pipe(
								skipWhile(i => !i),
								take(1),
								map(res => res as any)
							)
						])?.pipe(
							map(([itemTwo, info]) => {
								console.log(`INFO`, info);
								console.log('Excess', itemTwo?.excess);
								const job_id = itemTwo?.id;
								const job_excess = itemTwo?.excess;
								let excess_collection: string;
								let excess_amount: string;
								let excess_method: string;
								const excess_whoArr: any = info.excess_who;
								const excess_howArr: any = info.excess_how;
								for (let i = 0; i <= job_excess.length - 1; i++) {
									if (job_excess[i]?.job === job_id) {
										excess_amount = job_excess[i]?.amount;
									}
								}
								for (let i = 0; i <= job_excess.length - 1; i++) {
									if (job_excess[i]?.job === job_id) {
										excess_whoArr.forEach(element => {
											if (element.id === job_excess[i]?.who_collects) {
												excess_collection = element.name;
											}
										});
									}
								}
								for (let i = 0; i <= job_excess.length - 1; i++) {
									if (job_excess[i]?.job === job_id) {
										excess_howArr.forEach(element => {
											if (element.id === job_excess[i]?.how_collect) {
												excess_method = element.name;
											}
										});
									}
								}
								const customercontactlist = {
									'Client name ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : 'Customer name not available',
									'Contact number ': itemTwo?.claim?.loan_information?.contactnumber ?? 'Contact number not on record',
									'Client cell ': itemTwo?.claim?.loan_information?.cellnumber ?? 'Cellular number not on record',
									'Excess Collection ': excess_collection ? `${excess_collection} to collect` : '-'
								};
								return [customercontactlist];
							})
						);
					}
				},
				whatMattersKeyValue: {
					errorMessage: `'What Matters' data could not be retrieved`,
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const whatmatters = {
										'What matters to the customer ': itemTwo?.claim?.loan_information?.whatmatters ?? `'What matters' not provided`
									};
									return [whatmatters];
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'New job allocated on existing claim',
							itemMargin: '0 0 35px 0',
							instructions: [
								'A new job has been allocated on a claim you are already attending to',
								'Please indicated if you are able to get to the job at the required appointment time.'
							]
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'What Matters',
							data$: 'whatMattersKeyValue',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'I accept the job',
					nextNode: 'JobAllocationNotification'
				},
				{
					text: 'I decline the job',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Could not update job',
							directCall: (http, store) =>
								store.select(getFullItemTwo)?.pipe(
									skipWhile(x => !x),
									take(1),
									switchMap(job => {
										const data = {
											job_id: job?.id,
											job_information: job?.job_information,
											current_state: job?.state,
											new_state: 77
										};
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								)
						}
					}
				}
			]
		},
		JobAllocationNotification: {
			...SP_GLOBAL_21.nodes.JobAllocationNotification,
			component: {
				children: [
					{
						component: 'FLXKeyValueDisplayWithInstructionsComponent',
						inputs: {
							title: 'Job Allocation',
							instructions: ['You have been awarded the job. Please proceed and allocate a Team Leader to perform the work.']
						}
					},
					...(SP_GLOBAL_21.nodes.JobAllocationNotification.component as any)?.children.slice(1)
				]
			}
		}
	},
	bigFormToStoreMapper: {
		...SP_GLOBAL_21.bigFormToStoreMapper
	}
};
