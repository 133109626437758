import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { skipWhile, take, map } from 'rxjs/operators';
import { BUI_97 } from './BUI_97';

export const BUI_106: Flow_0_0_2 = {
	id: '106',
	name: 'assessor_on_site',
	itemType: 'flow',
	fetchLevel1And2: BUI_97.fetchLevel1And2,
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					if (itemOne) {
						let title = 'Assessor on Site';
						if (itemOne?.loan_information?.mavenclaimnumber) {
							title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
						}
						if (itemOne.applicant?.surname) {
							title += ` - ${itemOne.applicant?.surname}`;
						}

						return title;
					} else {
						return 'Assessor on Site';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		...BUI_97.footer
	},
	instructions: {
		...BUI_97.instructions
	},
	actionPanel: BUI_97.actionPanel,
	serverCalls: {
		...BUI_97.serverCalls
	},
	startNode: 'AssessorOverview',
	nodes: {
		...BUI_97?.nodes
	},
	bigFormToStoreMapper: {
		...BUI_97.bigFormToStoreMapper
	}
};
