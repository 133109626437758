import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import {  filter, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest,  Subscription } from 'rxjs';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, MakeBillingServerCall, getAvailableLineItems, getBOQ, getExcess, getExcessChangeDetails, getFullItemTwo, getTravelDetails } from '@flexus/core';
import { Store } from '@ngrx/store';
import { difference, clone } from 'ramda';
import { FLXModalComponent, ModalNavButton } from '@flexus/ui-elements';
import { decimalQuantityValidator, nonNegativeQuantityValidator, numberQuantityValidator } from '@flexus/utilities';
import { BillingLineItem, IExcessChangeData, iBillingTemplate, iLineItem } from '@flexus/models';

@Component({
	selector: 'flx-line-items',
	templateUrl: './line-items.component.html',
	styleUrls: ['./line-items.component.scss']
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineItemsComponent implements AfterViewInit, OnInit, OnDestroy {
	@Input() boqLogo: string;
	@Input() boqLogoAlt: string;
	@Input() clientName = 'SIL';
	@Input() saveDraft = false;
	@Input() actualLineItemsFormArray: FormArray;
	@Input() showLineItemGenerator: boolean;
	@Input() canEditLineItems: boolean;
	@Input() currentState: number;
	@Input() rejectBoqLineItems: boolean;

	_subscription: Subscription;
	_subSaveDraftQuote: Subscription;
	_editSubscriptions: Subscription[] = [];
	_createSubscription: Subscription;
	availableLineItems: iLineItem[] = [];
	boqItems$: BehaviorSubject<any[]> = new BehaviorSubject([]);
	isInvoiceOnlyState = false;
	private sub: Subscription;
	callOutStates = [25, 65, 66, 67, 69, 80, 94, 105, 171, 206, 207];
	invoiceOnlyStates = [26, 46, 47];
	selectedTemplate: iBillingTemplate;
	modificationType: string;
	modalOpen = false;
	itemToEdit: BillingLineItem;
	createLineItemForm: AbstractControl;
	private isAfterHoursItem: boolean;
	private isHomeAssistItem: boolean;
	modalButtons: ModalNavButton[];
	maximumTravelDistance = 0;
	@ViewChild('travelCostsModal', { static: false }) travelCostsModal: FLXModalComponent;
	@ViewChild('changeExcessModal') changeExcessModal: FLXModalComponent;
	allow_custom_items = true;
	private excessChangeDetails: IExcessChangeData;
	excessChangeModalStatement =
		'Please note if the Invoice total amounts change, the excess is subject to change. <br /> The Service Provider is liable to collect the additional excess';
	excessChangeModalButtons: ModalNavButton[];
	private hasExcessChangedCallInit = false;
	private job;
	private excess;
	travelCostModalResponded = false;

	constructor(private bf: BigFormService, private formBuilder: UntypedFormBuilder, private store: Store<any>, private cdr: ChangeDetectorRef) {}

	ngAfterViewInit(): void {}

	ngOnInit() {
		this.initTravelCostsModal();
		console.log({ actualLineItemsFormArray: this.actualLineItemsFormArray });

		const availableLineItems$ = this.store.select(getAvailableLineItems);

		const boq$ = this.store.select(getBOQ).pipe(
			map(boq => {
				let actualLineItems = [];

				// Mark if boq items exist so you update rather than create
				if (boq?.line_items) {
					this.bf.bigForm.get('hasBoq').patchValue(true);
					actualLineItems = boq.line_items.map((lineItem: iLineItem) => {
						const cloned = JSON.parse(JSON.stringify(lineItem));
						const can_edit = this.canEditLineItems;
						return { ...cloned, can_edit };
					});

					// If boq line items are rejected, ignore them
					if (this.rejectBoqLineItems) {
						actualLineItems = [];
					} else {
						if (actualLineItems.length) {
							actualLineItems.forEach((actualLineItem, i) => {
								const existingQuote = this.actualLineItemsFormArray.value.find(it => it.item.id === actualLineItem.item.id);
								console.log({ existingQuote, actualLineItem });
								if (!existingQuote) {
									const itemFormGroup = this.buildLineItemForm(actualLineItem);
									this.actualLineItemsFormArray.push(itemFormGroup);
								}
							});
						}
					}
				}

				return actualLineItems;
			})
		);
		const fullItemTwo$ = this.store.select(getFullItemTwo).pipe(
			filter(x => !!x) // Only emit when x is truthy=
		);
		const travelDetails$ = this.store.select(getTravelDetails);
		const excess$ = this.store.select(getExcess);
		const excessChangeDetails$ = this.store.select(getExcessChangeDetails);

		this.sub = combineLatest([availableLineItems$, boq$, fullItemTwo$, travelDetails$, excess$, excessChangeDetails$])
			.pipe(
				filter(([availableLineItems, boq, job, travelDetails, excess, excessChangeDetails]) => availableLineItems !== undefined),
				map(([availableLineItems, boq, job, travelDetails, excess, excessChangeDetails]) => ({
					availableLineItems,
					boq,
					job,
					travelDetails,
					excess,
					excessChangeDetails
				}))
			)
			.subscribe(({ availableLineItems, boq, job, travelDetails, excess, excessChangeDetails }) => {
				console.log({ availableLineItems, boq, job, travelDetails, excess, excessChangeDetails });

				// TRIGGER FORM CHANGES
				const newVals = this.actualLineItemsFormArray.value.map(i => ({ ...i }));
				this.actualLineItemsFormArray.patchValue(newVals);

				this.job = job;
				this.isHomeAssistItem = this.job?.claim?.claim_type === 28;
				this.isAfterHoursItem = !!this.job?.appointment?.[0]?.after_hours;
				this.selectedTemplate = this.bf.bigForm.get('selectedTemplate')?.value;
				this.excessChangeDetails = excessChangeDetails;
				this.excess = excess;

				// Filter based on isAfterHoursItem property
				if (availableLineItems) {
					this.availableLineItems = availableLineItems.filter(boqItem => !this.isAfterHoursItem || !boqItem.is_after_hours);
				}

				// Determine invoice-only state
				this.isInvoiceOnlyState = this.callOutStates.includes(this.job.state) || this.invoiceOnlyStates.includes(this.job.state);

				// If in invoice-only state and excess must change, open change excess modal after 1 second
				if (this.isInvoiceOnlyState && this.excessChangeDetails?.excess_must_change) {
					setTimeout(() => this.openChangeExcessModal(), 1000);
				}

				// If in invoice-only state and a template is selected, process compulsory and optional line items
				if (this.isInvoiceOnlyState && this.selectedTemplate) {
					// Set allow_custom_items property
					this.allow_custom_items = this.selectedTemplate?.allow_custom_items;

					// Process compulsory line items
					const clonedCompulsoryItems = JSON.parse(JSON.stringify(this.selectedTemplate?.compulsory_items));
					const reversedCompulsoryItems = JSON.parse(JSON.stringify(clonedCompulsoryItems.reverse()));
					reversedCompulsoryItems.forEach(compulsoryItemId => {
						const compulsoryItem = this.availableLineItems.find(lineItem => compulsoryItemId === lineItem.id);

						if (compulsoryItem) {
							const price = compulsoryItem.price || 0;
							const compulsoryLineItemFormGroup = this.formBuilder.group({
								isCustomItem: [false],
								id: [compulsoryItem.id, [Validators.required]],
								search: [compulsoryItem.name],
								description: [compulsoryItem.name, [Validators.required]],
								unitPrice: [+compulsoryItem.price],
								quantity: [1, [Validators.required, decimalQuantityValidator(), nonNegativeQuantityValidator(), numberQuantityValidator()]],
								item: [compulsoryItem],
								can_edit: [false]
							});
							const compultsoryItemExists = this.actualLineItemsFormArray.value.find(c => c.item.id === compulsoryItemId);
							console.log({ compulsoryItemId, compultsoryItemExists });
							if (!compultsoryItemExists) {
								this.actualLineItemsFormArray.insert(0, compulsoryLineItemFormGroup);
							}
						}
					});

					// Filter for optional line items
					this.availableLineItems = this.availableLineItems.filter(
						lineItem => this.selectedTemplate?.optional_items.includes(lineItem.id) || (this.allow_custom_items && lineItem.is_custom_items)
					);
				}

				this.availableLineItems = this.availableLineItems.map(availableLineItem => {
					let item = clone(availableLineItem);

					if (!item.icons) {
						item = { ...item, icons: [] };
					}
					if (!item.is_custom_items) {
						item = { ...item, icons: [...item.icons, { src: this.boqLogo, alt: this.boqLogoAlt }] };
					}
					if (this.isAfterHoursItem && item.is_after_hours) {
						item = { ...item, icons: [...item.icons, { src: 'assets/images/boq-after-hours-logo.svg', alt: 'After-Hours' }] };
					}
					return item;
				});

				if (this.clientName === 'Pinggo') {
					return 0;
				}

				if (this.availableLineItems) {
					this.checkPermittedTravelDistance(travelDetails);
				}
				this.cdr.detectChanges();
			});
	}

	filterBoqItems(boqItems: iLineItem[], filterOutItems: BillingLineItem[]): iLineItem[] {
		return difference(
			boqItems,
			filterOutItems.map(x => x.item)
		);
	}

	private buildLineItemForm(lineItem: BillingLineItem) {
		return this.formBuilder.group({
			description: [lineItem.item?.name.length > 0 ? lineItem.item?.name : '', [Validators.required]],
			search: [lineItem['search'] || lineItem.item?.name],
			quantity: [lineItem.quantity, [Validators.required, decimalQuantityValidator(), nonNegativeQuantityValidator(), numberQuantityValidator()]],
			unitPrice: [+lineItem.unit_price, [Validators.required]],
			// itemTotal: [lineItem.total],
			id: [lineItem.item?.id, [Validators.required]],
			can_edit: [lineItem.can_edit],
			item: [lineItem.item],
			job_invoice: [lineItem.job_invoice],
			invoice: [lineItem.invoice]
		});
	}

	private openChangeExcessModal() {
		this.excessChangeModalButtons = [
			{
				text: 'Confirm',
				clickHandler: () => this.changeExcessModal.close()
			}
		];
		this.cdr.detectChanges();
		this.changeExcessModal.open();
	}

	public removeLineItem(itemIndex) {
		this.actualLineItemsFormArray.removeAt(itemIndex);
	}

	boqModifyCall(mod) {
		if (mod.closeModal) {
			this.modalOpen = false;
		} else {
			this.createOrUpdateCustomLineItem(this, mod?.data, mod.functionName);

			this.modalOpen = false;
		}
	}
	createOrUpdateCustomLineItem(instance, data, functionName) {
		instance.store.dispatch(
			new MakeBillingServerCall({
				dataKey: 'lineItems',
				responseSlice: 'payload.items',
				functionName,
				errorMessage: 'Could not add custom line item',
				data
			})
		);
	}

	public getCustomLineData() {
		this.modificationType = 'create';
		this.modalOpen = true;
	}

	public getUpdateCustomLineData(item) {
		this.itemToEdit = item;
		this.modificationType = 'edit';
		this.modalOpen = true;
	}

	public calcTotal() {
		let total = 0;
		this.actualLineItemsFormArray.value.forEach(lineItem => (total += Number(lineItem.total)));
		// this.bf.bigForm.get('total')?.setValue(total);

		if (this.excessChangeDetails?.excess_must_change && this.excessChangeDetails?.new_excess < total + (this.bf.bigForm.get('vatPercentage')?.value / 100) * total) {
			if (this.hasExcessChangedCallInit) return;

			this.hasExcessChangedCallInit = true;
			this.excessChangeModalStatement =
				`This job's excess will be changed. <br /> The excess for this ` +
				`${this.job.claim.loan_information.claimtype} job will be ${this.excessChangeDetails.percent}% ` +
				`of the subtotal as opposed to ${this.excess.excess}, based on the policy rules.`;

			this.openChangeExcessModal();
			return;
		}

		this.hasExcessChangedCallInit = false;
	}

	public getLineItemIcons(lineItem: iLineItem) {
		const icons = [];

		if (lineItem?.is_custom_items !== true) {
			icons.push({ src: this.boqLogo, alt: this.boqLogoAlt });
		}

		if (lineItem?.is_after_hours === true) {
			icons.push({ src: 'assets/images/boq-after-hours-logo.svg', alt: 'After-Hours' });
		}

		return icons;
	}

	public filterAvailableLineItems = () => {
		const chosenItemIds = this.actualLineItemsFormArray.value.map(i => i.id);
		return this.availableLineItems.filter(item => !chosenItemIds.includes(item.id));
	};

	initTravelCostsModal() {
		this.modalButtons = [
			{
				text: 'No',
				linkType: 'close',
				clickHandler: () => {
					this.travelCostsModal.close();
					this.travelCostModalResponded = true;
				}
			},
			{
				text: 'Yes',
				clickHandler: () => {
					this.travelCostsModalResponse();
					this.travelCostModalResponded = true;
				}
			}
		];
	}

	travelCostsModalResponse() {
		this.travelCostsModal.close();

		const travelCost = this.getTravelFeeLineItem();

		const travelCostItem = this.formBuilder.group({
			id: [travelCost.id],
			// item_id: [travelCost.id],
			item: [travelCost],
			description: [travelCost.name],
			search: [travelCost.name],
			quantity: [this.maximumTravelDistance],
			unitPrice: [+travelCost.price],
			itemTotal: [+travelCost.price * this.maximumTravelDistance],
			can_edit: [false]
		});

		this.actualLineItemsFormArray.push(travelCostItem);
		this.actualLineItemsFormArray.patchValue(this.actualLineItemsFormArray.value);
		this.calcTotal();
	}

	private checkPermittedTravelDistance(travelDetails) {
		if (travelDetails && travelDetails.km > 0) {
			this.maximumTravelDistance = travelDetails.km;
			if (!this.checkForTravelFeeLineItem() && this.getTravelFeeLineItem()) {
				setTimeout(() => !this.travelCostModalResponded && this.travelCostsModal.open(), 1000);
			}
		}
	}

	checkForTravelFeeLineItem() {
		return this.actualLineItemsFormArray.value.find(lineItem => lineItem.item?.name === 'Travel Fee (Every km Over 60km) - per km' && !lineItem.item?.is_custom_items);
	}

	getTravelFeeLineItem() {
		return this.availableLineItems.find(lineItem => lineItem.name === 'Travel Fee (Every km Over 60km) - per km' && !lineItem.is_custom_items);
	}

	convertControlToFormGroup(control: AbstractControl): UntypedFormGroup {
		return control as UntypedFormGroup;
	}

	ngOnDestroy() {
		if (this._subscription) {
			this._subscription.unsubscribe();
		}
		this._editSubscriptions.forEach(sub => {
			if (sub) sub.unsubscribe();
		});

		if (this.sub) {
			this.sub.unsubscribe();
		}
	}
}
