import { getSelectedItemTwo, NodePoint_0_0_2 } from '@flexus/core';
import { environment } from 'apps/studio/src/environments/environment';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

export const invoiceNode = (state, new_state?): NodePoint_0_0_2 => {
	return {
		initFormFields: (bf, item, instance, sq, store) => {
			if (new_state) {
				bf.patchValues({ new_state });
			}
		},
		showTabs: true,
		name: 'Invoice',
		serverCalls: {
			dataFile: {
				errorMessage: `Couldn't get invoice.`,
				directCall: (http, store, sq, bf) => {
					return store.select(getSelectedItemTwo).pipe(
						skipWhile(x => !x),
						take(1),
						switchMap(selected => {
							return http
								.post<any>(environment.api_url + `v1/file_action/get_invoice`, {
									job_id: selected?.id,
									return_type: 1
								})
								.pipe(map(returned => returned.payload.data));
						})
					);
				}
			}
		},
		component: {
			children: [
				{
					component: 'FLXFileViewerComponent',
					inputs: {
						base64Source$: 'dataFile',
						mimeType: 'pdf',
						containerHeight: '500px'
					}
				}
			]
		},
		navs: [
			...(state === 252 || state === 253 || state === 250
				? [
						{
							text: 'Query',
							color: 'secondary',
							nextNode: 'QueryInvoice'
						},
						{
							text: 'Payment Complete',
							color: 'primary',
							nextNode: 'Decision'
						}
				  ]
				: [
						{
							text: 'SP Rework',
							nextNode: 'rework',
							color: 'secondary'
						},
						{
							text: 'QA Regional',
							nextNode: 'requestQA',
							color: 'secondary'
						},
						{
							text: 'Issue Fixed',
							nextNode: 'resolvedProblem',
							color: 'primary'
						}
				  ])
		]
	};
};
