import { Flow_0_0_2 } from '@flexus/core';

export const BUI_5: Flow_0_0_2 = {
	id: '5',
	name: 'invalid_no_progress',
	itemType: 'flow',
	header: {
		title: 'Invalid Claim',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	startNode: '',
	nodes: {}
};
