import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { map } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';
import { SP_GLOBAL_69 } from './SP_GLOBAL_69';

export const SP_GLOBAL_105: Flow_0_0_2 = {
	id: '105',
	name: 'no_work_required_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: store => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant) {
							return `No Work - Requested Assessor : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'No Work - Requested Assessor';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'No work required'
		}
	},
	serverCalls: {
		...SP_GLOBAL_69.serverCalls
	},
	startNode: SP_GLOBAL_69.startNode,
	nodes: {
		...SP_GLOBAL_69.nodes
	},
	bigFormToStoreMapper: {
		...SP_GLOBAL_69.bigFormToStoreMapper,
		current_state: '105'
	}
};
