import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'flexus-agent-mini-dashboard',
	templateUrl: './agent-mini-dashboard.component.html',
	styleUrls: ['./agent-mini-dashboard.component.scss']
})
export class AgentMiniDashboardComponent {
	chartData = { graph_data: [{ counts: [], label: '' }] };
	@ViewChild(BaseChartDirective) chart?: BaseChartDirective;
	colors = ['#29a845', '#a33c02', 'blue', 'navy', 'orange', 'purple', 'red', 'silver', 'teal', 'white', 'yellow'];

	public barChartOptions: ChartConfiguration['options'] = {
		responsive: true,
		scales: {
			x: {
				stacked: true, // Enable stacking for x-axis
				grid: {
					display: false // Hide vertical grid lines for x-axis
				},
				ticks: {
					color: '#bababa', // Set the font color of labels
					minRotation: 45 // Rotate labels by 45 degrees (diagonally)
				}
			},
			y: {
				min: 0,
				stacked: true, // Enable stacking for y-axis
				ticks: {
					display: false // Hide y-axis ticks
				}
			}
		},
		plugins: {
			legend: {
				display: false
			}
		}
	};
	public barChartType: ChartType = 'bar';

	public barChartData: ChartData<'bar'> = {
		labels: [],
		datasets: []
	};

	performance_matrices: any = {};
	state_number: string;
	state_data: any;
	agent_self_performance: Observable<any> = of(null);

	states: number[] = [];
	agent_matrices = { agent_names: [], states: [], performance_grid: [] };
	average_closure_time_per_state: any[] = [];

	constructor(private http: HttpClient) {}

	ngOnInit(): void {
		this.fetchChartData();
		this.fetchMyPerformanceCounts();
		this.fetchPerformanceMatrix();
	}

	fetchChartData(): void {
		this.chartData = {
			graph_data: [
				{
					label: 'Straight Through Payment',
					counts: [{ state: 176, value: 987 }]
				},
				{
					label: 'Problem On Validation',
					counts: [
						{ state: 177, value: 187 },
						{ state: 232, value: 670 },
						{ state: 233, value: 900 }
					]
				},
				{
					label: 'Problem With Installation',
					counts: [
						{ state: 185, value: 575 },
						{ state: 180, value: 457 },
						{ state: 181, value: 200 },
						{ state: 185, value: 654 }
					]
				}
			]
		};

		const { graph_data = [] } = this.chartData;
		if (!graph_data?.length) return;

		const countsObjLength = graph_data.map(item => item?.counts?.length ?? 0); // Get number of each entry's state count
		const longestArrLength = Math.max(...countsObjLength); // used to make the data length the same for every stack dynamically
		const tempData = [];

		this.barChartData['labels'] = graph_data?.map(item => item.label);

		// Create Dynamic Stacks Objects Based On The Longest Array Length
		for (let i = 0; i < longestArrLength; i++) {
			tempData.push(Array(longestArrLength).fill(0, 0, longestArrLength));
			const colors =
				this.barChartData['labels'][i] === 'Straight Through Payment'
					? [this.colors[0]]
					: this.barChartData['labels'][i] === 'Problem On Validation'
					? [...this.colors.slice(1)]
					: [...this.colors.slice(4)];
			this.barChartData['datasets'].push({
				data: [],
				label: '',
				backgroundColor: [...colors],
				barPercentage: 0.2
			});
		}

		this.setChartDataView(graph_data, tempData);
	}

	setChartDataView(graph_data, tempData): void {
		graph_data.forEach((item, i) => {
			item?.counts?.forEach((obj, j) => {
				tempData[j][i] = obj['value'] ?? 0;
				this.barChartData['datasets'][j]['data'] = [...tempData[j]];
				this.barChartData['datasets'][j]['label'] = `State - ${obj?.state} Count`;
			});
		});
	}

	fetchMyPerformanceCounts(): void {
		this.agent_self_performance = of({
			received: 98,
			resolved: 180,
			in_queue: 262,
			over_SLA: 0,
			average_resolution_time: '1h 54m',
			resolution_within_SLA: '100%'
		});
	}

	fetchPerformanceMatrix(): void {
		this.average_closure_time_per_state = [
			{
				agent_name: 'Bongiwe Mabasa',
				states: [
					{
						state: 176,
						average_closure_time: '1h 54min'
					},
					{
						state: 253,
						average_closure_time: '0h 42min'
					},
					{
						state: 187,
						average_closure_time: '2h 11min'
					}
				]
			},
			{
				agent_name: 'Siyabonga Hlongwane',
				states: [
					{
						state: 176,
						average_closure_time: '2h 54min'
					},
					{
						state: 253,
						average_closure_time: '2h 42min'
					},
					{
						state: 187,
						average_closure_time: '3h 11min'
					},
					,
					{
						state: 177,
						average_closure_time: '3h 11min'
					}
				]
			},
			{
				agent_name: 'Hardus Lourens',
				states: [
					{
						state: 176,
						average_closure_time: '2h 54min'
					},
					{
						state: 253,
						average_closure_time: '2h 42min'
					},
					{
						state: 187,
						average_closure_time: '3h 11min'
					},
					,
					{
						state: 177,
						average_closure_time: '3h 11min'
					},
					{
						state: 174,
						average_closure_time: '2h 54min'
					},
					{
						state: 252,
						average_closure_time: '2h 42min'
					},
					{
						state: 188,
						average_closure_time: '3h 11min'
					},
					,
					{
						state: 171,
						average_closure_time: '3h 11min'
					},
					{
						state: 170,
						average_closure_time: '2h 54min'
					},
					{
						state: 233,
						average_closure_time: '2h 42min'
					},
					{
						state: 179,
						average_closure_time: '3h 11min'
					},
					,
					{
						state: 154,
						average_closure_time: '3h 11min'
					}
				]
			},
			{
				agent_name: 'Tiaan van Staden',
				states: [
					{
						state: 176,
						average_closure_time: '1h 54min'
					},
					{
						state: 253,
						average_closure_time: '0h 42min'
					},
					{
						state: 187,
						average_closure_time: '2h 11min'
					}
				]
			},
			{
				agent_name: 'Enoch Abassey',
				states: [
					{
						state: 176,
						average_closure_time: '2h 54min'
					},
					{
						state: 253,
						average_closure_time: '2h 42min'
					},
					{
						state: 187,
						average_closure_time: '3h 11min'
					},
					,
					{
						state: 177,
						average_closure_time: '3h 11min'
					}
				]
			}
		];

		if (!this.average_closure_time_per_state?.length) return;
		this.states = this.setPerformanceMatricesStates(this.average_closure_time_per_state);

		// Create dynamic array using record with highest quantity of states
		let performance_grid = Array(this.average_closure_time_per_state?.length).fill([], 0, this.average_closure_time_per_state?.length);

		// Map each state to own agent
		this.average_closure_time_per_state.forEach((item: any, indx) => {
			this.states.forEach(state => {
				const average_closure_time = item?.states?.find(item => item?.state === state)?.average_closure_time;
				performance_grid[indx] = [...performance_grid[indx], { [state]: average_closure_time }];
				performance_grid[indx] = performance_grid[indx]?.sort((item1, item2) => item1 - item2);
			});
		});

		// Create Agent Matrices Object
		this.agent_matrices = {
			agent_names: this.average_closure_time_per_state.map(({ agent_name }) => agent_name),
			states: this.states,
			performance_grid: performance_grid
		};
	}

	setPerformanceMatricesStates(data: any[]): number[] {
		let states = [];

		// Loop over the objects and pluck the state
		data?.forEach((item: any) => item?.states.forEach(obj => obj.state && (states = [...states, obj.state])));

		// Remove duplicates from the states array
		const set = new Set([...states]);
		return Array.from(set)?.sort((a, b) => a - b);
	}
}
