import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

export const PGG_CALL_LOGS: Flow_0_0_2 = {
	id: 'CallLogs',
	itemType: 'flow',
	name: 'Call Logs',
	excludeForOnlyStates: [169],
	header: {
		title: (_store: Store, bf) => {
			return _store.select(getFullItemOne).pipe(
				map((itemOne: any) => {
					if (itemOne) {
						return `Call Logs  :   ${itemOne?.loan_information?.voucher_key}  -  ${itemOne?.applicant?.first_name}`;
					} else {
						return 'Call Logs';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'CallLogs',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		CallLogs: {
			component: 'CallLogsComponent',
			navs: [],
			inputs: {
				title: 'Call Logs',
				location: 'context'
			}
		},
		SubmissionSuccess: {
			component: 'FlXSubmissionSuccess'
		}
	},
	bigFormToStoreMapper: {},

	serverCalls: {
		logs: {
			serviceVariable: 'buiService',
			functionName: 'getCallLogs',
			// responseSlice: 'payload',
			errorMessage: 'No call logs could be found!'
		}
	}
};
