<flx-heading size="medium" [align]="'center'" weight="extra-light" type="creation">{{ title }}</flx-heading>
<div style="text-align: center">Current Installer Technician: {{ currentTeamLeader$ | async }}</div>

<form [formGroup]="bf.bigForm">
	<div class="teamLeadInfoContainer">
		<div style="text-align: center">Enter the company to retrieve the installer technicians</div>
		<div class="search-container">
			<flx-select-list *ngIf="sps$" [options$]="formattedSps$" formControlName="select_company_list" [canFilter]="true"></flx-select-list>
		</div>
		<flx-reactive-form-error-display style="text-align: center" [control]="$any(bf.bigForm).controls['select_company_list']" class="error"> </flx-reactive-form-error-display>
		<div style="text-align: center">
			<flx-button *ngIf="!bf.bigForm.get('select_company_list').invalid" (click)="lookup()" [align]="'center'" [disabled]="bf.bigForm.get('select_company_list').invalid"
				>load installer technicians</flx-button
			>
		</div>
		<br /><br />

		<div class="spanner">
			<div style="text-align: center">Select the installer technician you want to allocate the job against</div>
			<br />
			<!--			<ng-container *ngIf="isLoading$ | async"> <flx-loader-component></flx-loader-component> </ng-container>-->
			<ng-container *ngIf="teamLeads?.length < 1 && hasDoneLookup"> <flx-alert type="info" [message]="errorMessage"></flx-alert> </ng-container>
			<ng-container>
				<flx-panel alignItems="center">
					<flx-select-list *ngIf="formattedTeamleads$" [options$]="formattedTeamleads$" formControlName="select_teamlead_list"></flx-select-list>
					<flx-button
						*ngIf="!bf.bigForm.get('select_teamlead_list').invalid"
						[align]="'center'"
						[disabled]="bf.bigForm.get('select_teamlead_list').invalid"
						(click)="setTeamleadFailReason()"
						>continue</flx-button
					>

					<!-- <ng-container>
						<div
							*ngFor="let teamLead of teamLeads"
							[class.selected]="bf.bigForm.value?.assign_teamleader_id === teamLead.id"
							(click)="selectTeamLead(teamLead?.id, teamLead.full_name)"
							class="team-lead-result-container"
						>
							<div class="team-lead-description">
								<span>{{ teamLead.full_name }}</span>
							</div>
						</div>
					</ng-container> -->
				</flx-panel>
			</ng-container>
		</div>
	</div>
</form>
