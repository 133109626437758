import { Injectable, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, UntypedFormBuilder, ValidationErrors, UntypedFormArray } from '@angular/forms';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { map, take, distinctUntilChanged } from 'rxjs/operators';
import { path, flatten, uniq, mergeDeepRight, assocPath } from 'ramda';
import { Store } from '@ngrx/store';
import { ManifestController } from '../controllers/manifest.controller';
import { getActiveManifestItem } from '../manifest/manifest.selectors';
import { ENVIRONMENT } from './engine.constants';


type ErrorType = 'required' | 'email' | 'min' | 'max';

@Injectable({ providedIn: 'root' })
export class BigFormService implements OnDestroy {
	bigForm: UntypedFormGroup;
	testSub: Subscription;
	private _errorMessages = {};
	bigFormErrors = {};
	storeObject: any;
	storeObjectSubscription: Subscription;
	constructor(private store: Store<any>, private controller: ManifestController<any>, public fb: UntypedFormBuilder, @Inject(ENVIRONMENT) environment: any) {
		this.initForm();
		this.storeObjectSubscription = this.store.subscribe(obj => (this.storeObject = obj));
		if (!environment.production) {
			this.bigForm?.valueChanges
				?.pipe(
					distinctUntilChanged((prevValue, currValue) => {
						// Use deep comparison to check for equality of objects and arrays
						return JSON.stringify(prevValue) === JSON.stringify(currValue);
					})
				)
				.subscribe(form => console.log({ BIGFORM: form, FormModel: this.bigForm }));
		}
	}

	initForm() {
		if (!this.bigForm) {
			this.bigForm = new UntypedFormGroup({});
		}
	}

	get errorMessages() {
		return this._errorMessages;
	}

	addErrorMessage(controlName: string, errorType: ErrorType, message: string) {
		this._errorMessages[`${controlName}:${errorType}`] = message;
	}

	addSimpleValueToFormModel(controlName: string, value: any) {
		if (!this.bigForm.get(controlName)) {
			this.bigForm.addControl(controlName, new UntypedFormControl(value));
		}
	}

	addObjectToFormModel(controlName: string, obj: any) {
		if (!this.bigForm.get(controlName)) {
			if (obj) {
				this.bigForm.addControl(controlName, this.fb.group(obj));
			}
		}
	}

	patchToForm(sourceObservable: Observable<any>, fieldMaps: { [key: string]: string }) {
		return sourceObservable.pipe(
			map(sourceObj => {
				return Object.entries(sourceObj)?.reduce((acc, [key, value]: [string, any]) => {
					if (typeof value === 'string') {
						return {
							...acc,
							[key]: value?.trim()
						};
					} else {
						return {
							...acc,
							[key]: value
						};
					}
				}, {});
			}),
			map(sourceObj => {
				Object.entries(fieldMaps).forEach(([key, value]) => {
					if (key && value) {
						let control = this.getControl(value);
						if (control) {
							control?.setValue(this.getSourceValue(sourceObj, key));
						} else {
							this.setControl(value);
							control = this.getControl(value);
							control?.setValue(this.getSourceValue(sourceObj, key));
						}
					}
				});
			})
		);
	}

	getSourceValue(source, fieldPath: string) {
		const thePath = fieldPath?.split('.');
		return path(thePath, source) || '';
	}

	patchValues(formValues: { [key: string]: any }) {
		Object.entries(formValues).forEach(([key, value]) => {
			if (!this.bigForm.get(key)) {
				if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
					this.bigForm.addControl(key, this.fb.control(value));
				} else if (Array.isArray(value)) {
					if (value.some(val => typeof val === 'function' || Array.isArray(val))) {
						// Then it contains a validation function
						this.bigForm.addControl(key, this.fb.control(value[0], value[1]));
					} else {
						this.bigForm.addControl(key, this.fb.control(value[0]));
					}
				} else {
					this.bigForm.addControl(key, this.fb.control(value));
				}
			} else {
				this.bigForm.patchValue({ [key]: value });
			}
		});
	}

	addControls(formValues: { [key: string]: any }) {
		Object.entries(formValues).forEach(([key, value]) => {
			if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
				this.bigForm.addControl(key, this.fb.group(value));
			} else {
				this.bigForm.addControl(key, this.fb.control(value));
			}
		});
	}

	addControl(controlName: string, control: AbstractControl) {
		if (!this.bigForm.get(controlName)) {
			this.bigForm.addControl(controlName, control);
		}
	}

	getControl(controlPath: string) {
		this.initForm();
		const path = controlPath?.split('.');
		let control = this.bigForm.controls[path[0]];

		if (!control) {
			this.setControl(controlPath);
			control = this.bigForm.controls[path[0]];
		}
		if (path.length > 1) {
			for (let i = 1; i < path.length; i++) {
				control = control.get(path[i]);
			}
		}
		return control;
	}

	getControlForValidation(controlPath: string) {
		this.initForm();
		const path = controlPath?.split('.');
		let control = this.bigForm.controls[path[0]];

		// if (!control) {
		//   this.setControl(controlPath);
		//   control = this.bigForm.controls[path[0]];
		// }
		if (path.length > 1) {
			for (let i = 1; i < path.length; i++) {
				control = control.get(path[i]);
			}
		}
		return control;
	}

	setControl(controlPath: string) {
		const path = controlPath?.split('.');
		if (path.length === 1) {
			this.bigForm.addControl(path[0], new UntypedFormControl(null));
		} else if (path.length === 2) {
			if (this.bigForm.get(path[0])) {
				(<UntypedFormGroup>this.bigForm.get(path[0]))?.addControl(path[1], new UntypedFormControl(null));
			} else {
				this.bigForm.addControl(path[0], new UntypedFormGroup({ [path[1]]: null }));
			}
		}
	}

	bigFormToStoreMapper(mapper: { [id: string]: string | any[] }) {
		return this.bigForm?.valueChanges?.pipe(
			// debounceTime(10),
			map(formValues => {
				return Object.entries(mapper)?.reduce((acc, [formPath, storePath]) => {
					const valueExists = path(formPath?.split('.'), formValues) !== undefined && path(formPath?.split('.'), formValues) !== null;
					if (typeof storePath === 'string') {
						return valueExists ? mergeDeepRight(acc, assocPath(storePath?.split('.'), path(formPath?.split('.'), formValues), {})) : acc;
					} else if (Array.isArray(storePath) && typeof storePath[0] === 'string') {
						return storePath?.reduce((innerAcc, innerStorePath) => {
							const innerObj = valueExists ? mergeDeepRight(innerAcc, assocPath(innerStorePath?.split('.'), path(formPath?.split('.'), formValues), {})) : innerAcc;
							return mergeDeepRight(acc, innerObj);
						}, {});
					} else if (Array.isArray(storePath) && typeof storePath[0] === 'function') {
						const [func, pathToStore] = storePath;
						const transformed = func(path(formPath?.split('.'), formValues), this.storeObject, this.bigForm.value, this);
						return valueExists ? mergeDeepRight(acc, assocPath(pathToStore?.split('.'), transformed, {})) : acc;
					} else if (Array.isArray(storePath) && Array.isArray(storePath[0])) {
						return storePath?.reduce((inner1, funcToPathArray) => {
							const [func, pathToStore] = funcToPathArray;
							const transformed = func(path(formPath?.split('.'), formValues), this.storeObject, this.bigForm.value, this);
							const innerObj = valueExists ? mergeDeepRight(inner1, assocPath(pathToStore?.split('.'), transformed, {})) : inner1;
							// const innerObj1 = valueExists
							//   ? R.mergeDeepRight(inner1, R.assocPath(pathToStore.split('.'), R.path(formPath.split('.'), formValues), {}))
							//   : inner1;
							return mergeDeepRight(acc, innerObj);
						}, {});
					}
				}, {});
			})
		);
	}

	allValid() {
		this.bigForm?.updateValueAndValidity();
		return this.bigForm?.valueChanges?.pipe(map(val => this.bigForm.valid));
	}

	fieldsValid(fieldPaths: string[]) {
		const watchedControls = fieldPaths.map(field => this.getControl(field));

		return combineLatest(
			...watchedControls.map(control => {
				control?.updateValueAndValidity();
				return control?.valueChanges?.pipe(map(val => control.valid));
			})
		).pipe(map(res => res?.every(val => val === true)));
	}

	getFormGroupErrors(form: UntypedFormGroup | UntypedFormArray) {
		if (Array.isArray(form.controls)) {
			return form.controls
				.map((ctrl, key) => {
					const control = form.get(`${key}`);
					if (control instanceof UntypedFormControl) {
						const controlErrors: ValidationErrors = control.errors;
						if (controlErrors) {
							return Object.keys(controlErrors).map(keyError => {
								return `${key}:${keyError}`;
							});
						}
					} else if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
						return this.getFormGroupErrors(control);
					}
				})
				.filter(val => !!val)
				.map(data => flatten(data));
		} else {
			return Object.keys(form.controls)
				.map(key => {
					const control = form.get(key);
					if (control instanceof UntypedFormControl) {
						const controlErrors: ValidationErrors = control.errors;
						if (controlErrors) {
							return Object.keys(controlErrors).map(keyError => {
								return `${key}:${keyError}`;
							});
						}
					} else if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
						return this.getFormGroupErrors(control);
					}
				})
				.filter(val => !!val)
				.map(data => flatten(data));
		}
	}

	getErrorMessagesForDisplay(errorMessages: { [key: string]: string }) {
		const formGroupErrors = flatten(this.getFormGroupErrors(this.bigForm));
		const errors = formGroupErrors.map(key => {
			return key && errorMessages && errorMessages[key];
		});
		return uniq(flatten(errors.filter(val => !!val)));
	}

	retrieveErrors$() {
		return this.controller.select(getActiveManifestItem).pipe(
			take(1),
			map(item => item?.flowErrorMessages),
			map(errorMessages => {
				return this.getErrorMessagesForDisplay(errorMessages);
			})
		);
	}

	//////

	// handleValidator(controlName, validator) {
	//   this.errorMessages[controlName] = {};
	//   switch (validator.type) {
	//     case 'required': {
	//       this.errorMessages[controlName].required = validator.errorMessage;
	//       return Validators.required;
	//     }
	//     case 'minLength': {
	//       this.errorMessages[controlName].minLength = validator.errorMessage;
	//       return Validators.minLength(validator.value);
	//     }
	//     case 'maxLength': {
	//       this.errorMessages[controlName].maxLength = validator.errorMessage;
	//       return Validators.maxLength(validator.value);
	//     }
	//   }
	// }

	// runValidators(controlName: string, validators: FieldValidator[]) {
	//   if (validators) {
	//     const ctrl = this.bigForm.controls[controlName];
	//     const vals = validators.map((val) => this.handleValidator(controlName, val));
	//     ctrl.setValidators(vals);
	//   }
	// }

	// runRemoveControlWhen(controlName: string, removeControlWhen: any[], validators: FieldValidator[]) {
	//   let ctrlValue = null;
	//   if (!!removeControlWhen) {
	//     return removeControlWhen
	//       .map((cond) => {
	//         const fieldControl = this.bigForm.controls[cond.field];
	//         const ctrl = this.bigForm.controls[controlName];
	//         // this.ctrlValue = ctrl.value;
	//         switch (cond.operator) {
	//           case 'is': {
	//             if (cond.value === 'valid') {
	//               if (fieldControl.valid) {
	//                 if (ctrl) {
	//                   ctrlValue = ctrl.value;
	//                   this.bigForm.removeControl(controlName);
	//                 }
	//                 return true;
	//               } else {
	//                 if (!ctrl) {
	//                   // tslint:disable-next-line: max-line-length
	//                   this.bigForm.addControl(controlName, new FormControl(ctrlValue, validators.map((val) => this.handleValidator(controlName, val))));
	//                   this.bigForm.controls[controlName].markAsDirty();
	//                   this.bigForm.updateValueAndValidity();
	//                 }
	//                 return false;
	//               }
	//             } else if (cond.value === 'invalid') {
	//               if (fieldControl.invalid) {
	//                 if (ctrl) {
	//                   ctrlValue = ctrl.value;
	//                   this.bigForm.removeControl(controlName);
	//                 }
	//                 return true;
	//               } else {
	//                 if (!ctrl) {
	//                   // tslint:disable-next-line: max-line-length
	//                   this.bigForm.addControl(controlName, new FormControl(ctrlValue, validators.map((val) => this.handleValidator(controlName, val))));
	//                   this.bigForm.controls[controlName].markAsDirty();
	//                   this.bigForm.updateValueAndValidity();
	//                 }
	//                 return false;
	//               }
	//             }
	//             break;
	//           }
	//           case 'equals': {
	//             if (fieldControl.value === cond.value) {
	//               if (ctrl) {
	//                 ctrlValue = ctrl.value;
	//                 this.bigForm.removeControl(controlName);
	//               }
	//               return true;
	//             } else {
	//               if (!ctrl) {
	//                 // tslint:disable-next-line: max-line-length
	//                 this.bigForm.addControl(controlName, new FormControl(ctrlValue, validators.map((val) => this.handleValidator(controlName, val))));
	//                 this.bigForm.controls[controlName].markAsDirty();
	//                 this.bigForm.updateValueAndValidity();
	//               }
	//               return false;
	//             }
	//             break;
	//           }
	//           case 'contains': {
	//             if (fieldControl.value.includes(cond.value)) {
	//               if (ctrl) {
	//                 ctrlValue = ctrl.value;
	//                 this.bigForm.removeControl(controlName);
	//               }
	//               return true;
	//             } else {
	//               if (!ctrl) {
	//                 // tslint:disable-next-line: max-line-length
	//                 this.bigForm.addControl(controlName, new FormControl(ctrlValue, validators.map((val) => this.handleValidator(controlName, val))));
	//                 this.bigForm.controls[controlName].markAsDirty();
	//                 this.bigForm.updateValueAndValidity();
	//               }
	//               return false;
	//             }
	//             break;
	//           }
	//         }
	//       })
	//       .includes(true);
	//   }
	// }

	executeFormRules(formRules) {
		// return this.bigForm.valueChanges.pipe(
		//   debounceTime(300),
		//   map(() => {
		//     return Object.entries(formRules).map(([controlName, rules]: any) => {
		//       controlName && this.runValidators(controlName, rules.validators);
		//       controlName && this.runRemoveControlWhen(controlName, rules.removeControlWhen, rules.validators);
		//       return true;
		//     });
		//   }),
		// );
	}

	ngOnDestroy() {
		if (this.testSub) this.testSub.unsubscribe();
		if (this.storeObjectSubscription) this.storeObjectSubscription.unsubscribe();
	}
}
