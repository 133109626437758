<form [formGroup]="creditNoteGroup">
	<div class="line-item-modify" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
		<div class="line-item-modify__item line-item-modify__item--flex-grow">
			<div
				class="line-item-modify__item__active-zone line-item-modify__item__active-zone--description line-item-modify__item__active-zone--left"
				[ngClass]="{ hover: isItemHover || isItemActive }"
			>
				<flx-dropdown
					[searchEnabled]="false"
					id="installation-types"
					[itemsOption]="installationTypes"
					[displayOptions]="{ displayKey: 'display', valueKey: 'display' }"
					formControlName="VoucherTypeandStatus"
					placeHolder="Select Installation Type"
				>
				</flx-dropdown>
			</div>
		</div>
		<div class="line-item-modify__item">
			<div class="line-item-modify__item__active-zone" [ngClass]="{ hover: isItemHover || isItemActive }">
				<flx-input-text formControlName="VoucherNumber" type="text" size="normal"></flx-input-text>
			</div>
		</div>
		<div class="line-item-modify__item">
			<div class="line-item-modify__item__active-zone line-item-modify__item__active-zone--right" [ngClass]="{ hover: isItemHover || isItemActive }">
				<input type="number" min="0.00" step="0.01" formControlName="AmountExclVAT" formatCurrency allowOnlyAbsoluteNumbers />
			</div>
		</div>
		<div *ngIf="!isNonVatSP" class="line-item-modify__item">
			<div class="line-item-modify__item__active-zone line-item-modify__item__active-zone--right" [ngClass]="{ hover: isItemHover || isItemActive }">
				<span> {{ vatAmount }} </span>
			</div>
		</div>
	</div>
</form>
