import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { BigFormService, getAllInfo } from '@flexus/core';
import { skipWhile, take } from 'rxjs/operators';
import { findName, addObjectProperty } from '@flexus/utilities';

@Component({
	selector: 'flexus-customer-rating',
	templateUrl: './customer-rating.component.html',
	styleUrls: ['./customer-rating.component.scss']
})
export class CustomerRatingComponent implements OnInit, OnDestroy {
	@Input() skills$: Observable<any>;
	@Input() serviceProviders$: Observable<any>;

	get sps() {
		return this.serviceProviders$;
	}

	@Input() organisationName: string;
	@Input() selectedItem$: Observable<any>;

	SPListSub: Subscription;
	serviceProviderList: any[];
	spList = [];
	skillsSub: Subscription;

	constructor(public _store: Store<any>, public bf: BigFormService) {}
	/* --------------------------------- Life cycle hooks --------------------------------- */

	ngOnInit() {
		forkJoin([
			this.serviceProviders$.pipe(
				skipWhile(x => !x),
				take(1)
			),
			this._store.select(getAllInfo).pipe(
				skipWhile(x => !x),
				take(1)
			)
		])
			.pipe(take(1))
			.subscribe(([sp, allInfo]) => {
				sp.forEach(element => {
					const sp = addObjectProperty('skillName', findName(element.skill, allInfo['skills']).name, element);
					this.spList.push(sp);
				});
			});
	}

	onOrganisationWhatMattersChanges(organisationYesNo: boolean) {
		if (organisationYesNo === true) {
			this.bf.bigForm.get('failedwhatmattersreason').reset();
			this.bf.bigForm.get('failedwhatmattersreason')?.disable();
		} else {
			this.bf.bigForm.get('failedwhatmattersreason')?.enable();
		}
	}

	onJobWhatMattersChanges(spYesNo: boolean, index: number) {
		if (spYesNo === true) {
			this.bf.bigForm.get(`sp_ratings.${index}.whatmattersfailreason`).reset();
			this.bf.bigForm.get(`sp_ratings.${index}.whatmattersfailreason`)?.disable();
		} else {
			this.bf.bigForm.get(`sp_ratings.${index}.whatmattersfailreason`)?.enable();
		}
	}

	ngOnDestroy() {
		if (this.SPListSub) {
			this.SPListSub.unsubscribe();
		}
	}
}
