<ng-container>
	<div class="multi-file-upload-container">
		<flx-heading size="medium" color="secondary" align="center">Selected Photos</flx-heading>
		<flx-heading size="small" color="secondary" align="center">Please assign purpose tags to the selected photos before uploading</flx-heading>
	</div>
	<div class="image-elements-container">
		<flx-glow-line></flx-glow-line>
		<ng-container *ngIf="$any(images$ | async) as images; else noImages">
			<!-- <flx-image-upload-card ></flx-image-upload-card> -->
			<!-- <div *ngFor="let image of images">a file</div> -->
			<form [formGroup]="$any(_bf.bigForm)">
				<ng-container *ngFor="let image of images; let i = index">
					<div class="image-card">
						<div><img id="image" height="40px" [src]="_sanitiser.bypassSecurityTrustUrl(image.thumbnail)" /></div>
						<div class="image-name">{{ image.name }}</div>
						<div class="image-size">{{ image.size }} MB</div>
						<div class="purpose-selector">
							<flx-dropdown [itemsOption]="purposeOptions" placeHolder="Add purpose tag" [displayOptions]="displayConfig"></flx-dropdown>
							<!-- <flx-select-list [options$]="purposeOptions$" [canFilter]="false" isCollapsed="true"></flx-select-list> -->
						</div>

						<div class="action-icon"><flx-icon [type]="'check-circle-1'"></flx-icon></div>
					</div>
				</ng-container>
			</form>
		</ng-container>
		<ng-template #noImages>No images selected!</ng-template>
		<flx-glow-line></flx-glow-line>
	</div>
</ng-container>
