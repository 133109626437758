import { NodePoint_0_0_2, getFullItemTwo, getAllInfo, getCurrentUser, getData } from '@flexus/core';
import { skipWhile, take, map, filter, pluck, switchMap } from 'rxjs/operators';
import { combineLatest, forkJoin, of } from 'rxjs';
import { findName } from '@flexus/utilities';
import { KVLHeading } from '@flexus/ui-elements';
import { GetDecoderSerialNumberDisplay } from '../../../mul-sp/configs/reusable/dataManipulations';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { environment } from 'apps/studio/src/environments/environment';

export const jobInfoNode = (state, new_state?): NodePoint_0_0_2 => {
	const stateArray1 = [331, 185, 285, 335, 238];
	return {
		name: 'Job Information',
		component: {
			children: [
				...(state === 311
					? [
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: 'Job Summary',
									headingConfig: { size: 'small', weight: 'medium', color: 'default' },
									instructions: ['Please contact the customer to set a new appointment time for this job.']
								}
							}
					  ]
					: []),
				// ...(state !== 254
				// 	? [
				// 			{
				// 				component: 'FLXKeyValueListComponent',
				// 				inputs: { data$: 'customer_details', colouredHeading: new KVLHeading('Customer Contact Details', 'default') }
				// 			}
				// 	  ]
				// 	: []),
				...(state === 254
					? [
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Decoder Information',
									data$: 'decoder',
									itemMargin: '0 0 35px 0'
								}
							}
					  ]
					: []),
					...(state === 257
						? [
								{
									component: 'FLXHeadingWithInstructionsComponent',
									inputs: {
										title: 'Job Information',
										headingConfig: {
											itemMargin: '2.5rem 0px 0px 0px'
										}
									}
								},
								{
									component: 'FLXKeyValueListComponent',
									inputs: {
										heading: 'Installer Details',
										data$: 'installer_details',
										itemMargin: '0 0 35px 0'
									}
								},
								{
									component: 'FLXKeyValueListComponent',
									inputs: {
										heading: 'Customer Contact Details',
										data$: 'customer_details',
										itemMargin: '0 0 35px 0'
									}
								}
						  ]
						: []),
				...(state === 331 || state === 185 || state === 335
					? [
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									data$: 'important_information',
									heading: 'Job Summary',
									color: 'secondary',
									colouredHeading: new KVLHeading('Your payment was declined. Please fix the issues listed below and resubmit for payment.', 'secondary')
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Installation Details',
									data$: 'installationKeyValues2',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Installer Details',
									data$: 'installer_details',
									itemMargin: '0 0 35px 0'
								}
							}
					  ]
					: []),
				...(state === 176
					? [
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: 'Job Summary',
									instructions: ['Post request for payment was unsuccessful.', 'Check if payment is complete, if not please do manual payment.	']
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Installation Details',
									data$: 'installationKeyValues2',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Installer Details',
									data$: 'installer_details',
									itemMargin: '0 0 35px 0'
								}
							}
					  ]
					: []),
				...(state === 186
					? [
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									data$: 'important_information',
									heading: 'Customer Activation - FSIA',
									color: 'secondary',
									colouredHeading: new KVLHeading('FSIA Error', 'secondary')
								}
							},

							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Installer Details',
									data$: 'installer_details',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Customer Contact Details',
									data$: 'customer_details',
									itemMargin: '0 0 35px 0'
								}
							}
					  ]
					: []),
				...(state === 311
					? [
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									heading: 'Installation Details',
									data$: 'installationKeyValues',
									itemMargin: '0 0 35px 0'
								}
							}
					  ]
					: [
							// {
							// 	component: 'FLXKeyValueListComponent',
							// 	inputs: {
							// 		data$: 'important_information',
							// 		heading: 'Job Summary',
							// 		color: 'default',
							// 		colouredHeading: new KVLHeading('Important Information', 'secondary')
							// 	}
							// },
							// {
							// 	component: 'FLXKeyValueListComponent',
							// 	inputs: {
							// 		heading: 'Installation Details',
							// 		data$: 'installationKeyValues',
							// 		itemMargin: '0 0 35px 0'
							// 	}
							// },
							// {
							// 	component: 'FLXKeyValueListComponent',
							// 	inputs: {
							// 		data$: 'installer_details',
							// 		colouredHeading: new KVLHeading('Installer details', 'default')
							// 	}
							// }
					  ]),
				...(state === 238
					? [
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: 'Job Summary',
									headingConfig: {
										weight: 'normal',
										size: 'medium'
									}
								}
							},
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: 'Integration triggers for state 21 and 24 have failed',
									headingConfig: {
										weight: 'bold',
										size: 'small',
										color: 'secondary'
									}
								}
							},
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: 'Re-trigger the integration calls by clicking the button below',
									headingConfig: {
										weight: 'normal',
										size: 'extra-small',
										color: 'secondary',
										itemMargin: '-8px 0 25px 0'
									}
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									data$: 'installationKeyValues2',
									heading: 'Installation Details',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									data$: 'installer_details',
									heading: 'Installer Details'
								}
							}
					  ]
					: [])
			]
		},
		// showTabs: true,
		serverCalls: {
			important_information: {
				errorMessage: 'Cannot get job info summary',
				directCall: (http, store) => {
					return forkJoin([
						store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1)
						),
						store.select(getAllInfo).pipe(
							skipWhile(x => !x),
							take(1)
						)
					]).pipe(
						take(1),
						map(([job, allinfo]) => {
							const autopay_decline_reason = `${job?.office_use?.claim_status}`;
							console.log('autopay fail ------', autopay_decline_reason);
							const { job_information } = job;
							const list: any = [];
							console.log('important information', autopay_decline_reason);
							if (job_information.interstate_comments) {
								const obj = job_information.interstate_comments;
								if (obj) {
									const message = obj.existing_dish ? obj.existing_dish : '';
									// const timeStamp = new Date(Number(obj.timeStamp));
									const previousState = findName(Number(obj.currentState), allinfo['states'])?.description;
									console.log('hitting here further');

									list.push({
										'Message from previous state': message,
										//    'Time stamp': `${timeStamp.toDateString()} ${timeStamp.toTimeString()}`,
										'Where did this come from': 'testing previous state'
									});
								}
							}

							if (state === 331 || state === 335) {
								list.push({
									'Reason for failure': `${autopay_decline_reason}`
								});
							}
							if (state === 186) {
								list.push({
									'FSIA Activation Failure Reason': job_information?.unable_to_activate_reason
								});
							}
							if (state === 248) {
								const replacementDeviceId = Number(job?.office_use?.device_details?.replacement_device?.id);
								const device = allinfo['config_options']?.general?.mc_insurance_items?.find(x => x.id === replacementDeviceId);

								let newInvoiceQueryMessage;
								let newProofMessage;
								const proof_of_purchase_queries = job?.job_information?.purchase_proof_requests;

								const additionalcosts_queries = job?.job_information?.additionalcosts_queries;
								if (additionalcosts_queries) {
									if (Array.isArray(additionalcosts_queries)) {
										newInvoiceQueryMessage = additionalcosts_queries[additionalcosts_queries.length - 1]?.queryreply;
									} else {
										newInvoiceQueryMessage = additionalcosts_queries?.queryreply;
									}
								} else {
									newInvoiceQueryMessage = 'No new messages';
								}

								if (proof_of_purchase_queries) {
									if (Array.isArray(proof_of_purchase_queries)) {
										newProofMessage = proof_of_purchase_queries[proof_of_purchase_queries.length - 1]?.queryreply;
									} else {
										newProofMessage = proof_of_purchase_queries?.queryreply;
									}
								} else {
									newProofMessage = 'No new messages';
								}

								list.push({
									'Invoice query reply from SP': newInvoiceQueryMessage,
									'Proof of purchase request reply': newProofMessage,
									'Current Invoice Amount': `R ${job_information?.claim_value ?? 0}`,
									'Expected Voucher Cost': `R ${device?.max_retail_price ?? 0}`
								});
							}
							if (state === 247) {
								const replacementDeviceId = Number(job?.office_use?.device_details?.replacement_device?.id);
								const device = allinfo['config_options']?.general?.mc_insurance_items?.find(x => x.id === replacementDeviceId);

								list.push({
									'Current Invoice Amount': `R ${job_information?.claim_value ?? 0}`,
									'Expected Voucher Cost': `R ${device?.max_retail_price ?? 0}`
								});
							}
							if (state === 271) {
								const replacementDeviceId = Number(job?.office_use?.device_details?.replacement_device?.id);
								const device = allinfo['config_options']?.general?.mc_insurance_items?.find(x => x.id === replacementDeviceId);
								const messageobj = job?.job_information?.purchase_proof_requests;
								let message = '';
								if (messageobj) {
									if (Array.isArray(messageobj)) {
										message = messageobj[messageobj.length - 1].queryreply;
									} else {
										message = messageobj.queryreply;
									}
								}

								list.push({
									'Message from SP': `${message}`,
									'Current Invoice Amount': `R ${job_information?.claim_value ?? 0}`,
									'Expected Voucher Cost': `R ${device?.max_retail_price ?? 0}`
								});
							}
							if (state === 254) {
								console.log(job?.job_information?.voucherclaim_fail_comments);
								const voucherFailReasons = job?.job_information?.voucherclaim_fail_reason;

								list.push({
									'Reason for problem': voucherFailReasons ?? 'no reason found!'
								});
							}

							if (state === 311) {
								return null;
							}

							if (list.length > 0) {
								return list;
							} else {
								list.push({
									'Not found': 'No information was sent'
								});
								return list;
							}
						})
					);
				}
			},
			installer_details: {
				errorMessage: 'Cannot get job info summary',
				directCall: (http, store) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.pipe(
							take(1),
							map(info => {
								const { claim, team_leader } = info;
								const list: any = [];
								// Installer details

								list.push({
									company_name: team_leader?.sp?.name ?? '',
									accreditation_number: team_leader?.sp?.accreditation_number ?? '',
									'Installation technician qualification number': team_leader?.qualification_number ?? '',
									'Installer/Technician name': team_leader?.full_name ?? '',
									contact_person: team_leader?.sp?.contact_person ?? '',
									contact_number: team_leader?.sp?.contact_primary ?? ''
								});
								if (state === 311) {
									return null;
								}

								return list;
							})
						);
				}
			},
			customer_details: {
				errorMessage: 'Cannot get job info summary',
				directCall: (http, store) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.pipe(
							take(1),
							map(info => {
								const { claim, team_leader, identities } = info;
								const list: any = [];
								// Customer details
								if (claim?.applicant) {
									list.push({
										full_name: claim?.applicant?.first_name + ' ' + claim?.applicant?.surname,
										// ...(state !== 186 ? { customer_id: claim?.applicant?.id_number ?? '', subscriber_number: identities?.subscriber_number ?? '' } : {}),
										...(state !== 186 ? { customer_id: claim?.applicant?.id_number ?? '', subscriber_number: claim?.applicant?.information?.dstv_account_number ?? '' } : {}),
										installation_address:
											state === 186
												? claim?.address
												: `${claim?.loan_information?.propertystreetaddress ?? ''} ${claim?.loan_information?.propertystreetaddress_2 ?? ''} ${
														claim?.loan_information?.propertysuburb ?? ''
												  } ${claim?.loan_information?.propertycity ?? ''}, ${claim?.loan_information?.suburbcode ?? ''}`,
										email_address: claim?.loan_information?.email ?? claim?.loan_information?.Email ?? '',
										contact_number: claim?.applicant?.contact_number,
										GPS_Coordinates: claim?.location
									});
								}
								return list;
							})
						);
				}
			},
			...(state === 247 || state === 248 || state === 311
				? {
						installationKeyValues: {
							errorMessage: "Couldn't return installation info",
							directCall: (http, store, sq) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										filter(x => !!x),
										take(1),
										map(res => res as any)
									),
									store.select(getAllInfo).pipe(
										filter(x => !!x),
										take(1),
										map(res => res as any)
									)
								]).pipe(
									map(([job, info]) => {
										const { appointment, job_information, claim } = job;
										const { skills, appointment_types } = info;
										const skillid = job?.skill;
										let installationtype, skill, streetaddress, suburb, appointmentdate, appointmenttype, appointmentname;

										installationtype = claim?.type;
										for (let i = 0; i < skills?.length; i++) {
											if (skillid === skills[i]?.id) {
												skill = skills[i]?.name;
											}
										}
										streetaddress = claim?.loan_information.propertystreetaddress;
										suburb = claim?.loan_information.propertysuburb;
										appointmentdate = appointment[0]?.range_start;
										appointmenttype = appointment[0]?.appointment_type;
										for (let i = 0; i < appointment_types?.length; i++) {
											if (appointmenttype === appointment_types[i]?.id) {
												appointmentname = appointment_types[i]?.name;
											}
										}
										const installationdetails = {
											'Installation Type': installationtype,
											Skill: skill,
											Address: `${streetaddress}, ${suburb}`,
											'Appointment Date': `${moment(appointmentdate).format(moment.HTML5_FMT.DATE)}`,
											'Appointment Time': `${appointmentname} ${moment(appointmentdate).format(moment.HTML5_FMT.TIME)}`
										};
										return [installationdetails];
									})
								);
							}
						}
				  }
				: {}),
			...(stateArray1.includes(state)
				? {
						installationKeyValues2: {
							errorMessage: 'The installation details could not be retrieved.',
							directCall(_http, _store, _sq, _bf, _controller, _modal) {
								return forkJoin([
									_store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map((mapped: any) => mapped)
									),
									_store.select(getAllInfo).pipe(
										skipWhile(x => !x),
										take(1),
										map((mapped: any) => mapped)
									)
								]).pipe(
									map(([job, info]: any) => {
										const { skills } = info;
										let installation_type;
										const { claim, skill } = job;
										const { applicant, loan_information, address } = claim;
										const { first_name, surname, contact_number } = applicant;
										const voucher = loan_information?.vouchers?.title;
										for (let i = 0; i < skills.length; i++) {
											if (skill === skills[i].id) {
												installation_type = skills[i].name;
											}
										}

										const productype = 'Decoder';
										const installationdetails = {
											'Full name': `${first_name} ${surname}`,
											'Customer number': `${contact_number}`,
											'Installation address': `${address}`,
											'Voucher numbers': `${voucher}`,
											'Installation type': `${installation_type}`,
											'Product type': `${productype}`
										};
										return [installationdetails];
									})
								);
							}
						}
				  }
				: {}),
			...(state === 186
				? {
						decoder: {
							errorMessage: 'Cannot get decoder details',
							directCall: (http, store) => {
								return store
									.select(getData)
									.pipe(
										skipWhile(x => !x.voucher),
										pluck('voucher'),
										take(1)
									)
									.pipe(
										map((voucher: any) => {
											const list: any = [];
											// Decoder details
											list.push(GetDecoderSerialNumberDisplay(voucher?.decoder_number));
											return list;
										})
									);
							}
						}
				  }
				: {}),
			...(state === 254
				? {
						decoderInfoKeyValues: {
							errorMessage: 'Something went wrong with decoder info',
							directCall: (http, store, sq) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(itt => !itt),
										take(1),
										map(res => res as any)
									),
									store.select(getData).pipe(
										skipWhile(v => !v.voucher),
										pluck('voucher'),
										take(1)
									)
								]).pipe(
									map(([job, voucher]) => {
										console.log({ job, voucher });
										const decoderDetails = GetDecoderSerialNumberDisplay(voucher?.decoder_number);

										const decoderInfo = {
											...decoderDetails,
											'Voucher Code': job?.job_information?.vouchers?.voucher_code ?? 'No additional decoder information',
											'Additional Decoder Info ': job?.job_information?.vouchers?.title ?? 'No additional decoder information'
										};
										return [decoderInfo];
									})
								);
							}
						}
				  }
				: {})
		},
		initFormFields: (bf, item, instance, sq, store) => {
			if (new_state) {
				bf.patchValues({ new_state });
			}
			combineLatest([
				store.select(getCurrentUser).pipe(filter(x => !!x, take(1))),
				store.select(getFullItemTwo).pipe(
					filter(x => !!x),
					take(1)
				)
			])
				.pipe(take(1))
				.subscribe(([user, job]) => {
					if (state === 176 || state === 188 || state === 187) {
						bf.patchValues({ payment_validated: false });
						job?.job_information?.extra_cost_total ? bf.patchValues({ new_state: 183 }) : bf.patchValues({ new_state: 28 });
					}
					bf.patchValues({
						author: user?.user?.id,
						author_name: user?.user?.full_name,
						currentState: job?.state
					});
				});
		},
		navs: [
			...(state === 177 || state === 233
				? [
						{
							text: 'Send to 4-sure support',
							nextNode: 'requestQA',
							color: 'secondary'
						},
						{
							text: 'Approve',
							nextNode: 'resolvedProblem'
						}
				  ]
				: state === 180
				? [
						{
							text: 'Continue',
							nextNode: 'inFiledTechSummary'
						}
				  ]
				: state === 186
				? [
						{
							text: 'Error Fixed',
							nextNode: 'resolvedProblem',
							color: 'secondary'
						}
				  ]
				: state === 176 || state === 188 || state === 187 //uncommen
				? [
						{
							text: 'Send to QA',
							nextNode: 'sendToQa',
							color: 'secondary'
						},
						{
							text: 'Send to support',
							nextNode: 'decline',
							color: 'secondary'
						},
						{
							text: 'Submit',
							nextNode: 'submissionSuccess',
							serverFirst: true,
							optIntoValidation: true,
							color: 'primary',
							linkType: 'submit' as any,
							serverCalls: {
								response: {
									serviceVariable: 'mulService',
									functionName: 'updateJob',
									errorMessage: 'Job could not be updated!!'
								}
							}
						}
				  ]
				: state === 252 || state === 253
				? [
						{
							text: 'Query',
							nextNode: 'QueryInvoiceNode',
							color: 'secondary'
						},
						{
							text: 'Payment Complete',
							nextNode: 'QueryInvoiceNode',
							color: 'primary'
						}
				  ]
				: state === 311
				? [
						{
							text: 'set appointment',
							nextNode: 'SetAppointment',
							color: 'primary'
						}
				  ]
				: state === 247 || state === 248 || state === 271
				? [
						{
							text: 'request proof of purchase',
							nextNode: 'RequestProofOfPurchase',
							color: 'secondary'
						},
						{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'alert' },
						{
							text: 'Approve',
							nextNode: 'submissionSuccess',
							color: 'primary',
							serverCalls: {
								response: {
									serviceVariable: 'mulService',
									functionName: 'updateJob',
									errorMessage: 'Job could not be updated!!'
								}
							}
						}
				  ]
				: state === 238
				? [
						{
							text: 're-trigger integration calls',
							serverFirst: true,
							serverCalls: {
								response: {
									errorMessage: 'Could not re-trigger',
									directCall: (_http: HttpClient, _store: Store) => {
										return _store.select(getFullItemTwo).pipe(
											skipWhile(x => !x),
											take(1),
											switchMap((response: any) => {
												const { id } = response;
												const request = {
													job_id: id,
													new_state: 26
												};
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);

												return of({});
											})
										);
									}
								}
							},
							nextNode: 'SubmissionSuccess',
							color: 'primary'
						}
				  ]
				: state === 285
				? [
						{
							text: 'Payment Complete',
							nextNode: 'SubmissionSuccess',
							color: 'secondary',
							serverCalls: {
								response: {
									errorMessage: 'Could not re-trigger',
									directCall: (_http: HttpClient, _store: Store) => {
										return _store.select(getFullItemTwo).pipe(
											skipWhile(x => !x),
											take(1),
											switchMap((response: any) => {
												const { id } = response;
												const request = {
													job_id: id,
													new_state: 26
												};
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);

												return of({});
											})
										);
									}
								}
							},
						}
				  ]
				: state === 185
				? [
						{
							text: 'send query to installer',
							color: 'default',
							nextNode: 'SendQueryToSP'
						},
						{
							text: 'resubmit for payment',
							color: 'primary',
							serverFirst: true,
							serverCalls: {
								response: {
									serviceVariable: 'mulService',
									functionName: 'updateJob',
									errorMessage: 'Job could not be resubmitted for payment'
								}
							},
							nextNode: 'SubmissionSuccess'
						}
				  ]
				: // : state === 331
				  // ? [
				  // 		{
				  // 			text: 'cancel job',
				  // 			color: 'secondary',
				  // 			serverCalls: {
				  // 				response: {
				  // 					errorMessage: 'Job could not be updated!',
				  // 					directCall: (_httP: HttpClient, _store: Store, sq, _bf: BigFormService, ctrl, _modal: ModalService) => {
				  // 						_bf.patchValues({ new_state: 28 });
				  // 						_modal.openModalDirectly((instance: any) => {
				  // 							instance.type = 'warning';
				  // 							instance.message = 'Are you sure? This will close the job';
				  // 							instance.navButtons = [
				  // 								{
				  // 									text: 'cancel',
				  // 									linkType: 'close',
				  // 									clickHandler: () => {
				  // 										_bf.patchValues({ new_state: 341 });
				  // 									}
				  // 								},
				  // 								{
				  // 									text: 'close job',
				  // 									linkType: 'close',
				  // 									serverFirst: true,
				  // 									clickHandler: () => {
				  // 										_store.dispatch(
				  // 											new MakeServerCall({
				  // 												dataKey: 'updateJob',
				  // 												errorMessage: 'Could not update job',
				  // 												serviceVariable: 'mulService',
				  // 												functionName: 'updateJob',
				  // 												nextNode: 'SubmissionSuccess'
				  // 											})
				  // 										);
				  // 									}
				  // 								}
				  // 							];
				  // 						});
				  // 						return of({});
				  // 					}
				  // 				}
				  // 			}
				  // 		},
				  // 		{
				  // 			text: 'manual payment made',
				  // 			color: 'secondary',

				  // 			serverCalls: {
				  // 				response: {
				  // 					errorMessage: 'Job could not be updated!',
				  // 					directCall: (_httP: HttpClient, _store: Store, sq, _bf: BigFormService, ctrl, _modal: ModalService) => {
				  // 						_bf.patchValues({ new_state: 28 });
				  // 						_modal.openModalDirectly((instance: any) => {
				  // 							instance.type = 'warning';
				  // 							instance.message = 'Are you sure? This will close the job';
				  // 							instance.navButtons = [
				  // 								{
				  // 									text: 'cancel',
				  // 									linkType: 'close',
				  // 									clickHandler: () => {
				  // 										_bf.patchValues({ new_state: 341 });
				  // 									}
				  // 								},
				  // 								{
				  // 									text: 'close job',
				  // 									// nextNode: 'SubmissionSuccess',
				  // 									linkType: 'close',
				  // 									serverFirst: true,
				  // 									clickHandler: () => {
				  // 										_store.dispatch(
				  // 											new MakeServerCall({
				  // 												dataKey: 'updateJob',
				  // 												errorMessage: 'Could not update job',
				  // 												serviceVariable: 'mulService',
				  // 												functionName: 'updateJob',
				  // 												nextNode: 'SubmissionSuccess'
				  // 											})
				  // 										);
				  // 									}
				  // 								}
				  // 							];
				  // 						});
				  // 						return of({});
				  // 					}
				  // 				}
				  // 			}
				  // 		},
				  // 		{
				  // 			text: 'NEXT',
				  // 			color: 'primary',
				  // 			serverFirst: true,
				  // 			nextNode: 'DetailsNode'
				  // 		}
				  //   ]
				  // : state === 335
				  // ? [
				  // 		{
				  // 			text: 'send query to installer',
				  // 			nextNode: 'SendQueryToSP'
				  // 			// location: 'center'
				  // 		},
				  // 		{
				  // 			text: 'close job',
				  // 			color: 'secondary',
				  // 			serverCalls: {
				  // 				response: {
				  // 					errorMessage: 'Job could not be updated!',
				  // 					directCall: (_httP: HttpClient, _store: Store, sq, _bf: BigFormService, ctrl, _modal: ModalService) => {
				  // 						_bf.patchValues({ new_state: 28 });
				  // 						_modal.openModalDirectly((instance: any) => {
				  // 							instance.type = 'warning';
				  // 							instance.message = 'Are you sure? This will close the job';
				  // 							instance.navButtons = [
				  // 								{
				  // 									text: 'cancel',
				  // 									linkType: 'close',
				  // 									clickHandler: () => {
				  // 										_bf.patchValues({ new_state: 341 });
				  // 									}
				  // 								},
				  // 								{
				  // 									text: 'close job',
				  // 									linkType: 'close',
				  // 									serverFirst: true,
				  // 									clickHandler: () => {
				  // 										_store.dispatch(
				  // 											new MakeServerCall({
				  // 												dataKey: 'updateJob',
				  // 												errorMessage: 'Could not update job',
				  // 												serviceVariable: 'mulService',
				  // 												functionName: 'updateJob',
				  // 												nextNode: 'SubmissionSuccess'
				  // 											})
				  // 										);
				  // 									}
				  // 								}
				  // 							];
				  // 						});
				  // 						return of({});
				  // 					}
				  // 				}
				  // 			}
				  // 		},
				  // 		{
				  // 			text: 'manual payment made',
				  // 			color: 'secondary',

				  // 			serverCalls: {
				  // 				response: {
				  // 					errorMessage: 'Job could not be updated!',
				  // 					directCall: (_httP: HttpClient, _store: Store, sq, _bf: BigFormService, ctrl, _modal: ModalService) => {
				  // 						_bf.patchValues({ new_state: 28 });
				  // 						_modal.openModalDirectly((instance: any) => {
				  // 							instance.type = 'warning';
				  // 							instance.message = 'Are you sure? This will close the job';
				  // 							instance.navButtons = [
				  // 								{
				  // 									text: 'cancel',
				  // 									linkType: 'close',
				  // 									clickHandler: () => {
				  // 										_bf.patchValues({ new_state: 341 });
				  // 									}
				  // 								},
				  // 								{
				  // 									text: 'close job',
				  // 									// nextNode: 'SubmissionSuccess',
				  // 									linkType: 'close',
				  // 									serverFirst: true,
				  // 									clickHandler: () => {
				  // 										_store.dispatch(
				  // 											new MakeServerCall({
				  // 												dataKey: 'updateJob',
				  // 												errorMessage: 'Could not update job',
				  // 												serviceVariable: 'mulService',
				  // 												functionName: 'updateJob',
				  // 												nextNode: 'SubmissionSuccess'
				  // 											})
				  // 										);
				  // 									}
				  // 								}
				  // 							];
				  // 						});
				  // 						return of({});
				  // 					}
				  // 				}
				  // 			}
				  // 		},
				  // 		{
				  // 			text: 'next',
				  // 			color: 'primary',
				  // 			serverFirst: true,
				  // 			nextNode: 'DetailsNode'
				  // 		}
				  //   ]
				  [
						{
							text: 'SP Rework',
							nextNode: 'rework',
							color: 'secondary'
						},
						{
							text: 'QA Regional',
							nextNode: 'requestQA',
							color: 'secondary'
						},
						{
							text: state === 179 ? 'Fix Issue' : state === 183 ? 'Done' : 'Issue Fixed',
							nextNode: 'resolvedProblem',
							color: 'primary'
						}
				  ])
		]
	};
};
