<flx-flex-container alignItems="center">
	<flx-heading size="medium" align="center" type="creation" weight="extra-light">Create new job card</flx-heading>
	<!-- show existing jobs if they exist -->
	<form [formGroup]="$any(_bf.bigForm)">
		<ng-container> </ng-container>
		<!-- add new job -->
		<ng-container *ngFor="let job of $any(_bf.bigForm).get('jobs')?.controls; let i = index">
			<div formArrayName="jobs">
				<div class="job-card-section" [formGroupName]="i">
					<div class="job-card-input">
						<flx-dropdown
							(click)="purchaseDecoderAction($event, i)"
							formControlName="skill_id"
							placeHolder="Job Type"
							[displayOptions]="displayConfig"
							[searchEnabled]="false"
							[itemsOption]="jobtypes$ | async"
							(itemsSelected)="jobTypeAction($event, i)"
							#job_type
						></flx-dropdown>
						<flx-reactive-form-error-display [control]="_bf.bigForm?.controls?.['jobs']?.['controls']?.skill_id"></flx-reactive-form-error-display>
					</div>
					<div class="job-card-input">
						<flx-dropdown
							formControlName="to_purchase"
							[displayOptions]="displayConfig"
							[itemsOption]="purchaseOptions"
							placeHolder="Are you supplying decoder for customer?"
							[searchEnabled]="false"
							[disabled]="_bf.bigForm.get('jobs').value[i].skill_id === 99"
							#is_repair
						></flx-dropdown>
					</div>

					<div class="device-holder">
						<div class="job-card-input">
							<flx-dropdown
								(click)="purchaseDecoderAction($event, i)"
								formControlName="decoder_type"
								[displayOptions]="displayConfig"
								[itemsOption]="decoderTypes$ | async"
								placeholder="Deccoder Type"
								[searchEnabled]="false"
								(itemsSelected)="setDeviceType($event, i)"
							></flx-dropdown>
						</div>
					</div>

					<div class="add-another-job">
						<flx-icon *ngIf="jobList.length !== 1" (click)="removeJob(i)" [type]="'subtraction'"></flx-icon>
						<flx-icon *ngIf="i === jobList.length - 1" (click)="incJobCount(i + 1)" [type]="'addition'"></flx-icon>
					</div>
					<flx-glow-line *ngIf="jobList.length > 1" margin="0.5rem 0"></flx-glow-line>
				</div>
			</div>
		</ng-container>
	</form>
</flx-flex-container>
