<flx-heading size="medium" [align]="'center'" weight="extra-light" type="creation">{{ title }}</flx-heading>
<form [formGroup]="bf.bigForm">
	<div class="policyInfoContainer">
		<div class="search-container">
			<flx-input-text
				*ngIf="bf.bigForm.get('policy_lookup')"
				size="medium"
				type="text"
				[noEndingSpace]="true"
				[formControlName]="'policy_lookup'"
				class="search-policy"
				[autocomplete]="false"
				radius="4px 0 0 4px"
				[maxLength]="15"
				(keyup.enter)="lookup()"
			></flx-input-text>
			<flx-icon
				[ngClass]="(isLoading$ | async) || bf.bigForm.get('policy_lookup')?.invalid ? 'disabled' : ''"
				[type]="'search'"
				use="button"
				color="button"
				hover="container"
				layout="right"
				(click)="lookup()"
			></flx-icon>
		</div>
		<flx-reactive-form-error-display style="text-align: center" [control]="$any(bf)?.bigForm.controls['policy_lookup']" class="error"> </flx-reactive-form-error-display>
		<div style="text-align: center">Example: 'HLC0000000000'</div>
		<br /><br />

		<div class="spanner">
			<ng-container *ngIf="isLoading$ | async"> <flx-loader-component></flx-loader-component> </ng-container>
			<ng-container *ngIf="policies?.length < 1 && hasDoneLookup && !(isLoading$ | async)"> <flx-alert type="info" [message]="errorMessage"></flx-alert> </ng-container>
			<ng-container *ngIf="(policies$ | async)?.payload?.policies && !(isLoading$ | async) && (isLoaded$ | async)">
				<flx-panel alignItems="center">
					<ng-container *ngIf="policies as policyDetailsArray">
						<div
							*ngFor="let policy of policyDetailsArray; index as idx"
							[class.invalid]="!isValidPolicy(policy)"
							[class.selected]="selectedPolicy === idx"
							(click)="isValidPolicy(policy) ? selectPolicy(idx) : null"
							class="policy-result-container"
						>
							<div class="policy-description">
								<span>{{ policy.POLICYDESCRIPTION }}</span>
							</div>

							<div class="insured-sum">{{ checkSumInsuredValue(policy.SUMINSURED) | currency: 'R' }}</div>

							<div class="validity">
								<div *ngIf="isValidPolicy(policy); else invalid">Policy valid</div>
								<ng-template #invalid> <div>Policy invalid</div> </ng-template>
							</div>
							<div class="indicator"><flx-indicator *ngIf="isValidPolicy(policy)" color="primary" position="right"></flx-indicator></div>
						</div>
					</ng-container>
				</flx-panel>
			</ng-container>
		</div>
	</div>
</form>
