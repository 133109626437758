import { Flow_0_0_2, getFullItemOne, getAllInfo, getFullItemTwo, getSelectedItemOne, getData, getSubmissionData, getAppointmentTypes, getSkills } from '@flexus/core';
import { of, forkJoin, iif, combineLatest } from 'rxjs';
import { skipWhile, take, map, pluck, filter, switchMap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { findName } from '@flexus/utilities';
import gql from 'graphql-tag';
import moment from 'moment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const BUI_81: Flow_0_0_2 = {
	id: '81',
	name: 'job_created_awaiting_autorisation',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Awaiting authorisation : ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Awaiting authorisation';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Approve new job request'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),

	serverCalls: {
		skills: {
			serviceVariable: 'buiService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills could be found!'
		},
		appointmentTypes: {
			serviceVariable: 'buiService',
			functionName: 'getAppointmentTypes',
			errorMessage: 'No Appointment Types could be found!'
		},
		spSearch: {
			errorMessage: 'Sp not found',
			responseSlice: 'payload',
			directCall: (http, store) => {
				return store.select(getFullItemTwo).pipe(
					filter(x => !!x),
					take(1),
					switchMap(job => {
						const noSP = of({ payload: 'No sp' });
						return iif(
							() => job?.sp,
							http
								.post(`${environment.api_url}v1/sp_action/search/`, {
									sp_id: job?.sp
								})
								.pipe(
									filter(x => !!x),
									take(1)
								),
							noSP
						);
					})
				);
			}
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			component: {
				children: [
					{
						component: 'FLXKeyValueDisplayWithInstructionsComponent',
						inputs: {
							title: 'New Skill Requested -',
							instructions: ['A request that a new job card be opened for this claim has been made.', 'Please approve the request.'],
							keyValueList$: 'keyValueList',
							title$: 'title'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList2' }
					}
				]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Error getting claim information for node: Summary. in state 81',
					directCall: (http, store) => {
						return store.select(getFullItemOne).pipe(
							skipWhile(x => !x),
							take(1),
							map(claim => {
								const clientName = {
									first_name: claim && claim?.applicant && claim?.applicant?.first_name ? claim?.applicant?.first_name : '--',
									surname: claim && claim?.applicant && claim?.applicant?.surname ? claim?.applicant?.surname : '--'
								};

								const infoPackOne = {
									'Claim Type': claim && claim?.loan_information ? claim?.loan_information.claimtype : '',
									Address: claim ? claim?.address : '',
									'Client Name': `${clientName.first_name} ${clientName.surname}`,
									'Contact Number': claim && claim?.loan_information ? claim?.loan_information.contactnumber : '',
									'Cell Number': claim && claim?.loan_information ? claim?.loan_information.cellnumber : '',
									'What Matters': claim && claim?.loan_information ? claim?.loan_information.whatmatters : ''
								};

								return [infoPackOne];
							})
						);
					}
				},

				keyValueList2: {
					errorMessage: 'Error getting SP details for node: Summary. in state 81',
					directCall: (http, store) => {
						return combineLatest([
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getData).pipe(
								pluck('spSearch'),
								skipWhile(x => !x),
								take(1)
							),
							store.select(getSelectedItemOne).pipe(
								filter(x => !!x),
								pluck('jobs'),
								take(1)
							)
						]).pipe(
							take(1),
							map(([job, allInfo, spSearch, relatedJobs]) => {
								const newRelatedJobs = relatedJobs && Array.isArray(relatedJobs) ? relatedJobs : [relatedJobs];

								let jobsString = '';
								for (const j of newRelatedJobs) {
									jobsString +=
										`${findName(j.skill, allInfo['skills']).name} / ${findName(j.sp, allInfo['sps']).name} / ${findName(j.state, allInfo['states'])?.description}  ` + ' ';
								}

								const infoPackTwo =
									spSearch === 'No sp'
										? {
												'Reason for New Skill': job?.office_use?.creation_reason ?? '',
												'Other Existing Job Cards on This Claim': jobsString
										  }
										: {
												'Person Requesting New Job': spSearch?.contact_person ?? '--',
												'Contact Number': spSearch?.contact_primary ?? '--',
												'SP They Work For': findName(parseInt(job?.sp, 10), allInfo['sps'])?.name ? findName(parseInt(job?.sp, 10), allInfo['sps'])?.name : 'No SP found',
												'Reason for New Skill': job?.office_use?.creation_reason ?? '',
												'Other Existing Job Cards on This Claim': jobsString
										  };

								return [infoPackTwo];
							})
						);
					}
				},

				title: {
					errorMessage: 'Error getting title in state 81',
					directCall: (http, store) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								skipWhile(x => !x),
								take(1)
							)
						]).pipe(
							take(1),
							map(([job, allInfor]) => {
								return findName(job.skill, allInfor['skills']).name;
							})
						);
					}
				}
			},

			navs: [
				{
					text: 'Decline',
					color: 'secondary',
					linkType: 'submit',
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => ({ Related_jobs: job?.id, job_id: job?.id, new_state: 45 })),
										switchMap(dataSub => {
											console.log({ dataSub });
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					},
					nextNode: 'SubmissionSuccess'
				},
				{
					text: 'Approve',
					color: 'primary',
					nextNode: 'SetAppointment'
				}
			]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(null),
						skillcatagory: new UntypedFormControl(null)
					})
				);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skill
										skillcatagory
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(values => {
						bf.bigForm.get('appointmentData').get('skill')?.patchValue(values.skill);
						bf.bigForm.get('appointmentData').get('skillcatagory')?.patchValue(values.skillcatagory);
					});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												surname
											}
											loan_information {
												contactnumber
												cellnumber
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{
											'Client Name': queryData ? `${queryData.first_name} ${queryData.surname}` : ''
										},
										{ 'Contact Number': queryData ? `${queryData.contactnumber}` : '' },
										{ 'Mobile Number': queryData ? `${queryData.cellnumber}` : '' }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											loan_information {
												onsiteperson
												onsitecontact
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': queryData ? `${queryData.onsiteperson}` : '' }, { 'Onsite Contact Number': queryData ? `${queryData.onsitecontact}` : '' }];
								})
							);
					}
				}
			},
			component: 'FLXJobAppointmentComponent',
			navs: [
				{
					text: 'continue',
					color: 'primary',
					linkType: 'submit',
					nextNode: 'SetExcess'
				}
			]
		},
		SetExcess: {
			component: 'FLXJobExcessComponent',
			initFormFields: (bf, item, instance, sq, store) => {
				bf.addControl(
					'excessData',
					new UntypedFormGroup({
						amount: new UntypedFormControl(null, [Validators.required]),
						who_collects: new UntypedFormControl(null, [Validators.required]),
						payment_method: new UntypedFormControl(null, [Validators.required]),
						skill: new UntypedFormControl(null),
						skillcatagory: new UntypedFormControl(null)
					})
				);

				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skill
										skillcatagory
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(values => {
						bf.bigForm.get('excessData').get('skill')?.patchValue(values.skill);
						bf.bigForm.get('excessData').get('skillcatagory')?.patchValue(values.skillcatagory);
					});
			},
			serverCalls: {
				whoPaysExcessOptions: {
					errorMessage: 'Error at serviceProviderList server call. node: Excess. State 81',
					directCall: (http, store) => {
						return store.select(getAllInfo).pipe(
							skipWhile(x => !x),
							take(1),
							map((allInfo: any) => allInfo.excess_who)
						);
					}
				},
				howExcessIsPaidOptions: {
					errorMessage: 'Error at paymentMethodList server call. node: Excess. State 81',
					directCall: (http, store) => {
						return store.select(getAllInfo).pipe(
							skipWhile(x => !x),
							take(1),
							map((allInfo: any) => allInfo.excess_how)
						);
					}
				}
			},
			navs: [
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSkills).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getAppointmentTypes).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, skills, appointmentTypes, submissionData]) => {
										let skillFound = false;

										if (job.office_use && job?.office_use.skill) {
											skillFound = !!(skills as any[])?.find(s => s.name === job?.office_use.skill);
										}

										const appointmentDetails = submissionData ? submissionData.appointment_data : {};

										const date = appointmentDetails.appointmentDatePicker ? moment(appointmentDetails.appointmentDatePicker) : null;

										const range_start = moment(
											new Date(date.year(), date.month(), date.date(), appointmentDetails.appointmentTimePicker.hour, appointmentDetails.appointmentTimePicker.minutes)
										)?.format('YYYY-MM-DDTHH:mm:ss');

										const appointment = {
											range_start: range_start,
											reason: '',
											appointment_type: appointmentTypes.find(type => type.id === appointmentDetails.appointmentTime).id
										};

										const excess = {
											amount: submissionData && submissionData.excess_data ? submissionData.excess_data.amount : 0,
											who_collects: submissionData && submissionData.excess_data ? submissionData.excess_data.who_collects : '',
											payment_method: submissionData && submissionData.excess_data ? submissionData.excess_data.payment_method : ''
										};

										return {
											appointment,
											excess,
											Related_jobs: job?.id,
											job_id: job?.id,
											new_state: skillFound ? 82 : 28
										};
									}),
									switchMap(dataSub => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
									})
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		appointmentData: 'appointment_data',
		excessData: 'excess_data'
	}
};
