import { Flow_0_0_2, getAllInfo, getFullItemOne, getFullItemTwo } from '@flexus/core';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { BET_288 } from './BET_288';
import { findName } from '@flexus/utilities';

export const BET_290: Flow_0_0_2 = {
	id: '290',
	name: 'repudiation_review_query_response',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map((itemOne: any) => {
					if (itemOne) {
						return `Repudiation Review Query Response : ${itemOne?.mid} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Repudiation Review Query Response';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp']),
	serverCalls: {
		...BET_288.serverCalls,
		queryDetails: {
			errorMessage: 'Could get query details',
			serviceVariable: '',
			directCall: (_h, store) =>
				store
					.select(getFullItemTwo)
					.pipe(
						skipWhile((itemTwo: any) => !itemTwo),
						take(1)
					)
					.pipe(
						take(1),
						map(itemTwo => {
							const important_information = { 'Important note': 'The service provider has responded to your query.' };
							if (itemTwo?.job_information?.da_query) {
								const query = !Array.isArray(itemTwo?.job_information?.da_query) ? [{ ...itemTwo?.job_information?.da_query }] : [...itemTwo?.job_information?.da_query];

								return {
									'Important Information': Object.assign(
										{},
										important_information,
										...query.map((q, index) => ({ [`Query ${index + 1}`]: q.query, [`Response ${index + 1}`]: q.queryreply }))
									)
								};
							}

							return { important_information };
						})
					)
		},
		summaryDetails: {
			errorMessage: 'Could get summary details',
			serviceVariable: '',
			directCall: (_h, store) =>
				forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile((itemTwo: any) => !itemTwo),
						take(1)
					),
					store.select(getAllInfo).pipe(
						skipWhile((allInfo: any) => !allInfo),
						map((allInfo: any) => allInfo.appointment_types),
						take(1)
					)
				]).pipe(
					take(1),
					map(([itemTwo, appointmentTypes]: any[]) => {
						const date = moment(itemTwo?.appointment?.[0]?.range_start);
						return {
							'Claim Details': {
								'Claim Type': itemTwo?.claim?.type,
								'Skill Required': itemTwo?.office_use?.skill,
								Address: itemTwo?.address,
								'Appointment Date': date?.format('YYYY-MM-DD'),
								'Appointment Time': `${findName(itemTwo?.appointment?.[0]?.appointment_type, appointmentTypes)?.name} ${date.format('HH:mm')}${
									itemTwo?.appointment?.[0]?.range_end ? ' - ' + moment(itemTwo?.appointment?.[0]?.range_end).format('HH:mm') : ''
								}`
							},
							'Team Leader Details': {
								'Contact Number': itemTwo?.team_leader?.contact_number,
								'Email Address': itemTwo?.team_leader?.email_address
							},
							'Service Provider Details': {
								'Contact Person': itemTwo?.team_leader?.sp?.contact_person,
								'Contact Number': itemTwo?.team_leader?.sp?.contact_primary,
								'Email Address': itemTwo?.team_leader?.sp?.email_receiving
							}
						};
					})
				)
		}
	},
	instructions: {
		editRoles: {
			0: 'Repudiation Review Query Response'
		},
		viewRoles: {
			0: 'Repudiation Review Query Response'
		}
	},
	startNode: 'Summary',
	nodes: {
		...BET_288.nodes,
		Summary: {
			...BET_288.nodes.Summary,
			component: {
				children: [
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							headingConfig: { title: 'Job Summary', color: 'default', size: 'medium' },
							subHeadingConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							keyValueHeadingConfig: { color: 'secondary' },
							objectInfo$: 'queryDetails'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'summaryDetails'
						}
					}
				]
			}
		}
	},
	bigFormToStoreMapper: {
		...BET_288.bigFormToStoreMapper
	}
};
