import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ManifestController, SetNextNode } from '@flexus/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'flx-success-tick',
	templateUrl: './success-tick.component.html',
	styleUrls: ['./success-tick.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXSuccessTickComponent implements OnInit, AfterViewInit {
	constructor(private router: Router, private _control: ManifestController<any>) {}
	@Input() autoClose = true;
	@Input() copyText$: Observable<any>;
	@Input() copyTextHeading = 'Claim Number';
	@Input() heading = 'The submission was successful. You can return to the workflow.';
	@Input() headingcolour: 'success' | 'danger' | 'alert' | 'secondary' = 'success';
	@Input() altNextNode: string;

	ngOnInit() {}

	ngAfterViewInit() {
		if (this.autoClose) {
			setTimeout(() => {
				if (this.altNextNode) {
					this.altDirect();
				} else {
					this.redirect();
				}
			}, 3000);
		}
	}
	redirect() {
		this.router.navigate(['/workflow']);
	}
	altDirect() {
		this._control.dispatch(new SetNextNode(this.altNextNode));
	}
}
