<form [formGroup]="_bf.bigForm">
	<div class="month-date-range-container__custom">
		<!-- <div [ngClass]="{ 'month-date-range-container__custom': !custom_daterange, 'month-date-range-container__custom': custom_daterange }" formGroupName="dates"> -->
		<div class="invoice-number-select" formGroupName="numbersearch">
			<div class="invoice-number-label">Invoice Number</div>
			<input class="flx-text-input" #htmlinput type="text" (paste)="handlePaste($event)" formControlName="invoiceNumber" />
			<!-- <flx-input-text #invoiceNUM label="Invoice Number" formControlName="invoiceNumber" (input)="toggleSearchButtonVisibility()"></flx-input-text> -->
		</div>
		<div class="or">or</div>
		<!-- <div formGroupName="dates"> -->
		<div class="month-date-range-selection-area">
			<div style="margin-bottom: 5px">Dates</div>
			<flx-dropdown
				[displayOptions]="displayConfig"
				[itemsOption]="invoiceRangeOptions"
				[searchEnabled]="false"
				placeHolder="SELECT INVOICE PERIOD"
				(itemsSelected)="getInvoiceDateRangeFromPeriodSelection($event)"
			></flx-dropdown>
		</div>
		<div *ngIf="!custom_daterange" class="selected-month-display-area">
			<div style="box-sizing: border-box; padding-top: 25px">{{ monthName | uppercase }}</div>
		</div>
		<div *ngIf="custom_daterange" class="datepicker" formGroupName="dates">
			<div style="margin-bottom: 3px">From Date</div>
			<input
				class="date-picker-input"
				type="text"
				formControlName="startDate_picker"
				[flxDatepicker]="datePicker1"
				placeholder="Select Start Date"
				readonly
				oninput="setStartDate()"
			/>
			<flx-datepicker #datePicker1></flx-datepicker>
		</div>
		<div *ngIf="custom_daterange" class="datepicker" formGroupName="dates">
			<div style="margin-bottom: 3px">To Date</div>
			<input class="date-picker-input" type="text" formControlName="endDate_picker" [flxDatepicker]="datePicker2" placeholder="Select End Date" readonly />
			<flx-datepicker #datePicker2></flx-datepicker>
		</div>
		<!-- </div> -->
		<div *ngIf="!custom_daterange && showSearchButton" style="box-sizing: border-box; padding-top: 25px">
			<flx-button color="primary" (click)="searchInvoices()">SEARCH INVOICES</flx-button>
		</div>
	</div>
</form>
