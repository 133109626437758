import { Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { RemoveError } from '@flexus/error-handler';
import { CustomValidators } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { filter, map, skipWhile, switchMap, take } from 'rxjs/operators';

export const SIL_IMPACT_CLAIM_CLASS_UPDATE_THIRD_PARTY: Flow_0_0_2 = {
	id: 'ImpactClaimClassUpdateThirdParty',
	itemType: 'flow',
	includeForOnlyStates: ['all'],
	includeForOnlyRoles: [1],
	excludeForOnlyStates: [],
	excludeForOnlyRoles: [],
	isVisible: (item: any) => {
		const IMPACT_CLAIM_TYPE_ID = 6;
		return item.claim_type_id === IMPACT_CLAIM_TYPE_ID;
	},
	name: 'Update third party details',
	header: {
		title: 'Update third party details',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	valuesToBigForm: (bf, store) => {
		store
			.select(getFullItemOne)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe(itemOne => {
				const { loan_information } = itemOne;

				if (loan_information) {
					bf.patchValues({
						_3rdpartyinsurancecompany: loan_information?._3rdpartyinsurancecompany ?? '',
						_3rdpartyvechilereg: loan_information?._3rdpartyvechilereg ?? '',
						_3rdpartyvechilemake: loan_information?._3rdpartyvechilemake ?? '',
						_3rdpartydrivername: loan_information?._3rdpartydrivername ?? '',
						_3rdpartydriverid: loan_information?._3rdpartydriverid ?? '',
						_3rdpartydrivercell: loan_information?._3rdpartydrivercell ?? ''
					});
				}
			});
	},
	startNode: 'ThirdPartyClaim',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		ThirdPartyClaim: {
			component: 'FLXFlatDynamicFormComponent',
			checkValidityForFields: ['_3rdpartyinsurancecompany', '_3rdpartyvechilereg', '_3rdpartyvechilemake', '_3rdpartydrivername', '_3rdpartydriverid', '_3rdpartydrivercell'],
			serverCalls: {
				claimDetailsInfo: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											claim_types {
												id
												name
												description
												skill_groups
												priority
											}
											skills {
												id
												name
											}
											config_options {
												desktop {
													upfront_rejection_reasons
												}
												general {
													ia_request_reasons
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(x => !x),
								take(1)
							);
					}
				}
			},
			inputs: {
				formControls: {
					0: {
						label: 'Insurance Company',
						inputType: 'input',
						defaultValue: '',
						formControlName: '_3rdpartyinsurancecompany',
						validators: [Validators.required, Validators.minLength(4)]
					},
					1: {
						label: 'Vehicle Registration',
						inputType: 'input',
						defaultValue: '',
						formControlName: '_3rdpartyvechilereg',
						validators: [Validators.required, Validators.minLength(4)]
					},
					2: {
						label: 'Vehicle Make',
						inputType: 'input',
						defaultValue: '',
						formControlName: '_3rdpartyvechilemake',
						validators: [Validators.required, Validators.minLength(2)]
					},
					3: {
						label: 'Name',
						inputType: 'input',
						defaultValue: '',
						formControlName: '_3rdpartydrivername',
						validators: [Validators.required, Validators.minLength(2)]
					},
					4: {
						label: 'ID Number',
						inputType: 'input',
						defaultValue: '',
						formControlName: '_3rdpartydriverid',
						validators: [Validators.required, Validators.minLength(8), CustomValidators.numeric, CustomValidators.noSpaces]
					},
					5: {
						label: 'Cell Number',
						inputType: 'input',
						defaultValue: '',
						formControlName: '_3rdpartydrivercell',
						validators: [Validators.required, CustomValidators.cellphone, CustomValidators.noSpaces]
					}
				},
				formLayout: 'three-column'
			},
			navs: [
				{
					text: 'Save',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Claim not updated',
							directCall: (http, store, sq, bf, controller, modal) => {
								bf.bigForm?.updateValueAndValidity();
								return store
									.select(getFullItemOne)
									.pipe(
										filter(x => !!x && Object.keys(x).length !== 0),
										take(1),
										map(res => res as any)
									)
									.pipe(
										switchMap(claim => {
											const { loan_information } = claim;
											return http.post(`${environment.api_url}v1/claim_action/update_claim`, {
												claim_id: claim?.id,
												claim,
												loan_information: {
													...loan_information,
													_3rdpartyinsurancecompany: bf.bigForm.get('_3rdpartyinsurancecompany').value,
													_3rdpartyvechilereg: bf.bigForm.get('_3rdpartyvechilereg').value,
													_3rdpartyvechilemake: bf.bigForm.get('_3rdpartyvechilemake').value,
													_3rdpartydriverid: bf.bigForm.get('_3rdpartydriverid').value,
													_3rdpartydrivercell: bf.bigForm.get('_3rdpartydrivercell').value,
													_3rdpartydrivername: bf.bigForm.get('_3rdpartydrivername').value
												}
											});
										})
									);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		_3rdpartyinsurancecompany: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartyinsurancecompany'
		],
		_3rdpartyvechilereg: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartyvechilereg'
		],
		_3rdpartyvechilemake: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartyvechilemake'
		],
		_3rdpartydriverid: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartydriverid'
		],
		_3rdpartydrivercell: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartydrivercell'
		],
		_3rdpartydrivername: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartydrivername'
		]
	}
};
