import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ServerCall_0_0_2 } from './server-call_0_0_2';
import { HttpClient } from '@angular/common/http';
import { BigFormService } from '../../services/big-form.service';
import { NetworkService } from '../../services/network.service';

export class NAV_BUTTON_ACTION_PANEL_COMPONENT {
	getStaffType(typeindice) {}
	getInitials(fullName) {}
	getActionPanelState() {}
	getAdditionalActionPanelState() {}
	toggleActionPanel() {}
	onClick(action) {}
	onClickSketchPad() {}
	onClickReminders() {}
	onClickJobRequests() {}
	onClickJobRequestDetail() {}
	loadComponent(compOrNode) {}
}
export class NAV_BUTTON_MODAL_COMPONENT {
	type: string;
	navButtons: any[];
	open(): void {}
	close(): void {}
	openTemplateOverlay() {}
	clickBackdrop() {}
	setMessage(content: any[]) {}
	setHeading(heading: string) {}
	takeButtonAction(button) {}
	clearData() {}
	openModalDirectly(arg0: (instance: any) => void) {}
	showCalculations(lineItems: { display: string; value: number; type: 'add' | 'subtract' }[], totalHeading?: string) {}
}

export interface NavButton_0_0_2 {
	text: string;
	linkType?: 'portal' | 'submit' | 'close';
	portalData?: {
		type: 'actionPanel' | 'modal';
		paramFunc:
			| ((instance: NAV_BUTTON_ACTION_PANEL_COMPONENT, mapper: any) => any)
			| ((instance: NAV_BUTTON_MODAL_COMPONENT, store?: Store<any>, bf?: BigFormService, http?: HttpClient) => void);
	};
	intersectData?: (bf: BigFormService, store: Store<any>) => void;
	decisionValue?: any;
	beforeNextNode?: (navComponentInstance: any, onSuccess?: () => void, onFail?: () => void) => void;
	nextNode?: string;
	nextState?: number;
	serverFirst?: boolean;
	optIntoValidation?: boolean;
	location?: 'left' | 'center' | 'right';
	color?: string;
	serverCalls?: {
		[id: string]: ServerCall_0_0_2;
	};
	visible?: (bf: BigFormService, store: Store<any>, networkService: NetworkService) => Observable<boolean>;
	disableOnLoad?: boolean;
	disableOnFirstClick?: boolean;
	clickHandler?: Function;
	self?: any;
}
