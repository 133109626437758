import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { BigFormService } from '@flexus/core';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SelectListOption } from '@flexus/ui-elements';

@Component({
	selector: 'flx-amp-damage-class',
	templateUrl: './amp-damage-class.component.html',
	styleUrls: ['./amp-damage-class.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmpDamageClassComponent implements OnInit, OnDestroy, AfterViewInit {
	// =========================================== Public Variables ===========================================================
	// public damageClasses$: Observable<SelectListOption[]>;TODO:fix
	public selectedDamageClassId = -1;

	// =========================================== Private Variables ===========================================================

	// -----------------------------------------  INPUT / OUTPUT events -----------------------------------------
	@Input() title = 'Damage Details';
	@Input() damageClasses$: Observable<SelectListOption[]>; //TODO:remove

	// ----------------------------------------- Constructor -----------------------------------------
	constructor(public _store: Store<any>, public bf: BigFormService, private fb: UntypedFormBuilder) {}

	// ----------------------------------------- Life-cycle methods -----------------------------------------
	ngOnInit() {
		const control = this.bf.bigForm.get('selectedDamageClass');
		if (control?.value?.length > 0) {
			this.selectedDamageClassId = Number(control.value[0]);
		} else {
			this.bf.bigForm.addControl('selectedDamageClass', new UntypedFormControl([], [Validators.required]));
		}
	}

	ngAfterViewInit(): void {}

	ngOnDestroy() {}

	// ============================================= Private Methods ============================================================
}
