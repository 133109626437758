import {  AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, ModalService } from '@flexus/core';
import { CustomValidators } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'pgg-bank-details',
	templateUrl: 'pgg-bank-details-template.component.html',
	styleUrls: ['pgg-bank-details-template.component.scss']
})
export class PggBankDetailsTemplateComponent implements OnInit, AfterViewInit {
	name!: string;
	branchSelected = true;
	bankDetailsForm: UntypedFormGroup = new UntypedFormGroup({});

	bankNamesSub!: Subscription;
	bankNameChangeSub!: any;
	currentBankDetailsSub!: Subscription;
	bankNames!: any;

	// ============================================= Inputs =============================================================
	@Input() accountTypes$!: Observable<any[]>; // an array of account types to be used in the drop down
	@Input() bankNames$!: Observable<any>;
	@Input() bankAccountDetails$!: Observable<any>;
	@Input() bankCodeLookupEvent!: Function;
	@Input() jobID$: Observable<any> = null as any;
	// @Input() currBankDetails$!: Observable<any>;
	bankDetailsExist!: boolean;
	bankDetailsControl;

	@Input() branchLookup$: Observable<any> = null as any;
	bankAccHolderExist: boolean;
	bankAccNumberExist: boolean;
	account_holder_name: any;
	account_number: any;
	initialisationDelay;

	constructor(private _store: Store, private _cd: ChangeDetectorRef, public bf: BigFormService, public _toastr: ToastrService, private _modalsService: ModalService) {}

	intialiseInformation() {
		this.name = 'Account Holder Name';
	}

	private appendBankDetailsToFormControl(form: UntypedFormGroup) {
		form.addControl('account_holder_name', new UntypedFormControl('', []));
		// form.addControl('account_holder_name', new FormControl('', [CustomValidators.alphaNumericWithSpacesDotDash]));
		form.addControl('bank_name', new UntypedFormControl('', [CustomValidators.hardMaxLength(45)]));
		form.addControl('branch_code', new UntypedFormControl('', { validators: [CustomValidators.hardMaxLength(8), CustomValidators.numeric] }));
		form.addControl(
			'account_number',
			new UntypedFormControl('', {
				validators: [CustomValidators.hardMaxLength(30), CustomValidators.numeric, Validators.minLength(6)]
			})
		);
		form.addControl('account_type', new UntypedFormControl('Select account', { validators: [CustomValidators.hardMaxLength(30)] }));
	}

	ngOnInit() {
		this.bankDetailsControl = this.bf.getControl('bank_details');

		this.appendBankDetailsToFormControl(this.bankDetailsForm);
		this.jobID$.pipe(take(1)).subscribe(id => {
			if (id !== undefined) {
				// if appending to a job
				const jobs = this.bf.bigForm.get('jobs') as UntypedFormArray;
				let jobIndex = -1;
				for (let i = 0; i < jobs?.value?.length; i++) {
					if (jobs.value[i]?.id === id) {
						jobIndex = i;
					}
				}
				if (jobIndex !== -1) {
					// bank details to each require job
					const currentJob = jobs.at(jobIndex) as UntypedFormGroup;
					if (!currentJob.get('bank_details')) {
						currentJob.addControl('bank_details', this.bankDetailsForm);
					} else {
						this.bankDetailsForm.patchValue(currentJob.get('bank_details')?.value);
					}
				} else {
					if (!this.bf.bigForm.get('bank_details')) {
						this.bf.bigForm.addControl('bank_details', this.bankDetailsForm);
					} else {
						this.bankDetailsForm.patchValue(this.bf.bigForm.get('bank_details')?.value);
					}
				}
			} else {
				// else add to root level
				if (!this.bf.bigForm.get('bank_details')) {
					this.bf.bigForm.addControl('bank_details', this.bankDetailsForm);
				} else {
					this.bankDetailsForm.patchValue(this.bf.bigForm.get('bank_details')?.value);
				}
			}
		});
		this.getExistingDetailsForFormValues();
		this.intialiseInformation();

		this.bankNamesSub = this.bankNames$
			.pipe(
				skipWhile(f => !f),
				take(1)
			)
			.subscribe(_bankNames => {
				const banknames = _bankNames['payload'];
				this.bankNames = [...banknames];
			});
	}
	getExistingDetailsForFormValues() {
		this.currentBankDetailsSub = this.bankAccountDetails$
			.pipe(
				skipWhile((got: any) => !got),
				take(1)
			)
			.subscribe((_bankDetails: any) => {
				this.account_holder_name = _bankDetails?.b_acc_holder;
				this.account_number = _bankDetails?.b_acc_no;
				if (!this.account_holder_name) {
					this.bankAccHolderExist = false;
				} else {
					this.bankAccHolderExist = true;
				}
				if (!this.account_number) {
					this.bankAccNumberExist = false;
				} else {
					this.bankAccNumberExist = true;
				}
			});
	}
	initialiseForm() {
		if (!this.bankAccHolderExist) {
			this.bankDetailsControl.get('account_holder_name').setValue('');
		} else {
			this.bankDetailsControl.get('account_holder_name').setValue(this.account_holder_name);
		}
		if (!this.bankAccNumberExist) {
			this.bankDetailsControl.get('account_number').setValue('');
		} else {
			this.bankDetailsControl.get('account_number').setValue(this.account_number);
		}
	}
	ngAfterViewInit() {
		clearInterval(this.initialisationDelay);
		if (this.bankCodeLookupEvent && typeof this.bankCodeLookupEvent === 'function') {
			this.bankNameChangeSub = this.bankDetailsForm.get('bank_name')?.valueChanges.subscribe(bankName => {
				if (this.bankNames) {
					const selectedBank = this.bankNames.find((_bankName: any) => _bankName.name.includes(bankName));
					if (selectedBank) {
						this.bankCodeLookupEvent(selectedBank.id, this._store, this.bf);
					}
				}
			});
		}
		// this.initialiseForm();
	}
}
