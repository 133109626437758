import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, take, skipWhile } from 'rxjs/operators';
import { BankBranchData, AVSResultFlag } from './models';
import { CustomValidators, cleanUpSub } from '@flexus/utilities';
import { BigFormService, MakeServerCall } from '@flexus/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { ModalNavButton } from '@flexus/ui-elements';
import { ModalService } from '@flexus/core';

@Component({
	selector: 'flx-bank-details',
	templateUrl: './bank-details.component.html',
	styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
	// ===========================================  Variables ===========================================================
	// Vars for the company toggle
	name: string;
	individual: boolean;
	avsSwitchToggle: boolean;
	identification: string;

	bankDetailsForm: UntypedFormGroup = new UntypedFormGroup({});

	// Branch look up
	branchResponse$: Observable<any>;
	branchSub: Subscription;
	branchSearchSub: Subscription;
	branches: BankBranchData[] = [];
	storeLookupData: BankBranchData;
	branchSelected = true;

	// Avs response
	storeSubAvsResponse: Subscription;
	avsFailedReasons = '';
	avsResponseSub: Subscription;
	prevAvsResult: any;
	// avsResponse: [];

	storeSubBranchResponse: Subscription;
	branches$: Observable<any>;
	firstLookup = false;
	initialLoad = false;

	currentBankDetailsSub: Subscription;

	modalData: {
		type: string;
		heading: string;
		navs: ModalNavButton[];
	} = {
		type: 'warning',
		heading: '',
		navs: []
	};

	// ============================================= Inputs =============================================================
	@Input() contextMenuState = false;
	@Input() manualAvs = true; // Wont look for an avs response, gives you a manual success option
	@Input() avsSuccess: AVSResultFlag = AVSResultFlag.not_tested; // the initial AVS Result, which you can override
	@Input() avsByPassList: string[]; // List of exclusions when testing the response from the server
	@Input() avsIgnoreList: string[]; // List of ignores so the result can still say success
	@Input() accountTypes$: Observable<any[]>; // an array of account types to be used in the drop down
	@Input() file$: Observable<any> = null; // if provided, it will load a file to view while capturing bank details
	@Input() avsCheck$: Observable<any> = null; // avs response from the server call called acsCheck
	@Input() branchFlag = false; // if true, configurations for branch look up are added
	@Input() branchLookupEndpoint: any; // Endpoint for the branch look up

	@Input() branchLookup$: Observable<any> = null; // branch look up response
	@Input() errorFieldMapper: any; // errors expected mapped to the field names -- used to highlight wrong fields

	@Input() nextNode: string = null;

	@Input() jobID$: Observable<any> = null;
	@Input() currBankDetails$: Observable<any> = null;

	@Input() deflectErrors: { match: string; message: string }[] = null; // Handle errors and display abetter response. Match the error, then display the message.

	@Input() modalNavSuccess: any = {
		linkType: 'nextNode',
		nextNode: this.nextNode,
		text: 'Continue',
		color: 'primary'
	}; // The nave buttons for the bottem of the modal
	// ============================================ Constructor =========================================================
	constructor(private _store: Store<any>, private cd: ChangeDetectorRef, public bf: BigFormService, public _toaster: ToastrService, private modalsService: ModalService) {}

	// ============================================= Life cycle Methods ============================================================
	ngOnInit() {
		this.appendBankDetailsToFormControl(this.bankDetailsForm);
		this.jobID$.pipe(take(1)).subscribe(id => {
			if (id !== undefined) {
				// if appending to a job
				const jobs = this.bf.bigForm.get('jobs') as UntypedFormArray;
				let jobIndex = -1;
				for (let i = 0; i < jobs?.value?.length; i++) {
					if (jobs.value[i]?.id === id) {
						jobIndex = i;
					}
				}
				if (jobIndex !== -1) {
					// bank details to each require job
					const currentJob = jobs.at(jobIndex) as UntypedFormGroup;
					if (!currentJob.get('bank_details')) {
						currentJob.addControl('bank_details', this.bankDetailsForm);
					} else {
						this.bankDetailsForm.patchValue(currentJob.get('bank_details')?.value);
						this.initialLoad = true;
					}
				} else {
					if (!this.bf.bigForm.get('bank_details')) {
						this.bf.bigForm.addControl('bank_details', this.bankDetailsForm);
					} else {
						this.bankDetailsForm.patchValue(this.bf.bigForm.get('bank_details')?.value);
						this.initialLoad = true;
					}
				}
			} else {
				// else add to root level
				if (!this.bf.bigForm.get('bank_details')) {
					this.bf.bigForm.addControl('bank_details', this.bankDetailsForm);
				} else {
					this.bankDetailsForm.patchValue(this.bf.bigForm.get('bank_details')?.value);
					this.initialLoad = true;
				}
			}
		});

		this.intialiseInformation();
		if (!this.manualAvs) {
			this.triggerAvsResponse();
		}
	}

	ngAfterViewInit(): void {
		this.currentBankDetailsSub = this.currBankDetails$
			.pipe(
				skipWhile(val => !val),
				take(1)
			)
			.subscribe(vals => {
				if (vals !== null && vals !== undefined) {
					this.bankDetailsForm.patchValue(vals, { emitEvent: false, onlySelf: true });
				}
			});
		this.triggerBranchLookup();
	}

	ngOnDestroy() {
		cleanUpSub(this.branchSub);
		cleanUpSub(this.avsResponseSub);
		cleanUpSub(this.branchSearchSub);
		cleanUpSub(this.storeSubAvsResponse);
		cleanUpSub(this.storeSubBranchResponse);
		cleanUpSub(this.currentBankDetailsSub);
		if (this.bf.bigForm.get('avs_data')) {
			this.bf.bigForm.removeControl('avs_data');
		}
	}

	// ============================================================ Initial Methods ============================================================
	intialiseInformation() {
		this.avsSwitchToggle = false;
		this.individual = true;
		this.name = 'Surname';
		this.identification = 'ID/Passport Number';

		if (this.bankDetailsForm !== undefined && this.bankDetailsForm.value.account_use === 'Company') {
			this.individual = false;
			this.name = 'Company Name';
			this.identification = 'Company Reg';
		}

		if (this.bankDetailsForm !== undefined && this.bankDetailsForm.value.avs_check_switch === 'manual') {
			this.avsSwitchToggle = true;
		}
	}

	triggerAvsResponse() {
		this.avsResponseSub = this.avsCheck$.subscribe(avsResult => {
			if (avsResult !== undefined && avsResult !== null && this.prevAvsResult !== avsResult) {
				this.prevAvsResult = avsResult;
				if (!this.initialLoad) {
					this.checkAvsSuccess(avsResult);
					this.bankDetailsForm?.updateValueAndValidity();
				} else {
					this.initialLoad = false;
				}
			}
		});
	}

	private appendBankDetailsToFormControl(form: UntypedFormGroup) {
		// These controls will always be added to the form as you will always need access to the values of the form. You take what you need in the manifest file
		form.addControl('account_holder_name', new UntypedFormControl('', [CustomValidators.alphaNumericWithSpacesDotDash, CustomValidators.hardMaxLength(30)]));
		form.addControl('bank_name', new UntypedFormControl('', { validators: [CustomValidators.hardMaxLength(45), Validators.minLength(3)] }));
		form.addControl(
			'account_number',
			new UntypedFormControl('', {
				validators: [CustomValidators.hardMaxLength(30), CustomValidators.numeric, Validators.minLength(6)]
			})
		);
		form.addControl(
			'identification_number',
			new UntypedFormControl('', {
				validators: [CustomValidators.hardMaxLength(15), CustomValidators.numeric, Validators.minLength(6)]
			})
		);
		form.addControl('initials', new UntypedFormControl('', { validators: [CustomValidators.hardMaxLength(4), CustomValidators.alphaNumeric] }));
		form.addControl('branch_code', new UntypedFormControl('', { validators: [CustomValidators.hardMaxLength(8), CustomValidators.numeric] }));
		form.addControl('branch', new UntypedFormControl('', { validators: [CustomValidators.hardMaxLength(30), Validators.minLength(3)] }));
		form.addControl('account_type', new UntypedFormControl('ChequeAccount', { validators: [CustomValidators.hardMaxLength(30)] }));
		form.addControl('account_use', new UntypedFormControl('Individual'));

		form.addControl('avsSuccess', new UntypedFormControl(AVSResultFlag.do_later));
		form.addControl('avsFailedReasons', new UntypedFormControl(''));

		form.addControl('avs_check_switch', new UntypedFormControl('auto', { validators: [] }));
		form.addControl('acc_type', new UntypedFormControl(null, []));
		form.addControl('acc_type_desc', new UntypedFormControl(null, []));
	}

	/* ===================================================== Branch lookup Methods ===================================================== */
	triggerBranchLookup() {
		if (this.branchLookupEndpoint) {
			this.branchSub = this.branchLookup$.subscribe(branches => {
				if (Array.isArray(branches)) {
					this.branches = branches;
				} else {
					this.branches = [{ branch_name: 'No branches matching that description.', branch_code: '', bank_name: '' }];
				}
			});

			// Trigger the search only when bank and branch have been changed from previous value & are valid
			this.storeLookupData = {
				branch_name: this.bankDetailsForm.get('branch')?.value,
				bank_name: this.bankDetailsForm.get('bank_name')?.value
			};
			this.branchSearchSub = this.bankDetailsForm?.valueChanges?.pipe(debounceTime(1200)).subscribe(() => {
				if (this.bankDetailsForm.get('bank_name')?.value !== undefined && this.bankDetailsForm.get('branch')?.value !== undefined) {
					if (this.bankDetailsForm.get('bank_name')?.value?.length >= 3 && this.bankDetailsForm.get('branch')?.value?.length >= 3) {
						if (
							this.storeLookupData.bank_name !== this.bankDetailsForm.get('bank_name')?.value?.toLowerCase() ||
							this.storeLookupData.branch_name !== this.bankDetailsForm.get('branch')?.value?.toLowerCase()
						) {
							this.storeLookupData = {
								branch_name: this.bankDetailsForm.get('branch')?.value?.toLowerCase(),
								bank_name: this.bankDetailsForm.get('bank_name')?.value?.toLowerCase()
							};
							this._store.dispatch(
								new MakeServerCall({
									...this.branchLookupEndpoint,
									data: {
										bank: this.storeLookupData.bank_name === 'fnb' ? 'first' : this.storeLookupData.bank_name,
										branch: this.storeLookupData.branch_name
									}
								})
							);
							this.bankDetailsForm.get('branch_code')?.patchValue('');
							this.branchSelected = false;
							this.firstLookup = true;
						}
					}
				}
			});
		}
	}

	toggleBankAccountType() {
		this.individual = !this.individual;
		if (this.individual) {
			this.name = 'Surname';
			this.identification = 'ID/Passport Number';
		} else {
			this.name = 'Company Name';
			this.identification = 'Company Reg';
		}
	}

	avsSwitch() {
		if (this.bankDetailsForm?.value?.avs_check_switch === 'manual') {
			this.avsSwitchToggle = true;
		} else if (this.bankDetailsForm?.value?.avs_check_switch === 'auto') {
			this.avsSwitchToggle = false;
		}
	}

	setBranchInfo(branch: BankBranchData) {
		this.branchSelected = true;
		if (branch.bank_name && branch.branch_code && branch.branch_name) {
			this.storeLookupData = branch;
			this.bankDetailsForm.patchValue(
				{
					bank_name: branch.bank_name,
					branch: branch.branch_name,
					branch_code: branch.branch_code
				},
				{ emitEvent: false }
			);
			this.storeLookupData = {
				branch_name: branch.branch_name?.toLowerCase(),
				bank_name: branch.bank_name?.toLowerCase()
			};
		}
		this.cd.detectChanges();
	}

	/* ===================================================== AVS Methods ===================================================== */
	//To see if any failed reasons are in a bypass list
	checkAvsSuccess(response: any) {
		if (response['success'] === true) {
			this.avsSuccess = AVSResultFlag.success;
			this.showResultMessage(['Proceed to next step.'], AVSResultFlag.success);
			this.saveAvsResults(this.avsSuccess, response);
		} else {
			let reasons = response['reason'];
			let searchFlag = true;
			const failReasons = [];
			// Build array of fail reasons
			while (searchFlag) {
				const pos = reasons.indexOf('\n');
				if (pos !== -1) {
					failReasons.push(reasons.substring(0, pos));
					reasons = reasons.substring(pos + 1, reasons.length);
				} else {
					if (reasons.length > 5) {
						failReasons.push(reasons);
					}
					searchFlag = false;
				}
			}

			// Check if it is a bypass reason
			const failDisplayRes: any[] = [];
			let success = true;
			let forcePayment = false;
			failReasons.forEach(fRes => {
				if (!this.avsIgnoreList.includes(fRes)) {
					forcePayment = true;
					if (!this.avsByPassList.includes(fRes)) {
						success = false;
						failDisplayRes.push(this.handleError(fRes));
					}
				}
			});

			this.avsSuccess = success ? AVSResultFlag.fail_force_payment : AVSResultFlag.fail;
			this.saveAvsResults(this.avsSuccess, response);
			this.showResultMessage(failDisplayRes, this.avsSuccess, !forcePayment);
		}
	}

	saveAvsResults(res: AVSResultFlag, response: any) {
		this.bankDetailsForm.patchValue({ avsSuccess: res });
		if ((res = AVSResultFlag.fail_force_payment)) {
			this.bankDetailsForm.patchValue({ avsFailedReasons: response['reason'] });
		}
	}

	handleError(message: string): string {
		const res = this.deflectErrors.find(element => message?.toLowerCase().match(element.match));
		console.log({ HandleRESS: res });
		return res !== undefined ? res?.message : message;
	}

	/* ===================================================== Prompt Methods ===================================================== */
	showResultMessage(message: string[], res: AVSResultFlag, falsePositive: boolean = false) {
		// Display a warning prompt
		if (falsePositive) {
			this.modalData.heading = 'AVS passed.';
			this.modalData.type = 'success';
			message = [''];
			this.modalData.navs = [this.modalNavSuccess];
		} else {
			switch (res) {
				case AVSResultFlag.fail:
					this.modalData.heading = 'AVS check failed.';
					this.modalData.type = 'warning';
					this.modalData.navs = [{ linkType: 'close', text: 'Fix Details', color: 'alert' }];
					break;
				case AVSResultFlag.fail_force_payment:
					this.modalData.heading = 'AVS check failed. Forced payment can still be made.';
					this.modalData.type = 'warning';
					this.modalData.navs = [{ linkType: 'close', text: 'Fix Details', color: 'alert' }, this.modalNavSuccess];
					break;
				case AVSResultFlag.success:
					this.modalData.heading = 'AVS passed.';
					this.modalData.type = 'success';
					message = [''];
					this.modalData.navs = [this.modalNavSuccess];
					break;
			}
		}
		this.modalsService.openModalDirectly(inst => {
			inst.setHeading(this.modalData.heading);
			inst.setMessage(message);
			inst.type = this.modalData.type;
			inst.navButtons = this.modalData.navs;
		});
		// this.highlightIncorrectFields(message);
	}

	// TODO highlight incorrect fields
	highlightIncorrectFields(failed: any[]) {
		for (const key in this.errorFieldMapper) {
			if (key) {
				if (Array.isArray(this.errorFieldMapper[key])) {
					this.errorFieldMapper[key]?.forEach(err => {
						if (failed.includes(err)) {
							this.bankDetailsForm.get(key)?.setErrors({ incorrect: true });
							this.bankDetailsForm.get(key)?.updateValueAndValidity();
						}
					});
				} else {
					if (failed.includes(this.errorFieldMapper[key])) {
						this.bankDetailsForm.get(key)?.setErrors({ incorrect: true });
						this.bankDetailsForm.get(key)?.updateValueAndValidity();
					}
				}
			}
		}
	}
}
