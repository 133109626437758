import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { GoogleMapsModule } from '@angular/google-maps';
import { UIElementsModule } from '@flexus/ui-elements';
import { PipesModule } from '@flexus/pipes';
import { FLXSpPingWindowComponent } from './sp-ping-window.component';
import { SpAvailableJobDetailComponent } from './sp-available-job-detail';
import { FLXSpAvailableJobDetailComponent } from './sp-available-job-ping';

@NgModule({
	declarations: [FLXSpPingWindowComponent, SpAvailableJobDetailComponent, FLXSpAvailableJobDetailComponent],
	imports: [CommonModule, GoogleMapsModule, NgxPaginationModule, UIElementsModule, PipesModule],
	exports: [FLXSpPingWindowComponent]
})
export class FLXSpPingWindowModule {}
