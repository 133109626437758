import { Action } from '@ngrx/store';

export const STOP_LOADING = '[Global] Stop loading';
export const STOP_LOADER = '[Global] Stop Loader';

export class StopLoader implements Action {
	readonly type = STOP_LOADER;
}

export class StopLoading implements Action {
	readonly type = STOP_LOADING;
	constructor(public payload: { loaderID?: string }) {}
}

export type globalActions = StopLoading;
