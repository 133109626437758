import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, forkJoin, Observable, EMPTY } from 'rxjs';
import { catchError, switchMap, mergeMap, map, tap, take, concatMap, skipWhile } from 'rxjs/operators';
import * as contextActions from './selected-context.actions';
import { path } from 'ramda';
import { MakeServerCallFail, GetFullItemTwo, GetFullItemOne, MakeServerCallSuccess } from './selected-context.actions';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { getSelectedItemOne, getSelectedItemTwo, getFullItemTwo } from './selected-context.selector';

import { UUID } from 'angular2-uuid';
import { CLIENTSERVICE } from '../services/engine.constants';
import { PopupService } from '../services/popup.service';
import { BigFormService } from '../services/big-form.service';
import { ModalService } from '../services/modal.service';
import { IndexedDbService } from '../services/storage-services';
import { StoreQuery } from '../services/store-query.service';
import { getOrgKey } from '../manifest/manifest.selectors';
import { SetActiveManifestItem, SetNextNode } from '../manifest';
import { ManifestController } from '../controllers/manifest.controller';
import { ServerCall_0_0_2 } from '../manifest-versions';

@Injectable()
export class SelectedContextEffects {
	constructor(
		private actions$: Actions,
		@Inject(CLIENTSERVICE) private service: any,
		private http: HttpClient,
		private store: Store<any>,
		private controller: ManifestController<any>,
		private sq: StoreQuery,
		private bf: BigFormService,
		private modal: ModalService,
		private popup: PopupService,
		private iDBService: IndexedDbService
	) {}

	// @Effect({ dispatch: false })
	// getFullItemOneAfterManifest$ = this.actions$.pipe(
	//   ofType(manifestActions.SET_ACTIVE_MANIFEST_ITEM),
	//   switchMap((action: manifestActions.SetActiveManifestItem) => {
	//     const { orgKey, pathToFlows, itemId, itemOne, itemTwo } = action.payload;

	//     return this.controller.stateObservable.pipe(
	//       map((store) => store.mainManifest),
	//       map((manifest) => manifest['organizations'][orgKey]),
	//       map((activeOrg) => path(pathToFlows, activeOrg)[itemId]),
	//       take(1),
	//       map((activeManifestItem) => {
	//         if (!activeManifestItem.dontLoadNodes) {
	//           if (itemOne || itemTwo) {
	//             itemTwo
	//               ? this.setAllItemTwoInformation(activeManifestItem, itemOne, itemTwo)
	//               : this.setAllItemOneInformation(activeManifestItem, itemOne);
	//           }
	//         }
	//       }),
	//     );
	//   }),
	// );

	getFullOne$ = createEffect(() =>
		this.actions$.pipe(
			ofType(contextActions.GET_FULL_ITEM_ONE),
			switchMap((action: contextActions.GetFullItemOne) => {
				const { id } = action.payload;
				return (this.service.getFullItemOne(id) as Observable<any>).pipe(
					switchMap((res: any) => {
						const itemOne = res && res?.success === true ? res?.payload : null;
						this.bf.patchValues({ itemOne });
						return res && res?.success === true
							? of(new contextActions.GetFullItemOneSuccess({ fullItemOne: res?.payload }))
							: of(
									new MakeServerCallFail({
										dataKey: `getFullItemOne`,
										error: res,
										errorMessage: 'Could not get selected claim',
										retryCall: {
											functionName: 'getFullItemOne',
											errorMessage: 'Could not get selected claim'
										}
									})
							  );
					}),
					catchError(err =>
						of(
							new MakeServerCallFail({
								dataKey: `getFullItemOne`,
								error: err,
								errorMessage: 'Could not get selected claim',
								retryCall: { functionName: 'getFullItemOne', errorMessage: 'Could not get selected claim' }
							})
						)
					)
				);
			})
		)
	);

	addClaimToWorkTimeline$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(contextActions.SELECT_ITEM_ONE),
				concatMap((action: contextActions.SelectItemOne) => {
					this.store
						.select(getSelectedItemOne)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.subscribe(x => {
							this.iDBService.user
								.toArray()
								.then(user => {
									return +user;
								})
								.then(userID => {
									return {
										id: UUID.UUID(),
										userID: userID,
										claim_num: x?.mid || 'N/A',
										applicant: x?.applicant?.surname || 'N/A',
										access_date: new Date()
									};
								})
								.then(stamp => {
									this.iDBService.recent_activity
										.where('userID')
										.equals(stamp.userID)
										.sortBy('access_date')
										.then(data => {
											if (data?.length >= 20) {
												const oldestEntry = data[0];
												this.iDBService.recent_activity.delete(oldestEntry.id);
											}
											this.iDBService.recent_activity.put(stamp);
										});
								});
						});
					return EMPTY;
				})
			),
		{ dispatch: false }
	);

	addJobToWorkTimeline$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(contextActions.SELECT_ITEM_TWO),
				concatMap((action: contextActions.SelectItemTwo) => {
					this.store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.subscribe(x => {
							this.iDBService.user
								.toArray()
								.then(user => {
									return +user;
								})
								.then(userID => {
									return {
										id: UUID.UUID(),
										userID: userID,
										claim_num: x?.claim?.mid || 'N/A',
										applicant: x?.claim?.applicant?.surname || 'N/A',
										access_date: new Date()
									};
								})
								.then(stamp => {
									this.iDBService.recent_activity
										.where('userID')
										.equals(stamp.userID)
										.sortBy('access_date')
										.then(data => {
											if (data?.length >= 20) {
												const oldestEntry = data[0];
												this.iDBService.recent_activity.delete(oldestEntry.id);
											}
											this.iDBService.recent_activity.put(stamp);
										});
								});
						});
					return EMPTY;
				})
			),
		{ dispatch: false }
	);

	getFullOneWithFullTwos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(contextActions.GET_FULL_ITEM_ONE_WITH_FULL_ITEM_TWOS),
			switchMap((action: contextActions.GetFullItemOneWithFullItemTwos) => {
				const { oneId, twoId } = action.payload;
				return (this.service.getFullItemOneWithItemTwo(oneId) as Observable<any>).pipe(
					switchMap((res: any) => {
						const itemOne = res && res?.success === true ? res?.payload : null;
						this.bf.patchValues({ itemOne });
						let jobs = [];

						if (twoId) {
							jobs = res?.payload.jobs.filter(job => job?.id !== twoId);
						} else {
							jobs = res?.payload.jobs;
						}

						this.store.dispatch(
							new contextActions.SetRelatedItems({
								relatedItemTwos: jobs
							})
						);

						return res && res?.success === true
							? of(new contextActions.GetFullItemOneWithFullItemTwosSuccess({ fullItemOne: res?.payload }))
							: of(
									new MakeServerCallFail({
										dataKey: `getFullItemOne`,
										error: res,
										errorMessage: 'Could not get selected claim',
										retryCall: {
											functionName: 'getFullItemOne',
											errorMessage: 'Could not get selected claim'
										}
									})
							  );
					}),
					catchError(err =>
						of(
							new MakeServerCallFail({
								dataKey: `getFullItemOne`,
								error: err,
								errorMessage: 'Could not get selected claim',
								retryCall: { functionName: 'getFullItemOne', errorMessage: 'Could not get selected claim' }
							})
						)
					)
				);
			})
		)
	);

	getFullItemTwo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(contextActions.GET_FULL_ITEM_TWO),
			switchMap((action: contextActions.GetFullItemTwo) => {
				const { id: job_id } = action.payload;
				return (this.service.getFullItemTwo(job_id) as Observable<any>).pipe(
					switchMap(res => {
						const itemTwo = res && res?.success === true ? res?.payload : null;
						this.bf.patchValues({ itemTwo });
						return (res && res?.success === true) === true
							? of(
									new contextActions.GetFullItemTwoSuccess({
										fullItemTwo: res?.payload
									})
							  )
							: of(
									new MakeServerCallFail({
										dataKey: `getFullItemTwo`,
										error: res,
										errorMessage: 'Could not get selected job',
										retryCall: {
											functionName: 'getFullItemTwo',
											errorMessage: 'Could not get selected job'
										}
									})
							  );
					}),
					catchError(error =>
						of(
							new MakeServerCallFail({
								dataKey: `getFullItemTwo`,
								error,
								errorMessage: 'Could not get selected job',
								retryCall: { functionName: 'getFullItemTwo', errorMessage: 'Could not get selected job' }
							})
						)
					)
				);
			})
		)
	);

	makeServerCall = createEffect(() =>
		this.actions$.pipe(
			ofType(contextActions.MAKE_SERVER_CALL),
			map((action: contextActions.MakeServerCall) => {
				const { isBackgroundTask } = action.payload;
				if (isBackgroundTask) {
					return new contextActions.MakeServerCallWithoutLoader(action.payload);
				} else {
					return new contextActions.MakeServerCallWithLoader(action.payload);
				}
			})
		)
	);

	makeServerCallWithOrWithoutLoader = createEffect(() =>
		this.actions$.pipe(
			ofType(contextActions.MAKE_SERVER_CALL_WITH_LOADER, contextActions.MAKE_SERVER_CALL_WITHOUT_LOADER),
			mergeMap((action: { payload: ServerCall_0_0_2 }) => {
				const serverCallsTracker = [];
				const {
					directCall,
					dataKey,
					functionName,
					responseSlice,
					followUpFailCalls,
					followUpSuccessCalls,
					errorMessage,
					data,
					ignoreFalseError,
					nextNode,
					timeoutMilliseconds = 6000000000000,
					loaderID
				} = action.payload;

				if (directCall && typeof directCall === 'function') {
					return directCall(this.http, this.store, this.sq, this.bf, this.controller, this.modal, this.popup).pipe(
						map((res: any) => {
							if (!ignoreFalseError) {
								if (res && res?.success === false) {
									return new MakeServerCallFail({
										followUpFailCalls,
										dataKey,
										error: {
											reason: res?.payload.reason['@RESULTS'] ? res?.payload.reason['@RESULTS'] : res?.payload.reason
										},
										errorMessage,
										retryCall: action.payload,
										loaderID: loaderID
									});
								}
							}

							const result = responseSlice ? path(responseSlice?.split('.'), res) : res;
							return new MakeServerCallSuccess({ nextNode, dataKey, result, loaderID });
						}),
						tap(() => {
							// MOVE NEXT IF SERVER FIRST IS SET AND THER IS NO FURTHER FOLLOWUP CALLS TO MAKE
							if (!followUpSuccessCalls && nextNode) {
								this.controller.dispatch(new SetNextNode(nextNode));
							}
							if (followUpSuccessCalls && Object.keys(followUpSuccessCalls)?.length > 0) {
								Object.entries(followUpSuccessCalls)?.forEach(([dataKey, value]: [string, any]) => {
									if (!serverCallsTracker.includes(dataKey)) {
										this.store.dispatch(new contextActions.MakeServerCall({ dataKey, ...value, nextNode }));
										serverCallsTracker.push(dataKey);
									}
								});
							}
						}),
						catchError(error =>
							of(
								new MakeServerCallFail({
									followUpFailCalls,
									dataKey,
									error,
									errorMessage,
									retryCall: action.payload,
									loaderID: loaderID
								})
							)
						)
					);
				} else {
					return (this.service[functionName](data) as Observable<any>).pipe(
						map((res: any) => {
							if (!ignoreFalseError) {
								if (res && res?.success === false) {
									return new MakeServerCallFail({
										followUpFailCalls,
										dataKey,
										error: { reason: res?.payload.reason },
										errorMessage,
										retryCall: action.payload,
										loaderID: loaderID
									});
								}
							}
							const result = responseSlice ? path(responseSlice?.split('.'), res) : res;
							return new MakeServerCallSuccess({ nextNode, dataKey, result, loaderID });
						}),
						tap(() => {
							// MOVE NEXT IF SERVER FIRST IS SET AND THERE IS NO FURTHER FOLLOWUP CALLS TO MAKE
							if (!followUpSuccessCalls && nextNode) {
								this.controller.dispatch(new SetNextNode(nextNode));
							}
							if (followUpSuccessCalls && Object.keys(followUpSuccessCalls)?.length > 0) {
								Object.entries(followUpSuccessCalls)?.forEach(([dataKey, value]: [string, any]) => {
									if (!serverCallsTracker.includes(dataKey)) {
										this.store.dispatch(new contextActions.MakeServerCall({ dataKey, ...value, nextNode }));
										serverCallsTracker.push(dataKey);
									}
								});
							}
						}),
						catchError(error =>
							of(
								new MakeServerCallFail({
									followUpFailCalls,
									dataKey,
									error,
									errorMessage,
									retryCall: action.payload,
									loaderID: loaderID
								})
							)
						)
					);
				}
			})
		)
	);

	// @Effect()
	// runFollowUpSuccessCalls = this.actions$.pipe(
	//   ofType(contextActions.MAKE_SERVER_CALL_SUCCESS),
	//   concatMap((action: contextActions.MakeServerCallSuccess) => {
	//     const calls = action.payload.followUpSuccessCalls || {};
	//     const nextNode = action.payload.nextNode;
	//     return Object.entries(calls)?.map(([dataKey, value]) => {
	//       if (Object.keys(calls)?.length > 0) {
	//         return of(new contextActions.MakeServerCall({ dataKey, ...value, nextNode }));
	//       } else {
	//         return EMPTY;
	//       }
	//     });
	//   }),
	//   concatMap(res => res)
	// );

	runFollowUpFailCalls = createEffect(() =>
		this.actions$.pipe(
			ofType(contextActions.MAKE_SERVER_CALL_FAIL),
			concatMap((action: contextActions.MakeServerCallFail) => {
				const calls = action.payload.followUpFailCalls || {};
				const nextNode = null;
				return Object.entries(calls)?.map(([dataKey, value]) => {
					if (Object.keys(calls)?.length > 0) {
						return of(new contextActions.MakeServerCall({ dataKey, ...value, nextNode }));
					} else {
						return EMPTY;
					}
				});
			}),
			concatMap(res => res)
		)
	);

	ifDifferentStateThenLoadNewManifestItem = createEffect(
		() =>
			this.actions$.pipe(
				ofType(contextActions.MAKE_SERVER_CALL_SUCCESS),
				switchMap((action: contextActions.MakeServerCallSuccess) => {
					const { result, nextNode } = action.payload;

					if (nextNode) {
						return EMPTY;
					} else {
						return forkJoin([
							this.store.select(getSelectedItemOne).pipe(
								skipWhile(x => !x),
								take(1)
							),
							this.store.select(getSelectedItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							)
						]).pipe(
							tap(([itemOne, itemTwo]) => {
								if (result && result.payload && result.payload.id === itemTwo?.id && result.payload.state !== itemTwo?.state) {
									this.store.dispatch(new GetFullItemTwo({ id: itemTwo?.id }));
									this.store.dispatch(new GetFullItemOne({ id: itemOne?.id }));

									// this.store.dispatch(new SelectItemTwo({ itemOne: itemOne, itemTwo: itemTwo }));
									this.controller
										.select(getOrgKey)
										.pipe(take(1))
										.subscribe(key => {
											this.controller.dispatch(
												new SetActiveManifestItem({
													pathToFlows: ['manifestItems'],
													orgKey: key,
													itemId: result.payload.state
												})
											);
										});
								} else if (result && result.payload && result.payload.id === itemOne?.id && result.payload.state !== itemOne?.state) {
									// this.store.dispatch(
									//   new SelectItemOne({
									//     itemOne: itemOne,
									//   }),
									// );
									this.controller
										.select(getOrgKey)
										.pipe(take(1))
										.subscribe(key => {
											this.controller.dispatch(
												new SetActiveManifestItem({
													pathToFlows: ['manifestItems'],
													orgKey: key,
													itemId: result.payload.state
												})
											);
										});
								}
							})
						);
					}
				})
			),
		{ dispatch: false }
	);
}
