import { Flow_0_0_2, getAllInfo, getFullItemTwo } from '@flexus/core';
import { setActionPanelItems, CollapseActionPanel } from 'apps/studio/src/app/app-shell-features';
import { forkJoin } from 'rxjs';
import { skipWhile, map, take, filter } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { B } from '@angular/cdk/keycodes';
import { UntypedFormControl, Validators } from '@angular/forms';
import { JOB_INFO_NODE } from '../reusable/JOB_INFO_NODE';

export const MUL_256: Flow_0_0_2 = {
	id: '256',
	name: 'no_stock-phone_customer',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any, bf: any) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo?.claim) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `No Stock - Phone Customer : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'No Stock - Phone Customer';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'mulSpService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		files: {
			serviceVariable: 'mulSpService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'NoStockNotification',
	nodes: {
		NoStockNotification: {
			initFormFields: (bf: any) => {
				bf.addControl('new_state', new UntypedFormControl('279', [Validators.required]));
			},
			serverCalls: {
				installationKeyValues: {
					errorMessage: 'Could not return installation info',
					directCall: (http: any, store: any) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile((itt: any) => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile((ai: any) => !ai),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile((x: any) => !x),
								take(1),
								map((res: any) => res['payload'])
							)
						]).pipe(
							map(([itemTwo, info, teamleaders]: any) => {
								const sp = itemTwo?.sp;
								const sps = info.sps;
								let companyname, teamleadname;
								for (let i = 0; i < sps.length; i++) {
									if (sp === sps[i]?.id) {
										companyname = sps[i]?.name;
									}
								}
								const tleads = teamleaders as any;
								const templead = itemTwo?.team_leader;
								if (templead) {
									for (let i = 0; i < tleads.length; i++) {
										if (tleads[i]?.id === templead?.id) {
											teamleadname = tleads[i]?.full_name;
										}
									}
								} else {
									teamleadname = 'No team leader appointed';
								}
								const accreditation_number = itemTwo?.team_leader?.sp?.accreditation_number ?? '--';
								const qualification_number = itemTwo?.team_leader?.qualification_number ?? '--';
								const installerinfo = {
									'Company name ': companyname,
									'Accreditation number ': accreditation_number,
									'Installation technician qualification number': qualification_number,
									'Installer/Technician name ': teamleadname
								};
								return [installerinfo];
							})
						);
					}
				},
				contactKeyValues: {
					errorMessage: "Couldn't return customer contact details",
					directCall: (http: any, store: any, sq: any) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								filter((x: any) => !!x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map((job: any) => {
									const claim_type: number = job?.claim?.claim_type;
									const insurance_claimtypes: any[] = [20, 10, 8, 7, 32, 34, 35];
									let emailaddress: string;
									let contactnumber: string;
									const { claim, identities } = job;
									const contactperson = claim?.applicant;
									const loan_information = claim?.loan_information;
									const installationaddress: string = job?.address ?? 'No address returned';
									if (insurance_claimtypes.includes(claim_type)) {
										emailaddress = loan_information?.email;
										contactnumber = loan_information?.contactnumber ?? 'No contact number returned';
									}
									if (claim_type == 37) {
										emailaddress = loan_information?.onsitecontact_e ?? 'No email address returned';
										contactnumber = loan_information?.onsitecontact_c ?? 'No contact number returned';
									}

									const gpscoordinates: string = claim?.location ?? 'No location returned';
									const firstname: string = contactperson?.first_name;
									const surname: string = contactperson?.surname;
									const fullname = `${firstname} ${surname}` ?? 'no name returned';
									const contactdetails = {
										'Full Name': `${fullname}`,
										'Installation Adress': `${installationaddress}`,
										'Email address': `${emailaddress}`,
										'Contact Number': `${contactnumber}`,
										'GPS Coordinates': `${gpscoordinates}`
									};

									return [contactdetails];
								})
							);
					}
				},
				decoderTypeKeyValues: {
					errorMessage: 'Could not determine decoder type',
					directCall: (http: any, store: any) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile((fit: any) => !fit),
								take(1),
								map((result: any) => result)
							)
							.pipe(
								map((job: any) => {
									const decoder_type = job?.claim?.loan_information?.device_details?.customer_device?.cust_device_type;
									const decodertypeObj = { 'Decoder Type': `${decoder_type}` };
									return [decodertypeObj];
								})
							);
					}
				}
			},
			// ...JOB_INFO_NODE,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							instructions: [
								'The Team Leader/Collector has indicated that they are',
								'unable to purchase decoder stock.',
								'Please contact the customer to let them know the appointment will be missed.'
							],
							title: 'No Stock - Phone Customer',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Details',
							data$: 'installationKeyValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Contact Details',
							data$: 'contactKeyValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Decoder Type',
							data$: 'decoderTypeKeyValues',
							itemMargin: '0 0 15px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'phoned customer',
					color: 'primary',

					serverCalls: {
						response: {
							errorMessage: 'Could not update job',
							serviceVariable: 'mulSpService',
							functionName: 'updateJob'
						}
					},
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
