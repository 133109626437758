<div [class]="'layout-' + use">
	<div [class]="layout">
		<div #customIcon>
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 26 26"
				style="enable-background: new 0 0 24 24"
				xml:space="preserve"
			>
				<title>{{ instruction }}</title>
				<g #svgPaths></g>
			</svg>
		</div>
	</div>
</div>
