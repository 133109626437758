<flx-flex-container alignItems="center" maxWidth="50vw">
	<flx-heading size="medium" align="center" type="creation" weight="extra-light">On-Site Person</flx-heading>

	<flx-panel class="onsite-container" density="cozy" alignItems="center" width="50vw">
		<section [formGroup]="bf.bigForm" class="on-site-person-container">
			<div class="on-site-person">
				<div class="auto-detail-button">
					<flx-input-text formControlName="on_site_person" placeholder="Onsite Person" margin="0 0 14px 0" radius="4px 0 0 4px"></flx-input-text>
					<flx-icon [type]="'auto-add-details'" use="button" color="button" hover="container" layout="right" (click)="personOnSite()"></flx-icon>
				</div>
				<flx-reactive-form-error-display margin="-14px 0 0 0" [control]="$any(bf)?.bigForm.controls['on_site_person']"> </flx-reactive-form-error-display>
				<flx-input-text formControlName="on_site_contact" type="tel" [onlyValidPhoneChars]="true" placeholder="Contact number"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bf)?.bigForm.controls['on_site_contact']"> </flx-reactive-form-error-display>
			</div>
			<div class="on-site-notes"><flx-textarea [rows]="3" formControlName="on_site_notes" placeholder="Notes"></flx-textarea></div>
		</section>
	</flx-panel>
	<flx-panel [formGroup]="bf.bigForm" density="cozy" alignItems="center" width="50vw">
		<flx-heading size="medium" align="center" type="creation" weight="extra-light" margin="0 0 1rem">Appointment Time </flx-heading>

		<!-- Current appointments -->
		<ng-container *ngIf="showCurrentJobs && bf.bigForm.get('currentJobs')">
			<div class="appointments" formArrayName="currentJobs">
				<div *ngFor="let currentJob of currentJobControls?.controls; let i = index" [formGroupName]="i">
					<flx-set-appointment
						[appointmentTypes]="appointmentTypes$ | async"
						[appointmentNeeded]="currentJob.get('providertype_id')?.value !== '2'"
						[hasMinDate]="false"
						[isDisabled]="true"
						[(appointmentData)]="currentJobControls?.controls[i]"
						[datePickerFilter]="datePickerFilterCopy"
						[publicHolidays]="publicHolidays"
					>
					</flx-set-appointment>
					<br />
				</div>
				<flx-glow-line *ngIf="(bf.bigForm?.value?.currentJobs | fsIsArray) && bf.bigForm.value?.currentJobs.length !== 1" margin="0.5rem 0"></flx-glow-line>
			</div>
		</ng-container>

		<ng-container *ngIf="bf.bigForm.get('jobs'); else noJobs">
			<ng-container *ngIf="(bf.bigForm?.value?.jobs | fsIsArray) && bf.bigForm?.value?.jobs.length > 0; else noJobs">
				<div class="appointments" formArrayName="jobs">
					<div *ngFor="let item of getAppointmentsArray()?.controls; let i = index" [formGroupName]="i">
						<flx-set-appointment
							[minHour]="minHour"
							[maxHour]="maxHour"
							[alternateMaxHour]="alternateMaxHour"
							[alternateMaxHourDays]="alternateMaxHourDays"
							[minDate]="minDate"
							[appointmentTypes]="appointmentTypes$ | async"
							[appointmentNeeded]="
								(bf.bigForm.value?.jobs)[i]?.providertype_id !== '2' &&
								(bf.bigForm.value?.jobs)[i]?.providertype_id !== 2 &&
								(bf.bigForm.value?.jobs)[i]?.skill_id !== 44 &&
								!!bf.bigForm.value?.upfrontrepudiation === false
							"
							[(appointmentData)]="$any(getAppointmentsArray())?.controls[i]"
							(appointmentTimeChange)="checkForAfterHours($event, (bf.bigForm.value?.jobs)[i]?.skill_id)"
							[datePickerFilter]="datePickerFilterCopy"
							[publicHolidays]="publicHolidays"
						>
						</flx-set-appointment>
						<flx-glow-line *ngIf="(bf.bigForm.value?.jobs | fsIsArray) && bf.bigForm.value?.jobs.length > 1" margin="0.5rem 0"></flx-glow-line>
					</div>
				</div>
			</ng-container>
		</ng-container>
		<ng-template #noJobs>
			<flx-heading size="extra-small" align="center" weight="extra-light" margin="0 0 1rem"> Please create a Job Card before setting an appointment </flx-heading>
		</ng-template>
	</flx-panel>
</flx-flex-container>
