<flx-flex-container>
	<flx-heading [size]="'large'" [align]="'center'" [weight]="'extra-light'">{{ title }}</flx-heading>
	<flx-heading [size]="'medium'" [align]="'center'" [weight]="'extra-light'">{{ subtitle }}</flx-heading>
	<ng-container *ngIf="closeClaimSummary$ | async as closeClaim">
		<flx-panel maxWidth="500px" alignItems="center" justifyContent="center" margin="auto">
			<div class="close-claim-info">
				<div class="row">
					<span>All payments made on Maven</span> <span>:</span>
					<span [ngClass]="isChecked(closeClaim?.payload.maven_paid) ? 'positive' : 'negative'">{{ isChecked(closeClaim?.payload.maven_paid) ? 'Yes' : 'No' }}</span>
				</div>
				<div class="row">
					<span>Consignment stock invoice on Maven</span> <span>:</span>
					<span [ngClass]="isConsignmentChecked(closeClaim?.payload.has_invoice) ? 'positive' : 'negative'">{{
						isConsignmentChecked(closeClaim?.payload.has_invoice) ? 'Yes' : 'No'
					}}</span>
				</div>
				<div class="row">
					<span>Cost of claim</span> <span>:</span> <span>R{{ closeClaim?.payload.claim_cost }}</span>
				</div>
				<div class="row">
					<span>Final claim outcome</span> <span>:</span> <span>{{ closeClaim?.payload.final_claim_outcome || 'unspecified' }}</span>
				</div>
				<div class="row">
					<span>Final close state on Maven</span> <span>:</span> <span>{{ maven_state_description }}</span>
				</div>
				<div class="row">
					<span>Underwritter</span> <span>:</span> <span>{{ closeClaim?.payload.underwritter | uppercase }}</span>
				</div>
			</div>
		</flx-panel>
		<flx-alert *ngIf="(closeClaim?.success === false || closeClaim?.payload.maven_paid === false) && reasons?.length > 0" message="{{ reasons }}"></flx-alert>
	</ng-container>
</flx-flex-container>
