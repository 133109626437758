<flx-heading [align]="headingAlignment" [weight]="location === 'action-panel' ? 'normal' : 'extra-light'" size="medium">{{ title }}</flx-heading>

<ng-container *ngIf="queryHistory$ | async as conversationData">
	<div *ngIf="conversationData.length > 0; else noHistory" class="conversation-container">
		<div class="scroll-window" style="min-height: 300px" [ngClass]="locationClass">
			<div>
				<div *ngIf="conversationHistory.length > 0">
					<div class="conversation-history" *ngFor="let speech of conversationHistory">
						<div class="conversation-query-card indentLeft"><strong>Query:</strong>&nbsp;{{ speech.query }}</div>

						<div class="conversation-response-card indentRight">
							<strong>Response:</strong>&nbsp;{{ speech.queryreply ? speech.queryreply : 'Service provider has not responded.' }}
						</div>
						<hr style="margin: 2px 0; border-color: #272727; margin: 0 auto; width: 600px" />
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #noHistory>
	<div class="empty-zone"><flx-alert type="no-info" missingItem="query history"></flx-alert></div>
</ng-template>
