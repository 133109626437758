import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, of, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { BetFlowComponent } from '../base-components/bet-flow/bet-flow.component';
import { IndexedDbService, getActiveManifestItem, ModalService, ManifestController, BigFormService } from '@flexus/core';
import { BetWorkflowShellComponent } from '../base-components/bet-workflow-shell/bet-workflow-shell.component';
import { take } from 'rxjs/operators';

@Injectable()
export class DeactivateStateGuard implements OnDestroy, CanDeactivate<BetFlowComponent | BetWorkflowShellComponent> {
	canGo$ = new BehaviorSubject(false);
	canDiscard: boolean;
	manifestItem;
	manifestItemSub: Subscription;
	constructor(
		public indexedDbService: IndexedDbService,
		private controller: ManifestController<any>,
		public store: Store<any>,
		public modalService: ModalService,
		public bf: BigFormService,
		public router: Router
	) {
		this.manifestItemSub = this.controller
			.select(getActiveManifestItem)
			.pipe(take(1))
			.subscribe(item => (this.manifestItem = item));
	}

	canDeactivate(
		component: BetFlowComponent,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState: RouterStateSnapshot
	): Observable<boolean> | boolean | Promise<boolean> {
		if (this.manifestItem && this.manifestItem.deactivateStateGuard) {
			return this.manifestItem.deactivateStateGuard(this, component, this.router, currentRoute, currentState, nextState);
			//  return this.canGo.asObservable();
		} else {
			return of(true);
		}
	}

	resetCanGoVariable() {
		this.canGo$.next(false);
	}

	ngOnDestroy() {
		if (this.manifestItemSub) this.manifestItemSub.unsubscribe();
	}
}
