
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditNoteComponent } from './credit-note.component';
import { AllowOnlyNaturalNumbersDirective } from './directives/natural-numbers.directive';
import { FLXLabelsModule, FLXIconModule, FLXButtonsModule, FLXTextareaModule, FLXLoaderModule, FLXInputTextModule, FLXFormsModule, FLXModalsModule, FLXHeadingsModule, FLXReactiveFormsErrorDisplayModule } from '@flexus/ui-elements';
import { ReactiveFormsModule } from '@angular/forms';

import { BaseInvoiceAndCreditNoteNumbersComponent, CreditNoteLineItemsComponent, CreditNoteSummaryComponent, LineItemGeneratorComponent, LineItemModifyComponent } from './components';

const COMPONENTS = [CreditNoteComponent, CreditNoteSummaryComponent, LineItemGeneratorComponent, LineItemModifyComponent, CreditNoteLineItemsComponent];
@NgModule({
	declarations: [AllowOnlyNaturalNumbersDirective, ...COMPONENTS, BaseInvoiceAndCreditNoteNumbersComponent],
	exports: [...COMPONENTS],
	imports: [
		CommonModule,
		FLXLabelsModule,
		FLXTextareaModule,
		ReactiveFormsModule,
		FLXIconModule,
		FLXButtonsModule,
		FLXLoaderModule,
		FLXModalsModule,
		FLXHeadingsModule,
		FLXReactiveFormsErrorDisplayModule,
		FLXInputTextModule,
		FLXFormsModule
	]
})
export class CreditNoteModule {
  static forRoot(config: {environment: any}): ModuleWithProviders<CreditNoteModule> {
    return {
      ngModule: CreditNoteModule,
      providers: [
        { provide: 'environment', useValue: config.environment },
      ]
    };
  }
}
