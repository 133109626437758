import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormArray, AbstractControl, Validators } from '@angular/forms';
import { BigFormService } from '@flexus/core';

@Component({
	selector: 'flx-claim-payment-details',
	templateUrl: './claim-payment-details.component.html',
	styleUrls: ['./claim-payment-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimPaymentDetailsComponent implements OnInit, OnDestroy {
	// ===========================================  Variables ===========================================================
	@Input() serviceProviderList$!: Observable<any>;
	@Input() paymentMethodList$!: Observable<any>;
	@Input() skillsList$!: Observable<any>;
	@Input() showCurrentJobs = false;
	@Input() showAdditionalExcess = true;
	@Input() showJobExcess = true;
	@Input() mandatoryTitle = 'Mandatory Excess';
	@Input() title = 'Policy Excess';

	bigFormSub!: Subscription;
  env;

	policyExcessFlag = true;

	//
	voluntaryexcess!: any;
	additionalexcess!: any;
	reinsuranceexcess!: any;
	specialclause!: any;

	constructor(public _store: Store<any>, @Inject('environment')  environment: any, public bf: BigFormService) {
    this.env = environment;
  }

	private calculateTotalAdditionalExcess(form: UntypedFormGroup): number {
		const { additionalexcess, reinsuranceexcess, voluntaryexcess, itemOne } = form?.value;
		let amount = 0;
		amount += Number(itemOne?.loan_information?.additionalexcess ?? additionalexcess);
		amount += Number(itemOne?.loan_information?.reinsuranceexcess ?? reinsuranceexcess);
		amount += Number(itemOne?.loan_information?.voluntaryexcess ?? voluntaryexcess);
		if (isNaN(amount) || amount === 0) {
			amount = 0;
			this.policyExcessFlag = false;
		}
		form.get('policy_excess').get('amount')?.patchValue(amount);
		return amount;
	}

	ngOnInit() {
		if (this.showAdditionalExcess) {
			const { voluntaryexcess, additionalexcess, reinsuranceexcess, specialclause } = this.bf.bigForm?.value;
			this.voluntaryexcess = voluntaryexcess ? voluntaryexcess : 'NA';
			this.additionalexcess = additionalexcess ? additionalexcess : 'NA';
			this.reinsuranceexcess = reinsuranceexcess ? reinsuranceexcess : 'NA';
			this.specialclause = specialclause ? specialclause : 'NA';
			this.bigFormSub = this.bf.bigForm?.valueChanges?.subscribe(vals => {
				if (vals.itemOne) {
					const itemOne = vals.itemOne;
					this.voluntaryexcess = `R${itemOne.loan_information?.voluntaryexcess}` || 'NA';
					this.additionalexcess = `R${itemOne.loan_information?.additionalexcess}` || 'NA';
					this.reinsuranceexcess = `R${itemOne.loan_information?.reinsuranceexcess}` || 'NA';
					this.specialclause = `R${itemOne.loan_information?.specialclause}` || 'NA';
				}
			});
			this.calculateTotalAdditionalExcess(this.bf.bigForm);
		}
	}

	getJobCardRequestControls() {
		return (this.bf.bigForm.get('jobs') as UntypedFormArray)?.controls;
	}

	toggleCancellation(control: AbstractControl, controlName?: string) {
		const formControl = controlName ? control.get(controlName) : control;

		const cancelledField = formControl.get('is_cancelled');
		cancelledField.patchValue(!cancelledField?.value);
		if (formControl?.value?.is_cancelled) {
			//If waived remove validations from item
			if (formControl.get('skillrequested')) formControl.get('skillrequested')?.clearValidators();
			if (formControl.get('skillcatagory_id')) formControl.get('skillcatagory_id')?.clearValidators();
			if (formControl.get('skill_id')) formControl.get('skill_id')?.clearValidators();
			if (formControl.get('providertype_id')) formControl.get('providertype_id')?.clearValidators();
			if (formControl.get('amount')) {
				const amountFormControl = formControl.get('amount');
				amountFormControl.reset();
				amountFormControl?.clearValidators();
			}
			if (formControl.get('who_collects')) formControl.get('who_collects')?.clearValidators();
			if (formControl.get('payment_method')) {
				const paymentMethodFormControl = formControl.get('payment_method');
				paymentMethodFormControl.reset();
				paymentMethodFormControl?.clearValidators();
			}
			if (formControl.get('skillcatagory_id')) formControl.get('skillrequested').updateValueAndValidity();
			if (formControl.get('skillcatagory_id')) formControl.get('skillcatagory_id').updateValueAndValidity();
			if (formControl.get('providertype_id')) formControl.get('providertype_id').updateValueAndValidity();
			if (formControl.get('amount')) formControl.get('amount').updateValueAndValidity();
			if (formControl.get('who_collects')) formControl.get('who_collects').updateValueAndValidity();
			if (formControl.get('payment_method')) formControl.get('payment_method').updateValueAndValidity();
		} else {
			if (formControl?.get('skillrequested')) formControl?.get('skillrequested')?.setValidators([Validators.required]);
			if (formControl?.get('skillcatagory_id')) formControl?.get('skillcatagory_id')?.setValidators([Validators.required]);
			if (formControl?.get('skill_id')) formControl?.get('skill_id')?.setValidators([Validators.required]);
			if (formControl?.get('providertype_id')) formControl.get('providertype_id')?.setValidators([Validators.required]);
			if (formControl?.get('amount')) formControl.get('amount')?.setValidators([Validators.required]);
			if (formControl?.get('who_collects')) formControl.get('who_collects')?.setValidators([Validators.required]);
			if (formControl?.get('payment_method')) formControl.get('payment_method')?.setValidators([Validators.required]);

			if (formControl?.get('skillrequested')) formControl.get('skillrequested')?.updateValueAndValidity();
			if (formControl?.get('skillcatagory_id')) formControl.get('skillcatagory_id')?.updateValueAndValidity();
			if (formControl?.get('providertype_id')) formControl.get('providertype_id')?.updateValueAndValidity();
			if (formControl?.get('amount')) formControl.get('amount')?.updateValueAndValidity();
			if (formControl?.get('who_collects')) formControl.get('who_collects')?.updateValueAndValidity();
			if (formControl?.get('payment_method')) formControl?.get('payment_method')?.updateValueAndValidity();
		}
	}

	ngOnDestroy(): void {
		if (this.bigFormSub) this.bigFormSub.unsubscribe();
	}
}
