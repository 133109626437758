import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, empty, of, forkJoin } from 'rxjs';
import { map,timeout } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { IDENTITY_CONFIG, IdentityConfig } from './auth.constants';
import { Store } from '@ngrx/store';
import { MsalService } from '@azure/msal-angular';
import { ENVIRONMENT, JWT_TOKEN_KEY } from './engine.constants';
import { LogOut } from '../identity/identity.actions';
import { User } from '@flexus/models';

@Injectable({ providedIn: 'root' })
export class LocalAuthenticationService {
	// headersConfig = {
	// 	'Content-Type': 'application/json',
	// 	Accept: 'application/json',
	// 	Authorization: `Token ${localStorage.getItem('flexus.web.jwtToken')}`
	// };

	api_url: string;
	private _tokenKey: string;

	auth_urls = [];

	constructor(
		private _http: HttpClient,
		@Inject(JWT_TOKEN_KEY) private tokenKey: string,
		@Inject(ENVIRONMENT) private environment: any,
		private router: Router,
		@Inject(IDENTITY_CONFIG) private identityConfig: IdentityConfig,
		private store: Store<any>,
		@Optional() private msalService: MsalService
	) {
		const api_urls_arr = Object.values(environment.api_urls);
		if (environment.client === 'amp' && api_urls_arr && api_urls_arr.length !== 0) {
			const _tokenKeys = api_urls_arr.filter(apiUrlItem => apiUrlItem['url']?.includes(environment.api_url));
			if (_tokenKeys && _tokenKeys.length === 1) this._tokenKey = _tokenKeys[0]['tokenName'];
		} else {
			this._tokenKey = tokenKey;
		}
	}

	// Verify JWT in localStorage with server & load user's info
	// This runs once on application startup
	getLoggedInUserFromServer(endpoint: string): Observable<any> {
		if (window.navigator.onLine) {
			if (this.userIsAuthenticated) {
				return this._http.get<{ user: any }>(endpoint).pipe(
					map(currentUser => {
						// this.token = currentUser.user.token;
						return currentUser?.user;
					})
				);
			} else {
				// if (this.identityConfig.no_auth_urls.includes(this.router.url)) {
				//   console.log({ currentRouteUrl: this.router.url });
				//   this.router.navigate([this.router.url]);
				//   return empty();
				// } else {
				//   this.router.navigate([this.identityConfig.after_logout_url]);
				//   return empty();
				// }
				// this.toastr.success('Your session has expired! Please login again.', 'Online', { timeOut: 2000 });
				if (!location.href.includes('/azure/callback')) {
					this.store.dispatch(new LogOut());
				}
				return empty();
			}
		} else {
			// this.store.dispatch(new LogOut());
			// return this.offlineService.getLoggedInUserFromServer().pipe(map(currentUser => currentUser.user));
			return empty();
		}
	}

	getLoggedInUserFromServerSilently(endpoint: string): Observable<any> {
		if (window.navigator.onLine) {
			if (this.userIsAuthenticated) {
				return this._http.get<{ user: any }>(endpoint).pipe(
					map(currentUser => {
						// this.token = currentUser.user.token;
						return currentUser?.user;
					})
				);
			} else {
				// if (this.identityConfig.no_auth_urls.includes(this.router.url)) {
				//   console.log({ currentRouteUrl: this.router.url });
				//   this.router.navigate([this.router.url]);
				//   return empty();
				// } else {
				//   this.router.navigate([this.identityConfig.after_logout_url]);
				//   return empty();
				// }
				// this.toastr.show('Your session has expired! Please login again.');
				// this.store.dispatch(new LogOut());
				return empty();
			}
		} else {
			// this.store.dispatch(new LogOut());
			// return this.offlineService.getLoggedInUserFromServer().pipe(map(currentUser => currentUser.user));
			return empty();
		}
	}

	login(credentials: { email: string; password: string }): Observable<{ success: boolean; user: any }> {
		if (window.navigator.onLine) {
			const { api_urls, client } = this.environment;
			const api_urls_arr = Object.values(api_urls);
			if (client === 'amp' && api_urls_arr && api_urls_arr.length !== 0) {
				const loginApiCalls = [];
				api_urls_arr.forEach((apiUrl: any) => {
					const call = this._http
						.post<any>(`${apiUrl.url}${apiUrl.version}auth/login/`, {
							user: credentials
						})
						.pipe(
							map((data: { success: boolean; user: User; token: string }) => {
								this._tokenKey = apiUrl.tokenName;
								this.token = data?.user && data?.user.token ? data?.user.token : data?.token;
								return data;
							})
						);
					loginApiCalls.push(call);
				});
				return forkJoin(loginApiCalls).pipe(map((data: { success: boolean; user: User }[]) => data[0]));
			} else {
				return this._http
					.post<any>(this.identityConfig.login_endpoint, {
						user: credentials
					})
					.pipe(
						// delay(500000),
						map(data => {
							// this.token = data.user.token;
							return data;
						}),
						timeout(25000)
					);
			}
		} else {
			return of({
				user: null,
				success: false,
				errorMessage: 'You are offline. You can login when online!',
				dataKey: 'offlineUserLogin'
			});
			// return this.offlineService.login(credentials).pipe(
			//   map((data: any) => {
			//     if (data) {
			//       // this.token = data.user.token;
			//       return data;
			//     } else {
			//       return {
			//         user: null,
			//         success: false,
			//         errorMessage: 'You are offline. You can login when online!',
			//         dataKey: 'offlineUserLogin'
			//       };
			//     }
			//   })
			// );
		}
	}

	sendResetLink(email): Observable<any> {
		return this._http
			.post(this.identityConfig.send_reset_link_endpoint, {
				email
			})
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	logout() {
		this.destroyToken();
		switch (localStorage.getItem('flexus.web.authMethod')) {
			case 'azuread': {
				this.msalService.logout();
				break;
			}
			case 'local': {
				this.router.navigate([this.identityConfig.after_logout_url]);
				break;
			}
		}
		localStorage.removeItem('flexus.web.authMethod');
	}

	get userIsAuthenticated(): boolean {
		const jwtHelper = new JwtHelperService();
		return !!this.token && !jwtHelper.isTokenExpired(this.token);
	}

	get token(): string {
		return localStorage.getItem(this._tokenKey);
	}

	set token(token: string) {
		localStorage.setItem(this._tokenKey, token);
	}

	private destroyToken(): void {
		localStorage.removeItem(this._tokenKey);
	}

	getTokenExpirationDate(token: string): Date {
		const jwtHelper = new JwtHelperService();
		return jwtHelper.getTokenExpirationDate(token);
	}
}
