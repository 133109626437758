<div class="list-view-job-info" [id]="'job-' + itemTwo?.id">
	<div class="mock-item-one-container">
		<div class="indicator"></div>
		<div class="mock-item-one">
			<div style="text-align: left; font-size: small; color: #808782; box-sizing: border-box; padding: 0 0 0 75px">{{ itemTwo?.claim?.mid }}</div>
			<div style="text-align: right; font-size: larger; padding: 0 45px 0 0">{{ itemTwo?.claim?.applicant?.first_name }} {{ itemTwo?.claim?.applicant?.surname }}</div>
		</div>
	</div>
	<div class="job" (click)="takeAction(itemTwo)">
		<div class="indicator"><flx-indicator size="small"></flx-indicator></div>
		<div class="job-card-actions">
			<div class="context-menu-container">
				<flx-context-menu iconSize="small" *ngIf="isOnline && itemTwoContextMenuList?.length > 0" class="context-menu-override-disabled">
					<ul>
						<ng-container *ngFor="let menuItem of itemTwoContextMenuList">
							<li
								*ngIf="
									(menuItem?.excludeForOnlyStates.length > 0 && !menuItem?.excludeForOnlyStates.includes(itemTwo.state)) ||
									(!menuItem?.excludeForOnlyStates.includes(itemTwo?.state) && menuItem?.includeForOnlyStates.includes('all')) ||
									(menuItem?.includeForOnlyStates.includes(itemTwo?.state) && (!menuItem?.includeFilterFunction || menuItem?.includeFilterFunction(itemTwo)))
								"
								(click)="menuItem?.command ? this[menuItem.command]() : selectMenuItem(itemTwo, menuItem)"
							>
								{{ menuItem?.name }}
							</li>
						</ng-container>
						<li class="divider"><flx-glow-line></flx-glow-line></li>
					</ul>
				</flx-context-menu>
			</div>
		</div>

		<div class="job-card-div">
			<div *ngIf="client === 'pgg_sp'">{{ itemTwo?.voucher }}</div>
			<div *ngIf="client !== 'pgg_sp'">{{ itemTwo?.skillName }}</div>
		</div>

		<div class="job-card-div2">
			<div>{{ itemTwo?.state }} : {{ itemTwo?.stateName }}</div>
			<div class="take-action"><!-- Gets populated dynamically --></div>
		</div>
	</div>
</div>
