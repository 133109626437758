import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, MakeServerCall, ManifestController, ModalService, SetNextNode } from '@flexus/core';
import { cleanUpSub } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { Observable, of, Subscription } from 'rxjs';
import { map, pluck, skipWhile, take } from 'rxjs/operators';

@Component({
	selector: 'flx-add-job',
	templateUrl: './add-job.component.html',
	styleUrls: ['./add-job.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddJobComponent implements OnInit, OnDestroy, AfterViewInit {
	// ===========================================  Variables ===========================================================
	displayConfig = { displayKey: 'display', valueKey: 'value' };

	claimClassForm = new UntypedFormGroup({});
	// Claim Group information - Filtered select
	claimTypes$: Observable<any>;
	claimDetailsSub: Subscription;

	selectedClaimClass = 0;

	// Look up all skill information
	skillLookUp$: Observable<any>;
	skillLookUp: Array<any> = [];
	skillLookUpSub: Subscription;

	// Skill group information - drop down 1
	skillGroups$: Observable<any>;
	selectedClaimGroup$: Observable<any>;
	selectGroupData$: any;

	// Specific skill information - drop down 2
	allSkills$: Observable<any>;
	allSkillSub: Subscription;
	contextSkillsArr: any[];

	// Form data
	jobList: UntypedFormArray = null;
	currentJobList: UntypedFormArray = null;

	isIaOnJob: { [index: number]: boolean } = {};
	iaRequested = false;
	IAreasons$: Observable<any>;

	providerTypes = [
		{ value: 1, display: 'Request SP' },
		{ value: 2, display: 'Cash in Lieu' }
	];

	// ===========================================================  INPUT / OUTPUT events ===========================================================

	@Input() cashInLieuComp: any;
	@Input() offPanelComp: any;
	@Input() claimDetailsInfo$: Observable<any>;
	@Input() showCurrentJobs = false;

	// ============================================ Constructor =========================================================
	constructor(
		public _store: Store<any>,
		private controller: ManifestController<any>,
		private cd: ChangeDetectorRef,
		public bf: BigFormService,
		private fb: UntypedFormBuilder, // private jobCardRequestService: JobCardRequestService,
		private modalsService: ModalService
	) {}

	// ============================================= Methods ============================================================
	// ----------------------------------------- Life-cycle methods -----------------------------------------
	ngOnInit() {
		if (!this.bf.bigForm.get('jobs')) {
			this.bf.bigForm.addControl('jobs', new UntypedFormArray([this.createJob()]));
		}

		this.jobList = this.bf.bigForm?.value?.jobs as UntypedFormArray;
		this.splitData(this.claimDetailsInfo$);

		if (this.bf.bigForm.get('selectedClaimClass')?.value !== null) {
			this.getSkillGroup(this.bf.bigForm.get('selectedClaimClass')?.value);
		}

		if (this.showCurrentJobs) {
			const currentJobControls = this.bf.bigForm.get('currentJobs');
			this.currentJobList = currentJobControls?.value;
		}
	}

	ngAfterViewInit(): void {
		this.claimDetailsSub = this.bf.bigForm.get('jobs')?.valueChanges?.subscribe(val => {
			this.jobList = val as UntypedFormArray;
		});

		if (this.bf.bigForm.get('selectedClaimClass') && this.bf.bigForm.get('selectedClaimClass')?.value !== null) {
			this.selectedClaimClass = this.bf.bigForm.get('selectedClaimClass')?.value;
		}
	}

	ngOnDestroy() {
		cleanUpSub(this.claimDetailsSub);
		cleanUpSub(this.allSkillSub);
	}

	splitData(d$: Observable<any>) {
		this.claimTypes$ = d$.pipe(
			skipWhile(ct => !ct),
			map(data => data?.claim_types)
		);
		this.skillLookUp$ = d$.pipe(
			skipWhile(ct => !ct),
			map(data => data?.skills)
		);

		this.skillLookUpSub = this.skillLookUp$.pipe(skipWhile(x => !x)).subscribe(skills => {
			this.skillLookUp = skills ?? [];
		});

		// All Claim IDs associated with their skill groups only
		this.skillGroups$ = this.claimTypes$.pipe(
			map((obj: { [key: string]: { id: number; skill_groups: Array<any> } }) => {
				return obj && Object.values(obj).map(entry => ({ id: entry.id, skill_groups: entry?.skill_groups }));
			})
		);

		this.IAreasons$ = this.claimDetailsInfo$.pipe(
			pluck('ia_request_reasons'),
			map(entry => entry.map(reason => ({ display: reason, value: reason })))
		);
	}

	sortByPriority(a, b) {
		if (a.priority > b.priority) return -1;
		if (a.priority < b.priority) return 1;
	}

	// ===========================================================  Fetch drop down data methods ===========================================================
	getSkillGroup(index: number) {
		if (index !== 0 && index !== null && index !== undefined) {
			// find chosen group based on claim class
			this.selectedClaimGroup$ = this.skillGroups$.pipe(
				map(skillGroups => skillGroups?.find(skillGroup => Number(skillGroup?.id) === Number(index))),
				map(skillGroup => skillGroup?.skill_groups)
			);

			// build data for the drop down
			this.selectGroupData$ = this.selectedClaimGroup$.pipe(
				map((obj: { [key: string]: { id: number; name: string } }) => {
					return obj && Object.values(obj).map(entry => ({ display: entry?.name, value: entry.id }));
				})
			);

			// Initialize the possible skills based on the group with id and an associated array
			this.allSkills$ = this.selectedClaimGroup$.pipe(
				map((obj: { [key: string]: { id: number; skills: Array<any> } }) => {
					return obj && Object.values(obj).map(entry => ({ id: entry.id, skills: entry.skills }));
				})
			);

			this.allSkillSub = this.allSkills$.subscribe(skills => {
				if (skills) {
					this.contextSkillsArr = skills;
				}
			});
		}
	}

	getSkills(sGroup: string) {
		const group = parseInt(sGroup, 10);
		if (sGroup !== null && group !== 0) {
			const pos = this.findPos(group, this.contextSkillsArr);
			if (pos !== -1) {
				const skill = this.contextSkillsArr[pos];
				const skillArr = this.lookUpSkills(skill.skills);
				return skillArr;
			}
		}
		return this.defaultSkills();
	}

	// Look up each skill id and return a name based on the skill groups
	lookUpSkills(indexes: number[]) {
		const res = [];
		this.skillLookUp?.forEach(skill => {
			if (indexes.includes(skill.id)) {
				res?.push({ display: skill.name, value: skill.id });
			}
		});
		return res;
	}

	// This is to return a list of all the skills for the select to use as a default, to help patch the data
	defaultSkills(): Array<any> {
		const arr = this.skillLookUp ?? [];
		return arr.map(skill => ({ display: skill.name, value: skill.id }));
	}

	getProviderType(skill: number) {
		switch (skill) {
			case 41: // temp accommodation
			case 46: // Emergency Repairs
			case 22: // TV Aerial
				this.providerTypes = [{ value: 2, display: 'Cash in Lieu' }];
				break;
			case 44:
				this.providerTypes = [{ value: 1, display: 'Request SP' }];
				break;
			default:
				this.providerTypes = [
					{ value: 1, display: 'Request SP' },
					{ value: 2, display: 'Cash in Lieu' }
				];
				break;
		}
	}

	// ===========================================================  Contextual Methods ===========================================================
	goSkillGroupAction(groupID: number, index: number) {
		if (groupID !== null) {
			this.selectGroupData$.pipe(take(1)).subscribe(groups => {
				groups.forEach(group => {
					if (group.value === groupID) {
						this.bf.bigForm.get(`jobs.${index}.skillcatagory`).patchValue(group.display);
						this.bf.bigForm.get(`jobs.${index}.skill_id`).patchValue(null);
						this.bf.bigForm.get(`jobs.${index}.skill`).patchValue(null);
						return;
					}
				});
			});
		}
	}

	goSkillAction(skillID: number, index: number) {
		// Give the  warnings
		if (skillID !== null) {
			this.bf.bigForm.get(`jobs.${index}.skill`)?.patchValue(this.nameLookup(skillID, 'skills', 'name', this.claimDetailsInfo$));
			switch (skillID) {
				case 44:
					this.showWarningMessage([
						'You have selected an Internal Assessor',
						'',
						'It is strongly advised that you do not request any other skills when selecting an Assessor. The Assessor will take care of appointing service providers.'
					]);
					this.isIaOnJob[index] = true;
					break;
				default:
					this.isIaOnJob[index] = false;
					break;
			}
			this.getProviderType(skillID);
		}
	}

	goProviderTypeAction(event: any, index: number) {
		this.validateJobInputs();
		this.iaRequested = this.isIaRelated();
		if (event !== null) {
			const providerTypeSelected = this.providerTypes?.filter(providerType => providerType.value === event);
			if (providerTypeSelected.length === 1) {
				this.bf.bigForm.get(`jobs.${index}.providertype`)?.patchValue(providerTypeSelected[0]?.display);
				this.bf.bigForm.get(`jobs.${index}.providertype_id`)?.patchValue(providerTypeSelected[0]?.value);
			}
			switch (event) {
				case 1:
					// Uncomment for excess management pre-population

					this.bf.bigForm.get(`jobs.${index}.who_collects`)?.patchValue(2);
					this.bf.bigForm.get(`jobs.${index}.who_collects_description`)?.patchValue('Service provider');
					break;
				case 2:
					this.bf.bigForm.get(`jobs.${index}.who_collects`)?.patchValue(1);
					this.bf.bigForm.get(`jobs.${index}.who_collects_description`)?.patchValue('Insurer');

					this._store.dispatch(
						new MakeServerCall({
							dataKey: 'jobID',
							errorMessage: 'Could not save the job index',
							directCall: (http, store, sq) => {
								return of(this.jobList[index]?.id);
							}
						})
					);
					this.controller.dispatch(new SetNextNode(this.cashInLieuComp));
					break;
				case 3:
					this.controller.dispatch(new SetNextNode(this.offPanelComp));
					break;
			}
		}
	}

	isIaRelated(): boolean {
		let flag = false;
		for (const job in this.isIaOnJob) {
			if (!!job && this.isIaOnJob[job]) {
				flag = true;
			}
		}
		console.log('Checking IA,', flag);
		return flag;
	}

	// isExtraInfo(numArr: number[], claimClass: number) {
	//   if (numArr !== undefined && claimClass !== 0) {
	//     const res = this.findPos(claimClass, numArr);
	//     if (res === -1) {
	//       return false;
	//     } else {
	//       return true;
	//     }
	//   }
	// }

	nameLookup(targetId: number, group: string, field: string, source$: Observable<any>) {
		// Used to look up the name that corresponds to an id in an observable
		let res: any[] = [];
		const tempSub: Subscription = source$
			.pipe(
				pluck(group),
				map((obj: { [key: string]: { id: number; name: string } }) => {
					return Object.values(obj).map(entry => ({ id: entry.id, name: entry[field] }));
				})
			)
			.subscribe(testData => {
				res = testData;
				const resPos = this.findPos(targetId, res);
				res = res[resPos];
			});

		if (tempSub) {
			tempSub.unsubscribe();
		}

		return res !== undefined ? res['name'] : '';
	}

	validateJobInputs() {
		if (this.jobList.length > 1) {
			// Check for duplicates
			for (let index = 0; index < this.jobList.length; index++) {
				const element = this.jobList[index];
				for (let index2 = 0; index2 < this.jobList.length; index2++) {
					if (index !== index2) {
						const elementCompare = this.jobList[index2];
						if (this.compareJobs(element, elementCompare)) {
							this.showWarningMessage('You have just added the same Job Card.');
							break;
						}
					}
				}
			}
		}
	}

	// Compare two jobs against each other to see if they ae the same
	compareJobs(first: any, second: any): boolean {
		let flag = true;
		if (first.skillcatagory_id !== second.skillcatagory_id) {
			flag = false;
		}
		if (first.skill_id !== second.skill_id) {
			flag = false;
		}
		return flag;
	}

	showWarningMessage(message: string | string[]) {
		// Display a warning prompt
		setTimeout(() => {
			this.modalsService.openModalDirectly(insta => {
				insta.setMessage(Array.isArray(message) ? message : [message]);
				insta.closeButton = 'true';
				insta.type = 'warning';
				insta.navButtons = [];
			});
		}, 0);
	}

	//find the position of the id in the look up array
	findPos(id: any, arr: any[], field = 'id'): number {
		let res = -1;
		if (arr !== undefined) {
			let count = 0;
			arr.forEach(item => {
				if (item[field] === id || item === id) {
					res = count;
				}
				count++;
			});
		}
		return res;
	}

	// =========================================================== Add / Remove Job Methods ===========================================================
	get jobListDynamic() {
		return this.bf.bigForm.get('jobs') as UntypedFormArray;
	}

	createJob(job?): UntypedFormGroup {
		if (job) {
			let jobExcess;
			if (Array.isArray(job.excess)) {
				jobExcess = job?.excess.find(j => j?.excess_description === 'Additional Excess');
			} else {
				jobExcess = job?.excess;
			}
			return this.fb.group({
				id: UUID.UUID(),
				skillrequested: job?.skillrequested,
				skillcatagory_id: job?.skillcatagory_id,
				skillcatagory: job?.skillcatagory, // string name
				skill_id: job?.skill_id,
				skill: job?.skill, // string name
				providertype_id: job?.providertype_id,
				providertype: job?.providertype, // string name
				appointmentDatePicker: new Date(job.requestedappointmentdate),
				appointmentTimePicker: {
					hour: job?.requestedappointmenttime?.split(':')[0] ?? '',
					minutes: job?.requestedappointmenttime?.split(':')[1] ?? ''
				},
				appointmentTime: job?.appointment_type_id,
				appointmentDateType: job?.appointmentdatetype,
				amount: jobExcess?.excess_amount,
				who_collects: jobExcess?.who_collects_excess,
				who_collects_description: jobExcess?.who_collects_excess_desc,
				is_cancelled: false,
				excess_description: jobExcess?.excess_description,
				payment_method: jobExcess?.excess_payment_method,
				payment_description: jobExcess?.excess_payment_method_desc
			});
		} else {
			return this.fb.group({
				id: UUID.UUID(),
				skillrequested: [1, [Validators.required]], // signal to the server to create job
				skillcatagory_id: [null, [Validators.required]],
				skillcatagory: null, // string name
				skill_id: [null, [Validators.required]],
				skill: null, // string name
				providertype_id: [null, [Validators.required]],
				providertype: null, // string name
				appointmentDatePicker: null,
				appointmentTimePicker: null,
				appointmentTime: null,
				amount: [null, [Validators.required]],
				who_collects: [null, [Validators.required]],
				who_collects_description: null,
				is_cancelled: false,
				excess_description: 'Standard Excess',
				payment_method: [null, [Validators.required]],
				payment_description: null
			});
		}
	}

	incJobCount(index: number) {
		this.jobListDynamic.push(this.createJob());
		if (this.jobList !== null) {
			if (this.jobList.length > 1) {
				if (this.selectedClaimClass === 1 || this.selectedClaimClass === 4) {
					const name = this.selectedClaimClass === 1 ? 'Geyser' : 'Pipes';
					this.showWarningMessage('You are adding multiple job cards to a ' + name + ' Only claim. Please double check you have selected the correct claim class.');
				}
			}
		}
	}

	removeJob(i: number) {
		if (this.jobList !== null) {
			this.jobListDynamic.removeAt(i);
			this.isIaOnJob[i] = false;
			this.iaRequested = this.isIaRelated();
		}
	}
}
