import { offlineActions, actionTypes } from './offline.actions';

export interface State {
	isConnected: boolean;
}

const initialState: State = {
	isConnected: window.navigator.onLine
};

export function reducers(state: State = initialState, action: offlineActions) {
	switch (action.type) {
		case actionTypes.UPDATE_NETWORK_CONNECTION: {
			const newState = { ...state, isConnected: action.payload };
			return newState;
		}
		default:
			return state;
	}
}
