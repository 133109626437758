import { Flow_0_0_2, getData, SetNextNode, MakeServerCall, getCurrentUser, getFullItemOne } from '@flexus/core';
import { map, switchMap, take, filter, skipWhile, first, pluck } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { getSelectedItemOne, getFullItemTwo } from '@flexus/core';
import { of, forkJoin, EMPTY } from 'rxjs';
import gql from 'graphql-tag';
import { values } from 'ramda';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { environment } from 'apps/studio/src/environments/environment';
import { BUI_50 } from './BUI_50';
import { getAllInfoIndex } from '@flexus/utilities';
import { findJobLog } from '../reusable/state-utils';

export const BUI_51: Flow_0_0_2 = {
	id: '51',
	name: 'payment_approval_2',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					let headerStr = 'Payment Approval 2';
					if (itemOne) {
						headerStr += ` : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					}
					return headerStr;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	instructions: {
		editRoles: {
			0: 'Authorise the payment'
		},
		viewRoles: {
			0: '--'
		}
	},
	events: {
		invalidatePaymentDataIfDeclineIsSet: {
			triggerOn: 'decline_reason',
			triggerWhen: qr => !!qr,
			dataMutations: bf => {
				bf.patchValues({
					invoice_number: null,
					new_state: 52
				});
			}
		},

		invalidateDeclineDataIfInvoice: {
			triggerOn: 'invoice_number',
			triggerWhen: invoice_number => !!invoice_number,
			dataMutations: bf => {
				bf.patchValues({
					decline_reason: null,
					new_state: 30
				});
			}
		}
	},

	serverCalls: {
		invoice: {
			serviceVariable: 'buiService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		dataFiles: {
			errorMessage: 'No invoice found!',
			directCall: (http, store, sq) => {
				// return throwError('something went wrong');
				return sq
					.queryObject(
						gql`
							{
								invoice {
									data
								}
							}
						`,
						store.select(getData)
					)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1),
						map(data => data['data'])
					);
			}
		},
		authorisers: {
			serviceVariable: 'buiService',
			functionName: 'getAuthorizers',
			errorMessage: 'No authorizers could be found!'
		},
		timeStamps: {
			errorMessage: 'Could not get time stamps',
			directCall: (http, store) => {
				return forkJoin([
					store.select(getSelectedItemOne).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getFullItemTwo).pipe(
						skipWhile(x => !x),
						take(1)
					)
				]).pipe(
					filter(x => !!x),
					switchMap(([claim, job]) => {
						return http.post(`${environment.api_url}v1/claim_action/get_time_logs/`, { [`claim_id`]: claim?.id }).pipe(
							skipWhile(logs => !logs),
							switchMap((res: any) => {
								const setsOfJobLogs: any[] = res?.payload['jobs'];
								const autoPayJobLogForState27Or39 = findJobLog(setsOfJobLogs, job, [27, 39]);
								const autoPayJobLogForState50 = findJobLog(setsOfJobLogs, job, [50]);
								const applicationCreatorId = claim?.application_creator?.id;
								const paymentapproval1 = job?.job_information?.paymentapproval1;
								const paymentapproval2 = job?.job_information?.paymentapproval2;
								return http
									.post(environment.api_url + 'v1/staff_action/get_staff_member_names', {
										ids: [+applicationCreatorId, +paymentapproval1, +paymentapproval2]
									})
									.pipe(
										map((data: any) => data?.payload),
										map((mapper: any) => ({
											payArroval1: autoPayJobLogForState27Or39?.modifier || mapper[paymentapproval1],
											payHandler: mapper[applicationCreatorId],
											payApproval2: autoPayJobLogForState50?.modifier || mapper[paymentapproval2]
										}))
									);
							})
						);
					})
				);
			}
		}
	},
	startNode: 'Decision',

	nodes: {
		Decision: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				authorization: (navs, store, modal) => {
					return forkJoin([
						store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1)
						),
						store.select(getCurrentUser).pipe(
							skipWhile(x => !x),
							take(1)
						)
					])
						.pipe(
							filter(x => !!x),
							switchMap(([job, user]) => {
								switch (true) {
									case job?.job_creator === user?.user?.id: {
										modal.openModalDirectly(instance => {
											instance.type = 'warning';
											instance.message = 'Job creator cannot provide second and third eyes for payment authorization';
											instance.navButtons = [
												{
													text: 'Back to workflow',
													clickHandler: event => {
														instance.router.navigate(['/workflow']);
													},
													linkType: 'close',
													color: 'alert'
												}
											];
										});
										break;
									}
									case parseInt(job?.job_information?.paymentapproval1, 10) === user?.user?.id: {
										modal.openModalDirectly(instance => {
											instance.type = 'warning';
											instance.message = 'You can not process a job you have authorized';
											instance.navButtons = [
												{
													text: 'Back to workflow',
													clickHandler: event => {
														instance.router.navigate(['/workflow']);
													},
													linkType: 'close',
													color: 'alert'
												}
											];
										});
										break;
									}
									case parseInt(job?.job_information?.paymentapproval2, 10) === user?.user?.id: {
										modal.openModalDirectly(instance => {
											instance.type = 'warning';
											instance.message = 'You can not process a job which you initiated payment for';
											instance.navButtons = [
												{
													text: 'Back to workflow',
													clickHandler: event => {
														instance.router.navigate(['/workflow']);
													},
													linkType: 'close',
													color: 'alert'
												}
											];
										});
										break;
									}
									default: {
										store.dispatch(
											new MakeServerCall({
												dataKey: 'updateJob',
												serviceVariable: '',
												functionName: '',
												errorMessage: 'Could not update Job',
												directCall: (http, store, sq, bf, controller) => {
													bf.patchValues({
														paymentapproval3: user?.user?.id
													});
													controller.dispatch(new SetNextNode('PaymentPreview'));

													return of({});
												}
											})
										);

										break;
									}
								}
								return 'Decision';
							})
						)
						.pipe(take(1))
						.subscribe(nextNode => {});
				}
			},
			navs: [{ text: 'Success', nextNode: 'SubmissionSuccess' }]
		},

		PaymentPreview: {
			inputs: { inputHeading: 'Payment Preview' },
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList2', heading: 'Approvals' }
					}
				]
			},
			serverCalls: {
				...BUI_50?.nodes?.PaymentPreview?.serverCalls,
				keyValueList2: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store, sq) => {
						const selectedContext$ = sq
							.queryStore(
								gql`
									{
										selectedContext {
											timeStamps
										}
									}
								`
							)
							.pipe(
								skipWhile(f => !f.timeStamps),
								take(1),
								pluck('timeStamps'),
								map(value => {
									const list = {
										'Job Creator': value && value.payHandler ? value.payHandler : '',
										'Payment Approval Handler': value && value.payArroval1 ? value.payArroval1 : '',
										'First Authoriser': value && value.payApproval2 ? value.payApproval2 : ''
									};

									return [list];
								})
							);

						return selectedContext$;
					}
				}
			},

			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ new_state: 30 });

				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
								}
							}
							identity {
								currentUser {
									user {
										id
									}
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(data => {
						bf.patchValues({ current_state: data?.state, authid: data?.id });
					});

				sq.queryStore(
					gql`
						{
							selectedContext {
								invoice {
									invoice_number
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(invoice_number => {
						bf.patchValues({ invoice_number: invoice_number.invoice_number });
					});
			},

			navs: [
				{ text: 'Decline Payment', nextNode: 'DeclinePayment', color: 'alert' },
				{
					text: 'Approve Payment',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		DeclinePayment: {
			component: 'FLXFlatDynamicFormComponent',
			serverCalls: {
				selectOptions: {
					errorMessage: 'Cannot find decline reasons',
					directCall: (http, store, sq) => {
						return sq
							.queryStore(
								gql`
									{
										allInfo {
											cancel_reasons
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1),
								map(values),
								map(value => value[0]),
								map(vals => ({ declineReasons: vals }))
							);
					}
				}
			},
			inputs: {
				heading: '',
				formControls: {
					0: {
						label: 'Select reason for declining payment',
						inputType: 'select',
						selectConfig: {
							displayOptions: { displayKey: 'name', valueKey: 'id' },
							itemsOption: 'declineReasons',
							searchEnabled: false,
							placeHolder: 'Decline Reason'
						},
						formControlName: 'decline_reason'
					},
					1: {
						label: 'Enter note on the reason for declining',
						inputType: 'textarea',
						formControlName: 'decline_notes'
					}
				},
				formLayout: 'stacked',
				containerWidth: '400px'
			},

			checkValidityForFields: ['decline_reason', 'decline_notes'],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('decline_reason', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('decline_reason_string', new UntypedFormControl(''));

				bf.addControl('decline_notes', new UntypedFormControl('', [Validators.required, Validators.minLength(3)]));

				bf.patchValues({ new_state: 52 });
				// bf.addControl('new_state', new FormControl(52));
			},
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		authid: ['authid', 'job_information.authid'],
		current_state: 'current_state',
		paymentapproval3: 'job_information.paymentapproval3',
		new_state: 'new_state',
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		decline_reason: [
			(dr, storeObj, bf) => {
				let pd: any[] = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo &&
					storeObj['selectedContext']?.fullItemTwo.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline)) {
						pd = storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline;
					} else {
						pd = [
							{
								...(<object>storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline)
							}
						];
					}
				}

				const payment_decline = [...pd, { reason: dr, comment: bf.decline_notes }];
				return payment_decline;
			},
			'job_information.payment_decline'
		],

		decline_reason_string: [
			(dr, storeObj, bf) => {
				return !!bf.decline_reason ? getAllInfoIndex('cancel_reasons', 'id', 'name', bf.decline_reason, storeObj) : null;
			},
			'job_information.declinereason'
		],
		decline_notes: ['job_information.declinereasoncomments']
	}
};
