import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VatService {
	private vatTracker = new BehaviorSubject<number>(0);

	getVATPercentage$() {
		return this.vatTracker.asObservable();
	}

	setVATPercentage(vat: number) {
		this.vatTracker.next(vat);
	}

	_currentVatRate = 15;
	_previousVatRate = 14;
	_vatChangeDate = new Date(2018, 3, 1);

	constructor() {}

	public getVatPercentageFromDate(invoice_date: Date): number {
		if (invoice_date >= this._vatChangeDate) {
			return this._currentVatRate;
		} else {
			return this._previousVatRate;
		}
	}

	public getVatRate(total: number, vatPercentage: number): number {
		return total * vatPercentage;
	}
}
