<div class="sp-reply-list dynamic-component">
	<ng-container *ngIf="replyListData$ | async as data">
		<div class="title" *ngIf="data?.templateTitle">
			<flx-heading weight="extra-light" size="medium" [align]="'center'">{{ data?.templateTitle }}</flx-heading>
			<flx-heading weight="extra-light" size="medium" [align]="'center'" *ngIf="subTitle">{{ subTitle }}</flx-heading>
		</div>
		<div class="subTitle" *ngIf="templateSubTitle">
			<flx-heading size="small" [align]="'center'"> {{ templateSubTitle }}</flx-heading>
		</div>

		<ng-container *ngIf="data?.cardData">
			<ng-container *ngIf="data?.cardData.length > 0; else noCards">
				<flx-panel maxWidth="50vw" alignItems="center" margin="auto">
					<form [formGroup]="_bfs.bigForm" *ngIf="hasSearch" class="sp-reply-list-formgroup">
						<flx-input-text type="text" class="form-input" formControlName="sp_list_search" placeholder="Search..." autocomplete="false" size="medium"></flx-input-text>
						<br />
					</form>
					<flx-scroll-container height="400px" color="default" padding="0.5rem">
						<flx-entity-list (outputData)="handleEntitySelection($event, data)" [config]="data?.listConfig" [data$]="cardData$" [state]="state"></flx-entity-list>
					</flx-scroll-container>
				</flx-panel>
			</ng-container>
		</ng-container>

		<ng-template #noCards>
			<div class="no-cards">
				<flx-heading size="small" [align]="'center'">{{ data?.noCardsMessage }}</flx-heading>
			</div>
		</ng-template>
	</ng-container>
</div>
