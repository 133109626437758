import { Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { filter, skipWhile, first, map, take } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const SIL_59: Flow_0_0_2 = {
	id: '59',
	name: 'joint_account_confirmation',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map(itemOne => {
					if (itemOne) {
						return 'Joint Account Confirmation : ' + itemOne?.loan_information.mavenclaimnumber + ' - ' + itemOne?.applicant?.surname;
					} else {
						return 'Joint Account Confirmation';
					}
				})
			);
		},
		controls: () => () => []
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Obtain joint account approva'
		},
		viewRoles: {
			0: 'Joint Account Confirmation'
		}
	},
	startNode: 'JointAccConfirm',
	nodes: {
		JointAccConfirm: {
			component: 'FileViewWithExtraComponent',
			initFormFields: bf => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(58));
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Cannot get retrieve data',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map(vals => vals.claim),
							map((vals: any) => {
								const res = {
									Claimant: vals && vals.applicant ? vals.applicant?.first_name + ' ' + vals.applicant?.surname : '',
									'Claim Type': vals && vals.loan_information ? vals.loan_information.claimtype : '',
									Address:
										vals && vals.loan_information
											? vals.loan_information.propertystreetaddress + ', ' + vals.loan_information.propertysuburb + ', ' + vals.loan_information.propertycity
											: '',
									'Contact Number': vals && vals.loan_information ? vals.loan_information.contactnumber : '',
									'Cell Number': vals && vals.loan_information ? vals.loan_information.cellnumber : '',
									'What Matters': vals && vals.loan_information ? vals.loan_information.whatmatters : ''
								};
								return [res];
							})
						);
					}
				},
				copyText: {
					errorMessage: 'Cannot return maven claim number!',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map(vals => vals.claim?.mid),
							map((vals: any) => {
								return vals;
							})
						);
					}
				}
			},
			inputs: {
				inputHeading: 'Joint Account Confirmation',
				subHeading: 'More than one person exist as claimants on this policy',
				instructions: ['Check Maven for account details', 'Phone the other joint account holders to confirm that they are aware of the request for Cash in Lieu'],
				containerWidth: '50vw',
				fileDoesNotExistMessage: ''
			},
			navs: [
				{
					text: 'Continue',
					serverFirst: true,
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemTwo.id': 'job_id',
		'itemTwo.state': 'current_state'
	}
};
