import { Action } from '@ngrx/store';

export const LOAD_MANIFEST = '[Manifest] LOAD MANIFEST';
export const SET_ACTIVE_ORGANIZATION = '[Manifest] SET ACTIVE ORGANIZATION';
// export const SET_ACTIVE_STATEFLOW = '[Manifest] SET ACTIVE STATEFLOW';
// export const SET_ACTIVE_STATEFLOW_FOR_GRAPH = '[Manifest] SET ACTIVE STATEFLOW FOR GRAPH';

export const SET_ACTIVE_MANIFEST_ITEM = '[Manifest] SET ACTIVE MANIFEST ITEM';
export const SET_ACTIVE_MANIFEST_ITEM_FOR_GRAPH = '[Manifest] SET ACTIVE MANIFEST ITEM FOR GRAPH';

export const SET_NEXT_NODE = '[Manifest] SET NEXT NODE';
export const SET_NEXT_NODE_USING_TAB = '[Manifest] SET NEXT NODE USING TAB';
export const SET_PREVIOUS_NODE = '[Manifest]  SET PREVIOUS NODE';
export const GO_TO_WORKFLOW = '[Manifest] GO TO WORKLFOW';

export const SET_VIEW = 'SET VIEW';
export const SET_VIEW_SUCCESS = 'SET VIEW SUCCESS';
export const SET_VIEW_FAIL = 'SET VIEW FAIL';
export const USE_EXISTING_DATASOURCE_FOR_VIEW = 'USE EXISTING DATASOURCE FOR VIEW';
export const DELETE_LOCAL_DRAFT = 'DELETE LOCAL DRAFT';
export const CHANGE_MANIFEST_STATE = 'CHANGE_MANIFEST_STATE';

export class LoadManifest implements Action {
	readonly type = LOAD_MANIFEST;
	constructor(public payload: any) {}
}

export class SetActiveOrganization implements Action {
	readonly type = SET_ACTIVE_ORGANIZATION;
	constructor(public payload: any) {}
}

export class SetActiveManifestItem implements Action {
	readonly type = SET_ACTIVE_MANIFEST_ITEM;
	constructor(
		public payload: {
			pathToFlows: string[];
			orgKey: string;
			itemId: number | string;
			defaultNodeKey?: string;
			itemOne?: any;
			itemTwo?: any;
		}
	) {}
}

export class SetActiveManifestItemForGraph implements Action {
	readonly type = SET_ACTIVE_MANIFEST_ITEM_FOR_GRAPH;
	constructor(public payload: { pathToFlows: string[]; orgKey: string; itemId: number | string }) {}
}

export class SetNextNode implements Action {
	readonly type = SET_NEXT_NODE;
	constructor(public payload: string) {
		console.log('next node payload', payload);
	}
}

export class SetNextNodeUsingTab implements Action {
	readonly type = SET_NEXT_NODE_USING_TAB;
	constructor(public payload: any) {}
}

export class SetPreviousNode implements Action {
	readonly type = SET_PREVIOUS_NODE;
}

export class SetView implements Action {
	readonly type = SET_VIEW;
	constructor(public payload: { func: Function; key: string; params: { reverse: boolean } }) {}
}

export class SetViewSuccess implements Action {
	readonly type = SET_VIEW_SUCCESS;
	constructor(public payload: { key: string; result: any }) {}
}

export class GoToWorkflow implements Action {
	readonly type = GO_TO_WORKFLOW;
	constructor() {}
}

export class SetViewFail implements Action {
	readonly type = SET_VIEW_FAIL;
	constructor(public payload: any) {}
}

export class UseExistingDatasourceForView implements Action {
	readonly type = USE_EXISTING_DATASOURCE_FOR_VIEW;
	constructor(public payload: { func: Function; key: string; params: { reverse: boolean } }) {}
}

export class DeleteLocalDraft implements Action {
	readonly type = DELETE_LOCAL_DRAFT;
	constructor(public payload: string) {}
}

export class ChangeManifestState implements Action {
	readonly type = CHANGE_MANIFEST_STATE;
	constructor(public payload: (state: any) => any) {}
}

export type manifestActions =
	| LoadManifest
	| SetActiveOrganization
	| SetActiveManifestItem
	| SetActiveManifestItemForGraph
	| SetNextNode
	| SetNextNodeUsingTab
	| SetPreviousNode
	| SetView
	| SetViewSuccess
	| SetViewFail
	| UseExistingDatasourceForView
	| DeleteLocalDraft;
