<div class="card-container" [id]="'claim-' + itemOne?.id">
	<!--  <div class="claim-card" [ngClass]="[isOffline, indicatorClass]" (click)="takeClaimAction()">-->
	<div class="claim-card" (click)="takeClaimAction()" [ngClass]="!isOnline && itemOne?.state !== 169 ? 'is-offline' : null">
		<div class="indicator"><flx-indicator></flx-indicator></div>
		<div class="claim-card-icons">
			<div class="context-menu-container">
				<div class="action">
					<flx-context-menu *ngIf="isOnline || itemOne?.state === 169" class="context-menu-icon" iconSize="small">
						<ul>
							<ng-container *ngFor="let menuItem of itemOneContextMenuList">
								<li
									*ngIf="
										(menuItem?.excludeForOnlyStates.length > 0 && !menuItem?.excludeForOnlyStates.includes(itemOne.state)) ||
										(!menuItem?.excludeForOnlyStates.includes(itemOne.state) && menuItem?.includeForOnlyStates.includes('all')) ||
										(menuItem?.includeForOnlyStates.includes(itemOne.state) && (!menuItem?.includeFilterFunction || menuItem?.includeFilterFunction(itemOne)))
									"
									(click)="selectMenuItem(itemOne, menuItem)"
								>
									{{ menuItem?.name }}
								</li>
							</ng-container>
							<li class="divider"><flx-glow-line></flx-glow-line></li>
						</ul>
					</flx-context-menu>
				</div>
			</div>
			<div class="indicator-icons">
				<!-- CAT indicator -->
				<div class="action" *ngIf="itemOne && itemOne?.cat_code && itemOne?.cat_code != null">
					<flx-icon size="extra-small" [type]="'storms'" instruction="Catastrophe" color="indicator" [isClickable]="false"></flx-icon>
				</div>
				<!-- Private Client indicator -->
				<div class="action" *ngIf="itemOne && itemOne?.private_banking !== null && itemOne?.private_banking === 1">
					<flx-icon class="pc" instruction="Private Banking Client" [type]="'private-client'" color="indicator"></flx-icon>
					<!-- <flx-icon size="extra-small" instruction="Private Banking Client" type="star" color="indicator"></flx-icon> -->
				</div>
				<!-- Notes indicator -->
				<div class="action" *ngIf="itemOne && itemOne?.note_count !== null && itemOne?.note_count > 0">
					<flx-icon
						size="extra-small"
						[instruction]="itemOne.note_count > 1 ? itemOne?.note_count + ' notes' : itemOne?.note_count + ' note'"
						[type]="'notes'"
						(click)="selectMenuItem(itemOne, { key: 'SIL_CONTEXT_MENU_NOTES' })"
					></flx-icon>
				</div>
				<!-- <div class="action" *ngIf="itemOne && itemOne?.cat_code && itemOne?.cat_code != null">
          <flx-icon size="default" instruction="Private Client" type="star" color="indicator" isClickable="false"></flx-icon>
        </div> -->
			</div>
			<!--      <div *ngIf="(isOnline$ | async)" class="context-menu-container" (click)="setActiveObject(claim)">-->
		</div>
		<div class="claim-info">
			<p class="claim-number">
				<small *ngIf="engClaimType" class="claim-type">{{ engClaimType }}</small> <span class="hv-copy">&nbsp;{{ itemOne?.mid }}</span>
			</p>
			<p class="claimant">{{ itemOne?.applicant?.first_name + ' ' + itemOne?.applicant?.surname || 'No Client Name Captured' }}</p>
			<p *ngIf="engState" class="claim-state">{{ itemOne?.state }}: {{ engState }}</p>
			<p class="sla-timer"></p>
		</div>
	</div>
	<div class="job-cards-container">
		<flx-mul-job-card
			[itemTwo]="itemTwo"
			[itemOne]="itemOne"
			[isOnline]="isOnline"
			[allInfo]="allInfo"
			[itemTwoContextMenuList]="itemTwoContextMenuList"
			[itemTwoPermissions]="itemTwoPermissions"
			[user]="user"
			[activeOrg]="activeOrg"
			*ngFor="let itemTwo of itemOne?.jobs"
		>
		</flx-mul-job-card>
	</div>
</div>
