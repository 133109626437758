<ng-container>
	<div class="container">
		<div class="heading">
			<flx-heading *ngIf="!is_qrOnly" align="center" size="medium"
				>Please Enter Correct {{ filteredQRItems[0] }}, {{ filteredQRItems[1] }} and {{ filteredQRItems[2] }} QR Code
			</flx-heading>
			<flx-heading *ngIf="is_qrOnly" align="center" size="medium">Please Enter Correct QR Code </flx-heading>
		</div>
		<div class="form-controls-container">
			<form [formGroup]="qrCodeInputForm">
				<!-- <div formGroupName="qrCodeInputFormGroup"> -->
				<div *ngIf="!is_qrOnly">
					<div *ngFor="let qr_item of filteredQRItems; let i = index" formArrayName="qrCodeInputItems">
						<div class="qrcode-input-line-item">
							<div class="qrcode-descriptor">{{ filteredQRItems[i] }} QR Code</div>
							<div class="qrcode-item-input">
								<flx-input-text type="text" formControlName="qr_code_input"></flx-input-text>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="is_qrOnly">
					<div class="qrcode-input-line-item">
						<div class="qrcode-descriptor">QR Code</div>
						<div class="qrcode-item-input">
							<flx-input-text type="text"></flx-input-text>
						</div>
					</div>
				</div>
				<!-- </div> -->
			</form>
		</div>
	</div>
</ng-container>
