import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getAllInfo } from '@flexus/core';
import { take, map, skipWhile, pluck } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { findName } from '@flexus/utilities';

export const BET_322: Flow_0_0_2 = {
	id: '322',
	name: 'repudiation_overturned_inform_customer',
	itemType: 'flow',
	header: {
		title: store => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
				map((itemOne: any) => `${itemOne?.mid} - ${itemOne?.applicant?.first_name} ${itemOne?.applicant?.surname}`)
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], { filesFunctionName: 'getAllClaimFiles' }),
	serverCalls: {
		importantInfo: {
			errorMessage: '',
			directCall: (_h, store) =>
				store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					pluck('job_information'),
					map((job_information: any) => {
						if (job_information) {
							return {
								important_information: {
									'Reason repudiation was overturned': job_information?.repudiation_overturned_reason
								}
							};
						}
					})
				)
		},
		claimDetails: {
			errorMessage: 'Could get summary details',
			serviceVariable: '',
			directCall: (_h, store) =>
				store.select(getFullItemTwo).pipe(
					skipWhile((itemTwo: any) => !itemTwo),
					take(1),
					map((itemTwo: any) => ({
						'Claim Details': {
							'Claim Number': itemTwo?.claim?.mid,
							'Policy Number': itemTwo?.claim?.applicant?.claimantpoliceynum,
							'Claim Type': itemTwo?.claim?.type,
							'Skill Required': itemTwo?.office_use?.skill,
							Address: [
								itemTwo?.claim?.loan_information?.propertystreetaddress,
								itemTwo?.claim?.loan_information?.propertysuburb,
								itemTwo?.claim?.loan_information?.propertycity
							].join(', ')
						}
					}))
				)
		},
		customerDetails: {
			errorMessage: '',
			directCall: (_h, store) =>
				forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile(job => !job),
						take(1)
					),
					store.select(getAllInfo).pipe(take(1))
				]).pipe(
					map(([job, allInfo]) => ({
						customer_details: {
							customer: `${job?.claim?.applicant?.first_name} ${job?.claim?.applicant?.surname}`,
							'Client Cell': job?.claim?.loan_information?.cellnumber,
							'Client Alternative No.': job?.claim?.loan_information?.contactnumber,
							'Excess Collection': `${findName(job?.excess[0]?.who_collects, allInfo.excess_who)?.name} To Collect`,
							'Excess Amount': `R${job?.excess[0]?.amount}`,
							'Excess Payment': findName(job?.excess[0]?.how_collect, allInfo.excess_how)?.name
						}
					}))
				)
		}
	},
	instructions: {
		editRoles: {
			0: 'Repudiation overturned - inform customer'
		},
		viewRoles: {
			0: 'Repudiation overturned - inform customer'
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			name: 'Summary',
			initFormFields: bf => {
				bf.patchValues({ new_state: 291, current_state: 322 });
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Repudiation overturned. Contact the customer',
							instructions: [
								'Please contact the customer and notify them that the repudiation on this claim has been overturned',
								'The service provider will be in contact with the customer to start the work'
							],
							instructionConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							keyValueHeadingConfig: { color: 'secondary' },
							objectInfo$: 'importantInfo'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'claimDetails'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'customerDetails'
						}
					}
				]
			},
			navs: [
				{
					text: 'Customer notified',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						updateJob: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		current_state: [() => 322, 'current_state']
	}
};
