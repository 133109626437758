<div [ngClass]="client === 'pgg_sp' ? 'detail-view-job-info-brand' : 'detail-view-job-info'" [id]="'job-' + itemTwo?.id">
	<div *ngIf="client === 'pgg_sp'" class="brand-box" [inlineSVG]="demand_source_imgurl">
		<div *ngIf="itemTwo.demandSource && !demand_source_imgurl">{{ itemTwo?.demandSource | uppercase }}</div>
		<div [inlineSVG]="demand_source_imgurl" class="brand-svg"></div>
	</div>
	<div *ngIf="client === 'pgg_sp'" class="spacer"></div>
	<div [ngClass]="client === 'pgg_sp' ? 'mock-item-one-brand-container' : 'mock-item-one-container'">
		<div class="indicator"></div>
		<div [ngClass]="client === 'pgg_sp' ? 'mock-item-one-brand' : 'mock-item-one'">
			<div style="text-align: right; font-size: larger">{{ itemTwo?.claim?.applicant?.first_name }} {{ itemTwo?.claim?.applicant?.surname }}</div>
			<div style="text-align: right; font-size: smaller">{{ itemTwo?.suburb }}</div>
			<div style="text-align: right; font-size: smaller; color: #808782">{{ itemPrefix }}: {{ itemTwo?.claim?.mid }}</div>
			<div *ngIf="userclient === 'multichoice'" style="text-align: right; font-size: smaller; color: #808782">
				Claim type: <strong>{{ claimtype }}</strong>
			</div>
		</div>
	</div>
	<div class="job" (click)="takeAction(itemTwo)">
		<div class="indicator"><flx-indicator size="small"></flx-indicator></div>
		<div class="job-card-actions">
			<div class="context-menu-container">
				<flx-context-menu iconSize="small" *ngIf="isOnline && itemTwoContextMenuList?.length > 0" class="context-menu-override-disabled">
					<ul>
						<ng-container *ngFor="let menuItem of itemTwoContextMenuList">
							<li
								*ngIf="
									(menuItem?.excludeForOnlyStates.length > 0 && !menuItem?.excludeForOnlyStates.includes(itemTwo.state)) ||
									(!menuItem?.excludeForOnlyStates.includes(itemTwo?.state) && menuItem?.includeForOnlyStates.includes('all')) ||
									(menuItem?.includeForOnlyStates.includes(itemTwo?.state) && (!menuItem?.includeFilterFunction || menuItem?.includeFilterFunction(itemTwo)))
								"
								(click)="menuItem?.command ? this[menuItem.command]() : selectMenuItem(itemTwo, menuItem)"
							>
								{{ menuItem?.name }}
							</li>
						</ng-container>
						<li class="divider"><flx-glow-line></flx-glow-line></li>
					</ul>
				</flx-context-menu>
			</div>
		</div>
		<div class="action">
			<flx-icon
				*ngIf="itemTwo?.note_count !== null && itemTwo?.note_count > 0"
				size="extra-small"
				[instruction]="itemTwo?.note_count > 1 ? itemTwo?.note_count + ' notes' : itemTwo?.note_count + ' note'"
				[type]="'notes'"
				(click)="selectMenuItem(itemTwo, { key: 'SP_CONTEXT_MENU_JOB_NOTES' })"
				class="notes"
			></flx-icon>
		</div>
		<div class="job-card-div">
			<div *ngIf="client === 'pgg_sp'">{{ itemTwo?.voucher }}</div>
			<div *ngIf="client !== 'pgg_sp'">{{ itemTwo?.skillName }}</div>
			<div>{{ itemTwo?.teamleadName }}</div>
		</div>
		<div class="job-card-div">
			<div>{{ itemTwo?.formattedDate }}</div>
			<div>{{ itemTwo?.appointmentType }} {{ itemTwo?.appointmentTime }}</div>
		</div>
		<div class="job-card-div">
			<div>{{ itemTwo?.state }} : {{ itemTwo?.stateName }}</div>
			<div class="take-action"><!-- Gets populated dynamically --></div>
		</div>

		<div class="sla-timer"></div>
		<!-- <div class="sla-timer">{{ itemTwo?.timeSinceUpdate }}</div> -->
	</div>
</div>
