import { Component, Input } from '@angular/core';

@Component({
	selector: 'flx-circle-indicator',
	templateUrl: 'circle-indicator.component.html',
	styleUrls: ['circle-indicator.component.scss']
})
export class CircleIndicatorComponent {
	@Input() color: string;
}
