import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'flx-card-body',
	templateUrl: './card-body.component.html',
	styleUrls: ['./card-body.component.scss']
})
export class FLXCardBodyComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
