import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { BigFormService } from '@flexus/core';
import { map, take } from 'rxjs/operators';
import { FLXDropdownComponent } from '@flexus/ui-elements';

@Component({
	selector: 'flx-current-setup',
	templateUrl: './current-setup.component.html',
	styleUrls: ['./current-setup.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrentSetupComponent implements OnInit, OnDestroy {
	displayConfig = { displayKey: 'description', valueKey: 'id' };

	@Input() installationDetails$: Observable<any>;
	@Input() is_sp_job_card: boolean;
	@ViewChild('typeof_tvs') typeof_tvs: FLXDropdownComponent;
	noOfTVs: any;
	typeOfTVs: any;
	noOfViews: any;
	dwellingType: any;
	mountedInstallation: any;
	tvnumbersub: Subscription;

	constructor(public _store: Store<any>, public bf: BigFormService, private _cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.installationDetails$.pipe(take(1)).subscribe((vals: any[]) => {
			this.noOfTVs = vals.find(x => x.description === 'Number of TVs');
			this.typeOfTVs = vals.find(x => x.description === 'Current TV type');
			this.noOfViews = vals.find(x => x.description === 'Viewing environments required');
			this.dwellingType = vals.find(x => x.description === 'Dwelling type');
			this.mountedInstallation = vals.find(x => x.description === 'Yes/No');
		});
	}

	setItem(e, type, groupName) {
		console.log({ e, type });

		if (e !== null) {
			// setTimeout(() => this.bf.bigForm.get(`current_environment.${type}`)?.patchValue(e), 1000);
			setTimeout(() => this.bf.bigForm.get(`current_environment.${type}`)?.patchValue(this.nameLookup(e, 'description', of(this[groupName]))), 1000);
		}
	}

	nameLookup(targetId: number, field: string, source$: Observable<any>) {
		// Used to look up the name that corresponds to an id in an observable
		let res: any[] = [];
		const tempSub: Subscription = source$
			.pipe(
				map(obj => {
					console.log({ obj });
					return Object.values(obj.options).map(entry => ({ id: entry['id'], description: entry[field] }));
				})
			)
			.subscribe(testData => {
				res = testData;
				const resPos = this.findPos(targetId, res);
				res = res[resPos];
			});

		if (tempSub) {
			tempSub.unsubscribe();
		}

		return res !== undefined ? res : {};
	}
	//
	//find the position of the id in the look up array
	findPos(id: any, arr: any[], field = 'id'): number {
		let res = -1;
		if (arr !== undefined) {
			let count = 0;
			arr.forEach(item => {
				if (item[field] === id || item === id) {
					res = count;
				}
				count++;
			});
		}
		return res;
	}
	typeofTVDecisionHandler(typeoftv: any) {
		const cur_envctrl = this.bf.getControl('current_environment');
		this.tvnumbersub = cur_envctrl.valueChanges
			.pipe(
				map(values => {
					if (values.no_of_tvs.id === 1) {
						this.typeOfTVs = {
							description: 'Current TV type',
							options: [
								{ id: 1, description: 'Flat Screen' },
								{ id: 2, description: 'Box Tube TV' }
							]
						};
						this._cd.detectChanges();
					} else {
						this.typeOfTVs = {
							description: 'Current TV type',
							options: [
								{ id: 1, description: 'Flat Screen' },
								{ id: 2, description: 'Box Tube TV' },
								{ id: 3, description: 'Both' }
							]
						};
						this._cd.detectChanges();
					}
					return values;
				})
			)
			.subscribe();
		// const currentenv_ctrl = this.bf.bigForm.controls['current_environment'];
	}

	ngOnDestroy() {
		if (this.tvnumbersub) {
			this.tvnumbersub.unsubscribe();
		}
	}
}
