import { AllInfoEffects } from "./all-info/all-info.effect";
import { BillingEffects } from "./billing";
import { GlobalEffects } from "./global/global.effects";
import { IdentityEffects } from "./identity";
import { MCAutopayInvoicingEffects } from "./mc-autopay-invoicing";
import { OfflineEffects } from "./offline";
import { RemindersEffects } from "./reminders";
import { SelectedContextEffects } from "./selected-context";
import { SPJobPingsEffects } from "./sp-job-pings";
import { SPOwnJobEffects } from "./sp-own-job";
import { SPUsersManagementEffects } from "./sp-user-management";


export const appEffects: any[] = [
  AllInfoEffects,
  GlobalEffects,
  OfflineEffects,
  IdentityEffects,
  SelectedContextEffects,
  SPJobPingsEffects,
  SPOwnJobEffects,
  SPUsersManagementEffects,
  MCAutopayInvoicingEffects,
  BillingEffects,
  RemindersEffects,
];

