import { Action } from '@ngrx/store';

export const CHECK_AVAILABILITY = '[Check Availablity] Check For Available Jobs';
export const CHECK_AVAILABILITY_SUCCESS = '[Check Availablity] Check For Available Jobs Success';
export const CHECK_AVAILABILITY_STORE_RESULT = '[Check Availablity] Check For Available Jobs Store Results';
export const CHECK_AVAILABILITY_FAIL = '[Check Availablity] Check For Available Jobs Fail';

export const GET_FILTERED_JOBS = '[Filtered Jobs] Get Filtered Jobs';
export const GET_FILTERED_JOBS_SUCCESS = '[Filtered Jobs] Get Filtered Jobs Success';
export const GET_FILTERED_JOBS_FAIL = '[Filtered Jobs] Get Filtered Jobs Fail';

export const GET_AVAILABLE_JOBS = '[Available Jobs] Get Available Jobs';
export const GET_AVAILABLE_JOBS_SUCCESS = '[Available Jobs] Get Available Jobs Success';
export const GET_AVAILABLE_JOBS_FAIL = '[Available Jobs] Get Available Jobs Fail';

export const SELECT_AVAILABLE_JOB = '[Selected Job] Select Available Job';

export const RESPOND_TO_JOB = '[Respond to Job] Respond to Job';
export const RESPOND_TO_JOB_SUCCESS = '[Respond to Job] Respond to Job Success';
export const RESPOND_TO_JOB_FAIL = '[Respond to Job] Respond to Job Fail';

export const RESPOND_TO_JOBS = '[Respond to Jobs] Respond to Jobs';
export const RESPOND_TO_JOBS_SUCCESS = '[Respond to Jobs] Respond to Jobs Success';
export const RESPOND_TO_JOBS_FAIL = '[Respond to Jobs] Respond to Jobs Fail';

export const REMOVE_JOB_RESPONSE = '[Job Responses] Remove Job Response';
export const REMOVE_JOB_RESPONSE_SUCCESS = '[Job Responses] Remove Job Response Successful';
export const REMOVE_JOB_RESPONSE_FAIL = '[Job Responses] Remove Job Response Fail';

export const REMOVE_ALL_JOB_RESPONSES = '[Job Responses] Remove All Job Responses';
export const REMOVE_ALL_JOB_RESPONSES_SUCCESS = '[Job Responses] Remove All Job Responses Successful';
export const REMOVE_ALL_JOB_RESPONSES_FAIL = '[Job Responses] Remove All Job Responses Fail';

export const CHECK_FOR_JOBS_AWARDED = '[Check Jobs Awarded] Check For Jobs Awarded';
export const CHECK_FOR_JOBS_AWARDED_SUCCESS = '[Check Jobs Awarded] Check For Jobs Awarded Success';
export const CHECK_FOR_JOBS_AWARDED_FAIL = '[Check Jobs Awarded] Check For Jobs Awarded Fail';

export const CHECK_FOR_JOBS_LOST = '[Check Jobs Lost] Check For Jobs Lost';
export const CHECK_FOR_JOBS_LOST_SUCCESS = '[Check Jobs Lost] Check For Jobs Lost Success';
export const CHECK_FOR_JOBS_LOST_FAIL = '[Check Jobs Lost] Check For Jobs Lost Fail';

export const GET_JOB_RESPONSES = '[Job Responses] Get Job Responses';
export const GET_JOB_RESPONSES_SUCCESS = '[Job Responses] Get Job Responses Successful';
export const GET_JOB_RESPONSES_FAIL = '[Job Responses] Get Job Responses Fail';

export const PING_MODAL_SHOWN = '[Ping Modal] Ping Modal Shown';
export const SHOW_PING_MODAL = '[Ping Modal] Show Ping Modal';

export const JOB_NOTIFICATION_MODAL_SHOWN = '[Ping Modal] Job Notification Modal Shown';
export const SHOW_JOB_NOTIFICATION_MODAL = '[Ping Modal] Show Job Notification Modal ';

export const CLEAR_IGNORED_JOB_RESPONSES = '[Job Responses] Clear Ignored Job Responses';
export const CLEAR_IGNORED_JOB_RESPONSES_SUCCESS = '[Job Responses] Clear Ignored Job Responses Successful';
export const CLEAR_IGNORED_JOB_RESPONSES_FAIL = '[Job Responses] Clear Ignored Job Responses Fail';

export class CheckAvailability implements Action {
	readonly type = CHECK_AVAILABILITY;
	constructor() {}
}

export class CheckAvailabilitySuccess implements Action {
	readonly type = CHECK_AVAILABILITY_SUCCESS;
	constructor(public payload: any) {}
}

export class CheckAvailabilityStoreResults implements Action {
	readonly type = CHECK_AVAILABILITY_STORE_RESULT;
	constructor(public payload: any) {}
}

export class CheckAvailabilityFail implements Action {
	readonly type = CHECK_AVAILABILITY_FAIL;
	constructor(public payload: any) {}
}

export class GetFilteredJobs implements Action {
	readonly type = GET_FILTERED_JOBS;
}

export class GetFilteredJobsSuccess implements Action {
	readonly type = GET_FILTERED_JOBS_SUCCESS;
	constructor(public payload: any) {}
}

export class GetFilteredJobsFail implements Action {
	readonly type = GET_FILTERED_JOBS_FAIL;
	constructor(public payload: any) {}
}

export class GetAvailableJobs implements Action {
	readonly type = GET_AVAILABLE_JOBS;
}

export class GetAvailableJobsSuccess implements Action {
	readonly type = GET_AVAILABLE_JOBS_SUCCESS;
	constructor(public payload: any) {}
}

export class GetAvailableJobsFail implements Action {
	readonly type = GET_AVAILABLE_JOBS_FAIL;
	constructor(public payload: any) {}
}

export class SelectAvailableJob implements Action {
	readonly type = SELECT_AVAILABLE_JOB;
	constructor(public payload: any) {}
}

export class CheckForJobsAwarded implements Action {
	readonly type = CHECK_FOR_JOBS_AWARDED;
	constructor(public allawardedjobs: any[]) {}
}

export class CheckForJobsAwardedSuccess implements Action {
	readonly type = CHECK_FOR_JOBS_AWARDED_SUCCESS;
	constructor(public payload: any[]) {}
}

export class CheckForJobsAwardedFail implements Action {
	readonly type = CHECK_FOR_JOBS_AWARDED_FAIL;
	constructor(public payload: any) {}
}

export class CheckForJobsLost implements Action {
	readonly type = CHECK_FOR_JOBS_LOST;
	constructor(public allawardedjobs: number[]) {}
}

export class CheckForJobsLostSuccess implements Action {
	readonly type = CHECK_FOR_JOBS_LOST_SUCCESS;
	constructor(public payload: any[]) {}
}

export class CheckForJobsLostFail implements Action {
	readonly type = CHECK_FOR_JOBS_LOST_FAIL;
	constructor(public payload: any) {}
}

export class RespondToJob implements Action {
	readonly type = RESPOND_TO_JOB;
	constructor(public payload: any) {}
}

export class RespondToJobSuccess implements Action {
	readonly type = RESPOND_TO_JOB_SUCCESS;
	constructor(public payload: any) {}
}

export class RespondToJobFail implements Action {
	readonly type = RESPOND_TO_JOB_FAIL;
	constructor(public payload: any) {}
}

export class RespondToJobs implements Action {
	readonly type = RESPOND_TO_JOBS;
	constructor(public payload: any[]) {}
}

export class RespondToJobsSuccess implements Action {
	readonly type = RESPOND_TO_JOBS_SUCCESS;
	constructor(public payload: any[]) {}
}

export class RespondToJobsFail implements Action {
	readonly type = RESPOND_TO_JOBS_FAIL;
	constructor(public payload: any) {}
}

export class GetJobResponses implements Action {
	readonly type = GET_JOB_RESPONSES;
	constructor() {}
}

export class GetJobResponsesSuccess implements Action {
	readonly type = GET_JOB_RESPONSES_SUCCESS;
	constructor(public payload: any) {}
}

export class GetJobResponsesFail implements Action {
	readonly type = GET_JOB_RESPONSES_FAIL;
	constructor(public payload: any) {}
}

export class RemoveJobResponse implements Action {
	readonly type = REMOVE_JOB_RESPONSE;
	constructor(public payload: any) {}
}

export class RemoveJobResponseSuccess implements Action {
	readonly type = REMOVE_JOB_RESPONSE_SUCCESS;
	constructor(public payload: any) {}
}

export class RemoveJobResponseFail implements Action {
	readonly type = REMOVE_JOB_RESPONSE_FAIL;
	constructor(public payload: any) {}
}

export class RemoveAllJobResponses implements Action {
	readonly type = REMOVE_ALL_JOB_RESPONSES;
	constructor() {}
}

export class RemoveAllJobResponsesSuccess implements Action {
	readonly type = REMOVE_ALL_JOB_RESPONSES_SUCCESS;
	constructor(public payload: any) {}
}

export class RemoveAllJobResponsesFail implements Action {
	readonly type = REMOVE_ALL_JOB_RESPONSES_FAIL;
	constructor(public payload: any) {}
}

export class PingModalShown implements Action {
	readonly type = PING_MODAL_SHOWN;
	constructor() {}
}

export class ShowPingModal implements Action {
	readonly type = SHOW_PING_MODAL;
	constructor() {}
}

export class JobNotificationsModalShown implements Action {
	readonly type = JOB_NOTIFICATION_MODAL_SHOWN;
	constructor() {}
}

export class ShowJobNotificationsModal implements Action {
	readonly type = SHOW_JOB_NOTIFICATION_MODAL;
	constructor() {}
}

export class ClearIgnoredJobResponses implements Action {
	readonly type = CLEAR_IGNORED_JOB_RESPONSES;
	constructor() {}
}

export class ClearIgnoredJobResponsesSuccess implements Action {
	readonly type = CLEAR_IGNORED_JOB_RESPONSES_SUCCESS;
	constructor(public payload: any) {}
}

export class ClearIgnoredJobResponsesFail implements Action {
	readonly type = CLEAR_IGNORED_JOB_RESPONSES_FAIL;
	constructor(public payload: any) {}
}

export type JobPingsActions =
	| CheckAvailability
	| CheckAvailabilitySuccess
	| CheckAvailabilityStoreResults
	| CheckAvailabilityFail
	| GetFilteredJobs
	| GetFilteredJobsSuccess
	| GetFilteredJobsFail
	| GetAvailableJobs
	| GetAvailableJobsSuccess
	| GetAvailableJobsFail
	| SelectAvailableJob
	| CheckForJobsAwarded
	| CheckForJobsAwardedSuccess
	| CheckForJobsAwardedFail
	| CheckForJobsLost
	| CheckForJobsLostSuccess
	| CheckForJobsLostFail
	| RespondToJob
	| RespondToJobFail
	| RespondToJobSuccess
	| RespondToJobs
	| RespondToJobsSuccess
	| RespondToJobsFail
	| RemoveJobResponse
	| RemoveJobResponseFail
	| RemoveJobResponseSuccess
	| RemoveAllJobResponses
	| RemoveAllJobResponsesFail
	| RemoveAllJobResponsesSuccess
	| GetJobResponses
	| GetJobResponsesSuccess
	| GetJobResponsesFail
	| PingModalShown
	| ShowPingModal
	| ShowPingModal
	| JobNotificationsModalShown
	| ShowJobNotificationsModal
	| ClearIgnoredJobResponses
	| ClearIgnoredJobResponsesSuccess
	| ClearIgnoredJobResponsesFail;
