import { DateFormats } from './date-formats';

export const FLX_NATIVE_DATE_FORMATS: DateFormats = {
	parse: {
		dateInput: null
	},
	display: {
		dateInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
		monthYearLabel: { year: 'numeric', month: 'short' },
		dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
		monthYearA11yLabel: { year: 'numeric', month: 'long' }
	}
};
