/* eslint-disable @typescript-eslint/no-explicit-any */
import { SpClaimWorkflow } from './configs/workflow/mul-sp-claim-workflow';
import { Manifest_0_0_2, getCurrentUser } from '@flexus/core';
import { MulSpSearch } from './configs/search/mul-sp-search';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { EditNewJobCard } from './configs/add-new-job-card';
import { FLXAppMenuComponent } from '../../app-shell-features/app-menu/app-menu.component';
import { Roles } from '../../sp_globals/sp-user.roles';

// Orgs
import * as MulSPStates from './configs/flows';
import { SP_GLOBAL_STATES } from '../../sp_globals/configs/flows';
import { jobPermissions } from '../../sp_globals/configs/permissions/job.permissions';
import { applySilVirtualStates } from '../../sp_globals/sp-virtual-state.helper';
import { environment } from '../../../environments/environment';
import { ManageSPUsers } from './configs/users';
import { AddNewJobCard } from './configs/add-new-job-card';
import { MulSPInvoicing } from './configs/invoicing-payments/mul-invoicing-payments';
import { MulSpDashboard } from './configs/sp-dashboard/sp-dashboard';

export const mul_sp: Manifest_0_0_2 = {
	version: '0.0.2',
	orgKey: environment.client,
	settings: {
		addActionPanel: true,
		addAppBar: true,
		addAppMenu: true,
		addFooter: true,
		addHeaderActions: true,
		addSearchBanner: true,
		addReminder: true
	},
	appMenu: (instance: FLXAppMenuComponent, store: Store<any>) => {
		return store.select(getCurrentUser).pipe(
			filter(user => !!user),
			map(res => {
				const createRoles = [Roles.SP_JOB_SCHEDULER];
				const menuItems = [
					{ id: 1, name: 'Workflow', routerLink: '/workflow', iconType: 'app-menu-workflow' }
					// { id: 11, name: 'Chart', routerLink: '/flow-chart', iconType: 'app-menu-workflow,' }
				]; //

				if (res?.user.roles.some(role => createRoles.includes(role))) {
					menuItems.push({
						id: 2,
						name: 'Staff',
						routerLink: '/workflow/detail',
						iconType: 'multiple-users'
					});
				}
				// TODO: remove below for MC pre-autopay PRs
				// if (res?.user.roles.some(role => createRoles.includes(role))) {
				// 	menuItems.push({
				// 		id: 3,
				// 		name: 'Invoices',
				// 		routerLink: '/workflow/detail',
				// 		iconType: 'invoicing-payments'
				// 	});
				// }
				if (res?.user.roles.some(role => createRoles.includes(role))) {
					menuItems.push({
						id: 3,
						name: 'ISOI',
						routerLink: '/workflow/detail',
						iconType: 'app-menu-new-claim'
					});
				}

				return menuItems;
			})
		);
	},
	virtualStatesFunction: applySilVirtualStates,
	appSearch: MulSpSearch,
	permissions: {
		itemTwo: jobPermissions
	},
	manifestItems: {
		...SP_GLOBAL_STATES,
		// manage_users: ManageSPUsers,
		manage_users: ManageSPUsers,
		add_new_jobcard: AddNewJobCard,
		invoices_payments: MulSPInvoicing,
		sp_dashboard: MulSpDashboard,
		// ...sil.manifestItems, // PLEASE JUST FOR TESTING PURPOSES; REMOVE BEFORE DEPLOY
		// 8: EditNewJobCard,
		21: MulSPStates.MUL_21,
		41: MulSPStates.MUL_41,
		26: MulSPStates.MUL_26,
		42: MulSPStates.MUL_42,
		62: MulSPStates.MUL_62,
		80: MulSPStates.MUL_80,
		190: MulSPStates.MUL_190,
		208: MulSPStates.MUL_208,
		249: MulSPStates.MUL_249,
		251: MulSPStates.MUL_251,
		256: MulSPStates.MUL_256,
		270: MulSPStates.MUL_270,
		280: MulSPStates.MUL_280,
		298: MulSPStates.MUL_298,
		325: EditNewJobCard,
		333: MulSPStates.MUL_333,
		336: MulSPStates.MUL_336,
		338: MulSPStates.MUL_338,
		339: MulSPStates.MUL_339,
		workflow: SpClaimWorkflow
	}
};
/*
	organizations: {
		sil: {
			manifestItems: {
				...pickFromStates(sil.manifestItems, [27, 21]),
				...cheripickFromStates(sil.manifestItems, [14, 15], ['header', 'instructions']),
				...cheripickFromStates(sil.manifestItems, [24, 25], ['footer'])
			}
		},
		mul: {
			manifestItems: {
				...pickFromStates(sil.manifestItems, [27, 21]),
				...cheripickFromStates(sil.manifestItems, [14, 15], ['header', 'instructions']),
				...cheripickFromStates(sil.manifestItems, [24, 25], ['footer'])
			}
		}
	}
*/
