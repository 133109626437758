import { Component, Inject, Input, OnInit } from '@angular/core';
import { RemoveJobResponse } from '@flexus/core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'flx-job-notification-detail',
	templateUrl: 'sp-job-notification-detail.component.html',
	styleUrls: ['sp-job-notification-detail.component.scss']
})
export class FLXJobNotificationDetailComponent implements OnInit {
	private _job!: any;
	public _vouchertype_skill;
	private _client = this.environment.client;

	@Input()
	set notificationDetails(job: any) {
		this._job = job;
	}
	get notificationDetails() {
		return this._job;
	}
	constructor(private store: Store, @Inject('environment') private environment: any) {}

	ngOnInit() {
    this._client = this.environment.client;
		if (this._client !== 'pgg_sp') {
			this._vouchertype_skill = this.notificationDetails?.job?.skill;
		} else {
			this._vouchertype_skill = this.notificationDetails?.job?.voucher;
		}
	}

	removeNotification() {
		this.store.dispatch(new RemoveJobResponse(this._job.id));
	}
}
