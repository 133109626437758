import { addObjectProperty } from '@flexus/utilities';
import * as contextActions from './selected-context.actions';

import { sort, descend } from 'ramda';
 export interface SelectedContextState {
	selectedItem: any;
	selectedItemOne: any;
	selectedItemTwo: any;
	relatedItemTwos: any[];
	errors: { [key: string]: any };
	errorMessages: { [key: string]: string };
	retryCalls: { [key: string]: any };
	fullItemOne: any;
	fullItemTwo: any;
	submissionData: any;
	alertMessages: string[];
	latestTwenty: any;
}

export const initialState: SelectedContextState = {
	selectedItem: null,
	selectedItemOne: null,
	selectedItemTwo: null,
	relatedItemTwos: null,
	errors: {},
	errorMessages: {},
	retryCalls: {},
	fullItemOne: null,
	fullItemTwo: null,
	submissionData: null,
	alertMessages: [],
	latestTwenty: null
};

export function selectedContextReducer(state = initialState, action: contextActions.selectedContextActions | any) {
	switch (action.type) {
		// TODO: Refactor later
		case contextActions.MAKE_SERVER_CALL_SUCCESS: {
			const { dataKey, result } = action.payload;

			// Remove related error, if it exists
			const { [dataKey]: removedError, ...errors } = state.errors;
			const { [dataKey]: removedMessage, ...errorMessages } = state.errorMessages;
			const { [dataKey]: removedCall, ...retryCalls } = state.retryCalls;

			const files = result;
			let quotations, latestQuotation, photos, reports, agreement_of_loss_letters, latestReport, invoices, latestInvoice;
			const latestDocuments = [];

			// If files, split them up
			if (dataKey === 'files') {
				const purposeLimitTo = purpose => file => file.purpose?.toLowerCase()?.includes(purpose);
				const fileTypeLimitTo = type => file => file.filename?.toLowerCase()?.includes(type);
				// quotations
				quotations = [...files.filter(purposeLimitTo('quotation')), ...files.filter(purposeLimitTo('quote'))].map((quote: any) => {
					let quoWithExtension = {};
					if (!quote.filename.includes('.pdf')) {
						quoWithExtension = addObjectProperty('filename', quote.filename + '.pdf', quote);
						return quoWithExtension;
					}
					return quote;
				});
				// latest quotation
				latestQuotation = sort(descend((f: any) => new Date(f.created)))(quotations)[0];

				// Report
				// newReport = (files as any[]).reduce((acc, file) => {
				// 	if (purposeLimitTo('loss_adjuster_team_lead_report')) {
				// 		if (!file.filename.includes('.pdf')) {
				// 			return [...acc, addObjectProperty('filename', file.filename + '.pdf', file)];
				// 		}
				// 		return [...acc, file];
				// 	}
				// 	return [...acc, file];
				// }, []);

				// Invoices
				invoices = files.filter(purposeLimitTo('invoice')).map((inv: any) => {
					let invWithExtension = {};
					if (!inv.filename.includes('.pdf')) {
						invWithExtension = addObjectProperty('filename', inv.filename + '.pdf', inv);
						return invWithExtension;
					}
					return inv;
				});
				latestInvoice = sort(descend((f: any) => new Date(f.created)))(invoices)[0];

				// photos
				photos = [...files.filter(fileTypeLimitTo('.jpg')), ...files.filter(fileTypeLimitTo('.png'))];

				//check for reports with no pdf in name
				const extraRep = files.filter(purposeLimitTo('report')).map((rep: any) => {
					let repWithExtension = {};
					if (!rep.filename.includes('.pdf')) {
						repWithExtension = addObjectProperty('filename', rep.filename + '.pdf', rep);
						return repWithExtension;
					}
					return rep;
				});
				// console.log({ extraRep });
				// reports
				// reports = files.filter(
				// 	file => file.filename?.toLowerCase()?.includes('.pdf') && !(file.purpose?.toLowerCase()?.includes('quot') || file.purpose.toLowerCase().includes('invoice'))
				// );

				// Removed Invoice from the report ::: 'invoice',
				reports = files
					.filter(
						file =>
							['report', 'coc_certificate', 'repudiation_letter'].some(purpose => file.purpose.toLowerCase().includes(purpose)) && !file.purpose?.toLowerCase()?.includes('quot')
					)
					.map(file => (!file.filename.includes('.') ? { ...file, filename: `${file.filename}.pdf` } : file));

				// Get and format AOL filenames
				agreement_of_loss_letters = files.filter(purposeLimitTo('agreement of loss')).map((aol: any) => {
					let aolWithExtension = {};
					if (!aol.filename.includes('.pdf')) {
						aolWithExtension = addObjectProperty('filename', aol.filename + '.pdf', aol);
						return aolWithExtension;
					}
					return aol;
				});

				// Get and format AOL filenames
				// loss_adjuster_team_lead_report = files.filter(purposeLimitTo('report')).map((aol: any) => {
				// 	let aolWithExtension = {};
				// 	if (!aol.filename.includes('.pdf')) {
				// 		aolWithExtension = addObjectProperty('filename', aol.filename + '.pdf', aol);
				// 		return aolWithExtension;
				// 	}
				// 	return aol;
				// });

				// const latest_aol_letter = sort(descend(f => new Date(f.created)))(agreement_of_loss_letters)[0];

				// Including AOL letters to be included in reports displayed
				reports = [...reports, ...agreement_of_loss_letters];

				// latest report
				latestReport = sort(descend((f: any) => new Date(f.created)))(reports)[0];

				// Latest report and quote
				if (!!latestReport) {
					latestDocuments.push(latestReport);
				}
				if (!!latestInvoice) {
					latestDocuments.push(latestInvoice);
				}
				if (!!latestQuotation) {
					latestDocuments.push(latestQuotation);
				}
				// console.log('latest report ', latestReport);
				return {
					...state,
					files,
					quotations,
					latestQuotation,
					reports,
					latestReport,
					invoices,
					latestInvoice,
					latestDocuments,
					photos,
					errors,
					errorMessages,
					retryCalls
				};
			}
			if (dataKey === 'photos') {
				const fileTypeLimitTo = type => file => file.filename?.toLowerCase()?.includes(type);
				photos = [...files.filter(fileTypeLimitTo('.jpg')), ...files?.filter(fileTypeLimitTo('.png'))];
				return {
					...state,
					photos,
					errors,
					errorMessages,
					retryCalls
				};
			}

			return {
				...state,
				errors,
				errorMessages,
				retryCalls,
				[dataKey]: result
			};
		}
		case contextActions.MAKE_SERVER_CALL_FAIL: {
			const { dataKey, error, errorMessage, retryCall } = action.payload;
			const errors = { ...state.errors, [dataKey]: error };
			const errorMessages = { ...state.errorMessages, [dataKey]: errorMessage };
			const retryCalls = { ...state.retryCalls, [dataKey]: retryCall };
			return {
				...state,
				errors,
				errorMessages,
				retryCalls
			};
		}
		case contextActions.UPDATE_INFLOW_WITH_FORM_DATA: {
			const { storeKey, data } = action.payload;
			return {
				...state,
				[storeKey]: {
					...data
				}
			};
		}

		case contextActions.WRITE_DATA_TO_STORE: {
			const submissionData = action.payload;
			return {
				...state,
				submissionData
			};
		}

		case contextActions.INITIALIZE_TEMP_DATA: {
			return {
				...initialState
			};
		}
		case 'ROUTER_NAVIGATED': {
			const {
				event: { urlAfterRedirects }
			} = action.payload;
			if (urlAfterRedirects === '/workflow' || urlAfterRedirects === '/workflow/chart') {
				return initialState;
			}
			return state;
		}

		case contextActions.SELECT_ITEM_ONE: {
			const { itemOne: claim } = action.payload;
			return {
				...state,
				selectedItemOne: claim,
				selectedItemTwo: null,
				selectedItem: claim
			};
		}
		case contextActions.SELECT_ITEM_TWO: {
			const { itemTwo, itemOne, setRelated } = action.payload;
			let toReturn = {
				...state,
				selectedItemOne: itemOne,
				selectedItemTwo: { ...itemTwo },
				selectedItem: itemTwo
			};

			if (setRelated) {
				const relatedItemTwos = itemOne?.jobs.filter(j => j.id !== itemTwo?.id);
				toReturn = { ...toReturn, relatedItemTwos };
			}

			return toReturn;
		}
		case contextActions.GET_FULL_ITEM_ONE_SUCCESS:
		case contextActions.GET_FULL_ITEM_ONE_WITH_FULL_ITEM_TWOS_SUCCESS: {
			let { fullItemOne } = action.payload;

			if (!fullItemOne.loan_information) {
				fullItemOne = { loan_information: {}, ...fullItemOne };
			}

			return {
				...state,
				fullItemOne,
				errors: { ...state.errors, getFullClaim: null }
			};
		}

		case contextActions.GET_FULL_ITEM_TWO_SUCCESS: {
			let { fullItemTwo } = action.payload;

			if (!fullItemTwo.job_information) {
				fullItemTwo = { job_information: {}, ...fullItemTwo };
			}

			return {
				...state,
				fullItemTwo,
				errors: {
					...state.errors,
					getFullJob: null
				}
			};
		}

		case contextActions.SET_RELATED_ITEMS: {
			const { relatedItemTwos } = action.payload;

			return {
				...state,
				relatedItemTwos
			};
		}

		case contextActions.MANUALLY_SET_MOCK_CONTEXT_DATA: {
			const data = action.payload;
			return {
				...state,
				...data
			};
		}

		case 'LOGOUT_SUCCESS': {
			return initialState;
		}

		default: {
			return state;
		}
	}
}
