import { Flow_0_0_2, getFullItemOne, getCurrentUser } from '@flexus/core';
import { filter, take, pluck, skipWhile, first, map } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { UntypedFormControl } from '@angular/forms';

export const PGG_197: Flow_0_0_2 = {
	id: '197',
	name: 'unallocated_afterhours_claim',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Claim Created After Hours - Unallocated: ${
							itemOne?.loan_information?.mavenclaimnumber ? itemOne?.loan_information?.mavenclaimnumber : itemOne?.loan_information?.mavenclaimnumber
						} - ${itemOne.applicant?.surname}`;
					} else {
						return 'Claim Created After Hours - Unallocated';
					}
				})
			);
		},
		controls: () => () => []
	},
	actionPanel: instance =>
		setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp', 'sp-details'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Allocate Claim'
		},
		viewRoles: {
			0: 'Allocate Claim'
		}
	},
	startNode: 'ConfirmClaim',
	nodes: {
		ConfirmClaim: {
			component: 'FileViewWithExtraComponent',
			serverCalls: {
				copyText: {
					errorMessage: 'Cannot return installation claim number!',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map((vals: any) => {
								return { key: 'INSTALLATION NO:', value: vals.mid };
							})
						);
					}
				},
				keyValueList: {
					errorMessage: 'Cannot get retrieve data',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map((vals: any) => {
								const res = {
									'Claim Type': vals && vals.loan_information ? vals.loan_information.claimtype : '',
									Address:
										vals && vals.loan_information
											? vals.loan_information.propertystreetaddress + ', ' + vals.loan_information.propertysuburb + ', ' + vals.loan_information.propertycity
											: '',
									'Client Name': vals && vals.applicant ? vals.applicant?.first_name + ' ' + vals.applicant?.surname : '',
									'Contact Number': vals && vals.loan_information ? vals.loan_information.contactnumber : '',
									'Cell Number': vals && vals.loan_information ? vals.loan_information.cellnumber : '',
									'What Matters': vals && vals.loan_information ? vals.loan_information.whatmatters : ''
								};
								return [res];
							})
						);
					}
				}
			},
			initFormFields: (bf, item, inst, sq, store) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(1));
				store
					.select(getCurrentUser)
					.pipe(
						skipWhile(x => !x),
						pluck('user', 'id'),
						take(1)
					)
					.subscribe(id => {
						bf.bigForm.addControl('handler', new UntypedFormControl(id));
					});
			},
			inputs: {
				inputHeading: 'This Installation has been allocated to you',
				subHeading: 'This claim was created after hours by the 4-Sure team.',
				fileDoesNotExistMessage: '',
				instructions: [['This is not an installation type or province that is managed by 4-sure.']]
			},
			navs: [
				{
					text: 'Acknowledge Claim',
					serverFirst: true,
					color: 'primary',
					linkType: 'submit',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							serviceVariable: 'pggService',
							functionName: 'updateClaim',
							errorMessage: 'Could not assign handler!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			serverCalls: {
				copyText: {
					errorMessage: 'Cannot return installation number',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map((vals: any) => {
								return vals.mid;
							})
						);
					}
				}
			},
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemOne.id': 'claim_id',
		handler: 'new_handler'
		// handler: ['loan_information.handler', 'new_handler'],
	}
};
