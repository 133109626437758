import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BigFormService, getActiveOrganization, getManifestSearchData, IndexedDbService, ManifestController } from '@flexus/core';
import moment from 'moment';
import { getFilterData, SearchOnServer, SetFilterDataField } from '@flexus/core';
import { Store } from '@ngrx/store';
import { forkJoin, Subscription } from 'rxjs';
import { pluck, take } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'flx-recent-activity',
	templateUrl: './recent-activity.component.html',
	styleUrls: ['./recent-activity.component.scss']
})
export class FLXRecentActivityComponent implements OnInit, OnDestroy {
	private _configuration = {} as any;
	private _configSubscription: Subscription;
	private _searchStoreSubscription: Subscription;
	private _activeOrg;

	entries = [];
	constructor(
		private indexDbService: IndexedDbService,
		private store: Store<any>,
		private controller: ManifestController<any>,
		private cdr: ChangeDetectorRef,
		public bf: BigFormService
	) {}

	ngOnInit() {
		this.bf.addControl('search', new UntypedFormControl({ checkClosed: false, search: '' }));
		this.indexDbService.user
			.toArray()
			.then(user => {
				return +user;
			})
			.then(userID => {
				this.indexDbService.recent_activity
					.where('userID')
					.equals(userID)
					.reverse()
					.sortBy('access_date')
					.then(data => {
						if (data) {
							this.entries = data.map(e => {
								return {
									claim_num: e.claim_num,
									applicant: e.applicant,
									access_date: moment(e.access_date)?.calendar()
								};
							});
							this.cdr.detectChanges();
						}
					});
			});

		this.controller
			.select(getActiveOrganization)
			.pipe(take(1))
			.subscribe(org => (this._activeOrg = org));

		this._configSubscription = forkJoin([
			this.controller.select(getManifestSearchData)?.pipe(take(1)),
			this.store.pipe(take(1)),
			this.controller.stateObservable.pipe(take(1))
		])?.subscribe(([searchFunc, store, controller]: any) => {
			this._configuration = searchFunc(store, controller);
		});

		this._searchStoreSubscription = this.store
			.select(getFilterData)
			.pipe(pluck('searchField'))
			.subscribe((searchField: any) => {
				if (searchField !== undefined && searchField.inSearch !== undefined && searchField.checkClosed !== undefined) {
					this.bf.bigForm.patchValue({ search: searchField });
				}
			});
		this.cdr.detectChanges();
	}

	doSearch(entry: any) {
		if (entry.claim_num !== 'N/A') {
			this.store.dispatch(new SetFilterDataField({ key: 'searchField', data: { inSearch: entry.claim_num, checkClosed: true } }));
			this.store.dispatch(
				new SearchOnServer({
					endpoint: this._configuration.searchEndpoint,
					active: true,
					search: entry.claim_num,
					virtualStatesFunction: this._activeOrg.virtualStatesFunction
				})
			);
			this.cdr.detectChanges();
		}
	}

	ngOnDestroy() {
		this._configSubscription.unsubscribe();
		this._searchStoreSubscription.unsubscribe();
	}
}
