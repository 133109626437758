import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterFiles'
})
export class FilterFilesPipe implements PipeTransform {
	transform(values: any[], arg: string) {
		if (!arg || arg === 'all') {
			return values;
		}
		return values.filter(f => f.purpose === arg);
	}
}
