import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { FLXDisplayReminderComponent } from '@flexus/ui-composites';
import { map, delay, take, skipWhile, filter, share, switchMap } from 'rxjs/operators';
import { Subscription, Observable, from } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
	getActiveNode,
	getActiveManifestItem,
	getIsAuthenticated,
	NetworkService,
	getLoadingState,
	BigFormService,
	ModalService,
	ManifestController,
	ReminderService,
	deleteActiveReminder,
	getCurrentActiveReminders,
	getSettings,
	IndexedDbService,
	StoreQuery,
	getReleaseNote
} from '@flexus/core';
import { SetTheme } from '@flexus/ux';
import { FLXModalComponent } from '@flexus/ui-elements';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { FlxLoaderService } from './sp_globals/services';
import { HttpClient } from '@angular/common/http';
import { HideActionPanel, LoadActionPanelComponent, ShowActionPanel, getActionPanelExpanded, getActionPanelVisible } from './app-shell-features';




@Component({
  selector: 'flx-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  opened = false;
	actionPanelVisible = false;
	actionPanelExpanded: boolean;
	networkCheckSubscription: Subscription;
	loading$: Observable<boolean>;
	manualLoading$: Observable<boolean> = this.loaderService.getLoading();
	isOffline = false;
	localStorage = window.localStorage;
	navDataSubscription: Subscription;
	navs;
	manifestItemSubscription: Subscription;
	manifestItem;
	actionPanelVisibleSub: Subscription;
	actionPanelExpandSub: Subscription;
	routerNavigationSub: Subscription;
	settings: any;

	flexusPreferences: any;

	@ViewChild(FLXModalComponent) modalInstance: FLXModalComponent;
	@ViewChild(FLXDisplayReminderComponent) reminderInstance: FLXDisplayReminderComponent;
	modalSubscription: Subscription;

	snoozeSubs: Subscription[] = [];
	reminderSubscription: Subscription;
	activeRemindersSubscription: Subscription;
	activeReminders = [];
	currentRemindersSubscription: Subscription;
	loaderOptions: { showBackdrop: boolean };

  isSpUserPath: boolean;
  isSpUserPathSub: Subscription;


	constructor(
		private _http: HttpClient,
		private _store: Store<any>,
		private controller: ManifestController<any>,
		private _toastr: ToastrService,
		private sq: StoreQuery,
		private indexedDbService: IndexedDbService,
		private networkService: NetworkService,
		private modalService: ModalService,
		private reminderService: ReminderService,
		private bf: BigFormService,
		private router: Router,
    public route: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		// private swUpdate: SwUpdate, // private serviceWorker: ServiceWorkerService,
		private loaderService: FlxLoaderService
	) {
		const currentTheme = JSON.parse(this.localStorage.getItem('flexusPreferences'));
		if (!currentTheme) {
			this.localStorage.setItem('flexusPreferences', JSON.stringify({ theme: { name: 'blackout' } }));
		}
	}

	ngOnInit() {
		// this.serviceWorker.checkForUpdates();
		// console.log({ workerEnabled: this.swUpdate.isEnabled });
		this.ngOnDestroy();
		this.getSettings();
		// Nav portal to control something in actionpanel
		this.routerNavigationSub = (this.router.events.pipe(filter(evt => evt instanceof NavigationEnd)) as Observable<NavigationEnd>).subscribe(event => {
			if (event) {
        console.log({event})
        const isSpUserPath = event.url.includes('sp-user');
        if (isSpUserPath) {
          this.manifestItem = null;
        }
        console.log(this.isSpUserPath)

				this._store.dispatch({ type: 'ROUTER_NAVIGATED', payload: { event } });
				this.controller.dispatch({ type: 'ROUTER_NAVIGATED', payload: { event } });
			}
		});

		this.manifestItemSubscription = this.controller
			.select(getActiveManifestItem)
			.pipe(skipWhile(item => !item), delay(0))
			.subscribe(item => {
				this.manifestItem = item;
			});

		this.notifyNetworkStatus();
		this.settings && this.settings.addActionPanel && this.getActionPanelState();
		this.setAppShellVisibility();

		this.navDataSubscription = this.controller
			.select(getActiveNode)
			// .pipe(delay(2000))
			.subscribe(node => {
				if (node) {
					const { loaderOptions } = node;
					this.loaderOptions = loaderOptions;
					this.navs = node.navs;
				} else {
					this.navs = null;
				}
			});

		this.flexusPreferences = JSON.parse(this.localStorage.getItem('flexusPreferences'));
		if (this.flexusPreferences) {
			this._store.dispatch(new SetTheme({ name: this.flexusPreferences.theme.name }));
		}
	}

	private getSettings() {
		this.controller
			.select(getSettings)
			.pipe(take(1))
			.subscribe(settings => (this.settings = settings));
	}

	private listenToModal() {
		this.modalSubscription = this.modalService.modalActions.pipe(filter(x => !!x)).subscribe(modalFunc => {
			this.modalInstance.open();
			modalFunc(this.modalInstance, this._store, this.bf, this._http);
			this.cdr.detectChanges();
		});
	}

	ngAfterViewInit() {
		this.listenToModal();
		if (this.settings && this.settings.addReminder) {
			this.reminderService.activateReminders();
			this.listenToReminder();
			this.getActiveReminders();
		}
		this.loading$ = this._store.select(getLoadingState)?.pipe(delay(0));

		if (environment.client === `sil_sp` || environment.client === `sil`) {
			this.showReleaseNotes();
		}
	}

	toggleMenu() {
		this.opened = !this.opened;
	}

	private setAppShellVisibility() {
		this._store
			.select(getIsAuthenticated)
			.pipe(take(1))
			.subscribe(value => {
				// this.appShellVisible = value;
				if (value) {
					this._store.dispatch(new ShowActionPanel());
				} else {
					this._store.dispatch(new HideActionPanel());
				}
			});
	}

	get userIsAuthenticated$(): Observable<boolean> {
		return this._store.select(getIsAuthenticated)?.pipe(share());
	}

	// private socketConnectionForAuthedUser() {
	//   this._store
	//     .select(getIsAuthenticated)
	//     .pipe(
	//       map((authed) => {
	//         if (authed) {
	//           this.socketConsumer.setupSocketConnection();
	//           this.socketConsumer.subscribeToSocket();
	//         }
	//       }),
	//     )
	//     .subscribe();
	// }

	private getActionPanelState() {
		this.actionPanelExpandSub = this._store
			.select(getActionPanelExpanded)
			.pipe(
				map(expanded => !!expanded),
				delay(0)
			)
			.subscribe(value => {
				this.actionPanelExpanded = value;
				this.cdr.markForCheck();
			});
		this.actionPanelVisibleSub = this._store
			.select(getActionPanelVisible)
			.pipe(delay(0))
			.subscribe(actionPanelVisible => {
				this.actionPanelVisible = this.settings && this.settings.addActionPanel && actionPanelVisible;
				this.cdr.markForCheck();
			});
	}

	private notifyNetworkStatus() {
		this.networkCheckSubscription = this.networkService.isOnline.subscribe(isOnline => {
			if (isOnline) {
				this.isOffline = false;
				//this._toastr.success('You are online', 'Network Connection', { timeOut: 2000 });
			} else {
				this.isOffline = true;
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.settings && this.settings.addActionPanel) {
			this.getActionPanelState();
		}
		this.cdr.detectChanges();
	}

	ngOnDestroy() {
		// this.socketConsumer.unsubscribeToSocket();
		if (this.networkCheckSubscription) {
			this.networkCheckSubscription.unsubscribe();
		}
		if (this.navDataSubscription) this.navDataSubscription.unsubscribe();
		if (this.manifestItemSubscription) this.manifestItemSubscription.unsubscribe();
		if (this.modalSubscription) this.modalSubscription.unsubscribe();
		if (this.reminderSubscription) this.reminderSubscription.unsubscribe();
		if (this.activeRemindersSubscription) this.activeRemindersSubscription.unsubscribe();
		if (this.actionPanelVisibleSub) this.actionPanelVisibleSub.unsubscribe();
		if (this.actionPanelExpandSub) this.actionPanelExpandSub.unsubscribe();
		if (this.routerNavigationSub) this.routerNavigationSub.unsubscribe();
		if (this.currentRemindersSubscription) this.currentRemindersSubscription.unsubscribe();
    this.isSpUserPathSub?.unsubscribe();
	}

	// ------------------------------- Reminders functionality -------------------------------
	private listenToReminder() {
		this.reminderSubscription = this.reminderService.reminderActions.pipe(filter(x => !!x)).subscribe(remindersFunc => {
			this.reminderInstance.open();
			remindersFunc(this.reminderInstance, this._store, this.bf);
			this.cdr.detectChanges();
		});
	}

	reminderDisplayCommandHandler(action: any) {
		switch (action.action) {
			case 'snooze':
				this.reminderService.snooze(action.reminder);
				break;
			case 'deleteActive':
				this._store.dispatch(deleteActiveReminder({ id: action.id }));
				break;
			case 'openToClaim':
				this._store.dispatch(new LoadActionPanelComponent('SearchComponent'));
				setTimeout(() => {
					this.bf?.bigForm?.get('search')?.setValue({ checkClosed: false, inSearch: action.claimNo });
					this.reminderService?.doClaimSearch();
				}, 500);
				break;
		}
	}

	getActiveReminders() {
		this.activeRemindersSubscription = this._store.select(getCurrentActiveReminders).subscribe(reminders => {
			const reminderclone = [...reminders];
			this.activeReminders = reminderclone.sort(this.reminderService.sortByDateAndTime);
			if (this.activeReminders.length > 0) {
				this.reminderService.openReminderDirectly((inst, store, bf) => {
					inst.reminderInstances = this.activeReminders;
				});
			}
		});
	}

	showReleaseNotes() {
		this._store
			.select(getIsAuthenticated)
			.pipe(
				filter(isAuthenticated => isAuthenticated === true),
				switchMap(() =>
					// this.sq
					// 	.queryObject(
					// 		gql`
					// 			{
					// 				allInfo {
					// 					config_options {
					// 						desktop {
					// 							web_release_notes_body
					// 							web_release_notes_version
					// 						}
					// 					}
					// 				}
					// 			}
					// 		`,
					// 		this._store
					// 	)
					this._store.select(getReleaseNote)?.pipe(
						filter(({ web_release_notes_version }: { web_release_notes_body: string; web_release_notes_version: string }) => !!web_release_notes_version),
						map(({ web_release_notes_body, web_release_notes_version }: { web_release_notes_body: string; web_release_notes_version: string }) => ({
							releaseNotesVersion: web_release_notes_version ?? undefined,
							releaseNotesBody: web_release_notes_body ?? undefined
						}))
					)
				),
				switchMap((releaseNote: { releaseNotesVersion: string; releaseNotesBody: string }) => {
					return from(this.indexedDbService.releaseNotesVersion.get(1))?.pipe(
						map(release => ({
							...releaseNote,
							currentVersion: release?.versionNumber ?? undefined
						}))
					);
				})
			)
			.subscribe(({ currentVersion, releaseNotesVersion, releaseNotesBody }: { currentVersion: string; releaseNotesVersion: string; releaseNotesBody: string }) => {
				if (!!currentVersion) {
					if (currentVersion !== releaseNotesVersion) {
						this.indexedDbService.releaseNotesVersion.put({
							id: 1,
							versionNumber: releaseNotesVersion
						});
						this.modalService.openModalDirectly(inst => {
							(inst.type = 'info'), (inst.closeButton = 'true');
							inst.setMessage([`<b>Release Notes:  ${releaseNotesVersion}</b>`, releaseNotesBody]);
						});
					}
				} else {
					this.indexedDbService.releaseNotesVersion.delete(1);
					if (releaseNotesVersion) {
						this.indexedDbService.releaseNotesVersion.add({
							id: 1,
							versionNumber: releaseNotesVersion
						});
					}
				}
			});
	}
}
