import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubFlowNodeComponent, DecisionNodeComponent, TemplateComponent } from './components';
import { DcModule } from '../dc';
import { DynamicLoaderModule } from '../dynamic-loader';
import { ManifestController, ManifestHandlers } from '@flexus/core';

@NgModule({
	declarations: [DecisionNodeComponent, TemplateComponent, SubFlowNodeComponent],
	imports: [CommonModule, DcModule, DynamicLoaderModule],
	exports: [DecisionNodeComponent, TemplateComponent, SubFlowNodeComponent],
	providers: [ManifestController, ManifestHandlers]
})
export class ManifestCoreModule {
	constructor(inj: ManifestHandlers) {}
}
