import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit, Renderer2, ElementRef, SimpleChanges, OnChanges, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { getOrgKey, SelectItemTwo, GetFullItemTwo, GetFullItemOne, SetActiveManifestItem, ManifestController } from '@flexus/core';
import { FLXContextMenuComponent, FLXIndicatorComponent } from '@flexus/ui-elements';

@Component({
	selector: 'flx-amp-list-job-card',
	templateUrl: './amp-list-job-card.component.html',
	styleUrls: ['./amp-list-job-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmpListJobCardComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
	@Input() itemTwo: any;
	@Input() itemOne: any;
	@Input() itemTwoContextMenuList = [];
	@Input() user: any;
	@Input() itemTwoPermissions = [];
	@Input() activeOrg;
	@Input() allInfo: any;
	@Input() isOnline: boolean;

	sub: Subscription[] = [];

	@ViewChild(FLXIndicatorComponent, { static: true }) indicator: FLXIndicatorComponent;
	@ViewChild(FLXContextMenuComponent) contextMenu: FLXContextMenuComponent;
	constructor(
		private _store: Store<any>,
		private controller: ManifestController<any>,
		private router: Router,
		private route: ActivatedRoute,
		private renderer: Renderer2,
		private el: ElementRef,
		private ngZone: NgZone
	) {}

	ngOnInit() {}
	ngOnChanges(changes: SimpleChanges) {
		if (changes['itemTwoPermissions']) {
			this.sub.forEach(s => {
				if (s) s.unsubscribe();
			});
			if (this.itemTwo && this.itemOne && this.user && this.activeOrg && this.allInfo) {
				this.itemTwoPermissions.forEach(fn => {
					const res = fn(this.itemTwo, this.itemOne, this.user, this.renderer, this.el.nativeElement, this.activeOrg, this.allInfo, this);
					if (res) {
						this.sub.push(res);
					}
				});
			}
		}
	}
	ngAfterViewInit() {}

	takeAction(itemTwo) {
		if (this.user?.user?.edit_states.includes(itemTwo.state)) {
			if (this.isOnline) {
				this.router.navigate(['/workflow/detail']);
				// this.selectedItemTwo = itemTwo;

				this.controller
					.select(getOrgKey)
					.pipe(take(1))
					.subscribe(key => {
						this.controller.dispatch(
							new SetActiveManifestItem({
								pathToFlows: ['manifestItems'],
								orgKey: key,
								itemId: itemTwo?.state,
								itemOne: this.itemOne,
								itemTwo: this.itemTwo
							})
						);
					});
			}
		}
	}

	selectMenuItem(itemTwo, menuItem) {
		this.router.navigate(['/workflow/detail']);
		this._store.dispatch(new GetFullItemTwo({ id: itemTwo?.id }));
		this._store.dispatch(new SelectItemTwo({ itemOne: this.itemOne, itemTwo: this.itemTwo }));
		this.controller
			.select(getOrgKey)
			.pipe(take(1))
			.subscribe(key => {
				this.controller.dispatch(
					new SetActiveManifestItem({
						pathToFlows: ['manifestItems', 'workflow', 'contextMenu', 'itemTwo'],
						orgKey: key,
						itemId: menuItem.key,
						itemOne: this.itemOne,
						itemTwo: this.itemTwo
					})
				);
			});
		this.contextMenu.close();
	}

	ngOnDestroy() {
		this.sub.forEach(s => s.unsubscribe());
	}
}
