import { UntypedFormControl, Validators } from '@angular/forms';
import { NodePoint_0_0_2 } from '@flexus/core';

export const REWORK_NODE: NodePoint_0_0_2 = {
	component: 'FLXFlatDynamicFormComponent',
	name: 'Rework',
	hideTabItem: true,
	inputs: {
		heading: 'Re-work Requested',
		formControls: {
			0: {
				label: 'Please comment why the job is assigned for SP rework.',
				inputType: 'textarea',
				rows: 5,
				formControlName: 'message'
			}
		},
		formLayout: 'stacked',
		containerWidth: '50vw'
	},
	checkValidityForFields: ['message'],
	initFormFields: (bf, item, instance) => {
		bf.patchValues({ new_state: 42 });
		bf.addControl('message', new UntypedFormControl('', Validators.required));
	},
	navs: [
		{
			text: 'Submit',
			disableOnLoad: true,
			serverFirst: true,
			nextNode: 'submissionSuccess',
			color: 'primary',
			optIntoValidation: true,
			linkType: 'submit',
			serverCalls: {
				response: {
					serviceVariable: 'mulService',
					functionName: 'updateJob',
					errorMessage: 'Job could not be updated!!'
				}
			}
		}
	]
};
