import { Component, ViewChild, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Subscription, of, Observable } from 'rxjs';
import { DynamicLoaderComponent } from '../../../dynamic-loader/dynamic-loader.component';
import { ManifestController, SCREENMAPPER, getActiveNode } from '@flexus/core';


@Component({
	selector: 'flexus-sub-flow-node',
	templateUrl: './sub-flow-node.component.html',
	styleUrls: ['./sub-flow-node.component.scss']
})
export class SubFlowNodeComponent {
	activeNodeSubscription: Subscription;
	title$: Observable<string>;
	loading$: Observable<boolean>;
	@ViewChild(DynamicLoaderComponent, { static: true }) host: DynamicLoaderComponent;

	nodes: { [key: string]: any };
	nodeKeys: string[] = [];
	constructor(private store: Store<any>, private controller: ManifestController<any>, @Inject(SCREENMAPPER) private screenMapper: any) {}

	ngOnInit() {
		this.title$ = of('');
		this.activeNodeSubscription = this.controller
			.pipe(
				select(getActiveNode),
				map(node => node.subFlow)
			)
			.subscribe((subFlow: any) => {
				if (subFlow) {
					const { nodes } = subFlow;
					this.nodes = nodes;
					this.nodeKeys = Object.keys(nodes);
					this.loadComponent(this.nodes[this.nodeKeys[0]]);
				}
			});
	}

	loadComponent(node: any) {
		const { component, inputs, navs } = node;
		if (typeof component === 'string') {
			this.host.loadWithInputs(this.screenMapper[component], { ...inputs, navs }, node);
		}
	}

	ngOnDestroy() {
		if (this.activeNodeSubscription) {
			this.activeNodeSubscription.unsubscribe();
		}
	}
}
