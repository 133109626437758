<ng-template cdk-portal #overlayTemplate="cdkPortal">
	<div class="flx-modal" [ngClass]="arrClasses" [ngStyle]="{ 'max-width': messageOnly ? '560px' : '70vw' }" #modalHeader>
		<div class="flx-modal-heading" *ngIf="displayHeading">
			<flx-heading *ngIf="heading" size="large" [color]="color" align="center">{{ heading }}</flx-heading>
			<flx-heading *ngIf="subheading" [color]="color" size="small" align="center">{{ subheading }}</flx-heading>
		</div>

		<div class="flx-modal-body" [ngStyle]="{ 'align-items': align }">
			<div [className]="type">
				<div className="imageSource" *ngIf="imageSource" [innerHTML]="imageSource | safeHtml"></div>
				<div>{{ message }}</div>
				<div style="font-size: x-small">{{ submessage }}</div>

				<div id="modal-content-output" #modalContent><ng-content></ng-content></div>

				<form [formGroup]="_bf.bigForm" *ngIf="control">
					<flx-label *ngIf="control.label">{{ control.label }}</flx-label>
					<flx-textarea width="100%" [formControlName]="control.controlName" id="control" [rows]="10"></flx-textarea>
					<flx-reactive-form-error-display [control]="$any(_bf)?.bigForm.controls['control']" class="error"> </flx-reactive-form-error-display>
				</form>

				<ng-container>
					<div class="modal-calc" *ngIf="showCalc">
						<!-- <flx-heading size="small" class="columns col-7 col-mx-auto" margin="0">Payment Calculation:</flx-heading> -->
						<div *ngFor="let item of calculationLineItems" class="columns col-7 col-mx-auto calc-item">
							<span>{{ item?.value | currency: 'ZAR':'symbol-narrow':'.2-2' }}</span> <span>{{ item?.display }}</span>
						</div>
						<div class="columns col-7 col-mx-auto">--------------------------------</div>
						<div class="columns col-7 col-mx-auto calc-item">
							<span> {{ this.calcTotal | currency: 'ZAR':'symbol-narrow':'.2-2' }}</span
							><span>{{ this.calcTotalHeading }}</span>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
		<ng-container *ngIf="navButtons !== null">
			<div class="flx-modal-footer-one">
				<ng-container *ngFor="let button of navButtons">
					<flx-button [id]="button?.isOnlineBtn ? 'isOnlineBtn' : ''" [color]="button.color" [disabled]="button.disabled | async" (click)="takeButtonAction(button)">{{
						button.text
					}}</flx-button>
				</ng-container>
			</div>
		</ng-container>
		<div class="flx-modal-footer" *ngIf="closeButton === true"><flx-button color="alert" (click)="close()">Close</flx-button></div>
		<!-- Action Confirmation -->
		<div class="flx-modal-confirm" *ngIf="actionToConfirm">
			<div class="flx-modal-confirm-content">
				<div class="flx-modal-confirm-text">
					<flx-heading [color]="actionToConfirm.type ? actionToConfirm.type : 'default'" size="small" align="center"> {{ actionToConfirm.text }} </flx-heading>
				</div>
				<div class="flx-modal-confirm-footer">
					<flx-button color="default" (click)="actionToConfirm = null">cancel</flx-button>
					<flx-button [color]="actionToConfirm.confirm.color" (click)="takeButtonAction(actionToConfirm.confirm)"> {{ actionToConfirm.confirm.text }} </flx-button>
				</div>
			</div>
		</div>
	</div>
</ng-template>
