<div class="storm-shell">
	<ng-template #loader> <flx-loader-component></flx-loader-component> </ng-template>

	<div *ngIf="location === 'screen'; else showReason">
		<flx-tabs [tabs]="tabs" [currentStep$]="currentTab$" [externalPages]="true" (outputEventStream)="handleTab($event)"> </flx-tabs>

		<ng-container *ngIf="(currentTab$ | async) === 'queryHistory'; else showReason">
			<flx-query-history [queryHistory$]="queryHistory$" [location]="location"></flx-query-history>
		</ng-container>
	</div>

	<ng-template #showReason>
		<ng-container *ngIf="!usedInContextMenu">
			<div *ngIf="showQuoteInput" class="storm-shell-quote-amount">
				<flx-heading size="extraSmall">Please enter the quote amount </flx-heading>
				<form [formGroup]="bf.bigForm">
					<flx-input-text type="text" formControlName="quote_amount"></flx-input-text>
					<flx-button [disabled]="bf.bigForm.pristine" (clicked)="submit()">Submit</flx-button>
				</form>
			</div>
		</ng-container>
		<div class="dynamic-component" *ngIf="!showQuoteInput">
			<flx-heading size="small" align="center" color="secondary"> Reason for IA Appointment: </flx-heading>
			<p class="reason-text" *ngIf="iaReason$ | async as reason; else loader">{{ reason }}</p>
			<flx-heading size="small" align="center" color="secondary" margin="0"> Claim Details </flx-heading>
			<flx-key-value-list *ngIf="claimDetails$ as claimDetails" [data$]="claimDetails"> </flx-key-value-list>

			<!-- <flx-dynamic-component-header title="Select an action to take"></flx-dynamic-component-header> -->
			<div class="action">
				<flx-heading align="center" weight="extra-light" size="medium">Select an action to take</flx-heading>
				<div class="ia-job-actions">
					<flx-button (clicked)="goToQueryQuotation()">Query</flx-button>
					<flx-button (clicked)="goToRecommendCIL()">Recommend CIL</flx-button>
					<flx-button (clicked)="goToRecommendRejection()">Recommend Rejection</flx-button>
					<flx-button (clicked)="goToRecommendLossAdjuster()">Recommend Loss Adjuster</flx-button>
					<flx-button (clicked)="continue()">Approve</flx-button>
				</div>
			</div>
		</div>
	</ng-template>
</div>
