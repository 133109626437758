<flx-flex-container alignItems="center">
	<flx-heading size="medium" align="center" type="creation" weight="extra-light">{{ title }}</flx-heading>
	<br />
	<form [formGroup]="bf.bigForm">
		<flx-select-list
			[options$]="registeredProductsFormatted$"
			formControlName="selectedAppliance"
			[height]="selectedApplianceId === '' ? '40vh' : '150px'"
			[canFilter]="selectedApplianceId === ''"
			maxWidth="300px"
		>
		</flx-select-list>
	</form>

	<div *ngIf="selectedAppliance && selectedAppliance.photo && this.selectedAppliance.photo.length > 0" class="appliance-images">
		<img *ngFor="let photo of photos$ | async" [src]="photo.file" />
	</div>

	<flx-button
		color="primary"
		display="block"
		*ngIf="!(registeredProductsFormatted$ | async) || (registeredProductsFormatted$ | async).length < 3"
		(click)="initAndshowAddApplianceModal()"
		>Add New Appliance</flx-button
	>
</flx-flex-container>

<!-- Add Appliance modal -->
<flx-modal type="info" [backgroundClose]="false">
	<form [formGroup]="addApplianceForm" [style.textAlign]="'left'">
		<flx-flex-container direction="row" justifyContent="space-between">
			<div class="user-select__modal__column user-select__modal__column--margin-right">
				<flx-label>Type</flx-label>
				<flx-dropdown id="type" [itemsOption]="item_types" [displayOptions]="{ displayKey: 'name', valueKey: 'id' }" formControlName="item_type" placeHolder="Select type">
				</flx-dropdown>
				<flx-reactive-form-error-display [control]="itemTypeCtrl"></flx-reactive-form-error-display>
				<flx-label>Make</flx-label>
				<flx-dropdown id="make" [itemsOption]="brands" [displayOptions]="{ displayKey: 'name', valueKey: 'id' }" formControlName="make" placeHolder="Select make"> </flx-dropdown>
				<flx-reactive-form-error-display [control]="makeCtrl"></flx-reactive-form-error-display>
			</div>
			<div>
				<flx-label>Serial</flx-label>
				<flx-input-text formControlName="serial" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="serialCtrl"></flx-reactive-form-error-display>
				<flx-label>Model</flx-label>
				<flx-input-text formControlName="model" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="modelCtrl"></flx-reactive-form-error-display>
			</div>
		</flx-flex-container>
		<flx-label>Additional Information</flx-label>
		<flx-textarea formControlName="additionalInfo" id="additionalInfo" [rows]="5"></flx-textarea>
	</form>
</flx-modal>
