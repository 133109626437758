import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar.component';
import { FLXFormsModule, FLXButtonsModule, FLXReactiveFormsErrorDisplayModule, FLXIconModule } from '@flexus/ui-elements';
import { FLXAlertMessagesModule } from '../alert-messages';

@NgModule({
	imports: [CommonModule, FLXFormsModule, FLXButtonsModule, FLXReactiveFormsErrorDisplayModule, FLXIconModule, FLXAlertMessagesModule],
	declarations: [SearchBarComponent],
	exports: [SearchBarComponent]
})
export class FLXSearchBarModule {}
