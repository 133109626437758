<form [formGroup]="baseInvoiceAndCreditNoteNumber">
	<div class="input-container">
		<div class="base-invoice-number">
			<flx-label>Base Invoice Number</flx-label>
			<flx-input-text [placeholder]="'#1234567'" formControlName="base_invoice_number"></flx-input-text>
		</div>
		<div class="credit-note-number">
			<flx-label>Credit Note Number</flx-label>
			<flx-input-text [placeholder]="'#1234567'" formControlName="credit_note_number"></flx-input-text>
		</div>
	</div>
</form>
