import { Flow_0_0_2 } from '@flexus/core';

export const BUI_66: Flow_0_0_2 = {
	id: '66',
	name: 'no_work_cil_sp_notification',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Wait for SP to invoice call out fee'
		}
	},
	startNode: '',
	nodes: {}
};
