<flx-heading size="medium" align="center" weight="extra-light" type="creation">Confirm Policy Details</flx-heading>

<div class="policy-detail-container">
	<form *ngIf="bf.bigForm" [formGroup]="bf.bigForm">
		<flx-flex-container *ngIf="!isMC" direction="row" justifyContent="right" maxWidth="44vw">
			<flx-tabs [tabs]="tabs" [currentStep$]="currentTab$" [externalPages]="true" [hideBackground]="true" (outputEventStream)="handleTab($event)"> </flx-tabs>
		</flx-flex-container>

		<flx-flex-container direction="row" justifyContent="center" maxWidth="50vw">
			<flx-panel density="cozy" formGroupName="client_details" margin="8">
				<flx-label>{{ 'first_name' | fsToHuman }}</flx-label>
				<flx-input-text formControlName="first_name" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm.controls['client_details']['controls']?.first_name"></flx-reactive-form-error-display>

				<flx-label>{{ 'surname' | fsToHuman }}</flx-label>
				<flx-input-text formControlName="surname" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm.controls['client_details']['controls']?.surname"> </flx-reactive-form-error-display>

				<flx-label>ID Number {{ isMC ? '/ Passport Number' : ' / Business Registration' }}</flx-label>
				<flx-input-text formControlName="id_number" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm.controls['client_details']['controls']?.id_number"> </flx-reactive-form-error-display>

				<flx-label>{{ 'policy_number' | fsToHuman }}</flx-label>
				<flx-input-text formControlName="policy_number" [noEndingSpace]="true" type="text" size="normal" [maxLength]="15"> </flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm.controls['client_details']['controls']?.policy_number"> </flx-reactive-form-error-display>

				<div *ngIf="isMC">
					<flx-label>DStv Account Number</flx-label>
					<flx-input-text formControlName="dstv_account_number" [noEndingSpace]="true" type="text" size="normal" [maxLength]="20"> </flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['client_details']['controls']?.dstv_account_number"> </flx-reactive-form-error-display>

					<flx-label>Claim Number</flx-label>
					<flx-input-text formControlName="mavenclaimnumber" [noEndingSpace]="true" type="text" size="normal" [maxLength]="20"> </flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['client_details']['controls']?.mavenclaimnumber"> </flx-reactive-form-error-display>
				</div>

				<div *ngIf="!isMC">
					<flx-label>{{ 'bond_number' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="bond_number" [noEndingSpace]="true" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['client_details']['controls']?.bond_number"> </flx-reactive-form-error-display>
				</div>
			</flx-panel>

			<flx-panel density="cozy" formGroupName="contact_details" margin="8">
				<flx-label>{{ 'contact_number' | fsToHuman }}</flx-label>

				<flx-input-text formControlName="contact_number" type="contact_number" [onlyValidPhoneChars]="true" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm.controls['contact_details']['controls']?.contact_number" class="error"> </flx-reactive-form-error-display>

				<flx-label>{{ 'cell_number' | fsToHuman }}</flx-label>
				<flx-input-text formControlName="cell_number" type="tel" [onlyValidPhoneChars]="true" size="normal"> </flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm.controls['contact_details']['controls']?.cell_number" class="error"></flx-reactive-form-error-display>

				<flx-label>{{ 'email' | fsToHuman }}</flx-label>
				<flx-input-text formControlName="email" type="email" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm.controls['contact_details']['controls']?.email" class="error"></flx-reactive-form-error-display>

				<div *ngIf="!isMC">
					<flx-label>{{ 'preferred_com_method' | fsToHuman }}</flx-label>
					<flx-dropdown
						[itemsOption]="commsOptions"
						[displayOptions]="{ displayKey: 'display', valueKey: 'value' }"
						formControlName="preferred_com_method"
						placeHolder="Select method"
						[searchEnabled]="false"
					>
					</flx-dropdown>
				</div>
			</flx-panel>

			<flx-panel density="cozy" formGroupName="insured_address" margin="8">
				<ng-container *ngIf="(currentTab$ | async) === 'house_estate' && !isMC">
					<flx-label>{{ 'estate_name' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="complex" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.complex"> </flx-reactive-form-error-display>

					<flx-label>{{ 'street_address' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="street_address" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.street_address"> </flx-reactive-form-error-display>

					<flx-label>{{ 'suburb' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="suburb" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.suburb"> </flx-reactive-form-error-display>

					<flx-label>{{ 'city' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="city" [noEndingSpace]="true" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.city"> </flx-reactive-form-error-display>

					<flx-label>{{ 'postal_code' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="postal_code" [noEndingSpace]="true" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.postal_code"> </flx-reactive-form-error-display>
				</ng-container>

				<ng-container *ngIf="(currentTab$ | async) === 'flat_office' && !isMC">
					<flx-label>Flat/Office Name</flx-label>
					<flx-input-text formControlName="complex" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.complex"> </flx-reactive-form-error-display>

					<flx-label>{{ 'unit_number' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="complex_unit_number" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.complex_unit_number"> </flx-reactive-form-error-display>

					<flx-label>{{ 'block' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="complex_block" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.complex_block"> </flx-reactive-form-error-display>

					<flx-label>{{ 'street_address' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="street_address" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.street_address"> </flx-reactive-form-error-display>

					<flx-label>{{ 'suburb' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="suburb" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.suburb"> </flx-reactive-form-error-display>

					<flx-label>{{ 'city' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="city" [noEndingSpace]="true" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.city"> </flx-reactive-form-error-display>

					<flx-label>{{ 'postal_code' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="postal_code" [noEndingSpace]="true" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.postal_code"> </flx-reactive-form-error-display>
				</ng-container>

				<ng-container *ngIf="isMC">
					<flx-label>{{ 'complex' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="complex" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.complex"> </flx-reactive-form-error-display>
					<ng-container *ngIf="bf.bigForm.controls['insured_address']['controls']?.complex?.value && bf.bigForm.controls['insured_address']['controls']?.complex_unit_number">
						<flx-label>{{ 'complex_unit_number' | fsToHuman }}</flx-label>
						<flx-input-text formControlName="complex_unit_number" type="text" size="normal"></flx-input-text>
						<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.complex_unit_number"> </flx-reactive-form-error-display>
					</ng-container>
					<flx-label>{{ 'street_address' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="street_address" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.street_address"> </flx-reactive-form-error-display>
					<flx-label>{{ 'suburb' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="suburb" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.suburb"> </flx-reactive-form-error-display>
					<flx-label>{{ 'city' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="city" [noEndingSpace]="true" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.city"> </flx-reactive-form-error-display>
					<flx-label>{{ 'postal_code' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="postal_code" [noEndingSpace]="true" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.postal_code"> </flx-reactive-form-error-display>
				</ng-container>

				<flx-label>{{ 'province' | fsToHuman }}</flx-label>
				<flx-dropdown
					(itemsSelected)="setArea($event)"
					[itemsOption]="provinceList"
					[displayOptions]="{ displayKey: 'display', valueKey: 'value' }"
					formControlName="province"
					placeHolder="Select Province"
					[searchEnabled]="false"
				>
				</flx-dropdown>
				<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.province"> </flx-reactive-form-error-display>
				<!-- <ng-container
					*ngIf="bf.bigForm.controls['insured_address']['controls']?.complex?.value && bf.bigForm.controls['insured_address']['controls']?.complex_unit_number">
					<flx-label>{{ 'province' | fsToHuman }}</flx-label>
					<flx-input-text formControlName="complex_unit_number" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display
						[control]="bf.bigForm.controls['insured_address']['controls']?.complex_unit_number">
					</flx-reactive-form-error-display>
				</ng-container>-->
			</flx-panel>
		</flx-flex-container>
	</form>
</div>
