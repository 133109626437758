import { Flow_0_0_2 } from '@flexus/core';
import { MUL_176 } from './MUL_176';
import { voucherInfoNode } from '../reusable/VOUCHER_INFO_NODE';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { photoNode } from '../reusable/PHOTO_NODE';

export const MUL_188: Flow_0_0_2 = {
	...MUL_176,
	nodes: {
		...MUL_176.nodes,
		voucherData: {
			...voucherInfoNode(188)
		},
		jobInformation: {
			showTabs: true,
			...jobInfoNode(188)
		},
		photo: {
			...photoNode(188)
		}
	},
	id: '188',
	name: 'payment_request_qa_done',
	header: {
		title: 'Payment validation QA Done',
		controls: () => () => []
	},
	instructions: {
		editRoles: {
			0: 'Payment Validation Request - Q.A Done'
		},
		viewRoles: {
			0: 'Payment Validation Request - Q.A Done'
		}
	}
};
