import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'idToName',
	pure: false
})
export class IdToNamePipe implements PipeTransform {
	transform(value: any, ...args): any {
		if (args?.length > 0) {
			const current = args[0]?.find(c => c.id === value[0]);
			return current?.name;
		}
		return 'NA';
	}
}
