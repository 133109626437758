import { Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { map, pluck, filter, skipWhile, first, take, switchMap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import gql from 'graphql-tag';

export const BUI_88: Flow_0_0_2 = {
	id: '88',
	name: 'supply_chain_assign_assessor',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					return `Assign Internal Assessor : ${
						itemOne?.loan_information?.mavenclaimnumber ? itemOne?.loan_information?.mavenclaimnumber : itemOne?.loan_information?.mavenclaimnumber
					} - ${itemOne.applicant?.surname}`;
				})
			);
		},
		controls: () => () => []
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),

	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Appoint Assessor'
		},
		viewRoles: {
			0: 'Supply Chain to appoint Assessor'
		}
	},
	startNode: 'SelectAssignIA',
	nodes: {
		SelectAssignIA: {
			component: 'AssignIAComponent',
			initFormFields: (bf, item, inst, sq, store) => {
				bf.addControl('assign_assessor_id', new UntypedFormControl(null, [Validators.required]));
				bf.addControl('new_state', new UntypedFormControl(89));
				bf.addControl('ia_request_reason', new UntypedFormControl('', [Validators.required]));
			},
			serverCalls: {
				iaListData: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return http.get(`${environment.api_url}v1/claim_action/get_claim_handler/`, { params: { roles: '2' } }).pipe(
							pluck('payload'),
							filter(x => !!x)
						);
					}
				},
				IAreasons: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											config_options {
												general {
													ia_request_reasons
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map((arr: any) => arr.ia_request_reasons),
								map((entry: Array<any>) => entry.map(reason => ({ display: reason, value: reason })))
							);
					}
				}
			},
			checkValidityForFields: ['assign_assessor_id'],
			navs: [
				{
					text: 'Continue',
					location: 'right',
					color: 'primary',
					nextNode: 'AssignIA',
					serverFirst: true,
					linkType: 'submit',
					optIntoValidation: true,
					serverCalls: {
						appointAssessor: {
							directCall: (http, store, sq, bf) => {
								return store.select(getFullItemTwo).pipe(
									skipWhile(res => !res),
									pluck('id'),
									take(1),
									switchMap((selected_job_id: any) => {
										return http.post<any>(`${environment.api_url}v1/job_action/assign_assessor`, {
											job_id: selected_job_id,
											assessor_id: Number(bf.bigForm.get('assign_assessor_id')?.value[0])
										});
									})
								);
							},
							errorMessage: 'An error occurred while assigning an assessor',
							followUpSuccessCalls: {
								updateJob: {
									serviceVariable: 'service',
									functionName: 'updateJobNewState',
									errorMessage: 'An error occurred while updating the job state'
								},
								updateClaim: {
									// Update the IA reason that sits on the claim level
									errorMessage: 'Error IA appointment reason',
									directCall: (http, store, sq, bf) => {
										return store.select(getFullItemOne).pipe(
											take(1),
											map((claim: any) => {
												const { loan_information } = claim;
												const result = {
													...loan_information,
													ia_requested_reason: bf.bigForm.get('ia_request_reason')?.value
												};
												const response = {
													loan_information: result,
													claim
												};
												return response;
											}),
											switchMap((response: any) => {
												const { claim, ...data } = response;
												return http.post(`${environment.api_url}v1/claim_action/update_claim/`, {
													claim_id: claim?.id,
													...data
												});
											})
										);
									}
								}
							}
						}
					}
				}
			]
		},
		AssignIA: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		assign_assessor_id: [
			(val, storeObj, formValue) => {
				return formValue.assign_assessor_id ? Number(formValue.assign_assessor_id[0]) : null;
			},
			'assign_assessor_id'
		],
		new_state: ['new_state'],
		ia_request_reason: ['loan_information.ia_requested_reason']
	}
};
