<div class="appointment" [formGroup]="appointmentData">
	<div class="cell job-class" *ngIf="mustIncludeSkillDetails">{{ skillString }}</div>
	<ng-container *ngIf="appointmentNeeded; else appNotNeeded">
		<div class="cell appointment-date">
			<input
				class="date-picker-input"
				type="text"
				formControlName="appointmentDatePicker"
				[flxDatepicker]="myDatePicker"
				[min]="hasMinDate ? minDate : ''"
				[max]="maxDate"
				placeholder="Select Date"
				[disabled]="isDisabled"
				[flxDatepickerFilter]="datePickerFilter"
				readonly
			/>
			<flx-datepicker #myDatePicker></flx-datepicker>
		</div>

		<div class="cell appointment-type">
			<flx-dropdown
				#myAppointmentType
				[multiple]="false"
				[itemsOption]="appointmentTypes"
				formControlName="appointmentTime"
				[disabled]="isDisabled"
				[displayOptions]="displayOptions"
				[searchEnabled]="false"
				[autoOpen]="appointmentData?.value?.appointmentDatePicker !== '' && appointmentData?.value?.appointmentTime === ''"
			>
			</flx-dropdown>
		</div>

		<div *ngIf="appointmentData.get('from_time') && appointmentData.get('to_time') && appointmentData?.value?.appointmentTime === 3" class="cell appointment-time">
			<flx-time-picker
				#myTimePicker
				formControlName="from_time"
				[autoOpen]="appointmentData?.value?.appointmentDatePicker !== '' && appointmentData?.value?.appointmentTime !== '' && appointmentData?.value?.appointmentTimePicker === ''"
				[minuteInterval]="minuteInterval"
				[disabledHours]="disabledHours"
				[disabledMins]="disabledMin"
				[isDisabled]="isDisabled"
				[minHour]="minHour"
				[maxHour]="maxHour"
				(hourPicked)="validateDisabledMin($event)"
				(changeTime)="timeChanged($event, 'from_time')"
			>
			</flx-time-picker>

			<flx-time-picker
				#myTimePicker
				formControlName="to_time"
				[autoOpen]="appointmentData?.value?.appointmentDatePicker !== '' && appointmentData?.value?.appointmentTime !== '' && appointmentData?.value?.appointmentTimePicker === ''"
				[minuteInterval]="minuteInterval"
				[disabledHours]="disabledHours"
				[disabledMins]="disabledMin"
				[isDisabled]="isDisabled"
				[minHour]="minHour"
				[maxHour]="maxHour"
				(hourPicked)="validateDisabledMin($event)"
				(changeTime)="timeChanged($event, 'to_time')"
			>
			</flx-time-picker>
		</div>

		<div *ngIf="appointmentData.get('appointmentTimePicker') && appointmentData?.value?.appointmentTime !== 3" class="cell appointment-time">
			<flx-time-picker
				#myTimePicker
				formControlName="appointmentTimePicker"
				[autoOpen]="appointmentData?.value?.appointmentDatePicker !== '' && appointmentData?.value?.appointmentTime !== '' && appointmentData?.value?.appointmentTimePicker === ''"
				[minuteInterval]="minuteInterval"
				[disabledHours]="disabledHours"
				[disabledMins]="disabledMin"
				[isDisabled]="isDisabled"
				[minHour]="minHour"
				[maxHour]="maxHour"
				(hourPicked)="validateDisabledMin($event)"
				(changeTime)="timeChanged($event, 'appointmentTimePicker')"
			>
			</flx-time-picker>
		</div>
	</ng-container>
</div>
<ng-template #appNotNeeded> <flx-heading size="extra-small" align="center" weight="extra-light">No appointment needed</flx-heading> </ng-template>

<div class="form-feedback" *ngIf="appointmentData.errors && (appointmentData.dirty || appointmentData.touched)">
	<span *ngIf="appointmentData.errors.timeRangeError"> Please select an end time later than the start time. </span>
</div>
