import { BigFormService, Flow_0_0_2, getAllInfo, getFullItemTwo } from '@flexus/core';
import { setActionPanelItems, CollapseActionPanel } from 'apps/studio/src/app/app-shell-features';
import { skipWhile, map, take } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin } from 'rxjs';
import { KVLHeading } from '@flexus/ui-elements';
import { FormControl } from '@angular/forms';

export const MUL_338: Flow_0_0_2 = {
	id: '338',
	name: 'credit_note_required',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any, bf: any) => {
			return store.select(getFullItemTwo)?.pipe(
				map((itemTwo: any) => {
					if (itemTwo?.claim) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Credit Note Required : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Credit Note Required';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Upload Credit Note'
		},
		viewRoles: {
			0: 'Scheduler to provide credit note' // check the text
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'jobSummary',
	nodes: {
		jobSummary: {
			initFormFields: (bf: BigFormService) => {
				bf.addControl('new_state', new FormControl(28)), bf.addControl('purpose', new FormControl('Credit_Note'));
			},
			showTabs: true,
			name: 'Job Summary',
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Account over paid. Credit note required'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('You have been over paid on this job. Please supply a credit note for the excess payment', 'secondary'),
							data$: 'creditNote_info',
							color: 'secondary',
							itemMargin: '0  0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Details',
							data$: 'installationDetailValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			serverCalls: {
				creditNote_info: {
					errorMessage: 'Something went wrong with Credit note info',
					directCall: (http, store, sq, bf) => {
						const job_id = bf.bigForm.get('itemTwo')?.value?.id;
						return forkJoin([
							http.get(`${environment.api_url}v1/job/${job_id}/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['job_information'])
							)
						]).pipe(
							map(([job_information]) => {
								const { credit_note } = job_information;
								const reason_overpay = credit_note?.credit_note_reason ?? '-';
								const creditNote_amount = (credit_note?.credit_amount && `R${credit_note?.credit_amount}`) ?? '-';
								const creditNote_number = credit_note?.credit_note_no ?? '-';

								const creditNote_info = {
									'Reason for overpayment ': `${reason_overpay}`,
									'Credit Note Amount ': `${creditNote_amount}`,
									'Use Credit Note Number ': `${creditNote_number}`
								};
								return [creditNote_info];
							})
						);
					}
				},
				installationDetailValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile((ai: any) => !ai),
								take(1),
								map(res => res as any)
							)
						]).pipe(
							map(([itemTwo, info]) => {
								const voucher_code = itemTwo?.job_information?.vouchers?.voucher_code ?? 'No voucher number';
								const claim_type_no = itemTwo?.claim?.claim_type;
								const claimtype_description = info?.claim_types.find(el => el.id === claim_type_no).description;
								const product_type: string = itemTwo?.office_use?.device_details?.replacement_device?.type ?? 'No product information';

								const installationdetails = {
									'Full name ': `${itemTwo.claim?.applicant?.first_name} ` + `${itemTwo.claim?.applicant?.surname}`,
									'Customer Number': itemTwo?.claim?.loan_information.ContactNumber,
									'Installation Address':
										`${itemTwo?.claim?.loan_information?.propertystreetaddress}` +
										' ' +
										`${itemTwo?.claim?.loan_information?.propertystreetaddress_2},` +
										' ' +
										`${itemTwo?.claim?.loan_information?.propertysuburb}`,
									'Voucher numbers': `${voucher_code.split(',').join(', ')}`,
									'Installation Type': `${claimtype_description}`,
									'Product Type': `${product_type}`
								};
								return [installationdetails];
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'upload credit note',
					nextNode: 'uploadCreditNote',
					color: 'primary'
				}
			]
		},
		uploadCreditNote: {
			showTabs: true,
			name: 'Upload credit note',
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Upload credit note'
						}
					},
					{
						component: 'FLXFileUploadWithPreviewComponent',
						inputs: {
							purposes: [{ display: 'Credit Note', value: 'SP Report' }], //this does not work yet
							heading: 'Select file to upload',
							multipleUploads: false,
							allowedFileTypes: ['pdf', 'image'],
							containerWidth: '50vw',
							maxWidth: '50vw'
						}
					}
				]
			},
			navs: [
				//need to run this whole nav
				{
					text: 'Upload',
					serverFirst: true,
					nextNode: 'FileSubmissionSuccess',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'portal',
					serverCalls: {
						uploadInvoice: {
							errorMessage: 'Credit note could not be uploaded',
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const filenom = fileQueue[0] ? fileQueue[0]?.file.nativeFile.name : '';
								const body: object = {
									data: fileQueue[0] ? fileQueue[0]?.file.base64 : '',
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: filenom
								};
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
							}
						}
					}
				}
			]
		},
		jobDetails: {
			showTabs: true,
			name: 'Job Details',
			component: {
				children: [
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Installation Details', 'secondary'),
							data$: 'installationDetailValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Decoder', 'secondary'),
							data$: 'decoderDetailValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Equipment', 'secondary'),
							data$: 'equipmentKeyValues',
							itemMargin: '0  0 15px 0'
						}
					}
				]
			},
			serverCalls: {
				installationDetailValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http, store, sq) => {
						return forkJoin([
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(job => {
									let installationDetailsData = {
										id: job?.id,
										'claim id': job?.claim?.id,
										address: job?.address,
										'appointment date': `${job?.claim?.loan_information?.requestedappointmentdate}`,
										'appointment time': `${job?.claim?.loan_information?.appointment_type} ${job?.claim?.loan_information?.requestedappointmenttime}`,
										team_leader_id: job?.team_leader?.id
									};
									return installationDetailsData;
								})
							)
						]).pipe(
							map(([teamLeads, installationDetails]) => {
								const teamLead = teamLeads.find(team_lead => team_lead?.id === installationDetails?.team_leader_id);
								delete installationDetails.team_leader_id;
								Object.assign(installationDetails, { 'Team Leader': teamLead['full_name'] ?? '' });
								return [installationDetails];
							})
						);
					}
				},
				decoderDetailValues: {
					errorMessage: 'Something went wrong with decoder details',
					directCall: (http, store, sq, bf) => {
						const job_id = bf.bigForm.get('itemTwo')?.value?.id;
						return forkJoin([
							http.get(`${environment.api_url}v1/job_action/get_voucher_details?job_id=${job_id}`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([decoderDetails]) => {
								const decoder_number = decoderDetails?.decoder_number.join(', ') ?? 'No decoder number';
								const voucher_code = decoderDetails?.voucher_number ?? 'No voucher code';
								const additional_info = decoderDetails?.voucher?.title ?? 'No additional decoder info';

								const decoderDisplayValues = {
									'Decoder Serial Number': `${decoder_number}`,
									'Voucher Code': `${voucher_code}`,
									'Additional Decoder Info': `${additional_info}`
								};
								return [decoderDisplayValues];
							})
						);
					}
				},
				equipmentKeyValues: {
					errorMessage: 'Something went wrong with equiptment info',
					directCall: (http, store, sq, bf) => {
						const job_id = bf.bigForm.get('itemTwo')?.value?.id;
						return forkJoin([
							http.get(`${environment.api_url}v1/job/${job_id}/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['job_information'])
							)
						]).pipe(
							map(([job_information]) => {
								const { installed_items } = job_information;

								const dish_qr = installed_items?.filter(({ item }) => item === 'Dish').map(({ serial }) => serial.split(',').join(', '));
								const bracket_code = installed_items?.filter(({ item }) => item === 'Bracket').map(({ serial }) => serial.split(',').join(', '));
								const lnb_qr = installed_items?.filter(({ item }) => item === 'LNB').map(({ serial }) => serial.split(',').join(', '));
								const additional_equipt = installed_items?.filter(item => item?.category === 'adhoc').map(({ item }) => item);

								const equipmentDetails = {
									'Dish QR': `${dish_qr}`,
									'Bracket Code': `${bracket_code}`,
									'LNB QR Code': `${lnb_qr}`,
									'Additional Equipment': `${additional_equipt.join(', ')}`
								};
								return [equipmentDetails];
							})
						);
					}
				}
			}
		},
		FileSubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'File successfully uploaded' },
			navs: [
				{
					text: 'Continue',
					serverCalls: {
						response: {
							serviceVariable: 'mulSpService',
							functionName: 'updateJob',
							errorMessage: 'Could not update job'
						}
					},
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
