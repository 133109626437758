import { Component, OnInit, Input, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'flx-set-excess',
	templateUrl: './set-excess.component.html',
	styleUrls: ['./set-excess.component.scss']
})
export class FLXSetExcessComponent implements OnInit, OnDestroy {
	@Input() whoPaysExcessOptions: { id: number; name: string }[];
	@Input() howExcessIsPaidOptions: { id: number; name: string }[];
	@Input() canRemove: boolean;
	@Input() excessData: UntypedFormGroup;
	@Input() mustIncludeSkillDetails: boolean = true;
	@Input() jobCard: any;

	@ViewChildren('who_collects_picker') who_collects_picker: QueryList<any>;
	@ViewChildren('payment_method_picker') payment_method_picker: QueryList<any>;

	private _subscriptions: Subscription[] = [];
	public skillString: string;
	private amount_control: AbstractControl;
	private who_collects_control;
	private payment_method_control;

	constructor() {}

	instantiateControls() {
		if (!this.amount_control) {
			this.excessData.addControl('amount', new UntypedFormControl(null, [Validators.required]));
		}
		this._subscriptions.push(
			this.amount_control?.valueChanges?.pipe(debounceTime(500)).subscribe(res => {
				this.openWhoCollectsControl();
			})
		);

		if (!this.who_collects_control) {
			this.excessData.addControl('appointmentTime', new UntypedFormControl(null, [Validators.required]));
		}
		this._subscriptions.push(
			this.who_collects_control?.valueChanges?.subscribe(date => {
				this.openPaymentMethodControl();
			})
		);

		if (!this.payment_method_control) {
			this.excessData.addControl('payment_method', new UntypedFormControl(null, [Validators.required]));
		}
	}

	openWhoCollectsControl() {
		this.who_collects_picker['_results'][0]?.open();
	}

	openPaymentMethodControl() {
		this.payment_method_picker['_results'][0]?.open();
	}

	createSkillString() {
		let skillString = '';
		const skillCat = this.excessData.get('skillcatagory');
		const skill = this.excessData.get('skill');

		if (skillCat && skillCat.value && skill && skill.value) {
			skillString = `${skillCat.value} - ${skill.value}`;
		} else if (skill && skill.value && skill.value !== '') {
			skillString = this.excessData.get('skill')?.value;
		} else {
			skillString = 'Skill needed not set';
		}

		return skillString;
	}

	ngOnInit() {
		this.amount_control = this.excessData.get('amount');
		this.who_collects_control = this.excessData.get('who_collects');
		this.payment_method_control = this.excessData.get('who_collects');

		this.skillString = this.createSkillString();
		this.instantiateControls();
	}

	ngOnDestroy(): void {
		this._subscriptions
			.filter(x => !!x)
			.forEach(subscription => {
				subscription.unsubscribe();
			});
	}
}
