import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemTwo, SetPreviousNode } from '@flexus/core';
import { KVLHeading } from '@flexus/ui-elements';
import { setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { skipWhile, take, switchMap, map } from 'rxjs/operators';
import { getInsuranceInvoiceQueries } from '../reusable/dataManipulations';
import { photoNode } from '../reusable/PHOTO_NODE';
import { invoiceNode } from '../reusable/VIEW_INVOICE_NODE';

export const MUL_250: Flow_0_0_2 = {
	id: '250',
	name: 'additional-costs-approved',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Additional Costs Approved',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Additional Costs Approved - Finalise Payment'
		},
		viewRoles: {
			0: 'Additional Costs Approved - Payment approval in process'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		}
	},
	startNode: 'JobInformation',
	nodes: {
		JobInformation: {
			initFormFields: (bf, item, instance, sq, store) => {
				bf.addControl('new_state', new UntypedFormControl(28));
			},
			name: 'Job Information',
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary',
							headingConfig: { color: 'default', size: 'medium', weight: 'normal' }
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'installer_details',
							itemMargin: '15px 0 35px 0',
							colouredHeading: new KVLHeading('Installation details', 'default')
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'customer_details', colouredHeading: new KVLHeading('Customer details', 'default') }
					}
				]
			},
			showTabs: true,
			serverCalls: {
				installer_details: {
					errorMessage: 'Cannot get job info summary',
					directCall: (http, store) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								take(1),
								map(info => {
									const { job_information, team_leader } = info;
									const list: any = [];
									// Installer details

									list.push({
										'Claim value': job_information ? job_information?.claim_value : 'No invoice total',
										company_name: team_leader?.sp?.name ?? '',
										accreditation_number: team_leader?.sp?.accreditation_number ?? '',
										'Installation technician qualification number': team_leader?.qualification_number ?? '',
										'Installer/Technician name': team_leader?.full_name ?? '',
										contact_person: team_leader?.sp?.contact_person ?? '',
										contact_number: team_leader?.sp?.contact_primary ?? ''
									});

									return list;
								})
							);
					}
				},
				customer_details: {
					errorMessage: 'Cannot get job info summary',
					directCall: (http, store) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								take(1),
								map(info => {
									const { claim, team_leader, identities, state } = info;
									console.log('STATE', state);

									const list: any = [];
									// Customer details
									if (claim?.applicant) {
										list.push({
											full_name: claim?.applicant?.first_name + ' ' + claim?.applicant?.surname,
											...(state !== 186 ? { customer_id: claim?.applicant?.id_number ?? '', subscriber_number: claim?.applicant?.information?.dstv_account_number ?? '' } : {}),
											installation_address:
												state === 186
													? claim?.address
													: `${claim?.loan_information?.propertystreetaddress ?? ''} ${claim?.loan_information?.propertystreetaddress_2 ?? ''} ${
															claim?.loan_information?.propertysuburb ?? ''
													  } ${claim?.loan_information?.propertycity ?? ''}, ${claim?.loan_information?.suburbcode ?? ''}`,
											email_address: claim?.loan_information?.email ?? claim?.loan_information?.Email ?? '',
											contact_number: claim?.applicant?.contact_number,
											GPS_Coordinates: claim?.location
										});
									}
									return list;
								})
							);
					}
				}
			},

			navs: [
				{
					text: 'query',
					color: 'secondary',
					nextNode: 'QueryInvoice'
				},
				{
					text: 'payment complete',
					color: 'primary',
					nextNode: 'Decision'
				}
			]
		},
		Photos: {
			...photoNode(250, 28)
		},
		Invoice: {
			...invoiceNode(250, 28)
		},
		QueryInvoice: {
			showTabs: false,
			hideTabItem: true,
			checkValidityForFields: ['invoice_query'],
			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ new_state: 251 });
				bf.addControl('invoice_query', new UntypedFormControl('', [Validators.required]));
				// for possible complete message
				/*
        	combineLatest([store.select(getCurrentUser).pipe(filter(x => !!x, take(1))), store.select(getFullItemTwo).pipe(filter(x => !!x, take(1)))])
					.pipe(take(1))
					.subscribe(([author, job]) => {
						bf.patchValues({ author: author.user.id });
						bf.patchValues({ author_name: author.user.full_name });
						bf.patchValues({ current_state: job.state });
					});


        */
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Reply to Installer Scheduler',
							formControls: {
								0: {
									label: 'Comment why you are returning this to the Installer Scheduler',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'invoice_query'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'SUBMIT',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: '',
							serviceVariable: 'mulService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		Decision: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual'
			},
			decisions: {
				makePayment: (navs, store, modal, controller) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(jobber => {
								modal.openModalDirectly(instance => {
									instance.type = 'warning';
									instance.heading = 'Confirm Payment Approval';
									instance.setMessage(['You are about to confirm a payment', 'This can not be undone', '', 'Please confirm that you have completed payment']);
									instance.navButtons = [
										{
											text: 'back',
											clickHandler: event => {
												controller.dispatch(new SetPreviousNode());
											},
											color: 'default',
											linkType: 'close'
										},
										{
											text: 'confirm payment',
											color: 'default',
											linkType: 'submitThenNext',
											serverFirst: true,
											nextNode: 'SubmissionSuccess',
											serverCalls: {
												response: {
													serviceVariable: 'mulService',
													functionName: 'updateJob',
													errorMessage: 'job could not be updated'
												}
											}
										}
									];
								});
								return 'Decision';
							})
						)
						.subscribe();
				}
			},
			navs: []
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		current_state: 'current_state',
		invoice_query: [(costsquery, storeObj, bf) => getInsuranceInvoiceQueries(costsquery, storeObj, bf), 'job_information.insurance_invoice_queries']
	}
};
