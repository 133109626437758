import { Flow_0_0_2, getAllInfo, getFullItemTwo } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';
import { AUTO_SELECT_TEMPLATE_DECISION, BILLING_INVOICE, billingServerCalls, SELECT_CALLOUT_TEMPLATE } from './BILLING';
import { environment } from 'apps/studio/src/environments/environment';

export const SP_GLOBAL_94: Flow_0_0_2 = {
	id: '94',
	name: 'no_work_required_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Assessor Rejection - Invoice call-out : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Assessor Rejection - Invoice call-out';
						}
					}
				})
			);
		},
		controls: () => () => []
	},

	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'No work required'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		claimDetailKeyValues: {
			errorMessage: 'Something went wrong claim details',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							const claimdetaillist = {
								'Claim Type ': itemTwo?.claim?.type ?? '',
								'Skill required ': itemTwo?.office_use?.skillcatagory ?? '',
								'Address ': itemTwo ? itemTwo?.address : '',
								'Appointment Date ': itemTwo?.office_use?.requestedappointmentdate ?? 'No appointment date set',
								'Appointment Time ': itemTwo?.office_use
									? `${itemTwo.office_use.appointment_type} ${itemTwo.office_use.requestedappointmenttime}`
									: 'Appointment time not retrieved'
							};
							return [claimdetaillist];
						})
					);
			}
		},
		excessKeyValues: {
			errorMessage: 'Customer contact information could not be retrieved',
			directCall: (http, store, sq, bf) => {
				return forkJoin([
					store.select(getFullItemTwo)?.pipe(
						skipWhile(f => !f),
						take(1),
						map(res => res as any)
					),
					store.select(getAllInfo)?.pipe(
						skipWhile(i => !i),
						take(1),
						map(res => res as any)
					)
				])?.pipe(
					map(([itemTwo, info]) => {
						const job_id = itemTwo?.id;
						const job_excess = itemTwo?.excess;
						let excess_collection: string;
						let excess_amount: string;
						let excess_method: string;
						const excess_whoArr: any = info.excess_who;
						const excess_howArr: any = info.excess_how;
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_amount = job_excess[i]?.amount;
							}
						}
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_whoArr.forEach(element => {
									if (element.id === job_excess[i]?.who_collects) {
										excess_collection = element.name;
									}
								});
							}
						}
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_howArr.forEach(element => {
									if (element.id === job_excess[i]?.how_collect) {
										excess_method = element.name;
									}
								});
							}
						}
						const excessinfo = {
							'Excess Collection ': excess_collection ? `${excess_collection} to collect` : '-',
							'Excess Amount ': excess_amount ? `R${excess_amount}` : '-',
							'Excess payment ': excess_method,
							'Excess state': 'not set yet'
						};
						return [excessinfo];
					})
				);
			}
		},
		customerContactKeyValues: {
			errorMessage: 'Customer contact information could not be retrieved',
			directCall: (http, store, sq, bf) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(f => !f),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							console.log('Excess', itemTwo?.excess);
							const job_id = itemTwo?.id;

							const customercontactlist = {
								'Client name ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : 'Customer name not available',
								'Client cell ': itemTwo?.claim?.loan_information?.cellnumber ?? 'Cellular number not on record',
								'Customer alternative number ': itemTwo?.claim?.loan_information?.contactnumber ?? 'Contact number not on record'
							};
							return [customercontactlist];
						})
					);
			}
		},
		altContactAndNotes: {
			errorMessage: 'Customer contact information could not be retrieved',
			directCall: (http, store, sq, bf) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(f => !f),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							console.log('Excess', itemTwo?.excess);
							const job_id = itemTwo?.id;

							const customercontactlist = {
								'Contact person ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : 'Customer name not available',
								'Contant number ': itemTwo?.claim?.loan_information?.contactnumber ?? 'Contact number not on record',
								Notes: itemTwo?.claim?.loan_information?.contactnumber ?? 'Contact number not on record'
							};
							return [customercontactlist];
						})
					);
			}
		},
		...billingServerCalls
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Claim Repudiated by Assessor',
							instructions: ['The assessor has repudiated the claim.', 'Please invoice a call-out fee'],
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Excess Details',
							data$: 'excessKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Invoice',
					nextNode: 'CallOutInvoice'
				}
			]
		},
		CallOutInvoice: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Invoice Call-out Fee',
							instructions: ['The Internal Assessor has repudiated the claim for this job card.', 'Please invoice a call-out fee'],
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Excess Details',
							data$: 'excessKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Contact Details',
							data$: 'altContactAndNotes',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Invoice',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION'
				}
			]
		},
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'invoice',
				// docTitle: '',
				numberLabel: 'Invoice',
				currentState: 94,
				rejectBoqLineItems: true,
				newState: 27,
				showLineItemGenerator: true,
				canEditLineItems: true,
				boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
				boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Standard Bank Insurance Limited',
				clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL'
			},
			navs: [
				{
					text: 'Generate Invoice',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		current_state: [() => 94, 'current_state'],
		new_state: [() => 70, 'new_state'],
		total: [
			(a, b, bf) => {
				return bf.total + bf.vatRate;
			},
			'job_information.quote_amount'
		],
		invoiceNumber: 'job_information.quote_number'
	}
};
