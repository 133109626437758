import { Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { take, map, filter, skipWhile, first } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const BUI_18: Flow_0_0_2 = {
	id: '18',
	name: 'claim_reopened',
	itemType: 'flow',
	header: {
		title: 'Claim Re-opened',
		controls: () => () => []
	},
	actionPanel: instance =>
		setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp', 'sp-details'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Move to work in progress state'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			component: 'FLXKeyValueDisplayWithInstructionsComponent',
			initFormFields: (bf, i, instance) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(3));
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'error in Summary node keyValueList',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemOne)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								map((itemOne: any) => {
									bf.addControl('claim_id', new UntypedFormControl(itemOne.id));
									return [
										{
											'Client Name': itemOne?.applicant
												? `${itemOne.applicant?.first_name ? itemOne?.applicant?.first_name : ''} ${itemOne.applicant?.surname ? itemOne?.applicant?.surname : ''}`
												: ''
										},
										{ Address: itemOne?.address ? itemOne?.address : 'NO ADDRESS FOUND' },
										{
											'Claim Type': itemOne?.loan_information?.claimtype ?? 'NO CLAIM TYPE FOUND'
										},
										{
											'Contact Number': itemOne?.loan_information?.contactnumber ?? 'NO CONTACT NUMBER FOUND'
										},
										{
											'Cell Number': itemOne?.loan_information?.cellnumber ?? 'NO CELL NUMBER FOUND'
										},
										{
											'What Matters': itemOne?.loan_information?.whatmatters ?? 'NO WHAT MATTERS FOUND'
										}
									];
								})
							);
					}
				}
			},
			inputs: {
				title: 'This claim has been re-opened',
				instructions: ['This claim will be moved to a work in progress state so you can perform the required task.']
			},
			navs: [
				{
					text: 'Continue',
					serverFirst: true,
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateClaim',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		claim_id: 'claim_id'
	}
};
