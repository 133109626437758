import { Flow_0_0_2, getFullItemOne, getCurrentUser } from '@flexus/core';
import { map, skipWhile, take, pluck } from 'rxjs/operators';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import gql from 'graphql-tag';
import { of, forkJoin } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';

export const BUI_REOPEN_CLAIM: Flow_0_0_2 = {
	id: 'ReopenClaim',
	itemType: 'flow',
	name: 'Reopen Claim',
	excludeForOnlyRoles: [18],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map((itemOne: any) => {
					if (itemOne) {
						return `Re-open Closed Claim: ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Re-open Closed Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ReopenClaim',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	includeForOnlyStates: [7, 9],
	nodes: {
		ReopenClaim: {
			component: 'FLXReopenClaimComponent',
			serverCalls: {
				reopenReasons: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											reopen_reasons {
												id
												description
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(x => !x),
								pluck('reopen_reasons'),
								take(1)
							);
					}
				},
				claimAppointments: {
					serviceVariable: 'silService',
					functionName: 'getClaimAppointments',
					errorMessage: 'No claim appointments could be found!'
				}
			},
			inputs: {},
			initFormFields: bf => {
				bf.addControl(
					'reopen_data',
					new UntypedFormGroup({
						reopen_reason: new UntypedFormControl(null, [Validators.required])
					})
				);
			},
			navs: [
				{
					text: 'Re-open Claim',
					color: 'secondary',
					nextNode: 'SubmissionSuccess',
					linkType: 'submit',
					serverFirst: true,
					visible: (bf, store, networkService) => {
						return store.select(getCurrentUser).pipe(
							skipWhile(f => !f),
							pluck('user', 'is_team_leader'),
							take(1),
							map(res => {
								return res as any;
							})
						);
					},
					serverCalls: {
						response: {
							errorMessage: 'Claim was not reopened, something went wrong',
							serviceVariable: 'silService',
							functionName: 'reopenClaim'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {},
	serverCalls: {}
};
