import { Flow_0_0_2, getAllInfo, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { skipWhile, map, take, pluck } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { findName } from '@flexus/utilities';
import moment from 'moment';
import { forkJoin } from 'rxjs';

export const BET_SP_317: Flow_0_0_2 = {
	id: '317',
	name: 'underwriter_query',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: store =>
			store.select(getFullItemTwo).pipe(
				skipWhile((itemTwo: any) => !itemTwo),
				take(1),
				map((itemTwo: any) => {
					if (itemTwo) {
						return `Underwriter Query : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Underwriter Query';
					}
				})
			),
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		jobSummary: {
			errorMessage: '',
			directCall: (_h, store, _sq) =>
				store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					pluck('job_information'),
					map((job_information: any) => {
						if (job_information) {
							const jobSummary = {
								important_information: {
									important_note: 'The underwriter has responded to your query'
								}
							};

							if (job_information?.mfrf_query) {
								const query = !Array.isArray(job_information?.mfrf_query) ? [{ ...job_information?.mfrf_query }] : [...job_information?.mfrf_query];
								jobSummary.important_information['query'] = query[query.length - 1].query;
							}

							return jobSummary;
						}
					})
				)
		},
		summaryDetails: {
			errorMessage: 'Could get summary details',
			serviceVariable: '',
			directCall: (_h, store, _sq) =>
				forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile((itemTwo: any) => !itemTwo),
						take(1)
					),
					store.select(getAllInfo).pipe(
						skipWhile((allInfo: any) => !allInfo),
						pluck('appointment_types'),
						take(1)
					)
				]).pipe(
					take(1),
					map(([itemTwo, appointmentTypes]: any[]) => {
						const date = moment(itemTwo?.appointment?.[0]?.range_start);
						return {
							'Claim Details': {
								'Claim Type': itemTwo?.claim?.type,
								'Skill Required': itemTwo?.office_use?.skill,
								Address: itemTwo?.address,
								'Appointment Date': date?.format('YYYY-MM-DD'),
								'Appointment Time': `${findName(itemTwo?.appointment?.[0]?.appointment_type, appointmentTypes)?.name} ${date.format('HH:mm')}${
									itemTwo?.appointment?.[0]?.range_end ? ' - ' + moment(itemTwo?.appointment?.[0]?.range_end).format('HH:mm') : ''
								}`
							}
						};
					})
				)
		},
		assessorDetails: {
			errorMessage: 'Could get assessor details',
			serviceVariable: 'betService',
			functionName: 'getAssessorDetailsformatted'
		},
		customerDetails: {
			errorMessage: '',
			directCall: (http, store, sq) =>
				store.select(getFullItemTwo).pipe(
					skipWhile(job => !job),
					take(1),
					map((job: any) => ({
						customer_details: {
							customer: `${job?.claim?.applicant?.first_name} ${job?.claim?.applicant?.surname}`,
							'Contact Number': job?.claim?.applicant?.contact_number
						}
					}))
				)
		}
	},
	instructions: {
		editRoles: {
			0: 'Reply to underwriter query'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			name: 'Summary',
			showTabs: true,
			initFormFields: () => {},
			component: {
				children: [
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							headingConfig: { title: 'Job Summary', color: 'default', size: 'medium' },
							subHeadingConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							keyValueHeadingConfig: { color: 'secondary' },
							objectInfo$: 'jobSummary'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'summaryDetails'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'assessorDetails'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'customerDetails'
						}
					}
				]
			},
			navs: [
				{
					text: 'Respond',
					nextNode: 'ServiceProviderResponse',
					color: 'default'
				}
			]
		},
		ServiceProviderResponse: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Respond to underwriter'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: "Please respond to the underwriter's query",
									inputType: 'textarea',
									formControlName: 'mfrf_response'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: bf => {
				bf.patchValues({ mfrf_response: bf.getControl('mfrf_response')?.value || '', new_state: 318 });
				bf.bigForm.get('mfrf_response')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			checkValidityForFields: ['mfrf_response'],
			navs: [
				{
					text: 'Submit To SP',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					color: 'primary'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			serverCalls: {
				response: {
					serviceVariable: 'betService',
					functionName: 'updateJob',
					errorMessage: 'Job could not be updated!!'
				}
			},
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		current_state: [() => 317, 'current_state'],
		mfrf_response: [
			(qr, storeObj) => {
				let query = [];
				const FI2 = storeObj['selectedContext']?.fullItemTwo;
				if (FI2?.job_information?.mfrf_query) {
					if (Array.isArray(FI2?.job_information?.mfrf_query)) {
						query = FI2?.job_information?.mfrf_query.map(_mfrfQuery => ({ ..._mfrfQuery }));
					} else {
						query = [{ ...(<object>FI2?.job_information?.mfrf_query) }];
					}
					if (qr) query[query.length - 1].queryreply = qr;
				}
				return query;
			},
			'job_information.mfrf_query'
		]
	}
};
