<flx-heading type="page" size="medium">Claim Summary</flx-heading>

<ng-container>
	<!-- <h1>Claim Information</h1> -->
	<flx-heading size="small" color="secondary">Claim Details</flx-heading>
	<flx-key-value-list [data$]="claimDetails$" align="left" width="110px"></flx-key-value-list>

	<flx-heading size="small" color="secondary">Claimant</flx-heading>
	<flx-key-value-list [data$]="claimantData$" align="left" width="110px"></flx-key-value-list>

	<flx-heading size="small" color="secondary">Address</flx-heading>
	<flx-key-value-list [data$]="addressData$" align="left" width="110px"></flx-key-value-list>

	<ng-container *ngIf="whatMattersData$ | async as whatmatter">
		<flx-heading size="small" color="secondary">What Matters</flx-heading>
		<p>{{ whatmatter }}</p>
	</ng-container>
</ng-container>
