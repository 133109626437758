import { Flow_0_0_2 } from '@flexus/core';
import { MUL_176 } from './MUL_176';
import { voucherInfoNode } from '../reusable/VOUCHER_INFO_NODE';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { photoNode } from '../reusable/PHOTO_NODE';

export const MUL_187: Flow_0_0_2 = {
	...MUL_176,
	nodes: {
		...MUL_176.nodes,
		voucherData: {
			...voucherInfoNode(187)
		},
		jobInformation: {
			showTabs: true,
			...jobInfoNode(187)
		},
		photo: {
			...photoNode(187)
		}
	},
	id: '187',
	name: 'payment_request_validation_support_fixed',
	header: {
		title: 'Payment validation support fixed ',
		controls: () => () => []
	},
	instructions: {
		editRoles: {
			0: 'Payment Validation Request - Support Fixed'
		},
		viewRoles: {
			0: 'Payment Validation Request - Support Fixed'
		}
	}
};
