import { SearchFunc_0_0_2 } from '@flexus/core';
import { addObjectProperty, deleteObjectProperty } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';

export function setToArray<T>(s: Set<T>): Array<T> {
	const values = s.values();
	const arrOut: Array<T> = [];
	for (let entry = values.next(); entry.done === false; entry = values.next()) {
		arrOut.push(entry.value);
	}
	return arrOut;
}

export function setToObject<T>(s: Set<T>, fn: (t: T) => string): { [k: string]: T } {
	const values = s.values();
	const objOut: { [key: string]: T } = {};
	for (let entry = values.next(); entry.done === false; entry = values.next()) {
		objOut[fn(entry.value)] = entry.value;
	}
	return objOut;
}

export function sortObject<T extends object>(o: T): T {
	let oOut = {};
	Object.keys(o)
		.sort((x, y) => x.localeCompare(y))
		.forEach(value => {
			oOut[value] = o[value];
		});
	return <T>oOut;
}

const bucketStateFactory = (arrStates: Array<string | number>) => claimWithJobs => {
	if (arrStates.findIndex(x => `${x}` === `${claimWithJobs.state}`) !== -1) {
		return true;
	}
	const { jobs = [] } = claimWithJobs;
	for (const currentJob of jobs) {
		if (arrStates.findIndex(x => `${x}` === `${currentJob.state}`) !== -1) {
			return true;
		}
	}
	return false;
};

export const AmpSearch: SearchFunc_0_0_2 = (storeObj, manifestObj) => {
	const states = storeObj?.allInfo?.[0]?.states?.reduce((acc, state) => ({ ...acc, [state.id]: state }), {}) ?? {};
	const claims = manifestObj.viewData.default || [];
	const sps = storeObj?.allInfo?.[0]?.sps && storeObj.allInfo[0]?.sps.reduce((acc, sp) => ({ ...acc, [sp.id]: sp }), {});
	const skills = storeObj?.allInfo?.[0]?.skills && storeObj.allInfo[0]?.skills.reduce((acc, skill) => ({ ...acc, [skill.id]: skill }), {});

	const claimList = [];
	const joblist = [];

	for (const claim of claims) {
		const { jobs = [], ...claimRest } = <any>claim;
		claimList.push(claimRest);
		joblist.push(...jobs);
	}

	const rawStateIds = new Set();
	const rawRegion = new Set();
	const rawSpIds = new Set();
	const rawSkillIds = new Set();

	for (const claim of claimList) {
		rawStateIds.add(claim?.state);
	}
	for (const job of joblist) {
		rawRegion.add(job.suburb);
		rawStateIds.add(job.state);
		rawSpIds.add(job.sp);
		rawSkillIds.add(job.skill);
	}
	// now those raw lists are set, so now to create the context's for them
	const filters = {};
	let stateContext = setToObject(rawStateIds, (state: any) => `${states[state] ? states[state]?.description : 'Not Yet Set'}  : ${state ? state : ''}`);
	let regionContext = setToObject(rawRegion, (region: any) => `${region}`.toLocaleLowerCase()?.replace(/^./gi, value => value.toLocaleUpperCase()));
	let spContext = setToObject(rawSpIds, (id: any) => (id !== null ? sps[id]?.name : 'No SP Assigned'));
	let skillsContext = setToObject(rawSkillIds, (skillId: any) => (skillId !== null ? skills[skillId]?.name : 'No Skill Set yet'));
	// now to sort them
	stateContext = sortObject(stateContext);
	regionContext = sortObject(regionContext);
	spContext = sortObject(spContext);
	skillsContext = sortObject(skillsContext);
	return {
		searchEndpoint: environment.api_url + `v1/claim_action/search_claims/`,
		filters: {
			'Job Value': {
				type: 'numericRange',
				configuration: {
					min: 0,
					prompt: 'Enter values to filter claims',
					type: 'numericRange'
				},
				paths: [],
				filterFactory:
					([min, max]) =>
					claimWithJobs => {
						const remainingJobs = [];
						const { jobs = [] } = claimWithJobs;
						for (const currentJob of jobs) {
							const currentVal = parseFloat(currentJob.claim_value);
							if (isNaN(currentVal) === false && min <= currentVal && max >= currentVal) {
								remainingJobs.push(currentJob);
							}
						}
						if (remainingJobs.length > 0) {
							deleteObjectProperty('jobs', claimWithJobs);
							claimWithJobs = addObjectProperty('jobs', remainingJobs, claimWithJobs);
							// if anything remains, erase the current and replace with new set
							return true;
						}
						return false;
					}
			},
			State: {
				type: 'select',
				allInfoName: 'states',
				configuration: {
					context: sortObject(setToObject(rawStateIds, (state: any) => `${states[state] ? states[state]?.description : 'Not Yet Set'}  : ${state ? state : ''}`)),
					prompt: ' Select state',
					type: 'select'
				},
				paths: [],
				filterFactory:
					([stateValue]) =>
					claimWithJobs => {
						if (`${claimWithJobs.state}` === `${stateValue}`) {
							return true;
						}
						const remainingJobs = [];
						const { jobs = [] } = claimWithJobs;
						for (const currentJob of jobs) {
							if (`${currentJob.state}` === `${stateValue}`) {
								remainingJobs.push(currentJob);
							}
						}
						if (remainingJobs.length > 0) {
							deleteObjectProperty('jobs', claimWithJobs);
							claimWithJobs = addObjectProperty('jobs', remainingJobs, claimWithJobs);
							// if anything remains, erase the current and replace with new set
							return true;
						}
						return false;
					}
			},
			'Required Skill': {
				type: 'select',
				allInfoName: 'skills',
				configuration: {
					context: sortObject(setToObject(rawSkillIds, (skillId: any) => (skillId !== null ? skills[skillId]?.name : 'No Skill Set yet'))),
					prompt: 'Select skill',
					type: 'select'
				},
				paths: [],
				filterFactory:
					([skillId]) =>
					claimWithJobs => {
						const remainingJobs = [];
						const { jobs = [] } = claimWithJobs;
						for (const currentJob of jobs) {
							if (`${currentJob.skill}` === `${skillId}`) {
								remainingJobs.push(currentJob);
							}
						}
						if (remainingJobs.length > 0) {
							deleteObjectProperty('jobs', claimWithJobs);
							claimWithJobs = addObjectProperty('jobs', remainingJobs, claimWithJobs);
							// if anything remains, erase the current and replace with new set
							return true;
						}
						return false;
					}
			},
			Suburb: {
				type: 'select',
				configuration: {
					context: sortObject(setToObject(rawRegion, (region: any) => `${region}`.toLocaleLowerCase()?.replace(/^./gi, value => value.toLocaleUpperCase()))),
					prompt: 'Select suburb',
					type: 'select'
				},
				paths: [],
				filterFactory:
					([regionStr]) =>
					claimWithJobs => {
						const remainingJobs = [];
						const { jobs = [] } = claimWithJobs;
						for (const currentJob of jobs) {
							if (`${currentJob.suburb}`.toLocaleLowerCase() === `${regionStr}`.toLocaleLowerCase()) {
								remainingJobs.push(currentJob);
							}
						}
						if (remainingJobs.length > 0) {
							deleteObjectProperty('jobs', claimWithJobs);
							claimWithJobs = addObjectProperty('jobs', remainingJobs, claimWithJobs);
							// if anything remains, erase the current and replace with new set
							return true;
						}
						return false;
					}
			},
			'Service Provider': {
				type: 'select',
				allInfoName: 'sps',
				configuration: {
					context: sortObject(setToObject(rawSpIds, (id: any) => (id !== null ? sps[id]?.name : 'No SP Assigned'))),
					prompt: 'Select SP',
					type: 'select'
				},
				filterFactory:
					([spId]) =>
					claimWithJobs => {
						const remainingJobs = [];
						const { jobs = [] } = claimWithJobs;
						for (const currentJob of jobs) {
							if (`${currentJob.sp}` === `${spId}`) {
								remainingJobs.push(currentJob);
							}
						}
						if (remainingJobs.length > 0) {
							deleteObjectProperty('jobs', claimWithJobs);
							claimWithJobs = addObjectProperty('jobs', remainingJobs, claimWithJobs);
							// if anything remains, erase the current and replace with new set
							return true;
						}
						return false;
					},
				paths: []
			}
		},
		buckets: {
			mapper: {
				'Servicing: Appoint SP': bucketStateFactory([20, 77]),
				'Work for SP': bucketStateFactory([21, 22])
			}
		}
	};
};
