import { Flow_0_0_2, getFullItemOne, getCurrentUser } from '@flexus/core';
import { filter, skipWhile, first, map, take, pluck } from 'rxjs/operators';
import { SIL_197 } from './SIL_197';
import { UntypedFormControl } from '@angular/forms';

export const SIL_198: Flow_0_0_2 = {
	id: '198',
	name: 'upfront_repudiation_unallocated_afterhours_claim',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Claim Created After Hours - Upfront Repudiation Unallocated: ${
							itemOne?.loan_information?.mavenclaimnumber ? itemOne?.loan_information?.mavenclaimnumber : itemOne?.loan_information?.mavenclaimnumber
						} - ${itemOne.applicant?.surname}`;
					} else {
						return 'Claim Created After Hours - Upfront Repudiation Unallocated';
					}
				})
			);
		},
		controls: () => () => []
	},
	instructions: {
		editRoles: {
			0: 'Allocate Claim'
		},
		viewRoles: {
			0: 'Allocate Claim'
		}
	},
	footer: {
		type: 'node_nav'
	},
	itemType: 'flow',
	serverCalls: {
		files: {
			serviceVariable: 'silService',
			functionName: 'getAllClaimFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		logs: {
			serviceVariable: 'silService',
			functionName: 'getCallLogs',
			errorMessage: 'No call logs could be found!'
		}
	},
	startNode: 'ConfirmClaim',
	nodes: {
		ConfirmClaim: {
			...SIL_197?.nodes?.ConfirmClaim,
			inputs: {
				...SIL_197?.nodes?.ConfirmClaim?.inputs,
				inputHeading: 'Unallocated Repudiation Claim'
			},
			initFormFields: (bf, item, inst, sq, store) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(19));
				store
					.select(getCurrentUser)
					.pipe(
						skipWhile(x => !x),
						pluck('user', 'id'),
						take(1)
					)
					.subscribe(id => {
						bf.bigForm.addControl('handler', new UntypedFormControl(id));
					});
			}
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		...SIL_197.bigFormToStoreMapper
	}
};
