<flx-heading size="medium" align="center" weight="extra-light" type="creation">{{ title }}</flx-heading>

<form *ngIf="bf.bigForm" [formGroup]="bf.bigForm">
	<flx-flex-container direction="row" justifyContent="center" maxWidth="50vw">
		<ng-container *ngFor="let formItem of $any(formGroupItems)">
			<flx-panel density="cozy" [formGroupName]="formItem?.formGroupName" margin="8">
				<ng-container *ngFor="let formControl of formItem?.controls">
					<flx-label>{{ formControl?.label }}</flx-label>
					<ng-container [ngSwitch]="formControl?.elementType">
						<flx-input-text
							*ngSwitchCase="'input'"
							id="{{ formControl?.formControlName }}"
							[formControlName]="formControl?.formControlName"
							type="{{ formControl?.elementVars?.type }}"
							size="normal"
							[noEndingSpace]="formControl?.elementVars?.noEndingSpace !== undefined ? formControl?.elementVars?.noEndingSpace : false"
							[onlyValidPhoneChars]="formControl?.elementVars?.type === 'contact_number' || formControl?.elementVars?.type === 'tel'"
						></flx-input-text>
						<flx-dropdown
							*ngSwitchCase="'dropdown'"
							id="{{ formControl?.formControlName }}"
							[itemsOption]="formControl?.elementVars?.options"
							[displayOptions]="{ displayKey: 'display', valueKey: 'value' }"
							[formControlName]="formControl?.formControlName"
							placeHolder="{{ formControl?.elementVars?.placeholder }}"
							[searchEnabled]="formControl?.elementVars?.searchEnabled !== undefined ? formControl?.elementVars?.searchEnabled : false"
						>
						</flx-dropdown>
					</ng-container>
					<flx-reactive-form-error-display [control]="$any(bf)?.bigForm.get(formItem?.formGroupName)['controls'][formControl?.formControlName]"></flx-reactive-form-error-display>
				</ng-container>
			</flx-panel>
		</ng-container>
	</flx-flex-container>
</form>
