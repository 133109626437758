<div *ngFor="let key of errorKeys">
	<ng-container
		*ngIf="errors[key].retryCall?.displayError === null || errors[key].retryCall?.displayError === undefined || errors[key].retryCall?.displayError || errors[key]?.error?.reason"
	>
		<div class="warning-error">
			<div class="error-container">
				<flx-icon class="alert-icon" [type]="'alert'" color="button" [isClickable]="false"></flx-icon>
				<div class="error-messages">
					<div class="error-code" *ngIf="!(errors[key]?.error | isEmpty) && errors[key]?.error?.message">
						{{ errors[key]?.error?.message.substr(errors[key].error.message.lastIndexOf(':') + 1, errors[key].error.message.length) }}
					</div>
					<div class="error-message" *ngIf="errors[key]?.errorMessage">{{ errors[key]?.errorMessage }}</div>
					<div class="error-message" *ngIf="errors[key]?.error?.error?.reason || errors[key]?.error?.reason">
						Server Response: &nbsp;{{ errors[key]?.error?.error?.reason || errors[key]?.error?.reason }}
					</div>
				</div>
			</div>
			<div class="buttons">
				<flx-icon
					*ngIf="node?.errorHandler?.retryPolicy === 'manual'"
					class="alert-icon"
					[type]="'refresh'"
					color="button"
					[isClickable]="true"
					instruction="Retry"
					(click)="onRetry(errors[key].retryCall)"
				></flx-icon>
				<flx-icon class="alert-icon" [type]="'remove'" color="button" instruction="Dismiss" [isClickable]="true" (click)="onCancel(key)"></flx-icon>
				<!-- <button *ngIf="node?.errorHandler?.retryPolicy === 'manual'" (click)="onRetry(errors[key].retryCall)">Retry</button> -->
			</div>
		</div>
	</ng-container>
</div>
