<flx-flex-container alignItems="center">
	<div class="logo-display"><span class="sb-logo" [inlineSVG]="logo_url"></span></div>
	<flx-heading size="small" align="center" weight="bold" margin="2rem 0 4rem 0">{{ byline }}</flx-heading>
	<flx-panel class="glow-panel" hasBackground="true" density="cozy" width="400px" maxWidth="400px" margin="auto">
		<flx-heading size="extraLarge" type="page">Reset Password</flx-heading>

		<div [hidden]="!isSuccess" class="alert valid">Success</div>
		<form [formGroup]="resetForm" novalidate (submit)="resetPassword()">
			<fieldset [disabled]="isSubmitting$ | async" class="form-group">
				<flx-input-text type="password" class="form-input" formControlName="newPassword" placeholder="New Password" autocomplete="false" size="medium"> </flx-input-text>
				<flx-input-text type="password" class="form-input" formControlName="confirmPassword" placeholder="Confirm New Password" autocomplete="false" size="medium">
				</flx-input-text>
				<flx-button class="reset-button" color="primary" [disabled]="resetForm.invalid">Rest Password</flx-button>
			</fieldset>
		</form>
	</flx-panel>
	<div class="sure-logo"><span>Powered by</span> <img src="/assets/images/4-sure-logo-new.svg" /></div>
</flx-flex-container>
