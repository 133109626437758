import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import * as RemindersActions from './reminders.actions';
import { ReminderService } from '../services/reminder.service';

@Injectable()
export class RemindersEffects {
	constructor(private actions$: Actions, private reminderService: ReminderService) {}

	getReminders$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RemindersActions.getReminders),
			switchMap(action => this.reminderService.getReminders()),
			map(rems => RemindersActions.getRemindersSuccess({ reminders: rems }))
		)
	);

	addReminder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RemindersActions.addReminder),
			switchMap(action => {
        this.reminderService.snooze(action.reminder);
        return this.reminderService.addReminder(action.reminder);
      }),
			map(rem => RemindersActions.getReminders())
		)
	);

	updateReminder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RemindersActions.updateReminder),
			switchMap(action => this.reminderService.updateReminder(action.reminder)),
			map(rem => RemindersActions.getReminders())
		)
	);

	deleteReminder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RemindersActions.deleteReminder),
			switchMap(action => this.reminderService.deleteReminder(action.id)),
			map(rem => RemindersActions.getReminders())
		)
	);

	// Deal with Active reminders

	getActiveReminders$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RemindersActions.getActiveReminders),
			switchMap(action => this.reminderService.getActiveReminders()),
			map(rems => RemindersActions.getActiveRemindersSuccess({ reminders: rems }))
		)
	);

	addActiveReminder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RemindersActions.addActiveReminder),
			switchMap(action => this.reminderService.addActiveReminder(action.reminder)),
			map(rem => RemindersActions.getActiveReminders())
		)
	);

	deleteActiveReminder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RemindersActions.deleteActiveReminder),
			switchMap(action => this.reminderService.deleteActiveReminder(action.id)),
			map(rem => RemindersActions.getActiveReminders())
		)
	);
}
