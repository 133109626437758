<flx-heading>{{ header }} </flx-heading>
<flx-heading size="extraSmall">{{ instruction }} </flx-heading>

<div *ngIf="otherJobs$ | async as otherJobs">
	<div class="jobs" *ngIf="skills$ | async as skills">
		<flx-button class="btn btn-lg" *ngFor="let job of otherJobs" (clicked)="toggleOtherJobSelection(job)" [ngClass]="{ 'btn-primary': checkIfJobIsSelected(job) }">
			{{ skills[job.skill]?.name }}
		</flx-button>
	</div>

	<p *ngIf="(otherJobs | fsIsArray) && otherJobs.length == 0">No jobs available to choose from, please continue</p>
</div>
