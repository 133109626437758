import { claimPermissions } from './configs/permissions/claim.permissions';
import { jobPermissions } from './configs/permissions/job.permissions';
import { ClaimWorkflow } from './configs/workflow/claim-workflow';
import { MulCreateClaim } from './configs/create-claim/mul-create-claim';
import { MulTeamManagement } from './configs/team-management/mul-team-management';
import * as States from './configs/flows';
import { Manifest_0_0_2, getCurrentUser } from '@flexus/core';
import { MulSearch } from './configs/search/mul-search';
import { applySilVirtualStates } from './mul-virtual-state.helper';
import { MulEditClaim } from './configs/create-claim/mul-edit-claim';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { FLXAppMenuComponent } from '../../app-shell-features/app-menu/app-menu.component';
import { MulAgentDashboard } from './configs/agent-dashboard/mul-agent-dashboard';

export const mul: Manifest_0_0_2 = {
	version: '0.0.2',
	orgKey: 'mul',
	settings: {
		addActionPanel: true,
		addAppBar: true,
		addAppMenu: true,
		addFooter: true,
		addHeaderActions: true,
		addSearchBanner: true,
		addReminder: true
	},
	appMenu: (instance: FLXAppMenuComponent, store: Store<any>) => {
		return store.select(getCurrentUser).pipe(
			filter(user => !!user),
			map(res => {
				const createRoles = [1, 6];
				// const validationRoles = [22, 36];
				const menuItems = [
					{ id: 1, name: 'Workflow', routerLink: '/workflow', iconType: 'app-menu-workflow' }
					// { id: 11, name: 'Chart', routerLink: '/flow-chart', iconType: 'app-menu-workflow' }
				];

				if (res?.user?.roles?.some(role => createRoles.includes(role))) {
					menuItems.push({ id: 2, name: 'New Claim', routerLink: '/workflow/detail', iconType: 'app-menu-new-claim' });
				}
				// TODO: remove this for pre-autopay PRs
				// if (res?.user?.roles?.some(role => validationRoles.includes(role))) {
				// 	menuItems.push({ id: 3, name: 'Team', routerLink: '/workflow/detail', iconType: 'teams-area' });
				// }
				return menuItems;
			})
		);
	},
	virtualStatesFunction: applySilVirtualStates,
	appSearch: MulSearch,
	permissions: {
		itemOne: claimPermissions,
		itemTwo: jobPermissions
	},
	manifestItems: {
		// [72]: States.MUL_177,

		// [States.MUL_177.id]: States.MUL_177,
		create_item_one: MulCreateClaim,
		team_management: MulTeamManagement,
		agent_dashboard: MulAgentDashboard,
		// [8 || 172]: MulEditClaim,
		// [States.SIL_1.id]: States.SIL_1,
		workflow: ClaimWorkflow,
		[States.MUL_8.id]: MulEditClaim,
		[States.MUL_16.id]: States.MUL_16,
		[States.MUL_17.id]: States.MUL_17,
		[States.MUL_20.id]: States.MUL_20,
		[States.MUL_43.id]: States.MUL_43,
		[States.MUL_169.id]: States.MUL_169,
		[States.MUL_177.id]: States.MUL_177,
		[States.MUL_176.id]: States.MUL_176,
		[States.MUL_179.id]: States.MUL_179,
		[States.MUL_180.id]: States.MUL_180,
		[States.MUL_182.id]: States.MUL_182,
		[States.MUL_183.id]: States.MUL_183,
		[States.MUL_185.id]: States.MUL_185,
		[States.MUL_186.id]: States.MUL_186,
		[States.MUL_187.id]: States.MUL_187,
		[States.MUL_188.id]: States.MUL_188,
		[States.MUL_197.id]: States.MUL_197,
		[States.MUL_227.id]: States.MUL_227,
		[States.MUL_232.id]: States.MUL_232,
		[States.MUL_233.id]: States.MUL_233,
		[States.MUL_238.id]: States.MUL_238,
		[States.MUL_247.id]: States.MUL_247,
		[States.MUL_248.id]: States.MUL_248,
		[States.MUL_250.id]: States.MUL_250,
		[States.MUL_252.id]: States.MUL_252,
		[States.MUL_253.id]: States.MUL_253,
		[States.MUL_254.id]: States.MUL_254,
		[States.MUL_257.id]: States.MUL_257,
		[States.MUL_271.id]: States.MUL_271,
		[States.MUL_285.id]: States.MUL_285,
		[States.MUL_297.id]: States.MUL_297,
		[States.MUL_299.id]: States.MUL_299,
		[States.MUL_311.id]: States.MUL_311,
		[States.MUL_331.id]: States.MUL_331,
		[States.MUL_334.id]: States.MUL_334,
		[States.MUL_335.id]: States.MUL_335,
		[States.MUL_337.id]: States.MUL_337
	}
};
