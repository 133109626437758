<ng-container *ngIf="counter$ | async as counter"></ng-container>
<ng-template cdk-portal #lockoutTemplate="cdkPortal">
	<div class="logout-modal">
		<flx-heading color="danger" align="center">Are you still there?</flx-heading>
		<p>You are about to be logged out!</p>

		<!-- <flx-alert type="danger" [message]="timeLeft + ' seconds.'"></flx-alert> -->
		<div class="count-down">{{ timeLeft }}</div>

		<flx-button (click)="refreshToken()" color="danger">Yes!</flx-button>
	</div>
</ng-template>
