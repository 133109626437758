import { Component, OnInit, Input, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { FLXModalComponent, ModalNavButton } from '@flexus/ui-elements';
import { UUID } from 'angular2-uuid';
import { BigFormService, getCurrentUser, ManifestController, ReminderService, SetActiveManifestItem } from '@flexus/core';
import { Subscription } from 'rxjs';
import { cleanUpSub } from '@flexus/utilities';
import { Router } from '@angular/router';
import { map, skipWhile, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Reminder } from '@flexus/models';

@Component({
	selector: 'flx-add-reminder',
	templateUrl: './add-reminder.component.html',
	styleUrls: ['./add-reminder.component.scss']
})
export class FLXAddReminderComponent implements OnInit, OnDestroy {
	// ====================================================== VARIABLES ======================================================
	reminderForm: UntypedFormGroup;
	modalHeading = 'Add New Reminder';

	modalButtons: ModalNavButton[];

	dateSub?: Subscription;

	validationSub: Subscription;

	error: { triggered: boolean; message: string } = { triggered: false, message: '' };

	savedDateTime: { date: any; time: any } = { date: '', time: '' };
	minLength = 0;

	client: string;
	clientsub: Subscription;
	linktoclaimHeading: string;

	// ====================================================== INPUT / OUTPUTS ======================================================
	@Input() dropdownOptions = [
		{ display: 'General', key: 'General' },
		{ display: 'Phone', key: 'Call' },
		{ display: 'Email', key: 'Email' }
	];

	@Input() minHour = 7;
	@Input() maxHour = 18;
	@Input() minuteInterval = 5;
	@Input() disabledHours: { min?: number; max?: number };

	@Input() maxDate: Date;
	@Input() minDate = new Date();

	@Input() set currentDetails(d: any) {
		this.reminderForm.patchValue(d);
	}

	@Output() addReminder = new EventEmitter();
	@Output() updateReminder = new EventEmitter();

	@ViewChild(FLXModalComponent) modal: FLXModalComponent;
	// ====================================================== LIFE CYCLE METHODS ======================================================
	constructor(private _store: Store, private remService: ReminderService, private router: Router, public bf: BigFormService, private controller: ManifestController<any>) {
		this.clientsub = this._store
			.select(getCurrentUser)
			.pipe(
				skipWhile(result => !result),
				take(1),
				map(data => (this.client = data.client))
			)
			.subscribe();
	}

	ngOnInit() {
		if (this.client === 'sil' || this.client === 'bettersure') {
			this.linktoclaimHeading = 'Link to Claim';
		} else {
			this.linktoclaimHeading = 'Link to Installation';
		}

		this.bf.addControl('search', new UntypedFormControl({ checkClosed: false, inSearch: '' }));

		this.minLength = this.remService.minLength;
		this.reminderForm = new UntypedFormGroup({
			id: new UntypedFormControl(UUID.UUID()),
			title: new UntypedFormControl('', Validators.required),
			type: new UntypedFormControl(null, Validators.required),
			date: new UntypedFormControl(moment().format('YYYY-MM-DD'), Validators.required),
			time: new UntypedFormControl('', Validators.required),
			message: new UntypedFormControl('', Validators.required)
		});
		this.validationSub = this.reminderForm?.valueChanges?.subscribe(values => {
			if (!!values.time && !!values.date) {
				if (this.savedDateTime.time !== values.time || this.savedDateTime.date !== values.date) {
					this.savedDateTime = { time: values.time, date: values.date };
					if (!this.validateTime(values.time, values.date)) {
						this.error = { triggered: true, message: 'The time entered has already passed.' };
					} else {
						this.error = { triggered: false, message: '' };
					}
				}
			}
		});

		setTimeout(() => {
			if (this.bf.bigForm.get('itemOne')?.value) {
				this.bf.bigForm.patchValue({
					search: {
						...this.bf?.bigForm?.get('search')?.value,
						inSearch: this.bf.bigForm.get('itemOne')?.value?.mid
					}
				});
			} else {
				this.bf.bigForm.patchValue({
					search: {
						...this.bf?.bigForm?.get('search')?.value,
						inSearch: ''
					}
				});
			}

			this.bf?.bigForm?.get('search')?.markAsDirty();
		}, 2000);
	}

	// ====================================================== METHODS ======================================================
	open(addingToClaim?: boolean) {
		this.resetForm();
		this.modalButtons = [
			{
				text: 'Cancel',
				linkType: 'close',
				clickHandler: () => {
					if (addingToClaim) {
						this.resetWorkflow();
					}
				}
			},
			{
				text: 'Add Reminder',
				clickHandler: event => {
					if (this.reminderForm.valid) {
						if (this.validateTime(this.reminderForm?.value?.time, this.reminderForm?.value?.date)) {
							this.addReminder.emit(this.buildReminderData(addingToClaim));
							cleanUpSub(this.dateSub);
							this.modal.close();
							if (addingToClaim) {
								this.resetWorkflow();
							}
						}
					}
				}
			}
		];
		this.modal.open();
	}

	validateTime(time: { hour: string; minutes: string }, date: any) {
		const currHours = new Date().getHours();
		const currMin = new Date().getMinutes();
		const testMin = parseInt(time.minutes, 10);
		const testHours = parseInt(time.hour, 10);
		if (moment(date).isAfter(moment())) {
			return true;
		} else {
			if (testHours > currHours) {
				return true;
			} else {
				if ((testHours === currHours && testMin <= currMin) || testHours < currHours) {
					return false;
				} else {
					return true;
				}
			}
		}
	}

	resetForm() {
		this.modalHeading = 'Add New Reminder';
		this.error = { triggered: false, message: '' };
		this.reminderForm.patchValue({
			id: UUID.UUID(),
			title: ' ',
			type: 'General',
			date: moment().format('YYYY-MM-DD'),
			time: null,
			message: ' '
		});
		this.setSelectedDateType(moment(this.reminderForm.get('date')?.value));
		this.dateSub = this.reminderForm.get('date')?.valueChanges?.subscribe(date => {
			this.setSelectedDateType(moment(date));
		});
	}

	setSelectedDateType(d: moment.Moment) {
		const today = moment().startOf('day');
		this.disabledHours = {};
		if (d.isSame(today)) {
			this.disabledHours = { max: new Date().getHours() - 1 };
		}
	}

	openInEditMode(data: any) {
		const time = this.splitTime(data?.time);
		this.modalHeading = 'Edit Reminder: ' + data?.title;
		this.reminderForm.patchValue({
			id: data?.id,
			title: data?.title,
			type: data?.type,
			date: data?.date,
			time: { hour: time.hour, minutes: time.min },
			message: data?.message
		});

		this.modalButtons = [
			{
				text: 'Cancel',
				linkType: 'close'
			},
			{
				text: 'Update Reminder',
				clickHandler: event => {
					if (this.reminderForm.valid) {
						if (this.reminderForm.valid) {
							if (this.validateTime(this.reminderForm?.value?.time, this.reminderForm?.value?.date)) {
								this.updateReminder.emit(this.buildReminderData());
								this.resetForm();
								this.modal.close();
							}
						}
					}
				}
			}
		];
		this.modal.open();
	}

	buildReminderData(withClaimNo?: boolean): Reminder {
		const formValues = this.reminderForm.value;
		return {
			id: formValues.id,
			title: formValues.title,
			type: formValues.type,
			date: formValues.date,
			time: formValues.time.hour + ':' + formValues.time.minutes,
			message: formValues.message,
			...(withClaimNo && { claimNo: this.bf.bigForm.get('search')?.value?.inSearch })
		} as Reminder;
	}

	splitTime(time: string): { hour: any; min: any } {
		const hour = time.substr(0, time.indexOf(':'));
		const min = time.substr(time.indexOf(':') + 1, time.length);
		return { hour, min };
	}

	ngOnDestroy(): void {
		cleanUpSub(this.validationSub);
		if (this.clientsub) {
			this.clientsub.unsubscribe();
		}
	}

	doSearch() {
		this.remService.doClaimSearch();
	}

	clearSearch() {
		this.remService.clearClaimSearch();
	}

	private resetWorkflow() {
		this.controller.dispatch(
			new SetActiveManifestItem({
				pathToFlows: ['manifestItems'],
				orgKey: this.remService._activeOrg.orgKey,
				itemId: 'workflow'
			})
		);
	}
}
