<div class="sorting" *ngFor="let sortField of this._sortFields">
	<span>
		<div *ngIf="sortField.displayName as fieldName" (click)="cycleField(fieldName)">
			<h4 *ngIf="sortField.displayName[0] != '_'">{{ fieldName }}:</h4>
			<span *ngIf="sortField.context as context">{{ context[_direction[fieldName]] }}</span>
			<!-- display when ascending -->
			<i *ngIf="_direction[fieldName] === _sortStates.ascending" class="icon icon-light icon-sm search-icon" [inlineSVG]="'/assets/icons/sort-ascending.svg'"></i>
			<!-- display when descending -->
			<i *ngIf="_direction[fieldName] === _sortStates.descending" class="icon icon-light icon-sm search-icon" [inlineSVG]="'/assets/icons/sort-descending.svg'"></i>
			<!-- display field isn't being filtered -->
			<i data-title="click to sort" *ngIf="_direction[fieldName] === _sortStates.inactive" class="icon icon-sm search-icon" [inlineSVG]="'/assets/icons/sort-not-applied.svg'"></i>
		</div>
	</span>
</div>
