<flx-loader-component *ngIf="isLoading"></flx-loader-component>
<flx-invoice-daterange-picker></flx-invoice-daterange-picker>
<div class="container" style="margin-top: 50px">
	<ng-container
		*ngIf="
			invoices$
				| async
				| paginate
					: {
							itemsPerPage: paginationVariables.pageSize,
							currentPage: paginationVariables.currentPage,
							id: 'mc_payments_invoice'
					  } as payments;
			else noInvoicesLoaded
		"
	>
		<flx-heading size="medium" align="center">Invoices Available For Downloading</flx-heading>
		<div class="invoice-table-headings" *ngIf="!numberedInvoiceExists$">
			<div class="date-heading">Date</div>
			<div class="invoice_number-heading">Invoice number</div>
			<div class="amount-heading">Amount</div>
			<div class="state-heading">State</div>
			<div></div>
		</div>
		<div *ngFor="let payment of payments">
			<div class="payment-container">
				<!-- <div><flx-checkbox></flx-checkbox></div> -->
				<div>{{ payment.Date }}</div>
				<div>{{ payment.InvoiceNumber }}</div>
				<div>{{ payment.TotalInclVAT }}</div>
				<div>{{ payment.State }}</div>
				<div><flx-button [disabled]="payment.State === 'Failed'" (click)="download_invoice(payment.InvoiceNumber)">DOWNLOAD INVOICE</flx-button></div>
			</div>
			<div class="glowline-container">
				<flx-glow-line></flx-glow-line>
			</div>
		</div>
		<pagination-controls
			id="mc_payments_invoice"
			*ngIf="(invoices$ | async)?.length > paginationVariables.pageSize"
			class="flx-pagination"
			(pageChange)="page($event)"
			previousLabel="←"
			nextLabel="→"
		>
		</pagination-controls>
	</ng-container>
	<ng-container *ngIf="numberedInvoiceExists$ | async">
		<div class="invoice-headings">
			<div class="date-heading">File Name</div>
			<div class="invoice_number-heading">Date</div>

			<div></div>
		</div>
		<div class="invoice-container">
			<!-- <div><flx-checkbox></flx-checkbox></div> -->
			<div>{{ invoice.CreationDate }}</div>
			<div>{{ invoice.FileName }}</div>

			<div><flx-button (click)="save_invoice()">DOWNLOAD INVOICE</flx-button></div>
		</div>
		<div class="glowline-container">
			<flx-glow-line></flx-glow-line>
		</div>
	</ng-container>

	<ng-template #noInvoicesLoaded>
		<flx-heading size="large" align="center">No Invoices To View</flx-heading>
		<flx-heading size="small" align="center">Enter an invoice number or select a period to search against</flx-heading>
	</ng-template>
</div>
