
import { Filter } from "@flexus/models";

/**
 * TODO:
 * IN PROGRESS DO NOT USE
 * util functions
 * TODO:
 */
export const pickOutValues = (obj: any, path: string) => {
	return null;
};

/**
 * the bellow was exrracted from the filter pipe. to be reused throughout the project
 */
export type FilterIndexFunction = (element: any) => string | number;
/**
 * helper function to remove duplicates from a given array
 * @param arr array to screen out the duplicated
 * @param indexBy function used to get the object that will be compared and used to determine what is a duplicate
 */
export function removeDuplicates(arr: any[], indexBy: FilterIndexFunction = x => x) {
	const objOut = new Map();
	// the bellow will naturally overwrite all duplicates
	for (const entry of arr) {
		objOut.set(indexBy(entry), entry);
	}
	return objOut.values;
}

/**
 * used to compare 2 arrays, will return false if they are different based on key referred to
 * by indexby
 * @param arr1 type t
 * @param arr2 must mach type 1
 * @param indexBy used to compare, make sure this returns a number
 */
export function compareArrays(arr1: any[], arr2: any[], indexBy: FilterIndexFunction) {
	const objOut = new Set();
	for (const entry of arr1) {
		objOut.add(indexBy(entry));
	}
	// now to reduce the second to see if any keys dont exist
	// checking that all keys in second exist in first
	for (const entry of arr2) {
		// will make it false if the object didnt exist to delete
		if (objOut.delete(indexBy(entry)) === false) {
			return false;
		}
	}
	// checking all keys in first existed in the second
	return objOut.size === 0;
	//
}

export function compareFilterSets(filterSet1: Filter[], filterSet2: Filter[]) {
	if (filterSet1.length !== filterSet2.length) {
		return false;
	}

	const filterObj = {};
	for (const entry of filterSet1) {
		const idx = filterSet2.findIndex(filter => entry.field === filter.field);
		// doesnt exist or arent equal
		if (idx === -1 || entry.filterValue.source.toLocaleLowerCase().localeCompare(filterSet2[idx]?.filterValue.source.toLocaleLowerCase()) !== 0) {
			return false;
		}
	}
	return true;
}
