<flx-modal type="job-notifications" heading="Job Notifications" [navButtons]="modalButtons" [backgroundClose]="false" [messageOnly]="false">
	<div class="notifications-container">
		<ng-container *ngIf="jobsAwarded | async as ja">
			<ng-container *ngIf="ja.length > 0">
				<div
					class="notification-type"
					*ngIf="
						ja
							| paginate
								: {
										itemsPerPage: paginationVariables.pageSize,
										currentPage: paginationVariables.jaCurrentPage,
										id: 'sp_jobs_awarded'
								  } as ja_limited
					"
				>
					<div class="notification-header notification-header-image-awarded">You have been awarded the following jobs:</div>
					<flx-job-notification-detail *ngFor="let job of ja_limited" [notificationDetails]="job"></flx-job-notification-detail>
					<pagination-controls
						id="sp_jobs_awarded"
						*ngIf="ja.length > paginationVariables.pageSize"
						class="flx-pagination"
						(pageChange)="paginatorPage($event, 'jobsawarded')"
						previousLabel="←"
						nextLabel="→"
					>
					</pagination-controls>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="jobsLost | async as jl">
			<ng-container *ngIf="jl.length > 0">
				<div
					class="notification-type"
					*ngIf="
						jl
							| paginate
								: {
										itemsPerPage: paginationVariables.pageSize,
										currentPage: paginationVariables.jlCurrentPage,
										id: 'sp_jobs_lost'
								  } as jl_limited
					"
				>
					<div class="notification-header notification-header-image-not-awarded">You have NOT been awarded the following jobs.</div>
					<flx-job-notification-detail *ngFor="let job of jl_limited" [notificationDetails]="job"></flx-job-notification-detail>
					<pagination-controls
						id="sp_jobs_lost"
						*ngIf="jl.length > paginationVariables.pageSize"
						class="flx-pagination"
						(pageChange)="paginatorPage($event, 'jobslost')"
						previousLabel="←"
						nextLabel="→"
					>
					</pagination-controls>
				</div>
			</ng-container>
		</ng-container>
	</div>
</flx-modal>
