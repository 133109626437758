import { Component, Input, OnInit } from '@angular/core';
import { Observable, } from 'rxjs';
import { map, skipWhile, take, } from 'rxjs/operators';
import moment from 'moment';

@Component({
	selector: 'flx-policy-history',
	templateUrl: './policy-history.component.html',
	styleUrls: ['./policy-history.component.scss']
})
export class FLXPolicyHistoryComponent implements OnInit {
	@Input() policyHistory$: Observable<any> = null;

	constructor() {}

	ngOnInit() {
		this.policyHistory$ = this.policyHistory$.pipe(
			skipWhile(x => !x),
			take(1),
			map((items: any) =>
				items.length > 0
					? items.map(item => ({
							title: `${moment(item.date.value).format('DD MMM YYYY')} - ${item.eventType.value}`,
							...item
					  }))
					: []
			)
		);
	}
}
