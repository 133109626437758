import { Component, OnInit, Input, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BigFormService, ModalService } from '@flexus/core';
import { AppointmentTypesToExclude } from '@flexus/utilities';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { KeyValueListData } from '@flexus/ui-elements';

@Component({
	selector: 'flx-job-appointment',
	templateUrl: './job-appointment.component.html',
	styleUrls: ['./job-appointment.component.scss']
})
export class FLXJobAppointmentComponent implements OnInit {
	@Input() customer_details$!: Observable<KeyValueListData>;
	@Input() onsite_details$!: Observable<KeyValueListData>;
	@Input() existing_appointment$!: Observable<KeyValueListData>;
	@Input() appointmentTypes$!: Observable<{ id: number; name: string }[]>;
	@Input() minDate!: Date;
	@Input() maxDate!: Date;
	@Input() minHour!: number;
	@Input() maxHour!: number;
	@Input() minuteInterval!: number;
	// @Input() showExistingAppointment = false;

	// for after hours
	@Input() checkAfterHours = false;
	@Input() afterHoursEndpoint: string = null;
	@Input() afterHoursSkills: number[] = []; // Skills that will not trigger a warning about setting a time after hours

	// for takealot manual allocation; could be used for any client
	@Input() showExistingAppointment!: boolean;

	timeFormat = 'hh:mm:ss';
	defaultAfterHoursTime = { start: moment('07:00', this.timeFormat), end: moment('17:00', this.timeFormat) }; // Anything after this time will be considered after hour call out

	public appointmentData;
	public mustIncludeSkillDetails = true;
	public appointmentTypesToExclude = AppointmentTypesToExclude.list;

	constructor(public bf: BigFormService, private modal: ModalService, private http: HttpClient, @Inject('environment') private _environment: any) {}

	ngOnInit() {
		this.appointmentData = this.bf.bigForm.get('appointmentData');
	}

	checkForAfterHours(appData: any, skillId: number) {
		if (this.checkAfterHours) {
			const appointment = `${moment(appData.date).format('YYYY-MM-DD')} ${appData.time.hour}:${appData.time.minutes}`;
			this.isAfterHours(appointment)
				.pipe(take(1))
				.subscribe((data: any) => {
					if (data.success) {
						if (data.payload) {
							this.triggerAfterHoursWarning(skillId);
						}
					} else {
						this.defaultCheck(appData, skillId);
					}
					return data;
				});
		}

		// TODO check if offline... if so do default checks
	}

	defaultCheck(appData: any, skillId: number) {
		const selectedTime = moment(`${appData.time.hour}:${appData.time.minutes}`, this.timeFormat);
		if (selectedTime.isAfter(this.defaultAfterHoursTime.end) || selectedTime.isBefore(this.defaultAfterHoursTime.start)) {
			console.log('Is after hours');
			this.triggerAfterHoursWarning(skillId);
		}
	}

	triggerAfterHoursWarning(skillId) {
		if (!this.afterHoursSkills.includes(skillId)) {
			// Not an afterhours skill -- Show warning
			this.modal.openModalDirectly(instance => {
				instance.type = 'warning';
				instance.heading = 'Are you sure you selected the correct time?';
				instance.subheading = 'The SP will charge an After Hours rate.';
				instance.message = 'The time you have entered falls out of normal working hours';
				instance.closeButton = false;
				instance.navButtons = [
					{
						text: 'Close',
						linkType: 'close',
						color: 'alert'
					}
				];
			});
		}
	}

	isAfterHours(data): Observable<any> {
		return this.http.post<any>(this._environment.api_url + this.afterHoursEndpoint, { appointment: data });
	}
}
