<ng-container>
	<ng-container *ngIf="theList$ | async as items">
		<ng-container *ngIf="items.length >= 1">
			<div *ngFor="let item of items | paginate: { itemsPerPage: pageSize, currentPage: currentPage, id: 'list' }; index as i; trackBy: trackByFunc">
				<flx-mul-claim-card
					[itemOne]="item"
					[itemOneContextMenuList]="itemOneContextMenuList"
					[itemTwoContextMenuList]="itemTwoContextMenuList"
					[isOnline]="isOnline$ | async"
					[allInfo]="allInfo$ | async"
					[stateDescriptions]="stateDescriptions$ | async"
					[claimTypeDescriptions]="claimTypeDescriptions$ | async"
					[activeOrg]="activeOrg$ | async"
					[itemOnePermissions]="itemOnePermissions"
					[itemTwoPermissions]="itemTwoPermissions"
					[user]="user$ | async"
				>
				</flx-mul-claim-card>
			</div>
		</ng-container>
	</ng-container>
</ng-container>
