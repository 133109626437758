import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIElementsModule } from '@flexus/ui-elements';
import { FLXUserCardViewModule } from './user-card-view/user-card-view.module';
import { FLXUserCardEditModule } from './user-card-edit/user-card-edit.module';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, FormsModule, UIElementsModule],
	declarations: [],
	exports: [FLXUserCardViewModule, FLXUserCardEditModule]
})
export class SpUserCardsModule {}
