export class DarkTheme {
	// getComputedStyle(document.documentElement).getPropertyValue('--background')
	public static styles: google.maps.MapTypeStyle[] = [
		{
			//background-color
			elementType: 'geometry',
			stylers: [
				{
					color: '#212121'
				}
			]
		},
		{
			// provincial borders text fill
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#757575'
				}
			]
		},
		{
			// provincial borders text outline
			elementType: 'labels.text.stroke',
			stylers: [
				{
					color: '#212121'
				}
			]
		},
		{
			//country borders
			featureType: 'administrative',
			elementType: 'geometry',
			stylers: [
				{
					color: '#757575'
				}
			]
		},
		{
			//country name text fill
			featureType: 'administrative.country',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#9e9e9e'
				}
			]
		},
		{
			//propery border color
			featureType: 'administrative.land_parcel',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#323232'
				}
			]
		},
		{
			//suburb name text fill
			featureType: 'administrative.locality',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#bdbdbd'
				}
			]
		},
		{
			//poi name text fill
			featureType: 'poi',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#757575'
				}
			]
		},
		{
			//poi park name land color fill
			featureType: 'poi.park',
			elementType: 'geometry',
			stylers: [
				{
					color: '#181818'
				}
			]
		},
		{
			//poi park name text fill
			featureType: 'poi.park',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#616161'
				}
			]
		},
		{
			//poi park name text outline
			featureType: 'poi.park',
			elementType: 'labels.text.stroke',
			stylers: [
				{
					color: '#1b1b1b'
				}
			]
		},
		{
			//road fill color
			featureType: 'road',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#2c2c2c'
				}
			]
		},
		{
			//road fill color
			featureType: 'road',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#8a8a8a'
				}
			]
		},
		{
			//main road name fill color
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [
				{
					color: '#373737'
				}
			]
		},
		{
			//highway fill color
			featureType: 'road.highway',
			elementType: 'geometry',
			stylers: [
				{
					color: '#3c3c3c'
				}
			]
		},
		{
			//highway tolled fill color
			featureType: 'road.highway.controlled_access',
			elementType: 'geometry',
			stylers: [
				{
					color: '#4e4e4e'
				}
			]
		},
		{
			//small road fill color
			featureType: 'road.local',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#616161'
				}
			]
		},
		{
			//cant find this one
			featureType: 'transit',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#757575'
				}
			]
		},
		{
			//water fill color
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{
					color: '#000000'
				}
			]
		},
		{
			//water label fill color
			featureType: 'water',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#3d3d3d'
				}
			]
		}
	];
}
