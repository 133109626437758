export enum JOB_INTEREST {
	INTERESTED = 1,
	DECLINED = -1,
	IGNORED = 0
}
export enum AUTOPAY_CLAIM_STATUSES {
	CLAIMSTATUS_00 = 'Customer Number Not Valid',
	CLAIMSTATUS_01 = 'Decoder Serial Number Not Correct',
	CLAIMSTATUS_02 = 'Decoder already claimed',
	CLAIMSTATUS_03 = 'Device not linked to voucher',
	CLAIMSTATUS_04 = 'Device not active',
	CLAIMSTATUS_05 = 'Voucher not valid',
	CLAIMSTATUS_06 = 'Voucher previously claimed',
	CLAIMSTATUS_07 = 'No photos uploaded',
	CLAIMSTATUS_08 = 'Additional equipment invalid',
	CLAIMSTATUS_09 = 'Installer not accredited',
	CLAIMSTATUS_10 = 'Technician not qualified',
	CLAIMSTATUS_11 = 'LNB QR code Invalid',
	CLAIMSTATUS_12 = 'QR Code previously claimed',
	CLAIMSTATUS_13 = 'Switch ,Dish and Bracket',
	CLAIMSTATUS_14 = 'Technical Error',
	CLAIMSTATUS_15 = 'QR Code not valid',
	CLAIMSTATUS_16 = 'Dish QR code Invalid',
	CLAIMSTATUS_17 = 'Switch QR code Invalid',
	CLAIMSTATUS_18 = 'Not trained for installation capability',
	CLAIMSTATUS_19 = 'Not trained for installation type',
	CLAIMSTATUS_20 = 'Installer not participating in project',
	CLAIMSTATUS_21 = 'Invalid distributor for device',
	CLAIMSTATUS_22 = 'Invalid Bracket QR code',
	CLAIMSTATUS_23 = 'LNB ,Dish and Bracket',
	CLAIMSTATUS_24 = 'Claim is older than 39 months',
	CLAIMSTATUS_25 = 'Installer already paid',
	CLAIMSTATUS_26 = 'Duplicate Invoice',
	CLAIMSTATUS_27 = 'No XML attachment',
	CLAIMSTATUS_28 = 'Invoice not Paid',
	CLAIMSTATUS_29 = '',
	CLAIMSTATUS_30 = 'No condition',
	CLAIMSTATUS_31 = 'With condition',
	CLAIMSTATUS_32 = 'Dependant on Geolocation on CSIA - 25KM from the costal',
	CLAIMSTATUS_33 = 'Paid for with the normal voucher not Ad-Hoc',
	CLAIMSTATUS_34 = `Special approval via SUD's process for moving home`,
	CLAIMSTATUS_35 = `Deferred - Rejected: Unhandled error`
}
