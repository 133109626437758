import { Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { take, map, skipWhile, pluck } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { BET_316 } from './BET_316';

export const BET_318: Flow_0_0_2 = {
	id: '318',
	name: 'mfrf_underwriter_query_reply',
	itemType: 'flow',
	header: {
		title: store => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => !itemOne),
				map((itemOne: any) => {
					if (itemOne) {
						return `Underwriter Query Reply : ${itemOne?.mid} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Underwriter Query Reply';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], { filesFunctionName: 'getAllClaimFiles' }),
	serverCalls: {
		...BET_316.serverCalls,
		jobSummary: {
			errorMessage: '',
			directCall: (_h, store, _sq) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					pluck('job_information'),
					map((job_information: any) => {
						if (job_information) {
							const jobSummary = {
								important_information: {
									important_note: 'The service provider has responded to your query'
								}
							};

							if (job_information?.mfrf_query) {
								const query = !Array.isArray(job_information?.mfrf_query) ? [{ ...job_information?.mfrf_query }] : [...job_information?.mfrf_query];
								jobSummary.important_information['query'] = query[query.length - 1].query;
								jobSummary.important_information['sp_response'] = query[query.length - 1]?.queryreply;
							}

							return jobSummary;
						}
					})
				);
			}
		}
	},
	instructions: {
		editRoles: {
			0: 'Underwriter Query Reply'
		},
		viewRoles: {
			0: 'Underwriter Query Reply'
		}
	},
	startNode: 'Summary',
	nodes: {
		...BET_316.nodes
	},
	bigFormToStoreMapper: {
		...BET_316.bigFormToStoreMapper,
		current_state: [() => 318, 'current_state']
	}
};
