import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BigFormService, getData, getCurrentUser } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CustomValidators, generateRange } from '@flexus/utilities';
import { pluck, skipWhile, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

enum FileUploadTypes {
	invoicePDF,
	reportPDF,
	photo
}

@Component({
	selector: 'flx-upload-documents',
	templateUrl: './upload-documents.component.html',
	styleUrls: ['./upload-documents.component.scss']
})
export class FLXUploadDocumentsComponent implements OnInit, OnDestroy {
	public showUpload: boolean;
	public title: string;
	public client: string;
	public isNotMC: boolean;
	public instructions: string[];
	public buttons: { color: string; text: string; clickHandler: (ev: Event, store: Store<any>) => void }[];
	public formControls;
	public allowedFileTypes: string[];

	private _uploadType: FileUploadTypes;
	private _subscription: Subscription;
	client_sub: Observable<void>;
	clientsub: Subscription;

	constructor(private bf: BigFormService, private _store: Store<any>, private cdr: ChangeDetectorRef) {
		this.clientsub = this._store
			.select(getCurrentUser)
			.pipe(
				skipWhile((x: any) => !x),
				map((result: any) => result)
			)
			.subscribe((user: any) => {
				this.client = user?.client;
				this.isNotMC = this.client !== 'multichoice' ? true : false;
			});

		this.showUpload = false;
		this.title = 'Upload Documents';
		this.instructions = [
			'Only use this section to upload additional documents.',
			'Follow normal workflow procedure (click the job card in the appropriate state) to upload invoice.'
		];
		this.allowedFileTypes = [];
		this.buttons = [
			{
				color: 'primary',
				text: 'Upload invoice PDF',
				clickHandler: () => {
					if (this._uploadType !== FileUploadTypes.invoicePDF) {
						this._uploadType = FileUploadTypes.invoicePDF;
						this.setAllBtnColor('primary');
						this.showUpload = true;
						this.allowedFileTypes = ['pdf'];
						this.buttons[0].color = 'alert';
						this.bf.bigForm.get('fileQueue')?.setValue([]);
						this.bf.bigForm.get('purpose')?.setValue('Invoice');
						if (this.isNotMC) {
							this.formControls = {
								0: {
									formControlName: 'invoice_amount',
									label: 'Enter Invoice Amount',
									inputType: 'input'
								},
								1: {
									formControlName: 'invoice_number',
									label: 'Enter Invoice Number',
									inputType: 'input'
								},
								2: {
									formControlName: 'excess_amount',
									label: 'Confirm Excess',
									inputType: 'input'
								}
							};
							this.bf.bigForm.addControl(
								'invoice_amount',
								new UntypedFormControl('', [
									Validators.required,
									Validators.minLength(4),
									CustomValidators.hardMaxLength(13),
									CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])
								])
							);
							this.bf.bigForm.addControl('invoice_number', new UntypedFormControl('', [Validators.required]));
							this.bf.bigForm.addControl(
								'excess_amount',
								new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(13), CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])])
							);
						} else {
							this.formControls = {
								0: {
									formControlName: 'invoice_amount',
									label: 'Enter Invoice Amount',
									inputType: 'input'
								},
								1: {
									formControlName: 'invoice_number',
									label: 'Enter Invoice Number',
									inputType: 'input'
								}
							};
							this.bf.bigForm.addControl(
								'invoice_amount',
								new UntypedFormControl('', [
									Validators.required,
									Validators.minLength(4),
									CustomValidators.hardMaxLength(13),
									CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])
								])
							);
							this.bf.bigForm.addControl('invoice_number', new UntypedFormControl('', [Validators.required]));
						}
					}
				}
			},
			{
				color: 'primary',
				text: 'Upload report PDF',
				clickHandler: () => {
					if (this._uploadType !== FileUploadTypes.reportPDF) {
						this._uploadType = FileUploadTypes.reportPDF;
						this.showUpload = true;
						this.setAllBtnColor('primary');
						this.allowedFileTypes = ['pdf'];
						this.buttons[1].color = 'alert';
						this.bf.bigForm.get('fileQueue')?.setValue([]);
						this.bf.bigForm.get('purpose')?.setValue('Assessment Report');
						this.formControls = {};
						this.bf.bigForm.removeControl('invoice_amount');
						this.bf.bigForm.removeControl('invoice_number');
						this.bf.bigForm.removeControl('excess_amount');
					}
				}
			},
			{
				color: 'primary',
				text: 'Upload image',
				clickHandler: () => {
					if (this._uploadType !== FileUploadTypes.photo) {
						this._uploadType = FileUploadTypes.photo;
						this.setAllBtnColor('primary');
						this.showUpload = true;
						this.allowedFileTypes = ['image'];
						this.buttons[2].color = 'alert';
						this.bf.bigForm.get('fileQueue')?.setValue([]);
						this.bf.bigForm.get('purpose')?.setValue('Photo');
						this.formControls = {};
						this.bf.bigForm.removeControl('invoice_amount');
						this.bf.bigForm.removeControl('invoice_number');
						this.bf.bigForm.removeControl('excess_amount');
					}
				}
			}
		];

		this.bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required]));
		this.bf.bigForm.addControl('purpose', new UntypedFormControl('', [Validators.required]));
	}

	ngOnInit() {
		this._subscription = this._store
			.select(getData)
			.pipe(
				pluck('uploadDoc'),
				skipWhile(x => !x)
			)
			.subscribe(data => {
				this.bf.bigForm.get('fileQueue')?.setValue([]);
				this.showUpload = false;
				this.setAllBtnColor('primary');
				this._uploadType = null;
				this.cdr.detectChanges();
			});
	}

	ngOnDestroy() {
		if (this._subscription) {
			this._subscription.unsubscribe();
		}
	}

	private setAllBtnColor(color: string) {
		this.buttons = this.buttons.map(btn => ({ ...btn, color: color }));
	}
}
