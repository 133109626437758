/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getSelectedItemTwo, getCurrentUser, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { filter, skipWhile, map, switchMap, pluck, take } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin, of } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import { JOB_INFO_NODE } from '../reusable/JOB_INFO_NODE';
import { HttpClient } from '@angular/common/http';

export const PGG_21: Flow_0_0_2 = {
	id: '21',
	name: 'job_assigned_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo?.claim) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Job Awarded : ${itemTwo?.claim?.loan_information?.voucher_key} - ${itemTwo?.claim?.applicant?.first_name} `;
						} else {
							return 'Job Awarded - Allocate team leader';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		jobLocationData: {
			errorMessage: 'The job location was not returned!',
			serviceVariable: 'spService',
			functionName: 'getJobLocation',
			responseSlice: 'payload'
		},
		teamleaderLocations: {
			errorMessage: 'Team leader locations not returned!',
			directCall: (http) => {
				return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
					pluck('payload'),
					filter((x: any) => !!x),
					map((teamleaders: any[]) => {
						return teamleaders;
					})
				);
			}
		}
	},

	instructions: {
		editRoles: {
			0: 'Allocate a team leader to job'
		},
		viewRoles: {
			0: 'Awaiting TL allocation'
		}
	},
	startNode: 'JobAllocationNotification',
	nodes: {
		JobAllocationNotification: {
			...JOB_INFO_NODE,
			showTabs: true,
			navs: [
				{
					text: 'Continue',
					linkType: 'portal',
					color: 'primary',
					nextNode: 'AssignTeamLeader',
					serverFirst: true
				}
			]
		},

		AssignTeamLeader: {
			name: 'Select Team Leader / Map',
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: bf => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl('', Validators.required));
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http:HttpClient) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter((x: any) => !!x),
							map((teamleaders: any[]) =>
								teamleaders.map(teamLeader => {
									return {
										display: teamLeader.full_name,
										value: teamLeader.id,
										teamLeader,
										shouldHaveImage: true
									};
								})
							)
						);
					}
				}
			},
			component: 'AssignTLComponent',
			inputs: [
				{
					joblocation$: 'jobLocationData',
					teamleadersPositions$: 'teamleaderLocations'
				}
			],

			navs: [
				// {
				//   text: 'Update Map',
				//   nextNode: ''
				// },
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't update job!",
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getCurrentUser).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									of(bf.bigForm.get('assign_teamleader_id')?.value).pipe(
										skipWhile(x => !x),
										take(1),
										map(rest => rest)
									)
								]).pipe(
									switchMap(([j, uid, lead]) => {
										const job = j as any;
										const user = uid as any;
										const job_id = job?.id;
										const staffmember = user.id;
										const team = lead[0];

										const data = {
											job_id: job_id,
											staffmember: staffmember,
											team_id: team
											// new_state: 22
										};
										return http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
									})
								);
							}
						}
					},

					location: 'right'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		assign_teamleader_id: [
			[
				(tlid: any) => {
					if (tlid) {
						let id;
						tlid = tlid && Array.isArray(tlid) ? tlid : [];
						for (const lead of tlid) {
							id = parseInt(lead, 10);
							// console.log('TEAM LEAD ID', id);
						}
						return id;
					}
					return 0;
				},
				'job_information.team_leader'
			]
		]
	}
};
