import { FileOverlayControl } from '../file-overlay/file-overlay-control';
import { FilePreviewOverlayService } from '../file-overlay/file-preview-overlay.service';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, OnChanges, OnDestroy } from '@angular/core';

import { split, last } from 'ramda';
import { Subscription } from 'rxjs';
import { FileListService } from './file-list.service';
import { DomSanitizer } from '@angular/platform-browser';
import { addObjectProperty } from '@flexus/utilities';
import moment from 'moment';

@Component({
	selector: 'flx-file-list',
	templateUrl: './file-list.component.html',
	styleUrls: ['./file-list.component.scss'],
	providers: [FileListService, FilePreviewOverlayService]
})
export class FLXFileListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	locationClass = 'container';

	currentPage = 1;
	pageSize = 5;

	private _display: string;

	@Input() paginationId: string;
	@Input() files: any[] = [];
	@Input() defaultFile: any;
	@Input() filterInProp: any;
	@Input() filterValue: string;
	@Input() location: string;
	@Input() showMeta = true;

	@Input() set display(val: string) {
		this._display = val;
	}

	get display() {
		return this._display;
	}

	@Input() imagesSelectable: boolean;
	@Input() documentsSelectable: boolean;
	@Input() thumbSize: string;

	@Output() fseOnSelectFiles = new EventEmitter();
	@Output() fseOnDocumentClose = new EventEmitter();
	private overlayRef: FileOverlayControl;
	private serverCallSubscription: Subscription;
	Loading = false;
	selectedFiles: any[] = [];
	previewedFile: any;
	closedPreview = true;

	constructor(
		private fileService: FileListService,
		private changeDetectorRef: ChangeDetectorRef,
		private fileOverlay: FilePreviewOverlayService,
		private _sanitizer: DomSanitizer
	) {}

	ngAfterViewInit(): void {
		if (this.defaultFile) {
			this.previewFile(this.defaultFile);
		}
	}

	ngOnChanges(): void {
		if (this.defaultFile) {
			this.previewFile(this.defaultFile);
		}
	}

	selectFile(event, file) {
		if (event.target.checked) {
			this.selectedFiles = [...this.selectedFiles, file];
			this.fseOnSelectFiles.emit(this.selectedFiles);
		} else {
			this.selectedFiles = this.selectedFiles.filter(f => f.filename !== file.filename);
			this.fseOnSelectFiles.emit(this.selectedFiles);
		}
	}

	getMimeType(filename: string): string {
		if (!filename) {
			return null;
		}

		const parts = split('.')(filename);
		return last(parts)?.toLowerCase();
	}

	previewFile(file) {
		this.previewedFile = file;
		this.Loading = true;

		// Get the full image file
		this.serverCallSubscription = this.fileService.getFullFile(file.id).subscribe(response => {
			if (this.previewedFile && response?.payload?.data) {
				const fileForPreview = addObjectProperty('base64Source', response.payload?.data, this.previewedFile);
				this.overlayRef = this.fileOverlay.previewFile({
					file: fileForPreview,
					mimeType: this.getMimeType(file.filename),
					containerHeight: '100%'
				});
				this.closePreview();
				this.Loading = false;
				this.changeDetectorRef.detectChanges();
			}
		});
	}

	closePreview() {
		this.previewedFile = null;
		this.closedPreview = true;
		this.fseOnDocumentClose.emit(this.closedPreview);
	}

	createDownload(dSource: string) {
		let temp = null;
		if (dSource != null) {
			const blob = this.base64StringtoBlob(dSource, 'application/pdf');
			temp = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
		}
		return temp;
	}

	base64StringtoBlob(b64Data, contentType = '', sliceSize = 512): Blob {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}

	humanizeDateFromServer(rawDate: string) {
		if (!!rawDate) {
			const timePos = rawDate.indexOf('T');
			const date = rawDate.substr(0, timePos);
			const human = moment(date);
			return human.format('DD/MM/YYYY');
		} else {
			return '';
		}
	}

	ngOnDestroy(): void {
		if (this.serverCallSubscription) {
			this.serverCallSubscription.unsubscribe();
		}

		// This is to protect the change detector from throwing an error after component view is destroyed
		this.previewedFile = null;
	}

	ngOnInit() {
		switch (this.location) {
			case 'action-panel':
				this.locationClass = 'container-ap';
				break;
			case 'context-menu':
				this.locationClass = 'container-cm';
				break;
			case 'custom':
				this.locationClass = 'container-custom';
				break;
			default:
				break;
		}
	}
}
