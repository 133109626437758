import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, getSubmissionData } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { skipWhile, take, map } from 'rxjs/operators';
import { VatService } from '../../services';
import { iLineItem } from '@flexus/models';

@Component({
	selector: 'flx-credit-note-line-item-generator',
	templateUrl: './line-item-generator.component.html',
	styleUrls: ['./line-item-generator.component.scss']
})
export class LineItemGeneratorComponent implements OnInit, OnDestroy {
	public showDropDown = false;
	public isItemHover = false;
	public isItemActive = false;
	
	@Input() index: number;
	@Input() availableLineItems: iLineItem[];
	@Input() icons: { src: string; alt: string }[] = [];
	@Input() allow_custom_items: boolean;
	@Input() actualLineItemsFormArray: FormArray;
	@Input() isNonVatSP: boolean = false;
	
	@Output() startCreateCustomItem = new EventEmitter();
	@Output() startUpdateCustomItem = new EventEmitter();
	@Output() changed = new EventEmitter();
	creditNoteGroup = new UntypedFormGroup({
		VoucherTypeandStatus: new UntypedFormControl('', Validators.required),
		VoucherNumber: new UntypedFormControl('', Validators.required),
		AmountExclVAT: new UntypedFormControl('', Validators.required),
		TotalVAT: new UntypedFormControl(0, Validators.required),
		vatPercentage: new UntypedFormControl(0, Validators.required)
	});
	searchField = this.fb.control('');
	subscription: Subscription;
	creditNoteGroupSub: Subscription;
	getSubmissionDataSub: Subscription;
	vatAmount = '0';
	installationTypes: any[] = [
		{ display: 'Installer MVP Single Installation' },
		{ display: 'Distributor' },
		{ display: 'Installer Price Lock Installation Only' },
		{ display: 'Installer Pricelock Decoder Only' }
	];
	constructor(public bf: BigFormService, private fb: UntypedFormBuilder, private store: Store<any>, public vatService: VatService) {}

	ngOnInit(): void {
		this.getFormValueFromStore();
		this.creditNoteGroupSub = this.creditNoteGroup.valueChanges.subscribe(changes => {
			if (!Number(changes?.AmountExclVAT)) {
				changes = { ...changes, AmountExclVAT: 0 };
			}
			console.log({ actualLineItemsFormArray: this.actualLineItemsFormArray });
			this.actualLineItemsFormArray?.controls[this.index]?.patchValue({ ...changes });
			const currAmountExclVat = this.actualLineItemsFormArray?.controls[this.index]?.get('AmountExclVAT')?.value;
			this.vatAmount = ((currAmountExclVat * this.vatService._currentVatRate) / 100).toFixed(2);
		});
	}

	ngOnDestroy(): void {
		this.creditNoteGroupSub && this.creditNoteGroupSub.unsubscribe();
		this.getSubmissionDataSub && this.getSubmissionDataSub.unsubscribe();
	}

	getFormValueFromStore() {
		this.getSubmissionDataSub = this.store
			.select(getSubmissionData)
			.pipe(
				skipWhile(x => !x),
				take(1),
				map(res => {
					const updatedArray = [...res.line_items];
					updatedArray[this.index] = {
						VoucherTypeandStatus: updatedArray[this.index]?.VoucherTypeandStatus ?? '',
						VoucherNumber: updatedArray[this.index]?.VoucherNumber ?? '',
						AmountExclVAT: updatedArray[this.index]?.AmountExclVAT ?? '',
						TotalVAT: updatedArray[this.index]?.TotalVAT ?? '',
						vatPercentage: updatedArray[this.index]?.vatPercentage ?? ''
					};
					return updatedArray;
				})
			)
			.subscribe(data => {
				if (data?.length > 0) {
					this.creditNoteGroup.patchValue({ ...data?.[this.index] });
					this.actualLineItemsFormArray?.controls[this.index]?.patchValue({ ...data?.[this.index] });
				}
			});
	}

	@HostListener('mouseenter') onMouseEnter() {
		this.isItemHover = true;
	}

	@HostListener('mouseleave') onMouseLeave() {
		this.isItemHover = false;
	}
}
