<flx-heading size="medium" align="center" weight="extra-light" type="creation">{{ addressConfirmationText }}</flx-heading>
<p class="policy-address">
	<b>{{ addressType }}:</b> {{ policyAddress$ | async }}
</p>
<ng-container *ngIf="!isOffline; else offline">
	<ng-container *ngIf="(policyAddress$ | async) && locationPickerForm?.value['address_details']; else noMaps">
		<form [formGroup]="locationPickerForm" class="le-map">
			<div class="address-found">
				<flx-heading size="small" align="center" color="custom-background" margin="0">Address Found</flx-heading>
				<div class="box">
					<div class="heading">
						<strong align="end">Policy Address</strong>
						<strong align="start">Address Found</strong>
					</div>
					<br />
					<div *ngIf="policyAddressObject$ | async as policyAddressObject">
						<div class="line">
							<span>{{ displayPropertyWithValue(policyAddressObject?.propertystreetaddress) }}</span>
							<flx-circle-indicator
								[color]="setColorIndicatorColor(policyAddressObject?.propertystreetaddress, locationPickerForm.value['searched_address']?.address)"
							></flx-circle-indicator>
							<span>{{ displayPropertyWithValue(locationPickerForm.value['searched_address']?.address) }}</span>
						</div>
						<div class="line">
							<span>{{ displayPropertyWithValue(policyAddressObject?.propertysuburb) }}</span>
							<flx-circle-indicator
								[color]="setColorIndicatorColor(policyAddressObject?.propertysuburb, locationPickerForm.value['searched_address']?.suburb)"
							></flx-circle-indicator>
							<span>{{ displayPropertyWithValue(locationPickerForm.value['searched_address']?.suburb) }}</span>
						</div>
						<div class="line">
							<span>{{ displayPropertyWithValue(policyAddressObject?.propertycity) }}</span>
							<flx-circle-indicator [color]="setColorIndicatorColor(policyAddressObject?.propertycity, locationPickerForm.value['searched_address']?.city)"></flx-circle-indicator>
							<span>{{ displayPropertyWithValue(locationPickerForm.value['searched_address']?.city) }}</span>
						</div>
						<div class="line">
							<span>{{ displayPropertyWithValue(policyAddressObject?.suburbcode) }}</span>
							<flx-circle-indicator
								[color]="setColorIndicatorColor(policyAddressObject?.suburbcode, locationPickerForm.value['searched_address']?.suburb_code)"
							></flx-circle-indicator>
							<span>{{ displayPropertyWithValue(locationPickerForm.value['searched_address']?.suburb_code) }}</span>
						</div>
						<div class="line">
							<span>{{ displayPropertyWithValue(province) }}</span>
							<flx-circle-indicator [color]="setColorIndicatorColor(province, locationPickerForm.value['searched_address']?.province)" color="green"></flx-circle-indicator>
							<span>{{ displayPropertyWithValue(locationPickerForm.value['searched_address']?.province) }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="confirmed-address" *ngIf="locationSearched | async">
				<flx-heading size="small" align="center" color="custom-background" margin="0">Pinned Address</flx-heading>
				<br />
				<div class="details">
					<div *ngIf="!(isInSA | async) && (locationSearched | async)" class="warning-block">Not in South Africa</div>
					<div *ngIf="locationPickerForm.value['searched_address']?.address">Street : {{ locationPickerForm.value['searched_address']?.address }}</div>
					<div *ngIf="locationPickerForm.value['searched_address']?.suburb">Suburb : {{ locationPickerForm.value['searched_address']?.suburb }}</div>
					<div *ngIf="locationPickerForm.value['searched_address']?.city">City : {{ locationPickerForm.value['searched_address']?.city }}</div>
					<div *ngIf="locationPickerForm.value['searched_address']?.suburb_code">Postal Code : {{ locationPickerForm.value['searched_address']?.suburb_code }}</div>
					<div *ngIf="locationPickerForm.value['searched_address']?.province">Province : {{ locationPickerForm.value['searched_address']?.province }}</div>
					<br />
					<div *ngIf="locationPickerForm.value['searched_address']?.latitude">Latitude : {{ locationPickerForm.value['searched_address']?.latitude | number: '1.2-5' }}</div>
					<div *ngIf="locationPickerForm.value['searched_address']?.longitude">Longitude : {{ locationPickerForm.value['searched_address']?.longitude | number: '1.2-5' }}</div>
				</div>
			</div>
			<flx-location-picker [formControlName]="'address_details'" [showSearch]="true" [theme]="activeTheme | async"></flx-location-picker>
		</form>
	</ng-container>
	<ng-template #noMaps> <flx-heading size="small" align="center">Please confirm address before selecting a location.</flx-heading> </ng-template>
</ng-container>
<ng-template #offline> <flx-alert type="danger" [message]="offlineMessage"></flx-alert> </ng-template>
