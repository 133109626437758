<flx-flex-container alignItems="center">
	<flx-heading size="medium" align="center" type="creation" weight="extra-light">Select the Claim Class</flx-heading>

	<div class="dynamic-component claim-details-container">
		<form [formGroup]="bf.bigForm">
			<div class="claim-class-information">
				<!-- ---------------------------------------------- select claim class ---------------------------------------------- -->
				<div class="claim-details-claim-class" [ngClass]="{ animateExtraInMoveClaimClass: enabledClaimContextAction?.length > 0 }">
					<flx-select-list
						[options]="claimClassesData$ | async"
						formControlName="selectedClaimClass"
						[height]="selectedClaimClass === 0 || selectedClaimClass === null ? '40vh' : '150px'"
						[canFilter]="selectedClaimClass === 0 || selectedClaimClass === null"
					>
					</flx-select-list>
				</div>
			</div>
		</form>

		<!-- ---------------------------------------------- Additional claim information based on claim class selection ---------------------------------------------- -->
		<ng-container *ngIf="enabledClaimContextAction?.length > 0">
			<div *ngFor="let action of enabledClaimContextAction">
				<ng-container [ngSwitch]="action.action">
					<!-- display a form -->
					<div *ngSwitchCase="'form'"><flx-flat-dynamic-form [formControls]="action.inputs" [formLayout]="action.layout"> </flx-flat-dynamic-form></div>
					<!-- display a bullet point list -->
					<div *ngSwitchCase="'display-list'">
						<flx-heading size="extra-small" align="center">{{ action.list.heading }}</flx-heading>
						<p *ngIf="action.list.subheading">{{ action.list.subheading }}</p>
						<ul *ngIf="action.list.points">
							<ng-container *ngFor="let point of action.list.points">
								<li>{{ point }}</li>
							</ng-container>
						</ul>
					</div>
					<!-- display normal text -->
					<div *ngSwitchCase="'display-text'">
						<flx-heading size="extra-small" align="center" [color]="action.displayText.color"> {{ action.displayText.text }} </flx-heading>
					</div>
					<!-- upfront reject a claim -->
					<div *ngSwitchCase="'upfront-reject'">
						<ng-container *ngIf="toggleReason">
							<flx-heading size="small">Upfront Rejection Reason</flx-heading>
							<form [formGroup]="bf.bigForm">
								<flx-select-list
									[canFilter]="false"
									formControlName="upfrontrepudiationreason"
									layout="basic"
									[options]="upfrontRejectReasons$ | async"
									height="150px"
									(click)="triggerUpfrontRejection()"
								>
								</flx-select-list>
							</form>
						</ng-container>
						<div class="upfront-reject-button">
							<flx-button *ngIf="!toggleReason" class="text-center" size="small" color="secondary" (click)="rejectClaim()"> Upfront Reject Claim </flx-button>
						</div>
					</div>
				</ng-container>
				<flx-glow-line size="100%"></flx-glow-line>
			</div>
		</ng-container>

		<!-- Provide reason for appointing IA -->
		<div class="ia-reasons-select" *ngIf="iaRequested && !(selectedClaimClass <= 0 || selectedClaimClass === null)">
			<form [formGroup]="bf.bigForm">
				<flx-heading size="extra-small" align="left" margin="0">Select a reason for appointing IA</flx-heading>
				<div>
					<flx-dropdown
						[displayOptions]="displayConfig"
						[itemsOption]="IAreasons$ | async"
						[searchEnabled]="false"
						formControlName="ia_request_reason"
						placeHolder="IA Appointment Reason"
					></flx-dropdown>
				</div>
			</form>
			<br />
			<flx-glow-line size="100%"></flx-glow-line>
		</div>

		<!-- ---------------------------------------------- Add a Job ---------------------------------------------- -->
		<div class="claim-details-jobs" *ngIf="!(selectedClaimClass <= 0 || selectedClaimClass === null) && !excludeJobs">
			<flx-heading size="medium" align="center" type="creation" weight="extra-light">Create job card</flx-heading>

			<form [formGroup]="bf.bigForm">
				<ng-container formArrayName="jobs" *ngFor="let job of $any(bf.bigForm).get('jobs')?.controls; let i = index">
					<div [ngClass]="isMC ? 'job-card-section-if-single-job' : 'job-card-section'" [formGroupName]="i">
						<div class="job-card-input">
							<flx-dropdown
								formControlName="skillcatagory_id"
								[displayOptions]="displayConfig"
								[itemsOption]="selectGroupData$ | async"
								placeHolder="Skill Group"
								[searchEnabled]="false"
								[isDisabled]="selectedClaimClass <= 0 || selectedClaimClass === null"
								[autoOpen]="!(enabledClaimContextAction?.length > 0) && !jobList[i].skillcatagory_id"
								(itemsSelected)="goSkillGroupAction($event, i)"
							>
							</flx-dropdown>
						</div>
						<div class="job-card-input">
							<flx-dropdown
								formControlName="skill_id"
								[displayOptions]="displayConfig"
								[itemsOption]="getSkills(jobList[i].skillcatagory_id)"
								placeHolder="Skill"
								[searchEnabled]="getSkills(jobList[i].skillcatagory_id)?.length >= 10"
								[isDisabled]="!jobList[i].skillcatagory_id"
								[autoOpen]="jobList[i].skillcatagory_id && !jobList[i].skill_id && !jobList[i].skill_id"
								(itemsSelected)="goSkillAction($event, i)"
							></flx-dropdown>
						</div>
						<div class="job-card-input" *ngIf="!isMC">
							<flx-dropdown
								formControlName="providertype_id"
								[displayOptions]="displayConfig"
								[itemsOption]="getProviderType(jobList[i].skill_id)"
								placeHolder="Provider Type"
								[searchEnabled]="false"
								[isDisabled]="jobList[i].skill_id === null"
								[autoOpen]="jobList[i].skill_id && !jobList[i].providertype_id && !jobList[i].providertype_id"
								(itemsSelected)="goProviderTypeAction($event, i)"
							></flx-dropdown>
						</div>
						<div class="add-another-job" *ngIf="!isMC">
							<!-- remove job -->
							<flx-icon *ngIf="jobList?.length !== 1" (click)="removeJob(i)" [type]="'subtraction'"></flx-icon>
							<!-- edit off panel or cash in lieu -->
							<flx-icon
								[type]="'edit'"
								*ngIf="jobList[i].providertype_id === 2 || jobList[i].providertype_id === 3"
								(click)="goProviderTypeAction(jobList[i].providertype_id, i)"
							></flx-icon>
							<!-- add another job -->
							<flx-icon *ngIf="canAddJob(i)" (click)="incJobCount(i + 1)" [type]="'addition'"></flx-icon>
						</div>
						<flx-glow-line *ngIf="$any(bf.bigForm).get('jobs')?.controls?.length > 1" margin="0.5rem 0"></flx-glow-line>
					</div>
				</ng-container>
			</form>
		</div>
	</div>
</flx-flex-container>
