import { Flow_0_0_2 } from '@flexus/core';

export const SIL_94: Flow_0_0_2 = {
	id: '94',
	name: 'assessor_rejection',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Assessor recommends rejection'
		},
		viewRoles: {
			0: 'Assessor recommends rejection'
		}
	},
	startNode: '',
	nodes: {}
};
