import * as filterStoreActions from '../actions';

export interface FilterStoreState {
	//
	filterData: {
		[key: string]: any;
	};
	filters: Array<any>;
	serverSearchResults: any[];
}

export const intialFilterStoreState: FilterStoreState = {
	filterData: {},
	filters: [],
	serverSearchResults: []
};

export function filterStore(state: FilterStoreState = intialFilterStoreState, action: filterStoreActions.FilterStoreActionTypes | any) {
	switch (action.type) {
		case filterStoreActions.FilterActionTypes.SET_RANDOM_FIELD: {
			const { key, data } = action.payload;
			return { ...state, filterData: { ...state.filterData, [key]: data } };
		}
		case filterStoreActions.FilterActionTypes.SET_FILTER_SET: {
			const { filterSet } = action.payload;
			return { ...state, filters: [...filterSet] };
		}
		case filterStoreActions.FilterActionTypes.RESET_FILTERS: {
			return { ...state, filters: [] };
		}
		case filterStoreActions.FilterActionTypes.RESET_STATE: {
			return intialFilterStoreState;
		}
		case filterStoreActions.FilterActionTypes.SEARCH_ON_SERVER_SUCCESS: {
			const serverSearchResults = action.payload;
			return {
				...state,
				serverSearchResults
			};
		}
		case filterStoreActions.FilterActionTypes.RESET_SERVER_SEARCH: {
			return {
				...state,
				serverSearchResults: []
			};
		}

		case 'LOGOUT_SUCCESS': {
			return intialFilterStoreState;
		}

		default: {
			return { ...state };
		}
	}
}
