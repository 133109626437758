import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadSilManifestService } from './services/load-sil-manifest.service';
import { SilClaimCardComponent } from './components/sil-claim-card/sil-claim-card.component';
import { SilJobCardComponent } from './components/sil-job-card/sil-job-card.component';
import { SilListJobCardComponent } from './components/sil-list-job-card/sil-list-job-card.component';
import { SilListClaimCardComponent } from './components/sil-list-claim-card/sil-list-claim-card.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { RouterModule } from '@angular/router';
import { FLXDetailsComponent } from './views/details-view/details-view.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FLXListViewComponent } from './views/list-view/list-view.component';
import { SilService, ClaimService, JobService } from './services';
import { UIElementsModule } from '@flexus/ui-elements';
import { ThemeModule } from '@flexus/ux';
import { SilWorkflowShellComponent } from './base-components/sil-workflow-shell/sil-workflow-shell.component';
import { SilFlowComponent } from './base-components/sil-flow/sil-flow.component';
import { environment } from '../../../environments/environment';
import { UICompositesModule } from '@flexus/ui-composites';
import { ErrorHandlerModule } from '@flexus/error-handler';
import { ScreensModule } from '@flexus/screens';
import { ActivateStateGuard } from './guards/activate-state.guard';
import { DeactivateStateGuard } from './guards/deactivate-state.guard';
import { SearchBannerModule } from '../../app-shell-features';
import { MsalModule } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { DynamicLoaderModule } from '@flexus/engine';
import { IdentityModule } from '@flexus/identity';


export function loadConfig(manifest: LoadSilManifestService) {
	return function () {
		return manifest.load();
	};
}

export function tokenGetter() {
	return localStorage.getItem('flexus.web.jwtToken');
}
@NgModule({
	imports: [
		CommonModule,
		...environment.appShellFeatures,
		DynamicLoaderModule,
		ErrorHandlerModule,
		ScreensModule,
		InlineSVGModule,
		NgxPaginationModule,
		RouterModule,
		ThemeModule,
		...environment.plugins['global'],
		UIElementsModule.forRoot({ environment: environment }),
		UICompositesModule,
		environment['azure']
			? MsalModule.forRoot(
					new PublicClientApplication({
						auth: {
							clientId: environment['azure']['clientId'],
							postLogoutRedirectUri: `${environment.base_url}auth/login`
						}
					}),
					null,
					null
			  )
			: CommonModule,
		IdentityModule.forRoot({
			after_login_url: '/workflow',
			after_logout_url: '/auth/login',
			send_reset_link_endpoint: `${environment.api_url}v1/forgot_password/check-email`,
			login_endpoint: `${environment.api_url}v2/auth/login/`,
			no_auth_urls: ['/cons']
		}),
		SearchBannerModule
	],
	declarations: [
		SilClaimCardComponent,
		SilListClaimCardComponent,
		SilJobCardComponent,
		SilListJobCardComponent,
		FLXDetailsComponent,
		FLXListViewComponent,
		SilWorkflowShellComponent,
		SilFlowComponent
	],
	exports: [
		...environment.appShellFeatures,
		SilClaimCardComponent,
		SilJobCardComponent,
		SilListClaimCardComponent,
		SilListJobCardComponent,
		FLXDetailsComponent,
		FLXListViewComponent
	],
	providers: [
		ActivateStateGuard,
		DeactivateStateGuard,
		SilService,
		ClaimService,
		JobService,
		{
			provide: APP_INITIALIZER,
			useFactory: loadConfig,
			deps: [LoadSilManifestService],
			multi: true
		}
	]
})
export class SilManifestModule {}
