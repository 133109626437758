<flx-flex-container>
	<div *ngIf="usedFilterKeys.length > 0" class="filter-list-container">
		<flx-heading type="page" size="medium">Selected Filters</flx-heading>
		<ng-container *ngFor="let usedFilterKey of usedFilterKeys">
			<div class="filter-entry">
				<div class="filter-name">
					<span class="filter-info">{{ usedFilterKey }}</span>
				</div>
				<div class="filter-value">
					<span *ngFor="let value of usedFilters[usedFilterKey]?.values" class="filter-info">
						<ng-container [ngSwitch]="usedFilterKey">
							<ng-container *ngSwitchCase="'State'">{{ value | stateName: states }}</ng-container>
							<ng-container *ngSwitchCase="'Required Skill'">{{ value | spSkillName: skills }}</ng-container>
							<ng-container *ngSwitchCase="'Service Provider'">{{ value | idToName: sps }}</ng-container>
							<ng-container *ngSwitchCase="'Province'">{{ value | idToName: provinces }}</ng-container>
							<ng-container *ngSwitchDefault>{{ value }}</ng-container>
						</ng-container>
					</span>
				</div>
				<!-- <flx-button class="filter-info" (click)="removeFilter(usedFilterKey)" color="secondary" size="small">Remove Filter</flx-button> -->
				<flx-icon hover="secondary" class="filter-info" (click)="removeFilter(usedFilterKey)" [type]="'remove'"></flx-icon>
			</div>
		</ng-container>
		<!-- bellow ads a filter -->
		<flx-glow-line margin="0 0 1rem 0"></flx-glow-line>
	</div>
	<flx-slide-navigation (valueSelection)="handleValueSelection($event)" [availableFilters]="filteredList" [hasFilteredItems]="usedFilterKeys.length !== 0"></flx-slide-navigation>
</flx-flex-container>
