import { Flow_0_0_2, getAllInfo, getCurrentUser, getFullItemTwo, getSelectedItem, getSubmissionData } from '@flexus/core';
import { filter, map, pluck, skipWhile, take, switchMap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import gql from 'graphql-tag';
import { EMPTY, forkJoin } from 'rxjs';
import moment from 'moment';
import { environment } from 'apps/studio/src/environments/environment';
import { humaniseDate } from '@flexus/utilities';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';

import { JOB_INFO_NODE } from '../reusable/JOB_INFO_NODE';

export const SP_GLOBAL_86: Flow_0_0_2 = {
	id: '86',
	name: 'quote_approved_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Quote Approved : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Quote Approved';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Set new appointment'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		claimDetailKeyValues: {
			errorMessage: 'Something went wrong claim details',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							const claimdetaillist = {
								'Claim Type ': itemTwo?.claim?.type ?? '',
								'Skill required ': itemTwo?.office_use?.skillcatagory ?? '',
								'Address ': itemTwo ? itemTwo?.address : '',
								'Appointment Date ': itemTwo?.office_use?.requestedappointmentdate ?? 'No appointment date set',
								'Appointment Time ': itemTwo?.office_use
									? `${itemTwo.office_use.appointment_type} ${itemTwo.office_use.requestedappointmenttime}`
									: 'Appointment time not retrieved'
							};
							return [claimdetaillist];
						})
					);
			}
		},
		onsiteKeyValues: {
			errorMessage: "Couldn't retrieve on-site info",
			directCall: (http, store) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							const onsitelist = {
								'On site contact person ': itemTwo?.claim?.loan_information?.onsiteperson ?? 'Not retrieved',
								'Contact number ': itemTwo?.claim?.loan_information?.onsitecontact ?? 'On-site contact number not available',
								'Notes ': itemTwo?.claim?.loan_information?.whatmatters ?? 'What Matters Not Retrieved'
							};
							return [onsitelist];
						})
					);
			}
		},
		customerContactKeyValues: {
			errorMessage: 'Customer contact information could not be retrieved',
			directCall: (http, store, sq, bf) => {
				return forkJoin([
					store.select(getFullItemTwo)?.pipe(
						skipWhile(f => !f),
						take(1),
						map(res => res as any)
					),
					store.select(getAllInfo)?.pipe(
						skipWhile(i => !i),
						take(1),
						map(res => res as any)
					)
				])?.pipe(
					map(([itemTwo, info]) => {
						const job_id = itemTwo?.id;
						const job_excess = itemTwo?.excess;
						let excess_collection: string;
						let excess_amount: string;
						let excess_method: string;
						const excess_whoArr: any = info.excess_who;
						const excess_howArr: any = info.excess_how;
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_amount = job_excess[i]?.amount;
							}
						}
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_whoArr.forEach(element => {
									if (element.id === job_excess[i]?.who_collects) {
										excess_collection = element.name;
									}
								});
							}
						}
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_howArr.forEach(element => {
									if (element.id === job_excess[i]?.how_collect) {
										excess_method = element.name;
									}
								});
							}
						}
						const customercontactlist = {
							'Customer ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : 'Customer name not available',
							'Client cell ': itemTwo?.claim?.loan_information?.cellnumber ?? 'Cellular number not on record',
							'Excess Collection ': excess_collection ? `${excess_collection} to collect` : '-',
							'Excess Amount ': excess_amount ? `R${excess_amount}` : '-',
							'Excess payment ': excess_method
						};
						return [customercontactlist];
					})
				);
			}
		},
		whatMattersKeyValues: {
			errorMessage: `'What Matters' data could not be retrieved`,
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							const whatmatters = {
								'Inspection Fee Only': 'inspection fee',
								'Consignment stock used': 'consignment stock',
								'What matters to the customer ': itemTwo?.claim?.loan_information?.whatmatters ?? `'What matters' not provided`
							};
							return [whatmatters];
						})
					);
			}
		},
		keyValueListForCIL1: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1)
					)
					.pipe(
						take(1),
						map(job => {
							const list = {
								'Claim Type': job?.claim ? job?.claim?.type : '',
								'Client Name': job?.claim && job?.claim?.applicant ? job?.claim?.applicant?.first_name + ' ' + job?.claim?.applicant?.surname : '',
								'Contact number': job?.claim && job?.claim?.applicant ? job?.claim?.loan_information?.contactnumber : '',
								'Client Alternative no': job?.claim && job?.claim?.loan_information ? job?.claim?.loan_information?.cellnumber : '',
								Address: job?.claim ? job?.claim?.address : ''
							};
							return [list];
						})
					);
			}
		},
		keyValueListForCIL2: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getAllInfo).pipe(
						skipWhile(x => !x),
						take(1)
					)
				]).pipe(
					take(1),
					map(([job, allInfo]) => {
						const appointment = job?.appointment[job.appointment.length - 1];
						const list = {
							'Claim Type': job?.claim ? job?.claim?.type : '',
							Skill: job?.office_use ? job?.office_use.skill : '',
							'Client Name': job?.claim && job?.claim?.applicant ? job?.claim?.applicant?.first_name + ' ' + job?.claim?.applicant?.surname : '',
							'Contact number': job?.claim && job?.claim?.applicant ? job?.claim?.loan_information?.contactnumber : '',
							'Client Alternative no': job?.claim && job?.claim?.loan_information ? job?.claim?.loan_information?.cellnumber : '',
							Address: job?.claim ? job?.claim?.address : '',
							'Original Appointment Date': appointment.range_start ? `${humaniseDate(appointment.range_start)}` : '',
							'Original Appointment Time': appointment.range_start ? `${moment(appointment.range_start)?.format('HH:mm')}` : '',

							'Excess collection': ((): string => {
								let collection = '';
								if (job.excess[0]) {
									allInfo['excess_who']?.forEach(element => {
										if (element.id === job?.excess[0]?.who_collects) {
											collection = element.name;
										}
									});
								}
								return collection;
							})(),
							'Excess amount': job?.excess?.length !== 0 ? `R ${job.excess[0]?.amount}` : 0,
							'Excess Payment': ((): string => {
								let pay = '';
								if (job.excess[0]) {
									allInfo['excess_how']?.forEach(element => {
										if (element.id === job?.excess[0]?.how_collect) {
											pay = element.name;
										}
									});
								}
								return pay;
							})(),
							'Excess State': ((): string => {
								let state = '';
								if (job.excess[0]) {
									allInfo['excess_states']?.forEach(element => {
										if (element.id === job?.excess[0]?.state) {
											state = element.name;
										}
									});
								}
								return state;
							})(),
							'Inspection fee only': job?.claim?.inspection || 'No',
							'Consignment stock used': job?.claim?.consignment || 'No',
							'What mattered to the customer': job?.claim && job?.claim?.loan_information?.whatmatters ? job?.claim?.loan_information.whatmatters : ''
						};

						return [list];
					})
				);
			}
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Quote Accepted',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'On-site details',
							data$: 'onsiteKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'What Matters',
							data$: 'whatMattersKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			// component: {
			//   children: [
			//     {
			//       component: 'FLXKeyValueDisplayWithInstructionsComponent',
			//       inputs: {
			//         title: 'Qoute accepted',
			//         instructions: ['Phone client to set a new appointment date and continue the work.'],
			//         keyValueList$: 'keyValueListForQouteAccepted',
			//         title$: 'title'
			//       }
			//     }
			//   ]
			// },
			navs: [
				{
					text: 'Unable To Collect Excess',
					nextNode: 'UnableToCollectExcess',
					location: 'center'
				},
				{
					text: 'Cancel Job',
					nextNode: 'CancelJob',
					location: 'center'
				},
				{
					text: 'Change to Cash-in-lieu',
					nextNode: 'RecommendCIL1',
					location: 'center'
				},
				{
					text: 'Continue',
					nextNode: 'SetAppointment'
				}
			]
		},
		UnableToCollectExcess: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				authorization: (navs, store, modal) => {
					return modal.openModalDirectly(instance => {
						instance.type = 'warning';
						instance.heading = 'Unable to Collect Excess';
						instance.message = `The customer will be notified that you are unable to collect the excess from them. If they do not respond within 2 weeks then the job will be cancelled and you will be able to invoice a call-out fee.`;
						instance.navButtons = [
							{
								text: 'Back',
								linkType: 'nextNode',
								nextNode: 'Summary',
								color: 'alert'
							},
							{
								// TODO: Need to check which state the job should be set to after unable to collect excess
								text: 'Continue',
								color: 'primary',
								linkType: 'submitThenNext',
								nextNode: 'SubmissionSuccess',
								serverCalls: {
									reinstateJob: {
										errorMessage: 'Unable to reinstate job',
										directCall: (http, store) => {
											return store.select(getFullItemTwo)?.pipe(
												filter(x => !!x),
												take(1),
												switchMap((job: any) => {
													return http.post(`${environment.api_url}v1/job_action/update_job/`, {
														job_information: job.job_information,
														job_id: job?.id,
														new_state: 80
													});
												})
											);
										}
									}
								}
							}
						];
					});
				}
			},
			navs: []
		},
		CancelJob: {
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Reason for Cancellation',
							formControls: {
								0: {
									formControlName: 'cancelled_reason',
									inputType: 'textarea',
									label: 'Enter a reason why the customer cancelled',
									maxWidth: '50vw'
								}
							}
						}
					}
				]
			},
			checkValidityForFields: ['cancelled_reason'],
			initFormFields: (bf, item, instance, sq) => {
				// TODO: Need to check if current_state is required
				bf.addControl('new_state', new UntypedFormControl('80'));
				bf.bigForm.addControl('cancelled_reason', new UntypedFormControl('', Validators.required));
			},
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					// TODO: Check if State 80 job_information.cancelled_reason are being passed correctly
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(null, [Validators.required]),
						skillcatagory: new UntypedFormControl(null)
					})
				);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skill
										skillcatagory
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(values => {
						bf.bigForm.get('appointmentData').get('skill')?.patchValue(values.skill);
						bf.bigForm.get('appointmentData').get('skillcatagory')?.patchValue(values.skillcatagory);
					});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}

												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{
											'Client Name': `${queryData.first_name} ${queryData.surname}`
										},
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'spService',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				},
				jobLocationData: {
					errorMessage: 'The job location was not returned!',
					serviceVariable: 'spService',
					functionName: 'getJobLocation',
					responseSlice: 'payload'
				},
				teamleaderLocations: {
					errorMessage: 'Team leader locations not returned!',
					directCall: (http, store, sq, bf) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter(x => !!x),
							map((teamleaders: any[]) => teamleaders)
						);
					}
				}
			},
			component: 'FLXJobAppointmentComponent',
			navs: [
				{ text: 'Change to Cash-in-lieu', nextNode: 'RecommendCIL2' },
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'AssignTeamLeader'
				}
			]
		},
		RecommendCIL2: {
			component: {
				children: [
					{
						component: 'FLXKeyValueDisplayWithInstructionsComponent',
						inputs: {
							title: 'Client has requested to be paid out in cash',
							instructions: ['No work was done. Invoice a call out fee.'],
							keyValueList$: 'keyValueListForCIL1',
							title$: 'title'
						}
					}
				]
			},
			navs: [
				{
					text: 'Request Cash In Lieu',
					nextNode: 'SubmissionSuccess',
					color: 'primary'
				}
			]
		},
		RecommendCIL1: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				authorization: (navs, store, modal) => {
					return modal.openModalDirectly(instance => {
						instance.type = 'warning';
						instance.heading = 'Change to Cash-In-Lieu';
						instance.message = `Are you sure the customer has requested a cash-in-lieu payment?`;
						instance.navButtons = [
							{
								text: 'Back',
								linkType: 'nextNode',
								nextNode: 'Summary',
								color: 'alert'
							},
							{
								text: 'Yes, Request Cash-in-lieu',
								linkType: 'submitThenNext',
								nextNode: 'SubmissionSuccess',
								serverFirst: true,
								color: 'primary',
								serverCalls: {
									response: {
										errorMessage: 'Job could not be moved to cil!!',
										directCall: (http, store, sq, bf) => {
											return store.select(getFullItemTwo).pipe(
												take(1),
												switchMap((data: any) => {
													const effected_jobs: number[] = data?.id;
													const cil_state = 72;
													const cildata = {
														effected_jobs: [effected_jobs],
														cil_state: cil_state
													};
													return http.post(`${environment.api_url}v1/job_action/move_to_cil/`, cildata);
												})
											);
										}
									}
								}
							}
						];
					});
				}
			},
			navs: []
		},
		AssignTeamLeader: {
			initFormFields: bf => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl(null, [Validators.required]));
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter(x => !!x),
							map((teamleaders: any[]) =>
								teamleaders.map(teamLeader => {
									return {
										display: teamLeader.full_name,
										value: teamLeader.id,
										teamLeader,
										shouldHaveImage: true
									};
								})
							)
						);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXSelectListComponent',
						inputs: {
							heading: 'Select Team Leader',
							subheading: 'Please select a new team leader for the job',
							options$: 'tlListData',
							canFilter: true,
							maxWidth: '40%',
							collapseOnSelect: false,
							selectOptionFunc: instance => instance?.bf?.bigForm?.get('assign_teamleader_id')?.patchValue(instance.selectedOption.teamLeader.id)
						}
					},
					{
						component: 'FLXTeamleadPickerComponent',
						inputs: { joblocation$: 'jobLocationData', teamleadersPositions$: 'teamleaderLocations' }
					}
				]
			},
			navs: [
				{
					text: 'Update Map',
					color: 'secondary'
				},
				{
					text: 'Allocate Job',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							serviceVariable: 'spService',
							functionName: 'changeJobAppointmentFromContextMenu',
							followUpSuccessCalls: {
								response: {
									errorMessage: 'Error updating job',
									directCall: (http, store, sq, bf, controller) => {
										const getFullItemTwo$ = store.select(getFullItemTwo).pipe(
											skipWhile(item => !item),
											take(1)
										);

										const getCurrentUser$ = store.select(getCurrentUser).pipe(
											skipWhile(user => !user),
											take(1),
											map(user => user as any)
										);

										return forkJoin([getFullItemTwo$, getCurrentUser$]).pipe(
											take(1),
											map(([item, user]: any) => ({
												job_id: item?.id,
												staffmember: user.id,
												team_id: bf.bigForm.get('assign_teamleader_id')?.value || '',
												new_state: 87
											})),
											switchMap(data => http.post(`${environment.api_url}v1/job_action/assign_team/`, data))
										);
									}
								}
							}
						}
					},
					location: 'right'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: [],
			inputs: {
				autoClose: true
			}
		}
	},
	bigFormToStoreMapper: {
		purpose: 'purpose',
		current_state: 'current_state',
		new_state: ['new_state'],
		assign_teamleader_id: [
			[
				tlid => {
					if (tlid) {
						let id;
						tlid = tlid && Array.isArray(tlid) ? tlid : [];
						for (const lead of tlid) {
							id = parseInt(lead, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.team_leader'
			]
		],
		staffmember: 'job_information.staffmember',
		cancelled_reason: 'job_information.cancelled_reason'
	}
};
