import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { BigFormService, getLoadingState, getLoadedState } from '@flexus/core';
import { Store } from '@ngrx/store';
import { pluck } from 'rxjs/operators';
@Component({
	selector: 'flx-list-and-keys-info',
	templateUrl: './list-and-keys-info.component.html',
	styleUrls: ['./list-and-keys-info.component.scss']
})
export class FLXListAndKeysInfoComponent implements OnInit, OnDestroy {
	@Input() claimMid$: Observable<any>;
	@Input() listData: any;
	isLoading$: Observable<boolean>;
	isLoaded$: Observable<boolean>;

	constructor(public _store: Store<any>, public bf: BigFormService) {}

	ngOnInit() {
		this.isLoading$ = this._store.select(getLoadingState);
		this.isLoaded$ = this._store.select(getLoadedState);
	}
	ngOnDestroy() {}
}
