import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FLXFormsModule, FLXButtonsModule, FLXIconModule } from '@flexus/ui-elements';
import { AccordionGroupComponent } from './accordion-template-group.component';
import { AccordionTemplateComponent } from './accordion-template.component';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, FLXFormsModule, FLXButtonsModule, FLXIconModule],
	declarations: [AccordionGroupComponent, AccordionTemplateComponent],
	exports: [AccordionGroupComponent, AccordionTemplateComponent]
})
export class FLXAccordionModule {}
