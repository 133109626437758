<div class="upload-preview-content container">
	<div class="upload-header">
		<flx-heading *ngIf="fileQueue.length < 1" size="medium" align="center">{{ heading }}</flx-heading>
	</div>
	<div class="upload-preview-content" style="display: flex">
		<div [ngStyle]="{ width: (formControls | isEmpty) || fileQueue.length < 1 ? '100%' : '70%' }" style="width: 70%; margin: 1rem">
			<div class="single-upload" *ngIf="!multipleUploads">
				<flx-expanded-file-select *ngIf="fileQueue.length < 1" [config]="fileSelectConfig" (outputData)="handleFileSelection($event)"> </flx-expanded-file-select>
				<div class="single-file-view" *ngIf="fileQueue.length >= 1">
					<flx-file-viewer [base64Source]="fileQueue[0]?.file.base64" [mimeType]="fileQueue[0]?.file.type"> </flx-file-viewer>
					<div class="file-upload-preview-buttons"><flx-button color="danger" (click)="removeAllFiles()">Remove File</flx-button></div>
				</div>
			</div>
			<div *ngIf="!(formControls | isEmpty) && fileQueue.length > 0" style="width: 30%; margin: 1rem">
				<flx-flat-dynamic-form containerWidth="100vw" formLayout="one-column" [formControls]="formControls"> </flx-flat-dynamic-form>
			</div>
			<div *ngIf="multipleUploads">
				<flx-expanded-multi-file-select *ngIf="fileQueue.length < 1" (outputData)="handleFileSelection($event)"></flx-expanded-multi-file-select>
				<ng-container *ngIf="fileQueue.length > 0">
					<div class="multiple-file-view">
						<flx-multi-file-upload-viewer [images$]="images"></flx-multi-file-upload-viewer>
						<!-- <span *ngFor="let file of files$ | async">this will be the multi file viewer</span> -->
					</div>
				</ng-container>
			</div>

			<ng-container *ngIf="formControls !== null && fileQueue.length > 0">
				<flx-flat-dynamic-form containerWidth="50vw" formLayout="three-column" [formControls]="formControls"> </flx-flat-dynamic-form>
			</ng-container>
		</div>
	</div>
</div>
