import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { BigFormService } from '@flexus/core';
import { findName } from '@flexus/utilities';

@Component({
	selector: 'flx-warranty-void',
	templateUrl: './warranty-void-jobcontext.component.html',
	styleUrls: ['./warranty-void-jobcontext.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXWarrantyVoidComponent implements OnInit, OnDestroy {
	@Input() warrantyVoidData$: Observable<any>;
	@Input() heading: string;
	@Input() subtitle: string;

	public _job: any;
	keyvaldata$: Observable<any>;
	claimtype: string;
	claimTypes: any;
	claimant: string;
	currentstate: any;
	jobId: string;
	sub: Subscription;

	constructor(private bf: BigFormService, private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this.sub = this.warrantyVoidData$
			.pipe(
				skipWhile(data => !data),
				map(data => {
					return data;
				})
			)
			.subscribe(res => {
				// debugger;
				const { job, stor } = res;
				if (job) {
					this.jobId = job?.id;
					this.claimTypes = stor.allInfo.claim_types;
					this.claimtype = findName(job.claim?.claim_type, this.claimTypes).name; // this.claimTypes[job.claim?.claim_type].name;
					this.currentstate = job?.state;
					this.claimant = `${job.claim?.applicant?.first_name} ${job.claim?.applicant?.surname}`;
					this._job = job;
					this.keyvaldata$ = of([{ claimType: this.claimtype, claimant: this.claimant, jobID: this.jobId, currentstate: this.currentstate }]);
				}
				this.cdr.detectChanges();
			});
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}
}
