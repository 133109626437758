import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdentityModule } from '@flexus/identity';
import { environment } from '../../../environments/environment';
import { MsalModule } from '@azure/msal-angular';
import { LoadSpManifestService } from '../../sp_globals/services/load-sp-manifest.service';
import { bet_sp } from './bet-sp.manifest';
import { SpGlobalModule } from '../../sp_globals/sp-global.module';
import { SearchBannerModule } from '../../app-shell-features';
import { PublicClientApplication } from '@azure/msal-browser';


export function loadConfig(manifest: LoadSpManifestService) {
	return function () {
		return manifest.load(bet_sp);
	};
}
@NgModule({
	imports: [
		CommonModule,
		...environment.appShellFeatures,
		SpGlobalModule,
		environment['azure']
			? MsalModule.forRoot(
					new PublicClientApplication({
						auth: {
							clientId: environment['azure']['clientId'],
							postLogoutRedirectUri: `${environment.base_url}auth/login`
						}
					}),
					null,
					null
			  )
			: CommonModule,
		IdentityModule.forRoot({
			after_login_url: '/workflow',
			after_logout_url: '/auth/login',
			send_reset_link_endpoint: `${environment.api_url}v1/forgot_password/check-email`,
			login_endpoint: `${environment.api_url}v2/auth/login/`,
			no_auth_urls: ['/cons']
		}),
		SearchBannerModule
	],
	declarations: [],
	exports: [...environment.appShellFeatures],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: loadConfig,
			deps: [LoadSpManifestService],
			multi: true
		}
	]
})
export class BetSpManifestModule {}
