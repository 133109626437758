import { NgModule } from '@angular/core';
import { FLXPopupOverlayPickerComponent, FLX_POPUP_OVERLAY_SCROLL_STRATEGY_PROVIDER } from './popup-overlay-picker.component';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
	imports: [CommonModule, OverlayModule, PortalModule],
	declarations: [FLXPopupOverlayPickerComponent],
	exports: [FLXPopupOverlayPickerComponent],
	providers: [FLX_POPUP_OVERLAY_SCROLL_STRATEGY_PROVIDER]
})
export class FLXPopupOverlayPickerModule {}
