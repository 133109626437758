import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { CONTEXT_DATA_CALLS } from '../../reusable/DATA_CALLS';
import { CollapseActionPanel, setActionPanelItems } from '../../../../../app-shell-features';
import { of } from 'rxjs';
import { findExcessRow, getExcessCalculationValue, makeExcessCard } from '@flexus/utilities';

export const BET_UPLOAD_DOCUMENTS: Flow_0_0_2 = {
	id: 'UploadDocuments',
	itemType: 'flow',
	fetchLevel1And2: true,
	name: 'Upload Documents',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant) {
							return `Upload Documents : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Upload Documents';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'UploadDocuments',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	onStateInit: instance => {
		// inst.store.dispatch(new CollapseActionPanel());
		const paramFunc = (inst, mapper) => {
			return {
				id: 'claim-excess',
				icon: 'excess-details',
				command: inst.loadComponent.bind(inst, mapper['FLXExcessDetailsOnJobComponent'])
			};
		};
		instance.navService.portalActions.next({ call: 'onClick', paramFunc });
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['claim-excess', 'sp-details', 'documents']),
	serverCalls: {
		...CONTEXT_DATA_CALLS
	},
	nodes: {
		UploadDocuments: {
			component: 'FLXUploadDocumentsComponent',
			inputs: {
				location: 'screen'
			},
			name: 'Upload Documents',
			serverCalls: {},
			navs: [
				{
					text: 'Upload Document',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit',
					serverCalls: {
						uploadDoc: {
							errorMessage: 'File could not be uploaded',
							timeoutMilliseconds: 2000,
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: any = {
									data: fileQueue[0]?.file.base64,
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									invoice_number: bf.bigForm.get('invoice_number') ? bf.bigForm.get('invoice_number')?.value : undefined,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}`
								};
								if (bf.bigForm.get('purpose')?.value === 'Photo') {
									body.filename += `.${fileQueue[0]?.file.type.substring(fileQueue[0]?.file.type.indexOf('/') + 1)}`;
									body.filename = body.filename?.replace('jpeg', 'jpg');
									body.thumbnail = fileQueue[0]?.file.base64;
								} else {
									body.filename += '.pdf';
								}
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
							},
							followUpSuccessCalls: {
								updateExcess: {
									errorMessage: 'Can not update excess!!',
									directCall: (http, store, sq, bf) => {
										const itemTwo = bf.bigForm.get('itemTwo')?.value;
										// check if the excess field exists, else it ignores it
										if (bf.bigForm.get('excess_amount') && bf.bigForm.get('excess_amount')?.value !== undefined) {
											let allExcess = [];
											if (itemTwo.excess !== undefined && itemTwo?.excess !== null) {
												if (!Array.isArray(itemTwo.excess)) {
													allExcess.push(itemTwo.excess);
												} else {
													allExcess = itemTwo?.excess;
												}
											}
											const jobExcess = findExcessRow(itemTwo.id, allExcess);
											let params = {};
											const excessVal = getExcessCalculationValue(bf.bigForm.get('invoice_amount')?.value, bf.bigForm.get('excess_amount')?.value);

											if (jobExcess.length === 0) {
												params = { job_id: itemTwo?.id, new_excess: makeExcessCard(excessVal) }; // Attach new excess to job
											} else if (jobExcess.length >= 1) {
												params = { excess_id: jobExcess[0]?.id, new_value: excessVal }; // change existing excess
											}
											return http.post<any>(`${environment.api_url}v1/job_action/change_excess/`, params);
										} else {
											return of(false);
										}
									}
								},
								displaySucces: {
									errorMessage: 'Could not diplay success',
									directCall: (http, store, sq, bf, con, modal) => {
										modal.openModalDirectly(inst => {
											inst.type = 'success';
											inst.heading = 'Successfully uploaded file.';
											inst.navButtons = [
												{
													text: 'Continue',
													color: 'primary',
													linkType: 'close'
												}
											];
										});
										return of(true);
									}
								},
								files: {
									serviceVariable: 'betService',
									functionName: 'getAllJobFiles',
									responseSlice: 'payload',
									errorMessage: 'Could not get files from server!'
								}
							},
							followUpFailCalls: {
								displaySucces: {
									errorMessage: 'Could not diplay success',
									directCall: (http, store, sq, bf, con, modal) => {
										modal.openModalDirectly(inst => {
											inst.type = 'warning';
											inst.heading = 'Could not upload file.';
											inst.navButtons = [
												{
													text: 'Continue',
													color: 'secondary',
													linkType: 'close'
												}
											];
										});
										return of(false);
									}
								}
							}
						}
					}
				}
			]
		}
	}
};
