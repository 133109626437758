import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { UIElementsModule } from '@flexus/ui-elements';
import { PipesModule } from '@flexus/pipes';
import { FLXSpJobNotificationsWindowComponent } from './sp-job-notifications-window.component';
import { FLXJobNotificationDetailComponent } from './sp-job-notification-detail';

@NgModule({
	declarations: [FLXSpJobNotificationsWindowComponent, FLXJobNotificationDetailComponent],
	imports: [CommonModule, NgxPaginationModule, UIElementsModule, PipesModule],
	exports: [FLXSpJobNotificationsWindowComponent]
})
export class FLXSpJobNotificationsWindowModule {}
