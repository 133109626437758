<div class="container">
	<div class="refund-breakdown-content">
		<div class="heading"><flx-heading size="medium" color="primary" align="center"></flx-heading></div>
		<div *ngIf="subheading">{{ subheading }}</div>
		<div class="card-holder">
			<div class="card">
				<div class="descriptor">Voucher amount</div>
				<div class="amount">{{ voucher_amount | currency: 'R' }}</div>
			</div>
			<div class="glow-line"><flx-glow-line></flx-glow-line></div>
			<div class="card bottom-out">
				<div class="descriptor">Call-out fee</div>
				<div class="amount">{{ calloutfee | currency: 'R' }}</div>
			</div>
			<div class="card bottom-out">
				<div class="descriptor boldprimary">Refund due</div>
				<div class="amount boldprimary">{{ refund_due | currency: 'R' }}</div>
			</div>
		</div>
	</div>
</div>
