import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';

import { skipWhile, map, take, switchMap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { UntypedFormControl, Validators } from '@angular/forms';
import { getExtension } from '@flexus/utilities';

export const SP_GLOBAL_156: Flow_0_0_2 = {
	id: '156',
	name: 'quote_query_report',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Query Report - On-site Assessment Complete : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Query Report - On-site Assessment Complete';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		claimDetailKeyValues: {
			errorMessage: 'Something went wrong claim details',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							const claimdetaillist = {
								'Claim Type ': itemTwo?.claim?.type ?? '',
								'Skill required ': itemTwo?.office_use?.skillcatagory ?? '',
								'Address ': itemTwo ? itemTwo?.address : '',
								'Appointment Date ': itemTwo?.office_use?.requestedappointmentdate ?? 'No appointment date set',
								'Appointment Time ': itemTwo?.office_use
									? `${itemTwo.office_use.appointment_type} ${itemTwo.office_use.requestedappointmenttime}`
									: 'Appointment time not retrieved'
							};
							return [claimdetaillist];
						})
					);
			}
		}
	},

	instructions: {
		editRoles: {
			0: 'Upload assessment report'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'UploadAssessmentReportNotification',
	nodes: {
		UploadAssessmentReportNotification: {
			initFormFields: (bf, item, instance, sq, store) => {
				bf.addControl('new_state', new UntypedFormControl('26'));
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Upload Assessment Report',
							instructions: ['The team leader has been onsite to assess the job.', 'Please upload an assessment report to complete job.']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			name: 'Job Summary',
			showTabs: true,
			serverCalls: {
				claimDetailKeyValues: {
					errorMessage: 'Something went wrong claim details',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const claimdetaillist = {
										'Claim Type ': itemTwo?.claim?.type ?? '',

										'Address ': itemTwo ? itemTwo?.address : '',
										'Appointment Date ': itemTwo?.office_use?.requestedappointmentdate ?? 'No appointment date set',
										'Appointment Time ': itemTwo?.office_use
											? `${itemTwo.office_use.appointment_type} ${itemTwo.office_use.requestedappointmenttime}`
											: 'Appointment time not retrieved'
									};
									return [claimdetaillist];
								})
							);
					}
				},
				customerContactKeyValues: {
					errorMessage: 'Customer contact information could not be retrieved',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const customercontactlist = {
										'Customer ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : 'Customer name not available',
										'Contact number': itemTwo?.claim?.loan_information ? `${itemTwo?.claim?.loan_information?.contactnumber}` : 'Cellular number not on record',
										'Customer cell ': itemTwo?.claim?.loan_information?.cellnumber ?? 'Cellular number not on record',
										'Customer Alernative No ': itemTwo?.claim?.loan_information?.contactnumber ?? 'Cellular number not on record'
									};
									return [customercontactlist];
								})
							);
					}
				}
			},
			navs: [{ text: 'Continue', linkType: 'submit', color: 'primary', nextNode: 'UploadOnsiteAssessmentReport' }]
		},
		UploadOnsiteAssessmentReport: {
			initFormFields: (bf, item, instance, sq, store) => {
				bf.bigForm.addControl('purpose', new UntypedFormControl('report', [Validators.required]));
			},
			component: 'FLXFileUploadWithPreviewComponent',
			name: 'On-site Report',
			showTabs: true,
			inputs: {
				purposes: [{ display: 'Onsite Assessment', value: 'report' }],
				heading: 'Upload TL Report',
				multipleUploads: false,
				// allowedFileTypes: ['pdf'],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			checkValidityForFields: ['purpose'],
			navs: [
				{
					text: 'Upload Report',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit',
					serverCalls: {
						uploadReport: {
							errorMessage: "Couldn't upload report",
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								console.log({ fileQueue });
								const body: object = {
									data: fileQueue[0]?.file?.base64,
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								return http
									.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
										observe: 'events',
										reportProgress: true
									})
									.pipe();
							},
							followUpSuccessCalls: {
								response: {
									errorMessage: 'Job could not be updated',
									// serviceVariable: 'spService',
									directCall: (http, store, sq, bf) => {
										return store.select(getFullItemTwo)?.pipe(
											skipWhile(x => !x),
											take(1),
											map(job => {
												const spJob = job as any;
												return {
													job_information: spJob.job_information,
													job_id: spJob.id,
													current_state: '156',
													new_state: '26'
												};
											}),
											switchMap(data => {
												return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
											})
										);
									}
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'File successfully uploaded' },
			navs: [
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccessContinue'
				}
			]
		},
		SubmissionSuccessContinue: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
