import { Flow_0_0_2, getSelectedItemOne, getFullItemOne, getAllInfo, getData, SetNextNode } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { take, filter, skipWhile, first, map, switchMap, pluck } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';

export const BUI_55: Flow_0_0_2 = {
	id: '55',
	name: 'finalise_claim',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Finalise Claim : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Finalise Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance =>
		setActionPanelItems(instance, ['notes', 'documents', 'time-stamp', 'claim-card', 'call-logs'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	instructions: {
		editRoles: {
			0: 'Finalise Claim'
		},
		viewRoles: {
			0: '--'
		}
	},
	events: {},
	fetchLevel1And2: true,
	serverCalls: {
		closeClaimSummary: {
			serviceVariable: 'buiService',
			errorMessage: '',
			ignoreFalseError: true,
			functionName: 'getCloseClaimInfo'
		},
		canCloseClaim: {
			serviceVariable: 'buiService',
			directCall: (http, store, sq, bf, controller, modal) => {
				return forkJoin([
					store.select(getSelectedItemOne).pipe(
						skipWhile(x => !x),
						take(1),
						map(res => res as any)
					),
					store.select(getAllInfo).pipe(
						skipWhile(x => !x),
						take(1),
						map(res => res as any[])
					)
				]).pipe(
					switchMap(([claim, information]) => {
						const claim_id = claim?.id;
						const info = information;
						const data = {
							claim_id: claim_id
						};
						return http.post(`${environment.api_url}v1/claim_action/can_finalise_claim`, data).pipe(
							skipWhile(x => !x),
							take(1),
							map((res: any) => {
								bf.bigForm.get('maven_claim_state')?.setValue(res?.payload?.maven_claim_state);
								if (res && res['success']) {
									if (res['success'] === true) {
										bf.bigForm.get('maven_claim_state')?.setValue(res['payload']['maven_claim_state']);
										bf.bigForm.get('reasons_provided')?.setValue(true);
									} else if (res['success'] === false) {
									}
								}
							})
						);
					})
				);
			},
			errorMessage: '    Claim can not be closed',
			ignoreFalseError: true
		},
		claimAppointments: {
			serviceVariable: 'buiService',
			functionName: 'getClaimAppointments',
			// responseSlice: 'payload',
			errorMessage: 'No claim appointments could be found!'
		},
		paymentsExist: {
			errorMessage: `Payments don't exist`,
			directCall: (http, store, sq, bf) => {
				return store
					.select(getData)
					.pipe(
						pluck('claimAppointments', 'payload', 'xml'),
						skipWhile(x => !x),
						take(1),
						map(pay => pay as any)
					)
					.pipe(
						switchMap(pay => {
							const payments = pay.payments;
							console.log('Payments', payments);
							bf.addControl('payments_exist', new UntypedFormControl(null));
							bf.addControl('number_of_payments', new UntypedFormControl(null));
							if (payments === null || payments === undefined || Array.isArray(payments)) {
								bf.bigForm.get('payments_exist')?.setValue(false);
								bf.bigForm.get('number_of_payments')?.setValue(0);
							} else {
								bf.bigForm.get('payments_exist')?.setValue(true);
								bf.bigForm.get('number_of_payments')?.setValue(payments['item']?.length);
								console.log(`THIS LOOOONG`, payments['item']?.length);
							}
							return of(payments);
						})
					);
			}
		}
	},
	startNode: 'CloseClaim',
	nodes: {
		CloseClaim: {
			mockContextData: {},
			checkValidityForFields: ['reasons_provided'],
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.addControl('new_state', new UntypedFormControl(7));
				bf.addControl('reasons_provided', new UntypedFormControl(false, [Validators.requiredTrue]));
				bf.addControl('maven_claim_state', new UntypedFormControl(null, [Validators.required]));
			},
			inputs: {
				title: 'Close the Claim',
				subtitle: 'Confirm that all work was done and close the claim.',
				maven_claim_stateArray: [
					{ id: -1, description: 'Undefined' },
					{ id: 0, description: 'Cancel claim' },
					{ id: 1, description: 'Cancel with excess' },
					{ id: 2, description: 'Finalise' },
					{ id: 3, description: 'Repudiate' }
				],
				reasonsArray: [
					{ id: 0, description: ' Job not paid on maven' },
					{ id: 1, description: ' Job not appointed / paid on Maven' },
					{ id: 2, description: ' No consignment invoice on Maven' },
					{ id: 3, description: ' Maven response problem' }
				]
			},
			component: 'CloseClaimComponent',
			serverCalls: {},
			navs: [
				{
					text: 'Finalise',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							ignoreFalseError: false,
							errorMessage: 'Claim could not be closed',
							// functionName: 'finaliseClaim',
							directCall: (http, store, sq, bf, cntrl, modal) => {
								return forkJoin([
									store.select(getFullItemOne).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getData).pipe(
										pluck('closeClaimSummary'),
										skipWhile(x => !x),
										take(1),
										map(res => res?.payload)
									)
								]).pipe(
									switchMap(([res, finalisedData]) => {
										const mavenstate: number = bf.bigForm.get('maven_claim_state')?.value;
										const claim_id: any = res?.id;
										switch (mavenstate) {
											case 0: // ---------------------------- Cancelled (4)----------------------------
											case 1: // ---------------------------- Cancelled - with Excess (13) ----------------------------
												const reason = mavenstate === 0 ? '4' : '13';
												const canceledData = {
													claim_id: claim_id,
													reason: reason
												};
												return http.post(`${environment.api_url}v1/claim_action/cancel_claim/`, canceledData);
											case 2: // ---------------------------- Finalise claim ----------------------------
												const data = {
													claim_id: claim_id
												};
												return http.post(`${environment.api_url}v1/claim_action/finalise_claim/`, data).pipe(
													filter(x => !!x),
													take(1),
													map(res => {
														if (!res['success']) {
															modal.openModalDirectly(instance => {
																instance.type = 'warning';
																instance.message = res['reason'] ? res['reason'][0] : 'An error occurred could not finalise claim';
																instance.navButtons = [
																	{
																		text: 'Back to workflow',
																		clickHandler: event => {
																			instance.router.navigate(['/workflow']);
																		},
																		linkType: 'close',
																		color: 'alert'
																	}
																];
															});
														}
													})
												);
											case 3: // ---------------------------- Finalise Repudiation ----------------------------
												const repData = {
													claim_id: claim_id,
													repudiation_type: res?.office_use.repudiation ? res?.office_use.repudiation.repudiation_type : '',
													repudiation_reason: 1,
													letter_key: res?.office_use.repudiation.repudiation_letter,
													repudiation_sub_reason: 1,
													diagnosis: '--',
													email: res?.office_use.repudiation ? res?.office_use.repudiation.emailaddress : '',
													final: 'Y',
													sendletter: 'N',
													_optionalState: 7
												};
												return http.post(`${environment.api_url}v1/claim_action/finalise_repudiation/`, repData);
										}
									})
								);
							}
						}
					}
				},
				{
					text: 'View Payments',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance, store, bf) => {
							if (bf.bigForm.get('payments_exist')?.value === false) {
								(instance.type = 'info'), instance?.setMessage(['There are no payments associated with this claim']);
								instance.navButtons = [
									{
										text: 'OK',
										color: 'default',
										linkType: 'close'
										// nextNode: 'CloseClaim'
									}
								];
							} else if (bf.bigForm.get('payments_exist')?.value === true) {
								instance.type = 'info';
								if (bf.bigForm.get('number_of_payments')?.value === 1) {
									instance.setMessage([bf.bigForm.get('number_of_payments')?.value + ` Payment has been made on this claim`, `Click 'View Payments' to proceed`]);
								} else {
									instance.setMessage([bf.bigForm.get('number_of_payments')?.value + ` Payments have been made on this claim`, `Click 'View Payments' to proceed`]);
								}
								instance.navButtons = [
									{
										text: 'No thanks!',
										color: 'default',
										linkType: 'close'
									},
									{
										text: 'View Payments',
										color: 'default',
										linkType: 'nextNode',
										nextNode: 'ViewPayments'
									}
								];
							}
						}
					},
					// nextNode: 'Decision',
					location: 'center',
					color: 'secondary'
				}
			]
		},
		ViewPayments: {
			serverCalls: {},
			component: 'ViewPaymentsComponent',
			initFormFields: () => {}
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		claim_id: 'claim_id',
		new_state: 'new_state'
	}
};
