import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getFullItemOne } from '@flexus/core';
import { take, map, filter } from 'rxjs/operators';
import moment from 'moment';
@Component({
	selector: 'flx-ap-claim-summary',
	templateUrl: './ap-claim-summary.component.html',
	styleUrls: ['./ap-claim-summary.component.scss']
})
export class FLXAPClaimSummaryComponent implements OnInit, OnDestroy {
	claimDetails$: Observable<any>;
	claimantData$: Observable<any>;
	addressData$: Observable<any>;
	whatMattersData$: Observable<any>;

	constructor(private store: Store<any>) {}

	ngOnInit(): void {
		this.loadFullClaim();
	}

	// Loading the full claim
	loadFullClaim() {
		this.store
			.select(getFullItemOne)
			.pipe(
				filter(x => !!x),
				take(1)
			)
			.subscribe(selectedFullClaim => {
				const { loan_information, applicant } = selectedFullClaim;

				// Creating claim details observable.
				this.claimDetails$ = of([
					{
						'Claim Description': loan_information?.claimdescription,
						'Date of Loss': `${moment(loan_information?.dateofloss)?.format(moment.HTML5_FMT.DATE)} ${moment(loan_information?.dateofloss)?.format(moment.HTML5_FMT.TIME)}`,
						'Claim Handler': loan_information?.handler,
						'Handler Email': loan_information?.handleremail,
						'Handler Contact': loan_information?.handlercontact
					}
				]);
				// Creating claimant observable.
				this.claimantData$ = of([
					{
						'Full Name': applicant?.first_name + ' ' + applicant?.surname,
						// Commented out because it's missing in the full claim object.
						// 'ID No': '',
						'Policy No': loan_information?.mavenclaimnumber,
						'Bond No': loan_information?.clientcode,
						'Contact No': loan_information?.contactnumber,
						Email: loan_information?.email
					}
				]);

				// Creating address observable.
				this.addressData$ = of([
					{
						Complex: loan_information?.propertycomplex,
						'Unit Number': loan_information?.propertycomplexunitnumber,
						Street: loan_information?.propertystreetaddress,
						Suburb: loan_information?.propertysuburb,
						City: loan_information?.propertycity,
						// commented out because the full claim didn't have province key.
						// Province: '',
						'Area Code': loan_information?.suburbcode
					}
				]);

				// Creating What matters observable.
				this.whatMattersData$ = of(selectedFullClaim.loan_information?.whatmatters);
			});
	}

	ngOnDestroy(): void {
		// Destroying the subscription.
	}
}
