<flx-flex-container alignItems="center">
	<flx-heading size="medium" align="center" type="creation" weight="extra-light">{{ title }}</flx-heading>
	<br />
	<form [formGroup]="bf.bigForm">
		<flx-select-list
			[options$]="damageClasses$"
			formControlName="selectedDamageClass"
			[height]="selectedDamageClassId === -1 ? '40vh' : '150px'"
			[canFilter]="selectedDamageClassId === -1"
			maxWidth="300px"
		>
		</flx-select-list>
	</form>
</flx-flex-container>
