<!-- Action Panel -->
<flx-heading type="page" size="medium">Documents</flx-heading>
<flx-accordion-template>
	<flx-accordion-group title="Photos" *ngIf="photos$ !== null">
		<ng-container *ngIf="photos$ | async as photos">
			<flx-file-browser
				[showFilters]="true"
				thumbSize="100px"
				paginationId="photos"
				*ngIf="photos?.length > 0; else noPhotos"
				[files$]="photos$"
				[location]="location"
				display="image-grid"
			>
			</flx-file-browser>
		</ng-container>
		<ng-template #noPhotos>
			<div class="empty-zone"><flx-alert type="no-info" missingItem="photos"></flx-alert></div>
		</ng-template>
	</flx-accordion-group>

	<flx-accordion-group title="Quotations" *ngIf="quotations$ !== null">
		<ng-container *ngIf="quotations$ | async as quotations">
			<flx-file-browser
				[showFilters]="false"
				paginationId="quotations"
				*ngIf="quotations?.length > 0; else noQuotations"
				[files$]="quotations$"
				[location]="location"
				display="file-grid"
			></flx-file-browser>
		</ng-container>
		<ng-template #noQuotations>
			<div class="empty-zone"><flx-alert type="no-info" missingItem="quotations"></flx-alert></div>
		</ng-template>
	</flx-accordion-group>

	<flx-accordion-group title="Invoices" *ngIf="this.invoices$ !== null">
		<ng-container *ngIf="invoices$ | async as invoices">
			<flx-file-browser
				[showFilters]="false"
				paginationId="invoices"
				*ngIf="invoices?.length > 0; else noInvoices"
				[files$]="invoices$"
				[location]="location"
				display="file-grid"
			></flx-file-browser>
		</ng-container>
		<ng-template #noInvoices>
			<div class="empty-zone"><flx-alert type="no-info" missingItem="invoices"></flx-alert></div>
		</ng-template>
	</flx-accordion-group>

	<flx-accordion-group title="Reports" *ngIf="this.reports$ !== null">
		<ng-container *ngIf="reports$ | async as reports">
			<flx-file-browser
				[showFilters]="false"
				paginationId="reports"
				*ngIf="reports?.length > 0; else noReports"
				[files$]="reports$"
				[location]="location"
				display="file-grid"
			></flx-file-browser>
		</ng-container>
		<ng-template #noReports>
			<div class="empty-zone"><flx-alert type="no-info" missingItem="reports"></flx-alert></div>
		</ng-template>
	</flx-accordion-group>
	<flx-accordion-group title="Latest Documents" *ngIf="latestDocuments$ !== null">
		<flx-heading size="extra-small" align="center" weight="extra-light"> Latest Invoice, Quotation and Report </flx-heading>
		<ng-container *ngIf="latestDocuments$ | async as latestDocuments">
			<flx-file-browser
				[showFilters]="false"
				paginationId="latest-documents"
				*ngIf="latestDocuments?.length > 0; else noLatestDocuments"
				[files$]="latestDocuments$"
				display="file-grid"
				[location]="location"
			></flx-file-browser>
		</ng-container>
		<ng-template #noLatestDocuments> <flx-alert type="no-info" missingItem="documents"></flx-alert> </ng-template>
	</flx-accordion-group>
</flx-accordion-template>
