import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';
import { DcModule, DynamicLoaderModule } from '@flexus/engine';
import { WorkflowAppRoutingModule } from './workflow-app.routing';
import { FLXWorkflowAppComponent } from './workflow-app.component';
import { UIElementsModule } from '@flexus/ui-elements';
@NgModule({
	declarations: [FLXWorkflowAppComponent],
	imports: [
		CommonModule,
		WorkflowAppRoutingModule,
		CommonModule,
		FormsModule,
		// OfflineModule,
		PortalModule,
		UIElementsModule,
		DynamicLoaderModule,
		// ErrorHandlerModule,
		DcModule.withComponents([])
	]
})
export class WorkflowAppModule {}
