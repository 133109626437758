import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { humaniseDate } from '@flexus/utilities';
import { Reminder } from '@flexus/models';
import { Subscription } from 'rxjs';

@Component({
	selector: 'flx-reminder-card',
	templateUrl: './reminder-card.component.html',
	styleUrls: ['./reminder-card.component.scss']
})
export class FLXReminderCardComponent implements OnInit, OnDestroy {
	// ====================================================== VARIABLES ======================================================
	reminderInstance: Reminder;
	// ====================================================== INPUTS / OUTPUTS ======================================================
	@Output() reminderCardCommand = new EventEmitter();
	@Input() reminder: Reminder;
	reminderSubscription: Subscription;

	// ====================================================== LIFE CYCLE METHODS ======================================================
	constructor() {}

	ngOnInit() {
		this.reminderInstance = new Reminder(this.reminder);
		this.reminderSubscription = this.reminderInstance.triggerReminder$.subscribe(reminder => {
			// DISPLAY NOTIFICATION
			this.reminderCardCommand.emit({ action: 'showNotification', reminder });
			// DELETE FROM INDEXEDDB & REMOVE FROM IN-MEMORY
			this.reminderCardCommand.emit({ action: 'deleteSilently', id: reminder.id });
		});
	}

	// ====================================================== GENERAL METHODS ======================================================
	takeAction(action: string, reminder: Reminder) {
		switch (action) {
			case 'edit':
				this.reminderCardCommand.emit({ action: 'edit', id: reminder.id });
				break;
			case 'open':
				this.reminderCardCommand.emit({ action: 'open', id: reminder.id });
				break;
			case 'openToClaim':
				this.reminderCardCommand.emit({ action: 'openToClaim', claimNo: reminder?.claimNo });
				break;
			case 'delete':
				this.reminderCardCommand.emit({ action: 'delete', id: reminder.id });
				break;
		}
	}

	formatDate(date: string): string {
		return humaniseDate(date);
	}

	ngOnDestroy() {
		if (this.reminderSubscription) this.reminderSubscription.unsubscribe();
	}
}
