import { Flow_0_0_2 } from '@flexus/core';

export const BET_154: Flow_0_0_2 = {
	id: '154',
	name: 'upfront_repudiation',
	itemType: 'flow',
	header: {
		title: 'Upfront Repudation',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Take claim action'
		},
		viewRoles: {
			0: 'Take claim action'
		}
	},
	startNode: '',
	nodes: {}
};
