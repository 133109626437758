import { Flow_0_0_2, getFullItemOne, getSubmissionData } from '@flexus/core';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import gql from 'graphql-tag';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { transformJobsToJobCardRequest } from '../../create-claim/transform.functions';
import { BuiCreateClaim } from '../../create-claim/bui-create-claim';
import { flatten, values } from 'ramda';
import { CustomValidators } from '@flexus/utilities';
import { forkJoin } from 'rxjs';
import { NewJobPayload } from '../../../models/new-job.model';
import moment from 'moment';
import { BUI_CHANGE_CLAIM_CLASS } from './CHANGE_CLAIM_CLASS';
import { BUI_BANK_DETAILS } from '../../flows';

export const BUI_ADD_NEW_JOB_CARD: Flow_0_0_2 = {
	id: 'AddNewJobCard',
	itemType: 'flow',
	name: 'Add New Job Card',
	excludeForOnlyStates: [169],
	excludeForOnlyRoles: [18],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Add new job card to this claim :  ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Add new job card to claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ClaimDetails',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		ClaimDetails: {
			component: 'ClaimClassComponent',
			serverCalls: {
				...BUI_CHANGE_CLAIM_CLASS?.nodes?.ChangeClaimClass.serverCalls
			},
			initFormFields: (bf, item, comp, sq, store) => {
				bf.bigForm.removeControl('jobs');
				BUI_CHANGE_CLAIM_CLASS?.nodes?.ChangeClaimClass.initFormFields(bf, item, comp, sq, store);
			},
			inputs: {
				...BUI_CHANGE_CLAIM_CLASS?.nodes?.ChangeClaimClass.inputs,
				blockedLossClasses: [{ class: 28, message: 'Cannot Add New Job Card', name: 'Home assist' }] // Loss classes that cannot have a new job card added
			},
			checkValidityForFields: ['claim_class', 'can_progress'],
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					intersectData: (bf, store) => {},
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							instance.store
								.select(getFullItemOne)
								.pipe(take(1))
								.subscribe(data => {
									if (!instance?.bf?.bigForm?.get('whatmatters')) {
										instance?.bf?.bigForm?.addControl('whatmatters', new UntypedFormControl(data?.loan_information.whatmatters));
									}

									if (!instance?.bf?.bigForm?.get('date_of_loss')) {
										instance?.bf?.bigForm?.addControl('date_of_loss', new UntypedFormControl(data?.loan_information.dateofloss));
									}
								});
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: { isDateOfLossDisabled: true }
								})
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'newJobCard', optIntoValidation: true }
			]
		},
		newJobCard: {
			name: 'Add New Job',
			serverCalls: {
				claimDetailsInfo: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											claim_types {
												id
												name
												description
												skill_groups
												priority
											}
											skills {
												id
												name
											}
											config_options {
												general {
													ia_request_reasons
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(x => !x),
								take(1)
							);
					}
				}
			},
			initFormFields: (bf, item, comp, sq, store) => {
				store
					.select(getFullItemOne)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(claim => {
						const controls = [];
						if (claim && claim?.loan_information && claim?.loan_information.jobcardrequest) {
							claim?.loan_information.jobcardrequest.forEach(request => {
								controls.push(comp.createJob(request));
							});
						}
						bf.bigForm.addControl('currentJobs', new UntypedFormArray(controls));
					});
				// IA Reason needed
				bf.bigForm.addControl('ia_request_reason', new UntypedFormControl(null));
			},
			inputs: {
				cashInLieuComp: 'CashInLieu',
				offPanelComp: 'OffPanel',
				showCurrentJobs: true
			},
			component: 'AddJobComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							instance.store
								.select(getFullItemOne)
								.pipe(take(1))
								.subscribe(data => {
									if (!instance?.bf?.bigForm?.get('whatmatters')) {
										instance?.bf?.bigForm?.addControl('whatmatters', new UntypedFormControl(data?.loan_information.whatmatters));
									}

									if (!instance?.bf?.bigForm?.get('date_of_loss')) {
										instance?.bf?.bigForm?.addControl('date_of_loss', new UntypedFormControl(data?.loan_information.dateofloss));
									}
								});
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: { isDateOfLossDisabled: true }
								})
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'SetAppointment' }
			]
		},
		SetAppointment: {
			...BuiCreateClaim?.nodes?.claimAppointments,
			inputs: {
				...BuiCreateClaim?.nodes?.ClaimAppointments.inputs,
				showCurrentJobs: true
			},
			initFormFields: (bf, item, instance, sq, store) => {
				bf.addControl('on_site_person', new UntypedFormControl('', [Validators.required, Validators.minLength(2)]));
				bf.addControl('on_site_notes', new UntypedFormControl('', Validators.required));
				bf.addControl('on_site_contact', new UntypedFormControl('', CustomValidators.contact_number));
				bf.addControl('timepicker', new UntypedFormControl(''));
				bf.addControl('selectedDateType', new UntypedFormControl(''));
				bf.addControl('isSameAppointment', new UntypedFormControl(''));
				bf.addControl('appointments', new UntypedFormArray([]));
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemOne {
									applicant {
										first_name
										surname
										contact_number
									}
									loan_information {
										contactnumber
									}
								}
							}
						}
					`
				)
					.pipe(take(1))
					.subscribe(applicantValues => {
						bf.addControl(
							'client_details',
							new UntypedFormGroup({
								first_name: new UntypedFormControl(applicantValues.first_name),
								surname: new UntypedFormControl(applicantValues.surname)
							})
						);
						bf.addControl(
							'contact_details',
							new UntypedFormGroup({
								cell_number: new UntypedFormControl(applicantValues.contactnumber)
							})
						);
					});
			},
			serverCalls: {
				appointmentTypes: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											appointment_types {
												id
												name
											}
										}
									}
								`,
								store
							)
							.pipe(take(1), map(values), map<any, any>(flatten));
					}
				},
				appointmentData: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											appointment_types {
												id
												name
											}
										}
									}
								`,
								store
							)
							.pipe(take(1), map(values), map<any, any>(flatten));
					}
				}
			},
			navs: [
				{
					text: 'next',
					nextNode: 'ExcessDetails'
				},
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							instance.store
								.select(getFullItemOne)
								.pipe(take(1))
								.subscribe(data => {
									if (!instance?.bf?.bigForm?.get('whatmatters')) {
										instance?.bf?.bigForm?.addControl('whatmatters', new UntypedFormControl(data?.loan_information.whatmatters));
									}

									if (!instance?.bf?.bigForm?.get('date_of_loss')) {
										instance?.bf?.bigForm?.addControl('date_of_loss', new UntypedFormControl(data?.loan_information.dateofloss));
									}
								});
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: { isDateOfLossDisabled: true }
								})
							};
						}
					}
				}
			],
			component: 'ClaimAppointmentsComponent'
		},
		CashInLieu: {
			...BUI_BANK_DETAILS,
			inputs: {
				...BUI_BANK_DETAILS.inputs,
				modalNavSuccess: {
					linkType: 'nextNode',
					nextNode: 'newJobCard',
					text: 'Continue',
					color: 'primary'
				}
			},
			navs: [
				{
					text: 'Enter Details Later',
					location: 'right',
					color: 'secondary',
					nextNode: 'newJobCard'
				},
				...BUI_BANK_DETAILS.navs
			]
		},
		ExcessDetails: {
			...BuiCreateClaim?.nodes?.ClaimPaymentDetails,
			inputs: {
				...BuiCreateClaim?.nodes?.ClaimDetails.inputs,
				showTabs: false,
				showCurrentJobs: true
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.addControl(
					'policy_excess',
					new UntypedFormGroup({
						is_cancelled: new UntypedFormControl(false),
						excess_description: new UntypedFormControl('Standard Excess'),
						amount: new UntypedFormControl(
							{
								value: '',
								disabled: false
							},
							{ validators: [Validators.required, CustomValidators.currency] }
						),
						who_collects: new UntypedFormControl({ value: null, disabled: false }, { validators: [Validators.required] }),
						who_collects_description: new UntypedFormControl(''),
						payment_method: new UntypedFormControl({ value: null, disabled: false }, { validators: [Validators.required] }),
						payment_method_description: new UntypedFormControl('')
					})
				);
				bf.addControl('jobs', new UntypedFormArray([]));

				const jobs = bf.bigForm?.value?.jobs;

				jobs.forEach((job, index) => {
					if (job.skill === 'Loss Adjusting' || job?.skill === 'Internal Assessor') {
						bf.bigForm.get(`jobs.${index}`).get('is_cancelled')?.setValue(true);
					} else {
						bf.bigForm.get(`jobs.${index}`).get('is_cancelled')?.setValue(false);
					}
				});
			},
			showTabs: false,
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							instance.store
								.select(getFullItemOne)
								.pipe(take(1))
								.subscribe(data => {
									if (!instance?.bf?.bigForm?.get('whatmatters')) {
										instance?.bf?.bigForm?.addControl('whatmatters', new UntypedFormControl(data?.loan_information.whatmatters));
									}

									if (!instance?.bf?.bigForm?.get('date_of_loss')) {
										instance?.bf?.bigForm?.addControl('date_of_loss', new UntypedFormControl(data?.loan_information.dateofloss));
									}
								});
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: { isDateOfLossDisabled: true }
								})
							};
						}
					}
				},
				{
					text: 'submit',
					disableOnFirstClick: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							errorMessage: `Claim was not updated with this new job`,
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemOne).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									)
								]).pipe(
									switchMap(([item, submit]) => {
										const claim_id: any = item?.id;
										const { jobcardrequest } = item?.loan_information;
										const application = { ...submit.loan_information, ...item?.loan_information };

										if (submit.loan_information.whatmatters) {
											application.whatmatters = submit.loan_information.whatmatters;
											application.claimdescription = submit.loan_information.claimdescription;
											application.dateofloss = submit.loan_information.dateofloss;
										}

										const newjobcardrequest = submit.jobcardrequest;
										const newClaimClassRequired = parseInt(submit.claim_type_id[0], 10) !== item?.claim_type;

										let newJobCardRequest = [];
										if (Array.isArray(jobcardrequest)) {
											newJobCardRequest = [...jobcardrequest, ...newjobcardrequest];
										} else {
											newJobCardRequest = [jobcardrequest, newjobcardrequest];
										}

										const data: NewJobPayload = {
											claim: claim_id,
											application: {
												...application,
												jobcardrequest: newJobCardRequest
											},
											jobcardrequest: newjobcardrequest
										};

										if (newClaimClassRequired) {
											data.new_claim_type = parseInt(bf.bigForm.get('selectedClaimClass')?.value[0], 10);
										}

										return http.post(`${environment.api_url}v1/job_action/new_job/`, data);
									})
								);
							}
						}
					},
					color: 'primary'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		// what matters update
		whatmatters: 'loan_information.whatmatters',
		claimdescription: [
			(claimdescription, storeObj, formValues) => {
				const handler = storeObj?.identity?.currentUser?.user?.full_name;
				return 'Date: ' + moment().format('YYYY-MM-DD') + ' \nStaff: ' + (handler || '') + ' \n' + formValues.whatmatters + '.';
			},
			'loan_information.claimdescription'
		],
		date_of_loss: 'loan_information.dateofloss',
		claim: ['id', 'loan_information.id'],
		// ADD NEW JOB CARD
		selectedClaimClass: ['claim_type_id', 'loan_information.claimtype_id'],
		claim_class_description: 'loan_information.claimtype',
		jobs: [
			[transformJobsToJobCardRequest, 'jobcardrequest'],
			[transformJobsToJobCardRequest, 'loan_information.jobcardrequest']
		],
		ia_request_reason: ['loan_information.ia_requested_reason'],

		// Claim Appointment
		on_site_contact: 'loan_information.onsiteperson',
		on_site_notes: 'loan_information.onsitenotes',
		on_site_person: 'loan_information.onsiteperson',

		// Excess
		reinsuranceexcess: ['loan_information.reinsuranceexcess'],
		voluntaryexcess: ['loan_information.voluntaryexcess'],
		additionalexcess: ['loan_information.additionalexcess'],
		underwriter: 'loan_information.underwriter',
		sum_insured: 'loan_information.suminsured',

		_3rdpartyinsurancecompany: [
			(val, storeObj, formValue) => {
				return formValue && formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartyinsurancecompany'
		],
		_3rdpartyvechilereg: [
			(val, storeObj, formValue) => {
				return formValue && formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartyvechilereg'
		],
		_3rdpartyvechilemake: [
			(val, storeObj, formValue) => {
				return formValue && formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartyvechilemake'
		],
		_3rdpartydriverid: [
			(val, storeObj, formValue) => {
				return formValue && formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartydriverid'
		],
		_3rdpartydrivercell: [
			(val, storeObj, formValue) => {
				return formValue && formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartydrivercell'
		],
		_3rdpartydrivername: [
			(val, storeObj, formValue) => {
				return formValue && formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartydrivername'
		],
		firedamageextent: [
			(val, storeObj, formValue) => {
				return formValue && formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 7 ? val : null;
			},
			'loan_information.firedamageextent'
		],
		recomendedadjuster: [
			(val, storeObj, formValue) => {
				return formValue && formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 7 ? val : null;
			},
			'loan_information.recomendedadjuster'
		],
		policeclaimnumber: [
			(val, storeObj, formValue) => {
				return formValue && formValue.selectedClaimClass && [8, 9, 16]?.includes(Number(formValue.selectedClaimClass[0])) ? val : null;
			},
			'loan_information.policeclaimnumber'
		],
		upfrontrepudiationreason: [
			(val, storeObj, formValue) => {
				return formValue && formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 21 ? val : null;
			},
			'loan_information.upfrontrepudiationreason'
		]
	},
	flowErrorMessages: {
		'claim_class:required': 'Claim type is required',
		'jobs:required': 'At least one job card request is needed',
		'cell_number:cellphone': 'Cell number is required',
		'contact_number:contact_number': 'Contact number is required'
	}
};
