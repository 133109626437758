import { Action } from '@ngrx/store';

export const GET_SP_USERS_LIST = '[SP Users] Get SP Users';
export const GET_SP_USERS_LIST_SUCCESS = '[SP Users] Get SP Users Success';
export const GET_SP_USERS_LIST_FAIL = '[SP Users] Get SP Users Success Fail';
export const USERS_LOADED = '[SP Users] Get SP Users Loaded State';

export class GetSPUsersList implements Action {
	readonly type = GET_SP_USERS_LIST;
	constructor() {}
}
export class GetSPUsersListSuccess implements Action {
	readonly type = GET_SP_USERS_LIST_SUCCESS;
	constructor(public payload: any) {}
}
export class GetSPUsersListFail implements Action {
	readonly type = GET_SP_USERS_LIST_FAIL;
	constructor(public payload: any) {}
}

export class UsersLoaded implements Action {
	readonly type = USERS_LOADED;
	constructor(public payload: any) {}
}

export type SPUsersManagementActions = GetSPUsersList | GetSPUsersListSuccess | GetSPUsersListFail | UsersLoaded;
