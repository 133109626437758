import { NodePoint_0_0_2, getFullItemTwo, getAllInfo, getCurrentUser, getData } from '@flexus/core';
import { skipWhile, take, map, filter, pluck, switchMap } from 'rxjs/operators';
import { combineLatest, forkJoin } from 'rxjs';
import { findName } from '@flexus/utilities';
import { KVLHeading } from '@flexus/ui-elements';
import { GetDecoderSerialNumberDisplay } from '../../../mul-sp/configs/reusable/dataManipulations';

export const jobInfoNode_insurance = (state, new_state?): NodePoint_0_0_2 => {
	return {
		name: 'Job Information',
		component: {
			children: [
				{
					component: 'FLXKeyValueListComponent',
					inputs: {
						data$: 'important_infor',
						heading: 'Job Summary',
						color: 'secondary',
						colouredHeading: new KVLHeading('Important Information', 'secondary')
					}
				},
				{
					component: 'FLXKeyValueListComponent',
					inputs: {
						data$: 'installer_details',
						colouredHeading: new KVLHeading('Installation details', 'default')
					}
				},
				{
					component: 'FLXKeyValueListComponent',
					inputs: { data$: 'customer_details', colouredHeading: new KVLHeading('Customer details', 'default') }
				}
			]
		},
		showTabs: true,
		serverCalls: {
			important_infor: {
				errorMessage: 'Cannot get job info summary',
				directCall: (http, store) => {
					return forkJoin([
						store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1)
						),
						store.select(getAllInfo).pipe(
							skipWhile(x => !x),
							take(1)
						)
					]).pipe(
						take(1),
						switchMap(([job, allinfo]) => {
							const { job_information } = job;
							let tempqueries = job_information?.insurance_invoice_queries;
							let queryre;
							if (Array.isArray(tempqueries)) {
								queryre = tempqueries?.[tempqueries.length - 1]?.queryreply;
							} else {
								queryre = tempqueries?.queryreply;
							}
							let list = [];
							list.push({
								'The SP replied:': queryre
							});
							return [list];
						})
					);
				}
			},
			installer_details: {
				errorMessage: 'Cannot get job info summary',
				directCall: (http, store) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.pipe(
							take(1),
							map(info => {
								const { claim, team_leader } = info;
								const list: any = [];
								// Installer details

								list.push({
									company_name: team_leader?.sp?.name ?? '',
									accreditation_number: team_leader?.sp?.accreditation_number ?? '',
									'Installation technician qualification number': team_leader?.qualification_number ?? '',
									'Installer/Technician name': team_leader?.full_name ?? '',
									contact_person: team_leader?.sp?.contact_person ?? '',
									contact_number: team_leader?.sp?.contact_primary ?? ''
								});

								return list;
							})
						);
				}
			},
			customer_details: {
				errorMessage: 'Cannot get job info summary',
				directCall: (http, store) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.pipe(
							take(1),
							map(info => {
								const { claim, team_leader, identities } = info;
								const list: any = [];
								// Customer details
								if (claim?.applicant) {
									list.push({
										full_name: claim?.applicant?.first_name + ' ' + claim?.applicant?.surname,
										...(state !== 186 ? { customer_id: claim?.applicant?.id_number ?? '', subscriber_number: claim?.applicant?.information?.dstv_account_number ?? '' } : {}),
										installation_address:
											state === 186
												? claim?.address
												: `${claim?.loan_information?.propertystreetaddress ?? ''} ${claim?.loan_information?.propertystreetaddress_2 ?? ''} ${
														claim?.loan_information?.propertysuburb ?? ''
												  } ${claim?.loan_information?.propertycity ?? ''}, ${claim?.loan_information?.suburbcode ?? ''}`,
										email_address: claim?.loan_information?.email ?? claim?.loan_information?.Email ?? '',
										contact_number: claim?.applicant?.contact_number,
										GPS_Coordinates: claim?.location
									});
								}
								return list;
							})
						);
				}
			},
			...(state === 186
				? {
						decoder: {
							errorMessage: 'Cannot get decoder details',
							directCall: (http, store) => {
								return store
									.select(getData)
									.pipe(
										skipWhile(x => !x.voucher),
										pluck('voucher'),
										take(1)
									)
									.pipe(
										map((voucher: any) => {
											const list: any = [];
											// Decoder details
											list.push(GetDecoderSerialNumberDisplay(voucher?.decoder_number));
											return list;
										})
									);
							}
						}
				  }
				: {})
		},
		initFormFields: (bf, item, instance, sq, store) => {
			if (new_state) {
				bf.patchValues({ new_state });
			}
			combineLatest([
				store.select(getCurrentUser).pipe(filter(x => !!x, take(1))),
				store.select(getFullItemTwo).pipe(
					filter(x => !!x),
					take(1)
				)
			])
				.pipe(take(1))
				.subscribe(([user, job]) => {
					if (state === 176) {
						bf.patchValues({ payment_validated: false });
						job?.job_information?.extra_cost_total ? bf.patchValues({ new_state: 183 }) : bf.patchValues({ new_state: 28 });
					}
					bf.patchValues({
						author: user?.user?.id,
						author_name: user?.user?.full_name,
						currentState: job?.state
					});
				});
		},
		navs: [
			...(state === 177 || state === 233
				? [
						{
							text: 'Send to 4-sure support',
							nextNode: 'requestQA',
							color: 'secondary'
						},
						{
							text: 'Approve',
							nextNode: 'resolvedProblem'
						}
				  ]
				: state === 180
				? [
						{
							text: 'Continue',
							nextNode: 'inFiledTechSummary'
						}
				  ]
				: state === 186
				? [
						{
							text: 'Error Fixed',
							nextNode: 'resolvedProblem',
							color: 'secondary'
						}
				  ]
				: state === 176
				? [
						{
							text: 'Send to QA',
							nextNode: 'sendToQa',
							color: 'secondary'
						},
						{
							text: 'Send to support',
							nextNode: 'decline',
							color: 'secondary'
						},
						{
							text: 'Submit',
							nextNode: 'submissionSuccess',
							serverFirst: true,
							optIntoValidation: true,
							color: 'primary',
							linkType: 'submit' as any,
							serverCalls: {
								response: {
									serviceVariable: 'mulService',
									functionName: 'updateJob',
									errorMessage: 'Job could not be updated!!'
								}
							}
						}
				  ]
				: state === 252 || state === 253
				? [
						{
							text: 'Query',
							nextNode: 'QueryInvoice',
							color: 'secondary'
						},
						{
							text: 'Payment Complete',
							color: 'primary',
							nextNode: 'Decision'
						}
				  ]
				: [
						{
							text: 'SP Rework',
							nextNode: 'rework',
							color: 'secondary'
						},
						{
							text: 'QA Regional',
							nextNode: 'requestQA',
							color: 'secondary'
						},
						{
							text: state === 179 ? 'Fix Issue' : 'Issue Fixed',
							nextNode: 'resolvedProblem',
							color: 'primary'
						}
				  ])
		]
	};
};
