import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXGlowLineComponent } from './glow-line/glow-line.component';

@NgModule({
	declarations: [FLXGlowLineComponent],
	imports: [CommonModule],
	exports: [FLXGlowLineComponent]
})
export class FLXGlowLineModule {}
