import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
	BigFormService,
	Flow_0_0_2,
	getCurrentUser,
	getFullItemOne,
	getFullItemTwo,
	getSelectedItem,
	getSelectedItemOne,
	getSelectedItemTwo,
	MakeServerCall
} from '@flexus/core';
import { CustomValidators } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import { setActionPanelItems, CollapseActionPanel } from 'apps/studio/src/app/app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { flatten, values } from 'ramda';
import { skipWhile, take, map, filter, switchMap, mergeMap, } from 'rxjs/operators';
import {  PGG_BANK_DETAILS } from '.';
import { customerQueryNode } from '../reusable/CUSTOMER_REFUND_QUERY';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';

export const PGG_229: Flow_0_0_2 = {
	id: '229',
	name: 'damaged items',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Damaged Items';
					if (itemOne) {
						headingstring += ` :   ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		accountTypes: {
			errorMessage: 'Could not get account types!',
			directCall: (http: any, store: any, sq: any) => {
				return sq
					.queryObject(
						gql`
							{
								allInfo {
									account_type
								}
							}
						`,
						store
					)
					.pipe(
						filter((x: any) => !!x),
						map(values),
						map(flatten as any),
						take(1)
					);
			}
		},
		voucherAmount: {
			errorMessage: `Couldn't get voucher amount`,
			directCall: (_htp: HttpClient, _store: Store, _sq: any) => {
				return _sq
					.queryObject(
						gql`
							{
								fullItemTwo {
									claim {
										loan_information {
											values {
												total_value
												client_value
												sp_value
											}
										}
									}
								}
							}
						`,
						_store.select(getFullItemTwo).pipe(
							skipWhile((fit: any) => !fit),
							take(1),
							map((res: any) => ({ fullItemTwo: res }))
						)
					)
					.pipe(
						map((querydata: any) => {
							const total_value: number = +querydata?.total_value;
							const client_value: number = +querydata?.client_value;
							const sp_value: number = +querydata?.sp_value;
							let valueobject: any = {
								total_value: total_value,
								client_value: client_value,
								sp_value: sp_value
							};
							console.log('QUERYDATA', valueobject);

							return valueobject;
						})
					);
			}
		}
	},
	instructions: {
		editRoles: {
			0: 'Awaiting replacement stock notification'
		},
		viewRoles: {
			0: 'Awaiting replacement stock notification'
		}
	},
	startNode: 'DamagedItemsSummary',
	nodes: {
		DamagedItemsSummary: {
			initFormFields: (bf: any) => {
				bf.addControl('no_refund', new UntypedFormControl(false, [CustomValidators.contains(['refund'])]));
			},
			checkValidityForFields: ['no_refund'],
			...jobInfoNode(229, 'Damaged Items', ['The customer has not acknowledged that the stock is damaged.', 'Contact the customer to determine the current status.']),
			navs: [
				{
					text: 'cancel installation', // revert to 'cancel installation'
					// optIntoValidation: true,
					color: 'secondary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any) => {
							instance.type = 'warning';
							instance.heading = 'Confirmation of Voucher Cancellation';
							instance.setMessage(['Please confirm with customer that they want to cancel the installation so that a refund can take place.']);
							instance.navButtons = [
								{
									text: 'BACK',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'CONFIRM',
									color: 'secondary',
									linkType: 'nextNode',
									nextNode: 'FakeModal'
								}
							];
						}
					}
				},
				{
					text: 'replacement stock arrived',
					color: 'default',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any) => {
							instance.type = 'warning';
							instance.heading = 'Confirmation of Replacement Stock';
							instance.setMessage([
								'Please confirm that the replacement stock has arrived and is with the customer.',
								'Please notify the customer that the installer will be in contact',
								'to arrange the new appointment.'
							]);
							instance.navButtons = [
								{
									text: 'back',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'confirm',
									color: 'secondary',
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										response: {
											errorMessage: `Couldn't update job with replacment stock`,
											directCall: (http: any, store: any, sq: any, bf: any) => {
												bf.addControl('new_state', new UntypedFormControl(null, [Validators.required]));
												bf.patchValues({ new_state: 201 });
												const new_state = bf.getControl('new_state').value;
												return store
													.select(getSelectedItem)
													.pipe(
														skipWhile((x: any) => !x),
														take(1),
														map((response: any) => response?.id)
													)
													.pipe(
														switchMap((jobid: any) => {
															const data = {
																job_id: jobid,
																new_state: new_state
															};
															return http.post(`${environment.api_url}v1/job_action/update_job`, data);
														})
													);
											}
										}
									}
								}
							];
						}
					}
				}
			]
		},
		FakeModal: {
			component: 'PingGoFakeModalComponent'
		},
		RefundBreakdown: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Refund breakdown',
							instructions: ['Please confirm expected refund amount with customer']
						}
					},
					{
						component: 'PinggoRefundBreakdownComponent',
						inputs: {
							subheading: 'Please confirm expected refund amount with customer',
							voucherAmount$: 'voucherAmount'
						}
					}
				]
			},
			navs: [
				{
					text: 'query refund',
					color: 'secondary',
					nextNode: 'CustomerQuery'
				},
				{
					text: 'continue',
					nextNode: 'BankDetailsCapture',
					color: 'primary'
				}
			]
		},
		BankDetailsCapture: {
			checkValidityForFields: ['refund_query'],
			initFormFields: (bf: any) => {
				bf.addControl('new_state', new UntypedFormControl('', [Validators.required]));
				bf.patchValues({ new_state: 269 }); // find correct  state here
			},
			component: 'PggBankDetailsTemplateComponent',
			...PGG_BANK_DETAILS,
			navs: [
				{
					text: 'submit',
					color: 'primary',
					linkType: 'portal',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService) => {
								_bf.addControl('new_state', new UntypedFormControl(269));
								return _store.select(getSelectedItemOne).pipe(
									skipWhile((result: any) => !result),
									take(1),
									mergeMap((data: any) => {
										const claim = data;
										const { id } = claim;
										const formcontrol = _bf.getControl('bank_details');
										const account_holder_name = formcontrol.get('account_holder_name').value;
										const account_number = formcontrol.get('account_number').value;
										const account_type = formcontrol.get('account_type').value.toLowerCase();
										const bank_name = formcontrol.get('bank_name').value;
										return _http.post(`${environment.api_url}v1/refund_action/modify_bank_details/`, {
											application_id: id,
											account_holder: account_holder_name,
											account_type: account_type,
											bank_name: bank_name,
											account_number: account_number
										});
									})
								);
							},
							followUpSuccessCalls: {
								updateJob: {
									errorMessage: 'Could not update job!',
									directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
										return _store.select(getSelectedItemTwo).pipe(
											skipWhile((x: any) => !x),
											take(1),
											switchMap((job: any) => {
												const job_id = job?.id;
												const new_state = _bf.getControl('new_state').value;
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
											})
										);
									}
								}
							}
						}
					}
				}
			]
		},
		CustomerQuery: {
			checkValidityForFields: ['refund_query'],
			initFormFields: (bf: any) => {
				bf.addControl('refund_query', new UntypedFormControl('', [Validators.required]));
			},
			...customerQueryNode(229, `Customer's Query`, [], 'refund_query', `Please enter the customer's query`),
			navs: [
				{
					text: 'submit',
					// optIntoValidation: true,
					serverCalls: {
						response: {
							errorMessage: `Couldn't update job with customer query`,
							serviceVariable: 'pggService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		refund_query: [
			(refundquery: any) => {
				return refundquery;
			},
			'job_information.refund_query'
		]
	}
};
