import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'flx-heading-with-ul',
	templateUrl: './heading-with-ul.component.html',
	styleUrls: ['./heading-with-ul.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXHeadingWithULComponent implements OnInit {
	@Input() title: string;
	@Input() instructions: string[];
	@Input() listItems: any[];

	ngOnInit() {
		// console.log(this.sizeClass);
	}
}
