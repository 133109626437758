import { Flow_0_0_2, getData, getFullItemOne, getCurrentUser } from '@flexus/core';
import { map, take, filter, skipWhile, first, pluck } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { getSelectedItemOne, getFullItemTwo, getAllInfo } from '@flexus/core';
import { forkJoin, empty, EMPTY } from 'rxjs';
import gql from 'graphql-tag';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { BUI_27 } from './BUI_27';

export const BUI_49: Flow_0_0_2 = {
	id: '49',
	name: 'invoice_query_update_invalid_claim',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					return `Invoice Updated - Invalid Claim : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Review the invoice query'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	events: {
		invalidateQueryDataIfVatRateIsSet: {
			triggerOn: 'vat_rate',
			triggerWhen: rate => !!rate,
			dataMutations: bf => {
				bf.patchValues({
					query: null,
					new_state: 50
				});
			}
		},

		invalidatePaymentDataIfQueryIsSet: {
			triggerOn: 'query',
			triggerWhen: qr => !!qr,
			dataMutations: bf => {
				bf.patchValues({
					claim_value: null,
					update_excess: null,
					invoice_number: null,
					vat_rate: null,
					new_state: 47
				});
			}
		}
	},

	serverCalls: {
		invoice: {
			serviceVariable: 'buiService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		dataFiles: {
			errorMessage: 'No invoice found!',
			directCall: (http, store, sq) => {
				// return throwError('something went wrong');
				return sq
					.queryObject(
						gql`
							{
								invoice {
									data
								}
							}
						`,
						store.select(getData)
					)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1),
						map(data => data['data'])
					);
			}
		}
	},

	startNode: 'PaymentPreview',

	nodes: {
		PaymentPreview: {
			// component: 'FileViewWithExtraComponent',
			inputs: { inputHeading: 'Payment Preview' },
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'keyValueList2',
							numbering: true,
							heading: 'Query History'
						}
					}
				]
			},
			serverCalls: {
				...BUI_27?.nodes?.PaymentPreview?.serverCalls,
				keyValueList2: {
					errorMessage: '',
					directCall: (http, store) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							map(job => {
								const query =
									job?.job_information?.invoicequery && Array.isArray(job?.job_information?.invoicequery)
										? job?.job_information?.invoicequery
										: job?.job_information?.invoicequery
										? [job?.job_information?.invoicequery]
										: [];

								return query;
							})
						);
					}
				}
			},

			initFormFields: (bf, item, instance, sq, store) => {
				store
					.select(getCurrentUser)
					.pipe(
						skipWhile(x => !x),
						take(1)
					)
					.subscribe(user => {
						bf.patchValues({ paymentapproval1: user?.user.id });
					});

				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									job_creator
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(job => {
						bf.patchValues({ paymentapprovalhandler: job?.job_creator });
					});
			},

			navs: [
				{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'secondary' },
				{ text: 'Approve Invoice', nextNode: 'PaymentConfirm', color: 'secondary' }
			]
		},

		PaymentConfirm: {
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'claim_value',
									inputType: 'currency',
									label: 'Invoice amount to be paid'
								},
								1: {
									formControlName: 'invoice_number',
									inputType: 'input',
									label: 'Confirm invoice number'
								},
								2: {
									formControlName: 'update_excess',
									inputType: 'currency',
									label: 'Enter excess amount'
								}
							},
							formLayout: 'three-column',
							containerWidth: '50vw'
						}
					}
				]
			},

			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('new_state', new UntypedFormControl(50));
				bf.addControl('vat_rate', new UntypedFormControl(15));
				bf.patchValues({
					update_excess: '',
					invoice_number: '',
					claim_value: ''
				});
				bf.bigForm.get('claim_value')?.setValidators([Validators.required]);
				bf.bigForm.get('update_excess')?.setValidators([Validators.required]);
				bf.bigForm.get('invoice_number')?.setValidators([Validators.required]);

				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(values => {
						bf.patchValues({
							current_state: values.state
						});
					});
			},

			navs: [
				{
					text: 'Approve',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		QueryInvoice: {
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'query',
									inputType: 'textarea',
									label: 'Query Invoice',
									maxWidth: '50vw'
								}
							}
						}
					}
				]
			},

			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},

			inputs: {
				inputHeading: 'Query Invoice'
			},
			checkValidityForFields: ['query'],

			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ new_state: 47, query: '' });
				bf.bigForm.get('query')?.setValidators([Validators.required, Validators.minLength(3)]);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(values => {
						bf.patchValues({ current_state: values.state });
					});
			},

			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		current_state: 'current_state',
		new_state: 'new_state',
		vat_rate: 'vat_rate',
		claim_value: ['claim_value', 'job_information.claim_value'],
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		update_excess: ['update_excess', 'job_information.update_excess'],
		query: [
			(qr, storeObj) => {
				let iaq = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo &&
					storeObj['selectedContext']?.fullItemTwo.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery)) {
						iaq = storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery;
					} else {
						iaq = [
							{
								...storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
							}
						];
					}
				}
				const invoicequery = [...iaq, { query: qr, queryreply: '' }];
				return invoicequery;
			},
			'job_information.invoicequery'
		]
	}
};
