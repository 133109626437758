import { NgModule } from '@angular/core';
import { ForgotPasswordComponent, ResetPasswordComponent, LoginComponent, AzureCallbackComponent } from './components';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { FLXAuthShellComponent } from './auth-shell.component';
import { AuthRoutingModule } from './auth.routing';
import { UIElementsModule } from '@flexus/ui-elements';
import { ErrorHandlerModule } from '@flexus/error-handler';
import { LocalLoginComponent } from './components/local-login/local-login.component';
import { AzureLoginComponent } from './components/azure-login/azure-login.component';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, InlineSVGModule, UIElementsModule, ErrorHandlerModule, AuthRoutingModule],
	declarations: [ForgotPasswordComponent, AzureCallbackComponent, ResetPasswordComponent, LoginComponent, LocalLoginComponent, AzureLoginComponent, FLXAuthShellComponent],
	providers: []
})
export class AuthModule {}
