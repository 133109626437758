import { Flow_0_0_2 } from '@flexus/core';
import { voucherInfoNode } from '../reusable/VOUCHER_INFO_NODE';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { photoNode } from '../reusable/PHOTO_NODE';
import { setActionPanelItems } from '../../../../app-shell-features';
import { getInterstateComments } from '../reusable/dataManipulations';

export const MUL_257: Flow_0_0_2 = {
	id: '257',
	name: 'voucher_support_request',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Voucher Support Request ',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Voucher Support Request '
		},
		viewRoles: {
			0: 'Voucher Support Request '
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		}
	},
	startNode: 'jobInformation',
	nodes: {
		voucherData: {
			...voucherInfoNode(257, 26),
			navs: [
				{
					text: 'I have linked the decoder',
					nextNode: 'submissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit' as any,
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		jobInformation: {
			showTabs: true,
			...jobInfoNode(257, 26),
			navs: [
				{
					text: 'I have linked the decoder',
					nextNode: 'submissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit' as any,
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		photo: {
			...photoNode(257, 26),
			navs: [
				{
					text: 'I have linked the decoder',
					nextNode: 'submissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit' as any,
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		message: [(message, storeObj, bf) => getInterstateComments(message, storeObj, bf), 'job_information.interstate_comments']
	}
};
