import { Component, OnInit, ViewChild, OnDestroy, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { take, delay, filter } from 'rxjs/operators';
import {
	DynamicLoaderComponent,
	DecisionNodeComponent,
	TemplateComponent,
	SubFlowNodeComponent,
	DynamicLoaderService
} from '@flexus/engine';
import {
	NavService,
	getActionPanelLoadingState,
	getCurrentUser,
	ManifestController,
	MakeServerCall,
	BigFormService,
} from '@flexus/core';
import { ExpandActionPanel, CollapseActionPanel, ExpandAdditionalActionPanel, CollapseAdditionalActionPanel } from './store';
import { getActionPanelExpanded, getAdditionalActionPanelExpanded, getActionPanelComponentToLoad } from './store';
import { screenMapper } from '@flexus/screens';
import { uiCompMapper } from '@flexus/ui-elements';
import * as compositeMapper from '@flexus/ui-composites';
import { pluginComponentMapper } from '../../plugins';

const allMappers = { ...pluginComponentMapper, ...screenMapper, ...uiCompMapper, ...compositeMapper };

@Component({
	selector: 'flx-action-panel',
	templateUrl: './action-panel.component.html',
	styleUrls: ['./action-panel.component.scss']
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FLXActionPanelComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild(DynamicLoaderComponent) host: DynamicLoaderComponent;
	@ViewChild('additionalContent') additionalContent: DynamicLoaderComponent;

	@Input() actionPanelManifest;
	expandActionPanel$: Observable<boolean>;
	expandAdditionalActionPanel$: Observable<boolean>;
	panelActions = [];
	clickedAction = null;
	clickedAdditionalAction = null;
	portalActionSub: Subscription;
	expandAdditionalActionPanelSub: Subscription;
	loading$: Observable<boolean>;
	currentUserSub: Subscription;
	initials = 'Hi';
	isSp;

	constructor(
		public store: Store<any>,
		private navService: NavService,
		private cdr: ChangeDetectorRef,
		public controller: ManifestController<any>,
		public bf: BigFormService,
		public dynamicLoaderService: DynamicLoaderService
	) {}

	ngOnInit() {
		this.currentUserSub = this.store
			.select(getCurrentUser)
			.pipe(take(1))
			.subscribe((currentUser: any) => {
				const staffType = currentUser?.user?.staff_type ?? '';
				const fullName = currentUser?.user?.full_name ?? '';
				this.initials = this.getInitials(fullName);
				this.isSp = this.getStaffType(staffType);
			});

		this.store
			.select(getActionPanelComponentToLoad)
			.pipe(filter(res => res && res !== ''))
			.subscribe((newComponent: any) => {
				this.host.load(pluginComponentMapper[newComponent]);
			});

		this.getActionPanelState();
		this.getAdditionalActionPanelState();
		this.loading$ = this.store.select(getActionPanelLoadingState).pipe(delay(0));

		// Nav portal to control something in actionpanel
		this.portalActionSub = this.navService.portalActions.subscribe(({ call, paramFunc }) => {
			if (call) {
				const param = paramFunc(this, allMappers);
				this[call](param);
			}
		});

		this.expandAdditionalActionPanelSub = this.expandAdditionalActionPanel$.subscribe(expanded => {
			if (!expanded) {
				this.clickedAdditionalAction = null;
			}
		});
	}

	ngOnChanges() {
		this.panelActions = this.actionPanelManifest ? this.actionPanelManifest(this)() : [];
	}

	getStaffType(typeindice) {
		switch (typeindice) {
			case 2:
				return true;
			default:
				return false;
		}
	}

	getInitials(fullName) {
		let initials: string;
		const nameArray = fullName?.split(' ');
		const first = nameArray[0]?.charAt(0).toLocaleUpperCase();
		const last = nameArray.length > 1 ? nameArray[nameArray.length - 1]?.charAt(0).toLocaleUpperCase() : null;

		if (last !== null && last !== '') {
			initials = `${first}${last}`;
		} else if (first !== '') {
			initials = first;
		} else {
			initials = 'HI';
		}

		return initials;
	}

	getActionPanelState() {
		this.expandActionPanel$ = this.store.select(getActionPanelExpanded);
	}

	getAdditionalActionPanelState() {
		this.expandAdditionalActionPanel$ = this.store.select(getAdditionalActionPanelExpanded);
	}

	toggleActionPanel(action: any) {
		this.expandActionPanel$.pipe(take(1)).subscribe(expanded => {
			if (expanded) {
				this.host.load(null);
				this.store.dispatch(new CollapseActionPanel());
				this.clickedAction = null;
				this.cdr.detectChanges();
				// this.cdr.markForCheck();
			} else {
				this.store.dispatch(new ExpandActionPanel());
				this.clickedAction = action;
				action?.command?.();
				this.cdr.detectChanges();
				// this.cdr.markForCheck();
			}
		});
	}

	onClick(action) {
		// this.cdr.markForCheck();
		if (Object.values(action).length === 0) {
			return;
		}

		this.cdr.detectChanges();
		if (this.clickedAction && this.clickedAction.id === action.id) {
			this.toggleActionPanel(action);
			if (action.BFControls?.length > 0) {
				this.removeBFControls(action.BFControls);
			}
		} else {
			this.store.dispatch(new ExpandActionPanel());
			this.clickedAction = action;
			action.command();
		}
	}

	removeBFControls(controls: string[]) {
		controls.forEach(c => this.bf.bigForm.removeControl(c));
	}

	onClickSketchPad() {
		if (this.clickedAdditionalAction && this.clickedAdditionalAction.id === 'scratch-pad') {
			this.store.dispatch(new CollapseAdditionalActionPanel());
		} else {
			this.store.dispatch(new ExpandAdditionalActionPanel());
			this.clickedAdditionalAction = { id: 'scratch-pad' };
			this.additionalContent.load(pluginComponentMapper['FLXScratchPadComponent']);
		}
	}

	onClickReminders() {
		this.host.load(pluginComponentMapper['FLXRemindersComponent']);
		if (this.clickedAction && this.clickedAction.id === 'reminders') {
			this.toggleActionPanel(this.clickedAction);
		} else {
			this.store.dispatch(new ExpandActionPanel());
			this.clickedAction = { id: 'reminders' };
		}
	}

	onClickJobRequests() {
		this.host.load(pluginComponentMapper['FLXAPJobRequestPingComponent']);
		if (this.clickedAction && this.clickedAction.id === 'job-request-ping') {
			this.toggleActionPanel(this.clickedAction);
		} else {
			this.store.dispatch(new ExpandActionPanel());
			this.clickedAction = { id: 'job-request-ping' };
		}
	}
	onClickJobRequestDetail() {
		this.host.load(pluginComponentMapper['FLXAPJobRequestPingDetailComponent']);
		if (this.clickedAction && this.clickedAction.id === 'job-request-ping-detail') {
			this.toggleActionPanel(this.clickedAction);
		} else {
			this.store.dispatch(new ExpandActionPanel());
			this.clickedAction = { id: 'job-request-ping-detail' };
		}
	}

	loadComponent(compOrNode) {
		if (typeof compOrNode === 'function') {
			if (this.host) {
				this.host.load(compOrNode);
				this.cdr.detectChanges();
			}
		} else {
			this.loadNodeComponent(compOrNode);
			this.cdr.detectChanges();
		}
	}

	private renderServerCalls(node) {
		if (node && node.serverCalls) {
			Object.entries(node.serverCalls).forEach(([dataKey, call]: any) => {
				this.store.dispatch(new MakeServerCall({ dataKey, ...call }));
			});
		}
	}

	private loadNodeComponent(node) {
		if (node) {
			// RUN SERVER CALLS ON ACTION PANEL NODE
			this.renderServerCalls(node);

			const { inputs, navs, nodeType, permissions } = node;
			const comp = node['component'];
			if (!!comp && typeof comp === 'string') {
				switch (nodeType) {
					case 'decision':
						this.host.loadWithInputs(DecisionNodeComponent, {}, node);
						this.cdr.detectChanges();
						break;
					case 'singleView':
						this.host.loadWithInputs(allMappers[comp], { ...inputs, navs, permissions }, node);
						this.cdr.detectChanges();
						break;
					case 'subflow':
						this.host.loadWithInputs(SubFlowNodeComponent, { ...inputs, navs, permissions }, node);
						this.cdr.detectChanges();
						break;
					default:
						this.host.loadWithInputs(allMappers[comp], { ...inputs, navs, permissions }, node);
						this.cdr.detectChanges();
						break;
				}
			} else if (nodeType === 'decision') {
				this.host.loadWithInputs(DecisionNodeComponent, {}, node);
				this.cdr.detectChanges();
			} else {
				this.host.loadWithInputs(TemplateComponent, { nodeInputs: inputs, navs, permissions, templateDefinition: comp, mapper: allMappers }, node);
				this.cdr.detectChanges();
			}
		}
	}

	ngOnDestroy() {
		if (this.portalActionSub) {
			this.portalActionSub.unsubscribe();
		}
		if (this.expandAdditionalActionPanelSub) this.expandAdditionalActionPanelSub.unsubscribe();
	}
}
