<!-- i want a sort set , and quickFilter and detailed -->
<section class="filters">
	<ng-container *ngIf="this.mode['full'] || this.mode['quickFilter']">
		<div class="has-icon-left">
			<!--  [disabled]="this.disabled"-->
			<flx-input-text
				[formControl]="quickFilter"
				[placeholder]="'Filter Workflow'"
				[id]="'quickFilter'"
				[type]="'text'"
				data-cy="quickFilter"
				data-title="Filter displayed data."
				[inputClass]="'quick-filter-input form-input optional mr-2'"
				[svgContent]="'/assets/icons/filter-outlet.svg'"
			></flx-input-text>

			<i *ngIf="quickFilter.value" style="z-index: 4" class="icon clear-input" (click)="resetFilter()" [inlineSVG]="'/assets/icons/cancel.svg'" data-cy="clearQuickFilter"></i>
		</div>
	</ng-container>

	<ng-container *ngIf="(this.mode['full'] || this.mode['valueFilter']) && this.fields && this.fieldOptions">
		<div *ngIf="this.fields.length > 0 && this.fieldOptions.length > 0 && this.fields.length === this.fieldOptions.length">
			<form [formGroup]="fieldFilterForm">
				<ng-container *ngFor="let fillField of this.fields; index as i">
					<div class="has-icon-left">
						<!-- [disabled]="this.disabled" -->
						<input
							class="form-input state-filter optional"
							multiple
							size="2"
							[attr.list]="fillField.displayName"
							[formControl]="$any(fieldFilterForm).controls[fillField.displayName]"
							placeholder="Filter by {{ fillField.displayName }}"
							data-cy="filterByState"
						/>
						<i class="form-icon icon filter-icon" [inlineSVG]="'/assets/icons/state-filter.svg'" style="margin-left: 1.2rem"></i>
						<i
							*ngIf="fieldFilterForm.controls[fillField.displayName]?.value"
							class="icon clear-input"
							(click)="resetFormField(fieldFilterForm, fillField.displayName)"
							[inlineSVG]="'/assets/icons/cancel.svg'"
							style="margin-right: -0.8rem"
							data-cy="filterByStateClear"
						></i>
					</div>
					<datalist *ngIf="!this.disabled" [id]="fillField.displayName">
						<option *ngFor="let val of fieldOptions[i]" [value]="val">
							<span *ngIf="fillField.context && fillField.context[val]">{{ fillField.context[val] }}</span>
						</option>
					</datalist>
				</ng-container>
			</form>
		</div>
	</ng-container>
</section>
