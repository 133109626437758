import { NodePoint_0_0_2, getFullItemTwo } from '@flexus/core';
import { map, filter, take } from 'rxjs/operators';
import { KVLHeading } from '@flexus/ui-elements';

export const SATPAL_INFO_NODE: NodePoint_0_0_2 = {
	name: 'SATPAL',
	showTabs: true,
	component: {
		children: [
			{
				component: 'FLXKeyValueListComponent',
				inputs: {
					data$: 'satpal',
					textTransform: 'none',
					colouredHeading: new KVLHeading('SATPAL Information', 'default')
				}
			},
			{
				component: 'FLXKeyValueListComponent',
				inputs: {
					data$: 'install_quality',
					textTransform: 'none',
					colouredHeading: new KVLHeading('Installation Quality', 'default')
				}
			}
		]
	},

	serverCalls: {
		satpal: {
			errorMessage: 'Cannot get SATPAL information',
			directCall: (http, store, sq, bf, controller) => {
				return store.select(getFullItemTwo).pipe(
					filter(x => !!x),
					take(1),
					map(info => {
						const list: any = [];
						const IRI = info?.job_information?.installation_report_info;
						if (IRI) {
							const satpal = {
								'Smartcard Number': IRI.smartcard_number,
								'11554VL dBuV': IRI.signal_11554_ver_low_str,
								'11554VL MER': IRI.signal_11554_ver_low_mer,
								'11554VL BER': IRI.signal_11554_ver_low_ber,
								'11554HL dBuV': IRI.signal_11554_hor_low_str,
								'11554HL MER': IRI.signal_11554_hor_low_mer,
								'11554HL BER': IRI.signal_11554_hor_low_ber,
								'11888VH dBuV': IRI.signal_11888_ver_high_str,
								'11888VH MER': IRI.signal_11888_ver_high_mer,
								'11888VH BER': IRI.signal_11888_ver_high_ber,
								'11928HH dBuV': IRI.signal_11928_hor_high_str,
								'11928HH MER': IRI.signal_11928_hor_high_mer,
								'11928HH BER': IRI.signal_11928_hor_high_ber
							};
							list.push(satpal);
						}

						return list;
					})
				);
			}
		},
		install_quality: {
			errorMessage: 'Cannot get SATPAL information',
			directCall: (http, store, sq, bf, controller) => {
				return store.select(getFullItemTwo).pipe(
					filter(x => !!x),
					take(1),
					map(info => {
						const list: any = [];
						if (info.job_information) {
							const install_quality = {
								'Dish installation quality': info?.job_information?.quality_of_dish_installation ?? '',
								'Coaxial cabling quality': info?.job_information?.quality_of_coaxial_installation ?? '',
								'Decoder installation quality': info?.job_information?.quality_of_decoder_installation ?? ''
							};
							list.push(install_quality);
						}

						return list;
					})
				);
			}
		}
	},
	navs: [
		{
			text: 'SP Rework',
			nextNode: 'rework',
			color: 'secondary'
		},
		{
			text: 'QA Regional',
			nextNode: 'requestQA',
			color: 'secondary'
		},
		{
			text: 'Issue Fixed',
			nextNode: 'resolvedProblem',
			color: 'primary'
		}
	]
};
