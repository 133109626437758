import { Injectable, Inject } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as billingActions from './billing.actions';
import { switchMap, catchError, map, mergeMap, tap, skipWhile, take } from 'rxjs/operators';
import { BigFormService, BillingService, StoreQuery } from '../services/';
import { Observable, of } from 'rxjs';
import { path } from 'ramda';
import { getBOQ } from './billing.selector';
import { getSelectedItemTwo } from '../selected-context';
import { ManifestController } from '../controllers';

@Injectable()
export class BillingEffects {
	constructor(
    @Inject('ENVIRONMENT') private environment: any,
		private actions$: Actions,
		@Inject(BillingService) private service: any,
		private http: HttpClient,
		private store: Store<any>,
		private controller: ManifestController<any>,
		private sq: StoreQuery,
		private bf: BigFormService
	) {}

	getTemplates$ = createEffect(() =>
		this.actions$.pipe(
			ofType(billingActions.FETCH_TEMPLATES),
			switchMap((action: billingActions.FetchTemplates) => {
				const { callOutSkill, type, callOutIdType, isAfterHoursItem } = action.payload;

				return (this.service.getBOQTemplates(callOutSkill, type, callOutIdType, isAfterHoursItem ? 'includes_after_hours_items' : null) as Observable<any>).pipe(
					switchMap((res: any) => {
						const itemOne = res && res?.success === true ? res?.payload : null;
						// why patching here?
						this.bf.patchValues({ itemOne });
						if (res && res?.success === true) {
							const availableTemplates = res?.payload.map(template => ({
								display: template.name,
								value: template.id,
								template
							}));

							return of(new billingActions.FetchTemplatesSuccess({ availableTemplates }));
						} else {
							return of(
								new billingActions.MakeBillingServerCallFail({
									dataKey: `availableTemplates`,
									error: res,
									errorMessage: 'Failed to fetch BOQ ' + type + ' templates',
									retryCall: {
										functionName: 'getBOQTemplates',
										errorMessage: 'Failed to fetch BOQ ' + type + ' templates'
									}
								})
							);
						}
					}),
					catchError(err =>
						of(
							new billingActions.MakeBillingServerCallFail({
								dataKey: `availableTemplates`,
								error: err,
								errorMessage: 'Failed to fetch BOQ ' + type + ' templates',
								retryCall: {
									functionName: 'getBOQTemplates',
									errorMessage: 'Failed to fetch BOQ ' + type + ' templates'
								}
							})
						)
					)
				);
			})
		)
	);

	MakeBillingServerCall = createEffect(() =>
		this.actions$.pipe(
			ofType(billingActions.MAKE_BILLING_SERVER_CALL),
			map((action: billingActions.MakeBillingServerCall) => {
				const { isBackgroundTask } = action.payload;
				if (isBackgroundTask) {
					return new billingActions.MakeBillingServerCallWithoutLoader(action.payload);
				} else {
					return new billingActions.MakeBillingServerCallWithLoader(action.payload);
				}
			})
		)
	);

	MakeBillingServerCallWithOrWithoutLoader = createEffect(() =>
		this.actions$.pipe(
			ofType(billingActions.MAKE_BILLING_SERVER_CALL_WITH_LOADER, billingActions.MAKE_BILLING_SERVER_CALL_WITHOUT_LOADER),
			mergeMap((action: any) => {
				const serverCallsTracker = [];
				const {
					directCall,
					dataKey,
					serviceVariable,
					functionName,
					responseSlice,
					followUpFailCalls,
					followUpSuccessCalls,
					errorMessage,
					data,
					ignoreFalseError,
					timeoutMilliseconds = 6000000000000,
					loaderID
				} = action.payload;
				if (directCall) {
					return (directCall(this.http, this.store, this.sq, this.bf, this.controller) as Observable<any>).pipe(
						map((res: any) => {
							if (!ignoreFalseError) {
								if (res && res?.success === false) {
									return new billingActions.MakeBillingServerCallFail({
										followUpFailCalls,
										dataKey,
										error: {
											reason: res?.payload.reason['@RESULTS'] ? res?.payload.reason['@RESULTS'] : res?.payload.reason
										},
										errorMessage,
										retryCall: action.payload,
										loaderID: loaderID
									});
								}
							}

							const result = responseSlice ? path(responseSlice.split('.'), res) : res;
							return new billingActions.MakeBillingServerCallSuccess({ dataKey, result, loaderID });
						}),
						tap(() => {
							if (followUpSuccessCalls && Object.keys(followUpSuccessCalls).length > 0) {
								Object.entries(followUpSuccessCalls).forEach(([key, value]: [string, any]) => {
									if (!serverCallsTracker.includes(dataKey)) {
										this.store.dispatch(new billingActions.MakeBillingServerCall({ dataKey: key, ...value }));
										serverCallsTracker.push(dataKey);
									}
								});
							}
						}),
						catchError(error =>
							of(
								new billingActions.MakeBillingServerCallFail({
									followUpFailCalls,
									dataKey,
									error,
									errorMessage,
									retryCall: action.payload,
									loaderID: loaderID
								})
							)
						)
					);
				} else {
					return (this.service[functionName](data) as Observable<any>).pipe(
						map((res: any) => {
							if (!ignoreFalseError) {
								if (res && res?.success === false) {
									return new billingActions.MakeBillingServerCallFail({
										followUpFailCalls,
										dataKey,
										error: { reason: res?.payload.reason },
										errorMessage,
										retryCall: action.payload,
										loaderID: loaderID
									});
								}
							}
							const result = responseSlice ? path(responseSlice.split('.'), res) : res;
							return new billingActions.MakeBillingServerCallSuccess({ dataKey, result, loaderID });
						}),
						tap(() => {
							if (followUpSuccessCalls && Object.keys(followUpSuccessCalls).length > 0) {
								Object.entries(followUpSuccessCalls).forEach(([key, value]: [string, any]) => {
									if (!serverCallsTracker.includes(dataKey)) {
										this.store.dispatch(new billingActions.MakeBillingServerCall({ dataKey: key, ...value }));
										serverCallsTracker.push(dataKey);
									}
								});
							}
						}),
						catchError(error =>
							of(
								new billingActions.MakeBillingServerCallFail({
									followUpFailCalls,
									dataKey,
									error,
									errorMessage,
									retryCall: action.payload,
									loaderID: loaderID
								})
							)
						)
					);
				}
			})
		)
	);

	SaveDraftQuote$ = createEffect(() =>
		this.actions$.pipe(
			ofType(billingActions.SAVE_DRAFT_QUOTE),
			switchMap(() => {
				return this.createOrUpdateBOQ().pipe(
					switchMap(() => {
						const invoiceNumber = this.bf.bigForm.get('invoiceNumber')?.value;
						const notes = this.bf.bigForm.get('invoiceNotes')?.value;
						const vatPercentage = this.bf.bigForm.get('vatPercentage')?.value;

						if (this.isNullOrUndefinedOrEmpty(invoiceNumber) || this.isNullOrUndefinedOrEmpty(notes) || this.isNullOrUndefinedOrEmpty(vatPercentage)) {
							return of(new billingActions.SaveDraftQuoteNoAction());
						}

						return this.store.select(getSelectedItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							switchMap((job: any) =>
								this.generateBOQQuote(job.id, invoiceNumber, notes, vatPercentage, true).pipe(
									map(() => new billingActions.SaveDraftQuoteSuccess()),
									catchError(this.handleDraftQuoteError)
								)
							)
						);
					}),
					catchError(this.handleDraftQuoteError)
				);
			})
		)
	);

	generateBOQQuote(jobId: number, quoteNumber: string, note: string, vatRate: number, draft = false): Observable<any> {
		const params = new HttpParams()
			.set('job_id', jobId.toString())
			.set('quote_number', quoteNumber)
			.set('notes', note)
			.set('vat_rate', vatRate.toString())
			.set('draft', draft.toString());

		return this.http.get(this.environment.api_url + 'v2/invoicing_action/generate_quote', { params });
	}

	createOrUpdateBOQ() {
		const newVals = [];
		this.bf.bigForm.get('actualLineItemsFormArray')?.value?.forEach(lineItem => {
			let newObj = { ...lineItem, id: lineItem.item?.id };
			if (lineItem.item_id === undefined || lineItem.item_id === null) {
				newObj = { ...newObj, item_id: lineItem.item?.id };
			}
			newVals.push(newObj);
		});
		this.bf.bigForm.get('actualLineItemsFormArray')?.patchValue(newVals, { onlySelf: true, emitEvent: false });

		return this.store.select(getSelectedItemTwo).pipe(
			skipWhile(x => !x),
			take(1),
			switchMap(job =>
				this.store.select(getBOQ).pipe(
					skipWhile(boq => !boq),
					switchMap((boq: any) => {
						if (boq.line_items) {
							return this.updateBOQ(job.id);
						} else {
							return this.createBOQ(job.id);
						}
					})
				)
			)
		);
	}

	updateBOQ(jobId: number): Observable<any> {
		const postData = {
			job_id: jobId,
			items: this.bf.bigForm.get('actualLineItemsFormArray')?.value
		};
		return this.http.post(this.environment.api_url + '_action/update_boq', postData);
	}

	createBOQ(jobId: number): Observable<any> {
		const postData = {
			job_id: jobId,
			items: this.bf.bigForm.get('actualLineItemsFormArray')?.value
		};
		return this.http.post(this.environment.api_url + '_action/create_boq', postData);
	}

	isNullOrUndefinedOrEmpty(value: any): boolean {
		return value === null || value === undefined || value === '';
	}

	handleDraftQuoteError = (error: any): Observable<billingActions.SaveDraftQuoteFail> => {
		// Save draft quote to local storage
		this.store
			.select(getSelectedItemTwo)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe((job: any) => {
				localStorage.setItem(
					`DRAFT_${job.id}`,
					JSON.stringify({
						job_id: job.id,
						date: this.bf.bigForm.get('invoiceDate')?.value,
						invoiceNumber: this.bf.bigForm.get('invoiceNumber')?.value,
						notes: this.bf.bigForm.get('invoiceNotes')?.value,
						vatPercentage: this.bf.bigForm.get('vatPercentage')?.value,
						items: this.bf.bigForm.get('actualLineItemsFormArray')?.value
					})
				);
			});
		return of(new billingActions.SaveDraftQuoteFail(error));
	};
}
