<flx-heading size="medium" align="center">{{ inputHeading }}</flx-heading>
<div class="page-extras">
	<flx-heading size="small" align="center" weight="light" *ngIf="subHeading !== null">{{ subHeading }}</flx-heading>
	<!-- display a file -->
	<ng-container *ngIf="dataFiles$ | async as file; else defaultMsg">
		<flx-file-viewer *ngIf="file?.data" [base64Source]="file?.data" mimeType="pdf" margin="0 0 1rem 0"></flx-file-viewer>
	</ng-container>
	<!-- opt in to a no file message -->
	<ng-template #defaultMsg>
		<ng-container *ngIf="fileDoesNotExistMessage.length > 1">
			<ng-container *ngIf="loading">
				<div class="file-loading-spinner"><flx-loader-component [isFullScreen]="true" [loadingMessage]="loadingText"></flx-loader-component></div>
			</ng-container>
			<flx-heading align="center" size="small" *ngIf="fileDoesNotExistMessage.length > 0">{{ fileDoesNotExistMessage }} </flx-heading>
		</ng-container>
	</ng-template>
	<!-- if there is a list of instructions needed -->
	<div class="instructions" *ngIf="instructions">
		<ul>
			<ng-container *ngFor="let instruc of instructions">
				<li>{{ instruc }}</li>
			</ng-container>
		</ul>
	</div>
	<!-- heading that is copyable -->
	<div *ngIf="copyText$ | async as text">
		<flx-heading size="small" align="center">
			<div class="item-to-copy-container">
				<div class="item-to-copy-heading">{{ text.key }}</div>
				<div class="item-to-copy" hoverClickCopy>{{ text.value }}</div>
			</div>
		</flx-heading>
	</div>
	<!-- key value list -->
	<flx-key-value-list *ngIf="keyValueList$ | async" [data$]="keyValueList$"></flx-key-value-list>
	<!-- flat dynamic form -->
	<flx-flat-dynamic-form *ngIf="formInputs" [formControls]="formInputs"></flx-flat-dynamic-form>
	<!-- render out buttons on the screen -->
	<div *ngIf="buttons">
		<ng-container *ngFor="let button of buttons">
			<flx-button [color]="button.type" (click)="handleButtonEvent(button.type)">{{ button.name }}</flx-button>
		</ng-container>
	</div>
</div>
