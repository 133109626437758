<flx-heading type="page" size="medium">Time Stamps</flx-heading>
<ng-container *ngIf="timeStamps$ | async as timeStamps">
	<flx-heading size="small" color="secondary">Claim updates:</flx-heading>
	<ng-container *ngFor="let claimState of timeStamps?.claimStates">
		<span class="time-stamp__info--indent-1">{{ claimState?.mytimestamp | date: 'yyyy-MM-dd HH:mm' }} - {{ claimState?.modifier }}</span> <br />
		<span class="time-stamp__info--indent-2">{{ claimState?.state }}: {{ claimState?.state_description }}</span> <br />
		<span class="time-stamp__info--indent-2">{{ claimState?.reason }}</span>
		<flx-glow-line margin="1rem 0"></flx-glow-line>
	</ng-container>

	<flx-accordion-template *ngIf="timeStamps?.jobs?.length > 0; else noStamps">
		<flx-heading size="small" color="secondary">Job updates:</flx-heading>
		<ng-container *ngFor="let jobStamp of timeStamps?.jobs">
			<flx-accordion-group title="{{ jobStamp.job_id }}: {{ jobStamp.title }}">
				<ng-container *ngFor="let item of jobStamp">
					<span>{{ item?.dateTime | date: 'yyyy-MM-dd HH:mm' }} - {{ item?.modifier }}</span
					><br />
					<span class="time-stamp__info--indent-1">{{ item?.state }}: {{ item?.state_description }}</span
					><br />
					<span class="time-stamp__info--indent-1">{{ item?.reason }}</span>
					<flx-glow-line margin="1rem 0"></flx-glow-line>
				</ng-container>
			</flx-accordion-group>
		</ng-container>
	</flx-accordion-template>
	<ng-template #noStamps> <p>No time stamps</p> </ng-template>
</ng-container>
