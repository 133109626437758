<ng-container *ngIf="paymentsList$ | async as paymentsArray">
	<div class="main-heading">
		<flx-heading size="small" color="secondary">4-Sure</flx-heading>
		<flx-heading size="small" color="secondary">{{ paymentMadeTo }}</flx-heading>
	</div>
	<div class="payment-heading">
		<flx-heading size="extra-small" color="secondary">Service Provider</flx-heading>
		<flx-heading size="extra-small" color="secondary">Skill</flx-heading>
		<flx-heading size="extra-small" color="secondary">State</flx-heading>
		<flx-heading size="extra-small" color="secondary">Amount</flx-heading>
		<div class="seperator"></div>
		<flx-heading size="extra-small" color="secondary">Service Provider</flx-heading>
		<flx-heading size="extra-small" color="secondary">Skill</flx-heading>
		<flx-heading size="extra-small" color="secondary">State</flx-heading>
		<flx-heading size="extra-small" color="secondary">Amount</flx-heading>
	</div>
	<div *ngFor="let item of paymentsArray">
		<flx-card [config]="{ expandable: true, expansionLocation: afterBody }">
			<flx-card-body>
				<div class="view-payment">
					<p>{{ item?.usSp }}</p>
					<p>{{ item?.usSkill }}</p>
					<p>{{ item?.usState }}</p>
					<p>{{ item?.usAmount }}</p>
					<div class="suggested-fix-icon"><flx-icon [type]="'suggested-fix'"></flx-icon></div>
					<p>{{ item?.themSp }}</p>
					<p>{{ item?.themSkill }}</p>
					<p>{{ item?.themState }}</p>
					<p>{{ item?.themAmount }}</p>
				</div>
			</flx-card-body>
			<flx-card-expansion>
				<div class="expansion-container">
					<div class="message-container">
						<flx-heading type="form" color="secondary" size="small">Suggested Fix</flx-heading>
						<div *ngFor="let message of item?.message">
							<p>{{ message }}</p>
						</div>
						<ng-container>
							<flx-button color="secondary" (click)="paymentDone(item)" display="block" *ngIf="item?.jobId">Payment done on {{ paymentMadeTo }}</flx-button>
						</ng-container>
					</div>
				</div>
			</flx-card-expansion>
		</flx-card>
	</div>
</ng-container>
