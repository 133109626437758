import { NgModule } from '@angular/core';
import { FLXNavComponent } from './nav.component';
import { CommonModule } from '@angular/common';
import { FLXButtonComponent, FLXButtonsModule } from '@flexus/ui-elements';

@NgModule({
	imports: [CommonModule, FLXButtonsModule],
	declarations: [FLXNavComponent],
	exports: [FLXNavComponent],
	providers: []
})
export class FLXNavModule {}
