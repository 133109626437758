import { BigFormService, Flow_0_0_2, getCurrentUser, getFullItemTwo, getSelectedItem, getSubmissionData } from '@flexus/core';
import { setActionPanelItems, CollapseActionPanel } from 'apps/studio/src/app/app-shell-features';
import { skipWhile, map, take } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin, of } from 'rxjs';
import { KVLHeading } from '@flexus/ui-elements';
import { FormArray, UntypedFormControl, Validators } from '@angular/forms';

export const UPLOAD_CREDIT_NOTE: Flow_0_0_2 = {
	id: 'credit_note',
	name: 'Upload Credit Note',
	itemType: 'flow',
	includeForOnlyStates: [28, 332],
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any, bf: any) => {
			return store.select(getFullItemTwo)?.pipe(
				skipWhile(x => !x),
				take(1),
				map((itemTwo: any) => {
					bf.addControl('job_id', new UntypedFormControl(itemTwo?.id));
					return 'Credit Note Required';
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Upload Credit Note'
		},
		viewRoles: {
			0: 'Scheduler to provide credit note'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		jobSummary: {
			errorMessage: 'Error setting job id',
			directCall: (http, store, sq, bf) => {
				return store.select(getSelectedItem)?.pipe(
					skipWhile(x => !x),
					take(1),
					map((selectedItem: any) => bf?.bigForm?.addControl('job_id', new UntypedFormControl(selectedItem?.id)))
				);
			}
		}
	},
	startNode: 'jobSummary',
	nodes: {
		jobSummary: {
			showTabs: true,
			name: 'Job Summary',
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Account over paid. Credit note required',
							itemMargin: '0 0 45px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Please create and upload a credit note based on the information supplied by Multichoice', 'secondary'),
							itemMargin: '0 0 30px 0',
							data$: 'requestHeading'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Installation Details', 'secondary'),
							data$: 'installationDetailValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Decoder', 'secondary'),
							data$: 'decoderDetailValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Equipment', 'secondary'),
							data$: 'equipmentKeyValues',
							itemMargin: '0  0 15px 0'
						}
					}
				]
			},
			serverCalls: {
				instructionHeader: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return of('Account over paid. Credit note required');
					}
				},
				installationDetailValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http, store, sq) => {
						return forkJoin([
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(job => {
									const product_type: string = job?.office_use?.device_details?.replacement_device?.type ?? 'No product information';
									const installationDetailsData = {
										id: job?.id,
										'claim id': job?.claim?.id,
										'customer name': `${job.claim?.applicant?.first_name} ${job.claim?.applicant?.surname}`,
										address: `${job?.claim?.loan_information?.propertystreetaddress}
											${job?.claim?.loan_information?.propertystreetaddress_2},
											${job?.claim?.loan_information?.propertysuburb}`,
										'installation type': product_type,
										team_leader_id: job?.team_leader?.id
									};
									return installationDetailsData;
								})
							)
						]).pipe(
							map(([teamLeads, installationDetails]) => {
								const teamLead = teamLeads.find(team_lead => team_lead?.id === installationDetails?.team_leader_id);
								delete installationDetails.team_leader_id;
								Object.assign(installationDetails, { 'Team Leader': teamLead?.['full_name'] ?? '' });
								return [installationDetails];
							})
						);
					}
				},
				requestHeading: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return of({
							'': ' '
						});
					}
				},
				decoderDetailValues: {
					errorMessage: 'Something went wrong with decoder details',
					directCall: (http, store, sq, bf) => {
						const job_id = bf?.bigForm?.get('job_id')?.value;
						return forkJoin([
							http.get(`${environment.api_url}v1/job_action/get_voucher_details?job_id=${job_id}`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(job => {
									const additional_decoder_info = job?.office_use?.device_details?.replacement_device?.name ?? 'No additional decoder info';
									return additional_decoder_info;
								})
							)
						]).pipe(
							map(([decoderDetails, additional_decoder_info]) => {
								const decoder_number = decoderDetails?.decoder_number.join(', ') ?? 'No decoder number';
								const voucher_code = decoderDetails?.voucher_number ?? 'No voucher code';
								const additional_info = additional_decoder_info;

								const decoderDisplayValues = {
									'Decoder Serial Number': `${decoder_number}`,
									'Voucher Code': `${voucher_code}`,
									'Additional Decoder Info': `${additional_info}`
								};
								return [decoderDisplayValues];
							})
						);
					}
				},
				equipmentKeyValues: {
					errorMessage: 'Something went wrong with equiptment info',
					directCall: (http, store, sq, bf) => {
						const job_id = bf?.bigForm?.get('job_id')?.value;
						return forkJoin([
							http.get(`${environment.api_url}v1/job/${job_id}/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['job_information'])
							)
						]).pipe(
							map(([job_information]) => {
								const { installed_items } = job_information;
								if (!installed_items) return [{ 'Dish QR': '', 'Bracket Code': '', 'LNB QR Code': '', 'Additional Equipment': '' }];
								const dish_qr = installed_items?.filter(({ item }) => item === 'Dish')?.map(({ serial }) => serial.split(',').join(', '));
								const bracket_code = installed_items?.filter(({ item }) => item === 'Bracket')?.map(({ serial }) => serial.split(',').join(', '));
								const lnb_qr = installed_items?.filter(({ item }) => item === 'LNB')?.map(({ serial }) => serial.split(',').join(', '));
								const additional_equipt = installed_items?.filter(item => item?.category === 'adhoc')?.map(({ item }) => item);

								const equipmentDetails = {
									'Dish QR': `${dish_qr}` ?? '',
									'Bracket Code': `${bracket_code}` ?? '',
									'LNB QR Code': `${lnb_qr}` ?? '',
									'Additional Equipment': `${additional_equipt.join(', ')}` ?? ''
								};

								return [equipmentDetails];
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'upload credit note',
					nextNode: 'UploadCreditNote',
					color: 'primary'
				}
			]
		},
		UploadCreditNote: {
			showTabs: true,
			name: 'Upload Credit Note',
			initFormFields: (bf: BigFormService) => {
				bf.addControl('base_invoice_number', new UntypedFormControl('', Validators.required));
				bf.addControl('credit_note_number', new UntypedFormControl('', Validators.required));
				bf.addControl('credit_note_amount', new UntypedFormControl('', Validators.required));
				bf.addControl('VoucherTypeandStatus', new UntypedFormControl('', Validators.required));
				bf.addControl('VoucherNumber', new UntypedFormControl('', Validators.required));
				bf.addControl('AmountExclVAT', new UntypedFormControl('', Validators.required));
				bf.addControl('vatPercentage', new UntypedFormControl(0));
				bf.addControl('line_items', new FormArray([], [Validators.required]));
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Upload credit note',
							instructions: ['Please add the line items for the credit note as supplied by Multichoice']
						}
					},
					{
						component: 'CreditNoteComponent',
						inputs: {
							docType: 'credit_note',
							numberLabel: 'Credit Note',
							docTitle: 'Credit Note',
							showLineItemGenerator: true,
							canEditLineItems: true,
							boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
							boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Multichoice',
							clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'Multichoice'
						}
					}
				]
			},
			checkValidityForFields: ['credit_note_number', 'base_invoice_number', 'line_items'],
			navs: [
				{
					text: 'Upload',
					serverFirst: true,
					nextNode: 'UploadCreditNoteFile',
					color: 'primary',
					optIntoValidation: true
				}
			]
		},
		UploadCreditNoteFile: {
			initFormFields: bf => {
				bf.addControl('filename', new UntypedFormControl('', Validators.required));
				bf.addControl('data', new UntypedFormControl('', Validators.required));
				bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
			},
			component: {
				children: [
					{
						component: 'FLXFileUploadWithPreviewComponent',
						inputs: {
							purposes: [{ display: 'SP Credit Note', value: 'credit_note' }],
							heading: 'Upload credit note',
							multipleUploads: false,
							allowedFileTypes: ['pdf'],
							containerWidth: '50vw',
							maxWidth: '50vw'
						}
					}
				]
			},
			checkValidityForFields: ['fileQueue'],
			navs: [
				{
					text: 'Upload',
					nextNode: 'FileSubmissionSuccess',
					location: 'right',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						uploadCreditNote: {
							errorMessage: 'Credit Note could not be uploaded',
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const fileName = fileQueue[0] ? fileQueue[0]?.file.nativeFile.name : '';
								const fileData = fileQueue[0] ? fileQueue[0]?.file.base64 : '';

								bf.patchValues({ filename: fileName, data: fileData });
								return of(true);
							}
						}
					}
				}
			]
		},
		FileSubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'File successfully uploaded' },
			navs: [
				{
					text: 'Continue',
					color: 'primary',
					serverCalls: {
						companyName: {
							errorMessage: 'Error getting company name',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getCurrentUser).pipe(
										skipWhile(x => !x),
										take(1),
										map((currentUser: any) => ({ CompanyType: currentUser?.user?.sp?.name }))
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res)
									)
								]).pipe(
									map(([{ CompanyType }, submissionData]) => {
										const newLineItemsArr = submissionData ? [...submissionData.line_items] : [];
										const vatPercentage = bf.bigForm.get('vatPercentage')?.value;
										const body = {
											...submissionData,
											credit_note_amount: Number(submissionData?.credit_note_amount),
											line_items: newLineItemsArr.map(ele => {
												return {
													...ele,
													CompanyType,
													TotalVAT: (ele?.AmountExclVAT * (-vatPercentage / 100)).toFixed(2),
													AmountExclVAT: (ele?.AmountExclVAT * -1).toFixed(2)
												};
											})
										};
										return http
											.post(`${environment.api_url}v1/mc_actions/submit_credit_note`, body, {
												observe: 'events',
												reportProgress: true
											})
											.subscribe(res => console.log(res));
									})
								);
							}
						}
					},
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		job_id: 'job_id',
		credit_note_number: 'credit_note_number',
		credit_note_amount: 'credit_note_amount',
		base_invoice_number: 'base_invoice_number',
		filename: 'filename',
		data: 'data',
		line_items: 'line_items'
	}
};
