import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIElementsModule } from '@flexus/ui-elements';
import { FLXSPJobDetailsComponent } from './sp-job-details.component';

@NgModule({
	declarations: [FLXSPJobDetailsComponent],
	imports: [CommonModule, UIElementsModule],
	exports: [FLXSPJobDetailsComponent]
})
export class SPJobDetailsModule {}
