import { Directive, ElementRef, Input, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { WindowRef, DocumentRef } from './agmish';

class Script {
	src: string;
	loaded: boolean;

	constructor(scriptSRC: string) {
		this.src = scriptSRC;
		this.loaded = false;
	}
}

@Directive({
	selector: '[scriptLoader]'
})
export class ScriptLoaderDirective implements OnInit {
	private script: Script;

	@Input('scriptLoader')
	scriptSource: string;

	@Output() scriptCallback: EventEmitter<boolean> = new EventEmitter();

	constructor(private _renderer: Renderer2, private w: WindowRef, private d: DocumentRef) {}

	ngOnInit() {
		this.script = new Script(this.scriptSource);
		this.loadScript().then(result => {
			this.scriptCallback.emit(this.script.loaded);
		});
	}

	loadScript() {
		return new Promise((resolve, reject) => {
			if (!this.checkIfScriptExists()) {
				if (!this.script.loaded) {
					const scriptElm: HTMLScriptElement = this._renderer.createElement('script');
					scriptElm.type = 'text/javascript';
					scriptElm.src = this.script.src;

					this._renderer.appendChild(document.body, scriptElm);

					scriptElm.onload = () => {
						this.script.loaded = true;
						resolve({ loaded: true, status: 'Loaded' });
					};

					scriptElm.onerror = (error: any) => {
						this.script.loaded = false;
						resolve({ loaded: false, status: 'Loaded' });
					};
				}
			} else {
				this.script.loaded = true;
				resolve({ loaded: true, status: 'Already Loaded' });
			}
		});
	}

	// will return the script if it exists on the page
	checkIfScriptExists() {
		return document.querySelectorAll(`script[src="${this.script.src}"]`).length > 0;
	}
}
