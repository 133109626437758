<flx-flex-container [alignItems]="this.viewConfig.viewAlignment" [maxWidth]="this.viewConfig.viewWidth">
	<flx-heading size="small" *ngIf="this.viewConfig.showHeading">Time Stamps</flx-heading>

	<ng-container *ngIf="timeStampsObservable | async as timeStamps">
		<flx-heading size="medium">Claim updates:</flx-heading>
		<ng-container *ngFor="let claimState of timeStamps?.claimStates">
			<span class="time-stamp-info-indent-1">{{ claimState?.mytimestamp | date: 'yyyy-MM-dd HH:mm' }} - {{ claimState?.modifier }}</span>
			<span class="time-stamp-info-indent-2">{{ claimState?.state }}: {{ claimState?.state_description }}</span>
			<span class="time-stamp-info-indent-2">{{ claimState?.reason }}</span>
			<flx-glow-line margin="1rem 0"></flx-glow-line>
		</ng-container>

		<flx-accordion-template *ngIf="(timeStamps?.jobs | fsIsArray) && timeStamps?.jobs.length > 0; else noStamps">
			<flx-heading size="medium">Job updates:</flx-heading>
			<ng-container *ngFor="let jobStamp of timeStamps?.jobs">
				<flx-accordion-group title="{{ jobStamp.job_id }}: {{ jobStamp.title }}">
					<ng-container *ngFor="let item of jobStamp">
						<span>{{ item?.dateTime | date: 'yyyy-MM-dd HH:mm' }} - {{ item?.modifier }}</span
						><br />
						<span class="time-stamp-info-indent-1">{{ item?.state }}: {{ item?.state_description }}</span
						><br />
						<span class="time-stamp-info-indent-1">{{ item?.reason }}</span>
						<flx-glow-line margin="1rem 0"></flx-glow-line>
					</ng-container>
				</flx-accordion-group>
			</ng-container>
		</flx-accordion-template>

		<ng-template #noStamps> <flx-alert type="no-info" missingItem="time stamps"></flx-alert> </ng-template>
	</ng-container>
</flx-flex-container>
