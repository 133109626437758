import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
	BigFormService,
	Flow_0_0_2,
	getAllInfo,
	getFullItemOne,
	getFullItemTwo,
	getSelectedItemTwo,
	MakeServerCall,
	ManifestController,
	ModalService,
	SetNextNode
} from '@flexus/core';
import { KVLHeading } from '@flexus/ui-elements';
import { Store } from '@ngrx/store';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import moment from 'moment';
import { AUTO_SELECT_TEMPLATE_DECISION, billingServerCalls, SELECT_CALLOUT_TEMPLATE } from './BILLING';
import { EMPTY, forkJoin, of } from 'rxjs';
import { map, pluck, skipWhile, take } from 'rxjs/operators';

SELECT_CALLOUT_TEMPLATE.navs = [
	{
		text: 'Continue',
		optIntoValidation: false,
		nextNode: 'BILLING_INVOICE'
	}
];

export const PGG_378: Flow_0_0_2 = {
	id: '378',
	name: 'invoice_warranty_request',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	instructions: {
		editRoles: { 0: '' },
		viewRoles: { 0: '' }
	},
	header: {
		title: (store: any, bf: any) => {
			return store.select(getFullItemOne).pipe(
				map((itemOne: any) => {
					if (itemOne?.claim) {
						if (itemOne?.claim?.applicant && itemOne?.claim?.loan_information) {
							return `Invoice Warranty Request : ${itemOne?.claim?.loan_information?.voucher_key} - ${itemOne?.claim?.applicant?.first_name} `;
						} else {
							return 'Invoice Warranty Request';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		voucherinfo: {
			errorMessage: `Couldn't get the voucher`,
			directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService) => {
				return _store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1),
						map((rest: any) => rest)
					)
					.pipe(
						map((itemTwo: any) => {
							const { claim } = itemTwo;
							const { job_information } = itemTwo;
							let additional_cost;
							let incentive;
							const additional_fees_amount = job_information?.additional_fees_amount;
							if (!additional_fees_amount) {
								additional_cost = parseFloat(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
							} else {
								// additional_cost = +additional_fees_amount;
								additional_cost = parseFloat(new DecimalPipe('en-US').transform(Number(additional_fees_amount), '1.2-2'));
							}
							const installer_incentive_amount = job_information?.installer_incentive_amount;
							if (!installer_incentive_amount) {
								incentive = parseFloat(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
							} else {
								// incentive = +installer_incentive_amount;
								incentive = parseFloat(new DecimalPipe('en-US').transform(Number(installer_incentive_amount), '1.2-2'));
							}
							const values = claim?.loan_information?.values;
							const voucher_amount = values?.total_value;
							const sp_value = +values?.sp_value;
							const summed_invoice_amount: any = sp_value + incentive + additional_cost;
							const approved_sp_cost = new DecimalPipe('en-US').transform(Number(summed_invoice_amount), '1.2-2');
							const voucherobject = {
								// 'Voucher amount': `R ${voucher_amount}`,
								'Approved SP Cost (Excl. VAT)': `R ${approved_sp_cost}`
							};
							return [voucherobject];
						})
					);
			}
		},
		customerdetails: {
			errorMessage: 'No customer details were found!',
			directCall: (http, store, sq) => {
				return sq
					.queryObject(
						gql`
							{
								fullItemTwo {
									claim {
										applicant {
											first_name
											surname
											contact_number
										}
										loan_information {
											onsitecontact
										}
									}
								}
							}
						`,
						store.select(getFullItemTwo).pipe(
							skipWhile(fi => !fi),
							take(1),
							map(res => ({ fullItemTwo: res }))
						)
					)
					.pipe(
						map((qdat: any) => {
							let firstname, surname, contact_number, cell_number;
							if (!qdat) {
								firstname = 'No first name available';
								surname = 'No surname available';
								contact_number = 'No contact number';
							} else {
								if (qdat.first_name === '') {
									firstname = 'No first name available';
								} else {
									firstname = qdat.first_name ?? 'No first name available';
								}
								if (qdat.first_name === '') {
									surname = 'No surname available';
								} else {
									surname = qdat?.surname ?? 'No surname available';
								}
								if (qdat.contact_number === '') {
									contact_number = 'No contact number';
								} else {
									contact_number = qdat?.contact_number ?? 'No contact number';
								}
								if (qdat.cellnumber === '') {
									cell_number = 'No cell number';
								} else {
									cell_number = qdat?.onsitecontact ?? 'No cell number supplied';
								}
							}
							return [
								{
									'Client Name': `${firstname} ${surname}`,
									'Contact Number': `${contact_number}`,
									'Mobile Number': `${cell_number}`
								}
							];
						})
					);
			}
		},
		installationsummary: {
			errorMessage: `Couldn't get installation summary`,
			directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
				return forkJoin([
					_store.select(getFullItemTwo).pipe(
						skipWhile((x: any) => !x),
						take(1),
						map((rest: any) => rest)
					),
					_store.select(getAllInfo).pipe(
						skipWhile((x: any) => !x),
						take(1),
						map((result: any) => result)
					)
				]).pipe(
					map(([itemtwo, estimates]: any) => {
						const voucher_number: string = itemtwo?.claim?.loan_information?.voucher_key ?? 'No voucher mumber';
						const product: string = itemtwo?.claim?.loan_information?.voucher_type ?? 'No product information';
						const skillid: number | 'No skill' = +itemtwo?.claim?.loan_information?.skill_id ?? 'No skill';
						const { estimate_installation_times } = estimates;
						const estimate: any = estimate_installation_times.find(el => el.skill === skillid)?.installation_time;

						const installationsummaryobject = {
							'Voucher number': `${voucher_number}`,
							Product: `${product}`,
							'Estimated installation completion': `${estimate} hours`
						};
						return [installationsummaryobject];
					})
				);
			}
		},
		installerdetails: {
			errorMessage: `Couldn't get installation summary`,
			directCall: (http, store, sq, bf) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					map((rest: any) => {
						const installer: string = rest?.team_leader?.full_name ?? 'No team leader assigned';
						const installernumber: string = rest?.team_leader?.contact_number ?? 'No contact number for team leader';

						const installerobject = {
							'Installer Name': `${installer}`,
							'Installer contact number': `${installernumber}`
							// 'Installer was on site': 'Yes',
							// 'Installer completed installation': `Yes`
						};
						return [installerobject];
					})
				);
			}
		},
		...billingServerCalls
	},
	startNode: 'PingGoWorkCompleteStart',
	// startNode: 'AUTO_SELECT_TEMPLATE_DECISION',
	nodes: {
		DuplicatePaymentDecision: {
			hideTabItem: true,
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => EMPTY
			},
			decisions: {
				approveOrRevokePaymentAttempt: (_navs: any, _store: Store) => {
					return _store
						.select(getSelectedItemTwo)
						.pipe(
							skipWhile((response: any) => !response),
							take(1),
							map((data: any) => {
								const job_id = data?.id;
								_store.dispatch(
									new MakeServerCall({
										errorMessage: 'Could not complete server call',
										directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService, _controller: ManifestController<any>, _modal: ModalService) => {
											return _http.post(`${environment.api_url}v2/invoicing_action/check_duplicate_job/`, { job_id: job_id }).pipe(
												skipWhile((respons: any) => !respons),
												take(1),
												pluck('payload'),
												map((response: any) => {
													if (response?.duplicate === false) {
														_controller.dispatch(new SetNextNode('PingGoWorkCompleteStart'));
													} else {
														_modal.openModalDirectly(instance => {
															instance.type = 'warning';
															instance.heading = 'Warning!';
															instance.setMessage(['This job has already been paid!', 'You cannot claim for it again, this job will be closed.']);
															instance.navButtons = [
																{
																	text: 'okay',
																	color: 'default',
																	linkType: 'submitThenNext',
																	serverFirst: true,
																	nextNode: 'SubmissionSuccess',
																	serverCalls: {
																		response: {
																			errorMessage: 'Job state could not be updated to 28',
																			directCall: (_http: HttpClient) => {
																				return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: 28 });
																			}
																		}
																	}
																}
															];
														});
													}
												})
											);
										}
									})
								);
								return of({});
							})
						)
						.subscribe();
				}
			},
			navs: []
		},
		PingGoWorkCompleteStart: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Work Complete'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							color: 'secondary',
							data$: 'voucherinfo',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',
							data$: 'customerdetails',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Summary',
							data$: 'installationsummary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installer Details',
							data$: 'installerdetails',
							itemMargin: '0 0 65px 0'
						}
					}
				]
			},
			navs: [
				
				{
					text: 'upload invoice',
					color: 'primary',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
					linkType: 'close'
				}
			]
		},

		
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'invoice',
				// docTitle: '',
				numberLabel: "SP's Own Invoice",
				currentState: 26,
				newState: 226,
				showLineItemGenerator: false,
				canEditLineItems: false,
				getImageData: () => {},
				boqLogo: environment.client === 'pgg_sp' ? 'assets/images/PingGo_Logo.svg' : 'assets/images/PingGo_Logo.svg',
				boqLogoAlt: environment.client === 'pgg_sp' ? 'Pinggo' : 'Standard Bank Insurance Limited',
				clientName: environment.client === 'pgg_sp' ? 'Pinggo' : 'SIL'
			},
			navs: [
				{
					text: 'Generate Invoice',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		new_state: 'new_state',
		appointmentData: [
			(appointment: any) => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					const appointment_formatted = {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
					return appointment_formatted;
				}
			},
			'appointment'
		],
		additional_fees_amount: [(amount: any) => amount, 'job_information.additional_fees_amount'],
		additional_fees_reason: [(reason: any) => reason, 'job_information.additional_fees_reason']
	}
};

// job_total: [(ttl: any) => ttl, 'job_information.claim_value'],
