import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ViewChild } from '@angular/core';
import { BigFormService, ModalService, MakeServerCall, getData, getAllInfoAdditional } from '@flexus/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map, take, skipWhile, pluck, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { FLXModalComponent } from '@flexus/ui-elements';
import { Store } from '@ngrx/store';

export class AmpProduct {
	id?: string;
	policy?: string;
	serial?: string;
	item_type?: number;
	make?: number;
	model?: string;
	item_data?: { desc: string };
	active?: boolean;
	files?: any[];
	photo?: any[];
	created?: string;
	constructor(product) {
		const _product = {
			policy: '',
			serial: '',
			item_type: -1,
			make: -1,
			model: '',
			additionalInfo: '',
			active: true,
			files: [],
			created: ''
		};
		this.id = product.id;
		this.policy = product.policy ? product.policy : _product.policy;
		this.serial = product.serial ? product.serial : _product.serial;
		this.item_type = product.item_type ? product.item_type : _product.item_type;
		this.make = product.make ? product.make : _product.make;
		this.model = product.model ? product.model : _product.model;
		this.item_data = { desc: product.additionalInfo ? product.additionalInfo : _product.additionalInfo };
		this.active = product.active ? product.active : _product.active;
		this.files = product.files ? product.files : _product.files;
		this.created = product.created ? product.created : _product.created;
	}
}

@Component({
	selector: 'flx-amp-product-selection',
	templateUrl: './amp-product-selection.component.html',
	styleUrls: ['./amp-product-selection.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmpProductSelectionComponent implements OnInit, OnDestroy {
	// =========================================== Public Variables ===========================================================
	public selectedApplianceId = '';
	public selectedAppliance: AmpProduct;
	public productAdded$: Observable<any>;

	public navButtons: any[] = [
		{ text: 'back', linkType: 'close' },
		{ text: 'add appliance', linkType: 'close', color: 'primary', clickHandler: this._addAppliance, self: this }
	];
	public addApplianceForm: UntypedFormGroup;
	public item_types: { id: number; name: string }[] = [];
	public brands: { id: number; name: string }[] = [];
	public registeredProductsFormatted$: BehaviorSubject<any[]> = new BehaviorSubject([]);

	// =========================================== Private Variables ===========================================================
	private _subscription: Subscription;
	private _formSubscription: Subscription;
	private _productAddedSub: Subscription;
	private _registeredProductsSub: Subscription;
	private _registeredProducts: AmpProduct[];
	private _planId = '';

	// -----------------------------------------  INPUT / OUTPUT events -----------------------------------------
	@Input() title = 'Select Appliance';
	@Input() registeredProducts$: Observable<any[]>;
	@Input() photos$: Observable<any[]>;
	@Input() addProductEndpoint: any;
	@Input() getFilesEndpoint: any;
	@ViewChild(FLXModalComponent) modal: FLXModalComponent;

	// Controls
	itemTypeCtrl: UntypedFormControl;
	makeCtrl: UntypedFormControl;
	serialCtrl: UntypedFormControl;
	modelCtrl: UntypedFormControl;

	// ----------------------------------------- Constructor -----------------------------------------
	constructor(public bf: BigFormService, private fb: UntypedFormBuilder, private store: Store<any>, private modalsService: ModalService) {
		this._initAddApplianceFormGroup();
	}

	// ----------------------------------------- Life-cycle methods -----------------------------------------
	ngOnInit() {
		this._registeredProductsSub = this.registeredProducts$
			.pipe(
				skipWhile(products => !products),
				pluck('results'),
				switchMap((products: AmpProduct[]) => {
					this._registeredProducts = products;
					return this.store.select(getAllInfoAdditional).pipe(
						map(allInfoAdditional => allInfoAdditional.payload),
						take(1),
						map(allInfo => {
							this.item_types = allInfo.item_types;
							this.brands = allInfo.brands;
							return this._formatProductsForSelectedList(products, this.item_types, this.brands);
						})
					);
				}),
				take(1)
			)
			.subscribe(registeredProductsFormatted => {
				this.registeredProductsFormatted$.next(registeredProductsFormatted);
			});

		let control = this.bf.bigForm.get('selectedAppliance');
		if (control) {
			this.selectedApplianceId = control.value;
		} else {
			this.bf.bigForm.addControl('selectedAppliance', new UntypedFormControl('', [Validators.required]));
			control = this.bf.bigForm.get('selectedAppliance');
		}

		if (!this.bf.bigForm.get('jobs')) {
			this.bf.bigForm.addControl('jobs', new UntypedFormArray([]));
		}

		this.store
			.select(getData)
			.pipe(
				map(data => data['lookupDetails']),
				skipWhile(data => !data),
				take(1)
			)
			.subscribe(lookupDetails => {
				this._planId = lookupDetails[0]?.id;
			});

		this._productAddedSub = this.store
			.select(getData)
			.pipe(
				map(data => data['productAdded']),
				skipWhile(productAdded => !productAdded || !productAdded.results),
				distinctUntilChanged(),
				pluck('results')
			)
			.subscribe(products => {
				this._registeredProducts = products;
				this.registeredProductsFormatted$.next(this._formatProductsForSelectedList(products, this.item_types, this.brands));
			});

		this._formSubscription = control?.valueChanges?.subscribe(changes => {
			if (changes[0] !== this.selectedApplianceId) {
				this.selectedApplianceId = changes[0];

				const _selectedAppliance = this._filterAppliance(this._registeredProducts, this.selectedApplianceId);
				this.selectedAppliance = null;
				if (_selectedAppliance) {
					this.selectedAppliance = { ..._selectedAppliance };
				}

				this._setApplianceControl(this.selectedAppliance);
				if (this.selectedApplianceId && this.selectedAppliance && this.selectedAppliance.photo && this.selectedAppliance.photo.length > 0) {
					this.store.dispatch(
						new MakeServerCall({
							...this.getFilesEndpoint,
							data: { fileGuids: this.selectedAppliance.photo }
						})
					);
				}
			}
		});
	}

	ngOnDestroy() {
		if (this._subscription) this._subscription.unsubscribe();
		if (this._formSubscription) this._formSubscription.unsubscribe();
		if (this._productAddedSub) this._productAddedSub.unsubscribe();
		if (this._registeredProductsSub) this._registeredProductsSub.unsubscribe();
	}

	// ============================================= Private Methods ============================================================
	private _addAppliance(event) {
		event.self.modalsService.openModalDirectly(inst => {
			inst.closeButton = true;
			inst.setMessage(['You are about to register an appliance.', 'Once you register the appliance,', 'you will not be able to modify the appliance details.']);
			inst.type = 'warning';
			inst.heading = 'Are you sure?';
			inst.navButtons = [
				{
					text: 'Cancel',
					linkType: 'close',
					color: 'default',
					clickHandler: () => {
						event.self._showAddApplianceModal();
					}
				},
				{
					text: 'I am sure',
					linkType: 'close',
					color: 'default',
					clickHandler: () => {
						const addApplianceGroup = event.self.addApplianceForm;
						if (addApplianceGroup.valid && event.self.bf.bigForm.get('policy_lookup')) {
							event.self.bf.bigForm.get('selectedAppliance').patchValue('');

							const addAppliance = new AmpProduct({
								...addApplianceGroup.value,
								policy: event.self._planId
							});

							event.self.store.dispatch(
								new MakeServerCall({
									...event.self.addProductEndpoint,
									data: { product: addAppliance }
								})
							);
						}
					}
				}
			];
		});
	}

	private _initAddApplianceFormGroup() {
		this.addApplianceForm = this.fb.group({
			item_type: ['', Validators.required],
			serial: '',
			make: ['', Validators.required],
			model: '',
			additionalInfo: ''
		});
		this.itemTypeCtrl = this.addApplianceForm.controls['item_type'] as UntypedFormControl;
		this.makeCtrl = this.addApplianceForm.controls['make'] as UntypedFormControl;
		this.modelCtrl = this.addApplianceForm.controls['model'] as UntypedFormControl;
		this.serialCtrl = this.addApplianceForm.controls['serial'] as UntypedFormControl;
	}

	private _formatProductsForSelectedList(products: AmpProduct[], item_types: { id; name }[], brands: { id; name }[]) {
		return products.map(product => {
			const newProduct = { ...product } as any;
			newProduct.item_type = item_types.filter(type => Number(type.id) === Number(product.item_type))[0];
			newProduct.make = brands.filter(brand => Number(brand.id) === Number(product.make))[0];
			return {
				display: `${newProduct && newProduct.make ? newProduct.make.name : ''} ${newProduct && newProduct.model ? newProduct.model : ''} | ${
					newProduct && newProduct.item_type ? newProduct.item_type.name : ''
				}`,
				value: newProduct.id,
				hasAlertIcon: !newProduct.active
			};
		});
	}

	private _filterAppliance(registeredProducts: AmpProduct[], selectedApplianceId: string): AmpProduct {
		if (registeredProducts && selectedApplianceId !== undefined) {
			const _selectedAppliances = registeredProducts.filter(userProduct => userProduct.id === this.selectedApplianceId);
			if (_selectedAppliances.length > 0) {
				return _selectedAppliances[0];
			}
		}
		return null;
	}

	private _setApplianceControl(_selectedAppliance) {
		if (_selectedAppliance) {
			const jobsFormArray = this.bf.bigForm.get('jobs') as UntypedFormArray;
			jobsFormArray.setControl(0, this.fb.group({ ...this.selectedAppliance }));
		}
	}

	private _showAddApplianceModal() {
		this.modal.heading = 'Add Appliance';
		this.modal.navButtons = this.navButtons;
		this.modal.open();
	}

	// ============================================= Public Methods ============================================================
	public initAndshowAddApplianceModal() {
		this._initAddApplianceFormGroup();
		this._showAddApplianceModal();
	}
}
