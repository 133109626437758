import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { getSelectedItem, ManifestController, SetNextNode } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';

@Component({
	selector: 'flx-pgg-fake-modal',
	templateUrl: 'pgg-fake-modal.component.html',
	styleUrls: ['pgg-fake-modal.component.scss']
})
export class PingGoFakeModalComponent {
	customersub!: Subscription;
	constructor(private _store: Store, private _http: HttpClient, @Inject('environment') private environment: any, private _ctrllr: ManifestController<any>) {}


	customerEntersDetails() {
		return this._store
			.select(getSelectedItem)
			.pipe(
				skipWhile(x => !x),
				take(1),
				switchMap((job: any) => {
					const job_id = job?.id;
					const new_state = 258;
					const data = {
						job_id: job_id,
						new_state: new_state
					};
					this._ctrllr.dispatch(new SetNextNode('SubmissionSuccess'));
					return this._http.post(`${this.environment.api_url}v1/job_action/update_job/`, data);
				})
			)
			.subscribe();
	}
	proceedCustomerDetails() {
		return this._ctrllr.dispatch(new SetNextNode('RefundBreakdown'));
	}

}
