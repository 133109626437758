import { NgModule } from '@angular/core';
import { AppFooterComponent } from './app-footer.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { FLXNavModule } from './nav';

@NgModule({
	declarations: [AppFooterComponent],
	imports: [CommonModule, NgxPaginationModule, FLXNavModule],
	exports: [FLXNavModule, AppFooterComponent]
})
export class AppFooterModule {}
