/*

this component takes 2 inputs:

  • joblocation$
  • teamleadersPositions$

  This component is meant to inform the user of the whereabouts of team
  leaders with activated handsets by displaying the service provider and
  team leader names and  data about the distance and driving time to the
  job. No direct communication between itself and the select list that it
  shares a screen with is catered for. At least some of the team
  leaders that populate the select list should be

*/

import { Component, ViewChild, OnInit, Inject, AfterViewInit, OnDestroy, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, forkJoin } from 'rxjs';
import { take, map, skipWhile, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
export {} from 'googlemaps';

import { TeamleadPickerService } from './teamlead-picker.service';
import { getCurrentUser, getFullItemTwo, ModalService } from '@flexus/core';

interface TeamleadPickerConfig extends google.maps.MapOptions {
	scriptId?: string;
	url?: string;
}

export interface ILocation {
	lat: number;
	lng: number;
}

@Component({
	selector: 'flx-teamlead-picker',
	templateUrl: './teamlead-picker.component.html',
	styleUrls: ['./teamlead-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TeamleadPickerService]
})
export class FLXTeamleadPickerComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() joblocation$;
	@Input() teamleadersPositions$;

	@ViewChild('mapContainerDiv') containingElem: any;
	mapsContainer: google.maps.Map;

	public googleMapsSrc = `https://maps.googleapis.com/maps/api/js?key=${this.environment.google_maps_api_key}&region=ZA&language=en`;
	private defaultCenter = { lat: -26.2041, lng: 28.0473 };
	private scriptsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private customerConcern$: Observable<any>;
	public customerConcern: boolean = false;

	private _config: TeamleadPickerConfig;
	private _defaultConfig: TeamleadPickerConfig;

	public sa: google.maps.Map;

	public jobLocation;
	private returnedLeaders;

	public rDir_distance: string;
	public rDir_duration: string;
	private _posiDataSubscription: Subscription;
	private _teamleadPositionSub: Subscription;
	private usersub: Subscription;
	customersub: Subscription;

	public that = this;
	public device_type: string;
	public tv_type;
	public dwelling_type;
	public existing_decoders;
	public no_of_tvs;
	public wall_type;
	job;
	client;

	constructor(@Inject('environment') private environment: any, private _store: Store<any>, public modal: ModalService, private _cdr: ChangeDetectorRef) {
		this.usersub = forkJoin([
			this._store.select(getCurrentUser).pipe(
				skipWhile(x => !x),
				take(1),
				map(user => user)
			),
			this._store.select(getFullItemTwo).pipe(
				skipWhile(x => !x),
				take(1),
				map((d: any) => d)
			)
		])
			.pipe(
				map(([user, selected]) => {
					const claimtype = selected?.claim?.claim_type;
					const pricelockclaimtypes = [26];
					const insuranceclaimtypes = [20, 10, 8, 7, 32, 34, 35];
					const customerscheduledclaimtypes = [36];
					const installerownclaimtypes = [37];
					// show site detail under map if client is multichoice and claim type is 'Customer Scheduled Appointment' and also for
					if (user?.client === 'multichoice') {
						if (pricelockclaimtypes.includes(claimtype) === true) {
							this.customerConcern = true;
						}
						if (insuranceclaimtypes.includes(claimtype) === true) {
							this.customerConcern = false;
						}
						if (customerscheduledclaimtypes.includes(claimtype) === true) {
							this.customerConcern = true;
						}
						if (installerownclaimtypes.includes(claimtype) === true) {
							this.customerConcern = true;
						}
					} else {
						this.customerConcern = false;
					}
				})
			)
			.subscribe();

		this.customerConcern$ = this._store.select(getFullItemTwo).pipe(
			skipWhile(x => !x),
			take(1),
			switchMap((job: any) => {
				const current_environment = job?.claim?.loan_information?.current_environment;
				const claimtype = job?.claim?.claim_type;
				let device_details;
				// const device_details = job?.claim?.loan_information?.device_details;
				if (claimtype === 36) {
					this.wall_type = job?.claim?.loan_information?.current_environment?.wall_type ?? 'Not specified';
					device_details = job?.office_use?.device_details?.customer_device?.cust_device_type ?? 'No device type';
				} else if (claimtype === 37) {
					this.wall_type = job?.claim?.loan_information?.current_environment?.wall_type ?? 'Not specified';
					// device_details = job?.office_use?.device_details?.replacement_device?.type ?? 'No device type available';
					device_details = job?.office_use?.device_details;
					if (device_details instanceof Array) {
						// console.log('it is an array');
						const decodersArray: string[] = [];
						for (let i = 0; i < device_details.length; i++) {
							const decodername: string = device_details[i].replacement_device?.type;
							decodersArray.push(decodername);
						}

						if (decodersArray.length === 2) {
							this.device_type = decodersArray.join(' and ');
						} else if (decodersArray.length > 2) {
							const last_in_list = decodersArray.pop();
							const others_in_list: string[] = [];
							for (let i = 0; i < device_details.length - 1; i++) {
								const decodername: string = device_details[i].replacement_device?.type;
								others_in_list.push(decodername);
							}
							const prelist: string = others_in_list.join(', ');
							this.device_type = prelist.concat(' and ', last_in_list);
						}
					} else {
						device_details = device_details?.replacement_device?.type;
					}
				}
				this.device_type = device_details;
				this.tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs : 'Data not available';
				this.dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type : 'Data not available';
				this.existing_decoders = current_environment?.existing_decoder === undefined ? 'No' : 'Yes';
				this.no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs : 'Data not available';
				// this.wall_type = current_environment?.wall_type ? current_environment?.wall_type : 'Data not avaiable';
				this._cdr.detectChanges();
				return of(job);
			})
		);
	}

	get config() {
		return this._config || this._defaultConfig;
	}

	ngOnInit() {
		this.customersub = this.customerConcern$.subscribe();
	}

	ngAfterViewInit() {
		this.scriptsLoaded.subscribe(loadStatus => {
			if (loadStatus) {
				this._defaultConfig = {
					zoom: 9,
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					center: this.defaultCenter,
					backgroundColor: 'none',
					mapTypeControlOptions: {
						mapTypeIds: []
					}
				};
				this.sa = new google.maps.Map(this.containingElem.nativeElement, this.config);
				this.initMap();
			}
		});
	}

	initMap() {
		this._posiDataSubscription = forkJoin([
			this.joblocation$.pipe(
				skipWhile(x => !x),
				take(1)
			),
			this.teamleadersPositions$.pipe(
				skipWhile(x => !x),
				take(1)
			)
		])
			.pipe(
				skipWhile(res => !res),
				take(1),
				map(([jobLocation, tlLocations]: any) => ({ jobLocation, tlLocations }))
			)
			.subscribe(sub => {
				this.jobLocation = sub.jobLocation;
				this.returnedLeaders = sub.tlLocations;
				this.job = new google.maps.LatLng({ lat: this.jobLocation.lat, lng: this.jobLocation.lng });
				this.setJobMarker({ lat: this.jobLocation.lat, lng: this.jobLocation.lng });
				const job_marker = new google.maps.Marker({
					position: this.jobLocation,
					map: this.sa
				});

				this.createTeamLeadMarkers();
			});
	}

	setJobMarker(coords) {
		this.sa.setCenter(coords);
	}

	createTeamLeadMarkers() {
		const that = this;
		const icons = {
			sp: {
				icon: '../../assets/images/truck.png'
			}
		};
		const teamleaders = [];
		for (let l = 0; l < this.returnedLeaders.length; l++) {
			const teamleadobjec = {
				position: { lat: +this.returnedLeaders[l]?.lat, lng: +this.returnedLeaders[l]?.long },
				type: 'sp',
				name: this.returnedLeaders[l]?.full_name,
				sp: this.returnedLeaders[l]?.sp
			};
			teamleaders.push(teamleadobjec);
		}

		let marker, i, currentPos;
		const markers = [];

		for (i = 0; i < teamleaders.length; i++) {
			marker = new google.maps.Marker({
				position: teamleaders[i]?.position,
				icon: icons[teamleaders[i]?.type]?.icon,
				map: this.sa
			});
			markers.push(marker);
			google.maps.event.addListener(
				marker,
				'click',
				// tslint:disable-next-line:no-shadowed-variable
				(function (marker, i) {
					return function () {
						currentPos = marker.map.getZoom();
						marker.map.setZoom(15);
						marker.map.setCenter(marker.getPosition());

						const directionsService = new google.maps.DirectionsService();
						const directionsRenderer = new google.maps.DirectionsRenderer();
						directionsRenderer.setMap(marker.map);

						const route = {
							origin: marker.position,
							destination: that.job,
							travelMode: google.maps.TravelMode.DRIVING
						};

						directionsService.route(route, function (response, status) {
							if (status !== google.maps.DirectionsStatus.OK) {
								that.modal.openModalDirectly(instance => {
									const message = [
										"The directions service couldn't resolve one or both of the locations provided.",
										'It should still be possible to allocate a team leader, however.'
									];
									(instance.type = 'info'),
										(instance.heading = 'Google Maps Directions Service Error'),
										instance.setMessage(Array.isArray(message) ? message : [message]),
										(instance.navButtons = [
											{
												text: 'OK',
												linkType: 'close',
												color: 'primary'
											}
										]);
								});
								return;
							} else {
								directionsRenderer.setDirections(response);
								const directionsData = response.routes[0]?.legs[0];

								if (!directionsData) {
									that.modal.openModalDirectly(instance => {
										const message = ['Directions request failed.', 'It should still be possible to allocate a team leader, however.'];
										(instance.type = 'info'),
											(instance.heading = 'Google Maps Directions Service Error'),
											instance.setMessage(Array.isArray(message) ? message : [message]),
											(instance.navButtons = [
												{
													text: 'OK',
													linkType: 'close',
													color: 'primary'
												}
											]);
									});
									window.alert('Directions request failed');
									return;
								} else {
									that.rDir_distance = directionsData.distance.text;
									that.rDir_duration = directionsData.duration.text;
								}
							}
							const contentstr =
								'<div id="content">' +
								'<div id="site-error">' +
								'</div>' +
								'<h3 id="firstHeading" class="firstHeading" style="margin: 0; padding: 0">' +
								teamleaders[i]?.sp +
								'</h3>' +
								'<h4 id="firstHeading" class="firstHeading" style="margin: 0; padding: 0">' +
								teamleaders[i]?.name +
								'</h4>' +
								'<div id="jobsnum">' +
								'<p style="padding: 0; margin: 0">Driving distance : ' +
								that.rDir_distance +
								' (' +
								that.rDir_duration +
								') ' +
								'</p>' +
								'</div>' +
								'</div>';

							const info = new google.maps.InfoWindow({
								content: contentstr
							});

							info.open(marker.map, marker);
						});

						window.setTimeout(() => {
							marker.map.setZoom(currentPos);
						}, 5000);
					};
				})(marker, i)
			);
		}
	}

	scriptLoaderCallback(isLoaded) {
		this.scriptsLoaded.next(isLoaded);
	}

	ngOnDestroy() {
		if (this._posiDataSubscription) {
			this._posiDataSubscription.unsubscribe();
		}
		if (this.customersub) {
			this.customersub.unsubscribe();
		}
	}
}
