import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';
import { skipWhile, take, map, switchMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';

export const SP_GLOBAL_41: Flow_0_0_2 = {
	id: '41',
	name: 'job-cancelled-notification',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Cancel Job : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Cancel Job';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		}
	},
	instructions: {
		editRoles: {
			0: 'Confirm job cancellation'
		},
		viewRoles: {
			0: 'Wait for SP to confirm cancellation'
		}
	},
	startNode: 'JobCancelledNotification',
	nodes: {
		JobCancelledNotification: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Cancelled',
							instructions: ['This job was cancelled', 'This job card will now be moved out of the workflow.']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList' }
					}
				]
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.addControl('new_state', new UntypedFormControl(45));
				bf.addControl('cancelclaim_reason', new UntypedFormControl('Cancelled'));
			},
			serverCalls: {
				keyValueList: {
					errorMessage: `Couldn't get the job info`,
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const list = {
										'Claim Type : ': itemTwo?.claim?.type ?? '',
										'Address : ': itemTwo?.address ?? '',
										'Appointment Date : ': itemTwo?.office_use?.requestedappointmentdate ?? 'No appointment date set',
										'Appointment Time : ': itemTwo?.office_use
											? `${itemTwo.office_use.appointment_type} ${itemTwo.office_use.requestedappointmenttime}`
											: 'Appointment time not retrieved'
									};
									return [list];
								})
							);
					}
				}
			},
			navs: [
				{
					text: 'Remove Job',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					color: 'primary',
					linkType: 'submit',
					serverCalls: {
						response: {
							errorMessage: 'Job could not be removed',
							// serviceVariable: 'spService',
							// functionName: 'cancelJob',
							directCall: (http, store, sq, bf) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										switchMap(job => {
											const spJob = job as any;
											const new_state = 45;

											const data = {
												job_id: spJob.id,
												new_state
											};
											return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
										})
									);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		reason: 'cancelclaim_reason'
	}
};
