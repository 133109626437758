import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicFormInputs } from '@flexus/ui-composites';

@Component({
	selector: 'flx-file-view-with-side-extra',
	templateUrl: './file-view-with-side-extras.component.html',
	styleUrls: ['./file-view-with-side-extras.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXFileViewWithSideExtrasComponent implements OnInit, OnDestroy {
	@Input() dataFiles$: Observable<any>;
	@Input() keyValueList$: Observable<any>;
	@Input() formInputs: DynamicFormInputs;
	@Input() inputHeading: string;
	@Input() subHeading: string = null;
	@Input() selectedItem$: Observable<any>;
	@Input() selectedItemOne$: Observable<any>;
	@Input() selectedItemTwo$: Observable<any>;
	@Input() fullInfo$: Observable<any>;
	@Input() initValues$: Observable<any>;
	@Input() fileDoesNotExistMessage = 'No invoice or quotation found!';
	@Input() buttons: [{ type: string; event: string; name: string }] = null;
	@Input() instructions: any[] = null;
	@Input() copyText$: Observable<any> = null;
	@Input() loading = false;
	@Input() loadingText = 'Fetching file';

	ngOnInit(): void {}

	handleButtonEvent(e: string) {}

	ngOnDestroy(): void {}
}
