import { Flow_0_0_2, getFullItemTwo, getAllInfo, getSelectedItemTwo, getSelectedItem } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { findName, getPropertyCaseInsensitive, isNumber } from '@flexus/utilities';
import { KVLHeading } from '@flexus/ui-elements';

export const SP_CONTEXT_MENU_VIEW_JOB_SUMMARY: Flow_0_0_2 = {
	id: 'JobSummary',
	itemType: 'flow',
	includeForOnlyStates: ['all'],
	name: 'Job Summary',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant) {
							return `Summary for this job on installation: ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'View Notes:';
						}
					}
				})
			);
		},
		controls: () => () => []
	},

	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	startNode: 'JobSummary',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {},
	nodes: {
		JobSummary: {
			component: {
				children: [
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Job Information', 'secondary'),
							data$: 'installerKeyValues'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Customer Info', 'secondary'),
							data$: 'customerContactKeyValues'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Internal Assessor', 'secondary'),
							data$: 'assessorDetails'
						}
					}
				]
			},
			serverCalls: {
				installerKeyValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile(ai => !ai),
								take(1),
								map(res => res as any)
							),
							store.select(getSelectedItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getSelectedItem).pipe(
								skipWhile(x => !x),
								take(1)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([itemTwo, allInfo, selectedJob, selectedItem, team]) => {
								const sp = itemTwo?.sp;
								const sps = allInfo.sps;
								const supplier_type = allInfo.supplier_type;
								let companyname;
								// let accreditation_number;
								let qualification_number;
								for (let i = 0; i < sps.length; i++) {
									if (sp === sps[i]?.id) {
										companyname = sps[i]?.name;
									}
								}
								const tleads = team as any;
								let teamleadname;
								for (let i = 0; i < tleads.length; i++) {
									if (!itemTwo.team_leader) {
										teamleadname = 'Team leader not assigned yet';
									} else if (tleads[i]?.id === itemTwo?.team_leader.id) {
										teamleadname = tleads[i]?.full_name;
									}
								}
								// const accreditation_number =
								if (!itemTwo.team_leader || !itemTwo.team_leader.sp) {
									// accreditation_number = 'There is no accreditation number available';
									qualification_number = 'No qualification number for the technician';
								} else {
									// accreditation_number = itemTwo?.team_leader.sp.accreditation_number;
									qualification_number = itemTwo?.team_leader.qualification_number;
								}

								// itemTwo && itemTwo?.team_leader && itemTwo?.team_leader.sp
								//   ? itemTwo?.team_leader.sp.accreditation_number
								//   : '--';
								// const qualification_number =
								//   itemTwo && itemTwo?.team_leader ? itemTwo?.team_leader.qualification_number : '--';
								const installerinfo = {
									Id: selectedItem.id,
									'Claim Type Id': getPropertyCaseInsensitive(itemTwo.claim.loan_information, 'claimtype_id'),
									'Assessor Name': selectedItem.assessor_name ? selectedItem.assessor_name : 'No assessor appointed',
									Lat: selectedItem.lat,
									Long: selectedItem.long,
									Suburb: selectedItem.suburb,
									Address: selectedItem.address,
									'Postal Code': selectedItem.postal_code,
									Updated: selectedItem.updated,
									Skill: selectedItem.skillName,
									Sp: isNumber(selectedItem.sp) ? (findName(selectedItem.sp, sps)['name'] ? findName(selectedItem.sp, sps)['name'] : 'sp not found') : selectedItem.sp,
									State: selectedItem.stateName,
									'Supplier Type': isNumber(selectedItem.supplier_type) ? findName(selectedItem.supplier_type, supplier_type)['name'] : selectedItem.supplier_type
									// 'Company name ': companyname,
									// 'Accreditation number ': accreditation_number,
									// 'Installation technician qualification number': qualification_number,
									// 'SP/Technician name ': teamleadname
								};

								return [installerinfo];
							})
						);
					}
				},
				customerContactKeyValues: {
					errorMessage: 'Something went wrong customer contact info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([itemTwo, teamleaders]) => {
								const tleads = teamleaders as any;
								let teamleadname;
								let joblocation;
								for (let i = 0; i < tleads.length; i++) {
									if (tleads[i]?.id === itemTwo?.team_leader) {
										teamleadname = tleads[i]?.full_name;
									}
								}
								// if (!itemTwo.location) {
								//   joblocation = 'No LatLng data provived';
								// } else if (itemTwo.location === '') {
								//   joblocation = 'No LatLng data provived';
								// } else {
								//   joblocation = 'itemTwo.location';
								// }
								if (!itemTwo?.claim?.loan_information) {
									joblocation = 'The customer misses data';
								}
								if (!itemTwo?.claim?.loan_information?.claimlatitude || !itemTwo?.claim?.loan_information?.claimlongitude) {
									joblocation = 'At lease one coordinate not supplied!';
								}
								if (itemTwo?.claim?.loan_information?.claimlatitude && itemTwo?.claim?.loan_information?.claimlongitude) {
									const latitude = itemTwo?.claim?.loan_information?.claimlatitude;
									const longitude = itemTwo?.claim?.loan_information?.claimlongitude;
									joblocation = `${latitude}, ${longitude}`;
								}

								const customerContactData = {
									'Onsite Person': `${getPropertyCaseInsensitive(itemTwo.claim?.loan_information, 'onsiteperson')}`,
									'Contact number': `${getPropertyCaseInsensitive(itemTwo.claim?.loan_information, 'contactnumber')}`,
									'Cell Number': getPropertyCaseInsensitive(itemTwo?.claim?.loan_information, `CellPhoneNumber`),
									'Alternative Contact numbers': getPropertyCaseInsensitive(itemTwo?.claim?.loan_information, 'onsitecontact'),
									/*
// Removes duplicate contact numbers for onsite person comparing cell phone number and contact number
										[itemTwo.claim.loan_information?.policydetails.CellPhoneNumber, itemTwo?.claim.loan_information?.onsitecontact]
											.filter(phoneNumber => !!phoneNumber)
											.filter(
												phoneNumber => itemTwo?.claim.loan_information?.contactnumber !== phoneNumber && itemTwo?.claim.loan_information?.policydetails?.CellPhoneNumber !== phoneNumber
											)
											.join(', ') || '-', */

									// 'Full name ': `${itemTwo?.claim?.applicant?.first_name} ` + `${itemTwo?.claim?.applicant?.surname}`,
									// 'Installation Address': `${itemTwo?.claim?.loan_information?.propertystreetaddress},` + ' ' + `${itemTwo?.claim?.loan_information?.propertysuburb}`,
									'Email address ': itemTwo?.claim?.loan_information?.email ?? 'No email address returned'

									// // 'GPS Coordinates ': itemTwo ? itemTwo?.location : '--'
									// 'GPS Coordinates ': joblocation
								};
								return [customerContactData];
							})
						);
					}
				},
				assessorDetails: {
					errorMessage: 'Could get assessor details',
					serviceVariable: 'spService',
					functionName: 'getAssessorDetailsformatted'
				}
			}
		}
	}
};
