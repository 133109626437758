import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXIndicatorComponent } from './indicator.component';

@NgModule({
	declarations: [FLXIndicatorComponent],
	imports: [CommonModule],
	exports: [FLXIndicatorComponent]
})
export class FLXIndicatorModule {}
