import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { iLineItem } from '@flexus/models';
import { BigFormService } from '@flexus/core';
import { decimalQuantityValidator, nonNegativeQuantityValidator, numberQuantityValidator } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import { Subscription } from 'dexie';

@Component({
	selector: 'flx-line-item-generator',
	templateUrl: './line-item-generator.component.html',
	styleUrls: ['./line-item-generator.component.scss']
})
export class LineItemGeneratorComponent implements OnDestroy {
	public showDropDown = false;
	public isItemHover = false;
	public isItemActive = false;

	@Input() index: number;
	@Input() availableLineItems: iLineItem[];
	@Input() icons: { src: string; alt: string }[] = [];
	@Input() allow_custom_items: boolean;
	@Input() actualLineItemsFormArray: FormArray;
	@Output() startCreateCustomItem = new EventEmitter();
	@Output() startUpdateCustomItem = new EventEmitter();
	@Output() changed = new EventEmitter();

	searchField = this.fb.control('');
	subscription: Subscription;

	constructor(public bf: BigFormService, private fb: UntypedFormBuilder, private store: Store<any>) {}

	ngOnDestroy(): void {
		this.subscription && this.subscription.unsubscribe();
	}

	@HostListener('mouseenter') onMouseEnter() {
		this.isItemHover = true;
	}

	@HostListener('mouseleave') onMouseLeave() {
		this.isItemHover = false;
	}

	public addToActualLineItems(availableLineItem: iLineItem) {
		const doesNotExist = !this.actualLineItemsFormArray.value.find(item => item.id === availableLineItem.id);
		if (doesNotExist) {
			this.actualLineItemsFormArray?.push(
				this.fb.group({
					id: [availableLineItem.id, [Validators.required]],
					search: [availableLineItem.name],
					description: [availableLineItem.name, [Validators.required]],
					unitPrice: [+availableLineItem.price],
					quantity: [1, [Validators.required, decimalQuantityValidator(), nonNegativeQuantityValidator(), numberQuantityValidator()]],
					item: [availableLineItem],
					can_edit: [true]
				})
			);
		}
		this.showDropDown = false;
	}

	updateCustomItem(event, item) {
		this.showDropDown = false;
		this.startUpdateCustomItem.emit(item);
		event.stopPropagation();
	}

	createCustomItem(event) {
		this.showDropDown = false;
		this.startCreateCustomItem.emit(true);
	}

	public openDropdown() {
		this.showDropDown = true;
		this.isItemActive = true;
	}
}
