import { BigFormService, Flow_0_0_2, getAllInfo, getCurrentUser, getFullItemTwo, getSelectedItemOne } from '@flexus/core';
import { filter, map, skipWhile, take } from 'rxjs/operators';
import { photoNode } from '../reusable/PHOTO_NODE';
import moment from 'moment';
import { forkJoin, of, combineLatest } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { setActionPanelItems, CollapseActionPanel } from 'apps/studio/src/app/app-shell-features';
import { HttpClient } from '@angular/common/http';
import { KVLHeading } from '@flexus/ui-elements';
import { Store } from '@ngrx/store';
import { UntypedFormControl, Validators } from '@angular/forms';
import { convertDateTimeToTimeStamp } from '@flexus/utilities';

export const MUL_334: Flow_0_0_2 = {
	id: '334',
	name: 'confirm_customer_activation',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any, bf: any) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo?.claim) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Autopay Deferred Query Response : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Autopay Deferred Query Response';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Check installer response and resubmit' //double check the text
		},
		viewRoles: {
			0: 'With Validation Agent' //double check the text
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'QueryResponse',
	nodes: {
		QueryResponse: {
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('new_state', new UntypedFormControl(341, []));
			},
			name: 'Query Response',
			showTabs: true,
			serverCalls: {
				query_history: {
					errorMessage: `Couldn't return validation query history`,
					directCall: (_http: HttpClient, _store: Store) => {
						return _store.select(getFullItemTwo).pipe(
							skipWhile((response: any) => !response),
							take(1),
							map((response: any) => {
								const list: any = [];
								const { job_information, office_use } = response;
								const { autopay_validation_queries } = job_information;
								const validation_query = autopay_validation_queries[autopay_validation_queries.length - 2].query;
								const installer_response = autopay_validation_queries[autopay_validation_queries.length - 1].queryreply;

								list.push({
									'Your Query': `${validation_query}`,
									'Installer Response': `${installer_response}`
								});

								return list;
							})
						);
					}
				},
				installer_details: {
					errorMessage: 'Cannot get job info summary',
					directCall: (_http: HttpClient, _store: Store) => {
						return _store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							// )  // not need to pipe twice; take note remove these
							// .pipe(
							// take(1),
							map(info => {
								const { claim, team_leader } = info;
								const list: any = [];

								list.push({
									company_name: team_leader?.sp?.name ?? '',
									accreditation_number: team_leader?.sp?.accreditation_number ?? '',
									'Installation technician qualification number': team_leader?.qualification_number ?? '',
									'Installer/Technician name': team_leader?.full_name ?? '',
									contact_person: team_leader?.sp?.contact_person ?? '',
									contact_number: team_leader?.sp?.contact_primary ?? ''
								});

								return list;
							})
						);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Query Response',
							headingConfig: {
								size: 'medium',
								type: 'page'
							}
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							color: 'secondary',
							colouredHeading: new KVLHeading('You sent a query to the installer', 'secondary'),
							data$: 'query_history'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installer Details',
							data$: 'installer_details',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Send query to SP',
					nextNode: 'SendQueryToSP',
					color: 'secondary'
				},
				{
					text: 'resubmit for payment',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not resubmit for payment',
							serviceVariable: 'mulService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		JobSummary: {
			name: 'Job Summary',
			showTabs: true,
			initFormFields: (_bf: BigFormService) => {
				_bf.patchValues({ new_state: 341 });
			},
			serverCalls: {
				installationDetailValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile((ai: any) => !ai),
								take(1),
								map(res => res as any)
							)
						]).pipe(
							map(([itemTwo, info]) => {
								const voucher_code = itemTwo?.job_information?.vouchers?.voucher_code ?? 'No voucher number';
								const claim_type_no = itemTwo?.claim?.claim_type;
								const claimtype_description = info?.claim_types.find(el => el.id === claim_type_no).description;
								const product_type: string = itemTwo?.office_use?.device_details?.replacement_device?.type ?? 'No product information';

								const installationdetails = {
									'Full name ': `${itemTwo.claim?.applicant?.first_name} ` + `${itemTwo.claim?.applicant?.surname}`,
									'Customer Number': itemTwo?.claim?.loan_information.ContactNumber, //find out if this is for customer
									'Installation Address':
										`${itemTwo?.claim?.loan_information?.propertystreetaddress}` +
										' ' +
										`${itemTwo?.claim?.loan_information?.propertystreetaddress_2},` +
										' ' +
										`${itemTwo?.claim?.loan_information?.propertysuburb}`,
									'Voucher number': `${voucher_code}`,
									'Installation Type': `${claimtype_description}`,
									'Product Type': `${product_type}` //find out if this is correct, look above
								};
								return [installationdetails];
							})
						);
					}
				},
				installer_details: {
					errorMessage: 'Cannot get job info summary',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
							// store.select(getAllInfo).pipe(
							// 	skipWhile((ai: any) => !ai),
							// 	take(1),
							// 	map(res => res as any)
							// )
						]).pipe(
							take(1),
							map(([info]) => {
								const { team_leader } = info;
								const comp_name = team_leader?.sp?.name ?? '';
								const accreditation_number = team_leader?.sp?.accreditation_number ?? '';
								const technicianname = team_leader?.full_name ?? '';
								const technicianqual = team_leader?.qualification_number ?? '';

								const installerDetail_values = {
									'Company Name': comp_name,
									'Accreditation Number': accreditation_number,
									'Technician name': technicianname,
									'Technician Qualification Number': technicianqual,
									//contact_person: team_leader?.sp?.contact_person ?? '',
									'Company Contact Number': team_leader?.sp?.contact_primary ?? ''
								};
								return [installerDetail_values];
							})
						);
					}
				},
				queryResponse_info: {
					errorMessage: 'Could not get autopay fail reason',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile((ai: any) => !ai),
								take(1),
								map(res => res as any)
							)
						]).pipe(
							map(([itemTwo, info]) => {
								const { office_use } = itemTwo;
								const autopay_fail_reason = `${office_use?.claim_status}`; // All of this functionality still required

								const failreason = {
									//naming
									'Reason for failure ': `${autopay_fail_reason}`
								};
								return [failreason];
							})
						);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary',
							headingConfig: {
								size: 'medium',
								type: 'page'
							}
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							title: 'Auto payment has failed',

							headingConfig: {
								size: 'small',
								type: 'page',
								color: 'secondary'
							},
							// TODO: Implement like keyvalue list example above; maybe we'll have to envhance to show bulleted list as value
							//       will fix when data becomes available
							data$: 'queryResponse_info',
							color: 'secondary'
							//itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Details',
							data$: 'installationDetailValues', //check naming conventions
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installer Details',
							data$: 'installer_details',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Send query to SP',
					nextNode: 'SendQueryToSP',
					color: 'secondary'
				},
				{
					text: 'resubmit for payment',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not resubmit for payment',
							serviceVariable: 'mulService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		Photos: {
			initFormFields: (_bf: BigFormService) => {
				_bf.patchValues({ new_state: 341 });
			},
			...photoNode(),
			navs: [
				{
					text: 'Send query to SP',
					nextNode: 'SendQueryToSP',
					color: 'secondary'
				},
				{
					text: 'resubmit for payment',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not resubmit for payment',
							serviceVariable: 'mulService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		jobDetails: {
			showTabs: true,
			name: 'Job Details',
			component: {
				children: [
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Installation Details', 'secondary'),
							data$: 'installationDetailValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Decoder', 'secondary'),
							data$: 'decoderDetailValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Equipment', 'secondary'),
							data$: 'equipmentKeyValues',
							itemMargin: '0  0 15px 0'
						}
					}
				]
			},
			serverCalls: {
				installationDetailValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getSelectedItemOne).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([claim, itemTwo, teamleaders]) => {
								const { appointment } = itemTwo;
								const job_id = itemTwo?.id;
								let appointmentdate;
								appointmentdate = appointment[0]?.range_start;
								const { team_leader } = itemTwo;
								const technicianname = team_leader?.full_name ?? ''; //must find out if this is team lead name
								// const tleads = teamleaders as any;
								// let teamleadname;
								// for (let i = 0; i < tleads?.length; i++) {
								// 	if (!itemTwo.team_leader) {
								// 		teamleadname = 'Team leader not assigned yet';
								// 	} else if (tleads[i]?.id === itemTwo?.team_leader.id) {
								// 		//This does not display team lead name
								// 		teamleadname = tleads[i]?.full_name;
								// 	}
								// }

								const installationdetails = {
									'Id ': `${job_id}`,
									'Claim ID': claim?.id ?? '',
									Address:
										`${itemTwo?.claim?.loan_information?.propertystreetaddress}` +
										' ' +
										`${itemTwo?.claim?.loan_information?.propertystreetaddress_2},` +
										' ' +
										`${itemTwo?.claim?.loan_information?.propertysuburb}`,
									'Appointment Date': `${moment(appointmentdate).format(moment.HTML5_FMT.DATE)}`,
									'Appointment Time': `${moment(appointmentdate).format(moment.HTML5_FMT.TIME)}`,
									'Team Leader ': technicianname //find out if this points to correct data
								};
								return [installationdetails];
							})
						);
					}
				},
				decoderDetailValues: {
					errorMessage: 'Something went wrong with decoder details',
					directCall: (http, store, sq) => {
						return forkJoin([
							// store.select(getSelectedItemOne).pipe(
							// 	skipWhile(x => !x),
							// 	take(1)
							// ),
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
						]).pipe(
							map(([itemTwo]) => {
								const decoder_number = itemTwo?.office_use?.device_details?.replacement_device?.serial_number;
								const voucher_code = itemTwo?.job_information?.vouchers?.voucher_code ?? 'No voucher number';
								const additional_info = itemTwo?.office_use?.device_details?.replacement_device?.name;

								const installationdetails = {
									'Decoder Serial Number': `${decoder_number}`,
									'Voucher Code': `${voucher_code}`,
									'Additional Decoder Info': `${additional_info}` //need to make sure if this is the correct output
								};
								return [installationdetails];
							})
						);
					}
				},
				equipmentKeyValues: {
					errorMessage: 'Something went wrong with equiptment info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getSelectedItemOne).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
						]).pipe(
							map(([claim, itemTwo]) => {
								const dish_qr = 'Add Dish QR Functionality ; this is a comment';
								const bracket_code = 'Add Bracket Code Functionality ; this is a comment';
								const lnb_qr = 'Add LNB QR Code Functionality ; this is a comment';
								const additional_equipt = 'Add Additional Equipment Functionality ; this is a comment';
								const decoderdetails = {
									'Dish QR': `${dish_qr}`,
									'Bracket Code': `${bracket_code}`,
									'LNB QR Code': `${lnb_qr}`,
									'Additional Equipment': `${additional_equipt}`
								};
								return [decoderdetails]; //All of this functionality is still needed
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Send query to SP',
					nextNode: 'SendQueryToSP',
					color: 'secondary'
				},
				{
					text: 'resubmit for payment',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not resubmit for payment',
							serviceVariable: 'mulService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		SendQueryToSP: {
			checkValidityForFields: ['validation_query_installer'],
			showTabs: false,
			initFormFields: (_bf: any, item: any, instance: any, storeQuery: any, store: any) => {
				_bf.patchValues({ new_state: 333, current_state: 334 });
				_bf.addControl('validation_query_installer', new UntypedFormControl('', [Validators.required]));
				store
					.select(getCurrentUser)
					.pipe(
						skipWhile(x => !x),
						take(1),
						map((res: any) => res)
					)
					.subscribe(user => {
						_bf.patchValues({ author: user.user.id });
						_bf.patchValues({ author_name: user.user.full_name });
						console.log('DING', user);
					});
			},

			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Send a Query to the Installer',
							formControls: {
								0: {
									label: 'Enter your query',
									inputType: 'textarea',
									placeholder: '',
									// rows: 5,
									formControlName: 'validation_query_installer'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,

					linkType: 'portal',
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Could not update the job.'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		validation_query_installer: [
			(query, storeobj, _bf) => {
				let validation_query_responses = [];
				const date = new Date();
				console.log('AUTHOR', _bf.author);
				const author_id = _bf.author;
				const newquery = {
					query: query,
					author_id: _bf.author,
					author_name: _bf.author_name,
					currentState: _bf.current_state,
					timeStamp: convertDateTimeToTimeStamp(date.toDateString())
				};
				const stored_queries = storeobj['selectedContext']?.fullItemTwo?.job_information?.autopay_validation_queries;
				if (!stored_queries) {
					validation_query_responses.push(newquery);
				}
				if (Array.isArray(stored_queries)) {
					if (stored_queries.length === 0) {
						validation_query_responses.push(newquery);
					} else {
						validation_query_responses.push(...stored_queries, newquery);
					}
				}

				return validation_query_responses;
			},
			'job_information.autopay_validation_queries'
		]
	}
};
