<div [scriptLoader]="googleMapsSrc" (scriptCallback)="scriptLoaderCallBack($event)">
	<div class="picker-container">
		<div class="map-container" #containerDiv></div>
		<div class="search-overlay" [ngClass]="showSearch ? 'search-overlay--active' : 'search-overlay--inactive'">
			<div class="search-button" (click)="showSearch = !showSearch">
				<div *ngIf="!showSearch" class="comparison-icon pass"><flx-icon [type]="'right-arrow'" size="small"></flx-icon></div>
				<div *ngIf="showSearch" class="comparison-icon pass"><flx-icon [type]="'left-arrow'" size="small"></flx-icon></div>
			</div>
			<div class="search-container"><input #autocompleteElem style="width: 100%" type="text" placeholder="Search new address" /></div>
		</div>
	</div>
</div>
