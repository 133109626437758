<ng-container>
	<flx-tabs [tabs]="shellTabs" [currentStep$]="currentTab$" [externalPages]="true" (outputEventStream)="handleTab($event)">
		<div id="claim_information" *ngIf="(claimSummary$ | async) && (currentTab$ | async) === 'claim_information'">
			<flx-alert type="info" *ngIf="(claimSummary$ | async).ia_requested_reason" [message]="(claimSummary$ | async).ia_requested_reason"></flx-alert>
			<flx-heading size="small" color="secondary" align="center"> Claim Details </flx-heading>
			<flx-key-value-list [data$]="claimDetails$"></flx-key-value-list>

			<flx-heading size="small" align="center" color="secondary"> Address </flx-heading>
			<flx-key-value-list [data$]="claimAddress$"></flx-key-value-list>
		</div>

		<ng-container *ngFor="let job of jobInformation">
			<flx-nested-object-key-value-list
				id="{{ getViewJobID(job) }}"
				class="tab-content"
				*ngIf="(currentTab$ | async) === getViewJobID(job)"
				data-title="Basic Details"
				[objectInfo$]="jobObject$"
			></flx-nested-object-key-value-list>
		</ng-container>
	</flx-tabs>
</ng-container>
