import { Flow_0_0_2, getAllInfo, getData, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { JOB_INFO_NODE } from '../reusable/JOB_INFO_NODE';
import { PHOTO_NODE } from '../../../../sp_globals/configs/reusable/PHOTO_NODE';
import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin } from 'rxjs';
import { skipWhile, take, map, pluck } from 'rxjs/operators';
import { GetDecoderSerialNumberDisplay } from '../reusable/dataManipulations';
import { KVLHeading } from '@flexus/ui-elements';
import { getExtension } from '@flexus/utilities';

export const MUL_270: Flow_0_0_2 = {
	id: '270',
	name: 'request-proof-of-purchase',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: 'Request for Proof of Purchase',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'mulSpService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		files: {
			serviceVariable: 'mulSpService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	instructions: {
		editRoles: {
			0: 'Process job for payment'
		},
		viewRoles: {
			0: 'Wait for SP to start job'
		}
	},
	startNode: 'JobInformationNode',
	nodes: {
		JobInformationNode: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							color: 'secondary',
							data$: 'importantKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Details',
							data$: 'installerKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Contact Details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			serverCalls: {
				importantKeyValues: {
					errorMessage: 'Could not get POP request',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							map((res: any) => {
								debugger;
								let proof_request;
								const poprequests = res?.job_information?.purchase_proof_requests;
								if (poprequests) {
									if (Array.isArray(poprequests)) {
										proof_request = poprequests[poprequests.length - 1].query;
									} else {
										proof_request = poprequests.query;
									}
								} else {
									proof_request = 'No message from SUD';
								}
								const purchaseproofrequest = {
									'Message from SUD ': proof_request
								};
								return [purchaseproofrequest];
							})
						);
					}
				},
				installerKeyValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile(ai => !ai),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([itemTwo, info, teamleaders]) => {
								const sp = itemTwo?.sp;
								const sps = info.sps;
								let companyname;
								let accreditation_number;
								let qualification_number;
								for (let i = 0; i < sps.length; i++) {
									if (sp === sps[i]?.id) {
										companyname = sps[i]?.name;
									}
								}
								const tleads = teamleaders as any;
								let teamleadname;
								for (let i = 0; i < tleads?.length; i++) {
									if (!itemTwo.team_leader) {
										teamleadname = 'Team leader not assigned yet';
									} else if (tleads[i]?.id === itemTwo?.team_leader.id) {
										teamleadname = tleads[i]?.full_name;
									}
								}
								// const accreditation_number =
								if (!itemTwo.team_leader || !itemTwo.team_leader.sp) {
									accreditation_number = 'There is no accreditation number available';
									qualification_number = 'No qualification number for the technician';
								} else {
									accreditation_number = itemTwo?.team_leader.sp.accreditation_number;
									qualification_number = itemTwo?.team_leader.qualification_number;
								}

								const installerinfo = {
									'Company name ': companyname,
									'Accreditation number ': accreditation_number,
									'Installation technician qualification number': qualification_number,
									'Installer/Technician name ': teamleadname
								};
								return [installerinfo];
							})
						);
					}
				},
				customerContactKeyValues: {
					errorMessage: 'Something went wrong customer contact info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([itemTwo, teamleaders]) => {
								let claimtype = itemTwo?.claim?.type;
								let joblocation;
								let teamleadname;
								let address;
								let subscriber_no;
								let contactno;
								let altno;
								let e_mail;
								const tleads = teamleaders as any;
								const { identities, claim, team_leader } = itemTwo;
								const { loan_information } = claim;
								for (let i = 0; i < tleads?.length; i++) {
									if (tleads[i]?.id === itemTwo?.team_leader) {
										teamleadname = tleads[i]?.full_name;
									}
								}

								if (!loan_information) {
									joblocation = 'The customer misses data';
								}
								if (!loan_information?.claimlatitude || !loan_information?.claimlongitude) {
									joblocation = 'At least one coordinate not supplied!';
								}
								if (loan_information?.claimlatitude && loan_information?.claimlongitude) {
									const latitude = loan_information?.claimlatitude;
									const longitude = loan_information?.claimlongitude;
									joblocation = `${latitude}, ${longitude}`;
								}
								if (claimtype !== 'Pricelock Installation') {
									address = `${claim?.address}, ` + `${claim?.suburb}`;
									contactno = loan_information?.contactnumber;
									e_mail = loan_information?.email;
									altno = 'No alternative numbers';
									subscriber_no = identities?.policy_number;
								} else {
									// debugger;
									const contacts = [loan_information?.onSiteContact, loan_information?.onsitecontact_c, loan_information?.onsitecontact_w, loan_information?.onsitecontact];
									const alt = contacts.filter(x => x && x !== loan_information?.ContactNumber);
									address = `${loan_information?.propertystreetaddress_2}, ` + `${loan_information?.propertysuburb}`;
									altno = alt.join(', ') || loan_information?.ContactNumber;
									contactno = loan_information?.ContactNumber;
									e_mail = loan_information?.Email;
									subscriber_no = identities?.subscriber_number;
								}

								const customerContactData = {
									'Full name ': `${claim?.applicant?.first_name} ` + `${claim?.applicant?.surname}`,
									'Subscriber Number': `${subscriber_no}`,
									'Installation Address': `${address}`,
									'Email address ': `${e_mail}` ?? 'No email address returned',
									'Contact number ': `${contactno}` ?? 'No contact number supplied',
									'Alternate contact numbers': `${altno}` ?? 'No alternative numbers found',
									// 'GPS Coordinates ': itemTwo ? itemTwo?.location : '--'
									'GPS Coordinates ': joblocation
								};
								return [customerContactData];
							})
						);
					}
				},
				voucher: {
					serviceVariable: 'mulService',
					functionName: 'getVoucherInfo',
					responseSlice: 'payload',
					errorMessage: 'Could not get voucher from server!',
					followUpSuccessCalls: {
						decoderInfoKeyValues: {
							errorMessage: 'unable to add voucher to itemTwo',
							directCall: (http, store) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(itt => !itt),
										take(1),
										map(res => res as any)
									),
									store.select(getData).pipe(
										skipWhile(v => !v.voucher),
										pluck('voucher'),
										take(1)
									)
								]).pipe(
									map(([job, voucher]) => {
										const decoderDetails = GetDecoderSerialNumberDisplay(voucher?.decoder_number);
										return [
											{
												...decoderDetails,
												'Voucher Code': job?.job_information?.vouchers?.voucher_code ?? 'No additional decoder information',
												'Additional Decoder Info ': job?.job_information?.vouchers?.title ?? 'No additional decoder information'
											}
										];
									})
								);
							}
						}
					}
				}
			},
			navs: [
				{ text: 'do not have proof of purchase', color: 'secondary', nextNode: 'NoProofReplyNode' },
				{ text: 'upload proof of purchase', color: 'primary', nextNode: 'UploadProofNode' }
			]
		},
		JobPhotosNode: {
			...PHOTO_NODE,
			navs: [
				{ text: 'do not have proof of purchase', color: 'secondary', nextNode: 'NoProofReplyNode' },
				{ text: 'upload proof of purchase', color: 'primary', nextNode: 'UploadProofNode' }
			]
		},
		NoProofReplyNode: {
			checkValidityForFields: ['no_proof_reason'],
			showTabs: true,
			hideTabItem: false,
			initFormFields: bf => {
				bf.patchValues({ new_state: 271 });
				bf.addControl('no_proof_reason', new UntypedFormControl('', [Validators.required, Validators.minLength(13)]));
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Not able to upload Proof of Purchase',
							formControls: {
								0: {
									label: `Why don't you have a proof of purchase?`,
									inputType: 'textarea',
									rows: 5,
									formControlName: 'no_proof_reason',
									placeholder: 'Enter a reason of no less than 13 characters.'
								}
							},
							formLayout: 'stacked',
							containerWidth: '30vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			navs: [
				{
					optIntoValidation: true,
					text: 'submit',
					nextNode: 'SubmissionSucccess',
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'mulSpService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated'
						}
					}
				}
			]
		},
		UploadProofNode: {
			showTabs: false,
			hideTabItem: true,
			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ new_state: 271 });
				bf.bigForm.addControl('purpose', new UntypedFormControl('proof_of_purchase'));
				bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
			},
			checkValidityForFields: ['purpose', 'fileQueue'],
			component: {
				children: [
					{
						component: 'FLXFileUploadWithPreviewComponent',
						inputs: {
							purposes: [{ display: 'Proof of Purchase', value: 'proof of purchase' }],
							heading: 'Upload a receipt of any purchased equipment',
							multipleUploads: false,
							allowedFileTypes: ['pdf', 'image'],
							containerWidth: '30vw',
							maxWidth: '30vw'
						}
					}
				]
			},
			navs: [
				{
					text: 'Submit',
					optIntoValidation: true,
					nextNode: 'FileSubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not upload proof of purchase',
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: any = {
									data: fileQueue[0] ? fileQueue[0]?.file.base64 : '',
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								if (bf.bigForm.get('purpose')?.value === 'proof_of_purchase') {
									body.thumbnail = fileQueue[0]?.file.base64;
								}
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
							}
						}
					}
				}
			]
		},
		FileSubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'File Successfully uploaded' },
			navs: [
				{
					text: 'Continue',
					serverCalls: {
						response: {
							serviceVariable: 'mulSpService',
							functionName: 'updateJob',
							errorMessage: 'Could not update job!'
						}
					},
					nextNode: 'SubmissionSucccess'
				}
			]
		},
		SubmissionSucccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		no_proof_reason: [
			(npr, store, bf) => {
				let purchasequeries = [];
				let popqueriestemp = store?.['selectedContext']?.fullItemTwo?.job_information?.purchase_proof_requests;
				if (popqueriestemp) {
					if (Array.isArray(popqueriestemp)) {
						const lastquery = popqueriestemp[popqueriestemp.length - 1].query;
						const updatePoPRequestObj = { query: lastquery, queryreply: npr };
						popqueriestemp = popqueriestemp.slice(0, popqueriestemp.length - 1);
						purchasequeries = [...popqueriestemp, updatePoPRequestObj];
					} else {
						purchasequeries = [
							{
								...popqueriestemp,
								queryreply: npr
							}
						];
					}
				}
				return purchasequeries;
			},
			'job_information.purchase_proof_requests'
		]
	}
};
