import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { SIL_71 } from './SIL_71';
import { map, skipWhile } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const SIL_74: Flow_0_0_2 = {
	id: '74',
	name: 'claim_repudiated_send_letter',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map(itemOne => {
					if (itemOne) {
						return `Partial Repudiation' : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Partial Repudiation';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance =>
		setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},

	instructions: {
		editRoles: {
			0: 'Partially repudiated'
		},
		viewRoles: {
			0: 'Partially repudiated'
		}
	},

	startNode: 'viewRepudiationLetter',
	nodes: {
		// 73 nodes are included in 71 to allow seamless movement.
		// state 73 need to be able to edit the repudiation letter so it needs access to 71
		// if you spead 73 from 71 then you cant spread 71 back into 73 as it creates circular dependancies
		// the quick solution is include all 73 nodes in 71 and use start node in 73 to point to a node in 71
		...SIL_71.nodes
	},

	bigFormToStoreMapper: {}
};
