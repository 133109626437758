import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { skipWhile, map, take } from 'rxjs/operators';
import { SIL_97 } from './SIL_97';

export const SIL_90: Flow_0_0_2 = {
	id: '90',
	name: 'assessor_to_visit_site',
	itemType: 'flow',
	fetchLevel1And2: SIL_97.fetchLevel1And2,
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					if (itemOne) {
						let title = 'Assessor To Visit Site';
						if (itemOne?.loan_information?.mavenclaimnumber) {
							title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
						}
						if (itemOne.applicant?.surname) {
							title += ` - ${itemOne.applicant?.surname}`;
						}

						return title;
					} else {
						return 'Assessor to Visit Site';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		...SIL_97.footer
	},
	instructions: {
		editRoles: {
			0: 'Assessor to visit site'
		},
		viewRoles: {
			0: 'Assessor to visit site'
		}
	},
	actionPanel: SIL_97.actionPanel,
	serverCalls: {
		...SIL_97.serverCalls
	},
	startNode: 'AssessorOverview',
	nodes: {
		...SIL_97.nodes
	},
	bigFormToStoreMapper: {
		...SIL_97.bigFormToStoreMapper
	}
};
