<flx-button display="block" class="filter-inputs button" color="primary" (click)="open()">Add Reminder </flx-button>
<flx-modal type="info" [displayHeading]="false" [navButtons]="modalButtons" [backgroundClose]="false">
	<flx-heading size="large" color="primary" align="center">
		<span class="add-rem-heading">{{ modalHeading }}</span>
	</flx-heading>
	<form [formGroup]="reminderForm" class="add-reminder-form">
		<div class="message-details">
			<div>
				<flx-label>Title of Reminder</flx-label>
				<flx-input-text formControlName="title" [maxLength]="30"></flx-input-text>
			</div>
			<div>
				<flx-label>Channel</flx-label>
				<flx-dropdown
					formControlName="type"
					[searchEnabled]="false"
					placeHolder="Select type"
					[itemsOption]="dropdownOptions"
					[displayOptions]="{ displayKey: 'display', valueKey: 'key' }"
				></flx-dropdown>
			</div>
			<div>
				<flx-label>Date</flx-label>
				<input class="date-picker-input" type="text" formControlName="date" [flxDatepicker]="myDatePicker" [min]="minDate" [max]="maxDate" placeholder="Select Date" readonly />
				<flx-datepicker #myDatePicker></flx-datepicker>
			</div>
			<div>
				<flx-label>Time</flx-label>
				<flx-time-picker #myTimePicker formControlName="time" [minuteInterval]="minuteInterval" [minHour]="minHour" [maxHour]="maxHour" [disabledHours]="disabledHours">
				</flx-time-picker>
			</div>
		</div>
		<br />
		<div>
			<flx-label>Reminder Message</flx-label>
			<flx-textarea formControlName="message" [maxLength]="255"></flx-textarea>
		</div>

		<div>
			<flx-heading type="page" size="small">{{ linktoclaimHeading }}</flx-heading>
			<form [formGroup]="bf.bigForm">
				<flx-search-bar [minLength]="minLength" formControlName="search" (clear)="clearSearch()" (doSearch)="doSearch()" [showIncludeClosedClaims]="false"></flx-search-bar>
			</form>
		</div>
	</form>
	<ng-container *ngIf="error.triggered">
		<div>{{ error.message }}</div>
	</ng-container>
</flx-modal>
