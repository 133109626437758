<div class="file-list" *ngIf="closedPreview" [ngClass]="display">
	<div class="container" [ngClass]="locationClass">
		<ng-container *ngFor="let file of files | filterFiles: filterValue | paginate: { itemsPerPage: pageSize, currentPage: currentPage, id: paginationId }">
			<!--Show Image Thumbnail -->

			<div class="images-display" *ngIf="getMimeType(file.filename) !== 'pdf'">
				<div
					class="img-thumb"
					(click)="previewFile(file)"
					[ngStyle]="{
						'background-image': 'url(data:image/png;base64,' + file.thumbnail + ')',
						width: thumbSize,
						height: thumbSize
					}"
				>
					<span *ngIf="!file.thumbnail" class="no-image"> <flx-icon hover="none" [type]="'no-image'" instruction="No Thumbnail Available"></flx-icon> </span>
					<input *ngIf="imagesSelectable" class="form-control" type="checkbox" (change)="selectFile($event, file)" />
					<span class="thumb-meta" *ngIf="Loading != true || previewedFile !== file; else loader">
						<span *ngIf="showMeta">
							{{ file?.created | date }} <br />
							{{ file?.description || 'No Description' }} <span>Purpose: {{ file.purpose }} </span>
						</span>
					</span>

					<ng-template #loader>LOADING </ng-template>
				</div>
			</div>

			<!-- Show Document Thumbnail -->
			<div *ngIf="getMimeType(file.filename) === 'pdf'" class="file-icon" [class.selected]="previewedFile === file" (click)="previewFile(file)">
				<input *ngIf="documentsSelectable" class="form-control" type="checkbox" (change)="selectFile($event, file)" />
				<div class="documents-file">
					<flx-icon class="file-icon-doc" layout="none" use="center" [type]="'documents-files'"></flx-icon>
					{{ file.filename }} <br />
					<small>{{ humanizeDateFromServer(file.created) }}</small>
				</div>
			</div>
		</ng-container>

		<!-- PAGINATION -->
	</div>
</div>
<div class="file-pagination" *ngIf="closedPreview">
	<pagination-controls class="flx-pagination" (pageChange)="currentPage = $event" [id]="paginationId" [autoHide]="true" previousLabel="←" nextLabel="→"> </pagination-controls>
</div>
