import { SearchFunc_0_0_2 } from '@flexus/core';
import { addObjectProperty, deleteObjectProperty } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';

export function setToArray<T>(s: Set<T>): Array<T> {
	const values = s.values();
	const arrOut: Array<T> = [];
	for (let entry = values.next(); entry.done === false; entry = values.next()) {
		arrOut.push(entry.value);
	}
	return arrOut;
}

export function setToObject<T>(s: Set<T>, fn: (t: T) => string): { [k: string]: T } {
	const values = s.values();
	const objOut: { [key: string]: T } = {};
	for (let entry = values.next(); entry.done === false; entry = values.next()) {
		objOut[fn(entry.value)] = entry.value;
	}
	return objOut;
}

export function sortObject<T extends object>(o: T): T {
	let oOut = {};
	Object.keys(o)
		.sort((x, y) => x.localeCompare(y))
		.forEach(value => {
			oOut[value] = o[value];
		});
	return <T>oOut;
}

const bucketStateFactory = (arrStates: Array<string | number>) => claimWithJobs => {
	if (arrStates.findIndex(x => `${x}` === `${claimWithJobs.state}`) !== -1) {
		return true;
	}
	const { jobs = [] } = claimWithJobs;
	for (const currentJob of jobs) {
		if (arrStates.findIndex(x => `${x}` === `${currentJob.state}`) !== -1) {
			return true;
		}
	}
	return false;
};

export const BuiSearch: SearchFunc_0_0_2 = (storeObj, manifestObj) => {
	const states = storeObj?.allInfo?.states?.reduce((acc, state) => ({ ...acc, [state.id]: state }), {}) ?? {};
	const claims = manifestObj.viewData.default || [];
	const sps = storeObj?.allInfo?.sps?.reduce((acc, sp) => ({ ...acc, [sp.id]: sp }), {});
	const skills = storeObj?.allInfo?.skills?.reduce((acc, skill) => ({ ...acc, [skill.id]: skill }), {});

	const claimList = [];
	const joblist = [];

	for (const claim of claims) {
		const { jobs = [], ...claimRest } = <any>claim;
		claimList.push(claimRest);
		joblist.push(...jobs);
	}

	const rawStateIds = new Set();
	const rawRegion = new Set();
	const rawSpIds = new Set();
	const rawSkillIds = new Set();

	for (const claim of claimList) {
		rawStateIds.add(claim?.state);
	}
	for (const job of joblist) {
		rawRegion.add(job.suburb);
		rawStateIds.add(job.state);
		rawSpIds.add(job.sp);
		rawSkillIds.add(job.skill);
	}
	// now those raw lists are set, so now to create the context's for them
	const filters = {};
	let stateContext = setToObject(rawStateIds, (state: any) => `${states && states[state] ? states[state]?.description : 'Not Yet Set'}  : ${state ? state : ''}`);
	let regionContext = setToObject(rawRegion, (region: any) => `${region}`.toLocaleLowerCase()?.replace(/^./gi, value => value.toLocaleUpperCase()));
	let spContext = setToObject(rawSpIds, (id: any) => (sps && id && sps[id] ? sps[id]?.name : 'No SP Assigned'));
	let skillsContext = setToObject(rawSkillIds, (skillId: any) => (skills && skillId && skills[skillId] && skills[skillId]?.name ? skills[skillId]?.name : 'No Skill Set yet'));
	// now to sort them
	stateContext = sortObject(stateContext);
	regionContext = sortObject(regionContext);
	spContext = sortObject(spContext);
	skillsContext = sortObject(skillsContext);
	return {
		searchEndpoint: environment.api_url + `v1/claim_action/search_claims/`,
		filters: {
			'Job Value': {
				type: 'numericRange',
				configuration: {
					min: 0,
					prompt: 'Enter values to filter claims',
					type: 'numericRange'
				},
				paths: [],
				filterFactory:
					([min, max]) =>
					claimWithJobs => {
						const remainingJobs = [];
						const { jobs = [] } = claimWithJobs;
						for (const currentJob of jobs) {
							const currentVal = parseFloat(currentJob.claim_value);
							if (isNaN(currentVal) === false && min <= currentVal && max >= currentVal) {
								remainingJobs.push(currentJob);
							}
						}
						if (remainingJobs.length > 0) {
							deleteObjectProperty('jobs', claimWithJobs);
							claimWithJobs = addObjectProperty('jobs', remainingJobs, claimWithJobs);
							// if anything remains, erase the current and replace with new set
							return true;
						}
						return false;
					}
			},
			State: {
				type: 'select',
				allInfoName: 'states',
				configuration: {
					context: sortObject(setToObject(rawStateIds, (state: any) => `${states[state] ? states[state]?.description : 'Not Yet Set'}  : ${state ? state : ''}`)),
					prompt: ' Select state',
					type: 'select'
				},
				paths: [],
				filterFactory:
					([stateValue]) =>
					claimWithJobs => {
						if (`${claimWithJobs.state}` === `${stateValue}`) {
							return true;
						}
						const remainingJobs = [];
						const { jobs = [] } = claimWithJobs;
						for (const currentJob of jobs) {
							if (`${currentJob.state}` === `${stateValue}`) {
								remainingJobs.push(currentJob);
							}
						}
						if (remainingJobs.length > 0) {
							deleteObjectProperty('jobs', claimWithJobs);
							claimWithJobs = addObjectProperty('jobs', remainingJobs, claimWithJobs);
							// if anything remains, erase the current and replace with new set
							return true;
						}
						return false;
					}
			},
			'Required Skill': {
				type: 'select',
				allInfoName: 'skills',
				configuration: {
					context: sortObject(setToObject(rawSkillIds, (skillId: any) => (skills && skillId && skills[skillId] ? skills[skillId]?.name : 'No Skill Set yet'))),
					prompt: 'Select skill',
					type: 'select'
				},
				paths: [],
				filterFactory:
					([skillId]) =>
					claimWithJobs => {
						const remainingJobs = [];
						const { jobs = [] } = claimWithJobs;
						for (const currentJob of jobs) {
							if (`${currentJob.skill}` === `${skillId}`) {
								remainingJobs.push(currentJob);
							}
						}
						if (remainingJobs.length > 0) {
							deleteObjectProperty('jobs', claimWithJobs);
							claimWithJobs = addObjectProperty('jobs', remainingJobs, claimWithJobs);
							// if anything remains, erase the current and replace with new set
							return true;
						}
						return false;
					}
			},
			Suburb: {
				type: 'select',
				configuration: {
					context: sortObject(setToObject(rawRegion, (region: any) => `${region}`.toLocaleLowerCase()?.replace(/^./gi, value => value.toLocaleUpperCase()))),
					prompt: 'Select suburb',
					type: 'select'
				},
				paths: [],
				filterFactory:
					([regionStr]) =>
					claimWithJobs => {
						const remainingJobs = [];
						const { jobs = [] } = claimWithJobs;
						for (const currentJob of jobs) {
							if (`${currentJob.suburb}`.toLocaleLowerCase() === `${regionStr}`.toLocaleLowerCase()) {
								remainingJobs.push(currentJob);
							}
						}
						if (remainingJobs.length > 0) {
							deleteObjectProperty('jobs', claimWithJobs);
							claimWithJobs = addObjectProperty('jobs', remainingJobs, claimWithJobs);
							// if anything remains, erase the current and replace with new set
							return true;
						}
						return false;
					}
			},
			'Service Provider': {
				type: 'select',
				allInfoName: 'sps',
				configuration: {
					context: sortObject(setToObject(rawSpIds, (id: any) => (sps && id && sps[id] ? sps[id]?.name : 'No SP Assigned'))),
					prompt: 'Select SP',
					type: 'select'
				},
				filterFactory:
					([spId]) =>
					claimWithJobs => {
						const remainingJobs = [];
						const { jobs = [] } = claimWithJobs;
						for (const currentJob of jobs) {
							if (`${currentJob.sp}` === `${spId}`) {
								remainingJobs.push(currentJob);
							}
						}
						if (remainingJobs.length > 0) {
							deleteObjectProperty('jobs', claimWithJobs);
							claimWithJobs = addObjectProperty('jobs', remainingJobs, claimWithJobs);
							// if anything remains, erase the current and replace with new set
							return true;
						}
						return false;
					},
				paths: []
			}
		},
		buckets: {
			mapper: {
				// 'Before Sunset': bucketStateFactory([]),
				// Finalize: bucketStateFactory([11, 15, 9]),
				'After Hours': bucketStateFactory([197, 198]),
				Izinga: bucketStateFactory([34]),
				'Managed On Maven': bucketStateFactory([76, 75]),
				// 'No Work': bucketStateFactory([77, 11, 65, 68, 45, 28]),
				Payments: bucketStateFactory([58, 27, 39, 70, 48, 49, 50, 60, 51, 30, 78, 38, 40, 52]),
				'Servicing: Appoint SP': bucketStateFactory([16, 17, 8, 20, 64, 63, 81, 6, 33, 77]),
				'Servicing: Call Customer For CIL': bucketStateFactory([13, 93, 56, 66, 72, 59, 107]),
				'Servicing: Call for Invoice': bucketStateFactory([57]),
				'Servicing: Call for rejection': bucketStateFactory([19, 154, 25, 94, 71, 74, 173]),
				'Servicing: Call Manufacturer': bucketStateFactory([67]),
				// 'Servicing: Call to do rating': bucketStateFactory([1, 2, 3, 4, 7, 10, 13, 31, 44, 12, 14]),
				'Servicing: Call to do rating': bucketStateFactory([9, 11, 15, 31, 44, 65, 68, 12, 14]),
				'Servicing: Review Quote/Report': bucketStateFactory([83, 84, 108, 41, 97, 104, 103, 102, 80, 73, 18]),
				// 'Servicing: State Change-review': bucketStateFactory([102]),
				Stansure: bucketStateFactory([79]),
				// 'Urgent Actions': bucketStateFactory([]),
				'Work for HIA': bucketStateFactory([105, 88, 89, 95, 90, 106, 96, 91, 99, 100, 98, 101, 53, 54]),
				'Work for SP': bucketStateFactory([21, 82, 22, 23, 42, 62, 162, 161, 35, 32, 37, 24, 36, 61, 85, 87, 156, 43, 26, 46, 47, 69])
			}
		}
	};
};
