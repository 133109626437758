<form [formGroup]="bf.bigForm">
	<ng-container *ngIf="isNonVoucheredDecoderFailure">
		<div class="no-valid-decoder">
			<div><flx-key-value-list heading="No Valid Voucher Found" [data$]="decoderKeyValues$" [itemMargin]="'-9px 0 35px 0'"></flx-key-value-list></div>
			<ng-template #loader> <flx-loader-component></flx-loader-component> </ng-template>
			<div class="image-and-input" *ngIf="verificationImageSource$ | async as file; else loader">
				<div class="image">
					<flx-file-browser
						location="custom"
						[thumbSize]="'300px'"
						[showFilters]="false"
						[showMeta]="false"
						paginationId="reports"
						[files$]="verificationImageSource$"
					></flx-file-browser>
				</div>
				<div style="margin-left: 10px; display: grid; grid-template-rows: auto auto; height: 75px">
					<flx-label>Enter a decoder serial number (Ser No.) to create a new voucher</flx-label
					><flx-input-text width="20vw" placeholder="Decoder Serial Number" formControlName="nonvouchered_decoder_fix"></flx-input-text>
				</div>
			</div>
			<div class="center-input">
				<flx-button color="primary" type="button" [disabled]="bf.bigForm.get('nonvouchered_decoder_fix')?.invalid" (click)="setSummaryNode()">create voucher</flx-button>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="isNoVoucherFailure">
		<div class="no-valid-decoder">
			<div><flx-key-value-list heading="No Valid Decoder Found" [data$]="decoderKeyValues$" [itemMargin]="'-9px 0 35px 0'"></flx-key-value-list></div>
			<ng-template #loader> <flx-loader-component></flx-loader-component> </ng-template>
			<div class="image-and-input" *ngIf="verificationImageSource$ | async as file; else loader">
				<div class="image">
					<flx-file-browser
						location="custom"
						[showFilters]="false"
						[thumbSize]="'300px'"
						[showMeta]="false"
						paginationId="reports"
						[files$]="verificationImageSource$"
					></flx-file-browser>
				</div>
				<div style="margin-left: 10px; display: grid; grid-template-rows: auto auto; height: 75px">
					<flx-label>Enter a decoder serial number (Ser No.) to create a new voucher</flx-label
					><flx-input-text width="20vw" placeholder="Decoder Serial Number" formControlName="no_valid_decoder_fix"></flx-input-text>
				</div>
			</div>
			<div class="center-input">
				<flx-button color="primary" type="button" [disabled]="bf.bigForm.get('no_valid_decoder_fix')?.invalid" (click)="setSummaryNode()">Check decoder validity</flx-button>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="isMultipleValidDecoderFailure">
		<div class="multiple-vouchers-heading">
			<flx-heading-with-instructions title="Manage Vouchers" color="primary" [instructions]="instructions"></flx-heading-with-instructions>
		</div>
		<div class="image-container">
			<div class="image-for-multiple" *ngIf="verificationImageSource$ | async as file; else loader">
				<div class="image">
					<flx-file-browser
						location="custom"
						[showMeta]="false"
						[thumbSize]="'300px'"
						[showFilters]="false"
						paginationId="reports"
						[files$]="verificationImageSource$"
					></flx-file-browser>
				</div>
			</div>
		</div>

		<div class="multiple-decoders">
			<div *ngIf="installedDecoders$ | async as decoders" class="verification-items-list">
				<flx-select-list *ngIf="installedDecoders$" [options$]="formattedDecoderValues$" formControlName="select_decoder_number" maxWidth="333px"></flx-select-list>
			</div>
			<div style="text-align: center">
				<ng-template #loader> <flx-loader-component></flx-loader-component> </ng-template>
			</div>
		</div>
		<div class="failure-action-button">
			<flx-button color="primary" type="button" (click)="selectDecoderNotListed()">decoder not listed</flx-button>
			<flx-button color="primary" type="button" [disabled]="bf.bigForm.get('select_decoder_number')?.invalid" (click)="setSummaryNode()">MAKE primary</flx-button>
		</div>
	</ng-container>
</form>
