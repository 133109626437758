import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { A11yModule } from '@angular/cdk/a11y';

import {
	FLXDialogContainerComponent,
	Dialog,
	DIALOG_SCROLL_STRATEGY_PROVIDER,
	DialogCloseDirective,
	DialogContentDirective,
	DialogTitleDirective,
	DialogActionsDirective
} from '.';

@NgModule({
	imports: [CommonModule, OverlayModule, PortalModule, A11yModule],
	exports: [FLXDialogContainerComponent, DialogCloseDirective, DialogTitleDirective, DialogContentDirective, DialogActionsDirective],
	declarations: [FLXDialogContainerComponent, DialogCloseDirective, DialogTitleDirective, DialogContentDirective, DialogActionsDirective],
	providers: [Dialog, DIALOG_SCROLL_STRATEGY_PROVIDER]
})
export class FLXDialogModule {}
