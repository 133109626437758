import { OnInit, OnDestroy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TabsData } from '@flexus/ui-elements';
import { BigFormService, ManifestController, ModalService } from '@flexus/core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'flx-sp-edit-tl-report',
	templateUrl: './sp-edit-tl-report.component.html',
	styleUrls: ['./sp-edit-tl-report.component.scss']
})
export class FLXSpEditTLReportComponent implements OnInit, OnDestroy {
	@Input() jobSummary$: Observable<any>;
	@Input() selectedItemTwo$: Observable<any>;
	@Input() continueFunc: Function;
	@Input() openActionPanel: Function;

	@Input() location = 'action-panel';

	tabs: TabsData[] = [
		{ display: 'Overview', show: true, targetId: 'overview' },
		{ display: 'On-site Report', show: true, targetId: 'onsitereport' },
		{ display: 'Diagnosis', show: true, targetId: 'diagnosis' }
		// { display: 'Recommend', show: true, targetId: 'recommend' },
		// { display: 'On-site report', show: true, targetId: 'onsitereport' }
	];
	currentTab$ = of('overview');

	flatFormInput(reportarea) {
		let thislabel;
		switch (reportarea) {
			case 'overview':
				thislabel = 'Review and edit what caused the loss';
				break;
			case 'description':
				thislabel = 'Review and edit the description of loss';
				break;
			case 'diagnosis':
				thislabel = 'Review and edit the diagnosis';
				break;
			default:
				thislabel = 'No label provided';
		}
		const formControls = {
			0: {
				formControlName: reportarea,
				inputType: 'textarea',
				label: thislabel
			}
		};
		return formControls;
	}

	constructor(public bf: BigFormService, private modalService: ModalService, public _store: Store<any>, private controller: ManifestController<any>) {}

	ngOnInit() {}

	handleTab(tabInfo) {
		this.currentTab$ = of(tabInfo.target);
	}

	ngOnDestroy() {}
}
