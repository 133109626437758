import { LocationPickerConfig } from './agmish/interfaces';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WindowRef, DocumentRef } from './agmish';
import { Injectable, Renderer2 } from '@angular/core';
import { map, tap } from 'rxjs/operators';

// so there are a few ways for this to work
/**
 * config ?
 * can be set and will be protected , can not be changed multiple times.
 *
 */
@Injectable()
export class Lps {
	private _config: LocationPickerConfig;
	private _defaultConfig: LocationPickerConfig = {
		scriptId: 'location-picker-script',
		center: {
			lat: -120,
			lng: 20
		},
		zoom: 8,
		url: 'https://maps.googleapis.com/maps/api/js' // will be replaced by api request
	};

	get config() {
		return this._config || this._defaultConfig;
	}

	set config(c) {
		if (this._config) {
			return;
		} else {
			this._config = { ...this._defaultConfig, ...c };
		}
	}

	private buildParams(key: string): string {
		let otherParams = {};
		otherParams = { libraries: 'places' };
		const oParms = Object.keys(otherParams)
			.map(objKey => `${objKey}=${otherParams[objKey]}`)
			?.join('&');
		return `?key=${key}${oParms ? '&' + oParms : ''}`;
	}

	constructor(private _http: HttpClient) {}

	// the job of this is to make the actual get request and return the result
	makeRequest(apiKey: string) {
		// const params = ;
		// so create and build request and body here,for now just api key will be
		return this._http.jsonp<string>(`${this.config.url}${this.buildParams(apiKey)}`, 'callback');
	}
}
