import { Provider, Injectable } from '@angular/core';

@Injectable()
export class WindowRef {
	get nativeWindow() {
		return window;
	}
}

@Injectable()
export class DocumentRef {
	get nativeDocument() {
		return document;
	}
}

export const BROWSER_GLOBALS_PROVIDERS: Provider[] = [WindowRef, DocumentRef];
