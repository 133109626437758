import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXHeadingsModule } from '@flexus/ui-elements';
import { FLXHeadingWithInstructionsComponent } from './heading-with-instructions.component';

@NgModule({
	imports: [CommonModule, FLXHeadingsModule],
	declarations: [FLXHeadingWithInstructionsComponent],
	exports: [FLXHeadingWithInstructionsComponent]
})
export class FLXHeadingWithInstructionsModule {}
