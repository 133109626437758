import { Flow_0_0_2 } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

const today = new Date();
const tomorrow = new Date(today);
const isAfterOne = today.getHours() > 12;
tomorrow.setDate(tomorrow.getDate() + 1);

export const MulSPInvoicing: Flow_0_0_2 = {
	addHeaderActions: true,
	id: 'invoicing_payments',
	name: 'invoicing and payments',

	onStateInit: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['what-matters']),
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	header: {
		title: 'Installer payments',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'InstallerPaymentList',

	nodes: {
		InstallerPaymentList: {
			// name: 'Paid',
			showTabs: false,
			component: 'InstallerPaymentListComponent'
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			inputs: {},
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
