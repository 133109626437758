import { Component, OnInit, OnDestroy, Input, AfterViewInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getFullItemOne, MakeServerCall } from '@flexus/core';
import { take, filter, map, skipWhile } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'flx-ap-time-stamps',
	templateUrl: './time-stamps.component.html',
	styleUrls: ['./time-stamps.component.scss']
})
export class FLXTimeStampsComponent implements OnInit, OnDestroy, AfterViewInit {
	claimId: number;
	timeStampsObservable: Observable<any>;
	viewConfig: {
		showHeading: boolean;
		viewWidth: string;
		viewAlignment: string;
	};

	@Input() set timeStamps$(timeStamps: Observable<any>) {
		this.timeStampsObservable = timeStamps.pipe(
			filter(x => !!x),
			map(values => {
				if (values && Array.isArray(values)) {
					values.forEach((element, index, array) => {
						if (element.length === 0) {
							array.splice(index, 1);
						}
					});
				}
				return values;
			})
		);
	}
	// get timeStamps$() {
	//   return this.timeStampsObservable;
	// }

	_config: { DisplayType: string };
	@Input('config')
	get config(): { DisplayType: string } {
		if (!this._config) {
			this.config = { DisplayType: 'action-panel' };
		}

		return this._config;
	}
	set config(value: { DisplayType: string }) {
		this._config = value;
		this.setViewSettings();
	}

	constructor(private _store: Store<any>, @Inject('environment') private _environment: any) {}

	ngOnInit() {}

	setViewSettings() {
		switch (this.config.DisplayType) {
			case 'full-screen':
				this.viewConfig = {
					showHeading: false,
					viewWidth: '50vw',
					viewAlignment: 'left'
				};
				break;
			default:
				this.viewConfig = {
					showHeading: true,
					viewWidth: '100%',
					viewAlignment: 'left'
				};
				break;
		}
	}

	ngAfterViewInit(): void {
		this._store
			.select(getFullItemOne)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe(claim => {
				this._store.dispatch(
					new MakeServerCall({
						dataKey: 'timeStamps',
						serviceVariable: 'silService',
						functionName: '',
						errorMessage: 'No time stamps',
						directCall: (http, store) => {
							return http.post(`${this._environment.api_url}v1/claim_action/get_time_logs/`, { [`claim_id`]: claim?.id }).pipe(
								filter(x => !!x),
								take(1),
								map((res: any) => {
									const jobs = res?.payload['jobs'];
									const claimStates = res?.payload['claim'];
									const temArr = [];
									if (res && jobs) {
										jobs.forEach(element => {
											if (element.length > 0) {
												element.forEach(inner => {
													element.title = inner.job_skill;
													element.job_id = inner.job_id;
													inner.dateTime = inner['mytimestamp'];
												});
												temArr.push(element);
											}
										});
									}
									return { jobs: temArr, claimStates: claimStates };
								})
							);
						}
					})
				);
			});
	}

	ngOnDestroy(): void {
		this._store.dispatch(
			new MakeServerCall({
				dataKey: 'timeStamps',
				serviceVariable: 'silService',
				functionName: '',
				errorMessage: 'No time stamps',
				directCall: (http, store) => {
					return of({});
				}
			})
		);
	}
}
