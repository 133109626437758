import { Flow_0_0_2, getFullItemTwo, getAllInfo, getSubmissionData, getAppointmentTypes, getSkills, MakeServerCall, SetNextNode } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { forkJoin, of, EMPTY } from 'rxjs';
import { skipWhile, take, map, switchMap } from 'rxjs/operators';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { KVLHeading } from '@flexus/ui-elements';
import moment from 'moment';

export const MUL_62: Flow_0_0_2 = {
	id: '62',
	name: 'work_paused',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: 'Job Paused - Needs Appointment',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Set new appointment'
		},
		viewRoles: {
			0: 'Job Paused'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'mulSpService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		}
	},
	startNode: 'Decision',
	nodes: {
		Decision: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				pausedreason: (navs, store, modal) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1),
							map(res => {
								switch (true) {
									case res?.job_information?.paused_reason !== '':
										{
											store.dispatch(
												new MakeServerCall({
													errorMessage: 'No paused reason!',
													directCall: (http, stor, sq, bf, controller) => {
														controller.dispatch(new SetNextNode('PausedReasonNotification'));
														return of({});
													}
												})
											);
										}
										break;
								}
								console.log('62', res);
							})
						)
						.subscribe();
				}
			},
			navs: [{ text: 'Success', nextNode: 'PausedReasonNotification' }]
		},
		PausedReasonNotification: {
			serverCalls: {
				importantInfoKeyValues: {
					errorMessage: '!',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const importantinfo = {
										'Paused reason ': itemTwo?.job_information?.paused_reason ?? 'No reason was provided for pausing the job.'
									};
									return [importantinfo];
								})
							);
					}
				},
				installerKeyValues: {
					errorMessage: 'Something went wrong with installer info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile(ai => !ai),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([itemTwo, info, teamleaders]) => {
								const sp = itemTwo ? itemTwo?.sp : '--';
								const sps = info.sps;
								let companyname;
								for (let i = 0; i < sps.length; i++) {
									if (sp === sps[i]?.id) {
										companyname = sps[i]?.name;
									}
								}
								const tleads = teamleaders as any;
								let teamleadname;
								for (let i = 0; i < tleads.length; i++) {
									if (tleads[i]?.id === itemTwo?.team_leader.id) {
										teamleadname = tleads[i]?.full_name;
									}
								}
								const accreditation_number = itemTwo?.team_leader?.sp?.accreditation_number ?? '--';
								const qualification_number = itemTwo?.team_leader?.qualification_number ?? '--';

								const installerinfo = {
									'Company name ': companyname,
									'Accreditation number ': accreditation_number,
									'Installation technician qualification number': qualification_number,
									'Installer/Technician name ': teamleadname
								};
								return [installerinfo];
							})
						);
					}
				},
				customerContactKeyValues: {
					errorMessage: 'Something went wrong with customer contact info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([itemTwo, teamleaders]) => {
								const tleads = teamleaders as any;
								let teamleadname;
								let joblocation;
								for (let i = 0; i < tleads.length; i++) {
									if (tleads[i]?.id === itemTwo?.team_leader) {
										teamleadname = tleads[i]?.full_name;
									}
								}

								if (!itemTwo?.claim?.loan_information) {
									joblocation = 'The customer misses data';
								}
								if (!itemTwo?.claim?.loan_information?.claimlatitude || !itemTwo?.claim?.loan_information?.claimlongitude) {
									joblocation = 'At least one coordinate was not supplied!';
								}
								if (itemTwo?.claim?.loan_information?.claimlatitude && itemTwo?.claim?.loan_information?.claimlongitude) {
									const latitude = itemTwo?.claim?.loan_information?.claimlatitude;
									const longitude = itemTwo?.claim?.loan_information?.claimlongitude;
									joblocation = `${latitude}, ${longitude}`;
								}

								const customerContactData = {
									'Full name ': `${itemTwo?.claim?.applicant?.first_name} ` + `${itemTwo?.claim?.applicant?.surname}`,
									'Installation Address': `${itemTwo?.claim?.loan_information?.propertystreetaddress},` + ' ' + `${itemTwo?.claim?.loan_information?.propertysuburb}`,
									'Email address ': itemTwo?.claim?.loan_information?.Email ?? 'No email address returned',
									'Contact number ': `${itemTwo?.claim?.loan_information?.ContactNumber}`,
									'GPS Coordinates ': joblocation
								};
								return [customerContactData];
							})
						);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary'
						}
					},

					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							data$: 'importantInfoKeyValues',
							color: 'secondary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Details',
							data$: 'installerKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Contact Details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},

			navs: [{ text: 'Set Appointment', nextNode: 'SetAppointment', color: 'primary' }]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date(),
				minHour: 8,
				maxHour: 17
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, Validators.required),
						appointmentTime: new UntypedFormControl(null, Validators.required),
						appointmentTimePicker: new UntypedFormControl(null, Validators.required),
						appointmentDateType: new UntypedFormControl(null, Validators.required),
						skill: new UntypedFormControl('Dstv L2 Installation')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									return [
										{
											'Client Name': `${qdat.first_name} ${qdat.surname}`
										},
										{ 'Contact Number': `${qdat.contactnumber}` },
										{ 'Mobile Number': `${qdat.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			component: 'FLXJobAppointmentComponent',
			checkValidityForFields: ['appointmentData'],
			navs: [
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					optIntoValidation: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated.',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getAppointmentTypes).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSkills).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submissionData, appointmentTypes, skills]) => {
										const appointmentData = submissionData.appointment;
										const current_state = 62;
										const new_state = 35;

										const packet = {
											job_id: job?.id,
											appointment: appointmentData,
											current_state: current_state,
											new_state: new_state
										};
										return packet;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					const appointment_formatted = {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
					return appointment_formatted;
				}
			},
			'appointment'
		],
		new_state: 'new_state',
		assign_teamleader_id: [
			[
				tlid => {
					if (tlid) {
						let id;
						tlid = tlid && Array.isArray(tlid) ? tlid : [];
						for (const lead of tlid) {
							id = parseInt(lead, 10);
							console.log('TEAM LEAD ID', id);
						}
						return id;
					}
					return 0;
				},
				'job_information.team_leader'
			]
		]
	}
};
