import { Observable, of } from 'rxjs';
import { delay, share } from 'rxjs/operators';

export class Reminder {
	private _rawData: Reminder;

	constructor(rem?: any) {
		// console.log({ rem });
		this._rawData = rem;
	}

	//
	get id() {
		return this._rawData.id;
	}

	set id(id) {
		this._rawData.id = id;
	}

	get userID() {
		return this._rawData.userID;
	}

	set userID(userID) {
		this._rawData.userID = userID;
	}

	//
	get title() {
		return this._rawData.title;
	}
	set title(title) {
		this._rawData.title = title;
	}

	//
	get type() {
		return this.iconType(this._rawData.type);
	}
	set type(type) {
		this._rawData.type = type;
	}

	//
	get date() {
		return this._rawData.date;
	}

	set date(date) {
		this._rawData.date = date;
	}

	//
	get time() {
		return this._rawData.time;
	}

	set time(time) {
		this._rawData.time = time;
	}

	//
	get message() {
		return this._rawData.message;
	}
	set message(message) {
		this._rawData.message = message;
	}

	get claimNo() {
		return this._rawData.claimNo;
	}

	set claimNo(claimNo) {
		this._rawData.claimNo = claimNo;
	}

	//
	iconType(type: string): string {
		switch (type?.toLowerCase()) {
			case 'general':
				return 'alarm-clock';
			case 'call':
				return 'phone';
			case 'email':
				return 'envelope';
			default:
				return 'alarm-clock';
		}
	}

	get triggerReminder$(): Observable<Reminder> {
		const date = new Date(this._rawData.date);
		const year = date.getFullYear();
		const month = date.getMonth();
		const day = date.getDate();
		const [hours, minutes] = this._rawData.time.split(':');
		const triggerDate = new Date(year, month, day, hours, minutes);
		console.log({ rawData: this._rawData, year, month, day, hours, minutes, triggerDate });
		return of(this._rawData).pipe(delay(triggerDate), share());
	}
}
