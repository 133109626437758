import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXHeadingsModule } from '@flexus/ui-elements';
import { FLXHeadingWithULComponent } from './heading-with-ul.component';
import { FLXHeadingWithInstructionsModule } from '../heading-with-instructions';

@NgModule({
	imports: [CommonModule, FLXHeadingsModule, FLXHeadingWithInstructionsModule],
	declarations: [FLXHeadingWithULComponent],
	exports: [FLXHeadingWithULComponent]
})
export class FLXHeadingWithULModule {}
