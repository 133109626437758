import { Flow_0_0_2, getFullItemTwo, getSubmissionData } from '@flexus/core';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';
import { SP_GLOBAL_46 } from './SP_GLOBAL_46';

export const SP_GLOBAL_47: Flow_0_0_2 = {
	id: '47',
	name: 'work_changed_to_private_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Work changed to Private SP : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Work changed to Private SP';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Invoice Query'
		}
	},
	serverCalls: {
		...SP_GLOBAL_46.serverCalls
	},
	startNode: 'InvoiceQuery',
	nodes: {
		...SP_GLOBAL_46.nodes,
		NoteOnInvoiceQuery: {
			...SP_GLOBAL_46.nodes.NoteOnInvoiceQuery,
			navs: [
				{
					text: 'Continue',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					visible: (bf, store, networkService) => {
						return bf.getControl('reply_to_invoicequery').valueChanges.pipe(map(value => bf.getControl('reply_to_invoicequery').valid && value !== ''));
					},
					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo)?.pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData)?.pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submit]) => {
										const job_id = job?.id;
										const current_state = job?.state;
										const new_state = 49;
										return { job_id, current_state, new_state, ...submit };
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			]
		}
	},
	bigFormToStoreMapper: {
		...SP_GLOBAL_46.bigFormToStoreMapper,
		currentState: [() => 47, 'current_state']
	}
};
