import { getAllInfoIndex } from '@flexus/utilities';
import moment from 'moment';
import { AbstractControl, Validators, UntypedFormGroup, UntypedFormArray, FormControl } from '@angular/forms';

export function checkPolicyType(pol: string): string {
	if (pol !== undefined && pol !== null) {
		const policy = pol.substring(0, 3);
		// AMP
		const ampPolicies = ['HLC', 'BMB', 'HLB', 'EBK'];
		const stansurePolicies = ['STH', 'SHS'];
		if (ampPolicies.includes(policy.toUpperCase())) {
			return 'AMP';
		}
		// Stansure
		else if (stansurePolicies.includes(policy.toUpperCase())) {
			return 'StanSure';
		} else {
			return 'AMP';
		}
	} else return null;
}

export const transformJobsToJobCardRequest = (jobcards, storeObj, formValue, bf) => {
	if (jobcards !== undefined && jobcards !== null && jobcards.length > 0) {
		return jobcards
			.map(job => {
				const theRes = {
					skillrequested: 1,
					skill: job?.skill ? job?.skill : null,
					skill_id: job?.skill_id ? job?.skill_id : null,
					skillcatagory_id: job?.skillcatagory_id,
					skillcatagory: job?.skillcatagory ? job?.skillcatagory : null,
					providertype: 'Request SP',
					providertype_id: !bf.bigForm?.value?.upfrontrepudiation ? 1 : 7,

					requestedappointmentdate: job?.appointmentDatePicker ? moment(job.appointmentDatePicker)?.format('YYYY-MM-DD') : null,
					requestedappointmenttime: job?.appointmentTimePicker && job?.appointmentTime !== 3 ? `${job?.appointmentTimePicker?.hour}:${job?.appointmentTimePicker?.minutes}` : null,
					from_time: job?.from_time && job?.appointmentTime === 3 ? `${job?.from_time?.hour}:${job?.from_time?.minutes}` : null,
					to_time: job?.to_time && job?.appointmentTime === 3 ? `${job?.to_time?.hour}:${job?.to_time?.minutes}` : null,
					appointment_type_id: job?.appointmentTime ? job?.appointmentTime : null,
					appointment_type: job?.appointmentTime ? getAllInfoIndex('appointment_types', 'id', 'name', job?.appointmentTime, storeObj, storeObj['allInfo'][0]) : null, // at before
					appointmentdatetype: job?.appointmentDateType ? job?.appointmentDateType : null
				};
				return theRes;
			})
			.map((job, index) => {
				if (index === 0 && bf.bigForm?.value?.policy_excess?.amount) {
					const pExcess = bf.bigForm?.value?.policy_excess;
					const policyExcess = {
						excess_amount: pExcess.amount ? pExcess.amount : null,
						excess_description: pExcess?.excess_description,
						who_collects_excess: pExcess.who_collects ? pExcess.who_collects : null,
						who_collects_excess_desc: pExcess.who_collects ? getAllInfoIndex('excess_who', 'id', 'name', pExcess.who_collects, storeObj, storeObj['allInfo'][0]) : null,
						excess_payment_method: pExcess.payment_method ? pExcess.payment_method : null,
						excess_payment_method_desc: pExcess.payment_method ? getAllInfoIndex('excess_how', 'id', 'name', pExcess.payment_method, storeObj, storeObj['allInfo'][0]) : null
					};
					const excess = job?.excess ? [{ ...policyExcess }, ...job.excess] : [{ ...policyExcess }];
					return pExcess && pExcess.is_cancelled ? job : { ...job, excess };
				}
				return job;
			});
	}
	return null;
};

export const transformJobsToJobCardRequestWithJob = (jobcards, storeObj, formValue, bf) => {
	if (jobcards !== undefined && jobcards !== null && jobcards.length > 0) {
		return jobcards
			.map(job => {
				const theRes = {
					...job,
					make: {
						id: job?.make,
						name: getAllInfoIndex('brands', 'id', 'name', job?.make, storeObj, storeObj['allInfo'][1]?.payload)
					},
					item_type: {
						id: job?.item_type,
						name: getAllInfoIndex('item_types', 'id', 'name', job?.item_type, storeObj, storeObj['allInfo'][1]?.payload)
					},
					skillrequested: 1,
					skill: job?.skill ? job?.skill : null,
					skill_id: job?.skill_id ? job?.skill_id : null,
					skillcatagory_id: job?.skillcatagory_id,
					skillcatagory: job?.skillcatagory ? job?.skillcatagory : null,
					providertype: 'Request SP',
					providertype_id: !bf.bigForm?.value?.upfrontrepudiation ? 1 : 7,

					requestedappointmentdate: job?.appointmentDatePicker ? moment(job.appointmentDatePicker)?.format('YYYY-MM-DD') : null,
					requestedappointmenttime: job?.appointmentTimePicker ? `${job.appointmentTimePicker.hour}:${job.appointmentTimePicker.minutes}` : null,
					appointment_type_id: job?.appointmentTime ? job?.appointmentTime : null,
					appointment_type: job?.appointmentTime ? getAllInfoIndex('appointment_types', 'id', 'name', job?.appointmentTime, storeObj, storeObj['allInfo'][0]) : null, // at before
					appointmentdatetype: job?.appointmentDateType ? job?.appointmentDateType : null
				};
				return theRes;
			})
			.map((job, index) => {
				if (index === 0 && bf.bigForm?.value?.policy_excess?.amount) {
					const pExcess = bf.bigForm?.value?.policy_excess;
					const policyExcess = {
						excess_amount: pExcess.amount ? pExcess.amount : null,
						excess_description: pExcess?.excess_description,
						who_collects_excess: pExcess.who_collects ? pExcess.who_collects : null,
						who_collects_excess_desc: pExcess.who_collects ? getAllInfoIndex('excess_who', 'id', 'name', pExcess.who_collects, storeObj, storeObj['allInfo'][0]) : null,
						excess_payment_method: pExcess.payment_method ? pExcess.payment_method : null,
						excess_payment_method_desc: pExcess.payment_method ? getAllInfoIndex('excess_how', 'id', 'name', pExcess.payment_method, storeObj, storeObj['allInfo'][0]) : null
					};
					const excess = job?.excess ? [{ ...policyExcess }, ...job.excess] : [{ ...policyExcess }];
					return pExcess && pExcess.is_cancelled ? job : { ...job, excess };
				}
				return job;
			});
	}
	return null;
};

// Clears validators on fields not needed once repudiated and resets them after.
export function toggleUpfrontValidation(toggle: boolean, bf: UntypedFormGroup) {
	const jobs = bf.get('jobs') as UntypedFormArray;
	const controls = jobs.controls;
	for (let index = 0; index < controls.length; index++) {
		if (toggle) {
			removeValidators(controls[index] as UntypedFormGroup);
		} else {
			checkBusinessRules(controls[index] as UntypedFormGroup);
		}
	}
}

export function checkBusinessRules(bf: UntypedFormGroup) {
	const jobs = bf.get('jobs') as UntypedFormArray;
	const controls = jobs.controls;
	for (let index = 0; index < controls.length; index++) {
		const formControl = controls[index] as UntypedFormGroup;
		if (formControl.get('providertype_id')?.value !== 2 && !!formControl.get('assessorWaived')?.value === false) {
			addAppointmentValidators(formControl);
		} else {
			removeAppointmentValidators(formControl);
		}
		if (!(!!formControl.get('is_cancelled')?.value || !!formControl.get('assessorWaived')?.value)) {
			addExcessValidators(formControl);
		} else {
			removeExcessValidators(formControl);
		}
	}
}

function removeValidators(control: UntypedFormGroup) {
	for (const key in control.controls) {
		if (control.get(key)) {
			if (key !== 'bank_details') {
				control.get(key)?.clearValidators();
				control.get(key)?.updateValueAndValidity();
			}
		}
	}
}

function addExcessValidators(formControl: UntypedFormGroup) {
	if (formControl.get('amount')) formControl.get('amount')?.setValidators([Validators.required]);
	if (formControl.get('who_collects')) formControl.get('who_collects')?.setValidators([Validators.required]);
	if (formControl.get('payment_method')) formControl.get('payment_method')?.setValidators([Validators.required]);

	formControl.get('amount')?.updateValueAndValidity();
	formControl.get('who_collects')?.updateValueAndValidity();
	formControl.get('payment_method')?.updateValueAndValidity();
}

function addAppointmentValidators(formControl: UntypedFormGroup) {
	if (formControl.get('appointmentDatePicker')) formControl.get('appointmentDatePicker')?.setValidators([Validators.required]);
	if (formControl.get('appointmentTime')) formControl.get('appointmentTime')?.setValidators([Validators.required]);
	if (formControl.get('appointmentTimePicker')) formControl.get('appointmentTimePicker')?.setValidators([Validators.required]);

	formControl.get('appointmentDatePicker')?.updateValueAndValidity();
	formControl.get('appointmentTime')?.updateValueAndValidity();
	formControl.get('appointmentTimePicker')?.updateValueAndValidity();
}

function removeExcessValidators(formControl: UntypedFormGroup) {
	if (formControl.get('amount')) formControl.get('amount')?.clearValidators();
	if (formControl.get('who_collects')) formControl.get('who_collects')?.clearValidators();
	if (formControl.get('payment_method')) formControl.get('payment_method')?.clearValidators();

	formControl.get('amount')?.updateValueAndValidity();
	formControl.get('who_collects')?.updateValueAndValidity();
	formControl.get('payment_method')?.updateValueAndValidity();
}

function removeAppointmentValidators(formControl: UntypedFormGroup) {
	if (formControl.get('appointmentDatePicker')) formControl.get('appointmentDatePicker')?.clearValidators();
	if (formControl.get('appointmentTime')) formControl.get('appointmentTime')?.clearValidators();
	if (formControl.get('appointmentTimePicker')) formControl.get('appointmentTimePicker')?.clearValidators();

	formControl.get('appointmentDatePicker')?.updateValueAndValidity();
	formControl.get('appointmentTime')?.updateValueAndValidity();
	formControl.get('appointmentTimePicker')?.updateValueAndValidity();
}
