import { Flow_0_0_2 } from '@flexus/core';
import { BUI_97 } from './BUI_97';

export const BUI_89: Flow_0_0_2 = {
	id: '89',
	name: 'internal_assessor_job',
	itemType: 'flow',
	fetchLevel1And2: true,
	header: {
		...BUI_97.header
	},
	footer: {
		...BUI_97.footer
	},
	instructions: {
		...BUI_97.instructions
	},
	actionPanel: BUI_97.actionPanel,
	serverCalls: {
		...BUI_97.serverCalls
	},
	startNode: 'AssessorOverview',
	nodes: {
		...BUI_97?.nodes
	},
	bigFormToStoreMapper: {
		...BUI_97.bigFormToStoreMapper
	}
};
