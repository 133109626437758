import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IndexedDbService } from '@flexus/core';
import { debounceTime, skipWhile, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { CollapseAdditionalActionPanel } from '../../../app-shell-features/action-panel/store';
import { UUID } from 'angular2-uuid';

export interface ScratchPad {
	userID: number;
	notes: string;
}

@Component({
	selector: 'flx-scratch-pad',
	templateUrl: './scratch-pad.component.html',
	styleUrls: ['./scratch-pad.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXScratchPadComponent implements OnInit, OnDestroy {
	public scratchFormGroup: UntypedFormGroup;
	private userID: number;
	public _subscription: Subscription;

	constructor(private indexedDbService: IndexedDbService, private _store: Store<any>) {
		this.scratchFormGroup = new UntypedFormGroup({
			id: new UntypedFormControl(UUID.UUID()),
			notes: new UntypedFormControl(''),
			userID: new UntypedFormControl(0)
		});
	}

	ngOnInit() {
		this.indexedDbService.user
			.toArray()
			.then(user => {
				return (this.userID = +user);
			})
			.then(userID => {
				return this.indexedDbService.scratch_pad
					.where('userID')
					.equals(userID)
					.first(data => {
						this.scratchFormGroup.get('userID')?.setValue(userID);
						if (data?.id) {
							this.scratchFormGroup.get('id')?.setValue(data?.id);
							this.scratchFormGroup.get('notes')?.setValue(data?.notes);
						}
					});
			})
			.then(x => {
				this._subscription = this.scratchFormGroup
					.get('notes')
					?.valueChanges?.pipe(
						skipWhile(x => !x),
						debounceTime(500)
					)
					.subscribe(__ => {
						this.indexedDbService.scratch_pad.put(this.scratchFormGroup.value);
					});
			});
	}

	ngOnDestroy(): void {
		if (this._subscription) this._subscription.unsubscribe();
	}

	closeScratchPad() {
		this._store.dispatch(new CollapseAdditionalActionPanel());
	}
}
