import { Component, OnInit, Input, ViewChild, ElementRef, forwardRef, OnChanges, AfterViewInit, Inject } from '@angular/core';
import { ControlValueAccessor, Validator, UntypedFormGroup, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Lps } from './lps';
import { LocationPickerConfig, LocationPickerTheme } from './agmish';
import { BehaviorSubject } from 'rxjs';
import { DarkTheme } from './themes/dark';
import { LightTheme } from './themes/light';
export {} from 'googlemaps';

export class LocationPickerResult {
	public description: string;
	public address: string;
	public suburb: string;
	public city: string;
	public suburb_code: string;
	public country: string;
	public province: string;
	public latitude: number;
	public longitude: number;

	constructor(address: string, suburb: string, city: string, suburb_code: string, province: string, country: string, latitude: number, longitude: number) {
		this.address = address;
		this.suburb = suburb;
		this.city = city;
		this.suburb_code = suburb_code;
		this.province = province;
		this.country = country;
		this.latitude = latitude;
		this.longitude = longitude;
		this.description = this.getDescription();
	}

	getDescription(): string {
		const fullAddressArray = [];

		if (this.address) fullAddressArray.push(this.address);

		if (this.suburb) fullAddressArray.push(this.suburb);

		if (this.city) fullAddressArray.push(this.city);

		if (this.suburb_code) fullAddressArray.push(this.suburb_code);

		return fullAddressArray?.join(', ');
	}
}

@Component({
	selector: 'flx-location-picker',
	templateUrl: './location-picker.component.html',
	styleUrls: ['./location-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => LocationPickerComponent),
			multi: true
		}
	]
})
export class LocationPickerComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit {
	// =========================================  Instance Fields =======================================================
	@ViewChild('containerDiv', { static: true }) containerElem: any;
	mapsContainer: google.maps.Map;

	@ViewChild('autocompleteElem', { static: true }) autocompleteElem: any;

	// ============================================= Inputs =============================================================
	@Input() _placeValue: LocationPickerResult;

	@Input() showSearch = false;

	private _theme: LocationPickerTheme;
	@Input('theme')
	get theme() {
		return this._theme;
	}

	set theme(val: LocationPickerTheme) {
		this._theme = val;

		if (this.scriptsLoaded.value) {
			this.setMapTheme();
		}
	}

	// ======================================== Internal Variables ======================================================
	public sendChanges: (_: any) => {};
	public touchChanges: (_: any) => {};
	public validateFn: (_: any) => {};

	public googleMapsSrc: string;
	private mapsAutocomplete: google.maps.places.Autocomplete;
	private mapsGeocoder: google.maps.Geocoder;
	private mapsMarker: google.maps.Marker;
	private defaultCenter = { lat: -26.2041, lng: 28.0473 };
	private scriptsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private _config: LocationPickerConfig;
	private _defaultConfig: LocationPickerConfig;
	private _darkThemeStyles: google.maps.MapTypeStyle[];

	public form: UntypedFormGroup = new UntypedFormGroup({});

	// =========================================== Getters/Setters =============================================================
	get placeValue() {
		return this._placeValue;
	}

	set placeValue(val) {
		this._placeValue = val;
		if (this.sendChanges) {
			this.sendChanges(this._placeValue);
		}
	}

	get config() {
		return this._config || this._defaultConfig;
	}

	// ============================================ Constructor =========================================================
	constructor(private _l: Lps, private el: ElementRef, @Inject('environment') private environment: any) {}

	// ========================================== Life-cycle methods ====================================================
	ngOnInit() {
		this.googleMapsSrc = `https://maps.googleapis.com/maps/api/js?key=${this.environment.google_maps_api_key}&libraries=places&region=ZA&language=en`;
	}

	ngAfterViewInit() {
		console.log('after view init....');

		this.scriptsLoaded.subscribe(loadStatus => {
			if (loadStatus) {
				this.mapsGeocoder = new google.maps.Geocoder();

				this._defaultConfig = {
					zoom: 10,
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					center: this.defaultCenter,
					streetViewControl: true,
					backgroundColor: 'none',
					mapTypeControlOptions: {
						mapTypeIds: []
					}
				};

				this.renderMap();
				this.renderAutocomplete();
				this.registerMapOnClickListener();

				this.renderNewLocation();
			}
		});
	}

	setMapTheme() {
		switch (this._theme) {
			case LocationPickerTheme.Dark:
				this.mapsContainer.setOptions({ styles: DarkTheme.styles });
				break;
			default:
				this.mapsContainer.setOptions({ styles: LightTheme.styles });
				break;
		}
	}

	renderNewLocation() {
		if (this.placeValue.latitude && this.placeValue.longitude) {
			this.geocoderResolveByCoords({ lat: this.placeValue.latitude, lng: this.placeValue.longitude });
		} else if (this.placeValue.description) {
			this.geocoderResolveByAddress(this.placeValue.description);
		}
	}

	// ======================================= Control Value Accessor Methods ===========================================
	writeValue(value: any): void {
		if (value) {
			this.placeValue = value;
		}
	}

	registerOnChange(fn: any): void {
		this.sendChanges = fn;
	}

	registerOnTouched(fn: any): void {
		this.touchChanges = fn;
	}

	// ===================================== Validator Related Methods ==================================================
	validate(c: UntypedFormControl) {
		return this.validateFn(c);
	}

	// ============================================= Methods ============================================================
	renderMap() {
		this.mapsContainer = new google.maps.Map(this.containerElem.nativeElement, this.config);
		this.setMapTheme();
	}

	renderAutocomplete() {
		const options = {
			componentRestrictions: { country: 'za' }
		};

		this.mapsAutocomplete = new google.maps.places.Autocomplete(this.autocompleteElem.nativeElement, options);
		this.registerAutocompleteListener();
	}

	registerAutocompleteListener() {
		this.mapsAutocomplete.addListener('place_changed', event => {
			this.autocompletePlaceChanged(event);
		});
	}

	registerMapOnClickListener() {
		this.mapsContainer.addListener('click', event => {
			const coordinates = { lat: event.latLng.lat(), lng: event.latLng.lng() };

			this.addMarkerToMap(coordinates);

			this.geocoderResolveByCoords(coordinates, false);
		});
	}

	autocompletePlaceChanged(event) {
		const place: google.maps.places.PlaceResult = this.mapsAutocomplete.getPlace();
		const loc = this.handleAddressResult(place);

		const coordinates = new google.maps.LatLng(Number(loc.latitude), Number(loc.longitude));

		this.placeValue = loc;

		this.showSearch = true;
		this.autocompleteElem.value = '';

		this.addMarkerToMap(coordinates);
	}

	geocoderResolveByCoords(coords, forceLatLng = true) {
		const obj = { lat: coords.lat, lng: coords.lng };

		this.mapsGeocoder.geocode({ location: obj, region: 'za' }, (results, status) => {
			if (status?.toString()?.toLowerCase() === 'ok') {
				const loc = this.handleAddressResult(results[0]);

				const coordinates = { lat: loc.latitude, lng: loc.longitude };

				this.placeValue = loc;

				if (forceLatLng) {
					this.addMarkerToMap(coordinates);
				}
			}
		});
	}

	geocoderResolveByAddress(addr) {
		this.mapsGeocoder?.geocode({ address: addr, region: 'za' }, (results, status) => {
			if (status?.toString()?.toLowerCase() === 'ok') {
				const loc = this.handleAddressResult(results[0]);

				const coordinates = new google.maps.LatLng(Number(loc.latitude), Number(loc.longitude));

				this.addMarkerToMap(coordinates);

				this.placeValue = loc;
			} else {
				this.mapsContainer.setZoom(8);
			}
		});
	}

	handleAddressResult(result: google.maps.GeocoderResult | google.maps.places.PlaceResult): LocationPickerResult {
		const street_number = this.pluckByType(result, 'street_number');
		const street_name = this.pluckByType(result, 'route');

		let address = '';
		if (street_name) {
			address = `${street_number ? street_number + ' ' : ''}${street_name}`;
		}

		let suburb = this.pluckByType(result, 'sublocality_level_1');
		if (!suburb) {
			suburb = this.pluckByType(result, 'locality');
		}

		const city = this.pluckByType(result, 'locality');
		const province = this.pluckByType(result, 'administrative_area_level_1');
		const postal_code = this.pluckByType(result, 'postal_code');
		const latitude = result.geometry.location.lat();
		const longitude = result.geometry.location.lng();

		let country = '';
		const countryExists = this.getCountry(result.address_components);
		if (countryExists !== false) {
			country = this.getCountry(result.address_components);
		}

		return new LocationPickerResult(address, suburb, city, postal_code, province, country, latitude, longitude);
	}

	pluckByType(result: google.maps.GeocoderResult | google.maps.places.PlaceResult, typeToFind: string): string {
		const plucked = result.address_components?.filter(f => f.types.indexOf(typeToFind) !== -1);
		if (plucked.length > 0) {
			return plucked[0]?.long_name?.trim();
		} else {
			return '';
		}
	}

	addMarkerToMap(coordinates) {
		if (this.mapsMarker) {
			this.mapsMarker.setPosition(coordinates);
		} else {
			this.mapsMarker = new google.maps.Marker({
				position: coordinates,
				map: this.mapsContainer,
				draggable: true,
				animation: google.maps.Animation.DROP
			});

			this.mapsMarker.addListener('dragend', event => {
				this.onMarkerDragEnd(event);
			});
		}

		this.mapsContainer.panTo(coordinates);
		this.mapsContainer.setZoom(17);
	}

	onMarkerDragEnd(markerEvent) {
		const lat = markerEvent.latLng.lat();
		const lng = markerEvent.latLng.lng();

		this.geocoderResolveByCoords({ lat: lat, lng: lng }, false);
	}

	getCountry(addrComponents) {
		for (let i = 0; i < addrComponents.length; i++) {
			if (addrComponents[i]?.types[0] === 'country') {
				return addrComponents[i]?.long_name;
			}
		}

		return false;
	}

	scriptLoaderCallBack(isLoaded) {
		this.scriptsLoaded.next(isLoaded);
	}
}
