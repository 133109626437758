import { Component, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getFullItemOne, MakeServerCall } from '@flexus/core';
import { take, filter, map, skipWhile } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'flx-ap-time-stamps',
	templateUrl: './time-stamps.component.html',
	styleUrls: ['./time-stamps.component.scss']
})
export class FLXAPTimeStampsComponent implements OnDestroy, AfterViewInit {
	claimId: number;
	@Input() timeStamps$: Observable<any>;

	constructor(private _store: Store<any>) {}
	ngAfterViewInit(): void {
		this._store
			.select(getFullItemOne)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe(claim => {
				this._store.dispatch(
					new MakeServerCall({
						dataKey: 'timeStamps',
						serviceVariable: 'silService',
						functionName: '',
						errorMessage: 'No time stamps',
						loaderID: 'action-panel',
						directCall: (http, store) => {
							return http.post(`${environment.api_url}v1/claim_action/get_time_logs/`, { [`claim_id`]: claim?.id })?.pipe(
								filter(x => !!x),
								take(1),
								map((res: any) => {
									const jobs = res?.payload['jobs'];
									const claimStates = res?.payload['claim'];
									const temArr = [];
									if (res && jobs) {
										jobs.forEach(element => {
											element.forEach(inner => {
												element.title = inner.job_skill;
												element.job_id = inner.job_id;
												inner.dateTime = inner['mytimestamp'];
											});
											temArr.push(element);
										});
									}
									return { jobs: temArr, claimStates: claimStates };
								})
							);
						}
					})
				);
			});
	}

	ngOnDestroy(): void {
		this._store.dispatch(
			new MakeServerCall({
				dataKey: 'timeStamps',
				serviceVariable: 'silService',
				functionName: '',
				isBackgroundTask: true,
				errorMessage: 'No time stamps',
				directCall: (http, store) => {
					return of({});
				}
			})
		);
	}
}
