<div class="list-view-card" [id]="'claim-' + claim?.id">
	<div class="list-view-client-info" (click)="takeClaimAction()">
		<div class="indicator"><flx-indicator size="small"></flx-indicator></div>

		<div class="claim-card-icons">
			<div class="context-menu-container">
				<div class="action">
					<flx-context-menu *ngIf="isOnline || claim?.state === 169" class="context-menu-icon" iconSize="small">
						<ul>
							<ng-container *ngFor="let menuItem of itemOneContextMenuList">
								<li
									*ngIf="
										(menuItem?.excludeForOnlyStates.length > 0 && !menuItem?.excludeForOnlyStates.includes(claim?.state)) ||
										(!menuItem?.excludeForOnlyStates.includes(claim?.state) && menuItem?.includeForOnlyStates.includes('all')) ||
										(menuItem?.includeForOnlyStates.includes(claim?.state) && (!menuItem?.includeFilterFunction || menuItem?.includeFilterFunction(claim)))
									"
									(click)="selectClaimMenuItem(claim, menuItem)"
								>
									{{ menuItem?.name }}
								</li>
							</ng-container>
							<li class="divider"><flx-glow-line></flx-glow-line></li>
						</ul>
					</flx-context-menu>
				</div>
			</div>

			<div class="indicator-icons">
				<!-- CAT indicator -->
				<div class="action" *ngIf="claim && claim?.cat_code && claim?.cat_code !== null">
					<flx-icon size="extra-small" [type]="'storms'" instruction="catastrophe" color="indicator" [isClickable]="false"></flx-icon>
				</div>
				<!-- Private Client indicator -->
			</div>
		</div>
		<div class="indicator-icons">
			<div class="action" *ngIf="claim && claim?.private_banking !== null && claim?.private_banking === 1">
				<flx-icon class="pc" instruction="Private Banking Client" [type]="'private-client'" color="indicator"></flx-icon>
			</div>
		</div>
		<div class="indicator-icons">
			<div class="notes-indicator" *ngIf="claim && claim?.note_count !== null && claim?.note_count > 0">
				<flx-icon
					size="extra-small"
					[instruction]="claim?.note_count > 1 ? claim?.note_count + ' notes' : claim?.note_count + ' note'"
					[type]="'notes'"
					(click)="selectClaimMenuItem(claim, { key: 'SIL_CONTEXT_MENU_NOTES' })"
				></flx-icon>
			</div>
		</div>

		<div>{{ claim?.mid }}</div>
		<div>{{ claim?.applicant?.first_name + ' ' + claim?.applicant?.surname || 'No Client Name Captured' }}</div>
		<div *ngIf="engState">{{ claim?.state }}: {{ engState }}</div>
	</div>

	<flx-sil-list-job-card
		[itemTwo]="job"
		[itemOne]="claim"
		[itemTwoContextMenuList]="itemTwoContextMenuList"
		[user]="user"
		[itemTwoPermissions]="itemTwoPermissions"
		[activeOrg]="activeOrg"
		[allInfo]="allInfo"
		[isOnline]="isOnline"
	></flx-sil-list-job-card>
</div>
