import { UntypedFormControl, Validators } from '@angular/forms';
import { PHOTO_NODE } from '../../../../sp_globals/configs/reusable';
import { map, pluck, skipWhile, take } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { AUTO_SELECT_TEMPLATE_DECISION, SELECT_CALLOUT_TEMPLATE, billingServerCalls } from '../../../../sp_globals/configs/flows/BILLING';
import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';

export const BET_SP_293: Flow_0_0_2 = {
	id: '293',
	name: 'review_tl_boq',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) =>
			store.select(getFullItemTwo).pipe(
				skipWhile((itemTwo: any) => !itemTwo),
				take(1),
				map((itemTwo: any) => {
					if (itemTwo) {
						return `Review Bill of Quantity : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Review Bill of Quantity';
					}
				})
			),
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		jobSummary: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					pluck('job_information'),
					map((job_information: any) => {
						if (job_information) {
							return {
								important_information: {
									important_note: 'This job has been repudiated. Please complete quotation with report'
								}
							};
						}
					})
				);
			}
		},
		claimDetails: {
			errorMessage: '',
			directCall: (http, store, sq) =>
				store.select(getFullItemTwo).pipe(
					skipWhile(f => !f),
					take(1),
					map((job: any) => ({
						claim_details: {
							'Claim Type': job?.claim?.type,
							'Skill Required': job?.office_use?.skill,
							Address: job?.address,
							'Appointment Date': job?.office_use?.requestedappointmentdate,
							'Appointment Time': `${job?.office_use?.appointment_type} ${job?.office_use?.requestedappointmenttime}`
						}
					}))
				)
		},
		assessorDetails: {
			errorMessage: 'Could get assessor details',
			serviceVariable: 'betService',
			functionName: 'getAssessorDetailsformatted'
		},
		files: {
			serviceVariable: 'betService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		...billingServerCalls
	},
	instructions: {
		editRoles: {
			0: 'Review Bill of Quantity'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'JobSummary',
	nodes: {
		JobSummary: {
			name: 'Summary',
			showTabs: true,
			component: {
				children: [
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							headingConfig: { title: 'Job Summary', color: 'default', size: 'medium' },
							subHeadingConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							keyValueHeadingConfig: { color: 'secondary' },
							objectInfo$: 'jobSummary'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'claimDetails'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'assessorDetails'
						}
					}
				]
			},
			navs: [
				{
					text: 'Review Draft Report',
					nextNode: 'DraftReport',
					color: 'primary'
				}
			]
		},
		DraftReport: {
			component: 'FLXFlatDynamicFormComponent',
			name: 'Draft Report',
			showTabs: true,
			inputs: {
				heading: 'Team Lead Draft Report',
				formControls: {
					0: {
						formControlName: 'description_of_claim',
						inputType: 'textarea',
						rows: 3,
						label: 'What is the damage'
					},
					1: {
						formControlName: 'circumstance_of_loss',
						inputType: 'textarea',
						rows: 3,
						label: 'What caused the loss'
					},
					2: {
						formControlName: 'materiality',
						inputType: 'textarea',
						rows: 3,
						label: 'Materiality'
					},
					3: {
						formControlName: 'recommendations',
						inputType: 'textarea',
						rows: 3,
						label: 'Recommendations/Reason for Repudiation'
					},
					4: {
						formControlName: 'description_of_further_inspection',
						inputType: 'textarea',
						rows: 3,
						label: 'Upon Further Inspection'
					}
				},
				formLayout: 'stacked',
				containerWidth: '50vw'
			},
			checkValidityForFields: ['circumstance_of_loss', 'description_of_claim', 'materiality', 'recommendations', 'description_of_further_inspection'],
			navs: [
				{
					text: 'Review Photos',
					color: 'primary',
					nextNode: 'JobPhotosNode',
					optIntoValidation: true
				}
			],
			initFormFields: (bf, item, instance, sq, store) => {
				store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1)
					)
					.subscribe(value => {
						bf.addControl('description_of_claim', new UntypedFormControl(value?.job_information?.description_of_claim || '', [Validators.required]));
						bf.addControl('circumstance_of_loss', new UntypedFormControl(value?.job_information.circumstance_of_loss || '', [Validators.required]));
						bf.addControl('materiality', new UntypedFormControl(value?.job_information?.materiality || '', [Validators.required]));
						bf.addControl('recommendations', new UntypedFormControl(value?.job_information?.recommendations || '', [Validators.required]));
						bf.addControl('description_of_further_inspection', new UntypedFormControl(value?.job_information?.description_of_further_inspection || '', [Validators.required]));
					});
			}
		},
		JobPhotosNode: {
			showTabs: true,
			...PHOTO_NODE,
			checkValidityForFields: ['circumstance_of_loss', 'description_of_claim', 'materiality', 'recommendations', 'description_of_further_inspection'],
			navs: [
				{
					text: 'Review Quotation',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
					color: 'primary',
					optIntoValidation: true
				}
			]
		},
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'quote',
				numberLabel: 'Quote',
				currentState: 293,
				newState: 288,
				showLineItemGenerator: true,
				canEditLineItems: true,
				boqLogo: 'assets/images/boq-bettersure-logo.svg',
				boqLogoAlt: 'Bettersure',
				clientName: 'Bettersure'
			},
			navs: [
				{
					text: 'Generate Quote',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			initFormFields: (bf, item, instance, sq, store) => {
				store.dispatch(new CollapseActionPanel());
			}
		}
	},
	bigFormToStoreMapper: {
		new_state: [() => 288, 'new_state'],
		current_state: [() => 293, 'current_state'],
		circumstance_of_loss: 'job_information.circumstance_of_loss',
		description_of_claim: 'job_information.description_of_claim',
		materiality: 'job_information.materiality',
		recommendations: 'job_information.recommendations',
		description_of_further_inspection: 'job_information.description_of_further_inspection'
	}
};
