import { flatten } from 'ramda';

/**
 * Search for an auto pay log for a job.
 * @param setsOfJobLogs Array of job log arrays
 * @param job The job object.
 * @returns A log object related to the `job` object passed.
 */
export const findJobLog = (setsOfJobLogs: any[][], job: any, qualifyingJobStates: number[]) => {
	return flatten(setsOfJobLogs).find((jobLog: any) => {
		return jobLog?.job_id === job?.id && qualifyingJobStates.includes(jobLog.state) && jobLog.reason === 'Auto pay rule pass';
	});
};
