<flx-heading size="'medium" align="center" weight="extra-light" type="creation">Confirm DSTV Customer Details</flx-heading>
<div class="customer-details-container">
	<form *ngIf="bf.bigForm.get('client_details')" [formGroup]="bf.bigForm">
		<flx-flex-container direction="row" justifyContent="center" maxWidth="50vw">
			<!-- customer details -->
			<flx-panel density="cozy" formGroupName="client_details" margin="8">
				<flx-label>Clarity Customer Number</flx-label>
				<flx-input-text formControlName="clarity_customer_number" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['client_details']?.['controls']?.clarity_customer_number"> </flx-reactive-form-error-display>

				<flx-label>{{ 'first_name' | fsToHuman }}</flx-label>
				<flx-input-text formControlName="first_name" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['client_details']?.['controls']?.first_name"></flx-reactive-form-error-display>

				<flx-label>{{ 'surname' | fsToHuman }}</flx-label>
				<flx-input-text formControlName="surname" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['client_details']?.['controls']?.surname"> </flx-reactive-form-error-display>
			</flx-panel>
			<!-- contact details -->
			<flx-panel density="cozy" formGroupName="contact_details" margin="8">
				<flx-label>Contact Number</flx-label>
				<flx-input-text formControlName="contact_number" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['contact_details']?.['controls']?.contact_number"> </flx-reactive-form-error-display>

				<flx-label>Alternative Contact Number</flx-label>
				<flx-input-text formControlName="cell_number" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['contact_details']?.['controls']?.cell_number"></flx-reactive-form-error-display>

				<flx-label>Email Address</flx-label>
				<flx-input-text formControlName="email" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['contact_details']?.['controls']?.email"> </flx-reactive-form-error-display>
			</flx-panel>
			<!-- address details -->
			<flx-panel density="cozy" formGroupName="insured_address" margin="8">
				<flx-label>Complex</flx-label>
				<flx-input-text formControlName="complex" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['insured_address']?.['controls']?.complex"> </flx-reactive-form-error-display>

				<flx-label>Street Address</flx-label>
				<flx-input-text formControlName="street_address" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['insured_address']?.['controls']?.street_address"></flx-reactive-form-error-display>

				<flx-label>Suburb</flx-label>
				<flx-input-text formControlName="suburb" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['insured_address']?.['controls']?.suburb"> </flx-reactive-form-error-display>

				<flx-label>City</flx-label>
				<flx-input-text formControlName="city" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['insured_address']?.['controls']?.city"> </flx-reactive-form-error-display>
				<flx-label>Postal Code</flx-label>
				<flx-input-text formControlName="postal_code" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="bf.bigForm?.controls?.['insured_address']?.['controls']?.postal_code"> </flx-reactive-form-error-display>
				<flx-label>{{ 'province' | fsToHuman }}</flx-label>
				<flx-dropdown
					(itemsSelected)="setArea($event)"
					[itemsOption]="provinceList"
					[displayOptions]="{ displayKey: 'display', valueKey: 'value' }"
					formControlName="province"
					placeHolder="Select Province"
					[searchEnabled]="false"
				>
				</flx-dropdown>
				<flx-reactive-form-error-display [control]="bf.bigForm.controls['insured_address']['controls']?.province"> </flx-reactive-form-error-display>
			</flx-panel>
		</flx-flex-container>
	</form>
</div>
