import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getAllInfo } from '@flexus/core';
import { filter, skipWhile, first, map, take, switchMap, pluck } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { forkJoin } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';

import gql from 'graphql-tag';

export const BET_58: Flow_0_0_2 = {
	id: '58',
	name: 'initiate_cil_payment',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Approve SP Invoice : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Approve SP Invoice';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Initiate Cash-in-lieu payment'
		},
		viewRoles: {
			0: 'Initiate Cash-in-lieu payment'
		}
	},
	serverCalls: {
		invoice: {
			serviceVariable: 'betService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	startNode: 'ViewInfo',
	nodes: {
		ViewInfo: {
			component: 'FileViewWithExtraComponent',
			serverCalls: {
				keyValueList: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						const invoice$ = sq
							.queryStore(
								gql`
									{
										selectedContext {
											invoice
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1)
							);
						return forkJoin([
							store.select(getFullItemOne).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							invoice$.pipe(
								skipWhile(x => !x),
								pluck('invoice'),
								take(1)
							)
						]).pipe(
							take(1),
							map(([claim, job, allInfo, invoice]) => {
								const list = {
									'Claim Type': claim?.type ?? '',
									'Client Name': claim?.applicant ? claim?.applicant?.first_name + ' ' + claim?.applicant?.surname : '',
									'Contact number': claim?.applicant ? claim?.loan_information.contactnumber : '',
									'Client Alternative no': claim?.loan_information?.cellnumber ?? '',
									'Excess collection': ((): string => {
										let collection = '';
										if (job.excess[0]) {
											allInfo['excess_who']?.forEach(element => {
												if (element.id === job?.excess[0]?.who_collects) {
													collection = element.name;
												}
											});
										}
										return collection;
									})(),
									'Excess amount': job?.excess?.length !== 0 ? `R ${job.excess[0]?.amount}` : 0,
									'Excess Payment': ((): string => {
										let pay = '';
										if (job.excess[0]) {
											allInfo['excess_how']?.forEach(element => {
												if (element.id === job?.excess[0]?.how_collect) {
													pay = element.name;
												}
											});
										}
										return pay;
									})(),
									'Excess State': ((): string => {
										let state = '';
										if (job.excess[0]) {
											allInfo['excess_states']?.forEach(element => {
												if (element.id === job?.excess[0]?.state) {
													state = element.name;
												}
											});
										}
										return state;
									})(),
									'Inspection fee only': claim?.inspection || 'No',
									'Consignment stock used': claim?.consignment || 'No',
									'What mattered to the customer': claim?.loan_information.whatmatters ?? ''
								};

								return [list];
							})
						);
					}
				}
			},
			inputs: {
				inputHeading: 'Approve Job Invoice/Quote - On panel SP',
				subHeading: 'Review uploaded support documents and approve invoice/quote',
				fileDoesNotExistMessage: ''
			},
			navs: [
				{
					text: 'Continue',
					serverFirst: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const { job_information, id } = job;
											const result = JSON.parse(JSON.stringify(job_information));
											const subData = {
												job_information: result,
												job_id: id,
												new_state: 27
											};
											return subData;
										}),
										switchMap(dataSub => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					}
				}
			]
		}
	},
	bigFormToStoreMapper: {
		nextApprovalStaffName: [
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.nextApprovalStaffName'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.authid'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'authid'
			]
		],
		paymentapprovalhandler: 'job_information.paymentapprovalhandler',
		paymentapproval1: 'job_information.paymentapproval1',
		current_state: 'current_state',
		new_state: 'new_state',
		vat_rate: 'vat_rate',
		claim_value: ['claim_value', 'job_information.claim_value'],
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		update_excess: ['update_excess', 'job_information.update_excess'],
		query: [
			(qr, storeObj) => {
				let iaq = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo &&
					storeObj['selectedContext']?.fullItemTwo.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery)) {
						iaq = storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery;
					} else {
						iaq = [
							{
								...storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
							}
						];
					}
				}
				const invoicequery = [...iaq, { query: qr, queryreply: '' }];
				return invoicequery;
			},
			'job_information.invoicequery'
		]
	}
};
