import {
	BigFormService,
	ChangeManifestState,
	Flow_0_0_2,
	getAllInfo,
	getCurrentUser,
	getOwnJobTeamleadersStaff,
	getOwnJobTeamPositions,
	getSelectedItemTwo,
	GetSPOwnJobTeamLeaderStaff,
	GetSPOwnJobTeamPositions,
	getSubmissionData,
	ManifestController,
	ModalService,
	SetNextNode
} from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators, generateRange } from '@flexus/utilities';
import { map, skipWhile, take, debounceTime, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { gql } from 'graphql-tag';
import { transformJobsToJobCardRequest, transformJobsToDeviceDetails } from './transform.functions';
import { RemoveError } from '@flexus/error-handler';
import { values, flatten } from 'ramda';
import { empty, from, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { environment } from 'apps/studio/src/environments/environment';

const today = new Date();
const tomorrow = new Date(today);
const isAfterOne = today.getHours() > 12;
tomorrow.setDate(tomorrow.getDate() + 1);

export const AddNewJobCard: Flow_0_0_2 = {
	id: 'add_new_jobcard',
	name: 'add new job card',
	startNode: 'CustomerDetails',
	serverCalls: {
		provinceList: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return sq
					.queryObject(
						gql`
							{
								allInfo {
									active_regions
								}
							}
						`,
						store
					)
					.pipe(
						take(1),
						map(res => {
							return (res as any)?.active_regions;
						})
					);
			}
		}
	},
	deactivateStateGuard: (guardService: any, comp, router, currentRoute, currentState, nextState) => {
		return from(guardService.indexedDbService.currentItem.get('currentItem')).pipe(
			withLatestFrom(guardService.canGo$),
			switchMap(([currentItem, canGo]: any) => {
				return <Observable<any>>guardService.store.select(getSelectedItemTwo).pipe(
					take(1),
					map((itemOne: any) => {
						if (!canGo && currentItem && currentItem.applicant && currentItem.loan_information) {
							if (itemOne && itemOne?.tempKey && guardService.bf.bigForm.touched) {
								// Local draft has been edited
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this local draft.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Save Changes Locally',
											color: 'primary',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												const localDraft = {
													...currentItem,
													tempKey: itemOne?.tempKey,
													state: 169
												};
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.claimInDraft.put(localDraft).then(() => {
														guardService.indexedDbService.currentItem.delete('currentItem');
													});
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (itemOne && itemOne?.tempKey && !guardService.bf.bigForm.touched) {
								// Local draft opened but not edited yet
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this local draft.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (itemOne && !itemOne.tempKey && !guardService.bf.bigForm.touched) {
								// Any Server Draft not edited
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from your current view.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (itemOne && !itemOne.tempKey && guardService.bf.bigForm.touched) {
								// Any server draft that is edited
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this draft.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne && !guardService.bf.bigForm.touched) {
								// Creating New Claim but forms has not been touched yet
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving from your current view.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne && guardService.bf.bigForm.touched) {
								// In Claim creation and form has been touched
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									// inst.message = '';
									inst.setMessage(['You are moving from your current view.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							}
						} else {
							return true;
						}
					})
				);
			})
		);
	},
	activateStateGuard: () => {
		return of(true);
	},
	onStateInit: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: instance => instance.store.dispatch(new CollapseActionPanel()),
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['what-matters']),
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	header: {
		title: 'Schedule Installation Appointment',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	nodes: {
		CustomerDetails: {
			name: 'Customer Details',
			showTabs: true,
			initFormFields: (bf: any, item: any, instance: any, sq: any, store: any) => {
				bf.bigForm.addControl('checked_customer_details', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('checked_address_confirmation', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('checked_installation_details', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('checked_appointments', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('checked_current_setup', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('checked_team_lead', new UntypedFormControl(null, [Validators.required]));

				bf.bigForm.patchValue({ checked_customer_details: true });
				// bf.addControl('jobs', new UntypedFormArray([]));

				store
					.select(getCurrentUser)
					.pipe(take(1))
					.subscribe((user: any) => {
						const { full_name, contact_number } = user?.user;
						const email_address = user?.email;
						const service_provider_id = user?.user?.sp?.id.toString();
						bf.bigForm.addControl('sil_agent', new UntypedFormControl(false));
						bf.bigForm.addControl('handler', new UntypedFormControl(full_name));
						bf.bigForm.addControl('handlercontact', new UntypedFormControl(contact_number));
						bf.bigForm.addControl('handleremail', new UntypedFormControl(email_address));
						bf.bigForm.addControl('service_provider_id', new UntypedFormControl(service_provider_id, [Validators.required]));
						bf.bigForm.addControl('claim_type_id', new UntypedFormControl(40, [Validators.required]));
						bf.bigForm.addControl('sub_section_id', new UntypedFormControl(6, [Validators.required]));
					});

				bf.bigForm.addControl('new_state', new UntypedFormControl(1));

				// For maps initialisation. Otherwise validators do not pick it up
				let suburb_code, latitude, longitude, province, area_code, area;

				if (bf.bigForm.get('latitude')) {
					latitude = bf.bigForm.get('latitude')?.value;
					bf.bigForm.get('latitude')?.setValidators([Validators.required]);
				} else {
					bf.addControl('latitude', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('longitude')) {
					longitude = bf.bigForm.get('longitude')?.value;
					bf.bigForm.get('longitude')?.setValidators([Validators.required]);
				} else {
					bf.addControl('longitude', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('suburb_code')) {
					suburb_code = bf.bigForm.get('suburb_code')?.value;
					// bf.bigForm.get('suburb_code')?.setValidators([Validators.required]);
				} else {
					bf.addControl('suburb_code', new UntypedFormControl(null));
				}

				if (bf.bigForm.get('province')) {
					province = bf.bigForm.get('province')?.value;
					bf.bigForm.get('province')?.setValidators([Validators.required]);
				} else {
					bf.addControl('province', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('area_code')) {
					area_code = bf.bigForm.get('area_code')?.value;
					bf.bigForm.get('area_code')?.setValidators([Validators.required]);
				} else {
					bf.addControl('area_code', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('area')) {
					area = bf.bigForm.get('area')?.value;
					bf.bigForm.get('area')?.setValidators([Validators.required]);
				} else {
					bf.addControl('area', new UntypedFormControl(null, [Validators.required]));
				}

				if (!latitude || !longitude) {
					latitude = null;
					longitude = null;
				}

				// add flag to set this node checked
				bf.addControl(
					'client_details',
					new UntypedFormGroup({
						clarity_customer_number: new UntypedFormControl('', [Validators.minLength(9), CustomValidators.hardMaxLength(9)]),
						first_name: new UntypedFormControl(null, [
							Validators.required,
							Validators.minLength(2),
							CustomValidators.hardMaxLength(100),
							CustomValidators.onlyAllowedASCII([32, 39, 44, 45, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
						]),
						surname: new UntypedFormControl('', [
							Validators.required,
							Validators.minLength(2),
							CustomValidators.hardMaxLength(100),
							CustomValidators.onlyAllowedASCII([32, 39, 44, 45, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
						])
					})
				);
				bf.addControl(
					'contact_details',
					new UntypedFormGroup({
						contact_number: new UntypedFormControl('', [
							Validators.required,
							CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
							CustomValidators.contact_number,
							Validators.minLength(10),
							CustomValidators.hardMaxLength(12)
						]),
						cell_number: new UntypedFormControl('', [
							Validators.required,
							CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
							CustomValidators.numeric,
							Validators.minLength(10),
							CustomValidators.hardMaxLength(12)
						]),
						email: new UntypedFormControl('', [Validators.required, CustomValidators.onlyAllowedASCII(generateRange(32, 126, 1))])
					})
				);
				bf.addControl(
					'insured_address',
					new UntypedFormGroup({
						complex: new UntypedFormControl('', CustomValidators.hardMaxLength(64)),
						street_address: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)]),
						suburb: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)]),
						city: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)]),
						postal_code: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(5)]),
						province: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)])
					})
				);

				bf.addControl(
					'current_environment',
					new UntypedFormGroup({
						no_view_environments: new UntypedFormControl(null, [Validators.required]),
						dwelling_type: new UntypedFormControl(null, [Validators.required]),
						no_of_tvs: new UntypedFormControl(null, [Validators.required]),
						type_of_tvs: new UntypedFormControl(null, [Validators.required]),
						mounted_installation: new UntypedFormControl(null, [Validators.required])
					})
				);
				store.dispatch(new GetSPOwnJobTeamLeaderStaff());
			},
			component: 'CustomerDetailsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'AddressConfirmation'
				}
			]
		},
		AddressConfirmation: {
			name: 'Maps',
			showTabs: true,
			serverCalls: {
				provinceList: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											active_regions
										}
									}
								`,
								store
							)
							.pipe(
								take(1),
								map(res => {
									return (res as any)?.active_regions;
								})
							);
					}
				},
				policyAddress: {
					errorMessage: '',
					directCall: (http: any, store: any, sq: any) => {
						// clear any errors
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											submissionData {
												loan_information {
													propertycomplex
													propertycomplexunitnumber
													propertystreetaddress
													propertysuburb
													propertycity
													suburbcode
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile((returned: any) => !returned),
								take(1),
								map((result: any) => {
									const res = result as any;
									const addressArray = [];

									if (res?.propertycomplex) addressArray.push(`${res?.propertycomplexunitnumber} ${res?.propertycomplex}`);
									if (res?.propertystreetaddress) addressArray.push(res?.propertystreetaddress);
									if (res?.propertysuburb) addressArray.push(res?.propertysuburb);
									if (res?.propertycity) addressArray.push(res?.propertycity);
									if (res?.suburbcode) addressArray.push(res?.suburbcode);

									return addressArray.join(', ');
								})
							);
					}
				},
				policyAddressObject: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											submissionData {
												loan_information {
													propertycomplex
													propertycomplexunitnumber
													propertystreetaddress
													propertysuburb
													propertycity
													suburbcode
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1)
							);
					}
				}
			},
			initFormFields: (bf: any, item, instance, sq, _store: Store) => {
				bf.bigForm.patchValue({ checked_address_confirmation: true });
				_store.dispatch(new GetSPOwnJobTeamPositions());
			},
			inputs: {
				title: 'Address Confirmation',
				addressConfirmationText: 'Customer Address',
				addressType: 'Installation Address'
			},
			component: 'AddressConfirmationComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'InstallationJobCard'
				}
			]
		},
		InstallationJobCard: {
			checkValidityForFields: ['skill_id', 'decoder_type', 'jobs'],
			serverCalls: {
				claimDetailsInfo: {
					errorMessage: '',
					directCall: (http: any, store: any, sq: any) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						// store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											claim_types {
												id
												name
												description
												skill_groups
												priority
											}
											skills {
												id
												name
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile((x: any) => !x),
								take(1),
								map(({ claim_types, skills }) => {
									const clonedSkills = [...skills];

									clonedSkills.sort((x: any, y: any) => (x.id === 92 ? -1 : y.id === 92 ? 1 : 0));

									return { claim_types, skills: clonedSkills };
								})
							);
					}
				},
				decoderTypes: {
					errorMessage: `Couldn't get decoder types`,
					directCall: (_http: HttpClient, _store: Store) => {
						const claim_type_id: number = 40;
						return _http.post(`${environment.api_url}v1/mc_actions/get_voucher_types/`, { claim_type_id: claim_type_id }).pipe(
							skipWhile((data: any) => !data),
							take(1),
							map((result: any) => {
								const dectypes: any[] = result?.payload;
								const dectypeDisplayArray: any[] = [];

								dectypes.forEach(element => {
									const obj = {
										display: element.name,
										value: element.id
									};
									dectypeDisplayArray.push(obj);
								});
								return dectypeDisplayArray;
							})
						);
					}
				}
			},
			initFormFields: (_bf: any, item: any, component: any) => {
				_bf.bigForm.patchValue({ checked_installation_details: true });
				// _bf.addControl(
				// 	'installation',
				// 	new UntypedFormGroup({
				// 		skill: new UntypedFormControl('null', [Validators.required]),
				// 		to_purchase: new UntypedFormControl('null', [Validators.required]),
				// 		decoder_type: new UntypedFormControl('null', [Validators.required])
				// 	})
				// );
				_bf.addControl('jobs', new UntypedFormArray([component.createJob()]));
			},
			name: 'Installation',
			showTabs: true,
			component: 'SpAddJobComponent',

			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'JobAppointment'
				}
			]
		},
		JobAppointment: {
			checkValidityForFields: ['appointmentTimePicker', 'appointmentDatePicker'],
			name: 'Appointment',
			initFormFields: bf => {
				bf.bigForm.patchValue({ checked_appointments: true });

				bf.addControl(
					'on_site_person',
					new UntypedFormControl('', [
						Validators.required,
						Validators.minLength(2),
						CustomValidators.hardMaxLength(140),
						CustomValidators.onlyAllowedASCII([32, 39, 44, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
					])
				);
				bf.addControl('on_site_notes', new UntypedFormControl('', [CustomValidators.onlyAllowedASCII([10, 13]?.concat(generateRange(32, 126, 1)))]));
				bf.addControl(
					'on_site_contact',
					new UntypedFormControl('', [
						Validators.required,
						CustomValidators.numeric,
						Validators.minLength(10),
						CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
						CustomValidators.hardMaxLength(12)
					])
				);
				bf.addControl('timepicker', new UntypedFormControl(''));
				bf.addControl('selectedDateType', new UntypedFormControl(''));
				bf.addControl('isSameAppointment', new UntypedFormControl(''));
				bf.addControl('appointments', new UntypedFormArray([]));
			},
			showTabs: true,
			inputs: {
				minHour: 0,
				maxHour: 23,
				checkAfterHours: false
			},
			serverCalls: {
				appointmentTypes: {
					errorMessage: '',
					directCall: (http: any, store: any, sq: any) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						// store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											appointment_types {
												id
												name
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile((f: any) => !f),
								take(1),
								map(values),
								map<any, any>(flatten)
							);
					}
				},
				appointmentData: {
					errorMessage: '',
					directCall: (http: any, store: any, sq: any) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											appointment_types {
												id
												name
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(values),
								map<any, any>(flatten)
							);
					}
				}
				// publicHolidays: {
				// 	serviceVariable: 'mulSpService',
				// 	functionName: 'getPublicHolidays',
				// 	responseSlice: 'payload',
				// 	errorMessage: 'Could not get public holidays!'
				// }
			},
			component: 'ClaimAppointmentsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'CurrentSetup'
				}
			]
		},
		CurrentSetup: {
			name: 'Current Setup',
			showTabs: true,
			serverCalls: {
				installationDetails: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											config_options {
												general {
													installation_details_options {
														tv_count
														tv_type
														viewing_environs
														dwelling_type
														mounted_installation
													}
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(values),
								map<any, any>(flatten)
							);
					}
				}
			},
			initFormFields: (bf, item, comp, sq) => {
				bf.bigForm.patchValue({ checked_current_setup: true });
			},
			inputs: {
				is_sp_job_card: true
			},
			component: 'CurrentSetupComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'AssignTeamLead'
				}
			]
		},
		AssignTeamLead: {
			name: 'Team Lead',
			showTabs: true,
			component: 'AssignTLComponent',
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: (_bf: BigFormService, item: any, instance: any, sq: any, _store: Store) => {
				_bf.bigForm.addControl('assign_teamleader_id', new UntypedFormControl('', [Validators.required]));
				_bf.bigForm.patchValue({ checked_team_lead: true });
			},
			serverCalls: {
				tlListData: {
					errorMessage: 'No team leaders returned',
					directCall: (http: HttpClient, store: Store, sq: any, bf: BigFormService) => {
						return store.select(getOwnJobTeamleadersStaff).pipe(
							skipWhile(x => !x),
							take(1),
							map(data => data.teamleaders)
						);
						// return http.post(`${environment.api_url}v1/sp_action/manage_list_staff/`, { role_id: 10, image: true }).pipe(
						// 	pluck('payload'),
						// 	skipWhile((response: any) => !response),
						// 	map((teamleaders: any[]) =>
						// 		teamleaders.map(teamLeader => {
						// 			const namestringarr = teamLeader.full_name?.split(' ');
						// 			let mono1, mono2;
						// 			if (!namestringarr[0]) {
						// 				mono1 = ' ';
						// 			} else {
						// 				mono1 = namestringarr[0]?.slice(0, 1);
						// 			}
						// 			if (!namestringarr[1]) {
						// 				mono2 = '';
						// 			} else {
						// 				mono2 = namestringarr[1]?.slice(0, 1);
						// 			}
						// 			const monogram = mono1 + mono2;
						// 			return {
						// 				display: teamLeader.full_name,
						// 				value: teamLeader.id,
						// 				image: teamLeader.image,
						// 				monogram: monogram,
						// 				teamLeader,
						// 				shouldHaveImage: true
						// 			};
						// 		})
						// 	)
						// );
					}
				},
				skills: {
					serviceVariable: 'spService',
					functionName: 'getSkills',
					responseSlice: 'skills',
					errorMessage: 'No skills were found!'
				},
				jobLocationData: {
					errorMessage: 'The job location was not returned!',
					directCall: (_http: HttpClient, _st: Store, _sq: any, _bf: BigFormService) => {
						const lat = _bf.bigForm.get('latitude').value;
						const lng = _bf.bigForm.get('longitude').value;
						return of({ lat, lng });
					}
				},
				teamleaderLocations: {
					errorMessage: 'Team leader locations not returned!',
					directCall: (http: any, store: any, sq: any, bf: any) => {
						// debugger;
						return store.select(getOwnJobTeamPositions).pipe(
							skipWhile(x => !x),
							take(1),
							map((data: any) => data.teampositions)
						);

						// return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
						// 	pluck('payload'),
						// 	filter((x: any) => !!x),
						// 	map((teamleaders: any[]) => {
						// 		return teamleaders;
						// 	})
						// );
					}
				}
			},
			inputs: [
				{
					disableOptionWhen: {
						message: 'Upload profile picture to select team leader',
						evaluationCriteria: {
							property: 'image',
							operator: '!'
						}
					},
					includeUserDisplay: true,
					tlListData$: 'tlListData',
					joblocation$: 'jobLocationData',
					teamleaderLocations$: 'teamleaderLocations'
				}
			],
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'JobSubmissionSummary'
				}
			]
		},
		JobSubmissionSummary: {
			name: 'Submit',
			showTabs: true,
			checkValidityForFields: [
				'checked_customer_details',
				'checked_address_confirmation',
				'checked_installation_details',
				'checked_appointments',
				'checked_current_setup',
				'checked_team_lead'
			],
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Submit Job Card To Proceed',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXAlertMessagesComponent',
						inputs: { alertMessages$: 'alertMessages' }
					}
				]
			},
			serverCalls: {
				alertMessages: {
					errorMessage: 'Could not retrieve the form errors',
					directCall: (_http: any, _store: any, _sq: any, _bf: BigFormService) => {
						if (!!_bf.bigForm.get('jobs')) {
							const jobs = _bf.bigForm?.value?.jobs;
						}
						_bf.bigForm?.updateValueAndValidity();
						return _bf.bigForm?.valueChanges?.pipe(
							debounceTime(100),
							switchMap(() => {
								return _bf.retrieveErrors$();
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'Save as Draft',
					// nextNode: 'SubmissionSuccess',
					// optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						saveAsServerDraft: {
							errorMessage: 'Could not save the draft',

							directCall: (_http: HttpClient, _store: Store, _q: any, _bf: BigFormService, _ctrl: ManifestController<any>, _modal: ModalService) => {
								return _store.select(getSubmissionData).pipe(
									skipWhile(x => !x),
									take(1),
									switchMap((details: any) => {
										const { jobcardrequest } = details;
										if (jobcardrequest === undefined || jobcardrequest === null || (Array.isArray(jobcardrequest) && jobcardrequest.length === 0)) {
											_modal.openModalDirectly((_instance, _store, bf) => {
												(_instance.type = 'warning'), _instance.setMessage(['Please specify a job skill']);
												_instance.navButtons = [
													{
														text: 'OK',
														clickHandler: () => {
															_instance.controller.dispatch(new SetNextNode('InstallationJobCard'));
														},
														linkType: 'close'
													}
												];
											});
										} else {
											if (window.navigator.onLine) {
												const claim = details;
												return _http.post(`${environment.api_url}v1/claim_action/create_claim/`, { ...claim, oj_draft: true, new_state: 327 });
											}
										}
										// _ctrl.dispatch(new SetNextNode('SubmissionSuccess'));
									})
								);
								return of();
							},
							followUpSuccessCalls: {
								response: {
									errorMessage: 'Navigation broke',
									directCall: (_http: HttpClient, _stor: Store, q: any, _bf: BigFormService, _cntrl: ManifestController<any>) => {
										_cntrl.dispatch(new SetNextNode('SubmissionSuccess'));
										return of();
									}
								}
							}
						}

						// saveAsServerDraft: {
						// 	errorMessage: 'Could not save the draft!',
						// 	serviceVariable: 'mulSpService',
						// 	functionName: 'checkNetworkAndSaveDraft',
						// 	followUpSuccessCalls: {
						// 		deleteCurrentEditableClaim: {
						// 			errorMessage: 'Could not delete the current editable claim!',
						// 			serviceVariable: 'mulService',
						// 			functionName: 'deleteCurrentEditableItem'
						// 		}
						// 	}
						// }
					}
				},
				{
					text: 'Submit',
					linkType: 'portal',
					optIntoValidation: true,
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'This claim could not be created!',
							serviceVariable: 'mulSpService',
							functionName: 'createClaim',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'mulSpService',
									functionName: 'deleteCurrentEditableItem'
								},
								clearCachedClaims: {
									errorMessage: 'Could not clear cached claims!',
									directCall: (http, store, sq, bf, controller, modal) => {
										// if (!!bf.bigForm?.value?.is_cat === true) {
										// 	modal.openModalDirectly(inst => {
										// 		inst.heading = 'Appointment will be ignored';
										// 		inst.type = 'info';
										// 		inst.subheading = 'This claim is part of a catastrophe.';
										// 		inst.message = 'Notify customer that appointment times cannot be met based on the volume of claims.';
										// 		inst.navButtons = [
										// 			{
										// 				text: 'Continue',
										// 				color: 'primary',
										// 				linkType: 'close'
										// 			}
										// 		];
										// 		inst.closeButton = false;
										// 	});
										// }
										bf.bigForm.reset();
										Object.keys(bf.bigForm.controls)?.forEach(control => bf.bigForm.removeControl(control));
										controller.dispatch(
											new ChangeManifestState(state => {
												return {
													...state,
													viewData: {
														...state.viewData,
														default: []
													}
												};
											})
										);
										return empty();
									}
								}
							},
							followUpFailCalls: {
								createLocalDraft: {
									errorMessage: 'Could not create the claim in local draft',
									serviceVariable: 'mulSpService',
									functionName: 'saveAsLocalDraft',
									followUpSuccessCalls: {
										deleteCurrentEditableClaim: {
											errorMessage: 'Could not delete the current editable claim!',
											serviceVariable: 'mulService',
											functionName: 'deleteCurrentEditableItem'
										},
										navigateToWorkflow: {
											errorMessage: '',
											serviceVariable: 'mulService',
											functionName: 'navigateToWorkflow'
										}
									}
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			serverCalls: {
				copyText: {
					errorMessage: 'Could not retrieve new claim number!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											response {
												payload {
													mid
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(values),
								map<any, any>(flatten),
								switchMap((val: any) => {
									if (!!val) return val;
									else return null;
								}),
								tap(() => {
									store.dispatch(new CollapseActionPanel());
								})
							);
					}
				}
			},
			inputs: {
				copyTextHeading: 'Claim Number',
				autoClose: false
			},
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		claim_type_id: 'claim_type_id',
		sub_section_id: 'sub_section_id',

		handler: 'loan_information.handler',
		handlercontact: 'loan_information.handlercontact',
		handleremail: 'loan_information.handleremail',

		// Customer Details
		'client_details.clarity_customer_number': ['applicant.claimantpoliceynum'],
		'client_details.first_name': ['applicant.first_name', 'name'],
		'client_details.surname': ['applicant.surname', 'surname'],
		'contact_details.contact_number': ['loan_information.contactnumber', 'loan_information.cellnumber'],
		'contact_details.cell_number': ['loan_information.cell_number'],
		'contact_details.email': ['loan_information.email'],

		// Address confirmation
		latitude: ['location.lat', 'loan_information.claimlatitude'],
		longitude: ['location.long', 'loan_information.claimlongitude'],
		suburb_code: ['postal_code', 'loan_information.suburbcode'],
		province: ['claimprovince'],
		'insured_address.province': ['loan_information.claimprovince'],
		area: ['area'],
		'insured_address.complex': ['loan_information.propertycomplex'],
		'insured_address.complex_unit_number': ['loan_information.propertycomplexunitnumber'],
		'insured_address.street_address': ['address', 'loan_information.propertystreetaddress'],
		'insured_address.suburb': ['suburb', 'loan_information.propertysuburb'],
		'insured_address.city': ['loan_information.propertycity'],
		'insured_address.postal_code': ['postal_code', 'loan_information.suburbcode', 'loan_information.postal_code'],
		// Current Setup

		'current_environment.no_view_environments': ['loan_information.current_environment.no_view_environments'],
		'current_environment.dwelling_type': ['loan_information.current_environment.dwelling_type'],
		'current_environment.no_of_tvs': ['loan_information.current_environment.no_of_tvs'],
		'current_environment.type_of_tvs': ['loan_information.current_environment.type_of_tvs'],
		'current_environment.mounted_installation': ['loan_information.current_environment.mounted_installation'],

		jobs: [
			[transformJobsToJobCardRequest, 'jobcardrequest'],
			[transformJobsToJobCardRequest, 'loan_information.jobcardrequest'],
			[transformJobsToDeviceDetails, 'loan_information.device_details']
		],

		whatmatters: 'loan_information.whatmatters',

		service_provider_id: ['loan_information.sp_id'],
		assign_teamleader_id: [
			(_id: any, _storeobj: any, _bf: BigFormService) => {
				let teamlead;
				if (Array.isArray(_id)) {
					teamlead = _id[0];
				} else {
					teamlead = _id;
				}
				return teamlead;
			},
			'loan_information.tl_id'
		],

		// Claim Appointment
		on_site_contact: 'loan_information.onsitecontact',
		on_site_notes: 'loan_information.onsitenotes',
		on_site_person: 'loan_information.onsiteperson'
	},
	flowErrorMessages: {
		'checked_customer_details:required': 'Please fill in the Customer Details',
		'checked_address_confirmation:required': 'Please confirm the address on the map!',
		'checked_installation_details:required': 'Please fill in the installation details',
		'checked_appointments:required': 'Please set an Appointment',
		'checked_current_setup:required': 'Please specify the current setup',
		'checked_team_lead:required': 'Please pick a team leader',
		// 'clarity_customer_number:required': 'The Clarity customer number is required.',
		'first_name:required': 'Applicant first name is required.',
		'first_name:minLength': 'First name needs a minimum of 3 characters.',
		'first_name:onlyAllowedASCII': 'First name field contains invalid character(s).',
		'first_name:text': 'Applicant first name contains non-next characters.',
		'surname:required': 'Surname is required. In (Customer Details)',
		'surname:clarity_customer_number': 'Surname field contains invalid characters.',
		'contact_number:required': 'Contact number is required (in Customer Details).',
		'contact_number:contact_number': 'Contact number is invalid (in Customer Details).',
		'cell_number:required': 'Alternative contact number is required (in Customer Details).',
		'email:required': 'Email address is required (in Customer Details)',
		'province:required': 'Province is required (in Policy Details)',
		'email:email': 'You need to enter a valid email address (in Customer Details)',
		'street_address:required': 'Property street address is required (in Customer Details)',
		'suburb:required': 'Property suburb is required (in Customer Details)',
		'postal_code:required': 'Postal code is required (in Customer Details)',
		'addressconfirmation:required': 'Please confirm property address',
		'longitude:required': 'No address longitude has been picked up (in Maps)',
		'latitude:required': 'No address latitude has been picked up (in Maps)',
		'jobs:allFieldsRequired': 'All fields are required',
		'skill_id:required': 'Skill is required (in Installation)',
		// 'to_purchase:required': 'To purchase? is required (In Installation)',
		'decoder_type:required': 'Decoder type is required (In Installation)',
		'on_site_contact:required': 'On site contact number required (in Appointments)',
		'on_site_contact:contact_number': 'On site contact number is invalid (in Appointments)',
		'on_site_person:required': 'On site person name is required (in Appointments)',
		'no_view_environments:required': 'Number of viewing environments is required (in Current Setup)',
		'dwelling_type:required': 'Dwelling type is required (in Current Setup)',
		'no_of_tvs:required': 'Number of TVs is required (in Current Setup)',
		'mounted_installation:required': 'Specify if mounted installation allowed (in Current Setup)',
		'type_of_tvs:required': 'Type of TVs is required (in Current Setup)',
		'appointmentDatePicker:required': 'Job appointment date is required (in Appointments)',
		'appointmentTimePicker:required': 'Job appointment time is required (in Appointments)',
		'whatmatters:required': 'What matters is required (in What Matters)'
	}
};
