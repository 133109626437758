import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	getAvailableJobs,
	SelectAvailableJob,
	ModalService,
	getJobResponses,
	RespondToJob,
	CheckAvailability,
	ShowPingModal,
	RespondToJobs,
	ShowJobNotificationsModal
} from '@flexus/core';
import { skipWhile, map, filter, take } from 'rxjs/operators';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CollapseActionPanel, LoadActionPanelComponent } from '../../../app-shell-features/action-panel/store';
import moment from 'moment';
import { JOB_INTEREST } from '@flexus/utilities';

interface JobLocationConfig extends google.maps.MapOptions {
	scriptId?: string;
	url?: string;
}

@Component({
	selector: 'flx-job-request-ping',
	templateUrl: './job-request-ping.component.html',
	styleUrls: ['./job-request-ping.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXAPJobRequestPingComponent implements OnInit, OnDestroy {
	@Input()
	currentUser$: Observable<any>;
	public availableJobs$!: Observable<any[]>;
	public jobResponses!: Observable<any[]>;
	public availabilityCheckSub!: Subscription;
	public availableJobsSub!: Subscription;

	lastServerJobCountCheck!: any;
	lastJobsMeetingCriteriaCheck!: any;
	lasttimestampCheck!: any;
	getJobsInterval!: any;
	showJobsList = true;
	showJobDetail = false;
	disableJobNotificationButton = false;
	public ignored: boolean;
	public declined: boolean;

	paginationVariables = {
		pageSize: 4,
		currentPage: 1
	};

	constructor(@Inject('environment') private _environment: any, private store: Store<any>, private modal: ModalService, private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this.availableJobs$ = forkJoin([
			this.store.select(getAvailableJobs)?.pipe(
				filter((x: any) => !!x && Array.isArray(x)),
				take(1),
				map((data: any) => data)
			),
			this.store.select(getJobResponses).pipe(
				skipWhile((response: any) => !response),
				take(1),
				map((data: any) => data)
			)
		]).pipe(
			map(([jobs, responses]: any) => {
				console.log('MOVED JOBS', jobs);
				console.log('MOVED RESPONSES', responses);

				const clonedjobs = jobs.slice();
				const clonedresponses = responses.slice();
				const filteredjobs = clonedresponses.filter((job: any) => {
					return clonedjobs.map((res: any) => res.id).includes(job.id);
				});
				// let filteredJobs: any[] = [];
				// for (let i = 0; i < clonedjobs.length; i++) {
				// 	for (let j = 0; j < clonedresponses.length; j++) {
				// 		if (clonedjobs[i]?.id !== clonedresponses[j]?.id) {
				// 			filteredJobs.push(clonedjobs[i]);
				// 			console.log('CLONED JOB', clonedjobs[i]);
				// 		}
				// 	}
				// }
				console.log('FILTERED JOBS', filteredjobs);

				const modClonedJobs: any[] = [];
				clonedjobs.forEach((item: any) => {
					//change array name here
					let skill: string = '';
					let appointment: any = {};
					if (item?.claim_type_id === '37') {
						skill = `Own Stock ${item?.skill}`;
					}
					if (item.voucher) {
						skill = item?.voucher;
					} else {
						skill = `${item.skill}`;
					}
					appointment = {
						after_hours: `${item?.appointment?.after_hours}`,
						appointment_name: `${item?.appointment?.appointment_name}`,
						appointment_type_id: `${item?.appointment?.appointment_type_id}`,
						range_start: `${item?.appointment?.range_start}`,
						id: `${item?.id}`,
						range_end: `${item?.appointment?.range_end}`
					};
					const modObj = {
						...appointment,
						address: `${item?.address}`,
						area: `${item?.area}`,
						id: `${item?.id}`,
						location: `${item?.location}`,
						skill: `${skill}`,
						suburb: `${item?.suburb}`
					};
					modClonedJobs.push(modObj);
				});
				return modClonedJobs;
			})
		);
		// PLEASE REVIEW THIS IN PR
		// this.store
		// 	.select(getJobResponses)
		// 	.pipe(skipWhile((res: any) => !res))
		// 	.subscribe((data: any) => {
		// 		console.log('JOB RESPONSE DATA', data);
		// 		this.jobResponses = data;
		// 		this.jobResponses.forEach((response: any) => {
		// 			if (response) {
		// 				this.availableJobs$.subscribe(jobArray => {
		// 					for (let i = 0; i < jobArray.length; i++) {
		// 						const avjob = jobArray[i];
		// 						const responded = response as any;
		// 						const respondedid = responded.id;

		// 						if (avjob.id === respondedid) {
		// 							if (responded.interested === -1) {
		// 								// this.declined = true;
		// 							} else if (responded.interested === 0) {
		// 								jobArray.splice(1, i);
		// 								// this.ignored = true;
		// 							} else if (responded.interested === 1) {
		// 								jobArray.splice(1, i);
		// 							}
		// 						}
		// 					}
		// 					return jobArray;
		// 				});
		// 			}
		// 		});
		// 	})
		// 	.unsubscribe();
		// this.cdr.detectChanges();
	}

	page(event) {
		this.paginationVariables.currentPage = event;
	}

	refreshAvailableJobs() {
		this.store.dispatch(new CheckAvailability());
	}

	showPingWindow() {
		this.store.dispatch(new ShowPingModal());
	}

	showJobNotifications() {
		this.store.dispatch(new ShowJobNotificationsModal());
	}

	ignoreAllJobs() {
		const time_of_response = moment().valueOf();
		this.availableJobsSub = this.availableJobs$
			.pipe(
				filter((jobs: any) => jobs.length > 0),
				take(1)
			)
			.subscribe((jobs: any) => {
				this.respondToJobs(jobs.map((job: any) => ({ job_id: job?.id, job: job, interest: JOB_INTEREST.IGNORED, time_of_response })));
			});
	}

	respondToJobs(jobs: any) {
		// this.availableJobs$ = of([]);

		this.store.dispatch(new RespondToJobs(jobs));
	}

	respondToJob(value: any) {
		const params = {
			job_id: value.job_id,
			spm: value.spm,
			interested: value.interest
		};

		this.availableJobs$.pipe(
			map(jobs => {
				const index = jobs.indexOf(value);

				if (index !== -1) {
					jobs = jobs.splice(index, 1);
				}
				return jobs;
			})
		);

		this.store.dispatch(new RespondToJob({ job_id: value.job.id, job: value.job, interested: value.interest }));
	}

	selectAvailableJob(id: string) {
		this.store.dispatch(new LoadActionPanelComponent('FLXAPJobRequestPingDetailComponent'));
		this.store.dispatch(new SelectAvailableJob(id));
	}

	toggleView() {
		if (this.showJobsList) {
			this.showJobsList = false;
			this.showJobDetail = true;
		} else if (!this.showJobsList) {
			this.showJobsList = true;
			this.showJobDetail = false;
		}
	}

	openIgnoreWarningModal() {
		this.modal.openModalDirectly((instance: any) => {
			instance.type = 'warning';
			instance.heading = 'Ignore all Job Requests';
			instance.message = 'Are you sure you want to ignore all job requests?';
			instance.navButtons = [
				{
					text: 'Cancel',
					linkType: 'close',
					color: 'default'
				},
				{
					text: 'Yes',
					clickHandler: (event: any) => {
						this.ignoreAllJobs();
						this.store.dispatch(new CollapseActionPanel());
					},
					linkType: 'close'
				}
			];
		});
	}

	ngOnDestroy() {
		this.disableJobNotificationButton = false;
	}
}
