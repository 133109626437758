import { Flow_0_0_2 } from '@flexus/core';

export const BUI_29: Flow_0_0_2 = {
	id: '29',
	name: 'TL_declined',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: '',
	nodes: {}
};
