import { Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { take, map, filter, skipWhile, first } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const BUI_72: Flow_0_0_2 = {
	id: '72',
	name: 'client_requested_cil_from_sp',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map(itemOne => {
					if (itemOne) {
						return 'Client Requested Cash in Lieu from SP : ' + itemOne?.loan_information?.mavenclaimnumber + ' - ' + itemOne?.applicant?.surname;
					} else {
						return 'Client Requested Cash in Lieu from SP';
					}
				})
			);
		},
		controls: () => () => []
	},
	actionPanel: instance =>
		setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp', 'sp-details'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},

	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Phone customer for banking details'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'ConfirmJob',
	nodes: {
		ConfirmJob: {
			component: 'FileViewWithExtraComponent',
			initFormFields: bf => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(56));
				bf.bigForm.addControl('currAvsResult', new UntypedFormControl(`-2`));
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Cannot get retrieve data',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map(vals => vals.claim),
							map((vals: any) => {
								const res = {
									'Claim Type': vals && vals.loan_information ? vals.loan_information.claimtype : '',
									Address:
										vals && vals.loan_information
											? vals.loan_information.propertystreetaddress + ', ' + vals.loan_information.propertysuburb + ', ' + vals.loan_information.propertycity
											: '',
									'Client Name': vals && vals.applicant ? vals.applicant?.first_name + ' ' + vals.applicant?.surname : '',
									'Contact Number': vals && vals.loan_information ? vals.loan_information.contactnumber : '',
									'Cell Number': vals && vals.loan_information ? vals.loan_information.cellnumber : '',
									'What Matters': vals && vals.loan_information ? vals.loan_information.whatmatters : ''
								};
								return [res];
							})
						);
					}
				}
			},
			inputs: {
				inputHeading: 'Job changed to Cash in Lieu',
				subHeading:
					'The client requested that the Team Leader change the job to Cash in Lieu. A new job card has been created and now needs support documents and banking details.',
				fileDoesNotExistMessage: ''
			},
			navs: [
				{
					text: 'Continue',
					serverFirst: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemTwo.id': 'job_id',
		'itemTwo.state': 'current_state'
	}
};
