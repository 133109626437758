import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { BuiCreateClaim } from './bui-create-claim';
import { skipWhile, take, map } from 'rxjs/operators';
import moment from 'moment';
import { UUID } from 'angular2-uuid';
import { stringToBoolean, checkForValidDate, CustomValidators, generateRange } from '@flexus/utilities';

export const BuiEditClaim: Flow_0_0_2 = {
	// From create Claim
	...BuiCreateClaim,

	id: 'edit_item_one',
	name: 'edit claim',

	header: {
		...BuiCreateClaim.header,
		title: 'Edit Claim'
	},
	valuesToBigForm: (bf, store) => {
		store
			.select(getFullItemOne)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe(itemOne => {
				// Initialize values
				const applicant = itemOne?.applicant;
				const loan_information = itemOne?.loan_information;
				const jobcardrequest = loan_information?.jobcardrequest;
				const createJob = (job?) => {
					if (job) {
						let jobExcess = null;
						if (Array.isArray(job.excess)) {
							jobExcess = job?.excess.find(j => j?.excess_description === 'Standard Excess');
						} else {
							jobExcess = job?.excess;
						}
						return bf.fb.group({
							id: UUID.UUID(),
							skillrequested: job?.skillrequested,
							skillcatagory_id: job?.skillcatagory_id,
							skillcatagory: job?.skillcatagory, // string name
							skill_id: job?.skill_id,
							skill: job?.skill, // string name
							providertype_id: job?.providertype_id,
							providertype: job?.providertype, // string name
							appointmentDatePicker: new Date(job.requestedappointmentdate),
							appointmentTimePicker: {
								hour: job?.requestedappointmenttime?.split(':')[0] ?? '',
								minutes: job?.requestedappointmenttime?.split(':')[1] ?? ''
							},
							appointmentTime: job?.appointment_type_id,
							appointmentDateType: job?.appointmentdatetype,
							amount: [
								jobExcess?.excess_amount,
								[Validators.required, Validators.minLength(4), CustomValidators.hardMaxLength(13), CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])]
							],
							who_collects: jobExcess?.who_collects_excess,
							who_collects_description: jobExcess?.who_collects_excess_desc,
							is_cancelled: parseInt(loan_information.claimtype_id, 10) === 28 && !jobExcess ? true : false,
							excess_description: jobExcess?.excess_description,
							payment_method: jobExcess?.excess_payment_method,
							payment_description: jobExcess?.excess_payment_method_desc
						});
					} else {
						return bf.fb.group({
							id: UUID.UUID(),
							skillrequested: [1, [Validators.required]], // signal to the server to create job
							skillcatagory_id: [null, [Validators.required]],
							skillcatagory: null, // string name
							skill_id: [null, [Validators.required]],
							skill: null, // string name
							providertype_id: [null, [Validators.required]],
							providertype: null, // string name
							appointmentDatePicker: null,
							appointmentTimePicker: null,
							appointmentTime: null,
							amount: [
								null,
								[Validators.required, Validators.minLength(4), CustomValidators.hardMaxLength(13), CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])]
							],
							who_collects: [null, [Validators.required]],
							who_collects_description: null,
							is_cancelled: false,
							excess_description: 'Standard Excess',
							payment_method: [null, [Validators.required]],
							payment_description: null
						});
					}
				};
				const mandatoryExcess = jobcardrequest?.[0]?.excess && Array.isArray(jobcardrequest[0]?.excess) && jobcardrequest[0]?.excess[0];

				const jobArray =
					jobcardrequest && jobcardrequest.length
						? jobcardrequest.map(job => {
								const theJob = createJob(job);
								return theJob;
						  })
						: [createJob()];

				// Form binding
				bf.addControl('jobs', new UntypedFormArray(jobArray));
				bf.addControl(
					'address_details',
					new UntypedFormControl({
						latitude: +loan_information.claimlatitude || 0,
						longitude: +loan_information.claimlongitude || 0
					})
				);

				bf.patchValues({
					new_state: 1,
					checked_policy_details: true,
					checked_address_confirmation: true,
					checked_claim_details: true,
					checked_appointments: true,
					checked_excess: true,
					// Policy Lookup
					policy_lookup: applicant && applicant.claimantpoliceynum,
					// Policy Details
					policy_number: applicant && applicant.claimantpoliceynum ? applicant.claimantpoliceynum : '',
					client_details: {
						surname: [
							(applicant && applicant.surname) || '',
							[
								Validators.required,
								Validators.minLength(2),
								CustomValidators.hardMaxLength(140),
								CustomValidators.onlyAllowedASCII([32, 39, 44, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
							]
						],
						id_number: [
							(applicant && applicant.id_number) || '',
							[
								Validators.required,
								CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
								CustomValidators.alphaNumericWithForwardSlash,
								Validators.minLength(6),
								CustomValidators.hardMaxLength(13)
							]
						],
						first_name: [
							(applicant && applicant.first_name) || '',
							[
								Validators.required,
								Validators.minLength(2),
								CustomValidators.hardMaxLength(140),
								CustomValidators.onlyAllowedASCII([32, 39, 44, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
							]
						],
						policy_number: [
							(applicant && applicant.claimantpoliceynum) || '',
							[Validators.required, CustomValidators.alphaNumericWithForwardSlash, Validators.minLength(8), CustomValidators.hardMaxLength(20)]
						],
						bond_number: [(applicant && applicant.bondnumber) || '', [Validators.minLength(5), CustomValidators.hardMaxLength(13)]]
					},
					contact_details: {
						contact_number: [
							loan_information.contactnumber || '',
							[
								Validators.required,
								CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
								CustomValidators.contact_number,
								Validators.minLength(10),
								CustomValidators.hardMaxLength(12)
							]
						],
						cell_number: [
							loan_information.cellnumber || '',
							[
								Validators.required,
								CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
								CustomValidators.numeric,
								Validators.minLength(10),
								CustomValidators.hardMaxLength(12)
							]
						],
						email: [loan_information.email || '', [Validators.required, CustomValidators.onlyAllowedASCII(generateRange(32, 126, 1))]],
						preferred_com_method: [loan_information.preferedcommethod || '', [Validators.required]]
					},
					insured_address: {
						complex: [loan_information.propertycomplex || '', [CustomValidators.hardMaxLength(64)]],
						complex_unit_number: new UntypedFormControl(loan_information.propertycomplexunitnumber || '', CustomValidators.hardMaxLength(64)),
						street_address: [loan_information.propertystreetaddress || '', [Validators.required, CustomValidators.hardMaxLength(64)]],
						suburb: [loan_information.propertysuburb || '', [Validators.required, CustomValidators.hardMaxLength(64)]],
						city: [loan_information.propertycity || '', [Validators.required, CustomValidators.hardMaxLength(64)]]
					},

					handler: loan_information?.handler || '',
					handlercontact: loan_information?.handlercontact || '',
					handleremail: loan_information?.handleremail || '',

					// Policy Details
					market_segment_key: loan_information?.market_segment_key || '',
					local_file: loan_information?.local_file || '',
					is_joint_account: loan_information?.isjointaccount || '',
					clientcode: loan_information?.clientcode || '',

					// Address confirmation
					suburb_code: loan_information?.suburbcode || '',
					addressconfirmation: loan_information?.addressconfirmation || '',
					area_code: loan_information?.areacode || '',

					// Claim details
					claim_class: loan_information?.claimtype_id || '',
					claim_class_description: loan_information?.claimtype || '',

					// Claim Appointment
					on_site_contact: loan_information?.onsitecontact || '',
					on_site_notes: loan_information?.onsitenotes || '',
					on_site_person: loan_information?.onsiteperson || '',

					//Payment Details
					reinsuranceexcess: loan_information?.reinsuranceexcess || '',
					specialclause: loan_information?.specialclause || '',
					voluntaryexcess: loan_information?.voluntaryexcess || '',
					additionalexcess: loan_information?.additionalexcess,
					underwriter: loan_information?.underwriter || '',
					sum_insured: loan_information?.suminsured || '',
					// additionalexcess: Number(additionalexcess),
					// reinsuranceexcess: Number(reinsuranceexcess),
					// voluntaryexcess: Number(voluntaryexcess),
					// specialclause: Number(specialclause),
					policy_excess: {
						is_cancelled: false,
						excess_description: mandatoryExcess ? mandatoryExcess?.excess_description : '',
						amount: Number(loan_information.additionalexcess) + Number(loan_information.reinsuranceexcess) + Number(loan_information.voluntaryexcess),
						who_collects: mandatoryExcess ? mandatoryExcess.who_collects_excess : '',
						who_collects_description: mandatoryExcess ? mandatoryExcess.who_collects_excess_desc : '',
						payment_method: mandatoryExcess ? mandatoryExcess.excess_payment_method : '',
						payment_method_description: mandatoryExcess ? mandatoryExcess.excess_payment_method_desc : ''
					},

					// // Other
					upfrontrepudiation: loan_information?.upfrontrepudiation ? stringToBoolean(loan_information.upfrontrepudiation) : '',
					upfrontrepudiationreason: loan_information?.upfrontrepudiationreason || '',
					dontpingsp: loan_information?.dontpingsp || '',

					// What Matters Action Panel
					date_of_loss: loan_information?.dateofloss ? checkForValidDate(loan_information.dateofloss) : '',
					whatmatters: loan_information?.whatmatters || '',
					claimdescription: loan_information?.claimdescription || '',

					// Claim Details context actions
					_3rdpartyinsurancecompany: loan_information?._3rdpartyinsurancecompany || '',
					_3rdpartyvechilereg: loan_information?._3rdpartyvechilereg || '',
					_3rdpartyvechilemake: loan_information?._3rdpartyvechilemake || '',
					_3rdpartydriverid: loan_information?._3rdpartydriverid || '',
					_3rdpartydrivercell: loan_information?._3rdpartydrivercell || '',
					_3rdpartydrivername: loan_information?._3rdpartydrivername || '',
					firedamageextent: loan_information?.firedamageextent || '',
					recomendedadjuster: loan_information?.recomendedadjuster || '',
					policeclaimnumber: loan_information?.policeclaimnumber || ''
				});
			});
	},
	startNode: 'PolicyLookup',
	nodes: {
		PolicyLookup: {
			...BuiCreateClaim?.nodes?.PolicyLookup
		},
		PolicyDetails: {
			...BuiCreateClaim?.nodes?.PolicyDetails
		},
		AddressConfirmation: {
			...BuiCreateClaim?.nodes?.AddressConfirmation
		},
		ClaimDetails: {
			...BuiCreateClaim?.nodes?.ClaimDetails
		},
		ClaimAppointments: {
			...BuiCreateClaim?.nodes?.ClaimAppointments
		},
		ClaimPaymentDetails: {
			...BuiCreateClaim?.nodes?.ClaimPaymentDetails
		},
		ClaimHistory: {
			...BuiCreateClaim?.nodes?.ClaimHistory
		},
		ClaimSubmissionSummary: {
			...BuiCreateClaim?.nodes?.ClaimSubmissionSummary,
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'Update Draft',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					visible: (bf, store, networkService) => {
						return networkService.isOnline;
					},
					serverCalls: {
						saveAsServerDraft: {
							errorMessage: 'Could not update the draft!',
							serviceVariable: 'buiService',
							functionName: 'updateServerDraft',
							data: 8,
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'buiService',
									functionName: 'deleteCurrentEditableItem'
								}
							}
						}
					}
				},
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'This claim could not be created!',
							serviceVariable: 'buiService',
							functionName: 'updateServerDraftAsSubmitted',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'buiService',
									functionName: 'deleteCurrentEditableItem'
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			...BuiCreateClaim?.nodes?.SubmissionSuccess
		}
	}
};
