<ng-container>
	<flx-heading type="creation" size="small" align="center" [color]="headingcolour">{{ heading }}</flx-heading>
	<div class="ui-success">
		<svg viewBox="0 0 87 87" version="1.1">
			<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="Group-3" transform="translate(2.000000, 2.000000)">
					<circle id="Oval-2" stroke="rgba(76, 175, 80, 0.4)" stroke-width="2" cx="41.5" cy="41.5" r="41.5"></circle>
					<circle class="ui-success-circle" id="Oval-2" stroke="var(--success)" stroke-width="2" cx="47" cy="44" r="41.5"></circle>
					<polyline class="ui-success-path" id="Path-2" stroke="var(--success)" stroke-width="2" points="19 38.8036813 31.1020744 54.8046875 63.299221 28"></polyline>
				</g>
			</g>
		</svg>
	</div>
	<ng-container *ngIf="copyText$ | async as text">
		<flx-heading type="creation" size="small" align="center" margin="4rem 0 0 0" color="success">
			<div class="claim-to-copy-container">
				<div class="claim-to-copy-heading" *ngIf="text !== 'false'">{{ copyTextHeading }}:</div>
				<div *ngIf="text === 'false'">
					<div class="claim-to-copy-heading" style="color: #c54f00">{{ copyTextHeading }}.</div>
					<br />
					<div class="claim-to-copy-heading" style="color: #c54f00"></div>
				</div>
				<!-- <div class="text-to-copy" *ngIf="text === 'false'" hoverClickCopy>{{ text }}</div> -->
				<div class="text-to-copy" *ngIf="text !== 'false'" hoverClickCopy>{{ text }}</div>
			</div>
		</flx-heading>
	</ng-container>
</ng-container>
