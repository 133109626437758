import { BigFormService, Flow_0_0_2, getAllInfo, getFullItemTwo } from '@flexus/core';
import { filter, map, pluck, skipWhile, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { values } from 'ramda';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import gql from 'graphql-tag';

export const MUL_CANCEL_JOB: Flow_0_0_2 = {
	id: 'CancelJob',
	itemType: 'flow',
	name: 'Cancel Job',
	excludeForOnlyStates: [27, 41, 48, 39, 49, 54, 46, 47, 50, 51, 30, 40, 45, 28, 311],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						return `Cancel Job : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Cancel Job';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'CancelJob',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},

	nodes: {
		CancelJob: {
			serverCalls: {
				jobdata: {
					errorMessage: 'No job information returned',
					directCall: (_http: HttpClient, _store: Store) => {
						return forkJoin([
							_store.select(getFullItemTwo).pipe(
								skipWhile((itemTwo: any) => !itemTwo),
								take(1)
							),
							_store.pipe(
								skipWhile((stor: any) => !stor),
								take(1)
							)
						]).pipe(
							map(([job, stor]: any) => {
								return { job, stor };
							})
						);
					}
				}
			},
			component: 'FLXCancelJobComponent',
			inputs: {
				heading: 'Cancel the selected Job Card',
				subtitle: 'Job cards can not be cancelled if work is already in progress',
				organisation: 'mul'
			},
			initFormFields: (bf, item, instance) => {},
			navs: [
				{
					text: 'Cancel this job',
					nextNode: 'CancelJobReason',
					color: 'secondary'
				}
			]
		},
		CancelJobReason: {
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('cancelclaim_reason', new UntypedFormControl('', [Validators.required]));
				bf.addControl('cancelclaim_reason_name', new UntypedFormControl(null, [Validators.required]));
			},
			serverCalls: {
				selectOptions: {
					errorMessage: 'Could not get cancel reasons',
					directCall: (_http: HttpClient, _store: Store, _sq: any) => {
						return _sq
							.queryStore(
								gql`
									{
										allInfo {
											config_options {
												general {
													cancel_reasons
												}
											}
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1),
								map(values),
								map(value => value?.[0]),
								map(vals => ({ cancelreasons: vals }))
							);
					}
				}
			},
			component: 'FLXFlatDynamicFormComponent',
			checkValidityForFields: ['cancelclaim_reason'],
			inputs: {
				heading: 'Reason for Cancellation',
				formControls: {
					0: {
						label: 'Select the reason for cancellation',
						inputType: 'select',
						selectConfig: {
							validators: Validators.required,
							displayOptions: { displayKey: 'name', valueKey: 'id' },
							itemsOption: 'cancelreasons',
							searchEnabled: false,
							placeholder: 'Reason for cancellation',
							addConditionalForm: {
								onControlSelectedValue: 9,
								inputType: 'textarea',
								formControlName: 'cancelclaim_reason_details',
								width: '200px',
								label: 'Please provide more details (this will not be sent to the customer)',
								placeholder: 'Custom Reason'
							}
						},
						formControlName: 'cancelclaim_reason'
					}
				},
				formLayout: 'stacked',
				containerWidth: '30vw',
				headingSize: 'medium',
				headingWeight: 'light',
				headingType: 'creation',
				headingMargin: '20px 0 35px 0'
			},
			navs: [
				{
					text: 'continue',
					color: 'secondary',
					optIntoValidation: true,
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (_instance: any, _store: Store, _bf: BigFormService) => {
							_store
								.select(getAllInfo)
								.pipe(
									skipWhile((x: any) => !x),
									pluck('config_options'),
									take(1),
									map((config: any) => {
										let cancelreason;
										const cancelreasons: any[] = config?.general?.cancel_reasons;
										cancelreasons.map((reason: any) => {
											if (_bf.bigForm.get('cancelclaim_reason')?.value === reason?.id) {
												cancelreason = reason.name;
												_bf.bigForm.get('cancelclaim_reason_name').setValue(cancelreason);
											}
										});
									})
								)
								.subscribe();
							_instance.messageOnly = true;
							_instance.type = 'warning';
							_instance.heading = 'Warning';
							_instance.setMessage([
								'You are about to cancel this job.',
								'',
								'An SMS will be sent to the customer informing them of the reason and the job will be closed on the workflow.',
								'',
								'Are you sure you wish to cancel?',
								'',
								''
							]),
								(_instance.navButtons = [
									{
										text: 'take me back',
										color: 'secondary',
										linkType: 'close'
									},
									{
										text: 'confirm cancellation',
										color: 'secondary',
										linkType: 'submitThenNext',
										nextNode: 'SubmissionSuccess',
										serverCalls: {
											response: {
												serviceVariable: 'mulService',
												functionName: 'cancelJob',
												errorMessage: 'Job not cancelled'
											}
										}
									}
								]);
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	}
};
