import { Flow_0_0_2, getAllInfo, getFullItemOne, getSelectedItemOne, getData, SetNextNode } from '@flexus/core';
import { map, pluck, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { getErrors } from '@flexus/error-handler';

export const BET_CANCEL_CLAIM: Flow_0_0_2 = {
	fetchLevel1And2: true,
	id: 'CancelClaim',
	itemType: 'flow',
	name: 'Cancel Claim',
	excludeForOnlyStates: [169],
	excludeForOnlyRoles: [2],
	header: {
		title: store => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Cancel Claim : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Cancel Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'CancelClaim',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: () => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		CancelClaim: {
			serverCalls: {
				cancelclaimdata: {
					errorMessage: 'No claim data returned',
					directCall: (http, store) => {
						return combineLatest([
							store.select(getFullItemOne).pipe(
								skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
								take(1)
							),
							store.pipe(take(1))
						]).pipe(
							map(([claimjob, stor]: any) => {
								return { claimjob, stor };
							})
						);
					}
				}
			},
			component: 'FLXCancelClaimComponent',
			inputs: {
				subtitle: 'Do you want to permanently cancel the claim?'
			},
			mockContextData: {},
			checkValidityForFields: [],
			navs: [
				{
					text: 'Cancel this claim',
					nextNode: 'CancelClaimReason',
					color: 'secondary',
					linkType: 'submit'
				}
			]
		},
		CancelClaimReason: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				containerWidth: '50vw',
				heading: '',
				subtitle: 'Do you want to permanently cancel the claim?',
				formControls: {
					0: {
						label: 'Select the reason for cancellation',
						inputType: 'select',
						selectConfig: {
							displayOptions: { displayKey: 'display', valueKey: 'value' },
							itemsOption: [
								{ display: 'Cancel', value: 'Cancel' },
								{ display: 'Cancel - Client Request', value: 'Cancel - Client Request' },
								{ display: 'Duplicate', value: 'Duplicate' },
								{ display: 'Other', value: 'Other' }
							],
							placeholder: 'Reason for cancellation'
						},
						formControlName: 'cancelclaim_reason'
					}
				}
			},
			navs: [
				{
					text: 'Cancel Claim',
					color: 'secondary',
					nextNode: 'Decision',
					linkType: 'submit',
					serverFirst: true,
					serverCalls: {
						cancelResponse: {
							errorMessage: 'Claim was not cancelled',
							directCall: (http, store, sq, bf, cntr, modal) => {
								return forkJoin([
									store.select(getSelectedItemOne).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getAllInfo).pipe(
										skipWhile(x => !x),
										take(1),
										pluck('claim_cancel_reasons'),
										map(res => res as any[])
									)
								]).pipe(
									switchMap(([claim, reasons]) => {
										const cancelreasons = reasons;
										let claim_id;
										claim_id = claim?.id;
										const reason: any = bf.bigForm.get('cancelclaim_reason')?.value;

										const data = {
											claim_id: claim_id,
											reason: reason
										};
										return http.post(`${environment.api_url}v1/claim_action/cancel_claim/`, data).pipe(
											skipWhile(x => !x),
											tap(value => {
												if (!value['success'])
													modal.openModalDirectly(instance => {
														instance.type = 'warning';
														instance.message = value['reason'];
														instance.navButtons = [
															{
																text: 'Back to workflow',
																clickHandler: event => {
																	instance.router.navigate(['/workflow']);
																},
																linkType: 'close',
																color: 'alert'
															}
														];
													});
											})
										);
									})
								);
							}
						}
					}
				}
			],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('claim_id', new UntypedFormControl(null));
				bf.addControl('cancelclaim_reason', new UntypedFormControl(null));
			}
		},

		Decision: {
			nodeType: 'decision',
			decisions: {
				isCancelSuccess: (navs, store, modal, controller) => {
					let errorSub, successSub: Subscription;

					errorSub = store
						.select(getErrors)
						.pipe(
							pluck('cancelResponse'),
							skipWhile(x => !x),
							take(1)
						)
						.subscribe(_ => {
							controller.dispatch(new SetNextNode('CancelClaim'));
							if (successSub) {
								successSub.unsubscribe();
							}
						});

					successSub = store
						.select(getData)
						.pipe(
							pluck('cancelResponse'),
							skipWhile(x => !x),
							take(1)
						)
						.subscribe(_ => {
							controller.dispatch(new SetNextNode('SubmissionSuccess'));
							if (errorSub) {
								errorSub.unsubscribe();
							}
						});
				}
			},
			navs: []
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		claim_id: [
			(cid, storeObj) => {
				cid = storeObj['selectedContext'].selectedItemOne?.id;
				return cid;
			},
			'claim_id'
		],
		reason: 'Cancel'
	}
};
