import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getSelectedItem } from '@flexus/core';
import { Store } from '@ngrx/store';
import { CollapseActionPanel, setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { customerQueryNode } from '../reusable/CUSTOMER_REFUND_QUERY';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { PGG_BANK_DETAILS } from './BANK_DETAILS';

export const PGG_243: Flow_0_0_2 = {
	id: '243',
	name: 'excessive_additional_fees',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),

	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Excessive Additional Fees';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {},
	instructions: {
		editRoles: {
			0: 'Assess excessive additional fees'
		},
		viewRoles: {
			0: 'Excessive additional fees in review'
		}
	},
	startNode: 'ExcessiveAdditionalFeesNotification',
	nodes: {
		ExcessiveAdditionalFeesNotification: {
			...jobInfoNode(243, 'Excessive Additional Fees', [
				'The customer has not acknowledged that the installation has been cancelled due to additional fees.',
				'Contact the customer to determine the current status.'
			]),
			navs: [
				{
					text: 're-instate job',
					linkType: 'portal',
					color: 'secondary',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any) => {
							instance.type = 'warning';
							instance.heading = 'Confirmation of Re-instated Cancellation';
							instance.setMessage(['Please confirm that you would like to re-instate this job']);
							instance.navButtons = [
								{
									text: 'BACK',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'CONFIRM',
									color: 'secondary',
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										response: {
											errorMessage: 'Could not re-instate job',
											directCall: (http: any, store: any, sq: any, bf: any) => {
												return store.select(getSelectedItem).pipe(
													skipWhile(x => !x),
													take(1),
													switchMap((job: any) => {
														const job_id = job?.id;
														const new_state = 244;
														const data = {
															job_id: job_id,
															new_state: new_state
														};
														return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
													})
												);
											}
										}
									}
								}
							];
						}
					}

					// nextNode: 'SubmissionSuccess'
				},
				{
					text: 'Confirm Cancel Installation',
					// nextNode: 'FakeModal',
					color: 'secondary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any, store: any) => {
							instance.type = 'warning';
							instance.heading = 'Confirmation of Voucher Cancellation';
							instance.setMessage(['Please confirm with the customer that they want to cancel the cancellaton', 'so that a refund can take place']);
							instance.navButtons = [
								{
									text: 'BACK',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'CONFIRM',
									color: 'secondary',
									linkType: 'nextNode',
									nextNode: 'FakeModal'
								}
							];
						}
					}
				}
			]
		},
		FakeModal: {
			component: 'PingGoFakeModalComponent'
		},
		RefundBreakdown: {
			serverCalls: {
				// add server calls to get the voucher amount
				voucherAmount: {
					errorMessage: `Couldn't get voucher amount`,
					directCall: (_htp: HttpClient, _store: Store, _sq: any) => {
						return _sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													values {
														total_value
														client_value
														sp_value
													}
												}
											}
										}
									}
								`,
								_store.select(getFullItemTwo).pipe(
									skipWhile((fit: any) => !fit),
									take(1),
									map((res: any) => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((querydata: any) => {
									const total_value: number = +querydata?.total_value;
									const client_value: number = +querydata?.client_value;
									const sp_value: number = +querydata?.sp_value;
									let valueobject: any = {
										total_value: total_value,
										client_value: client_value,
										sp_value: sp_value
									};
									console.log('QUERYDATA', valueobject);

									return valueobject;
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Refund breakdown',
							instructions: ['Please confirm expected refund amount with customer']
						}
					},
					{
						component: 'PinggoRefundBreakdownComponent',
						inputs: { voucherAmount$: 'voucherAmount' }
					}
				]
			},
			navs: [
				{
					text: 'query refund',
					color: 'secondary',
					linkType: 'portal',
					nextNode: 'CustomerQuery'
				},
				{
					text: 'continue',
					nextNode: 'BankDetailsCapture',
					color: 'primary'
				}
			]
		},
		BankDetailsCapture: {
			initFormFields: (bf: any) => {
				bf.addControl('new_state', new UntypedFormControl(null, [Validators.required]));
				bf.patchValues({ new_state: 269 }); // find correct  state here
			},
			component: 'PggBankDetailsTemplateComponent',
			...PGG_BANK_DETAILS
		},
		CustomerQuery: {
			...customerQueryNode(243),
			navs: [
				{
					text: 'submit',
					serverCalls: {
						response: {
							errorMessage: `Couldn't update job with customer query`,
							serviceVariable: 'pggService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		refund_query: [
			(refundquery: any) => {
				return refundquery;
			},
			'job_information.refund_query'
		]
	}
};
