import { Flow_0_0_2, getCurrentUser, getFullItemOne, getFullItemTwo, MakeServerCall, SetNextNode } from '@flexus/core';
import { take, map, skipWhile, switchMap } from 'rxjs/operators';
import { SIL_96 } from './SIL_96';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { actionPanelItems } from '../../../../app-shell-features';
import { UntypedFormControl, Validators } from '@angular/forms';

export const SIL_92: Flow_0_0_2 = {
	id: '92',
	name: 'assessor_query_sp_response',
	itemType: 'flow',
	onStateInit: SIL_96.onStateInit,
	onStateDestroy: SIL_96.onStateDestroy,
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					if (itemOne) {
						let title = 'Assessor Query SP Response';
						if (itemOne?.loan_information?.mavenclaimnumber) {
							title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
						}
						if (itemOne.applicant?.surname) {
							title += ` - ${itemOne.applicant?.surname}`;
						}

						return title;
					} else {
						return 'Assessor Query SP Response';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Review'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: SIL_96.actionPanel,
	serverCalls: {
		queryHistoryData: {
			errorMessage: 'No query history could be found!',
			directCall: (http, store, sq) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile((job: any) => !job || !job.job_information),
					take(1),
					map((data: any) => {
						const ia_query = data?.job_information?.ia_query;

						return Array.isArray(ia_query) ? ia_query : ia_query ? [ia_query] : [];
					})
				);
			}
		},
		...SIL_96.serverCalls
	},
	startNode: 'InternalAssessorJobOverview',
	nodes: {
		InternalAssessorJobOverview: {
			component: {
				children: [
					{
						component: 'FLXInternalAssessorJobOverviewComponent',
						inputs: {
							continueFunc: instance => {
								instance._store.dispatch(
									new MakeServerCall({
										serviceVariable: 'silService',
										errorMessage: `No data returned for InternalAssessorJob'`,
										directCall: (http, store, sq, bf, controller) => {
											return forkJoin([
												instance._store.select(getFullItemTwo).pipe(
													skipWhile(x => !x),
													take(1)
												),
												instance._store.select(getCurrentUser).pipe(
													skipWhile(y => !y),
													take(1)
												)
											]).pipe(
												take(1),
												map(([job, user]: any) => {
													instance?.bf?.patchValues({
														job_id: job?.id,
														current_state: job?.state
													});

													return {
														job_id: job?.id,
														current_state: job?.state,
														new_state: 86,
														job_information: {
															...job.job_information,
															quote_approved_by: `${user?.user?.username},${user?.user?.full_name}`
														},
														Reason: `Quotation approved by:{${user?.user?.username}, ${user?.user?.full_name}}`
													};
												}),
												switchMap(dataSub => {
													controller.dispatch(new SetNextNode('SubmissionSuccess'));
													return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
												})
											);
										}
									})
								);
							},
							openActionPanel: instance => {
								const paramFunc = inst => actionPanelItems(inst).find(x => x.id === 'query-history');
								instance.navService.portalActions.next({ call: 'onClick', paramFunc });
							},
							usedInContextMenu: false,
							iaReason$: 'iaReason',
							queryHistory$: 'queryHistoryData',
							location: 'screen',
							claimSummary$: 'claimSummary'
						}
					}
				]
			},
			checkValidityForFields: ['quote_amount'],
			initFormFields: bf => {
				return bf.bigForm?.valueChanges?.pipe(take(1)).subscribe(val => {
					if (val?.itemTwo) {
						const quote_amount = val?.itemTwo?.job_information?.quote_amount ?? '';
						bf.addControl('quote_amount', new UntypedFormControl(quote_amount?.replace(',', ''), Validators.required));
						bf.addControl('job_id', new UntypedFormControl(val.itemTwo.id));
						bf.addControl('current_state', new UntypedFormControl(val.itemTwo.state));
					}
				});
			}
		},
		QueryInvoice: { ...SIL_96?.nodes?.QueryInvoice },
		RecommendCIL: { ...SIL_96?.nodes?.RecommendCIL },
		RecommendRejection: { ...SIL_96?.nodes?.RecommendRejection },
		RecommendLossAdjuster: { ...SIL_96?.nodes?.RecommendLossAdjuster },
		SubmissionSuccess: { ...SIL_96?.nodes?.SubmissionSuccess }
	},
	bigFormToStoreMapper: { ...SIL_96.bigFormToStoreMapper }
};
