import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXFlatDynamicFormComponent } from './flat-dynamic-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UIElementsModule } from '@flexus/ui-elements';

@NgModule({
	declarations: [FLXFlatDynamicFormComponent],
	imports: [CommonModule, ReactiveFormsModule, FormsModule, UIElementsModule],
	exports: [FLXFlatDynamicFormComponent]
})
export class FLXFlatDynamicFormModule {}
