import { Flow_0_0_2, getFullItemTwo, getFullItemOne, SetNextNode, GetFullItemOne, getCurrentUser, getData, MakeServerCall } from '@flexus/core';
import { Validators, UntypedFormControl } from '@angular/forms';
import { take, map, skipWhile, filter, tap, pluck } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { empty, forkJoin, of } from 'rxjs';
import moment from 'moment';

export const PAYMENT_CHECKS: Flow_0_0_2 = {
	id: 'payment_checks',
	name: 'payment_checks',
	itemType: 'flow',
	header: {
		title: 'Payment checks',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	startNode: 'MiscellaneousClass',

	nodes: {
		Decision: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: { retryInterval: 300, retryCount: 3 },
				onRetryComplete: () => {
					return empty();
				}
			},
			decisions: {
				authorization: (navs, store, modal, controller) => {
					return store
						.select(getFullItemOne)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.subscribe(claim => {
							if (claim.mid) {
								controller.dispatch(new SetNextNode('ClaimMID'));
								return 'Claim MID';
							} else {
								// ===============================================================================================================
								// ================================================ No Claim MID present =========================================
								// ===============================================================================================================
								controller.dispatch(new SetNextNode('MavenID'));
								return 'No Claim ID';
							}
						});
				}
			},
			navs: [{ text: 'Success', nextNode: 'SubmissionSuccess' }]
		},

		ClaimMID: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: { retryInterval: 300, retryCount: 3 },
				onRetryComplete: () => {
					return empty();
				}
			},
			decisions: {
				checks: (navs, store, modal, controller) => {
					return forkJoin([
						store.select(getFullItemOne).pipe(
							skipWhile(x => !x && !x.mid),
							take(1)
						),
						store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1)
						),
						store.select(getCurrentUser).pipe(
							skipWhile(x => !x),
							take(1)
						),
						store.select(getData).pipe(
							pluck('files'),
							filter(value => Array.isArray(value)),
							take(1)
						)
					])
						.pipe(
							take(1),
							map(([claim, job, user, allFiles]) => {
								const supplierTypesNoMidCheck = [2]; // add types exclude from job mid check to array
								if (job.mid || supplierTypesNoMidCheck?.includes(job.supplier_type)) {
									const invoices = allFiles.filter(entry => entry.purpose?.toLowerCase() === 'invoice');
									if (invoices?.length === 0) {
										// that means there is a problem
										//  this means push back to state 26
										store.dispatch(
											new MakeServerCall({
												dataKey: 'updateJob',
												serviceVariable: 'betService',
												functionName: 'updateJob',
												errorMessage: 'Could not update Job',
												directCall: http => {
													const data = {
														job_information: job?.job_information,
														job_id: job?.id,
														new_state: 26
													};
													return http
														.post(`${environment.api_url}v1/job_action/update_job/`, {
															...data
														})
														.pipe(
															filter(x => !!x),
															take(1)
														);
												}
											})
										);

										store
											.select(getData)
											.pipe(
												pluck('updateJob'),
												filter(x => !!x),
												take(1)
											)
											.subscribe((value: any) => {
												if (value.success) {
													controller.dispatch(new SetNextNode('SubmissionSuccess'));
												}
											});
										// ===============================================================================================================
										// ================================================ No invoice present= ==========================================
										// ===============================================================================================================
										return 'Going SubmissionSuccess';
									} else {
										const latestInvoice = invoices?.reduce((redFile, currentFile) => {
											if (moment(redFile.created).isBefore(moment(currentFile.created))) {
												return currentFile;
											} else {
												return redFile;
											}
										}, invoices[0]);

										store.dispatch(
											new MakeServerCall({
												dataKey: 'updateJob',
												serviceVariable: '',
												functionName: '',
												errorMessage: 'Could not update Job',
												directCall: (http, store, sq, bf) => {
													bf.addControl('paymentapproval1', new UntypedFormControl('', Validators.required));
													bf.patchValues({
														paymentapprovalhandler: claim?.application_creator?.id ?? '',
														paymentapproval1: user?.user?.id
													});
													controller.dispatch(new SetNextNode('PaymentPreview'));
													return of({});
												}
											})
										);

										return 'Going Next';
									}
								} else {
									// ===========================================================================================================================
									// ============================================================  job has no mid   ============================================
									// ===========================================================================================================================
									store.dispatch(
										new MakeServerCall({
											dataKey: 'forceSPAppointment',
											serviceVariable: '',
											functionName: '',
											ignoreFalseError: true,
											errorMessage: 'Could not force appoinment',
											directCall: http => {
												return http.post(`${environment.api_url}v1/job_action/retry_appoint_sp_on_maven/`, { job_id: job?.id }).pipe(
													filter(x => !!x),
													take(1)
												);
											}
										})
									);

									store
										.select(getData)
										.pipe(
											pluck('forceSPAppointment'),
											filter(x => !!x),
											take(1)
										)
										.subscribe(response => {
											const { success, reason, payload } = response;
											if (success === true) {
												controller.dispatch(new SetNextNode('PaymentPreview'));
											} else {
												switch (true) {
													case reason === 'Please provide a valid appoint key.': {
														modal.openModalDirectly(instance => {
															instance.type = 'warning';
															instance.message = 'Please move job to a rework state';
															instance.navButtons = [
																{
																	text: 'Back to workflow',
																	clickHandler: event => {
																		instance.router.navigate(['/workflow']);
																	},
																	linkType: 'close',
																	color: 'alert'
																},
																{
																	text: 'Force appoinment',
																	nextNode: 'ForceAppoinment',
																	color: 'alert'
																}
															];
														});
														break;
													}
													// todo check the bellow, very important
													case /(skill)/gi.test(reason) === true: {
														modal.openModalDirectly(instance => {
															instance.type = 'warning';
															instance.message = `Please manually update this SP's skill set on maven to include the required skill`;
															instance.navButtons = [
																{
																	text: 'Back to workflow',
																	linkType: 'close',
																	color: 'alert',
																	clickHandler: () => {
																		instance.router.navigate(['/workflow']);
																	}
																}
															];
														});
														break;
													}
													case reason !== '': {
														modal.openModalDirectly(instance => {
															instance.type = 'warning';
															instance.message = `Reason given for failure: ${reason}`;
															instance.navButtons = [
																{
																	text: 'Back to workflow',
																	clickHandler: () => {
																		instance.router.navigate(['/workflow']);
																	},
																	linkType: 'close',
																	color: 'alert'
																},
																{
																	text: 'Force appoinment',
																	nextNode: 'ForceAppoinment',
																	color: 'alert'
																}
															];
														});
														break;
													}
													default: {
														modal.openModalDirectly(instance => {
															instance.type = 'warning';
															instance.message = 'something bad happened please retry';
															instance.navButtons = [
																{
																	text: 'Back to workflow',
																	clickHandler: () => {
																		instance.router.navigate(['/workflow']);
																	},
																	linkType: 'close',
																	color: 'alert'
																}
															];
														});
														break;
													}
												}
											}
										});
									return 'No Job ID';
								}
							})
						)
						.pipe(take(1))
						.subscribe();
				}
			},
			navs: [{ text: 'Success', nextNode: 'SubmissionSuccess' }]
		},

		MavenID: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				formControls: {
					0: {
						label: 'Manually enter maven ID',
						inputType: 'textarea',
						formControlName: 'maven_id'
					}
				}
			},

			checkValidityForFields: ['maven_id'],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('maven_id', new UntypedFormControl('', [Validators.required, Validators.minLength(6)]));
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemOne {
									state
									id
									loan_information
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x)?.length !== 0),
						take(1)
					)
					.subscribe(values => {
						bf.addControl('current_state', new UntypedFormControl(values?.state));
						bf.addControl('id', new UntypedFormControl(values?.id));
						bf.addControl('loan', new UntypedFormControl(values?.loan_information));
					});
			},

			navs: [
				{
					text: 'Add ID',
					color: 'alert',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						updateJob: {
							serviceVariable: 'betService',
							functionName: '',
							errorMessage: 'Could not add ID!',
							directCall: (http, store, sq, bf) => {
								const claim = {
									mid: bf.bigForm.get('maven_id')?.value,
									claim_id: bf.bigForm.get('id')?.value,
									loan_information: bf.bigForm.get('loan')?.value
								};
								return http.post(`${environment.api_url}v1/claim_action/update_claim/`, claim).pipe(
									tap(res => {
										if (res && res?.success) {
											store.dispatch(new GetFullItemOne({ id: res?.payload.id }));
										}
									})
								);
							},
							followUpSuccessCalls: {
								gotoNextNode: {
									errorMessage: 'Could not redirect',
									directCall: (http, store, sq, bf, controller) => {
										controller.dispatch(new SetNextNode('ClaimMID'));
										return of({});
									}
								}
							}
						}
					},
					nextNode: 'ClaimMID'
				}
			]
		},

		ForceAppoinment: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				formControls: {
					0: {
						label: 'Reason for force payment',
						inputType: 'textarea',
						formControlName: 'force_reason'
					}
				}
			},

			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('force_reason', new UntypedFormControl('cancel'));
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
									id
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x)?.length !== 0),
						take(1)
					)
					.subscribe(values => {
						bf.addControl('current_state', new UntypedFormControl(values?.state));
						bf.addControl('id', new UntypedFormControl(values?.id));
					});
			},

			navs: [
				{
					text: 'Force Appoinment',
					color: 'alert',
					serverCalls: {
						updateJob: {
							serviceVariable: 'betService',
							functionName: '',
							errorMessage: 'Could not force appointment!',
							directCall: (http, store, sq, bf) => {
								const body = {
									job_id: bf.bigForm.get('id')?.value,
									new_state: bf.bigForm.get('current_state')?.value,
									force_payment: 'Y',
									force_note: bf.bigForm.get('force_reason')?.value
								};
								return http.post(`${environment.api_url}v1/job_action/retry_appoint_sp_on_maven/`, body);
							}
						}
					},
					nextNode: 'PaymentPreview'
				}
			]
		}
	}
};
