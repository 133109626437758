import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';

import { skipWhile, take, map } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
export const SP_GLOBAL_34: Flow_0_0_2 = {
	id: '34',
	name: 'work-completed-component',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Work Completed - Component : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Work Completed - Component';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		}
	},
	instructions: {
		editRoles: {
			0: 'Invoice Izinga'
		},
		viewRoles: {
			0: 'Wait for SP to invoice Izinga'
		}
	},
	startNode: 'IzingaNotification',
	nodes: {
		IzingaNotification: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Invoice Izinga',
							instructions: ['The work is complete. The work required component replacement', 'Please invoice the job to Izinga then finalise.']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList2' }
					}
				]
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.addControl('new_state', new UntypedFormControl(38));
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Could not retrieve job information!',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const claiminfo = {
										'Claim Type : ': itemTwo?.claim?.type ?? '',
										'Address : ': itemTwo?.address ?? ''
									};
									return [claiminfo];
								})
							);
					}
				},
				keyValueList2: {
					errorMessage: 'No entry for replaced components',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const component = {
										'Replaced Components : ': itemTwo?.job_information?.components ?? ''
									};
									return [component];
								})
							);
					}
				}
			},
			navs: [
				{
					text: 'Finalise Claim',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					// optIntoValidation: true,
					color: 'primary',
					linkType: 'submit',
					serverCalls: {
						response: {
							serviceVariable: 'spService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated',
							responseSlice: 'payload'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		current_state: '34',
		new_state: '38'
	}
};
