<flx-heading align="center" size="large" color="primary" margin="-30px 0 20px 0"> Indicate your availability for this job</flx-heading>
<flx-heading align="center" size="medium">Job Information</flx-heading>

<div class="av-job-detail-contain" (click)="passJobIdUp(selectedJobId)">
	<div class="av-job-detail-info">
		<flx-heading size="small" align="center" color="secondary" weight="light" margin="-1px 0 0 0">Job Details</flx-heading>
		<flx-key-value-list align="center" [data$]="jobDetailData$"></flx-key-value-list>
		<!-- <ng-container *ngIf="whatMattersData$ | async as whatmatters">
			<p>{{ whatmatters }}</p>
		</ng-container> -->
		<div *ngIf="showSiteDetails">
			<flx-heading size="small" align="center" weight="light" color="secondary">Customer On-site Notes</flx-heading>
			<div style="margin: -10px 0 20px 0">{{ onsite_note }}</div>
			<div class="sitedetail-and-heading">
				<div><flx-heading size="small" align="center" weight="light" color="secondary" margin="0 0 7px 0">Installation Details</flx-heading></div>
				<div class="sitedetail">
					<div style="text-align: right">
						<span class="key">Decoder Type </span><br />
						<span class="key">Kind of TVs </span><br />
						<span class="key">Home Type</span><br />
						<span class="key">Existing Decoder?</span><br />
						<span class="key">Number of TVs </span><br />
						<span class="key">Wall Type</span><br />
					</div>
					<div style="text-align: left">
						<span class="value">{{ device_type }}</span
						><br />
						<span class="value">{{ tv_type }}</span
						><br />
						<span class="value">{{ dwelling_type }}</span
						><br />
						<span class="value">{{ existing_decoder }}</span
						><br />
						<span class="value">{{ no_of_tvs }}</span
						><br />
						<span class="value">{{ wall_type }}</span
						><br />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div style="height: 50vh" *ngIf="apiLoaded; else mapLoading">
		<google-map [options]="options" width="100%" height="100%">
			<map-marker *ngIf="jobloco" [position]="{ lat: jobloco.lat, lng: jobloco.lng }"></map-marker>
		</google-map>
	</div>
	<ng-template #mapLoading>Loading...</ng-template>
</div>
