import { Flow_0_0_2, getFullItemOne, getAllInfo } from '@flexus/core';
import { map, skipWhile, filter, take } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { findName } from '@flexus/utilities';

export const BET_COMMENTS: Flow_0_0_2 = {
	id: 'Comments',
	itemType: 'flow',
	name: 'View Claim Comments',
	excludeForOnlyStates: [169],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Claim comments :  ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Claim comments';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	fetchLevel1And2: true,
	startNode: 'Comments',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		Comments: {
			inputs: { inputHeading: 'Claim Comments' },
			component: {
				children: [{ component: 'FLXCommentsComponent', inputs: { comments$: 'comments' } }]
			},
			serverCalls: {
				comments: {
					errorMessage: '',
					directCall: (http, store) => {
						return forkJoin([
							store.select(getFullItemOne).pipe(
								filter(x => !!x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								filter(x => !!x),
								take(1)
							)
						]).pipe(
							take(1),
							map(([claim, allInfo]) => {
								const { jobs, ...claimInfo } = claim;

								const newJobs = jobs.map(element => ({
									tabName: findName(element?.skill, allInfo['skills'])?.name,
									id: element?.id,
									job_paused_reason: element?.job_information?.paused_reason,
									geyser_original: element?.job_information?.geyser_original,
									geyser_replacement: element?.job_information?.geyser_replacement,
									circumstance_of_loss: element?.job_information?.circumstance_of_loss,
									recommendations: element?.job_information?.recommendations,
									job_repudiation_reason: element?.job_information?.repudiation_reason,
									job_diagnosis: element?.job_information?.diagnosis,
									job_cancel_reason: element?.job_information?.cancel_reason,
									invoice_query: element?.job_information?.invoicequery,
									quote_amount: element?.job_information?.quote_amount,
									quote_number: element?.job_information?.quote_number,
									electronic_assessment: element?.job_information?.electronic_assessment,
									ia_requested_reason: element?.job_information?.ia_requested_reason,
									ia_query: element?.job_information?.ia_query
								}));

								const claimObj = {
									id: claimInfo?.id,
									tabName: 'Claim',
									claim_description: claimInfo?.loan_information?.claimdescription,
									customer_ratings: claimInfo?.loan_information?.customerrating,
									police_number: claimInfo?.loan_information?.policeclaimnumber,
									ia_requested_reason: claimInfo?.loan_information?.ia_requested_reason,
									what_matters_achieved: parseInt(claimInfo?.loan_information?.whatmattersachieved, 10) === 1 ? 'Yes' : 'No',
									failed_what_matters_reason: claimInfo?.loan_information?.failedwhatmattersreason
								};

								return [claimObj, ...newJobs];
							})
						);
					}
				}
			}
		}
	}
};
