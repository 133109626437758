import { getAllInfo, getFullItemOne, getFullItemTwo, getSelectedItemTwo, MakeServerCall, NodePoint_0_0_2 } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import gql from 'graphql-tag';
import { filter, map, skipWhile, take } from 'rxjs/operators';
import { FLXActionPanelComponent } from '../index';
import { combineLatest } from 'rxjs';
import { findName } from '@flexus/utilities';
import { pluginComponentMapper } from '../../../plugins/plugin.mapper';

const notesActionPanelNode: NodePoint_0_0_2 = {
	name: 'notes',
	component: 'NotesComponent',
	inputs: {
		title: 'Add Job Note',
		location: 'action-panel',
		formInputs: {
			0: { formControlName: 'message', inputType: 'textarea', label: 'Message' }
		},
		submitFunction: instance => {
			instance._store.dispatch(
				new MakeServerCall({
					dataKey: 'notes',
					serviceVariable: '',
					functionName: 'addJobNote',
					errorMessage: 'Could not add note',
					loaderID: 'action-panel'
				})
			);
			instance?.bf?.bigForm?.patchValue({ message: '' });
			instance?.bf?.bigForm?.markAsPristine();
		}
	},
	initFormFields: bf => {
		bf.bigForm.addControl('message', new UntypedFormControl('', Validators.required));
	},
	serverCalls: {
		notes: {
			serviceVariable: '',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No claim notes could be found!',
			loaderID: 'action-panel'
		}
	}
};

const claimExcessActionPanelNode: NodePoint_0_0_2 = {
	name: 'claim_excess',
	component: 'ExcessDetailsComponent',
	inputs: {
		location: 'action-panel'
	},
	serverCalls: {
		excess: {
			errorMessage: 'Could not retrieve excess',
			loaderID: 'action-panel',
			directCall: (http, store) => {
				return combineLatest([
					store.select(getFullItemOne).pipe(
						skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
						take(1)
					),
					store.select(getSelectedItemTwo).pipe(
						skipWhile((itemTwo: any) => itemTwo === null || itemTwo === undefined),
						take(1)
					),
					store.select(getAllInfo).pipe(take(1))
				]).pipe(
					map(([fullItemOne, selectedItemTwo, allInfo]: any) => {
						let excesses = {};

						const jobs = fullItemOne.jobs;
						const currentJobId = selectedItemTwo.id;

						const { excess_who, excess_how, skills } = allInfo;

						const composeExcess = excess => {
							if (excess === undefined) {
								return [];
							}

							if (Array.isArray(excess)) {
								excess = excess.map(e => {
									return {
										amount: e.amount,
										how_collect: findName(e.how_collect, excess_how).name,
										who_collects: findName(e.who_collects, excess_who).name,
										reason: e.reason
									};
								});
							}
							return excess;
						};

						const formatJob = job => {
							let { skill, excess } = job;

							skill = findName(skill, skills).name;
							excess = composeExcess(excess);

							return {
								skill,
								excess
							};
						};

						if (Array.isArray(jobs) && jobs.length > 0) {
							let currentJob = jobs.filter(job => job?.id === currentJobId);
							let otherJobs = jobs.filter(job => job?.id !== currentJobId);

							currentJob = currentJob.map(x => {
								return formatJob(x);
							});

							otherJobs = otherJobs.map(j => {
								return formatJob(j);
							});

							excesses = { currentJob, otherJobs };
						}
						return excesses;
					})
				);
			}
		}
	}
};

const iaNotesActionPanelNode: NodePoint_0_0_2 = {
	name: 'ia_notes',
	component: 'IANotesComponent',
	inputs: {
		title: 'Add Assessor Note',
		location: 'action-panel',
		formInputs: {
			0: { formControlName: 'ia_message', inputType: 'textarea', label: 'Message' }
		},
		submitFunction: instance => {
			instance._store.dispatch(
				new MakeServerCall({
					serviceVariable: '',
					functionName: 'addAssessmentNoteApi',
					errorMessage: 'Could not add assessor note',
					loaderID: 'action-panel',
					followUpSuccessCalls: {
						iaNotes: {
							serviceVariable: '',
							functionName: 'getjobAssessmentNotes',
							errorMessage: 'No assessor notes could be found!',
							loaderID: 'action-panel'
						}
					}
				})
			);
			instance?.bf?.bigForm?.patchValue({ ia_message: '' });
			instance?.bf?.bigForm?.markAsPristine();
		}
	},
	initFormFields: bf => {
		bf.bigForm.addControl('ia_message', new UntypedFormControl('', Validators.required));
	},
	serverCalls: {
		iaNotes: {
			serviceVariable: '',
			functionName: 'getjobAssessmentNotes',
			errorMessage: 'No assessor notes could be found!',
			loaderID: 'action-panel'
		}
	}
};

const policyHistoryActionPanelNode: NodePoint_0_0_2 = {
	name: 'policyHistory',
	component: 'FLXPolicyHistoryComponent',
	inputs: {
		title: 'Policy History'
	},
	serverCalls: {
		policyHistory: {
			serviceVariable: '',
			functionName: 'getPolicyHistory',
			errorMessage: 'No policy history could be found!',
			loaderID: 'action-panel'
		}
	}
};

const queryHistoryActionPanelNode: NodePoint_0_0_2 = {
	name: 'queryHistory',
	component: 'FLXQueryHistoryComponent',
	inputs: {
		title: 'Query History'
	},
	serverCalls: {
		queryHistory: {
			errorMessage: 'No query history could be found!',
			loaderID: 'action-panel',
			directCall: (http, store, sq) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile((job) => !job || !job.job_information),
					take(1),
					map((data) => {
						const ia_query = data?.job_information?.ia_query;

						return Array.isArray(ia_query) ? ia_query : ia_query ? [ia_query] : [];
					})
				);
			}
		}
	}
};

const callLogsActionPanelNode: NodePoint_0_0_2 = {
	name: 'callLogs',
	component: 'CallLogsComponent',
	inputs: {
		title: 'Call Logs',
		containerDirection: 'column'
	},
	serverCalls: {
		logs: {
			serviceVariable: '',
			functionName: 'getCallLogs',
			// responseSlice: 'payload',
			errorMessage: 'No call logs could be found!',
			loaderID: 'action-panel'
		}
	}
};

const fileBrowserActionPanel: NodePoint_0_0_2 = {
	name: 'fileBrowser',
	component: 'FLXAPViewDocumentsComponent',
	inputs: {
		title: 'Documents',
		location: 'action-panel'
	},
	serverCalls: {
		files: {
			serviceVariable: '',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!',
			loaderID: 'action-panel'
		}
	}
};

const jobRequestPingActionPanelNode: NodePoint_0_0_2 = {
	name: 'jobRequestPing',
	component: 'FLXAPJobRequestPingComponent',
	inputs: {
		title: 'Job Requests',
		location: 'action-panel'
	}
};

const jobRequestPingDetailActionPanelNode: NodePoint_0_0_2 = {
	name: 'jobRequestPingDetail',
	component: 'FLXAPJobRequestPingDetailComponent',
	inputs: {
		title: 'Job Request Detail',
		location: 'action-panel'
	}
};

const installationSummaryActionPanelNode: NodePoint_0_0_2 = {
	name: 'installationSummary',
	component: 'FLXAPInstallationSummaryComponent',
	inputs: {
		title: 'Installation Summary',
		location: 'action-panel'
	}
};

const jobSummaryActionPanelNode: NodePoint_0_0_2 = {
	name: 'jobSummary',
	component: 'FLXSPJobDetailsComponent',
	inputs: { title: ' Job SummaryX', location: 'action-panel' }
};

export type ActionPanelIDs =
	| 'call-logs'
	| 'claim-card'
	| 'documents'
	| 'time-stamp'
	| 'what-matters'
	| 'notes'
	| 'job-card'
	| 'sp-details'
	| 'job-details'
	| 'query-history'
	| 'policy-history'
	| 'search'
	| 'filter'
	| 'bucket'
	| 'claim-excess'
	| 'recent-activity'
	| 'ia_notes'
	| 'job-request-ping'
	| 'job-request-ping-detail'
	| 'installation-summary';

export const actionPanelItems = (instance, extras?) => {
	const serverCalls = {
		files: { ...fileBrowserActionPanel.serverCalls.files, functionName: extras?.filesFunctionName ?? 'getAllJobFiles' }
	};

	return [
		{
			id: 'call-logs',
			icon: 'call-log',
			instruction: 'Call Logs',
			command: instance.loadComponent.bind(instance, callLogsActionPanelNode)
		},
		{
			id: 'claim-card',
			icon: 'claim-card',
			instruction: 'Claim Summary',
			command: instance.loadComponent.bind(instance, pluginComponentMapper['FLXAPClaimSummaryComponent'])
		},
		{
			id: 'installation-summary',
			icon: 'claim-card',
			instruction: 'Installation Summary',
			command: instance.loadComponent.bind(instance, installationSummaryActionPanelNode)
		},
		{
			id: 'documents',
			icon: 'documents',
			instruction: 'Documents',
			command: instance.loadComponent.bind(instance, { ...fileBrowserActionPanel, serverCalls })
		},
		{
			id: 'installation-summary',
			icon: 'claim-card',
			instruction: 'Installation Summary',
			command: instance.loadComponent.bind(instance, installationSummaryActionPanelNode)
		},
		{
			id: 'time-stamp',
			icon: 'time-stamp',
			instruction: 'Timestamps',
			command: instance.loadComponent.bind(instance, pluginComponentMapper['FLXAPTimeStampsComponent'])
		},
		{
			id: 'what-matters',
			icon: 'what-matters',
			instruction: 'What Matters',
			command: instance.loadComponent.bind(instance, pluginComponentMapper['FLXWhatMattersComponent'])
		},
		{
			id: 'notes',
			icon: 'notes',
			instruction: 'Notes',
			command: instance.loadComponent.bind(instance, notesActionPanelNode),
			// to remove controls when action panel is toggled close
			BFControls: ['message']
		},
		{
			id: 'ia_notes',
			icon: 'internal-assessor-notes',
			instruction: 'Internal Assessor Notes',
			command: instance.loadComponent.bind(instance, iaNotesActionPanelNode)
		},
		{
			id: 'job-card',
			icon: 'job-card',
			instruction: 'Job Summary',
			command: instance.loadComponent.bind(instance, pluginComponentMapper['FLXAPJobSummaryComponent'])
		},
		{
			id: 'sp-details',
			icon: 'sp-details',
			instruction: 'SP Details',
			command: instance.loadComponent.bind(instance, pluginComponentMapper['FLXSPDetailsComponent'])
		},
		{
			id: 'query-history',
			icon: 'query-history',
			instruction: 'Query History',
			command: instance.loadComponent.bind(instance, queryHistoryActionPanelNode)
		},
		{
			id: 'policy-history',
			icon: 'policy-history',
			instruction: 'Policy History',
			command: instance.loadComponent.bind(instance, policyHistoryActionPanelNode)
		},
		{
			id: 'search',
			icon: 'search',
			instruction: 'Search',
			command: instance.loadComponent.bind(instance, pluginComponentMapper['SearchComponent'])
		},
		{
			id: 'filter',
			icon: 'filter-funnel',
			instruction: 'Filter',
			command: instance.loadComponent.bind(instance, pluginComponentMapper['SearchFilterComponent'])
		},
		{
			id: 'bucket',
			icon: 'bucket',
			instruction: 'Bucket',
			command: instance.loadComponent.bind(instance, pluginComponentMapper['BucketFilterComponent'])
		},
		{
			id: 'claim-excess',
			icon: 'excess-details',
			instruction: 'Claim Excess',
			command: instance.loadComponent.bind(instance, claimExcessActionPanelNode)
		},
		{
			id: 'recent-activity',
			icon: 'timeline',
			instruction: 'Recent Activity',
			command: instance.loadComponent.bind(instance, pluginComponentMapper['FLXRecentActivityComponent'])
		},
		{
			id: 'job-details',
			icon: 'job-card',
			instruction: 'Job Summary',
			command: instance.loadComponent.bind(instance, pluginComponentMapper['FLXSPJobDetailsComponent'])
		},
		{
			id: 'job-request-ping',
			icon: 'job-request-ping',
			instruction: 'Job Requests',
			command: instance.loadComponent.bind(instance, jobRequestPingActionPanelNode)
		},
		{
			id: 'job-request-ping-detail',
			icon: 'job-request-ping',
			instruction: 'Job Request Detail',
			command: instance.loadComponent.bind(instance, jobRequestPingDetailActionPanelNode)
		}
	];
};

/**
 * Add the specified items passed in the ids array to the action panel.
 * The possible IDs are:
 * call-log
 * claim-card
 * documents
 * time-stamp
 * what-matters
 * notes
 * job-card
 * query-history
 * policy-history
 * search
 * bucket
 *
 *
 * @param instance, FLXActionPanelComponent
 * @param ids, a list of ids of the desired action panel items
 * @param extras (optional), any extra options that can be used for customization
 * @returns - {() => any[]}
 */
export const setActionPanelItems = (instance: FLXActionPanelComponent, ids: ActionPanelIDs[], extras?: { [key: string]: any }) => () =>
	ids?.reduce((returnedItems, id) => [...returnedItems, ...[actionPanelItems(instance, extras)?.find(item => item?.id === id)]], []);

// actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents'], 'spService'),
