import { Component, OnInit} from '@angular/core';
import { BigFormService } from '@flexus/core';

@Component({
	selector: 'flx-autopay-add-equipment',
	templateUrl: 'autopay-add-equipment.component.html',
	styleUrls: ['autopay-add-equipment.component.scss']
})
export class FLXAutopayAddEquipmentComponent implements OnInit {
	// variables
	diplexer_qty: number = 0;
	rfmodulator_qty: number = 0;
	wificonnector_qty: number = 0;
	dishplate_steel_qty: number = 0;
	dishplate_fibre_qty: number = 0;
	dishplate_alum_qty: number = 0;

	constructor(private bigForm: BigFormService) {}

	incrementNumber(childElement) {
		switch (childElement) {
			case 'dishplate_aluminium':
				let alu_dishplate_number = this.dishplate_alum_qty;
				alu_dishplate_number = alu_dishplate_number + 1;
				this.dishplate_alum_qty = alu_dishplate_number;

				this.bigForm.getControl('dishplate_aluminium').setValue(this.dishplate_alum_qty);
				break;
			case 'dishplate_steel':
				let steel_dishplate_number = this.dishplate_steel_qty;
				steel_dishplate_number = steel_dishplate_number + 1;
				this.dishplate_steel_qty = steel_dishplate_number;

				this.bigForm.getControl('dishplate_steel').setValue(this.dishplate_steel_qty);
				break;
			case 'dishplate_fibre':
				let fibre_dishplate_number = this.dishplate_fibre_qty;
				fibre_dishplate_number = fibre_dishplate_number + 1;
				this.dishplate_fibre_qty = fibre_dishplate_number;

				this.bigForm.getControl('dishplate_fibre').setValue(this.dishplate_fibre_qty);
				break;
			case 'wificonnector':
				let wificonnect_number = this.wificonnector_qty;
				wificonnect_number = wificonnect_number + 1;
				this.wificonnector_qty = wificonnect_number;

				this.bigForm.getControl('wificonnector').setValue(this.wificonnector_qty);
				break;
			case 'rfmodulator':
				let rmmodulator_number = this.rfmodulator_qty;
				rmmodulator_number = rmmodulator_number + 1;
				this.rfmodulator_qty = rmmodulator_number;

				this.bigForm.getControl('rfmodulator').setValue(this.rfmodulator_qty);
				break;
			case 'diplexer':
				let diplexer_number = this.diplexer_qty;
				diplexer_number = diplexer_number + 1;
				this.diplexer_qty = diplexer_number;

				this.bigForm.getControl('diplexer').setValue(this.diplexer_qty);
				break;
		}
	}
	decrementNumber(childElement) {
		switch (childElement) {
			case 'dishplate_aluminium':
				if (this.dishplate_alum_qty === 0) {
					this.dishplate_alum_qty = 0;
				} else {
					let alu_dishplate_number = this.dishplate_alum_qty;
					alu_dishplate_number = alu_dishplate_number - 1;
					this.dishplate_alum_qty = alu_dishplate_number;
				}

				this.bigForm.getControl('dishplate_aluminium').setValue(this.dishplate_alum_qty);
				break;
			case 'dishplate_steel':
				if (this.dishplate_steel_qty === 0) {
					this.dishplate_steel_qty = 0;
				} else {
					let steel_dishplate_number = this.dishplate_steel_qty;
					steel_dishplate_number = steel_dishplate_number - 1;
					this.dishplate_steel_qty = steel_dishplate_number;
				}

				this.bigForm.getControl('dishplate_steel').setValue(this.dishplate_steel_qty);
				break;
			case 'dishplate_fibre':
				if (this.dishplate_fibre_qty === 0) {
					this.dishplate_fibre_qty = 0;
				} else {
					let fibre_dishplate_number = this.dishplate_fibre_qty;
					fibre_dishplate_number = fibre_dishplate_number - 1;
					this.dishplate_fibre_qty = fibre_dishplate_number;
				}

				this.bigForm.getControl('dishplate_fibre').setValue(this.dishplate_fibre_qty);
				break;
			case 'wificonnector':
				if (this.wificonnector_qty === 0) {
					this.wificonnector_qty = 0;
				} else {
					let wificonnector_number = this.wificonnector_qty;
					wificonnector_number = wificonnector_number - 1;
					this.wificonnector_qty = wificonnector_number;
				}

				this.bigForm.getControl('wificonnector').setValue(this.wificonnector_qty);
				break;
			case 'rfmodulator':
				if (this.rfmodulator_qty === 0) {
					this.rfmodulator_qty = 0;
				} else {
					let rmmodulator_number = this.rfmodulator_qty;
					rmmodulator_number = rmmodulator_number - 1;
					this.rfmodulator_qty = rmmodulator_number;
				}

				this.bigForm.getControl('rfmodulator').setValue(this.rfmodulator_qty);
				break;
			case 'diplexer':
				if (this.diplexer_qty === 0) {
					this.diplexer_qty = 0;
				} else {
					let diplexer_number = this.diplexer_qty;
					diplexer_number = diplexer_number - 1;
					this.diplexer_qty = diplexer_number;
				}

				this.bigForm.getControl('diplexer').setValue(this.diplexer_qty);
				break;
		}
	}

	ngOnInit() {}
}
