import { UntypedFormControl } from '@angular/forms';
import { Flow_0_0_2, getData, getFullItemOne, getFullItemTwo, getSelectedItem } from '@flexus/core';
import { setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { filter, skipWhile, first, map, take, switchMap } from 'rxjs/operators';

export const PGG_226: Flow_0_0_2 = {
	id: '226',
	name: 'ready-for-payment-process',
	itemType: 'flow',
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				filter((x: any) => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Ready for Payment : ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					} else {
						return 'Ready for Payment';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: (instance: any) => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	serverCalls: {
		invoice: {
			serviceVariable: 'pggService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		files: {
			serviceVariable: 'pggService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		notes: {
			serviceVariable: 'pggService',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No claim notes could be found!'
		}
	},
	instructions: {
		editRoles: {
			0: 'Pay the Scheduler Now'
		},
		viewRoles: {
			0: 'Ready for Payment Processing'
		}
	},
	startNode: 'ReadyForPayment',
	nodes: {
		ReadyForPayment: {
			serverCalls: {
				dataFiles: {
					errorMessage: 'No invoice found!',
					// directCall: (http: any, store: any) => {
					// 	return store
					// 		.select(getData)
					// 		.pipe(
					// 			skipWhile((data: any) => !data),
					// 			take(1)
					// 		)
					// 		.pipe(
					// 			map((da: any) => {
					// 				console.log('DADA', da);
					// 				debugger;
					// 			})
					// 		);
					// }
					directCall: (http: any, store: any, sq: any) => {
						// debugger;
						// return throwError('something went wrong');
						return sq
							.queryObject(
								gql`
									{
										invoice {
											data
										}
									}
								`,
								store.select(getData)
							)
							.pipe(
								filter(x => !!x && Object.keys(x)?.length !== 0),
								take(1),
								map((data: any) => {
									return data['data'];
								})
							);
					}
				},
				claimvalue: {
					errorMessage: `Couldn't retrieve claim value`,
					directCall: (http: any, store: any) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile((data: any) => !data),
								take(1),
								map((value: any) => value)
							)
							.pipe(
								map((job: any) => {
									const claimvalue: string = job?.claim_value;
									const claimValueObj = {
										'Payout amount': `${claimvalue}` ?? 'Payout amount not reflected on job.'
									};
									return [claimValueObj];
								})
							);
					}
				}
			},
			initFormFields: (bf: any) => {
				bf.addControl('new_state', new UntypedFormControl(28));
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'This job is ready for payment processing.',
							instructions: ['Pay the Scheduler.', `Click 'Paid' once payment is complete.`]
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'claimvalue',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					}
				]
			},
			navs: [
				{
					text: 'paid',
					color: 'primary',
					linkType: 'submit',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated',
							directCall: (http: any, store: any, sq: any, bf: any) => {
								return store
									.select(getSelectedItem)
									.pipe(
										skipWhile((x: any) => !x),
										take(1),
										map((result: any) => result.id)
									)
									.pipe(
										switchMap((res: any) => {
											const data = {
												job_id: res,
												new_state: bf.bigForm.get('new_state')?.value
											};
											return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
										})
									);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: [() => 28, 'new_state']
	}
};
