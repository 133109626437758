import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

import { FLX_CONTEXT_MENU_SCROLL_STRATEGY_PROVIDER, FLXContextMenuComponent } from './context-menu.component';
import { FLXDialogModule } from '../dialog/dialog.module';
import { FLXIconModule } from '../inline-icons/icon.module';

@NgModule({
	imports: [CommonModule, FLXDialogModule, OverlayModule, PortalModule, FLXIconModule],
	declarations: [FLXContextMenuComponent],
	exports: [FLXContextMenuComponent],
	providers: [FLX_CONTEXT_MENU_SCROLL_STRATEGY_PROVIDER]
})
export class FLXContextMenuModule {}
