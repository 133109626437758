import { Injectable } from '@angular/core';
import { switchMap, map, distinctUntilChanged } from 'rxjs/operators';
import { interval, of, Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { split, clone, mergeDeepRight } from 'ramda';

@Injectable({ providedIn: 'root' })
export class NetworkService {
	get isOnline(): Observable<boolean> {
		return interval(10).pipe(
			switchMap(() => of(window.navigator.onLine)),
			distinctUntilChanged()
		);
	}

	disableFieldsWhenOffline(form: UntypedFormGroup, fieldNames: string[]): Observable<any> {
		const fieldControls: { [key: string]: AbstractControl } | { [key: string]: UntypedFormControl } = fieldNames?.reduce(
			(acc: { [key: string]: UntypedFormControl }, name: string) => {
				if (name.includes('.')) {
					const props = split('.', name);
					const lastProp = props[props.length - 1];
					let fg: UntypedFormGroup = clone(form);
					for (let i = 0; i < props.length - 1; i++) {
						const prop = props[i];
						fg = form.controls[prop] as UntypedFormGroup;
					}
					return {
						...acc,
						[name]: fg.controls[lastProp]
					};
				} else {
					return {
						...acc,
						[name]: form.controls[name]
					};
				}
			},
			{}
		);
		return this.isOnline.pipe(
			map(isOnline => {
				if (isOnline) {
					return Object.entries(fieldControls).map(([key, ctrl]) => {
						ctrl.enable();
						return { [key]: { status: 'ENABLED', enabled: ctrl.enabled, disabled: ctrl.disabled } };
					});
				} else {
					return Object.entries(fieldControls).map(([key, ctrl]) => {
						ctrl.disable();
						return { [key]: { status: 'DISABLED', enabled: ctrl.enabled, disabled: ctrl.disabled } };
					});
				}
			}),
			map(res => {
				return res?.reduce((formed, obj) => {
					const key = Object.keys(obj)[0];
					return {
						...mergeDeepRight(formed, normalizePropsAsObject(key, obj[key]))
					};
				}, {});
			})
		);
	}
}

const normalizePropsAsObject = (key: string, value: any) => {
	let res = {};
	if (key.includes('.')) {
		const propNames = key.split('.');
		for (let i = propNames.length; i > 0; i--) {
			const item = propNames[i - 1];
			const temp = {};
			temp[item] = i === propNames.length ? value : {};
			if (i !== propNames.length) {
				Object.keys(res).forEach(r => {
					temp[item][r] = res[r];
				});
			}
			res = temp;
		}
	}
	res = JSON.parse(JSON.stringify(res));
	return res;
};
