import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { cleanUpSub } from '@flexus/utilities';
import { BigFormService } from '@flexus/core';

export interface SearchBarValue {
  checkClosed: boolean;
  inSearch: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'flx-search-bar',
  styleUrls: ['./search-bar.component.scss'],
  templateUrl: './search-bar.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBarComponent),
      multi: true,
    },
  ],
})
export class SearchBarComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  readonly #fb = inject(UntypedFormBuilder);
  internalForm: UntypedFormGroup = this.#fb.group({
    checkClosed: [false],
    inSearch: ['', [Validators.minLength(4)]],
  });
  disabled = false;
  internalFormSub: Subscription;
  private _onTouch: () => void;
  private _onChange: (obj) => void;
  // toggleOptions = ['offline', 'online'];
  @Output() clear = new EventEmitter();
  @Output() doSearch = new EventEmitter();
  @Input() prompt = '';
  @Input() minLength: number;
  @Input() showIncludeClosedClaims = true;
  inSearchCtrl: UntypedFormControl;

  constructor(private bf: BigFormService) {}

  clearForm() {
    this.clear.emit('clearSearch');
  }

  ngOnInit() {
    this.inSearchCtrl = this.internalForm.controls[
      'inSearch'
    ] as UntypedFormControl;

    if (this.minLength) {
      this.internalForm
        .get('inSearch')
        ?.setValidators([Validators.minLength(this.minLength)]);
    }

    this.internalFormSub = this.internalForm.valueChanges.subscribe(
      (changes) => {
        if (!this.bf.getControl('search')) {
          this.bf.addControl(
            'search',
            new UntypedFormControl({ checkClosed: false, search: '' })
          );
        }
        if (!!this._onChange && !!this._onTouch && this.disabled === false) {
          this._onChange(changes);
          this._onTouch();
        }
      }
    );
  }

  sendSearch() {
    this.doSearch.emit('search');
  }

  ngOnDestroy() {
    cleanUpSub(this.internalFormSub);
  }

  // ====================== Control Value Accessor Methods ================================
  writeValue(obj: { checkClosed?: boolean; inSearch?: string }): void {
    if (obj) {
      this.internalForm.patchValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.internalForm.disable();
    } else {
      this.internalForm.enable();
    }
  }
}
