import { Flow_0_0_2, getData, getFullItemTwo } from '@flexus/core';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { photoNode } from '../reusable/PHOTO_NODE';
import { setActionPanelItems } from '../../../../app-shell-features';
import { forkJoin } from 'rxjs';
import { filter, map, pluck, skipWhile, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

export const MUL_254: Flow_0_0_2 = {
	id: '254',
	name: 'problem_claiming_voucher_in_portal',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Problem Claiming Voucher In Portal',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Problem Claiming Voucher In Portal'
		},
		viewRoles: {
			0: 'Problem Claiming Voucher In Portal'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulSpService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Cannot get voucher summary'
		}
	},
	startNode: 'jobInformation',
	nodes: {
		jobInformation: {
			showTabs: true,
			...jobInfoNode(254),
			navs: [
				{
					text: 'Problem Fixed',
					color: 'primary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'warning';
							instance.heading = 'Confirmation';
							instance.setMessage(['Are you sure you have fixed the problem raised?']);
							instance.navButtons = [
								{
									text: 'Back',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'Yes',
									color: 'default',
									linkType: 'submitThenNext',
									nextNode: 'submissionSuccess',
									serverCalls: {
										updateJob: {
											errorMessage: 'Job was not updated',
											directCall: (http, store) => {
												return forkJoin([
													store.select(getFullItemTwo).pipe(
														filter(x => !!x && Object.keys(x).length !== 0),
														take(1),
														map(res => res as any)
													),
													store.select(getData).pipe(
														skipWhile(v => !v),
														pluck('voucher'),
														take(1)
													)
												]).pipe(
													switchMap(([job, voucher]: any) => {
														const thisjob = job as any;
														if (job && voucher) {
															const decoder_number = voucher?.['decoder_number'];
															const poh_job_info = job?.['job_information'];

															const data = {
																job_id: job?.['id'],
																job_information: { ...poh_job_info, decoder_number },
																current_state: 254,
																new_state: 26
															};
															return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
														}
													})
												);
											}
										}
									}
								}
							];
						}
					}
				}
			]
		},
		photo: {
			...photoNode(254)
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
