import { Injectable, Inject } from '@angular/core';
import { map, catchError, take, mergeMap, switchMap, tap, skipWhile, filter, withLatestFrom } from 'rxjs/operators';
import { of, Subscription, empty, iif, forkJoin, from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
	SET_ACTIVE_MANIFEST_ITEM,
	SET_NEXT_NODE,
	SET_NEXT_NODE_USING_TAB,
	SetView,
	SetViewSuccess,
	SetViewFail,
	SET_VIEW,
	LoadManifest,
	SET_ACTIVE_ORGANIZATION,
	LOAD_MANIFEST,
	SET_ACTIVE_MANIFEST_ITEM_FOR_GRAPH,
	SET_PREVIOUS_NODE,
	SET_VIEW_SUCCESS,
	USE_EXISTING_DATASOURCE_FOR_VIEW,
	SetActiveManifestItem,
	DeleteLocalDraft,
	DELETE_LOCAL_DRAFT,
	CHANGE_MANIFEST_STATE,
	ChangeManifestState
} from './manifest.actions';
import { getActiveManifestItem, getActiveViewData, getManifestState } from './manifest.selectors';
import { path } from 'ramda';
import {
	MakeServerCall,
	MakeServerCallFail,
	MakeServerCallSuccess,
	SelectItemOne,
	GetFullItemOneWithFullItemTwos,
	GetFullItemOne,
	SelectItemTwo,
	GetFullItemTwo,
	MAKE_SERVER_CALL_SUCCESS,
	MAKE_SERVER_CALL_FAIL
} from '../selected-context/selected-context.actions';
import { ManifestController } from '../controllers/manifest.controller';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { IndexedDbService } from '../services/storage-services';
import { BigFormService } from '../services/big-form.service';
import { NavService } from '../services/nav.service';
import { ModalService } from '../services/modal.service';
import { CLIENTSERVICE } from '../services/engine.constants';
import { StoreQuery } from '../services/store-query.service';
import { OfflineService } from '../services';
import { StopLoading } from '../global/global.action';
import { ServerCall_0_0_2 } from '../manifest-versions';

@Injectable()
export class ManifestHandlers {
	valueToBigFormSubscription: Subscription;
	activeOrg = null;
	constructor(
		private controller: ManifestController<any>,
		private store: Store<any>,
		private actions$: Actions,
		private sq: StoreQuery,
		public http: HttpClient,
		private indexedDb: IndexedDbService,
		private offlineService: OfflineService,
		private bf: BigFormService,
		public navService: NavService,
		private modal: ModalService,
		@Inject(CLIENTSERVICE) private service: any
	) {
		// console.log('In manifest handlers');
		controller.registerActionHandler(SET_ACTIVE_MANIFEST_ITEM, this.makeServerCallOnStateFlow).registerActionHandler(SET_ACTIVE_MANIFEST_ITEM, this.listenToCallsForLoader);
		controller.registerActionHandler(SET_NEXT_NODE, this.makeServerCallOnNode);
		controller.registerActionHandler(SET_NEXT_NODE_USING_TAB, this.makeServerCallOnNode);

		controller.registerActionHandler(SET_VIEW, this.setView);
		controller.registerActionHandler('ROUTER_NAVIGATED', this.listenToNavigation);
		controller.registerActionHandler(LOAD_MANIFEST, this.loadManifest);
		controller.registerActionHandler(SET_ACTIVE_ORGANIZATION, this.setActiveOrganization);
		controller.registerActionHandler(SET_ACTIVE_MANIFEST_ITEM, this.setActiveManifestItem);
		controller.registerActionHandler(SET_ACTIVE_MANIFEST_ITEM, this.getFullItemOneAfterManifest);
		controller.registerActionHandler(SET_ACTIVE_MANIFEST_ITEM_FOR_GRAPH, this.setActiveManifestItemForGraph);

		controller.registerActionHandler(SET_NEXT_NODE, this.setNextNode);
		controller.registerActionHandler(SET_NEXT_NODE_USING_TAB, this.setNextNodeUsingTab);
		controller.registerActionHandler(SET_PREVIOUS_NODE, this.setPreviousNode);
		controller.registerActionHandler(SET_VIEW, this.setViewActiveData);
		controller.registerActionHandler(SET_VIEW_SUCCESS, this.setViewSuccess);
		// controller.registerActionHandler(SET_VIEW_FAIL, this.loadClaimsForOffline);
		controller.registerActionHandler(USE_EXISTING_DATASOURCE_FOR_VIEW, this.useExistingDatasourceForView);
		controller.registerActionHandler('ROUTER_NAVIGATED', this.routerNavigated);
		controller.registerActionHandler(DELETE_LOCAL_DRAFT, this.deleteLocalDraft);
		controller.registerActionHandler(CHANGE_MANIFEST_STATE, this.changeManifestState);
	}

	getFullItemOneAfterManifest = (controller: ManifestController<any>, action: SetActiveManifestItem) => {
		const { orgKey, pathToFlows, itemId, itemOne, itemTwo } = action.payload;

		return this.controller.stateObservable
			.pipe(
				map((store: any) => store.activeOrganization),
				map((manifest: any) => (manifest?.orgKey === orgKey ? manifest : manifest['organizations']?.[orgKey])),
				map((activeOrg: any) => path<any>(pathToFlows, activeOrg)?.[itemId]),
				take(1),
				map(activeManifestItem => {
					if (!activeManifestItem?.dontLoadNodes) {
						if (itemOne || itemTwo) {
							itemTwo ? this.setAllItemTwoInformation(activeManifestItem, itemOne, itemTwo) : this.setAllItemOneInformation(activeManifestItem, itemOne);
						}
					}
				})
			)
			.subscribe(x => {
				// console.log('geting item one after manifest', { x });
			});
	};

	setAllItemOneInformation(activeManifestItem, itemOne) {
		this.store.dispatch(new SelectItemOne({ itemOne }));

		if (!activeManifestItem?.dontLoadItemOneFromServer) {
			if (activeManifestItem?.fetchLevel1And2) {
				this.store.dispatch(new GetFullItemOneWithFullItemTwos({ oneId: itemOne?.id }));
			} else {
				this.store.dispatch(new GetFullItemOne({ id: itemOne?.id }));
			}
		}
	}

	setAllItemTwoInformation(activeManifestItem, itemOne, itemTwo) {
		if (itemOne) {
			this.store.dispatch(new SelectItemTwo({ itemOne, itemTwo, setRelated: !activeManifestItem?.fetchLevel1And2 }));
		} else {
			this.store.dispatch(new SelectItemTwo({ itemOne: null, itemTwo, setRelated: false }));
		}

		if (itemOne) {
			if (activeManifestItem?.fetchLevel1And2) {
				this.store.dispatch(new GetFullItemOneWithFullItemTwos({ oneId: itemOne?.id, twoId: itemTwo?.id }));
			} else {
				this.store.dispatch(new GetFullItemOne({ id: itemOne?.id }));
			}
		}

		this.store.dispatch(new GetFullItemTwo({ id: itemTwo?.id }));
	}

	makeServerCallOnStateFlow = (controller: ManifestController<any>, action) => {
		const { pathToFlows, orgKey, itemId } = action?.payload;
		this.controller
			.select(getManifestState)
			.pipe(
				take(1),
				mergeMap(state => {
					// const activeOrganization = manifest.orgKey === orgKey ? manifest : manifest['organizations'][orgKey];
					let activeOrganization;
					if (state.activeOrganization?.orgKey === orgKey) {
						activeOrganization = state?.activeOrganization;
					} else if (orgKey === 'sp') {
						activeOrganization = state?.spOrganization;
					} else {
						activeOrganization = state?.activeOrganization['organizations'][orgKey];
					}
					this.activeOrg = activeOrganization;
					const allStates: Record<string, any> = path(pathToFlows, activeOrganization) as Record<string, any>;
					const activeManifestItem = allStates?.[itemId];

					// handle also for the startnode
					const startNode = activeManifestItem?.itemType === 'flow' ? activeManifestItem['startNode'] : null;
					if (startNode) {
						const activeNode = activeManifestItem['nodes'][startNode];
						let mockContextData = activeManifestItem['mockContextData'] ? { ...activeManifestItem['mockContextData'] } : {};
						mockContextData = activeNode['mockContextData'] ? { ...mockContextData['mockContextData'], ...activeNode['mockContextData'] } : mockContextData;

						if (activeManifestItem?.useMockContextData && Object.keys(mockContextData)?.length > 0) {
							return of(mockContextData);
						} else {
							let calls = {};
							calls = allStates?.serverCalls ? { ...calls, ...allStates.serverCalls } : calls;
							calls = activeManifestItem?.serverCalls ? { ...calls, ...activeManifestItem?.serverCalls } : calls;
							calls = activeNode?.serverCalls ? { ...calls, ...activeNode.serverCalls } : calls;
							return Object.entries(calls).map(([dataKey, value]: [string, ServerCall_0_0_2]) => {
								if (Object.keys(calls)?.length > 0) {
									return of(this.store.dispatch(new MakeServerCall({ dataKey, ...value })));
								} else {
									return empty();
								}
							});
						}
					} else {
						return empty();
					}
				}),
				mergeMap(res => res)
			)
			.subscribe();
	};

	listenToCallsForLoader = (controller: ManifestController<any>, action) => {
		let serverCallKeys = [];
		const serverCallResponse = [];
		this.actions$
			.pipe(
				ofType(MAKE_SERVER_CALL_SUCCESS, MAKE_SERVER_CALL_FAIL),
				filter(x => !!x),
				withLatestFrom(this.controller.select(getActiveManifestItem)),
				map(([actionable, manifestItem]) => {
					const actions: { payload: any } = actionable;
					const dataKey = actions.payload?.dataKey;
					const followUpKeys = actions.payload?.followUpSuccessCalls ? Object.keys(actions.payload?.followUpSuccessCalls) : [];
					serverCallResponse.push(dataKey);
					serverCallKeys = manifestItem?.serverCalls ? Object.keys(manifestItem?.serverCalls) : [];
					serverCallKeys.push(...followUpKeys);
					if (serverCallKeys.every(x => serverCallResponse.includes(x))) {
						setTimeout(() => {
							this.store.dispatch(new StopLoading({ loaderID: actions.payload?.loaderID }));
						});
					}
				})
			)
			.subscribe();
	};

	makeServerCallOnNode = (controller: ManifestController<any>, action) => {
		const compKey = action?.payload;
		this.controller
			.select(getActiveManifestItem)
			.pipe(
				skipWhile(activeManifestItem => !activeManifestItem?.['nodes'] || !activeManifestItem?.['nodes'][compKey]),
				take(1),
				mergeMap(activeManifestItem => {
					// handle also for the startnode
					const activeNode = activeManifestItem['nodes'][compKey];
					const mockContextData = activeNode.mockContextData;
					if (activeManifestItem?.useMockContextData && Object.keys(mockContextData)?.length > 0) {
						return of(mockContextData);
					} else {
						const serverCalls: ServerCall_0_0_2[] = activeNode.serverCalls || {};
						return Object.entries(serverCalls).map(([dataKey, value]) => {
							if (Object.keys(serverCalls)?.length > 0) {
								const {
									directCall,
									serviceVariable,
									functionName,
									responseSlice,
									errorMessage,
									followUpSuccessCalls,
									nextNode,
									ignoreFalseError,
									timeoutMilliseconds = 6000000000000
								} = value;
								if (directCall && typeof directCall === 'function') {
									// console.log('Running direct call');
									return directCall(this.http, this.store, this.sq, this.bf, this.controller, this.modal).pipe(
										// timeout(timeoutMilliseconds),
										map((res: any) => {
											// console.log({ FromDirectCall: res });

											if (!ignoreFalseError) {
												if (res?.success === false) {
													this.store.dispatch(
														new MakeServerCallFail({
															dataKey,
															error: { reason: res?.payload.reason },
															errorMessage,
															retryCall: { dataKey, ...(value as object) }
														})
													);
												} else {
													const result = responseSlice ? path(responseSlice?.split('.'), res) : res;
													this.store.dispatch(
														new MakeServerCallSuccess({
															followUpSuccessCalls,
															nextNode,
															dataKey,
															result
														})
													);
												}
											} else {
												const result = responseSlice ? path(responseSlice?.split('.'), res) : res;
												// console.log({ FromDirectCallResult: res });
												this.store.dispatch(
													new MakeServerCallSuccess({
														followUpSuccessCalls,
														nextNode,
														dataKey,
														result
													})
												);
											}
										}),
										catchError(error =>
											of(
												this.store.dispatch(
													new MakeServerCallFail({
														dataKey,
														error,
														errorMessage,
														retryCall: { dataKey, ...(value as object) }
													})
												)
											)
										)
									);
								} else {
									return this.service[functionName]().pipe(
										// timeout(timeoutMilliseconds),
										map((res: any) => {
											if (!ignoreFalseError) {
												if (res?.success === false) {
													this.store.dispatch(
														new MakeServerCallFail({
															dataKey,
															error: { reason: res?.payload?.reason },
															errorMessage,
															retryCall: { dataKey, ...(value as object) }
														})
													);
												} else {
													const result = responseSlice ? path(responseSlice?.split('.'), res) : res;
													this.store.dispatch(
														new MakeServerCallSuccess({
															followUpSuccessCalls,
															nextNode,
															dataKey,
															result
														})
													);
												}
											} else {
												const result = responseSlice ? path(responseSlice?.split('.'), res) : res;
												this.store.dispatch(
													new MakeServerCallSuccess({
														followUpSuccessCalls,
														nextNode,
														dataKey,
														result
													})
												);
											}
										}),
										catchError(error =>
											of(
												this.store.dispatch(
													new MakeServerCallFail({
														dataKey,
														error,
														errorMessage,
														retryCall: { dataKey, ...(value as object) }
													})
												)
											)
										)
									);
								}
							} else {
								return empty();
							}
						});
					}
				}),
				mergeMap((res: Observable<ServerCall_0_0_2>[]) => res)
			)
			.subscribe();
	};

	setView = (controller: ManifestController<any>, action: SetView) => {
		// Use this to trigger loading in ngrx store
		this.store.dispatch(new SetView({ func: null, key: '', params: { reverse: false } }));

		const viewData = action.payload.func(this.http, this.controller, this.indexedDb, this.offlineService, this.store);

		// Params
		const reverse = action.payload.params.reverse;

		return iif(
			() => viewData?.id === 'default' || viewData?.id === 'alt' || viewData?.id === 'team',
			forkJoin([
				from(this.indexedDb.claimInDraft.toArray()).pipe(
					skipWhile(x => !x),
					take(1)
				),
				viewData.dataSource().pipe(
					skipWhile(x => !x),
					take(1),
					map((data: any) => (reverse ? data?.reverse() : data)),
					map(this.activeOrg.virtualStatesFunction)
				)
			]).pipe(map(([localDrafts, items]: any) => [...localDrafts, ...items])),
			viewData.dataSource().pipe(
				map((data: any) => (reverse ? data?.reverse() : data)),
				map(this.activeOrg.virtualStatesFunction)
			)
		)
			.pipe(
				// take(1),
				// takeUntil(this.actions$.pipe(ofType(INITIALIZE_TEMP_DATA))),
				map(res => controller.dispatch(new SetViewSuccess({ key: viewData?.id, result: res }))),
				catchError(err => of(controller.dispatch(new SetViewFail({ error: err, viewData }))))
			)
			.subscribe();
	};

	// loadClaimsForOffline = (controller: ManifestController<any>, action) => {
	//   const viewData = action.payload.viewData;
	//   viewData
	//     .storeBinding
	//     .pipe(
	//       skipWhile((x) => !x),
	//       take(1),
	//       map(this.activeOrg.virtualStatesFunction),
	//       map((items: any[]) => items.filter((item) => !item.tempKey)),
	//       switchMap((items) => {
	//         return from(this.indexedDb.claimInDraft.toArray()).pipe(
	//           skipWhile((x) => !x),
	//           take(1),
	//           map((localDrafts) => [...localDrafts, items]),
	//         );
	//       }),
	//       map((res) => controller.dispatch(new SetViewSuccess({ key: viewData.id, result: res }))),
	//     )
	//     .subscribe();
	// };

	listenToNavigation = (controller: ManifestController<any>, action) => {
		this.bf.bigForm.reset();
		Object.keys(this.bf.bigForm.controls).forEach(control => this.bf.bigForm.removeControl(control));
	};

	loadManifest = (controller: ManifestController<any>, action: LoadManifest) => {
		// Get Rootlevel state
		const state = controller.getCurrentState();

		const activeOrganization = action.payload;
		// KEEPING TRACK OF THE SP ORGANIZATION FOR RESTORE
		const spOrganization = activeOrganization.orgKey === 'sp' ? activeOrganization : null;

		// use parent, area and new data to update state
		controller.updateState({
			...state,
			activeOrganization,
			spOrganization,
			navigationStack: []
		});
	};

	setActiveOrganization = (controller: ManifestController<any>, action) => {
		// Get Rootlevel state
		const state = controller.getCurrentState();

		const activeOrganization = action.payload;

		// use parent, area and new data to update state
		controller.updateState({
			...state,
			activeOrganization
		});
	};

	setActiveManifestItem = (controller: ManifestController<any>, action) => {
		// Get Rootlevel state
		const state = controller.getCurrentState();

		const { pathToFlows, orgKey, itemId, defaultNodeKey } = action?.payload;
		let activeOrganization;
		if (state.activeOrganization?.orgKey === orgKey) {
			activeOrganization = state?.activeOrganization;
		} else if (orgKey === 'sp') {
			activeOrganization = state?.spOrganization;
		} else {
			activeOrganization = state.activeOrganization['organizations'][orgKey];
		}
		const activeManifestItem = path(pathToFlows, activeOrganization)?.[itemId];
		const startNode = activeManifestItem?.itemType === 'flow' ? activeManifestItem['startNode'] : null;

		if (startNode) {
			const activeNode = defaultNodeKey ? activeManifestItem['nodes'][defaultNodeKey] : activeManifestItem['nodes'][startNode];
			let navigationStack;
			if (state.activeNode && !pathToFlows.includes('contextMenu')) {
				const prevNode = { ...state.activeNode, prevState: state.activeManifestItemId };
				navigationStack = [...state.navigationStack, prevNode];
			} else if (pathToFlows.includes('contextMenu')) {
				navigationStack = [];
			} else {
				navigationStack = [];
			}

			controller.updateState({
				...state,
				activeOrganization,
				activeManifestItemId: itemId,
				activeManifestItem,
				activeNode: { ...activeNode, id: startNode },
				navigationStack
			});
		} else {
			this.controller.updateState({
				...state,
				activeOrganization,
				activeManifestItemId: itemId,
				activeManifestItem,
				activeNode: null
			});
		}
	};

	setActiveManifestItemForGraph = (controller: ManifestController<any>, action) => {
		// Get Rootlevel state
		const state = controller.getCurrentState();

		const { pathToFlows, orgKey, itemId, defaultNodeKey } = action?.payload;
		const activeOrganization = state.activeOrganization.orgKey === orgKey ? state.activeOrganization : state.activeOrganization['organizations'][orgKey];
		const activeManifestItem = path(pathToFlows, activeOrganization)[itemId];
		const startNode = activeManifestItem?.itemType === 'flow' ? activeManifestItem['startNode'] : null;

		if (startNode) {
			const activeNode = defaultNodeKey ? activeManifestItem['nodes'][defaultNodeKey] : activeManifestItem['nodes'][startNode];
			let navigationStack;
			if (state?.activeNode && !pathToFlows.includes('contextMenu')) {
				const prevNode = { ...state.activeNode, prevState: state?.activeManifestItemId };
				navigationStack = [...state.navigationStack, prevNode];
			} else if (pathToFlows.includes('contextMenu')) {
				navigationStack = [];
			} else {
				navigationStack = [];
			}

			controller.updateState({
				...state,
				activeOrganization,
				activeManifestItemId: itemId,
				activeManifestItem,
				activeNode: { ...activeNode, id: startNode },
				navigationStack
			});
		} else {
			this.controller.updateState({
				...state,
				activeOrganization,
				activeManifestItemId: itemId,
				activeManifestItem
			});
		}
	};

	setNextNode = (controller: ManifestController<any>, action) => {
		// Get Rootlevel state
		const state = controller.getCurrentState();

		const compKey = action.payload;
		if (state.activeManifestItem?.itemType !== 'flow') {
			return;
		}
		const nodesObj = state.activeManifestItem['nodes'];

		const activeNode = nodesObj[compKey];

		if (Object.is(activeNode, state.activeNode)) {
			return;
		}
		// If a decision node, don't push to navstack
		if (state?.activeNode?.nodeType === 'decision') {
			controller.updateState({
				...state,
				activeNode: { ...activeNode, id: compKey }
			});
		} else {
			controller.updateState({
				...state,
				navigationStack: [...state?.navigationStack, state?.activeNode],
				activeNode: { ...activeNode, id: compKey }
			});
		}
	};

	setNextNodeUsingTab = (controller: ManifestController<any>, action) => {
		// Get Rootlevel state
		const state = controller.getCurrentState();

		const compKey = action.payload;
		const nodesObj = state.activeManifestItem['nodes'];
		const nodesArray = Object.keys(nodesObj);
		const indexOfCompKey = nodesArray.indexOf(compKey);
		const activeNode = nodesObj[compKey];
		if (Object.is(activeNode, state?.activeNode)) {
			return;
		}
		let navigationStack = [];
		for (let i = 0; i < indexOfCompKey; i++) {
			const key = nodesArray[i];
			navigationStack = [...navigationStack, nodesObj[key]];
		}
		controller.updateState({
			...state,
			navigationStack,
			activeNode: { ...activeNode, id: compKey }
		});
	};

	setPreviousNode = (controller: ManifestController<any>, action) => {
		// Get Rootlevel state
		const state = controller.getCurrentState();

		const navigationStack = state?.navigationStack;
		const prevNode = navigationStack.pop();

		if (prevNode && state?.activeManifestItem?.nodes) {
			const prevItemIds = Object.entries(state?.activeManifestItem?.nodes)
				.filter(node => prevNode['component'] === node[1]['component'])
				.map(data => {
					if (data?.length > 0) {
						return data[0];
					}
					return data;
				});
			if (prevItemIds?.length > 0) prevNode.id = prevItemIds[0];
		}

		if (prevNode?.prevState) {
			const activeManifestItemId = prevNode?.prevState;
			const activeManifestItem = state.activeOrganization['manifestItems'][activeManifestItemId];
			controller.updateState({
				...state,
				activeManifestItemId,
				activeManifestItem,
				activeNode: prevNode,
				navigationStack
			});
		} else {
			controller.updateState({
				...state,
				activeNode: prevNode,
				navigationStack
			});
		}
	};

	setViewActiveData = (controller: ManifestController<any>, action) => {
		// Get Rootlevel state
		const state = controller.getCurrentState();

		controller.updateState({
			...state,
			activeViewData: action?.payload
		});
	};

	setViewSuccess = (controller: ManifestController<any>, action: SetViewSuccess) => {
		// Trigger stopping loading
		this.store.dispatch(new SetViewSuccess({ key: '', result: null }));
		// Get Rootlevel state
		const state = controller.getCurrentState();

		const { key, result } = action?.payload;
		controller.updateState({
			...state,
			viewData: {
				...state.viewData,
				[key]: result
			}
		});
	};

	useExistingDatasourceForView = (controller: ManifestController<any>, action) => {
		// Get Rootlevel state
		const state = controller.getCurrentState();

		controller.updateState({ ...state, activeViewData: action?.payload });
	};

	routerNavigated = (controller: ManifestController<any>, action) => {
		// Get Rootlevel state
		const state = controller.getCurrentState();

		const {
			event: { url }
		} = action.payload;

		if (url === '/workflow') {
			controller.updateState({
				...state,
				activeNode: null,
				navigationStack: []
			});
		}
	};

	deleteLocalDraft = (controller: ManifestController<any>, action: DeleteLocalDraft) => {
		this.indexedDb.claimInDraft.delete(action.payload).then(() => {
			controller
				.select(getActiveViewData)
				.pipe(
					skipWhile(x => !x),
					take(1),
					switchMap(key => {
						return controller.select(getActiveManifestItem).pipe(
							take(1),
							tap(manifestItem => {
								if (window.navigator.onLine) {
									const viewFunc = manifestItem.views['default'];
									this.controller.dispatch(new SetView({ func: viewFunc, key, params: { reverse: false } }));
								} else {
									const viewFunc = manifestItem.views['defaultOffline'];
									this.controller.dispatch(new SetView({ func: viewFunc, key, params: { reverse: false } }));
								}
							})
						);
					})
				)
				.subscribe();
		});
	};

	changeManifestState = (controller: ManifestController<any>, action: ChangeManifestState) => {
		const state = controller.getCurrentState();

		const stateHandler = action.payload;
		const newState = stateHandler(state);

		controller.updateState(newState);
	};
}
