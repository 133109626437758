import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXModalComponent } from './modal/modal.component';
import { PipesModule } from '@flexus/pipes';
import { FLXButtonsModule } from '../buttons';
import { FLXHeadingsModule } from '../headings';
import { FLXFormsModule } from '../forms/';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { FLXReactiveFormsErrorDisplayModule } from '../reactive-form-error-display';
import { FLXTextareaModule } from '../forms/textarea';

@NgModule({
	imports: [CommonModule, FLXButtonsModule, PipesModule, FLXHeadingsModule, PortalModule, OverlayModule, FLXFormsModule, FLXReactiveFormsErrorDisplayModule, FLXTextareaModule],
	declarations: [FLXModalComponent],
	exports: [FLXModalComponent]
})
export class FLXModalsModule {}
