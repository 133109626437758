import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/studio/src/environments/environment';
import { Store } from '@ngrx/store';
import { AzureLogin } from '@flexus/core';

@Component({
	selector: 'azure-callback',
	template: ` <flx-loader-component [isFullScreen]="true" [disableBackground]="loaderOptions && !loaderOptions.showBackdrop"></flx-loader-component> `
})
export class AzureCallbackComponent implements OnInit {
	loaderOptions = { showBackdrop: true };
	constructor(private route: ActivatedRoute, private http: HttpClient, private store: Store<any>, private router: Router) {}

	ngOnInit() {
		const code = this.route.snapshot.queryParamMap.get('code');
		// console.log({ code });
		this.http.get(`${environment.api_url}${environment['azure']['codeForTokenExchangeUrl']}?code=${code}`).subscribe(res => {
			this.store.dispatch(new AzureLogin(res));
			this.router.navigate(['/']);
		});
	}
}
