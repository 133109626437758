import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { DocumentRef, WindowRef } from './agmish';
// import { LocationPickerService } from './location-picker.service';
import { CommonModule } from '@angular/common';
import { NgModule, Renderer2 } from '@angular/core';
import { LocationPickerComponent } from './location-picker.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Lps } from './lps';
import { PlacesAutocompleteDirective } from './places-autocomplete.directive';
import { ScriptLoaderDirective } from './script-loader.directive';
import { InlineSVGModule } from 'ng-inline-svg';
import { FLXIconModule } from '../inline-icons';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientJsonpModule, InlineSVGModule, FLXIconModule],
	declarations: [LocationPickerComponent, PlacesAutocompleteDirective, ScriptLoaderDirective],
	exports: [InlineSVGModule, LocationPickerComponent, PlacesAutocompleteDirective],
	providers: [DocumentRef, WindowRef, Lps, HttpClient]
})
export class FLXLocationPickerModule {}
