<main>
	<section
		class="app-shell-container"
		#appShell
		[ngClass]="[actionPanelVisible ? 'action-panel-visible' : 'action-panel-hidden', actionPanelExpanded ? 'action-panel-expand' : 'action-panel-collapse']"
	>
		<section class="module-wrapper">
			<!-- <div *ngIf="isOffline" class="offline">You are offline</div> -->
			<!-- <prompt-add-to-home-screen></prompt-add-to-home-screen> -->
			<!-- <h1>Test Web Socket</h1> -->
			<!-- <button (click)="testSocket()">Send Message</button> -->
			<!-- <h1>Push Notification</h1> -->
			<!-- <p>This page must be accessed using HTTPS or via localhost.</p> -->
			<!-- <button class="js-notify-btn">Notify me!</button> -->
			<!-- <button disabled class="js-push-btn">Enable Push Messaging</button><br /><br /> -->
			<!-- <div class="js-sub-endpoint" style="display:none;width:80%">
        <h3>Subscription Object:</h3>
        <code class="js-subscription-json"></code><br /><br /><br />
        <h3>Endpoint URL:</h3>
        <code class="js-endpoint-url"></code>
      </div>-->
			<div id="installBanner" class="addToHomeScreenBanner"><button id="installBtn">Install 4SURE</button></div>

			<flx-app-bar *ngIf="(userIsAuthenticated$ | async) && settings?.addAppBar" [manifestItem]="manifestItem"></flx-app-bar>
			<flx-error-handler></flx-error-handler>
			<flx-modal></flx-modal>
			<ng-container *ngIf="settings?.addReminder"> <flx-display-reminder (reminderDisplayCommand)="reminderDisplayCommandHandler($event)"></flx-display-reminder> </ng-container>
			<section class="module-container" [ngClass]="{ 'module-container--is-loading': loading$ | async }"><router-outlet></router-outlet></section>
			<app-footer [manifestItem]="manifestItem" [navs]="navs" *ngIf="(userIsAuthenticated$ | async) && settings?.addFooter"> </app-footer>
		</section>
	</section>
	<ng-container *ngIf="userIsAuthenticated$ | async">
		<flx-action-panel [actionPanelManifest]="manifestItem?.actionPanel" *ngIf="actionPanelVisible && settings?.addActionPanel"></flx-action-panel>
	</ng-container>
	<flx-popup></flx-popup>
</main>
<flx-loader-component [isFullScreen]="true" *ngIf="loading$ | async" [disableBackground]="loaderOptions && !loaderOptions.showBackdrop"></flx-loader-component>
<flx-loader-component [isFullScreen]="true" *ngIf="manualLoading$ | async" [disableBackground]="loaderOptions && !loaderOptions.showBackdrop"></flx-loader-component>
