<ng-container>
	<ng-container *ngIf="theList$ | async as jobs">
		<ng-container *ngIf="jobs?.length >= 1; else noResults">
			<div *ngFor="let job of jobs | paginate: { itemsPerPage: pageSize, currentPage: currentPage, id: 'list' }; index as i; trackBy: trackByFunc">
				<flx-sp-list-job-card
					[itemTwo]="job"
					[itemTwoContextMenuList]="itemTwoContextMenuList"
					[user]="user$ | async"
					[itemTwoPermissions]="itemTwoPermissions"
					[activeOrg]="activeOrg$ | async"
					[allInfo]="allInfo$ | async"
					[isOnline]="isOnline$ | async"
				></flx-sp-list-job-card>

				<!-- and here... -->
				<!-- CARD END -->
			</div>
		</ng-container>
	</ng-container>
</ng-container>
<div class="center-load">
	<ng-template #noResults>
		<flx-no-results [message]="'No results returned : please try a different filter or refresh the data.'"> </flx-no-results>
	</ng-template>
</div>
