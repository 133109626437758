<div #fileContainer class="file-viewer-preview-container" [style.height]="containerHeight + 50">
	<object #file [ngClass]="mimeType === 'pdf' ? 'pdf-preview' : ''" type="application/pdf" *ngIf="mimeType == 'pdf'">
		<!-- <p>This browser does not support PDFs. Please download the PDF to view it: <a [href]="source">Download PDF</a>.</p> -->
	</object>
	<div class="file-viewer-svg" *ngIf="mimeType == 'tiff'">
		<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
			<path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z" fill="white" />
			<path d="M0 0h24v24H0z" fill="none" />
		</svg>
		<p>File Preview Not Available</p>
	</div>
	<div *ngIf="mimeType == 'image'" class="file-viewer-image"><img [src]="source" width="100%" /></div>
</div>
