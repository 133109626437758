import { Flow_0_0_2, getAllInfo, getCurrentUser, getFullItemTwo, getSelectedItemTwo } from '@flexus/core';
import { map, pluck, skipWhile, take, filter, mergeMap, switchMap } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { EMPTY, forkJoin, of } from 'rxjs';
import { CustomValidators } from '@flexus/utilities';

let jobId;

export const MUL_CHANGE_TEAM_LEAD_DETAILS: Flow_0_0_2 = {
	id: 'ChangeTeamLeasDetails',
	itemType: 'flow',
	includeForOnlyStates: ['all'],
	name: 'Team Leader Details',
	header: {
		title: 'Team Leader Details',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	startNode: 'TeamLeadLookup',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {
		sps: {
			errorMessage: '',
			directCall: (http, store, sq, bf) => {
				return store.select(getAllInfo).pipe(
					skipWhile(x => !x),
					take(1),
					pluck('sps')
				);
			}
		},
		currentTeamLeader: {
			errorMessage: 'Current team leader could not be determined',
			directCall: (http, store, sq) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						filter(x => !!x),
						take(1),
						map(res => res as any)
					),
					http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
						skipWhile(x => !x),
						take(1),
						map(res => res['payload'])
					)
				]).pipe(
					map(([job, teamleaders]) => {
						jobId = job?.id;
						let teamLeadName;

						for (const teamleader of teamleaders) {
							if (teamleader?.id === job?.team_leader.id) {
								teamLeadName = teamleader?.full_name;
							}
						}

						if (!job.team_leader) {
							teamLeadName = 'Team leader not assigned yet';
						}

						return teamLeadName;
					})
				);
			}
		}
	},
	nodes: {
		TeamLeadLookup: {
			name: 'Team Lead Lookup',
			errorHandler: {
				retryPolicy: 'manual',
				displayFormat: 'none',
				onRetryComplete: () => EMPTY
			},
			initFormFields: (bf, item, inst, sq, store) => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl('', Validators.required));
				bf.bigForm.addControl('team_lead_lookup', new UntypedFormControl('', [Validators.minLength(2)]));

				store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(fullItemTwo => {
						if (fullItemTwo?.team_leader?.id) {
							bf.bigForm.get('assign_teamleader_id').patchValue(fullItemTwo?.team_leader?.id);
						}
					});
			},
			checkValidityForFields: ['assign_teamleader_id'],
			inputs: {
				// title: 'Search for Team Leader by Company',
				title: 'Reallocate Team Lead'
			},
			hasLoader: false,
			component: 'TeamLeadLookupComponent',
			navs: [
				{
					text: 'Save and Re-trigger',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't update job!",
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getCurrentUser).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									of(bf.bigForm.get('assign_teamleader_id')?.value).pipe(
										skipWhile(x => !x),
										take(1),
										map(rest => rest)
									)
								]).pipe(
									switchMap(([uid, lead]) => {
										const job_id = jobId;
										const staffmember = uid?.id;

										const data = {
											job_id: job_id,
											// error is: Staff member SP and Job assigned SP do not match
											staffmember: staffmember,
											team_id: lead
										};
										return http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
									})
								);
							}
						}
					},
					location: 'right'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {}
};
