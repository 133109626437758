import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromTheme from './theme.reducer';
import * as fromPagination from './pagination.reducer';
import { AppMenuState, appMenuReducer } from '@flexus/core';
// import { appMenuReducer, AppMenuState } from '@flexus/core';

export const UXReducers: ActionReducerMap<any> = {
	theme: fromTheme.reducer,
	pagination: fromPagination.reducer,
	appMenu: appMenuReducer
};

export interface UXState {
	theme: fromTheme.ThemeState;
	pagination: fromPagination.State;
	appMenu: AppMenuState;
}

export const getUXState = createFeatureSelector<UXState>('ux');
