import { Flow_0_0_2, getCurrentUser, getFullItemTwo, getSelectedItemTwo } from '@flexus/core';

import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { photoNode } from '../reusable/PHOTO_NODE';
import { setActionPanelItems } from '../../../../app-shell-features';
import { getAdditionalCostsComments, getInterstateComments } from '../reusable/dataManipulations';
import { combineLatest, EMPTY } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import gql from 'graphql-tag';
import { filter, map, skipWhile, switchMap, take } from 'rxjs/operators';
import { requestProofNode } from '../reusable/REQUEST_PROOF_PURCHASE';
import { environment } from 'apps/studio/src/environments/environment';
import { viewProofNode } from '../reusable/VIEW_PROOF_PURCHASE';

export const MUL_247: Flow_0_0_2 = {
	id: '247',
	name: 'approve_additional_costs',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Approve additional costs',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Approve additional costs'
		},
		viewRoles: {
			0: 'Approve additional costs'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		},
		invoice: {
			directCall: (http, store, sq, bf) => {
				return store.select(getSelectedItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					switchMap((itemTwo: any) => {
						return http
							.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
								job_id: itemTwo?.id,
								return_type: 1
							})
							.pipe(
								map(payload => {
									console.log('PAYLOAD', payload.payload);
									return payload.payload;
								})
							);
					})
				);
			},
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'jobInformation',
	nodes: {
		jobInformation: {
			initFormFields: () => {},
			showTabs: true,
			...jobInfoNode(247, 250)
		},
		photo: {
			...photoNode(247, 250)
		},
		InvoicePreview: {
			checkValidityForFields: ['request_proof_purchase'],
			name: 'Invoice',
			showTabs: true,
			errorHandler: {
				displayFormat: 'none',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			component: {
				children: [
					{
						// component: 'FLXFileViewerComponent',
						// inputs: {
						// 	base64Source$: 'invoice',
						// 	mimeType: ['pdf']
						// }
						// // }

						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: { dataFiles$: 'invoice', inputHeading: 'Invoice Preview' }
					}
				]
			},
			navs: [
				{
					text: 'request proof of purchase',
					nextNode: 'RequestProofOfPurchase',
					color: 'secondary'
				},
				{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'alert' },
				{
					text: 'Approve',
					nextNode: 'submissionSuccess',
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		ViewProofOfPurchase: {
			...viewProofNode(247, 250)
		},
		RequestProofOfPurchase: {
			...requestProofNode(247)
		},
		QueryInvoice: {
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('query', new UntypedFormControl('', [Validators.required, Validators.minLength(3)]));
				bf.addControl('new_state', new UntypedFormControl(249));
				bf.patchValues({ new_state: 249 });
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Reply to Installer Scheduler',
							headingMargin: '30px 0 75px 0',
							headingSize: 'medium',
							formControls: {
								0: {
									formControlName: 'query',
									inputType: 'textarea',
									label: "Comment why you're returning this to the Installer Scheduler",
									maxWidth: '50vw'
								}
							}
						}
					}
				]
			},
			inputs: {
				inputHeading: 'Reply to Installer Scheduler '
			},
			checkValidityForFields: ['query'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'submissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		query: [(query, storeObj) => getAdditionalCostsComments(query, storeObj), 'job_information.additionalcosts_queries'],
		request_proof_purchase: [
			(pop, stor, dev) => {
				let proof_of_purchase_queries = [];
				let tempqueries = stor?.['selectedContext']?.fullItemTwo?.job_information?.purchase_proof_requests;
				if (tempqueries) {
					if (Array.isArray(tempqueries)) {
						proof_of_purchase_queries = tempqueries;
					} else {
						proof_of_purchase_queries = [
							{
								...tempqueries
							}
						];
					}
				}
				const purchaseproofquery = [...proof_of_purchase_queries, { query: pop, queryreply: '' }];
				return purchaseproofquery;
			},
			'job_information.purchase_proof_requests'
		]
	}
};
