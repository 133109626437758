import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BigFormService, ManifestController, ModalService, SetNextNode, getFullItemTwo } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

@Component({
	selector: 'flx-fsia-login',
	templateUrl: 'fsia-login.component.html',
	styleUrls: ['fsia-login.component.scss']
})
export class FSIALoginComponent implements OnInit, AfterViewInit {
	public fsiaLoginForm!: UntypedFormGroup;
	isLoading: boolean = false;
	storeObjectSubscription: any;
	storeObject: any;
	state;
	statesub: Subscription;

	constructor(
		private _http: HttpClient,
		private _store: Store<any>,
		private _bf: BigFormService,
		private _modal: ModalService,
		private _cntrllr: ManifestController<any>,
		private _cd: ChangeDetectorRef,
		private _route: Router,
    @Inject('environment') private environment: any,
	) {}

	ngOnInit() {
		this.statesub = this._store
			.select(getFullItemTwo)
			.pipe(
				skipWhile(x => !x),
				take(1),
				map((response: any) => response)
			)
			.subscribe((data: any) => {
				this.state = data?.state;
				console.log('STATE', this.state);
			});
		this.fsiaLoginForm = new UntypedFormGroup({
			fsia_username: new UntypedFormControl('', [Validators.required]),
			fsia_password: new UntypedFormControl('', [Validators.required])
		});
		this._bf.addControl('fsiaLoginForm', this.fsiaLoginForm);
	}
	ngAfterViewInit() {
		this._cd.detectChanges();
	}
	cancelDefaultEventOnLoginSubmit(event: Event) {
		event.preventDefault();
		return this._store
			.select(getFullItemTwo)
			.pipe(
				skipWhile(x => !x),
				take(1),
				switchMap((result: any) => {
					let nextNode;
					const { office_use } = result;
					const { claim_status } = office_use;
					console.log('FISA RESULT', claim_status);
					this._bf.addControl('nextnode', new UntypedFormControl('', []));
					if (claim_status === 'Device not linked to voucher') {
						this._bf.patchValues({ nextnode: 'LinkDevice' });
					} else if (claim_status === 'Device not active') {
						this._bf.patchValues({ nextnode: 'LinkDevice' });
					}

					const loginDetail = this._bf.getControl('fsiaLoginForm').value;
					const username = loginDetail?.fsia_username;
					const password = loginDetail?.fsia_password;
					console.log('LOGIN DETAIL', loginDetail);
					this.isLoading = true;
					return this._http
						.post(`${this.environment.api_url}v1/installer_actions/login/`, {
							username: username,
							password: password
						})
						.pipe(
							skipWhile(x => !x),
							map((response: any) => {
								if (response.success) {
									const payload = response?.payload;
									const token = payload?.token;
									if (token && typeof token === 'string' && token.length > 0) {
										const received_token = token;
										console.log('FSIA TOKEN', received_token);
										this.isLoading = false;
										this._bf.patchValues({ fsia_username: '', fsia_password: '' });
										this._cntrllr.dispatch(new SetNextNode(this._bf.getControl('nextnode').value));
									}
									if (token && token?.success === false && token?.reason === 'Installer already logged into FSIA. Please wait for token to expire and try again.') {
										this.isLoading = false;
										const server_response = token?.reason;
										this._modal.openModalDirectly(instance => {
											instance.type = 'warning';
											instance.heading = 'Unsuccessful login!';
											instance.message = `Server response :'${server_response}'`;
											instance.navButtons = [
												{
													text: 'RETRY',
													linkType: 'close',
													clickHandler: ev => {
														this.isLoading = false;
														const { username, password } = this.fsiaLoginForm.controls;
														console.log('USERNAME', this.fsiaLoginForm.controls);
														// this._cntrllr.dispatch(new SetNextNode('FixProblem'));
														this._route.navigate(['/workflow']);
													}
												}
											];
										});
									}
									if (!token) {
										console.log('There is no token !!!!');
									}
									// if (!token.success) {
									// 	this.isLoading = false;
									// 	const server_response = token?.reason;
									// 	this._modal.openModalDirectly(instance => {
									// 		instance.type = 'warning';
									// 		instance.heading = 'Unsuccessful login!';
									// 		instance.message = `Server response :'${server_response}'`;
									// 		instance.navButtons = [
									// 			{
									// 				text: 'RETRY',
									// 				linkType: 'close',
									// 				clickHandler: ev => {
									// 					this.isLoading = false;
									// 					const { username, password } = this.fsiaLoginForm.controls;
									// 					console.log('USERNAME', this.fsiaLoginForm.controls);
									// 					this._cntrllr.dispatch(new SetNextNode('FSIALogin'));
									// 				}
									// 			}
									// 		];
									// 	});
									// }
								}
							})
						);
				})
			)
			.subscribe();
	}
	openFSAIInNewBrowserWindow(e: any) {
		e.preventDefault();
		this._bf.addControl('fsia_launched', new UntypedFormControl(true, []));
		console.log('opening FSIA');
		const url = 'https://dt-fsia.multichoice.co.za/FSIA/#/';
		window.open(url, '_blank');
	}
	ngOnDestroy() {
		if (this.statesub) {
			this.statesub.unsubscribe();
		}
	}
}
