import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getSubmissionData, SetNextNode } from '@flexus/core';
import { AVSResultFlag } from '@flexus/screens';
import { BET_BANK_DETAILS } from './BANK_DETAILS';
import { getAllInfoIndex, CustomValidators, getExtension } from '@flexus/utilities';
import { SINGLE_FILE_UPLOAD_SINGLE_PURPOSE } from './single_file_upload_single_purpose';
import { take, switchMap, map, skipWhile, filter, first, tap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { UntypedFormControl } from '@angular/forms';
import { of } from 'rxjs';

export const BET_56: Flow_0_0_2 = {
	id: '56',
	name: 'awaiting_invoice_cil',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Cash in Lieu : ${itemOne?.loan_information?.mavenclaimnumber ? itemOne?.loan_information?.mavenclaimnumber : itemOne?.loan_information?.mavenclaimnumber} - ${
							itemOne.applicant?.surname
						}`;
					} else {
						return 'Cash in Lieu';
					}
				})
			);
		},
		controls: () => () => []
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Cash in Lieu - Upload Invoice'
		},
		viewRoles: {
			0: 'Cash in Lieu - Upload Invoice'
		}
	},
	serverCalls: {
		// Clear out key value list from previous flow
		keyValueList: {
			errorMessage: '',
			directCall: () => {
				return of([]);
			}
		}
	},
	startNode: 'ViewCurrentInvoice',
	nodes: {
		ViewCurrentInvoice: {
			component: 'FileViewWithExtraComponent',
			errorHandler: { displayFormat: 'none' },
			initFormFields: (bf, item, inst, sq, store) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(220));
				bf.bigForm.addControl('currAvsResult', new UntypedFormControl(`1`, CustomValidators.contains(['1', '-2'])));
			},
			serverCalls: {
				dataFiles: {
					errorMessage: 'No invoice found!',
					ignoreFalseError: true,
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							filter(x => !!x),
							first(itemTwo => itemTwo !== null || itemTwo !== undefined),
							switchMap(itemTwo => {
								return http
									.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
										job_id: itemTwo?.id,
										return_type: 1
									})
									.pipe(
										map(value => {
											bf.bigForm.addControl('invoice', new UntypedFormControl(true, CustomValidators.contains([true])));
											return value.payload;
										})
									);
							})
						);
					}
				}
			},
			inputs: {
				fileDoesNotExistMessage: 'No invoice found. Please upload one.',
				inputHeading: 'View / Upload Cash in Lieu Invoice',
				subHeading: '1. Upload and check invoice   2. Confirm customer bank details '
			},
			checkValidityForFields: ['bank_details', 'invoice'],
			navs: [
				{ text: 'Upload Invoice', nextNode: 'UploadDocument' },
				{ text: 'View / Edit Bank Details', nextNode: 'BankDetails' },
				{
					text: 'Continue',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						next: {
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq, bf, controller) => {
								return store.select(getFullItemTwo).pipe(
									skipWhile(fullItemTwo => !fullItemTwo),
									take(1),
									tap(fullItemTwo => {
										if (fullItemTwo.skill === 87) {
											// If accidental death cover
											controller.dispatch(new SetNextNode('UploadIDDocument'));
										} else {
											controller.dispatch(new SetNextNode('SubmissionSuccess'));
										}
									})
								);
							}
						}
					}
				}
			]
		},
		...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes,
		BankDetails: {
			...BET_BANK_DETAILS,
			inputs: {
				...BET_BANK_DETAILS.inputs
			},
			serverCalls: {
				...BET_BANK_DETAILS.serverCalls,
				currBankDetails: {
					errorMessage: 'Could not pull bank details!',
					ignoreFalseError: true,
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							filter(x => !!x),
							first(itemTwo => itemTwo !== null || itemTwo !== undefined),
							switchMap(itemTwo => {
								const officeUse = itemTwo?.office_use;
								const policyDetails = itemTwo?.claim?.loan_information?.policydetails;
								const res = {
									initials: officeUse?.initials || policyDetails?.Initials || '',
									account_holder_name: officeUse?.surname || policyDetails?.Surname || '',
									account_number: officeUse?.bank_acc_number || '',
									identification_number: officeUse?.idnumber || policyDetails?.IdNumber || '',
									branch_code: officeUse?.branch_code || '',
									bank_name: officeUse?.bank || '',
									branch: officeUse?.branch || '',
									acc_type: officeUse?.acc_type || '',
									account_use: officeUse?.isnonpanel === true ? 'Company' : 'Individual'
								};
								return of(res);
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Bank details confirmed',
					location: 'right',
					color: 'secondary',
					nextNode: 'ViewCurrentInvoice'
				}
			]
		},
		UploadIDDocument: {
			...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes?.UploadDocument,
			hideTabItem: true,
			initFormFields: bf => {
				bf.patchValues({ new_state: 223 });
				bf.patchValues({ fileQueue: [] });
			},
			checkValidityForFields: [],
			inputs: {
				...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes?.UploadDocument?.inputs,
				heading: 'Upload the ID document',
				subheading: 'Upload the ID document',
				formControls: {}
			},
			navs: [
				{
					text: 'Upload ID Document',
					optIntoValidation: false,
					color: 'primary',
					serverFirst: true,
					nextNode: 'UploadDeathCertificateDocument',
					serverCalls: {
						uploadDoc: {
							errorMessage: 'File could not be uploaded',
							timeoutMilliseconds: 2000,
							directCall: (http, store, sq, bf, controller, modal) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0]?.file.base64,
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: 'id',
									filename: `id-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};

								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body).pipe(
									tap(response => {
										modal.openModalDirectly(inst => {
											inst.type = 'success';
											inst.heading = 'Successfully uploaded document.';
											inst.navButtons = [
												{
													text: 'Continue',
													color: 'primary',
													linkType: 'close'
												}
											];
										});
									})
								);
							},
							followUpFailCalls: {
								displaySucces: {
									errorMessage: 'Could not diplay success',
									directCall: (http, store, sq, bf, con, modal) => {
										modal.openModalDirectly(inst => {
											inst.type = 'warning';
											inst.heading = 'Could not upload file.';
											inst.navButtons = [
												{
													text: 'Continue',
													color: 'secondary',
													linkType: 'close'
												}
											];
										});
										return of(false);
									}
								}
							}
						}
					}
				}
			]
		},
		UploadDeathCertificateDocument: {
			...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes?.UploadDocument,
			hideTabItem: true,
			initFormFields: bf => {
				bf.patchValues({ fileQueue: [] });
			},
			checkValidityForFields: [],
			inputs: {
				...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes?.UploadDocument?.inputs,
				heading: 'Upload the death certificate',
				subheading: 'Upload the death certificate',
				showTabs: false,
				formControls: {}
			},
			navs: [
				{
					text: 'Upload death certificate',
					optIntoValidation: false,
					color: 'primary',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						uploadDoc: {
							errorMessage: 'File could not be uploaded',
							timeoutMilliseconds: 2000,
							directCall: (http, store, sq, bf, controller, modal) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0]?.file.base64,
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: 'death certificate',
									filename: `death_certificate-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};

								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body).pipe(
									tap(response => {
										modal.openModalDirectly(inst => {
											inst.type = 'success';
											inst.heading = 'Successfully uploaded document.';
											inst.navButtons = [
												{
													text: 'Continue',
													color: 'primary',
													linkType: 'close'
												}
											];
										});
									})
								);
							},
							followUpFailCalls: {
								displaySucces: {
									errorMessage: 'Could not diplay success',
									directCall: (http, store, sq, bf, con, modal) => {
										modal.openModalDirectly(inst => {
											inst.type = 'warning';
											inst.heading = 'Could not upload file.';
											inst.navButtons = [
												{
													text: 'Continue',
													color: 'secondary',
													linkType: 'close'
												}
											];
										});
										return of(false);
									}
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			serverCalls: {
				response: {
					serviceVariable: 'betService',
					functionName: 'updateJob',
					errorMessage: 'Job could not be updated!!'
				}
			}
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemTwo.id': 'job_id',
		'itemTwo.state': 'current_state',
		invoice_amount: ['claim_value', 'job_information.claim_value'],
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		excess_amount: ['update_excess', 'job_information.update_excess'],

		//bank details
		'bank_details.initials': 'office_use.initials',
		'bank_details.account_holder_name': 'office_use.surname',
		'bank_details.account_number': 'office_use.bank_acc_number',
		'bank_details.identification_number': 'office_use.idnumber',
		'bank_details.branch_code': 'office_use.branch_code',
		'bank_details.bank_name': 'office_use.bank',
		'bank_details.branch': 'office_use.branch',
		'bank_details.acc_type': 'office_use.acc_type',

		acc_type_desc: [
			(desc, storeObj, formValue) => {
				if (formValue.bank_details !== undefined) {
					return getAllInfoIndex('account_type', 'mid', 'description', formValue.bank_details.acc_type, storeObj);
				} else {
					return null;
				}
			},
			'office_use.acc_type_desc'
		],
		'bank_details.account_use': [
			val => {
				return val === 'Individual' ? false : true;
			},
			'office_use.isnonpanel'
		],
		'bank_details.avsSuccess': 'office_use.avsresult',
		'bank_details.avsFailedReasons': [
			(val, storeObj, formVals) => {
				if (formVals.avsSuccess === AVSResultFlag.fail_force_payment) return val;
				else return null;
			},
			'office_use.avsinitialsfailreason'
		]
	}
};
