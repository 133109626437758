import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FileListService {
	constructor(private http: HttpClient, @Inject('environment') private environment: any) {}

	getFullFile(imageId) {
		return this.http.post<any>(this.environment.api_url + 'v1/file_action/get_file', {
			file_id: imageId,
			return_type: 1
		});
	}
}
