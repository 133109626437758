import { Directive, ViewContainerRef, HostListener } from '@angular/core';
import { DynamicLoaderService } from '../services/dynamic-loader.service';

/** The directive that receives the currently loaded component, and manages what the current viewContainerRef is. */
@Directive({
	selector: '[dynamicLoaderHost]'
})
export class DynamicLoaderDirective {
	// private static _currentHost: ViewContainerRef;
	// private static _incomingHost: ViewContainerRef;

	constructor(public vcr: ViewContainerRef, public loaderService: DynamicLoaderService) {}

	/** Event handler for the invoke event.
	 * @param {any} comp The component to be loaded into the current containerRef.
	 */
	@HostListener('invoke', ['$event'])
	defineViewContainer(comp: any) {
		if (!comp) {
			// if (DynamicLoaderDirective._currentHost) {
			//   DynamicLoaderDirective._currentHost.clear();
			// }

			// if (DynamicLoaderDirective._incomingHost) {
			//   DynamicLoaderDirective._incomingHost.clear();
			// }
			this.vcr.clear();
			return;
		}

		// this.setCurrentHost();
		this.vcr.clear();
		this.loaderService.loadComponent(comp, this.vcr);
	}

	@HostListener('invokeWithInputs', ['$event'])
	defineViewContainerWithInputs({ component, inputs, node }) {
		if (!component) {
			// if (DynamicLoaderDirective._currentHost) {
			//   DynamicLoaderDirective._currentHost.clear();
			// }

			// if (DynamicLoaderDirective._incomingHost) {
			//   DynamicLoaderDirective._incomingHost.clear();
			// }
			this.vcr.clear();
			return;
		}

		// this.setCurrentHost();
		this.vcr.clear();
		this.loaderService.loadComponent(component, this.vcr, inputs, node);
	}

	/** Sets the current viewContainerRef and clears the previous one.
	 * This ensures we always have only one host for the dynamic components at any point in time.
	 */
	// private setCurrentHost() {
	//   if (DynamicLoaderDirective._currentHost === undefined) {
	//     DynamicLoaderDirective._currentHost = this.vcr;
	//   } else {
	//     // DynamicLoaderDirective._incomingHost = this.vcr;
	//     // DynamicLoaderDirective._currentHost.clear();
	//     // DynamicLoaderDirective._currentHost = DynamicLoaderDirective._incomingHost;
	//   }
	// }
}
