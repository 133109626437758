import { NgModule } from '@angular/core';
import { environment } from 'apps/studio/src/environments/environment';
import { UIElementsModule } from '@flexus/ui-elements';
import { CommonModule } from '@angular/common';
import { FLXAppMenuComponent } from './app-menu.component';
import { AppMenuOverlayService } from './app-menu.service';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
	imports: [
		CommonModule,
		// environment.appShellFeatures.includes(HeaderActionsModule) && HeaderActionsModule,
		UIElementsModule,
		OverlayModule
	],
	declarations: [FLXAppMenuComponent],
	exports: [FLXAppMenuComponent],
	providers: [AppMenuOverlayService]
})
export class AppMenuModule {}
