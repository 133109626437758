import { Flow_0_0_2 } from '@flexus/core';

export const SIL_9: Flow_0_0_2 = {
	id: '9',
	name: 'claim_cancelled',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	startNode: '',
	nodes: {}
};
