
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, getSubmissionData } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { skipWhile, take, map } from 'rxjs/operators';

@Component({
	selector: 'flx-base-invoice-and-credit-note-numbers',
	templateUrl: './base-invoice-and-credit-note-numbers.component.html',
	styleUrls: ['./base-invoice-and-credit-note-numbers.component.scss']
})
export class BaseInvoiceAndCreditNoteNumbersComponent implements OnInit, OnDestroy {
	baseInvoiceAndCreditNoteNumber = new UntypedFormGroup({
		base_invoice_number: new UntypedFormControl('', Validators.required),
		credit_note_number: new UntypedFormControl('', Validators.required)
	});
	formSubscription: Subscription;
	getSubmissionDataSub: Subscription;

	constructor(private bf: BigFormService, private store: Store) {}

	ngOnInit(): void {
		this.getFormValueFromStore();
		this.formSubscription = this.baseInvoiceAndCreditNoteNumber.valueChanges.subscribe(changes => {
			this.bf.patchValues({ ...changes });
		});
	}

	getFormValueFromStore() {
		this.getSubmissionDataSub = this.store
			.select(getSubmissionData)
			.pipe(
				skipWhile(x => !x),
				take(1),
				map(res => ({ base_invoice_number: res.base_invoice_number, credit_note_number: res.credit_note_number }))
			)
			.subscribe(data => {
				this.baseInvoiceAndCreditNoteNumber.patchValue({ ...data });
			});
	}

	ngOnDestroy(): void {
		if (this.formSubscription) this.formSubscription.unsubscribe();
		if (this.getSubmissionDataSub) this.getSubmissionDataSub.unsubscribe();
	}
}
