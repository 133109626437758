import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { iLineItem } from '@flexus/models';
import { BigFormService } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'dexie';

@Component({
	selector: 'flx-line-item-modify',
	templateUrl: './line-item-modify.component.html',
	styleUrls: ['./line-item-modify.component.scss']
})
export class LineItemModifyComponent implements OnInit, OnDestroy {
	public showDropDown = false;
	public isItemHover = false;
	public isItemActive = false;

	@Input() index: number;
	@Input() lineItemFormGroup!: UntypedFormGroup;
	@Input() availableLineItems: iLineItem[];
	@Input() icons: { src: string; alt: string }[] = [];
	@Input() allow_custom_items: boolean;
	@Input() actualLineItemsFormArray: FormArray;
	@Output() removeItem = new EventEmitter();
	@Output() startCreateCustomItem = new EventEmitter();
	@Output() startUpdateCustomItem = new EventEmitter();

	searchField = this.fb.control('');
	subscription: Subscription;

	client;

	constructor(public bf: BigFormService, @Inject('environment') private environment: any, private fb: UntypedFormBuilder, private store: Store<any>) {
		this.client = this.environment?.client;
	}

	ngOnDestroy(): void {
		this.subscription && this.subscription.unsubscribe();
	}

	ngOnInit(): void {}

	@HostListener('mouseenter') onMouseEnter() {
		this.isItemHover = true;
	}

	@HostListener('mouseleave') onMouseLeave() {
		this.isItemHover = false;
	}

	public updateActualLineItem(availableLineItem: iLineItem) {
		this.lineItemFormGroup.patchValue({
			id: availableLineItem.id,
			search: availableLineItem.name,
			description: availableLineItem.name,
			unitPrice: Number(availableLineItem.price).toFixed(2),
			quantity: 1
		});

		this.showDropDown = false;
	}

	updateCustomItem(event, item) {
		this.showDropDown = false;
		this.startUpdateCustomItem.emit(item);
		event.stopPropagation();
	}

	createCustomItem(event) {
		this.showDropDown = false;
		this.startCreateCustomItem.emit(true);
	}

	public openDropdown() {
		this.showDropDown = true;
		this.isItemActive = true;
	}

	get quantityUntypedFormControl() {
		return this.lineItemFormGroup.get('quantity') as UntypedFormControl;
	}
}
