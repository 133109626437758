<div class="flex-container">
	<div class="filtered-select-form" [style.width]="maxWidth">
		<flx-heading *ngIf="heading" size="medium" align="center">{{ heading }}</flx-heading>
		<flx-heading *ngIf="subheading" size="extra-small" align="center">{{ subheading }}</flx-heading>

		<form [formGroup]="FilterForm">
			<div class="filtered-select-input" *ngIf="canFilter && (!isCollapsed || !collapseOnSelect)">
				<flx-input-text size="large" formControlName="searchField" type="search" placeholder="Type to filter" #txtname> </flx-input-text>
			</div>
		</form>
		<div class="select-list__button" [style.maxHeight]="height" [class.condense-selection]="isCollapsed && collapseOnSelect">
			<flx-glow-line></flx-glow-line>

			<div class="select-list__options" [style.maxHeight]="height" #scrollContent>
				<ng-container *ngIf="displayList$ | async as displayList">
					<ng-container *ngIf="displayList.length > 0; else noList">
						<div
							*ngFor="let item of displayList"
							(click)="selectOption(item)"
							[class.selected]="selectedOptions.has(item?.value)"
							class="select-list__option"
							[ngClass]="{ disabled: item?.disabled }"
						>
							<div class="option__disabled" *ngIf="item?.disabled">
								<small>{{ disableOptionWhen.message }}</small>
							</div>
							<div class="option__content" [id]="item?.value">
								<div class="option__user" *ngIf="includeUserDisplay">
									<div class="option__user__monogram" *ngIf="!item?.image">{{ item?.monogram }}</div>
									<div class="option__user__image" *ngIf="item?.image"><img src="{{ 'data:image/jpeg;base64,' + item?.image }}" /></div>
								</div>
								<div class="option__display">{{ item?.display }}</div>
							</div>
						</div>
					</ng-container>
					<ng-template #noList>Not Found!</ng-template>
				</ng-container>
			</div>

			<flx-glow-line></flx-glow-line>
		</div>
	</div>
</div>
