import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { skipWhile, map, take, pluck } from 'rxjs/operators';
import { Validators } from '@angular/forms';

export const BET_SP_91: Flow_0_0_2 = {
	id: '91',
	name: 'ia_query_to_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) =>
			store.select(getFullItemTwo).pipe(
				skipWhile((itemTwo: any) => !itemTwo),
				take(1),
				map(itemTwo => {
					if (itemTwo) {
						return `Internal Assessor Query : ${itemTwo?.claim?.loan_information?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Internal Assessor Query';
					}
				})
			),
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		claimDetails: {
			errorMessage: 'Could get assessor details',
			serviceVariable: 'betSpService',
			functionName: 'getClaimDetailsFormatted'
		},
		assessorDetails: {
			errorMessage: 'Could get assessor details',
			serviceVariable: 'betSpService',
			functionName: 'getAssessorDetailsformatted'
		},
		quoteQuery: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					pluck('job_information', 'ia_query'),
					map((ia_query: any) => {
						if (ia_query) {
							const query = !Array.isArray(ia_query) ? [{ ...ia_query }] : [...ia_query];
							return {
								important_information: {
									"Assessor's Query": query[query.length - 1]?.query
								}
							};
						}
					})
				);
			}
		}
	},

	instructions: {
		editRoles: {
			0: 'Reply to desktop Assessor Query'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'ReviewAssessorQuery',
	nodes: {
		ReviewAssessorQuery: {
			name: 'Assessor Details',
			component: {
				children: [
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							headingConfig: { title: 'Quote Query', color: 'default', size: 'medium' },
							subHeadingConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							keyValueHeadingConfig: { color: 'secondary' },
							objectInfo$: 'quoteQuery'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'claimDetails'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'assessorDetails'
						}
					}
				]
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'QueryResponse',
					color: 'primary'
				}
			]
		},
		QueryResponse: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Respond to Assessor'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: "Please respond to the assessor's query",
									inputType: 'textarea',
									formControlName: 'queryreply'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.patchValues({ queryreply: bf.getControl('queryreply')?.value || '', new_state: '92' });
				bf.bigForm.get('queryreply')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			checkValidityForFields: ['queryreply'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'betSpService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!'
						}
					},
					color: 'primary'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		queryreply: [
			(queryreply, storeObject) => {
				const fullItemTwo = storeObject['selectedContext']?.fullItemTwo;

				const queries = fullItemTwo?.job_information?.ia_query;
				const ia_query = Array.isArray(queries) ? [...queries] : queries && typeof queries === 'object' && Object.keys(queries).length > 0 ? [{ ...queries }] : [];

				const lastIndex = ia_query.length - 1;

				if (lastIndex >= 0) {
					ia_query[lastIndex] = {
						...ia_query[lastIndex],
						queryreply: queryreply, // Update the queryreply
						queryreply_datetime: new Date().toISOString()
					};
				}

				return ia_query;
			},
			'job_information.ia_query'
		],
		total: [
			(a, b, bf) => {
				return bf.total + bf.vatRate;
			},
			'job_information.quote_amount'
		],
		invoiceNumber: 'job_information.quote_number'
	}
};
