import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getAllInfo,getFullItemTwo } from '@flexus/core';
import { KVLHeading } from '@flexus/ui-elements';
import { environment } from 'apps/studio/src/environments/environment';
import {  forkJoin } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';
import { getInsuranceInvoiceQueries } from '../reusable/dataManipulations';
import { CustomValidators } from '@flexus/utilities';
import { setActionPanelItems, CollapseActionPanel } from '../../../../app-shell-features';

export const MUL_251: Flow_0_0_2 = {
	id: '251',
	name: 'insurance-invoice-query',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	/**
  note: make more dynamic header
  */
	header: {
		title: 'MC Insurance Invoice Query',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Address the Invoice Query'
		},
		viewRoles: {
			0: 'Wait for SP to respond to query'
		}
	},
	startNode: 'InsuranceInvoiceQueryNotification',
	nodes: {
		InsuranceInvoiceQueryNotification: {
			serverCalls: {
				importantKeyValues: {
					errorMessage: 'Something went wrong with info',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									let message;
									let timestamp;
									let fromwho;
									const interstatecomments = job?.job_information?.insurance_invoice_queries;
									if (interstatecomments) {
										if (Array.isArray(interstatecomments)) {
											message = interstatecomments[interstatecomments.length - 1]?.query;
											timestamp = interstatecomments[0]?.timestamp;
											fromwho = interstatecomments[0]?.author_name;
										} else {
											message = interstatecomments?.query;
											timestamp = interstatecomments?.timestamp;
											fromwho = interstatecomments?.author_name;
										}
									} else {
										message = 'No new messages.';
										timestamp = 'Not dated.';
										fromwho = 'No eyes on this.';
									}
									const importantinfo = {
										'Message from Validation agent': message
										// Timestamp: `${moment(timestamp).format(moment.HTML5_FMT.DATE)} at ${moment(timestamp).format(moment.HTML5_FMT.TIME)}`,
										// 'Who did this come from?': fromwho
									};
									return [importantinfo];
								})
							);
					}
				},
				installationKeyValues: {
					errorMessage: "Couldn't return installation info",
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile(ai => !ai),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([itemTwo, info, teamleaders]) => {
								const sp = itemTwo?.sp;
								const sps = info.sps;
								let companyname, teamleadname;
								for (let i = 0; i < sps.length; i++) {
									if (sp === sps[i]?.id) {
										companyname = sps[i]?.name;
									}
								}
								const tleads = teamleaders as any;
								const templead = itemTwo?.team_leader;
								if (templead) {
									for (let i = 0; i < tleads.length; i++) {
										if (tleads[i]?.id === templead?.id) {
											teamleadname = tleads[i]?.full_name;
										}
									}
								} else {
									teamleadname = 'No team leader appointed';
								}
								const accreditation_number = itemTwo?.team_leader?.sp?.accreditation_number ?? '--';
								const qualification_number = itemTwo?.team_leader?.qualification_number ?? '--';
								const installerinfo = {
									'Company name ': companyname,
									'Accreditation number ': accreditation_number,
									'Installation technician qualification number': qualification_number,
									'Installer/Technician name ': teamleadname
								};
								return [installerinfo];
							})
						);
					}
				},
				contactKeyValues: {
					errorMessage: "Couldn't return customer contact details",
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								filter(x => !!x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									const { claim } = job;
									const claimtype = claim?.type;
									const contactperson = claim?.applicant;
									const loan_information = claim?.loan_information;
									let firstname = contactperson?.first_name;
									let surname = contactperson?.surname;
									let contactnumber = contactperson?.contact_number;
									let mobilenumber = loan_information?.cellnumber;
									let onsitename, onsitenumber;
									if (!contactperson) {
										firstname = 'John';
										surname = 'Doe';
										contactnumber = 'No number entered for contanct persron';
										mobilenumber = 'No cell number available.';
										onsitename = 'No detail for on-site person available';
										onsitenumber = 'No onsite detail available';
									} else {
										if (claimtype !== 'Pricelock Installation') {
											onsitename = loan_information?.onsiteperson;
											onsitenumber = loan_information?.onsitecontact;
										} else {
											onsitename = loan_information?.onSiteContact;
											onsitenumber = loan_information?.onsitecontact_w;
										}
									}
									const contactdetails = {
										'Full Name': `${firstname} ${surname}`,
										'Contact number': `${contactnumber}`,
										'Mobile number': `${mobilenumber}`,
										'On-site contact name': `${onsitename}`,
										'On-site contact number': `${onsitenumber}`
									};

									return [contactdetails];
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							color: 'secondary',
							data$: 'importantKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',
							data$: 'contactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Details',
							data$: 'installationKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			hideTabItem: false,
			navs: [
				{
					text: 'Upload New Invoice',
					linkType: 'portal',
					location: 'right',
					color: 'primary',
					nextNode: 'UploadNewInvoice'
				},
				{ text: 'Reply with Comment', color: 'primary', nextNode: 'InsuranceInvoiceQueryReply' }
			]
		},

		ViewInvoice: {
			hideTabItem: false,
			showTabs: true,
			name: 'Previous Invoice',
			serverCalls: {
				installerKeyValues: {
					errorMessage: 'Something went wrong with installer info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile(ai => !ai),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([itemTwo, info, teamleaders]) => {
								const sp = itemTwo?.sp;
								const sps = info.sps;
								let companyname;
								for (let i = 0; i < sps.length; i++) {
									if (sp === sps[i]?.id) {
										companyname = sps[i]?.name;
									}
								}
								const tleads = teamleaders as any;
								let teamleadname;
								for (let i = 0; i < tleads.length; i++) {
									if (tleads[i]?.id === itemTwo?.team_leader.id) {
										teamleadname = tleads[i]?.full_name;
									}
								}
								const accreditation_number = itemTwo?.team_leader?.sp?.accreditation_number ?? '--';
								const qualification_number = itemTwo?.team_leader?.qualification_number ?? '--';
								const installerinfo = {
									'Company name ': companyname,
									'Accreditation number ': accreditation_number,
									'Installation technician qualification number': qualification_number,
									'Installer/Technician name ': teamleadname
								};
								return [installerinfo];
							})
						);
					}
				},
				dataFile: {
					errorMessage: 'No files found',
					serviceVariable: 'spService',
					functionName: 'getJobInvoice'
				}
			},
			component: {
				children: [
					{
						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: {
							inputHeading: 'Insurance Invoice Query',
							dataFiles$: 'dataFile'
							// keyValueList$: 'installerKeyValues'
						}
					}
				]
			},
			navs: [
				{ text: 'upload new Invoice', color: 'primary', nextNode: 'UploadNewInvoice' },
				{ text: 'Reply with Comment', color: 'primary', nextNode: 'InsuranceInvoiceQueryReply' }
			]
		},
		InsuranceInvoiceQueryReply: {
			hideTabItem: true,
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.addControl('current_state', new UntypedFormControl(251, [Validators.required]));
				bf.addControl('new_state', new UntypedFormControl(252, [Validators.required]));
				bf.addControl('insurance_invoice_query', new UntypedFormControl('', [Validators.required]));
				bf.patchValues({ new_state: 252 });
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Reply to the insurance invoice query',
							formControls: {
								0: {
									label: 'Enter your message for the validation agent?',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'insurance_invoice_query'
								}
							},
							formLayout: 'stacked',
							containerWidth: '30vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'The job was not updated',
							serviceVariable: 'mulSpService',
							functionName: 'updateJob'
						}
					},
					linkType: 'submit'
				}
			]
		},
		UploadNewInvoice: {
			hideTabItem: true,
			initFormFields: (bf, item, instance, sq, store) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(253, [Validators.required]));
				bf.bigForm.addControl('current_state', new UntypedFormControl(251, [Validators.required]));
				bf.bigForm.addControl('purpose', new UntypedFormControl('Invoice', [Validators.required]));
				bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
				bf.bigForm.addControl('job_total', new UntypedFormControl('', [Validators.required, CustomValidators.noSpaces]));
			},
			checkValidityForFields: ['purpose', 'fileQueue', 'job_total'],
			component: {
				children: [
					{
						component: 'FLXFileUploadWithPreviewComponent',
						inputs: {
							purposes: [{ display: 'SP Invoice', value: 'invoice' }],
							heading: 'Select the Invoice to Attach',
							multipleUploads: false,
							allowedFileTypes: ['pdf'],
							containerWidth: '50vw',
							maxWidth: '50vw'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'job_total',
									inputType: 'currency',
									label: 'Enter invoice total.'
								}
							},
							formLayout: 'stacked',
							containerWidth: '10vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Upload Invoice',
					serverFirst: true,
					nextNode: 'FileSubmissionSuccess',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'portal',
					serverCalls: {
						uploadInvoice: {
							errorMessage: 'Invoice could not be uploaded',
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const filenom = fileQueue[0] ? fileQueue[0]?.file.nativeFile.name : '';
								const body: object = {
									data: fileQueue[0] ? fileQueue[0]?.file.base64 : '',
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: filenom
								};
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
							}
						}
					}
				}
			]
		},
		FileSubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'File successfully uploaded' },
			navs: [
				{
					text: 'Continue',
					serverCalls: {
						response: {
							serviceVariable: 'mulSpService',
							functionName: 'updateJob',
							errorMessage: 'Could not update job!'
						}
					},
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		current_state: 'current_state',
		new_state: 'new_state',
		job_total: [(ttl, stor, bf) => ttl, 'job_information.claim_value'],
		insurance_invoice_query: [(costsquery, storeObj, bf) => getInsuranceInvoiceQueries(costsquery, storeObj, bf), 'job_information.insurance_invoice_queries']
	}
};
