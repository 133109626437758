import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { BET_96 } from './BET_96';

export const BET_92: Flow_0_0_2 = {
	id: '92',
	name: 'assessor_query_sp_response',
	itemType: 'flow',
	onStateInit: BET_96.onStateInit,
	onStateDestroy: BET_96.onStateDestroy,
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					if (itemOne) {
						let title = 'Assessor Query SP Response';
						if (itemOne?.loan_information?.mavenclaimnumber) {
							title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
						}
						if (itemOne.applicant?.surname) {
							title += ` - ${itemOne.applicant?.surname}`;
						}

						return title;
					} else {
						return 'Assessor Query SP Response';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Review'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: BET_96.actionPanel,
	serverCalls: {
		...BET_96.serverCalls
	},
	startNode: 'InternalAssessorJobReason',
	nodes: {
		InternalAssessorJobReason: { ...BET_96?.nodes?.InternalAssessorJobReason },
		InternalAssessorReport: { ...BET_96?.nodes?.InternalAssessorReport },
		InternalAssessorJobQuote: { ...BET_96?.nodes?.InternalAssessorJobQuote },
		InternalAssessorJobPhotos: { ...BET_96?.nodes?.InternalAssessorJobPhotos },
		InternalAssessorJobQueryHistory: { ...BET_96?.nodes?.InternalAssessorJobQueryHistory },
		RecommendCIL: { ...BET_96?.nodes?.RecommendCIL },
		RecommendRejection: { ...BET_96?.nodes?.RecommendRejection },
		RecommendLossAdjuster: { ...BET_96?.nodes?.RecommendLossAdjuster },
		SubmissionSuccess: { ...BET_96?.nodes?.SubmissionSuccess }
	},
	bigFormToStoreMapper: { ...BET_96.bigFormToStoreMapper }
};
