import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, Input } from '@angular/core';
import { Validators, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BigFormService, ModalService } from '@flexus/core';
import { map } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { CustomValidators, generateRange } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';

@Component({
	selector: 'flx-what-matters',
	templateUrl: './what-matters.component.html',
	styleUrls: ['./what-matters.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXWhatMattersComponent implements OnInit, OnDestroy {
	@ViewChild('myDatePicker', { static: true }) myDatePicker;
	whatMattersSubscription: Subscription;
	handler: string;
	maxDate = new Date();

	@Input() isDateOfLossDisabled = false;
	@Input() whatmattersFormValidators = [Validators.required, Validators.minLength(10), CustomValidators.onlyAllowedASCII([10, ...generateRange(32, 126, 1)])];
	@Input() datePickerTitle = 'Date of loss';
	@Input() whatMattersTitle = 'What matters to the customer';
	@Input() additionalTextArea: { width: string; formControlName: string; title: string } = null;

	private _formControlSubscription: Subscription;

	constructor(public bf: BigFormService, private modalService: ModalService) {}

	ngOnInit() {
		this.bf.bigForm.addControl('claimdescription', new UntypedFormControl(''));

		// TODO: date_of_loss removal should also apply to pgg
		if (!['mul_sp'].includes(environment.client)) {
			this.bf.bigForm.addControl('date_of_loss', new UntypedFormControl('', Validators.required));

			const dateOfLossControl = this.bf.bigForm.get('date_of_loss');
			dateOfLossControl?.setValidators(Validators.required);
			this.bf.bigForm.get('whatmatters')?.setValidators([...this.whatmattersFormValidators]);
			this._formControlSubscription = dateOfLossControl?.valueChanges
				.pipe(
					filter(x => !!x),
					map(dol => new Date(dol))
				)
				.subscribe((dateOfLossChange: Date) => {
					//If date of loss is one month or older: warning modal
					if (dateOfLossChange) {
						if (
							dateOfLossChange.getMonth() < new Date()?.getMonth() - 1 ||
							(dateOfLossChange.getMonth() < new Date()?.getMonth() && dateOfLossChange.getDate() <= new Date()?.getDate())
						) {
							this.modalService.openModalDirectly(instance => {
								instance.type = 'warning';
								instance.closeButton = 'true';
								instance.message = 'Please take note that the date of loss selected is older than 30 days.';
								instance.navButtons = [];
							});
						}
					}
				});
		}
		this.bf.bigForm.addControl('whatmatters', new UntypedFormControl('', [...this.whatmattersFormValidators]));
		if (this.additionalTextArea) {
			this.bf.bigForm.addControl(this.additionalTextArea.formControlName, new UntypedFormControl(''));
		}
		// this.bf.addControls({
		//   date_of_loss: '',
		//   claimdescription: '',
		//   whatmatters: ''
		// });

		if (this.additionalTextArea) this.bf.bigForm.get(this.additionalTextArea.formControlName)?.setValidators(Validators.required);
	}

	ngOnDestroy(): void {
		if (this.whatMattersSubscription) this.whatMattersSubscription.unsubscribe();
		if (this._formControlSubscription) this._formControlSubscription.unsubscribe();
	}

	setCustom() {
		this.myDatePicker.open();
	}
}
