import { Renderer2, Component, Input, EventEmitter, Output } from '@angular/core';
import { EntityListConfig } from './models';
import { Observable } from 'rxjs';
import { EntityData, EntityCardState } from '../../cards';
import { EntityListEventTypes } from '../list-interfaces';
import { Store } from '@ngrx/store';
import { BigFormService } from '@flexus/core';

@Component({
	selector: 'flx-entity-list',
	templateUrl: './entity-list.component.html',
	styleUrls: ['./entity-list.component.scss']
})
export class FLXEntityListComponent {
	d: Observable<EntityData[]>;
	@Input() state?: number;
	@Output() outputData = new EventEmitter<any>();

	constructor(private rd: Renderer2, private _store: Store, private _bf: BigFormService) {}
	private alwaysEmit = false;

	// ================================ config ===================================
	@Input() set config(c: EntityListConfig) {
		if (c.alwaysEmitEvent !== undefined) {
			this.alwaysEmit = c.alwaysEmitEvent;
		}
	}

	// ================================== data ===================================
	@Input() set data$(d: Observable<EntityData[]>) {
		this.d = d;
	}

	bubbleEvent(e) {
		this.outputData.emit(e);
	}

	entitySelected(entity: EntityData) {
		if (this.alwaysEmit === true || entity.cardStateInfo.state === EntityCardState.success) {
			this.outputData.emit({
				type: EntityListEventTypes.EntitySelected,
				payload: entity
			});
		}
	}
}
