import { Flow_0_0_2, getFullItemTwo, getFullItemOne, getRelatedItemTwos, SetNextNode, GetFullItemOne, getAllInfo, getSubmissionData } from '@flexus/core';
import { Validators, UntypedFormControl } from '@angular/forms';
import { take, map, switchMap, skipWhile, filter, tap, pluck } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { forkJoin, EMPTY } from 'rxjs';

export const MISCELLANEOUS_CLAIMS_PAYMENT_CHECKS: Flow_0_0_2 = {
	id: 'miscellaneous_claims_payment_checks',
	name: 'miscellaneous_claims_payment_checks',
	itemType: 'flow',
	header: {
		title: 'Miscellaneous checks',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	startNode: 'MiscellaneousClass',

	nodes: {
		MiscellaneousClass: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: { retryInterval: 300, retryCount: 3 },
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				classCheck: (navs, store, modal, controller) => {
					return forkJoin([
						store.select(getFullItemOne).pipe(
							skipWhile(x => !x),
							take(1)
						),
						store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1)
						),
						store.select(getRelatedItemTwos).pipe(
							skipWhile(x => !x),
							take(1)
						)
					])
						.pipe(
							filter(x => !!x),
							take(1)
						)
						.subscribe(([claim, job, relatedItems]) => {
							if (claim?.claim_type === 21) {
								// const jobCheck = job?.valid_job === 1 || job.valid_job === 101;
								// let relatedCheck = false;

								// relatedItems.forEach(element => {
								// 	if (element.valid_job === 1 || element.valid_job === 101) {
								// 		relatedCheck = true;
								// 	}
								// });
								// if (jobCheck || relatedCheck) {
								modal.openModalDirectly(instance => {
									instance.type = 'warning';
									instance.message = 'Payments for valid work can not be made against a Miscellaneous claim class. Please proceed and change the claim class.';
									instance.navButtons = [
										{
											text: 'Change claim class',
											clickHandler: event => {
												controller.dispatch(new SetNextNode('ChangeClaimClass'));
											},
											linkType: 'close',
											color: 'alert'
										}
									];
								});
								// } else {
								// 	controller.dispatch(new SetNextNode('Decision'));
								// }
							} else {
								controller.dispatch(new SetNextNode('Decision'));
							}
						});
				}
			},
			navs: [{ text: 'Success', nextNode: 'SubmissionSuccess' }]
		},

		ChangeClaimClass: {
			component: 'ClaimClassComponent',
			serverCalls: {
				claimDetailsInfo: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											claim_types {
												id
												name
												description
												skill_groups
												priority
											}
											skills {
												id
												name
											}
											config_options {
												desktop {
													upfront_rejection_reasons
												}
											}
										}
									}
								`,
								store
							)
							.pipe(take(1));
					}
				}
			},
			initFormFields: (bf, item, comp, sq, store) => {
				bf.addControl('claim_class', new UntypedFormControl(null, [Validators.required]));
				bf.addControl('selectedClaimClass', new UntypedFormControl(null));
				bf.addControl('claim_class_description', new UntypedFormControl(''));

				bf.bigForm.addControl('firedamageextent', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('recomendedadjuster', new UntypedFormControl(null, [Validators.required]));

				//impact
				bf.bigForm.addControl('_3rdpartyinsurancecompany', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('_3rdpartyvechilereg', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('_3rdpartyvechilemake', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('_3rdpartydrivername', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('_3rdpartydriverid', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('_3rdpartydrivercell', new UntypedFormControl(null, [Validators.required]));
				// Theft / damage
				bf.bigForm.addControl('policeclaimnumber', new UntypedFormControl(null, [Validators.required]));
				// Miscellaneous
				bf.bigForm.addControl('upfrontrepudiationreason', new UntypedFormControl(null, [Validators.required]));

				forkJoin([
					store.select(getFullItemOne).pipe(
						skipWhile(res => !res),
						take(1)
					),
					store.select(getAllInfo).pipe(
						skipWhile(res => !res),
						take(1),
						pluck('claim_types')
					)
				])
					.pipe(
						take(1),
						map(([claim, claim_types]) => ({ claim, claim_types }))
					)
					.subscribe(({ claim, claim_types }) => {
						// Contextual form values
						//fire
						bf.bigForm.get('firedamageextent')?.patchValue(claim?.loan_information.firedamageextent ? claim?.loan_information.firedamageextent : null);
						bf.bigForm.get('recomendedadjuster')?.patchValue(claim?.loan_information.recomendedadjuster ? claim?.loan_information.recomendedadjuster : null);
						//impact
						bf.bigForm.get('_3rdpartyinsurancecompany')?.patchValue(claim?.loan_information._3rdpartyinsurancecompany ? claim?.loan_information._3rdpartyinsurancecompany : null);
						bf.bigForm.get('_3rdpartyvechilereg')?.patchValue(claim?.loan_information._3rdpartyvechilereg ? claim?.loan_information._3rdpartyvechilereg : null);
						bf.bigForm.get('_3rdpartyvechilemake')?.patchValue(claim?.loan_information._3rdpartyvechilemake ? claim?.loan_information._3rdpartyvechilemake : null);
						bf.bigForm.get('_3rdpartydrivername')?.patchValue(claim?.loan_information._3rdpartydrivername ? claim?.loan_information._3rdpartydrivername : null);
						bf.bigForm.get('_3rdpartydriverid')?.patchValue(claim?.loan_information._3rdpartydriverid ? claim?.loan_information._3rdpartydriverid : null);
						bf.bigForm.get('_3rdpartydrivercell')?.patchValue(claim?.loan_information._3rdpartydrivercell ? claim?.loan_information._3rdpartydrivercell : null);
						// Theft / damage
						bf.bigForm.get('policeclaimnumber')?.patchValue(claim?.loan_information.policeclaimnumber ? claim?.loan_information.policeclaimnumber : null);
						// Miscellaneous
						bf.bigForm.get('upfrontrepudiationreason')?.patchValue(claim?.loan_information.upfrontrepudiationreason ? claim?.loan_information.upfrontrepudiationreason : null);

						const desc = (claim_types as any[]).find(type => type?.id === claim?.claim_type).name;

						bf.bigForm.get('claim_class')?.patchValue(claim?.claim_type);
						bf.bigForm.get('claim_class_description')?.patchValue(desc);

						if (bf.bigForm.get('selectedClaimClass')?.value === null) {
							bf.bigForm.get('selectedClaimClass')?.patchValue([claim?.claim_type]);
						}
					});
			},
			inputs: {
				heading: 'Change Claim Class',
				canEditClaimExtraInfo: false,
				claimClassTypes: {
					subsidence: [14, 15, 19],
					impact: [6],
					fire: [7],
					police: [8, 9, 16],
					miscellaneous: [21],
					warningOnly: [20]
				},
				subsidenceExclusionsList: [
					'Any digging or excavation (other than mining activities) or removal or weakening of support.',
					'Any alteration, addition or repair to the Insured Property, even if done before this Policy started.',
					'The compaction of made up ground or fill',
					'Any defective design, materials or workmanship',
					'Any changes in the volume or moisture of Active Soils',
					'Any cause that existed for over 12 months or before this Policy started',
					'Any similar cause that You already claimed for, unless You did what is needed to prevent future damage from that cause and maintained it',
					'Any foundation system, foundation, plinth wall, floor or flooring system below the level of the main living area level (for example, in any basement, entrance or garage below the main living ground floor area)',
					'Any swimming pool, tennis court, patio, terrace, driveway, path, paving, surfacing system, concealed or exposed pipe (or other * fluid conduit), boundary, garden, retaining wall, fence, post or gate.',
					'Settlement, shrinkage or expansion of the Insured Property because of active soil; and/or',
					'Consequential loss or damage of any kind whatsoever upfront rejection reason'
				],
				fireContainedOptions: [
					{ display: 'Contained to Kitchen', value: 0 },
					{ display: 'Contained to Other Area', value: 1 },
					{ display: 'Not Contained', value: 2 }
				]
			},
			checkValidityForFields: ['claim_class'],
			navs: [
				{
					text: 'Change Claim Class',
					color: 'primary',
					linkType: 'submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						changeClaimClass: {
							errorMessage: 'could not change claim class',
							directCall: (http, store, storeObj, bf) => {
								return forkJoin([
									store.select(getFullItemOne).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									take(1),
									map(([claim, submissionData]) => {
										const data = {
											claim_id: claim?.id,
											new_claim_class: parseInt(bf.bigForm.get('selectedClaimClass')?.value[0], 10),
											application: { ...claim?.loan_information, ...submissionData.loan_information }
										};
										return data;
									}),
									switchMap(postData => {
										return http.post(`${environment.api_url}v1/claim_action/change_claim_type/`, postData).pipe(
											tap((res: any) => {
												if (res && res?.success) {
													store.dispatch(new GetFullItemOne({ id: postData.claim_id }));
												}
											})
										);
									})
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	}
};
