import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, map, pluck, skipWhile, filter, } from 'rxjs/operators';
import { combineLatest, from, forkJoin, empty, } from 'rxjs';
import moment from 'moment';
import { environment } from 'apps/studio/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ClaimService } from './child-services/claim.service';
import { JobService } from './child-services/job.service';
import {
	getSelectedItemOne,
	getSelectedItemTwo,
	getAllInfo,
	getFullItemOne,
	BigFormService,
	getSubmissionData,
	getFullItemTwo,
	getAppointmentTypes,
	IndexedDbService,
	getData,
	ModalService
} from '@flexus/core';
import { NewJobPayload } from '../models/new-job.model';
import { inlineRecursiveMerge, transformCallLog, findName } from '@flexus/utilities';
import { UUID } from 'angular2-uuid';
import { AmpProduct } from '@flexus/screens';

@Injectable()
export class AmpService {
	internalAssessorJobStates = [
		96, // internal_assessment
		97 // internal_assessor_job_report_uploaded
	];

	constructor(
		private store: Store<any>,
		private http: HttpClient,
		private claimService: ClaimService,
		private jobService: JobService,
		private bf: BigFormService,
		private indexedDbService: IndexedDbService,
		private modalsService: ModalService
	) {}

	getSelectedItemOne() {
		return this.store.select(getSelectedItemOne).pipe(
			skipWhile(x => !x),
			take(1)
		);
	}

	getSelectedItemTwo() {
		return this.store.select(getSelectedItemTwo).pipe(
			skipWhile(x => !x),
			take(1)
		);
	}

	getAllClaims() {
		return this.claimService.getAllClaims();
	}

	getClaimAppointments() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.claimService.getClaimAppointments({ claim_id: claim?.id });
			})
		);
	}

	getCurrentFullItemOne() {
		return this.getSelectedItemOne().pipe(
			take(1),
			switchMap(item => this.getFullItemOne(item?.id))
		);
	}

	getFullItemOne(id) {
		return this.claimService.getFullClaim(id);
		// return this.getSelectedItemOne().pipe(
		//   switchMap((claim) => {
		//   }),
		// );
	}

	getFullItemOneWithItemTwo(id) {
		return this.claimService.getFullClaimWithJobs(id);
	}

	getFullItemTwo(id) {
		return this.jobService.getFullJob(id);
		// return this.getSelectedItemTwo().pipe(
		//   switchMap((job) => {
		//   }),
		// );
	}

	dealOutOfApp() {
		return this.getSelectedItemTwo().pipe(switchMap(itemTwo => this.jobService.updateJob({ job_id: itemTwo?.id, new_state: 76 })));
	}

	cancelJob() {
		return this.getSelectedItemTwo().pipe(
			switchMap(job => {
				return this.jobService.cancelJob({ job_id: job?.id, reason: this.bf.bigForm?.value?.cancelclaim_reason });
			})
		);
	}

	newJob(jobData: NewJobPayload) {
		return this.jobService.newJob(jobData);

		// return forkJoin([
		//   this.store.select(getSubmissionData).pipe(take(1)),
		//   this.store.select(getFullItemOne).pipe(take(1)),
		//   this.store.select(getFullItemTwo).pipe(take(1)),
		//   this.store.select(getAllInfo).pipe(
		//     take(1),
		//     pluck('skills'),
		//   ),
		//   this.store.select(getAllInfo).pipe(
		//     take(1),
		//     pluck('appointment_types'),
		//   ),
		// ]).pipe(
		//   map(([submissionData, claim, job, skills, appointment_types]) => {
		//     const appointmentDetails = submissionData.new_job_data;
		//     const jobData = {};

		//     const { jobcardrequest, selectedskills } =claim?.loan_information;
		//     let skillObject = {};
		//     const { job_information } = job;

		//     if (job_information.skills_needed !== undefined) {
		//       jobData = {
		//         ...NewJobUtilities.getSkillObjectByProperty(skills, 'name', job_information.skills_needed.replace(/,/gi, '')),
		//         jobData
		//       };

		//       jobData['skillrequested'] = job.office_use.skillrequested;
		//       jobData['skillcatagory'] = job.office_use.skillcatagory;
		//     }

		//     let newJobCardRequest = [];
		//     if (Array.isArray(jobcardrequest)) {
		//       newJobCardRequest = [...jobcardrequest, jobData];
		//     } else {
		//       newJobCardRequest = [jobcardrequest, jobData];
		//     }

		//     let newSelectedSkills = [];
		//     if (Array.isArray(selectedskills)) {
		//       newSelectedSkills = [...selectedskills, { category: jobData['skillcatagory'], subcategory: jobData['skill'] }];
		//     } else {
		//       newSelectedSkills = [selectedskills, { category: jobData['skillcatagory'], subcategory: jobData['skill'] }];
		//     }

		//     const appointment_types_array = appointment_types as any[];

		//     const toReturn = {
		//       claim:claim?.id,
		//       area: job.area,
		//       application: { ...claim?.loan_information, jobcardrequest: newJobCardRequest, selectedskills: newSelectedSkills },
		//       jobcardrequest: [
		//         {
		//           ...jobData,
		//           appointment_type: appointmentDetails.appointmentTime
		//             ? appointment_types_array.find((type) => type.id === appointmentDetails.appointmentTime).name
		//             : null,
		//           appointment_type_id: appointmentDetails.appointmentTime ? appointmentDetails.appointmentTime : null,
		//           appointmentdatetype: appointmentDetails.appointmentDateType,
		//           providertype: job.office_use.providertype,
		//           providertype_id: job.office_use.providertype_id,
		//           requestedappointmentdate: appointmentDetails.appointmentDatePicker
		//             ? moment(appointmentDetails.appointmentDatePicker).format(moment.HTML5_FMT.DATE)
		//             : null,
		//           requestedappointmenttime: appointmentDetails.appointmentTimePicker
		//             ? `${appointmentDetails.appointmentTimePicker.hour}:${appointmentDetails.appointmentTimePicker.minutes}:00`
		//             : null,
		//         },
		//       ],
		//     };

		//     return toReturn;
		//   }),
		//   switchMap((data) => {
		//     return this.jobService.newJob(data);
		//   }),
		// );
	}

	assessorVisitSite() {
		return forkJoin([
			this.getSelectedItemTwo().pipe(
				skipWhile(x => !x),
				take(1),
				map(res => res as any)
			),
			this.store.select(getAllInfo).pipe(
				skipWhile(x => !x),
				take(1),
				pluck('appointment_types'),
				map(res => res as any[])
			)
		]).pipe(
			switchMap(([job, appointment_types]) => {
				let new_state;
				let return_to_state;

				switch (job.state) {
					case 106:
					case 32:
						new_state = job?.state;
						return_to_state = 90;
						break;
					case 90:
						new_state = 90;
						return_to_state = job?.job_information?.return_to_state;
						break;
					default:
						new_state = 90;
						return_to_state = job?.state;
				}

				const appointmentData: any = this.bf.bigForm.get('appointmentData')?.value;
				const date = appointmentData.appointmentDatePicker ? moment(appointmentData.appointmentDatePicker) : null;

				const range_start = moment(
					new Date(date.year(), date.month(), date.date(), appointmentData.appointmentTimePicker.hour, appointmentData.appointmentTimePicker.minutes)
				).format('YYYY-MM-DDTHH:mm:ss');

				const data = {
					appointment: {
						appointment_type: appointmentData.appointmentTime ? appointment_types.find(type => type.id === appointmentData.appointmentTime).id : null,
						range_start: range_start,
						reason: ''
					},
					current_state: job?.state,
					job_id: job?.id,
					job_information: {
						return_to_state: return_to_state
					},
					new_state: new_state
				};

				return this.jobService.updateJob(data);
			})
		);
	}

	updateUnmanagedMavenJob() {
		return forkJoin([
			this.store.select(getSubmissionData).pipe(
				skipWhile(x => !x || !x.job_information),
				take(1)
			),
			this.store.select(getFullItemTwo).pipe(take(1))
		]).pipe(
			map(([submissionData, job]) => {
				const job_information = { ...job.job_information, ...submissionData.job_information };
				if (!job_information.OutAppFlowSPid && !job_information.OutAppFlowSPMID) {
					job_information.OutAppFlowSPid = 0;
					job_information.OutAppFlowSPMID = 0;
				}
				return { job_id: job?.id, new_state: submissionData.new_state, job_information: job_information };
			}),
			switchMap(data => {
				return this.jobService.updateJob(data);
			})
		);
	}

	updateJob() {
		return forkJoin([this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemTwo).pipe(take(1))]).pipe(
			map(([submissionData, job]) => {
				const { job_information } = job;
				const { job_information: ji, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(job_information ?? {}));
				inlineRecursiveMerge(result, ji);

				const response = {
					job_information: result,
					...rest,
					job
				};
				return response;
			}),
			switchMap(response => {
				const { job, ...data } = response;
				return this.jobService.updateJob({ job_id: job?.id, ...data });
			})
		);
		// return this.store.select(getSubmissionData).pipe(
		//   take(1),
		//   switchMap((serverData) => {
		//     return this.getSelectedItemTwo().pipe(
		//       switchMap((job) => {
		//         return this.jobService.updateJob({ job_id: job?.id, ...serverData });
		//       }),
		//     );
		//   }),
		// );
	}

	updateClaim() {
		return forkJoin(this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemOne).pipe(take(1))).pipe(
			map(([submissionData, claim]) => {
				const { loan_information } = claim;
				const { loan_information: ci, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(loan_information));
				inlineRecursiveMerge(result, ci);

				const response = {
					loan_information: result,
					...rest,
					claim
				};
				return response;
			}),
			switchMap(response => {
				const { claim, ...data } = response;
				return this.claimService.updateClaim({ claim_id: claim?.id, ...data });
			})
		);
	}

	policyHistorySearch() {
		const lookupControl = this.bf.bigForm.get('policy_lookup');
		if (lookupControl) {
			return this.http.get(`${environment.api_urls['amp']?.url}v1/claim_action/get_claim_history/?search=${lookupControl.value}`);
		}
	}

	createClaim() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(claim => {
				return this.claimService.createClaim({ ...claim });
			})
		);
	}

	cancelClaim() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.claimService.cancelClaim({ claim_id: claim?.id, reason: this.bf.bigForm?.value?.cancel_reason });
				// return this.claimService.cancelClaim({claim_id:claim?.id, reason: this.bf.bigForm?.value?.cancel_reason})
			})
		);
	}

	reopenClaim(data: any) {
		return this.store
			.select(getFullItemOne)
			.pipe(
				skipWhile(x => !x),
				take(1),
				map(res => res as any)
			)
			.pipe(
				map(claim => {
					const claim_id = (claim as any).id;
					const reason = this.bf.bigForm.get('reopen_data');

					return {
						claim_id: claim_id,
						reopen_code: reason?.value?.reopen_reason
					};
				}),
				switchMap(response => {
					return this.claimService.reopenClaim(response);
				})
			);
	}

	saveAsServerDraft() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(claim => {
				return this.claimService.createClaim({ ...claim, new_state: 8 });
				// return throwError({ success: false, payload: '' });
			})
			// catchError((error) => {
			//   console.log({ error });
			//   return of(error);
			// }),
		);
	}

	checkNetworkAndUpdateDraft() {
		if (window.navigator.onLine) {
			// update server draft
			return this.saveAsServerDraft();
		} else {
			return this.saveAsLocalDraft();
		}
	}

	updateServerDraft(new_state) {
		return forkJoin(this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemOne).pipe(take(1))).pipe(
			map(([submissionData, claim]) => {
				const { loan_information } = claim;
				const { loan_information: ci, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(loan_information));
				inlineRecursiveMerge(result, ci);

				const response = {
					loan_information: result,
					...rest,
					claim
				};
				// console.log({ claim });
				return response;
			}),
			switchMap(response => {
				const { claim, ...data } = response;
				return this.claimService.updateClaim({ claim_id: claim?.id, ...data, new_state });
			})
		);
	}

	updateServerDraftAsSubmitted() {
		return forkJoin(this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemOne).pipe(take(1))).pipe(
			map(([submissionData, claim]) => {
				const { loan_information } = claim;
				const { loan_information: ci, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(loan_information));
				inlineRecursiveMerge(result, ci);

				const response = {
					loan_information: result,
					...rest,
					claim
				};
				// console.log({ claim });
				return response;
			}),
			switchMap(response => {
				const { claim, ...data } = response;
				return this.claimService.updateClaim({ claim_id: claim?.id, ...data, new_state: 1 });
			})
		);
	}

	saveAsLocalDraft() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(item => {
				const localDraft = { tempKey: UUID.UUID(), ...item, state: 169 };
				return this.indexedDbService.claimInDraft.put(localDraft);
			})
		);
	}

	checkNetworkAndSaveDraft() {
		if (window.navigator.onLine) {
			return this.saveAsServerDraft();
		} else {
			return this.saveAsLocalDraft();
		}
	}

	deleteCurrentEditableItem() {
		return from(this.indexedDbService.currentItem.delete('currentItem')).pipe(map(res => ({ success: true, payload: res })));
	}

	deleteLocalDraft() {
		return this.getSelectedItemOne().pipe(switchMap(itemOne => from(this.indexedDbService.claimInDraft.delete(itemOne.tempKey))));
	}

	policyLookup(data: { search: string }) {
		return this.http.get(`${environment.api_urls['amyth']?.url}${environment.api_urls['amyth']?.version}policy/policy/${data?.search}`).pipe(
			map((response: any) => [
				{
					full_name: `${response.policy_data.initials} ${response.policy_data.surname}`,
					inceptionDate: response.inception_date,
					...response
				}
			])
		);
	}

	getRegisteredAppliances() {
		if (this.bf.bigForm.get('policy_lookup')) {
			return this.http.get(
				`${environment.api_urls['amyth']?.url}${environment.api_urls['amyth']?.version}policy/policy_item?policy_number=${this.bf.bigForm.get('policy_lookup')?.value}`
			);
		}
	}

	getAmythAllInfo() {
		return this.http.get(`${environment.api_urls['amyth']?.url}${environment.api_urls['amyth']?.version}all_info/fetch/`);
	}

	addPlanItem(data: { product: AmpProduct }) {
		return this.http.post(`${environment.api_urls['amyth']?.url}${environment.api_urls['amyth']?.version}policy/policy_item`, [data?.product]);
	}

	getItemOneExtra() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.claimService.getClaimExtra(claim?.id);
			})
		);
	}

	getSkills() {
		return this.store.select(getAllInfo).pipe(
			skipWhile((allInfo: any) => {
				return allInfo.states === null || allInfo.states[1] === undefined;
			})
		);
	}

	getAppointmentTypes() {
		return this.store.select(getAppointmentTypes).pipe(
			skipWhile((appointmentTypes: any) => {
				return appointmentTypes === null || appointmentTypes === undefined;
			})
		);
	}

	getActiveReions() {
		return this.store.select(this.getActiveReions).pipe(
			skipWhile((activeReions: any) => {
				return activeReions === null || activeReions === undefined;
			})
		);
	}

	getServiceProvidersOnClaim() {
		return combineLatest(this.getSelectedItemOne(), this.store.select(getAllInfo).pipe(map((allInfo: any) => allInfo.sps))).pipe(
			map(([claim, serviceProviders]) => {
				const jobs = claim?.jobs.filter(job => job?.state !== 45 || job?.state !== 72);
				return jobs
					.filter(job => !!job.sp)
					.map(job => ({
						jobId: job?.id,
						skill: job?.skill,
						spName: findName(job.sp, serviceProviders).name
					}));
			})
		);
	}

	getClaimMid() {
		return this.store.select(getFullItemOne).pipe(
			skipWhile(x => !x),
			pluck('mid'),
			take(1),
			map(data => {
				return data;
			})
		);
	}

	getPaymentAuthorizationList() {
		// return this.store.select(getFullItemTwo).pipe(
		//   switchMap((item) => {
		//     return this.paymentService.getPaymentAuthorizationList().pipe(
		//       map((data: any) => data?.payload),
		//       map((persons: any) => {
		//         switch (item?.state) {
		//           // case 27: {
		//           //   return persons.filter((p) => p.max_auth >= activeFlowJobInfo.claim_value);
		//           // }
		//           case 50:
		//           case 51: {
		//             return persons.filter((p) => p.max_auth >= item?.claim_value);
		//           }
		//           default: {
		//             return persons;
		//           }
		//         }
		//       }),
		//     );
		//   }),
		// );
		// return combineLatest(
		//   this.store.select(getActiveFlowItemData).pipe(map((data) => data?.tieredInformation.job.job_information)),
		//   this.store.select(getFullItemTwo),
		// ).pipe(
		//   takeWhile((value) => !!value && !!value[0] && !!value[1] && !!value[2]),
		//   map((value) => {
		//     const persons = value[0]?.payload;
		//     const activeFlowJobInfo = value[1];
		//     const fullJob = value[2];
		//     switch (fullJob.state) {
		//       case 27: {
		//         return persons.filter((p) => p.max_auth >= activeFlowJobInfo.claim_value);
		//       }
		//       case 50:
		//       case 51: {
		//         return persons.filter((p) => p.max_auth >= fullJob.claim_value);
		//       }
		//       default: {
		//         return persons;
		//       }
		//     }
		//   }),
		// );
	}

	getJobNotes() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				if (itemTwo) {
					return this.getJobNotesApi(itemTwo.id);
				} else {
					return empty();
				}
			})
		);
	}

	getjobAssessmentNotes() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				return this.getjobAssessmentNotesApi(itemTwo.id).pipe(
					skipWhile(x => !x),
					pluck('payload'),
					map(iaNotes => iaNotes.map(iaNote => ({ timestamp: iaNote.created, message: iaNote.note })))
				);
			})
		);
	}

	// getPolicyHistory() {
	//   return this.policyHistoryLookup({ search: this.bf.bigForm?.value?.policy_number }).pipe(
	//     pluck('payload', 'claims'),
	//     map((claims) => {
	//       if (claims) {
	//         return (claims as any[]).map((claim) => [
	//           { title: 'Loss Date', value: this.formatDate(claim.LOSSDATE) },
	//           { title: 'Claim Type', value:claim?.CLAIMTYPE },
	//           [{ title: 'Status', value:claim?.STATUS }, { title: 'Claim Cost', value: `R${claim.CLAIMCOST}` }],
	//           { title: 'Comment', value:claim?.COMMENTS },
	//         ]);
	//       }
	//       return claims;
	//     }),
	//     map((claims: any[]) => {
	//       if (claims) {
	//         return { claims: claims.sort((a, b) => moment.utc(b.date.value)?.diff(a.date.value)) };
	//       }
	//       return { claims: claims };
	//     }),
	//     take(1),
	//     map((res: any) => res?.claims),
	//   );
	// }

	// getPolicyHistory() {
	//   return this.store.select(getSelectedItemOne).pipe(
	//     skipWhile(x => !x),
	//     take(1),
	//     switchMap(itemOne => {
	//       if (itemOne) {
	//         return this.policyHistoryLookup({ claim_id: itemOne?.id, search: this.bf.bigForm?.value?.policy_number }).pipe(
	//           pluck('payload', 'claims'),
	//           map(claims => {
	//             if (claims) {
	//               return (claims as any[]).map(claim => {
	//                 const obj = {
	//                   date: { title: 'Loss Date', value: this.formatDate(claim.LOSSDATE) },
	//                   eventType: { title: 'Claim Type', value:claim?.CLAIMTYPE },
	//                   additionalItems: [
	//                     { title: 'Status', value: claim.STATUS },
	//                     { title: 'Claim Cost', value: `R${claim.CLAIMCOST}` }
	//                   ],
	//                   description: { title: 'Comment', value: claim.COMMENTS }
	//                 };
	//                 return obj;
	//               });
	//             }
	//             return claims;
	//           }),
	//           map((claims: any[]) => {
	//             if (claims) {
	//               return { claims: claims.sort((a, b) => moment.utc(b.date.value)?.diff(a.date.value)) };
	//             }
	//             return { claims: claims };
	//           }),
	//           take(1)
	//         );
	//       } else {
	//         return empty();
	//       }
	//     }),
	//     map(res => res?.claims)
	//   );
	// }

	formatDate(date: string): Date {
		return moment(date, 'MM-D-YYYY h mm ss a').toDate();
	}

	policyHistoryLookup(data: { claim_id: string; search: string }) {
		return this.http.post(`${environment.api_urls['amp']?.url}v1/claim_action/get_claim_history/`, {
			search: data?.search || '',
			claim_id: data?.claim_id
		});
	}

	searchClaims(params: { search: string; active: boolean }) {
		return this.http.post<any>(environment.api_urls['amp']?.url + `v1/claim_action/search_claims/`, { ...params });
	}

	getSelectedApplianceFiles(data: { fileGuids: string[] }) {
		if (data?.fileGuids && data?.fileGuids.length > 0) {
			const getFileCalls = [];
			data?.fileGuids.forEach(guid => {
				getFileCalls.push(this.http.get<any>(`${environment.api_urls['amyth']?.url}${environment.api_urls['amyth']?.version}media_storage/file/${guid}?type=json`));
			});
			return forkJoin(getFileCalls);
		}
	}

	addAssessmentNoteApi() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo =>
				this.http.post<any>(environment.api_urls['amp']?.url + 'v1/job_action/add_assessment_note', {
					job_id: itemTwo?.id,
					note: this.bf.bigForm.get('ia_message')?.value
				})
			)
		);
	}

	getjobAssessmentNotesApi(job_id: number) {
		const data = { job_id, job_only: true };
		return this.http.post<any>(environment.api_urls['amp']?.url + 'v1/job_action/get_assessment_notes', data);
	}

	getAssessmentNotesForAllJobsApi(job_id: number) {
		const data = { job_id, job_only: false };
		return this.http.post<any>(environment.api_urls['amp']?.url + 'v1/job_action/get_assessment_notes', data);
	}

	getJobNotesApi(job_id: number) {
		const data = { job_id };
		return this.http.post<any>(environment.api_urls['amp']?.url + 'v1/job_action/get_notes', data);
	}

	getCallLogs() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				return this.http.post<any>(`${environment.api_urls['amp']?.url}v1/claim_action/get_call_logs/`, { claim_id: itemOne?.id }).pipe(
					filter(x => !!x),
					map((data: any) => {
						const arr = [];
						data?.payload.forEach(log => {
							const formattedLog = transformCallLog(log);
							arr.push(formattedLog);
						});

						return arr;
					})
				);
			})
		);
	}

	addCallLog() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				const data = {
					claim_id: itemOne?.id,
					message: this.bf.bigForm.get('message')?.value,
					channel: this.bf.bigForm.get('channel')?.value,
					reason: this.bf.bigForm.get('reason')?.value,
					direction: this.bf.bigForm.get('direction')?.value
				};
				return this.http.post(`${environment.api_urls['amp']?.url}v1/claim_action/add_call_log/`, { ...data }).pipe(
					switchMap((x: any) => {
						if (x.success) {
							const addedLog = transformCallLog(x.payload);

							this.bf.bigForm.patchValue({
								message: '',
								channel: null,
								reason: null,
								direction: null
							});

							return this.store.select(getData).pipe(
								map((contextData: any) => [...[addedLog], ...contextData.logs]),
								take(1)
							);
						}
					})
				);
			})
		);
	}

	getClaimNotes() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				return this.getClaimNotesApi(itemOne.id);
			})
		);
	}

	getClaimNotesApi(claim_id: number) {
		const data = { claim_id };
		return this.http.post<any>(environment.api_urls['amp']?.url + 'v1/claim_action/get_notes', data).pipe(
			filter(x => !!x),
			map((notes: any) => {
				const arr = [];
				notes.payload.forEach(note => {
					note.date = moment(note['timestamp']).format(moment.HTML5_FMT.DATE);
					note.time = moment(note['timestamp']).format(moment.HTML5_FMT.TIME);
					arr.push(note);
				});
				return arr;
			})
		);
	}

	addJobNote() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				const data = {
					job_id: itemTwo?.id,
					message: this.bf.bigForm.get('message')?.value
				};
				return this.http.post(`${environment.api_urls['amp']?.url}v1/job_action/add_note/`, { ...data }).pipe(map(response => response['payload']));
			})
		);
	}

	addClaimNote() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				const data = {
					claim_id: itemOne?.id,
					message: this.bf.bigForm.get('message')?.value
				};

				return this.http.post(`${environment.api_urls['amp']?.url}v1/claim_action/add_note/`, { ...data }).pipe(map(response => response['payload']));
			})
		);
	}

	updateJobNewState() {
		return forkJoin([
			this.getSelectedItemTwo().pipe(
				pluck('id'),
				skipWhile(x => !x),
				take(1)
			),
			this.store.select(getSubmissionData).pipe(
				pluck('new_state'),
				skipWhile(x => !x),
				take(1)
			)
		]).pipe(
			switchMap(([job_id, new_state]) => {
				return this.jobService.updateJob({ job_id: job_id, new_state: new_state });
			})
		);
	}

	// recommendCil() {
	//   return this.store
	//     .select(getSubmissionData)
	//     .pipe(take(1))
	//     .pipe(
	//       switchMap(submissionData => {
	//         const { cil_state, effected_jobs, job_id } = submissionData;
	//         const data = { cil_state, effected_jobs, job_id };
	//         return this.http.post<any>(environment.api_urls['amp'] + 'v1/job_action/move_to_cil/', data);
	//       })
	//     );
	// }
}
