<flx-heading class="" size="medium" weight="light" align="center">Bank Account Details</flx-heading>
<form novalidate [formGroup]="bankDetailsForm">
	<div class="bank-details-container">
		<flx-flex-container direction="column" justifyContent="center">
			<flx-label>{{ name }}</flx-label>
			<flx-input-text [maxLength]="30" type="text" size="normal" formControlName="account_holder_name"></flx-input-text>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['account_holder_name']"></flx-reactive-form-error-display>
			<flx-label>Bank</flx-label>
			<ng-container *ngIf="bankNames && bankNames.length !== 0">
				<flx-dropdown
					formControlName="bank_name"
					[displayOptions]="{ displayKey: 'description', valueKey: 'name' }"
					[itemsOption]="bankNames"
					placeHolder="Bank Name"
					[searchEnabled]="false"
				></flx-dropdown>
			</ng-container>
			<ng-container>
				<flx-label>Branch Code</flx-label>
				<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="branch_code" [isDisabled]="!!bankCodeLookupEvent"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['branch_code']"> </flx-reactive-form-error-display>
			</ng-container>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['bank_name']"> </flx-reactive-form-error-display>
			<flx-label>Account Number</flx-label>
			<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="account_number"></flx-input-text>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['account_number']"> </flx-reactive-form-error-display>
			<ng-container *ngIf="accountTypes$ | async as accountTypes">
				<ng-container *ngIf="accountTypes.length > 1 && accountTypes !== null">
					<flx-label>Account Type</flx-label>
					<flx-dropdown
						formControlName="account_type"
						[displayOptions]="{ displayKey: 'description', valueKey: 'description' }"
						[itemsOption]="accountTypes"
						placeHolder="Account Type"
						[searchEnabled]="false"
					></flx-dropdown>
				</ng-container>
			</ng-container>
		</flx-flex-container>
	</div>
</form>
