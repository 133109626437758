import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getFullItemOne, getSelectedItemOne, StoreQuery, getAllInfo } from '@flexus/core';
import { map, filter, skipWhile, take } from 'rxjs/operators';
import { Observable, zip, from, forkJoin } from 'rxjs';
import moment from 'moment';
import gql from 'graphql-tag';
import { findName } from '@flexus/utilities';

@Component({
	selector: 'flx-ap-job-summary',
	templateUrl: './job-summary.component.html',
	styleUrls: ['./job-summary.component.scss']
})
export class FLXAPJobSummaryComponent implements OnInit {
	dataJobs$: Observable<any>;
	constructor(private _store: Store<any>, private storeQuery: StoreQuery) {}
	allInfoPack = [];

	ngOnInit(): void {
		this.loadClaim();
		this.createAllInfoPack();
	}
	loadClaim() {
		this.dataJobs$ = zip(this._store.select(getFullItemOne), this._store.select(getSelectedItemOne))?.pipe(map(([fullClaim, claim]) => ({ fullClaim, claim })));
	}
	getTime(date: string) {
		const timeString = moment(date)?.format(moment.HTML5_FMT.TIME);
		return timeString !== 'Invalid date' ? timeString : '';
	}
	getDate(date: string) {
		const dateString = moment(date)?.format(moment.HTML5_FMT.DATE);
		return dateString !== 'Invalid date' ? dateString : '';
	}

	createAllInfoPack() {
		forkJoin([
			this._store.select(getAllInfo)?.pipe(
				skipWhile(x => !x),
				take(1)
			),
			this._store.select(getSelectedItemOne)?.pipe(
				skipWhile(x => !x),
				take(1)
			)
		])
			.pipe(take(1))
			.subscribe(([allInfo, claim]) => {
				claim?.jobs.forEach(element => {
					const cloned = JSON.parse(JSON.stringify(element));
					const newEl = {
						...cloned,
						skillsName: findName(element.skill, allInfo['skills'])?.name,
						appointmentName: element.appointment && element.appointment.appointment_type ? findName(element.appointment.appointment_type, allInfo['appointment_types'])?.name : '',
						spsName: element.sp ? findName(element.sp, allInfo['sps'])?.name : '',
						stateName: element.state ? findName(element.state, allInfo['states'])?.description : ''
					};

					this.allInfoPack.push(newEl);
				});
			});
	}

	// getFromAllInfo() {
	//   return this.storeQuery
	//     .queryStore(
	//       gql`
	//         {
	//           allInfo {
	//             skills
	//             sps
	//             appointment_types
	//             states
	//           }
	//         }
	//       `,
	//     )
	//     .pipe(
	//       filter((x) => !!x && Object.keys(x)?.length !== 0),
	//       map(({ skills, sps, appointment_types, states }) => ({ skills, sps, appointment_types, states })),
	//     );
	// }
}
