import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'isEmpty'
})
export class IsEmptyPipe implements PipeTransform {
	transform(value: unknown | unknown[]): boolean {
		if (value === null || value === undefined) {
			return true;
		}
		if (Array.isArray(value)) {
			return value.length === 0;
		}
		if (typeof value === 'object') {
			return Object.keys(value).length === 0;
		}
	}
}
