import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalModule } from '@azure/msal-angular';
import { IDENTITY_CONFIG, IdentityConfig, LocalAuthInterceptorService } from '@flexus/core';

@NgModule({
	imports: [
    CommonModule,
    MsalModule,
  ],
	declarations: [],
	providers: []
})
export class IdentityModule {
	static forRoot(config: IdentityConfig): ModuleWithProviders<IdentityModule> {
		return {
			ngModule: IdentityModule,
			providers: [
				{ provide: IDENTITY_CONFIG, useValue: config },
				// NoAuthGuard,
				// AuthGuard,
				// LocalAuthInterceptorService,
				// LocalAuthenticationService,
				// LocalAuthorizationService,
				{
					provide: HTTP_INTERCEPTORS,
					useClass: LocalAuthInterceptorService,
					multi: true
				}
			]
		};
	}
}
