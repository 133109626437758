<div [ngClass]="locationClass" cdkScrollable>
	<flx-heading *ngIf="heading" size="medium" align="center" weight="extra-light">{{ heading }}</flx-heading>

	<!-- <div #buttonGroup *ngIf="showFilters" class="btn-group" role="group" align="left"></div> -->
	<flx-file-list
		[imagesSelectable]="imagesSelectable"
		[documentsSelectable]="documentsSelectable"
		[paginationId]="paginationId"
		[files]="files$ | async"
		[filterValue]="filterValue"
		[defaultFile]="defaultFile"
		(fseOnSelectFiles)="getSelectedFiles($event)"
		[thumbSize]="thumbSize"
		[display]="display"
		[location]="location"
		[showMeta]="showMeta"
	>
	</flx-file-list>
</div>
