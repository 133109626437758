import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BigFormService } from '@flexus/core';

@Component({
	selector: 'flx-authoriser-select',
	templateUrl: './authoriser-select.component.html',
	styleUrls: ['./authoriser-select.component.scss']
})
export class FLXAuthoriserSelectComponent implements OnInit {
	@Input()
	public heading: string;

	@Input()
	public subheading: string;

	@Input()
	public authorisers$: Observable<any[]>;

	@Input()
	public canFilter: boolean;

	@Input()
	public reason: false;

	@Input()
	public maxWidth: string = '600px';

	constructor(public bf: BigFormService) {}

	ngOnInit() {
		// 20/000837
	}
}
