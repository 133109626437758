import { Flow_0_0_2, getData, getFullItemOne, getCurrentUser } from '@flexus/core';
import { skipWhile, map, take, filter, first, pluck, switchMap, tap } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { getSelectedItemOne, getFullItemTwo, getAllInfo } from '@flexus/core';
import { empty, forkJoin, of, EMPTY } from 'rxjs';
import gql from 'graphql-tag';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { MISCELLANEOUS_CLAIMS_PAYMENT_CHECKS } from './miscellaneous_claims_payment_checks';
import { PAYMENT_CHECKS } from './payment_checks';

export const BET_27: Flow_0_0_2 = {
	id: '27',
	name: 'job_invoiced',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					let headerStr = 'Job Invoiced';
					if (itemOne) {
						headerStr += ` : ${itemOne.mid} - ${itemOne.applicant?.surname}`;
					}
					return headerStr;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Approve SP invoice'
		},
		viewRoles: {
			0: 'Wait for SP invoice approval'
		}
	},

	events: {
		invalidateQueryDataIfVatRateIsSet: {
			triggerOn: 'vat_rate',
			triggerWhen: rate => !!rate,
			dataMutations: bf => {
				bf.patchValues({
					query: null,
					new_state: 50
				});
			}
		},

		invalidatePaymentDataIfQueryIsSet: {
			triggerOn: 'query',
			triggerWhen: qr => !!qr,
			dataMutations: bf => {
				bf.patchValues({
					claim_value: null,
					update_excess: null,
					invoice_number: null,
					vat_rate: null,
					nextApprovalStaffName: null,
					new_state: 46
				});
			}
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	fetchLevel1And2: true,
	serverCalls: {
		invoice: {
			serviceVariable: 'betService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		files: {
			serviceVariable: 'betService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		notes: {
			serviceVariable: 'betService',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No claim notes could be found!'
		}
	},

	startNode: 'MiscellaneousClass',

	nodes: {
		...MISCELLANEOUS_CLAIMS_PAYMENT_CHECKS?.nodes,
		...PAYMENT_CHECKS?.nodes,

		PaymentPreview: {
			inputs: { inputHeading: 'Payment Preview' },
			component: {
				children: [
					{
						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: { dataFiles$: 'invoice', keyValueList$: 'keyValueList', inputHeading: 'Payment Preview' }
					}
				]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store, sq) => {
						const invoice$ = sq
							.queryStore(
								gql`
									{
										selectedContext {
											invoice
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1)
							);
						return forkJoin([
							store.select(getSelectedItemOne).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							invoice$.pipe(
								skipWhile(x => !x),
								pluck('invoice'),
								take(1)
							)
						]).pipe(
							take(1),
							map(([claim, job, allInfo, invoice]) => {
								const list = {
									Claimant: claim?.applicant ? `${claim?.applicant?.first_name} ${claim?.applicant?.surname}` : '',
									'Claim ID': claim?.id ?? '',
									'Claim handler': claim?.application_creator?.full_name ?? '',
									Address: job?.address ?? 'No address found',
									'Excess Amount': job?.excess?.length !== 0 ? `R ${job.excess[0]?.amount}` : 'No excess found',
									'Collected by': job?.office_use?.excess?.who_collects_excess_desc ?? '',
									'Payment method': job?.office_use?.excess?.excess_payment_method_desc ?? '',
									'Excess state': ((): string => {
										let state = '';
										if (job.excess[0]) {
											allInfo['excess_states']?.forEach(element => {
												if (element.id === job?.excess[0]?.state) {
													state = element.name;
												}
											});
										}
										return state;
									})(),
									'Amount due': invoice?.balance_due ? `R ${invoice.balance_due}` : 0,
									'Invoice number': invoice?.invoice_number ?? '',
									'Job Status': ((): string => {
										let job_status = '';
										if (allInfo) {
											allInfo['valid_job']?.forEach(element => {
												if (element.id === job?.valid_job) {
													job_status = element.name;
												}
											});
										}
										return job_status;
									})(),
									'Pay type': ((): string => {
										let pay_type = '';
										if (allInfo) {
											allInfo['supplier_type']?.forEach(element => {
												if (element.id === job?.supplier_type) {
													pay_type = element.name;
												}
											});
										}
										return pay_type;
									})(),
									'IUA Claim Number': claim ? claim?.mid : ''
								};

								let cloned = JSON.parse(JSON.stringify(list));
								if (job?.supplier_type === 2) {
									const { 'Amount due': deleted, ...rest } = list;
									cloned = rest;
								}
								return [cloned];
							})
						);
					}
				}
			},
			initFormFields: (bf, item, instance, sq, store) => {
				forkJoin([
					store.select(getFullItemOne).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getCurrentUser).pipe(
						skipWhile(x => !x),
						take(1)
					)
				])
					.pipe(take(1))
					.subscribe(([claim, user]) => {
						bf.patchValues({
							paymentapprovalhandler: claim?.application_creator?.id ?? '',
							paymentapproval1: user?.user?.id
						});
					});
			},
			navs: [
				{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'alert' },
				{
					text: 'Approve Invoice',
					color: 'primary',
					nextNode: 'PaymentConfirm'
				}
			]
		},

		PaymentConfirm: {
			component: {
				children: [
					{
						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: {
							dataFiles$: 'invoice',
							inputHeading: 'Payment Confirmation',
							formInputs: {
								0: {
									formControlName: 'claim_value',
									inputType: 'currency',
									label: 'Invoice amount (Including excess)'
								},
								1: {
									formControlName: 'invoice_number',
									inputType: 'input',
									label: 'Confirm invoice number'
								},
								2: {
									formControlName: 'update_excess',
									inputType: 'currency',
									label: 'Enter excess amount'
								}
							},
							formLayout: 'three-column',
							containerWidth: '50vw'
						}
					}
				]
			},

			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ new_state: 50, vat_rate: 15, claim_value: '', update_excess: '', invoice_number: '' });
				bf.bigForm.get('claim_value')?.setValidators([Validators.required]);
				bf.bigForm.get('update_excess')?.setValidators([Validators.required]);
				bf.bigForm.get('invoice_number')?.setValidators([Validators.required]);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(values => {
						bf.patchValues({
							current_state: values.state
						});
					});
			},

			checkValidityForFields: ['claim_value', 'update_excess'],

			inputs: {
				inputHeading: 'Payment Confirmation'
			},

			navs: [{ text: 'Approve', nextNode: 'AuthorizerSelect', color: 'primary', optIntoValidation: true }]
		},

		QueryInvoice: {
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'query',
									inputType: 'textarea',
									label: 'Query Invoice',
									maxWidth: '50vw'
								}
							}
						}
					}
				]
			},

			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},

			inputs: {
				inputHeading: 'Query Invoice'
			},

			checkValidityForFields: ['query'],

			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ query: '', new_state: 46 });
				bf.bigForm.get('query')?.setValidators([Validators.required, Validators.minLength(3)]);
				// bf.addControl('query', new FormControl(''));
				// bf.addControl('new_state', new FormControl(46));
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(values => {
						bf.patchValues({ current_state: values.state });
						// bf.addControl('current_state', new FormControl(values.state));
					});
			},

			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		AuthorizerSelect: {
			component: 'FLXAuthoriserSelectComponent',
			serverCalls: {
				authorisers: {
					errorMessage: 'No authorizers could be found!',
					directCall: (http, store, sq, bf) => {
						const claimVal = bf.bigForm.get('claim_value')?.value;
						return store.select(getCurrentUser).pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(curUser => {
								const { user } = curUser;
								return http.get(environment.api_url + 'v1/staff_action/get_authorizers').pipe(
									take(1),
									map(response => response['payload']),
									map(arr => arr.filter(x => x.max_auth >= claimVal && x.id !== user.id)),
									map((obj: { [key: string]: { id: number; full_name: string } }) => {
										return Object.values(obj).map(entry => ({
											display: entry.full_name,
											value: entry.id
										}));
									})
								);
							})
						);
					}
				}
			},
			inputs: {
				heading: 'Select who will continue the payment process',
				subheading: 'Scroll and select a name or start typing to limit the list',
				canFilter: true,
				maxWidth: '300px'
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ nextApprovalStaffName: '' });
				bf.bigForm.get('nextApprovalStaffName')?.setValidators([Validators.required]);
			},
			checkValidityForFields: ['nextApprovalStaffName'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		nextApprovalStaffName: [
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.nextApprovalStaffName'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return null;
				},
				'job_information.authid'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return null;
				},
				'authid'
			]
		],
		paymentapprovalhandler: 'job_information.paymentapprovalhandler',
		paymentapproval1: 'job_information.paymentapproval1',
		current_state: 'current_state',
		new_state: 'new_state',
		vat_rate: 'vat_rate',
		claim_value: ['claim_value', 'job_information.claim_value'],
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		update_excess: ['update_excess', 'job_information.update_excess'],
		query: [
			(qr, storeObj) => {
				let iaq = [];
				if (
					storeObj['selectedContext']?.fullItemTwo &&
					storeObj['selectedContext']?.fullItemTwo.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery)) {
						iaq = storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery;
					} else {
						iaq = [
							{
								...storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
							}
						];
					}
				}
				const invoicequery = [...iaq, { query: qr, queryreply: '' }];
				return invoicequery;
			},
			'job_information.invoicequery'
		]
	}
};
