import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingComponent } from './billing.component';
// import { DcModule } from '@flexus/core';
import {
	FLXBillingInvoiceSpecificsComponent,
	BillingHeaderComponent,
	AccountDetailsComponent,
	InvoiceSummaryComponent,
	ClickOutsideDirective,
	LineItemsComponent,
	LineItemDisplayComponent,
	LineItemModifyComponent,
	InvoiceNumberDateEditComponent,
	LineItemAddEditComponent
} from './components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchFilterPipe } from './pipes/filter.pipe';
import {
	FLXAlertsModule,
	FLXButtonsModule,
	FLXDatepickerModule,
	FLXFormsModule,
	FLXHeadingsModule,
	FLXIconModule,
	FLXInputTextModule,
	FLXLabelsModule,
	FLXModalsModule,
	FLXReactiveFormsErrorDisplayModule,
	FLXSelectListModule,
	FLXTextareaModule,
	FLXTimePickerModule,
	FLXLoaderModule
} from '@flexus/ui-elements';
import { FLXSearchBarModule } from '@flexus/ui-composites';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { AllowOnlyAbsoluteNumbersDirective } from './directives/absolute-numbers.directive';
import { FormatCurrencyDirective } from './directives/format-currency.directive';
import { AbsoluteNumberPipe } from '@flexus/pipes';
import { AllowOnlyNaturalNumbersDirective } from './directives/natural-numbers.directive';
import { LineItemGeneratorComponent } from './components/line-item-generator/line-item-generator.component';

@NgModule({
	imports: [
		CommonModule,
		// DcModule,
		FormsModule,
		ReactiveFormsModule,
		FLXAlertsModule,
		FLXLabelsModule,
		FLXDatepickerModule,
		FLXTextareaModule,
		FLXHeadingsModule,
		FLXSelectListModule,
		FLXSearchBarModule,
		FLXInputTextModule,
		FLXButtonsModule,
		FLXModalsModule,
		FLXFormsModule,
		FLXTimePickerModule,
		PortalModule,
		OverlayModule,
		FLXReactiveFormsErrorDisplayModule,
		// StoreModule.forFeature('billing', billingReducer),
		// EffectsModule.forFeature([BillingEffects]),
		FLXIconModule,
		FLXLoaderModule
	],
	declarations: [
		BillingComponent,
		BillingHeaderComponent,
		FLXBillingInvoiceSpecificsComponent,
		AccountDetailsComponent,
		InvoiceSummaryComponent,
		ClickOutsideDirective,
		AllowOnlyAbsoluteNumbersDirective,
		AllowOnlyNaturalNumbersDirective,
		FormatCurrencyDirective,
		LineItemsComponent,
		LineItemDisplayComponent,
		SearchFilterPipe,
		InvoiceNumberDateEditComponent,
		LineItemGeneratorComponent,
		LineItemModifyComponent,
		LineItemAddEditComponent
	],
	providers: [AbsoluteNumberPipe]
})
export class BillingModule {
  static forRoot({ environment}: Config): ModuleWithProviders<BillingModule> {
    return {
      ngModule: BillingModule,
      providers: [
        {provide: 'ENVIRONMENT', useValue: environment},
        {provide: 'environment', useValue: environment}
      ]
    }
  }
}


interface Config {
  environment: any;
}
