import { Flow_0_0_2, getCurrentUser, getFullItemTwo, getSelectedItemTwo } from '@flexus/core';
import { map, pluck, skipWhile, take, filter, mergeMap, switchMap } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { forkJoin, of } from 'rxjs';
import { JobLocation } from '@flexus/ui-elements';

export const SP_CONTEXT_MENU_CHANGE_TEAM: Flow_0_0_2 = {
	id: 'ChangeTeam',
	itemType: 'flow',
	includeForOnlyStates: ['all'],
	excludeForOnlyStates: [21, 25, 26, 27, 28, 30, 39, 42, 45, 48, 49, 50, 51, 62, 69, 70, 86, 161, 162, 170, 206],
	name: 'Change Team',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant) {
							return `Change Team : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Change Team :';
						}
					}
				})
			);
		},
		controls: () => () => []
	},

	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	startNode: 'ChangeTeam',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {
		tlListData: {
			errorMessage: '',
			directCall: (http, store, sq, bf) => {
				return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
					pluck('payload'),
					filter(x => !!x),
					map((teamleaders: any[]) =>
						teamleaders.map(teamLeader => {
							return {
								display: teamLeader.full_name,
								value: teamLeader.id,
								teamLeader,
								shouldHaveImage: true
							};
						})
					)
				);
			}
		},
		jobLocationData: {
			errorMessage: 'The job location was not returned!',
			directCall: (http, store, sq, bf) => {
				return store.select(getSelectedItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					mergeMap(j => {
						const job = j as any;
						return forkJoin([
							http.post(`${environment.api_url}v1/job_action/get_job/`, { job_id: job?.id }).pipe(
								skipWhile(x => !x),
								take(1),
								map(result => result as any)
							)
						]).pipe(
							map(([jobloc]) => {
								const locationString = jobloc['payload']?.claim?.location;
								const locArray = locationString?.split(',');
								const latitude = +locArray[0];
								const longitude = +locArray[1];
								return new JobLocation(latitude, longitude);
							})
						);
					})
				);
			}
		},
		teamleaderLocations: {
			errorMessage: 'Team leader locations not returned!',
			directCall: (http, store, sq, bf) => {
				return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
					pluck('payload'),
					filter(x => !!x),
					map((teamleaders: any[]) => {
						return teamleaders;
					})
				);
			}
		}
	},
	nodes: {
		ChangeTeam: {
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: bf => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl(null, [Validators.required]));
			},
			component: 'AssignTLComponent',
			inputs: [
				{
					joblocation$: 'jobLocationData',
					teamleadersPositions$: 'teamleaderLocations'
				}
			],
			navs: [
				{
					text: 'Submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't update job!",
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getCurrentUser).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									of(bf.bigForm.get('assign_teamleader_id')?.value).pipe(
										skipWhile(x => !x),
										take(1),
										map(rest => rest)
									)
								]).pipe(
									switchMap(([j, uid, lead]) => {
										const job = j as any;
										const user = uid as any;
										const job_id = job?.id;
										const staffmember = user.id;
										const team = lead[0];

										return http.post(`${environment.api_url}v1/job_action/assign_team/`, {
											job_id: job_id,
											staffmember: staffmember,
											team_id: team,
											new_state: j.state
										});
									})
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		assign_teamleader_id: [
			tlid => {
				if (tlid) {
					let id;
					tlid = tlid && Array.isArray(tlid) ? tlid : [];
					for (const lead of tlid) {
						id = parseInt(lead, 10);
					}
					return id;
				}
				return 0;
			},
			'job_information.team_leader'
		]
	}
};
