import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { FileOverlayControl } from './file-overlay-control';
import { InjectedFile, FLXFileOverlayComponent } from './file-overlay.component';
import { Injectable, Injector } from '@angular/core';
import { FILE_OVERLAY_DATA } from './file-overlay.token';

interface FilePreviewDialogConfig {
	panelClass?: string;
	hasBackdrop?: boolean;
	backdropClass?: string;
}

const DEFAULT_CONFIG: FilePreviewDialogConfig = {
	hasBackdrop: true,
	backdropClass: 'dark-backdrop',
	panelClass: 'tm-file-preview-dialog-panel'
};

@Injectable()
export class FilePreviewOverlayService {
	constructor(private overlay: Overlay, private injector: Injector) {}

	previewFile(file: InjectedFile): FileOverlayControl {
		// could add config in in the future as well as split out the methods better
		const dialogueConfig = { ...DEFAULT_CONFIG };

		const overlayConfig = new OverlayConfig({
			hasBackdrop: dialogueConfig.hasBackdrop,
			backdropClass: dialogueConfig.backdropClass,
			panelClass: dialogueConfig.panelClass,
			scrollStrategy: this.overlay.scrollStrategies.block(),
			positionStrategy: this.overlay.position().global()?.centerHorizontally()?.centerVertically()
		});

		const overlayRef = this.overlay.create(overlayConfig);

		// create control
		const fileOverlayControl = new FileOverlayControl(overlayRef);

		const injectionTokens = new WeakMap();
		// pass the wrapper a reference to its control, letting it kill itself
		injectionTokens.set(FileOverlayControl, fileOverlayControl);
		injectionTokens.set(FILE_OVERLAY_DATA, file);

		const portalInjector = new PortalInjector(this.injector, injectionTokens);

		const portalRef = new ComponentPortal(FLXFileOverlayComponent, null, portalInjector);

		overlayRef.attach(portalRef);
		return fileOverlayControl;
	}
}
