import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BigFormService } from '@flexus/core';

@Component({
	selector: 'flx-manage-users-edit',
	templateUrl: 'manage-sp-users-edit.component.html',
	styleUrls: ['manage-sp-users-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXManageSPUsersEditComponent implements OnInit {
	constructor(private _bf: BigFormService) {}

	isEdit: boolean;
	selected_user;
	ngOnInit() {
		this.selected_user = this._bf.getControl('userToEdit').value;
		console.log('user to edit', this._bf.getControl('userToEdit').value);
		this.isEdit = true;
	}
}
