import { Flow_0_0_2, getFullItemTwo, getAllInfo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';

import { forkJoin } from 'rxjs';
import { skipWhile, take, map, filter, pluck, switchMap } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { KVLHeading } from '@flexus/ui-elements';

export const SP_GLOBAL_42: Flow_0_0_2 = {
	id: '42',
	name: 'rework-job',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Rework Job : ${itemTwo?.claim?.loan_information?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Rework Job';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		jobLocationData: {
			errorMessage: 'The job location was not returned!',
			serviceVariable: 'spService',
			functionName: 'getJobLocation',
			responseSlice: 'payload'
		},
		teamleaderLocations: {
			errorMessage: 'Team leader locations not returned!',
			directCall: (http, store, sq, bf) => {
				return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
					pluck('payload'),
					filter(x => !!x),
					map((teamleaders: any[]) => {
						return teamleaders;
					})
				);
			}
		}
	},
	instructions: {
		editRoles: {
			0: 'Start job rework'
		},
		viewRoles: {
			0: 'Wait for SP to start job rework'
		}
	},
	startNode: 'JobReworkNotification',
	nodes: {
		JobReworkNotification: {
			showTabs: true,
			name: 'Overview',
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.addControl('new_state', new UntypedFormControl());
			},
			serverCalls: {
				importantInfoKV: {
					errorMessage: "Couldn't find team leader info",
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								filter(x => !!x),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([job, teamleaders]) => {
								const leads = teamleaders as any;
								const jobber = job as any;
								let teamleadname;
								let rework_reason;

								if (!jobber.team_leader) {
									teamleadname = 'No assigned team leader';
								} else if (jobber.team_leader) {
									for (let i = 0; i < leads.length; i++) {
										if (leads[i]?.id === job?.team_leader.id) {
											teamleadname = leads[i]?.full_name;
										}
									}
								}
								if (!jobber.appointment[0]?.reason) {
									rework_reason = 'NO REWORK REASON';
								} else if (jobber.appointment[0]?.reason === '') {
									rework_reason = 'Rework reason was not supplied';
								} else {
									rework_reason = jobber.appointment[0]?.reason;
								}
								const installer = {
									'Current Team Leader': teamleadname,
									'Reason for requesting rework': rework_reason
								};
								return [installer];
							})
						);
					}
				},
				claimDetailsKV: {
					errorMessage: 'Something went wrong with claim details',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const appointmentinfo = {
										'Claim Type ': itemTwo?.claim?.type ?? '',
										'Skill required ': itemTwo?.office_use?.skillcatagory ?? '',
										'Customer ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : '',
										'Address ': itemTwo?.address ?? '',
										'Appointment Date ': itemTwo?.office_use?.requestedappointmentdate ?? 'No appointment date set',
										'Appointment Time ': itemTwo?.office_use
											? `${itemTwo.office_use.appointment_type} ${itemTwo.office_use.requestedappointmenttime}`
											: 'Appointment time not retrieved'
									};
									return [appointmentinfo];
								})
							);
					}
				},
				onsiteDetailKV: {
					errorMessage: 'Something went wrong with onsite info',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const list2 = {
										'On site contact person ': itemTwo?.claim?.loan_information?.onsiteperson ?? 'Not retrieved',
										'Contact number ': itemTwo?.claim?.loan_information?.onsitecontact ?? 'Not retrieved'
									};
									return [list2];
								})
							);
					}
				},
				customerDetailKV: {
					errorMessage: 'Something went wrong with customer info',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									let firstname;
									let surname;
									let customername;
									let customercell;
									let clientalternativeno;
									if (!itemTwo?.claim?.applicant) {
										customername = 'This claim is missing applicant data';
									} else {
										if (!itemTwo?.claim?.applicant?.first_name) {
											firstname = 'no first name';
										} else {
											firstname = itemTwo?.claim?.applicant?.first_name;
										}
										if (!itemTwo?.claim?.applicant?.surname) {
											surname = 'no surname';
										} else {
											surname = itemTwo?.claim?.applicant?.surname;
										}
										customername = `${firstname} ${surname}`;
									}
									if (!itemTwo?.claim?.loan_information) {
										customercell = 'No loan information on this claim';
										clientalternativeno = 'No loan information on this claim';
									} else {
										customercell = itemTwo?.claim?.loan_information?.cellnumber;
										clientalternativeno = itemTwo?.claim?.loan_information?.contactnumber;
									}

									const list3 = {
										Customer: customername,
										'Customer Cell': customercell,
										'Client Alternative No.': clientalternativeno
									};
									return [list3];
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Rework Required',
							instructions: ['The customer has requested that the team return to fix an item they are not happy with.', 'Please allocate a team leader to return for the rework.']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							data$: 'importantInfoKV',
							color: 'secondary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailsKV',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Onsite Details',
							data$: 'onsiteDetailKV',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',
							data$: 'customerDetailKV',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},

			navs: [{ text: 'Continue', linkType: 'portal', color: 'primary', nextNode: 'AssignTeamLeader' }]
		},
		AssignTeamLeader: {
			showTabs: true,
			name: 'Assign Team Leader',
			initFormFields: (bf, item, instance, sq, store) => {
				let installer;
				let name;
				store
					.select(getAllInfo)
					.pipe(
						skipWhile(x => !x),
						take(1)
					)
					.subscribe(n => {
						installer = n.sps[1];
						for (let i = 0; i < installer.installer.length; i++) {
							if (installer.installer[i]?.id === 41) {
								name = installer.installer[i]?.full_name;
							}
						}
					});
				bf.addControl('assign_teamleader_id', new UntypedFormControl(null, [Validators.required]));
				store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(fullItemTwo => {
						bf.bigForm.get('assign_teamleader_id')?.patchValue([fullItemTwo.team_leader.id]);
					});
				bf.patchValues({
					assign_teamleader_id: bf.getControl('assign_teamleader_id')?.value || 'Hard Lawrence'
				});
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter(x => !!x),
							map((teamleaders: any[]) =>
								teamleaders.map(teamLeader => {
									return {
										display: teamLeader.full_name,
										value: teamLeader.id,
										teamLeader,
										shouldHaveImage: true
									};
								})
							)
						);
					}
				}
			},
			component: 'AssignTLComponent',
			checkValidityForFields: ['assign_teamleader_id'],
			inputs: {
				joblocation$: 'jobLocationData',
				teamleadersPositions$: 'teamleaderLocations'
			},
			navs: [
				{
					text: 'Allocate Job',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't update job!",
							directCall: (http, store, sq, bf) => {
								return store.select(getFullItemTwo).pipe(
									skipWhile(x => !x),
									take(1),
									map(res => res as any),
									switchMap((result: any) => {
										const job_id = result.id;
										const job_information = result.job_information;
										const new_state = 22;
										const data = {
											job_id: job_id,
											new_state: new_state,
											job_information: {
												...job_information,
												team_leader: bf.bigForm.get('assign_teamleader_id')?.value
											}
										};
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					},
					location: 'right'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		// assign_teamleader_id: `bf.bigForm.get('assign_teamleader_id')?.value`,
		assign_teamleader_id: [
			[
				tlid => {
					if (tlid) {
						let id;
						tlid = tlid && Array.isArray(tlid) ? tlid : [];
						for (const lead of tlid) {
							id = parseInt(lead, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.team_leader'
			]
		],
		new_state: 'new_state'
	}
};
