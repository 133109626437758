import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BigFormService } from '@flexus/core';
import { take } from 'rxjs/operators';

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'flx-team-lead-lookup',
	templateUrl: './team-lead-lookup.component.html',
	styleUrls: ['./team-lead-lookup.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: []
})
export class TeamLeadLookupComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() sps$: Observable<any>;
	@Input() currentTeamLeader$;

	sps: any[];
	hasDoneLookup = false;
	teamLeads: any[] = [];
	errorMessage = 'No Team Leaders found';

	constructor(public _store: Store<any>, public bf: BigFormService) {}

	lookup() {
		const searchTerm = this.bf.bigForm.value?.team_lead_lookup?.toLowerCase();

		const result = this.sps.find(x => {
			const u = x.name.toLowerCase();
			return u.includes(searchTerm);
		});

		this.teamLeads = result?.installer ?? [];

		this.hasDoneLookup = true;
	}

	ngOnInit() {
		this.sps$?.pipe(take(1)).subscribe(sps => {
			this.sps = sps;
		});
	}

	selectTeamLead(chosenTeamLeadId: number, name) {
		this.bf.patchValues({ assign_teamleader_id: chosenTeamLeadId, team_lead_lookup: name });
	}

	ngOnDestroy() {}
}
