import * as fromTheme from '../actions/theme.actions';

export interface ThemeState {
	activeTheme: string;
}

export const initialState: ThemeState = {
	activeTheme: 'blackout'
};

export function reducer(state = initialState, action: fromTheme.ThemeActions): ThemeState {
	switch (action.type) {
		case fromTheme.ThemeActionTypes.SET_THEME: {
			const { name } = action.payload;
			return {
				...state,
				activeTheme: name
			};
		}
		default: {
			return state;
		}
	}
}
