import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

interface IHeadingConfig {
	size?: string;
	type?: string;
	color?: string;
	weight?: string;
	itemMargin?: string;
}

class HeadingConfig implements IHeadingConfig {
	public size = 'medium';
	public type = 'page';
	public color = 'default';
	public weight = 'extra-light';
	public itemMargin = '1rem 0 1rem 0';
	constructor(data?: IHeadingConfig) {
		if (data) {
			if (data.size) this.size = data.size;
			if (data.type) this.type = data.type;
			if (data.color) this.color = data.color;
			if (data.weight) this.weight = data.weight;
			if (data.itemMargin) this.itemMargin = data.itemMargin;
		}
	}
}

@Component({
	selector: 'flx-heading-with-instructions',
	templateUrl: './heading-with-instructions.component.html',
	styleUrls: ['./heading-with-instructions.component.scss']
})
export class FLXHeadingWithInstructionsComponent implements OnInit, OnDestroy {
	private _headingConfig: HeadingConfig = new HeadingConfig();
	private _itemMargin = '';

	@Input() title: string;
	@Input() title$: Observable<any>;
	@Input() instructions: string[];

	@Input()
	get headingConfig(): HeadingConfig {
		return this._headingConfig;
	}
	set headingConfig(value: HeadingConfig) {
		this._headingConfig = new HeadingConfig(value);
	}

	@Input()
	set itemMargin(val: string) {
		this._itemMargin = val;
	}

	get itemMargin() {
		return this._itemMargin;
	}
	private _subscription: Subscription;
	private _title: string;

	constructor() {}

	ngOnInit() {
		if (this.title$) {
			this._title = this.title;
			this._subscription = this.title$.pipe(skipWhile(x => !x)).subscribe(title => {
				this.title = this._title;
				this.title += this._title && this._title !== '' ? ' ' : '';
				this.title += title;
			});
		}
	}

	ngOnDestroy() {
		if (this._subscription) {
			this._subscription.unsubscribe();
		}
	}
}
