import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, ChangeDetectionStrategy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { BigFormService } from '@flexus/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'flx-teamlead-lookup-composite',
	templateUrl: './teamlead-lookup-composite.component.html',
	styleUrls: ['./teamlead-lookup-composite.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: []
})
export class TeamleadLookupCompositeComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() sps$;
	@Input() title: string;
	@Input() propagatedFailReasons$: Observable<any>;
	lookupForm: UntypedFormGroup;
	reason: string = '';
	@Output() continueEvent = new EventEmitter<string>();
	public formattedSps$;
	public formattedTeamleads$;
	spsSubsription: Subscription;
	formattedTeamleadSub: Subscription;
	@Input() currentTeamLeader$;

	sps: any[];
	formattedSPArray: any[] = [];
	hasDoneLookup = false;
	teamLeads: any[] = [];
	errorMessage = 'No Team Leaders found';
	formatSubscription: Subscription;

	constructor(private _store: Store<any>, public bf: BigFormService) {}

	lookup() {
		const company_id_value = this.bf.getControl('select_company_list').value;
		const company_id = company_id_value[0];
		let selectedCompany = this.sps.find(x => {
			return x.id === company_id;
		});
		this.teamLeads = selectedCompany?.installer ?? [];
		this.hasDoneLookup = true;
		this.formattedTeamleads$ = this.buildTeamleadSelectListDisplayConfig(this.teamLeads);
		this.formattedTeamleadSub = this.formattedTeamleads$.subscribe(map => console.log('MAP', map));
	}

	ngOnInit() {
		this.spsSubsription = this.sps$.subscribe(result => {
			this.sps = result;
			let selectOption;
			const spArray = [];
			this.sps.forEach(company => {
				selectOption = { display: company?.name, value: company?.id };
				spArray.push(selectOption);
			});
			this.formattedSps$ = of(spArray);
		});
		this.title = 'Correct the Installer Techcnician Detail';
	}
	ngAfterViewInit() {}

	selectTeamLead(chosenTeamLeadId: number, name) {
		this.bf.patchValues({ assign_teamleader_id: chosenTeamLeadId, team_lead_lookup: name });
	}

	buildTeamleadSelectListDisplayConfig(rawOptions: any[]) {
		let selectOption: {};
		rawOptions.forEach(option => {
			if (option?.full_name === null || option?.full_name === '') {
				selectOption = { display: 'No name captured...', value: option.id };
			} else {
				selectOption = {
					display: option.full_name,
					value: option.id
				};
			}

			this.formattedSPArray.push(selectOption);
		});
		return of(this.formattedSPArray);
	}
	setTeamleadFailReason() {
		if (this.propagatedFailReasons$) {
			if (Array.isArray(this.propagatedFailReasons$)) {
				if (this.propagatedFailReasons$.find(el => el === 'TL has empty qualification number')) {
					this.reason = this.propagatedFailReasons$.find(el => el === 'TL has empty qualification number');
					this.continueEvent.emit(this.reason);
				} else if (this.propagatedFailReasons$.find(el => el === 'TL has empty name')) {
					this.reason = this.propagatedFailReasons$.find(el => el === 'TL has empty name');
					this.continueEvent.emit(this.reason);
				}
			}
		}
	}

	ngOnDestroy() {
		if (this.spsSubsription) {
			this.spsSubsription.unsubscribe();
		}
	}
}

// rawTeamLead: {display:string, value:string}
