import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, getFullItemTwo, getSelectedItemTwo, ManifestController, SetNextNode } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

enum TriggerFailReasons {
	NoVoucherLinkedFail = 'Job has no voucher linked',
	NonVoucheredDecoderLinkedFail = 'Job has non-vouchered decoder linked',
	MultipleValidDecodersFail = 'Job has multiple valid decoders'
}
@Component({
	selector: 'flx-decoder-issue-fixer',
	templateUrl: 'decoder-issue-fixer.component.html',
	styleUrls: ['decoder-issue-fixer.component.scss']
})
export class FLXDecoderIssueFixerComponent implements OnInit, OnDestroy {
	private _selected_job$;
	private _selected_job_id;
	@Input() verificationImageSource$;
	@Input() existingDecoders$;
	@Input() importantTLInfo$;
	@Input() installationInfo$;
	// @Input() serviceProviders$;
	@Input() installedDecoders$: Observable<any[]>;
	@Input() decoderKeyValues$;
	trigger_fail_reasons$;
	public fail_reasons_array: any[] = [];
	private issuesToFix = 0;
	isNoVoucherFailure = false;
	isNonVoucheredDecoderFailure = false;
	isMultipleValidDecoderFailure = false;
	instructions;
	keyValueListData$;
	currentDecoderKeyValue$;
	decoderSerialNoImage$;
	formattedDecoderValues$;

	decoderNumber;
	public thisdecoders: any;

	public jobidSub: Subscription;
	public decoderSub: Subscription;
	public selectFormatSub: Subscription;
	selectedDecoder;
	teamleadfailreason: any;

	constructor(private _store: Store<any>, @Inject('environment') private environment: any,  public bf: BigFormService, public http: HttpClient, public controller: ManifestController<any>, private fb: UntypedFormBuilder) {
		this._selected_job$ = this._store.select(getSelectedItemTwo).pipe(
			skipWhile(x => !x),
			take(1),
			map(result => {
				return result?.id;
			})
		);
	}

	ngOnInit() {
		this.jobidSub = this._selected_job$.subscribe(data => (this._selected_job_id = data));
		this.selectFormatSub = this.installedDecoders$.subscribe(data => {
			this.thisdecoders = data;
			let selectOption;
			const decoderArray = [];
			this.thisdecoders?.forEach(decoder => {
				selectOption = { display: decoder?.id, value: decoder?.id };
				decoderArray.push(selectOption);
			});
			this.formattedDecoderValues$ = of(decoderArray);
		});
		this.getTriggerFailReasons();
		this.setTriggerFailValueForConditionalDisplay(this.fail_reasons_array);

		this.instructions = ['Please select the primary installed decoder'];
	}

	getTriggerFailReasons() {
		this.trigger_fail_reasons$ = this._store
			.select(getFullItemTwo)
			.pipe(
				skipWhile(x => !x),
				take(1),
				map(result => result as any)
			)
			.pipe(
				map(job => {
					const failreasonsstring = job?.job_information.state_trigger_problems;
					const failreasonsArray = job?.job_information.state_trigger_problems.split(', ');
					this.fail_reasons_array = [...failreasonsArray];
					this.issuesToFix = this.fail_reasons_array.length;
					console.log('FAILURES STRING', failreasonsstring);
					return job;
				})
			)
			.subscribe();
	}
	setTriggerFailValueForConditionalDisplay(reasons: any[]) {
		for (let i = 0; i <= reasons?.length - 1; i++) {
			const failreason: string = reasons[i];
			switch (failreason) {
				case TriggerFailReasons.NoVoucherLinkedFail:
					this.isNoVoucherFailure = true;
					break;
				case TriggerFailReasons.NonVoucheredDecoderLinkedFail:
					this.isNonVoucheredDecoderFailure = true;
					break;
				case TriggerFailReasons.MultipleValidDecodersFail:
					this.isMultipleValidDecoderFailure = true;
					break;
				default:
				// this.issuesToFix = 0;
			}
		}
	}
	createDecodersForForm(decoders?): UntypedFormGroup {
		return decoders.forEach((item, index) => {
			return this.fb.group({
				vouchernumber: [item.number, Validators.required],
				voucherthis: [false, [Validators.required]]
			});
		});
	}
	getDecoderListDynamic() {
		if (this.bf.bigForm.get('decoders') === null) {
			this.bf.bigForm.setControl('decoders', this.fb.array([this.createDecodersForForm(this.thisdecoders)]));
		}
		console.log('FORM ARRAY', this.bf.bigForm.get('decoders').value);
		return this.bf.bigForm.get('decoders') as UntypedFormArray;
	}

	onVoucherIdentificationChange(res: boolean, selectedDecoder: any) {
		if (res) {
			const vouchernumber = this.bf.bigForm.get('vouchernumber');
			const voucherthis = this.bf.bigForm.get('voucherthis').value;
			this.selectedDecoder = selectedDecoder.id;
			vouchernumber.setValue(selectedDecoder.id);
			console.log('VOUCHER Number', vouchernumber.value);
			console.log('VOUCHER THIS', voucherthis);

			this.bf.bigForm.get('decoders');
			// for (let decoder of this.installeddecoders) {
			// 	debugger;
			// 	if (decoder.id !== selectedDecoder.id) {
			// 		decoder.selected = false;
			// 	} else {
			// 		decoder.selected = true;
			// 	}
			// }
		}
	}

	selectDecoderNotListed() {
		this.isNonVoucheredDecoderFailure = false;
		this.isNoVoucherFailure = true;
		// console.log('EVENT', $event.target.childNodes[1].wholeText);
	}
	setSummaryNode() {
		if (this.isNoVoucherFailure) {
			this.decoderNumber = this.bf.bigForm.get('no_valid_decoder_fix').value;
		} else if (this.isNonVoucheredDecoderFailure) {
			this.decoderNumber = this.bf.bigForm.get('nonvouchered_decoder_fix').value;
		} else if (this.isMultipleValidDecoderFailure) {
			const tempNo = this.bf.bigForm.get('select_decoder_number').value;
			if (Array.isArray(tempNo)) {
				this.decoderNumber = tempNo[0];
			} else {
				this.decoderNumber = this.bf.bigForm.get('select_decoder_number').value;
			}
		} else {
			this.decoderNumber = 0;
		}

		this.controller.dispatch(new SetNextNode('FixedDecoderReasonsSummary'));
		return this.http
			.post(`${this.environment.api_url}v1/job_action/link_installed_decoder_to_job/`, { job_id: this._selected_job_id, decoder_number: this.decoderNumber })
			.subscribe(flexi => {
				console.log('flexisub', flexi);
			});
	}
	ngOnDestroy() {
		if (this.decoderSub) {
			this.decoderSub.unsubscribe();
		}
	}
}
