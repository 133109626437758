import { Flow_0_0_2, getFullItemOne, getAllInfo, getSubmissionData } from '@flexus/core';
import { map, skipWhile, switchMap, take, pluck } from 'rxjs/operators';
import gql from 'graphql-tag';
import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import moment from 'moment';
import { forkJoin } from 'rxjs';

export const BET_CHANGE_CLAIM_CLASS: Flow_0_0_2 = {
	id: 'ChangeClaimClass',
	itemType: 'flow',
	name: 'Change Claim Class',
	excludeForOnlyStates: [169],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Change claim class : ${itemOne?.mid}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Change claim class';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ChangeClaimClass',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		ChangeClaimClass: {
			component: 'ClaimClassComponent',
			serverCalls: {
				claimDetailsInfo: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											claim_types {
												id
												name
												description
												skill_groups
												priority
											}
											skills {
												id
												name
											}
											config_options {
												desktop {
													upfront_rejection_reasons
												}
											}
										}
									}
								`,
								store
							)
							.pipe(take(1));
					}
				}
			},
			initFormFields: (bf, item, comp, sq, store) => {
				bf.addControl('claim_class', new UntypedFormControl(null, [Validators.required]));
				bf.addControl('selectedClaimClass', new UntypedFormControl(null));
				bf.addControl('claim_class_description', new UntypedFormControl(''));

				bf.bigForm.addControl('firedamageextent', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('recomendedadjuster', new UntypedFormControl(null, [Validators.required]));

				//impact
				bf.bigForm.addControl('_3rdpartyinsurancecompany', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('_3rdpartyvechilereg', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('_3rdpartyvechilemake', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('_3rdpartydrivername', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('_3rdpartydriverid', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('_3rdpartydrivercell', new UntypedFormControl(null, [Validators.required]));
				// Theft / damage
				bf.bigForm.addControl('policeclaimnumber', new UntypedFormControl(null, [Validators.required]));
				// Miscellaneous
				bf.bigForm.addControl('upfrontrepudiationreason', new UntypedFormControl(null, [Validators.required]));

				forkJoin([
					store.select(getFullItemOne).pipe(
						skipWhile(res => !res),
						take(1)
					),
					store.select(getAllInfo).pipe(
						skipWhile(res => !res),
						take(1),
						pluck('claim_types')
					)
				])
					.pipe(
						take(1),
						map(([claim, claim_types]) => ({ claim, claim_types }))
					)
					.subscribe(({ claim, claim_types }) => {
						// Contextual form values
						//fire
						bf.bigForm.get('firedamageextent').patchValue(claim?.loan_information.firedamageextent ? claim?.loan_information.firedamageextent : null);
						bf.bigForm.get('recomendedadjuster').patchValue(claim?.loan_information.recomendedadjuster ? claim?.loan_information.recomendedadjuster : null);
						//impact
						bf.bigForm.get('_3rdpartyinsurancecompany').patchValue(claim?.loan_information._3rdpartyinsurancecompany ? claim?.loan_information._3rdpartyinsurancecompany : null);
						bf.bigForm.get('_3rdpartyvechilereg').patchValue(claim?.loan_information._3rdpartyvechilereg ? claim?.loan_information._3rdpartyvechilereg : null);
						bf.bigForm.get('_3rdpartyvechilemake').patchValue(claim?.loan_information._3rdpartyvechilemake ? claim?.loan_information._3rdpartyvechilemake : null);
						bf.bigForm.get('_3rdpartydrivername').patchValue(claim?.loan_information._3rdpartydrivername ? claim?.loan_information._3rdpartydrivername : null);
						bf.bigForm.get('_3rdpartydriverid').patchValue(claim?.loan_information._3rdpartydriverid ? claim?.loan_information._3rdpartydriverid : null);
						bf.bigForm.get('_3rdpartydrivercell').patchValue(claim?.loan_information._3rdpartydrivercell ? claim?.loan_information._3rdpartydrivercell : null);
						// Theft / damage
						bf.bigForm.get('policeclaimnumber').patchValue(claim?.loan_information.policeclaimnumber ? claim?.loan_information.policeclaimnumber : null);
						// Miscellaneous
						bf.bigForm.get('upfrontrepudiationreason').patchValue(claim?.loan_information.upfrontrepudiationreason ? claim?.loan_information.upfrontrepudiationreason : null);

						const desc = (claim_types as any[]).find(type => type.id === claim?.claim_type).name;

						bf.bigForm.get('claim_class').patchValue(claim?.claim_type);
						bf.bigForm.get('claim_class_description').patchValue(desc);

						if (bf.bigForm.get('selectedClaimClass')?.value === null) {
							bf.bigForm.get('selectedClaimClass').patchValue([claim?.claim_type]);
						}
					});
			},
			inputs: {
				heading: 'Change Claim Class',
				canEditClaimExtraInfo: false,
				claimClassTypes: {
					subsidence: [14, 15, 19],
					impact: [6],
					fire: [7],
					police: [8, 9, 16],
					miscellaneous: [21],
					warningOnly: [20]
				},
				subsidenceExclusionsList: [
					'Any digging or excavation (other than mining activities) or removal or weakening of support.',
					'Any alteration, addition or repair to the Insured Property, even if done before this Policy started.',
					'The compaction of made up ground or fill',
					'Any defective design, materials or workmanship',
					'Any changes in the volume or moisture of Active Soils',
					'Any cause that existed for over 12 months or before this Policy started',
					'Any similar cause that You already claimed for, unless You did what is needed to prevent future damage from that cause and maintained it',
					'Any foundation system, foundation, plinth wall, floor or flooring system below the level of the main living area level (for example, in any basement, entrance or garage below the main living ground floor area)',
					'Any swimming pool, tennis court, patio, terrace, driveway, path, paving, surfacing system, concealed or exposed pipe (or other * fluid conduit), boundary, garden, retaining wall, fence, post or gate.',
					'Settlement, shrinkage or expansion of the Insured Property because of active soil; and/or',
					'Consequential loss or damage of any kind whatsoever upfront rejection reason'
				],
				fireContainedOptions: [
					{ display: 'Contained to Kitchen', value: 0 },
					{ display: 'Contained to Other Area', value: 1 },
					{ display: 'Not Contained', value: 2 }
				]
			},
			checkValidityForFields: ['claim_class'],
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							instance.store
								.select(getFullItemOne)
								.pipe(take(1))
								.subscribe(data => {
									if (!instance?.bf?.bigForm?.get('whatmatters')) {
										instance?.bf?.bigForm?.addControl('whatmatters', new UntypedFormControl(data?.loan_information.whatmatters));
									}

									if (!instance?.bf?.bigForm?.get('date_of_loss')) {
										instance?.bf?.bigForm?.addControl('date_of_loss', new UntypedFormControl(data?.loan_information.dateofloss));
									}
								});
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: { isDateOfLossDisabled: true }
								})
							};
						}
					}
				},
				{
					text: 'Change Claim Class',
					color: 'primary',
					linkType: 'submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						changeClaimClass: {
							errorMessage: 'could not change claim class',
							directCall: (http, store, storeObj, bf) => {
								return forkJoin([
									store.select(getFullItemOne).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									take(1),
									map(([claim, submissionData]) => {
										const data = {
											claim_id: claim?.id,
											new_claim_class: parseInt(bf.bigForm.get('selectedClaimClass')?.value[0], 10),
											application: {
												...claim?.loan_information,
												...submissionData.loan_information
											}
										};
										return data;
									}),
									switchMap(postData => {
										return http.post(`${environment.api_url}v1/claim_action/change_claim_type/`, postData);
									})
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		// what matters update
		whatmatters: 'loan_information.whatmatters',
		claimdescription: [
			(claimdescription, storeObj, formValues) => {
				const handler = storeObj?.identity?.currentUser?.user?.full_name;
				return 'Date: ' + moment().format('YYYY-MM-DD') + ' \nStaff: ' + (handler || '') + ' \n' + formValues.whatmatters + '.';
			},
			'loan_information.claimdescription'
		],
		date_of_loss: 'loan_information.dateofloss',
		claim: ['id', 'loan_information.id'],

		selectedClaimClass: ['claim_type_id', 'loan_information.claimtype_id'],
		claim_class_description: 'loan_information.claimtype',
		claim_id: [
			(cid, storeObj) => {
				cid = storeObj['selectedContext']?.selectedItemOne.id;
				return cid;
			},
			'claim_id'
		],

		// Claim Details context actions
		_3rdpartyinsurancecompany: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartyinsurancecompany'
		],
		_3rdpartyvechilereg: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartyvechilereg'
		],
		_3rdpartyvechilemake: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartyvechilemake'
		],
		_3rdpartydriverid: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartydriverid'
		],
		_3rdpartydrivercell: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartydrivercell'
		],
		_3rdpartydrivername: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 6 ? val : null;
			},
			'loan_information._3rdpartydrivername'
		],
		firedamageextent: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 7 ? val : null;
			},
			'loan_information.firedamageextent'
		],
		recomendedadjuster: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 7 ? val : null;
			},
			'loan_information.recomendedadjuster'
		],
		policeclaimnumber: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && [8, 9, 16]?.includes(Number(formValue.selectedClaimClass[0])) ? val : null;
			},
			'loan_information.policeclaimnumber'
		],
		upfrontrepudiationreason: [
			(val, storeObj, formValue) => {
				return formValue.selectedClaimClass && Number(formValue.selectedClaimClass[0]) === 21 ? val : null;
			},
			'loan_information.upfrontrepudiationreason'
		]
	}
};
