import { HttpClient } from '@angular/common/http';
import { BigFormService, getFullItemTwo, getSelectedItem, MakeServerCall, ModalService, NodePoint_0_0_2 } from '@flexus/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { skipWhile, take, map, switchMap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';

export const photoNode = (state?, new_state?): NodePoint_0_0_2 => {
	return {
		name: 'Photos',
		initFormFields: (bf, item, instance, sq, store) => {
			if (new_state) {
				bf.patchValues({ new_state });
			}
		},
		component: 'FLXViewPhotosComponent',
		inputs: {
			heading: 'Job Photos',
			location: 'context-menu'
		},
		showTabs: true,
		navs: [
			...(state === 176 || state === 188 || state === 187
				? [
						{
							text: 'Send to QA',
							nextNode: 'sendToQa',
							color: 'secondary'
						},
						{
							text: 'Send to support',
							nextNode: 'decline',
							color: 'secondary'
						},
						{
							text: 'Submit',
							nextNode: 'submissionSuccess',
							serverFirst: true,
							optIntoValidation: true,
							color: 'primary',
							// linkType: 'submit' as any,
							serverCalls: {
								response: {
									serviceVariable: 'mulService',
									functionName: 'updateJob',
									errorMessage: 'Job could not be updated!!'
								}
							}
						}
				  ]
				: // : state === 331 || state === 335
				// ? [
				// 		{
				// 			text: 'close job',
				// 			color: 'secondary',
				// TODO: 'location' nav propety used here causes app to break. Determine why! ... and fix
				// : state === 331 || state === 335
				// ? [
				// 		{
				// 			text: 'cancel job',
				// 			color: 'secondary',
				// 			location: 'center',
				// 			serverCalls: {
				// 				response: {
				// 					errorMessage: 'Job could not be updated!',
				// 					directCall: (_httP: HttpClient, _store: Store, sq, _bf: BigFormService, ctrl, _modal: ModalService) => {
				// 						_bf.patchValues({ new_state: 28 });
				// 						_modal.openModalDirectly((instance: any) => {
				// 							instance.type = 'warning';
				// 							instance.message = 'Are you sure? This will close the job';
				// 							instance.navButtons = [
				// 								{
				// 									text: 'cancel',
				// 									linkType: 'close',
				// 									clickHandler: () => {
				// 										_bf.patchValues({ new_state: 341 });
				// 									}
				// 								},
				// 								{
				// 									text: 'close job',
				// 									linkType: 'close',
				// 									serverFirst: true,
				// 									clickHandler: () => {
				// 										_store.dispatch(
				// 											new MakeServerCall({
				// 												dataKey: 'updateJob',
				// 												errorMessage: 'Could not update job',
				// 												serviceVariable: 'mulService',
				// 												functionName: 'updateJob',
				// 												nextNode: 'SubmissionSuccess'
				// 											})
				// 										);
				// 									}
				// 								}
				// 							];
				// 						});
				// 						return of({});
				// 					}
				// 				}
				// 			}
				// 		},
				// 		{
				// 			text: 'manual payment made',
				// 			color: 'secondary',

				// 			serverCalls: {
				// 				response: {
				// 					errorMessage: 'Job could not be updated!',
				// 					directCall: (_httP: HttpClient, _store: Store, sq, _bf: BigFormService, ctrl, _modal: ModalService) => {
				// 						_bf.patchValues({ new_state: 28 });
				// 						_modal.openModalDirectly((instance: any) => {
				// 							instance.type = 'warning';
				// 							instance.message = 'Are you sure? This will close the job';
				// 							instance.navButtons = [
				// 								{
				// 									text: 'cancel',
				// 									linkType: 'close',
				// 									clickHandler: () => {
				// 										_bf.patchValues({ new_state: 341 });
				// 									}
				// 								},
				// 								{
				// 									text: 'close job',
				// 									// nextNode: 'SubmissionSuccess',
				// 									linkType: 'close',
				// 									serverFirst: true,
				// 									clickHandler: () => {
				// 										_store.dispatch(
				// 											new MakeServerCall({
				// 												dataKey: 'updateJob',
				// 												errorMessage: 'Could not update job',
				// 												serviceVariable: 'mulService',
				// 												functionName: 'updateJob',
				// 												nextNode: 'SubmissionSuccess'
				// 											})
				// 										);
				// 									}
				// 								}
				// 							];
				// 						});
				// 						return of({});
				// 					}
				// 				}
				// 			}
				// 		},
				// 		{
				// 			text: 'next',
				// 			color: 'primary',
				// 			serverFirst: true,
				// 			nextNode: 'FixProblem'
				// 			text: 'resubmit for payment',
				// 			color: 'primary',
				// 			serverFirst: true,
				// 			nextNode: 'SubmissionSuccess',
				// 			serverCalls: {
				// 				response: {
				// 					serviceVariable: 'mulService',
				// 					functionName: 'updateJob',
				// 					errorMessage: 'Job could not be updated'
				// 				}
				// 			}
				// 		}
				//   ]
				state === 177 || state === 233
				? [
						{
							text: 'Send to 4-sure support',
							nextNode: 'requestQA',
							color: 'secondary'
						},
						{
							text: 'Approve',
							nextNode: 'resolvedProblem'
						}
				  ]
				: state === 238
				? [
						{
							text: 're-trigger integration calls',
							serverFirst: true,
							serverCalls: {
								response: {
									errorMessage: 'Could not re-trigger',
									directCall: (_http: HttpClient, _store: Store) => {
										return _store.select(getFullItemTwo).pipe(
											skipWhile(x => !x),
											take(1),
											switchMap((response: any) => {
												const { id } = response;
												const request = {
													job_id: id,
													new_state: 26
												};
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);

												return of({});
											})
										);
									}
								}
							},
							nextNode: 'SubmissionSuccess',
							color: 'primary'
						}
				  ]
				: state === 185
				? [
						{ text: 'send query to installer', color: 'default', nextNode: 'SendQueryToSP' },
						{
							text: 'resubmit for payment',
							color: 'primary',
							serverFirst: true,
							serverCalls: {
								response: {
									serviceVariable: 'mulService',
									functionName: 'updateJob',
									errorMessage: 'Job could not be resubmitted for payment'
								}
							},
							nextNode: 'SubmissionSuccess'
						}
				  ]
				: state === 252 || state === 253 || state === 250
				? [
						{
							text: 'Query',
							color: 'secondary',
							nextNode: 'QueryInvoice'
						},
						{
							text: 'Payment Complete',
							color: 'primary',
							nextNode: 'Decision'
						}
				  ]
				: state === 247 || state === 248 || state === 271
				? [
						{
							text: 'request proof of purchase',
							nextNode: 'RequestProofOfPurchase',
							color: 'secondary'
						},
						{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'alert' },
						{
							text: 'Approve',
							nextNode: 'submissionSuccess',
							color: 'primary',
							serverCalls: {
								response: {
									serviceVariable: 'mulService',
									functionName: 'updateJob',
									errorMessage: 'Job could not be updated!!'
								}
							}
						}
				  ]
				: [
						{
							text: 'SP Rework',
							nextNode: 'rework',
							color: 'secondary'
						},
						{
							text: 'QA Regional',
							nextNode: 'requestQA',
							color: 'secondary'
						},
						{
							text: 'Issue Fixed',
							nextNode: 'resolvedProblem',
							color: 'primary'
						}
				  ])
		]
	};
};
