import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FLXIconModule } from '@flexus/ui-elements';
import { FLXAlertMessagesComponent } from './alert-messages.component';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, FLXIconModule],
	declarations: [FLXAlertMessagesComponent],
	exports: [FLXAlertMessagesComponent]
})
export class FLXAlertMessagesModule {}
