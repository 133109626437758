<div
	class="card-container"
	[ngClass]="{
		'red-border': user.hasPhoto === false,
		'card-container': user.hasPhoto === true
	}"
>
	<div class="photo-container">
		<!-- {{ user | json }} -->
		<div
			[ngClass]="{
				'has-photo': user.hasPhoto === true,
				'monogram-only': user.hasPhoto === false
			}"
		>
			<span *ngIf="user.hasPhoto === false">{{ user.monogram }}</span> <img *ngIf="user.hasPhoto === true" src="{{ 'data:image/jpeg;base64,' + user.image }}" class="responsive" />
		</div>
	</div>
	<div class="detail-container" style="margin-left: 5px">
		<flx-heading size="small" weight="bold" margin="1.6rem 0 0 0">{{ user.full_name }}</flx-heading>
	</div>
</div>
