import { Component, OnInit, ViewChild, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Reminder } from '@flexus/models';
import { OverlayConfig, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { humaniseDate, returnOrDefault } from '@flexus/utilities';
import { CdkPortal } from '@angular/cdk/portal';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { IconTypes } from '@flexus/ui-elements';

@Component({
	selector: 'flx-display-reminder',
	templateUrl: './display-reminder.component.html',
	styleUrls: ['./display-reminder.component.scss']
})
export class FLXDisplayReminderComponent implements OnInit, OnDestroy {
	overlayRef: OverlayRef;
	snoozeState = 'open';
	instances: any;
	@ViewChild('overlayTemplate') overlayTemplate: CdkPortal;

	@Input() set reminderInstances(inst: Reminder[]) {
		this.instances = inst;
	}

	get reminderInstances() {
		return returnOrDefault(this.instances, []);
	}

	@Output() reminderDisplayCommand = new EventEmitter<any>();

	// ======================================== General Methods ========================================
	constructor(private overlay: Overlay, private store: Store<any>) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.close();
	}

	displayReminder(reminder) {
		this.reminderInstances.push(reminder);
	}

	takeAction(action: string, remInst: Reminder, snoozeTime?: number) {
		this.snoozeState = 'closed';
		switch (action) {
			case 'delete':
				// console.log('Delete Reminder: ', remInst);
				this.reminderDisplayCommand.emit({ action: 'deleteActive', id: remInst.id });
				if (this.reminderInstances.length <= 1) {
					this.close();
				}
				break;
			case 'snooze':
				// console.log('Snooze Reminder: ', remInst, 'by (min):', snoozeTime);
				remInst.date = moment().format('YYYY-MM-DD');
				remInst.time = moment()?.add(snoozeTime, 'minutes').format('HH:mm');
				this.reminderDisplayCommand.emit({ action: 'snooze', reminder: remInst });
				if (this.reminderInstances.length <= 1) {
					this.close();
				}
				break;
		}
	}

	formatDate(date: string): string {
		return humaniseDate(date);
	}

	public getIconType(type: string): IconTypes {
		switch (type?.toLowerCase()) {
			case 'general':
				return 'alarm-clock';
			case 'call':
				return 'phone';
			case 'email':
				return 'envelope';
			default:
				return 'alarm-clock';
		}
	}

	// ======================================== CDK Methods ========================================
	openTemplateOverlay() {
		const positionStrategy = this.overlay.position().global().top('60px').right('60px');

		const overlayConfig = new OverlayConfig({
			positionStrategy
		});

		overlayConfig.hasBackdrop = false;
		this.overlayRef = this.overlay.create(overlayConfig);
		this.overlayRef.attach(this.overlayTemplate);
	}

	open(): void {
		if (this.overlayRef) {
			this.overlayRef.dispose();
		}
		this.openTemplateOverlay();
	}

	close(): void {
		if (!!this.overlayRef) {
			this.overlayRef.dispose();
		}
	}

	openToClaim(claimNo: any) {
		this.reminderDisplayCommand.emit({ action: 'openToClaim', claimNo });
	}
}
