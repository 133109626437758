import { Component, OnInit, Input, AfterViewInit, OnDestroy, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, pluck, skipWhile } from 'rxjs/operators';
import { FLXDropdownComponent } from '@flexus/ui-elements';
import { ValidateAllObjectsFieldsInArray } from '@flexus/utilities';

@Component({
	selector: 'flx-sp-add-job',
	templateUrl: 'sp-add-job.component.html',
	styleUrls: ['sp-add-job.component.scss']
})
export class SpAddJobComponent implements OnInit, AfterViewInit, OnDestroy {
	// variables
	displayConfig = { displayKey: 'display', valueKey: 'value' };
	numbers = [1, 2, 3];

	@Input() claimDetailsInfo$: Observable<any>;
	@Input() jobtypes$: Observable<any>;
	@Input() decoderTypes$: Observable<any>;

	@ViewChildren('is_repair') is_repair: QueryList<FLXDropdownComponent>;
	@ViewChildren('job_type') job_type: QueryList<FLXDropdownComponent>;
	// @ViewChild('is_repair') is_repair: FLXDropdownComponent;

	purchaseOptions = [
		{ value: 1, display: 'Yes' },
		{ value: 2, display: 'No' }
	];

	jobList: UntypedFormArray = null;
	jobsSub: Subscription;
	isRepairSub: Subscription;
	repairSelectionArray;

	constructor(public _store: Store<any>, private _fb: UntypedFormBuilder, public _bf: BigFormService, private _cd: ChangeDetectorRef) {}

	ngOnInit() {
		if (!this._bf.bigForm.get('jobs')) {
			this._bf.bigForm.setControl('jobs', this._fb.array([this.createJob()], []));
		}
		this.jobList = this._bf.bigForm?.value?.jobs as UntypedFormArray;
		this.splitData(this.claimDetailsInfo$);
	}

	splitData(d$: Observable<any>) {
		this.jobtypes$ = d$.pipe(
			skipWhile((jt: any) => !jt),
			map((result: any) => {
				const skills = result?.skills;
				const skillsforjobs: any[] = [];
				skills.forEach(element => {
					const ownjob_skills: number[] = [99, 100, 101];
					if (ownjob_skills.includes(element.id)) {
						let name: string;
						switch (element.id) {
							case 99:
								name = 'Repair';
								break;
							case 100:
								name = 'Non-vouchered Installation';
								break;
							case 101:
								name = 'Vouchered Installation';
								break;
							default:
								name = 'Multichoice job';
						}
						const obj = {
							display: name,
							value: element?.id
						};

						skillsforjobs.push(obj);
					}
				});
				return skillsforjobs;
			})
		);
	}

	get jobListDynamic() {
		return this._bf.bigForm.get('jobs') as UntypedFormArray;
	}

	ngAfterViewInit(): void {
		this.jobsSub = this._bf.bigForm.get('jobs').valueChanges?.subscribe(val => {
			this.jobList = val as UntypedFormArray;
		});
	}

	createJob(job?): UntypedFormGroup {
		if (job) {
			return this._fb.group(
				{
					// id: UUID.UUID(),
					id: null,
					skillrequested: job?.skillrequested,
					appointmentDatePicker: new Date(job.requestedappointmentdate),
					appointmentTimePicker: [
						{
							hour: job?.requestedappointmenttime?.split(':')[0] ?? '',
							minutes: job?.requestedappointmenttime?.split(':')[1] ?? ''
						},
						Validators.required
					],
					appointmentTime: job?.appointment_type_id,
					appointmentDateType: job?.appointmentdatetype,
					skill: [job?.skill, [Validators.required]],
					to_purchase: [null, [Validators.required]],
					decoder_type: [null, [Validators.required]]
				},
				{ validators: [Validators.required, ValidateAllObjectsFieldsInArray()] }
			);
		} else {
			return this._fb.group(
				{
					// id: UUID.UUID(),
					id: null,
					skill: [null],
					skill_id: [null, [Validators.required]],
					providertype_id: 10,
					providertype: 'Own JobCard',
					appointmentDatePicker: [null, Validators.required],
					appointmentTimePicker: [null, Validators.required],
					appointmentTime: null,
					to_purchase: [null, [Validators.required]],
					decoder_type: [null, [Validators.required]]
				},
				{ validators: [Validators.required, ValidateAllObjectsFieldsInArray()] }
			);
		}
	}
	incJobCount(index: number) {
		this.jobListDynamic.push(this.createJob());
		console.log('INCREMENT', index);
		// if (this.jobList !== null) {
		// 	if (this.jobList.length > 0) {
		// 	}
		// }
	}
	removeJob(i: number) {
		if (this.jobList !== null) {
			this.jobListDynamic.removeAt(i);
		}
	}
	jobTypeAction(skillID: any, index: number) {
		if (skillID !== null) {
			this._bf.bigForm.get(`jobs.${index}.skill`)?.patchValue(this.nameLookup(skillID, 'skills', 'name', this.claimDetailsInfo$));
			this._bf.bigForm.get(`jobs.${index}.skillrequested`)?.patchValue(skillID);
		}
	}
	setDeviceType(event: any, jobIndex: number) {
		console.log('event', { event });
		if (event !== null) {
			this._bf.bigForm.get(`jobs.${jobIndex}.decoder_type`).patchValue(event);
			// console.log('the event is not null', X);
		}
	}

	purchaseDecoderAction(event: Event, index: number) {
		console.log('PURCHASE DECODER EVENT', event);
		this.repairSelectionArray = this.is_repair.toArray();

		this.isRepairSub = this._bf.bigForm.get('jobs').valueChanges.subscribe(vals => {
			vals.map(val => {
				for (let job = 0; job < this.jobList.length; job++) {
					if (this._bf.bigForm.get('jobs').value[index].skillrequested === 99) {
						this.repairSelectionArray[index].value = 'No';
						this._bf.bigForm.get('jobs').value[index].to_purchase = 2;
						// this._bf.bigForm.get('to_purchase').setValue(2);
					}
					this._bf.bigForm.updateValueAndValidity();
				}
			});
		});
		this._bf.patchValues({ to_purchase: event });
	}

	decoderTypeAction(event: Event) {
		this._bf.patchValues({ decoder_type: event });
	}
	getDecoderType(job): UntypedFormArray {
		return job?.get('decoder_type') as UntypedFormArray;
	}
	findPos(id: any, arr: any[], field = 'id'): number {
		let res = -1;
		if (arr !== undefined) {
			let count = 0;
			arr.forEach(item => {
				if (item[field] === id || item === id) {
					res = count;
				}
				count++;
			});
		}
		return res;
	}
	nameLookup(targetId?: number, group?: string, field?: string, source$?: Observable<any>) {
		// Used to look up the name that corresponds to an id in an observable
		let res: any[] = [];
		const tempSub: Subscription = source$
			.pipe(
				pluck(group),
				map((obj: { [key: string]: { id: number; name: string } }) => {
					return Object.values(obj).map(entry => ({ id: entry.id, name: entry[field] }));
				})
			)
			.subscribe(testData => {
				res = testData;
				const resPos = this.findPos(targetId, res);
				res = res[resPos];
			});

		if (tempSub) {
			tempSub.unsubscribe();
		}

		return res !== undefined ? res['name'] : '';
	}
	getJobsArray(): UntypedFormArray {
		const ajobs = this._bf.bigForm.get('jobs') as UntypedFormArray;
		return this._bf.bigForm.get('jobs') as UntypedFormArray;
	}
	setPurchaseToNoIfRepair(item, index, array) {}

	ngOnDestroy(): void {}
}
