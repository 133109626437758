import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicLoaderModule } from './dynamic-loader/dynamic-loader.module';
import { DcModule } from './dc';
import { CLIENTSERVICE, SCREENMAPPER, JWT_TOKEN_KEY, ENVIRONMENT } from '@flexus/core';
import { ManifestCoreModule } from './manifest-core/manifest-core.module';


// import { MCAutopayInvoicingModule } from './mc-autopay-invoicing';

interface EngineConfig {
	environment: any;
	service: any;
	screenMapper: any;
}

export const TokenizerFactory = (environment: any) => {
	let jwt: any;
	const api_urls_arr = Object.values(environment.api_urls);
	if (environment.url_usage === 'multi' && api_urls_arr && api_urls_arr.length > 0) {
		jwt = [];
		api_urls_arr.forEach((urlItem: any) => {
			jwt.push(urlItem);
		});
	} else {
		jwt = 'flexus.web.jwtToken';
	}
	return jwt;
};

@NgModule({
	imports: [
		CommonModule,
		DynamicLoaderModule,
		DcModule,
		// RootModule,
		ManifestCoreModule,

	],
	exports: [
		ManifestCoreModule,
		DynamicLoaderModule,
		DcModule,
  ],
	providers: []
})
export class EngineModule {
	static forRoot(config: EngineConfig): ModuleWithProviders<EngineModule> {
		return {
			ngModule: EngineModule,
			providers: [
				{ provide: CLIENTSERVICE, useClass: config.service },
				{ provide: SCREENMAPPER, useValue: config.screenMapper },
				{ provide: ENVIRONMENT, useValue: config.environment },
				{ provide: JWT_TOKEN_KEY, useFactory: TokenizerFactory, deps: [ENVIRONMENT] }
			]
		};
	}
}
