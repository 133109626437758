import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AddUserComponent } from "./add-user/add-user.component";
import { ReactiveFormsModule } from "@angular/forms";
import { UIElementsModule } from '@flexus/ui-elements';
import { InlineSVGModule } from "ng-inline-svg";
import { HttpClientModule } from '@angular/common/http';



@NgModule({
	declarations: [AddUserComponent],
	exports: [AddUserComponent],
	imports: [CommonModule, ReactiveFormsModule, InlineSVGModule, UIElementsModule, HttpClientModule]
})
export class SpUserModule {}
