import { SortState } from "./filter-types";

/**
 * a generic type neutral comparator, this will return for any of the various kinds of types
 * default ascending sort type output, a larger value for x will produce a positive.
 * this can handle null / undefined
 * @param x
 * @param y
 */
export const genericComparator = (x, y): number => {
	if (x === y) {
		return 0;
	}
	if (x === null || x === undefined) {
		return -1;
	}
	if (y === null || y === undefined) {
		return 1;
	}

	switch (typeof x) {
		case 'string':
			return x.localeCompare(y);
		case 'number':
			return x - y;
		case 'symbol':
		case 'undefined':
		case 'function':
			return 0;
		case 'boolean':
			return x !== y ? (x === true ? 1 : -1) : 0;
		default:
			return JSON.stringify(x).localeCompare(JSON.stringify(y));
	}
};

/**
 * A factory function to give the generic comparator a dynamic ascending descending order
 * @param direction A sort state to control the asc/ desc that this parameter generates
 */
export const directionalGenericComparator = (direction: SortState) => (direction === SortState.ascending ? genericComparator : (x, y) => genericComparator(x, y) * -1);

/**
 * A factory function to generate a comparator
 * Will return positive if x is the supplied value
 * Will return negative if y is the supplied value
 * Will return 0 if x ===y or neither equal value
 * @param val
 */
export const percolationComparator =
	val =>
	(x, y): number =>
		x === y ? 0 : x === val ? 1 : y === val ? -1 : 0;

/**
 * a generic factory function to give direction to the percolationComparator function
 * @param direction asc/desc
 * @param value value to look for
 */
export const directionalPercolationComparator = (direction: SortState, value: any) =>
	direction === SortState.ascending ? percolationComparator(value) : (x, y) => percolationComparator(value)(x, y) * -1;
