<flx-heading type="page" size="medium">What Matters</flx-heading>

<div class="what-matters-component">
	<!-- <flx-heading size="small">What Matters</flx-heading> -->
	<form [formGroup]="bf.bigForm" class="what-matters-form">
		<!-- <flx-input-text formControlName="date_of_loss" id="dateOutput" placeholder="Date Of Loss" ></flx-input-text> -->
		<ng-container *ngIf="bf.bigForm.get('date_of_loss')">
			<flx-label>{{ datePickerTitle }}</flx-label>
			<input formControlName="date_of_loss" [disabled]="isDateOfLossDisabled" [flxDatepicker]="myDatePicker" [max]="maxDate" id="dateOutput" autocomplete="off" /> <br />
			<flx-datepicker #myDatePicker></flx-datepicker>
		</ng-container>
		<!--    <flx-label>In the customer's words</flx-label>-->
		<input type="hidden" formControlName="claimdescription" id="claim_description" />
		<flx-label>{{ whatMattersTitle }}</flx-label>
		<flx-textarea width="280px" formControlName="whatmatters" id="what_matters" [rows]="10"></flx-textarea>
		<flx-reactive-form-error-display [control]="bf.bigForm.controls['whatmatters']" class="error"> </flx-reactive-form-error-display>

		<!-- additional textarea -->
		<ng-container *ngIf="additionalTextArea">
			<flx-label>{{ additionalTextArea?.title }}</flx-label>
			<flx-textarea
				width="{{ additionalTextArea?.width }}"
				formControlName="{{ additionalTextArea?.formControlName }}"
				id="{{ additionalTextArea?.formControlName }}"
				[rows]="10"
			></flx-textarea>
		</ng-container>
	</form>
</div>
