import { Component, OnInit, OnDestroy, AfterViewInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { BigFormService, } from '@flexus/core';
import { map } from 'rxjs/operators';

@Component({
	selector: 'flx-device-details',
	templateUrl: './device-details.component.html',
	styleUrls: ['./device-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
	displayConfig = { displayKey: 'name', valueKey: 'id' };

	@Input() showCurrentJobs = false;
	currentJobControls: any;
	@Input() replacementDeviceTypes$: Observable<{ id: number; name: string }[]>;

	constructor(public _store: Store<any>, public bf: BigFormService, private fb: UntypedFormBuilder) {}

	ngOnInit() {
		this.currentJobControls = this.bf.bigForm.get('currentJobs') as UntypedFormArray;
	}

	getJobsArray(): UntypedFormArray {
		return this.bf.bigForm.get('jobs') as UntypedFormArray;
	}

	getDevicesArray(job): UntypedFormArray {
		return job?.get('device_details') as UntypedFormArray;
	}

	ngAfterViewInit(): void {}

	setDeviceType(event: any, jobIndex: number, deviceIndex) {
		console.log({ event });
		if (event !== null) {
			this.bf.bigForm.get(`jobs.${jobIndex}.device_details.${deviceIndex}.replacement_device.id`)?.patchValue(event);

			setTimeout(() => {
				this.bf.bigForm.get(`jobs.${jobIndex}.device_details.${deviceIndex}.replacement_device.name`)?.patchValue(this.nameLookup(event, 'name', this.replacementDeviceTypes$));
			}, 1000);
		}
	}

	nameLookup(targetId: number, field: string, source$: Observable<any>) {
		// Used to look up the name that corresponds to an id in an observable
		let res: any[] = [];
		const tempSub: Subscription = source$
			.pipe(
				map((obj: { [key: string]: { id: number; name: string } }) => {
					return obj && Object.values(obj).map(entry => ({ id: entry.id, name: entry[field] }));
				})
			)
			.subscribe(testData => {
				res = testData;
				const resPos = this.findPos(targetId, res);
				res = res[resPos];
			});

		if (tempSub) {
			tempSub.unsubscribe();
		}

		return res !== undefined ? res['name'] : '';
	}

	//find the position of the id in the look up array
	findPos(id: any, arr: any[], field = 'id'): number {
		let res = -1;
		if (arr !== undefined) {
			let count = 0;
			arr.forEach(item => {
				if (item[field] === id || item === id) {
					res = count;
				}
				count++;
			});
		}
		return res;
	}

	incDeviceCount(job) {
		this.getDevicesArray(job).push(
			this.fb.group({
				replacement_device: this.fb.group({ name: [null], id: [null, [Validators.required]] }),
				insured_device: this.fb.group({
					serial_number: [null, [Validators.required]]
				})
			})
		);
	}

	removeDevice(job, i: number) {
		this.getDevicesArray(job).removeAt(i);
	}

	ngOnDestroy() {}
}
