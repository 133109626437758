import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { LoadSilManifestService } from './services/load-sil-manifest.service';
// import { SilClaimCardComponent } from './components/sil-claim-card/sil-claim-card.component';
// import { SilJobCardComponent } from './components/sil-job-card/sil-job-card.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { RouterModule } from '@angular/router';
// import { FLXDetailsComponent } from './views/bet-details-view/bet-details-view.component';
import { NgxPaginationModule } from 'ngx-pagination';
// import { FLXListViewComponent } from './views/bet-list-view/bet-list-view.component';
// import { SilService, ClaimService, JobService } from './services';
import { UIElementsModule } from '@flexus/ui-elements';
import { ThemeModule } from '@flexus/ux';
// import { SilWorkflowShellComponent } from './base-components/sil-workflow-shell/sil-workflow-shell.component';
// import { SilFlowComponent } from './base-components/sil-flow/sil-flow.component';
import { environment } from '../../../environments/environment';
import { UICompositesModule } from '@flexus/ui-composites';
import { ErrorHandlerModule } from '@flexus/error-handler';
import { ScreensModule } from '@flexus/screens';
// import { ActivateStateGuard } from './guards/activate-state.guard';
// import { DeactivateStateGuard } from './guards/deactivate-state.guard';
import { LoadBuildersStockManagerManifestService } from './services/load-builders-stock-manager-manifest.service';
import { BuildersOneComponent } from './base-components/builders-one.component';
import { BuildersTwoComponent } from './base-components/builders-two.component';
import { MsalModule } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { IdentityModule } from '@flexus/identity';
import { DynamicLoaderModule } from '@flexus/engine';


export function loadConfig(manifest: LoadBuildersStockManagerManifestService) {
	return function () {
		return manifest.load();
	};
}
@NgModule({
	imports: [
		...environment.appShellFeatures,
		CommonModule,
		DynamicLoaderModule,
		ErrorHandlerModule,
		ScreensModule,
		InlineSVGModule,
		NgxPaginationModule,
		RouterModule,
		ThemeModule,
		UIElementsModule.forRoot({ environment: environment }),
		UICompositesModule,
		environment['azure']
			? MsalModule.forRoot(
					new PublicClientApplication({
						auth: {
							clientId: environment['azure']['clientId'],
							postLogoutRedirectUri: `${environment.base_url}auth/login`
						}
					}),
					null,
					null
			  )
			: CommonModule,
		IdentityModule.forRoot({
			after_login_url: '/',
			after_logout_url: '/auth/login',
			send_reset_link_endpoint: `${environment.api_url}v1/forgot_password/check-email`,
			login_endpoint: `${environment.api_url}v2/auth/login/`,
			no_auth_urls: ['/cons']
		})
	],
	declarations: [
		// SilClaimCardComponent,
		// SilJobCardComponent,
		// FLXDetailsComponent,
		// FLXListViewComponent,
		BuildersOneComponent,
		BuildersTwoComponent
		// FLXSearchBannerComponent
	],
	exports: [
		...environment.appShellFeatures
		// SilClaimCardComponent, SilJobCardComponent, FLXDetailsComponent, FLXListViewComponent
	],
	providers: [
		// ActivateStateGuard,
		// DeactivateStateGuard,
		{
			provide: APP_INITIALIZER,
			useFactory: loadConfig,
			deps: [LoadBuildersStockManagerManifestService],
			multi: true
		}
	]
})
export class BuildersStockManagerManifestModule {}
