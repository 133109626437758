<flx-heading class="component-header" size="medium" weight="extra-light" type="creation" align="center">Bank Account Details</flx-heading>
<flx-heading class="component-header" size="small" weight="extra-light" type="form" color="sub-heading" align="center">
	Enter details of the account to which payment will be made
</flx-heading>
<form class="form-container" novalidate [formGroup]="bankDetailsForm">
	<flx-flex-container direction="row" justifyContent="center" maxWidth="50vw">
		<flx-panel density="cozy">
			<flx-label class="form-radio individual">
				<flx-radio id="individualAccount" value="Individual" (change)="toggleBankAccountType()" [checked]="individual" formControlName="account_use" data-cy="individualRadio">
					Individual
				</flx-radio>
			</flx-label>

			<ng-container *ngIf="name !== 'Company Name'">
				<flx-label>Initials</flx-label>
				<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="initials"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['initials']"> </flx-reactive-form-error-display>
			</ng-container>

			<flx-label>{{ name }}</flx-label>
			<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="account_holder_name"></flx-input-text>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['account_holder_name']"> </flx-reactive-form-error-display>

			<flx-label>{{ identification }}</flx-label>
			<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="identification_number"></flx-input-text>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['identification_number']"> </flx-reactive-form-error-display>

			<flx-label>Account Number</flx-label>
			<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="account_number"></flx-input-text>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['account_number']"> </flx-reactive-form-error-display>
		</flx-panel>
		<flx-panel density="cozy">
			<flx-label class="form-radio company">
				<flx-radio id="companyAccount" value="Company" (change)="toggleBankAccountType()" [checked]="!individual" formControlName="account_use" data-cy="companyRadio">
					Company / Trust
				</flx-radio>
			</flx-label>

			<ng-container *ngIf="accountTypes$ | async as accountTypes">
				<ng-container *ngIf="accountTypes.length > 1 && accountTypes !== null">
					<flx-label>Account Type</flx-label>
					<flx-dropdown
						formControlName="acc_type"
						[displayOptions]="{ displayKey: 'description', valueKey: 'mid' }"
						[itemsOption]="accountTypes"
						placeHolder="Account Type"
						[searchEnabled]="false"
					></flx-dropdown>
				</ng-container>
			</ng-container>

			<flx-label>Bank</flx-label>
			<flx-input-text [maxlength]="50" type="text" size="normal" formControlName="bank_name" placeholder="eg. Stan"> </flx-input-text>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['bank_name']"> </flx-reactive-form-error-display>

			<ng-container *ngIf="branchFlag">
				<flx-label> Branch Name </flx-label>
				<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="branch" placeholder="eg. Four"> </flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['branch']"> </flx-reactive-form-error-display>
				<!-- <ng-container *ngIf="!firstLookup"> <small>Please use the Bank and Branch Name fields to search for a branch.</small> </ng-container> -->

				<!-- Branch Lookup -->
				<ng-container *ngIf="branchLookupEndpoint && !branchSelected">
					<div
						class="branch-lookup-container"
						*ngIf="branchFlag && bankDetailsForm?.get('bank_name')?.value?.length >= 3 && bankDetailsForm?.get('branch')?.value?.length >= 3 && bankDetailsForm?.dirty"
					>
						<flx-glow-line></flx-glow-line>
						<div class="branch-lookup-options">
							<ng-container *ngFor="let branch of branches">
								<div class="branch-option" (click)="setBranchInfo(branch)">
									<div>
										{{ branch.branch_name }} <span *ngIf="branch.branch_code">(Branch Code: {{ branch.branch_code }})</span>
									</div>
								</div>
							</ng-container>
						</div>
						<flx-glow-line></flx-glow-line>
					</div>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="!branchLookupEndpoint || (branchSelected && !!bankDetailsForm.get('branch_code')?.value); else selectBranchMessage">
				<flx-label>Branch Code</flx-label>
				<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="branch_code" [isDisabled]="branchFlag && branchLookupEndpoint"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['branch_code']"> </flx-reactive-form-error-display>
			</ng-container>
			<ng-template #selectBranchMessage>
				<small>Please use the <b>Bank</b> and <b>Branch Name</b> fields to search and select a branch.</small>
			</ng-template>

			<ng-container *ngIf="avsSwitchToggle && manualAvs">
				<flx-label>Did AVS Check Pass</flx-label>
				<flx-label> <input type="radio" id="policyHolder" value="False" formControlName="avs_check_pass" /><i class="form-icon"></i>No </flx-label>
				<flx-label> <input type="radio" id="policyHolder" value="True" formControlName="avs_check_pass" /><i class="form-icon"></i>Yes </flx-label>
				<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['avs_check_pass']"> </flx-reactive-form-error-display>
			</ng-container>
		</flx-panel>
	</flx-flex-container>
</form>

<div class="columns" *ngIf="manualAvs">
	<div class="column col-12 text-center">
		<div class="form-group">
			<flx-heading size="small" align="center" class="form-label" for="">Use Manual AVS Check</flx-heading>
			<flx-label size="small" class="form-radio">
				<input type="radio" value="auto" (change)="avsSwitch()" formControlName="avs_check_switch" /><i class="form-icon"></i>No
			</flx-label>
			<flx-label size="small" class="form-radio">
				<input type="radio" value="manual" (change)="avsSwitch()" formControlName="avs_check_switch" /><i class="form-icon"></i>Yes
			</flx-label>
		</div>
	</div>
</div>
