import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { switchMap, map, catchError } from 'rxjs/operators';
import {of } from 'rxjs';

import * as allInfoActions from './all-info.action';
import { AllInfoService } from '../services/all-info.service';

@Injectable()
export class AllInfoEffects {
	constructor(private actions$: Actions, private allInfoService: AllInfoService) {}

	getAllInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(allInfoActions.GET_ALL_INFO),
			switchMap((action: allInfoActions.GetAllInfo) => this.allInfoService.getAllInfo(action.payload)),
			map(data => new allInfoActions.GetAllInfoSuccess(data)),
			catchError(error => of(new allInfoActions.GetAllInfoFail(error)))
		)
	);
}
