import { Action } from '@ngrx/store';
import { Filter2 } from '@flexus/models';

/**
 * how hard would it be to let you do an array, though you can stitch it togeather using the next actions continue option
 * doesnt it
 */
export enum FilterActionTypes {
	SET_RANDOM_FIELD = '[FILTER] SET_RANDOM_FIELD',
	SET_FILTER_SET = '[FILTER] SET_FILTER_SET',
	RESET_FILTERS = '[FILTER] RESET_FILTERS',
	RESET_STATE = '[FILTER] RESET_STATE',
	SEARCH_ON_SERVER = '[] SEARCH_ON_SERVER',
	SEARCH_ON_SERVER_SUCCESS = '[] SEARCH_ON_SERVER_SUCCESS',
	SEARCH_ON_SERVER_FAIL = '[] SEARCH_ON_SERVER_FAIL',
	RESET_SERVER_SEARCH = '[] RESET SERVER SEARCH'
}

export class SetFilterDataField implements Action {
	readonly type = FilterActionTypes.SET_RANDOM_FIELD;
	constructor(public payload: { key: string; data: any }) {}
}
export class SetFilterSet implements Action {
	readonly type = FilterActionTypes.SET_FILTER_SET;
	constructor(public payload: { filterSet: Array<Filter2> }) {}
}
export class ResetFilters implements Action {
	readonly type = FilterActionTypes.RESET_FILTERS;
	constructor() {}
}
export class ResetFilterState implements Action {
	readonly type = FilterActionTypes.RESET_STATE;
	constructor() {}
}

export class SearchOnServer implements Action {
	readonly type = FilterActionTypes.SEARCH_ON_SERVER;
	constructor(public payload: { endpoint: string; search: string; active: boolean; virtualStatesFunction?: Function }) {}
}
export class SearchOnServerSuccess implements Action {
	readonly type = FilterActionTypes.SEARCH_ON_SERVER_SUCCESS;
	constructor(public payload: any[]) {}
}
export class SearchOnServerFail implements Action {
	readonly type = FilterActionTypes.SEARCH_ON_SERVER_FAIL;
	constructor(public payload: { error: any }) {}
}

export class ResetServerSearch implements Action {
	readonly type = FilterActionTypes.RESET_SERVER_SEARCH;
}

export type FilterStoreActionTypes =
	| SetFilterDataField
	| SetFilterSet
	| ResetFilters
	| ResetFilterState
	| SearchOnServer
	| SearchOnServerSuccess
	| SearchOnServerFail
	| ResetServerSearch;
