import { pipe } from 'ramda';

const createSelector = pipe;

export const getManifestState = state => state && state;

export const getManifestItems = createSelector(getManifestState, (state: any) => state && state.manifestItems);

export const getActiveNode = createSelector(getManifestState, (state: any) => state && state.activeNode);

export const getViewData = createSelector(getManifestState, (state: any) => state && state.viewData);

export const getActiveViewData = createSelector(getManifestState, (state: any) => state && state.activeViewData);

export const getActiveManifestItem = createSelector(getManifestState, (state: any) => state && state.activeManifestItem);

// export const getActiveFlowTitleText = createSelector(
//   getActiveStateFlow,
//   (state: any) => (state && state.titleText ? state.titleText : ''),
// );

export const getNavigation = createSelector(getManifestState, (state: any) => state && state.navigationStack);

// export const getStoreKey = createSelector(
//   getActiveStateFlow,
//   (state) => state.storeKey,
// );

export const getActiveOrganization = createSelector(getManifestState, (state: any) => state && state.activeOrganization);

export const getSettings = createSelector(getActiveOrganization, (state: any) => state && state.settings);

export const getOrgKey = createSelector(getActiveOrganization, (state: any) => state && state.orgKey);

// export const getWorkflowManifest = createSelector(
//   getActiveOrganization,
//   (state: any) => state && state.outlet,
// );

export const getPermissions = createSelector(getActiveOrganization, (state: any) => state && state.permissions);

export const getManifestSearchData = createSelector(getActiveOrganization, (state: any) => state && state.appSearch);

// export const getActionPanel = createSelector(
//   getActiveOrganization,
//   (state: any) => state && state.actionPanel,
// );

// export const getHeaderActions = createSelector(
//   getActiveOrganization,
//   (state: any) => state && state.headerActions,
// );

// export const getContextMenu = createSelector(
//   getWorkflowManifest,
//   (state: any) => state.contextMenu,
// );

export const getItemOneContextMenu = createSelector(getActiveManifestItem, (state: any) => state && state.contextMenu && state.contextMenu.itemOne);

export const getItemTwoContextMenu = createSelector(getActiveManifestItem, (state: any) => state && state.contextMenu && state.contextMenu.itemTwo);
