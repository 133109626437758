import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, UntypedFormControl, Validators } from '@angular/forms';
import { BigFormService, getCurrentUser } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Subscription, take } from 'rxjs';

@Component({
	selector: 'flexus-credit-note',
	templateUrl: './credit-note.component.html',
	styleUrls: ['./credit-note.component.scss']
})
export class CreditNoteComponent implements OnInit, OnDestroy {
	@Input() docTitle: string = '';
	@Input() boqLogo: string = '';
	@Input() boqLogoAlt: string = '';
	@Input() clientName = 'MUL';
	@Input() saveDraft = false;
	@Input() showLineItemGenerator: boolean = false;
	@Input() canEditLineItems: boolean = false;
	@Input() rejectBoqLineItems: boolean = false;

	@Input() docType: 'invoice' | 'quote' = 'quote';
	@Input() numberLabel: string = '';
	@Input() currentState!: number;
	@Input() newState!: number;
	isNonVatSP: boolean = false;
	actualLineItemsFormArray!: FormArray;
	currentUserSub!: Subscription;
	constructor(public bf: BigFormService, private store: Store) { }

	ngOnInit() {
		this.currentUserSub = this.store
			.select(getCurrentUser)
			.pipe(take(1))
			.subscribe((currentUser) => this.isNonVatSP = !currentUser?.user?.sp?.vat_no || currentUser?.user?.sp?.vat_no.trim()?.length == 0);

		this.bf.bigForm.addControl('base_invoice_number', new UntypedFormControl('', Validators.required));
		this.bf.bigForm.addControl('credit_note_number', new UntypedFormControl('', Validators.required));

		this.bf.bigForm.addControl('VoucherTypeandStatus', new UntypedFormControl('', Validators.required));
		this.bf.bigForm.addControl('VoucherNumber', new UntypedFormControl(''));
		this.bf.bigForm.addControl('AmountExclVAT', new UntypedFormControl('0'));
		this.bf.bigForm.addControl('vatPercentage', new UntypedFormControl(false));
		this.bf.bigForm.addControl('line_items', new FormArray([], [Validators.required]));

		this.actualLineItemsFormArray = this.bf.bigForm.get('line_items') as FormArray;
		console.log('this.actualLineItemsFormArray', this.actualLineItemsFormArray);
	}

	ngOnDestroy(): void {
		this.currentUserSub && this.currentUserSub?.unsubscribe();
	}
}
