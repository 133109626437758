import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { map, skipWhile, first } from 'rxjs/operators';
import { SIL_71 } from './SIL_71';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const SIL_19: Flow_0_0_2 = {
	id: '19',
	name: 'upfront_repudiation',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map(itemOne => {
					if (itemOne) {
						return `Upfront Repudiation - ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Upfron Repudiation';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp']),

	startNode: 'DisplayUpfrontNotice',
	nodes: {
		//Node 1 ************
		DisplayUpfrontNotice: {
			component: 'FLXKeyValueDisplayWithInstructionsComponent',
			inputs: {
				title: 'Claim Repudiated Upfront',
				instructions: ['This claim was repudiated during registration of the claim', 'Continue and generate the repudiation letter to send to the customer']
			},
			navs: [
				{
					text: 'Continue',
					color: 'primary',
					nextNode: 'RepudiationConversation'
				}
			]
		},

		...SIL_71.nodes
	}
};
