import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Flow_0_0_2, getAllInfo, getFullItemOne, getFullItemTwo, getRelatedItemTwos, getSubmissionData } from '@flexus/core';
import gql from 'graphql-tag';
import { forkJoin, of } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { getExtension } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';
import { setActionPanelItems } from '../../../../app-shell-features';
import { ASSESSOR_OVERVIEW } from '../reusable/ASSESSOR_OVERVIEW';
import { billingServerCalls } from '../reusable/OWN_BILLING';

export const BET_97: Flow_0_0_2 = {
	id: '97',
	name: 'assessor_report_uploaded',
	itemType: 'flow',
	fetchLevel1And2: true,
	header: {
		title: (store, bf) => {
			bf.bigForm.addControl('workflow_current_state', new UntypedFormControl(97));
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					let title = 'Review Claim';
					if (itemOne?.loan_information?.mavenclaimnumber) {
						title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
					}
					if (itemOne.loan_information.surname) {
						title += ` - ${itemOne.applicant?.surname}`;
					}

					return title;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Review'
		},
		viewRoles: {
			0: 'Wait for IA'
		}
	},
	actionPanel: instance =>
		setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp', 'query-history', 'policy-history', 'notes', 'ia_notes'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	serverCalls: {
		IAReports: {
			serviceVariable: 'betService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		appointmentTypes: {
			serviceVariable: 'betService',
			functionName: 'getAppointmentTypes',
			errorMessage: 'No Appointment Types could be found!'
		},
		...billingServerCalls
	},
	startNode: 'JobSummary',
	nodes: {
		...ASSESSOR_OVERVIEW,
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: bf => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentDateType: new UntypedFormControl(null),
						skill: new UntypedFormControl('Onsite Visit'),
						skillcatagory: new UntypedFormControl('Assessor')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												surname
											}
											loan_information {
												contactnumber
												cellnumber
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											loan_information {
												onsiteperson
												onsitecontact
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				}
			},
			component: 'FLXJobAppointmentComponent',

			checkValidityForFields: ['appointmentData'],
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					optIntoValidation: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'The new job could not be created!',
							serviceVariable: 'betService',
							functionName: 'assessorVisitSite'
						}
					}
				}
			]
		},
		EditReport: {
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('circumstance_of_loss', new UntypedFormControl('', [Validators.required]));
				bf.addControl('description_of_claim', new UntypedFormControl('', [Validators.required]));
				bf.addControl('recommendations', new UntypedFormControl('', [Validators.required]));
				bf.addControl('current_state', new UntypedFormControl(89, [Validators.required]));
				bf.addControl('new_state', new UntypedFormControl(90, [Validators.required]));
				bf.addControl('purpose', new UntypedFormControl('Internal Assessor Report', [Validators.required]));
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									job_information {
										circumstance_of_loss
										description_of_claim
										recommendations
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(values => {
						bf.patchValues({
							circumstance_of_loss: values.circumstance_of_loss ? values.circumstance_of_loss : bf.getControl('circumstance_of_loss')?.value || '',
							description_of_claim: values.description_of_claim ? values.description_of_claim : bf.getControl('description_of_claim')?.value || '',
							recommendations: values.recommendations ? values.recommendations : bf.getControl('recommendations')?.value || ''
						});
					});
			},
			serverCalls: {
				dataFile: {
					serviceVariable: '',
					functionName: '',
					errorMessage: 'No file could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryStore(
								gql`
									{
										selectedContext {
											IAReports
										}
									}
								`
							)
							.pipe(
								skipWhile(result => !result),
								take(1),
								switchMap(result => {
									const allReports = result.IAReports;
									const spReports = [];

									if (allReports) {
										allReports.forEach(report => {
											if (report.purpose === 'SP Report' || report.purpose === 'Internal Assessor Report') {
												spReports.push({ ...report });
											}
										});

										if (spReports) {
											spReports.sort((a, b) => (a.created < b.created ? 1 : -1));

											if (spReports.length > 0) {
												return http
													.post(`${environment.api_url}v1/file_action/get_file/`, {
														file_id: spReports[0]?.id,
														return_type: 1
													})
													.pipe(
														skipWhile(report => !report),
														take(1),
														map((value: any) => {
															return value.payload;
														})
													);
											}
										}
									}

									//Returning of empty object as when returning empty the loader isn't being removed - tried EMPTY and it does not work either.
									return of({});
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							dataFiles$: 'dataFile'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'circumstance_of_loss',
									inputType: 'textarea',
									rows: 3,
									label: 'What caused the loss'
								},
								1: {
									formControlName: 'description_of_claim',
									inputType: 'textarea',
									rows: 3,
									label: 'What is the damage'
								},
								2: {
									formControlName: 'recommendations',
									inputType: 'textarea',
									rows: 3,
									label: 'Recommendations'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw'
						}
					}
				]
			},
			checkValidityForFields: ['circumstance_of_loss', 'description_of_claim', 'recommendations'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							// functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									sq
										.queryObject(
											gql`
												{
													selectedContext {
														selectedItem
													}
												}
											`,
											store
										)
										.pipe(
											skipWhile(x => !x),
											take(1),
											map(res => res as any)
										),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any[])
									)
								]).pipe(
									map(([selected, submissiondata]: any[]) => {
										// debugger;
										if (selected && submissiondata) {
											const job_id = selected?.selectedItem?.id;
											const create_sp_report = 1;
											const circumstance_of_loss = submissiondata?.job_information?.circumstance_of_loss;
											const description_of_claim = submissiondata?.job_information?.description_of_claim;
											const recommendations = submissiondata?.job_information?.recommendations;

											return {
												job_id: job_id,
												upload_report: bf.bigForm.value.uploadReport,
												create_sp_report: create_sp_report,
												job_information: {
													circumstance_of_loss: circumstance_of_loss,
													description_of_claim: description_of_claim,
													recommendations
												},
												purpose: 'Internal Assessor Report',
												...(selected?.selectedItem?.state === 89 ? { new_state: 97 } : {})
											};
										}
									}),
									switchMap(data => http.post(`${environment.api_url}v1/job_action/update_job/`, data))
								);
							}
						}
					}
				}
			]
		},
		UploadReport: {
			initFormFields: (bf, item, instance, sq, store) => {
				bf.getControl('circumstance_of_loss').clearValidators();
				bf.getControl('description_of_claim').clearValidators();
				bf.getControl('recommendations').clearValidators();
				bf.bigForm.addControl('purpose', new UntypedFormControl('Internal Assessor Report', [Validators.required]));
			},
			component: 'FLXFileUploadWithPreviewComponent',
			inputs: {
				// configurations
				purposes: [{ display: 'Internal Assessor Report', value: 'Internal Assessor Report' }],
				heading: 'Select internal assessor report to upload',
				multipleUploads: false,
				allowedFileTypes: ['pdf'],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			// },
			navs: [
				{
					text: 'Upload Document',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit',
					serverCalls: {
						uploadDoc: {
							errorMessage: 'File could not be uploaded',
							timeoutMilliseconds: 2000,
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0]?.file.base64,
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
							},
							followUpSuccessCalls: {
								updateJob: {
									serviceVariable: 'betService',
									functionName: 'AssessorReportUploadedUpdateJob',
									errorMessage: 'Job could not be updated!!'
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		circumstance_of_loss: 'job_information.circumstance_of_loss',
		description_of_claim: 'job_information.description_of_claim',
		recommendations: 'job_information.recommendations',
		purpose: 'upload_file.purpose',
		current_state: 'upload_file.current_state',
		new_state: 'upload_file.new_state'
	}
};
