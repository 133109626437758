<div [formGroup]="bf.bigForm">
	<flx-select-list
		[heading]="heading"
		[subheading]="subheading"
		formControlName="nextApprovalStaffName"
		[options$]="authorisers$"
		[canFilter]="canFilter"
		[maxWidth]="maxWidth"
	></flx-select-list>
	<div *ngIf="reason" [ngStyle]="{ 'max-width': maxWidth, margin: 'auto' }">
		<flx-heading align="left" size="extra-small">Please enter a reason</flx-heading>
		<flx-textarea class="center-me" [width]="maxWidth" formControlName="reason" id="what_matters" [rows]="3"> </flx-textarea>
	</div>
</div>
