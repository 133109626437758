import { Component, OnInit, Input, HostBinding, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DynamicFormInputs, SelectConfig, DynamicFormData } from './models';
import { BigFormService } from '@flexus/core';
import { take, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
	selector: 'flx-flat-dynamic-form',
	templateUrl: './flat-dynamic-form.component.html',
	styleUrls: ['./flat-dynamic-form.component.scss']
})
export class FLXFlatDynamicFormComponent implements OnInit, OnDestroy {
	@Input() formControls: DynamicFormInputs;
	@Input() formLayout = 'flex'; // Default layout
	@Input() heading: string = null;
	@Input() subheading: string = null;
	@Input() selectOptions$: Observable<any>;
	@Input() containerWidth = 'auto';
	@Input() maxWidth = 'auto';
	@Input() centered = false;
	@Input() headingSize = 'default';
	@Input() headingWeight = 'normal';
	@Input() headingType = 'page';
	@Input() headingMargin = '0 0 0 0';

	@HostBinding('style.height') get height() {
		return this.centered ? '100%' : null;
	}

	@HostBinding('style.alignItems') get alignItems() {
		return this.centered ? 'center' : null;
	}

	inputForm: UntypedFormGroup = new UntypedFormGroup({});

	widthSize = 'auto'; // Default input width so 2 inputs go next to each other with a bit of space between
	isFormValid = false;

	constructor(private fb: UntypedFormBuilder, public bf: BigFormService) {}

	ngOnInit() {
		Object.values(this.formControls).forEach((control: DynamicFormData) => {
			this.bf.bigForm.addControl(control.formControlName, new UntypedFormControl(control.defaultValue ? control.defaultValue : '', control.validators ?? []));
		});
	}

	labelExcludeType(control: string): boolean {
		switch (control) {
			// exclude the above label when working with these inputs
			case 'checkbox':
			case 'radio':
				return true;
			default:
				return false;
		}
	}

	getSelectionOptions(selectConfig: SelectConfig): any[] {
		if (Array.isArray(selectConfig.itemsOption)) {
			return selectConfig.itemsOption;
		} else {
			let temp = [];
			this.selectOptions$.pipe(take(1), pluck(selectConfig.itemsOption)).subscribe(c => {
				if (c !== undefined) {
					temp = c;
				}
			});
			return temp;
		}
	}

	ngOnDestroy() {}

	selected(e: string, conditionalForm?) {
		let clonedFormControls;
		if (e === conditionalForm?.['onControlSelectedValue']) {
			clonedFormControls = { ...JSON.parse(JSON.stringify(this.formControls)), [conditionalForm.formControlName]: conditionalForm };
			this.bf.bigForm.addControl(
				conditionalForm.formControlName,
				new UntypedFormControl(conditionalForm.defaultValue ? conditionalForm.defaultValue : '', conditionalForm.validators ?? [])
			);
			this.formControls = clonedFormControls;
		} else if (conditionalForm && this.formControls[conditionalForm.formControlName]) {
			clonedFormControls = JSON.parse(JSON.stringify(this.formControls));
			const { [conditionalForm.formControlName]: removed, ...rest } = clonedFormControls;
			this.bf.bigForm.removeControl(conditionalForm.formControlName);
			this.formControls = rest;
		}
	}
}

/* ------------------------- Config Layout Example--------------------------- */
// assigning it a number allows you to specify the order
/*export const dynamicFormDefaultConfig: DynamicFormConfig = {
  formControls: {
    0: { label: 'What did we do well?', inputType: 'textarea', width: '48%', formControlName: 'how_we_did' },
    1: { label: 'What can we improve on?', inputType: 'textarea', width: '48%', formControlName: 'how_can_do' },
    2: {
        label: 'Select tester',
        inputType: 'select',
        formControlName: 'test',
        selectConfig: {
          itemsOption: [{ display: 'hi', value: '1' }, { display: 'bye', value: '2' }, { display: 'lie', value: '3' }], // hard coded options
          displayOptions: { displayKey: 'display', valueKey: 'value' },
          searchEnabled: false,
        },
      },
      3: {
        label: 'Select tester 2',
        inputType: 'select',
        formControlName: 'test2',
        selectConfig: {
          itemsOption: 'TestSelectDropdown', // Call this from the store and populate it is data
          displayOptions: { displayKey: 'name', valueKey: 'id' },
          searchEnabled: false,
        },
      },
  },
  formLayout: 'flex',
};*/

/* ------------------------- Calling the component --------------------------- */
// <flx-flat-dynamic-form [config]="config"></flx-flat-dynamic-form>
