<div class="flat-dynamic-form-container" [style.maxWidth]="containerWidth">
	<flx-heading *ngIf="heading !== null" align="center" [size]="headingSize" [weight]="headingWeight" [type]="headingType" [margin]="headingMargin"> {{ heading }}</flx-heading>
	<flx-heading *ngIf="subheading !== null" align="center" size="small"> {{ subheading }}</flx-heading>
	<form class="form" [formGroup]="bf.bigForm">
		<div [ngClass]="formLayout">
			<ng-container *ngFor="let item of formControls | keyvalue">
				<flx-form-field
					class="dynamic-inputs"
					[ngStyle]="{
						maxWidth: item?.value?.maxWidth != null ? item?.value?.maxWidth : widthSize
					}"
				>
					<flx-label class="form-label" *ngIf="!labelExcludeType(item?.value?.inputType) && item?.value?.label">{{ item?.value?.label }}</flx-label>
					<!-- switch to choose which component to generate -->
					<ng-container [ngSwitch]="item?.value?.inputType">
						<flx-textarea
							*ngSwitchCase="'textarea'"
							[formControlName]="item?.value?.formControlName"
							[rows]="item?.value?.rows"
							[placeholder]="item?.value?.placeholder"
						></flx-textarea>
						<!-- <flx-radio *ngSwitchCase="'radio'" class="form-input" [formControlName]="item?.value?.formControlName">{{ item?.value?.label }}</flx-radio> -->
						<flx-input-text *ngSwitchCase="'input'" [formControlName]="item?.value?.formControlName" [isDisabled]="item?.value?.disabled"> </flx-input-text>
						<flx-input-text *ngSwitchCase="'currency'" type="currency" [decimal]="2" [formControlName]="item?.value?.formControlName" [isDisabled]="item?.value?.disabled">
						</flx-input-text>

						<input
							*ngSwitchCase="'date'"
							[formControlName]="item?.value?.formControlName"
							[disabled]="item?.value?.disabled"
							[flxDatepicker]="myDatePicker"
							[max]="item?.value?.maxDate"
							[autocomplete]="item?.value?.autocomplete"
						/>
						<br />
						<flx-datepicker #myDatePicker></flx-datepicker>

						<ng-container *ngSwitchCase="'select'">
							<ng-container *ngIf="item?.value?.selectConfig as select">
								<flx-dropdown
									[formControlName]="item?.value?.formControlName"
									[itemsOption]="getSelectionOptions(select)"
									[displayOptions]="select.displayOptions"
									[multiple]="select.multiple"
									[autoOpen]="select.autoOpen"
									[isDisabled]="select.disabled"
									[form]="select.form"
									[placeHolder]="select.placeHolder"
									[searchEnabled]="select.searchEnabled"
									[id]="select.id"
									[upperItemCountLimit]="select.upperItemCountLimit"
									(itemsSelected)="selected($event, select.addConditionalForm)"
								></flx-dropdown>
							</ng-container>
						</ng-container>
						<flx-checkbox
							*ngSwitchCase="'checkbox'"
							[checked]="bf.bigForm.get(item?.value?.formControlName)?.value"
							[formControlName]="item?.value?.formControlName"
							[disabled]="item?.value?.disabled || bf.bigForm.get(item?.value?.formControlName)?.disabled"
						>
							{{ item?.value?.label }}
						</flx-checkbox>

						<flx-reactive-form-error-display [control]="$any(bf)?.bigForm.controls[item?.value?.formControlName]"> </flx-reactive-form-error-display>
						<p *ngSwitchDefault>Input type not chosen</p>
					</ng-container>
				</flx-form-field>
			</ng-container>
		</div>
	</form>
</div>
