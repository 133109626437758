import { Manifest_0_0_2, getCurrentUser } from '@flexus/core';
import { BetSpSearch } from './configs/search/bet-sp-search';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { FLXAppMenuComponent } from '../../app-shell-features/app-menu/app-menu.component';

// Orgs
import * as BetSPStates from './configs/flows';
import { SP_GLOBAL_STATES } from '../../sp_globals/configs/flows';
import { SpClaimWorkflow } from '../../sp_globals/configs/workflow/sp-claim-workflow';
import { jobPermissions } from '../../sp_globals/configs/permissions/job.permissions';
import { applySilVirtualStates } from '../../sp_globals/sp-virtual-state.helper';
import { environment } from '../../../environments/environment';

export const bet_sp: Manifest_0_0_2 = {
	version: '0.0.2',
	orgKey: environment.client,
	settings: {
		addActionPanel: true,
		addAppBar: true,
		addAppMenu: true,
		addFooter: true,
		addHeaderActions: true,
		addSearchBanner: true,
		addReminder: true
	},
	appMenu: (instance: FLXAppMenuComponent, store: Store<any>) => {
		return store.select(getCurrentUser).pipe(
			filter(user => !!user),
			map(res => {
				const createRoles = [1, 6];
				const menuItems = [{ id: 1, name: 'Workflow', routerLink: '/workflow', iconType: 'app-menu-workflow' }];

				if (res?.user.roles.some(role => createRoles.includes(role))) {
					menuItems.push({
						id: 2,
						name: 'New Claim',
						routerLink: '/workflow/detail',
						iconType: 'app-menu-new-claim'
					});
				}

				return menuItems;
			})
		);
	},
	virtualStatesFunction: applySilVirtualStates,
	appSearch: BetSpSearch,
	permissions: {
		itemTwo: jobPermissions
	},
	manifestItems: {
		...SP_GLOBAL_STATES,
		86: BetSPStates.BET_SP_86,
		91: BetSPStates.BET_SP_91,
		211: BetSPStates.BET_SP_211,
		289: BetSPStates.BET_SP_289,
		291: BetSPStates.BET_SP_291,
		293: BetSPStates.BET_SP_293,
		317: BetSPStates.BET_SP_317,
		workflow: SpClaimWorkflow
	}
};
/*
	organizations: {
		sil: {
			manifestItems: {
				...pickFromStates(sil.manifestItems, [27, 21]),
				...cheripickFromStates(sil.manifestItems, [14, 15], ['header', 'instructions']),
				...cheripickFromStates(sil.manifestItems, [24, 25], ['footer'])
			}
		},
		bet_sp: {
			manifestItems: {
				...pickFromStates(sil.manifestItems, [27, 21]),
				...cheripickFromStates(sil.manifestItems, [14, 15], ['header', 'instructions']),
				...cheripickFromStates(sil.manifestItems, [24, 25], ['footer'])
			}
		}
	}
*/
