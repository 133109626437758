import { EventEmitter, Inject, Input, NgZone, Optional, Output, TemplateRef, ViewChild, ViewContainerRef, Directive } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { merge } from 'rxjs';
import { ESCAPE } from '@angular/cdk/keycodes';
import { filter, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

/**
 * This class is a helper class for all components
 * Using touch, overlays and dialogs
 */

@Directive()
export class UiBaseComponent {
	protected _touchUi = false;

	@Input()
	get touchUi(): boolean {
		return this._touchUi;
	}

	set touchUi(value: boolean) {
		this._touchUi = coerceBooleanProperty(value);
	}

	protected _disabled: boolean;
	@Input()
	set disabled(value: any) {
		this._disabled = coerceBooleanProperty(value);
	}
	get disabled() {
		return this._disabled !== undefined ? this._disabled : false;
	}

	protected _opened = false;
	@Input()
	get opened(): boolean {
		return this._opened;
	}

	set opened(shouldOpen: boolean) {
		shouldOpen ? this.open() : this.close();
	}

	// private _reposition: false;

	protected _popupRef: OverlayRef;

	/** A reference to the dialog when the menu is opened as a dialog. */
	// protected _dialogRef: DialogRef<any> | null;

	/** The element that was focused before the image was opened. */
	protected _focusedElementBeforeOpen: HTMLElement | null = null;

	@Output('opened') openedStream: EventEmitter<void> = new EventEmitter<void>();

	/** Emits when the datepicker has been closed. */
	@Output('closed') closedStream: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('portal', { static: true }) portalTemplateRef: TemplateRef<any>;

	constructor(
		@Optional()
		@Inject(DOCUMENT)
		public _document: any,
		public _viewContainerRef: ViewContainerRef,
		// public _dialog: Dialog,
		public _overlay: Overlay,
		public _ngZone: NgZone,
		protected _scrollStrategy,
		protected _reposition = false,
		protected _popupClass = ''
	) {}

	open(): void {
		if (this.disabled === true) {
			return;
		}

		if (this._opened) {
			return;
		}

		if (this._document) {
			this._focusedElementBeforeOpen = this._document.activeElement;
		}

		this.touchUi ? this._openAsDialog() : this._openAsPopup();
		this._opened = true;
		this.openedStream.emit();
	}

	close(): void {
		if (!this._opened) {
			return;
		}

		if (this._popupRef && this._popupRef.hasAttached()) {
			this._popupRef.detach();
		}

		// if (this._dialogRef) {
		//   this._dialogRef.close();
		//   this._dialogRef = null;
		// }

		const completeClose = () => {
			// The `_opened` could've been reset already if
			// we got two events in quick succession.
			if (this._opened) {
				this._opened = false;
				this.closedStream.emit();
				this._focusedElementBeforeOpen = null;
			}
		};

		if (this._focusedElementBeforeOpen && typeof this._focusedElementBeforeOpen.focus === 'function') {
			// Because IE moves focus asynchronously, we can't count on it being restored before we've
			// marked the datepicker as closed. If the event fires out of sequence and the element that
			// we're refocusing opens the datepicker on focus, the user could be stuck with not being
			// able to close the calendar at all. We work around it by making the logic, that marks
			// the datepicker as closed, async as well.
			this._focusedElementBeforeOpen.focus();
			setTimeout(completeClose);
		} else {
			completeClose();
		}
	}
	backDropClick() {
		return merge(this._popupRef.backdropClick(), this._popupRef.detachments(), this._popupRef.keydownEvents().pipe(filter(event => event.keyCode === ESCAPE)));
	}

	protected _openAsDialog(): void {
		// const potal = new
		//
		// this._dialogRef = this._dialog.open(this.portal, {
		//     viewContainerRef: this._viewContainerRef,
		//     panelClass: 'flx-context-menu-dialog',
		// });
		// this._dialogRef.afterClosed().subscribe(() => this.close());
	}

	protected _openAsPopup(): void {
		if (!this._popupRef) {
			this._createPopup();
		}

		if (!this._popupRef.hasAttached()) {
			this._popupRef.attach(this.portalTemplateRef);

			// Update the position once the menu has rendered.
			this._ngZone.onStable
				.asObservable()
				.pipe(take(1))
				.subscribe(() => {
					this._popupRef.updatePosition();
				});
		}
	}

	protected _createPopup(): void {
		const overlayConfig = new OverlayConfig({
			hasBackdrop: true,
			panelClass: this._popupClass ? ['flx-popup', this._popupClass] : 'flx-popup'
		});

		if (this._reposition) {
			overlayConfig.positionStrategy = this._createPopupPositionStrategy();
		}

		overlayConfig.backdropClass = 'flx-backdrop';

		if (this._popupClass) {
			overlayConfig.backdropClass = this._popupClass;
		}

		if (this._scrollStrategy) {
			overlayConfig.scrollStrategy = this._scrollStrategy();
		}

		this._popupRef = this._overlay.create(overlayConfig);

		merge(this._popupRef.backdropClick(), this._popupRef.detachments(), this._popupRef.keydownEvents().pipe(filter(event => event.keyCode === ESCAPE))).subscribe(() =>
			this.close()
		);
	}

	protected _createPopupPositionStrategy(): PositionStrategy {
		// const fallbackOffset = this._datepickerInput._getPopupFallbackOffset();
		// We will initialize the offset as zero for now.
		const fallbackOffset = 0;

		return this._overlay
			.position()
			.flexibleConnectedTo(this._viewContainerRef.element)
			.withPositions([
				{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
				{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: fallbackOffset },
				{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
				{ originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom', offsetY: fallbackOffset }
			]);
	}
}
