import { Flow_0_0_2, getAllInfo, getFullItemTwo } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';

import { UntypedFormControl } from '@angular/forms';

export const SP_GLOBAL_65: Flow_0_0_2 = {
	id: '65',
	name: 'work_changed_to_private_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},

	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Private work - No fee - SP Notice : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Private work - No fee - SP Notice';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Work changed'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		claimDetailKeyValues: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1)
					)
					.pipe(
						take(1),
						map(job => {
							const list = {
								'Claim Type': job?.claim?.type ?? '',
								Address: job?.claim?.address ?? ''
								// 'Replaced Components': '' // TODO add this
							};

							return [list];
						})
					);
			}
		},
		labelText: {
			errorMessage: 'Job closure warning could not be fetched',
			directCall: () => {
				return of('This job will now be closed on 4-Sure');
			}
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			initFormFields: bf => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(28));
			},
			component: {
				children: [
					{
						component: 'FLXKeyValueDisplayWithInstructionsComponent',
						inputs: {
							title: 'Work Changed to Private Job',
							instructions: ['The client has opted for you to take on the work as a private job.', 'Please invoice the client directly.'],
							itemMargin: '0 0 35px 0'

							// keyValueList$: 'keyValueList',
							// title$: 'title'
						}
					},
					{
						component: 'FLXLabelComponent',
						inputs: {
							text$: 'labelText',
							color: 'secondary'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Finalise Job',
					serverFirst: true,
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							serviceVariable: 'spService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		purpose: 'purpose',
		current_state: 'current_state',
		new_state: 'new_state'
	}
};
