import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { returnOrDefault } from '@flexus/utilities';
import { compareNumberToSize } from '../file_utils';
import { ERSingleFileSelectConfig, FileSizeUnit } from '../models';

@Component({
	selector: 'flx-expanded-multi-file-select',
	templateUrl: 'expanded-multi-file-select.component.html',
	styleUrls: ['expanded-multi-file-select.component.scss']
})
export class FLXExpandedMultiFileSelectComponent implements OnInit {
	// variables
	file_selector: any;
	dragOver = false;
	theFiles: any;
	private _config: ERSingleFileSelectConfig;

	@Output() outputData: EventEmitter<any> = new EventEmitter();

	@Input() set config(c: ERSingleFileSelectConfig) {
		if (!c.allowedFileTypes) {
			c.allowedFileTypes = ['pdf', 'image'];
		}
		if (!c.maxFileSize) {
			c.maxFileSize = { size: 10, unit: FileSizeUnit.MegaBytes };
		}
		// console.log(c);
		this._config = c;
	}
	get config() {
		return returnOrDefault(this._config, { allowedFileTypes: ['pdf', 'image'], maxFileSize: { size: 5, unit: FileSizeUnit.MegaBytes } });
	}

	constructor() {
		this.file_selector = document.getElementById('upload-button');
	}

	ngOnInit() {}

	checkFileAllowedTypes(f: File, allowedTypes: string[]): boolean {
		// console.log({ f });
		const type = f.type;
		for (const allowedType of allowedTypes) {
			if (new RegExp(allowedType).test(type) === true) {
				return true;
			}
		}
		return false;
	}
	getFilesFromInputSelection(e: Event): void {
		const selectedFiles = (e.target as HTMLInputElement)?.files;
		this.processFiles(selectedFiles);
	}
	getFilesFromDropEvent(e: any) {
		console.log('files dropped');
		e.preventDefault();
		if (e.dataTransfer.items) {
			console.log('data transfer');
			for (let i = 0; i < e.dataTransfer.items.length; i++) {
				if (e.dataTransfer.items[i].kind === 'file') {
					var file = e.dataTransfer.items[i].getAsFile();
					console.log('... file[' + i + '].name = ' + file.name);
				}
			}
		} else {
			for (let i = 0; i < e.dataTransfer.files.length; i++) {}
		}
		this.dragOver = false;
		const selected_Files = e.dataTransfer.files;
		this.processFiles(selected_Files);
	}

	processFiles(selectedfiles: any) {
		for (let i = 0; i < selectedfiles.length; i++) {
			const file: any = selectedfiles[i];
			if (!!file) {
				if (this.checkFileAllowedTypes(file.size, this.config.allowedFileTypes) === true) {
					if (compareNumberToSize(file.size, this.config.maxFileSize) > 0) {
						this.outputData.emit({
							type: 'fileRejected',
							file: file,
							reason: `Wrong size please upload a file smaller than ${this.config.maxFileSize.size + this.config.maxFileSize.unit}`
						});
					} else {
						this.outputData.emit({ type: 'fileAdded', file: file });
					}
				} else {
					this.outputData.emit({ type: 'fileRejected', file: file, reason: `Wrong type please upload ${this.config.allowedFileTypes?.join()}` });
				}
			}
			this.outputData.emit({ type: 'fileAdded', file: selectedfiles[i] });
		}
	}
}
