import { Flow_0_0_2, getAllInfo, getSelectedItemOne, getCurrentUser, getAllInfoAdditional } from '@flexus/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import gql from 'graphql-tag';
import { take, map, tap, withLatestFrom, switchMap, debounceTime, pluck, skipWhile } from 'rxjs/operators';
import { flatten, values } from 'ramda';
import moment from 'moment';
import { of, from, Observable, empty, forkJoin } from 'rxjs';
import { LocationPickerResult } from '@flexus/ui-elements';
import { CustomValidators } from '@flexus/utilities';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { transformJobsToJobCardRequest, transformJobsToJobCardRequestWithJob } from './transform.functions';
import { Store } from '@ngrx/store';

export const AmpCreateClaim: Flow_0_0_2 = {
	id: 'create_item_one',
	name: 'new incident',
	startNode: 'PlanLookup',
  serverCalls: {
		provinceList: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return sq
					.queryObject(
						gql`
							{
								allInfo {
									active_regions
								}
							}
						`,
						store
					)
					.pipe(
						take(1),
						map(res => {
							return (res as any)?.active_regions;
						})
					);
			}
		}
	},
	deactivateStateGuard: (guardService: any, comp, router, currentRoute, currentState, nextState) => {
		return from(guardService.indexedDbService.currentItem.get('currentItem')).pipe(
			withLatestFrom(guardService.canGo$),
			switchMap(([currentItem, canGo]: any) => {
				return <Observable<any>>guardService.store.select(getSelectedItemOne).pipe(
					take(1),
					map((itemOne: any) => {
						if (!canGo && currentItem && currentItem.applicant && currentItem.loan_information) {
							if (itemOne && itemOne?.tempKey && guardService.bf.bigForm.touched) {
								// Local draft has been edited
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									inst.setMessage(['You are moving away from this local draft.', ' Your changes will be discarded!']);
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Save Changes Locally',
											color: 'primary',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												const localDraft = {
													...currentItem,
													tempKey: itemOne?.tempKey,
													state: 169
												};
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.claimInDraft.put(localDraft).then(() => {
														guardService.indexedDbService.currentItem.delete('currentItem');
													});
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (itemOne && itemOne?.tempKey && !guardService.bf.bigForm.touched) {
								// Local draft opened but not edited yet
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this local draft.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (itemOne && !itemOne.tempKey && !guardService.bf.bigForm.touched) {
								// Any Server Draft not edited
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from your current view.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (itemOne && !itemOne.tempKey && guardService.bf.bigForm.touched) {
								// Any server draft that is edited
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this draft.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne && !guardService.bf.bigForm.touched) {
								// Creating New Incident but forms has not been touched yet
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving from your current view.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne && guardService.bf.bigForm.touched) {
								// In Claim creation and form has been touched
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									// inst.message = '';
									inst.setMessage(['You are moving from your current view.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							}
						} else {
							return true;
						}
					})
				);
			})
		);
	},
	activateStateGuard: () => {
		return of(true);
	},
	onStateInit: instance => {
		const paramFunc = (inst, mapper) => {
			return {
				id: '1',
				icon: 'what-matters',
				command: inst.loadNodeComponent.bind(inst, {
					component: 'FLXWhatMattersComponent',
					inputs: {
						datePickerTitle: 'Date of incident',
						additionalTextArea: {
							width: '280px',
							formControlName: 'incident_details',
							title: 'Description of Incident'
						}
					}
				})
			};
		};
		instance.navService.portalActions.next({ call: 'onClick', paramFunc });
	},
	onStateDestroy: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['what-matters']),
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	header: {
		title: 'New Incident',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	events: {},
	nodes: {
		PlanLookup: {
			name: 'Plan Lookup',
			showTabs: true,
			errorHandler: {
				retryPolicy: 'manual',
				displayFormat: 'inline',
				onRetryComplete: () => {
					return empty();
				}
			},
			initFormFields: (bf, item, inst, storeQuery, store) => {
				//Init values for submit check
				bf.bigForm.addControl('checked_plan_details', new UntypedFormControl(null, Validators.required));
				bf.bigForm.addControl('checked_address_confirmation', new UntypedFormControl(null, Validators.required));
				bf.bigForm.addControl('checked_appliance_details', new UntypedFormControl(null, Validators.required));
				bf.bigForm.addControl('checked_appointments', new UntypedFormControl(null, Validators.required));
				bf.bigForm.addControl('checked_fee', new UntypedFormControl(null, Validators.required));

				bf.bigForm.addControl('new_state', new UntypedFormControl(1));
				bf.bigForm.addControl('policy_lookup', new UntypedFormControl(''));
				store
					.select(getCurrentUser)
					.pipe(take(1))
					.subscribe(user => {
						const { full_name, email_address, contact_number } = user?.user;
						bf.bigForm.addControl('handler', new UntypedFormControl(full_name));
						bf.bigForm.addControl('handlercontact', new UntypedFormControl(contact_number));
						bf.bigForm.addControl('handleremail', new UntypedFormControl(email_address));
					});
			},
			checkValidityForFields: [],
			inputs: {
				title: 'Plan Lookup',
				lookupSelectedEvent: (lookupDetails: any) => {},
				isValidLookup: (store: Store<any>, lookupDetails: any) => {
					return lookupDetails.state === 1 || lookupDetails.state === 2;
				},
				getLookupValidStatus: (store: Store<any>, lookupDetails: any) => {
					let lookupStatus = '';
					if (lookupDetails) {
						store
							.select(getAllInfoAdditional)
							.pipe(
								take(1),
								skipWhile(allInfoAdditional => !allInfoAdditional),
								pluck('payload', 'policy_states')
							)
							.subscribe(policyStates => {
								const policyStatesFiltered = policyStates.filter(policyState => policyState.id === lookupDetails.state);
								if (policyStatesFiltered.length > 0) {
									lookupStatus = policyStatesFiltered[0]?.name;
								}
							});
					}
					return lookupStatus;
				},
				lookupName: 'Plan',
				nextNode: 'PlanDetails',
				lookupItemColumns: [{ lookupDetailsProperty: 'full_name' }, { lookupDetailsProperty: 'inceptionDate' }],
				lookupEndpoint: {
					dataKey: 'lookupDetails',
					serviceVariable: 'ampService',
					functionName: 'policyLookup',
					errorMessage: ' No plan found.',
					timeoutMilliseconds: 120000,
					loaderID: 'policy_lookup'
				}
			},
			hasLoader: false,
			component: 'AccountLookupComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: {
										datePickerTitle: 'Date of incident',
										additionalTextArea: {
											width: '280px',
											formControlName: 'incident_details',
											title: 'Description of Incident'
										}
									}
								})
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'PlanDetails' }
			]
		},
		PlanDetails: {
			name: 'Plan Details',
			showTabs: true,
			initFormFields: bf => {
				bf.bigForm.patchValue({ checked_plan_details: true });

				bf.bigForm.addControl(
					'client_details',
					new UntypedFormGroup({
						surname: new UntypedFormControl('', Validators.required),
						id_number: new UntypedFormControl('', Validators.required),
						initials: new UntypedFormControl('', Validators.required),
						plan_number: new UntypedFormControl('', Validators.required)
					})
				);
				bf.bigForm.addControl(
					'contact_details',
					new UntypedFormGroup({
						cell_number: new UntypedFormControl('', Validators.required),
						email: new UntypedFormControl('', [Validators.required]),
						preferred_com_method: new UntypedFormControl('', Validators.required)
					})
				);
				bf.bigForm.addControl(
					'insured_address',
					new UntypedFormGroup({
						complex: new UntypedFormControl(''),
						street_address: new UntypedFormControl(''),
						suburb: new UntypedFormControl('', Validators.required),
						city: new UntypedFormControl('')
					})
				);

				const lookupData = bf.bigForm?.value?.currentPolicyDetails;
				if (lookupData && bf.bigForm?.value?.client_details.plan_number !== bf.bigForm?.value?.policy_lookup) {
					bf.bigForm.setControl(
						'client_details',
						new UntypedFormGroup({
							surname: new UntypedFormControl(lookupData.policy_data.surname, Validators.required),
							id_number: new UntypedFormControl(lookupData.policy_data.id_number, Validators.required),
							initials: new UntypedFormControl(lookupData.policy_data.initials, Validators.required),
							plan_number: new UntypedFormControl(bf.bigForm?.value?.policy_lookup || '', Validators.required)
						})
					);
					bf.bigForm.setControl(
						'contact_details',
						new UntypedFormGroup({
							cell_number: new UntypedFormControl(lookupData.policy_data.cellphone, Validators.required),
							email: new UntypedFormControl('', [Validators.required]),
							preferred_com_method: new UntypedFormControl('', Validators.required)
						})
					);

					const addressArr = lookupData.policy_data.riskaddress?.split(';');
					const complexIndexAdjust = Number(addressArr.length === 6);
					const insuredAddressControls = {
						complex: complexIndexAdjust ? new UntypedFormControl(addressArr[0]) : new UntypedFormControl(''),
						street_address: new UntypedFormControl(addressArr[0 + complexIndexAdjust]),
						suburb: new UntypedFormControl(addressArr[1 + complexIndexAdjust], Validators.required),
						city: new UntypedFormControl(addressArr[2 + complexIndexAdjust])
					};

					bf.bigForm.setControl('insured_address', new UntypedFormGroup(insuredAddressControls));
				}
			},
			inputs: {
				title: 'Confirm Plan Details',
				formGroupItems: [
					{
						formGroupName: 'client_details',
						controls: [
							{ label: 'Initials', formControlName: 'initials', elementType: 'input', elementVars: {} },
							{ label: 'Surname', formControlName: 'surname', elementType: 'input', elementVars: {} },
							{ label: 'ID Number', formControlName: 'id_number', elementType: 'input', elementVars: {} },
							{
								label: 'Plan Number',
								formControlName: 'plan_number',
								elementType: 'input',
								elementVars: { noEndingSpace: true }
							}
						]
					},
					{
						formGroupName: 'contact_details',
						controls: [
							{
								label: 'Cell Number',
								formControlName: 'cell_number',
								elementType: 'input',
								elementVars: { type: 'tel' }
							},
							{ label: 'Email', formControlName: 'email', elementType: 'input', elementVars: { type: 'email' } },
							{
								label: 'Preferred Com Method',
								formControlName: 'preferred_com_method',
								elementType: 'dropdown',
								elementVars: {
									Placeholder: 'Select method',
									options: [
										{ value: 'Phone', display: 'Phone' },
										{ value: 'Sms', display: 'SMS' },
										{ value: 'Email', display: 'Email' },
										{ value: 'Other', display: 'Other' }
									]
								}
							}
						]
					},
					{
						formGroupName: 'insured_address',
						controls: [
							{
								label: 'Complex',
								formControlName: 'complex',
								elementType: 'input',
								elementVars: {}
							},
							{
								label: 'Street Address',
								formControlName: 'street_address',
								elementType: 'input',
								elementVars: {}
							},
							{ label: 'Suburb', formControlName: 'suburb', elementType: 'input', elementVars: {} },
							{ label: 'City', formControlName: 'city', elementType: 'input', elementVars: { noEndingSpace: true } }
						]
					}
				],
				sourceToFormMapper: {
					ADDITIONALEXCESS: 'additionalexcess',
					FIRSTNAME: 'client_details.initials',
					SURNAME: 'client_details.surname',
					IDNUMBER: 'client_details.id_number',
					POLICYNUMBER: 'client_details.plan_number',
					HOMETELNUMBER: 'contact_details.contact_number',
					CELLNUMBER: 'contact_details.cell_number',
					EMAIL: 'contact_details.email',
					LOCALFILE: 'local_file',
					ISJOINTACCOUNT: 'is_joint_account',
					CLIENTCODE: 'clientcode',
					PREFEREDCONTACT: 'contact_details.preferred_com_method',
					ADDRESS1: 'insured_address.street_address',
					ADDRESS2: 'insured_address.suburb',
					SUBURB: 'insured_address.city',
					SUBURBCODE: 'suburb_code'
				}
			},
			component: 'AccountDetailsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadNodeComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: {
										datePickerTitle: 'Date of incident',
										additionalTextArea: {
											width: '280px',
											formControlName: 'incident_details',
											title: 'Description of Incident'
										}
									}
								})
							};
						}
					}
				},
				{
					text: 'Continue',
					location: 'right',
					nextNode: 'AddressConfirmation',
					color: 'primary'
				}
			]
		},
		AddressConfirmation: {
			name: 'Maps',
			showTabs: true,
			initFormFields: (bf, item) => {
				bf.bigForm.patchValue({ checked_address_confirmation: true });
				let suburb_code, latitude, longitude, province, area_code;

				if (bf.bigForm.get('latitude')) {
					latitude = bf.bigForm.get('latitude')?.value;
					bf.bigForm.get('latitude')?.setValidators([Validators.required]);
				} else {
					bf.addControl('latitude', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('longitude')) {
					longitude = bf.bigForm.get('longitude')?.value;
					bf.bigForm.get('longitude')?.setValidators([Validators.required]);
				} else {
					bf.addControl('longitude', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('suburb_code')) {
					suburb_code = bf.bigForm.get('suburb_code')?.value;
					bf.bigForm.get('suburb_code')?.setValidators([Validators.required]);
				} else {
					bf.addControl('suburb_code', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('province')) {
					province = bf.bigForm.get('province')?.value;
					bf.bigForm.get('province')?.setValidators([Validators.required]);
				} else {
					bf.addControl('province', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('area_code')) {
					area_code = bf.bigForm.get('area_code')?.value;
					bf.bigForm.get('area_code')?.setValidators([Validators.required]);
				} else {
					bf.addControl('area_code', new UntypedFormControl(null, [Validators.required]));
				}

				if (!latitude || !longitude) {
					latitude = null;
					longitude = null;
				}

				bf.addControl('underwriter', new UntypedFormControl());
				if (bf.bigForm.get('client_details')) {
					bf.bigForm.patchValue({ underwriter: bf.bigForm.get('client_details')?.value?.plan_number });
				}
			},
			serverCalls: {
				provinceList: {
					errorMessage: '',
					directCall: (http, store) => {
						return store.select(getAllInfo).pipe(
							map((allInfo: any) => allInfo.active_regions),
							take(1),
							map(values),
							map<any, any>(flatten)
						);
					}
				},
				policyAddress: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											submissionData {
												loan_information {
													propertycomplex
													propertystreetaddress
													propertysuburb
													propertycity
													suburbcode
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(result => {
									const res = result as any;
									const addressArray = [];

									if (res?.propertycomplex) addressArray.push(res?.propertycomplex);
									if (res?.propertystreetaddress) addressArray.push(res?.propertystreetaddress);
									if (res?.propertysuburb) addressArray.push(res?.propertysuburb);
									if (res?.propertycity) addressArray.push(res?.propertycity);
									if (res?.suburbcode) addressArray.push(res?.suburbcode);

									return addressArray?.join(', ');
								})
							);
					}
				}
			},
			component: 'AddressConfirmationComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadNodeComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: {
										datePickerTitle: 'Date of incident',
										additionalTextArea: {
											width: '280px',
											formControlName: 'incident_details',
											title: 'Description of Incident'
										}
									}
								})
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'ClaimSelection' }
			]
		},

		// ===================== product-details =====================

		ClaimSelection: {
			name: 'Appliance Details',
			showTabs: true,
			serverCalls: {
				registeredProducts: {
					dataKey: 'registeredProducts',
					serviceVariable: 'service',
					functionName: 'getRegisteredAppliances',
					errorMessage: ' No registered appliances found',
					timeoutMilliseconds: 120000 // 2  minutes,
				}
			},
			initFormFields: bf => {
				bf.bigForm.patchValue({ checked_appliance_details: true });

				if (bf.bigForm?.value?.jobs && bf.bigForm?.value?.jobs.length > 0) {
					let control = bf.bigForm.get('selectedAppliance');
					if (!control) {
						bf.bigForm.addControl('selectedAppliance', new UntypedFormControl('', [Validators.required]));
						control = bf.bigForm.get('selectedAppliance');
					}
					control?.setValue([bf.bigForm?.value?.jobs[0]?.id]);
				}
			},
			inputs: {
				addProductEndpoint: {
					dataKey: 'productAdded',
					serviceVariable: 'ampService',
					functionName: 'addPlanItem',
					responseSlice: '',
					errorMessage: 'Error occurred adding your product',
					timeoutMilliseconds: 120000 // 2  minutes
				},
				getFilesEndpoint: {
					dataKey: 'photos',
					serviceVariable: 'ampService',
					functionName: 'getSelectedApplianceFiles',
					responseSlice: '',
					errorMessage: 'Error occurred while retrieving appliance images',
					timeoutMilliseconds: 120000 // 2  minutes
				}
			},
			component: 'AmpProductSelectionComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: {
										datePickerTitle: 'Date of incident',
										additionalTextArea: {
											width: '280px',
											formControlName: 'incident_details',
											title: 'Description of Incident'
										}
									}
								})
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'ClaimAppointments' }
			]
		},
		ClaimAppointments: {
			name: 'Appointments',
			initFormFields: (bf, item, inst, storeQuery, store) => {
				bf.bigForm.patchValue({ checked_appointments: true });

				bf.addControl('on_site_person', new UntypedFormControl('', [Validators.required, Validators.minLength(2)]));
				bf.addControl('on_site_notes', new UntypedFormControl(''));
				bf.addControl('on_site_contact', new UntypedFormControl('', [CustomValidators.contact_number, Validators.required]));
				bf.addControl('timepicker', new UntypedFormControl(''));
				bf.addControl('selectedDateType', new UntypedFormControl(''));
				bf.addControl('isSameAppointment', new UntypedFormControl(''));
				bf.addControl('appointments', new UntypedFormArray([]));
				const jobsControl = bf.bigForm.get('jobs') as UntypedFormArray;
				if (jobsControl) {
					forkJoin([store.select(getAllInfo).pipe(take(1)), store.select(getAllInfoAdditional).pipe(take(1))])
						.pipe(take(1))
						.subscribe(([allInfo, allInfoAdditional]: any[]) => {
							const item_type = allInfoAdditional.payload.item_types.filter(type => Number(type.id) === Number(jobsControl.value[0]?.item_type));
							const formControlObj = {
								...jobsControl.controls[0]['controls']
							};

							const skillsFiltered = allInfo.skills.filter(skill => skill?.name?.toLowerCase()?.includes(item_type[0]?.name.toLowerCase()));
							if (skillsFiltered.length > 0) {
								formControlObj.skill_id = new UntypedFormControl(skillsFiltered[0]?.id);
								formControlObj.skill = new UntypedFormControl(skillsFiltered[0]?.name);
							}

							let claimTypeIDControl = bf.bigForm.get('selectedDamageClassID');
							if (!claimTypeIDControl) {
								bf.bigForm.setControl('selectedDamageClassID', new UntypedFormControl({}));
								claimTypeIDControl = bf.bigForm.get('selectedDamageClassID');
							}
							let claimTypeControl = bf.bigForm.get('selectedDamageClass');
							if (!claimTypeControl) {
								bf.bigForm.setControl('selectedDamageClass', new UntypedFormControl({}));
								claimTypeControl = bf.bigForm.get('selectedDamageClass');
							}

							const groupsFiltered = [];
							allInfo.claim_types.forEach(claimType => {
								const _groupsFiltered = claimType.skill_groups.filter(skillGroup => skillGroup.skills.includes(skillsFiltered[0]?.id));
								if (_groupsFiltered.length > 0) {
									groupsFiltered.push(_groupsFiltered[0]);
									claimTypeIDControl?.setValue(claimType.id);
									claimTypeControl?.setValue(claimType.name);
								}
							});

							if (groupsFiltered.length > 0) {
								formControlObj.skillcatagory_id = new UntypedFormControl(groupsFiltered[0]?.id);
								formControlObj.skillcatagory = new UntypedFormControl(groupsFiltered[0]?.name);
							}

							jobsControl.setControl(0, new UntypedFormGroup(formControlObj));
						});
				}
			},
			showTabs: true,
			inputs: {},
			serverCalls: {
				appointmentTypes: {
					errorMessage: '',
					directCall: (http, store) => {
						return store.select(getAllInfo).pipe(
							map((allInfo: any) => allInfo.appointment_types),
							take(1),
							map(values),
							map<any, any>(flatten)
						);
					}
				}
			},
			component: 'ClaimAppointmentsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadNodeComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: {
										datePickerTitle: 'Date of incident',
										additionalTextArea: {
											width: '280px',
											formControlName: 'incident_details',
											title: 'Description of Incident'
										}
									}
								})
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'ClaimPaymentDetails' }
			]
		},
		ClaimPaymentDetails: {
			name: 'Incident Fee',
			initFormFields: bf => {
				bf.bigForm.patchValue({ checked_fee: true });

				bf.addControl(
					'policy_excess',
					new UntypedFormGroup({
						is_cancelled: new UntypedFormControl(false),
						excess_description: new UntypedFormControl('Standard Incident'),
						amount: new UntypedFormControl({ value: '100', disabled: false }),
						who_collects: new UntypedFormControl({ value: null, disabled: false }),
						who_collects_description: new UntypedFormControl(''),
						payment_method: new UntypedFormControl({ value: null, disabled: false }),
						payment_method_description: new UntypedFormControl(''),
						assessorWaived: new UntypedFormControl(false)
					})
				);
				bf.addControl('jobs', new UntypedFormArray([]));
			},
			showTabs: true,
			inputs: {
				title: 'Plan Incident fee',
				showAdditionalExcess: false,
				showJobExcess: false,
				mandatoryTitle: 'Mandatory Incident Fee'
			},
			serverCalls: {
				serviceProviderList: {
					errorMessage: '',
					directCall: (http, store) => {
						return store.select(getAllInfo).pipe(map((allInfo: any) => allInfo.excess_who));
					}
				},
				paymentMethodList: {
					errorMessage: '',
					directCall: (http, store) => {
						return store.select(getAllInfo).pipe(map((allInfo: any) => allInfo.excess_how));
					}
				}
			},
			component: 'ClaimPaymentDetailsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadNodeComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: {
										datePickerTitle: 'Date of incident',
										additionalTextArea: {
											width: '280px',
											formControlName: 'incident_details',
											title: 'Description of Incident'
										}
									}
								})
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'ClaimHistory' }
			]
		},
		ClaimHistory: {
			name: 'History',
			showTabs: true,
			serverCalls: {
				claimHistory: {
					dataKey: 'claimHistory',
					serviceVariable: 'service',
					isBackgroundTask: true,
					functionName: 'policyHistorySearch',
					responseSlice: 'payload.claims',
					errorMessage: 'No history could be found!'
				}
			},
			initFormFields: (bf, item) => {
				bf.bigForm.addControl('claim_history', new UntypedFormControl(''));
			},
			inputs: {
				title: 'Review Previous Incident History'
			},
			component: 'ClaimHistoryComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadNodeComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: {
										datePickerTitle: 'Date of incident',
										additionalTextArea: {
											width: '280px',
											formControlName: 'incident_details',
											title: 'Description of Incident'
										}
									}
								})
							};
						}
					}
				},
				{
					text: 'Continue',
					color: 'primary',
					nextNode: 'ClaimSubmissionSummary'
				}
			]
		},
		ClaimSubmissionSummary: {
			name: 'Submit',
			showTabs: true,
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Submit Incident To Proceed',
							formControls: {},
							formLayout: 'stacked',
							width: '200px',
							containerWidth: '340px',
							headingSize: 'medium',
							headingWeight: 'extra-light',
							headingType: 'creation'
						}
					},
					{
						component: 'FLXAlertMessagesComponent',
						inputs: { alertMessages$: 'alertMessages' }
					}
				]
			},
			serverCalls: {
				alertMessages: {
					errorMessage: 'Could not retrieve the form errors',
					directCall: (http, store, sq, bf) => {
						bf.bigForm?.updateValueAndValidity();
						return bf.bigForm?.valueChanges?.pipe(
							debounceTime(800),
							switchMap(() => {
								return bf.retrieveErrors$();
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: instance => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadNodeComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: {
										datePickerTitle: 'Date of incident',
										additionalTextArea: {
											width: '280px',
											formControlName: 'incident_details',
											title: 'Description of Incident'
										}
									}
								})
							};
						}
					}
				},
				{
					text: 'Save as Draft',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						saveAsServerDraft: {
							errorMessage: 'Could not save the draft!',
							serviceVariable: 'ampService',
							functionName: 'checkNetworkAndSaveDraft',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable incident!',
									serviceVariable: 'ampService',
									functionName: 'deleteCurrentEditableItem'
								}
							}
						}
					}
				},
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'This incident could not be created!',
							serviceVariable: 'ampService',
							functionName: 'createClaim',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable incident!',
									serviceVariable: 'ampService',
									functionName: 'deleteCurrentEditableItem'
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			serverCalls: {
				copyText: {
					errorMessage: 'Could not retrieve new incident number!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											response {
												payload {
													mid
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								take(1),
								map(values),
								map<any, any>(flatten),
								switchMap((val: any) => {
									if (!!val) return val;
									else return null;
								}),
								tap(() => {
									store.dispatch(new CollapseActionPanel());
								})
							);
					}
				}
			},
			inputs: {
				copyTextHeading: 'Incident Number',
				autoClose: false
			},
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: [
			(state, storeObj, formValue, bf) => {
				if (bf.bigForm?.value?.upfrontrepudiation) {
					return 19;
				} else {
					return state;
				}
			},
			'new_state'
		],

		// General
		handler: 'loan_information.handler',
		handlercontact: 'loan_information.handlercontact',
		handleremail: 'loan_information.handleremail',

		policy_lookup: 'applicant.claimantpoliceynum',

		// Policy Details
		'client_details.initials': ['applicant.first_name', 'name'],
		'client_details.surname': ['applicant.surname', 'surname'],
		'client_details.id_number': ['applicant.id_number'],
		'client_details.plan_number': ['policy_id', 'loan_information.policy_number', 'applicant.claimantpoliceynum'],
		'contact_details.cell_number': ['loan_information.cellnumber', 'loan_information.contactnumber'],
		'contact_details.email': ['loan_information.email'],
		'contact_details.preferred_com_method': 'loan_information.preferedcommethod',
		is_joint_account: ['loan_information.isjointaccount'],
		underwriter: ['loan_information.underwriter'],

		// Address confirmation
		latitude: ['location.lat', 'loan_information.claimlatitude'],
		longitude: ['location.long', 'loan_information.claimlongitude'],
		suburb_code: ['postal_code', 'loan_information.suburbcode'],
		province: ['claimprovince'],
		'insured_address.complex': ['loan_information.propertycomplex'],
		'insured_address.street_address': ['address', 'loan_information.propertystreetaddress'],
		'insured_address.suburb': ['suburb', 'loan_information.propertysuburb'],
		'insured_address.city': ['loan_information.propertycity'],
		addressconfirmation: 'loan_information.addressconfirmation',
		area_code: ['area_id', 'loan_information.areacode'],

		// Claim details
		selectedDamageClassID: ['claim_type_id', 'loan_information.claimtype_id'],
		selectedDamageClass: ['loan_information.claimtype'],
		jobs: [
			[transformJobsToJobCardRequest, 'jobcardrequest'],
			[transformJobsToJobCardRequestWithJob, 'loan_information.jobcardrequest']
		],

		// Claim Appointment
		on_site_contact: 'loan_information.onsitecontact',
		on_site_notes: 'loan_information.onsitenotes',
		on_site_person: 'loan_information.onsiteperson',

		// What Matters Action Panel
		date_of_loss: [date => moment(date)?.format('YYYY-MM-DD'), 'loan_information.dateofloss'],
		whatmatters: 'loan_information.whatmatters',
		incident_details: 'loan_information.incident_details',
		claimdescription: [
			(claimdescription, storeObj, formValues) => {
				const handler = storeObj.identity.currentUser.id;
				return 'Date: ' + moment()?.format('YYYY-MM-DD') + ' \nStaff: ' + handler + ' \n' + formValues.whatmatters + '.';
			},
			'loan_information.claimdescription'
		]
	},
	flowErrorMessages: {
		'checked_plan_details:required': 'Please fill in the Plan Details',
		'checked_address_confirmation:required': 'Please confirm the address on the Map.',
		'checked_appliance_details:required': 'Please fill in the Appliance Details.',
		'checked_appointments:required': 'Please set an Appointment.',
		'checked_fee:required': 'Please fill in the Incident Fee.',
		'who_collects:required': 'Specify who collects the incident fee',
		'payment_method:required': 'Specify payment method',
		'selectedDamageClass:required': 'Damage type is required (Damage Details)',
		'jobs:required': 'At least one damaged product is needed (Product Details)',
		'appointmentDatePicker:flxDatepickerMin': 'Job appointment date has to be from today',
		'street_address:required': 'Property street address is required (in Plan Details)',
		'suburb:required': 'Property suburb is required (in Plan Details)',
		'email:required': 'Email address is required (in Plan Details)',
		'email:email': 'You need to enter a valid email address (in Plan Details)',
		'cell_number:cellphone': 'Cell number is invalid (in Plan Details)',
		'cell_number:required': 'Cell number is required (in Plan Details)',
		'contact_number:contact_number': 'Contact number is invalid (in Plan Details)',
		'contact_number:required': 'Contact number is required (in Plan Details)',
		'preferred_com_method:required': 'Communication method is required (in Plan Details)',
		'addressconfirmation:required': 'Please confirm property address',
		'longitude:required': 'No address longitude has been picked up (in Maps)',
		'latitude:required': 'No address latitude has been picked up (in Maps)',
		'date_of_loss:required': 'No date of incident has been selected (on your right Action Panel)',
		'whatmatters:required': 'What Matters has not been completed (on your right Action Panel)',
		'incident_details:required': 'Description of Incident has not been completed (on your right Action Panel)',
		'initials:required': 'Applicant initial is required (in Plan Details)',
		'surname:required': 'Applicant surname is required (in Plan Details)',
		'id_number:required': 'Applicant ID number is required (in Plan Details)',
		'initials:text': 'Applicant initial has additional characters (in Plan Details)',
		'appointmentDatePicker:required': 'Job appointment date is required (in Appointments)',
		'appointmentTimePicker:required': 'Job appointment time is required (in Appointments)',
		'on_site_contact:required': 'On site contact number required (in Appointments)',
		'on_site_person:required': 'On site person name is required (in Appointments)'
	}
};
