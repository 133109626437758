import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FLXDecoderIssueFixerComponent } from './decoder-issue-fixer/decoder-issue-fixer.component';
import { FLXStaffIssueFixerComponent } from './staff-issue-fixer/staff-issue-fixer.component';
import { FLXTeamleadLookupCompositeModule } from '../teamlead-lookup-composite';
import {
	FLXButtonsModule,
	FLXHeadingsModule,
	FLXSelectListModule,
	FLXIconModule,
	FLXFormsModule,
	FLXKeyValueListModule,
	FLXLoaderModule,
	FileHandlerModule
} from '@flexus/ui-elements';
import { FLXHeadingWithInstructionsModule } from '../heading-with-instructions';
import { FLXGlowLineModule, FLXYesNoModule } from '@flexus/ui-elements';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		FLXHeadingWithInstructionsModule,
		FLXButtonsModule,
		FLXYesNoModule,
		FLXGlowLineModule,
		FLXKeyValueListModule,
		FLXLoaderModule,
		FLXFormsModule,
		FLXTeamleadLookupCompositeModule,
		FLXIconModule,
		FLXSelectListModule,
		FLXHeadingsModule,
		FileHandlerModule
	],
	declarations: [FLXDecoderIssueFixerComponent, FLXStaffIssueFixerComponent],
	exports: [FLXDecoderIssueFixerComponent, FLXStaffIssueFixerComponent]
})
export class FLXDecoderInstallationJobFailCorrectorModule {}
