<div class="main-container">
	<div class="date-picker" *ngIf="isFilterConfigurationsType('datePicker') !== -1">
		<div class="event-container">
			<div class="calendarMonths">
				<flx-button [disabled]="previousMonthIsDisabled" (click)="previousMonth()">{{ '<<' }}</flx-button>
				<label> {{ selectedMonth }}</label>
				<flx-button [disabled]="nextMonthIsDisabled" (click)="nextMonth()">{{ '>>' }}</flx-button>
			</div>

			<div class="calendarMonths">
				<flx-button (click)="selectTodayDate()">Today</flx-button>
				<flx-button (click)="selectTomorrowDate()">Tomorrow</flx-button>
			</div>
			<div [formGroup]="setYearForm" class="calendarMonths">
				<flx-dropdown
					[multiple]="false"
					[itemsOption]="getYears()"
					backcolor="medium"
					formControlName="selectYear"
					[disabled]="false"
					[displayOptions]="displayOptions"
				></flx-dropdown>
			</div>
			<div>{{ currentSelectedDate }}</div>
		</div>
		<div class="container">
			<div class="calendarDayNames" *ngFor="let name of weekDaysName">{{ name }}</div>
			<div class="calendarContainer" *ngFor="let cal of calendar; let x = index" id="week_{{ x }}">
				<div class="calendarDay" *ngFor="let day of cal.days; let i = index" (click)="onDateClick(day)" id="day_{{ i }}">
					<span [ngStyle]="day.selected ? getActiveStyle() : getCurrentDayStyle(day)">{{ getMomentDay(day.calendarDate) }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="type-picker" *ngIf="isFilterConfigurationsType('typeOfTime') !== -1">
		<flx-button (click)="sendTimeType('Before')">Before</flx-button><flx-button (click)="sendTimeType('At')">At</flx-button
		><flx-button (click)="sendTimeType('After')">After</flx-button>
	</div>
	<div class="time-picker" *ngIf="isFilterConfigurationsType('timePicker') !== -1">
		<form [formGroup]="formGroup" *ngIf="formGroup"><flx-time-picker formControlName="appointment_time"></flx-time-picker></form>
	</div>
</div>
