import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as MCAutopayInvoicingActions from './mc-autopay-invoicing.actions';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { GetInvoicesService } from '../../../../services/get-invoices.service';
import { environment } from 'apps/studio/src/environments/environment';
import { ModalService } from '@flexus/core';

@Injectable()
export class MCAutopayInvoicingEffects {
	constructor(
		private actions$: Actions,
		private _http: HttpClient,
		private _store: Store,
		private _invoicing: GetInvoicesService,
		private _modal: ModalService
	) {}
	invoice_number: any;

	getInvoicesByDate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MCAutopayInvoicingActions.GET_INVOICES_BY_DATE),
			switchMap((action: any) => {
				const dates = action.payload;
				console.log('DATES', dates[0], dates[1]);
				const start_date = dates[0];
				const end_date = dates[1];
				return this._http.get(`${environment.api_url}v1/mc_actions/get_invoice_list?accreditation_number=00011651MC&start_date=${start_date}&end_date=${end_date}`);
			}),
			map((data: any) => {
				if (data.success) {
					this._invoicing.setUILoadingStatus(false);
					this._store.dispatch(new MCAutopayInvoicingActions.SetNumberedInvoiceNull(null));
					console.log('EFFECT DATA', data);
					// this._store.dispatch(new MCAutopayInvoicingActions.GetInvoicesLoadedState(true));
					return new MCAutopayInvoicingActions.GetInvoicesByDateSuccess(data);
				} else if (!data.success) {
					this._modal.openModalDirectly(instance => {
						instance.type = 'warning';
						instance.heading = 'Something went wrong';
						instance.setMessage([`not sure wat the error is`, 'You can try again.']);
						instance.navButtons = [
							{
								text: 'close',
								linkType: 'close'
							}
						];
					});
				}
			}),
			catchError(error => of(new MCAutopayInvoicingActions.GetInvoicesByDateFail(error)))
		)
	);

	getInvoiceByNumber$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MCAutopayInvoicingActions.GET_INVOICE_BY_NUMBER),
			switchMap((action: any) => {
				const invoice_number = action.payload;
				console.log('EFFECT INVOICE NUMBER', invoice_number);
				this._invoicing.setUILoadingStatus(true);
				return this._http.get(`${environment.api_url}v1/mc_actions/get_invoice?invoice_number=${invoice_number}`);
			}),
			map((data: any) => {
				if (data.success) {
					this._invoicing.setUILoadingStatus(false);
				} else if (!data.success) {
					this._modal.openModalDirectly(instance => {
						instance.type = 'warning';
						instance.heading = 'Something went wrong';
						instance.setMessage(['Unknown error', 'If you try again, please make sure you enter a correct invoice number.']);
						instance.navButtons = [
							{
								text: 'close',
								linkType: 'close'
							}
						];
					});
				}
				this._invoicing.setUILoadingStatus(false);
				return new MCAutopayInvoicingActions.GetInvoiceByNumberSuccess(data);
			}),
			catchError(error => {
				this._modal.openModalDirectly(instance => {
					instance.type = 'warning';
					instance.heading = 'Something went wrong';
					instance.message = `${error}`;
				});
				return of(new MCAutopayInvoicingActions.GetInvoiceByNumberFail(error));
			})
		)
	);
	downloadInvoice$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MCAutopayInvoicingActions.DOWNLOAD_INVOICE),
			switchMap((action: any) => {
				const invoice_number = action.payload;
				this.invoice_number = invoice_number;
				return this._http.get(`${environment.api_url}v1/mc_actions/get_invoice?invoice_number=${invoice_number}`);
			}),
			map((data: any) => {
				if (data.success) {
					console.log('download success');
					const base64BinaryString = data?.payload?.Content;
					const byteCharacters = atob(base64BinaryString);
					const byteNumbers = new Array(byteCharacters.length);
					for (let i = 0; i < byteCharacters.length; i++) {
						byteNumbers[i] = byteCharacters.charCodeAt(i);
					}
					const byteArray = new Uint8Array(byteNumbers);

					// Create a Blob from the byte array
					const blob = new Blob([byteArray], { type: 'application/pdf' });

					// Create a Blob URL for the Blob
					const blobUrl = URL.createObjectURL(blob);

					// Create a link in your HTML that points to the Blob URL
					const link = document.createElement('a');
					link.href = blobUrl;
					link.target = '_blank'; // Open in a new tab
					link.download = `INVOICE__${this.invoice_number}`; // Specify the filename for the downloaded PDF
					// Trigger a click event on the link to download the PDF
					link.click();
					// this._invoicing.setUILoadingStatus(false);
					return new MCAutopayInvoicingActions.DownloadInvoiceSuccess(data);
				} else if (!data.success) {
					this._modal.openModalDirectly;
				}
			}),
			catchError((error: any) => {
				return of(new MCAutopayInvoicingActions.DownloadInvoiceFail(error));
			})
		)
	);
	setNumberedInvoiceStatus$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MCAutopayInvoicingActions.SET_NUMBERED_INVOICE),
			switchMap((action: any) => {
				// return  new MCAutopayInvoicingActions.SetNumberedInvoiceNull(action)
				return of(action.payload);
			}),
			take(1),
			map((data: any) => {
				return new MCAutopayInvoicingActions.SetNumberedInvoiceNull(data);
			}),
			catchError((error: any) => {
				return of(new MCAutopayInvoicingActions.SetNumberedInvoiceNullFail(error));
			})
		)
	);
	downloadInvoiceSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MCAutopayInvoicingActions.DOWNLOAD_INVOICE_SUCCESS),
			switchMap((action: any) => {
				console.log('DOWNLOAD INVOICE SUCCESS EFFECCT');
				this._invoicing.setUILoadingStatus(false);

				return of(action.payload);
			}),
			take(1),
			map((data: any) => {
				return new MCAutopayInvoicingActions.PostDownloadInvoiceSuccess();
			}),
			catchError((error: any) => {
				return of(new MCAutopayInvoicingActions.DownloadInvoiceFail(error));
			})
		)
	);
}
