import { BigFormService, Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { Validators, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { skipWhile, take, map, filter, pluck } from 'rxjs/operators';
import { AddNewJobCard } from '../add-new-job-card/add-new-jobcard';
import moment from 'moment';
import { UUID } from 'angular2-uuid';
import { stringToBoolean, checkForValidDate, CustomValidators, generateRange } from '@flexus/utilities';
import { transformJobsToDeviceDetails, transformJobsToJobCardRequest } from './transform.functions';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { flatten, values } from 'ramda';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';

export const EditNewJobCard: Flow_0_0_2 = {
	// From create Claim
	...AddNewJobCard,

	id: 'edit_item_one',
	name: 'edit job card',
	header: {
		...AddNewJobCard.header,
		title: 'Schedule Installation Appointment'
	},
	serverCalls: {
		setjoblocationIfExists: {
			errorMessage: '',
			directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService) => {
				return _store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					map(result => {
						if (result?.claim?.loan_information?.claimlatitude) {
							_bf.getControl('latitude').setValue(+result?.claim?.loan_information?.claimlatitude);
						} else {
							_bf.getControl('latitude').setValue(null);
						}
						if (result?.claim?.loan_information?.claimlongitude) {
							_bf.getControl('longitude').setValue(+result?.claim?.loan_information?.claimlongitude);
						} else {
							_bf.getControl('longitude').setValue(null);
						}
					})
				);
			}
		}
	},
	valuesToBigForm: (bf, store) => {
		store
			.select(getFullItemTwo)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe(itemTwo => {
				const applicant = itemTwo?.claim?.applicant;
				const loan_information = itemTwo?.claim?.loan_information;
				const jobcardrequest = loan_information?.jobcardrequest;
				bf.bigForm.addControl('claim_type_id', new UntypedFormControl(40, [Validators.required]));
				bf.bigForm.addControl('sub_section_id', new UntypedFormControl(40, [Validators.required]));

				bf.addControl(
					'client_details',
					new UntypedFormGroup({
						clarity_customer_number: new UntypedFormControl((applicant && applicant?.information?.claimantpoliceynum) || '', [
							Validators.minLength(9),
							CustomValidators.hardMaxLength(9)
						]),
						surname: new UntypedFormControl((applicant && applicant?.surname) || '', [
							Validators.required,
							Validators.minLength(2),
							CustomValidators.hardMaxLength(140),
							CustomValidators.onlyAllowedASCII([32, 39, 44, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
						]),

						first_name: new UntypedFormControl((applicant && applicant?.first_name) || '', [
							Validators.required,
							Validators.minLength(2),
							CustomValidators.hardMaxLength(140),
							CustomValidators.onlyAllowedASCII([32, 39, 44, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
						])
					})
				);
				bf.addControl(
					'contact_details',
					new UntypedFormGroup({
						contact_number: new UntypedFormControl(loan_information?.contactnumber || '', [
							Validators.required,
							CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
							CustomValidators.contact_number,
							Validators.minLength(10),
							CustomValidators.hardMaxLength(12)
						]),
						cell_number: new UntypedFormControl(loan_information?.cellnumber || '', [
							Validators.required,
							CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
							CustomValidators.numeric,
							Validators.minLength(10),
							CustomValidators.hardMaxLength(12)
						]),
						email: new UntypedFormControl(loan_information?.email || '', [Validators.required, CustomValidators.onlyAllowedASCII(generateRange(32, 126, 1))])
					})
				);
				bf.addControl(
					'insured_address',
					new UntypedFormGroup({
						complex: new UntypedFormControl(loan_information?.propertycomplex || '', CustomValidators.hardMaxLength(64)),
						complex_unit_number: new UntypedFormControl(loan_information?.propertycomplexunitnumber || '', CustomValidators.hardMaxLength(64)),
						street_address: new UntypedFormControl(loan_information?.propertystreetaddress || '', [Validators.required, CustomValidators.hardMaxLength(64)]),
						suburb: new UntypedFormControl(loan_information?.propertysuburb || '', [Validators.required, CustomValidators.hardMaxLength(64)]),
						city: new UntypedFormControl(loan_information?.propertycity || '', [Validators.required, CustomValidators.hardMaxLength(64)])
					})
				);
				bf.bigForm.addControl('assign_teamleader_id', new UntypedFormControl(loan_information.tl_id || '', [Validators.required]));
				bf.addControl(
					'current_environment',
					new UntypedFormGroup({
						no_view_environments: new UntypedFormControl(loan_information?.current_environment?.no_view_environments?.description || '', [Validators.required]),
						dwelling_type: new UntypedFormControl(loan_information?.current_environment?.dwelling_type?.description || '', [Validators.required]),
						no_of_tvs: new UntypedFormControl(loan_information?.current_environment?.no_of_tvs?.description || '', [Validators.required]),
						type_of_tvs: new UntypedFormControl(loan_information?.current_environment?.type_of_tvs?.description || '', [Validators.required]),
						mounted_installation: new UntypedFormControl(loan_information?.current_environment?.mounted_installation?.description || '', [Validators.required])
					})
				);
				const createJob = (job?) => {
					if (job) {
						return bf.fb.group({
							id: UUID.UUID(),
							skillrequested: job?.skillrequested,
							skillcatagory_id: job?.skillcatagory_id,
							skillcatagory: job?.skillcatagory, // string name
							skill_id: job?.skill_id,
							skill: job?.skill, // string name
							to_purchase: job?.to_purchase,
							decoder_type: job?.decoder_type,
							providertype_id: job?.providertype_id,
							providertype: job?.providertype, // string name
							appointmentDatePicker: new Date(job.requestedappointmentdate),
							appointmentTimePicker: {
								hour: job?.requestedappointmenttime?.split(':')[0] ?? '',
								minutes: job?.requestedappointmenttime?.split(':')[1] ?? ''
							},
							appointmentTime: job?.appointment_type_id,
							appointmentDateType: job?.appointmentdatetype
						});
					} else {
						return bf.fb.group({
							id: UUID.UUID(),
							skillrequested: [1, [Validators.required]], // signal to the server to create job
							skillcatagory_id: [null, [Validators.required]],
							skillcatagory: null, // string name
							skill_id: [null, [Validators.required]],
							skill: null, // string name
							to_purchase: null,
							decoder_type: null,
							providertype_id: [null, [Validators.required]],
							providertype: null, // string name
							appointmentDatePicker: null,
							appointmentTimePicker: null,
							appointmentTime: null
						});
					}
				};

				const jobArray =
					jobcardrequest && jobcardrequest.length
						? jobcardrequest.map(job => {
								const theJob = createJob(job);
								return theJob;
						  })
						: [createJob()];

				if (typeof loan_information?.claimlatitude === 'undefined' || !loan_information.claimlatitude) {
					if (loan_information) loan_information.claimlatitude = undefined;
				}

				if (typeof loan_information?.claimlongitude === 'undefined' || !loan_information.claimlongitude) {
					if (loan_information) loan_information.claimlongitude = undefined;
				}

				// Form binding
				bf.addControl('jobs', new UntypedFormArray(jobArray));
				bf.addControl(
					'address_details',
					new UntypedFormControl({
						latitude: [+loan_information?.claimlatitude || undefined, Validators.required],
						longitude: [+loan_information?.claimlongitude || undefined, Validators.required]
					})
				);

				bf.patchValues({
					new_state: 325,
					checked_customer_details: true,
					checked_address_confirmation: true,
					checked_installation_details: true,
					checked_appointments: true,
					checked_current_setup: true,
					checked_team_lead: true,

					handler: loan_information?.handler || '',
					handlercontact: loan_information?.handlercontact || '',
					handleremail: loan_information?.handleremail || '',
					// Customer Details
					// Address confirmation
					clarity_customer_number: applicant?.claimantpoliceynum || '',
					suburb_code: loan_information?.suburbcode || '',
					addressconfirmation: loan_information?.addressconfirmation || '',
					area_code: loan_information?.areacode || '',

					// Claim details
					// claim_class: loan_information?.claimtype_id || '',
					// claim_class_description: loan_information?.claimtype || '',

					// Claim Appointment
					on_site_contact: loan_information?.onsitecontact || '',
					on_site_notes: loan_information?.onsitenotes || '',
					on_site_person: loan_information?.onsiteperson || '',
					// What Matters Action Panel
					date_of_loss: loan_information?.dateofloss ? checkForValidDate(loan_information.dateofloss) : '',
					whatmatters: loan_information?.whatmatters || '',
					claimdescription: loan_information?.claimdescription || ''
				});
			});
	},
	startNode: 'CustomerDetails',
	nodes: {
		CustomerDetails: {
			name: 'Customer Details',
			showTabs: true,
			component: 'CustomerDetailsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'AddressConfirmation'
				}
			]
			// ...AddNewJobCard?.nodes?.CustomerDetails
		},
		AddressConfirmation: {
			...AddNewJobCard?.nodes?.AddressConfirmation
		},
		InstallationJobCard: {
			...AddNewJobCard?.nodes?.InstallationJobCard
		},
		JobAppointment: {
			// ...AddNewJobCard?.nodes?.JobAppointment
			name: 'Appointment',
			initFormFields: bf => {
				bf.bigForm.patchValue({ checked_appointments: true });

				bf.addControl(
					'on_site_person',
					new UntypedFormControl('', [
						Validators.required,
						Validators.minLength(2),
						CustomValidators.hardMaxLength(140),
						CustomValidators.onlyAllowedASCII([32, 39, 44, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
					])
				);
				bf.addControl('on_site_notes', new UntypedFormControl('', [CustomValidators.onlyAllowedASCII([10, 13]?.concat(generateRange(32, 126, 1)))]));
				bf.addControl(
					'on_site_contact',
					new UntypedFormControl('', [
						Validators.required,
						CustomValidators.numeric,
						Validators.minLength(10),
						CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
						CustomValidators.hardMaxLength(12)
					])
				);
				bf.addControl('timepicker', new UntypedFormControl(''));
				bf.addControl('selectedDateType', new UntypedFormControl(''));
				bf.addControl('isSameAppointment', new UntypedFormControl(''));
				bf.addControl('appointments', new UntypedFormArray([]));
			},
			showTabs: true,
			inputs: {
				minHour: 0,
				maxHour: 23,
				checkAfterHours: false
			},
			serverCalls: {
				appointmentTypes: {
					errorMessage: '',
					directCall: (http: any, store: any, sq: any) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						// store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											appointment_types {
												id
												name
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile((f: any) => !f),
								take(1),
								map(values),
								map<any, any>(flatten)
							);
					}
				},
				appointmentData: {
					errorMessage: '',
					directCall: (http: any, store: any, sq: any) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											appointment_types {
												id
												name
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(values),
								map<any, any>(flatten)
							);
					}
				}
				// publicHolidays: {
				// 	serviceVariable: 'mulSpService',
				// 	functionName: 'getPublicHolidays',
				// 	responseSlice: 'payload',
				// 	errorMessage: 'Could not get public holidays!'
				// }
			},
			component: 'ClaimAppointmentsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'CurrentSetup'
				}
			]
		},
		CurrentSetup: {
			...AddNewJobCard?.nodes?.CurrentSetup
		},
		// AssignTeamLead: {
		// 	...AddNewJobCard?.nodes?.AssignTeamLead
		// },
		AssignTeamLead: {
			name: 'Team Lead',
			showTabs: true,
			component: 'AssignTLComponent',

			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: (_bf: BigFormService, item: any, instance: any, sq: any, _store: Store) => {
				_bf.bigForm.patchValue({ checked_team_lead: true });
			},
			serverCalls: {
				tlListData: {
					errorMessage: 'No team leaders returned',
					directCall: (http: HttpClient, store: Store, sq: any, bf: BigFormService) => {
						return http.post(`${environment.api_url}v1/sp_action/manage_list_staff/`, { role_id: 10, image: true }).pipe(
							pluck('payload'),
							skipWhile((response: any) => !response),
							map((teamleaders: any[]) =>
								teamleaders.map(teamLeader => {
									const namestringarr = teamLeader.full_name?.split(' ');
									let mono1, mono2;
									if (!namestringarr[0]) {
										mono1 = ' ';
									} else {
										mono1 = namestringarr[0]?.slice(0, 1);
									}
									if (!namestringarr[1]) {
										mono2 = '';
									} else {
										mono2 = namestringarr[1]?.slice(0, 1);
									}
									const monogram = mono1 + mono2;
									return {
										display: teamLeader.full_name,
										value: teamLeader.id,
										image: teamLeader.image,
										monogram: monogram,
										teamLeader,
										shouldHaveImage: true
									};
								})
							)
						);
					}
				},
				skills: {
					serviceVariable: 'spService',
					functionName: 'getSkills',
					responseSlice: 'skills',
					errorMessage: 'No skills were found!'
				},
				jobLocationData: {
					errorMessage: 'The job location was not returned!',
					directCall: (_http: HttpClient, _st: Store, _sq: any, _bf: BigFormService) => {
						const lat = +_bf.bigForm.get('latitude')?.value;
						const lng = +_bf.bigForm.get('longitude')?.value;
						return of({ lat, lng });
					}
				},
				teamleaderLocations: {
					errorMessage: 'Team leader locations not returned!',
					directCall: (http: any, store: any, sq: any, bf: any) => {
						// debugger;
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter((x: any) => !!x),
							map((teamleaders: any[]) => {
								return teamleaders;
							})
						);
					}
				}
			},
			inputs: [
				{
					disableOptionWhen: {
						message: 'Upload profile picture to select team leader',
						evaluationCriteria: {
							property: 'image',
							operator: '!'
						}
					},
					includeUserDisplay: true,
					tlListData$: 'tlListData',
					joblocation$: 'jobLocationData',
					teamleaderLocations$: 'teamleaderLocations'
				}
			],
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'JobSubmissionSummary'
				}
			]
		},
		JobSubmissionSummary: {
			...AddNewJobCard?.nodes?.JobSubmissionSummary,
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'Update Draft',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					visible: (bf, store, networkService) => {
						return networkService.isOnline;
					},
					serverCalls: {
						saveAsServerDraft: {
							errorMessage: 'Could not update the draft!',
							serviceVariable: 'mulSpService',
							functionName: 'updateServerDraft',
							data: 8,
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'silService',
									functionName: 'deleteCurrentEditableItem'
								}
							}
						}
					}
				},
				{
					text: 'Submit',
					linkType: 'portal',
					// optIntoValidation: true,
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'This claim could not be created!',
							serviceVariable: 'mulSpService',
							functionName: 'updateServerDraftAsSubmitted',
							followUpSuccessCalls: {
								updateJobOnSubmittedServerDraft: {
									errorMessage: 'Could not update job on submitted server draft',
									// errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'mulSpService',
									functionName: 'updateJobOnSubmittedServerDraft'
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			...AddNewJobCard?.nodes?.SubmissionSuccess
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		claim_type_id: 'claim_type_id',
		sub_section_id: 'sub_section_id',

		handler: 'loan_information.handler',
		handlercontact: 'loan_information.handlercontact',
		handleremail: 'loan_information.handleremail',

		// appointmentData: [
		// 	appointment => {
		// 		if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
		// 			const date = moment(appointment.appointmentDatePicker);
		// 			date.hour(appointment.appointmentTimePicker.hour);
		// 			date.minutes(appointment.appointmentTimePicker.minutes);
		// 			const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
		// 			return {
		// 				range_start: date_formatted,
		// 				range_end: date_formatted,
		// 				appointment_type: appointment.appointmentTime
		// 			};
		// 		}
		// 	},
		// 	'appointment'
		// ],
		// Customer Details
		'client_details.clarity_customer_number': ['applicant.claimantpoliceynum'],
		'client_details.first_name': ['applicant.first_name', 'name'],
		'client_details.surname': ['applicant.surname', 'surname'],
		'contact_details.contact_number': ['loan_information.contactnumber', 'loan_information.cellnumber'],
		'contact_details.cell_number': ['loan_information.cell_number'],
		'contact_details.email': ['loan_information.email'],

		// Address confirmation
		latitude: ['location.lat', 'loan_information.claimlatitude'],
		longitude: ['location.long', 'loan_information.claimlongitude'],
		suburb_code: ['postal_code', 'loan_information.suburbcode'],
		province: ['claimprovince', 'loan_information.claimprovince'],
		'insured_address.complex': ['loan_information.propertycomplex'],
		'insured_address.complex_unit_number': ['loan_information.propertycomplexunitnumber'],
		'insured_address.street_address': ['address', 'loan_information.propertystreetaddress'],
		'insured_address.suburb': ['suburb', 'loan_information.propertysuburb'],
		'insured_address.city': ['loan_information.propertycity'],
		'insured_address.postal_code': ['loan_information.postal_code_input'],

		// Current Setup

		'current_environment.no_view_environments': ['loan_information.current_environment.no_view_environments'],
		'current_environment.dwelling_type': ['loan_information.current_environment.dwelling_type'],
		'current_environment.no_of_tvs': ['loan_information.current_environment.no_of_tvs'],
		'current_environment.type_of_tvs': ['loan_information.current_environment.type_of_tvs'],
		'current_environment.mounted_installation': ['loan_information.current_environment.mounted_installation'],

		jobs: [
			[transformJobsToJobCardRequest, 'jobcardrequest'],
			[transformJobsToJobCardRequest, 'loan_information.jobcardrequest'],
			[transformJobsToDeviceDetails, 'loan_information.device_details']
		],

		whatmatters: 'loan_information.whatmatters',

		service_provider_id: ['loan_information.sp_id'],
		assign_teamleader_id: [
			(_id: any, _storeobj: any, _bf: BigFormService) => {
				let teamlead;
				if (Array.isArray(_id)) {
					teamlead = _id[0];
				} else {
					teamlead = _id;
				}
				return teamlead;
			},
			'loan_information.tl_id'
		],

		// Claim Appointment
		on_site_contact: 'loan_information.onsitecontact',
		on_site_notes: 'loan_information.onsitenotes',
		on_site_person: 'loan_information.onsiteperson'
	},
	flowErrorMessages: {
		'checked_customer_details:required': 'Please fill in the Customer Details',
		'checked_address_confirmation:required': 'Please confirm the address on the map!',
		'checked_installation_details:required': 'Please fill in the installation details',
		'checked_appointments:required': 'Please set an Appointment',
		'checked_current_setup:required': 'Please specify the current setup',
		'checked_team_lead:required': 'Please pick a team leader',
		'clarity_customer_number:required': 'The Clarity customer number is required.',
		'first_name:required': 'Applicant first name is required.',
		'first_name:minLength': 'First name needs a minimum of 3 characters.',
		'first_name:onlyAllowedASCII': 'First name field contains invalid character(s).',
		'first_name:text': 'Applicant first name contains non-next characters.',
		'surname:required': 'Surname is required. In (Customer Details)',
		'surname:onlyAllowedASCII': 'Surname field contains invalid characters.',
		'contact_number:required': 'Contact number is required (in Customer Details).',
		'contact_number:contact_number': 'Contact number is invalid (in Customer Details).',
		'cell_number:required': 'Alternative contact number is required (in Customer Details).',
		'email:required': 'Email address is required (in Customer Details)',
		'email:email': 'You need to enter a valid email address (in Customer Details)',
		'street_address:required': 'Property street address is required (in Customer Details)',
		'suburb:required': 'Property suburb is required (in Customer Details)',
		'postal_code:required': 'Postal code is required (in Customer Details)',
		'addressconfirmation:required': 'Please confirm property address',
		'longitude:required': 'No address longitude has been picked up (in Maps)',
		'latitude:required': 'No address latitude has been picked up (in Maps)',
		'on_site_contact:required': 'On site contact number required (in Appointments)',
		'on_site_contact:contact_number': 'On site contact number is invalid (in Appointments)',
		'on_site_person:required': 'On site person name is required (in Appointments)',
		'no_view_environments:required': 'Number of viewing environments is required (in Current Setup)',
		'dwelling_type:required': 'Dwelling type is required (in Current Setup)',
		'no_of_tvs:required': 'Number of TVs is required (in Current Setup)',
		'mounted_installation:required': 'Specify if mounted installation allowed (in Current Setup)',
		'type_of_tvs:required': 'Type of TVs is required (in Current Setup)',
		'appointmentDatePicker:required': 'Job appointment date is required (in Appointments)',
		'appointmentTimePicker:required': 'Job appointment time is required (in Appointments)',
		'whatmatters:required': 'What matters is required (in What Matters)'
	}
};
