import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { map, skipWhile, filter, take, pluck, switchMap } from 'rxjs/operators';
import { iif, of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';

export const PGG_VIEW_CLAIM_SUMMARY: Flow_0_0_2 = {
	id: 'InstallationSummary',
	itemType: 'flow',
	name: 'View Installation Summary',
	excludeForOnlyStates: [169],
	// excludeForOnlyRoles: [18],
	header: {
		title: (_store: Store, bf) => {
			return _store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				map((itemOne: any) => {
					if (itemOne) {
						return `Installation Summary - ${itemOne?.loan_information?.voucher_key}  -  ${itemOne?.applicant?.first_name}`;
					} else {
						return 'Installation Summary';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ClaimSummary',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		// ClaimSummary: {
		//   component: 'ClaimSummaryComponent',
		//   navs: [],
		//   inputs: {
		//     title: 'Claim Information'
		//   }
		// },
		ClaimSummary: {
			component: {
				children: [
					{
						component: 'ClaimSummaryComponent',
						inputs: { title: 'Claim Information', assessorName$: 'assessorName' }
					}
				]
			},
			navs: [],
			serverCalls: {
				assessorName: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (_http: HttpClient, _store: Store) => {
						return _store.select(getFullItemOne).pipe(
							filter((x: any) => !!x),
							take(1),
							pluck('assessor'),
							switchMap((assessor: any) => {
								return iif(
									() => assessor,
									_http
										.post(environment.api_url + 'v1/staff_action/get_staff_member_names', {
											ids: [assessor]
										})
										.pipe(
											map((data: any) => {
												const namesMapper = data.payload;
												return namesMapper[assessor];
											})
										),
									of('Not Assigned')
								);
							})
						);
					}
				}
			}
		}
	}
};
