import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, merge, of, Subscription } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { FLXModalComponent, ModalNavButton } from '@flexus/ui-elements';
import { BigFormService } from '@flexus/core';
import { getCustomTypesList, getCustomYearsList, getSkillSubCategories, getUnitsOfMeasurement } from '@flexus/core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'flx-line-item-add-edit',
	templateUrl: './line-item-add-edit.component.html',
	styleUrls: ['./line-item-add-edit.component.scss']
})
export class LineItemAddEditComponent implements OnInit {
	@Input() modalButtons: ModalNavButton[];
	@Input() modificationType: string;
	@Input() editItem;
	@Input() clientName: string = 'SIL';
	@Output() modificationComplete = new EventEmitter<any>();
	@ViewChild(FLXModalComponent) modal: FLXModalComponent;

	element: Element;
	visible = false;
	visibleAnimate = false;
	modifyForm: UntypedFormGroup;

	boqTypes = new BehaviorSubject([]);
	boqYears = new BehaviorSubject([]);
	boqSubCategories = new BehaviorSubject([]);
	boqUnitsOfMeasurement = new BehaviorSubject([]);

	private sub: Subscription;
	autoSelectedSkills;

	constructor(public el: ElementRef, private fb: UntypedFormBuilder, private bf: BigFormService, private cdr: ChangeDetectorRef, private store: Store<any>) {
		this.element = el.nativeElement;
	}

	ngOnInit() {
		this.modifyForm = this.fb.group({
			item_name: ['', [Validators.required]],
			skill_categories: ['', [Validators.required]],
			item_type: ['', [Validators.required]],
			measurement: ['', [Validators.required]],
			price: ['', [Validators.required]],
			year: ['', [Validators.required]]
		});

		this.sub = combineLatest([
			this.store.select(getCustomTypesList),
			this.store.select(getUnitsOfMeasurement),
			this.store.select(getCustomYearsList),
			this.store.select(getSkillSubCategories)
		])
			.pipe(
				skipWhile(x => !x[0] || !x[1] || !x[2] || !x[3]),
				take(1)
			)
			.subscribe(([customTypesList, unitsOfMeasurement, customYearsList, skillSubCategories]) => {
				this.boqTypes.next(customTypesList);
				this.boqUnitsOfMeasurement.next(unitsOfMeasurement.map(val => ({ display: val, key: val })));
				this.boqYears.next(customYearsList);
				this.boqSubCategories.next(skillSubCategories);
				this.checkSubCategories();
			});

		setTimeout(() => {
			this.patchFields();
			this.initModal();
		}, 500);
	}

	checkSubCategories() {
		const hasCustomItem = this.boqSubCategories?.value?.filter(f => f.name?.toLowerCase() === 'custom').length > 0;
		if (!hasCustomItem) {
			const customItem = this.boqSubCategories?.value?.filter(f => f.name?.toLowerCase() === 'custom')[0];
			this.boqSubCategories?.value?.push(customItem);
		}
	}

	patchFields() {
		if (this.editItem) {
			this.modifyForm.patchValue({
				item_name: this.editItem.name,
				skill_categories: this.editItem.skill_categories.length === 0 ? ['Custom'] : this.editItem.skill_categories,
				item_source: 0,
				item_type: this.editItem.item_type,
				measurement: this.editItem.measurement || null,
				price: this.editItem.price,
				year: this.editItem.year,
				client: this.clientName
			});
			if (this.editItem.skill_categories.length === 0) {
				this.autoSelectedSkills = ['Custom'];
			} else {
				this.autoSelectedSkills = this.editItem.skill_categories.map(item => item?.name);
			}

			this.checkSubCategories();
		} else {
			this.autoSelectedSkills = ['Custom'];
		}
	}

	initModal() {
		this.modalButtons = [
			{
				text: 'Cancel',
				linkType: 'close',
				clickHandler: () => {
					this.modificationComplete.emit({ closeModal: true });
					if (this.autoSelectedSkills) {
						this.autoSelectedSkills.splice(0, 1);
					}
				}
			},
			{
				text: this.modificationType === 'create' ? 'Add Item' : 'Update Item',
				disabled: merge(of(this.modifyForm.invalid), this.modifyForm.valueChanges.pipe(map(() => this.modifyForm.invalid))),
				clickHandler: event => {
					if (this.modifyForm.valid) {
						this.submitModalForm(this.modificationType === 'create' ? 'createCustomLineItem' : 'updateCustomLineItem');
						this.modal.close();
					}
				}
			}
		];

		this.modal.open();
		this.cdr.detectChanges();
	}

	submitModalForm(functionName) {
		const data: any = {
			item_name: this.modifyForm.get('item_name')?.value,
			skill_categories: this.modifyForm.get('skill_categories')?.value,
			item_source: 0,
			item_type: this.modifyForm.get('item_type')?.value,
			measurement: this.modifyForm.get('measurement')?.value,
			price: this.modifyForm.get('price')?.value?.toString(),
			year: this.modifyForm.get('year')?.value,
			client: this.clientName
		};

		if (this.editItem) {
			data.id = this.editItem.id;
		}

		this.modificationComplete.emit({ data, functionName });

		if (this.autoSelectedSkills) {
			this.autoSelectedSkills.splice(0, 1);
		}
	}
}
