import {
	Component,
	OnInit,
	Input,
	ViewChild,
	OnDestroy,
	AfterViewInit,
	Renderer2,
	ElementRef,
	SimpleChanges,
	OnChanges,
	ChangeDetectionStrategy,
	NgZone,
	HostListener,
	ChangeDetectorRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { getOrgKey, SelectItemTwo, GetFullItemTwo, GetFullItemOne, SetActiveManifestItem, ManifestController } from '@flexus/core';
import { FLXContextMenuComponent, FLXIndicatorComponent } from '@flexus/ui-elements';

@Component({
	selector: 'flx-sil-job-card',
	templateUrl: './sil-job-card.component.html',
	styleUrls: ['./sil-job-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SilJobCardComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
	@Input() itemTwo: any;
	@Input() itemOne: any;
	@Input() itemTwoContextMenuList = [];
	@Input() user: any;
	@Input() itemTwoPermissions = [];
	@Input() activeOrg;
	@Input() allInfo: any;
	@Input() isOnline: boolean;
	selectedItemTwo: any;
	buttonText: string;
	permissionBasedContextMenuList = [];

	stateDescription: string;
	sp: any;
	skill: any;
	appt: string;

	sub: Subscription[] = [];
	@ViewChild(FLXIndicatorComponent, { static: true }) indicator: FLXIndicatorComponent;
	@ViewChild(FLXContextMenuComponent) contextMenu: FLXContextMenuComponent;
	constructor(
		private cdr: ChangeDetectorRef,
		private _store: Store<any>,
		private controller: ManifestController<any>,
		private router: Router,
		private route: ActivatedRoute,
		private renderer: Renderer2,
		private el: ElementRef,
		private ngZone: NgZone
	) {}

	ngOnInit() {
		this.getValuesFromAllInfo();
		this.permissionBasedContextMenuList = this.itemTwoContextMenuList.filter(item => this.checkPermission(item) === true);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.getValuesFromAllInfo();
		if (changes['itemTwoPermissions']) {
			this.ngZone.runOutsideAngular(() => {
				this.sub.forEach(s => {
					if (s) s.unsubscribe();
				});
				// setTimeout(() => {
				if (this.itemTwo && this.itemOne && this.user && this.activeOrg && this.allInfo) {
					this.itemTwoPermissions.forEach(fn => {
						const res = fn(this.itemTwo, this.itemOne, this.user, this.renderer, this.el.nativeElement, this.activeOrg, this.allInfo, this);
						if (res) {
							this.sub.push(res);
						}
					});
				}

				this.sub.length > 0 &&
					forkJoin(this.sub).subscribe(() => {
						this.cdr.detectChanges();
					});
				// }, 1000);
			});
		}
	}

	ngAfterViewInit() {}

	@HostListener('mouseenter')
	onMouseOver() {
		const state = this.itemTwo.state;
		const editRoles = this.user && this.user.user && this.user.user.edit_states;

		let jobCardElement = this.el.nativeElement.querySelector('.job-card');
		if (editRoles && editRoles.includes(state)) {
			this.renderer.setStyle(jobCardElement, 'cursor', 'pointer');
		} else {
			this.renderer.setStyle(jobCardElement, 'cursor', 'default');
		}
	}

	checkPermission(menuItem: any): boolean {
		if (this.user?.user && this.checkArrays(this.user?.user?.roles, menuItem.excludeForOnlyRoles)) {
			return false;
		} else if (menuItem.excludeForOnlyStates?.length > 0 && !menuItem.excludeForOnlyStates.includes(this.itemTwo.state)) {
			return true;
		} else if (!menuItem.excludeForOnlyStates?.includes(this.itemTwo?.state) && menuItem.includeForOnlyStates.includes('all')) {
			return true;
		} else if (menuItem.includeForOnlyStates?.includes(this.itemTwo?.state)) {
			return true;
		} else return false;
	}

	checkArrays(arr1: Array<any>, arr2: Array<any>) {
		let res = false;
		if (!!arr1 && !!arr2) {
			arr1.forEach(val => {
				if (arr2.includes(val)) {
					res = true;
				}
			});
		}
		return res;
	}

	private getValuesFromAllInfo() {
		this.skill = this.findName(this.itemTwo.skill, this.allInfo.skills);
		this.sp = this.allInfo && this.allInfo.sps ? this.allInfo.sps.filter(element => element?.id === this.itemTwo.sp)[0] : null;
		this.stateDescription = this.getStateDescription(this.itemTwo.state);
	}

	findName(id: number, arr: any[]) {
		if (arr !== undefined && id !== undefined) {
			const res = arr.find(obj => obj.id === id) || {};
			return res;
		} else {
			return {};
		}
	}

	getStateDescription(state: any): string {
		if (this.allInfo?.states) {
			return this.allInfo.states.filter(element => element.id === state)[0]?.description;
		} else {
			return '';
		}
	}

	takeAction(itemTwo) {
		if (this.user?.user?.edit_states.includes(itemTwo.state)) {
			if (this.isOnline) {
				this.router.navigate(['/workflow/detail']);
				this.selectedItemTwo = itemTwo;

				this.controller
					.select(getOrgKey)
					.pipe(take(1))
					.subscribe(key => {
						this.controller.dispatch(
							new SetActiveManifestItem({
								pathToFlows: ['manifestItems'],
								orgKey: key,
								itemId: itemTwo?.state,
								itemOne: this.itemOne,
								itemTwo: this.itemTwo
							})
						);
					});
			}
		}
	}

	selectMenuItem(itemTwo, menuItem) {
		this.router.navigate(['/workflow/detail']);
		// this._store.dispatch(new GetFullItemTwo({ id: itemTwo?.id }));
		this._store.dispatch(new SelectItemTwo({ itemOne: this.itemOne, itemTwo: this.itemTwo }));
		this.controller
			.select(getOrgKey)
			.pipe(take(1))
			.subscribe(key => {
				this.controller.dispatch(
					new SetActiveManifestItem({
						pathToFlows: ['manifestItems', 'workflow', 'contextMenu', 'itemTwo'],
						orgKey: key,
						itemId: menuItem.key,
						itemOne: this.itemOne,
						itemTwo: this.itemTwo
					})
				);
			});
		if (this.contextMenu) {
			this.contextMenu.close();
		}
	}

	ngOnDestroy() {
		this.sub.forEach(s => s.unsubscribe());
	}
}
