import { Flow_0_0_2 } from '@flexus/core';

export const BUI_99: Flow_0_0_2 = {
	id: '99',
	name: 'request_la',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Request Loss Ajustor'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: '',
	nodes: {}
};
