import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ManifestController, ModalService, getActiveNode } from '@flexus/core';

@Component({
	selector: 'flexus-decision-node',
	templateUrl: './decision-node.component.html',
	styleUrls: ['./decision-node.component.scss']
})
export class DecisionNodeComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	constructor(private store: Store<any>, controller: ManifestController<any>, public modalService: ModalService) {
		controller
			.select(getActiveNode)
			.pipe(take(1))
			.subscribe(node => {
				if (node && node.decisions) {
					if (node.navs) {
						Object.values(node.decisions).forEach((fn: Function) => {
							this.subscriptions.push(fn(node.navs, this.store, this.modalService, controller));
						});
					} else {
						throw new Error('One of the decision nodes lacks a nav section. Add a nav section to the node in the manifest');
					}
				} else {
					throw new Error('One of the decision nodes exists without any decision. Add at least one decision to the node in the manifest.');
				}
			});
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => {
			if (sub) {
				sub.unsubscribe();
			}
		});
	}
}
