import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, pluck, skipWhile, switchMap, take } from 'rxjs/operators';
import * as SPOwnJobActions from './sp-own-job.actions';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { getOwnJobClaimType } from './sp-own-job.selectors';
import { ENVIRONMENT } from '../services';

@Injectable()
export class SPOwnJobEffects {
	constructor(@Inject(ENVIRONMENT) private _environment: any, private actions$: Actions, private _http: HttpClient, private _store: Store) {}

	getOwnJobClaimType$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SPOwnJobActions.GET_OWNJOB_CLAIMTYPE),
			switchMap(() => {
				return this._store.select(getOwnJobClaimType).pipe(
					skipWhile(x => !x),
					take(1)
				);
			}),
			take(1),
			map(data => {
				return new SPOwnJobActions.GetSPOwnJobClaimTypeSuccess(data);
			}),
			catchError(error => of(new SPOwnJobActions.GetSPOwnJobClaimTypeFail(error)))
		)
	);

	getSPOwnJobTeamLeaderStaff$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SPOwnJobActions.GET_OWNJOB_STAFF_TEAM_LEADERS),
			switchMap(() => {
				return this._http.post(`${this._environment.api_url}v1/sp_action/manage_list_staff/`, { role_id: 10, image: true, autorized: true }).pipe(
					pluck('payload'),
					skipWhile((response: any) => !response),
					map((teamleaders: any[]) =>
						teamleaders.map(teamLeader => {
							const namestringarr = teamLeader.full_name?.split(' ');
							let mono1, mono2;
							if (!namestringarr[0]) {
								mono1 = ' ';
							} else {
								mono1 = namestringarr[0]?.slice(0, 1);
							}
							if (!namestringarr[1]) {
								mono2 = '';
							} else {
								mono2 = namestringarr[1]?.slice(0, 1);
							}
							const monogram = mono1 + mono2;
							return {
								display: teamLeader.full_name,
								value: teamLeader.id,
								image: teamLeader.image,
								monogram: monogram,
								teamLeader,
								shouldHaveImage: true
							};
						})
					)
				);
			}),
			take(1),
			map(data => {
				return new SPOwnJobActions.GetSPOwnJobTeamLeaderStaffSuccess(data);
			}),
			catchError(error => of(new SPOwnJobActions.GetSPOwnJobTeamLeaderStaffFail(error)))
		)
	);

	getSPOwnJobTeamPositions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SPOwnJobActions.GET_OWNJOB_TEAM_POSITIONS),
			switchMap(() => {
				return this._http.get(`${this._environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
					pluck('payload'),
					filter((x: any) => !!x),
					map((teamleaders: any[]) => {
						return teamleaders;
					})
				);
			}),
			take(1),
			map(data => {
				return new SPOwnJobActions.GetSPOwnJobTeamPositionsSuccess(data);
			}),
			catchError(error => of(new SPOwnJobActions.GetSPOwnJobTeamPositionsFail(error)))
		)
	);
}
