<flx-heading size="medium" align="center" weight="extra-light" type="creation">User Registration</flx-heading>

<div class="policy-detail-container">
	<form [formGroup]="bf.bigForm">
		<flx-flex-container direction="row" justifyContent="center" maxWidth="50vw">
			<flx-panel density="cozy" margin="8">
				<flx-label>User name</flx-label>
				<flx-input-text formControlName="username" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bf.bigForm.controls)?.username"></flx-reactive-form-error-display>

				<flx-label>First name</flx-label>
				<flx-input-text formControlName="first_name" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bf.bigForm.controls)?.first_name"></flx-reactive-form-error-display>

				<flx-label>Last name</flx-label>
				<flx-input-text formControlName="last_name" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bf.bigForm.controls)?.last_name"></flx-reactive-form-error-display>

				<!-- <flx-label>Confirm Password</flx-label>
					<flx-input-text formControlName="confirm_password" type="text" size="normal"></flx-input-text>
					<flx-reactive-form-error-display [control]="$any(form.controls)?.confirm_password"></flx-reactive-form-error-display> -->
			</flx-panel>

			<flx-panel density="cozy" margin="8">
				<flx-label>Password</flx-label>
				<flx-input-text formControlName="password" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bf.bigForm.controls)?.password"></flx-reactive-form-error-display>

				<flx-label>Contact Number</flx-label>
				<flx-input-text formControlName="contact_number" type="contact_number" [onlyValidPhoneChars]="true" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bf.bigForm.controls)?.contact_number" class="error"> </flx-reactive-form-error-display>

				<flx-label>Email Address</flx-label>
				<flx-input-text formControlName="email_address" type="email" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bf.bigForm.controls)?.email_address" class="error"></flx-reactive-form-error-display>

				<!-- <flx-label>Staff Number SP Name</flx-label>
				<flx-input-text formControlName="staff_number_sp_name" type="text" size="normal"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(form.controls)?.staff_number_sp_name"></flx-reactive-form-error-display> -->
			</flx-panel>
		</flx-flex-container>

		<div formGroupName="roles">
			<flx-flex-container direction="row" justifyContent="center">
				<flx-flex-container direction="column" justifyContent="start" alignItems="start">
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="claim_handler" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Claim Handler</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="insurer_assessor" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Insurer Assessor</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="insurer_field_agent_sil" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Insurer Field Agent SIL</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="insurer_field_agent_sp" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Insurer Field Agent SP</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="disbursement_officer" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Disbursement Officer</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="frontline_manager" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Frontline Manager</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="central_management" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Central Management</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="administrator" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Administrator</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="sp_job_scheduler" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">SP Job Scheduler</p>
					</div>
				</flx-flex-container>
				<flx-flex-container direction="column" justifyContent="start" alignItems="start">
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="sp_team_leader" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">SP Team Leader</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="payments_and_ratings" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Payments and Ratings</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="supply_chain_manager" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Supply Chain Manager</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="loss_adjuster_tl" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Loss Adjuster TL</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="verification_officer" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Verification Officer</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="external_claim_handler" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">External Claim Handler</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="authoriser" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Authoriser</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="dashboard" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Dashboard</p>
					</div>
					<div class="togglebutton">
						<label class="switch"> <input type="checkbox" formControlName="dashboard_super_user" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Dashboard Super User</p>
					</div>
				</flx-flex-container>
			</flx-flex-container>
		</div>
	</form>
</div>
