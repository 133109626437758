import { OnInit, Directive, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { WindowRef, DocumentRef } from '../location-picker';

class Script {
	src: string;
	loaded: boolean;

	constructor(scriptSRC: string) {
		this.src = scriptSRC;
		this.loaded = false;
	}
}

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[scriptLoader]'
})
export class ScriptLoaderDirective implements OnInit {
	private script: Script;

	@Input('scriptLoader') scriptSource: string;

	@Output() scriptCallback: EventEmitter<boolean> = new EventEmitter();

	constructor(private _renderer: Renderer2, private w: WindowRef, private d: DocumentRef) {}

	ngOnInit() {
		this.script = new Script(this.scriptSource);
		this.loadScript().then(result => {
			this.scriptCallback.emit(this.script.loaded);
		});
	}

	loadScript() {
		return new Promise((resolve, reject) => {
			if (!this.checkIfScriptExists()) {
				if (!this.script.loaded) {
					const scriptElem: HTMLScriptElement = this._renderer.createElement('script');
					scriptElem.type = 'text/javascript';
					scriptElem.src = this.script.src;

					this._renderer.appendChild(document.body, scriptElem);

					scriptElem.onload = () => {
						this.script.loaded = true;
						resolve({ loaded: true, status: 'Loaded' });
					};

					scriptElem.onerror = (error: any) => {
						this.script.loaded = false;
						resolve({ loaded: false, status: 'Not Loaded' });
					};
				}
			} else {
				this.script.loaded = true;
				resolve({ loaded: true, status: 'Already Loaded' });
			}
		});
	}

	checkIfScriptExists() {
		return document.querySelectorAll(`script[src="${this.script.src}"]`).length > 0;
	}
}
