import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicLoaderComponent } from './dynamic-loader.component';
import { DynamicLoaderDirective } from './directives/dynamic-loader.directive';
import { DynamicLoaderService } from './services/dynamic-loader.service';

@NgModule({
	imports: [CommonModule],
	declarations: [DynamicLoaderComponent, DynamicLoaderDirective],
	providers: [DynamicLoaderService],
	exports: [DynamicLoaderComponent, DynamicLoaderDirective]
})
export class DynamicLoaderModule {}
