import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getAllInfo, getFullItemOne, getSelectedItem } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { forkJoin, of } from 'rxjs';
import { skipWhile, take, map, switchMap } from 'rxjs/operators';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { PGG_261 } from './PGG_261';

export const PGG_263: Flow_0_0_2 = {
	id: '263',
	name: 'refund_payments_send_to_bank',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	instructions: {
		editRoles: { 0: 'Refund payment sent to bank' },
		viewRoles: { 0: 'Refund payment sent to bank' }
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(x => !x),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Refund Payment Sent to Bank';
					if (itemOne) {
						headingstring += ` : ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		...PGG_261.serverCalls
	},
	startNode: 'RefundPaymentAllocation',
	nodes: {
		RefundPaymentAllocation: {
			initFormFields: (bf: any) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(null, Validators.required));
			},
			...jobInfoNode(263, 'Refund Payment Allocation', []),
			navs: [
				{
					text: 'incorrect bank details ',
					color: 'secondary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any) => {
							instance.type = 'warning';
							instance.heading = 'Confirmation of Incorrect Bank Details';
							instance.setMessage([
								'Please confirm that the customer provided incorrect bank details',
								'Once you confirm, the customer will be asked to re-enter their banking details'
							]);
							instance.navButtons = [
								{
									text: 'back',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'confirm',
									color: 'default',
									serverFirst: true,
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										response: {
											directCall: (http: any, store: any, sq: any, bf: any) => {
												bf.patchValues({ new_state: 265 });
												return store.select(getSelectedItem).pipe(
													skipWhile(x => !x),
													take(1),
													switchMap((selectedjob: any) => {
														const job_id = selectedjob?.id;
														const state = bf.getControl('new_state')?.value;
														const request = {
															job_id: job_id,
															new_state: state
														};
														return http.post(`${environment.api_url}v1/job_action/update_job/`, request);
													})
												);
											}
										}
									}
								}
							];
						}
					}
				},
				{
					text: 'insufficient funds',
					color: 'secondary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any) => {
							instance.type = 'warning';
							instance.heading = 'Confirmation of Insufficient funds';
							instance.setMessage(['Please confirm this payment can not be done due to insufficient funds', 'Once you confirm, the job will be queued to be refunded again']);
							instance.navButtons = [
								{
									text: 'back',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'confirm',
									color: 'default',
									serverFirst: true,
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										response: {
											directCall: (http: any, store: any, sq: any, bf: any) => {
												bf.patchValues({ new_state: 266 });
												return store.select(getSelectedItem).pipe(
													skipWhile(x => !x),
													take(1),
													switchMap((selectedjob: any) => {
														const job_id = selectedjob?.id;
														const state = bf.getControl('new_state')?.value;
														const request = {
															job_id: job_id,
															new_state: state
														};

														return http.post(`${environment.api_url}v1/job_action/update_job/`, request);
													})
												);
											}
										}
									}
								}
							];
						}
					}
				},
				{
					text: 'payment successful',
					color: 'primary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any) => {
							instance.type = 'success';
							instance.heading = 'Confirmation of Successful Payment';
							instance.setMessage(['Please confirm that refund payment is complete', 'Once you confirm, this can not be undone']);
							instance.navButtons = [
								{
									text: 'back',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'confirm',
									color: 'default',
									serverFirst: true,
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										response: {
											directCall: (http: any, store: any, sq: any, bf: any) => {
												bf.patchValues({ new_state: 264 });
												return store.select(getSelectedItem).pipe(
													skipWhile(x => !x),
													take(1),
													switchMap((selectedjob: any) => {
														const job_id = selectedjob?.id;
														const state = bf.getControl('new_state')?.value;
														const request = {
															job_id: job_id,
															new_state: state
														};
														return http.post(`${environment.api_url}v1/job_action/update_job/`, request);
													})
												);
											}
										}
									}
								}
							];
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
