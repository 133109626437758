<!-- <flx-app-bar title="{{title$ | async }}"></flx-app-bar> -->
<div class="flow-action-shell">
	<flx-sp-ping-window></flx-sp-ping-window>
	<flx-sp-job-notification-window></flx-sp-job-notification-window>

	<ng-container *ngIf="showTabs">
		<flx-tabs [tabs]="dynamicTabs" [currentStep$]="currentTab$" [externalPages]="true" (outputEventStream)="selectTab($event)"></flx-tabs>
	</ng-container>

	<flx-dynamic-loader dynamicLoaderHost></flx-dynamic-loader>
</div>
