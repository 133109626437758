<div class="list-view-job-info" [id]="'job-' + itemTwo?.id" (click)="takeAction(itemTwo)">
	<div class="indicator"><flx-indicator size="small"></flx-indicator></div>
	<div class="job-card-actions">
		<flx-context-menu class="context-menu-container" iconSize="small" *ngIf="isOnline && itemTwoContextMenuList?.length > 0">
			<ul>
				<ng-container *ngFor="let menuItem of itemTwoContextMenuList">
					<li
						*ngIf="
							(menuItem?.excludeForOnlyStates.length > 0 && !menuItem?.excludeForOnlyStates.includes(itemTwo.state)) ||
							(!menuItem?.excludeForOnlyStates.includes(itemTwo?.state) && menuItem?.includeForOnlyStates.includes('all')) ||
							(menuItem?.includeForOnlyStates.includes(itemTwo?.state) && (!menuItem?.includeFilterFunction || menuItem?.includeFilterFunction(itemTwo)))
						"
						(click)="selectMenuItem(itemTwo, menuItem)"
					>
						{{ menuItem?.name }}
					</li>
				</ng-container>
				<li class="divider"><flx-glow-line></flx-glow-line></li>
			</ul>
		</flx-context-menu>
	</div>
	<div class="indicator-icons">
		<!-- Notes indicator -->
		<div class="notes-indicator" *ngIf="itemTwo?.note_count !== null && itemTwo?.note_count > 0">
			<flx-icon
				size="extra-small"
				[instruction]="itemTwo?.note_count > 1 ? itemTwo?.note_count + ' notes' : itemTwo?.note_count + ' note'"
				[type]="'notes'"
				(click)="selectMenuItem(itemTwo, { key: 'SIL_CONTEXT_MENU_JOB_NOTES' })"
			></flx-icon>
		</div>
	</div>

	<div>{{ itemTwo?.addressLine }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ itemTwo?.skillName }}</div>

	<div>{{ itemTwo?.state }} : {{ itemTwo?.stateName }}</div>
</div>
