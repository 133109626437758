import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { environment } from '../environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppStoreModule, NAV_BUTTON_ACTION_PANEL_COMPONENT, NAV_BUTTON_MODAL_COMPONENT, cryptoBlobKey } from '@flexus/core';
import { registeredClients, registeredServices } from './organisations';
import { UXStoreModule, ThemeModule, middayTheme, midnightTheme, blackoutTheme } from '@flexus/ux';
import { ScreensModule, screenMapper } from '@flexus/screens';
import { AuthModule } from './auth/auth.module';
import { ErrorHandlerModule, GlobalErrorHandler } from '@flexus/error-handler';
import { AppRoutingModule } from './app.routing';
import { InlineSVGModule } from 'ng-inline-svg';
import { HttpClientModule } from '@angular/common/http';
import { FLXModalComponent, UIElementsModule } from '@flexus/ui-elements';
import { UICompositesModule } from '@flexus/ui-composites';
import { devToolsModule } from '../store-builds';
import { SentryErrorHandler } from './sentry.service';
import { FLXActionPanelComponent } from './app-shell-features';
import { DecimalPipe } from '@angular/common';
import { AppComponent } from './app.component';
import { DynamicLoaderModule, EngineModule } from '@flexus/engine';
import { BillingModule } from '@flexus/billing';
import { SpUserModule } from './sp-user/sp-user.module';


@NgModule({
	declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InlineSVGModule,
    DynamicLoaderModule,
    UIElementsModule.forRoot({environment}),
    UICompositesModule.forRoot(environment),
    ScreensModule.forRoot(environment),
    registeredClients[environment.client],
    EngineModule.forRoot({
			screenMapper,
			service: registeredServices[environment.client],
			environment: environment
		}),
    AppStoreModule.forRoot({environment}),
    BillingModule.forRoot({environment}),
    ErrorHandlerModule,
    AuthModule,
    SpUserModule,
    ...devToolsModule,
    ToastrModule.forRoot({
			newestOnTop: true,
			timeOut: 10000,
			positionClass: 'toast-bottom-left',
			preventDuplicates: true
		}),
    ...environment.applicationModules,
    ThemeModule.forRoot({
			themes: [blackoutTheme, middayTheme, midnightTheme],
			active: 'blackout'
		}),
		UXStoreModule,
		AppRoutingModule
  ],
	providers: [
		// BillingService,
    { provide: cryptoBlobKey, useValue: 'flexusBlobKey' },
		{ provide: ErrorHandler, useClass: environment.production ? SentryErrorHandler : GlobalErrorHandler },
		// { provide: ErrorHandler, useClass: SentryErrorHandler },
		{ provide: NAV_BUTTON_ACTION_PANEL_COMPONENT, useClass: FLXActionPanelComponent },
		{ provide: NAV_BUTTON_MODAL_COMPONENT, useClass: FLXModalComponent },
		DecimalPipe
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
