import { Flow_0_0_2, getActiveViewData, SetView } from '@flexus/core';
import { take } from 'rxjs/operators';

export const MUL_DELETE_LOCAL_DRAFT: Flow_0_0_2 = {
	id: 'DeleteLocalDraft',
	name: 'Delete Local Draft',
	includeForOnlyStates: [169],
	onStateInit: instance => {
		// instance.store
		//   .select(getSelectedItemOne)
		//   .pipe(
		//     skipWhile((x) => !x),
		//     take(1),
		//   )
		//   .subscribe((item) => {
		//     // activateGuardInstance.indexedDbService.claimInDraft.delete(item.tempKey).then(() => {
		//     //   // TODO: Later use SetView
		//     //   window.location.reload();
		//     // });
		//   });
	},
	activity: (activateGuardInstance, store) => {
		// activateGuardInstance.controller
		// 	.select(getActiveViewData)
		// 	.pipe(take(1))
		// 	.subscribe(key => {
		// 		const viewFunc = this.manifestItem.views['default'];
		// 		this.controller.dispatch(new SetView({ func: viewFunc, key, params: { reverse: false } }));
		// 	});
	},
	dontLoadNodes: true
};
