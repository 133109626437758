import { getAllInfo, getFullItemTwo, NodePoint_0_0_2 } from '@flexus/core';
import { KVLHeading } from '@flexus/ui-elements';
import gql from 'graphql-tag';
import { forkJoin, of } from 'rxjs';
import { skipWhile, take, map } from 'rxjs/operators';

export const jobInfoNode = (state?: string | number, heading?: string, instructions?: any[], formControlName?: string, formcontrollabel?: string): NodePoint_0_0_2 => {
	return {
		name: 'Job Information',
		serverCalls: {
			important_information: {
				errorMessage: `Could not get important information`,
				directCall: (_http: any, _store: any, _sq: any, _bf: any) => {
					return _store
						.select(getFullItemTwo)
						.pipe(
							skipWhile((data: any) => !data),
							take(1),
							map((res: any) => res)
						)
						.pipe(
							map((selectedJob: any) => {
								const { job_information } = selectedJob;
								const list: any[] = [];
								if (state === 273) {
									const incentive_reason: string = job_information?.installer_incentive_reason ?? 'No reason provided';
									const incentive_amount: number = job_information?.installer_incentive_amount ?? 'No incentive';
									const incentive_comment: string = job_information?.installer_incentive_comments ?? 'No comments';

									const importantObj = {
										'Reason for Incentive ': `${incentive_reason}`,
										'Additional amount ': `${incentive_amount}`,
										'Additional comments': `${incentive_comment}`
									};
									list.push(importantObj);
									return list;
								} else if (state === 274) {
									const additional_reason: string = job_information?.additional_fees_reason ?? 'No reason provided';
									const additional_amount: string = job_information?.additional_fees_amount ?? 'No additional fees';
									const current_incenctive: string = job_information?.installer_incentive_amount ?? 'No existing incentive';

									const importantObj = {
										'Additional amount requested ': `${additional_amount}`,
										'Reason for request ': `${additional_reason}`,
										'Incentive value': `${current_incenctive}`
									};
									list.push(importantObj);
									return list;
								} else {
									return null;
								}
							})
						);
				}
			},
			voucher_information: {
				errorMessage: 'could not get the pinggo installation summary',
				directCall: (http: any, store: any, sq: any, bf: any) => {
					return forkJoin([
						sq.queryObject(
							gql`
								{
									fullItemTwo {
										claim {
											loan_information {
												voucher_key
												voucher_type
											}
											source
										}
									}
								}
							`,
							store.select(getFullItemTwo).pipe(
								skipWhile((fit: any) => !fit),
								take(1),
								map((res: any) => ({ fullItemTwo: res }))
							)
						),
						store.select(getAllInfo).pipe(
							skipWhile((data: any) => !data),
							take(1),
							map((rest: any) => rest)
						)
					]).pipe(
						map(([queryData, estimates]: any) => {
							const { estimate_installation_times }: any = estimates;
							const skillid: number = +queryData?.skill_id;
							// const estimate: any = estimate_installation_times.find(el => el.skill === skillid).installation_time;
							const voucherkey: string = queryData?.voucher_key;
							const voucherName: string = queryData?.voucher_type;
							const voucherclient: string = queryData?.source;
							const quantity: string = queryData?.quantity ? queryData?.quantity : 1;

							const list: any[] = [];
							const installationinfo = {
								'Voucher Number': `${voucherkey}`,
								'Voucher Name': `${voucherName}`,
								'Voucher Client': `${voucherclient}`,
								'Quantity (If Applicable)': `${quantity}`
							};
							list.push(installationinfo);
							return list;
							// return { fullItemTwo: queryData };
						})
					);
				}
			},
			customer_details: {
				errorMessage: `Could not get important information`,
				directCall: (http: any, store: any, sq: any, bf: any) => {
					return sq
						.queryObject(
							gql`
								{
									fullItemTwo {
										address
										claim {
											applicant {
												first_name
												contact_number
											}
										}
									}
								}
							`,
							store.select(getFullItemTwo).pipe(
								skipWhile((fit: any) => !fit),
								take(1),
								map((res: any) => ({ fullItemTwo: res }))
							)
						)
						.pipe(
							map((querydata: any) => {
								const name: string = querydata?.first_name;
								const number: string = querydata?.contact_number;
								const address: string = querydata?.address;
								const list: any[] = [];
								const contactinfo = {
									'Customer name': `${name}`,
									'Customer Contact Number': `${number}`,
									Address: `${address}`
								};
								list.push(contactinfo);
								return list;
							})
						);
				}
			},
			installation_summary: {
				errorMessage: 'could not get the pinggo installation summary',
				directCall: (http: any, store: any, sq: any, bf: any) => {
					return forkJoin([
						sq.queryObject(
							gql`
								{
									fullItemTwo {
										claim {
											loan_information {
												voucher_key
												skill_id
												voucher_type
											}
										}
									}
								}
							`,
							store.select(getFullItemTwo).pipe(
								skipWhile((fit: any) => !fit),
								take(1),
								map((res: any) => ({ fullItemTwo: res }))
							)
						),
						store.select(getAllInfo).pipe(
							skipWhile((data: any) => !data),
							take(1),
							map((rest: any) => rest)
						)
					]).pipe(
						map(([queryData, info]: any) => {
							const { estimate_installation_times, skills }: any = info;
							const skillid: number = +queryData?.skill_id;
							const estimate: any = estimate_installation_times.find(el => el.skill === skillid).installation_time;
							const voucherkey: string = queryData?.voucher_key;
							const product: string = queryData?.voucher_type;
							const skillstring = skills.find(el => el.id === skillid).name;
							const comments = 'product comments';

							const list: any[] = [];
							const installationinfo = {
								Product: `${skillstring}`,
								'Estimated time to complete installation': `${estimate} hrs`
								// 'Product comments': `${comments}`
							};
							list.push(installationinfo);
							return list;
							// return { fullItemTwo: queryData };
						})
					);
				}
			},
			installer_details: {
				errorMessage: 'could not get the pinggo installer details',
				directCall: (http: any, store: any, sq: any, bf: any) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile((data: any) => !data),
							take(1),
							map((result: any) => result)
						)
						.pipe(
							map((leader: any) => {
								let installername: string = '';
								let installernumber: string = '';
								let installer_onsite: string = '';
								let installer_complete: string = '';

								if (leader?.team_leader === null) {
									installername = 'no team leader detail';
									installernumber = 'no contact number provided';
								} else {
									installername = leader?.team_leader?.full_name;
									installernumber = leader?.team_leader.contact_number;
								}
								if (state === 273 || state === 21) {
									installer_onsite = 'No';
									installer_complete = 'No';
								} else {
									installer_onsite = 'Yes';
									installer_complete = 'Yes';
								}
								const installerobj = {
									'Installer name': `${installername}`,
									'Installer Contact Number': `${installernumber}`,
									'Installer was on site': `${installer_onsite}`,
									'Installer completed installation': `${installer_complete}`
								};
								const list: any[] = [];
								list.push(installerobj);
								if (state === 263) {
									return null;
								} else {
									return list;
								}
							})
						);
				}
			}
		},
		component: {
			children: [
				...(state === 20
					? [
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: 'Workmanship Installation Query'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('Customer Details', 'secondary'),
									data$: 'customer_details'
								}
							}
					  ]
					: [
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: heading,
									instructions: instructions
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('Important Information', 'secondary'),
									color: 'secondary',
									data$: 'important_information',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('Customer details', 'secondary'),
									data$: 'customer_details',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('Voucher Information', 'secondary'),
									data$: 'voucher_information',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('Installation Summary', 'secondary'),
									data$: 'installation_summary',
									itemMargin: '0 0 35px 0'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('Installer Details', 'secondary'),
									data$: 'installer_details',
									itemMargin: '0 0 35px 0'
								}
							}
					  ])
			]
		}
		// component: {
		// 	children: [
		// 		{
		// 			component: 'FLXHeadingWithInstructionsComponent',
		// 			inputs: {
		// 				title: heading,
		// 				instructions: instructions
		// 			}
		// 		},
		// 		{
		// 			component: 'FLXKeyValueListComponent',
		// 			inputs: {
		// 				colouredHeading: new KVLHeading('Important Information', 'secondary'),
		// 				color: 'secondary',
		// 				data$: 'important_information',
		// 				itemMargin: '0 0 35px 0'
		// 			}
		// 		},
		// 		{
		// 			component: 'FLXKeyValueListComponent',
		// 			inputs: {
		// 				colouredHeading: new KVLHeading('Customer details', 'secondary'),
		// 				data$: 'customer_details',
		// 				itemMargin: '0 0 35px 0'
		// 			}
		// 		},
		// 		{
		// 			component: 'FLXKeyValueListComponent',
		// 			inputs: {
		// 				colouredHeading: new KVLHeading('Voucher Information', 'secondary'),
		// 				data$: 'voucher_information',
		// 				itemMargin: '0 0 35px 0'
		// 			}
		// 		},
		// 		{
		// 			component: 'FLXKeyValueListComponent',
		// 			inputs: {
		// 				colouredHeading: new KVLHeading('Installation Summary', 'secondary'),
		// 				data$: 'installation_summary',
		// 				itemMargin: '0 0 35px 0'
		// 			}
		// 		},
		// 		{
		// 			component: 'FLXKeyValueListComponent',
		// 			inputs: {
		// 				colouredHeading: new KVLHeading('Installer Details', 'secondary'),
		// 				data$: 'installer_details',
		// 				itemMargin: '0 0 35px 0'
		// 			}
		// 		}
		// 	]
		// }
	};
};
