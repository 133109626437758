import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXSetExcessComponent } from './set-excess.component';
import { FLXDatepickerModule, FLXFormsModule, FLXTimePickerModule, FLXHeadingsModule, FLXGlowLineComponent, FLXGlowLineModule, FLXIconModule } from '@flexus/ui-elements';

@NgModule({
	declarations: [FLXSetExcessComponent],
	imports: [CommonModule, FLXDatepickerModule, FLXFormsModule, FLXTimePickerModule, FLXHeadingsModule, FLXGlowLineModule, FLXIconModule],
	exports: [FLXSetExcessComponent]
})
export class FLXSetExcessModule {}
