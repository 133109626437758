import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FLXImageUploadCardComponent } from './image-upload-card.component';
import { FLXIconModule, FLXFormsModule } from '@flexus/ui-elements';

@NgModule({
	declarations: [FLXImageUploadCardComponent],
	imports: [CommonModule, FLXIconModule, FLXFormsModule],
	exports: [FLXImageUploadCardComponent]
})
export class FLXImageUploadCardModule {}
