import * as fromActions from './all-info.action';

export interface AllInfoState {}

const initialState: AllInfoState = {};

export function allInfoReducer(state: AllInfoState = initialState, action: fromActions.allInfoActions) {
	switch (action.type) {
		case fromActions.GET_ALL_INFO_SUCCESS: {
			return action.payload;
		}
		default:
			return state;
	}
}
