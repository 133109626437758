import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'flexus-internal-assessor-jobs-summary',
	templateUrl: './internal-assessor-jobs-summary.component.html',
	styleUrls: ['./internal-assessor-jobs-summary.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXInternalAssessorJobsSummaryComponent {
	jobList$: Observable<{ title: string; keyValueList$: Observable<Record<string, unknown>> }[]>;

	@Input() set jobs$(values$: Observable<any[]>) {
		this.jobList$ = values$.pipe(
			map(values =>
				values.map(value => {
					const { skill, ...job } = value;
					return {
						title: skill,
						keyValueList$: of(job)
					};
				})
			)
		);
	}
}
