<div class="text-center image-modal" *ngIf="mimeType != 'pdf'">
	<div (click)="closePreview()" class="close-icon"><flx-icon [type]="'remove'" color="primary" [isClickable]="false"></flx-icon></div>
	<div class="image-modal-content"><img [src]="source" (click)="closePreview()" /></div>
</div>

<div class="pdf-modal" *ngIf="mimeType == 'pdf'">
	<div class="pdf-meta">
		<span class="caption"> {{ filename }} </span>
		<div class="file-preview-actions">
			<span class="file-list-download">
				<a [href]="createDownload(base64Source)" download="{{ filename }}"> <flx-icon [type]="'download'" color="primary" [isClickable]="false"></flx-icon> </a>
			</span>
			<span>
				<a (click)="closePreview()"> <flx-icon [type]="'remove'" color="primary" [isClickable]="false"></flx-icon> </a>
			</span>
		</div>
	</div>

	<div class="pdf-modal-content"><flx-file-preview [source]="source" [mimeType]="mimeType" [base64Source]="base64Source"> </flx-file-preview></div>
</div>
