import { Component, OnInit, Input, } from '@angular/core';
import { BigFormService, ManifestController, ModalService, SetNextNode, getSelectedItem } from '@flexus/core';
import {  Subscription,  } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { AUTOPAY_CLAIM_STATUSES } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';

export interface Reason {
	id: number;
	reason: string;
	buttontext: string;
	fixnode: string;
}
@Component({
	selector: 'flx-autopay-decline-fixer',
	templateUrl: 'autopay-decline-fixer.component.html',
	styleUrls: ['autopay-decline-fixer.component.scss']
})
export class FLXAutoPayFixerComponent implements OnInit {
	// variables
	fixautopay_heading = 'Fix the following issues and then resubmit for payment';
	statesubscription: Subscription;
	state;

	@Input() autopay_fail_reason$;
	reason;
	buttontext;
	nextnodetext;
	validation_or_qa: any[] = [331, 335];
	teamlead_to_fix_array: any[] = [
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_07,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_11,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_12,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_13,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_15,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_16,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_17,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_22,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_23
	];
	peripheral_declines: any[] = [
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_29,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_30,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_31,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_32,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_33,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_34
	];

	constructor(private _controller: ManifestController<any>, private _modal: ModalService, public _bf: BigFormService, private _http: HttpClient, private _store: Store) {}

	ngOnInit() {
		this.statesubscription = this._store
			.select(getSelectedItem)
			.pipe(
				skipWhile(res => !res),
				take(1),
				map(job => {
					this.state = job?.state;
					console.log('STATE RESULTS', this.state);
				})
			)
			.subscribe();

		this.autopay_fail_reason$
			.pipe(
				skipWhile(x => !x),
				take(1),
				map(data => data)
			)
			.pipe()
			.subscribe(res => {
				this.reason = res;
				console.log('setting text');
				this.setButtonTextAndNextNode(this.reason);
				return res;
			});
	}
	navigateToFixNode() {
		this._controller.dispatch(new SetNextNode(`${this.nextnodetext}`));
	}
	setButtonTextAndNextNode(reason: string) {
		this.buttontext = '';
		this.nextnodetext = '';

		if (this.teamlead_to_fix_array.includes(reason) && !this.validation_or_qa.includes(this.state)) {
			this._modal.openModalDirectly((instance: any) => {
				let teamleadmessage;
				switch (reason) {
					case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_07:
						{
							teamleadmessage = [
								'The team leader will need to return to site to take the required photographs.',
								'',
								' Please make sure all other issues are corrected before sending to the team lead.',
								'',
								'',
								'',
								''
							];
						}
						this.buttontext = 'Send to team';
						this.nextnodetext = 'FixProblem';
						break;
					case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_11:
						{
							teamleadmessage = [
								'The team leader will need to return to site to replace the LNB with a device that is valid.',
								'',
								' Please make sure all other issues are corrected before sending to the team lead.',
								'',
								'',
								'',
								''
							];
						}
						this.buttontext = 'Send to team';
						this.nextnodetext = 'FixProblem';
						break;
					case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_12:
						teamleadmessage = [
							'The team leader will need to return to site to ensure that a valid unclaimed QR code is used.',
							'',
							' Please make sure all other issues are corrected before sending to the team lead.',
							'',
							'',
							'',
							''
						];
						this.buttontext = 'Send to Team';
						this.nextnodetext = 'FixProblem';
						break;

					case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_13:
						teamleadmessage = [
							'The team leader will need to return to site to use a Switch, Dish, and Bracket with a QR Code that is valid.',
							'',
							' Please make sure all other issues are corrected before sending to the team lead.',
							'',
							'',
							'',
							''
						];
						this.buttontext = 'send to team';
						this.nextnodetext = 'SetAppointment';
						break;

					case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_15:
						teamleadmessage = [
							'The team leader will need to return to site to use a QR Code that is valid.',
							'',
							' Please make sure all other issues are corrected before sending to the team lead.',
							'',
							'',
							'',
							''
						];
						this.buttontext = 'send to team';
						this.nextnodetext = 'SetAppointment';
						break;
					case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_16:
						teamleadmessage = [
							'The team leader will need to return to site to use a Dish QR Code that is valid.',
							'',
							' Please make sure all other issues are corrected before sending to the team lead.',
							'',
							'',
							'',
							''
						];
						this.buttontext = 'send to team';
						this.nextnodetext = 'SetAppointment';
						break;
					case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_17:
						teamleadmessage = [
							'The team leader will need to return to site to use a Switch QR Code that is valid.',
							'',
							' Please make sure all other issues are corrected before sending to the team lead.',
							'',
							'',
							'',
							''
						];
						this.buttontext = 'send to team';
						this.nextnodetext = 'SetAppointment';
						break;
					case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_22:
						teamleadmessage = [
							'The team leader will need to return to site to use a Bracket with a QR Code that is valid.',
							'',
							' Please make sure all other issues are corrected before sending to the team lead.',
							'',
							'',
							'',
							''
						];
						this.buttontext = 'send to team';
						this.nextnodetext = 'SetAppointment';
						break;
					case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_23:
						teamleadmessage = [
							'The team leader will need to return to site to use a LNB, Dish, and Bracket with a QR Code that is valid.',
							'',
							' Please make sure all other issues are corrected before sending to the team lead.',
							'',
							'',
							'',
							''
						];
						this.buttontext = 'send to team';
						this.nextnodetext = 'SetAppointment';
						break;
				}
				instance.type = 'warning';
				instance.heading = 'Please note';
				instance.setMessage(teamleadmessage),
					(instance.navButtons = [
						{
							text: 'cancel',
							linkType: 'close'
						},
						{
							text: 'send to the team leader',
							linkType: 'close',
							clickHandler: async (_ev: any) => {
								try {
									this._controller.dispatch(new SetNextNode('SetAppointment'));
								} catch (error) {
									console.log('An error occurred');
								}
							}
						}
					]);
			});
		} else if (this.teamlead_to_fix_array.includes(reason) && this.state === 331) {
			this.buttontext = 'fix qr codes';
			this.nextnodetext = 'QRCodeFixesToFSIA';
		} else if (this.teamlead_to_fix_array.includes(reason) && this.state === 335) {
			this.buttontext = 'fix qr codes';
			this.nextnodetext = 'QRCodeFixesToFSIA';
		} else if (this.peripheral_declines.includes(reason) && this.state === 339) {
			this.buttontext = 'peripheral issues';
			this.nextnodetext = 'PeripheralFixDecision';
		} else {
			switch (reason) {
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_02: {
					this.buttontext = 'fix decoder issue';
					this.nextnodetext = 'FixVoucherNumber';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_03: {
					this.buttontext = 'link device to voucher';
					this.nextnodetext = 'LinkDevice';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_04: {
					this.buttontext = 'activate device';
					this.nextnodetext = 'FSIALogin';
					break;
				}

				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_05: {
					this.buttontext = 'fix voucher number';
					this.nextnodetext = 'FixVoucherNumber';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_06: {
					this.buttontext = 'fix voucher number';
					this.nextnodetext = 'FixVoucherNumber';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_09: {
					this.buttontext = 'fix accreditation number';
					this.nextnodetext = 'InstallerNotAccredited';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_10: {
					this.buttontext = 'fix qualification number';
					this.nextnodetext = 'TechnicianNotQualified';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_18: {
					this.buttontext = 'fix issue';
					this.nextnodetext = 'SchedulerTrainingRequired';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_19: {
					this.buttontext = 'fix issue';
					this.nextnodetext = 'SchedulerTrainingRequired';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_20: {
					this.buttontext = 'fix issue';
					this.nextnodetext = 'SchedulerTrainingRequired';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_21: {
					this.buttontext = 'fix issue';
					this.nextnodetext = 'SchedulerTrainingRequired';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_24: {
					this.buttontext = 'resubmit / finalise';
					this.nextnodetext = 'OldClaim';
					break;
				}
				case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_35: {
					this.buttontext = 'view the problem';
					this.nextnodetext = 'UnhandledError';
					break;
				}
			}
		}
	}
}
