import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { SIL_97 } from './SIL_97';
import { skipWhile, take, map } from 'rxjs/operators';

export const SIL_32: Flow_0_0_2 = {
	id: '32',
	name: 'TL_en_route',
	itemType: 'flow',
	fetchLevel1And2: SIL_97.fetchLevel1And2,
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					if (itemOne) {
						let title = 'Team Lead en route';
						if (itemOne?.loan_information?.mavenclaimnumber) {
							title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
						}
						if (itemOne.applicant?.surname) {
							title += ` - ${itemOne.applicant?.surname}`;
						}

						return title;
					} else {
						return 'Team Lead en route';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		...SIL_97?.footer
	},
	instructions: Object.assign({}, SIL_97?.instructions, {
		viewRoles: {
			0: 'Wait for TL to get onsite'
		}
	}),
	actionPanel: SIL_97?.actionPanel,
	serverCalls: {
		...SIL_97?.serverCalls
	},
	startNode: 'AssessorOverview',
	nodes: {
		...SIL_97?.nodes
	},
	bigFormToStoreMapper: {
		...SIL_97?.bigFormToStoreMapper
	}
};
