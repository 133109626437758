import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getSubmissionData } from '@flexus/core';
import { CustomValidators } from '@flexus/utilities';
import { SINGLE_FILE_UPLOAD_SINGLE_PURPOSE } from './single_file_upload_single_purpose';
import { take, switchMap, map, skipWhile, filter, first, pluck } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { UntypedFormControl } from '@angular/forms';
import { of } from 'rxjs';

export const BET_222: Flow_0_0_2 = {
	id: '222',
	name: '',
	itemType: 'flow',
	header: {
		title: 'Upload Invoice ',
		controls: () => () => []
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {
		// Clear out key value list from previous flow
		keyValueList: {
			errorMessage: '',
			directCall: () => {
				return of([]);
			}
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			initFormFields: (bf, item, inst, sq, store) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(27));
				bf.bigForm.addControl('purpose', new UntypedFormControl('invoice'));
				bf.bigForm.addControl('current_state', new UntypedFormControl(''));

				store
					.select(getFullItemTwo)
					.pipe(filter(x => !!x, take(1)))
					.pipe(take(1))
					.subscribe(job => {
						bf.patchValues({ current_state: job?.state });
					});
			},
			component: {
				children: [
					{
						component: 'FLXKeyValueDisplayWithInstructionsComponent',
						inputs: {
							title: 'Claim Details',
							instructions: [''],
							keyValueList$: 'keyValueList',
							title$: 'title'
						}
					}
				]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Error getting keyValueList for node: Summary. in state 222',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemOne).pipe(
							skipWhile(x => !x),
							take(1),
							map(claim => {
								const clientName = {
									first_name: claim && claim?.applicant && claim?.applicant?.first_name ? claim?.applicant?.first_name : '--',
									surname: claim && claim?.applicant && claim?.applicant?.surname ? claim?.applicant?.surname : '--'
								};

								const infoPackOne = {
									'Claim Type': claim && claim?.loan_information ? claim?.loan_information.claimtype : '',
									Address: claim ? claim?.address : '',
									'Client Name': `${clientName.first_name} ${clientName.surname}`,
									'Contact Number': claim && claim?.loan_information ? claim?.loan_information.contactnumber : '',
									'Cell Number': claim && claim?.loan_information ? claim?.loan_information.cellnumber : '',
									'What Matters': claim && claim?.loan_information ? claim?.loan_information.whatmatters : ''
								};

								return [infoPackOne];
							})
						);
					}
				},
				dataFiles: {
					errorMessage: 'No invoice found!',
					ignoreFalseError: true,
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							filter(x => !!x),
							first(itemTwo => itemTwo !== null || itemTwo !== undefined),
							switchMap(itemTwo => {
								return http
									.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
										job_id: itemTwo?.id,
										return_type: 1
									})
									.pipe(
										map(value => {
											bf.bigForm.addControl('invoice', new UntypedFormControl(true, CustomValidators.contains([true])));
											return value.payload;
										})
									);
							})
						);
					}
				}
			},
			inputs: {
				fileDoesNotExistMessage: 'No invoice found. Please upload one.',
				inputHeading: 'View / Upload Invoice',
				subHeading: 'Upload and check invoice'
			},
			checkValidityForFields: ['invoice'],
			navs: [
				{ text: 'Upload Invoice', nextNode: 'UploadDocument' },
				{
					text: 'Continue',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq, bf, controller) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										switchMap(job => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, {
												job_id: job?.id,
												new_state: 27,
												current_state: 222
											});
										})
									);
							}
						}
					}
				}
			]
		},
		...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes,
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {}
};
