import { Component, OnDestroy, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BigFormService, getAllInfo, getCurrentUser, getFullItemTwo, NetworkService } from '@flexus/core';
import { SelectListOptionDisableCriteria } from '@flexus/ui-elements';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter, map, pluck, skipWhile, take } from 'rxjs/operators';

@Component({
	selector: 'flx-assign-teamleader',
	templateUrl: './assign-team-leader.component.html',
	styleUrls: ['./assign-team-leader.component.scss']
})
export class AssignTLComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() tlListData$;
	@Input() jobLocationData$;
	@Input() teamleaderLocations$;
	@Input() client$;
	@Input() installationDetail$;
	displayCollectorMessage$: Observable<boolean>;
	client;
	subHeading;
	data = of({ key: 'value', key1: 'value' });

	// multichoiceCollectorMessage: string = 'Any person with Team Leader or Collector role can use the handset application flow to purchase the required replacement devices';

	private _includeUserDisplay = false;
	installation_sub: Subscription;
	@Input() get includeUserDisplay(): boolean {
		return this._includeUserDisplay;
	}
	set includeUserDisplay(value: boolean) {
		if (value) {
			this._includeUserDisplay = value;
		}
	}

	private _disableOptionWhen: SelectListOptionDisableCriteria;
	@Input() get disableOptionWhen(): SelectListOptionDisableCriteria {
		return this._disableOptionWhen;
	}
	set disableOptionWhen(value: SelectListOptionDisableCriteria) {
		if (value) {
			this._disableOptionWhen = value;
		}
	}

	public formattedData$;

	private networkCheckSubscription: Subscription;

	constructor(public _store: Store<any>, public bf: BigFormService, private networkService: NetworkService, private _cd: ChangeDetectorRef) {
		this.notifyNetworkStatus();
	}

	isOffline = false;
	offlineMessage = 'You are offline. Please come back to assign a team leader when you are back online.';
	ngOnInit() {
		// this.installationDetail$.pipe().subscribe();
		this.client$ = this._store
			.select(getCurrentUser)
			.pipe(
				skipWhile(x => !x),
				take(1),
				map(res => res as any)
			)
			.pipe(
				map(data => {
					this.client = data?.client;
					if (this.client === 'multichoice') {
						this.subHeading = 'Any person with Team Leader or Collector role can use the handset application flow to purchase the required replacement devices.';

						this.displayCollectorMessage$ = of(true);
					} else {
						this.subHeading = null;
					}
				})
			)
			.subscribe();
		if (this.client === 'multichoice') {
			this.getInstallationDetails();
		}
	}

	ngAfterViewInit() {
		this.formattedData$ = this.tlListData$.pipe(
			filter(x => !!x),
			take(1),
			map((teamleaders: any[]) =>
				teamleaders.map(tlItem => {
					return {
						display: tlItem.full_name,
						value: tlItem.id
					};
				})
			)
		);
		this._cd.detectChanges();
	}

	private notifyNetworkStatus() {
		this.networkCheckSubscription = this.networkService.isOnline.subscribe(isOnline => {
			if (isOnline) {
				this.isOffline = false;
			} else {
				this.isOffline = true;
			}
		});
	}
	private getInstallationDetails() {
		this.installationDetail$ = forkJoin([
			this._store.select(getFullItemTwo).pipe(
				skipWhile((result: any) => !result),
				take(1)
			),
			this._store.select(getAllInfo).pipe(
				filter((res: any) => !!res),
				pluck('appointment_types'),
				take(1)
			)
		]).pipe(
			map(([installation, appointment_types]: any) => {
				const street = installation?.address;
				const suburb = installation?.suburb;
				const appointment = installation?.appointment[0]?.range_start;
				const appointmenttype = installation?.appointment[0]?.appointment_type;
				let appointmentname;
				const contactperson = `${installation?.claim?.applicant?.first_name} ${installation?.claim?.applicant?.surname}`;
				for (let i = 0; i < appointment_types.length; i++) {
					if (appointmenttype === appointment_types[i].id) {
						appointmentname = appointment_types[i]?.name;
					}
				}
				const contactnumber = `${installation?.claim?.applicant?.contact_number}`;
				const notes = installation?.claim?.loan_information?.onsitenotes;

				const installationobject = {
					address: `${street}, ${suburb}`,
					'appointment date': `${moment(appointment)?.format(moment.HTML5_FMT.DATE)}`,
					'appointment time': `${appointmentname} ${moment(appointment)?.format(moment.HTML5_FMT.TIME)}`,
					'contact person': `${contactperson}`,
					'contact number': `${contactnumber}`,
					notes: notes
				};
				return [installationobject];
			})
		);
		this.installation_sub = this.installationDetail$.subscribe();
	}
	selectionHandler() {
		console.log('Selection makings');
	}
	ngOnDestroy() {
		if (this.networkCheckSubscription) {
			this.networkCheckSubscription.unsubscribe();
		}
		if (this.installation_sub) {
			this.installation_sub.unsubscribe();
		}
	}
}
