<ng-container *ngIf="currentTab$ | async">
	<flx-tabs [tabs]="tabs" [currentStep$]="currentTab$" [externalPages]="true" (outputEventStream)="handleTab($event)">
		<ng-container *ngFor="let job of jobInformation">
			<flx-nested-object-key-value-list
				id="{{ getViewJobID(job) }}"
				class="tab-content"
				*ngIf="(currentTab$ | async) === getViewJobID(job)"
				[objectInfo$]="jobObject$"
			></flx-nested-object-key-value-list>
		</ng-container>
	</flx-tabs>
	<flx-heading size="small" color="secondary" align="center">Contact Details</flx-heading>
	<flx-key-value-list [data$]="applicant$"></flx-key-value-list>
	<br />
	<br />
</ng-container>
