<table class="flx-calendar-table month">
	<thead class="flx-calendar-table-header">
		<tr>
			<th class="flx-calendar-table-header-divider" colspan="4"></th>
		</tr>
	</thead>
	<tbody
		flx-calendar-body
		[allowDisabledSelection]="true"
		[label]="_yearLabel"
		[rows]="_months"
		[todayValue]="_todayMonth"
		[selectedValue]="_selectedMonth"
		[labelMinRequiredCells]="2"
		[numCols]="4"
		[cellAspectRatio]="4 / 7"
		[activeCell]="_dateAdapter.getMonth(activeDate)"
		(selectedValueChange)="_monthSelected($event)"
	></tbody>
</table>
