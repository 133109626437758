import { Flow_0_0_2, getFullItemOne, getSelectedItemOne } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { take, map, skipWhile, switchMap, filter } from 'rxjs/operators';
import gql from 'graphql-tag';
import { flatten, values } from 'ramda';
import { forkJoin } from 'rxjs';
import { getAllInfoIndex } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export enum RepudiationState {
	'Repudiated' = 3,
	'Partially_Repudiated' = 7
}

export const SIL_71: Flow_0_0_2 = {
	id: '71',
	name: 'claim_repudiated',
	itemType: 'flow',
	fetchLevel1And2: true,
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(itemOne => !itemOne),
				map((itemOne: any) => {
					if (itemOne) {
						return `Claim Repudiated : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Claim Repudiated';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance =>
		setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	serverCalls: {
		logs: {
			serviceVariable: 'silService',
			functionName: 'getCallLogs',
			// responseSlice: 'payload',
			errorMessage: 'No call logs could be found!'
		}
	},

	instructions: {
		editRoles: {
			0: 'Claim repudiated'
		},
		viewRoles: {
			0: 'Claim repudiated'
		}
	},
	startNode: 'RepudiationConversation',
	nodes: {
		//Node 1 ************
		RepudiationConversation: {
			component: 'FLXFlatDynamicFormComponent',
			name: 'Record Conversation',
			showTabs: true,
			inputs: {
				heading: 'Repudiate',
				formControls: {
					0: {
						label: 'Record customer conversation',
						inputType: 'textarea',
						formControlName: 'customer_call'
					}
				},
				containerWidth: '40vw'
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				return store
					.select(getFullItemOne)
					.pipe(
						filter(x => !!x),
						take(1)
					)
					.subscribe(claim => {
						bf.addControl('customer_call', new UntypedFormControl(''));

						if (!bf.bigForm.get('customer_call')?.value && claim?.office_use !== null && claim?.office_use.repudiation !== null) {
							bf.bigForm.patchValue(claim?.office_use?.repudiation?.customer_call ? { customer_call: claim?.office_use.repudiation.customer_call } : { customer_call: '' });
						}
					});
			},
			navs: [
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'RepudiationLetterOptions',
					optIntoValidation: true,
					serverCalls: {
						logCall: {
							errorMessage: 'Could not log call',
							directCall: (http, store, sw, bf, cont, modal) => {
								const data = {
									claim_id: bf.bigForm.get('itemOne')?.value?.id,
									message: bf.bigForm.get('customer_call')?.value,
									channel: 3, // "Phone";
									reason: 3, // "Waste";
									direction: 3 // "Out-Bound"
								};
								return http.post(`${environment.api_url}v1/claim_action/add_call_log/`, { ...data });
							},
							followUpSuccessCalls: {
								logs: {
									serviceVariable: 'silService',
									functionName: 'getCallLogs',
									errorMessage: 'No call logs could be found!'
								}
							}
						}
					}
				}
			]
		},

		//Node 1c show the repudiation comments
		ViewComments: {
			component: 'FLXKeyValueListComponent',
			name: 'Claim Comments',
			showTabs: true,
			inputs: {
				heading: 'Claim Comments'
			},
			serverCalls: {
				data: {
					errorMessage: 'Unable to extract repudiation reasons from the jobs',
					directCall: (http, store, sq, bf) => {
						return forkJoin([
							store.select(getFullItemOne).pipe(
								filter(x => !!x),
								take(1)
							),
							store.pipe(take(1))
						]).pipe(
							map(([claim, storeObj]: any) => {
								let txt = {};
								if (claim && claim?.jobs !== undefined) {
									for (const job of claim?.jobs) {
										const myVal = getAllInfoIndex('sps', 'id', 'name', job?.sp, storeObj);
										txt = { ...txt, 'Service provider name': myVal };
										if (job.job_information) {
											if (job?.job_information?.circumstance_of_loss) {
												txt = {
													...txt,
													'What caused the loss': job?.job_information?.circumstance_of_loss
												};
											}
											if (job?.job_information?.repudiation_reason) {
												txt = {
													...txt,
													'Repudiation reason': job?.job_information?.repudiation_reason
												};
											}
										}
									}
								} else {
									txt = { SP: null };
								}
								return [txt];
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'RepudiationLetterOptions',
					optIntoValidation: true
				}
			]
		},

		//Node 2 ************
		RepudiationLetterOptions: {
			component: 'FLXFlatDynamicFormComponent',

			serverCalls: {
				selectOptions: {
					errorMessage: 'Unable to fetch Letter_keys',
					directCall: (http, store, StoreQuery) => {
						return StoreQuery.queryObject(
							gql`
								{
									allInfo {
										letter_key {
											id
											description
										}
									}
								}
							`,
							store
						).pipe(
							take(1),
							map(values),
							map<any, any>(flatten),
							// // at this point remove any items not active in the letter key
							// // if ends with --Not Active ... then dont include
							map((keys: any) => keys.filter(key => !key.description.endsWith('--Not Active'))),
							map(vals => ({ repudiationKeys: vals }))
						);
					}
				}
			},
			inputs: {
				formControls: {
					0: {
						label: 'Repudiation type',
						inputType: 'select',
						selectConfig: {
							placeHolder: 'Select a repudiation type',
							displayOptions: { displayKey: 'display', valueKey: 'value' },
							itemsOption: [
								{ display: 'Repudiated', value: 3 },
								{ display: 'Partial repudiation', value: 7 }
							]
						},
						width: '48%',
						formControlName: 'repudiation_type'
					},
					1: {
						label: 'Repudiation letter template to use',
						inputType: 'select',
						selectConfig: {
							placeHolder: 'Select a repudiation letter template',
							displayOptions: { displayKey: 'description', valueKey: 'id' },
							itemsOption: 'repudiationKeys'
						},
						width: '48%',
						formControlName: 'repudiation_letter'
					},
					2: {
						label: 'Email addresss to send to',
						inputType: 'input',
						width: '48%',
						formControlName: 'emailaddress'
					}
				},
				formLayout: 'stacked',
				containerWidth: '300px'
			},
			initFormFields: (bf, item, instance, sq, store) => {
				bf.addControl('new_state', new UntypedFormControl('73')); // if partial then go 74
				//set initial values for email
				return forkJoin([
					store.select(getFullItemOne).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getSelectedItemOne).pipe(
						skipWhile(x => !x),
						take(1)
					)
				]).subscribe(([fullItemOne, call2]: any[]) => {
					bf.addControl('repudiation_type', new UntypedFormControl(RepudiationState.Repudiated, [Validators.required]));
					bf.addControl('emailaddress', new UntypedFormControl(fullItemOne.loan_information.email, [Validators.required]));
					bf.addControl('repudiation_letter', new UntypedFormControl('', [Validators.required]));

					if (fullItemOne.office_use !== null && fullItemOne.office_use.repudiation !== null) {
						//check full or partial repudiate
						for (let job of call2.jobs) {
							//might not have job cards!! if upfront repudiate might need to cater for this
							if (job.valid_job !== 2 && job?.valid_job !== 102) {
								bf.bigForm.patchValue({ repudiation_type: RepudiationState.Partially_Repudiated });
								bf.bigForm.get('new_state')?.setValue('74');
							}
						}

						if (!bf.bigForm.get('repudiation_letter')?.value && fullItemOne?.office_use?.repudiation?.repudiation_letter) {
							bf.bigForm.patchValue({ repudiation_letter: fullItemOne.office_use.repudiation.repudiation_letter });
						}
					}
					bf.bigForm?.updateValueAndValidity();
				});
			},
			navs: [
				{
					text: 'Create Repudiation Letter',
					color: 'primary',
					optIntoValidation: true,
					nextNode: 'viewRepudiationLetter',
					serverFirst: true,
					serverCalls: {
						CreateRepudiationLetter: {
							errorMessage: 'Unable to create the repudiation letter',
							directCall: (http, store, sq, bf, crt, modal) => {
								return store.select(getFullItemOne).pipe(
									skipWhile((itemOne: any) => {
										return itemOne === null || itemOne === undefined;
									}),
									take(1),
									switchMap((claim: any) => {
										const clonedOfficeUse = JSON.parse(JSON.stringify(claim?.office_use ?? {}));
										const clonedRepudiation = JSON.parse(JSON.stringify(claim?.office_use?.repudiation ?? {}));
										const new_office_use = {
											...clonedOfficeUse,
											repudiation: {
												...clonedRepudiation,
												emailaddress: bf.bigForm.get('emailaddress')?.value,
												customer_call: bf.bigForm.get('customer_call')?.value,
												repudiation_type: bf.bigForm.get('repudiation_type')?.value,
												repudiation_letter: bf.bigForm.get('repudiation_letter')?.value
											}
										};

										return http
											.post<any>(environment.api_url + `v1/claim_action/create_repudiation`, {
												claim_id: claim?.id,
												final: 'N',
												sendletter: 'N',
												_optionalState: bf.bigForm.get('new_state')?.value,
												email: bf.bigForm.get('emailaddress')?.value,
												repudiation_type: bf.bigForm.get('repudiation_type')?.value, //repudiation_type: repType,
												letter_key: bf.bigForm.get('repudiation_letter')?.value, //myLetKey,
												repudiation_reason: 0,
												repudiation_sub_reason: 0,
												diagnosis: '--', //claim.office_use.repudiation.finalRepudiationReport,
												office_use: new_office_use
											})
											.pipe(
												map(data => {
													//place error checks here
													if (typeof data?.payload === 'function') {
														if (data?.payload.includes('Please provide a valid claim number')) {
															modal.openModalDirectly(instance => {
																instance.type = 'warning';
																instance.message = `Please provide a valid claim number: ${claim?.mid}. Maven reports that the supplied claim number does not have any repudiation registered against it.`;
																instance.navButtons = [
																	{
																		text: 'Back to workflow',
																		clickHandler: event => {
																			instance.router.navigate(['/workflow']);
																		},
																		linkType: 'close',
																		color: 'alert'
																	}
																];
															});
														}
													} else {
														//return the file data
														return data?.payload;
													}
												})
											);
									})
								);
							},
							followUpSuccessCalls: {
								RefetchClaim: {
									errorMessage: 'Unable to refetch the claim',
									serviceVariable: 'silService',
									functionName: 'getCurrentFullItemOne'
								}
							}
						}
					}
				}
			]
		},

		//Extension Nodes ************
		// ContinueToFlow 73(full repudiate) or 74(Partial repudiate)
		////////////////////////////////////////////////////////
		//Nodes for state 73 and 74
		//Node1 *************
		viewRepudiationLetter: {
			component: 'FileViewWithExtraComponent',
			inputs: {
				mimeType: 'pdf',
				fileDoesNotExistMessage: 'No preview available.'
			},
			serverCalls: {
				dataFiles: {
					errorMessage: 'Unable to fetch repudiation letter',
					ignoreFalseError: true,
					directCall: (http, store, sq, bf, crt, modal) => {

						return store.select(getSelectedItemOne).pipe(
							take(1),
							switchMap(itemOne => {
								return http.post<any>(environment.api_url + `v1/claim_action/get_claim/`, { claim_id: itemOne.id, full: true}).pipe(map(res => res.payload))
							}),
							switchMap((claim: any) => {
								return http
									.post<any>(environment.api_url + `v1/claim_action/preview_repudiation`, {
										claim_id: claim.id,
										letter_key: claim?.office_use?.repudiation?.repudiation_letter ?? '' //letKey,
									})
									.pipe(
										skipWhile(x => !x),
										map(data => {
											//place error checks here
											//There is no letter to preview at the moment, please try again later.
											//Please provide a valid claim number.
											if (data && !data?.success) {
												if (data?.payload.includes('Please provide a valid claim number')) {
													modal.openModalDirectly(instance => {
														instance.type = 'warning';
														instance.message = `Unable to fetch repudiation letter. Please provide a valid claim number: ${claim?.mid}. Maven reports that the supplied claim number does not have any repudiation registered against it.`;
														instance.navButtons = [
															{
																text: 'Back to workflow',
																clickHandler: event => {
																	instance.router.navigate(['/workflow']);
																},
																linkType: 'close',
																color: 'alert'
															}
														];
													});
												} else if (data?.payload.includes('There is no letter to preview at the moment')) {
													modal.openModalDirectly(instance => {
														instance.type = 'warning';
														instance.message = `Maven reports that the repudiation letter is not available yet for claim ${claim?.mid}. Please try again later`;
														instance.navButtons = [
															{
																text: 'Back to workflow',
																clickHandler: event => {
																	instance.router.navigate(['/workflow']);
																},
																linkType: 'close',
																color: 'alert'
															},
															{
																text: 'Continue anyway',
																//clickHandler: (event) => {
																//instance.router.navigate(['/workflow']);
																//},
																linkType: 'close',
																color: 'alert'
															}
														];
													});
												} else if (data?.payload.includes('The preview letter is no longer available, letter was sent to the client.')) {
													modal.openModalDirectly(instance => {
														instance.type = 'warning';
														instance.message = `The preview letter is no longer available, letter was sent to the client. ${claim?.mid}`;
														instance.navButtons = [
															{
																text: 'Continue',
																linkType: 'close',
																color: 'alert'
															}
														];
													});
												}
											} else {
												return { data: data?.payload };
											}
										})
									);
							})
						);
					}
				}
			},

			navs: [
				{
					text: 'Edit Letter',
					color: 'primary',
					nextNode: 'RepudiationLetterOptions',
					optIntoValidation: true
				},
				{
					text: 'Send Letter To Client',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						sendLetter: {
							errorMessage: 'Unable to send the repudiation letter',
							directCall: (http, store, sq, bf) => {
								return store.select(getSelectedItemOne).pipe(
									take(1),
									switchMap(itemOne => {
										return http.post<any>(environment.api_url + `v1/claim_action/get_claim/`, { claim_id: itemOne.id, full: true}).pipe(map(res => res.payload))
									})
								).pipe(
									switchMap((claim: any) => {
										return http
											.post<any>(environment.api_url + `v1/claim_action/finalise_repudiation`, {
												claim_id: claim?.id,
												sendletter: 'Y',
												final: 'N',
												email: claim?.office_use?.repudiation?.emailaddress ?? '',
												_optionalState: 173,
												repudiation_type: claim?.office_use?.repudiation?.repudiation_type ?? '',
												letter_key: claim?.office_use?.repudiation?.repudiation_letter ?? '',
												repudiation_reason: 0,
												repudiation_sub_reason: 0,
												diagnosis: '--'
											})
											.pipe(
												//catch any problems here
												map(x => {
													if (x.payload) {
														console.log({ x: x.payload });
													}
												})
											);
									})
								);
							},
							//},
							followUpSuccessCalls: {
								changeClaimState: {
									errorMessage: 'Unable to change the claim state',
									directCall: (http, store, sq, bf) => {
										// debugger;
										return store.select(getFullItemOne).pipe(
											skipWhile(x => !x),
											take(1),
											switchMap(claim => {
												// need to change the state to 55 if repudiation was upfront
												let canFinalise = false;
												if (!!claim && !!claim?.loan_information.upfrontrepudiation) {
													const val = `${claim?.loan_information.upfrontrepudiation}`?.toLowerCase();
													canFinalise = val === 'true' || '1' ? true : false;
												}
												if (canFinalise) {
													return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
														claim_id: claim?.id,
														new_state: 55
													});
												} else {
													return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
														claim_id: claim?.id,
														new_state: 173
													});
												}
											})
										);
									}
								}
							}
						}
					}
				}
			]
		},
		//submission Nodes ************
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},

	bigFormToStoreMapper: {
		customer_call: 'office_use.repudiation.customer_call',
		repudiation_type: 'office_use.repudiation.repudiation_type',
		repudiation_letter: 'office_use.repudiation.repudiation_letter',
		email: 'office_use.repudiation.emailaddress',

		new_state: 'new_state'
	}
};
