import { Component, OnInit, Input } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TabsData } from '@flexus/ui-elements';

export interface FileSummaryShape {
	claim: number;
	created: string;
	description: unknown;
	filename: string;
	id: number;
	job: number;
	on_maven: boolean;
	purpose: string;
	thumbnail: string;
	token: string;
}

@Component({
	selector: 'flx-view-photos',
	templateUrl: './view-photos.component.html',
	styleUrls: ['./view-photos.component.scss']
})
export class FLXViewPhotosComponent implements OnInit {
	@Input() location: string;
	@Input() heading: string;
	@Input() file$: Observable<FileSummaryShape[]>;
	@Input() photos$: Observable<FileSummaryShape[]>;

	constructor() {}

	ngOnInit() {}
}
