// import {} from 'googlemaps';

export interface LocationPickerConfig extends google.maps.MapOptions {
	scriptId?: string;
	url?: string;
}

export enum LocationPickerTheme {
	Light = 'light',
	Dark = 'dark'
}

export interface GoogleMapsScript {
	/** returns google.maps.LatLng */
	LatLng(lat: number, lng: number, noWrap?: boolean): void;
	/** returns google.maps.BicyclingLayer */
	BicyclingLayer(): void;
	/** returns google.maps.MapTypeRegistry */
	MapTypeRegistry(): void;
	/** returns google.maps.Map */
	Map(mapDiv: Element | null, opts?: google.maps.MapOptions): void;
	/** returns google.maps.Marker */
	Marker(opts?: google.maps.MarkerOptions): void;
	/** returns google.maps.TrafficLayer */
	TrafficLayer(opts?: google.maps.TrafficLayerOptions): void;
	/** returns google.maps.TransitLayer */
	TransitLayer(): void;
}
