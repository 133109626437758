import { Component, OnInit, Input, Inject } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface SilFileSummaryShape {
	claim: number;
	created: string;
	description: unknown;
	filename: string;
	id: number;
	job: number;
	on_maven: boolean;
	purpose: string;
	thumbnail: string; //b64
	token: string;
}

@Component({
	selector: 'flxap-view-documents',
	templateUrl: './view-documents.component.html',
	styleUrls: ['./view-documents.component.scss']
})
export class FLXAPViewDocumentsComponent implements OnInit {
	// selectedJobId: number;

	@Input() location: string;
	@Input() files$: Observable<SilFileSummaryShape[]>;
	@Input() quotations$: Observable<SilFileSummaryShape[]>;
	@Input() photos$: Observable<SilFileSummaryShape[]>;
	@Input() reports$: Observable<SilFileSummaryShape[]>;
	@Input() latestDocuments$: Observable<SilFileSummaryShape[]>;
	@Input() invoices$: Observable<SilFileSummaryShape[]>;
	geyserPhotos$: Observable<SilFileSummaryShape[]>;
	buildingPhotos$: Observable<SilFileSummaryShape[]>;

	@Input() selectDocsToView!: string[];

	constructor() {}

	ngOnInit() {
		if (this.selectDocsToView) {
			const inputArray = this.selectDocsToView;
			const observableArr = ['photos', 'reports', 'quotations', 'invoices', 'latestDocuments'];
			for (let i = 0; i <= inputArray.length - 1; i++) {
				for (let j = 0; j <= observableArr.length - 1; j++)
					if (inputArray[i] === observableArr[j]) {
						const index = observableArr.indexOf(observableArr[j]);
						observableArr.splice(index, 1);
					}
			}
			if (observableArr.includes('photos')) {
				this.photos$ = null;
			}
			if (observableArr.includes('invoices')) {
				this.invoices$ = null;
			}
			if (observableArr.includes('quotations')) {
				this.quotations$ = null;
			}
			if (observableArr.includes('reports')) {
				this.reports$ = null;
			}
			if (observableArr.includes('latestDocuments')) {
				this.latestDocuments$ = null;
			}
		} else {
			this.geyserPhotos$ = this.photos$.pipe(map((files: Array<SilFileSummaryShape>) => files.filter(entry => entry.purpose?.toLowerCase() === 'geyser')));
			this.buildingPhotos$ = this.photos$.pipe(map((files: Array<SilFileSummaryShape>) => files.filter(entry => entry.purpose?.toLowerCase() === 'building')));
		}

		// this.invoices$ = this.files$.pipe(
		//   filter(value => Array.isArray(value)),
		//   map((files: Array<SilFileSummaryShape>) => files.filter(entry => entry.purpose.toLowerCase() === 'invoice'))
		// );
	}
}
