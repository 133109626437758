import { Flow_0_0_2, getAllInfo, getFullItemOne, getSelectedItemOne, getData, SetNextNode, BigFormService } from '@flexus/core';
import { filter, map, pluck, skipWhile, switchMap, take } from 'rxjs/operators';
import { combineLatest, forkJoin, iif, of, Subscription } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { getErrors } from '@flexus/error-handler';
import { Store } from '@ngrx/store';
import { values } from 'ramda';
import { HttpClient } from '@angular/common/http';
import gql from 'graphql-tag';

export const SIL_CANCEL_CLAIM: Flow_0_0_2 = {
	fetchLevel1And2: true,
	id: 'CancelClaim',
	itemType: 'flow',
	name: 'Cancel Claim',
	excludeForOnlyStates: [169],
	header: {
		title: store => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Cancel Claim : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Cancel Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'CancelClaim',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: () => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		CancelClaim: {
			serverCalls: {
				cancelclaimdata: {
					errorMessage: 'No claim data returned',
					directCall: (http, store) => {
						return combineLatest([
							store.select(getFullItemOne).pipe(
								skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
								take(1)
							),
							store.pipe(take(1))
						]).pipe(
							map(([claimjob, stor]: any) => {
								return { claimjob, stor };
							})
						);
					}
				}
			},
			component: 'FLXCancelClaimComponent',
			inputs: {
				subtitle: 'Do you want to permanently remove the claim?'
			},
			mockContextData: {},
			checkValidityForFields: [],
			navs: [
				{
					text: 'Remove this claim',
					nextNode: 'CancelClaimReason',
					color: 'secondary',
					linkType: 'submit'
				}
			]
		},
		CancelClaimReason: {
			serverCalls: {
				selectOptions: {
					errorMessage: 'Could not get cancel reasons',
					directCall: (_http: HttpClient, _store: Store, _sq: any) => {
						return _sq
							.queryStore(
								gql`
									{
										allInfo {
											config_options {
												general {
													cancel_reasons
												}
											}
										}
									}
								`
							)
							.pipe(
								// skipWhile((reasons: any) => !reasons),
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1),
								map(values),
								map(value => value?.[0]),
								map(vals => ({ cancelreasons: vals }))
							);
					}
				}
			},
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				heading: '',
				subtitle: 'Do you want to permanently remove the claim?',
				formControls: {
					0: {
						label: 'Select the reason for cancellation',
						inputType: 'select',
						selectConfig: {
							displayOptions: { displayKey: 'name', valueKey: 'id' },
							itemsOption: 'cancelreasons',
							placeholder: 'Reason for cancellation',
							addConditionalForm: {
								onControlSelectedValue: 9,
								inputType: 'textarea',
								formControlName: 'cancelclaim_reason_details',
								width: '200px',
								label: 'Please provide more details (this will not be sent to the customer)',
								placeholder: 'Custom Reason'
							}
						},
						formControlName: 'cancelclaim_reason'
					}
				},
				formLayout: 'stacked',
				containerWidth: '30vw',
				headingSize: 'medium',
				headingWeight: 'light',
				headingType: 'creation',
				headingMargin: '20px 0 35px 0'
			},
			navs: [
				{
					text: 'Remove Claim',
					color: 'secondary',
					nextNode: 'Decision',
					// linkType: 'submit',
					serverFirst: true,
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (_instance: any) => {
							_instance.messageOnly = true;
							_instance.type = 'warning';
							_instance.heading = 'Warning';
							_instance.setMessage([
								'You are about to cancel this claim.',
								'',
								'An SMS will be sent to the customer informing them of the reason and the claim will be closed on the workflow.',
								'',
								'Are you sure you wish to cancel?',
								'',
								''
							]),
								(_instance.navButtons = [
									{
										text: 'take me back',
										color: 'secondary',
										linkType: 'close'
									},
									{
										text: 'confirm cancellation',
										color: 'secondary',
										linkType: 'submitThenNext',
										nextNode: 'SubmissionSuccess',
										serverCalls: {
											cancelResponse: {
												errorMessage: 'Claim was not removed',
												directCall: (_http: HttpClient, _store: Store, sq: any, bf: BigFormService) => {
													return forkJoin([
														_store.select(getSelectedItemOne).pipe(
															skipWhile(x => !x),
															take(1),
															map(res => res as any)
														),
														_store.select(getAllInfo).pipe(
															skipWhile(x => !x),
															take(1),
															pluck('config_options'),
															map(res => res as any)
														)
													]).pipe(
														switchMap(([claim, config]: any) => {
															let claim_id;
															let cancelreason;
															const cancelreasons: any[] = config?.general?.cancel_reasons;

															cancelreasons.map((reason: any) => {
																if (bf.bigForm.get('cancelclaim_reason')?.value === reason?.id) {
																	cancelreason = reason.name;
																}
															});
															const reason_details = bf.bigForm.get('cancelclaim_reason_details')?.value;
															claim_id = claim?.id;

															const data = {
																claim_id: claim_id,
																reason: cancelreason,
																reason_id: bf.bigForm.get('cancelclaim_reason')?.value,
																cancel_reason_desc: reason_details
															};
															// return of({});
															return _http.post(`${environment.api_url}v1/claim_action/cancel_claim/`, data);
														})
													);
												}
											}
										}
									}
								]);
						}
					}
					// serverCalls: {
					// 	cancelResponse: {
					// 		errorMessage: 'Claim was not removed',
					// 		directCall: (http, store, sq, bf) => {
					// 			return forkJoin([
					// 				store.select(getSelectedItemOne).pipe(
					// 					skipWhile(x => !x),
					// 					take(1),
					// 					map(res => res as any)
					// 				),
					// 				store.select(getAllInfo).pipe(
					// 					skipWhile(x => !x),
					// 					take(1),
					// 					pluck('claim_cancel_reasons'),
					// 					map(res => res as any[])
					// 				)
					// 			]).pipe(
					// 				switchMap(([claim, reasons]) => {
					// 					const cancelreasons = reasons;
					// 					let claim_id;
					// 					claim_id = claim?.id;
					// 					const reason: any = bf.bigForm.get('cancelclaim_reason')?.value;

					// 					const data = {
					// 						claim_id: claim_id,
					// 						reason: reason
					// 					};
					// 					return http.post(`${environment.api_url}v1/claim_action/cancel_claim/`, data);
					// 				})
					// 			);
					// 		}
					// 	}
					// }
				}
			],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('claim_id', new UntypedFormControl(null));
				bf.addControl('cancelclaim_reason', new UntypedFormControl(null));
			}
		},

		Decision: {
			nodeType: 'decision',
			decisions: {
				isCancelSuccess: (navs, store, modal, controller) => {
					let errorSub, successSub: Subscription;

					errorSub = store
						.select(getErrors)
						.pipe(
							pluck('cancelResponse'),
							skipWhile(x => !x),
							take(1)
						)
						.subscribe(_ => {
							controller.dispatch(new SetNextNode('CancelClaim'));
							if (successSub) {
								successSub.unsubscribe();
							}
						});

					successSub = store
						.select(getData)
						.pipe(
							pluck('cancelResponse'),
							skipWhile(x => !x),
							take(1)
						)
						.subscribe(_ => {
							controller.dispatch(new SetNextNode('SubmissionSuccess'));
							if (errorSub) {
								errorSub.unsubscribe();
							}
						});
				}
			},
			navs: []
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		claim_id: [
			(cid, storeObj) => {
				cid = storeObj['selectedContext'].selectedItemOne?.id;
				return cid;
			},
			'claim_id'
		],
		reason: 'Cancel'
	}
};
