export const getInterstateComments = (message, storeObj, bf) => {
	const toReturn = [];
	const date = new Date();
	const newInfo = {
		message: bf.alternate_qa ? bf.alternate_qa : message,
		author_id: bf.author,
		author_name: bf.author_name,
		currentState: bf.currentState,
		nextState: bf.new_state
	};

	if (bf.issue_reason_value) {
		newInfo['reason'] = bf.issue_reason_value;
	}

	if (bf.qa_type) {
		newInfo['type'] = bf.qa_type;
	}

	const temp = storeObj['selectedContext']?.fullItemTwo?.job_information?.interstate_comments;

	if (temp) {
		Array.isArray(temp) ? toReturn.push(...temp, newInfo) : toReturn.push(temp, newInfo);
		console.log({ toReturn });
	} else {
		toReturn.push(newInfo);
	}
	return toReturn;
};
