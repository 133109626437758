import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, forkJoin } from 'rxjs';
import { BucketFilterConfig } from './interfaces';
import { take} from 'rxjs/operators';
import { SetFilterDataField, SetFilterSet, ResetFilters, getFilterData, ResetServerSearch, getManifestSearchData, ManifestController  } from '@flexus/core';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'flx-bucket-filter',
	styleUrls: ['./bucket-filter.component.scss'],
	templateUrl: './bucket-filter.component.html'
})
export class BucketFilterComponent implements OnInit, OnDestroy {
	configSub: Subscription;

	configuration: BucketFilterConfig;
	selectedBucket: string;

	get filterList() {
		return Object.keys(this.configuration.filters)?.sort();
	}

	constructor(private _store: Store<any>, private controller: ManifestController<any>) {}

	selectBucket(bucketName: string) {
		this._store.dispatch(new SetFilterDataField({ key: 'selectedBucket', data: this.selectedBucket }));
		this._store.dispatch(new SetFilterDataField({ key: 'source', data: 'bucket' }));
		this._store.dispatch(
			new SetFilterSet({
				filterSet: [
					{
						filt: this.configuration.filters[bucketName],
						filterName: '',
						filterValue: [],
						filterType: 'bucket'
					}
				]
			})
		);
		this.selectedBucket = bucketName;
		// debugger;
	}

	clearBucket() {
		this._store.dispatch(new ResetFilters());
		this._store.dispatch(new SetFilterDataField({ key: 'source', data: 'none' }));
		this.selectedBucket = undefined;
	}

	ngOnInit() {
		// this._store
		//   .select(getActiveOrganization)
		//   .pipe(take(1))
		//   .subscribe((org) => (this.activeOrg = org));

		// __  Clear all filters when you start buckets
		this._store.dispatch(new SetFilterDataField({ key: 'filters', data: {} }));
		this._store.dispatch(new SetFilterSet({ filterSet: [] }));
		this._store.dispatch(new SetFilterDataField({ key: 'source', data: 'none' }));
		this._store.dispatch(new SetFilterDataField({ key: 'searchField', data: {} }));
		this._store.dispatch(new ResetServerSearch());

		this.configSub = forkJoin([this.controller.select(getManifestSearchData)?.pipe(take(1)), this._store.pipe(take(1)), this.controller.stateObservable.pipe(take(1))])?.subscribe(
			([searchFunc, store, controller]: any) => {
				this.configuration = { filters: searchFunc(store, controller)?.buckets.mapper };
			}
		);
		// this.configSub = this._store
		//   .select(getFilterData)
		//   .pipe(pluck('bucket-filter-config'))
		//   .subscribe((config: BucketFilterConfig) => {
		//     this.configuration = config;
		//   });

		this._store
			.select(getFilterData)
			.pipe(take(1))
			.subscribe(data => {
				const { selectedBucket, source } = data;
				this.selectedBucket = selectedBucket;
			});
	}

	ngOnDestroy() {
		if (this.configSub) this.configSub.unsubscribe();
	}
}
