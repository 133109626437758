import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { OfflineService } from './offline.service';

@Injectable({ providedIn: 'root'})
export class AllInfoService {
	constructor(private http: HttpClient, private offlineService: OfflineService) {}

	getAllInfo(endpoint) {
		if (window.navigator.onLine) {
			if (Array.isArray(endpoint)) {
				const allInfoCalls = [];
				endpoint.forEach(_endpoint => {
					allInfoCalls.push(this.http.get<any>(_endpoint));
				});
				return forkJoin(allInfoCalls);
			} else {
				return this.http.get<any>(endpoint);
			}
		} else {
			return this.offlineService.getAllInfo();
		}
	}

	getInfo(endpoint, args) {
		return this.http.post<any>(endpoint, args);
	}
}
