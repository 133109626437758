export interface ERSingleFileSelectEvent {
	type: 'fileAdded' | 'fileRejected';
	file: File;
	reason?: string;
}

export interface ERSingleFileSelectConfig {
	allowedFileTypes?: string[];
	maxFileSize?: FileSize;
}

export interface FileItem {
	nativeFile: File;
	id?: string;
	base64: string;
	type?: string;
}

export enum FileSizeUnit {
	Bytes = 'B',
	KibiBytes = 'KiB', // 1 * 1024
	KiloBytes = 'KB', // 1 * 1000
	MebiBytes = 'MiB', // 1 * 1000 * 1000
	MegaBytes = 'MB', // 1 * 1024 * 1024
	GibiBytes = 'GiB', // 1 * 1024 * 1024 * 1024
	GigaBytes = 'GB', // 1 * 1000 * 1000 * 1000
	TebiBytes = 'TB', // 1 * 1024 * 1024 * 1024 * 1024
	TeraBytes = 'TiB' // 1 * 1000 * 1000 * 1000 * 1000
}

export interface FileSize {
	unit: FileSizeUnit;
	size: number;
}

export interface FileQueue {
	file: FileItem;
	fileKey: string;
}
