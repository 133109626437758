import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UIElementsModule } from '@flexus/ui-elements';
import { UICompositesModule } from '@flexus/ui-composites';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { PipesModule } from '@flexus/pipes';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { FLXAPJobRequestPingDetailComponent } from './job-request-ping-detail.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

@NgModule({
	declarations: [FLXAPJobRequestPingDetailComponent],
	exports: [FLXAPJobRequestPingDetailComponent],
	imports: [CommonModule, GoogleMapsModule, HttpClientModule, HttpClientJsonpModule, ReactiveFormsModule, UIElementsModule, UICompositesModule, NgSlimScrollModule, PipesModule]
})
export class FLXAPJobRequestPingDetailModule {}
