import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BackFromOfflineService {
	constructor() {}

	get backFromOffline$() {
		const state = [true, true];
		return interval(100).pipe(
			map(() => window.navigator.onLine),
			distinctUntilChanged(),
			map(online => {
				state.shift();
				state.push(online);
				return state;
			}),
			map(([st1, st2]) => st1 === false && st2 === true)
		);
	}
}
