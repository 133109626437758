import { Flow_0_0_2, getData, getFullItemOne, getFullItemTwo, getAllInfo, getCurrentUser } from '@flexus/core';
import { map, take, filter, skipWhile, first, pluck } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import gql from 'graphql-tag';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { SIL_27 } from './SIL_27';

export const SIL_52: Flow_0_0_2 = {
	id: '52',
	name: 'payment_declined',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Payment Declined - ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Payment Declined';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Fix payment request'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	serverCalls: {
		invoice: {
			serviceVariable: 'silService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		dataFiles: {
			serviceVariable: 'silService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		}
	},

	events: {
		invalidateQueryDataIfVatRateIsSet: {
			triggerOn: 'vat_rate',
			triggerWhen: rate => !!rate,
			dataMutations: bf => {
				bf.patchValues({
					query: null,
					new_state: 50
				});
			}
		},

		invalidatePaymentDataIfQueryIsSet: {
			triggerOn: 'query',
			triggerWhen: qr => !!qr,
			dataMutations: bf => {
				bf.patchValues({
					claim_value: null,
					update_excess: null,
					invoice_number: null,
					vat_rate: null,
					nextApprovalStaffName: null,
					new_state: 46
				});
			}
		}
	},

	startNode: 'PaymentPreview',

	nodes: {
		...SIL_27?.nodes,
		PaymentPreview: {
			// component: 'FileViewWithExtraComponent',
			inputs: { inputHeading: 'Payment Preview' },
			component: {
				children: [
					{
						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: { dataFiles$: 'dataFiles', keyValueList$: 'keyValueList', inputHeading: 'Payment Preview' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList2', heading: 'Decline Reasons' }
					}
				]
			},
			serverCalls: {
				...SIL_27?.nodes?.PaymentPreview.serverCalls,
				keyValueList2: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								pluck('cancel_reasons'),
								skipWhile(x => !x),
								take(1)
							)
						]).pipe(
							take(1),
							map(([job, cancel_reasons]) => {
								const decline =
									job?.job_information?.payment_decline && Array.isArray(job?.job_information?.payment_decline)
										? job?.job_information?.payment_decline
										: job?.job_information?.payment_decline
										? [job?.job_information?.payment_decline]
										: [];

								return decline;
							})
						);
					}
				}
			},
			initFormFields: (bf, item, instance, sq, store) => {
				forkJoin([
					store.select(getFullItemOne).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getCurrentUser).pipe(
						skipWhile(x => !x),
						take(1)
					)
				])
					.pipe(take(1))
					.subscribe(([claim, user]) => {
						bf.patchValues({
							paymentapprovalhandler: claim?.application_creator ? claim?.application_creator.id : '',
							paymentapproval1: user?.user?.id ?? ''
						});
					});
			},
			navs: [
				{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'alert' },
				{
					text: 'Approve Invoice',
					nextNode: 'PaymentConfirm',
					color: 'primary'
				}
			]
		}
	},

	bigFormToStoreMapper: {
		nextApprovalStaffName: [
			[
				aid => {
					const id = aid && Array.isArray(aid) ? parseInt(aid[0], 10) : null;
					return id;
				},
				'authid'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return null;
				},
				'job_information.nextApprovalStaffName'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return null;
				},
				'job_information.authid'
			]
		],
		current_state: 'current_state',
		new_state: 'new_state',
		vat_rate: 'vat_rate',
		claim_value: [
			[
				(value, stoObj, bf) => {
					const rtnValue = parseInt(value, 10) - parseInt(bf.update_excess);
					return rtnValue;
				},
				'claim_value'
			],
			[
				(value, stoObj, bf) => {
					const rtnValue = value - bf.update_excess;
					return rtnValue;
				},
				'job_information.claim_value'
			]
		],
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		update_excess: ['update_excess', 'job_information.update_excess'],
		query: [
			(qr, storeObj) => {
				let iaq = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo &&
					storeObj['selectedContext']?.fullItemTwo.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery)) {
						iaq = storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery;
					} else {
						iaq = [
							{
								...storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
							}
						];
					}
				}
				const invoicequery = [...iaq, { query: qr, queryreply: '' }];
				return invoicequery;
			},
			'job_information.invoicequery'
		]
	}
};
