import { Flow_0_0_2, getFullItemOne, getCurrentUser, getSelectedItemOne } from '@flexus/core';
import { map, take, skipWhile, pluck, filter, switchMap } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';

export const SIL_IA_REALLOCATION: Flow_0_0_2 = {
	id: 'SIL_IA_REALLOCATION',
	itemType: 'flow',
	name: 'Reallocate Internal Assessor',
	includeForOnlyRoles: [12],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Reallocate Internal Assessor:  ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Reallocate Internal Assessor';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'assignIA',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	serverCalls: {
		authorisers: {
			errorMessage: '',
			directCall: (http, store, sq, bf) => {
				return http.get(`${environment.api_url}v1/claim_action/get_claim_handler/`, { params: { roles: '2' } }).pipe(
					pluck('payload'),
					filter(x => !!x),
					map((obj: { [key: string]: { id: number; full_name: string } }) => {
						return Object.values(obj).map(entry => ({
							display: entry?.full_name,
							value: entry?.id
						}));
					})
				);
			}
		}
	},
	nodes: {
		assignIA: {
			component: 'FLXAuthoriserSelectComponent',
			inputs: {
				heading: 'Reallocate Internal Assessor',
				subheading: 'Select a internal assessor to take over this claim',
				canFilter: true,
				maxWidth: '540px',
				reason: false
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ nextApprovalStaffName: null });
				bf.bigForm.get('nextApprovalStaffName')?.setValidators([Validators.required]);
			},
			checkValidityForFields: ['nextApprovalStaffName'],
			navs: [
				{
					text: 'Assign Claim',
					serverFirst: true,
					color: 'primary',
					linkType: 'submit',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						appointAssessor: {
							errorMessage: 'An error occurred while assigning an assessor',
							directCall: (http, store, sq, bf) => {
								return store.select(getSelectedItemOne).pipe(
									skipWhile(res => !res),
									pluck('jobs'),
									take(1),
									switchMap((jobs: any) => {
										console.log('JOBS', jobs);
										const iaJob = jobs.filter(job => job?.skill === 44);
										if (iaJob.length <= 0) {
											throw Error('Can not find IA job card on this claim');
										}
										return http.post<any>(`${environment.api_url}v1/job_action/assign_assessor`, {
											job_id: iaJob[0]?.id,
											assessor_id: Number(bf.bigForm.get('nextApprovalStaffName')?.value[0])
										});
									})
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {}
};
