import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'flx-card-expansion',
	templateUrl: './card-expansion.component.html',
	styleUrls: ['./card-expansion.component.scss']
})
export class FLXCardExpansionComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
