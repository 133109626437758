import { OnInit, OnDestroy, AfterViewInit, Component, ChangeDetectionStrategy, Renderer2, ElementRef, NgZone, OnChanges, Input, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	ManifestController,
	StoreQuery,
	IndexedDbService,
	GetFullItemTwo,
	SelectItemTwo,
	getOrgKey,
	SetActiveManifestItem,
	InitializeTempData,
	SelectItemOne,
	DeleteLocalDraft
} from '@flexus/core';
import { Router } from '@angular/router';
import { FLXContextMenuComponent, FLXIndicatorComponent } from '@flexus/ui-elements';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
	selector: 'flx-sil-list-claim-card',
	templateUrl: './sil-list-claim-card.component.html',
	styleUrls: ['./sil-list-claim-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SilListClaimCardComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	@Input() claim: any;
	@Input() job: any;
	@Input() itemOneContextMenuList = [];
	@Input() itemTwoContextMenuList = [];
	@Input() itemOnePermissions: Function[] = [];
	@Input() itemTwoPermissions: Function[] = [];
	@Input() allInfo: any;
	@Input() itemOneExtras: any;
	@Input() activeOrg: any;
	@Input() user: any;
	@Input() expanded = false;
	@Input() selectedItemOne;
	@Input() isOnline: boolean;
	@Input() stateDescriptions: any[];
	@Input() claimTypeDescriptions: any[];

	@ViewChild(FLXIndicatorComponent, { static: true }) indicator: FLXIndicatorComponent;
	@ViewChild(FLXContextMenuComponent) contextMenu: FLXContextMenuComponent;

	sub: Subscription[] = [];

	engState: string;
	engClaimType: string;

	constructor(
		private _store: Store<any>,
		private controller: ManifestController<any>,
		private sq: StoreQuery,
		private router: Router,
		private db: IndexedDbService,
		private renderer: Renderer2,
		private el: ElementRef,
		private ngZone: NgZone
	) {}
	ngOnInit() {}
	ngOnChanges(changes: SimpleChanges) {
		if (changes['itemOnePermissions']) {
			this.sub.forEach(s => {
				if (s) s.unsubscribe();
			});
			if (this.claim && this.user && this.activeOrg && this.allInfo) {
				this.itemOnePermissions.forEach(fn => {
					const res = fn(this.claim, this.user, this.renderer, this.el.nativeElement, this.activeOrg, this.allInfo, this);
					if (res) {
						this.sub.push(res);
					}
				});
			}
		}
		this.engState = this.stateDescriptions ? this.stateDescriptions[this.claim?.state] : '';
		this.engClaimType = this.claimTypeDescriptions ? this.claimTypeDescriptions[this.claim?.claim_type_id] : '';
	}
	ngAfterViewInit() {}

	selectClaimMenuItem(itemOne, menuItem) {
		if (itemOne.state === 169) {
			switch (menuItem?.id) {
				case 'DeleteLocalDraft': {
					this.controller.dispatch(new DeleteLocalDraft(itemOne.tempKey));
					break;
				}
				default:
					break;
			}
			if (this.contextMenu) {
				this.contextMenu.close();
			}
		} else if (this.isOnline) {
			// TODO Setup data and stuff needed by this context menu for this item
			this.controller.dispatch(new InitializeTempData());
			this._store.dispatch(new SelectItemOne({ itemOne }));
			this.controller
				.select(getOrgKey)
				.pipe(take(1))
				.subscribe(key => {
					this.controller.dispatch(
						new SetActiveManifestItem({
							pathToFlows: ['manifestItems', 'workflow', 'contextMenu', 'itemOne'],
							orgKey: key,
							itemId: menuItem.key,
							itemOne
						})
					);
				});
			this.router.navigate(['/workflow/detail']);
			if (this.contextMenu) {
				this.contextMenu.close();
			}
		}
	}

	selectJobMenuItem(itemTwo, menuItem) {
		this.router.navigate(['/workflow/detail']);
		this._store.dispatch(new GetFullItemTwo({ id: itemTwo?.id }));
		this._store.dispatch(new SelectItemTwo({ itemOne: this.claim, itemTwo: this.job }));
		this.controller
			.select(getOrgKey)
			.pipe(take(1))
			.subscribe(key => {
				this.controller.dispatch(
					new SetActiveManifestItem({
						pathToFlows: ['manifestItems', 'workflow', 'contextMenu', 'itemTwo'],
						orgKey: key,
						itemId: menuItem.key,
						itemOne: this.claim,
						itemTwo: this.job
					})
				);
			});
		if (this.contextMenu) {
			this.contextMenu.close();
		}
	}

	takeJobAction(itemTwo) {
		if (this.user?.user?.edit_states.includes(itemTwo.state)) {
			if (this.isOnline) {
				this.router.navigate(['/workflow/detail']);
				//this.selectedItemTwo = itemTwo;

				this.controller
					.select(getOrgKey)
					.pipe(take(1))
					.subscribe(key => {
						this.controller.dispatch(
							new SetActiveManifestItem({
								pathToFlows: ['manifestItems'],
								orgKey: key,
								itemId: itemTwo?.state,
								itemOne: this.claim,
								itemTwo: this.job
							})
						);
					});
			}
		}
	}

	takeClaimAction(): void {
		if (this.user?.user?.edit_states.includes(this.claim?.state)) {
			if (this.isOnline || this.claim?.state === 169) {
				this.router.navigate(['/workflow/detail']);
				const func = () => {
					this.controller
						.select(getOrgKey)
						.pipe(take(1))
						.subscribe(key => {
							this.controller.dispatch(
								new SetActiveManifestItem({
									pathToFlows: ['manifestItems'],
									orgKey: key,
									itemId: this.claim?.state,
									itemOne: this.claim
								})
							);
						});
				};
				func();
			}
		}
	}

	ngOnDestroy() {
		this.sub.forEach(s => s.unsubscribe());
	}
}
