import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { take, map, skipWhile, switchMap, filter } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { getAllInfoIndex } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from 'apps/studio/src/app/app-shell-features/action-panel';

export const BET_71: Flow_0_0_2 = {
	id: '71',
	name: 'claim_repudiated',
	itemType: 'flow',
	fetchLevel1And2: true,
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map(itemOne => {
					if (itemOne) {
						return `Claim Repudiated : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Claim Repudiated';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp']),
	serverCalls: {
		files: {
			serviceVariable: 'betService',
			functionName: 'getAllClaimFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		logs: {
			serviceVariable: 'betService',
			functionName: 'getCallLogs',
			responseSlice: 'payload',
			errorMessage: 'No call logs could be found!'
		}
	},
	instructions: {
		editRoles: {
			0: 'Claim repudiated'
		},
		viewRoles: {
			0: 'Claim repudiated'
		}
	},
	startNode: 'RepudiationDetails',
	nodes: {
		RepudiationDetails: {
			component: 'FLXHeadingWithInstructionsComponent',
			showTabs: true,
			name: 'Repudiation Information',
			inputs: {
				title: 'Repudiation send confimation letter',
				headingConfig: { size: 'medium', weight: 'normal' },
				instructions: ['Please send a letter to the customer confirming the Repudiation']
			},
			serverCalls: {},
			navs: [
				{
					text: 'Move To Desktop Assessor',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						changeClaimState: {
							errorMessage: 'Unable to change the claim state',
							directCall: (http, store, sq, bf) => {
								return store.select(getFullItemOne).pipe(
									skipWhile(x => !x),
									take(1),
									switchMap(claim => {
										const { loan_information } = claim;
										return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
											claim_id: claim?.id,
											new_state: 228,
											loan_information
										});
									})
								);
							}
						}
					}
				}
			]
		},
		ClaimDetails: {
			component: 'FLXNestedObjectKeyValueListComponent',
			showTabs: true,
			name: 'Claim Information',
			inputs: {
				headingConfig: { title: 'Claim Information', color: 'default', size: 'medium' },
				subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' }
			},
			serverCalls: {
				objectInfo: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemOne)
							.pipe(
								filter(x => !!x),
								take(1)
							)
							.pipe(
								map((claim: any) => {
									const txt = {
										claim_summary: {
											'Claim Number': claim?.mid,
											Claimant: claim?.applicant?.first_name + claim?.applicant?.surname,
											'Policy Number': claim?.applicant?.claimantpoliceynum,
											'Contact Number': claim?.applicant?.contact_number
										}
									};
									return txt;
								})
							);
					}
				}
			},
			navs: [
				{
					text: 'Move To Desktop Assessor',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						changeClaimState: {
							errorMessage: 'Unable to change the claim state',
							directCall: (http, store, sq, bf) => {
								return store.select(getFullItemOne).pipe(
									skipWhile(x => !x),
									take(1),
									switchMap(claim => {
										const { loan_information } = claim;
										return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
											claim_id: claim?.id,
											new_state: 228,
											loan_information
										});
									})
								);
							}
						}
					}
				}
			]
		},
		JobInformation: {
			component: 'FLXNestedObjectKeyValueListComponent',
			showTabs: true,
			name: 'Job Information',
			inputs: {
				headingConfig: { title: 'Job Summary', color: 'default', size: 'medium' },
				subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default', type: 'no-margin' },
				keyValueHeadingConfig: { margin: '0.5rem 1rem', color: 'default' }
			},
			serverCalls: {
				objectInfo: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemOne).pipe(
								filter(x => !!x),
								take(1)
							),
							store.pipe(take(1))
						]).pipe(
							map(([claim, storeObj]: any) => {
								const skills = storeObj.allInfo.skills;
								const txt = {};
								if (claim && claim?.jobs !== undefined) {
									for (const job of claim?.jobs) {
										const isValidInt = Number(job?.job_information?.is_valid);
										if (job.job_information && !isNaN(isValidInt) && !isValidInt) {
											const myVal = getAllInfoIndex('sps', 'id', 'name', job?.sp, storeObj);
											const jobtxt = { 'Service provider name': myVal };

											const jobSkill = skills.filter(skill => job?.skill === skill.id);
											if (job?.job_information?.repudiation_reason) {
												jobtxt['Repudiation Reason'] = job?.job_information?.repudiation_reason;
											}
											if (job?.job_information?.diagnosis) {
												jobtxt['Diagnosis'] = job?.job_information?.diagnosis;
											}
											if (job?.job_information?.job_report) {
												jobtxt['Report'] = job?.job_information?.job_report;
											}
											jobtxt[' '] = ' ';
											jobtxt['  '] = ' ';
											txt[jobSkill[0]?.name] = jobtxt;
										}
									}
								}
								return txt;
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Move To Desktop Assessor',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						changeClaimState: {
							errorMessage: 'Unable to change the claim state',
							directCall: (http, store, sq, bf) => {
								return store.select(getFullItemOne).pipe(
									skipWhile(x => !x),
									take(1),
									switchMap(claim => {
										const { loan_information } = claim;
										return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
											claim_id: claim?.id,
											new_state: 228,
											loan_information
										});
									})
								);
							}
						}
					}
				}
			]
		},
		//submission Nodes ************
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		customer_call: 'office_use.repudiation.customer_call',
		repudiation_type: 'office_use.repudiation.repudiation_type',
		repudiation_letter: 'office_use.repudiation.repudiation_letter',
		email: 'office_use.repudiation.emailaddress',

		new_state: 'new_state'
	}
};
