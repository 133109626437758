<flx-flex-container>
	<flx-heading [align]="headingAlignment" size="medium">Assessor Notes History</flx-heading>
	<flx-panel maxWidth="60vw" margin="auto">
		<div class="note-container">
			<div [ngClass]="locationClass" class="notes-view">
				<flx-note-list *ngIf="iaNotes$ | async as iaNotes" [notes]="iaNotes" maxHeight="100%" [location]="location"> </flx-note-list>
			</div>
			<div class="add-note">
				<flx-heading type="paragraph" size="small">Add Assessor Note</flx-heading>
				<flx-flat-dynamic-form [formControls]="formInputs"></flx-flat-dynamic-form>
				<flx-button color="primary" [disabled]="bf.bigForm.pristine" (clicked)="submit()">Add Note</flx-button>
			</div>
		</div>
	</flx-panel>
</flx-flex-container>
