import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchFilter' })
export class SearchFilterPipe implements PipeTransform {
	transform(values: any[], searchTerm: string, objectProperty: string) {
		if (!searchTerm) return values;
		const foundItems = values.filter(value => {
			if (objectProperty) {
				return value[objectProperty]?.toLowerCase()?.includes(searchTerm?.toLowerCase());
			} else {
				return value?.toLowerCase()?.includes(searchTerm?.toLowerCase());
			}
		});
		return foundItems;
	}
}
