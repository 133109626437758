import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BigFormService } from '@flexus/core';
import { Observable } from 'rxjs';
import { DynamicFormInputs } from '@flexus/ui-composites';

@Component({
	selector: 'flx-ia-notes',
	templateUrl: './ia-notes.component.html',
	styleUrls: ['./ia-notes.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IANotesComponent implements OnInit {
	@Input() iaNotes$: Observable<any>;
	@Input() title$: Observable<string>;
	@Input() submitFunction: Function;
	@Input() formInputs: DynamicFormInputs;
	@Input() location: string;

	headingAlignment = 'center';

	locationClass = 'note-edit';

	constructor(public _store: Store<any>, public bf: BigFormService) {}

	ngOnInit() {
		switch (this.location) {
			case 'action-panel':
				this.locationClass = 'compact';
				this.headingAlignment = 'left';
				break;
			case 'context-menu':
				this.locationClass = 'scattered';
				this.headingAlignment = 'center';
				break;
			default:
				break;
		}
	}

	submit() {
		this.submitFunction(this);
	}
}
