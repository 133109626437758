import { convertDateTimeToTimeStamp } from '@flexus/utilities';

export function GetDecoderSerialNumberDisplay(decoderNumber) {
	if (decoderNumber) {
		if (Array.isArray(decoderNumber)) {
			const numberOfDecoders = decoderNumber?.length;
			const decoderDetails = decoderNumber?.join(', ');

			switch (numberOfDecoders) {
				case 0:
					return { 'Decoder Serial Number': 'No serial number available' };
				case 1:
					return { 'Decoder Serial Number': decoderDetails };
				default:
					return { 'Decoder Serial Numbers': decoderDetails };
			}
		}
	} else {
		return { 'Decoder Serial Number': 'No serial number available' };
	}
}
export function GetDecoderSerialNumberAndVoucerDetailDisplay(decoderNumber, voucher) {
	const voucher_code = voucher?.voucher_number;
	const voucher_detail = voucher?.voucher?.title;
	if (decoderNumber) {
		if (Array.isArray(decoderNumber)) {
			const numberOfDecoders = decoderNumber?.length;
			const decoderDetails = decoderNumber?.join(', ');

			switch (numberOfDecoders) {
				case 0:
					return { 'Decoder Serial Number': 'No serial number available' };
				case 1:
					return {
						'Decoder Serial Number': decoderDetails,
						'Voucher Code': voucher_code,
						'Additional Decoder Info': voucher_detail
					};
				default:
					return { 'Decoder Serial Numbers': decoderDetails };
			}
		}
	} else {
		return { 'Decoder Serial Number': 'No serial number available' };
	}
}

export const getInterstateComments = (message, storeObj, bf) => {
	const toReturn = [];
	const date = new Date();
	const newInfo = {
		message: bf.alternate_qa ? bf.alternate_qa : message,
		author_id: bf.author,
		author_name: bf.author_name,
		currentState: bf.currentState,
		nextState: bf.new_state,
		timeStamp: convertDateTimeToTimeStamp(date.toDateString())
	};

	if (bf.issue_reason_value) {
		newInfo['reason'] = bf.issue_reason_value;
	}

	if (bf.qa_type) {
		newInfo['type'] = bf.qa_type;
	}

	const temp = storeObj['selectedContext']?.fullItemTwo?.job_information?.interstate_comments;

	if (temp) {
		Array.isArray(temp) ? toReturn.push(...temp, newInfo) : toReturn.push(temp, newInfo);
		console.log({ toReturn });
	} else {
		toReturn.push(newInfo);
	}
	return toReturn;
};

export const getAdditionalCostsComments = (message, storeObj) => {
	const tempQueries = storeObj['selectedContext']?.fullItemTwo?.job_information?.additionalcosts_queries;
	let validation_queries = [];

	if (tempQueries) {
		if (Array.isArray(tempQueries)) {
			validation_queries = tempQueries;
		} else {
			validation_queries = [
				{
					...tempQueries
				}
			];
		}
	}
	const clone = [...validation_queries];
	const validationquery = [...clone, { query: message, queryreply: '' }];
	return validationquery;
};
export const getInsuranceInvoiceQueries = (querynote, storeObj, bf) => {
	let returnqueries = [];

	let insurinvoicetemp = storeObj?.['selectedContext']?.fullItemTwo?.job_information.insurance_invoice_queries;
	if (insurinvoicetemp) {
		if (Array.isArray(insurinvoicetemp)) {
			returnqueries = insurinvoicetemp;
		} else {
			returnqueries = [
				{
					...insurinvoicetemp
				}
			];
		}
	}
	const invoicequery = [...returnqueries, { query: querynote, queryreply: '' }];
	return invoicequery;
};
