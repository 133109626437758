<div class="customer-rating-container">
	<form class="form" [formGroup]="bf.bigForm">
		<table class="table">
			<thead>
				<tr>
					<th style="width: 20%; text-align: right">WHO</th>
					<th style="width: 40%; text-align: center">RATING</th>
					<th style="width: 15%; text-align: center">WAS "WHAT MATTERS" MET</th>
					<th style="width: 15%; text-align: left">REASON</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td style="width: 20%; text-align: right">{{ organisationName }} Rating</td>
					<td class="ratingSelector" style="width: 40%; text-align: center"><flx-rating formControlName="customerrating" id="ratingComponentInput"></flx-rating></td>
					<td style="width: 15%; text-align: left">
						<flx-yes-no #OrganisationYesNo (click)="onOrganisationWhatMattersChanges(OrganisationYesNo.value)" formControlName="whatmattersachieved"></flx-yes-no>
					</td>
					<td><flx-input-text formControlName="failedwhatmattersreason"></flx-input-text></td>
				</tr>
				<tr>
					<td colspan="4"><flx-glow-line></flx-glow-line></td>
				</tr>
				<ng-container *ngIf="spList as serviceProviderList">
					<ng-container formArrayName="sp_ratings" *ngFor="let item of serviceProviderList; let i = index">
						<tr formGroupName="{{ i }}">
							<td style="width: 20%; text-align: right">
								<div class="ratings-sp-skill">{{ item?.skillName }}</div>
								<div class="ratings-sp-name">{{ item?.spName }}</div>
							</td>
							<td class="ratingSelector" style="width: 40%; text-align: center"><flx-rating formControlName="customerrating"></flx-rating></td>
							<td style="width: 15%; text-align: left"><flx-yes-no #SpYesNo (click)="onJobWhatMattersChanges(SpYesNo.value, i)" formControlName="whatmatters"></flx-yes-no></td>
							<td><flx-input-text formControlName="whatmattersfailreason"></flx-input-text></td>
						</tr>
					</ng-container>
				</ng-container>
				<tr>
					<td colspan="4"><flx-glow-line></flx-glow-line></td>
				</tr>
			</tbody>
		</table>
	</form>
</div>
