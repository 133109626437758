<flx-heading size="small" align="center" color="secondary" weight="normal" margin="1.5rem 0 2rem 0">{{ fixautopay_heading }}</flx-heading>
<div *ngIf="autopay_fail_reason$ | async">
	<div class="container">
		<div class="reasoncard">
			<span class="reasondesc">{{ reason }}</span>
			<span class="reasonbtn"
				><flx-button color="secondary" (click)="navigateToFixNode()">{{ buttontext }}</flx-button></span
			>
		</div>
	</div>
</div>
