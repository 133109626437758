import { Flow_0_0_2, getFullItemTwo, getAllInfo, getFullItemOne } from '@flexus/core';
import { forkJoin, of } from 'rxjs';
import { skipWhile, take, map, switchMap, first, filter, pluck, tap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const SIL_70: Flow_0_0_2 = {
	id: '70',
	name: 'job_invoiced_assessment_fee',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					return `Call Out Invoiced : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Call Out Invoiced'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	startNode: 'NoRework',
	nodes: {
		NoRework: {
			inputs: { inputHeading: 'Payment Details' },
			component: {
				children: [
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'keyValueList',
							heading$: 'heading'
						}
					}
				]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemOne).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								skipWhile(x => !x),
								take(1)
							)
						]).pipe(
							take(1),
							map(([claim, job, allInfo]) => {
								const list = {
									Address: job ? job?.address : '',
									'Client Name': claim && claim?.applicant ? claim?.applicant?.first_name + ' ' + claim?.applicant?.surname : '',
									'Contact number': claim && claim?.loan_information ? claim?.loan_information.contactnumber : '',
									'Cell number': claim && claim?.loan_information ? claim?.loan_information.cellnumber : '',
									'Excess collection': ((): string => {
										let collection = '';
										if (job.excess[0]) {
											allInfo['excess_who']?.forEach(element => {
												if (element?.id === job?.excess[0]?.who_collects) {
													collection = element.name;
												}
											});
										}
										return collection;
									})(),
									'Excess amount': job?.excess?.[0]?.amount ?? '',
									'Excess Payment': ((): string => {
										let pay = '';
										if (job.excess[0]) {
											allInfo['excess_how']?.forEach(element => {
												if (element?.id === job?.excess[0]?.how_collect) {
													pay = element.name;
												}
											});
										}
										return pay;
									})(),
									'Excess State': ((): string => {
										let state = '';
										if (job.excess[0]) {
											allInfo['excess_states']?.forEach(element => {
												if (element?.id === job?.excess[0]?.state) {
													state = element.name;
												}
											});
										}
										return state;
									})()
								};

								return [list];
							})
						);
					}
				},
				heading: {
					errorMessage: 'Could not get heading!',
					directCall: (http, store) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							pluck('valid_job'),
							map(validID => {
								let headingStr = '';
								switch (validID) {
									case 5:
										headingStr = 'Job Changed to Cash in Lieu';
										break;
									case 6:
										headingStr = 'Warranty Claim';
										break;
									default:
										headingStr = 'No Work Required';
										break;
								}
								return headingStr;
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Submit',
					serverFirst: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const { job_information, id } = job;
											const result = JSON.parse(JSON.stringify(job_information));
											const subData = {
												job_information: result,
												job_id: id,
												new_state: 27
											};
											return subData;
										}),
										switchMap(dataSub => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					}
				}
			]
		}
	},
	bigFormToStoreMapper: {
		paymentapprovalhandler: 'job_information.paymentapprovalhandler',
		paymentapproval1: 'job_information.paymentapproval1',
		current_state: 'current_state',
		new_state: 'new_state',
		vat_rate: 'vat_rate',
		claim_value: ['claim_value', 'job_information.claim_value'],
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		update_excess: ['update_excess', 'job_information.update_excess'],
		query: [
			(qr, storeObj) => {
				let iaq = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo &&
					storeObj['selectedContext']?.fullItemTwo.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery)) {
						iaq = storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery;
					} else {
						iaq = [
							{
								...storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
							}
						];
					}
				}
				const invoicequery = [...iaq, { query: qr, queryreply: '' }];
				return invoicequery;
			},
			'job_information.invoicequery'
		]
	}
};
