import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { map, filter, switchMap, first } from 'rxjs/operators';
import { getAllInfoIndex } from '@flexus/utilities';
import { BUI_BANK_DETAILS } from '../../flows';
import { of } from 'rxjs';
import { AVSResultFlag } from '@flexus/screens';

export const BUI_VIEW_BANK_DETAILS: Flow_0_0_2 = {
	id: 'ViewBankDetails',
	itemType: 'flow',
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	name: 'View/Edit Bank Details',
	includeForOnlyStates: [28, 56, 58, 59, 72, 107],
	excludeForOnlyRoles: [18],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo) {
						return `View/Edit Bank Details : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'View/Edit Bank Details';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		currBankDetails: {
			errorMessage: 'Could not pull bank details!',
			ignoreFalseError: true,
			directCall: (http, store, sq, bf) => {
				return store.select(getFullItemTwo).pipe(
					filter(x => !!x),
					first(itemOne => itemOne !== null || itemOne !== undefined),
					switchMap(itemTwo => {
						if (itemTwo.office_use !== null && itemTwo?.office_use !== undefined) {
							const data = itemTwo?.office_use;
							if (data['avsresult'] !== undefined) {
								const res = {
									initials: data['initials'],
									account_holder_name: data['surname'],
									account_number: data['bank_acc_number'],
									identification_number: data['idnumber'],
									branch_code: data['branch_code'] === undefined || data['branch_code'] === null ? '' : data?.branch_code,
									bank_name: data['bank'] === undefined || data['bank'] === null ? '' : data?.bank,
									branch: data['branch'] === undefined || data['branch'] === null ? '' : data?.branch,
									acc_type: data['acc_type'],
									account_use: data['isnonpanel'] === true ? 'Company' : 'Individual'
								};
								return of(res);
							}
						}
						return of(null);
					})
				);
			}
		}
	},
	startNode: 'ViewBankDetails',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	nodes: {
		ViewBankDetails: {
			...BUI_BANK_DETAILS,
			inputs: {
				...BUI_BANK_DETAILS.inputs,
				modalNavSuccess: {
					linkType: 'submitThenNext',
					serverCalls: {
						saveBankDetails: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					},
					nextNode: 'SubmissionSuccess',
					text: 'Continue',
					color: 'primary'
				}
			}
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemTwo.id': 'job_id',
		'itemTwo.state': 'current_state',

		//bank details
		'bank_details.initials': 'office_use.initials',
		'bank_details.account_holder_name': 'office_use.surname',
		'bank_details.account_number': 'office_use.bank_acc_number',
		'bank_details.identification_number': 'office_use.idnumber',
		'bank_details.branch_code': 'office_use.branch_code',
		'bank_details.bank_name': 'office_use.bank',
		'bank_details.branch': 'office_use.branch',
		'bank_details.acc_type': 'office_use.acc_type',
		acc_type_desc: [
			(desc, storeObj, formValue) => {
				if (formValue.bank_details !== undefined) {
					return getAllInfoIndex('account_type', 'mid', 'description', formValue.bank_details.acc_type, storeObj);
				} else {
					return null;
				}
			},
			'office_use.acc_type_desc'
		],
		'bank_details.account_use': [
			val => {
				return val === 'Individual' ? false : true;
			},
			'office_use.isnonpanel'
		],
		'bank_details.avsSuccess': 'office_use.avsresult',
		'bank_details.avsFailedReasons': [
			(val, storeObj, formVals) => {
				if (formVals.avsSuccess === AVSResultFlag.fail_force_payment) return val;
				else return null;
			},
			'office_use.avsinitialsfailreason'
		]
	}
};
