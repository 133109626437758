<footer class="module-footer">
	<ng-container>
		<pagination-controls
			*ngIf="manifestItem?.footer?.type === 'pagination'"
			class="flx-pagination"
			(pageChange)="setCurrentPage($event)"
			id="list"
			[maxSize]="5"
			[autoHide]="true"
			previousLabel="←"
			nextLabel="→"
		>
		</pagination-controls>
		<flx-node-nav *ngIf="manifestItem?.footer?.type === 'node_nav'" [navs]="navs"> </flx-node-nav>
	</ng-container>
</footer>
