import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BigFormService, } from '@flexus/core';

export class InputVars {
	type: string;
	noEndingSpace?: boolean;
	placeholder?: string;
	constructor(data: InputVars = { type: 'text' }) {
		this.type = data?.type;
		this.noEndingSpace = data?.noEndingSpace;
		this.placeholder = data?.placeholder;
	}
}

export class DropdownVars {
	options: { display: string; value: string }[];
	placeholder?: string;
	searchEnabled?: boolean;
	constructor(data: DropdownVars = { options: [] }) {
		this.options = data?.options;
		this.placeholder = data?.placeholder;
		this.searchEnabled = data?.searchEnabled;
	}
}

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'flx-account-details',
	templateUrl: './account-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
	// ======================= Private vars =======================

	// ----------------------- Input/Output vars -----------------------
	@Input() sourceToFormMapper;
	@Input() title = 'Confirm Policy Details';
	@Input() formGroupItems: {
		formGroupName: '';
		controls: {
			label: '';
			formControlName: '';
			elementType: 'input' | 'dropdown';
			elementVars: InputVars | DropdownVars;
		}[];
	};

	// ----------------------- Life-cycle functions -----------------------
	constructor(public _store: Store<any>, public bf: BigFormService) {}

	ngOnInit() {
		console.log('form group items', this.formGroupItems);
	}

	ngAfterViewInit() {}

	ngOnDestroy() {}
}
