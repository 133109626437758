import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getReminders } from '@flexus/core';
import { Store } from '@ngrx/store';

@NgModule({
	imports: [CommonModule]
})
export class RemindersCoreModule {
	constructor(store: Store<any>) {
		store.dispatch(getReminders());
	}
}
