import { Action, createReducer, on } from '@ngrx/store';
import * as RemindersActions from './reminders.actions';

export interface RemindersState {
	currentReminders: any[];
	activeReminders: any[];
}

export const initialRemindersState: RemindersState = {
	currentReminders: [],
	activeReminders: []
};

const remindersReducer = createReducer(
	initialRemindersState,
	on(RemindersActions.getRemindersSuccess, (state, { reminders }) => ({ ...state, currentReminders: reminders })),
	on(RemindersActions.getActiveRemindersSuccess, (state, { reminders }) => ({ ...state, activeReminders: reminders })),
	on(RemindersActions.clearReminders, () => initialRemindersState)
);

export function reducer(state: RemindersState | undefined, action: Action) {
	return remindersReducer(state, action);
}
