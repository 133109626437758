import { Flow_0_0_2 } from '@flexus/core';

export const SIL_162: Flow_0_0_2 = {
	id: '162',
	name: 'paused_no_excess_no_appointment',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Wait for SP to set new appointment'
		}
	},
	startNode: '',
	nodes: {}
};
