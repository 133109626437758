import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'flx-user-card-view',
	templateUrl: './user-card-view.component.html',
	styleUrls: ['./user-card-view.component.scss']
})
export class FLXUserCardViewComponent implements OnInit, OnDestroy {
	private _user: any;
	selectedUser;

	@Output() setUserToEditID = new EventEmitter<{}>();

	@Input()
	set user(val: any) {
		this._user = val;
	}
	get user() {
		return this._user;
	}

	constructor(private cdr: ChangeDetectorRef, private store: Store<any>) {}

	ngOnInit() {
		this.selectedUser = this.user;
	}

	// fireNextNodeEvent(event?: { name: string; surname: string }) {
	// 	console.log('next node');
	// 	this.setUserToEditID.emit({
	// 		id: this.selectedUser.id,
	// 		full_name: this.selectedUser.full_name,
	// 		monogram: this.selectedUser.monogram,
	// 		hasPhoto: this.selectedUser.hasPhoto,
	// 		image: this.selectedUser.image
	// 	});
	// 	// this.controller.dispatch(new SetNextNode('EditUser'));
	// }

	ngOnDestroy() {}
}
