import { Action } from '@ngrx/store';

export const GET_ALL_INFO = '[All Info] Get All Info';
export const GET_ALL_INFO_SUCCESS = '[All Info] Get All Info Success';
export const GET_ALL_INFO_FAIL = '[All Info] Get All Info Fail';

export const GET_ALL_STAFF = '[All Info] Get All Staff';
export const GET_ALL_STAFF_SUCCESS = '[All Info] Get All Staff Success';
export const GET_ALL_STAFF_FAIL = '[All Info] Get All Staff Fail';

export class GetAllInfo implements Action {
	readonly type = GET_ALL_INFO;
	constructor(public payload: string | string[]) {}
}

export class GetAllInfoSuccess implements Action {
	readonly type = GET_ALL_INFO_SUCCESS;
	constructor(public payload: any) {}
}

export class GetAllInfoFail implements Action {
	readonly type = GET_ALL_INFO_FAIL;
	constructor(public payload: any) {}
}

export type allInfoActions = GetAllInfo | GetAllInfoSuccess | GetAllInfoFail;
