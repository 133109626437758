<div
	class="expanded-select-drop-container"
	ngFileDrop
	ngFileSelect
	[ngClass]="{ 'is-drop-over': dragOver }"
	(dragenter)="this.dragOver = true"
	(drop)="getFilesFromDropEvent($event)"
>
	<p id="expanded-select-text">Drop your files here to upload</p>
	<label class="uploadButton" for="upload-button"> Select Files </label>
	<input id="upload-button" type="file" multiple (change)="getFilesFromInputSelection($event)" ngFileSelect />
</div>

<!-- ngFileDrop
ngFileSelect
[uploadInput]="uploadInput"
[ngClass]="{ 'is-drop-over': dragOver }"
(dragenter)="this.dragOver = true"
(dragleave)="this.dragOver = false"
(drop)="getFileFromDropEvent($event)" -->

<!-- ngFileSelect [uploadInput]="uploadInput" (change)="getFileFromInputSelection($event)" -->
