import {
	Component,
	OnInit,
	Input,
	Renderer2,
	ViewChild,
	ElementRef,
	OnDestroy,
	SimpleChanges,
	OnChanges,
	ComponentFactory,
	ComponentFactoryResolver,
	ViewContainerRef,
	AfterViewInit,
	ChangeDetectorRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import {
	getActiveNode,
	SetNextNode,
	SetPreviousNode,
	getNavigation,
	BigFormService,
	NavService,
	MakeServerCall,
	ModalService,
	ManifestController,
	NetworkService,
	SetSPOwnJobClaimType,
	AddressService
} from '@flexus/core';
import { FLXButtonComponent } from '@flexus/ui-elements';

// import { RemoveAllErrors } from '@flexus/error-handler';

@Component({
	selector: 'flx-node-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss']
})
export class FLXNavComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	@Input() navs: any[] = [];
	portalOpened = false;
	stackSubscription: Subscription;
	disabledSubscriptions: Subscription[] = [];
	formStatusSubscription: Subscription;
	navDataSubscription: Subscription;
	nextButtonsSubscription: Subscription[] = [];
	submitButtonSubscription: Subscription[] = [];
	@ViewChild('navRoot', { static: true }) navRoot: ElementRef;

	@ViewChild('leftButtons', { read: ViewContainerRef })
	leftButtons: ViewContainerRef;
	@ViewChild('centerButtons', { read: ViewContainerRef })
	centerButtons: ViewContainerRef;
	@ViewChild('rightButtons', { read: ViewContainerRef })
	rightButtons: ViewContainerRef;

	previousButton;
	navStack = [];
	nextButtons = [];
	validationButtons = [];
	renderCenter = false;
	factory: ComponentFactory<FLXButtonComponent>;
	private activeNode: any;

	constructor(
		private renderer: Renderer2,
		private store: Store<any>,
		private controller: ManifestController<any>,
		private router: Router,
		public bf: BigFormService,
		public addressService: AddressService,
		private navService: NavService,
		private newModalService: ModalService,
		private resolver: ComponentFactoryResolver,
		private networkService: NetworkService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.bf.bigForm?.updateValueAndValidity();
		this.factory = this.resolver.resolveComponentFactory(FLXButtonComponent);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.submitButtonSubscription) {
			this.submitButtonSubscription.forEach(sub => {
				if (sub) {
					sub.unsubscribe();
				}
			});
		}
		if (this.navs) {
			this.shouldIRenderCenterColumn();
		}

		if (changes['navs']?.firstChange) {
			this.createRouteToWorkflowListButton();
		}
		this.createPreviousButton();
		if (this.nextButtons.length > 0) {
			this.destroyNextButtons();
		}
		this.createNextButtons();
		this.handleDisablingButtons();
	}

	shouldIRenderCenterColumn() {
		const found = this.navs.some(nav => nav.location === 'center');
		this.renderCenter = found;
	}

	handleDisablingButtons() {
		if (this.disabledSubscriptions) this.disabledSubscriptions.forEach(n => n.unsubscribe());
		this.disabledSubscriptions.push(
			this.controller.select(getActiveNode).subscribe(node => {
				this.activeNode = node;
				if (node?.checkValidityForFields) {
					const allvalid = node.checkValidityForFields
						.map(fieldPath => this.bf.getControlForValidation(fieldPath) && this.bf.getControlForValidation(fieldPath).valid)
						.every(val => val === true);
					this.validationButtons.forEach(button => {
						button.instance.disabled = !allvalid;
					});
					this.bf.bigForm?.updateValueAndValidity();
					this.bf.bigForm?.valueChanges?.subscribe(val => {
						const allValidityFieldsValid = node.checkValidityForFields
							.map(fieldPath => {
								if (this.bf.getControlForValidation(fieldPath)) {
									return this.bf.getControlForValidation(fieldPath).valid;
								}
							})
							.every(test => test === true);
						this.validationButtons.forEach(button => {
							button.instance.disabled = !allValidityFieldsValid;
						});
					});
				}
			})
		);
	}

	private createNextButtons() {
		if (this.nextButtonsSubscription) this.nextButtonsSubscription.forEach(n => n.unsubscribe());
		if (this.leftButtons && this.rightButtons && this.centerButtons) {
			if (this.navs) {
				this.navs.forEach(n => {
					const button = this.getButtonLocation(n.location);
					n.color ? (button.instance.color = n.color) : (button.instance.color = 'default');
					button.instance.margin = '0 0 0 0.5rem';
					button.instance.title = n.text;
					button.instance.disableOnLoad = n.disableOnLoad;

					if (n.serverCalls) {
						if (n.nextState) {
							/// then update server then load next component
							// DISPATCH FOR A LOADING COMPONENT WHILE WAITING,
							// ON SUCCESS LOAD ACTUAL COMPONENT
							// ON ERROR LOAD ERROR COMPONENT
							this.nextButtonsSubscription.push(
								button.instance.clicked.subscribe(event => {
									// INTERSECTING DATA ON BUTTON ACTION
									if (n.intersectData) {
										n.intersectData(this.bf, this.store);
									}
									this.handleServerCall(n.serverCalls);
								})
							);
						} else {
							if (n.serverFirst) {
								// INTERSECTING DATA ON BUTTON ACTION
								if (n.intersectData) {
									n.intersectData(this.bf, this.store);
								}
								// THen update server with changes from this component and load next
								this.nextButtonsSubscription.push(
									button.instance.clicked.subscribe(event => {
										// Alternative disable on first click bindings without using submit button
										if (n.disableOnFirstClick) {
											button.instance.disabled = true;
										}

										// Prevent multiple clicks & ensure single command
										if (n.linkType && n.linkType === 'submit') {
											button.instance.disabled = true;
										}

										this.handleServerCall(n.serverCalls, n.nextNode);
									})
								);
							} else if (n.nextNode) {
								this.nextButtonsSubscription.push(
									button.instance.clicked.subscribe(event => {
										if (n.intersectData) {
											n.intersectData(this.bf, this.store);
										}
										this.handleServerCall(n.serverCalls);
										this.nextComponentKey(n.nextNode);
									})
								);
							} else {
								// INTERSECTING DATA ON BUTTON ACTION
								if (n.intersectData) {
									n.intersectData(this.bf, this.store);
								}
								this.nextButtonsSubscription.push(
									button.instance.clicked.subscribe(event => {
										this.handleServerCall(n.serverCalls);
									})
								);
							}
						}
					} else {
						if (n.nextState) {
							/// then update server then load next component
							// DISPATCH FOR A LOADING COMPONENT WHILE WAITING,
							// ON SUCCESS LOAD ACTUAL COMPONENT
							// ON ERROR LOAD ERROR COMPONENT
							this.nextButtonsSubscription.push(
								button.instance.clicked.subscribe(event => {
									// INTERSECTING DATA ON BUTTON ACTION
									if (n.intersectData) {
										n.intersectData(this.bf, this.store);
									}
									// this.store.dispatch(new SetActiveStateFlow(n.nextState));
								})
							);
						} else {
							if (n.nextNode) {
								this.nextButtonsSubscription.push(
									button.instance.clicked.subscribe(event => {
										// INTERSECTING DATA ON BUTTON ACTION
										if (n.intersectData) {
											n.intersectData(this.bf, this.store);
										}
										if (n.beforeNextNode) {
											n.beforeNextNode(
												this,
												() => {
													// onSuccess, go to next Node
													this.nextComponentKey(n.nextNode);
												},
												() => {
													// onFail do nothing
												}
											);
										} else {
											this.nextComponentKey(n.nextNode);
										}
									})
								);
							}
						}
					}

					if (n.visible) {
						this.nextButtonsSubscription.push(
							n.visible(this.bf, this.store, this.networkService).subscribe(res => {
								button.instance.visible = res;
							})
						);
					}

					if (n.linkType && n.linkType === 'portal' && n.portalData) {
						this.nextButtonsSubscription.push(
							button.instance.clicked.subscribe(event => {
								this.showPortal(n.portalData);
							})
						);
						// this.getButtonLocation(n.location);
					} else if (n.linkType && n.linkType === 'submit') {
						// this.getButtonLocation(n.location);
						button.instance.disabled = this.bf.bigForm.invalid;
						this.submitButtonSubscription.push(
							this.bf.allValid().subscribe(valid => {
								button.instance.disabled = !valid;
							})
						);

						// this.renderer.listen(but, 'click', () => {
						//   this.handleServerCall(n.serverCalls);
						// });
					} else {
						// this.renderer.listen(but, 'click', () => {
						//   this.handleServerCall(n.serverCalls);
						// });
						// this.getButtonLocation(n.location);
						// this.renderer.appendChild(this.centerButtons.nativeElement, but);
					}
					this.nextButtons.push(button);
					if (n.optIntoValidation) {
						this.validationButtons.push(button);
					}
				});
			}
		}
	}

	public getButtonLocation(location: string) {
		switch (location) {
			case 'left':
				return this.leftButtons.createComponent(this.factory);
			case 'center':
				return this.centerButtons.createComponent(this.factory);
			case 'right':
				return this.rightButtons.createComponent(this.factory);
			default:
				return this.rightButtons.createComponent(this.factory);
		}
	}

	private destroyNextButtons() {
		this.nextButtons.forEach(button => {
			button.destroy();
			// this.renderer.removeChild(this.navRoot.nativeElement, but);
		});
		this.nextButtons = [];
		this.validationButtons = [];
	}

	private createRouteToWorkflowListButton() {
		if (this.leftButtons) {
			const backToWorkflowButton = this.leftButtons.createComponent(this.factory);
			backToWorkflowButton.instance.color = 'default';
			backToWorkflowButton.instance.title = 'Back To Workflow';
			backToWorkflowButton.instance.clicked.subscribe(event => {
				this.router.navigate(['/workflow'], { skipLocationChange: true });
				this.store.dispatch(new SetSPOwnJobClaimType({ claimtype: '', isOwnJob: false }));
				this.store.dispatch({ type: 'REMOVE_ALL_ERRORS' });
			});
		}
		// const but: HTMLButtonElement = this.renderer.createElement('button');
		// this.renderer.addClass(but, 'btn');
		// this.renderer.addClass(but, 'btn-default');
		// const butText = this.renderer.createText('Back To Workflow');
		// this.renderer.appendChild(but, butText);
		// this.renderer.listen(but, 'click', () => {
		//   this.router.navigate(['/workflow']);
		// });
		// this.renderer.appendChild(this.leftButtons.nativeElement, but);
		// return but;
	}

	private createPreviousButton() {
		this.stackSubscription = this.controller.select(getNavigation).subscribe(navStack => {
			this.navStack = navStack;

			if (navStack.length >= 1 && !this.previousButton && this.leftButtons) {
				const prevButton = this.leftButtons.createComponent(this.factory);
				prevButton.instance.color = 'default';
				prevButton.instance.title = 'Back';
				prevButton.instance.margin = '0 0 0 0.5rem';
				prevButton.instance.clicked.subscribe(event => {
					this.previousComponent();
				});
				this.previousButton = prevButton;
			} else if ((navStack.length === 0 && !!this.previousButton) || (this.activeNode && this.activeNode.id === 'SubmissionSuccess' && this.previousButton)) {
				// this.renderer.removeChild(this.leftButtons.nativeElement, this.previousButton);
				this.previousButton.destroy();
				this.previousButton = null;
			}
		});
	}

	nextComponentKey(nextNodeKey: string) {
		// this.store.dispatch(new RemoveAllErrors());
		this.store.dispatch({ type: 'REMOVE_ALL_ERRORS' });
		if (nextNodeKey === 'PREVIOUS') {
			this.previousComponent();
		} else {
			this.controller.dispatch(new SetNextNode(nextNodeKey));
		}
	}

	previousComponent() {
		this.controller.dispatch(new SetPreviousNode());
	}

	handleServerCall(serverCalls, nextNode?) {
		if (serverCalls) {
			Object.entries(serverCalls).forEach(([dataKey, call]: any) => {
				this.store.dispatch(new MakeServerCall({ dataKey, ...call, nextNode }));
			});
		}
	}

	showPortal(portalData) {
		const { type, paramFunc } = portalData;
		if (type === 'actionPanel') {
			//TODO: refactor this to -> callActionPanel() ...
			this.navService.portalActions.next({ call: 'onClick', paramFunc });
		} else if (type === 'modal') {
			//
			this.newModalService.modalActions.next(paramFunc);
		}
	}

	ngAfterViewInit(): void {
		if (this.navs) {
			this.shouldIRenderCenterColumn();
		}
		setTimeout(() => {
			this.createRouteToWorkflowListButton();
			this.createNextButtons();
			this.handleDisablingButtons();
			this.cdr.detectChanges();
		});
	}

	ngOnDestroy() {
		if (this.stackSubscription) {
			this.stackSubscription.unsubscribe();
		}
		if (this.disabledSubscriptions) {
			this.disabledSubscriptions.forEach(sub => {
				sub.unsubscribe();
			});
		}
		if (this.nextButtonsSubscription) {
			this.nextButtonsSubscription.forEach(sub => {
				if (sub) sub.unsubscribe();
			});
		}
		if (this.submitButtonSubscription) {
			this.submitButtonSubscription.forEach(sub => {
				if (sub) {
					sub.unsubscribe();
				}
			});
		}

		if (this.navDataSubscription) this.navDataSubscription.unsubscribe();
	}
}
