<!--
  If there's not enough space in the first row, create a separate label row. We mark this row as
  aria-hidden because we don't want it to be read out as one of the weeks in the month.
-->
<tr *ngIf="_firstRowOffset < labelMinRequiredCells" aria-hidden="true">
	<td class="flx-calendar-body-label" style="display: none" [attr.colspan]="numCols">
		<!--      [style.paddingTop.%]="50 * cellAspectRatio / numCols"-->
		<!--      [style.paddingBottom.%]="50 * cellAspectRatio / numCols">-->
		{{label}}
	</td>
</tr>

<!-- Create the first row separately so we can include a special spacer cell. -->
<tr *ngFor="let row of rows; let rowIndex = index" role="row">
	<td *ngIf="rowIndex === 0 && _firstRowOffset" aria-hidden="true" class="flx-calendar-body-label" style="visibility: hidden" [attr.colspan]="_firstRowOffset">
		<!--      [style.paddingTop.%]="50 * cellAspectRatio / numCols"-->
		<!--      [style.paddingBottom.%]="50 * cellAspectRatio / numCols">-->
		{{_firstRowOffset >= labelMinRequiredCells ? label : ''}}
	</td>
	<td
		*ngFor="let item of row; let colIndex = index"
		role="gridcell"
		class="flx-calendar-body-cell"
		[tabindex]="_isActiveCell(rowIndex, colIndex) ? 0 : -1"
		[class.flx-calendar-body-disabled]="!item.enabled"
		[class.flx-calendar-body-active]="_isActiveCell(rowIndex, colIndex)"
		[attr.aria-label]="item.ariaLabel"
		[attr.aria-disabled]="!item.enabled || null"
		(click)="_cellClicked(item)"
		[style.width.rem]="2"
	>
		<!--      [style.paddingTop.%]="50 * cellAspectRatio / numCols"-->
		<!--      [style.paddingBottom.%]="50 * cellAspectRatio / numCols">-->
		<div class="flx-calendar-body-cell-content" [class.flx-calendar-body-selected]="selectedValue === item?.value" [class.flx-calendar-body-today]="todayValue === item?.value">
			{{item?.displayValue}}
		</div>
	</td>
</tr>
