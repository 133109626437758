import { OverlayModule } from '@angular/cdk/overlay';
import { FLXFileOverlayComponent } from './file-overlay/file-overlay.component';
import { NgModule } from '@angular/core';
import { FLXFileBrowserComponent } from './file-browser/file-browser.component';
import { CommonModule } from '@angular/common';
import { FLXFileListComponent } from './file-list/file-list.component';
import { FLXFilePreviewComponent } from './file-preview/file-preview.component';
import { FilterFilesPipe } from './pipes/filter.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilePreviewOverlayService } from './file-overlay/file-preview-overlay.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InlineSVGModule } from 'ng-inline-svg';
import { FLXLoaderModule } from '../loader/loader.module';
import { FLXExpandedFileSelectComponent } from './expanded-file-select/expanded-file-select.component';
import { FLXExpandedMultiFileSelectComponent } from './expanded-multi-file-select/expanded-multi-file-select.component';
import { FLXCompactFileSelectComponent } from './compact-file-select/compact-file-select.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { FLXIconModule } from '../inline-icons';
import { FLXButtonsModule } from '../buttons';
import { FLXHeadingsModule } from '../headings';
@NgModule({
	declarations: [
		FLXFileBrowserComponent,
		FLXFileListComponent,
		FLXFilePreviewComponent,
		FilterFilesPipe,
		FLXFileOverlayComponent,
		FLXExpandedFileSelectComponent,
		FLXCompactFileSelectComponent,
		FLXExpandedMultiFileSelectComponent
	],
	imports: [CommonModule, FLXHeadingsModule, NgxPaginationModule, ScrollingModule, InlineSVGModule, FLXLoaderModule, NgxUploaderModule, FLXIconModule, FLXButtonsModule],
	exports: [FLXFileBrowserComponent, FLXFilePreviewComponent, FLXExpandedFileSelectComponent, FLXCompactFileSelectComponent, FLXExpandedMultiFileSelectComponent],
	providers: [OverlayModule, FilePreviewOverlayService],
	// entryComponents: [FLXFileOverlayComponent, FLXFileBrowserComponent]
})
export class FileHandlerModule {}
