import { Injectable, Inject, EventEmitter } from '@angular/core';
import { THEMES, ACTIVE_THEME, Theme } from './symbols';

@Injectable()
export class ThemeService {
	themeChange = new EventEmitter<Theme>();

	constructor(@Inject(THEMES) public themes: Theme[], @Inject(ACTIVE_THEME) public theme: string) {}

	getTheme(name: string) {
		const theme = this.themes.find(t => t.name === name);
		if (!theme) {
			throw new Error(`Theme not found: ${name}`);
		}
		return theme;
	}

	getActiveTheme() {
		return this.getTheme(this.theme);
	}

	setTheme(name: string) {
		const currentTheme = JSON.parse(window.localStorage.getItem('flexusPreferences') || "{}");
		currentTheme.theme.name = name;
		window.localStorage.setItem('flexusPreferences', JSON.stringify(currentTheme));

		this.theme = name;

		// console.log('Setting theme to: ', this.theme);

		const themeToApply = this.getActiveTheme();

		Object.keys(themeToApply.properties).forEach(k => {
			document.documentElement.style.setProperty(k, themeToApply.properties[k]);
		});

		this.themeChange.emit(this.getActiveTheme());
	}
}
