import { BigFormService, Flow_0_0_2, getAllInfo, getFullItemOne, getFullItemTwo, getSelectedItemTwo } from '@flexus/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take, map, skipWhile, filter, first, mergeMap, switchMap, pluck } from 'rxjs/operators';
import gql from 'graphql-tag';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { forkJoin, of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { select, Store } from '@ngrx/store';
import { addObjectProperty, CustomValidators, humaniseDate } from '@flexus/utilities';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';

export const PGG_20: Flow_0_0_2 = {
	id: '20',
	name: 'manual_allocation',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),

	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				filter((x: any) => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Manually Allocate SP : ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					} else {
						return 'Manually Allocate SP';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Allocate an SP to the job'
		},
		viewRoles: {
			0: 'Awaiting SP allocation'
		}
	},
	startNode: 'SpReplyList',
	nodes: {
		SpReplyList: {
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('incentivised', new UntypedFormControl(false, []));
			},
			component: 'FLXSpReplyListComponent',
			inputs: {
				assignSpStateList: [20],
				selectSPNavigateToNode: 'AllocateSPSummary',
				subTitle: 'Please select a service provider below'
			},
			serverCalls: {
				interestedParties: {
					serviceVariable: 'pggService',
					functionName: 'getInterestedParties',
					responseSlice: 'payload',
					errorMessage: 'An error occurred getting the interested parties'
				},
				replyListData: {
					errorMessage: 'An error occurred getting the SP Data',
					serviceVariable: 'pggService',
					functionName: 'getSPReplyListData'
				}
			},
			navs: [
				{
					text: 'Reping SP Request',
					color: 'primary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any) => {
							instance.type = 'warning';
							instance.setMessage([
								'You are about to reping all the service providers that are able to do this job',
								'',
								'Please ensure you understand the implications of this decision'
							]);
							instance.navButtons = [
								{ text: 'Take me back', color: 'alert', linkType: 'close' },
								{
									text: 'I understand',
									color: 'alert',
									linkType: 'nextNode',
									nextNode: 'SetAppointmentForReping'
								}
							];
						}
					}
				}
			]
		},
		SetAppointment: {
			checkValidityForFields: ['appointmentData'],
			inputs: {
				minDate: new Date(),
				showExistingAppointment: true,
				minHour: 8,
				maxHour: 17
			},
			initFormFields: (bf: any, item: any, instance: any, sq: any, _store: Store) => {
				bf.addControl('claimtype_string', new FormControl(''));
				_store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1),
						map((data: any) => {
							const { claim } = data;
							const { source } = claim;
							let claimtypestring = source.replace(/^./, source[0].toUpperCase()) + ' Installation';
							bf.patchValues({ claimtype_string: claimtypestring });
						})
					)
					.subscribe()
					.unsubscribe();
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(bf.getControl('claimtype_string').value)
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				// });
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http: any, store: any, sq: any) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												surname
												contact_number
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile((f: any) => !f),
									take(1),
									map((res: any) => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contact_number}` },
										{ 'Mobile Number': `${queryData.contact_number}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http: any, store: any, sq: any) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												contact_number
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile((f: any) => !f),
									take(1),
									map((res: any) => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.first_name}` }, { 'Onsite Contact Number': `${queryData.contact_number}` }];
								})
							);
					}
				},
				existing_appointment: {
					errorMessage: `Couldn't get existing appointment details!`,
					directCall: (_tp: any, _st: any, _sq: any, __bf: any) => {
						return _st
							.select(getFullItemTwo)
							.pipe(
								skipWhile((x: any) => !x),
								take(1),
								map((res: any) => res)
							)
							.pipe(
								map((fit: any) => {
									const appointment = fit?.appointment[0] ?? [];
									return [
										{
											'Original Appointment Date': `${humaniseDate(appointment.range_start)}` ?? 'not set',
											'Original Appointment Time': `${moment(appointment.range_start)?.format('HH:mm')}` ?? 'not set'
										}
									];
								})
							);
					}
				},
				appointmentTypes: {
					errorMessage: `Couldn't get appointment types!`,
					directCall: (_http: any, _store: any, _s: any, _b: any, _c: any, _m: any) => {
						return _store
							.select(getAllInfo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								pluck('appointment_types'),
								map((res: any) => res)
							)
							.pipe(map((result: any) => result));
					}
				}
			},
			component: 'FLXJobAppointmentComponent',

			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'pggService',
							functionName: 'updateJob',
							errorMessage: 'Could not update takealot job',
							followUpSuccessCalls: {
								appointSP: {
									directCall: (_http: HttpClient, _store: Store, _sq: any, _bf) => {
										return forkJoin([
											_store.select(getFullItemTwo).pipe(
												skipWhile(x => !x),
												take(1)
											),
											of(_bf.bigForm.get('sp_selected_item')?.value),
											of(_bf.bigForm.get('incentivised').value)
										]).pipe(
											switchMap(([fulljob, selectedsp, incentivised]) => {
												const job = fulljob as any;
												const { job_information } = job;
												const job_info = JSON.parse(JSON.stringify(job_information));
												const sp = selectedsp as any;
												const job_id = fulljob?.id;
												const sp_id = sp?.id;
												if (!incentivised) {
													return _http.post(`${environment.api_url}v1/job_action/appoint_sp/`, { job_id: job_id, sp_id: sp_id });
												} else if (incentivised) {
													const new_state = 273;
													const request = {
														job_id: job_id,
														new_state: new_state
													};
													return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
												}
											})
										);
									},
									errorMessage: 'Could not appoint SP'
								}
							}
						}
					},
					color: 'primary',
					nextNode: 'EndSummary'
				}
			]
		},
		SetAppointmentForReping: {
			checkValidityForFields: ['appointmentData'],
			inputs: {
				minDate: new Date(),
				showExistingAppointment: true,
				minHour: 8,
				maxHour: 17
			},
			initFormFields: (bf: any, item: any, instance: any, sq: any, _store: Store) => {
				bf.addControl('claimtype_string', new FormControl(''));
				_store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1),
						map((data: any) => {
							const { claim } = data;
							const { source } = claim;
							let claimtypestring = source.replace(/^./, source[0].toUpperCase()) + ' Installation';
							bf.patchValues({ claimtype_string: claimtypestring });
						})
					)
					.subscribe()
					.unsubscribe();
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(bf.getControl('claimtype_string').value)
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				// });
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http: any, store: any, sq: any) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												surname
												contact_number
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile((f: any) => !f),
									take(1),
									map((res: any) => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contact_number}` },
										{ 'Mobile Number': `${queryData.contact_number}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http: any, store: any, sq: any) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												contact_number
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile((f: any) => !f),
									take(1),
									map((res: any) => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.first_name}` }, { 'Onsite Contact Number': `${queryData.contact_number}` }];
								})
							);
					}
				},
				existing_appointment: {
					errorMessage: `Couldn't get existing appointment details!`,
					directCall: (_tp: any, _st: any, _sq: any, __bf: any) => {
						return _st
							.select(getFullItemTwo)
							.pipe(
								skipWhile((x: any) => !x),
								take(1),
								map((res: any) => res)
							)
							.pipe(
								map((fit: any) => {
									const appointment = fit?.appointment[0] ?? [];
									return [
										{
											'Original Appointment Date': `${humaniseDate(appointment.range_start)}` ?? 'not set',
											'Original Appointment Time': `${moment(appointment.range_start)?.format('HH:mm')}` ?? 'not set'
										}
									];
								})
							);
					}
				},
				appointmentTypes: {
					errorMessage: `Couldn't get appointment types!`,
					directCall: (_http: any, _store: any, _s: any, _b: any, _c: any, _m: any) => {
						return _store
							.select(getAllInfo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								pluck('appointment_types'),
								map((res: any) => res)
							)
							.pipe(map((result: any) => result));
					}
				}
			},
			component: 'FLXJobAppointmentComponent',

			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					serverFirst: true,
					serverCalls: {
						repingSP: {
							serviceVariable: 'pggService',
							functionName: 'repingSP',
							errorMessage: 'An error occurred while repinging the Service Provider'
						}
					},
					color: 'primary',
					nextNode: 'EndSummary'
				}
			]
		},
		AllocateSPSummary: {
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.patchValue({ incentivised: false });
			},
			component: 'FLXKeyValueDisplayWithInstructionsComponent',
			inputs: {
				instructions: ['Please double check the details of the selected service provider'],
				title: 'Details for'
			},
			serverCalls: {
				title: {
					errorMessage: 'Could not get SP name',
					directCall: (http, store, sq, bf) => {
						return of(bf.bigForm.get('sp_selected_item')?.value?.name);
					}
				},
				keyValueList: {
					errorMessage: 'data for keyvaluelist not returned',
					directCall: (http, store, sq, bf) => {
						return store.select(getSelectedItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							mergeMap(selectedItem => {
								const job = selectedItem as any;
								return forkJoin([
									http.post(`${environment.api_url}v1/job_action/get_interested_parties/`, { job_id: job?.id }).pipe(
										skipWhile(x => !x),
										map(res => res as any)
									),
									of(bf.bigForm.get('sp_selected_item')?.value)
								]).pipe(
									map(([interested, bigformdata]) => {
										const interestedParties = interested?.payload;
										const selectedSP = bigformdata as any;
										const interestedParty = { ...interestedParties.find(ip => ip.sp.id === selectedSP?.id) };
										let interest;
										switch (interestedParty?.new_interested) {
											case -1: {
												interest = 'Rejected';
												break;
											}
											case 0: {
												interest = 'Ignored';
												break;
											}
											case 1: {
												interest = 'Accepted';
												break;
											}
											case null: {
												interest = 'No-Reply';
												break;
											}
											default:
												break;
										}
										return [
											{ 'Primary Contact': selectedSP.contact_person },
											{ 'Primary Contact Number': selectedSP.contact_primary },
											// { maven_id: selectedSP.mid !== null ? selectedSP.mid : 'Not On Maven' },
											{
												'response to job': interest
											},
											{ 'Currently Awarded Jobs': interestedParty?.awarded_today }
										];
									})
								);
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'request incentive',
					color: 'secondary',
					nextNode: 'InstallerIncentive'
				},
				{
					text: 'Allocate this SP',
					color: 'primary',
					nextNode: 'SetAppointment'
				}
			]
		},
		InstallerIncentive: {
			initFormFields: (_bf: any) => {
				const sp_in_form = _bf.bigForm.get('sp_selected_item')?.value;
				_bf.addControl('chosen_sp', new UntypedFormControl(sp_in_form, [Validators.required]));
				_bf.addControl('incentive_amount', new UntypedFormControl('', [Validators.required, CustomValidators.currency]));
				_bf.addControl('incentive_reason', new UntypedFormControl('', [Validators.required]));
				_bf.bigForm.patchValue({ incentivised: true });
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Pre-job SP Extras Fee',
							instructions: ['Enter any additional Pre-job SP Extras to be paid to the SP.', 'Note of this will be sent for approval.']
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Requested Pre-job Amount (Excl. VAT)',
									inputType: 'input',
									formControlName: 'incentive_amount'
								},
								1: {
									label: 'Reason for Pre-job SP Extras',
									inputType: 'select',
									selectConfig: {
										displayOptions: { displayKey: 'display', valueKey: 'value' },
										itemsOption: [
											{ display: 'Travel Additional', value: 'Travel Additional' },
											{ display: 'Installation Fee', value: 'Installation Fee' },
											{ display: 'Travel and Additional Installation', value: 'Travel and Additional Installation' }
										],
										searchEnabled: false,
										placeholder: 'Select reason for incentive'
									},
									formControlName: 'incentive_reason'
								},
								2: {
									label: 'Additional Comments',
									inputType: 'textarea',
									rows: 4,
									formControlName: 'incentive_comments'
								}
							},
							formLayout: 'stacked',
							containerWidth: '30vw'
						}
					}
				]
			},
			checkValidityForFields: ['incentive_amount', 'incentive_reason'],
			navs: [
				{
					text: 'submit',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'pggService',
							functionName: 'updateJob',
							errorMessage: 'Could not update job'
						}
					},
					color: 'primary',
					optIntoValidation: true,
					nextNode: 'SetAppointment'
				}
			]
		},
		EndSummary: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		// new_state: 'new_state',
		'sp_selected_item.id': 'sp',
		appointmentData: [
			(appointment: any) => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					return {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
				}
			},
			'appointment'
		],
		incentive_amount: [
			(inc_amount: any) => {
				if (inc_amount) {
					return inc_amount;
				} else {
					return null;
				}
			},
			'job_information.installer_incentive_amount'
		],
		incentive_reason: [
			(inc_rea: any) => {
				if (inc_rea) {
					return inc_rea;
				} else {
					return null;
				}
			},
			'job_information.installer_incentive_reason'
		],
		incentive_comments: [
			(add_inc_comm: any) => {
				return add_inc_comm;
			},
			'job_information.installer_incentive_comments'
		],
		chosen_sp: 'job_information.chosen_sp'
	}
};
