import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { search } from 'jmespath';

@Component({
	selector: 'flx-ap-team-members',
	templateUrl: './team-members.component.html',
	styleUrls: ['./team-members.component.scss']
})
export class FLXAPTeamMembersComponent implements OnInit, OnDestroy {
	/**
	 * @param query is a string that details the JSON path where to find the teams array in the store e.g identity.currentUser.staff_member.team
	 */
	@Input() query: string = '';
	/**
	 *  @param title is the title placed on top of the page.
	 */
	@Input() title: string = '';
	/**
	 * @param teams observable of type array of objects which contains name of team,
	 * leader object with property full_name, and an array member of objects with property full_name
	 */
	teams$: Observable<any>;

	constructor(private _store: Store<any>) {}

	ngOnInit(): void {
		this.teams$ = this._store.pipe(map(store => search(store, this.query)));
	}
	ngOnDestroy(): void {}
}
