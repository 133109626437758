<flx-heading size="medium" [align]="'center'" weight="extra-light" type="creation">{{ title }}</flx-heading>
<form [formGroup]="bf.bigForm">
	<div class="lookupInfoContainer">
		<div class="search-container">
			<flx-input-text
				*ngIf="bf.bigForm.get(searchFormControlName)"
				size="medium"
				type="text"
				[noEndingSpace]="true"
				[formControlName]="searchFormControlName"
				class="search-lookup"
				autocomplete="false"
				[required]="true"
				radius="4px 0 0 4px"
				(keyup.enter)="lookup()"
			></flx-input-text>
			<flx-icon [ngClass]="(isLoading$ | async) ? 'disabled' : ''" [type]="'search'" use="button" color="button" hover="container" layout="right" (click)="lookup()"></flx-icon>
		</div>
		<br /><br />

		<div class="spanner">
			<ng-container *ngIf="isLoading$ | async"><flx-loader-component></flx-loader-component></ng-container>
			<ng-container *ngIf="(!(lookupDetails$ | async) || (lookupDetails$ | async)?.length === 0) && hasDoneLookup && !(isLoading$ | async)">
				<flx-alert type="info" message="No {{ lookupName }} found"></flx-alert>
			</ng-container>
			<ng-container *ngIf="(lookupDetails$ | async) && (lookupDetails$ | async)?.length !== 0 && !(isLoading$ | async) && (isLoaded$ | async)">
				<flx-panel alignItems="center">
					<ng-container *ngIf="lookupDetails$ | async as lookupDetailsArray">
						<div
							*ngFor="let lookupDetails of lookupDetailsArray; index as idx"
							[class.invalid]="!isValidLookup(_store, lookupDetails)"
							[class.selected]="selectedLookup === idx"
							(click)="isValidLookup(_store, lookupDetails) ? selectLookup(idx) : null"
							class="lookup-result-container"
						>
							<ng-container *ngFor="let lookupItemColumn of lookupItemColumns">
								<div class="lookup-description">{{ lookupDetails[lookupItemColumn.lookupDetailsProperty] }}</div>
							</ng-container>

							<div class="validity">
								<div>{{ lookupName }} {{ getLookupValidStatus(_store, lookupDetails) }}</div>
							</div>
							<div class="indicator">
								<flx-indicator *ngIf="isValidLookup(_store, lookupDetails)" color="primary" position="right"></flx-indicator>
							</div>
						</div>
					</ng-container>
				</flx-panel>
			</ng-container>
		</div>
	</div>
</form>
