import { Component, OnInit, OnDestroy } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { StoreQuery, getAllInfo, getSelectedItem } from '@flexus/core';
import gql from 'graphql-tag';
import { skipWhile, take, map } from 'rxjs/operators';

@Component({
	selector: 'flx-sp-details',
	templateUrl: './sp-details.component.html',
	styleUrls: ['./sp-details.component.scss']
})
export class FLXSPDetailsComponent implements OnInit, OnDestroy {
	spInfo$: Observable<any>;
	spsubscription: Subscription;
	spname;
	spemail;
	spcontact;
	spcontactnumber;
	sp: boolean;

	constructor(private _store: Store<any>, private sq: StoreQuery) {}

	ngOnInit() {
		this.buildSPData();
	}

	buildSPData() {
		this.spInfo$ = this.sq.queryStore(
			gql`
				{
					allInfo {
						sps {
							id
							name
							contact_primary
							contact_person
							email_receiving
						}
					}
					selectedContext {
						selectedItem {
							id
							sp
						}
					}
				}
			`,
			forkJoin([
				this._store.select(getAllInfo)?.pipe(
					skipWhile(fj => !fj),
					take(1)
				),
				this._store.select(getSelectedItem)?.pipe(
					skipWhile(fj => !fj),
					take(1)
				)
			])?.pipe(map(res => ({ allInfo: res[0], selectedContext: [1] })))
		);

		this.spsubscription = this.spInfo$.subscribe((res: any) => {
			if (res) {
				const sptodisplay = (res?.sps as any[])?.filter(a => a.id === res?.sp);
				if (sptodisplay.length > 0) {
					const selectedIndex = sptodisplay[0];
					this.spname = selectedIndex?.name ?? null;
					this.spemail = selectedIndex?.email_receiving ?? null;
					this.spcontact = selectedIndex?.contact_person ?? null;
					this.spcontactnumber = selectedIndex?.contact_primary ?? null;
					// this.spemail = sptodisplay[0].email_receiving;
					// this.spcontact = sptodisplay[0].contact_person;
					// this.spcontactnumber = sptodisplay[0].contact_primary;
					this.sp = true;
				}
			}
			if (res === null || res === undefined || res === '' || Array.isArray(res)) {
				this.sp = false;
			}
		});
	}
	ngOnDestroy() {
		if (this.spsubscription) {
			this.spsubscription.unsubscribe();
		}
	}
}
