<nav
	class="app-menu"
	[ngClass]="{ opened: opened, closed: !opened }"
	[@slideContent]="animationState"
	(@slideContent.start)="onAnimationStart($event)"
	(@slideContent.done)="onAnimationDone($event)"
>
	<ng-container *ngIf="!isMC">
		<div class="app-menu-user-area">
			<div class="btn-user">
				Logged in as <br />
				<b>{{ (currentUser$ | async)?.user?.full_name | titlecase }}</b>
			</div>
			<a class="btn-menu-item" (click)="signout($event)"> <flx-icon hover="primary" class="ui-icon" [type]="'app-menu-logout'"></flx-icon>Log out </a>
		</div>
		<flx-glow-line margin="1rem 0"></flx-glow-line>

		<div class="app-menu-button-group">
			<a *ngFor="let item of navItems" [ngClass]="client === 'pgg_sp' ? 'btn-menu-item-fixed-active' : 'btn-menu-item'" (click)="redirect(item)">
				<flx-icon hover="primary" class="ui-icon" [type]="item?.iconType"></flx-icon>
				<span class="btn-menu-item__text">{{ item?.name }}</span>
			</a>

			<!-- <flx-icon [isActive]='true' class="ui-icon" [type]="item?.iconType"></flx-icon> -->
		</div>
		<flx-glow-line margin="1rem 0"></flx-glow-line>

		<div class="app-menu-theme-settings">
			<div class="togglebutton">
				<p class="uppertextleft">Dark</p>
				<label class="switch"> <input type="checkbox" (change)="toggleEditable($event)" [checked]="themeCheckedStatus" /> <span class="slider two round"></span> </label>
				<p class="uppertextright">Light</p>
			</div>
		</div>
		<flx-glow-line margin="1rem 0"></flx-glow-line>

		<!-- <div class="app-menu-user-settings"> -->
		<div class="btn-user version">Version {{ version }}</div>
		<!-- </div> -->

		<div class="app-menu-logo">
			<span *ngIf="second_logo_url" class="second-logo" [inlineSVG]="second_logo_url"></span><br />
			<span class="branding" [inlineSVG]="logo_url"></span>
		</div>
	</ng-container>
	<ng-container *ngIf="isMC">
		<div class="autopay-app-menu-user-area">
			<div class="app-menu-button-area">
				<flx-heading color="primary" size="small" align="center" weight="bold">Switch to</flx-heading>
				<div class="app-menu-button-group">
					<a *ngFor="let item of navItems" [ngClass]="client === 'pgg_sp' ? 'btn-menu-item-fixed-active' : 'btn-menu-item'" (click)="redirect(item)">
						<flx-icon hover="primary" class="ui-icon" [type]="item?.iconType"></flx-icon>
						<span class="btn-menu-item__text">{{ item?.name }}</span>
					</a>
				</div>

				<!-- <flx-icon [isActive]='true' class="ui-icon" [type]="item?.iconType"></flx-icon> -->
			</div>

			<div class="app-menu-user-section">
				<flx-glow-line></flx-glow-line>
				<flx-heading color="primary" size="small" align="center" weight="bold">User preference</flx-heading>
				<div>{{ (currentUser$ | async)?.user?.full_name | titlecase }}</div>
				<div class="app-menu-theme-settings">
					<div class="togglebutton">
						<p class="uppertextleft">Dark</p>
						<label class="switch"> <input type="checkbox" (change)="toggleEditable($event)" [checked]="themeCheckedStatus" /> <span class="slider two round"></span> </label>
						<p class="uppertextright">Light</p>
					</div>
				</div>
				<div class="logout-and-version">
					<div>
						<flx-button align="center" (click)="signout($event)">Logout</flx-button>
					</div>

					<div style="margin: 0 auto !important; text-align: center">Version {{ version }}</div>
				</div>

				<div class="app-menu-client-logo">
					<span *ngIf="second_logo_url" class="second-logo" [inlineSVG]="second_logo_url"></span><br />
					<span class="branding" [inlineSVG]="logo_url"></span>
				</div>
			</div>
		</div>
	</ng-container>
</nav>
