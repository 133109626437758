import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXHeadingsModule, FLXLoaderModule, FileHandlerModule, FLXAlertsModule } from '@flexus/ui-elements';
import { FLXViewPhotosComponent } from './view-photos.component';

@NgModule({
	imports: [CommonModule, FLXHeadingsModule, FLXLoaderModule, FileHandlerModule, FLXAlertsModule],
	exports: [FLXViewPhotosComponent],
	declarations: [FLXViewPhotosComponent]
})
export class FLXViewPhotosModule {}
