import { Flow_0_0_2, getFullItemOne, getSelectedItemTwo } from '@flexus/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take, map, skipWhile, filter, first, mergeMap, switchMap } from 'rxjs/operators';
import gql from 'graphql-tag';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { forkJoin, of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { select } from '@ngrx/store';

export const BUI_20: Flow_0_0_2 = {
	id: '20',
	name: 'manual_allocation',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Manually Allocate SP : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Manually Allocate SP';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Allocate an SP to the job'
		},
		viewRoles: {
			0: 'Awaiting SP allocation'
		}
	},
	startNode: 'SpReplyList',
	nodes: {
		SpReplyList: {
			component: 'FLXSpReplyListComponent',
			inputs: {
				assignSpStateList: [20],
				selectSPNavigateToNode: 'AllocateSPSummary',
				subTitle: 'Please select a service provider below'
			},
			serverCalls: {
				interestedParties: {
					serviceVariable: 'buiService',
					functionName: 'getInterestedParties',
					responseSlice: 'payload',
					errorMessage: 'An error occurred getting the interested parties'
				},
				replyListData: {
					errorMessage: 'An error occurred getting the SP Data',
					serviceVariable: 'buiService',
					functionName: 'getSPReplyListData'
				}
			},
			navs: [
				{
					text: 'Deal Out Of App',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'warning';
							instance.setMessage(['You are about to handle this job off of 4-Sure', '', 'Please ensure you understand the implications of this decision']);
							instance.navButtons = [
								{
									text: 'Cancel',
									color: 'alert',
									linkType: 'close'
								},
								{
									text: 'I understand',
									color: 'alert',
									nextNode: 'DealOutOfApp',
									linkType: 'submitThenNext',
									serverCalls: {
										dealOutOfApp: {
											serviceVariable: 'buiService',
											functionName: 'dealOutOfApp',
											errorMessage: 'An error occurred while updating the job'
										}
									}
								}
							];
						}
					}
				},
				{
					text: 'Reping SP Request',
					color: 'primary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'warning';
							instance.setMessage([
								'You are about to reping all the service providers that are able to do this job',
								'',
								'Please ensure you understand the implications of this decision'
							]);
							instance.navButtons = [
								{ text: 'Take me back', color: 'alert', linkType: 'close' },
								{
									text: 'I understand',
									color: 'alert',
									linkType: 'nextNode',
									nextNode: 'SetAppointment'
								}
							];
						}
					}
				}
			]
		},
		DealOutOfApp: {
			component: 'FLXSuccessTickComponent',
			navs: []
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skillcatagory
										skill
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res || !res?.skillcatagory || !res?.skill),
						take(1)
					)
					.subscribe(values => {
						bf.addControl(
							'appointmentData',
							new UntypedFormGroup({
								appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
								appointmentTime: new UntypedFormControl(null, [Validators.required]),
								appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
								appointmentdatetype: new UntypedFormControl(null),
								skill: new UntypedFormControl(values.skill),
								skillcatagory: new UntypedFormControl(values.skillcatagory)
							})
						);
					});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												surname
											}
											loan_information {
												contactnumber
												cellnumber
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											loan_information {
												onsiteperson
												onsitecontact
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			component: 'FLXJobAppointmentComponent',
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'EndSummary',
					serverFirst: true,
					serverCalls: {
						repingSP: {
							errorMessage: 'An error occurred while repinging the Service Provider',
							serviceVariable: 'buiService',
							functionName: 'repingSP'
						}
					}
				}
			]
		},
		AllocateSPSummary: {
			component: 'FLXKeyValueDisplayWithInstructionsComponent',
			inputs: {
				instructions: ['Please double check the details of the selected service provider'],
				title: 'Details for'
			},
			serverCalls: {
				title: {
					errorMessage: 'Could not get SP name',
					directCall: (http, store, sq, bf) => {
						return of(bf.bigForm.get('sp_selected_item')?.value?.name);
					}
				},
				keyValueList: {
					errorMessage: 'data for keyvaluelist not returned',
					directCall: (http, store, sq, bf) => {
						return store.select(getSelectedItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							mergeMap(selectedItem => {
								const job = selectedItem as any;
								return forkJoin([
									http.post(`${environment.api_url}v1/job_action/get_interested_parties/`, { job_id: job?.id }).pipe(
										skipWhile(x => !x),
										map(res => res as any)
									),
									of(bf.bigForm.get('sp_selected_item')?.value)
								]).pipe(
									map(([interested, bigformdata]) => {
										const interestedParties = interested?.payload;
										const selectedSP = bigformdata as any;
										const interestedParty = { ...interestedParties.find(ip => ip.sp.id === selectedSP?.id) };
										let interest;
										switch (interestedParty?.new_interested) {
											case -1: {
												interest = 'Rejected';
												break;
											}
											case 0: {
												interest = 'Ignored';
												break;
											}
											case 1: {
												interest = 'Accepted';
												break;
											}
											case null: {
												interest = 'No-Reply';
												break;
											}
											default:
												break;
										}
										return [
											{ 'Primary Contact': selectedSP.contact_person },
											{ 'Primary Contact Number': selectedSP.contact_primary },
											{ maven_id: selectedSP.mid !== null ? selectedSP.mid : 'Not On Maven' },
											{
												'response to job': interest
											},
											{ 'Currently Awarded Jobs': interestedParty?.awarded_today }
										];
									})
								);
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Allocate this SP',
					color: 'primary',
					nextNode: 'EndSummary',
					serverFirst: true,
					serverCalls: {
						appointSP: {
							errorMessage: 'An error occurred while manually allocating a service provider',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									of(bf.bigForm.get('sp_selected_item')?.value)
								]).pipe(
									switchMap(([selectedjob, selectedsp]) => {
										const job = selectedjob as any;
										const sp = selectedsp as any;
										const job_id = selectedjob?.id;
										const sp_id = selectedsp?.id;
										return http.post(`${environment.api_url}v1/job_action/appoint_sp/`, { job_id: job_id, sp_id: sp_id });
									})
								);
							}
						}
					}
				}
			]
		},
		EndSummary: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		'sp_selected_item.id': 'sp'
	}
};
