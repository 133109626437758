// import { Filter } from '@foursure-platform/shared/src/lib/components/dynamic-filter/models/interfaces';
// import { Filter2 } from '../models/interfaces';
import { Pipe, PipeTransform } from '@angular/core';
import { checkNullUndefined } from '@flexus/utilities';
import { FilterIndexFunction } from '../dynamic-filter.util';
import { Filter2 } from '@flexus/models';
// technically this is a pure pipe but as it is being used programatically
@Pipe({
	name: 'filter'
	// pure: true,
})
export class FilterPipe implements PipeTransform {
	private _previouslyFiltered: any[];
	private _previouslyEmittedKeys: Set<any>;
	private _previouslyUsedFilters: Filter2[];
	private _previouslyIndexedBy: FilterIndexFunction;

	/**
	 * helper function to remove duplicates from a given array
	 * @param arr array to screen out the duplicated
	 * @param indexBy function used to get the object that will be compared and used to determine what is a duplicate
	 */
	private _removeDuplicates(arr: any[], indexBy: FilterIndexFunction = x => x) {
		const objOut = new Map();
		// the bellow will naturally overwrite all duplicates
		for (const entry of arr) {
			objOut.set(indexBy(entry), entry);
		}
		return objOut.values;
	}

	/**
	 * used to convert an array of objects to one of distinct keys using a supplied extraction function
	 * @param arr array to analyse
	 * @param indexBy function to extract the "keys" from the array with
	 */
	private _getDistinctKeySet(arr: any[], indexBy: FilterIndexFunction) {
		const objOut = new Set();
		for (const entry of arr) {
			objOut.add(indexBy(entry));
		}
		return objOut;
	}

	/**
	 * used to compare 2 arrays, will return false if they are different based on key referred to
	 * by indexby
	 * @param arr1 type t
	 * @param arr2 must mach type 1
	 * @param indexBy used to compare, make sure this returns a number
	 */
	private _compareArrays(arr1: any[], arr2: any[], indexBy: FilterIndexFunction) {
		const objOut = new Set();
		for (const entry of arr1) {
			objOut.add(indexBy(entry));
		}
		// now to reduce the second to see if any keys dont exist
		// checking that all keys in second exist in first
		for (const entry of arr2) {
			// will make it false if the object didnt exist to delete
			if (objOut.delete(indexBy(entry)) === false) {
				return false;
			}
		}
		// checking all keys in first existed in the second
		return objOut.size === 0;
		//
	}

	private _compareFilterSets(filterSet1: Filter2[], filterSet2: Filter2[]) {
		if (filterSet1.length !== filterSet2.length) {
			return false;
		}
		// Ill do the compare then skip the memoization entirely

		for (const filter1entry of filterSet1) {
			const idx = filterSet2.findIndex(filterEntry2 => filterEntry2.filt === filter1entry.filt);
			//  if at any point the filter isnt found then the two sets are different
			if (idx === -1) {
				return false;
			}
		}

		return true;
		// return true;
	}

	/**
	 * function used to check if recalculation is needed against the given filter set
	 * @param toBeFiltered array to be filtered
	 * @param toBeFilteredWith set to be used to filter the array
	 * @param indexBy function used to extract the key's from the array
	 */
	private _checkIfRecalculationNeeded(toBeFiltered: any[], toBeFilteredWith: Filter2[], indexBy: (x: any) => any): boolean {
		if (
			checkNullUndefined(this._previouslyFiltered) ||
			checkNullUndefined(this._previouslyEmittedKeys) ||
			checkNullUndefined(this._previouslyUsedFilters) ||
			checkNullUndefined(this._previouslyIndexedBy)
		) {
			// console.log('null endef');
			return true;
		}

		const toCheckEntries = this._removeDuplicates(toBeFiltered);
		const previouslyCheckedEntries = this._removeDuplicates(this._previouslyFiltered);

		switch (true) {
			// case :
			// case
			case toBeFiltered.length !== this._previouslyFiltered.length || toBeFilteredWith.length !== this._previouslyUsedFilters.length:
			// console.log('length');

			// now to compare 1 by one
			case !this._compareArrays(toBeFiltered, this._previouslyFiltered, indexBy):
			// console.log('null different arrays');

			case !this._compareFilterSets(this._previouslyUsedFilters, toBeFilteredWith):
				// console.log('null different sets');

				//
				return true;

			default:
				return false;
		}
	}

	private _recalculate(toBeFiltered: any[], filters: Filter2[], indexBy: FilterIndexFunction): any[] {
		// console.log('recalculating');
		return this._memoize(
			filters.reduce((pv, cv) => pv.filter(cv.filt), toBeFiltered),
			toBeFiltered,
			filters,
			indexBy
		);
	}

	// redo emission but still send
	private _passThroughCalculation(toBeFiltered, indexBy: FilterIndexFunction) {
		const arrOut = [];
		// console.log('passing through');
		for (const entry of toBeFiltered) {
			if (this._previouslyEmittedKeys.has(indexBy(entry)) === true) {
				arrOut.push(entry);
			}
		}
		return arrOut;
	}

	private _memoize(toBeEmitted: any[], toBeFiltered: any[], usedFilters: Filter2[], indexBy: FilterIndexFunction) {
		// TODO:
		this._previouslyEmittedKeys = this._getDistinctKeySet(toBeEmitted, indexBy);
		this._previouslyFiltered = toBeFiltered;
		this._previouslyUsedFilters = usedFilters;
		// the bellow would be tricky to check
		this._previouslyIndexedBy = indexBy;
		return toBeEmitted;
	}

	/**
	 * takes an array of filters and uses it to filter out the value it is given based on the type
	 * indexBy
	 */
	transform(toBeFiltered: any[], filters: Filter2[], indexBy: FilterIndexFunction = x => x.id): any[] {
		// console.log({ toBeFiltered, filters });
		if (checkNullUndefined(toBeFiltered) === true || checkNullUndefined(filters) === true) {
			return [];
		}
		// i did remove the memoization but there is no choice at this point. because of how it is implemented
		//  in the outlet we HAVE to memoize it. but it should also be running far lighter as part of an observable stream
		// removes memoization
		// will need to see impact first though

		return this._recalculate(toBeFiltered.slice(), filters, indexBy);
		// null guards go here
		const res =
			this._checkIfRecalculationNeeded(toBeFiltered, filters, indexBy) === true
				? this._recalculate(toBeFiltered, filters, indexBy)
				: // TODO:
				  this._passThroughCalculation(toBeFiltered, indexBy);

		// console.log(res);
		return res;
	}
}
