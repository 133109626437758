import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Subscription } from 'rxjs';
import { map, pluck, take } from 'rxjs/operators';
import { getAllInfo, getFilterData, getManifestSearchData, ManifestController, SetFilterDataField, SetFilterSet } from '@flexus/core';
import {  ValueSelectionEvent } from '@flexus/ui-composites';
import { Filter2, FilterConfigs, FilterTypes } from '@flexus/models';

@Component({
	selector: 'flx-act-search-filter',
	templateUrl: './search-filter.component.html',
	styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit, OnDestroy {
	configSubscription: Subscription;
	filterSubscription: Subscription;

	states: any;
	skills: any;
	provinces: any;
	sps: any;

	usedFilters: {
		[name: string]: {
			type: FilterTypes;
			values: Array<any>;
			filterFn: (x) => boolean;
			allInfoName: string;
		};
	} = {};

	configuration = {} as any;
	filteredList: {
		[x: string]: FilterConfigs;
	};
	usedFilterKeys: any[] = [];

	constructor(private store: Store<any>, private controller: ManifestController<any>) {}

	get availableFilters(): { [key: string]: FilterConfigs } {
		if (!this.configuration) {
			return {};
		}
		const filterObj = {};
		const configFilters = this.configuration.filters;
		for (const key in configFilters) {
			if (key) {
				filterObj[key] = configFilters[key]?.configuration;
			}
		}
		return filterObj;
	}

	get filterSet(): Array<Filter2> {
		return Object.keys(this.usedFilters)?.map(filterKey => ({
			filterName: filterKey,
			filterValue: this.usedFilters[filterKey]?.values,
			filterType: this.usedFilters[filterKey]?.type,
			allInfoName: this.usedFilters[filterKey]?.allInfoName,
			filt: this.usedFilters[filterKey]?.filterFn
		}));
	}

	filterList() {
		this.filteredList = { ...this.availableFilters };
		this.usedFilterKeys = Object.keys(this.usedFilters);
		if (this.usedFilterKeys.length !== 0) {
			for (const key in this.availableFilters) {
				if (this.usedFilters[key] !== undefined) {
					const { [key]: removed, ...rest } = this.filteredList;
					this.filteredList = rest;
				}
			}
		}
	}

	removeFilter(name: string) {
		const { [name]: deleted, ...rest } = this.usedFilters;
		this.usedFilters = { ...rest };
		this.usedFilterKeys = Object.keys(this.usedFilters);
		this.updateStore();
		this.filterList();
	}

	handleValueSelection(e: ValueSelectionEvent) {
		const { selectedEntry, values } = e;
		let filterFn: (a: any) => boolean;
		if (this.configuration.filters[selectedEntry]?.filterFactory !== undefined) {
			filterFn = this.configuration.filters[selectedEntry]?.filterFactory(values);
		}
		this.usedFilters = {
			...this.usedFilters,
			[e.selectedEntry]: {
				type: e.selectionType,
				values: e.values,
				filterFn,
				allInfoName: this.configuration.filters[selectedEntry]?.allInfoName
			}
		};
		this.updateStore();
	}

	updateStore() {
		this.store.dispatch(new SetFilterDataField({ key: 'filters', data: this.usedFilters }));
		this.store.dispatch(new SetFilterSet({ filterSet: this.filterSet }));
		this.store.dispatch(new SetFilterDataField({ key: 'source', data: this.filterSet.length > 0 ? 'search-filter' : 'none' }));
	}

	ngOnInit(): void {
		this.configSubscription = forkJoin([
			this.controller.select(getManifestSearchData)?.pipe(take(1)),
			this.store.pipe(take(1)),
			this.controller.stateObservable.pipe(take(1))
		])?.subscribe(([searchFunc, store, controller]: any) => {
			this.configuration = searchFunc(store, controller);
		});

		this.store
			.select(getAllInfo)
			.pipe(
				take(1),
				map(x => {
					this.states = x['states'];
					this.skills = x['skills'];
					this.sps = x['sps'];
					this.provinces = x['active_regions'];
				})
			)
			.subscribe();

		this.filterSubscription = this.store
			.select(getFilterData)
			.pipe(pluck('filters'))
			.subscribe((filters: any) => {
				if (!!filters) {
					this.usedFilters = filters;
				}
				this.filterList();
			});
	}

	ngOnDestroy(): void {
		if (this.configSubscription) this.configSubscription.unsubscribe();
		if (this.filterSubscription) this.filterSubscription.unsubscribe();
	}
}
