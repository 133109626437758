import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BigFormService } from '@flexus/core';

@Component({
	selector: 'flx-job-excess',
	templateUrl: './job-excess.component.html',
	styleUrls: ['./job-excess.component.scss']
})
export class FLXJobExcessComponent implements OnInit {
	@Input() whoPaysExcessOptions$: Observable<{ id: number; name: string }[]>;
	@Input() howExcessIsPaidOptions$: Observable<{ id: number; name: string }[]>;

	public excessData;
	public mustIncludeSkillDetails = true;

	constructor(public bf: BigFormService) {}

	ngOnInit() {
		this.excessData = this.bf.bigForm.get('excessData');
	}
}
