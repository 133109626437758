<div class="container" [formGroup]="CsvPaymentForm">
	<div class="csv-cell">{{ sp_name }}</div>
	<div class="csv-cell">{{ bank_name }}</div>
	<div class="csv-cell">{{ account_type }}</div>
	<div class="csv-cell">{{ bank_branch }}</div>
	<div class="csv-cell">{{ acc_number }}</div>
	<div class="csv-cell">{{ amount }}</div>
	<div class="csv-cell">{{ statement_ref }}</div>
	<div class="csv-cell">{{ sp_email }}</div>
	<div class="csv-cell">{{ sps_sms }}</div>
	<div class="csv-cell"><flx-checkbox formControlName="exclude_from_csv"></flx-checkbox></div>
</div>
