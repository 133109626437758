import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { UntypedFormControl } from '@angular/forms';
import { take, map, filter, switchMap } from 'rxjs/operators';
import { BUI_64 } from './BUI_64';
import { environment } from 'apps/studio/src/environments/environment';

export const BUI_63: Flow_0_0_2 = {
	id: '63',
	name: 'unmanaged_claim_or_region',
	itemType: 'flow',
	header: {
		title: 'EXTERNAL CLAIM PICKUP',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Unmanaged Claim'
		},
		viewRoles: {
			0: 'Unmanaged Claim'
		}
	},
	events: {},
	serverCalls: {
		claimMid: {
			ignoreFalseError: true,
			serviceVariable: 'buiService',
			functionName: 'getClaimMid',
			errorMessage: 'No Maven claim number returned!'
		}
	},
	startNode: 'UnmanagedClaim',
	nodes: {
		UnmanagedClaim: {
			component: 'FileViewWithExtraComponent',
			mockContextData: {},
			checkValidityForFields: [],
			initFormFields: (bf, item, instance, storeQuery) => {
				bf.addControl('new_state', new UntypedFormControl('64'));
				bf.addControl('claim_id', new UntypedFormControl(''));
				bf.addControl('claim_handler', new UntypedFormControl(''));
			},
			serverCalls: {
				keyValueList: {
					errorMessage: `Couldn't retrieve claim/claimant details`,
					directCall: (http, store) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map((res: any) => {
								const arrobj = {
									Claimant: res && res?.applicant ? res?.applicant?.first_name + ' ' + res?.applicant?.surname : '',
									'Claim Type': res && res?.loan_information ? res?.loan_information.claimtype : '',
									Address:
										res && res?.loan_information
											? res?.loan_information.propertystreetaddress + ', ' + res?.loan_information.propertysuburb + ', ' + res?.loan_information.propertycity
											: '',
									'Contact Number': res && res?.loan_information ? res?.loan_information.contactnumber : '',
									'Cell Number': res && res?.loan_information ? res?.loan_information.cellnumber : '',
									'What Matters': res && res?.loan_information ? res?.loan_information.whatmatters : ''
								};
								return [arrobj];
							})
						);
					}
				}
			},
			inputs: {
				inputHeading: 'Unmanaged Claim Type',
				fileDoesNotExistMessage: '',
				subHeading: `
                 This is not a claim class or province that is managed by 4-Sure \n
              The claim is already opened in Maven but needs to be manually managed
        `,
				instructions: [['Start by allocating this claim to your workflow.']]
			},
			navs: [
				{
					text: 'Allocate Claim',
					linkType: 'portal',
					intersectData: (bf, store) => {},
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Claim not updated',
							// serviceVariable: 'buiService',
							// functionName: 'updateClaim'
							directCall: (http, store, sq, bf, controller, modal) => {
								return store
									.select(getFullItemOne)
									.pipe(
										filter(x => !!x && Object.keys(x).length !== 0),
										take(1),
										map(res => res as any)
									)
									.pipe(
										switchMap(claim => {
											bf.addControl('claim_id', new UntypedFormControl(claim?.id));
											bf.addControl('claim_handler', new UntypedFormControl(claim?.loan_information.handler));
											const claim_id = claim?.id;
											const handler = claim?.handler;
											const new_state = 64;
											const data = {
												claim_id: claim_id,
												new_state: new_state,
												claim_handler: handler
											};
											return http.post(`${environment.api_url}v1/claim_action/update_claim`, data);
										})
									);
							}
						}
					},
					portalData: {
						type: 'modal',
						paramFunc: (instance, store) => {
							instance.setMessage(['This claim has been allocated to your workflow to manage']);
							instance.type = 'info';
							instance.navButtons = [
								{
									text: 'Continue',
									color: 'primary',
									linkType: 'nextNode',
									nextNode: 'SubmissionSuccess'
								}
							];
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			decisions: {}
		},
		ManuallyManagedClaim: {
			...BUI_64
			// mockContextData: {},
			// navs: [
			//   {
			//     text: 'Allocate Claim',
			//     nextNode: 'SummarySuccess',

			//     color: 'primary'
			//   }
			// ]
		}
		// SummarySuccess: {
		//   component: 'FLXSuccessTickComponent'
		// }
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		claim_id: 'claim_id',
		claim_handler: 'claim_handler'
	}
};
