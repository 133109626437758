import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getAllInfo, SetNextNode, GetFullItemTwo } from '@flexus/core';
import { filter, skipWhile, first, map, take, switchMap, pluck } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { forkJoin, of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { BUI_BANK_DETAILS } from './BANK_DETAILS';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CustomValidators, getAllInfoIndex } from '@flexus/utilities';
import { AVSResultFlag } from '@flexus/screens';

export const BUI_58: Flow_0_0_2 = {
	id: '58',
	name: 'initiate_cil_payment',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Initiate Cash-in-lieu payment : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Initiate Cash-in-lieu payment';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Initiate Cash-in-lieu payment'
		},
		viewRoles: {
			0: 'Initiate Cash-in-lieu payment'
		}
	},

	serverCalls: {
		dataFiles: {
			serviceVariable: 'buiService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		}
	},

	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},

	startNode: 'CheckBankDetails',
	nodes: {
		CheckBankDetails: {
			nodeType: 'decision',
			decisions: {
				check_for_AOL_ID: (navs, store, modal, controller) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.subscribe((job: any) => {
							if (job?.office_use?.avsresult && ['1', '-2']?.includes(job?.office_use?.avsresult)) {
								controller.dispatch(new SetNextNode('ViewInfo'));
							} else {
								controller.dispatch(new SetNextNode('BankDetails'));
							}
						});
				}
			},
			navs: [{ text: 'Success', nextNode: 'SubmissionSuccess' }]
		},
		ViewInfo: {
			component: 'FileViewWithExtraComponent',
			serverCalls: {
				keyValueList: {
					errorMessage: '',
					directCall: (http, store, sq, bf, cont, modal) => {
						const invoice$ = sq
							.queryStore(
								gql`
									{
										selectedContext {
											dataFiles
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1)
							);
						return forkJoin([
							store.select(getFullItemOne).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							invoice$.pipe(
								skipWhile(x => !x),
								pluck('dataFiles'),
								take(1)
							)
						]).pipe(
							take(1),
							map(([claim, job, allInfo, invoice]) => {
								const list = {
									'Claim Type': claim ? claim?.type : '',
									'Client Name': claim && claim?.applicant ? claim?.applicant?.first_name + ' ' + claim?.applicant?.surname : '',
									'Contact number': claim && claim?.applicant ? claim?.loan_information.contactnumber : '',
									'Client Alternative no': claim && claim?.loan_information ? claim?.loan_information.cellnumber : '',
									'Excess collection': ((): string => {
										let collection = '';
										if (job.excess[0]) {
											allInfo['excess_who']?.forEach(element => {
												if (element.id === job?.excess[0]?.who_collects) {
													collection = element.name;
												}
											});
										}
										return collection;
									})(),
									'Excess amount': job?.excess?.length !== 0 ? `R ${job.excess[0]?.amount}` : 0,
									'Excess Payment': ((): string => {
										let pay = '';
										if (job.excess[0]) {
											allInfo['excess_how']?.forEach(element => {
												if (element.id === job?.excess[0]?.how_collect) {
													pay = element.name;
												}
											});
										}
										return pay;
									})(),
									'Excess State': ((): string => {
										let state = '';
										if (job.excess[0]) {
											allInfo['excess_states']?.forEach(element => {
												if (element.id === job?.excess[0]?.state) {
													state = element.name;
												}
											});
										}
										return state;
									})(),
									'Inspection fee only': claim?.inspection || 'No',
									'Consignment stock used': claim?.consignment || 'No',
									'What mattered to the customer': claim && claim?.loan_information.whatmatters ? claim?.loan_information.whatmatters : ''
								};

								// ================================== Check for required data ==================================
								const reasons = [];
								bf.bigForm.addControl('currAvsResult', new UntypedFormControl(`${job?.office_use?.avsresult}`, CustomValidators.contains(['1', '-2'])));
								bf.bigForm.addControl('invoice', new UntypedFormControl(invoice?.data ? true : false, Validators.requiredTrue));

								if (!(invoice && invoice?.data)) {
									reasons.push('No invoice has been uploaded.');
								}
								if (bf.bigForm.get('currAvsResult').invalid) {
									reasons.push('No valid bank details have been entered into the system for this job');
								}

								if (reasons.length > 0) {
									modal.openModalDirectly(inst => {
										inst.heading = 'Unable to progress';
										inst.subheading = 'Please complete all the steps required to start the payment process';
										inst.message = reasons?.toString();
										inst.type = 'warning';
									});
								}

								return [list];
							})
						);
					}
				}
			},
			inputs: {
				inputHeading: 'Inititate Cash in Lieu Payment',
				subHeading: 'Confirm CIL invoice and bank details to continue',
				fileDoesNotExistMessage: 'No inovice has been uploaded. Please up load one to continue.'
			},
			checkValidityForFields: ['currAvsResult', 'invoice'],
			navs: [
				{ text: 'View / Edit Bank Details', nextNode: 'BankDetails' },
				{
					text: 'Continue',
					serverFirst: true,
					color: 'primary',
					optIntoValidation: true,
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const { job_information, id } = job;
											const result = JSON.parse(JSON.stringify(job_information));
											const subData = {
												job_information: result,
												job_id: id,
												new_state: 27
											};
											return subData;
										}),
										switchMap(dataSub => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					}
				}
			]
		},
		BankDetails: {
			...BUI_BANK_DETAILS,
			inputs: {
				...BUI_BANK_DETAILS.inputs,
				modalNavSuccess: {
					linkType: 'submitThenNext',
					serverCalls: {
						saveBankDetails: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!',
							followUpSuccessCalls: {
								getJob: {
									errorMessage: 'Could not get job!',
									ignoreFalseError: true,
									directCall: (http, store, sq, bf) => {
										store.dispatch(new GetFullItemTwo({ id: bf.bigForm.get('itemTwo.id')?.value }));
										return store
											.select(getFullItemTwo)
											.pipe(
												skipWhile(x => !x),
												take(1)
											)
											.subscribe((job: any) => {
												if (job?.office_use?.avsresult) {
													const avsRes = job?.office_use?.avsresult;
													if (bf.bigForm.get('currAvsResult')) {
														bf.bigForm.get('currAvsResult').patchValue(avsRes);
													} else {
														bf.bigForm.addControl('currAvsResult', new UntypedFormControl(`${avsRes}`, CustomValidators.contains(['1', '-2'])));
													}
												}
											});
									}
								}
							}
						}
					},
					nextNode: 'ViewInfo',
					text: 'Continue',
					color: 'primary'
				}
			},
			navs: [
				{
					text: 'Avs Check',
					serverFirst: true,
					serverCalls: {
						avsCheck: {
							errorMessage: 'Could not run AVS Check!',
							ignoreFalseError: true,
							directCall: (http, store, sq, bf) => {
								const avsData = bf.bigForm.get('avs_data')?.value;
								if (avsData && !!avsData.branch_code) {
									const avsLookup = {
										bank_acc_number: avsData.account_number,
										idnumber: avsData.identification_number,
										acc_type: avsData.acc_type,
										branch_code: avsData.branch_code,
										isnonpanel: avsData.account_use === 'Individual' ? false : true,
										initials: avsData.initials,
										surname: avsData.account_holder_name
									};
									return http.post(`${environment.api_url}v1/claim_action/check_account_details/`, avsLookup);
								} else {
									return of({
										success: false,
										reason: 'Please use the Bank and Branch Name fields to search and select a branch before performing an AVS check.'
									});
								}
							}
						}
					}
				}
			]
		}
	},
	bigFormToStoreMapper: {
		'itemTwo.id': 'job_id',

		//bank details
		'bank_details.initials': 'office_use.initials',
		'bank_details.account_holder_name': 'office_use.surname',
		'bank_details.account_number': 'office_use.bank_acc_number',
		'bank_details.identification_number': 'office_use.idnumber',
		'bank_details.branch_code': 'office_use.branch_code',
		'bank_details.bank_name': 'office_use.bank',
		'bank_details.branch': 'office_use.branch',
		'bank_details.acc_type': 'office_use.acc_type',
		acc_type_desc: [
			(desc, storeObj, formValue) => {
				if (formValue.bank_details !== undefined) {
					return getAllInfoIndex('account_type', 'mid', 'description', formValue.bank_details.acc_type, storeObj);
				} else {
					return null;
				}
			},
			'office_use.acc_type_desc'
		],
		'bank_details.account_use': [
			val => {
				return val === 'Individual' ? false : true;
			},
			'office_use.isnonpanel'
		],
		'bank_details.avsSuccess': 'office_use.avsresult',
		'bank_details.avsFailedReasons': [
			(val, storeObj, formVals) => {
				if (formVals.avsSuccess === AVSResultFlag.fail_force_payment) return val;
				else return null;
			},
			'office_use.avsinitialsfailreason'
		]
	}
};
