import { Flow_0_0_2, getFullItemTwo, SetPreviousNode, MakeServerCall, getSelectedItemTwo } from '@flexus/core';
import { Validators, UntypedFormControl } from '@angular/forms';
import { CustomValidators, getExcessCalculationValue, findExcessRow, makeExcessCard, generateRange, getExtension } from '@flexus/utilities';
import { take, map, tap, switchMap, skipWhile, delay } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { of } from 'rxjs';
import gql from 'graphql-tag';

export const SINGLE_FILE_UPLOAD_SINGLE_PURPOSE: Flow_0_0_2 = {
	id: 'single_file_upload_single_purpose',
	name: 'single_file_upload_single_purpose',
	itemType: 'flow',
	header: {
		title: 'Upload Document',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	startNode: 'UploadDocument',
	nodes: {
		// Upload node
		UploadDocument: {
			component: 'FLXFileUploadWithPreviewComponent',
			name: 'Upload Document',
			initFormFields: (bf, item, instance, sq, store) => {
				bf.bigForm.addControl(
					'invoice_amount',
					new UntypedFormControl('', [
						Validators.required,
						Validators.minLength(2),
						CustomValidators.hardMaxLength(13),
						CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])
					])
				);
				bf.bigForm.addControl('invoice_number', new UntypedFormControl('', [Validators.required]));
				bf.bigForm.addControl('excess_amount', new UntypedFormControl(null, [Validators.required]));

				bf.bigForm.addControl('purpose', new UntypedFormControl('Invoice', [Validators.required]));
			},
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'none'
			},
			inputs: {
				// configurations
				purposes: [{ display: 'Invoice', value: 'Invoice' }], // TODO: extend to select purpose type of doc uploading (invoice etc..)
				heading: 'Select a file to upload',
				subheading: 'Select a pdf file to upload',
				multipleUploads: false,
				allowedFileTypes: ['pdf'],
				formControls: {
					0: {
						formControlName: 'invoice_amount',
						label: 'Enter Invoice Amount',
						inputType: 'input'
					},
					1: {
						formControlName: 'invoice_number',
						label: 'Enter Invoice Number',
						inputType: 'input'
					},
					2: {
						formControlName: 'excess_amount',
						label: 'Confirm Excess',
						inputType: 'input'
					}
				},
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			showTabs: true,
			checkValidityForFields: ['invoice_amount', 'invoice_number', 'excess_amount'],
			navs: [
				{
					text: 'Upload Document',
					optIntoValidation: true,
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						uploadDoc: {
							errorMessage: 'File could not be uploaded',
							timeoutMilliseconds: 2000,
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0]?.file.base64,
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									invoice_number: bf.bigForm.get('invoice_number')?.value,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
							},
							followUpSuccessCalls: {
								// Change / Add excess to invoice
								updateExcess: {
									errorMessage: 'Can not update excess!!',
									directCall: (http, store, sq, bf) => {
										const itemTwo = bf.bigForm.get('itemTwo')?.value;
										// check if the excess field exists, else it ignores it
										if (bf.bigForm.get('excess_amount')?.value !== undefined) {
											let allExcess = [];
											if (itemTwo.excess !== undefined && itemTwo?.excess !== null) {
												if (!Array.isArray(itemTwo.excess)) {
													allExcess.push(itemTwo.excess);
												} else {
													allExcess = itemTwo?.excess;
												}
											}
											const jobExcess = findExcessRow(itemTwo.id, allExcess);
											let params = {};
											const excessVal = getExcessCalculationValue(bf.bigForm.get('invoice_amount')?.value, bf.bigForm.get('excess_amount')?.value);

											if (jobExcess.length === 0) {
												params = { job_id: itemTwo?.id, new_excess: makeExcessCard(excessVal) }; // Attach new excess to job
											} else if (jobExcess.length >= 1) {
												params = { excess_id: jobExcess[0]?.id, new_value: excessVal }; // change existing excess
											}
											return http.post<any>(`${environment.api_url}v1/job_action/change_excess/`, params);
										} else {
											return of(false);
										}
									}
								},
								// load the uploaded invoice again
								// followUpSuccessCalls: {
								goToPreviousNode: {
									errorMessage: 'Cannot load invoice after upload',
									isBackgroundTask: false,
									directCall: (h, st, sq, bf, controller, modal) => {
										modal.openModalDirectly(inst => {
											inst.type = 'success';
											inst.heading = 'Successfully uploaded document.';
											inst.navButtons = [
												{
													text: 'Continue',
													color: 'primary',
													linkType: 'close'
												}
											];
										});
										controller.dispatch(new SetPreviousNode());
										return of({ success: true, payload: [] }).pipe(
											skipWhile(x => !x),
											delay(1500),
											take(1),
											tap(() => {
												st.dispatch(
													new MakeServerCall({
														dataKey: 'dataFiles',
														errorMessage: 'No invoice found!',
														ignoreFalseError: true,
														directCall: (http, store, sq, bf) => {
															return store.select(getSelectedItemTwo).pipe(
																take(1),
																switchMap(itemTwo => {
																	return http
																		.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
																			job_id: itemTwo['id'],
																			return_type: 1
																		})
																		.pipe(
																			take(1),
																			map(value => {
																				bf.bigForm.addControl('invoice', new UntypedFormControl(true));
																				return value.payload;
																			})
																		);
																})
															);
														}
													})
												);
											})
										);
									}
								}
								// }
								// }
							},
							followUpFailCalls: {
								displaySucces: {
									errorMessage: 'Could not diplay success',
									directCall: (http, store, sq, bf, con, modal) => {
										modal.openModalDirectly(inst => {
											inst.type = 'warning';
											inst.heading = 'Could not upload file.';
											inst.navButtons = [
												{
													text: 'Continue',
													color: 'secondary',
													linkType: 'close'
												}
											];
										});
										return of(false);
									}
								}
							}
						}
					}
				}
			]
		},
		// Check excess node
		checkExcess: {
			component: 'FLXDynamicTableComponent',
			name: 'View Claim Excess',
			serverCalls: {
				tableData: {
					errorMessage: 'No excess details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											office_use
											excess
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map(({ office_use, excess }) => {
									return excess.map(excessItem => ({
										Reason: excessItem.reason,
										job: office_use ? office_use.skill : '',
										who: office_use && office_use.excess ? office_use.excess.who_collects_excess_desc : '',
										how: office_use && office_use.excess ? office_use.excess.excess_payment_method_desc : '',
										amount: excessItem.amount
									}));
								})
							);
					}
				}
			},
			inputs: {
				heading: 'Claim Excess',
				subheading: 'Current excess for all jobs attached to this claim'
			},
			showTabs: true
		}
	}
};
