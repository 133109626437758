import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BigFormService, ManifestController, SetNextNode } from '@flexus/core';

@Component({
	selector: 'flx-dynamic-anchor',
	templateUrl: 'dynamic-anchor.component.html',
	styleUrls: []
})
export class DynamicAnchorComponent implements OnInit, AfterViewInit {
	@ViewChild('link') link: ElementRef;

	@Input() blobAndFilenameObject$;
	invoiceData;
	datastring;
	filename;

	constructor(private controller: ManifestController<any>, private _bf: BigFormService, private _sanitise: DomSanitizer) {}

	ngOnInit() {
		this.invoiceData = this._bf.getControl('invoice_data').value;
		this.datastring = this.invoiceData?.data;
		this.filename = this.invoiceData?.filename;
	}
	ngAfterViewInit() {
		const linkEl: HTMLAnchorElement = this.link.nativeElement;
		linkEl.click();
		this.controller.dispatch(new SetNextNode('SubmissionSuccess'));
	}

	createDownload(base64string: string) {
		let temp = null;
		if (base64string != null) {
			const blob = this.base64StringtoBlob(base64string, 'application/pdf');
			temp = this._sanitise.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
		}
		return temp;
	}

	base64StringtoBlob(b64Data, contentType = '', sliceSize = 512): Blob {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}
}
