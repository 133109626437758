import {
	Flow_0_0_2,
	getAllInfo,
	getCurrentUser,
	getData,
	getFullItemOne,
	getFullItemTwo,
	getSelectedItemOne,
	getSelectedItemTwo,
	getSubmissionData,
	MakeServerCall,
	SetPreviousNode
} from '@flexus/core';
import { CustomValidators, findExcessRow, findName, getAllInfoIndex, getExcessCalculationValue, getExtension, makeExcessCard } from '@flexus/utilities';
import { SINGLE_FILE_UPLOAD_SINGLE_PURPOSE } from './single_file_upload_single_purpose';
import { take, switchMap, map, skipWhile, filter, first, pluck, delay, tap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { FormControl, Validators } from '@angular/forms';
import { forkJoin, of } from 'rxjs';

export const BET_218: Flow_0_0_2 = {
	id: '218',
	name: 'incident_management_fee',
	fetchLevel1And2: true,
	itemType: 'flow',
	header: {
		title: 'Upload Invoice ',
		controls: () => () => []
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {
		// Clear out key value list from previous flow
		keyValueList: {
			errorMessage: '',
			directCall: () => {
				return of([]);
			}
		}
	},
	startNode: 'ClaimSummary',
	nodes: {
		ClaimSummary: {
			component: 'FLXNestedObjectKeyValueListComponent',
			showTabs: true,
			name: 'Claim Information',
			inputs: {
				headingConfig: { title: 'Claim Information', color: 'default', size: 'medium' },
				subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' }
			},
			serverCalls: {
				objectInfo: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemOne)
							.pipe(
								filter(x => !!x),
								take(1)
							)
							.pipe(
								map((claim: any) => {
									const txt = {
										claim_summary: {
											Claimant: `${claim?.applicant?.first_name} ${claim?.applicant?.surname}`,
											'Policy Number': claim?.applicant?.claimantpoliceynum,
											'Contact Number': claim?.applicant?.contact_number
										}
									};
									return txt;
								})
							);
					}
				}
			},
			navs: [
				{
					text: 'Cancel Job',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					color: 'secondary',
					linkType: 'submit',
					serverCalls: {
						response: {
							errorMessage: 'Job could not be removed',
							directCall: (http, store, sq, bf) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										switchMap(job => {
											const spJob = job as any;
											const new_state = 45;

											const data = {
												job_id: spJob.id,
												new_state
											};
											return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
										})
									);
							}
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'UploadDocument' }
			]
		},
		JobInformation: {
			component: 'FLXNestedObjectKeyValueListComponent',
			showTabs: true,
			name: 'Job Information',
			inputs: {
				headingConfig: { title: 'Job Summary', color: 'default', size: 'medium' },
				subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' }
			},
			serverCalls: {
				objectInfo: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemOne).pipe(
								filter(x => !!x),
								take(1)
							),
							store.pipe(take(1))
						]).pipe(
							map(([claim, storeObj]: any) => {
								const skills = storeObj?.allInfo?.skills;
								const states = storeObj?.allInfo?.states;
								const txt = {};
								if (claim?.jobs !== undefined) {
									for (const job of claim?.jobs) {
										const myVal = getAllInfoIndex('sps', 'id', 'name', job?.sp, storeObj);
										const jobtxt = { 'Service provider name': myVal };

										const jobState = states?.filter(state => job?.state === state?.id);
										if (jobState?.length === 1) {
											jobtxt['State'] = `${jobState[0]?.id} : ${jobState[0]?.description}`;
										}

										const jobSkill = skills?.filter(skill => job?.skill === skill?.id);
										txt[jobSkill[0]?.name] = jobtxt;
									}
								}
								return txt;
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Cancel Job',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					color: 'secondary',
					linkType: 'submit',
					serverCalls: {
						response: {
							errorMessage: 'Job could not be removed',
							directCall: (http, store, sq, bf) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										switchMap(job => {
											const spJob = job as any;
											const new_state = 45;

											const data = {
												job_id: spJob.id,
												new_state
											};
											return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
										})
									);
							}
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'UploadDocument' }
			]
		},
		UploadDocument: {
			...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes?.UploadDocument,
			inputs: {
				...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes?.UploadDocument.inputs,
				formControls: {
					0: {
						formControlName: 'claim_value',
						inputType: 'currency',
						label: 'Invoice amount to be paid'
					},
					1: {
						formControlName: 'invoice_number',
						label: 'Enter Invoice Number',
						inputType: 'input'
					}
				}
			},
			hideTabItem: true,
			checkValidityForFields: ['claim_value', 'invoice_number'],
			navs: [
				{
					text: 'Upload Document',
					optIntoValidation: true,
					color: 'primary',
					serverFirst: true,
					nextNode: 'AuthorizerSelect',
					serverCalls: {
						uploadDoc: {
							errorMessage: 'File could not be uploaded',
							timeoutMilliseconds: 2000,
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0]?.file.base64,
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									invoice_number: bf.bigForm.get('invoice_number')?.value,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
							}
						}
					}
				}
			]
		},
		AuthorizerSelect: {
			component: 'FLXAuthoriserSelectComponent',
			serverCalls: {
				authorisers: {
					errorMessage: 'No authorizers could be found!',
					directCall: (http, store, sq, bf) => {
						const claimVal = bf.bigForm.get('claim_value')?.value;
						return store.select(getCurrentUser).pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(curUser =>
								http.get(environment.api_url + 'v1/staff_action/get_authorizers').pipe(
									map(arr => arr['payload']?.filter(x => x.max_auth >= claimVal && x.id !== curUser?.user?.id)),
									map((obj: { [key: string]: { id: number; full_name: string } }) =>
										Object.values(obj).map(entry => ({
											display: entry?.full_name,
											value: entry?.id
										}))
									)
								)
							)
						);
					}
				}
			},
			inputs: {
				heading: 'Select who will continue the payment process',
				subheading: 'Scroll and select a name or start typing to limit the list',
				canFilter: true,
				maxWidth: '300px'
			},
			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ new_state: 50 });
				bf.patchValues({ nextApprovalStaffName: '' });
				bf.bigForm.get('nextApprovalStaffName')?.setValidators([Validators.required]);
				forkJoin([
					store.select(getFullItemOne).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getCurrentUser).pipe(
						skipWhile(x => !x),
						take(1)
					)
				])
					.pipe(take(1))
					.subscribe(([claim, user]) => {
						bf.patchValues({
							paymentapprovalhandler: claim?.application_creator?.id ?? '',
							paymentapproval1: user?.user?.id
						});
					});
			},
			checkValidityForFields: ['nextApprovalStaffName'],
			navs: [
				{
					text: 'Continue',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		nextApprovalStaffName: [
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.nextApprovalStaffName'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.authid'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'authid'
			]
		],
		paymentapprovalhandler: 'job_information.paymentapprovalhandler',
		paymentapproval1: 'job_information.paymentapproval1',
		current_state: 'current_state',
		new_state: 'new_state',
		vat_rate: '15',
		claim_value: ['claim_value', 'job_information.claim_value'],
		invoice_number: ['invoice_number', 'job_information.invoice_number']
	}
};
