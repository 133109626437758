import { Flow_0_0_2, getFullItemOne, getCurrentUser, getSelectedItemTwo, getAllInfo, getData } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { map, take, filter, first, skipWhile, pluck } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin } from 'rxjs';
import { findName } from '@flexus/utilities';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const SIL_76: Flow_0_0_2 = {
	id: '76',
	name: 'job_managed_on_maven',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map(itemOne => {
					if (itemOne) {
						return 'Manage Job On Maven : ' + itemOne?.loan_information?.mavenclaimnumber + ' - ' + itemOne?.applicant?.surname;
					} else {
						return 'Manage Job on Maven';
					}
				})
			);
		},
		controls: () => () => []
	},
	actionPanel: instance =>
		setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Manually manage job on Maven'
		},
		viewRoles: {
			0: 'Job Managed On Maven'
		}
	},
	startNode: 'ManuallyManagedClaim',
	nodes: {
		ManuallyManagedClaim: {
			component: 'FileViewWithExtraComponent',
			initFormFields: (bf, item, inst, sq, store) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(28));
				store
					.select(getCurrentUser)
					.pipe(pluck('user', 'id'), take(1))
					.subscribe(id => {
						bf.bigForm.addControl('handler', new UntypedFormControl(id));
					});
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Cannot get retrieve data',
					directCall: (http, store) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							// map((vals) => vals.claim),
							map((vals: any) => {
								const res = {
									Claimant: vals?.applicant ? vals.applicant?.first_name + ' ' + vals.applicant?.surname : '',
									Address: vals?.loan_information
										? vals.loan_information.propertystreetaddress + ', ' + vals.loan_information.propertysuburb + ', ' + vals.loan_information.propertycity
										: '',
									'Contact Number': vals?.loan_information?.contactnumber ?? '',
									'Cell Number': vals?.loan_information?.cellnumber ?? '',
									'What Matters': vals?.loan_information?.whatmatters ?? ''
								};
								return [res];
							})
						);
					}
				},
				copyText: {
					errorMessage: 'Cannot return maven claim number!',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map((vals: any) => {
								return { key: 'MAVEN CLAIM NO: ', value: vals.mid };
							})
						);
					}
				}
			},
			inputs: {
				inputHeading: 'Manually Managed Job On Maven',
				subHeading: 'Copy claim number and use in Maven',
				instructions: [
					'The claim is already opened in Maven but needs to be manually managed.',
					'Complete the job manually on Maven.',
					'Once the claim is done, select "Complete Claim" below.'
				],
				containerWidth: '50vw'
			},
			navs: [
				{
					text: 'Complete Claim',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'info';
							instance.setMessage(['This claim MUST be managed on Maven!', '', 'Have you manually completed this job on Maven?']);
							instance.navButtons = [
								{
									text: 'Back',
									color: 'alert',
									linkType: 'close'
								},
								{
									text: 'Confirm',
									color: 'primary',
									linkType: 'nextNode',
									nextNode: 'SpReplyList'
								}
							];
						}
					}
				}
			]
		},
		SpReplyList: {
			component: 'FLXSpReplyListComponent',
			inputs: {
				assignSpStateList: [76],
				hasSearch: true,
				selectSPNavigateToNode: 'SubmissionSuccess'
			},
			initFormFields: (bf, item, comp, sq) => {
				bf.addControl('new_state', new UntypedFormControl(28));
				bf.addControl('sp_list_search', new UntypedFormControl(null, [Validators.required]));
				bf.addControl('sp_selected_item', new UntypedFormControl(null));
			},
			serverCalls: {
				interestedParties: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return http
							.post<any>(`${environment.api_url}v1/sp_action/search/`, {
								skill_id: 6
							})
							.pipe(
								skipWhile(x => !x),
								pluck('payload')
							);
					}
				},
				replyListData: {
					errorMessage: 'An error occurred getting the SP Data',
					directCall: (http, store, sq, bf) => {
						return forkJoin([
							store.select(getSelectedItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getCurrentUser).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								take(1),
								map((allInfo: any) => allInfo.sps)
								// map((sps: any[]) => sps.reduce((acc, sp: any) => ({ ...acc, [sp.id]: sp }), {})),
							),
							store.select(getData).pipe(
								pluck('interestedParties'),
								skipWhile(x => !x),
								take(1)
							)
						]).pipe(
							map(([job, user, sps, parties]: any) => {
								if (!!job && !!user && !!sps && !!parties) {
									const isTeamLeader = user?.user?.is_team_leader === true;
									const templateTitle = `The Currently assigned SP is : ${job.sp ? findName(job.sp, sps).name : 'none'}`;
									const listConfig = { alwaysEmitEvent: true };

									const data = parties.map((party: any) => {
										return {
											entityId: party?.id,
											columns: [{ title: party?.name }]
										};
									});
									return {
										isTeamLeader,
										templateTitle,
										listConfig,
										cardData: data || [],
										loadingMessage: 'loading',
										noCardsMessage: 'There are no records to display'
									};
								}
								return {
									templateTitle: '',
									cardData: []
								};
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					linkType: 'submit'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			serverCalls: {
				manuallyManagedClaimResponse: {
					errorMessage: '',
					serviceVariable: 'service',
					functionName: 'updateUnmanagedMavenJob'
				}
			}
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'sp_selected_item.id': 'job_information.OutAppFlowSPid',
		'sp_selected_item.name': 'job_information.OutAppFlowSPName',
		'sp_selected_item.mid': 'job_information.OutAppFlowSPMID',
		sp_list_search: 'job_information.OutAppFlowSPName'
	}
};
