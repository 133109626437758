import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { getSelectedItemOne, getAllInfo, getSelectedItemTwo, getFullItemOne, getCurrentUser } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { findName } from '@flexus/utilities';
import { TabsData } from '@flexus/ui-elements';

@Component({
	selector: 'flx-job-summary',
	templateUrl: './job-summary.component.html',
	styleUrls: ['./job-summary.component.scss']
})
export class JobSummaryComponent implements OnInit {
	constructor(private _store: Store<any>, private cdr: ChangeDetectorRef) {}
	applicant$: Observable<any>;
	jobObject$: BehaviorSubject<any> = new BehaviorSubject<any>({});
	currentTab$: Observable<any>;
	tabs: TabsData[] = [];
	jobInformation: any;

	@Input() assessorName$: Observable<any>;
	@Input() teamLead$: Observable<any>;
	@Input() extras$: Observable<any> = of({});

	ngOnInit(): void {
		forkJoin([
			this._store.select(getAllInfo).pipe(
				skipWhile(x => !x),
				take(1)
			),
			this._store.select(getSelectedItemOne).pipe(
				skipWhile(x => !x),
				take(1)
			),
			this._store.select(getSelectedItemTwo).pipe(
				skipWhile(x => !x),
				take(1)
			),
			this.assessorName$.pipe(
				skipWhile(x => !x),
				take(1)
			),
			this.teamLead$.pipe(
				skipWhile(x => !x),
				take(1)
			),
			this.extras$?.pipe(
				skipWhile(x => !x),
				take(1)
			)
		])
			.pipe(take(1))
			.subscribe(([allInfo, claim, selectedJob, assessor, team, extras]: any) => {
				this.currentTab$ = of(this.getViewJobID(selectedJob));
				const fixedJobs = [];
				claim?.jobs.forEach((job: any) => fixedJobs.push(this.getRequiredJobInfo({ ...job, ...extras }, allInfo, assessor, team)));
				this.jobObject$.next(fixedJobs.find(j => j.id === selectedJob.id));
				this.populateTabs(fixedJobs);
				this.jobInformation = fixedJobs;
				this.cdr.detectChanges();
			});

		this._store
			.select(getCurrentUser)
			.pipe(
				skipWhile((user: any) => !user),
				take(1)
			)
			.pipe(take(1))
			.subscribe((user: any) => {
				if (user?.client === 'pinggo') {
					this.applicant$ = this._store.select(getFullItemOne).pipe(
						skipWhile((fIO: any) => !fIO),
						take(1),
						map((result: any) => {
							const onsiteperson: string = result?.loan_information?.onsiteperson;
							const contactnumber: string = result?.applicant?.contact_number;
							const cellnumber: string = result?.loan_information?.onsitecontact;
							return [{ 'On-site person': `${onsiteperson}`, 'Contact number': `${contactnumber}`, 'Cell number': `${cellnumber}` }];
						})
					);
				} else {
					this.applicant$ = this._store.select(getFullItemOne).pipe(
						skipWhile(x => !x),
						map(fc => fc.loan_information),
						map(li => [{ onsite_person: li.onsiteperson, contact_number: li.contactnumber, cell_number: li.cellnumber }]),
						take(1)
					);
				}
			});
	}

	getTime(date: string) {
		const timeString = moment(date).format(moment.HTML5_FMT.TIME);
		return timeString !== 'Invalid date' ? timeString : '';
	}

	getDate(date: string) {
		const dateString = moment(date).format(moment.HTML5_FMT.DATE);
		return dateString !== 'Invalid date' ? dateString : '';
	}

	getRequiredJobInfo(jobInfo, allInfo, assessor, team): any {
		const { token, area, claim, ping_count, job_creator, ...job } = jobInfo;
		const { states, skills, sps, supplier_type, appointment_types } = allInfo;

		const isNumber = value => typeof value === 'number';

		const clonedJob = JSON.parse(JSON.stringify(job));

		Object.keys(job).forEach(key => {
			if (key === 'state') {
				clonedJob.state = isNumber(job.state) ? findName(job.state, states)['description'] : job?.state;
			} else if (key === 'skill') {
				clonedJob.skill = isNumber(job.skill) ? findName(job.skill, skills)['name'] : job?.skill;
			} else if (key === 'sp') {
				clonedJob.sp = isNumber(job.sp) ? (findName(job.sp, sps)['name'] ? findName(job.sp, sps)['name'] : 'sp not found') : job?.sp;
			} else if (key === 'supplier_type') {
				clonedJob.supplier_type = isNumber(job.supplier_type) ? findName(job.supplier_type, supplier_type)['name'] : job?.supplier_types;
			}
		});

		if (job.team_leader !== null) {
			clonedJob.team_leader = team;
		}

		if (job.assessor !== null) {
			clonedJob.assessor = assessor;
		}

		if (isNumber(job.appointment.appointment_type)) {
			clonedJob.appointment.appointment_type = findName(job.appointment.appointment_type, appointment_types).name;
		} else if (job.appointment.appointment_type instanceof Object) {
			clonedJob.appointment.appointment_type = job?.appointment.appointment_type.name;
		} else {
			clonedJob.appointment.appointment_type = null;
		}

		const clonedJobAppointment = clonedJob.appointment ? clonedJob.appointment : {};
		const { ['job']: removedJob, ['state']: removedState, ...appointment } = clonedJobAppointment;
		return { ...clonedJob, appointment };
	}

	handleTab(tabInfo) {
		this.currentTab$ = of(tabInfo.target);
		this.jobObject$.next(this.jobInformation.find(x => `job-${x.id}` === tabInfo.target));
	}

	populateTabs(jobs) {
		for (const job of jobs) {
			this.tabs.push({
				display: job?.skill,
				targetId: `job-${job.id}`,
				show: true
			});
		}
	}

	getViewJobID(job) {
		return `job-${job.id}`;
	}
}
