import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { take, skipWhile } from 'rxjs/operators';
import { BigFormService, ModalService, NavService, ManifestController } from '@flexus/core';
import { SetNextNode } from '@flexus/core';
import { TabsData } from '@flexus/ui-elements';
import { cleanUpSub } from '@flexus/utilities';
import { DocumentQuery } from '../query-history';

@Component({
	selector: 'flx-internal-assessor-job-overview',
	templateUrl: './internal-assessor-job-overview.component.html',
	styleUrls: ['./internal-assessor-job-overview.component.scss']
})
export class FLXInternalAssessorJobOverviewComponent implements OnInit, OnDestroy {
	@Input() continueFunc: Function;
	@Input() openActionPanel: Function;
	@Input() usedInContextMenu: boolean;
	@Input() iaReason$: Observable<string>;
	@Input() queryHistory$: Observable<DocumentQuery[]>;
	@Input() location = 'action-panel';

	@Input() claimSummary$: Observable<any>;
	claimDetails$: Observable<any>;
	claimSumSub: Subscription;

	showQuoteInput: boolean;
	tabs: TabsData[] = [
		{ display: 'Reason', show: true, targetId: 'reason' },
		{ display: 'Query History', show: true, targetId: 'queryHistory' }
	];
	currentTab$ = of('reason');

	constructor(
		public bf: BigFormService,
		private modalsService: ModalService,
		public _store: Store<any>,
		public navService: NavService,
		private controller: ManifestController<any>
	) {}

	ngOnInit(): void {
		this.bf.bigForm.valueChanges
			.pipe(
				skipWhile(x => !x['quote_amount']),
				take(1)
			)
			.subscribe(values => {
				if (!values['quote_amount']) {
					this.modalsService.openModalDirectly(inst => {
						inst.closeButton = true;
						inst.setMessage(['There is no quote amount. Please enter a quote amount']);
						inst.type = 'warning';
						inst.navButtons = [{ text: 'OK', linkType: 'close', color: 'alert' }];
					});
					this.showQuoteInput = true;
				}
			});

		this.claimSumSub =
			this.claimSummary$ &&
			this.claimSummary$.pipe(skipWhile(res => !res)).subscribe((res: any) => {
				if (!!res && !!res?.claim_details && !!this.bf.bigForm.get('quote_amount')) {
					const arrItemQuote = {
						...res?.claim_details[0],
						quote_amount: `R ${Number(this.bf.bigForm.get('quote_amount')?.value).toFixed(2)}`
					};
					const newClaimDetails = [arrItemQuote, ...res?.claim_details.filter((_cd, index) => index !== 0)];
					this.claimDetails$ = of(newClaimDetails);
				} else {
					this.claimDetails$ = of(res?.claim_details);
				}
			});
	}

	// Diversions
	goToQueryQuotation(): void {
		this.controller.dispatch(new SetNextNode('QueryInvoice'));
		this.openActionPanel(this);
	}

	goToRecommendCIL(): void {
		this.controller.dispatch(new SetNextNode('RecommendCIL'));
	}

	goToRecommendRejection(): void {
		this.controller.dispatch(new SetNextNode('RecommendRejection'));
	}

	goToRecommendLossAdjuster(): void {
		this.controller.dispatch(new SetNextNode('RecommendLossAdjuster'));
	}

	submit() {
		this.showQuoteInput = false;
		this.bf.bigForm.controls['quote_amount']?.setValue(this.bf.bigForm?.value?.quote_amount);
	}

	continue() {
		this.modalsService.openModalDirectly(inst => {
			inst.closeButton = true;
			inst.setMessage(['Are you sure you want to approve this job?']);
			inst.type = 'warning';
			inst.navButtons = [
				{ text: 'NO', linkType: 'close', color: 'alert' },
				{
					text: 'YES',
					linkType: 'close',
					color: 'alert',
					clickHandler: () => {
						this.continueFunc(this);
					}
				}
			];
		});
	}

	handleTab(tabInfo) {
		this.currentTab$ = of(tabInfo.target);
	}

	ngOnDestroy(): void {
		cleanUpSub(this.claimSumSub);
	}
}
