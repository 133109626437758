<table class="flx-calendar-table">
	<thead class="flx-calendar-table-header">
		<tr>
			<th [ngClass]="(day.long === 'Sunday' || day.long === 'Saturday') ? 'alert-day' : ''" *ngFor="let day of _weekdays" [attr.aria-label]="day.long">{{day.narrow}}</th>
		</tr>
		<tr>
			<th class="flx-calendar-table-header-divider" colspan="7" aria-hidden="true"></th>
		</tr>
	</thead>
	<tbody
		flx-calendar-body
		[label]="_monthLabel"
		[rows]="_weeks"
		[todayValue]="_todayDate"
		[selectedValue]="_selectedDate"
		[labelMinRequiredCells]="3"
		[activeCell]="_dateAdapter.getDate(activeDate) - 1"
		(selectedValueChange)="_dateSelected($event)"
	></tbody>
</table>
