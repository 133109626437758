<ng-template cdk-portal #overlayTemplate="cdkPortal">
	<div class="all-displayed-reminders">
		<div class="display-rem-holder" *ngFor="let reminder of reminderInstances">
			<div class="display-rem-container">
				<div class="reminder-time">
					<div class="rem-icon-display"><flx-icon color="button" [type]="getIconType(reminder.type)" hover="none"></flx-icon></div>
					<div class="rem-time-display">{{ reminder?.time }}</div>
				</div>
				<div class="reminder-details">
					<div class="reminder-heading">{{ reminder?.title }}</div>
					<div class="reminder-message">{{ reminder?.message }}</div>
					<div *ngIf="reminder?.claimNo" (click)="openToClaim(reminder?.claimNo)" class="reminder-claim-no">#{{ reminder?.claimNo }}</div>
				</div>
				<div class="reminder-actions">
					<div class="reminder-snooze">
						<flx-context-menu icon="alarm-bell" iconSize="small" [state]="snoozeState">
							<ul>
								<!-- <li (click)="takeAction('snooze', reminder, 1)">1 min / for dev</li> -->
								<li (click)="takeAction('snooze', reminder, 5)">5 min</li>
								<li (click)="takeAction('snooze', reminder, 10)">10 min</li>
								<li (click)="takeAction('snooze', reminder, 15)">15 min</li>
								<li (click)="takeAction('snooze', reminder, 30)">30 min</li>
								<li (click)="takeAction('snooze', reminder, 60)">60 min</li>
							</ul>
						</flx-context-menu>
						<!-- <flx-icon
              type="alarm-bell"
              size="small"
              color="button"
              instruction="Snooze for 10 min"
              (click)="takeAction('snooze', reminder, 10)"
            ></flx-icon> -->
					</div>
					<div class="reminder-delete">
						<flx-icon color="button" [type]="'scratch-pad-trash'" size="small" instruction="Delete" (click)="takeAction('delete', reminder)"></flx-icon>
					</div>
				</div>
			</div>

			<div class="claim-view">
				<flx-label>View Claim</flx-label>

				<flx-icon class="right-arrow-btn" instruction="View Claim" size="small" [type]="'right-arrow'" fill="var(--primary)" (click)="openToClaim(reminder?.claimNo)"></flx-icon>
			</div>
		</div>
	</div>
</ng-template>
