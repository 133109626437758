import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { ManifestController } from '@flexus/core';
import { Observable } from 'rxjs';
import { skipWhile, take, map, pluck } from 'rxjs/operators';

@Component({
	selector: 'flexus-mc-agent-allocation',
	templateUrl: './mc-agent-allocation.component.html',
	styleUrls: ['./mc-agent-allocation.component.scss']
})
export class McAgentAllocationComponent {
	@Input() agentList$: Observable<any>;
	agentList;
	roleOptions = [
		{ value: 'Validation Payment Requests', display: 'Validation Payment Requests' },
		{ value: 'Validation queries', display: 'Validation queries' }
	];

	constructor(private _http: HttpClient, @Inject('environment') private environment: any, private _controller: ManifestController<any>) {}
	ngOnInit(): void {
		this.agentList$.pipe().subscribe((agent_data: any) => agent_data);
	}
	LoadBalanceAgentAssignment(agent_id: number) {
		console.log('AGENT ID', agent_id);
		return this._http
			.post(`${this.environment.api_url}v1/mc_actions/allocate_agent_jobs?staff_id/`, { staff_id: agent_id })
			.pipe(
				skipWhile(x => !x),
				take(1),
				map((response: any) => response)
			)
			.pipe(
				map(response => {
					if (response) {
						console.log('MOTHER LOAD', response);
					}
				})
			)
			.subscribe();

		// mc_actions/allocate_agent_jobs?staff_id=3
	}
}
