import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FLXFileUploadWithPreviewComponent } from './file-upload-with-preview.component';
import {
	FLXAlertsModule,
	FLXFormsModule,
	FLXHeadingsModule,
	FLXTabsModule,
	FLXButtonsModule,
	FileHandlerModule,
	FLXFileViewerModule,
	FLXGlowLineModule
} from '@flexus/ui-elements';
import { FLXFlatDynamicFormModule } from '../forms';
import { FLXMultiFileUploadViewerModule } from '../multi-file-upload-viewer';
import { MultiFileLoadingHandlerService } from '../multi-file-loading-handler.service';
import { PipesModule } from '@flexus/pipes';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FLXAlertsModule,
		FLXFormsModule,
		FLXHeadingsModule,
		FLXGlowLineModule,
		FLXTabsModule,
		FileHandlerModule,
		FLXFlatDynamicFormModule,
		FLXButtonsModule,
		FLXFileViewerModule,
		FLXMultiFileUploadViewerModule,
		PipesModule
	],
	exports: [FLXFileUploadWithPreviewComponent],
	declarations: [FLXFileUploadWithPreviewComponent],
	// entryComponents: [FLXFileUploadWithPreviewComponent],
	providers: [MultiFileLoadingHandlerService, PipesModule]
})
export class FLXFileUploadWithPreviewModule {}
