import { Flow_0_0_2, getFullItemTwo, MakeServerCall, SetNextNode, getCurrentUser, getSubmissionData } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import { combineLatest, EMPTY, forkJoin, of } from 'rxjs';
import { skipWhile, take, map, switchMap, filter } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PHOTO_NODE } from '../../../../sp_globals/configs/reusable';
import { JOB_INFO_NODE } from '../../../../sp_globals/configs/reusable';
import { OWN_BILLING_INVOICE, AUTO_SELECT_TEMPLATE_DECISION, billingServerCalls, SELECT_CALLOUT_TEMPLATE } from '../reusable/OWN_BILLING';
import { SAVE_TO_DESKTOP } from '../reusable';
import { convertDateTimeToTimeStamp, getExtension } from '@flexus/utilities';
import moment from 'moment';
import gql from 'graphql-tag';

export const SIL_SP_26: Flow_0_0_2 = {
	id: '26',
	name: 'tl-work-completed',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Upload Invoice : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Upload Invoice';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: { type: 'node_nav' },
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		...billingServerCalls
	},
	instructions: { editRoles: { 0: 'Invoice the job' }, viewRoles: { 0: 'Wait for SP to invoice' } }, // TODO: reset this to previous value
	startNode: 'Decision',
	nodes: {
		Decision: {
			nodeType: 'decision',
			initFormFields: bf => bf.patchValues({ new_state: 27 }),
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				checkIfConsignment: (navs, store, modal, control) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(job => {
								if (job.skill === 1) {
									// always upload COC document if skill is
									store.dispatch(
										new MakeServerCall({
											errorMessage: 'Could not go to next node!',
											directCall: (http, s, sq, bf, controller) => {
												controller.dispatch(new SetNextNode('COCNotification'));
												return of();
											}
										})
									);
								} else {
									store.dispatch(
										new MakeServerCall({
											errorMessage: 'Could not go to next node!',
											directCall: (http, s, sq, bf, controller) => {
												setTimeout(() => {
													controller.dispatch(new SetNextNode('AUTO_SELECT_TEMPLATE_DECISION'));
												}, 1000);
												return of();
											}
										})
									);
								}
								return 'Decision';
							})
						)
						.pipe(take(1))
						.subscribe();
				}
			},
			component: '',
			navs: []
		},
		COCNotification: {
			...JOB_INFO_NODE,
			hideTabItem: false,
			navs: [
				{
					text: 'Return job to team leader',
					linkType: 'portal',
					location: 'center',
					nextNode: 'ReturnToTLReasonEntry'
				},
				{ text: 'Continue', color: 'primary', nextNode: 'UploadGeyserComplianceCertificate' }
			]
		},
		JobPhotos: {
			...PHOTO_NODE,
			navs: []
		},
		ReturnToTLReasonEntry: {
			showTabs: false,
			hideTabItem: true,
			initFormFields: (bf, item, instance, storeQuery, store) => {
				combineLatest([store.select(getCurrentUser).pipe(filter(x => !!x, take(1))), store.select(getFullItemTwo).pipe(filter(x => !!x, take(1)))])
					.pipe(take(1))
					.subscribe(([author, job]) => {
						bf.patchValues({ author: author.user.id });
						bf.patchValues({ author_name: author.user.full_name });
						// bf.patchValues({ current_state: 26 });
					});
				bf.addControl('return_tl_reason', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Return to Team Leader',
							formControls: {
								0: {
									label: 'Please enter the reason for returning this to the team leader',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'return_tl_reason'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SetAppointment',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submit]) => {
										const job_id = job.id;
										const current_state = job.state;
										const new_state = submit.new_state;
										const appointment = submit.appointment;
										const return_tl_reason = submit.return_tl_reason;
										const data = {
											job_id: job_id,
											return_tl_reason: return_tl_reason
										};
										return data;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq, store) => {
				const formGroup = {
					appointmentDatePicker: new UntypedFormControl(null),
					appointmentTime: new UntypedFormControl(null),
					appointmentTimePicker: new UntypedFormControl(null),
					appointmentDateType: new UntypedFormControl(null)
				};
				if (environment.client === 'mul_sp') {
					bf.addControl('appointmentData', new UntypedFormGroup({ ...formGroup, skill: new UntypedFormControl('Dish Installation') }));
				} else {
					sq.queryObject(
						gql`
							{
								fullItemTwo {
									office_use {
										skill
										skillcatagory
									}
								}
							}
						`,
						store.select(getFullItemTwo).pipe(
							skipWhile(fi => !fi),
							take(1),
							map(res => ({ fullItemTwo: res }))
						)
					).subscribe(({ skill, skillcatagory }) => {
						bf.addControl('appointmentData', new UntypedFormGroup({ ...formGroup, skill: new UntypedFormControl(`${skillcatagory} - ${skill}`) }));
					});
				}

				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				bf.patchValues({ new_state: 205 });
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									let firstname, surname, contact_number, cell_number;
									if (!qdat) {
										firstname = 'No first name available';
										surname = 'No surname available';
										contact_number = 'No contact number';
									} else {
										if (qdat.first_name === '') {
											firstname = 'No first name available';
										} else {
											firstname = qdat.first_name ?? 'No first name available';
										}
										if (qdat.first_name === '') {
											surname = 'No surname available';
										} else {
											surname = qdat?.surname ?? 'No surname available';
										}
										if (qdat.contactnumber === '') {
											contact_number = 'No contact number';
										} else {
											contact_number = qdat?.contactnumber ?? 'No contact number';
										}
										if (qdat.cellnumber === '') {
											cell_number = 'No cell number';
										} else {
											cell_number = qdat?.cellnumber ?? 'No cell number supplied';
										}
									}
									return [
										{
											'Client Name': `${firstname} ${surname}`
										},
										{ 'Contact Number': `${contact_number}` },
										{ 'Mobile Number': `${cell_number}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Set Appointment',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submit]) => {
										const job_id = job.id;
										const current_state = job.state;
										const new_state = submit.new_state;
										const appointment = submit.appointment;
										const data = {
											job_id: job_id,
											current_state: current_state,
											new_state: new_state,
											appointment: appointment
										};
										return data;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			],
			component: 'FLXJobAppointmentComponent'
		},
		UploadGeyserComplianceCertificate: {
			hideTabItem: true,
			initFormFields: (bf, iten, instance, sq, store) => {
				bf.bigForm.addControl('purpose', new UntypedFormControl('COC_Certificate', [Validators.required]));
				bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
			},
			component: 'FLXFileUploadWithPreviewComponent',
			checkValidityForFields: ['purpose', 'fileQueue'],
			inputs: {
				purposes: [{ display: 'Certificate of Compliance', value: 'COC_Certificate' }],
				heading: 'Select Compliance Certificate to Attach',
				multipleUploads: false,
				allowedFileTypes: ['pdf'],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			navs: [
				{
					text: 'Upload Certificate',
					serverFirst: true,
					nextNode: 'FileSubmissionSuccess',
					optIntoValidation: false,
					color: 'primary',
					linkType: 'portal',
					serverCalls: {
						uploadCertificate: {
							errorMessage: 'Certificate could not be uploaded',
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0] ? fileQueue[0]?.file.base64 : '',
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
								return of({});
							},

							followUpSuccessCalls: {
								response: {
									errorMessage: 'Job could not be updated',
									serviceVariable: 'spService',
									directCall: (http, store, sq, bf) => {
										return store
											.select(getFullItemTwo)
											.pipe(
												skipWhile(x => !x),
												take(1)
											)
											.pipe(
												map(job => {
													const spJob = job as any;

													const data = {
														job_information: spJob.job_information,
														job_id: spJob.id
													};
													return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
												})
											);
									}
								}
							}
						}
					}
				}
			]
		},
		FileSubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'File successfully uploaded' },
			navs: [{ text: 'Continue', nextNode: 'AUTO_SELECT_TEMPLATE_DECISION' }]
		},
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		OWN_BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'invoice',
				numberLabel: 'Invoice',
				currentState: 26,
				newState: 27,
				showLineItemGenerator: true,
				canEditLineItems: true,
				boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
				boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Standard Bank Insurance Limited',
				clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL'
			},
			navs: [
				{
					text: 'Generate Invoice',
					nextNode: 'SAVE_TO_DESKTOP',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
		SAVE_TO_DESKTOP,
		DynamicAnchor: {
			component: 'DynamicAnchorComponent'
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		// purpose: 'purpose',
		current_state: [() => 26, 'current_state'],
		new_state: 'new_state',
		return_tl_reason: [
			(rtlr, storObj, bf) => {
				const return_tl_reasons = [];
				const date = new Date();
				const newInfo = {
					message: rtlr,
					author_id: bf.author,
					author_name: bf.author_name,
					currentState: bf.current_state,
					nextState: bf.new_state,
					timeStamp: convertDateTimeToTimeStamp(date.toDateString())
				};

				if (
					storObj['selectedContext']?.fullItemTwo &&
					storObj['selectedContext']?.fullItemTwo.job_information &&
					storObj['selectedContext']?.fullItemTwo?.job_information?.interstate_comments
				) {
					const temp = storObj['selectedContext']?.fullItemTwo?.job_information?.interstate_comments;

					Array.isArray(temp) ? return_tl_reasons.push(...temp, newInfo) : return_tl_reasons.push(temp, newInfo);
				} else {
					return_tl_reasons.push(newInfo);
				}

				return return_tl_reasons;
			},
			'job_information.interstate_comments'
		],
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					const appointment_formatted = {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
					return appointment_formatted;
				}
			},
			'appointment'
		],
		total: [
			(a, b, bf) => {
				return bf.total + bf.vatRate;
			},
			'job_information.quote_amount'
		],
		invoiceNumber: 'job_information.quote_number'
	}
};
