import { Flow_0_0_2, getData, GetFullItemOne, getFullItemOne, getFullItemTwo, getSelectedItemTwo, MakeServerCall, SetPreviousNode } from '@flexus/core';
import { delay, map, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { SINGLE_FILE_UPLOAD_SINGLE_PURPOSE } from './single_file_upload_single_purpose';
import { FormControl } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin, of } from 'rxjs';
import { getExtension } from '@flexus/utilities';

export const BET_221: Flow_0_0_2 = {
	id: '221',
	name: 'upload_settlement_letter',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map(itemOne => {
					if (itemOne) {
						return `Upload Settlement Letter : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Upload Settlement Letter';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], { filesFunctionName: 'getAllClaimFiles' }),
	instructions: {
		editRoles: {
			0: 'Upload Settlement Letter'
		},
		viewRoles: {
			0: 'Upload Settlement Letter'
		}
	},
	startNode: 'UploadDocument',
	nodes: {
		UploadDocument: {
			...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes?.UploadDocument,
			showTabs: false,
			initFormFields: bf => {
				bf.patchValues({ new_state: 58 });
			},
			checkValidityForFields: [],
			inputs: {
				...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes?.UploadDocument.inputs,
				heading: 'Upload the signed Settlement Letter',
				subheading: 'Upload the signed Settlement Letter',
				formControls: {}
			},
			navs: [
				{
					text: 'Upload Signed Document',
					optIntoValidation: false,
					color: 'primary',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						uploadDoc: {
							errorMessage: 'File could not be uploaded',
							timeoutMilliseconds: 2000,
							directCall: (http, store, sq, bf, controller, modal) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0]?.file.base64,
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: 'Agreement of Loss',
									filename: `agreement_of_loss_pdf${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};

								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body).pipe(
									tap(response => {
										modal.openModalDirectly(inst => {
											inst.type = 'success';
											inst.heading = 'Successfully uploaded document.';
											inst.navButtons = [
												{
													text: 'Continue',
													color: 'primary',
													linkType: 'close'
												}
											];
										});
									})
								);
							},
							followUpFailCalls: {
								displaySucces: {
									errorMessage: 'Could not diplay success',
									directCall: (http, store, sq, bf, con, modal) => {
										modal.openModalDirectly(inst => {
											inst.type = 'warning';
											inst.heading = 'Could not upload file.';
											inst.navButtons = [
												{
													text: 'Continue',
													color: 'secondary',
													linkType: 'close'
												}
											];
										});
										return of(false);
									}
								}
							}
						},
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		//submission Nodes ************
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},

	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
