import { NgModule } from '@angular/core';
import { FLXAppBarComponent } from './app-bar.component';
import { HeaderActionsModule } from '../header-actions';
import { UIElementsModule } from '@flexus/ui-elements';
import { CommonModule } from '@angular/common';
import { AppMenuModule } from '../app-menu';
import { UICompositesModule } from '@flexus/ui-composites';

@NgModule({
	imports: [CommonModule, AppMenuModule, HeaderActionsModule, UIElementsModule, UICompositesModule],
	declarations: [FLXAppBarComponent],
	exports: [FLXAppBarComponent]
})
export class AppBarModule {}
