import { Flow_0_0_2, getFullItemOne, getCurrentUser } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { map, skipWhile, pluck, take } from 'rxjs/operators';

const getClaimTypeName = (claim: any) => {
	return claim?.sub_section === 3 ? 'Commercial' : 'Stansure';
};

/**
 * For handling both Stansure and Commercial claims
 */
export const SIL_79: Flow_0_0_2 = {
	id: '79',
	name: 'enter_stansure_claim_number',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne?.loan_information?.mavenclaimnumber) {
						return `Enter a ${getClaimTypeName(itemOne)} claim number for this claim on policy : ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return `Enter ${getClaimTypeName(itemOne)} Claim Number`;
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Enter claim number'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'StansureClaimNumber',
	nodes: {
		StansureClaimNumber: {
			name: 'Enter Stansure Claim Number',
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				formControls: {
					0: { label: 'Enter claim number', inputType: 'input', formControlName: 'stansure_claim_number' }
				},
				containerWidth: '400px',
				centered: true
			},
			initFormFields: (bf, item, instance, sq, store) => {
				bf.bigForm.addControl('stansure_claim_number', new UntypedFormControl('', Validators.required));
				bf.bigForm.addControl('new_state', new UntypedFormControl('1'));
			},
			navs: [
				{
					text: 'submit',
					optIntoValidation: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Claim not updated with Stansure claim number',
							serviceVariable: 'silService',
							functionName: 'updateClaim'
						}
					},
					color: 'primary'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		stansure_claim_number: ['mid', 'loan_information.mavenclaimnumber']
	}
};
