import * as contextActions from './billing.actions';


import { deleteObjectProperty } from '@flexus/utilities';
import { IExcessChangeData, iBillingTemplate, iServiceProvider, iLineItem } from '@flexus/models';
// import { IExcessChangeData } from '../typings/excess-change-data.interface';

export interface iSkillSubCategory {
	id: number;
	name: string;
	assign_date: string;
	modified_date: string;
	skill: {
		id: number;
		name: string;
		specialist: string;
		mid: number;
		active: boolean;
	};
	item: iLineItem[];
	skillGroup: any[];
}

export interface iBilling {
	selectedTemplate: iBillingTemplate;
	boq: iBOQ;
	sp: iServiceProvider;
	lineItems: iLineItem[];
	customTypesList: { id: number; name: string }[];
	customYearsList: { id: number; year: number }[];
	skillCategories: { id: number; name: string }[];
	skillSubCategories: iSkillSubCategory[];
	unitsOfMeasurement: string[];
	invoice: any;
	banks: any;
	excess: any;
	travelDetails: any;
	excessChangeDetails: IExcessChangeData;
}

export interface iBOQ {
	id: number;
	is_interim: boolean;
	category: string;
	token: string;
	created: string;
	modified: string;
	state: number;
	job: number;
	line_items: iLineItem[];
}

export interface iState {
	availableTemplates: iBillingTemplate[];
	selectedContext: iBilling;
	errors: any;
	errorMessages: any;
}

export const billingInitialState = {
	availableTemplates: null,
	selectedContext: null,
	errors: {},
	errorMessages: {}
};

export function billingReducer(state: iState = billingInitialState, action: contextActions.billingActions | any) {
	switch (action.type) {
		case contextActions.FETCH_TEMPLATES_SUCCESS: {
			const { availableTemplates } = action.payload;
			return {
				...state,
				availableTemplates: availableTemplates
			};
		}
		case contextActions.MAKE_BILLING_SERVER_CALL_SUCCESS: {
			const { dataKey, result } = action.payload;

			// Remove related error, if it exists
			const errors = { ...deleteObjectProperty(dataKey, state.errors) };
			const errorMessages = { ...deleteObjectProperty(dataKey, state.errorMessages) };

			return {
				...state,
				selectedContext: {
					...state.selectedContext,
					[dataKey]: result
				},
				errors,
				errorMessages
			};
		}
		case 'ROUTER_NAVIGATED': {
			const {
				event: { urlAfterRedirects }
			} = action.payload;
			if (urlAfterRedirects === '/workflow' || urlAfterRedirects === '/workflow/chart') {
				return billingInitialState;
			}
			return state;
		}
		default: {
			return state;
		}
	}
}
