import * as fromActions from './mc-autopay-invoicing.actions';

export interface MCAutopayInvoicingState {
	Invoices: any[];
	invoicesLoaded: boolean;
	invoice_dates_range: any;
	NumberedInvoiceFromSearch: any;
}
const initialState: MCAutopayInvoicingState = {
	Invoices: [],
	invoicesLoaded: false,
	invoice_dates_range: [],
	NumberedInvoiceFromSearch: null
};
export function mcAutopayInvoicingReducer(state: MCAutopayInvoicingState = initialState, action: fromActions.MCAutopayInvoicingActions) {
	switch (action.type) {
		case fromActions.GET_INVOICES_BY_DATE: {
			const data = action.payload;
			return {
				...state,
				// Invoices: data.payload,
				invoice_dates_range: data.payload
			};
		}
		case fromActions.GET_INVOICES_BY_DATE_SUCCESS: {
			const data = action?.payload;
			return {
				...state,
				Invoices: data.payload,
				NumberedInvoiceFromSearch: null
			};
		}
		case fromActions.GET_INVOICES_LOADED_STATE: {
			const data = action?.payload;
			return {
				...state,
				invoicesLoaded: data
			};
		}
		case fromActions.GET_INVOICE_BY_NUMBER: {
			const data = action.payload;
			return {
				...state,
				// Invoices: data.payload,
				invoice_number: data.payload
			};
		}
		case fromActions.GET_INVOICE_BY_NUMBER_SUCCESS: {
			const data = action?.payload;
			return {
				...state,
				Invoices: data.payload
			};
		}
		case fromActions.DOWNLOAD_INVOICE_SUCCESS: {
			const data = action?.payload;
			console.log('DOWNLOAD SUCCESS DISPATCHING', data);
			return {
				...state
			};
		}
		case fromActions.POST_DOWNLOAD_INVOICE_SUCCESS: {
			const data = action;
			console.log('POST DOWNLOAD SUCCESS DISPATCHING', action);
			return {
				...state
			};
		}
		case fromActions.SET_NUMBERED_INVOICE: {
			const data = action?.payload;
			return {
				...state,
				NumberedInvoiceFromSearch: action.payload
			};
		}
	}
}
