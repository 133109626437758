import { Component, OnInit, OnDestroy, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable, of, forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCurrentUser, getSelectedJob, ModalService } from '@flexus/core';
import { HttpClient } from '@angular/common/http';
import { filter, skipWhile, take, map, catchError } from 'rxjs/operators';
import moment from 'moment';
import { JobLocation, JobRequest } from '../models';

interface JobLocationConfig extends google.maps.MapOptions {
	scriptId?: string;
	url?: string;
}

@Component({
	selector: 'flx-sp-available-job-detail',
	templateUrl: './sp-available-job-detail.component.html',
	styleUrls: ['./sp-available-job-detail.component.scss']
})
export class SpAvailableJobDetailComponent implements OnInit, OnDestroy {
	public showSiteDetails;
	private _job: JobRequest;
	@Input()
	set job(val) {
		val = this.selectedJob;
	}
	get job() {
		return this.selectedJob;
	}

	@Input() expandedPingDetails$: Observable<any>;

	@Output() bubbleJobId = new EventEmitter<{}>();

	public selectedJob: any;
	private _config: JobLocationConfig;
	private _defaultConfig: JobLocationConfig;
	public jobArea: google.maps.Map;
	jobDetailData$: Observable<any>;

	public _posiDataSub: Subscription;
	public selectedJobId;
	wmSub: Subscription;
	sub: Subscription;
	client;

	public jobloco;

	onsite_note;
	device_type;
	tv_type;
	no_of_tvs;
	dwelling_type;
	existing_decoder;
	wall_type;
	suburb;
	jobtype;
	appointment;
	appointment_name;
	customer_request;
	claimtype;
	apiLoaded: Observable<boolean>;
	options: google.maps.MapOptions = {
		center: { lat: -26.2041, lng: 28.0473 },
		zoom: 9,
		backgroundColor: 'none'
	};

	constructor(
		@Inject('environment') private _environment: any,
		private store: Store<any>,
		private modal: ModalService,
		private _http: HttpClient,
		private _cdr: ChangeDetectorRef
	) {
		// this.googleMapsSrc = `https://maps.googleapis.com/maps/api/js?key=${this._environment.google_maps_api_key}`;
		this.initMap();
		this.apiLoaded = _http.jsonp(`https://maps.googleapis.com/maps/api/js?key=${this._environment.google_maps_api_key}&region=ZA&language=en`, 'callback').pipe(
			map(() => true),
			catchError(() => of(false))
		);
	}

	get config() {
		return this._config || this._defaultConfig;
	}

	ngOnInit() {
		this.sub = this.expandedPingDetails$
			.pipe(
				skipWhile((exp: any) => !exp),
				take(1),
				map((rspns: any) => {
					console.log('device and environment', rspns);
					const current_environment = rspns?.payload?.current_environment;
					const device_details = rspns?.payload?.device_details;
					if (device_details instanceof Array) {
						const decodersArray: string[] = [];
						for (let i = 0; i < device_details.length; i++) {
							const decodername: string = device_details[i].replacement_device?.type;
							decodersArray.push(decodername);
						}
						if (decodersArray.length === 2) {
							this.device_type = decodersArray.join(' and ');
						} else if (decodersArray.length > 2) {
							const last_in_list = decodersArray.pop();
							const others_in_list: string[] = [];
							for (let i = 0; i < device_details.length - 1; i++) {
								const decodername: string = device_details[i].replacement_device?.type;
								others_in_list.push(decodername);
							}
							const prelist: string = others_in_list.join(', ');
							this.device_type = prelist.concat(' and ', last_in_list);
						}
					} else {
						if (device_details?.replacement_device) {
							this.device_type = device_details?.replacement_device?.type ?? 'Data not available';
						} else if (device_details?.customer_device) {
							this.device_type = device_details?.customer_device?.cust_device_type ?? 'Data not available';
						}
					}
					this.onsite_note = current_environment?.onsitenotes ? current_environment?.onsitenotes : 'No on-site note data available';
					this.tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs : 'Data not available';
					this.dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type : 'Data not available';
					this.existing_decoder = current_environment?.existing_decoder === undefined ? 'No' : 'Yes';
					this.no_of_tvs = current_environment?.no_of_tvs ? current_environment.no_of_tvs : 'Data not avaiable';
					this.wall_type = current_environment ? current_environment.wall_type : 'Data not available';
					this._cdr.detectChanges();
				})
			)
			.subscribe();

		forkJoin([
			this.store.select(getSelectedJob).pipe(
				skipWhile(x => !x),
				take(1),
				map((res: any) => res)
			),
			this.store.select(getCurrentUser).pipe(
				take(1),
				map((res: any) => res)
			)
		])
			.pipe(
				map(([job, user]) => {
					this.client = user?.client;
					this.suburb = job?.suburb;
					if (this.client !== 'pinggo') {
						if (job?.claim_type_id === '37') {
							this.jobtype = `Own Stock ${job?.skill}`;
						} else {
							this.jobtype = job?.skill;
						}
					} else {
						this.jobtype = job?.voucher;
					}
					this.appointment = job?.appointment.range_start;
					this.appointment_name = job?.appointment.appointment_name;
					this.selectedJobId = job?.id;
					if (this.client === 'multichoice') {
						switch (this.jobtype) {
							case 'Own Stock DStv L2 Installation':
								this.showSiteDetails = true;
								break;
							case 'DStv L2 Installation':
								this.showSiteDetails = true;
								break;
							case 'Vouchered Installation':
								this.showSiteDetails = true;
								break;
							case 'Non Vouchered Installation':
								this.showSiteDetails = true;
								break;
							case 'Installation Repair':
								this.showSiteDetails = true;
								break;
							default:
								this.showSiteDetails = false;
								break;
						}
						if (job?.claim_type_id === '37') {
							this.claimtype = 'Installer Own Stock';
						} else {
							this.claimtype = job?.claim_type;
						}
					} else if (this.client === 'sil') {
						this.claimtype = job?.claim_type;
					} else {
						this.showSiteDetails = false;
					}
				})
			)
			.subscribe();

		const jobDetailObj = {
			'Suburb ': this.suburb,
			'Type of Job': this.jobtype,
			Appointment: `${moment(this.appointment).format(moment.HTML5_FMT.DATE)} ${this.appointment_name} ${moment(this.appointment).format(moment.HTML5_FMT.TIME)}`
		};

		if (this.claimtype) jobDetailObj['Claim type'] = this.claimtype;

		this.jobDetailData$ = of([jobDetailObj]);

		this.returnJobLocation();
	}

	passJobIdUp(jobid: number) {
		this.bubbleJobId.emit(jobid);
	}

	returnJobLocation() {
		return this.store.select(getSelectedJob).pipe(
			skipWhile(x => !x),
			take(1),
			map(res => {
				const [latitude, longitude] = res?.location.split(',');
				return new JobLocation(+latitude, +longitude);
			})
		);
	}

	initMap() {
		this._posiDataSub = this.returnJobLocation()
			.pipe(filter(x => !!x))
			.subscribe(location => {
				this.jobloco = location;
			});
	}

	ngOnDestroy() {
		this.sub && this.sub.unsubscribe();
		this._posiDataSub && this._posiDataSub.unsubscribe();
	}
}
