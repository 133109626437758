import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BigFormService } from '@flexus/core';

@Component({
	selector: 'flx-button-list-with-instructions',
	templateUrl: './button-list-with-instructions.component.html',
	styleUrls: ['./button-list-with-instructions.component.scss']
})
export class FLXButtonListWithInstructionsComponent implements OnInit, OnDestroy {
	@Input() title$: Observable<any>;
	@Input() title: string;
	@Input() instructions: string[];
	@Input() buttons: { color: string; text: string; clickHandler: (ev: Event, store: Store<any>) => void }[];

	private _subscription: Subscription;
	private _title: string;

	constructor(private _store: Store<any>, private _bfs: BigFormService) {}

	ngOnInit() {
		if (this.title$) {
			this._title = this.title;
			this._subscription = this.title$.pipe(skipWhile(x => !x)).subscribe(title => {
				this.title = this._title;
				this.title += this._title && this._title !== '' ? ' ' : '';
				this.title += title;
			});
		}
	}

	ngOnDestroy() {
		if (this._subscription) {
			this._subscription.unsubscribe();
		}
	}

	handleClickEvent(clickHandler: (ev: Event, store: Store<any>, bfs: BigFormService) => void, event: Event) {
		clickHandler(event, this._store, this._bfs);
	}
}
