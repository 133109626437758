import { DOCUMENT } from '@angular/common';

import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	InjectionToken,
	Input,
	NgZone,
	Optional,
	ViewContainerRef,
	ViewEncapsulation
} from '@angular/core';

import { Overlay, RepositionScrollStrategy, ScrollStrategy } from '@angular/cdk/overlay';
import { Dialog } from '../dialog';
import { IconTypes } from '../inline-icons/icon-types';
import { UiBaseComponent } from '../dialog/ui-base.component';

/**
 * Unique Identifier for every menu
 */
let contextMenuUid = 0;

export const FLX_CONTEXT_MENU_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('flx-context-menu-scroll-strategy');

/** @docs-private */
export function FLX_CONTEXT_MENU_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay: Overlay): () => RepositionScrollStrategy {
	return () => overlay.scrollStrategies.reposition();
}

/** @docs-private */
export const FLX_CONTEXT_MENU_SCROLL_STRATEGY_PROVIDER = {
	provide: FLX_CONTEXT_MENU_SCROLL_STRATEGY,
	deps: [Overlay],
	useFactory: FLX_CONTEXT_MENU_SCROLL_STRATEGY_PROVIDER_FACTORY
};

/** component for creating a floating context menu.
 * @class
 * @example
 * <flx-context-menu>
 *                  <ul>
 *                      <li>Action 1</li>
 *                      <li>Action 2</li>
 *                      <li>Action 3</li>
 *                      <li>Action 4</li>
 *                  </ul>
 * </flx-context-menu>;
 */
@Component({
	selector: 'flx-context-menu',
	templateUrl: './context-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./context-menu.component.scss']
})
export class FLXContextMenuComponent extends UiBaseComponent {
	id = `flx-context-menu-${contextMenuUid++}`;
	@Input() icon: IconTypes = 'more-vertical';
	@Input() use = 'default';
	@Input() iconSize = 'default';

	@Input()
	set state(val: string) {
		switch (val) {
			case 'closed':
				this.close();
				break;
			default:
				break;
		}
	}

	constructor(
		@Optional()
		@Inject(DOCUMENT)
		public override _document: any,
		@Optional()
		@Inject(FLX_CONTEXT_MENU_SCROLL_STRATEGY)
		public override _scrollStrategy,
		public override _viewContainerRef: ViewContainerRef,
		public override _dialog: Dialog,
		public override _overlay: Overlay,
		public override _ngZone: NgZone
	) {
		super(_document, _viewContainerRef, _dialog, _overlay, _ngZone, _scrollStrategy, true);
	}

	override close(): void {
		super.close();
	}
}
