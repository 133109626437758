import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BigFormService } from '@flexus/core';
import { Subscription } from 'rxjs';
import { skipWhile, take, map } from 'rxjs/operators';
import { AUTOPAY_CLAIM_STATUSES } from '@flexus/utilities';

@Component({
	selector: 'flx-mc-qr-codes-fixes',
	templateUrl: 'mc-qr-codes-fixes.component.html',
	styleUrls: ['mc-qr-codes-fixes.component.scss']
})
export class MCQRCodesFixesComponent implements OnInit, OnDestroy {
	var1 = 'help';
	var2 = 'me';
	var3 = 'quick';

	@Input() autopay_fail_reason$;
	qrCodeInputForm: FormGroup;

	failreason_subscription: Subscription;
	qr_items_toFixString: string;
	is_qrOnly: boolean;
	filteredQRItems: any[] = [];
	multi_qr_fixes = [
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_11,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_13,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_16,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_17,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_22,
		AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_23
	];
	single_qr_fix_items = [AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_12, AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_15];
	multi_qr_fixes_itemlist = ['LNB', 'Dish', 'Bracket', 'Switch'];

	constructor(public _bf: BigFormService, private _frmBuild: FormBuilder) {}

	ngOnInit() {
		this._bf.addControl('qr_code_input', new UntypedFormControl('', [Validators.required]));
		this.getQRIssuesString();
		this.buildQRIssuesArray(this.qr_items_toFixString);
		this.qrCodeInputForm = this._frmBuild.group({
			qrCodeInputItems: this._frmBuild.array(this.filteredQRItems)
		});
		console.log('FORM GROUP?', this.qrCodeInputForm);
	}

	getQRIssuesString() {
		this.failreason_subscription = this.autopay_fail_reason$
			.pipe(
				skipWhile(x => !x),
				take(1),
				map((data: any) => {
					console.log('DATA', data);
					this.qr_items_toFixString = data;
					return data;
				})
			)
			.subscribe();
	}
	buildQRIssuesArray(fixString: any): [] {
		let filter_items = [];
		if (this.single_qr_fix_items.includes(fixString)) {
			this.is_qrOnly = true;
		} else if (this.multi_qr_fixes.includes(fixString)) {
			this.is_qrOnly = false;
		}
		const input_qr_items = fixString.match(/\w+\b/g);
		const filteredQRItems = input_qr_items.filter(qritem => this.multi_qr_fixes_itemlist.includes(qritem));
		this.filteredQRItems = filteredQRItems;
		// this.qr_input_controls =
		return [];
	}

	ngOnDestroy() {
		if (this.failreason_subscription) {
			this.failreason_subscription.unsubscribe();
		}
	}
}
