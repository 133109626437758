import { Flow_0_0_2, getFullItemTwo, MakeServerCall, SetNextNode, getCurrentUser, getSubmissionData, getSelectedContextEngine } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import { combineLatest, EMPTY, forkJoin, of } from 'rxjs';
import { skipWhile, take, map, switchMap, filter, pluck } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AUTO_SELECT_TEMPLATE_DECISION, OWN_BILLING_INVOICE, billingServerCalls, SELECT_CALLOUT_TEMPLATE, CHECK_IF_ATTENDED_AFTER_HOURS } from '../reusable/OWN_BILLING';
import { PHOTO_NODE } from '../reusable/PHOTO_NODE';
import { JOB_INFO_NODE } from '../reusable/JOB_INFO_NODE';
import { SAVE_TO_DESKTOP } from '../reusable/SAVE_TO_DESKTOP';
import { convertDateTimeToTimeStamp, getExtension } from '@flexus/utilities';
import moment from 'moment';
import gql from 'graphql-tag';

SELECT_CALLOUT_TEMPLATE.navs = [
	{
		text: 'Edit Report',
		nextNode: 'EditReport',
		visible(_b, store, _ns) {
			const ELECTRIC_GEYSER = 1;
			const SOLAR_GEYSER = 20;
			const GAS_GEYSER = 29;
			const GEYSER_SUPPLY = 39;
			const GLASS = 6;
			return store.select(getFullItemTwo)?.pipe(
				pluck('skill'),
				map(skillId => ![ELECTRIC_GEYSER, SOLAR_GEYSER, GAS_GEYSER, GEYSER_SUPPLY, GLASS].includes(skillId))
			);
		}
	},
	{
		text: 'Continue',
		optIntoValidation: false,
		nextNode: 'OWN_BILLING_INVOICE'
	}
];

export const SP_GLOBAL_26: Flow_0_0_2 = {
	id: '26',
	name: 'tl-work-completed',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Upload Invoice : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Upload Invoice';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: { type: 'node_nav' },
	serverCalls: {
		IAReports: {
			serviceVariable: 'silService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		...billingServerCalls
	},
	instructions: { editRoles: { 0: 'Invoice the job' }, viewRoles: { 0: 'Wait for SP to invoice' } },
	startNode: 'JOB_INFO_NODE',
	nodes: {
		JOB_INFO_NODE: {
			...JOB_INFO_NODE,
			hideTabItem: false,
			navs: [
				{
					text: 'Return job to team leader',
					linkType: 'portal',
					location: 'center',
					nextNode: 'ReturnToTLReasonEntry'
				},
				{
					text: 'Edit Report',
					nextNode: 'EditReport',

					visible: (_, store) =>
						store.select(getSelectedContextEngine('IAReports')).pipe(
							skipWhile(result => !result),
							take(1),
							map((allReports: any[]) => allReports && allReports.some(report => report.purpose === 'SP Report' || report.purpose === 'Internal Assessor Report'))
						)
				},
				{ text: 'Continue', color: 'primary', nextNode: 'Decision' }
			]
		},
		Decision: {
			nodeType: 'decision',
			initFormFields: bf => bf.patchValues({ new_state: 27, current_state: 26 }),
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				checkIfConsignment: (navs, store, modal, control) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(job => {
								if (job.skill === 1) {
									// always upload COC document if skill is
									store.dispatch(
										new MakeServerCall({
											isBackgroundTask: true,
											errorMessage: 'Could not go to next node!',
											directCall: (http, s, sq, bf, controller) => {
												controller.dispatch(new SetNextNode('COCNotification'));
												return of();
											}
										})
									);
								} else {
									store.dispatch(
										new MakeServerCall({
											isBackgroundTask: true,
											errorMessage: 'Could not go to next node!',
											directCall: (http, s, sq, bf, controller) => {
												setTimeout(() => {
													controller.dispatch(new SetNextNode('AUTO_SELECT_TEMPLATE_DECISION'));
												}, 1000);
												return of();
											}
										})
									);
								}
								return 'Decision';
							})
						)
						.pipe(take(1))
						.subscribe();
				}
			},
			component: '',
			navs: []
		},
		COCNotification: {
			component: 'FLXHeadingWithInstructionsComponent',
			inputs: {
				title: 'Certificate of Compliance (COC)',
				itemMargin: '0 0 35px 0',
				instructions: ['To upload the Certificate of Compliance (COC) document click continue.']
			},
			hideTabItem: false,
			navs: [
				{
					text: 'Return job to team leader',
					linkType: 'portal',
					location: 'center',
					nextNode: 'ReturnToTLReasonEntry'
				},
				{ text: 'Continue', color: 'primary', nextNode: 'UploadGeyserComplianceCertificate' }
			]
		},
		JobPhotos: {
			...PHOTO_NODE,
			navs: []
		},
		ReturnToTLReasonEntry: {
			showTabs: false,
			hideTabItem: true,
			initFormFields: (bf, item, instance, storeQuery, store) => {
				combineLatest([store.select(getCurrentUser)?.pipe(filter(x => !!x, take(1))), store.select(getFullItemTwo)?.pipe(filter(x => !!x, take(1)))])
					.pipe(take(1))
					.subscribe(([author, job]) => {
						bf.patchValues({ author: author.user.id });
						bf.patchValues({ author_name: author.user.full_name });
						bf.patchValues({ currentState: job?.state });
					});
				bf.addControl('return_tl_reason', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Return to Team Leader',
							formControls: {
								0: {
									label: 'Please enter the reason for returning this to the team leader',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'return_tl_reason'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SetAppointment',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo)?.pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData)?.pipe(
										skipWhile(x => !x),
										take(1)
									)
								])?.pipe(
									map(([job, submit]) => {
										const job_id = job?.id;
										const current_state = job?.state;
										const new_state = submit.new_state;
										const appointment = submit.appointment;
										const return_tl_reason = submit.return_tl_reason;
										const data = {
											job_id: job_id,
											return_tl_reason: return_tl_reason
										};
										return data;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq, store) => {
				const formGroup = {
					appointmentDatePicker: new UntypedFormControl(null),
					appointmentTime: new UntypedFormControl(null),
					appointmentTimePicker: new UntypedFormControl(null),
					appointmentDateType: new UntypedFormControl(null)
				};
				if (environment.client === 'mul_sp') {
					bf.addControl('appointmentData', new UntypedFormGroup({ ...formGroup, skill: new UntypedFormControl('Dish Installation') }));
				} else {
					sq.queryObject(
						gql`
							{
								fullItemTwo {
									office_use {
										skill
										skillcatagory
									}
								}
							}
						`,
						store.select(getFullItemTwo)?.pipe(
							skipWhile(fi => !fi),
							take(1),
							map(res => ({ fullItemTwo: res }))
						)
					).subscribe(({ skill, skillcatagory }) => {
						bf.addControl('appointmentData', new UntypedFormGroup({ ...formGroup, skill: new UntypedFormControl(`${skillcatagory} - ${skill}`) }));
					});
				}

				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				bf.patchValues({ new_state: 205 });
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo)?.pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									let firstname, surname, contact_number, cell_number;
									if (!qdat) {
										firstname = 'No first name available';
										surname = 'No surname available';
										contact_number = 'No contact number';
									} else {
										if (qdat.first_name === '') {
											firstname = 'No first name available';
										} else {
											firstname = qdat.first_name ?? 'No first name available';
										}
										if (qdat.first_name === '') {
											surname = 'No surname available';
										} else {
											surname = qdat?.surname ?? 'No surname available';
										}
										if (qdat.contactnumber === '') {
											contact_number = 'No contact number';
										} else {
											contact_number = qdat?.contactnumber ?? 'No contact number';
										}
										if (qdat.cellnumber === '') {
											cell_number = 'No cell number';
										} else {
											cell_number = qdat?.cellnumber ?? 'No cell number supplied';
										}
									}
									return [
										{
											'Client Name': `${firstname} ${surname}`
										},
										{ 'Contact Number': `${contact_number}` },
										{ 'Mobile Number': `${cell_number}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo)?.pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Set Appointment',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo)?.pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData)?.pipe(
										skipWhile(x => !x),
										take(1)
									)
								])?.pipe(
									map(([job, submit]) => {
										const job_id = job?.id;
										const current_state = job?.state;
										const new_state = submit.new_state;
										const appointment = submit.appointment;
										const data = {
											job_id: job_id,
											current_state: current_state,
											new_state: new_state,
											appointment: appointment
										};
										return data;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			],
			component: 'FLXJobAppointmentComponent'
		},
		UploadGeyserComplianceCertificate: {
			hideTabItem: true,
			initFormFields: (bf, iten, instance, sq, store) => {
				bf.bigForm.addControl('purpose', new UntypedFormControl('COC_Certificate', [Validators.required]));
				bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
			},
			component: 'FLXFileUploadWithPreviewComponent',
			checkValidityForFields: ['purpose', 'fileQueue'],
			inputs: {
				purposes: [{ display: 'Certificate of Compliance', value: 'COC_Certificate' }],
				heading: 'Select Compliance Certificate to Attach',
				multipleUploads: false,
				allowedFileTypes: ['pdf'],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			navs: [
				{
					text: 'Upload Certificate',
					serverFirst: true,
					nextNode: 'FileSubmissionSuccess',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'portal',
					serverCalls: {
						uploadCertificate: {
							errorMessage: 'Certificate could not be uploaded',
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0] ? fileQueue[0]?.file.base64 : '',
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
								return of({});
							},

							followUpSuccessCalls: {
								response: {
									errorMessage: 'Job could not be updated',
									serviceVariable: 'spService',
									directCall: (http, store, sq, bf) => {
										return store
											.select(getFullItemTwo)
											.pipe(
												skipWhile(x => !x),
												take(1)
											)
											.pipe(
												map(job => {
													const spJob = job as any;

													const data = {
														job_information: spJob.job_information,
														job_id: spJob.id
													};
													return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
												})
											);
									}
								}
							}
						}
					}
				}
			]
		},
		FileSubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'File successfully uploaded' },
			navs: [{ text: 'Continue', nextNode: 'AUTO_SELECT_TEMPLATE_DECISION' }]
		},
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		CHECK_IF_ATTENDED_AFTER_HOURS,
		OWN_BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'invoice',
				numberLabel: 'Invoice',
				currentState: 26,
				newState: 27,
				showLineItemGenerator: true,
				canEditLineItems: true,
				boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
				boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Standard Bank Insurance Limited',
				clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL'
			},
			navs: [
				{
					text: 'Generate Invoice',
					nextNode: 'SAVE_TO_DESKTOP',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
		SAVE_TO_DESKTOP,
		DynamicAnchor: {
			component: 'DynamicAnchorComponent'
		},
		EditReport: {
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('circumstance_of_loss', new UntypedFormControl('', [Validators.required]));
				bf.addControl('description_of_claim', new UntypedFormControl('', [Validators.required]));
				bf.addControl('recommendations', new UntypedFormControl('', [Validators.required]));
				bf.addControl('current_state', new UntypedFormControl(89, [Validators.required]));
				bf.addControl('new_state', new UntypedFormControl(90, [Validators.required]));
				bf.addControl('purpose', new UntypedFormControl('SP Report', [Validators.required]));
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									job_information {
										circumstance_of_loss
										description_of_claim
										recommendations
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(values => {
						bf.patchValues({
							circumstance_of_loss: values.circumstance_of_loss ? values.circumstance_of_loss : bf.getControl('circumstance_of_loss')?.value || '',
							description_of_claim: values.description_of_claim ? values.description_of_claim : bf.getControl('description_of_claim')?.value || '',
							recommendations: values.recommendations ? values.recommendations : bf.getControl('recommendations')?.value || ''
						});
					});
			},
			serverCalls: {
				dataFile: {
					serviceVariable: '',
					functionName: '',
					errorMessage: 'No file could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryStore(
								gql`
									{
										selectedContext {
											IAReports
										}
									}
								`
							)
							.pipe(
								skipWhile(result => !result),
								take(1),
								switchMap(result => {
									const allReports = result.IAReports;
									const spReports = [];

									if (allReports) {
										allReports.forEach(report => {
											if (report.purpose === 'SP Report' || report.purpose === 'Internal Assessor Report') {
												spReports.push({ ...report });
											}
										});

										if (spReports) {
											spReports.sort((a, b) => (a.created < b.created ? 1 : -1));

											if (spReports.length > 0) {
												return http
													.post(`${environment.api_url}v1/file_action/get_file/`, {
														file_id: spReports[0]?.id,
														return_type: 1
													})
													.pipe(
														skipWhile(report => !report),
														take(1),
														map((value: any) => {
															return value?.payload;
														})
													);
											}
										}
									}

									//Returning of empty object as when returning empty the loader isn't being removed - tried EMPTY and it does not work either.
									return of({});
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							dataFiles$: 'dataFile'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'circumstance_of_loss',
									inputType: 'textarea',
									rows: 3,
									label: 'What caused the loss'
								},
								1: {
									formControlName: 'description_of_claim',
									inputType: 'textarea',
									rows: 3,
									label: 'What is the damage'
								},
								2: {
									formControlName: 'recommendations',
									inputType: 'textarea',
									rows: 3,
									label: 'Recommendations'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw'
						}
					}
				]
			},
			checkValidityForFields: ['circumstance_of_loss', 'description_of_claim', 'recommendations'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									sq
										.queryObject(
											gql`
												{
													selectedContext {
														fullItemTwo
													}
												}
											`,
											store
										)
										.pipe(
											skipWhile(x => !x),
											take(1),
											map(res => res as any)
										),
									store.select(getSubmissionData)?.pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any[])
									)
								])?.pipe(
									map(([selected, submissiondata]: any[]) => {
										if (selected && submissiondata) {
											const job_id = selected?.fullItemTwo?.id;
											const create_sp_report = 1;
											const circumstance_of_loss = submissiondata?.job_information?.circumstance_of_loss;
											const description_of_claim = submissiondata?.job_information?.description_of_claim;
											const recommendations = submissiondata?.job_information?.recommendations;

											return {
												job_id: job_id,
												create_sp_report: create_sp_report,
												job_information: {
													...selected?.fullItemTwo?.job_information,
													circumstance_of_loss: circumstance_of_loss,
													description_of_claim: description_of_claim,
													recommendations
												},
												purpose: 'SP Report',
												...(selected?.selectedItem?.state === 89 ? { new_state: 97 } : {})
											};
										}
									}),
									switchMap(data => http.post(`${environment.api_url}v1/job_action/update_job/`, data))
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		// purpose: 'purpose',
		current_state: [() => 26, 'current_state'],
		new_state: 'new_state',
		circumstance_of_loss: 'job_information.circumstance_of_loss',
		description_of_claim: 'job_information.description_of_claim',
		recommendations: 'job_information.recommendations',

		return_tl_reason: [
			(rtlr, storObj, bf) => {
				const return_tl_reasons = [];
				const date = new Date();
				const newInfo = {
					message: rtlr,
					author_id: bf.author,
					author_name: bf.author_name,
					currentState: bf.current_state,
					nextState: bf.new_state,
					timeStamp: convertDateTimeToTimeStamp(date.toDateString())
				};

				if (
					storObj['selectedContext']?.fullItemTwo &&
					storObj['selectedContext']?.fullItemTwo.job_information &&
					storObj['selectedContext']?.fullItemTwo?.job_information?.interstate_comments
				) {
					const temp = storObj['selectedContext']?.fullItemTwo?.job_information?.interstate_comments;

					Array.isArray(temp) ? return_tl_reasons.push(...temp, newInfo) : return_tl_reasons.push(temp, newInfo);
				} else {
					return_tl_reasons.push(newInfo);
				}

				return return_tl_reasons;
			},
			'job_information.interstate_comments'
		],
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					const appointment_formatted = {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
					return appointment_formatted;
				}
			},
			'appointment'
		],
		total: [
			(a, b, bf) => {
				return bf.total + bf.vatRate;
			},
			'job_information.quote_amount'
		],
		invoiceNumber: 'job_information.quote_number'
	}
};
