import { NgModule } from '@angular/core';
import { FLXFlatDynamicFormModule } from './flat-dynamic-form';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FLXFormsModule } from '@flexus/ui-elements';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, FormsModule, FLXFormsModule, FLXFlatDynamicFormModule],
	declarations: [],
	exports: [FLXFlatDynamicFormModule]
})
export class FLXCompositeFormsModule {}
