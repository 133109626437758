import { map, switchMap, skipWhile, take, filter } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { getViewData, ViewSet_0_0_2, getCurrentUser, getActiveViewData, CheckForJobsAwarded, CheckForJobsLost, GetSPUsersList } from '@flexus/core';
import { from, Observable, zip } from 'rxjs';
import { CollapseActionPanel, FLXActionPanelComponent, setActionPanelItems } from '../../../../app-shell-features';

import { FLXHeaderActionsComponent } from 'apps/studio/src/app/app-shell-features/header-actions/header-actions.component';
import * as itemTwoContextMenuItems from './item-two-context-menu-items';
import { Roles } from '../../../../sp_globals/sp-user.roles';
import { Store } from '@ngrx/store';

import { FLXSPListViewComponent } from 'apps/studio/src/app/sp_globals/views/list-view/sp-list-view.component';
import { SpWorkflowShellComponent } from 'apps/studio/src/app/sp_globals/base-components/sp-workflow-shell/sp-workflow-shell.component';
import { SPDetailsViewComponent } from 'apps/studio/src/app/sp_globals/views/details-view/details-view.component';

export const SpClaimWorkflow: ViewSet_0_0_2 = {
	id: 'Workflow',
	itemType: 'view_set',
	baseComponent: SpWorkflowShellComponent,
	name: 'Workflow',
	onStateInit: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: 'Scheduler Workflow',
		// actionsComponent: WorkflowActionsComponent,
		controls: (instance: FLXHeaderActionsComponent, store: Store<any>) => {
			return () => {
				return zip(store.select(getCurrentUser).pipe(filter(user => !!user)), instance.controller.select(getActiveViewData).pipe(take(1))).pipe(
					map(([res, data]: any) => {
						const createRoles = [Roles.SP_JOB_SCHEDULER];
						instance.currentView = data?.key;
						instance.reversed = data?.params.reverse;
						const controls = [
							{
								id: 4,
								type: 'dropdown',
								children: [
									{
										text: 'Details View',
										command: instance.loadDetailsView.bind(instance)
									},
									{
										text: 'List View',
										command: instance.loadListView.bind(instance)
									}
								]
							},
							{
								id: 5,
								icon: data?.params.reverse ? 'sorting-up' : 'sorting-down',
								instruction: data?.params.reverse ? 'Oldest to Newest' : 'Newest to Oldest',
								command: instance.reOrder.bind(instance)
							}
						];
						if (res.user.roles.some(role => createRoles.includes(role))) {
							// if (createRoles) {
							controls.push({
								id: 3,
								icon: 'addition',
								instruction: 'Installer Schedules Own Installation',
								command: instance.newJob.bind(instance)
							});
							
							// controls.forEach((control, indx) => {
							// 	if(control?.id == 4) {
							// 		controls?.[indx]?.['children']?.push(
							// 			{
							// 				text: 'My Dashboard',
							// 				command: instance.loadDashboardView.bind(instance, 'sp_dashboard'),
							// 			}
							// 		) 
							// 	}
							// });
						}

						return controls;
					})
				);
			};
		}
	},
	footer: {
		type: 'pagination'
	},
	actionPanel: (instance: FLXActionPanelComponent) => setActionPanelItems(instance, ['search', 'filter', 'recent-activity']),
	views: {
		default: (http: any, controller: any, idbx: any, offline: any, store: any) => {
			return {
				id: 'default',
				dataSource: (): Observable<any> => {
					const input$ = http.get(`${environment.api_url}v1/staff_action/get_summary/`).pipe(
						map((data: any) => {
							//This logic needs to be run where only data from the actual endpoint and not when filtered.
							let all_awarded_jobs_ids: any = data?.payload.length > 0 ? data?.payload.map(({ id }: any) => id) : [];
							store.dispatch(new CheckForJobsAwarded(all_awarded_jobs_ids));
							store.dispatch(new GetSPUsersList());
							// store.dispatch(new CheckForJobsLost(all_awarded_jobs_ids));

							return data?.payload;
						}),
						map((data: any) => data?.map((d: any) => ({ ...d, orgKey: 'mul_sp' })))
					);
					return input$;
					// return fromWorker<any, any>(() => new Worker('../../workers/test.worker', { type: 'module' }), input$).pipe(take(1));
				},
				storeBinding: controller.select(getViewData).pipe(
					map((data: any) => {
						if (data) {
							return data?.default;
						}
					})
				),
				template: SPDetailsViewComponent
			};
		},
		defaultOffline: (http, controller, indexedDbService, offlineService) => {
			return {
				id: 'defaultOffline',
				dataSource: () => {
					return offlineService.getAllClaims().pipe(
						map((data: any) => data?.payload),
						take(1),
						map((items: any[]) => items.filter(item => !item?.tempKey)),
						switchMap((items: any[]) => {
							return from(indexedDbService.claimInDraft.toArray()).pipe(
								skipWhile(x => !x),
								take(1),
								map((localDrafts: any[]) => [...localDrafts, ...items])
							);
						})
					);
				},
				storeBinding: controller.select(getViewData).pipe(map((data: any) => data?.defaultOffline)),
				template: FLXSPListViewComponent
			};
		},
		alt: (http, controller, indexedDbService, offlineService, store) => {
			return {
				id: 'alt',
				dataSource: (): Observable<any> => {
					const input$ = http.get(`${environment.api_url}v1/staff_action/get_summary/`).pipe(
						map((data: any) => {
							//This logic needs to be run where only data from the actual endpoint and not when filtered.
							let all_awarded_jobs_ids = data?.payload.length > 0 ? data?.payload.map((id: any) => (id = id)) : [];
							store.dispatch(new CheckForJobsAwarded(all_awarded_jobs_ids));
							// store.dispatch(new CheckForJobsLost(all_awarded_jobs_ids));

							return data?.payload;
						}),
						map((data: any) => data?.map((d: any) => ({ ...d, orgKey: 'mul_sp' })))
					);
					return input$;
				},
				storeBinding: controller.select(getViewData).pipe(
					map((data: any) => {
						if (data) {
							return data?.alt;
						}
					})
				),
				template: FLXSPListViewComponent
			};
		}
		// reversed: (http, controller, indexedDbService, reverse, offlineService) =>
		//   claimWorkflowViews.default(http, controller, indexedDbService, true, offlineService)
	},
	useMockContextData: false,
	mockContextData: {},
	contextMenu: {
		itemOne: {
			// ...itemOneContextMenuItems
		},
		itemTwo: {
			...itemTwoContextMenuItems
		}
	}
};

const claimWorkflowViews = SpClaimWorkflow.views;
