import { Flow_0_0_2, getCurrentUser, getFullItemOne, getFullItemTwo, getSelectedItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { take, map, skipWhile, pluck, filter, mergeMap, switchMap } from 'rxjs/operators';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { CustomValidators, generateRange } from '@flexus/utilities';
import { BetCreateClaim } from '../../../bettersure-hoc/configs/create-claim/bet-create-claim';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin } from 'rxjs';
import { JobLocation } from '@flexus/ui-elements';

export const BET_SP_291: Flow_0_0_2 = {
	id: '291',
	name: 'repudiated_job_overturned',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) =>
			store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Repudiated Job Overturned : ${itemOne?.mid} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Repudiated Job Overturned';
					}
				})
			),
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		summaryInfo: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					pluck('job_information'),
					map((job_information: any) => ({
						important_information: {
							important_note: 'Repudiation for this job has been overturned. Please continue to complete the work.',
							'Reason for repudiation overturn': job_information?.repudiation_overturned_reason
						}
					}))
				);
			}
		},
		customerDetails: {
			errorMessage: '',
			directCall: (http, store, sq) =>
				store.select(getFullItemTwo).pipe(
					skipWhile(f => !f),
					take(1),
					map((job: any) => ({
						customer_details: {
							customer: `${job?.claim?.applicant?.first_name} ${job?.claim?.applicant?.surname}`,
							'Contact Number': job?.claim?.applicant?.contact_number
						}
					}))
				)
		},
		claimDetails: {
			errorMessage: '',
			directCall: (http, store, sq) =>
				store.select(getFullItemTwo).pipe(
					skipWhile(f => !f),
					take(1),
					map((job: any) => ({
						claim_details: {
							'Claim Type': job?.claim?.type,
							'Skill Required': job?.office_use?.skill,
							Address: job?.address
						}
					}))
				)
		}
	},
	instructions: {
		editRoles: {
			0: 'Repudiated Job Overturned'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			name: 'Summary',
			component: {
				children: [
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							headingConfig: { title: 'Invalid Job Overturned', color: 'default', size: 'medium' },
							subHeadingConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							keyValueHeadingConfig: { color: 'secondary' },
							objectInfo$: 'summaryInfo'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'customerDetails'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'claimDetails'
						}
					}
				]
			},
			navs: [
				{
					text: 'Set Appointment',
					nextNode: 'SetAppointment',
					color: 'primary'
				}
			]
		},
		SetAppointment: {
			...BetCreateClaim?.nodes?.claimAppointments,
			inputs: {
				...BetCreateClaim?.nodes?.ClaimAppointments?.inputs,
				showCurrentJobs: true
			},
			serverCalls: {
				...BetCreateClaim?.nodes?.ClaimAppointments?.serverCalls
			},
			component: 'ClaimAppointmentsComponent',
			initFormFields: (bf, item, comp, sq, store) => {
				store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(f => !f),
						take(1)
					)
					.subscribe(job => {
						bf.addControl(
							'client_details',
							new UntypedFormGroup({
								first_name: new UntypedFormControl(job?.claim?.applicant?.first_name),
								surname: new UntypedFormControl(job?.claim?.applicant?.surname)
							})
						);
						bf.addControl(
							'contact_details',
							new UntypedFormGroup({
								cell_number: new UntypedFormControl(job?.claim?.loan_information?.contactnumber)
							})
						);

						bf.addControl(
							'on_site_person',
							new UntypedFormControl(job?.claim?.loan_information?.onsiteperson, [
								Validators.required,
								Validators.minLength(2),
								CustomValidators.hardMaxLength(140),
								CustomValidators.onlyAllowedASCII([32, 39, 44, 45, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
							])
						);

						bf.addControl('on_site_notes', new UntypedFormControl(job?.claim?.loan_information?.onsitenotes, [CustomValidators.onlyAllowedASCII(generateRange(32, 126, 1))]));
						bf.addControl('on_site_contact', new UntypedFormControl(job?.claim?.loan_information?.onsitecontact, [CustomValidators.contact_number, Validators.required]));

						bf.bigForm.addControl(
							'jobs',
							new UntypedFormArray([
								new UntypedFormGroup({
									appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
									appointmentTime: new UntypedFormControl(null, [Validators.required]),
									appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
									appointmentdatetype: new UntypedFormControl(null),
									skill: new UntypedFormControl(job?.office_use?.skill),
									skillcatagory: new UntypedFormControl(job?.office_use?.skillcatagory)
								})
							])
						);
					});
				bf.addControl('new_state', new UntypedFormControl(292));
			},
			navs: [{ text: 'Assign Team Lead', color: 'primary', nextNode: 'AssignTeamLeader' }]
		},
		AssignTeamLeader: {
			name: 'Assign Team Leader',
			initFormFields: (bf, item, instance, sq, store) => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl(null, [Validators.required]));
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter(x => !!x),
							map((teamleaders: any[]) =>
								teamleaders.map(teamLeader => {
									return {
										display: teamLeader.full_name,
										value: teamLeader.id,
										teamLeader,
										shouldHaveImage: true
									};
								})
							)
						);
					}
				},
				jobLocationData: {
					errorMessage: 'The job location was not returned!',
					directCall: (http, store, sq, bf) => {
						return store.select(getSelectedItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							mergeMap(j => {
								const job = j as any;
								return forkJoin([
									http.post(`${environment.api_url}v1/job_action/get_job/`, { job_id: job?.id }).pipe(
										skipWhile(x => !x),
										take(1),
										map(result => result as any)
									)
								]).pipe(
									map(([jobloc]) => {
										const locationString = jobloc['payload']?.claim?.location;
										const locArray = locationString?.split(',');
										const latitude = +locArray[0];
										const longitude = +locArray[1];
										return new JobLocation(latitude, longitude);
									})
								);
							})
						);
					}
				},
				teamleaderLocations: {
					errorMessage: 'Team leader locations not returned!',
					directCall: (http, store, sq, bf) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter(x => !!x),
							map((teamleaders: any[]) => {
								return teamleaders;
							})
						);
					}
				}
			},
			component: 'AssignTLComponent',
			checkValidityForFields: ['assign_teamleader_id'],
			inputs: [
				{
					joblocation$: 'jobLocationData',
					teamleadersPositions$: 'teamleaderLocations'
				}
			],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						updateJobResponse: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!',
							followUpSuccessCalls: {
								response: {
									errorMessage: "Couldn't update job!",
									directCall: (http, store, sq, bf) => {
										return forkJoin([
											store.select(getSelectedItemTwo).pipe(
												skipWhile(x => !x),
												take(1),
												map(res => res as any)
											),
											store.select(getCurrentUser).pipe(
												skipWhile(x => !x),
												take(1),
												map(res => res as any)
											)
										]).pipe(
											switchMap(([j, uid]) => {
												const job_id = (j as any)?.id;
												const staffmember = (uid as any)?.id;
												const team = bf.bigForm.get('assign_teamleader_id')?.value[0];

												const data = {
													job_id: job_id,
													staffmember: staffmember,
													team_id: team,
													new_state: 292
												};
												return http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
											})
										);
									}
								}
							}
						}
					},
					location: 'right'
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		jobs: [
			(jobs, storeObj, formValue) => {
				if (jobs) {
					const date = moment(jobs[0]?.appointmentDatePicker);
					date.hour(jobs[0]?.appointmentTimePicker?.hour);
					date.minutes(jobs[0]?.appointmentTimePicker?.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					return {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: jobs[0]?.appointmentTime
					};
				}
			},
			'appointment'
		],
		assign_teamleader_id: [
			tlid => {
				if (tlid) {
					let id;
					tlid = tlid && Array.isArray(tlid) ? tlid : [];
					for (const lead of tlid) {
						id = parseInt(lead, 10);
					}
					return id;
				}
				return 0;
			},
			'job_information.team_leader'
		]
	}
};
