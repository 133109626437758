import { Flow_0_0_2, getData, getCurrentUser, SetNextNode, MakeServerCall } from '@flexus/core';
import { skipWhile, map, switchMap, take, filter, first, pluck } from 'rxjs/operators';
import { Validators, UntypedFormControl } from '@angular/forms';
import { getSelectedItemOne, getFullItemTwo, getAllInfo } from '@flexus/core';
import { of, forkJoin, EMPTY } from 'rxjs';
import gql from 'graphql-tag';
import { values } from 'ramda';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { findJobLog } from '../reusable/state-utils';

import { getAllInfoIndex } from '@flexus/utilities';

export const BUI_50: Flow_0_0_2 = {
	id: '50',
	name: 'payment_approval_1',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				filter(x => !!x),
				skipWhile((itemTwo: any) => {
					return itemTwo === null || itemTwo === undefined;
				}),
				first(itemTwo => itemTwo !== null || itemTwo !== undefined),
				map((itemTwo: any) => {
					let headerStr = 'Payment Approval 1';
					if (itemTwo) {
						headerStr += ` : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					}
					return headerStr;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	serverCalls: {
		authorisers: {
			serviceVariable: 'buiService',
			functionName: 'getAuthorizers',
			errorMessage: 'No authorizers could be found!'
		},
		invoice: {
			serviceVariable: 'buiService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		dataFiles: {
			errorMessage: 'No invoice found!',
			directCall: (http, store, sq) => {
				return sq
					.queryObject(
						gql`
							{
								invoice {
									data
								}
							}
						`,
						store.select(getData)
					)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1),
						map(data => data['data'])
					);
			}
		},
		timeStamps: {
			errorMessage: 'Could not get time stamps',
			directCall: (http, store) => {
				return forkJoin([
					store.select(getSelectedItemOne).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getFullItemTwo).pipe(
						skipWhile(x => !x),
						take(1)
					)
				]).pipe(
					filter(x => !!x),
					switchMap(([claim, job]) => {
						return http.post(`${environment.api_url}v1/claim_action/get_time_logs/`, { [`claim_id`]: claim?.id }).pipe(
							skipWhile(logs => !logs),
							switchMap((res: any) => {
								const setsOfJobLogs: any[] = res?.payload['jobs'];
								const autoPayJobLogForState27Or39 = findJobLog(setsOfJobLogs, job, [27, 39]);
								const applicationCreatorId = claim?.application_creator?.id;
								const paymentapproval1 = job?.job_information?.paymentapproval1;
								return http
									.post(environment.api_url + 'v1/staff_action/get_staff_member_names', {
										ids: [+applicationCreatorId, +paymentapproval1]
									})
									.pipe(
										map((data: any) => data?.payload),
										map((mapper: any) => ({ payArroval1: autoPayJobLogForState27Or39?.modifier || mapper[paymentapproval1], payHandler: mapper[applicationCreatorId] }))
									);
							})
						);
					})
				);
			}
		}
	},

	events: {
		invalidateDeclineDataIfNextApprovalStaffName: {
			triggerOn: 'nextApprovalStaffName',
			triggerWhen: nextApprovalStaffName => !!nextApprovalStaffName,
			dataMutations: bf => {
				bf.patchValues({
					decline_reason: null,
					new_state: 51
				});
			}
		},

		invalidatePaymentDataIfDeclineIsSet: {
			triggerOn: 'decline_reason',
			triggerWhen: qr => !!qr,
			dataMutations: bf => {
				bf.patchValues({
					nextApprovalStaffName: null,
					invoice_number: null,
					new_state: 52
				});
			}
		}
	},

	instructions: {
		editRoles: {
			0: 'Authorise the payment'
		},
		viewRoles: {
			0: '--'
		}
	},

	startNode: 'Decision',

	nodes: {
		Decision: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				authorization: (navs, store, modal) => {
					return forkJoin([
						store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1)
						),
						store.select(getCurrentUser).pipe(
							skipWhile(x => !x),
							take(1)
						)
					])
						.pipe(
							switchMap(([job, user]) => {
								switch (true) {
									case parseInt(job?.job_creator, 10) === user?.user?.id: {
										modal.openModalDirectly(instance => {
											instance.type = 'warning';
											instance.message = 'Job creator cannot provide second and third eyes for payment authorization';
											instance.navButtons = [
												{
													text: 'Back to workflow',
													clickHandler: event => {
														instance?.router?.navigate(['/workflow']);
													},
													linkType: 'close',
													color: 'alert'
												}
											];
										});
										break;
									}
									case parseInt(job?.job_information?.paymentapproval1, 10) === user?.user?.id: {
										modal.openModalDirectly(instance => {
											instance.type = 'warning';
											instance.message = 'You can not process a job which you initiated payment for';
											instance.navButtons = [
												{
													text: 'Back to workflow',
													clickHandler: event => {
														instance.router.navigate(['/workflow']);
													},
													linkType: 'close',
													color: 'alert'
												}
											];
										});
										break;
									}
									default: {
										store.dispatch(
											new MakeServerCall({
												dataKey: 'updateJob',
												serviceVariable: '',
												functionName: '',
												errorMessage: 'Could not update Job',
												directCall: (http, store, sq, bf, controller) => {
													bf.patchValues({ paymentapproval2: user?.user?.id });
													controller.dispatch(new SetNextNode('PaymentPreview'));
													return of({});
												}
											})
										);

										break;
									}
								}

								return 'Decision';
							})
						)
						.pipe(take(1))
						.subscribe(nextNode => {});
				}
			},
			navs: [{ text: 'Success', nextNode: 'SubmissionSuccess' }]
		},

		PaymentPreview: {
			inputs: { inputHeading: 'Payment Preview' },
			component: {
				children: [
					{ component: 'FLXFileViewerComponent', inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' } },
					{ component: 'FLXKeyValueListComponent', inputs: { data$: 'keyValueList' } },
					{ component: 'FLXKeyValueListComponent', inputs: { data$: 'keyValueList2', heading: 'Approvals' } }
				]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store, sq) => {
						const invoice$ = sq
							.queryStore(
								gql`
									{
										selectedContext {
											invoice
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1)
							);
						return forkJoin([
							store.select(getSelectedItemOne).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							invoice$.pipe(
								skipWhile(x => !x),
								pluck('invoice'),
								take(1)
							)
						]).pipe(
							take(1),
							map(([claim, job, allInfo, invoice]) => {
								const list = {
									Claimant: claim && claim?.applicant ? `${claim?.applicant?.first_name} ${claim?.applicant?.surname}` : '',
									'Claim ID': claim ? claim?.id : '',
									'Claim handler': claim && claim?.application_creator && claim?.application_creator.full_name ? claim?.application_creator.full_name : '',
									Address: job ? job?.address : 'No address found',
									'Excess Amount': job?.job_information?.update_excess
										? `R${job?.job_information?.update_excess}`
										: job?.excess && job?.excess.length !== 0
										? `R ${job.excess[0]?.amount}`
										: 'No excess found',
									'Collected by': job?.office_use?.excess?.who_collects_excess_desc ?? '',
									'Payment method': job?.office_use?.excess?.excess_payment_method_desc ?? '',
									'Excess state': ((): string => {
										let state = '';
										if (job.excess[0]) {
											allInfo['excess_states']?.forEach(element => {
												if (element.id === job?.excess[0]?.state) {
													state = element.name;
												}
											});
										}
										return state;
									})(),
									'Amount requested': job?.claim_value ? `R ${job.claim_value}` : invoice && invoice.balance_due ? `R ${invoice.balance_due.toFixed(2)}` : 0,
									'Invoice number': job?.job_information?.invoice_number ?? invoice?.invoice_number ? invoice.invoice_number : '',
									'Job Status': ((): string => {
										let job_status = '';
										if (allInfo) {
											allInfo['valid_job']?.forEach(element => {
												if (element.id === job?.valid_job) {
													job_status = element.name;
												}
											});
										}
										return job_status;
									})(),
									'Pay type': ((): string => {
										let pay_type = '';
										if (allInfo) {
											allInfo['supplier_type']?.forEach(element => {
												if (element.id === job?.supplier_type) {
													pay_type = element.name;
												}
											});
										}
										return pay_type;
									})(),
									'Maven Claim Number': claim ? claim?.mid : ''
								};
								return [list];
							})
						);
					}
				},
				keyValueList2: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store, sq) => {
						const selectedContext$ = sq
							.queryStore(
								gql`
									{
										selectedContext {
											timeStamps
										}
									}
								`
							)
							.pipe(
								skipWhile(f => !f.timeStamps),
								pluck('timeStamps'),
								take(1),
								map(value => {
									const list = {
										'Job Creator': value && value.payHandler ? value.payHandler : '',
										'Payment Approval Handler': value && value.payArroval1 ? value.payArroval1 : ''
									};
									return [list];
								})
							);

						return selectedContext$;
					}
				}
			},
			navs: [
				{ text: 'Decline Payment', nextNode: 'DeclinePayment', color: 'alert' },
				{ text: 'Approve Payment', color: 'primary', nextNode: 'AuthorizerSelect' }
			]
		},

		DeclinePayment: {
			component: 'FLXFlatDynamicFormComponent',
			serverCalls: {
				selectOptions: {
					errorMessage: 'Cannot find decline reasons',
					directCall: (http, stroe, sq) => {
						return sq
							.queryStore(
								gql`
									{
										allInfo {
											cancel_reasons
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1),
								map(values),
								map(value => value[0]),
								map(vals => ({ declineReasons: vals }))
							);
					}
				}
			},
			inputs: {
				heading: '',
				formControls: {
					0: {
						label: 'Select reason for declining payment',
						inputType: 'select',
						selectConfig: {
							displayOptions: { displayKey: 'name', valueKey: 'id' },
							itemsOption: 'declineReasons',
							searchEnabled: false,
							placeHolder: 'Decline Reason'
						},
						formControlName: 'decline_reason'
					},
					1: {
						label: 'Enter note on the reason for declining',
						inputType: 'textarea',
						width: '48%',
						formControlName: 'decline_notes'
					}
				},
				containerWidth: '300px'
			},

			checkValidityForFields: ['decline_notes'],

			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ decline_notes: '', new_state: 52 });
				bf.bigForm.get('decline_notes')?.setValidators([Validators.required, Validators.minLength(3)]);
				bf.bigForm.addControl('decline_reason_string', new UntypedFormControl(''));
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(current_state => {
						bf.patchValues({ current_state: current_state.state });
						// bf.addControl('current_state', new FormControl(current_state.state));
					});
			},

			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		AuthorizerSelect: {
			component: 'FLXAuthoriserSelectComponent',
			inputs: {
				heading: 'Select who will continue the payment process',
				subheading: 'Scroll and select a name or start typing to limit the list',
				canFilter: true,
				maxWidth: '440px'
			},
			serverCalls: {
				authorisers: {
					errorMessage: 'No authorizers could be found!',
					directCall: (http, store, sq, bf) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getCurrentUser).pipe(
								skipWhile(x => !x),
								take(1)
							)
						]).pipe(
							take(1),
							switchMap(([job, curUser]) => {
								const claimVal = job?.claim_value;
								const { user } = curUser;
								const params = { job_id: bf.bigForm?.value?.itemTwo.id };
								return http.post(environment.api_url + 'v1/staff_action/get_authorizers', params).pipe(
									take(1),
									map(response => response['payload']),
									map(arr => arr.filter(x => x.max_auth >= claimVal && x.id !== user.id)),
									map((obj: { [key: string]: { id: number; full_name: string } }) => {
										return Object.values(obj).map(entry => ({
											display: entry.full_name,
											value: entry.id
										}));
									})
								);
							})
						);
					}
				}
			},
			initFormFields: (bf, item, instance, sq) => {
				const nextApprovalStaffName = bf.bigForm.get('nextApprovalStaffName');
				bf.patchValues({ new_state: 51 });

				if (!nextApprovalStaffName) {
					bf.addControl('nextApprovalStaffName', new UntypedFormControl('', Validators.required));
				} else {
					nextApprovalStaffName?.setValidators(Validators.required);
				}
			},
			checkValidityForFields: ['nextApprovalStaffName', 'paymentapproval2'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		nextApprovalStaffName: [
			[
				aid => {
					const id = aid && Array.isArray(aid) ? parseInt(aid[0], 10) : null;
					return id;
				},
				'authid'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return null;
				},
				'job_information.nextApprovalStaffName'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return null;
				},
				'job_information.authid'
			]
		],
		paymentapproval2: 'job_information.paymentapproval2',
		current_state: 'current_state',
		new_state: 'new_state',
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		decline_reason: [
			(dr, storeObj, bf) => {
				let pd: any[] = [];
				if (storeObj?.['selectedContext']?.fullItemTwo.job_information && storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline)) {
						pd = storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline;
					} else {
						pd = [{ ...(<object>storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline) }];
					}
				}

				const payment_decline = [...pd, { reason: dr, comment: bf.decline_notes }];
				return payment_decline;
			},
			'job_information.payment_decline'
		],

		decline_reason_string: [
			(dr, storeObj, bf) => {
				return !!bf.decline_reason ? getAllInfoIndex('cancel_reasons', 'id', 'name', bf.decline_reason, storeObj) : null;
			},
			'job_information.declinereason'
		],
		decline_notes: ['job_information.declinereasoncomments']
	}
};
