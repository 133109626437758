import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ISlimScrollOptions } from 'ngx-slimscroll';
import { Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';

export class DocumentQuery {
	query?: string;
	queryreply?: string;
}

@Component({
	selector: 'flx-query-history',
	templateUrl: './query-history.component.html',
	styleUrls: ['./query-history.component.scss']
})
export class FLXQueryHistoryComponent implements OnInit, AfterViewInit {
	@Input() queryHistory$: Observable<DocumentQuery[]>;
	@Input() location = 'action-panel';
	@Input() title = 'Query History';
	conversationHistory: DocumentQuery[];
	@Input()
	opts: ISlimScrollOptions = {
		position: 'right',
		barBackground: '#C9C9C9',
		barOpacity: '0.8',
		barWidth: '10',
		barBorderRadius: '20',
		barMargin: '0',
		gridBackground: '#000000',
		gridOpacity: '1',
		gridWidth: '2',
		gridBorderRadius: '20',
		gridMargin: '0',
		alwaysVisible: true,
		visibleTimeout: 1000
	};
	// scrollEvents: EventEmitter<SlimScrollEvent>;

	headingAlignment = 'center';
	locationClass = 'note-edit';
	headingWeight = 'normal';

	constructor() {}

	ngOnInit() {
		// this.scrollEvents = new EventEmitter<SlimScrollEvent>();
		switch (this.location) {
			case 'action-panel':
				this.locationClass = 'compact';
				this.headingAlignment = 'left';
				this.headingWeight = 'normal';
				break;
			case 'screen':
				this.locationClass = 'scattered';
				this.headingAlignment = 'center';
				this.headingWeight = 'extra-light';
				break;
			default:
				break;
		}

		this.queryHistory$ &&
			this.queryHistory$
				.pipe(
					filter(x => !!x),
					take(1)
				)
				.subscribe(histories => {
					this.conversationHistory = [...histories].reverse();
				});
	}

	ngAfterViewInit() {
		// this.scrollEvents.emit(
		//   new SlimScrollEvent({
		//     type: 'recalculate'
		//   })
		// );
	}
}
