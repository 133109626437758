import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { BET_97 } from './BET_97';
import { skipWhile, take, map } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

export const BET_32: Flow_0_0_2 = {
	id: '32',
	name: 'TL_en_route',
	itemType: 'flow',
	fetchLevel1And2: BET_97.fetchLevel1And2,
	header: {
		title: (store, bf) => {
			bf.bigForm.addControl('workflow_current_state', new UntypedFormControl(32));
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					if (itemOne) {
						let title = 'Team Lead en route';
						if (itemOne?.loan_information?.mavenclaimnumber) {
							title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
						}
						if (itemOne.applicant?.surname) {
							title += ` - ${itemOne.applicant?.surname}`;
						}

						return title;
					} else {
						return 'Team Lead en route';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		...BET_97.footer
	},
	instructions: {
		editRoles: {
			0: 'Review'
		},
		viewRoles: {
			0: 'TL en route'
		}
	},
	actionPanel: BET_97.actionPanel,
	serverCalls: {
		...BET_97.serverCalls
	},
	startNode: 'JobSummary',
	nodes: {
		...BET_97.nodes
	},
	bigFormToStoreMapper: {
		...BET_97.bigFormToStoreMapper
	}
};
