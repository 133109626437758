import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, InjectionToken, NgZone, Optional, ViewContainerRef, ViewEncapsulation, OnInit } from '@angular/core';

import { Overlay, RepositionScrollStrategy, ScrollStrategy, BlockScrollStrategy } from '@angular/cdk/overlay';
import { UiBaseComponent } from '../ui-base.component';
// import { Dialog } from '../actions-and-effects';

/**
 * Unique Identifier for every menu
 */
let contextMenuUid = 0;

export const FLX_POPUP_OVERLAY_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('flx-popup-overlay-scroll-strategy');
/** @docs-private */
export function FLX_POPUP_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay: Overlay): () => RepositionScrollStrategy {
	return () => overlay.scrollStrategies.reposition();
}

export function FLX_POPUP_OVERLAY_SCROLL_PROVIDER_FACTORY_V2(overlay: Overlay): () => BlockScrollStrategy {
	return () => overlay.scrollStrategies.block();
}

/** @docs-private */
export const FLX_POPUP_OVERLAY_SCROLL_STRATEGY_PROVIDER = {
	provide: FLX_POPUP_OVERLAY_SCROLL_STRATEGY,
	deps: [Overlay],
	useFactory: FLX_POPUP_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY
};

@Component({
	selector: 'flx-popup-picker',
	templateUrl: './popup-overlay-picker.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	styleUrls: []
})
export class FLXPopupOverlayPickerComponent extends UiBaseComponent implements OnInit {
	id = `flx-popup-picker-${contextMenuUid++}`;

	// @Input() isOpen$: BehaviorSubject<{ isOpen: boolean }>;

	constructor(
		@Optional()
		@Inject(DOCUMENT)
		public override _document: any,
		@Optional()
		@Inject(FLX_POPUP_OVERLAY_SCROLL_STRATEGY)
		public override _scrollStrategy,
		public override _viewContainerRef: ViewContainerRef,
		// public _dialog: Dialog,
		public override _overlay: Overlay,
		public override _ngZone: NgZone
	) {
		super(_document, _viewContainerRef, _overlay, _ngZone, _scrollStrategy, true);
		// super(_document, _viewContainerRef, _dialog, _overlay, _ngZone, _scrollStrategy, true);
	}
	isPopUpOpen() {
		return this._opened;
	}
	ngOnInit(): void {
		// this.isOpen$.subscribe((open)=>{
		//   if(open.isOpen){
		//     this.open();
		//   }
		// })
	}
}
