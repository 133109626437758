import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { BigFormService, ManifestController, ModalService, SetNextNode, SetPreviousNode } from '@flexus/core';
import { Store } from '@ngrx/store';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { skipWhile, take } from 'rxjs/operators';

@Component({
	selector: 'flx-fake-modal',
	templateUrl: 'fake-modal.component.html',
	styleUrls: ['fake-modal.component.scss']
})
export class FakeModalComponent implements OnInit {
	// variables
	jobstopayArray: any[] = [];
	request_pack: any;

	constructor(private _store: Store, private _modal: ModalService,@Inject('environment') private environment: any, private _ctrllr: ManifestController<any>, private _http: HttpClient) {}

	ngOnInit() {
		this._modal.openModalDirectly((_instance: any, _store: Store, _bf: BigFormService) => {
			const { included_payments } = _bf.bigForm.value;
			const { excluded_payments } = _bf.bigForm.value;
			for (let i = 0; i < included_payments.length; i++) {
				const jobid = included_payments[i].job_id;
				this.jobstopayArray.push(jobid);
			}
			this.request_pack = {
				job_ids: this.jobstopayArray,
				reason: 'Payment requests made',
				new_state: 28
			};

			_instance.type = 'warning';
			_instance.heading = 'Are you happy with the\n downloaded Excel file?';
			_instance.message = 'If you proceed, all jobs in the Excel file will be moved to a new state.';
			_instance.navButtons = [
				{
					text: 'no, go back',
					clickHandler: () => {
						return this._ctrllr.dispatch(new SetPreviousNode());
					},
					linkType: 'close'
				},
				{
					text: 'yes, proceed',
					clickHandler: () => {
						return this._http
							.post(`${this.environment.api_url}v1/job_action/bulk_move_job_states/`, this.request_pack)
							.pipe(
								skipWhile((data: any) => !data),
								take(1)
							)
							.subscribe((success: any) => {
								if (success) {
									console.log('SUCCESS', success);
									return this._ctrllr.dispatch(new SetNextNode('SubmissionSuccess'));
								}
							});
					},
					linkType: 'nextNode'
				}
			];
		});
	}

}
