import { Flow_0_0_2, getFullItemTwo, getSelectedItemTwo } from '@flexus/core';
import { map, mergeMap, pluck, skipWhile, take } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import gql from 'graphql-tag';
import { forkJoin, of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';

export const BUI_MANAGE_SP: Flow_0_0_2 = {
	id: 'ManageSP',
	itemType: 'flow',
	excludeForOnlyStates: [45],
	excludeForOnlyRoles: [18],
	name: 'SP Pings & Responses',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant) {
							return `Allocate an SP to the Job : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Allocate an SP to the Job :';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	// header: {
	//   title: (store, bf) => {
	//     return store.select(getFullItemTwo).pipe(
	//       map((itemTwo) => {
	//         if (itemTwo) {
	//           if (itemTwo.applicant?.information) {
	//             return `Allocate an SP to the Job : ${itemTwo?.claim?.loan_information?.mavenclaimnumber}  -  ${itemTwo?.claim?.applicant?.surname}`;
	//           } else {
	//             return 'Allocate an SP to the job';
	//           }
	//         }
	//       }),
	//     );
	//   },
	//   controls: () => () => [],
	// },
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	startNode: 'ManageSP',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'buiService',
			functionName: 'getAllClaimFiles',
			responseSlice: 'payload',
			errorMessage: 'Claim files could not be retrieved!'
		},
		interestedParties: {
			serviceVariable: 'buiService',
			functionName: 'getInterestedParties',
			responseSlice: 'payload',
			errorMessage: ''
		},
		replyListData: {
			errorMessage: '',
			serviceVariable: 'buiService',
			functionName: 'getSPReplyListData'
		}
	},
	nodes: {
		ManageSP: {
			inputs: {
				assignSpStateList: [20],
				selectSPNavigateToNode: 'AllocateSPSummary'
			},
			component: 'FLXSpReplyListComponent',
			navs: [
				{
					text: 'Deal Out Of App',
					location: 'center',
					visible: (_, store) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(f => {
								return !f || !f.state;
							}),
							pluck('state'),
							map((f: any) => {
								return !![20, 21, 22]?.find(x => f === x);
							}),
							take(1)
						);
					},
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'warning';
							instance.setMessage(['You are about to handle this job off of 4-Sure', '', 'Please ensure you understand the implications of this decision']);
							instance.navButtons = [
								{
									text: 'Cancel',
									color: 'alert',
									linkType: 'close'
								},
								{
									text: 'I understand',
									color: 'alert',
									nextNode: 'DealOutOfApp',
									linkType: 'submitThenNext',
									serverCalls: {
										dealOutOfApp: {
											serviceVariable: 'buiService',
											functionName: 'dealOutOfApp',
											errorMessage: 'An error occurred while updating the job'
										}
									}
								}
							];
						}
					}
				},
				{
					text: 'Reping SP Request',
					visible: (_, store) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(f => !f || !f.state),
							pluck('state'),
							map((f: any) => {
								return !![20, 21, 22, 23, 32, 37, 42, 45, 77, 90, 98, 134, 135, 150, 151, 152, 153]?.find(x => f === x);
							}),
							take(1)
						);
					},
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'warning';
							instance.setMessage([
								'You are about to reping all the service providers that are able to do this job',
								'',
								'Please ensure you understand the implications of this decision'
							]);
							instance.navButtons = [
								{ text: 'Take me back', color: 'alert', linkType: 'close' },
								{
									text: 'I understand',
									color: 'alert',
									linkType: 'nextNode',
									nextNode: 'SetAppointment'
								}
							];
						}
					}
				}
			]
		},
		DealOutOfApp: {
			component: 'FLXSuccessTickComponent',
			navs: []
		},
		SetAppointment: {
			component: 'FLXJobAppointmentComponent',
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(null),
						skillcatagory: new UntypedFormControl(null)
					})
				);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skillcatagory
										skill
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(x => !x || !x.skill || !x.skillcatagory),
						take(1)
					)
					.subscribe(({ skill, skillcatagory }) => {
						const apd = bf.getControl('appointmentData');
						if (apd) {
							apd.get('skill')?.setValue(skill);
							apd.get('skillcatagory')?.setValue(skillcatagory);
						}
					});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f || !f.state),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'EndSummary',
					serverFirst: true,
					serverCalls: {
						repingSP: {
							errorMessage: 'An error occurred while repinging the Service Provider',
							serviceVariable: 'buiService',
							functionName: 'repingSP'
						}
					}
				}
			]
		},
		AllocateSPSummary: {
			component: 'FLXKeyValueDisplayWithInstructionsComponent',
			inputs: {
				instructions: ['Please double check the details of the selected service provider'],
				title: 'Details for'
			},
			serverCalls: {
				title: {
					errorMessage: 'Could not get SP name',
					directCall: (http, store, sq, bf) => {
						return of(bf.bigForm.get('sp_selected_item')?.value?.name);
					}
				},
				keyValueList: {
					errorMessage: 'data for keyvaluelist not returned',
					directCall: (http, store, sq, bf) => {
						return store.select(getSelectedItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							mergeMap(selectedItem => {
								const job = selectedItem as any;
								return forkJoin([
									http.post(`${environment.api_url}v1/job_action/get_interested_parties/`, { job_id: job?.id }).pipe(
										skipWhile(x => !x),
										map(res => res as any)
									),
									of(bf.bigForm.get('sp_selected_item')?.value)
								]).pipe(
									map(([interested, bigformdata]) => {
										const interestedParties = interested?.payload;
										const selectedSP = bigformdata as any;
										const interestedParty = { ...interestedParties.find(ip => ip.sp.id === selectedSP?.id) };
										let interest;
										switch (interestedParty?.new_interested) {
											case -1: {
												interest = 'Rejected';
												break;
											}
											case 0: {
												interest = 'Ignored';
												break;
											}
											case 1: {
												interest = 'Accepted';
												break;
											}
											case null: {
												interest = 'No-Reply';
												break;
											}
											default:
												break;
										}
										return [
											{ 'Primary Contact': selectedSP.contact_person },
											{ 'Primary Contact Number': selectedSP.contact_primary },
											{ maven_id: selectedSP.mid !== null ? selectedSP.mid : 'Not On Maven' },
											{
												'response to job': interest
											},
											{ 'Currently Awarded Jobs': interestedParty?.awarded_today }
										];
									})
								);
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'EndSummary',
					serverFirst: true,
					serverCalls: {
						appointSP: {
							serviceVariable: 'service',
							functionName: 'appointSP',
							errorMessage: 'An error occurred while manually allocating a service provider'
						}
					}
				}
			]
		},
		EndSummary: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	}
};
