import { NgModule } from '@angular/core';
import { FLXAPClaimSummaryComponent } from './ap-claim-summary.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UIElementsModule } from '@flexus/ui-elements';
import { UICompositesModule } from '@flexus/ui-composites';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { PipesModule } from '@flexus/pipes';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [FLXAPClaimSummaryComponent],
	exports: [FLXAPClaimSummaryComponent],
	imports: [CommonModule, UIElementsModule, UICompositesModule]
})
export class APClaimSummaryModule {}
