import { BigFormService, Flow_0_0_2, ManifestController, ModalService, SelectItemOneSuccess, SetNextNode, getCurrentUser, getFullItemTwo, getSelectedItem } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import gql from 'graphql-tag';
import { EMPTY, forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { R } from '@angular/cdk/keycodes';

export const PGG_SP_CHANGE_APPOINTMENT: Flow_0_0_2 = {
	id: 'ChangeAppointment',
	itemType: 'flow',
	includeForOnlyStates: ['all'],
	excludeForOnlyStates: [28, 226],
	name: 'Change Appointment',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						return `Change appointment : ${itemTwo?.claim?.loan_information?.voucher_key}  -  ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Change appointment';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'Decision',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		Decision: {
			hideTabItem: true,
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => EMPTY
			},
			decisions: {
				allowScheduling: (navs: any, store: Store, modal: ModalService, _controller: ManifestController<any>) => {
					console.log('hitting the appointment');
					return forkJoin([
						store.select(getCurrentUser).pipe(
							skipWhile(x => !x),
							take(1),
							map((data: any) => data)
						),
						store.select(getSelectedItem).pipe(
							skipWhile(x => !x),
							take(1),
							map((data: any) => data)
						)
					])
						.pipe(
							// skipWhile((forkResult: any) => forkResult),
							map(([user, selected]) => {
								console.log('USER', user);
								const schedulerEditStates = user?.user?.edit_states;
								const paymentstates = [26, 27, 50, 51, 52, 66, 96];
								const jobState = selected?.state;
								if (schedulerEditStates.includes(jobState) || paymentstates.includes(jobState)) {
									_controller.dispatch(new SetNextNode('ChangeAppointment'));
								} else {
									console.log('thi here in the modal ', schedulerEditStates, jobState);
									modal.openModalDirectly((_inst: any, _store: Store, _bf: BigFormService) => {
										// _inst.setHeading(`State ${jobState} is a team leader state!`);
										_inst.type = 'warning';
										// _inst.heading = 'Hey man!';
										_inst.setMessage([
											'You cannot change an appointment for this job as it is already in the hands',
											'of the Team Lead. Please request that the Team Lead change the appointment.'
										]);
										_inst.navButtons = [
											{
												text: 'OK',
												clickHandler: () => {
													_inst.router.navigate(['/workflow']);
												},
												linkType: 'close'
											}
										];
									});
								}
							})
						)
						.subscribe();
				}
			},
			navs: []
		},
		ChangeAppointment: {
			component: 'FLXJobAppointmentComponent',
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(null),
						skillcatagory: new UntypedFormControl(null)
					})
				);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skillcatagory
										skill
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(x => !x || !x.skill || !x.skillcatagory),
						take(1)
					)
					.subscribe(({ skill, skillcatagory }) => {
						const apd = bf.getControl('appointmentData');
						if (apd) {
							apd.get('skill')?.setValue(skill);
							apd.get('skillcatagory')?.setValue(skillcatagory);
						}
					});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f || !f.state),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						repingSP: {
							errorMessage: 'An error occurred while changing the job appointment',
							serviceVariable: 'spService',
							functionName: 'changeJobAppointmentFromContextMenu'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	}
};

// import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
// import { map, skipWhile, take } from 'rxjs/operators';
// import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
// import gql from 'graphql-tag';

// export const PGG_SP_CHANGE_APPOINTMENT: Flow_0_0_2 = {
// 	id: 'ChangeAppointment',
// 	itemType: 'flow',
// 	excludeForOnlyStates: [226],
// 	name: 'Change Appointment',
// 	header: {
// 		title: (store: any) => {
// 			return store.select(getFullItemTwo).pipe(
// 				map((itemTwo: any) => {
// 					if (itemTwo) {
// 						return `Change appointment : ${itemTwo?.claim?.loan_information?.voucher_key}  -  ${itemTwo?.claim?.applicant?.first_name}`;
// 					} else {
// 						return 'Change appointment';
// 					}
// 				})
// 			);
// 		},
// 		controls: () => () => []
// 	},
// 	footer: {
// 		type: 'node_nav'
// 	},
// 	startNode: 'ChangeAppointment',
// 	instructions: {
// 		editRoles: {
// 			0: ''
// 		},
// 		viewRoles: {
// 			0: ''
// 		}
// 	},
// 	actionPanel: instance => {
// 		return () => {
// 			return [{}];
// 		};
// 	},
// 	nodes: {
// 		ChangeAppointment: {
// 			component: 'FLXJobAppointmentComponent',
// 			inputs: {
// 				minDate: new Date()
// 			},
// 			initFormFields: (bf: any, item: any, instance: any, sq: any) => {
// 				bf.addControl(
// 					'appointmentData',
// 					new UntypedFormGroup({
// 						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
// 						appointmentTime: new UntypedFormControl(null, [Validators.required]),
// 						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
// 						appointmentdatetype: new UntypedFormControl(null),
// 						skill: new UntypedFormControl(null),
// 						skillcatagory: new UntypedFormControl(null)
// 					})
// 				);
// 				sq.queryStore(
// 					gql`
// 						{
// 							selectedContext {
// 								fullItemTwo {
// 									office_use {
// 										skillcatagory
// 										skill
// 									}
// 								}
// 							}
// 						}
// 					`
// 				)
// 					.pipe(
// 						skipWhile((x: any) => !x || !x.skill || !x.skillcatagory),
// 						take(1)
// 					)
// 					.subscribe(({ skill, skillcatagory }) => {
// 						const apd = bf.getControl('appointmentData');
// 						if (apd) {
// 							apd.get('skill')?.setValue(skill);
// 							apd.get('skillcatagory')?.setValue(skillcatagory);
// 						}
// 					});
// 			},
// 			serverCalls: {
// 				customer_details: {
// 					errorMessage: 'No customer contact details could be found!',
// 					directCall: (http, store, sq) => {
// 						return sq
// 							.queryObject(
// 								gql`
// 									{
// 										fullItemTwo {
// 											claim {
// 												applicant {
// 													first_name
// 												}
// 												loan_information {
// 													onsitecontact
// 												}
// 											}
// 										}
// 									}
// 								`,
// 								store.select(getFullItemTwo).pipe(
// 									skipWhile((f: any) => !f || !f.state),
// 									take(1),
// 									map((res: any) => ({ fullItemTwo: res }))
// 								)
// 							)
// 							.pipe(
// 								map((queryData: any) => {
// 									return [{ 'Client Name': `${queryData.first_name}` }, { 'Contact Number': `${queryData.onsitecontact}` }, { 'Mobile Number': `${queryData.onsitecontact}` }];
// 								})
// 							);
// 					}
// 				},
// 				onsite_details: {
// 					errorMessage: 'No onsite details could be found!',
// 					directCall: (http: any, store: any, sq: any) => {
// 						return sq
// 							.queryObject(
// 								gql`
// 									{
// 										fullItemTwo {
// 											claim {
// 												loan_information {
// 													onsiteperson
// 													onsitecontact
// 												}
// 											}
// 										}
// 									}
// 								`,
// 								store.select(getFullItemTwo).pipe(
// 									skipWhile((f: any) => !f),
// 									take(1),
// 									map(res => ({ fullItemTwo: res }))
// 								)
// 							)
// 							.pipe(
// 								map((queryData: any) => {
// 									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
// 								})
// 							);
// 					}
// 				},
// 				appointmentTypes: {
// 					serviceVariable: 'service',
// 					functionName: 'getAppointmentTypes',
// 					errorMessage: 'No Appointment Types could be found!'
// 				}
// 			},
// 			navs: [
// 				{
// 					text: 'Continue',
// 					linkType: 'submit',
// 					color: 'primary',
// 					nextNode: 'SubmissionSuccess',
// 					serverFirst: true,
// 					serverCalls: {
// 						repingSP: {
// 							errorMessage: 'An error occurred while changing the job appointment',
// 							serviceVariable: 'spService',
// 							functionName: 'changeJobAppointmentFromContextMenu'
// 						}
// 					}
// 				}
// 			]
// 		},
// 		SubmissionSuccess: {
// 			component: 'FLXSuccessTickComponent'
// 		}
// 	}
// };
