import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { BET_71 } from './BET_71';
import { environment } from 'apps/studio/src/environments/environment';

export const BET_228: Flow_0_0_2 = {
	id: '228',
	name: 'claim_repudiated_send_letter',
	itemType: 'flow',
	fetchLevel1And2: true,
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map(itemOne => {
					if (itemOne) {
						return `Repudiated - Agent to Send Letter : ${itemOne?.loan_information?.mavenclaimnumber ? itemOne?.loan_information?.mavenclaimnumber : itemOne?.mid} - ${
							itemOne.applicant?.surname
						}`;
					} else {
						return 'Repudiated - Agent to Send Letter';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp']),
	serverCalls: {
		...BET_71.serverCalls
	},
	instructions: {
		editRoles: {
			0: 'Repudiated - Agent to Send Letter'
		},
		viewRoles: {
			0: 'Repudiated - Agent to Send Letter'
		}
	},
	startNode: 'RepudiationDetails',
	nodes: {
		...BET_71?.nodes,
		RepudiationDetails: {
			...BET_71?.nodes?.RepudiationDetails,
			navs: [
				{
					text: 'Repudiation Letter Sent',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						changeClaimState: {
							errorMessage: 'Unable to change the claim state',
							directCall: (http, store, sq, bf) => {
								return store.select(getFullItemOne).pipe(
									skipWhile(x => !x),
									take(1),
									switchMap(claim => {
										// need to change the state to 55 if repudiation was upfront
										const { loan_information } = claim;
										let canFinalise = false;
										if (!!claim && !!claim?.loan_information.upfrontrepudiation) {
											const val = `${claim?.loan_information.upfrontrepudiation}`?.toLowerCase();
											canFinalise = val === 'true' || '1' ? true : false;
										}
										if (canFinalise) {
											return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
												claim_id: claim?.id,
												new_state: 55,
												loan_information
											});
										} else {
											return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
												claim_id: claim?.id,
												new_state: 173,
												loan_information
											});
										}
									})
								);
							}
						}
					}
				}
			]
		},
		ClaimDetails: {
			...BET_71?.nodes?.ClaimDetails,
			navs: [
				{
					text: 'Repudiation Letter Sent',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						changeClaimState: {
							errorMessage: 'Unable to change the claim state',
							directCall: (http, store, sq, bf) => {
								return store.select(getFullItemOne).pipe(
									skipWhile(x => !x),
									take(1),
									switchMap(claim => {
										// need to change the state to 55 if repudiation was upfront
										const { loan_information } = claim;
										let canFinalise = false;
										if (!!claim && !!claim?.loan_information.upfrontrepudiation) {
											const val = `${claim?.loan_information.upfrontrepudiation}`?.toLowerCase();
											canFinalise = val === 'true' || '1' ? true : false;
										}
										if (canFinalise) {
											return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
												claim_id: claim?.id,
												new_state: 55,
												loan_information
											});
										} else {
											return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
												claim_id: claim?.id,
												new_state: 173,
												loan_information
											});
										}
									})
								);
							}
						}
					}
				}
			]
		},
		JobInformation: {
			...BET_71?.nodes?.JobInformation,
			navs: [
				{
					text: 'Repudiation Letter Sent',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						changeClaimState: {
							errorMessage: 'Unable to change the claim state',
							directCall: (http, store, sq, bf) => {
								return store.select(getFullItemOne).pipe(
									skipWhile(x => !x),
									take(1),
									switchMap(claim => {
										// need to change the state to 55 if repudiation was upfront
										const { loan_information } = claim;
										let canFinalise = false;
										if (!!claim && !!claim?.loan_information.upfrontrepudiation) {
											const val = `${claim?.loan_information.upfrontrepudiation}`?.toLowerCase();
											canFinalise = val === 'true' || '1' ? true : false;
										}
										if (canFinalise) {
											return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
												claim_id: claim?.id,
												new_state: 55,
												loan_information
											});
										} else {
											return http.post<any>(environment.api_url + `v1/claim_action/update_claim`, {
												claim_id: claim?.id,
												new_state: 173,
												loan_information
											});
										}
									})
								);
							}
						}
					}
				}
			]
		}
	},
	bigFormToStoreMapper: {
		customer_call: 'office_use.repudiation.customer_call',
		repudiation_type: 'office_use.repudiation.repudiation_type',
		repudiation_letter: 'office_use.repudiation.repudiation_letter',
		email: 'office_use.repudiation.emailaddress',
		new_state: 'new_state'
	}
};
