import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of, empty } from 'rxjs';
import * as IdentityActions from './identity.actions';
import { LocalAuthenticationService } from '../services/authentication.service';
import { Store } from '@ngrx/store';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { IDENTITY_CONFIG, IdentityConfig } from '../services/auth.constants';
import { BigFormService } from '../services/big-form.service';
import { GetAllInfo } from '../all-info/all-info.action';
import { ChangeManifestState } from '../manifest/manifest.actions';
import { ManifestController } from '../controllers';
import { CryptoService, ENVIRONMENT, IndexedDbService, ReminderService } from '../services';
import { clearReminders } from '../reminders';

@Injectable()
export class IdentityEffects {
	constructor(
		private actions$: Actions,
		private router: Router,
		private authenticationService: LocalAuthenticationService,
		private indexedDbService: IndexedDbService,
		private cryptoService: CryptoService,
		@Inject(IDENTITY_CONFIG) private identityConfig: IdentityConfig,
		@Inject(ENVIRONMENT) private environment: any,
		private store: Store<any>,
		private reminderService: ReminderService,
		private bf: BigFormService,
		private http: HttpClient,
		private controller: ManifestController<any>
	) {
		// console.log(this.identityConfig.after_login_url);
	}

	forgotPassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType<IdentityActions.ForgotPassword>(IdentityActions.IdentityActionTypes.FORGOT_PASSWORD),
			switchMap(action => {
				const email = action.payload.email;
				return this.authenticationService.sendResetLink(email).pipe(
					map(data => {
						return new IdentityActions.ForgotPasswordSuccess(data);
					}),
					catchError(err =>
						of(
							new IdentityActions.ForgotPasswordFail({
								...err,
								dataKey: 'forgotPassword',
								errorMessage: err?.error?.reason
							})
						)
					)
				);
			})
		)
	);

	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType<IdentityActions.Login>(IdentityActions.IdentityActionTypes.LOGIN),
			switchMap(action => {
				const credentials = action.payload;
				return this.authenticationService.login(credentials).pipe(
					switchMap(res => {
						if (res && res?.success === true) {
							const jwtHelper = new JwtHelperService();
							const jwtPack = jwtHelper.decodeToken(res?.user.token);
							const { email, token, staff_member } = res?.user;
							localStorage.setItem('flexus.web.jwtToken', res?.user.token);

							// SET AUTH METHOD OF RESPONSE
							localStorage.setItem('flexus.web.authMethod', 'local');
							if (!staff_member) {
								return this.http
									.post(
										`${this.environment.api_url}v1/staff_action/get_staffmember/`,
										{ staff_id: jwtPack?.user?.id },
										{
											headers: {
												'Content-Type': 'application/json',
												Accept: 'application/json',
												Authorization: `Token ${localStorage.getItem('flexus.web.jwtToken')}`
											}
										}
									)
									.pipe(
										map((response: any) => response.payload),
										map(
											_staff_member =>
												new IdentityActions.LoginSuccess({
													email,
													token,
													...jwtPack,
													user: { ..._staff_member, ...jwtPack.user }
												})
										)
									);
							}
							return of(
								new IdentityActions.GetLoggedInUserSuccess({
									email,
									token,
									...jwtPack,
									user: { ...staff_member, ...jwtPack.user }
								})
							);
						} else {
							return of(new IdentityActions.LoginFail({ ...res, dataKey: 'login' }));
						}
					}),
					catchError(err => {
						console.log({ err });
						if (err?.name === 'TimeoutError') {
							return of(
								new IdentityActions.LoginFail({
									...err,
									dataKey: 'login',
									errorMessage: `${err?.message}. Server may be down.`
								})
							);
						} else {
							return of(
								new IdentityActions.LoginFail({
									...err,
									dataKey: 'login',
									errorMessage: err?.error?.reason ?? ''
								})
							);
						}
					})
				);
			})
		)
	);

	Azurelogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType<IdentityActions.AzureLogin>(IdentityActions.IdentityActionTypes.AZURE_LOGIN),
			switchMap(action => {
				const res = action.payload;
				if (res && res?.success === true) {
					const jwtHelper = new JwtHelperService();
					const jwtPack = jwtHelper.decodeToken(res?.user.token);
					const { email, token } = res?.user;
					localStorage.setItem('flexus.web.jwtToken', res?.user.token);

					// SET AUTH METHOD OF RESPONSE
					localStorage.setItem('flexus.web.authMethod', 'azuread');
					return this.http
						.post(
							`${this.environment.api_url}v1/staff_action/get_staffmember/`,
							{ staff_id: jwtPack?.user?.id },
							{
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: `Token ${localStorage.getItem('flexus.web.jwtToken')}`
								}
							}
						)
						.pipe(
							map((response: any) => response.payload),
							map(
								staff_member =>
									new IdentityActions.LoginSuccess({
										email,
										...jwtPack,
										token: localStorage.getItem('flexus.web.jwtToken'),
										user: { ...staff_member, ...jwtPack.user }
									})
							)
						);
				} else {
					return of(new IdentityActions.LoginFail({ ...res, dataKey: 'login' }));
				}
			}),
			catchError(err => {
				console.log({ err });
				if (err?.name === 'TimeoutError') {
					return of(
						new IdentityActions.LoginFail({
							...err,
							dataKey: 'login',
							errorMessage: `${err?.message}. Server may be down.`
						})
					);
				} else {
					return of(
						new IdentityActions.LoginFail({
							...err,
							dataKey: 'login',
							errorMessage: err?.error?.reason ?? ''
						})
					);
				}
			})
		)
	);

	navigateToDashboard = createEffect(() =>
		this.actions$.pipe(
			ofType<IdentityActions.LoginSuccess>(IdentityActions.IdentityActionTypes.LOGIN_SUCCESS),
			map(action => {
				this.indexedDbService.user.get('id').then(oldUserID => {
					this.reminderService.activateReminders();
					if (action.payload.user && oldUserID !== action.payload.user.id) {
						// if different user logged in reset user info
						this._clearUserSpecificData();
						this.indexedDbService.user.put(action.payload.user.id, 'id');
					}
				});
				this.router.navigate([this.identityConfig.after_login_url]);
			}),
			map(data => {
				const { api_urls, client } = this.environment;
				const api_urls_arr = Object.values(api_urls);
				if (client === 'amp' && api_urls_arr && api_urls_arr.length !== 0) {
					return new GetAllInfo([
						`${this.environment.api_urls['amp']?.url}${this.environment.api_urls['amp']?.version}all_info/`,
						`${this.environment.api_urls['amyth']?.url}${this.environment.api_urls['amyth']?.version}all_info/fetch/`
					]);
				}

				return new GetAllInfo(`${this.environment.api_url}v1/all_info/`);
			})
		)
	);

	checkLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType<IdentityActions.GetLoggedInUser>(IdentityActions.IdentityActionTypes.GET_LOGGED_IN_USER),
			switchMap(action => {
				return this.authenticationService?.getLoggedInUserFromServer(action.payload).pipe(
					mergeMap(data => {
						const jwtHelper = new JwtHelperService();
						const jwtPack = jwtHelper.decodeToken(data?.token);
						const { email, token, staff_member } = data;
						localStorage.setItem('flexus.web.jwtToken', token);
						if (
							this.authenticationService?.userIsAuthenticated &&
							(this.environment.client !== 'amp' || (this.environment.client === 'amp' && localStorage.getItem(this.environment.api_urls['amyth']?.tokenName)))
						) {
							// if (this.authenticationService?.userIsAuthenticated) {
							if (!staff_member) {
								return this.http
									.post(
										`${this.environment.api_url}v1/staff_action/get_staffmember/`,
										{ staff_id: jwtPack?.user?.id },
										{
											headers: {
												'Content-Type': 'application/json',
												Accept: 'application/json',
												Authorization: `Token ${token}`
											}
										}
									)
									.pipe(
										map((response: any) => response.payload),
										map(_staff_member => {
											return new IdentityActions.GetLoggedInUserSuccess({
												email,
												token,
												...jwtPack,
												user: { ..._staff_member, ...jwtPack?.user }
											});
										})
									);
							}
							return of(
								new IdentityActions.GetLoggedInUserSuccess({
									email,
									token,
									...jwtPack,
									user: { ...staff_member, ...jwtPack?.user }
								})
							);
						} else {
							return empty();
						}
					}),
					catchError((err: any) => of(new IdentityActions.LoginFail({ ...err, dataKey: 'login', errorMessage: err?.error?.reason })))
				);
			})
		)
	);

	getUserSilently$ = createEffect(() =>
		this.actions$.pipe(
			ofType<IdentityActions.GetLoggedInUserSilently>(IdentityActions.IdentityActionTypes.GET_LOGGED_IN_USER_SILENTLY),
			switchMap((action: any) => {
				return this.authenticationService.getLoggedInUserFromServerSilently(action.payload).pipe(
					switchMap((data: any) => {
						// console.log('in loggedInUserEffect', data);
						const jwtHelper = new JwtHelperService();
						const jwtPack = jwtHelper.decodeToken(data?.token);
						const { email, token } = data;
						localStorage.setItem('flexus.web.jwtToken', token);
						if (this.authenticationService.userIsAuthenticated) {
							return this.http
								.post(
									`${this.environment.api_url}v1/staff_action/get_staffmember/`,
									{ staff_id: jwtPack?.user?.id },
									{
										headers: {
											'Content-Type': 'application/json',
											Accept: 'application/json',
											Authorization: `Token ${token}`
										}
									}
								)
								.pipe(
									map((response: any) => response?.payload),
									map(
										staff_member =>
											new IdentityActions.GetLoggedInUserSuccessSilently({
												email,
												token,
												...jwtPack,
												user: { ...staff_member, ...jwtPack?.user }
											})
									)
								);
						} else {
							return empty();
						}
					}),
					catchError(err => of(new IdentityActions.LoginFail({ ...err, dataKey: 'login', errorMessage: err?.error?.reason })))
				);
			})
		)
	);

	navigateTo$ = createEffect(() =>
		this.actions$.pipe(
			ofType<IdentityActions.GetLoggedInUserSuccess>(IdentityActions.IdentityActionTypes.GET_LOGGED_IN_USER_SUCCESS),
			map(action => {
				if (!this.identityConfig.no_auth_urls.includes(this.router.url)) {
					this.router.navigate([this.identityConfig.after_login_url]);
				}
				const api_urls = Object.values(this.environment.api_urls);
				if (api_urls && api_urls.length > 1) {
					const urls: string[] = [];
					api_urls.forEach((_url: any) => {
						urls.push(`${_url.url}${_url.version}${_url.allInfo}`);
					});
					return new GetAllInfo(urls);
				}
				return new GetAllInfo(`${this.environment.api_url}v1/all_info/`);
			})
		)
	);

	logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType<IdentityActions.LogOut>(IdentityActions.IdentityActionTypes.LOGOUT),
			map(() => {
				this.bf.bigForm.reset();
				this.controller.dispatch(
					new ChangeManifestState(state => ({
						...state,
						activeManifestItemId: 0,
						activeManifestItem: null,
						activeNode: null,
						viewData: {},
						activeViewData: null,
						navigationStack: []
					}))
				);
				this.indexedDbService.currentItem.delete('currentItem');
				this.authenticationService.logout();
				this.store.dispatch(clearReminders());
				// this.indexedDbService.currentEditableClaim.clear()
				return new IdentityActions.LogOutSuccess();
			})
		)
	);

	private _clearUserSpecificData() {
		// clear user specific scratchpad data
		this.indexedDbService.scratch_pad.delete('scratch_pad').catch();
	}
}
