import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { UploadInput } from 'ngx-uploader';
import { BehaviorSubject, Observable } from 'rxjs';
import { returnOrDefault } from '@flexus/utilities';
import { ERSingleFileSelectConfig, ERSingleFileSelectEvent, FileItem, FileQueue, FileSizeUnit } from '../models';
import { compareNumberToSize } from '../file_utils';
import { BigFormService } from '@flexus/core';

// compact file select
@Component({
	selector: 'flx-compact-file-select',
	templateUrl: './compact-file-select.component.html',
	styleUrls: ['./compact-file-select.component.css']
})
export class FLXCompactFileSelectComponent implements OnInit {
	uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
	dragOver = false;
	data$: Observable<void>;
	private _config: ERSingleFileSelectConfig;
	extension;

	errorSubject = new BehaviorSubject<string>(null);

	@Output() outputData: EventEmitter<any> = new EventEmitter();
	@Output() outputB64 = new EventEmitter<string>();

	@Input() set config(c: ERSingleFileSelectConfig) {
		if (!c.allowedFileTypes) {
			c.allowedFileTypes = ['pdf', 'image'];
		}
		if (!c.maxFileSize) {
			c.maxFileSize = { size: 10, unit: FileSizeUnit.MegaBytes };
		}
		this._config = c;
	}
	get config() {
		return returnOrDefault(this._config, {
			allowedFileTypes: ['pdf', 'image'],
			maxFileSize: { size: 5, unit: FileSizeUnit.MegaBytes }
		});
	}

	constructor(private bf: BigFormService, private cdr: ChangeDetectorRef) {}

	ngOnInit() {}

	checkFileAllowedTypes(f: File, allowedTypes: string[]): boolean {
		const type = f.type;
		for (const allowedType of allowedTypes) {
			if (new RegExp(allowedType).test(type) === true) {
				return true;
			}
		}
		return false;
	}

	getFileFromDropEvent(e: DragEvent) {
		this.dragOver = false;
		const selectedFile = e.dataTransfer.files[0];
	}

	processFile(selectedFile: File) {
		if (!!selectedFile) {
			if (this.checkFileAllowedTypes(selectedFile, this.config.allowedFileTypes) === true) {
				if (compareNumberToSize(selectedFile.size, this.config.maxFileSize) > 0) {
					this.outputData.emit({
						type: 'fileRejected',
						file: selectedFile,
						reason: `Wrong size please upload a file smaller than ${this.config.maxFileSize.size + this.config.maxFileSize.unit}`
					});
				} else {
					this.encodeImageFileAsURL({ type: 'fileAdded', file: selectedFile });
				}
			} else {
				this.outputData.emit({ type: 'fileRejected', file: selectedFile });
			}
			this.uploadInput.emit({ type: 'removeAll' });
		}
	}
	encodeImageFileAsURL(selectedFile: ERSingleFileSelectEvent) {
		const fileReader = new FileReader();
		fileReader.onload = event => {
			const res = (event.target as FileReader).result as string;
			const fileItem: FileItem = {
				nativeFile: selectedFile.file,
				base64: btoa(res),
				type: selectedFile.type
			};
			if (selectedFile.type === 'fileAdded') {
				const outputB64string = fileItem.base64;
				const decoded = atob(outputB64string);
				const firstpos = decoded.indexOf(',') + 1;
				const imagestring = decoded.slice(firstpos);

				this.outputB64.emit(imagestring);
				this.updateFileQueue({ file: fileItem, fileKey: 'file' });
				return fileItem;
			} else if ((selectedFile.type = 'fileRejected')) {
				this.errorSubject.next(`File Rejected : ${selectedFile.reason}`);
				return;
			}
		};
		fileReader.readAsDataURL(selectedFile.file);
	}

	updateFileQueue(file: FileQueue) {
		const queue = this.bf.bigForm.get('fileQueue')?.value as Array<FileQueue>;
		queue.push(file);
		this.bf.bigForm.get('fileQueue')?.setValue(queue);

		this.cdr.detectChanges();
	}

	get fileQueue(): Array<FileQueue> {
		return this.bf.bigForm.get('fileQueue')?.value as Array<FileQueue>;
	}

	getFileFromInputSelection(e: Event): void {
		const selectedFile = (e.target as HTMLInputElement)?.files[0];
		this.processFile(selectedFile);
	}

	ngOnDestroy(): void {}
}
