import * as fromActionPanel from './action-panel.actions';

export interface ActionPanelState {
	actionPanelExpanded: boolean;
	actionPanelVisible: boolean;
	notifications: any[];
	additionalActionPanelExpanded: boolean;
	additionalActionPanelVisible: boolean;
	componentToLoad: string;
}

export const initialState: ActionPanelState = {
	actionPanelExpanded: false,
	actionPanelVisible: false,
	notifications: [],
	additionalActionPanelExpanded: false,
	additionalActionPanelVisible: false,
	componentToLoad: ''
};

export function reducer(state = initialState, action): ActionPanelState {
	switch (action.type) {
		// Expand Sidebar
		case fromActionPanel.actionPanelActionTypes.EXPAND_ACTION_PANEL: {
			return {
				...state,
				actionPanelExpanded: true,
				actionPanelVisible: true
			};
		}

		// Collapse Sidebar
		case fromActionPanel.actionPanelActionTypes.COLLAPSE_ACTION_PANEL: {
			return {
				...state,
				actionPanelExpanded: false,
				actionPanelVisible: true,
				componentToLoad: ''
			};
		}

		case fromActionPanel.actionPanelActionTypes.SHOW_ACTION_PANEL: {
			return {
				...state,
				actionPanelVisible: true
			};
		}

		case fromActionPanel.actionPanelActionTypes.HIDE_ACTION_PANEL: {
			return {
				...state,
				actionPanelVisible: false
			};
		}

		//Additional sidebar
		case fromActionPanel.actionPanelActionTypes.EXPAND_ADDITIONAL_ACTION_PANEL: {
			return {
				...state,
				additionalActionPanelExpanded: true,
				additionalActionPanelVisible: true
			};
		}
		case fromActionPanel.actionPanelActionTypes.COLLAPSE_ADDITIONAL_ACTION_PANEL: {
			return {
				...state,
				additionalActionPanelExpanded: false,
				additionalActionPanelVisible: true
			};
		}

		case fromActionPanel.actionPanelActionTypes.SHOW_ADDITIONAL_ACTION_PANEL: {
			return {
				...state,
				additionalActionPanelVisible: true
			};
		}

		case fromActionPanel.actionPanelActionTypes.HIDE_ADDITIONAL_ACTION_PANEL: {
			return {
				...state,
				additionalActionPanelVisible: false
			};
		}

		// case websocketActions.NOTIFICATIONS_FROM_WEBSOCKET: {
		//   const notification = action.payload.address;
		//   return {
		//     ...state,
		//     notifications: [...state.notifications, notification],
		//   };
		// }
		case fromActionPanel.actionPanelActionTypes.LOAD_ACTION_PANEL_COMPONENT: {
			return {
				...state,
				componentToLoad: action.payload
			};
		}
		case 'LOGOUT_SUCCESS': {
			return initialState;
		}
		default:
			return {
				...state
			};
	}
}
