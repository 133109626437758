import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getSelectedItem } from '@flexus/core';
import { CustomValidators } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import { CollapseActionPanel, setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { skipWhile, take, map, switchMap } from 'rxjs/operators';
import { customerQueryNode } from '../reusable/CUSTOMER_REFUND_QUERY';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { PGG_BANK_DETAILS } from './BANK_DETAILS';

export const PGG_236: Flow_0_0_2 = {
	id: '236',
	name: 'awaiting_replacement_stock',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Awaiting Replacement Stock';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		voucherAmount: {
			errorMessage: `Couldn't get voucher amount`,
			directCall: (_htp: HttpClient, _store: Store, _sq: any) => {
				return _sq
					.queryObject(
						gql`
							{
								fullItemTwo {
									claim {
										loan_information {
											values {
												total_value
												client_value
												sp_value
											}
										}
									}
								}
							}
						`,
						_store.select(getFullItemTwo).pipe(
							skipWhile((fit: any) => !fit),
							take(1),
							map((res: any) => ({ fullItemTwo: res }))
						)
					)
					.pipe(
						map((querydata: any) => {
							const total_value: number = +querydata?.total_value;
							const client_value: number = +querydata?.client_value;
							const sp_value: number = +querydata?.sp_value;
							let valueobject: any = {
								total_value: total_value,
								client_value: client_value,
								sp_value: sp_value
							};

							return valueobject;
						})
					);
			}
		}
	},
	instructions: {
		editRoles: {
			0: 'Awaiting replacement stock query'
		},
		viewRoles: {
			0: 'Awaiting replacement stock query'
		}
	},
	startNode: 'AwaitingReplacementStockNotification',
	nodes: {
		AwaitingReplacementStockNotification: {
			initFormFields: (bf: any) => {
				bf.addControl('no_refund', new UntypedFormControl(false, [CustomValidators.contains(['refund'])]));
			},
			checkValidityForFields: ['no_refund'],
			...jobInfoNode(236, 'Damaged Items', ['The customer has not acknowledged that the stock is damaged.', 'Contact the customer to determine the current status.']),
			navs: [
				{
					text: 'cancel installation',
					optIntoValidation: true,
					color: 'secondary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any) => {
							instance.type = 'warning';
							instance.heading = 'Confirmation of Voucher Cancellation';
							instance.setMessage(['Please confirm with customer that they want to cancel the installation so that a refund can take place']);
							instance.navButtons = [
								{
									text: 'BACK',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'CONFIRM',
									color: 'secondary',
									linkType: 'nextNode',
									nextNode: 'FakeModal'
								}
							];
						}
					}
				},
				{
					text: 'replacement stock arrived',
					color: 'default',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any, store: any) => {
							instance.type = 'warning';
							instance.heading = 'Confirmation of Replacement Stock';
							instance.setMessage([
								'Please confirm that the replacement stock has arrived and is with the customer.',
								'Please notify the customer that the installer will be in contact',
								'to arrange the new appointment.'
							]);
							instance.navButtons = [
								{
									text: 'back',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'confirm',
									color: 'secondary',
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										response: {
											errorMessage: `Couldn't update job with replacment stock`,
											directCall: (http: any, store: any, sq: any, bf: any) => {
												bf.addControl('new_state', new UntypedFormControl(null, [Validators.required]));
												bf.patchValues({ new_state: 201 });
												const new_state = bf.getControl('new_state').value;
												return store
													.select(getSelectedItem)
													.pipe(
														skipWhile((x: any) => !x),
														take(1),
														map((response: any) => response?.id)
													)
													.pipe(
														switchMap((jobid: any) => {
															const data = {
																job_id: jobid,
																new_state: new_state
															};
															return http.post(`${environment.api_url}v1/job_action/update_job`, data);
														})
													);
											}
										}
									}
								}
							];
						}
					}
				}
			]
		},
		FakeModal: {
			component: 'PingGoFakeModalComponent'
		},
		RefundBreakdown: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Refund breakdown',
							instructions: ['Please confirm expected refund amount with customer']
						}
					},
					{
						component: 'PinggoRefundBreakdownComponent',
						inputs: {
							subheading: 'Please confirm expected refund amount with customer',
							voucherAmount$: 'voucherAmount'
						}
					}
				]
			},
			navs: [
				{
					text: 'query refund',
					color: 'secondary',
					nextNode: 'CustomerQuery'
				},
				{
					text: 'continue',
					nextNode: 'BankDetailsCapture',
					color: 'primary'
				}
			]
		},
		BankDetailsCapture: {
			initFormFields: (bf: any) => {
				bf.addControl('new_state', new UntypedFormControl('', [Validators.required]));
				bf.patchValues({ new_state: 269 }); // find correct  state here
			},
			component: 'PggBankDetailsTemplateComponent',
			...PGG_BANK_DETAILS
		},
		CustomerQuery: {
			checkValidityForFields: ['refund_query'],
			initFormFields: (bf: any) => {
				bf.addControl('refund_query', new UntypedFormControl('', [Validators.required]));
			},
			...customerQueryNode(236),
			navs: [
				{
					text: 'submit',
					optIntoValidation: true,
					serverCalls: {
						response: {
							errorMessage: `Couldn't update job with customer query`,
							serviceVariable: 'pggService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		refund_query: [
			(refundquery: any) => {
				return refundquery;
			},
			'job_information.refund_query'
		]
	}
};
