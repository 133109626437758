import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'fsIsArray'
})
export class FLXIsArrayPipe implements PipeTransform {
	transform(value: any[]): boolean {
		return Array.isArray(value);
	}
}
