import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getAllInfo, getCurrentUser, getFullItemTwo, getSelectedItemOne } from '@flexus/core';
import gql from 'graphql-tag';
import { combineLatest, EMPTY, forkJoin } from 'rxjs';
import { filter, map, pluck, skipWhile, take } from 'rxjs/operators';
import { values } from 'ramda';
import { getInterstateComments } from '../reusable/DATA_MANIPULATION';
import { KVLHeading } from '@flexus/ui-elements';

export const SIL_276: Flow_0_0_2 = {
	id: '276',
	name: 'cash_in_lieu_query_reply',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Cash In Lieu Query Reply'
		},
		viewRoles: {
			0: 'Cash In Lieu - Query'
		}
	},
	serverCalls: {
		dataFiles: {
			serviceVariable: 'silService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		files: {
			serviceVariable: 'silService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		notes: {
			serviceVariable: 'silService',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No claim notes could be found!'
		}
	},

	startNode: 'Query',
	nodes: {
		Query: {
			showTabs: true,
			component: {
				children: [
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'important_infor',
							heading: 'Query Summary',
							color: 'secondary',
							colouredHeading: new KVLHeading('Cash in lieu query response', 'secondary')
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'customerDetails', heading: 'Customer Details' }
					}
				]
			},
			serverCalls: {
				important_infor: {
					errorMessage: 'Cannot get job info summary',
					directCall: (http, store) => {
						return forkJoin([
							store.select(getFullItemTwo)?.pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getAllInfo)?.pipe(
								skipWhile(x => !x),
								take(1)
							)
						])?.pipe(
							take(1),
							map(([job, allinfo]) => {
								const { job_information } = job;
								const list: any = [];

								if (job_information.interstate_comments) {
									const comments = Array.isArray(job_information.interstate_comments) ? [...job_information.interstate_comments] : [job_information.interstate_comments];
									const arr = comments.filter(comment => comment.nextState && parseInt(comment.nextState, 10) === 276);
									const obj = arr[arr?.length - 1];

									if (obj) {
										const message = obj.message ? obj.message : '';

										list.push({
											'Message from previous state': message
										});
									}
								}

								return list;
							})
						);
					}
				},
				customerDetails: {
					errorMessage: 'Cannot get job info summary',
					directCall: (http, store) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								take(1),
								map(info => {
									const { claim } = info;
									const list: any = [];
									// Customer details
									if (claim?.applicant) {
										list.push({
											full_name: claim?.applicant?.first_name + ' ' + claim?.applicant?.surname,
											email_address: claim?.loan_information?.email ?? claim?.loan_information?.Email ?? '',
											contact_number: claim?.applicant?.contact_number
										});
									}
									return list;
								})
							);
					}
				}
			},
			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ new_state: 107, authorised: 'Y' });
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									id
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(job => {
						bf.patchValues({ job_id: job?.id });
					});
			},
			navs: [
				{ text: 'Decline', nextNode: 'DeclinePayment', color: 'alert' },
				{ text: 'Query', nextNode: 'QueryPayment', color: 'alert' },
				{
					text: 'Approve Payment',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		InvoicePreview: {
			showTabs: true,
			inputs: { inputHeading: 'Invoice Preview' },
			component: {
				children: [
					{
						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: { dataFiles$: 'dataFiles', keyValueList$: 'keyValueList', inputHeading: 'Invoice Preview' }
					}
				]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store, sq) => {
						const invoice$ = sq
							.queryStore(
								gql`
									{
										selectedContext {
											dataFiles
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1)
							);
						return forkJoin([
							store.select(getSelectedItemOne)?.pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getFullItemTwo)?.pipe(
								skipWhile(x => !x),
								take(1)
							),

							invoice$.pipe(
								skipWhile(x => !x),
								pluck('dataFiles'),
								take(1)
							)
						])?.pipe(
							take(1),
							map(([claim, job, invoice]) => {
								const list = {
									Claimant: claim?.applicant ? `${claim?.applicant?.first_name} ${claim?.applicant?.surname}` : '',
									Address: job?.address ?? 'No address found',
									claim_type: job?.claim?.loan_information?.claimtype,
									date_of_loss: job?.claim?.loan_information?.dateofloss,
									'Excess Amount': job?.excess?.length !== 0 ? `R ${job.excess[0]?.amount}` : 'No excess found',
									'Amount due': invoice && invoice.balance_due ? `R ${invoice.balance_due}` : 0,
									'Invoice number': invoice && invoice.invoice_number ? invoice.invoice_number : '',
									'Maven Claim Number': claim ? claim?.mid : ''
								};

								let cloned = JSON.parse(JSON.stringify(list));
								if (job?.supplier_type === 2) {
									const { 'Amount due': deleted, ...rest } = list;
									cloned = rest;
								}

								return [cloned];
							})
						);
					}
				}
			},

			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ new_state: 107, authorised: 'Y' });
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									id
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(job => {
						bf.patchValues({ job_id: job?.id });
					});
			},
			navs: [
				{ text: 'Decline', nextNode: 'DeclinePayment', color: 'alert' },
				{ text: 'Query', nextNode: 'QueryPayment', color: 'alert' },
				{
					text: 'Approve Payment',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		DeclinePayment: {
			hideTabItem: true,
			serverCalls: {
				selectOptions: {
					errorMessage: 'Cannot find decline reasons',
					directCall: (http, store, sq) => {
						return sq
							.queryStore(
								gql`
									{
										allInfo {
											cash_in_lieu_rejection_reasons
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1),
								map(values),
								map(value => value[0]),
								map(vals => ({ declineReasons: vals }))
							);
					}
				}
			},

			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				heading: '',
				formControls: {
					0: {
						label: 'Select reason for declining payment',
						inputType: 'select',
						selectConfig: {
							displayOptions: { displayKey: 'description', valueKey: 'mid' },
							itemsOption: 'declineReasons',
							searchEnabled: false,
							placeHolder: 'Decline Reason'
						},
						formControlName: 'decline_reason'
					},
					1: {
						label: 'Enter note on the reason for declining',
						inputType: 'textarea',
						width: '48%',
						formControlName: 'decline_notes'
					}
				},
				containerWidth: '300px'
			},

			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},

			checkValidityForFields: ['decline_reason'],

			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ new_state: 209, authorised: 'N' });
				bf.bigForm.addControl('decline_notes', new UntypedFormControl('', [Validators.minLength(3)]));
				bf.bigForm.addControl('decline_reason', new UntypedFormControl('', [Validators.required, Validators.minLength(3)]));
			},

			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		QueryPayment: {
			hideTabItem: true,

			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				heading: '',
				formControls: {
					0: {
						label: 'Enter note on why you are quering payment',
						inputType: 'textarea',
						width: '48%',
						formControlName: 'query_notes'
					}
				},
				containerWidth: '300px'
			},

			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},

			checkValidityForFields: ['query_notes'],

			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ new_state: 275, authorised: 'N' });
				bf.bigForm.addControl('query_notes', new UntypedFormControl('', [Validators.minLength(3)]));
				combineLatest([
					store.select(getCurrentUser)?.pipe(filter(x => !!x, take(1))),
					store.select(getFullItemTwo)?.pipe(
						filter(x => !!x),
						take(1)
					)
				])
					.pipe(take(1))
					.subscribe(([user, job]) => {
						bf.patchValues({
							author: user?.user?.id,
							author_name: user?.user?.full_name,
							currentState: job?.state,
							nextState: 275
						});
					});
			},

			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		new_state: 'new_state',
		authorised: 'authorised',
		decline_reason: 'rejection_reason',
		decline_notes: 'rejection_notes',
		query_notes: [(message, storeObj, bf) => getInterstateComments(message, storeObj, bf), 'job_information.interstate_comments'],
		job_id: 'job_id'
	}
};
