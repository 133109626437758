import {
	ChangeDetectionStrategy,
	Component,
	ComponentRef,
	EventEmitter,
	Inject,
	InjectionToken,
	Input,
	NgZone,
	OnDestroy,
	Optional,
	Output,
	ViewContainerRef,
	ViewEncapsulation
} from '@angular/core';

import { DOCUMENT } from '@angular/common';

import { Overlay, OverlayConfig, OverlayRef, PositionStrategy, RepositionScrollStrategy, ScrollStrategy } from '@angular/cdk/overlay';

import { Directionality } from '@angular/cdk/bidi';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ESCAPE } from '@angular/cdk/keycodes';
import { ComponentPortal } from '@angular/cdk/portal';

import { take, filter } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';

import { NativeDateAdapter, DateAdapter, createMissingDateImplError } from './helpers';

// import {Dialog, DialogRef} from '../dialog';

import { DatepickerInputDirective } from './datepicker-input.directive';

import { DatePickerContentComponent } from './datepicker-content/datepicker-content';
import { DialogRef } from '../dialog/helpers';
import { Dialog } from '../dialog/dialog.service';
import { cleanUpSub } from '@flexus/utilities';

/** Used to generate a unique ID for each datepicker instance. */
let datepickerUid = 0;

/** Injection token that determines the scroll handling while the calendar is open. */
export const DATEPICKER_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('flx-datepicker-scroll-strategy');

/** @docs-private */
export function FLX_DATEPICKER_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay: Overlay): () => RepositionScrollStrategy {
	return () => overlay.scrollStrategies.reposition();
}

/** @docs-private */
export const DATEPICKER_SCROLL_STRATEGY_PROVIDER = {
	provide: DATEPICKER_SCROLL_STRATEGY,
	deps: [Overlay],
	useFactory: FLX_DATEPICKER_SCROLL_STRATEGY_PROVIDER_FACTORY
};

@Component({
	selector: 'flx-datepicker',
	template: '',
	exportAs: 'flxDatepicker',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	preserveWhitespaces: false,
	providers: [NativeDateAdapter],
	styleUrls: ['./datepicker.component.scss']
})
export class FLXDatepickerComponent<D> implements OnDestroy {
	/** The date to open the calendar to initially. */
	@Input()
	get startAt(): D | null {
		// If an explicit startAt is set we start there, otherwise we start at whatever the currently
		// selected value is.
		return this._startAt || (this._datepickerInput ? this._datepickerInput.value : null);
	}

	set startAt(date: D | null) {
		this._startAt = this._getValidDateOrNull(this._dateAdapter.deserialize(date));
	}

	private _startAt: D | null;

	/** The view that the calendar should start in. */
	@Input() startView: 'month' | 'year' = 'month';

	/**
	 * Whether the calendar UI is in touch mode. In touch mode the calendar opens in a dialog rather
	 * than a popup and elements have more padding to allow for bigger touch targets.
	 */
	@Input()
	get touchUi(): boolean {
		return this._touchUi;
	}

	set touchUi(value: boolean) {
		this._touchUi = coerceBooleanProperty(value);
	}

	private _touchUi = false;

	/** Whether the datepicker pop-up should be disabled. */
	@Input()
	get disabled(): boolean {
		return this._disabled === undefined && this._datepickerInput ? this._datepickerInput.disabled : this._disabled;
	}

	set disabled(value: boolean) {
		const newValue = coerceBooleanProperty(value);

		if (newValue !== this._disabled) {
			this._disabled = newValue;
			this._disabledChange.next(newValue);
		}
	}

	private _disabled: boolean;

	@Output() readonly selectedChanged: EventEmitter<D> = new EventEmitter<D>();

	/** Classes to be passed to the date picker panel. Supports the same syntax as `ngClass`. */
	@Input() panelClass: string | string[];

	/** Emits when the datepicker has been opened. */
	@Output('opened') openedStream: EventEmitter<void> = new EventEmitter<void>();

	/** Emits when the datepicker has been closed. */
	@Output('closed') closedStream: EventEmitter<void> = new EventEmitter<void>();

	/** Whether the calendar is open. */
	@Input()
	get opened(): boolean {
		return this._opened;
	}

	set opened(shouldOpen: boolean) {
		shouldOpen ? this.open() : this.close();
	}

	private _opened = false;

	/** The id for the datepicker calendar. */
	id = `flx-datepicker-${datepickerUid++}`;

	/** The currently selected date. */
	get _selected(): D | null {
		return this._validSelected;
	}

	set _selected(value: D | null) {
		this._validSelected = value;
	}

	private _validSelected: D | null = null;

	/** The minimum selectable date. */
	get _minDate(): D | null {
		return this._datepickerInput && this._datepickerInput.min;
	}

	/** The maximum selectable date. */
	get _maxDate(): D | null {
		return this._datepickerInput && this._datepickerInput.max;
	}

	get _dateFilter(): (date: D | null) => boolean {
		return this._datepickerInput && this._datepickerInput._dateFilter;
	}

	/** A reference to the overlay when the calendar is opened as a popup. */
	private _popupRef: OverlayRef;

	/** A reference to the dialog when the calendar is opened as a dialog. */
	private _dialogRef: DialogRef<any> | null;

	/** A portal containing the calendar for this datepicker. */
	// private _calendarPortal: ComponentPortal<FLXDatePickerContentComponent<D>>;
	private _calendarPortal: any;

	/** The element that was focused before the datepicker was opened. */
	private _focusedElementBeforeOpen: HTMLElement | null = null;

	private _inputSubscription = Subscription.EMPTY;

	/** The input element this datepicker is associated with. */
	_datepickerInput: DatepickerInputDirective<D>;

	/** Emits when the datepicker is disabled. */
	readonly _disabledChange = new Subject<boolean>();

	constructor(
		private _dialog: Dialog,
		private _overlay: Overlay,
		private _ngZone: NgZone,
		private _viewContainerRef: ViewContainerRef,
		@Inject(DATEPICKER_SCROLL_STRATEGY) private _scrollStrategy,
		@Optional() private _dateAdapter: DateAdapter<D>,
		@Optional() private _dir: Directionality,
		@Optional()
		@Inject(DOCUMENT)
		private _document: any
	) {
		if (!this._dateAdapter) {
			throw createMissingDateImplError('DateAdapter');
		}
	}

	ngOnDestroy() {
		this.close();
		this._inputSubscription.unsubscribe();
		this._disabledChange.complete();

		if (this._popupRef) {
			this._popupRef.dispose();
		}
	}

	/** Selects the given date */
	_select(date: D): void {
		const oldValue = this._selected;
		this._selected = date;
		if (!this._dateAdapter.sameDate(oldValue, this._selected)) {
			this.selectedChanged.emit(date);
		}
	}

	_registerInput(input: DatepickerInputDirective<D>): void {
		if (this._datepickerInput) {
			throw Error('A FLXDatepickerComponent can only be associated with a single input.');
		}

		this._datepickerInput = input;
		this._inputSubscription = this._datepickerInput._valueChange.subscribe((value: D | null) => (this._selected = value));
	}

	/** Open the calendar. */
	open(): void {
		if (this._opened || this.disabled) {
			return;
		}
		if (!this._datepickerInput) {
			throw Error('Attempted to open an FLXDatepickerComponent with no associated input.');
		}
		if (this._document) {
			this._focusedElementBeforeOpen = this._document.activeElement;
		}

		this.touchUi ? this._openAsDialog() : this._openAsPopup();
		this._opened = true;
		this.openedStream.emit();
	}

	/** Close the calendar. */
	close(): void {
		if (!this._opened) {
			return;
		}
		if (this._popupRef && this._popupRef.hasAttached()) {
			this._popupRef.detach();
		}
		if (this._dialogRef) {
			this._dialogRef.close();
			this._dialogRef = null;
		}
		if (this._calendarPortal && this._calendarPortal.isAttached) {
			this._calendarPortal.detach();
		}

		const completeClose = () => {
			if (this._opened) {
				this._opened = false;
				this.closedStream.emit();
				this._focusedElementBeforeOpen = null;
			}
		};

		if (this._focusedElementBeforeOpen && typeof this._focusedElementBeforeOpen.focus === 'function') {
			this._focusedElementBeforeOpen.focus();
			setTimeout(completeClose);
		} else {
			completeClose();
		}
	}

	/** Open the calendar as a dialog. */
	private _openAsDialog(): void {
		this._dialogRef = this._dialog.open(DatePickerContentComponent, {
			viewContainerRef: this._viewContainerRef,
			panelClass: 'flx-datepicker-dialog'
		});

		this._dialogRef.afterClosed().subscribe(() => this.close());
		this._dialogRef.componentInstance.datepicker = this;
	}

	/** Open the calendar as a popup. */
	private _openAsPopup(): void {
		if (!this._calendarPortal) {
			this._calendarPortal = new ComponentPortal(DatePickerContentComponent, this._viewContainerRef);
		}

		if (!this._popupRef) {
			this._createPopup();
		}

		if (!this._popupRef.hasAttached()) {
			const componentRef: ComponentRef<DatePickerContentComponent<D>> = this._popupRef.attach(this._calendarPortal);

			componentRef.instance.datepicker = this;

			// Update the position once the calendar has rendered.
			this._ngZone.onStable
				.asObservable()
				.pipe(take(1))
				.subscribe(() => {
					this._popupRef.updatePosition();
				});
		}
	}

	/** Create the popup. */
	private _createPopup(): void {
		const overlayConfig = new OverlayConfig({
			positionStrategy: this._createPopupPositionStrategy(),
			hasBackdrop: true,
			backdropClass: 'flx-overlay-transparent-backdrop',
			scrollStrategy: this._scrollStrategy(),
			panelClass: 'flx-datepicker-popup'
		});

		this._popupRef = this._overlay.create(overlayConfig);

		merge(this._popupRef.backdropClick(), this._popupRef.detachments(), this._popupRef.keydownEvents().pipe(filter(event => event.keyCode === ESCAPE))).subscribe(() =>
			this.close()
		);
	}

	/** Create the popup PositionStrategy. */
	private _createPopupPositionStrategy(): PositionStrategy {
		const fallbackOffset = this._datepickerInput._getPopupFallbackOffset();

		return this._overlay
			.position()
			.flexibleConnectedTo(this._datepickerInput.getPopupConnectionElementRef())
			.withPositions([
				{
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top'
				},
				{
					originX: 'start',
					originY: 'top',
					overlayX: 'start',
					overlayY: 'bottom',
					offsetY: fallbackOffset
				},
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top'
				},
				{
					originX: 'end',
					originY: 'top',
					overlayX: 'end',
					overlayY: 'bottom',
					offsetY: fallbackOffset
				}
			]);
	}

	private _getValidDateOrNull(obj: any): D | null {
		return this._dateAdapter.isDateInstance(obj) && this._dateAdapter.isValid(obj) ? obj : null;
	}
}
