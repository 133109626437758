import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, fromEvent, merge, of, combineLatest, NEVER } from 'rxjs';
import { skipWhile, take, map, bufferTime, filter, tap, distinctUntilChanged, delay, withLatestFrom, pluck, switchMap, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
	ViewSet_0_0_2,
	SetView,
	getActiveViewData,
	getActiveManifestItem,
	getOrgKey,
	SetActiveManifestItem,
	NavService,
	ModalService,
	ManifestController,
	IndexedDbService,
	BigFormService,
	OfflineService,
	getLoadingState,
	getCurrentUser,
	LogOut,
	getManifestSearchData,
	getActiveOrganization
} from '@flexus/core';
import {  getServerSearchResult, getFilters, getFilterData, SearchOnServer } from '@flexus/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicLoaderComponent } from '@flexus/engine';
import { FilterPipe } from '@flexus/ui-composites';

@Component({
	selector: 'flexus-workflow-shell',
	templateUrl: './bui-workflow-shell.component.html',
	styleUrls: ['./bui-workflow-shell.component.scss']
})
export class BuiWorkflowShellComponent implements OnInit, OnDestroy {
	workflowManifest: ViewSet_0_0_2;

	checkForUpdateSubscription: Subscription;
	itemsSubscription: Subscription;
	searchSubscription: Subscription;

	opened = false;
	mode: string;
	searching$: Observable<boolean>;
	filterPipe = new FilterPipe();
	views: {
		[id: string]: (
			http: HttpClient,
			controller: ManifestController<any>,
			indexedDbService,
			offlineService
		) => { id: string; dataSource: Function; template: any; storeBinding: any };
	};
	currentViewData;
	searchData: { inSearch: string; filters: any[] } = { inSearch: '', filters: [] };
	hasSearchValues = false;
	workflowItems: any[];
	loading$: Observable<boolean>;
	runSub: Subscription;

	@ViewChild(DynamicLoaderComponent, { static: true }) host: DynamicLoaderComponent;

	constructor(
		private store: Store<any>,
		private controller: ManifestController<any>,
		private http: HttpClient,
		public navService: NavService,
		public modalService: ModalService,
		private indexedDbService: IndexedDbService,
		private bf: BigFormService,
		public offlineService: OfflineService,
		private _toastr: ToastrService
	) {}
	ngOnInit() {
		this.loading$ = this.store.select(getLoadingState).pipe(delay(0));

		this.runSub = this.run().subscribe();
	}

	reloadView() {
		// console.log('heey he');
		this.loadViewToShow().pipe(take(1)).subscribe();
	}

	get showNoClaimsFoundMessage() {
		return typeof this.workflowItems !== 'undefined' && !this.workflowItems.length;
	}

	private run() {
		return this.store.select(getCurrentUser).pipe(
			filter(x => !!x),
			take(1),
			pluck('user'),
			switchMap((user: any) => {
				const usersNotToLogIn = [2];
				if (usersNotToLogIn.some(n => n === user.staff_type)) {
					this._toastr.warning('The login details you entered are not allowed on this platform');
					this.store.dispatch(new LogOut());
					return NEVER;
				} else {
					return this.loadWorkflow().pipe(
						mergeMap(() => this.getManifest()),
						tap(manifest => {
							this.views = manifest.views;

							this.checkAndDoSearch();

							// USING SAME DATA FOR ALL VIEWS HERE ELSE EACH VIEW SPECIFIES ITS OWN DATASOURCE
							this.controller
								.select(getActiveViewData)
								.pipe(take(1))
								.subscribe(data => {
									const view = data || { func: this.views['default'], key: 'default', params: { reverse: false } };
									this.controller.dispatch(new SetView(view));
								});
						}),
						mergeMap(manifest => this.loadViewToShow())
					);
				}
			})
		);
	}

	private loadWorkflow() {
		return this.controller.select(getOrgKey).pipe(
			take(1),
			tap(key => {
				this.controller.dispatch(new SetActiveManifestItem({ pathToFlows: ['manifestItems'], orgKey: key, itemId: 'workflow' }));
			})
		);
	}

	private getManifest() {
		return this.controller.select(getActiveManifestItem).pipe(
			skipWhile(item => !item),
			take(1),
			map(manifestItem => {
				this.workflowManifest = manifestItem;
				if (this.workflowManifest && this.workflowManifest.onStateInit) {
					this.workflowManifest.onStateInit(this);
				}
				return manifestItem;
			})
		);
	}

	hasSearched(filters) {
		if (this.searchData && (this.searchData.inSearch !== '' || (filters && filters.length > 0))) return true;
		return false;
	}

	private _filterItems(list, filters) {
		if (list && filters) {
			return this.filterPipe.transform(list, filters).sort((a, b) => (a.id < b.id ? 1 : -1));
		}
		return list;
	}

	checkAndDoSearch() {
		this.searchSubscription = combineLatest([
			this.store.select(getFilterData).pipe(take(1), pluck('searchField')),
			this.controller.select(getManifestSearchData).pipe(take(1)),
			this.controller.select(getActiveOrganization).pipe(take(1)),
			this.controller.stateObservable.pipe(take(1)),
			this.store.pipe(take(1))
		])
			.pipe(take(1))
			.subscribe(([search, searchFunc, org, controller, store]: any) => {
				if (!!search && !!search['inSearch']) {
					const _configuration = searchFunc(store, controller);
					const { inSearch, checkClosed } = search;
					this.store.dispatch(
						new SearchOnServer({
							endpoint: _configuration.searchEndpoint,
							active: !checkClosed,
							search: inSearch,
							virtualStatesFunction: org.virtualStatesFunction
						})
					);
				}
			});
	}

	loadViewToShow() {
		return combineLatest([
			this.store.select(getServerSearchResult),
			this.store.select(getFilterData),
			this.store.select(getFilters),
			this.controller.select(getActiveViewData).pipe(
				filter(x => !!x),
				distinctUntilChanged()
			)
		]).pipe(
			map(([serverManualSearchResult, searchData, filters, activeView]) => {
				// console.log({ serverManualSearchResult, searchData, filters, activeView });
				const currentViewData = activeView.func(this.http, this.controller, this.indexedDbService, this.offlineService);
				if (currentViewData) {
					if ((this.bf.bigForm.contains('inSearch') && this.bf.bigForm.get('search')) || !!searchData.searchField) {
						this.searchData.inSearch =
							(this.bf.bigForm.contains('inSearch') && this.bf.bigForm.get('search') ? this.bf.bigForm.get('search')?.value?.inSearch : '') ||
							(searchData.searchField && searchData.searchField.inSearch ? searchData.searchField.inSearch : '');
					} else {
						this.searchData.inSearch = '';
					}
					this.hasSearchValues = this.hasSearched(filters);
					this.itemsSubscription = currentViewData.storeBinding
						.pipe(
							skipWhile(x => !x)
							// take(1)
						)
						.subscribe(items => {
							if (this.searchData.inSearch) {
								// console.log('In load get server search results 2', serverManualSearchResult);
								this.workflowItems = serverManualSearchResult;
								this.host.loadWithInputs(currentViewData.template, { list$: of(this.workflowItems) }, null);
							} else if (filters.length !== 0) {
								// if filters has already been applied, filter after search
								const result = this._filterItems(items, filters);
								// console.log('In load filters', result);
								this.workflowItems = result;
								this.host.loadWithInputs(currentViewData.template, { list$: of(this.workflowItems) }, null);
							} else {
								this.workflowItems = items;
								// console.log('In load get server search results 1', items);
								this.host.loadWithInputs(currentViewData.template, { list$: of(this.workflowItems) }, null);
							}
						});
				}
			})
		);
	}

	private _setIndexWorkflowItems() {
		this.itemsSubscription = this.currentViewData.storeBinding.pipe(skipWhile(x => !x)).subscribe(items => {
			this.workflowItems = items;
			this.host.loadWithInputs(this.currentViewData.template, { list$: of(this.workflowItems) }, null);
		});
	}

	checkForAppUpdate() {
		this.checkForUpdateSubscription = this.userIdleTime(300000).subscribe(() => {
			document.location.reload();
		});
	}

	userIdleTime(milliseconds: number): Observable<any> {
		const mergedStream = merge(fromEvent(document, 'click'), fromEvent(document, 'keydown'), fromEvent(document, 'mousemove'), fromEvent(document, 'scroll'));
		const idleStream = mergedStream.pipe(
			bufferTime(milliseconds),
			filter(arr => arr.length === 0)
		);
		return idleStream;
	}

	trackByFunc(idx, claim) {
		return claim?.id;
	}

	ngOnDestroy() {
		if (this.runSub) this.runSub.unsubscribe();
		if (this.checkForUpdateSubscription) {
			this.checkForUpdateSubscription.unsubscribe();
		}
		if (this.itemsSubscription) this.itemsSubscription.unsubscribe();
		if (this.searchSubscription) this.searchSubscription.unsubscribe();

		if (this.workflowManifest && this.workflowManifest.onStateDestroy) {
			this.workflowManifest.onStateDestroy(this);
		}
	}
}
