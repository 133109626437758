import { Validators } from '@angular/forms';
import { NodePoint_0_0_2 } from '@flexus/core';

export const requestQANode = (state?: number, new_state?: number): NodePoint_0_0_2 => {
	let children;
	if (state === 177 || state === 233) {
		children = [
			{
				component: 'FLXFlatDynamicFormComponent',
				inputs: {
					heading: 'Reason for support assistance',
					// subheading: 'Please select the type of inspection required',
					formControls: {
						0: {
							inputType: 'select',
							formControlName: 'message',
							selectConfig: {
								placeHolder: 'QA',
								itemsOption: [
									{ qa_type: 'No voucher/decoder serial', qa_type_value: 'No voucher/decoder serial' },
									{
										qa_type: 'No installer accreditation or technician qualification number',
										qa_type_value: 'No installer accreditation or technician qualification number'
									},
									{ qa_type: 'Trigger 21, 24, 26', qa_type_value: 'Trigger 21, 24, 26' },
									{ qa_type: 'Other', qa_type_value: 'Other' }
								],
								displayOptions: { displayKey: 'qa_type', valueKey: 'qa_type_value' },
								// This is to add a form item based on a selection
								addConditionalForm: {
									onControlSelectedValue: 'Other',
									inputType: 'textarea',
									formControlName: 'alternate_qa',
									width: '200px',
									label: "Please give a description for 'Other'",
									validators: Validators.required
								}
							},
							width: '200px',
							validators: Validators.required
						}
					},
					formLayout: 'stacked',
					containerWidth: '500px'
				}
			}
		];
	} else {
		children = [
			{
				component: 'FLXFlatDynamicFormComponent',
				inputs: {
					heading: 'Allocate Quality Assurance',
					subheading: 'Please select the type of inspection required',
					formControls: {
						0: {
							inputType: 'select',
							formControlName: 'qa_type',
							selectConfig: {
								placeHolder: 'QA',
								itemsOption: [
									{ qa_type: 'Technical QA', qa_type_value: 'Technical QA' },
									{ qa_type: 'Validation QA', qa_type_value: 'Validation QA' },
									{ qa_type: 'Site-Inspection QA', qa_type_value: 'Site-Inspection QA' }
								],
								displayOptions: { displayKey: 'qa_type', valueKey: 'qa_type_value' }
							},
							width: '200px',
							validators: Validators.required
						}
					},
					formLayout: 'stacked',
					containerWidth: '500px'
				}
			},
			...(state !== 179
				? [
						{
							component: 'FLXFlatDynamicFormComponent',
							inputs: {
								formControls: {
									0: {
										label: 'Please provide a reason why this specific QA has been allocated',
										inputType: 'textarea',
										rows: 5,
										formControlName: 'message',
										validators: Validators.required
									}
								},
								formLayout: 'stacked',
								containerWidth: '50vw'
							}
						}
				  ]
				: [])
		];
	}

	return {
		component: {
			children
		},
		name: 'Request for QA',
		hideTabItem: true,
		initFormFields: (bf, item, instance) => {
			if (new_state) {
				bf.patchValues({ new_state });
			} else {
				bf.patchValues({ new_state: 180 });
			}
		},
		navs: [
			{
				text: 'Submit',
				disableOnLoad: true,
				serverFirst: true,
				nextNode: 'submissionSuccess',
				color: 'primary',
				optIntoValidation: true,
				linkType: 'submit',
				serverCalls: {
					response: {
						serviceVariable: 'mulService',
						functionName: 'updateJob',
						errorMessage: 'Job could not be updated!!'
					}
				}
			}
		]
	};
};
// ['qa_type', 'qa_reason']
// const checkValidityForFields = ['qa_type'];
