import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { getJobsAwarded, getJobsLost, RemoveAllJobResponses } from '@flexus/core';
import { FLXModalComponent, ModalNavButton } from '@flexus/ui-elements';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'flx-sp-job-notification-window',
	templateUrl: './sp-job-notifications-window.component.html',
	styleUrls: ['./sp-job-notifications-window.component.scss']
})
export class FLXSpJobNotificationsWindowComponent implements OnInit, OnDestroy {
	// ====================================================== VARIABLES ======================================================
	jobsAwarded: BehaviorSubject<any[]> = new BehaviorSubject(null as any);
	jobsLost: BehaviorSubject<any[]> = new BehaviorSubject(null as any);

	paginationVariables = {
		pageSize: 5,
		jaCurrentPage: 0,
		jlCurrentPage: 0
	};

	modalButtons: ModalNavButton[] = [
		{
			text: 'Clear All Notifications',
			color: 'alert',
			confirmAction: {
				text: 'Are you sure you want to clear all job notifications?',
				type: 'alert',
				confirm: {
					text: 'Yes, clear notifications',
					color: 'primary',
					clickHandler: () => this.clearAllNotifications(),
					linkType: 'close'
				}
			}
		},
		{
			text: 'Close',
			linkType: 'close'
		}
	];

	// ======================================================== INPUT / OUTPUTS ========================================================
	@Output() ignore = new EventEmitter<boolean>();
	@ViewChild(FLXModalComponent) modal!: FLXModalComponent;
	// ======================================================= LIFE CYCLE METHODS ======================================================

	constructor(private store: Store<any>) {}

	ngOnInit() {
		this.store
			.select(getJobsAwarded)
			.pipe(filter(x => !!x))
			.subscribe(res => {
				this.jobsAwarded.next(res);
			});

		this.store
			.select(getJobsLost)
			.pipe(filter(x => !!x))
			.subscribe(res => {
				this.jobsLost.next(res);
			});
	}

	paginatorPage(event: any, listname: any) {
		if (listname === 'jobsawarded') {
			this.paginationVariables.jaCurrentPage = event;
		}

		if (listname === 'jobslost') {
			this.paginationVariables.jlCurrentPage = event;
		}
	}

	// ============================================================ METHODS ============================================================
	clearAllNotifications() {
		this.store.dispatch(new RemoveAllJobResponses());
	}

	open() {
		this.modal.navButtons = this.modalButtons;
		this.modal.heading = 'Job Notifications';
		this.modal.open();
	}

	ngOnDestroy(): void {}
}
