import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, catchError } from 'rxjs/operators';
import { FilterActionTypes, SearchOnServer, SearchOnServerSuccess, SearchOnServerFail } from '../actions/filter-store.actions';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

// import { HttpEventType } from '@angular/common'

@Injectable()
export class FilterStoreEffects {
	constructor(private actions$: Actions, private _store: Store<any>, private http: HttpClient) {}

	searchForClaim$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FilterActionTypes.SEARCH_ON_SERVER),
			switchMap((action: SearchOnServer) => {
				const { endpoint, active, search, virtualStatesFunction } = action.payload;
				return this.http.post<any>(endpoint, { active, search }).pipe(
					switchMap(res => {
						const { payload = [], success, reason } = res;
						if (success === true) {
							return of(new SearchOnServerSuccess(virtualStatesFunction(payload)));
						} else {
							return of(new SearchOnServerFail({ error: reason }));
						}
						//  the bellow WILL get more complex
					}),
					catchError(err => of(new SearchOnServerFail({ error: err })))
				);
			})
		)
	);
}
