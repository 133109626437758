import { Flow_0_0_2, getFullItemTwo, getData, getSelectedItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { forkJoin, of } from 'rxjs';
import { skipWhile, take, map, pluck, filter, switchMap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { GetDecoderSerialNumberDisplay } from '../reusable/dataManipulations';

export const MUL_190: Flow_0_0_2 = {
	id: '190',
	name: 'decoder-delivery-received',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: 'Job Awarded - Awaiting Courier Delivery',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'mulSpService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		voucher: {
			serviceVariable: 'mulSpService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Cannot get voucher summary'
		}
	},
	instructions: {
		editRoles: {
			0: 'Proceed to installer'
		},
		viewRoles: {
			0: 'Wait for installer to complete'
		}
	},
	startNode: 'DecoderReceivedNotification',
	nodes: {
		DecoderReceivedNotification: {
			initFormFields: (bf, item, instance, storeQuery, store) => {},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Decoder Information',
							data$: 'decoderInfoKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Contact Details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			serverCalls: {
				decoderInfoKeyValues: {
					errorMessage: 'Something went wrong with decoder info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getData).pipe(
								skipWhile(v => !v.voucher),
								pluck('voucher'),
								take(1)
							)
						]).pipe(
							map(([job, voucher]) => {
								const decoderDetails = GetDecoderSerialNumberDisplay(voucher?.decoder_number);

								const decoderInfo = {
									...decoderDetails,
									'Voucher Code': job?.job_information?.vouchers?.voucher_code ?? 'No additional decoder information',
									'Additional Decoder Info ': job?.job_information?.vouchers?.title ?? 'No additional decoder information'
								};
								return [decoderInfo];
							})
						);
					}
				},
				customerContactKeyValues: {
					errorMessage: 'Something went wrong with customer info',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									let joblocation;

									if (!itemTwo?.claim?.loan_information) {
										joblocation = 'The customer misses data';
									}
									if (!itemTwo?.claim?.loan_information?.claimlatitude || !itemTwo?.claim?.loan_information?.claimlongitude) {
										joblocation = 'At least one coordinate was not supplied!';
									}
									if (itemTwo?.claim?.loan_information?.claimlatitude && itemTwo?.claim?.loan_information?.claimlongitude) {
										const latitude = itemTwo?.claim?.loan_information?.claimlatitude;
										const longitude = itemTwo?.claim?.loan_information?.claimlongitude;
										joblocation = `${latitude}, ${longitude}`;
									}
									const customerInfo = {
										'Full name ': `${itemTwo?.claim?.applicant?.first_name} ` + `${itemTwo?.claim?.applicant?.surname}`,
										'Subscriber number': `${itemTwo.identities?.subscriber_number}`,
										'Installation Address':
											`${itemTwo?.claim?.loan_information?.propertystreetaddress}` +
											' ' +
											`${itemTwo?.claim?.loan_information?.propertystreetaddress_2},` +
											' ' +
											`${itemTwo?.claim?.loan_information?.propertysuburb}`,
										'Email address ': itemTwo?.claim?.loan_information?.Email ?? 'No email address returned',
										'Contact number ': `${itemTwo?.claim?.loan_information?.ContactNumber}`,
										'GPS Coordinates ': joblocation
									};
									return [customerInfo];
								})
							);
					}
				}
			},
			navs: [
				{
					text: 'Incorrect Decoder',
					color: 'secondary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'warning';
							instance.heading = 'Incorrect Decoder Delivered';
							instance.setMessage([
								'Please contact installeradmin@multichoice.co.za to arrange for the correct decoder to be delivered.',
								'',
								"This job will be placed in 'Awaiting Replacement Decoder'."
							]);
							instance.navButtons = [
								{
									text: 'Back',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'OK',
									color: 'default',
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										updateJob: {
											errorMessage: 'Job was not updated',
											directCall: (http, store, sq, bf) => {
												return forkJoin([
													store.select(getFullItemTwo).pipe(
														filter(x => !!x && Object.keys(x).length !== 0),
														take(1),
														map(res => res as any)
													),
													store.select(getData).pipe(
														skipWhile(v => !v),
														pluck('voucher'),
														take(1)
													)
												]).pipe(
													switchMap(([job, voucher]: any) => {
														const thisjob = job as any;
														const decoder = voucher as any;
														if (job && voucher) {
															const job_id = thisjob.id;
															const decoder_number = decoder.decoder_number;
															const poh_job_info = thisjob.job_information;
															const job_information = { ...poh_job_info, decoder_number };
															const current_state = 190;
															const new_state = 208;
															const data = {
																job_id: job_id,
																job_information: job_information,
																current_state: current_state,
																new_state: new_state
															};
															return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
														}
													})
												);
											}
										}
									}
								}
							];
						}
					}
				},
				{
					text: 'Confirm Received',
					nextNode: 'SubmissionSuccess',
					linkType: 'submit',
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'mulSpService',
							errorMessage: 'Job was not updated!',
							ignoreFalseError: true,
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									)
								]).pipe(
									switchMap(([job]) => {
										// console.log('BBBBB => ', job);
										const job_id: number = job?.id;
										const new_state = 21;
										const current_state = 190;

										const data = {
											job_id: job_id,
											new_state: new_state,
											current_state: current_state
										};

										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		decoder_number: 'decoder_number'
	}
};
