import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BigFormService, getCurrentUser } from '@flexus/core';
import { Store } from '@ngrx/store';
import { map, skipWhile, take } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { FormArray } from '@angular/forms';
import { VatService } from '../../services';

@Component({
	selector: 'flexus-credit-note-summary',
	templateUrl: './credit-note-summary.component.html',
	styleUrls: ['./credit-note-summary.component.scss']
})
export class CreditNoteSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() editing;
	@Input() actualLineItemsFormArray: FormArray;
	@Input() isNonVatSP: boolean = false;
	private invoiceDateSub: Subscription;
	excess;
	excessChangeDetails;
	client;
	subTotal$: Observable<any>;
	excessAmount$: Observable<any>;
	vat$: Observable<number>;
	summaryValues;
	sub: Subscription;
	balanceDueSub: Subscription;
	balanceDue = 0;
	subTotal = 0;
	excessAmount = 0;
	vat = 0;
	vatAmount = 0;
	creditNoteTotal = 0;
	constructor(public bf: BigFormService, private vatService: VatService, private store: Store<any>, private cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.store
			.select(getCurrentUser)
			.pipe(
				skipWhile((usr: any) => !usr),
				take(1)
			)
			.subscribe((result: any) => {
				this.client = result?.client;
				console.log('client', this.client);
			});

		this.subTotal$ = this.actualLineItemsFormArray.valueChanges.pipe(
			map((lineItems: any[]) => {
				console.log('calculating subTotal');
				return lineItems.reduce((sum, item) => sum + Number(item.AmountExclVAT), 0);
			})
		);

		this.vat$ = this.vatService.getVATPercentage$();

		this.balanceDueSub = combineLatest([this.subTotal$]).subscribe(([subTotal]) => {
			this.subTotal = subTotal;
			this.vat = this.vatService._currentVatRate;
			this.vatAmount = (this.vat / 100) * subTotal;
			this.summaryValues = { subTotal, vatAmount: (this.vat / 100) * subTotal, vat: this.vat };
			console.log({ summaryvalues: this.summaryValues });
			this.creditNoteTotal = this.isNonVatSP ? subTotal : (subTotal + (!!this.vat ? (subTotal * this.vat) / 100 : 0));
			this.bf.patchValues({ credit_note_amount: Number(this.creditNoteTotal.toFixed(2)) });
			this.bf.patchValues({ vatPercentage: this.isNonVatSP ? 0 : this.vat });
		});
	}

	ngAfterViewInit() {
		this.actualLineItemsFormArray.patchValue(this.actualLineItemsFormArray.value);
		this.cd.detectChanges();
	}

	ngOnDestroy() {
		if (this.invoiceDateSub) {
			this.invoiceDateSub.unsubscribe();
		}
		this.sub?.unsubscribe();
		this.balanceDueSub?.unsubscribe();
	}
}
