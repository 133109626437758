<div [scriptLoader]="googleMapsSrc" (scriptCallback)="scriptLoaderCallback($event)">
	<div class="mapContainerDiv" #mapContainerDiv>Loading...</div>
	<div *ngIf="customerConcern" class="customerConcern">
		<div class="sitedetailsheading">Site Detail</div>
		<div class="sitedetail">
			<div class="left-site-detail">
				<div style="text-align: left; margin-left: 15px">
					<div class="key">Decoder type:</div>
					<br />
					<div class="key">Kind of TVs:</div>
					<br />
					<div class="key">Home type:</div>
				</div>
				<div style="text-align: left">
					<div class="value">{{ device_type }}</div>
					<br />
					<div class="value">{{ tv_type }}</div>
					<br />
					<div class="value">{{ dwelling_type }}</div>
				</div>
			</div>
			<div class="right-site-detail">
				<div style="text-align: left">
					<div class="key">Existing decoder:</div>
					<br />
					<div class="key">Number of TVs:</div>
					<br />
					<div class="key">Wall type:</div>
				</div>
				<div style="text-align: left">
					<div class="value">{{ existing_decoders }}</div>
					<br />
					<div class="value">{{ no_of_tvs }}</div>
					<br />
					<div class="value">{{ wall_type }}</div>
				</div>

				<br />
			</div>
		</div>
	</div>
</div>
