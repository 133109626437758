import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { map } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';

export const BET_ADD_POLICE_CASE_NUMBER: Flow_0_0_2 = {
	id: 'AddPoliceCaseNumber',
	itemType: 'flow',
	name: 'Add Police Case Number',
	excludeForOnlyStates: [169],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Add Police Case Number  :  ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname} `;
					} else {
						return 'Add Police Case Number';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'AddPoliceCaseNumber',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		AddPoliceCaseNumber: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				formControls: {
					0: { label: 'Enter Police Case Number', inputType: 'input', formControlName: 'police_case_number' }
				},
				containerWidth: '400px',
				centered: true
			},
			initFormFields: (bf, item, instance) => {
				bf.bigForm.addControl('police_case_number', new UntypedFormControl('', Validators.required));
			},
			checkValidityForFields: ['police_case_number'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Claim not updated',
							serviceVariable: 'betService',
							functionName: 'updateClaim'
						}
					},
					color: 'primary'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		police_case_number: 'loan_information.policecasenumber'
	}
};
