import { Component, OnInit, Input, ChangeDetectionStrategy, Inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, forkJoin } from 'rxjs';
import { filter, map, pluck, take, skipWhile, switchMap } from 'rxjs/operators';
import { findName } from '@flexus/utilities';
import { ModalService, MakeServerCall } from '@flexus/core';
import { getAllInfo, getData, getFullItemOne } from '@flexus/core';
import { FLXCardExpansionLocation, ModalNavButton } from '@flexus/ui-elements';
import { CurrencyPipe } from '@angular/common';
@Component({
	selector: 'flx-view-payments-component',
	templateUrl: './view-payment.component.html',
	styleUrls: ['./view-payment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CurrencyPipe]
})
export class ViewPaymentsComponent implements OnInit {
	constructor(public store: Store<any>, private modalsService: ModalService, @Inject('environment') private _environment: any, private currency: CurrencyPipe) {}

	paymentsList$: Observable<any>;
	afterBody = FLXCardExpansionLocation.afterBody;
	@Input() claimAppointments: Observable<any>;
	@Input() paymentMadeTo: string = 'Maven';

	ngOnInit(): void {
		this.payments();
	}


	payments() {
		this.paymentsList$ = forkJoin([
			this.store.select(getAllInfo).pipe(
				skipWhile(x => !x),
				take(1)
			),
			this.store.select(getData).pipe(
				pluck('claimAppointments', 'payload', 'xml'),
				filter(x => !!x),
				take(1)
			),
			this.store.select(getFullItemOne).pipe(
				skipWhile(x => !x),
				take(1)
			)
		]).pipe(
			take(1),
			map(([allInfo, claimAppointments, claim]) => {
				const matchArr = [];
				const jobMids = [];
				const appKeys = [];
				const payKey = [];

				const itemArr =
					claimAppointments?.appointments && Array.isArray(claimAppointments?.appointments?.item)
						? claimAppointments?.appointments?.item
						: claimAppointments?.appointments
						? [claimAppointments?.appointments.item]
						: [];

				const jobsArr = claimAppointments?.jobs && Array.isArray(claimAppointments?.jobs) ? claimAppointments?.jobs : claimAppointments?.jobs ? [claimAppointments?.jobs] : [];

				const paymentArr =
					claimAppointments?.payments && Array.isArray(claimAppointments?.payments?.item)
						? claimAppointments?.payments?.item
						: claimAppointments?.payments
						? [claimAppointments?.payments?.item]
						: [];

				const claimJobs = claim?.jobs && Array.isArray(claim?.jobs) ? claim?.jobs : claim?.jobs ? [claim?.jobs] : [];

				jobsArr.forEach(element => {
					if (element?.mid) jobMids.push(element?.mid);
				});

				itemArr.forEach(element => {
					if (element?.appointkey) appKeys.push(element?.appointkey);
				});

				paymentArr.forEach(element => {
					if (element?.appointmentkey) payKey.push(element?.appointmentkey);
				});

				for (const job of jobsArr) {
					if (claimAppointments && claimAppointments?.appointments) {
						itemArr.forEach(element => {
							if (element?.appointkey === job?.mid) {
								// if job has an mid and matches with appointment appkey, job is both on 4sure and on maven
								const matchObj = {
									usSp: findName(parseInt(job?.sp, 10), allInfo['sps']) ? findName(parseInt(job?.sp, 10), allInfo['sps']).name : '--',
									usSkill: findName(parseInt(job?.skill, 10), allInfo['skills']) ? findName(parseInt(job?.skill, 10), allInfo['skills'])?.name : '--',
									usState: findName(parseInt(job?.state, 10), allInfo['states']) ? findName(parseInt(job?.state, 10), allInfo['states'])?.description : '--',
									usAmount: job?.claim_value ? this.currency.transform(job?.claim_value, 'R', 'symbol', '1.2-2') : '--',
									themSp: element?.providername ? element?.providername : '--',
									themSkill: element?.appointdescription ? element?.appointdescription : '--',
									themState: paymentArr.filter(pay => pay?.appointmentkey === job?.mid)[0] ? paymentArr.filter(pay => pay?.appointmentkey === job?.mid)[0]?.status : '--',
									themAmount: paymentArr.filter(pay => pay?.appointmentkey === job?.mid)[0]
										? this.currency.transform(paymentArr.filter(pay => pay?.appointmentkey === job?.mid)[0]?.amountrequested, 'R', 'symbol', '1.2-2')
										: '--',
									message: this.composeMessage(
										claimJobs.find(jobs => jobs?.id === parseInt(job?.id, 10)),
										allInfo,
										null
									),
									jobId: job?.id
								};

								matchArr.push(matchObj);
							}
						});
					}

					if (!job?.mid || !appKeys?.includes(job?.mid)) {
						// if job has no mid or has mid but does not match with any appointments on maven, on 4sure but not on maven
						const matchObj = {
							usSp: findName(parseInt(job?.sp, 10), allInfo['sps'])?.name ? findName(parseInt(job?.sp, 10), allInfo['sps'])?.name : '--',
							usSkill: findName(parseInt(job?.skill, 10), allInfo['skills'])?.name ? findName(parseInt(job?.skill, 10), allInfo['skills'])?.name : '--',
							usState: findName(parseInt(job?.state, 10), allInfo['states'])?.description ? findName(parseInt(job?.state, 10), allInfo['states'])?.description : '--',
							usAmount: job?.claim_value ? `R${job?.claim_value}` : '--',
							themSp: '--',
							themSkill: '--',
							themState: '--',
							themAmount: '--',
							message: this.composeMessage(claimJobs.filter(jobs => jobs?.id === parseInt(job?.id, 10))[0], allInfo, null),
							jobId: job?.id
						};

						matchArr.push(matchObj);
					}
				}

				itemArr.forEach(element => {
					if (!jobMids?.includes(element?.appointkey)) {
						// job is in appointments but not in jobs, thus job is on maven but not on 4sure
						const matchObj = {
							usSp: '--',
							usSkill: '--',
							usState: '--',
							usAmount: '--',
							themSp: element?.providername ? element?.providername : '--',
							themSkill: element?.appointdescription ? element?.appointdescription : '--',
							themState: paymentArr.filter(pay => pay?.appointmentkey === element?.appointkey)[0]
								? paymentArr.filter(pay => pay?.appointmentkey === element?.appointkey)[0]?.status
								: '--',
							themAmount: paymentArr.filter(pay => pay?.appointmentkey === element?.appointkey)[0]
								? `R${paymentArr.filter(pay => pay?.appointmentkey === element?.appointkey)[0]?.amountrequested}`
								: '--',
							message: this.composeMessage(null, allInfo, paymentArr.filter(pay => pay?.appointmentkey === element?.appointkey)[0])
						};

						matchArr.push(matchObj);
					}
				});

				paymentArr.forEach(element => {
					if (!jobMids.includes(element?.appointmentkey) || !appKeys.includes(element?.appointmentkey)) {
						// if there is a payment but has no appointment or matching job, payment on maven but not linked on 4sure usually cash-in-lieu
						const matchObj = {
							usSp: '--',
							usSkill: '--',
							usState: '--',
							usAmount: '--',
							themSp: element?.payee ? element?.payee : '--',
							themSkill: element?.appointdescription ? element?.appointdescription : '--',
							themState: element?.status ? element?.status : '--',
							themAmount: element?.amountrequested ? `R${element?.amountrequested}` : '--',
							message: this.composeMessage(null, allInfo, element)
						};
						matchArr.push(matchObj);
					}
				});

				return matchArr;
			})
		);
	}

	composeMessage(job, allInfo, payment) {
		const messages = [];
		if (job && parseInt(job?.state, 10) === 54) {
			if (job?.office_use && job?.office_use?.failure_reason) {
				const msg = job?.office_use?.failure_reason;
				switch (true) {
					case msg.includes('This Service Provider has already been appointed on this claim'):
						messages.push(
							'Maven only allows an SP to be paid once for a specific skill. It appears this invoice matches another job payment for this SP under the same skill. Check this invoice is not a duplicate payment to this SP. If not then proceed by clicking Force payment'
						);
						break;
					case msg.includes('skill is not setup for the Service Provider'):
						messages.push(
							`The skill ${
								findName(parseInt(job.skill, 10), allInfo['skills'])?.name
							} needs to be added to the Service Provider profile on Maven. You can manually allocate the payment on Maven. Once payment is done move the payment forward here`
						);
						break;
					case msg.includes('Please provide a valid claim number'):
						messages.push(
							'Maven reports that this is not a valid Maven Claim Number. It may have been added manually on 4-Sure. Please check the Maven Claim Number is correct on 4-Sure. You can manually allocate the payment on Maven. Once payment is done move the payment forward here.'
						);
						break;
					case msg.includes('provide a valid provider type'):
						messages.push(
							`The skill ${findName(parseInt(job?.skill, 10), allInfo['skills'])?.name} does not have a Maven Provider Type on 4-Sure. Contact the 4-Sure team to fix this.`
						);
						break;
					case msg.includes('soap:Envelope'):
						messages.push('Maven or ESB were down when the original request was sent to them. 4-SUre can attempt to resubmit. If this does not work then contact 4-Sure');
				}
			}
		}

		if (payment && payment?.status?.includes('has been paid')) {
			messages.push('It appears that this job has already been paid on Maven. You can move the job forward on 4-Sure by selecting Payment Done');
		}

		if (messages.length === 0) {
			messages.push('No suggested fix');
		}

		return messages;
	}

	setShowDialog(modalOptions: { navButtons: ModalNavButton[]; message: string[]; hasCloseButton?: boolean }) {
		if (modalOptions.hasCloseButton === null || modalOptions.hasCloseButton === undefined) {
			modalOptions.hasCloseButton = false;
		}
		this.modalsService.openModalDirectly(inst => {
			inst.closeButton = modalOptions.hasCloseButton;
			inst.setMessage(modalOptions.message);
			inst.type = 'warning';
			inst.navButtons = modalOptions.navButtons;
		});
	}

	paymentDone(item) {
		this.setShowDialog({
			navButtons: [
				{ text: 'No, not yet', linkType: 'close', color: 'alert' },
				{
					text: 'Payment done',
					linkType: 'close',
					color: 'alert',
					clickHandler: () => {
						this.store.dispatch(
							new MakeServerCall({
								dataKey: 'updateJob',
								serviceVariable: 'silService',
								functionName: 'updateJob',
								errorMessage: 'Could not update Job',
								directCall: http => {
									return http.post(`${this._environment.api_url}v1/job_action/get_job/`, { job_id: item?.jobId }).pipe(
										filter(x => !!x),
										take(1),
										switchMap(job => {
											const data = {
												job_information: job['payload']?.job_information,
												job_id: job['payload']?.id,
												new_state: 28
											};
											return http.post(`${this._environment.api_url}v1/job_action/update_job/`, { ...data }).pipe(
												filter(x => !!x),
												take(1)
											);
										})
									);
								}
							})
						);
					}
				}
			],
			message: [`Have you manually completed the payment on ${this.paymentMadeTo}?`]
		});
	}
}
