import { Flow_0_0_2, getFullItemOne, getFullItemTwo, SetNextNode } from '@flexus/core';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import gql from 'graphql-tag';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';

export const SIL_REWORK: Flow_0_0_2 = {
	id: 'Rework',
	itemType: 'flow',
	includeForOnlyStates: [27, 28, 30, 34, 38, 39, 40, 50, 51],
	excludeForOnlyRoles: [18],
	name: 'Rework Job',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						return `View jobs for this claim : ${itemTwo?.claim?.loan_information?.mavenclaimnumber}  -  ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'View Jobs';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'Decision',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		Decision: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				reworkPossible: (navs, store, modal, controller) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							//filter((x) => !!x),
							skipWhile(x => !x),
							take(1),
							switchMap(job => {
								if (job.office_use?.providertype_id === 2) {
									modal.openModalDirectly(instance => {
										instance.type = 'warning';
										instance.message = 'Rework not possible! This is a CIL job';
										instance.navButtons = [
											{
												text: 'Back to workflow',
												clickHandler: event => {
													instance.router.navigate(['/workflow']);
												},
												linkType: 'close',
												color: 'alert'
											}
										];
									});
								} else if (job && job?.state === 28 && (job.claim?.state === 7 || job?.claim?.state === 9)) {
									modal.openModalDirectly(instance => {
										instance.type = 'warning';
										instance.message = 'Rework not possible! The claim needs to be re-opened before this job card can be reworked. Please go to team leader to re-open the claim.';
										instance.navButtons = [
											{
												text: 'Back to workflow',
												clickHandler: event => {
													instance.router.navigate(['/workflow']);
												},
												linkType: 'close',
												color: 'alert'
											}
										];
									});
								} else {
									controller.dispatch(new SetNextNode('SetAppointment'));
									return of({});
								}
								return 'Decision';
							})
						)
						.pipe(take(1))
						.subscribe(nextNode => {});
				}
			},
			navs: [{ text: 'Success', nextNode: 'SetAppointment' }]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skillcatagory
										skill
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(values => {
						bf.addControl(
							'appointmentData',
							new UntypedFormGroup({
								appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
								appointmentTime: new UntypedFormControl(null, [Validators.required]),
								appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
								appointmentdatetype: new UntypedFormControl(null),
								skill: new UntypedFormControl(values.skill),
								skillcatagory: new UntypedFormControl(values.skillcatagory)
							})
						);
					});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												surname
											}
											loan_information {
												contactnumber
												cellnumber
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											loan_information {
												onsiteperson
												onsitecontact
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			component: 'FLXJobAppointmentComponent',
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'CreateReworkJob'
				}
			]
		},
		CreateReworkJob: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				formControls: {
					0: {
						label: 'Please indicate what needs to be reworked and why.',
						inputType: 'textarea',
						rows: 5,
						width: '48%',
						formControlName: 'reworkReason'
					}
				},
				formLayout: 'stacked',
				containerWidth: '50vw'
			},
			checkValidityForFields: ['reworkReason'],
			initFormFields: (bf, item, instance) => {
				bf.addControl('reworkReason', new UntypedFormControl('', Validators.required));
				bf.addControl('new_state', new UntypedFormControl(42, Validators.required));
			},
			navs: [
				{
					text: 'Submit Rework',
					linkType: 'submit',
					color: 'primary',
					optIntoValidation: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'An error occurred while updating job!',
							serviceVariable: 'silService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		current_state: 'job_information.return_to_state',
		reworkReason: [
			rWr => {
				const formatted = 'Rework Required: ' + rWr;
				return formatted;
			},
			'appointment.reason'
		],
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					const appointment_formatted = {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
					return appointment_formatted;
				}
			},
			'appointment'
		]
	}
};
