import { Flow_0_0_2, getFullItemOne, getData, getFullItemTwo, getSelectedItemTwo, getAllInfo } from '@flexus/core';
import { Validators } from '@angular/forms';
import { take, map, skipWhile, switchMap, pluck, tap } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import moment from 'moment';
import { findName } from '@flexus/utilities';

export const BET_316: Flow_0_0_2 = {
	id: '316',
	name: 'mfrf_awaiting_mandate_underwriter_reply',
	itemType: 'flow',
	header: {
		title: store =>
			store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
				map((itemOne: any) => {
					if (itemOne) return `Awaiting mandate underwriter reply (MFRF) : ${itemOne?.mid} - ${itemOne?.applicant?.surname}`;
					return 'Awaiting mandate underwriter reply (MFRF)';
				})
			),
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], { filesFunctionName: 'getAllClaimFiles' }),
	serverCalls: {
		jobSummary: {
			errorMessage: '',
			directCall: (_h, store) =>
				store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					pluck('job_information'),
					map((job_information: any) => {
						if (job_information)
							return {
								important_information: {
									important_note: "This job is waiting for the underwriter's response"
								}
							};
					})
				)
		},
		summaryDetails: {
			errorMessage: 'Could get summary details',
			serviceVariable: '',
			directCall: (_h, store) =>
				forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile((itemTwo: any) => !itemTwo),
						take(1)
					),
					store.select(getAllInfo).pipe(
						skipWhile((allInfo: any) => !allInfo),
						pluck('appointment_types'),
						take(1)
					)
				]).pipe(
					take(1),
					map(([itemTwo, appointmentTypes]: any[]) => {
						const date = moment(itemTwo?.appointment?.[0]?.range_start);
						return {
							'Claim Details': {
								'Claim Type': itemTwo?.claim?.type,
								'Skill Required': itemTwo?.office_use?.skill,
								Address: itemTwo?.address,
								'Appointment Date': date?.format('YYYY-MM-DD'),
								'Appointment Time': `${findName(itemTwo?.appointment?.[0]?.appointment_type, appointmentTypes)?.name} ${date.format('HH:mm')}${
									itemTwo?.appointment?.[0]?.range_end ? ' - ' + moment(itemTwo?.appointment?.[0]?.range_end).format('HH:mm') : ''
								}`
							}
						};
					})
				)
		},
		customerDetails: {
			errorMessage: '',
			directCall: (_h, store) =>
				store.select(getFullItemTwo).pipe(
					skipWhile(job => !job),
					take(1),
					map((job: any) => ({
						customer_details: {
							customer: `${job?.claim?.applicant?.first_name} ${job?.claim?.applicant?.surname}`,
							'Contact Number': job?.claim?.applicant?.contact_number
						}
					}))
				)
		},
		aboveMandate: {
			errorMessage: '',
			directCall: (http, store) =>
				store.select(getSelectedItemTwo).pipe(
					skipWhile(job => !job),
					take(1),
					switchMap(job => http.post(environment.api_url + 'v1/job_action/check_requires_ia', { job_id: job?.id }).pipe(pluck('payload', 'above_mandate')))
				)
		}
	},
	instructions: {
		editRoles: {
			0: 'Awaiting mandate underwriter reply (MFRF)'
		},
		viewRoles: {
			0: 'Awaiting mandate underwriter reply (MFRF)'
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			name: 'Summary',
			showTabs: true,
			initFormFields: () => {},
			component: {
				children: [
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							headingConfig: { title: 'Job Summary', color: 'default', size: 'medium' },
							subHeadingConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							keyValueHeadingConfig: { color: 'secondary' },
							objectInfo$: 'jobSummary'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'summaryDetails'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'customerDetails'
						}
					}
				]
			},
			navs: [
				{
					text: 'Query SP',
					nextNode: 'QueryServiceProvider',
					color: 'default'
				},
				{
					text: 'MFRF Overturns Repudiation',
					nextNode: 'OverturnRepudiationReason',
					color: 'secondary'
				},
				{
					text: 'MFRF Approves Repudiation',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							directCall: (_h, _s, _sq, bf) => {
								bf.patchValues({ new_state: 25, current_state: 316 });
								return of();
							}
						},
						updateJob: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		OverturnRepudiationReason: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Overturn Repudiation'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Reason for Overturning Repudiation',
									inputType: 'textarea',
									formControlName: 'repudiation_overturned_reason'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: bf => {
				bf.patchValues({ repudiation_overturned_reason: bf.getControl('repudiation_overturned_reason')?.value || '', repudiation_overturned: 1 });
				bf.bigForm.get('repudiation_overturned_reason')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			checkValidityForFields: ['repudiation_overturned_reason'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							directCall: (_h, store, _sq, bf, _c, modal) =>
								store.select(getData).pipe(
									pluck('aboveMandate'),
									skipWhile(x => x === null || x === undefined),
									take(1),
									tap(aboveMandate => {
										bf.patchValues({ new_state: 294, current_state: 316 });
										if (aboveMandate) {
											modal.openModalDirectly(instance => {
												instance.type = 'info';
												instance.heading = 'Please take note';
												instance.message = 'Please contact MFRF and confirm repudiation';
												instance.navButtons = [
													{
														text: 'Send to IA',
														clickHandler: _e => {
															bf.patchValues({ new_state: 319 });
														},
														linkType: 'close',
														color: 'alert'
													},
													{
														text: 'Override IA',
														clickHandler: _e => {},
														linkType: 'close',
														color: 'alert'
													}
												];
											});
										}
									})
								)
						},
						updateJob: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		QueryServiceProvider: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Query Service Provider'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Query to Service Provider',
									inputType: 'textarea',
									formControlName: 'mfrf_query'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: bf => {
				bf.patchValues({ mfrf_query: bf.getControl('mfrf_query')?.value || '', new_state: 317 });
				bf.bigForm.get('mfrf_query')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			checkValidityForFields: ['mfrf_query'],
			navs: [
				{
					text: 'Submit To SP',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		current_state: [() => 316, 'current_state'],
		mfrf_query: [
			(qr, storeObj) => {
				if (qr) {
					let query = [];
					const FI2 = storeObj['selectedContext']?.fullItemTwo;

					if (FI2?.job_information?.mfrf_query) {
						if (Array.isArray(FI2?.job_information?.mfrf_query)) {
							query = FI2?.job_information?.mfrf_query;
						} else {
							query = [{ ...(<object>FI2?.job_information?.mfrf_query) }];
						}
					}
					const ia_query = [...query, { query: qr, queryreply: '' }];
					return ia_query;
				}
			},
			'job_information.mfrf_query'
		],
		repudiation_overturned: 'job_information.repudiation_overturned',
		repudiation_overturned_reason: 'job_information.repudiation_overturned_reason'
	}
};
