import { Flow_0_0_2 } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { voucherInfoNode } from '../reusable/VOUCHER_INFO_NODE';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { photoNode } from '../reusable/PHOTO_NODE';
import { setActionPanelItems } from '../../../../app-shell-features';
import { getInterstateComments } from '../reusable/dataManipulations';

export const MUL_176: Flow_0_0_2 = {
	id: '176',
	name: 'payment_request_validation',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Payment Validation ',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Payment Validation Request '
		},
		viewRoles: {
			0: 'Payment Validation Request '
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		}
	},
	startNode: 'jobInformation',
	nodes: {
		voucherData: {
			...voucherInfoNode(176)
		},
		jobInformation: {
			showTabs: true,
			...jobInfoNode(176)
		},
		photo: {
			...photoNode(176)
		},
		decline: {
			hideTabItem: true,
			name: 'Decline',
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Issue with Voucher',
							subheading: 'Reason for issue',
							formControls: {
								0: {
									inputType: 'select',
									formControlName: 'issue_reason_value',
									selectConfig: {
										placeHolder: 'Select Reason',
										itemsOption: [
											{
												issue_reason: 'Invoice stuck on authorised status',
												issue_reason_value: 'Invoice stuck on authorised status'
											},
											{
												issue_reason: 'Invoice stuck on success status',
												issue_reason_value: 'Invoice stuck on success status'
											},
											{ issue_reason: 'Voucher not linked', issue_reason_value: 'Voucher not linked' },
											{
												issue_reason: 'Voucher approved as labour only',
												issue_reason_value: 'Voucher approved as labour only'
											},
											{ issue_reason: 'Voucher not loaded on VMS', issue_reason_value: 'Voucher not loaded on VMS' },
											{ issue_reason: 'Invalid QR Codes', issue_reason_value: 'Invalid QR Codes' },
											{ issue_reason: 'Other', issue_reason_value: 'Other' }
										],
										displayOptions: { displayKey: 'issue_reason', valueKey: 'issue_reason_value' }
									},
									width: '200px'
								}
							},
							formLayout: 'stacked',
							containerWidth: '500px'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',

						inputs: {
							subheading: 'Please explain the issue with the voucher',
							formControls: {
								0: {
									inputType: 'textarea',
									rows: 5,
									formControlName: 'message'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw'
						}
					}
				]
			},

			checkValidityForFields: ['message', 'issue_reason_value'],
			initFormFields: (bf, item, instance) => {
				bf.addControl('message', new UntypedFormControl('', Validators.required));
				bf.patchValues({ new_state: 177 });
			},
			navs: [
				{
					text: 'Confirm',
					nextNode: 'submissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit',
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		sendToQa: {
			hideTabItem: true,
			component: 'FLXFlatDynamicFormComponent',
			name: 'Send to QA',
			inputs: {
				subheading: 'Please provide reason for sending to QA',
				formControls: {
					0: {
						inputType: 'textarea',
						rows: 5,
						formControlName: 'message'
					}
				},
				formLayout: 'stacked',
				containerWidth: '50vw'
			},
			checkValidityForFields: ['message'],
			initFormFields: (bf, item, instance) => {
				bf.addControl('message', new UntypedFormControl('', Validators.required));
				bf.patchValues({ new_state: 185 });
			},
			navs: [
				{
					text: 'Submit',
					nextNode: 'submissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit',
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		payment_validated: [
			(pvld, storeObj, bf) => {
				let validity = false;
				bf.new_state === 183 || bf.new_state === 28 ? (validity = true) : (validity = false);
				return validity;
			},
			'job_information.payment_validated'
		],
		message: [(message, storeObj, bf) => getInterstateComments(message, storeObj, bf), 'job_information.interstate_comments']
	}
};
