import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'moveAssessorJobsToTop'
})
export class MoveAssessorJobsToTopPipe implements PipeTransform {
	transform(array: any[]): any[] {
		if (!Array.isArray(array)) {
			return array;
		}

		const newArray: any[] = [];
		const objectsWithSkill44: any[] = [];

		for (const obj of array) {
			if (obj.skill === 44) {
				objectsWithSkill44.push(obj);
			} else {
				newArray.push(obj);
			}
		}

		return objectsWithSkill44.concat(newArray);
	}
}
