import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

export const BET_CANCEL_JOB: Flow_0_0_2 = {
	id: 'CancelJob',
	itemType: 'flow',
	// includeForOnlyStates: ['all'],
	name: 'Cancel Job',
	excludeForOnlyStates: [27, 48, 39, 49, 54, 46, 47, 50, 51, 30, 40, 45, 28],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						return `Cancel Job : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Cancel Job';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'CancelJob',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	serverCalls: {
		jobdata: {
			errorMessage: 'No job information returned',
			directCall: (http, store, sq) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile((itemTwo: any) => itemTwo === null || itemTwo === undefined),
						take(1)
					),
					store.pipe(
						// skipWhile((stor) => !stor),
						take(1)
					)
				]).pipe(
					map(([job, stor]: any) => {
						return { job, stor };
					})
				);
			}
		}
	},
	nodes: {
		CancelJob: {
			component: 'FLXCancelJobComponent',
			inputs: {
				heading: 'Cancel the selected Job Card',
				subtitle: 'Job cards can not be cancelled if work is already in progress'
			},
			initFormFields: (bf, item, instance) => {},
			navs: [
				{
					text: 'Cancel this job',
					nextNode: 'CancelJobReason',
					// serverFirst: true,
					// serverCalls: {
					//   response: {
					//     errorMessage: '',
					//     // directCall: () => {},
					//   },
					// },
					color: 'secondary'
				}
			]
		},
		CancelJobReason: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				containerWidth: '50vw',
				heading: '',
				formControls: {
					0: {
						label: 'Select the reason for cancellation',
						inputType: 'select',
						selectConfig: {
							displayOptions: { displayKey: 'display', valueKey: 'value' },
							itemsOption: [
								{ display: 'Cancel', value: 'Cancel' },
								{ display: 'Cancel - Client Request', value: 'Cancel - Client Request' },
								{ display: 'Duplicate', value: 'Duplicate' },
								{ display: 'Other', value: 'Other' }
							],
							searchEnabled: false,
							placeholder: 'Reason for cancellation'
						},
						formControlName: 'cancel_reason'
					}
				}
			},
			navs: [
				{
					text: 'continue',
					color: 'secondary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'cancelJob',
							errorMessage: 'Job not cancelled'
						}
					}
				}
			],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('cancel_reason', new UntypedFormControl());
			}
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	}
};
