import { Component, Input, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

@Component({
	selector: 'flx-dynamic-chart',
	templateUrl: './dynamic-chart.component.html',
	styleUrls: ['./dynamic-chart.component.scss']
})
export class DynamicChartComponent {
	@ViewChild(BaseChartDirective) chart?: BaseChartDirective;

	@Input() data;
	@Input() options;
	@Input() type;
	@Input() width: string = '';
	@Input() height: string = '';
	constructor() {}

}
