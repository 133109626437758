import { silRoles } from '../../mul-user.roles';
import moment from 'moment';
import { interval } from 'rxjs';
import { all } from 'ramda';
import { ItemOne_Permission_0_0_2 } from '@flexus/core';
import { claimStateLookupTable } from '../../models/indicator-lookup.model';
import { humaniseSeconds } from '@flexus/utilities';

export const claimPermissions: ItemOne_Permission_0_0_2 = {
	show_internal_assessor_can_finish_button: (claim: any, user, renderer, domElement: HTMLElement, activeOrg, allInfo, instance) => {
		const userRoles = user?.user?.roles;
		if (userRoles.includes(silRoles.INSURER_ASSESSOR)) {
			const iaStates = [96, 97];
			const internalAssessorOwnJobDoneState = [97];
			const associatedJobsInProgressStates = [96, 92, 91, 20, 21, 22, 23, 32, 37, 77, 84, 85, 88, 90];
			const iaJob = claim?.jobs ? claim?.jobs.filter(j => iaStates.includes(j.state))[0] || { id: 0 } : [];
			const associatedJobs = claim?.jobs?.filter(j => j.id !== iaJob.id) ?? [];
			const allOtherJobsInFinishedState = (associatedJobsInClaim: any[]): boolean => {
				const otherJobsStates = associatedJobsInClaim?.reduce((acc, job) => [...acc, job?.state], []);
				const excludeStates = stateList => state => !stateList.includes(state);
				return all(excludeStates(associatedJobsInProgressStates), otherJobsStates);
			};
			const canFinishJobs = internalAssessorOwnJobDoneState.includes(iaJob.state) && (allOtherJobsInFinishedState(associatedJobs) || associatedJobs.length === 0);
			//
			if (canFinishJobs) {
				const finished = canFinishJobs;
			}
		}
	},
	show_default_button: (claim, user, renderer, domElement: HTMLElement, activeOrg, allInfo, instance) => {
		// SHOW POST-TO-SERVER LOCALDRAFT BUTTON
		if (claim && claim?.tempKey) {
			const aclaim = claim;
		} else if (claim?.state !== 89) {
			//TOCHECK: Why a claim has a an ia job state 89
			// SHOW A DEFAULT BUTTON FOR CLAIMS
			const stateFlow = activeOrg.manifestItems[claim?.state];
			const instructions = stateFlow?.itemType === 'flow' && stateFlow?.instructions;
			if (instructions) {
				const editStates = user?.user?.edit_states;
				const userCanEdit = editStates?.includes(claim.state);
				const userRole = user?.user?.roles[0];
			}
		}
	},
	get_indicator_color: (claim, user, renderer, domElement: HTMLElement, activeOrg, allInfo, instance) => {
		const state = claim?.state;
		const editRoles = user?.user?.edit_states;
		const color = editRoles.includes(state) ? claimStateLookupTable[claim.state] : 'grey';
		instance.indicator.color = color;
	},
	display_sla_time: (claim, user, renderer, domElement: HTMLElement, activeOrg, allInfo, instance) => {
		const appendTime = () => {
			const slaTimeHolder = domElement.getElementsByClassName('sla-timer').item(0);
			if (slaTimeHolder) slaTimeHolder.childNodes.forEach(node => node.remove());
			const spanElement = renderer.createElement('span') as HTMLSpanElement;
			const currentSLA = allInfo?.states?.map(({ id, sla_time }) => ({ id, sla_time }))?.reduce((acc, { id, sla_time }) => ({ ...acc, [id]: sla_time }), {})[claim.state];
			const actualTime = moment()?.diff(moment(claim.state_change_date), 's');
			const setColor = () => {
				const result = (actualTime / currentSLA) * 100;
				if (result <= 80) {
					renderer.setStyle(spanElement, 'color', 'grey');
				} else if (result >= 81 && result <= 100) {
					renderer.setStyle(spanElement, 'color', '#DAA520');
				} else if (result >= 101) {
					renderer.setStyle(spanElement, 'color', 'red');
				}
			};
			const transform = (sec: number) => {
				return humaniseSeconds(sec);
			};
			setColor();
			const slaTime = transform(actualTime)?.toString();
			const timerText = renderer.createText(slaTime);
			renderer.appendChild(spanElement, timerText);
			if (slaTimeHolder) renderer.appendChild(slaTimeHolder, spanElement);
		};

		appendTime();
		return interval(60000).subscribe(() => {
			appendTime();
		});
	}
};
