<flx-heading size="medium" align="center" weight="extra-light" type="creation">Enter excess details for new job</flx-heading>
<flx-flex-container maxWidth="50vw" class="appointment-details">
	<flx-set-excess
		*ngIf="(whoPaysExcessOptions$ | async) && (whoPaysExcessOptions$ | async)"
		[mustIncludeSkillDetails]="mustIncludeSkillDetails"
		[whoPaysExcessOptions]="whoPaysExcessOptions$ | async"
		[howExcessIsPaidOptions]="howExcessIsPaidOptions$ | async"
		[excessData]="excessData"
	></flx-set-excess>
</flx-flex-container>
