import { Component, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { CurrentFrameData, FrameConfigurations, TwoFrameDynamicSelectionFrames } from './slide-navigation.component.interfaces';
import { take } from 'rxjs/operators';
import { cleanUpSub } from '@flexus/utilities';
import moment from 'moment';
import { FilterConfigs, FilterTypes } from '@flexus/models';

export interface ValueSelectionEvent {
	type: 'value-selected';
	values: Array<number | string>;
	// the bellow is the title of the entry that has been selected, aka its name
	selectedEntry: string;
	selectionType: FilterTypes;
}

@Component({
	selector: 'flx-slide-navigation',
	styleUrls: ['./slide-navigation.component.scss'],
	templateUrl: './slide-navigation.component.html'
})
export class SlideNavigationOrganism implements OnChanges, OnDestroy {
	currentFrameData: CurrentFrameData;
	// currentFrameConfig: { frameType: string; formGroup: FormGroup };
	frameValueSub: Subscription;
	selectedEntry: FilterConfigs;
	choice: string;
	@Output() valueSelection = new EventEmitter<ValueSelectionEvent>();
	@Input() availableFilters: FrameConfigurations;
	@Input() hasFilteredItems: boolean;

	constructor() {
		this.resetSelection();
	}

	public resetSelection() {
		this.currentFrameData = { currentFrame: TwoFrameDynamicSelectionFrames.closed };
		cleanUpSub(this.frameValueSub);
	}

	handleFrameEvent(currentFrame: number, event) {
		switch (currentFrame) {
			case 0: {
				// open
				// set current frame config so we can access it again later
				// this.currentFrameConfig = {
				// };
				const keys = Object.keys(this.availableFilters);
				const filterList = [];
				for (const entry of keys) {
					filterList.push({
						display: entry,
						value: entry
					});
				}
				// update the behavior subject so the ui will update
				this.currentFrameData = {
					currentFrame: 1,
					frameData: new BehaviorSubject(filterList),
					frameType: 'select',
					framePrompt: 'Select a filter',
					formGroup: new UntypedFormGroup({ initialSelect: new UntypedFormControl(null) })
				};
				this.frameValueSub = this.currentFrameData.formGroup?.valueChanges?.pipe(take(1)).subscribe(value => {
					//           value:
					// initialSelect: ["input"]
					this.handleFrameEvent(1, { value: value.initialSelect[0] });
				});
				//  create listener
				break;
			}
			case 1: {
				// render second frame form
				// const value = ;
				const choice = event.value;
				this.choice = choice;
				this.selectedEntry = this.availableFilters[choice];
				// make a for based on type
				const formGroup = new UntypedFormGroup({});
				const context = [];
				switch (this.selectedEntry.type) {
					case FilterTypes.input: {
						formGroup.addControl('control', new UntypedFormControl(null, [Validators.required]));
						break;
					}
					case FilterTypes.numericRange: {
						formGroup.addControl(
							'min',
							new UntypedFormControl(null, this.selectedEntry.min !== undefined ? [Validators.min(this.selectedEntry.min), Validators.required] : [Validators.required])
						);
						formGroup.addControl(
							'max',
							new UntypedFormControl(null, this.selectedEntry.max !== undefined ? [Validators.max(this.selectedEntry.max), Validators.required] : [Validators.required])
						);
						break;
					}
					case FilterTypes.select: {
						//  if its a select we need to generate the options and then later work back.
						// also create the behavior subject for the context
						const keys = Object.keys(this.selectedEntry.context);
						for (const entryKey of keys) {
							context.push({ display: entryKey, value: this.selectedEntry.context[entryKey] });
						}
						formGroup.addControl('select', new UntypedFormControl(null));
						// also should create the subscription
						this.frameValueSub = formGroup?.valueChanges?.pipe(take(1)).subscribe(value => {
							this.handleEnterKey();
						});
						break;
					}
					case FilterTypes.dateRange: {
						// console.log
						formGroup.addControl('startDate', new UntypedFormControl(null, [Validators.required]));
						formGroup.addControl('endDate', new UntypedFormControl(null, [Validators.required]));
						break;
					}
				}
				this.currentFrameData = {
					currentFrame: 2,
					frameType: this.selectedEntry.type,
					formGroup,
					framePrompt: this.selectedEntry.prompt,
					frameData: this.selectedEntry.type === 'select' ? new BehaviorSubject(context) : undefined
				};
				break;
			}
			case 2: {
				//  will have value passed into the event
				// process value and emit properly
				const values = [];
				switch (this.selectedEntry.type) {
					case FilterTypes.select: {
						values.push(event?.value?.select);
						// shoudl check for a context
						break;
					}
					case FilterTypes.input: {
						values.push(event?.value?.control);
						break;
					}
					case FilterTypes.numericRange: {
						values.push(event?.value?.min);
						values.push(event?.value?.max);
						break;
					}
					case FilterTypes.dateRange: {
						values.push(event?.value?.startDate);
						values.push(event?.value?.endDate);
						break;
					}
				}
				this.valueSelection.next({
					type: 'value-selected',
					values,
					selectedEntry: this.choice,
					selectionType: this.selectedEntry.type
				});
				this.resetSelection();
				break;
			}
		}
	}
	// will refactor this to have all functions pass through this specific one and be functional , but for now
	//  merely using the functionality exposed by the bellow will be good enough
	handleEnterKey() {
		if (this.currentFrameData.formGroup.valid === true) {
			const value =
				this.currentFrameData.frameType === 'dateRange'
					? {
							startDate: moment(this.currentFrameData.formGroup?.value?.startDate, 'MM-DD-YYYY'),
							endDate: moment(this.currentFrameData.formGroup?.value?.endDate, 'MM-DD-YYYY')?.add(1, 'days')
					  }
					: this.currentFrameData.formGroup.value;
			this.handleFrameEvent(this.currentFrameData.currentFrame, { value });
		}
		console.log('START DATE', moment(this.currentFrameData.formGroup?.value?.startDate, 'MM-DD-YYYY'));
	}
	handleTodayTomorrowSelection(event: any) {
		if (event.target.innerHTML === 'Today') {
			const today_at_six = moment();
			const today_at_midnight = moment();
			today_at_six.set({ hour: 6, minute: 0, second: 0, millisecond: 0 });
			today_at_midnight.set({ hour: 23, minutes: 59, second: 0, millisecond: 0 });
			today_at_six.toISOString();
			today_at_midnight.toISOString();
			today_at_six.format();
			today_at_midnight.format();
			const value =
				this.currentFrameData.frameType === 'dateRange'
					? {
							startDate: moment(today_at_six, 'MM-DD-YYYY'),
							endDate: moment(today_at_midnight, 'MM-DD-YYYY')
					  }
					: {};
			this.handleFrameEvent(this.currentFrameData.currentFrame, { value });
		} else if (event.target.innerHTML === 'Tomorrow') {
			const tomorrow = moment().add(1, 'days');
			const tomorow_at_midnight = moment().add(1, 'days');
			tomorrow.set({ hour: 6, minute: 0, second: 0, millisecond: 0 });
			tomorow_at_midnight.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
			const value =
				this.currentFrameData.frameType === 'dateRange'
					? {
							startDate: moment(tomorrow, 'MM-DD-YYYY'),
							endDate: moment(tomorow_at_midnight, 'MM-DD-YYYY')
					  }
					: {};
			this.handleFrameEvent(this.currentFrameData.currentFrame, { value });
		}
	}

	ngOnChanges() {
		if (!this.hasFilteredItems) {
			this.handleFrameEvent(0, null);
		}
	}

	ngOnDestroy(): void {
		cleanUpSub(this.frameValueSub);
	}
}
