import { Flow_0_0_2, getFullItemOne, getData, getFullItemTwo, getCurrentUser, SetNextNode } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { take, map, skipWhile, switchMap, pluck, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const BET_210: Flow_0_0_2 = {
	id: '210',
	name: 'review_assessor_decision',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map(itemOne => {
					if (itemOne) {
						return `Review Assessor Decision : ${itemOne?.mid} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Review Assessor Decision';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], { filesFunctionName: 'getAllClaimFiles' }),
	serverCalls: {
		claimDetails: {
			errorMessage: 'Could get assessor details',
			serviceVariable: 'betService',
			functionName: 'getClaimDetailsFormatted'
		},
		assessorDetails: {
			errorMessage: 'Could get assessor details',
			serviceVariable: 'betService',
			functionName: 'getAssessorDetailsformatted'
		},
		assessorSummary: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1),
						pluck('job_information', 'interstate_comments')
					)
					.pipe(
						map((interstate_comments: any) => {
							if (interstate_comments) {
								let message = '';
								if (Array.isArray(interstate_comments)) {
									message = interstate_comments[interstate_comments.length - 1]?.message?.toLowerCase();
								} else {
									message = interstate_comments.message?.toLowerCase();
								}

								const retMessage = {
									important_information: {
										"Assessor's Decision": ''
									}
								};

								switch (true) {
									case message.includes('approve'):
										retMessage.important_information["Assessor's Decision"] = 'Approval';
										break;
									case message.includes('recommendrejection'):
										retMessage.important_information["Assessor's Decision"] = 'Recommend Rejection';
										break;
									case message.includes('recommendcil'):
										retMessage.important_information["Assessor's Decision"] = 'Recommend CIL';
										break;
								}
								return retMessage;
							}
						})
					);
			}
		},
		files: {
			serviceVariable: 'betService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		latestReportFile: {
			errorMessage: 'Could not get latest report',
			directCall: (http, store, sq) => {
				return store.select(getData).pipe(
					pluck('latestReport'),
					skipWhile(x => !x),
					take(1),
					switchMap(latestReport =>
						http
							.post<any>(environment.api_url + 'v1/file_action/get_file', {
								file_id: latestReport.id,
								return_type: 1
							})
							.pipe(map(file => file.success && file.payload && { file: { base64: file.payload?.data, type: 'pdf' } }))
					)
				);
			}
		}
	},

	instructions: {
		editRoles: {
			0: 'Review Assessor Decision'
		},
		viewRoles: {
			0: 'Review Assessor Decision'
		}
	},
	startNode: 'ReviewAssessorDecision',
	nodes: {
		ReviewAssessorDecision: {
			showTabs: true,
			name: 'Assessor Details',
			component: {
				children: [
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							headingConfig: { title: 'Assessor Summary', color: 'default', size: 'medium' },
							subHeadingConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							keyValueHeadingConfig: { color: 'secondary' },
							objectInfo$: 'assessorSummary'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'claimDetails'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'assessorDetails'
						}
					}
				]
			},
			navs: [
				{
					text: 'Query SP',
					nextNode: 'QueryServiceProvider',
					color: 'default'
				},
				{
					text: 'Disagree With Decision',
					nextNode: 'DisagreeWithDecision',
					color: 'secondary'
				},
				{
					text: 'Agree With Decision',
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						decision: {
							errorMessage: '',
							directCall: (http, store, sq, bf, controller) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1),
										pluck('job_information', 'interstate_comments')
									)
									.pipe(
										tap((interstate_comments: any) => {
											bf.patchValues({ da_agree: true });
											if (interstate_comments) {
												let message = '';
												if (Array.isArray(interstate_comments)) {
													message = interstate_comments[interstate_comments?.length - 1]?.message?.toLowerCase();
												} else {
													message = interstate_comments?.message?.toLowerCase();
												}

												switch (true) {
													case message.includes('approve'):
														controller.dispatch(new SetNextNode('ApproveSubmissionSuccess'));
														break;
													case message.includes('recommendrejection'):
														controller.dispatch(new SetNextNode('RecommendRejectionSuccess'));
														break;
													case message.includes('recommendcil'):
														controller.dispatch(new SetNextNode('CILSubmissionSuccess'));
														break;
												}
											}
										})
									);
							}
						}
					}
				}
			]
		},
		AssessorReport: {
			name: "Assessor's Report",
			showTabs: true,
			initFormFields: (bf, item, inst, storeQuery, store) => {
				store
					.select(getData)
					.pipe(take(1), pluck('latestReportFile'))
					.subscribe(latestReport => {
						const latestReportControl = new UntypedFormControl([latestReport]);
						if (!bf.bigForm.get('fileQueue')) {
							bf.bigForm.addControl('fileQueue', latestReportControl);
						} else {
							bf.bigForm.setControl('fileQueue', latestReportControl);
						}
					});
			},
			component: 'FLXFileUploadWithPreviewComponent',
			inputs: {
				purposes: [],
				heading: 'Assessor Report',
				multipleUploads: false,
				allowedFileTypes: [],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			navs: [
				{
					text: 'Query SP',
					nextNode: 'QueryServiceProvider',
					color: 'default'
				},
				{
					text: 'Disagree With Decision',
					nextNode: 'DisagreeWithDecision',
					color: 'secondary'
				},
				{
					text: 'Agree With Decision',
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						decision: {
							errorMessage: '',
							directCall: (http, store, sq, bf, controller) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1),
										pluck('job_information', 'interstate_comments')
									)
									.pipe(
										tap((interstate_comments: any) => {
											bf.patchValues({ da_agree: true });
											if (interstate_comments) {
												let message = '';
												if (Array.isArray(interstate_comments)) {
													message = interstate_comments[interstate_comments.length - 1]?.message?.toLowerCase();
												} else {
													message = interstate_comments?.message?.toLowerCase();
												}

												switch (true) {
													case message.includes('approve'):
														controller.dispatch(new SetNextNode('ApproveSubmissionSuccess'));
														break;
													case message.includes('recommendrejection'):
														controller.dispatch(new SetNextNode('RecommendRejectionSuccess'));
														break;
													case message.includes('recommendcil'):
														controller.dispatch(new SetNextNode('CILSubmissionSuccess'));
														break;
												}
											}
										})
									);
							}
						}
					}
				}
			]
		},
		QueryServiceProvider: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Query Service Provider'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Query to Service Provider',
									inputType: 'textarea',
									formControlName: 'da_query'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.patchValues({ da_query: bf.getControl('da_query')?.value || '', new_state: 211 });
				bf.bigForm.get('da_query')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			checkValidityForFields: ['da_query'],
			navs: [
				{
					text: 'Submit To SP',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					},
					color: 'primary'
				}
			]
		},
		DisagreeWithDecision: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Disagree with decision'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: "Please explain why you disagree with the assessor's decision",
									inputType: 'textarea',
									formControlName: 'disagreeReasonField'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.patchValues({ disagreeReasonField: bf.getControl('disagreeReasonField')?.value || '' });
				bf.patchValues({ da_agree: false });
				bf.bigForm.get('disagreeReasonField')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			checkValidityForFields: ['disagreeReasonField'],
			navs: [
				{
					text: 'Recommend Rejection',
					nextNode: 'RecommendRejection',
					color: 'secondary',
					optIntoValidation: true
				},
				{
					text: 'Recommend CIL',
					nextNode: 'CILSubmissionSuccess',
					color: 'primary',
					optIntoValidation: true
				},
				{
					text: 'Approve',
					nextNode: 'ApproveSubmissionSuccess',
					color: 'primary',
					optIntoValidation: true
				}
			]
		},
		RecommendRejection: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Recommend Rejection'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Please provide a reason for rejection',
									inputType: 'textarea',
									formControlName: 'reasonField'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.patchValues({ reasonField: bf.getControl('reasonField')?.value || '', new_state: '94' });
				bf.bigForm.get('reasonField')?.setValidators([Validators.required, Validators.minLength(3)]);
				bf.addControl('interstateComment', new UntypedFormControl('recommendRejection'));
			},
			checkValidityForFields: ['reasonField'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					},
					color: 'primary'
				}
			]
		},
		ApproveSubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			serverCalls: {
				response: {
					errorMessage: '',
					directCall: (http, store, sq, bf, controller) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getCurrentUser).pipe(
								skipWhile(y => !y),
								take(1)
							)
						]).pipe(
							take(1),
							map(([job, user]: any) => {
								bf.patchValues({
									job_id: job?.id,
									current_state: job?.state
								});
								return {
									job_id: job?.id,
									current_state: job?.state,
									new_state: 86,
									job_information: {
										...job?.job_information,
										quote_approved_by: `${user?.user?.username ?? ''},${user?.user?.full_name ?? ''}`
									},
									Reason: `Quotation approved by:{${user?.user?.username ?? ''},${user?.user?.full_name ?? ''}}`
								};
							}),
							switchMap(dataSub => http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub))
						);
					}
				}
			},
			navs: []
		},
		RecommendRejectionSuccess: {
			component: 'FLXSuccessTickComponent',
			initFormFields: (bf, item, instance, storeQuery, store) => {},
			serverCalls: {
				response: {
					errorMessage: '',
					directCall: (http, store, sq, bf, controller) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getCurrentUser).pipe(
								skipWhile(y => !y),
								take(1)
							)
						]).pipe(
							take(1),
							map(([job, user]: any) => {
								bf.patchValues({
									job_id: job?.id,
									current_state: job?.state,
									new_state: 94
								});
								return {
									job_id: job?.id,
									current_state: job?.state,
									new_state: 94
								};
							}),
							switchMap(dataSub => http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub))
						);
					}
				}
			},
			navs: []
		},
		CILSubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.addControl('selectedJobs', new UntypedFormControl(''));
			},
			serverCalls: {
				response: {
					errorMessage: '',
					directCall: (http, store, sq, bf, controller) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getCurrentUser).pipe(
								skipWhile(y => !y),
								take(1)
							)
						]).pipe(
							take(1),
							map(([job, user]: any) => {
								bf.patchValues({
									job_id: job?.id,
									current_state: job?.state,
									cil_state: 93,
									new_state: 105,
									effected_jobs: [[...bf.bigForm.get('selectedJobs')?.value, bf.bigForm.get('itemTwo')?.value?.id]]
								});
								return {
									job_id: job?.id,
									current_state: job?.state,
									cil_state: 93,
									new_state: 105,
									effected_jobs: [[...bf.bigForm.get('selectedJobs')?.value, bf.bigForm.get('itemTwo')?.value?.id]]
								};
							}),
							switchMap(dataSub => http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub))
						);
					}
				}
			},
			navs: []
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		reasonField: 'job_information.diagnosis',
		da_agree: 'job_information.da_agree',
		new_state: 'new_state',
		da_query: [
			(qr, storeObj) => {
				let daq = [];
				const FI2 = storeObj['selectedContext']?.fullItemTwo;

				if (FI2?.job_information?.da_query) {
					if (Array.isArray(FI2?.job_information?.da_query)) {
						daq = FI2?.job_information?.da_query;
					} else {
						daq = [{ ...(<object>FI2?.job_information?.da_query) }];
					}
				}
				const ia_query = [...daq, { query: qr, queryreply: '' }];
				return ia_query;
			},
			'job_information.da_query'
		],
		disagreeReasonField: 'job_information.da_disagree_reason'
	}
};
