<flx-flex-container alignItems="center">
	<flx-heading weight="extra-light" size="medium" [align]="'center'">Please select an internal assessor for the job </flx-heading>
</flx-flex-container>
<br />
<flx-flex-container maxWidth="50vw" alignItems="flex-start" justifyContent="space-between" direction="row">
	<div [style.flex]="'1'">
		<flx-heading size="small" align="center">Available internal assessors</flx-heading>
		<div [formGroup]="bf.bigForm">
			<flx-select-list [canFilter]="true" [collapseOnSelect]="false" *ngIf="formattedData$" [options$]="formattedData$" formControlName="assign_assessor_id"></flx-select-list>
		</div>
		<div *ngIf="changeReason">
			<flx-heading size="small" align="center">Reason Assessor Was Requested</flx-heading>
			<form [formGroup]="bf.bigForm">
				<flx-dropdown
					[displayOptions]="displayConfig"
					[itemsOption]="IAreasons$ | async"
					[searchEnabled]="false"
					formControlName="ia_request_reason"
					placeHolder="Select a reason for appointing IA"
				>
				</flx-dropdown>
			</form>
		</div>
	</div>
	<div [style.flex]="'1'">
		<flx-heading size="small" align="center">Claim details</flx-heading>
		<flx-key-value-list [data$]="selectedInternalAssessorItem$"></flx-key-value-list>
		<div class="change-reason-button" *ngIf="!changeReason"><flx-button size="small" color="secondary" (click)="toggleReason()">Change Reason</flx-button></div>
	</div>
</flx-flex-container>
