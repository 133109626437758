import { getAllInfo, getFullItemOne, getSelectedItem, getSelectedItemTwo } from '@flexus/core';
import { findName } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin } from 'rxjs';
import { filter, map, pluck, skipWhile, switchMap, take } from 'rxjs/operators';

export const DATA_CALLS = {
	excess_on_job: {
		errorMessage: 'Could not retrieve excess',
		directCall: (http, store) => {
			return forkJoin([
				store.select(getFullItemOne).pipe(
					skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
					take(1)
				),
				store.select(getSelectedItemTwo).pipe(
					skipWhile((itemTwo: any) => itemTwo === null || itemTwo === undefined),
					take(1)
				),
				store.select(getAllInfo).pipe(take(1))
			]).pipe(
				map(([fullItemOne, selectedItemTwo, allInfo]: any) => {
					let excesses = {};

					const jobs = fullItemOne.jobs;
					const currentJobId = selectedItemTwo?.id;

					const { excess_who, excess_how, skills } = allInfo;

					const composeExcess = excess => {
						if (excess === undefined) {
							return [];
						}

						if (Array.isArray(excess)) {
							excess = excess.map(e => {
								return {
									amount: e.amount,
									how_collect: findName(e.how_collect, excess_how).name,
									who_collects: findName(e.who_collects, excess_who).name,
									reason: e.reason
								};
							});
						}
						return excess;
					};

					const formatJob = job => {
						let { skill, excess } = job;

						skill = findName(skill, skills).name;
						excess = composeExcess(excess);

						return {
							skill,
							excess
						};
					};

					if (Array.isArray(jobs) && jobs.length > 0) {
						let currentJob = jobs.filter(job => job?.id === currentJobId);
						let otherJobs = jobs.filter(job => job?.id !== currentJobId);

						currentJob = currentJob.map(x => {
							return formatJob(x);
						});

						otherJobs = otherJobs.map(j => {
							return formatJob(j);
						});

						excesses = { currentJob, otherJobs };
					}
					return excesses;
				})
			);
		}
	},
	excess_on_claim: {
		errorMessage: 'Could not retrieve excess',
		directCall: (http, store) => {
			return forkJoin([
				store.select(getFullItemOne).pipe(
					skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
					take(1)
				),
				store.select(getAllInfo).pipe(take(1))
			]).pipe(
				map(([fullItemOne, allInfo]: any) => {
					let excesses = {};

					let jobs = fullItemOne.jobs;

					const { excess_who, excess_how, skills, states, sps } = allInfo;

					const composeExcess = excess => {
						if (excess === undefined) {
							return [];
						}

						if (Array.isArray(excess)) {
							excess = excess.map(e => {
								return {
									amount: e.amount,
									how_collect: findName(e.how_collect, excess_how).name,
									who_collects: findName(e.who_collects, excess_who).name,
									reason: e.reason
								};
							});
						}
						return excess;
					};

					const formatJob = job => {
						let { skill, excess } = job;

						skill = findName(skill, skills).name;

						excess = composeExcess(excess);

						return {
							skill,
							excess
						};
					};

					if (Array.isArray(jobs) && jobs.length > 0) {
						jobs = jobs.map(x => {
							return formatJob(x);
						});

						excesses = { jobs };
					}
					return excesses;
				})
			);
		}
	},
	excess_on_claim_with_extra: {
		errorMessage: 'Could not retrieve excess',
		directCall: (http: any, store: any) => {
			return forkJoin([
				store.select(getFullItemOne).pipe(
					skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
					take(1)
				),
				store.select(getAllInfo).pipe(take(1))
			]).pipe(
				map(([fullItemOne, allInfo]: any) => {
					let excesses = {};

					let jobs = fullItemOne.jobs;

					const { excess_who, excess_how, skills, states, sps } = allInfo;

					const composeExcess = (excess: any) => {
						if (excess === undefined) {
							return [];
						}

						if (Array.isArray(excess)) {
							excess = excess.map(e => {
								return {
									amount: e.amount,
									how_collect: { name: findName(e.how_collect, excess_how).name, id: e.how_collect },
									who_collects: { name: findName(e.who_collects, excess_who).name, id: e.who_collects },
									reason: e.reason
								};
							});
						}
						return excess;
					};

					const formatJob = (job: any) => {
						let { skill, excess, sp, state, id } = job;

						skill = findName(skill, skills).name;
						sp = findName(sp, sps).name;
						state = { name: findName(state, states)?.description, id: state };
						excess = composeExcess(excess);

						return { id, skill, excess, sp, state };
					};

					if (Array.isArray(jobs) && jobs.length > 0) {
						jobs = jobs.map(x => {
							return formatJob(x);
						});

						excesses = { jobs };
					}
					return excesses;
				})
			);
		}
	},
	excess_options: {
		errorMessage: 'Could not retrieve excess options',
		directCall: (http: any, store: any) => {
			return store.select(getAllInfo).pipe(
				filter(x => !!x),
				take(1),
				map((all: any) => {
					const { excess_how, excess_states, excess_who } = all;
					return { excess_how, excess_states, excess_who };
				})
			);
		}
	},
	excess_on_jobs_api: {
		errorMessage: 'Could not retrieve excess',
		directCall: (http: any, store: any) => {
			return forkJoin([
				store.select(getSelectedItem).pipe(
					skipWhile((itemOne: any) => itemOne === null || itemOne === undefined),
					take(1)
				),
				store.select(getAllInfo).pipe(
					filter((x: any) => !!x),
					take(1)
				)
			]).pipe(
				map(([claim, all]: any) => {
					return { claim, all };
				}),
				switchMap((info: any) => {
					const claim = JSON.parse(JSON.stringify(info?.claim));
					const all = JSON.parse(JSON.stringify(info?.all));
					const { excess_who, excess_how, skills, states, sps, excess_states } = all;
					let jobs = claim?.jobs;

					const composeExcess = (excess: any) => {
						if (excess === undefined) {
							return [];
						}

						if (Array.isArray(excess)) {
							excess = excess.map(e => {
								return {
									amount: e?.amount,
									how_collect: { name: findName(e?.how_collect, excess_how)?.name, id: e?.how_collect },
									who_collects: { name: findName(e?.who_collects, excess_who)?.name, id: e?.who_collects },
									state: { name: findName(e.state, excess_states)?.name, id: e?.state },
									reason: e?.reason,
									excessId: e?.id
								};
							});
						}
						return excess;
					};

					const formatJob = (job: any) => {
						let { skill, sp, state, id } = job;
						skill = findName(skill, skills).name;
						sp = findName(sp, sps).name;
						state = { name: findName(state, states)?.description, id: state };

						return { id, skill, sp, state };
					};

					const ids = jobs.map((job: any) => job?.id);

					return http.post(environment.api_url + 'v1/job_action/get_excess_defaults/', { job_ids: ids }).pipe(
						filter(res => !!res),
						pluck('payload'),
						map(payload => {
							let jobExcessArr: any[] = [];
							if (Array.isArray(payload) && payload.length > 0) {
								payload.forEach(val => {
									jobExcessArr.push({ ...formatJob(jobs.filter((x: any) => x.id === val.job_id)[0]), excess: composeExcess(val.excess) });
								});
							}

							return [
								...jobExcessArr,
								...ids.filter((id: any) => !jobExcessArr.map(e => e.id).includes(id)).map(id => ({ ...formatJob(jobs.find((x: any) => x.id === id)), excess: [] }))
							];
						})
					);
				})
			);
		}
	},
	getAllJobFiles: {
		serviceVariable: 'silService',
		functionName: 'getJobAllFiles',
		responseSlice: 'payload',
		errorMessage: 'Could not get files from server!'
	},
	getAllClaimFiles: {
		serviceVariable: 'silService',
		functionName: 'getAllClaimFiles',
		responseSlice: 'payload',
		errorMessage: 'Could not get files from server!'
	},
	logs: {
		serviceVariable: 'silService',
		functionName: 'getCallLogs',
		errorMessage: 'No call logs could be found!'
	}
};

export const CONTEXT_DATA_CALLS = {
	excess_on_job: {
		...DATA_CALLS.excess_on_job
	},
	files: {
		...DATA_CALLS.getAllClaimFiles
	}
};
