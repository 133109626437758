import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { map, skipWhile, take, takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { from, BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { Reminder } from '@flexus/models';
import { ofType, Actions } from '@ngrx/effects';
import { addObjectProperty, cleanUpSub } from '@flexus/utilities';
import { IndexedDbService } from './storage-services';
import { ManifestController } from '../controllers';
import { BigFormService } from './big-form.service';
import { getActiveOrganization, getManifestSearchData } from '../manifest/manifest.selectors';
import { ResetServerSearch, SearchOnServer, SetFilterDataField } from '../dynamic-filter';

@Injectable({ providedIn: 'root' })
export class ReminderService {
	_activeOrg;
	private _configuration = {} as any;
	public minLength;

	constructor(
		private indexedDbService: IndexedDbService,
		private store: Store<any>,
		private actions$: Actions,
		private controller: ManifestController<any>,
		public bf: BigFormService
	) {
		forkJoin([
			controller.select(getManifestSearchData)?.pipe(
				skipWhile(x => !x),
				take(1)
			),
			controller.stateObservable.pipe(take(1)),
			controller.select(getActiveOrganization).pipe(
				skipWhile(x => !x),
				take(1)
			)
		])
			.pipe(take(1))
			.subscribe(([searchFunc, contr, org]: any) => {
				this._configuration = searchFunc(store, contr);
				this.minLength = this._configuration.options?.minLength;
				this._activeOrg = org;
			});
	}
	reminderActions = new BehaviorSubject<Function>(null);

	snoozeSubs: Subscription[] = [];
	currentSubs: { [id: string]: any } = {};
	currentRemindersSubscription: Subscription;

	getUserId() {
		return this.indexedDbService.user.toArray().then(user => {
			return +user;
		});
	}

	getReminders() {
		return from(
			this.getUserId().then(userID => {
				return this.indexedDbService.reminders.where('userID')?.equals(userID).toArray();
			})
		);
	}

	getActiveReminders() {
		return from(
			this.getUserId().then(userID => {
				return this.indexedDbService.activeReminders.where('userID')?.equals(userID).toArray();
			})
		);
	}

	// ======================================================= CRUD Operations =======================================================
	addReminder(reminder: Reminder) {
		return this.getUserId().then(userID => {
			const newRem = addObjectProperty<Reminder>('userID', userID, reminder);
			from(this.indexedDbService.reminders.put(newRem));
		});
	}

	deleteReminder(id: any) {
		cleanUpSub(this.currentSubs[id]);
		return from(this.indexedDbService.reminders.delete(id));
	}

	updateReminder(reminder: Reminder) {
		cleanUpSub(this.currentSubs[reminder.id]);
		const remObj = new Reminder(reminder);
		this.currentSubs[reminder.id] = remObj.triggerReminder$.pipe(takeUntil(this.actions$.pipe(ofType('LOGOUT_SUCCESS')))).subscribe(val => {
			// console.log('Trigger from update');
			this.addActiveReminder(val);
		});
		return from(this.indexedDbService.reminders.update(reminder.id, reminder));
	}

	addActiveReminder(reminder: Reminder) {
		return from(this.indexedDbService.activeReminders.put(reminder));
	}

	deleteActiveReminder(id: any) {
		return from(this.indexedDbService.activeReminders.delete(id));
	}

	// ======================================================= DISPLAY Reminder Methods =======================================================
	openReminderDirectly(func: (instance, store?: Store<any>, bf?: BigFormService) => void) {
		this.reminderActions.next(func);
	}

	// ======================================================= Check Reminder Methods =======================================================
	instantiateReminders() {
		// console.log('Instantiate Reminders');
		return from(this.indexedDbService.reminders.toArray()).pipe(
			map(currData => {
				const rem = currData === undefined || currData === null ? [] : currData;
				const currentTime = new Reminder({ date: moment().format('YYYY-MM-DD'), time: moment().format('HH:mm') });
				const activeRem = [];
				rem.forEach(reminder => {
					if (
						!this.compareRemindersDateTime(reminder, currentTime) ||
						(moment(reminder.date).isSame(moment().format('YYYY-MM-DD')) && moment(reminder.time).isAfter(currentTime.time))
					) {
						// Reminder has past and must display
						// console.log({ PastReminder: reminder });
						this.deleteReminder(reminder.id);
						this.addActiveReminder(reminder);
					} else {
						// Create subscription for current reminder
						// console.log({ CurrentReminder: reminder });
						activeRem.push(reminder);
						const remObj = new Reminder(reminder);
						this.currentSubs[remObj.id] = remObj.triggerReminder$.pipe(takeUntil(this.actions$.pipe(ofType('LOGOUT_SUCCESS')))).subscribe(val => {
							// console.log('Trigger current sub');
							this.addActiveReminder(val);
						});
					}
				});
				// Load reminders into the store
				this.getActiveReminders();
				this.getReminders();
				return activeRem;
			})
		);
	}

	activateReminders() {
		cleanUpSub(this.currentRemindersSubscription);
		Object.values(this.currentSubs).forEach((sub: Subscription) => cleanUpSub(sub));
		this.currentRemindersSubscription = this.instantiateReminders()
			.pipe(takeUntil(this.actions$.pipe(ofType('LOGOUT_SUCCESS'))))
			.subscribe(x => {
				console.log('Reminders Active', x);
			});
	}

	snooze(reminder) {
		this.deleteActiveReminder(reminder.id);
		this.snoozeSubs.push(
			this.snoozeReminder(reminder)
				.pipe(takeUntil(this.actions$.pipe(ofType('LOGOUT_SUCCESS'))))
				.subscribe(val => {
					this.addActiveReminder(val);
				})
		);
		this.addReminder(reminder);
	}

	snoozeReminder(reminder) {
		const rem = new Reminder(reminder);
		return rem.triggerReminder$;
	}

	// ======================================================= Sorting methods =======================================================
	compareRemindersDateTime(a: Reminder, b: Reminder) {
		const momentA = moment(a.date).set({ hour: Number(a.time.split(':')[0]), minute: Number(a.time.split(':')[1]) });
		const momentB = moment(b.date).set({ hour: Number(b.time.split(':')[0]), minute: Number(b.time.split(':')[1]) });
		return momentA.isAfter(momentB);
	}

	getCurrentReminders = rems => rems.filter(rem => moment(rem.date).set({ hour: Number(rem.time.split(':')[0]), minute: Number(rem.time.split(':')[1]) }) >= moment());

	sortByDateAndTime = (a: Reminder, b: Reminder) => {
		return this.compareRemindersDateTime(a, b) ? 1 : -1;
	};

	doClaimSearch() {
		if (this.minLength ? this.bf.bigForm?.value?.search?.inSearch?.length > this.minLength - 1 : this.bf.bigForm?.value?.search?.inSearch?.length > 3) {
			const { inSearch, checkClosed } = this.bf.bigForm?.value?.search || '';
			this.store.dispatch(new SetFilterDataField({ key: 'searchField', data: { inSearch, checkClosed } }));
			this.store.dispatch(
				new SearchOnServer({
					endpoint: this._configuration.searchEndpoint,
					active: !checkClosed,
					search: inSearch,
					virtualStatesFunction: this._activeOrg.virtualStatesFunction
				})
			);
		}
	}

	clearClaimSearch() {
		this.store.dispatch(new SetFilterDataField({ key: 'searchField', data: {} }));
		this.bf.bigForm.get('search')?.setValue({ checkClosed: false, inSearch: '' });
		this.store.dispatch(new ResetServerSearch());
	}
}
