<flx-heading class="component-header" size="medium" weight="extra-light" type="creation" align="center">Bank Account Details</flx-heading>
<flx-heading class="component-header" size="small" weight="extra-light" type="form" color="sub-heading" align="center">
	Enter details of the account to which payment will be made
</flx-heading>
<form class="form-container" novalidate [formGroup]="bankDetailsForm">
	<flx-flex-container direction="row" justifyContent="center" maxWidth="50vw">
		<flx-panel density="cozy">
			<flx-label class="form-radio individual">
				<flx-radio id="individualAccount" value="Individual" (change)="toggleBankAccountType()" [checked]="individual" formControlName="account_use" data-cy="individualRadio">
					Individual
				</flx-radio>
			</flx-label>

			<ng-container *ngIf="name !== 'Company Name'">
				<flx-label>Initials</flx-label>
				<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="initials"></flx-input-text>
				<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['initials']"> </flx-reactive-form-error-display>
			</ng-container>

			<flx-label>{{ name }}</flx-label>
			<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="account_holder_name"></flx-input-text>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['account_holder_name']"> </flx-reactive-form-error-display>

			<flx-label>{{ identification }}</flx-label>
			<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="identification_number"></flx-input-text>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['identification_number']"> </flx-reactive-form-error-display>

			<flx-label>Account Number</flx-label>
			<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="account_number"></flx-input-text>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['account_number']"> </flx-reactive-form-error-display>
		</flx-panel>
		<flx-panel density="cozy">
			<flx-label class="form-radio company">
				<flx-radio id="companyAccount" value="Company" (change)="toggleBankAccountType()" [checked]="!individual" formControlName="account_use" data-cy="companyRadio">
					Company / Trust
				</flx-radio>
			</flx-label>

			<ng-container *ngIf="accountTypes$ | async as accountTypes">
				<ng-container *ngIf="accountTypes.length > 1 && accountTypes !== null">
					<flx-label>Account Type</flx-label>
					<flx-dropdown
						formControlName="acc_type"
						[displayOptions]="{ displayKey: 'description', valueKey: 'mid' }"
						[itemsOption]="accountTypes"
						placeHolder="Account Type"
						[searchEnabled]="false"
					></flx-dropdown>
				</ng-container>
			</ng-container>

			<flx-label>Bank</flx-label>
			<ng-container *ngIf="bankNames && bankNames.length !== 0">
				<flx-dropdown
					formControlName="bank_name"
					[displayOptions]="{ displayKey: 'name', valueKey: 'name' }"
					[itemsOption]="bankNames"
					placeHolder="Bank Name"
					[searchEnabled]="true"
				></flx-dropdown>
			</ng-container>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['bank_name']"> </flx-reactive-form-error-display>

			<flx-label> Branch Name </flx-label>
			<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="branch" placeholder="eg. Four"> </flx-input-text>
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm)?.controls['branch']"> </flx-reactive-form-error-display>

			<flx-label>Branch Code</flx-label>
			<flx-input-text [maxlength]="30" type="text" size="normal" formControlName="branch_code"></flx-input-text
			><!-- [isDisabled]="!!bankCodeLookupEvent"-->
			<flx-reactive-form-error-display [control]="$any(bankDetailsForm).controls['branch_code']"> </flx-reactive-form-error-display>
		</flx-panel>
	</flx-flex-container>
</form>
