import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { map } from 'rxjs/operators';

export const BUI_VIEW_CLAIM_DOCUMENTS: Flow_0_0_2 = {
	id: 'ViewClaimDocuments',
	itemType: 'flow',
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	excludeForOnlyStates: [169],
	excludeForOnlyRoles: [18],
	name: 'View Documents',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						return `View Documents : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'View Documents';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ViewDocuments',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'silService',
			functionName: 'getAllClaimFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	nodes: {
		ViewDocuments: {
			component: 'ViewDocumentsComponent',
			inputs: {
				heading: 'View Documents',
				location: 'context-menu'
			},
			initFormFields: (bf, item, instance) => {},
			navs: []
		}
	}
};
