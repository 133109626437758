import { BigFormService, Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { setActionPanelItems } from '../../../../app-shell-features';
import { KVLHeading } from '@flexus/ui-elements';
import { skipWhile, take, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

export const MUL_297: Flow_0_0_2 = {
	id: '297',
	name: 'confirm_customer_activation',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Confirm Customer Activation',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Confirm customer was activated.'
		},
		viewRoles: {
			0: 'Confirm customer was activated.'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'confirmCustomerActivation',
	nodes: {
		confirmCustomerActivation: {
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('mc_customer_activation_status', new UntypedFormControl('', []));
				_bf.addControl('new_state', new UntypedFormControl(26, []));
			},
			serverCalls: {
				important_information: {
					errorMessage: 'Could not get the important information',
					directCall: (_http: HttpClient, _store: Store) => {
						const list: any[] = [];
						list.push({
							'Reason for failure': 'Activation pack has not been received'
						});

						return of(list);
					}
				},
				installer_details: {
					errorMessage: 'Cannot get job info summary',
					directCall: (_http: HttpClient, _store: Store) => {
						return _store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								take(1),
								map(info => {
									const { claim, team_leader } = info;
									const list: any = [];
									// Installer details

									list.push({
										company_name: team_leader?.sp?.name ?? '',
										accreditation_number: team_leader?.sp?.accreditation_number ?? '',
										'Installation technician qualification number': team_leader?.qualification_number ?? '',
										'Installer/Technician name': team_leader?.full_name ?? '',
										contact_person: team_leader?.sp?.contact_person ?? '',
										contact_number: team_leader?.sp?.contact_primary ?? ''
									});

									return list;
								})
							);
					}
				},
				customer_details: {
					errorMessage: 'Cannot get job info summary',
					directCall: (http, store) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								take(1),
								map(info => {
									const { claim, team_leader, identities } = info;
									const list: any = [];
									if (claim?.applicant) {
										list.push({
											full_name: claim?.applicant?.first_name + ' ' + claim?.applicant?.surname,
											customer_id: claim?.applicant?.id_number ?? '',
											subscriber_number: claim?.applicant?.information?.dstv_account_number ?? '',
											installation_address: claim?.address,
											email_address: claim?.loan_information?.email ?? claim?.loan_information?.Email ?? '',
											contact_number: claim?.applicant?.contact_number,
											GPS_Coordinates: claim?.location
										});
									}
									return list;
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Confirm Customer Activation',
							instructions: ['We have not received the customer activation pack.', 'Confirm on VMS that the customer has been activated.']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							data$: 'important_information',
							color: 'secondary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installer Details',
							data$: 'installer_details',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Contact Details',
							data$: 'customer_details'
						}
					}
				]
			},
			navs: [
				{
					text: 'customer is active',
					serverCalls: {
						response: { serviceVariable: 'mulService', functionName: 'updateJob', errorMessage: 'Could not update job' }
					},
					color: 'primary'
				}
			]
		},
		// jobInformation: {
		// 	...jobInfoNode(297, 26)
		// },
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		// mc_customer_activation_status: [() => 'manual_active', 'job_information.mc_customer_activation_status']
		mc_customer_activation_status: [() => 'manually_active', 'office_use.mc_customer_activation_status']
	}
};
