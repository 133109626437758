<flx-heading type="page" size="medium">Bucket Filter</flx-heading>

<div *ngIf="configuration && !this.selectedBucket; else removeBucket">
	<!-- <flx-heading type="form"  size="extra-small">Select a bucket to filter by</flx-heading> -->
	<!-- <flx-glow-line margin="1rem 0"></flx-glow-line> -->
	<div>
		<ng-container *ngFor="let bucketName of filterList">
			<div class="bucket-list">
				<flx-button display="block" size="small" align="left" (click)="selectBucket(bucketName)" [allCaps]="false"> {{ bucketName }} </flx-button>
			</div>
		</ng-container>
	</div>
</div>
<ng-template #removeBucket>
	<div class="bucket-info">
		<flx-heading size="extra-small" color="secondary">Current bucket:</flx-heading>
		<flx-heading type="form" size="extra-small">{{ selectedBucket }}</flx-heading>
	</div>
	<flx-button (click)="clearBucket()" color="secondary" display="block">clear bucket</flx-button>
</ng-template>
