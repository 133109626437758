import { Flow_0_0_2, getFullItemTwo, MakeServerCall, getCurrentUser, getFullItemOne, SetNextNode } from '@flexus/core';
import { take, map, filter, skipWhile, switchMap, takeUntil } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import gql from 'graphql-tag';
import { forkJoin, EMPTY } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, actionPanelItems, setActionPanelItems } from '../../../../app-shell-features';

export const SIL_96: Flow_0_0_2 = {
	id: '96',
	name: 'awaiting assessor_review',
	itemType: 'flow',
	fetchLevel1And2: true,
	onStateInit: instance => {
		const paramFunc = inst => actionPanelItems(inst)?.find(x => x?.id === 'documents');
		instance.navService.portalActions.next({ call: 'onClick', paramFunc });
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					if (itemOne) {
						let title = 'Awaiting Assessor Review';
						if (itemOne?.loan_information?.mavenclaimnumber) {
							title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
						}
						if (itemOne.applicant?.surname) {
							title += ` - ${itemOne.applicant?.surname}`;
						}

						return title;
					} else {
						return 'Awaiting Assessor Review';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Review'
		},
		viewRoles: {
			0: 'Wait for Assessor to review'
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'notes', 'time-stamp', 'query-history', 'policy-history']),
	serverCalls: {
		dataFile: {
			serviceVariable: '',
			functionName: '',
			errorMessage: 'No file could be found!',
			loaderID: 'dataFile',
			directCall: (http, store, sq) => {
				return sq
					.queryStore(
						gql`
							{
								selectedContext {
									latestQuotation {
										id
									}
								}
							}
						`
					)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1),
						switchMap(quotation => {
							return http
								.post<any>(environment.api_url + `v1/file_action/get_file/`, {
									file_id: quotation?.id,
									return_type: 1
								})
								.pipe(
									filter(x => !!x && Object.keys(x).length !== 0),
									take(1),
									map(value => {
										return value.payload;
									})
								);
						})
					);
			}
		},
		skills: {
			serviceVariable: 'silService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills could be found!',
			loaderID: 'skills'
		},
		iaReason: {
			serviceVariable: '',
			functionName: '',
			errorMessage: 'No ia reason found',
			loaderID: 'iaReason',
			directCall: (http, store, sq) => {
				return store.select(getFullItemOne).pipe(
					skipWhile((x: any) => !x || !x.loan_information),
					take(1),
					map((claim: any) => {
						return claim?.loan_information.ia_requested_reason || 'No IA requested reason found!';
					})
				);
			}
		},
		claimSummary: {
			errorMessage: '',
			loaderID: 'claimSummary',
			directCall: (http, store, sq) => {
				return sq
					.queryObject(
						gql`
							{
								fullItemOne {
									loan_information {
										claimtype
										dateofloss
									}
								}
							}
						`,
						store.select(getFullItemOne).pipe(
							skipWhile(f => !f),
							take(1),
							map(res => {
								return { fullItemOne: res };
							})
						)
					)
					.pipe(
						map((res: any) => {
							return {
								claim_details: [
									{
										claim_type: res?.claimtype,
										date_of_loss: res?.dateofloss
									}
								]
							};
						})
					);
			}
		}
	},
	startNode: 'InternalAssessorJobOverview',
	nodes: {
		InternalAssessorJobOverview: {
			intersectData: (bf, comp) => {},
			component: 'FLXInternalAssessorJobOverviewComponent',
			inputs: {
				usedInContextMenu: false,
				continueFunc: instance => {
					instance._store.dispatch(
						new MakeServerCall({
							serviceVariable: 'silService',
							errorMessage: `No data returned for InternalAssessorJob'`,
							directCall: (http, store, sq, bf, controller) => {
								return forkJoin([
									instance._store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									instance._store.select(getCurrentUser).pipe(
										skipWhile(y => !y),
										take(1)
									)
								]).pipe(
									take(1),
									map(([job, user]: any) => {
										instance?.bf?.patchValues({
											job_id: job?.id,
											current_state: job?.state
										});

										return {
											job_id: job?.id,
											current_state: job?.state,
											new_state: 86,
											job_information: {
												...job?.job_information,
												quote_approved_by: `${user?.user?.username ?? ''},${user?.user?.full_name ?? ''}`
											},
											Reason: `Quotation approved by:{${user?.user?.username ?? ''},${user?.user?.full_name ?? ''}}`
										};
									}),
									switchMap(dataSub => {
										controller.dispatch(new SetNextNode('SubmissionSuccess'));
										return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
									})
								);
							}
						})
					);
				},
				openActionPanel: instance => {
					const paramFunc = inst => actionPanelItems(inst)?.find(x => x?.id === 'query-history');
					instance.navService.portalActions.next({ call: 'onClick', paramFunc });
				}
			},
			checkValidityForFields: ['quote_amount'],
			initFormFields: bf => {
				return bf.bigForm?.valueChanges
					.pipe(
						filter(x => x.itemTwo),
						take(1)
					)
					.subscribe((val: any) => {
						if (val?.itemTwo) {
							const quote_amount = val.itemTwo?.job_information?.quote_amount ?? '';
							bf.addControl('quote_amount', new UntypedFormControl(quote_amount?.replace(',', ''), Validators.required));
							bf.addControl('job_id', new UntypedFormControl(val.itemTwo?.id));
							bf.addControl('current_state', new UntypedFormControl(val.itemTwo?.state));
						}
					});
			}
		},
		QueryInvoice: {
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							inputHeading: 'Query',
							dataFiles$: 'dataFile'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: { formControlName: 'query', inputType: 'textarea', label: 'Enter your query below' }
							}
						}
					}
				]
			},
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			checkValidityForFields: ['query'],

			initFormFields: bf => {
				bf.patchValues({ query: bf.getControl('query')?.value || '', new_state: 91 });
				bf.bigForm.get('query')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		RecommendCIL: {
			inputs: {
				header: 'Recommend Cash in Lieu',
				instruction: 'Select other jobs in this claim that you want the same recommendation to apply to'
			},
			component: 'InternalAssessorSelectOtherJobsComponent',
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('effected_jobs', new UntypedFormControl(''));
				bf.patchValues({ cil_state: 93 });
				bf.patchValues({ new_state: 105 });
				bf.patchValues({
					effected_jobs: [bf.bigForm.get('itemTwo')?.value?.id]
				});
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'recommendCil',
							errorMessage: 'Job could not be moved to cil!!'
						}
					}
				}
			]
		},
		RecommendRejection: {
			checkValidityForFields: ['reasonField'],
			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ reasonField: bf.getControl('reasonField')?.value || '' });
				bf.patchValues({ new_state: 94 });
				bf.bigForm.get('reasonField')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							inputHeading: 'Recommend Rejection',
							subHeading: 'Please provide a reason for rejection in the text box below',
							dataFiles$: 'dataFile'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: { formControlName: 'reasonField', inputType: 'textarea', label: 'Rejection reason:' }
							}
						}
					}
				]
			},
			navs: [
				{ text: 'Other Jobs', nextNode: 'RecommendCIL' },
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		RecommendLossAdjuster: {
			checkValidityForFields: ['recommendLossAdjusterReason'],
			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ recommendLossAdjusterReason: bf.getControl('recommendLossAdjusterReason')?.value || '' });
				bf.patchValues({ new_state: '99' });
				bf.bigForm.get('recommendLossAdjusterReason')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							inputHeading: 'Recommend Loss Adjuster',
							subHeading: 'Please provide a reason for recommending a loss adjuster in the text box below',
							dataFiles$: 'dataFile'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'recommendLossAdjusterReason',
									inputType: 'textarea',
									label: 'Recommend Loss Adjuster Reason:'
								}
							}
						}
					}
				]
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		current_state: 'current_state',
		new_state: 'new_state',
		effected_jobs: 'effected_jobs',
		cil_state: 'cil_state',
		reasonField: 'job_information.Diagnosis',
		recommendLossAdjusterReason: 'Reason',
		query: [
			(query, storeObj) => {
				const fullItemTwo = storeObj['selectedContext']?.fullItemTwo;

				const queries = fullItemTwo?.job_information?.ia_query;

				const ia_query = Array.isArray(queries) ? [...queries] : queries && typeof queries === 'object' && Object.keys(queries).length > 0 ? [{ ...queries }] : [];

				return [...ia_query, { id: ia_query.length + 1, query, query_datetime: new Date().toISOString(), queryreply: '', queryreply_datetime: '' }];
			},
			'job_information.ia_query'
		]
	}
};
