import { Flow_0_0_2, getFullItemOne, getFullItemTwo, SetNextNode } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { skipWhile, map, take, switchMap, first, filter, pluck, retryWhen, mergeMap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { UntypedFormControl, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { genericRetryStrategy } from '@flexus/utilities';

export const SIL_107: Flow_0_0_2 = {
	id: '107',
	name: 'create_agreement_of_loss',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(data => data?.applicant !== null || data?.applicant !== undefined),
				map(itemOne => {
					if (itemOne) {
						return 'Create AOL : ' + itemOne?.loan_information.mavenclaimnumber + ' - ' + itemOne?.applicant?.surname;
					} else {
						return 'Create AOL';
					}
				})
			);
		},
		controls: () => () => []
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'documents', 'notes']),
	serverCalls: {
		invoice: {
			errorMessage: 'No invoice found!',
			directCall: (http, store) => {
				return store.select(getFullItemTwo).pipe(
					filter(x => !!x),
					first(itemOne => itemOne !== null || itemOne !== undefined),
					switchMap(itemTwo => {
						return http
							.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
								job_id: itemTwo['id'],
								return_type: 1
							})
							.pipe(
								map(value => {
									return value.payload;
								})
							);
					})
				);
			}
		}
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Create Agreement of Loss Letter'
		},
		viewRoles: {
			0: '--'
		}
	},
	// startNode: 'CreateAOL',
	startNode: 'AOL_Genrated_Check',
	nodes: {
		AOL_Genrated_Check: {
			nodeType: 'decision',
			decisions: {
				check_for_AOL_ID: (navs, store, modal, controller) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1)
						)
						.subscribe((job: any) => {
							if (job?.office_use?.last_aol_document?.aol_id) controller.dispatch(new SetNextNode('PreviewAOL'));
							else controller.dispatch(new SetNextNode('CreateAOL'));
						});
				}
			},
			navs: [{ text: 'Success', nextNode: 'SubmissionSuccess' }]
		},
		CreateAOL: {
			component: 'AgreementOfLossComponent',
			name: 'Create Agreement of Loss',
			// serverCalls: {},
			initFormFields: (bf, item, inst, sq, store) => {
				if (bf.bigForm.get('dataFile')) {
					bf.bigForm.removeControl('dataFile');
				}
				bf.bigForm.addControl('invoiceRowItems', new UntypedFormControl());
				store
					.select(getFullItemTwo)
					.pipe(first(job => job !== null))
					.subscribe(({ excess }) => {
						bf.bigForm.addControl('excess', new UntypedFormControl(excess.reduce((a, b) => (a += Number(b.amount)), 0)));
					});
				bf.bigForm.addControl('total', new UntypedFormControl(0));
			},
			checkValidityForFields: ['agreementOfLossDetail'],
			navs: [
				{
					text: 'Create AOL Letter',
					nextNode: 'PreviewAOL',
					color: 'primary',
					disableOnLoad: true,
					linkType: 'submit',
					serverFirst: true,
					serverCalls: {
						createAOL: {
							errorMessage: 'Could not create Agreement of Loss letter!',
							followUpSuccessCalls: {
								dataFiles: {
									errorMessage: 'No AOL Letter found!',
									directCall: (http, store, sq, bf) => {
										bf.bigForm.addControl('dataFile', new UntypedFormControl(null, [Validators.required]));

										return of({});
									}
								}
							},
							directCall: (http, store, sq, bf) => {
								const items = bf.bigForm.get('invoiceRowItems')?.value?.reduce((a, b) => a + b.item + '|' + b.price + '~', '');
								const currentJob = bf.bigForm.get('itemTwo')?.value;
								const office_use = currentJob?.office_use;
								const data = {
									job_id: currentJob?.id,
									excess: bf.bigForm.get('excess')?.value?.toString(),
									account_name: office_use?.['initials']
										? currentJob?.office_use?.['initials'][0] + ' ' + currentJob?.office_use?.['surname']
										: currentJob?.office_use?.['surname'] ?? '',
									bank_acc_number: office_use?.['bank_acc_number'] ?? '',
									branch: office_use?.['branch'] ?? '',
									bank: office_use?.['bank'] ?? '',
									email: currentJob?.claim?.loan_information?.email ?? '',
									address: currentJob?.address ?? '',
									items: items?.replace(/~$/, '')
								};
								return http.post<any>(`${environment.api_url}v1/job_action/create_aol_letter/`, data).pipe(pluck('payload'));
							}
						}
					}
				}
			]
		},
		// Preview AOL Letter
		PreviewAOL: {
			component: 'FileViewWithExtraComponent',
			serverCalls: {
				dataFiles: {
					errorMessage: 'No AOL Letter found!',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							switchMap((data: any) => {
								return http
									.post<any>(`${environment.api_url}v1/job_action/preview_aol_letter/`, {
										job_id: data?.id
									})
									.pipe(
										retryWhen(
											genericRetryStrategy({
												maxRetryAttempts: 5,
												scalingDuration: 1500,
												includeErrorReasons: ['There is no letter to preview at the moment, please try again later.']
											})
										)
									);
							}),
							map(data => {
								bf.bigForm.addControl('dataFile', new UntypedFormControl(null, [Validators.required]));
								bf.bigForm.get('dataFile')?.patchValue(data?.payload.document);
								return { data: data?.payload.document };
							})
						);
					}
				}
			},
			name: 'Create Agreement of Loss',
			initFormFields: (bf, item, inst, sq, store) => {
				const newState = bf.bigForm.get('itemOne')?.value?.loan_information?.isjointaccount === 'Yes' ? 59 : 58;
				if (bf.bigForm.get('new_state')) {
					bf.bigForm.patchValue({ new_state: newState });
				} else {
					bf.bigForm.addControl('new_state', new UntypedFormControl(newState));
				}
			},
			checkValidityForFields: ['dataFile'],
			inputs: {
				inputHeading: 'Preview Agreement of Loss Letter',
				loading: true,
				loadingText: 'Fetching AOL Letter (+-2 Min)',
				fileDoesNotExistMessage: 'No file to display.'
			},
			navs: [
				{
					text: 'Fix Details',
					nextNode: 'CreateAOL',
					color: 'secondary',
					optIntoValidation: true,
					serverFirst: true
				},
				{
					text: 'Send Letter To Client',
					nextNode: 'sendAol',
					color: 'primary',
					serverFirst: true,
					linkType: 'submit',
					optIntoValidation: true,
					serverCalls: {
						sendAol: {
							errorMessage: 'Could not send AOL Letter',
							directCall: (http, store, sq, bf) => {
								return http.post<any>(`${environment.api_url}v1/job_action/send_aol_letter/`, {
									job_id: bf.bigForm.get('itemTwo')?.value?.id
								});
							},
							followUpSuccessCalls: {
								changeState: {
									errorMessage: 'Error changing state',
									serviceVariable: 'silService',
									functionName: 'updateJob'
								}
							}
						}
					}
				}
			]
		},
		sendAol: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemTwo.id': 'job_id',
		'itemTwo.state': 'current_state'
	}
};
