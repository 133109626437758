import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, getFullItemTwo, getSelectedItem } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';

@Component({
	selector: 'flx-refund-calculator',
	templateUrl: 'pinggo-refund-calculator.component.html',
	styleUrls: ['pinggo-refund-calculator.component.scss']
})
export class PinggoVoucherRefundCalculatorComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() refundVariables$!: Observable<any>;
	@Input() isAuthorise!: boolean;
	refundSubscription!: Subscription;
	subheading = 'Make any additional changes';
	// isAuthorise = true;
	refundCalcFormGroup!: UntypedFormGroup;
	netresult!: any;
	refund_amount_due!: any;
	voucheramount!: number;
	client_value!: number;
	sp_value!: any;
	installer_incentive;
	installer_additional!: any;
	installerpayment!: any;
	vatpercentage: number = 15;
	vat_for_display_sp;
	vat_for_display_customer;
	payment_to_installer_ex_vat;
	payment_to_customer_ex_vat;
	voucher_with_vat;
	state: any;
	valueSub: Subscription;
	incentiveAdditionalSub: Subscription;
	amountsControl = this.bf.getControl('amounts');
	key_value_list$: Observable<any>;

	constructor(public bf: BigFormService, private cdr: ChangeDetectorRef, private _store: Store<any>) {
		this._store
			.select(getSelectedItem)
			.pipe(
				skipWhile((selected: any) => !selected),
				map((item: any) => {
					this.state = item?.state;
				})
			)
			.subscribe();
	}

	ngOnInit() {
		this.key_value_list$ = of([
			{
				'VAT Vendor': 'Yes',
				'Already Invoiced': 'No',
				'SP been to site': 'Yes',
				'SP Completed Work': 'Yes'
			}
		]);
		this.bf.addControl('pay_refund', new UntypedFormControl(0, [Validators.required]));
		this.refundSubscription = this.refundVariables$
			.pipe(
				skipWhile(x => !x),
				take(1),
				map((data: any) => {
					console.log('REFUND DATA CALC', data);

					this.voucheramount = +data?.refundVariableObj?.voucher_amount;
					this.client_value = +data?.refundVariableObj?.client_value;
					// this.sp_value = new DecimalPipe('en-US').transform(+data?.refundVariableObj?.sp_value, '1.2-2');
					this.sp_value = parseFloat(data?.refundVariableObj?.sp_value);
					// this.installer_incentive = new DecimalPipe('en-US').transform(data?.refundVariableObj?.suggested_amounts?.incentive, '1.2-2');
					this.installer_incentive = parseFloat(data?.refundVariableObj?.suggested_amounts?.incentive);
					this.installer_additional = parseFloat(data?.refundVariableObj?.suggested_amounts?.additional);

					console.log('IIINCENTIVE TYPE', typeof this.installer_incentive, this.installer_incentive);
					this.getIncentiveAndAdditional();
					this.cdr.detectChanges();
					this.initialiseForm();
				})
			)
			.subscribe();
	}

	initialiseForm() {
		this.amountsControl.get('ins_installer_fee').setValue(this.sp_value);
		this.amountsControl.get('cus_takealot_fee').setValue(this.client_value);
		this.amountsControl.get('ins_installer_incentive').setValue(this.installer_incentive);
		// this.amountsControl.get('ins_installer_additional').setValue(this.installer_additional);
		this.amountsControl.get('ins_installer_additional').setValue(new DecimalPipe('en-US').transform(Number(this.installer_additional), '1.2-2'));
		this.amountsControl.get('cus_installer_fee').setValue(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
		this.amountsControl.get('cus_installer_incentive').setValue(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
		this.amountsControl.get('cus_installer_additional').setValue(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
		//
		const installerfee = +this.amountsControl.get('ins_installer_fee').value;
		const incentive = +this.amountsControl.get('ins_installer_incentive').value;
		const additional = +this.amountsControl.get('ins_installer_additional').value;
		const deductions = installerfee + incentive + additional;

		this.payment_to_installer_ex_vat = installerfee + incentive + additional;
		this.payment_to_customer_ex_vat = this.voucheramount - deductions;
		this.voucher_with_vat = (this.voucheramount * this.vatpercentage) / 100 + this.voucheramount;
		this.vat_for_display_sp = (this.payment_to_installer_ex_vat * this.vatpercentage) / 100;
		this.installerpayment = this.payment_to_installer_ex_vat + this.vat_for_display_sp;
		this.vat_for_display_customer = (this.payment_to_customer_ex_vat * this.vatpercentage) / 100;
		this.refund_amount_due = this.voucheramount - deductions + this.vat_for_display_customer;
		this.netresult = this.voucher_with_vat - (this.installerpayment + this.refund_amount_due);
		this.cdr.detectChanges();
	}
	ngAfterViewInit() {
		this.calculateAmounts(this.state);
	}
	calculateAmounts(state: number) {
		const amountsControl = this.bf.getControl('amounts');

		if (state === 261) {
			this.valueSub = this.bf.bigForm.get('amounts')?.valueChanges.subscribe(value => {
				// constants
				const payment_to_installer = +value.ins_installer_fee + +value.ins_installer_incentive + +value.ins_installer_additional;
				const payment_client = +value.cus_takealot_fee;
				const customer_refund_liable = +value.cus_installer_fee + +value.cus_installer_incentive + +value.cus_installer_additional;
				//
				this.payment_to_installer_ex_vat = payment_to_installer;
				this.vat_for_display_sp = (this.payment_to_installer_ex_vat * this.vatpercentage) / 100;
				this.payment_to_customer_ex_vat = customer_refund_liable;
				this.vat_for_display_customer = (this.payment_to_customer_ex_vat * this.vatpercentage) / 100;

				if (value.ins_installer_fee === '0.00' && value.ins_installer_incentive === '0.00' && value.ins_installer_additional === '0.00') {
					// this.netresult = 0;
				} else {
					// this.netresult = this.voucheramount - installer_refund_liable - customer_refund_liable - this.client_value;
				}

				this.installerpayment = this.payment_to_installer_ex_vat + this.vat_for_display_sp;
				this.refund_amount_due = this.payment_to_customer_ex_vat + this.vat_for_display_customer;
				this.bf.patchValues({ pay_refund: this.refund_amount_due });
				this.netresult = this.voucher_with_vat - (this.installerpayment + this.refund_amount_due);
			});
			// this.bf.bigForm.updateValueAndValidity();
		}
		this.cdr.detectChanges();
	}

	removeTakealotAmount(): void {
		this.amountsControl = this.bf.getControl('amounts');
		if (this.amountsControl) {
			this.amountsControl.get('cus_takealot_fee').setValue(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
		}
	}
	removeInstallerAmountFromInstallerColumn(): void {
		const amountsControl = this.bf.getControl('amounts');
		if (amountsControl) {
			amountsControl.get('ins_installer_fee').setValue(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
			amountsControl.get('cus_installer_fee').setValue(this.sp_value);
		}
		// this.updateInstallerAndRefundTotals();
	}
	removeIncentiveAmountFromInstallerColumn(): void {
		const amountsControl = this.bf.getControl('amounts');
		if (amountsControl) {
			amountsControl.get('ins_installer_incentive').setValue(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
			amountsControl.get('cus_installer_incentive').setValue(this.installer_incentive);
		}
	}
	removeAdditonalAmountFromInstallerColumn(): void {
		const amountsControl = this.bf.getControl('amounts');
		if (amountsControl) {
			amountsControl.get('ins_installer_additional').setValue(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
			amountsControl.get('cus_installer_additional').setValue(this.installer_additional);
		}
	}
	removeInstallerFeeFromCustomerColumn(): void {
		const amountsControl = this.bf.getControl('amounts');
		if (amountsControl) {
			amountsControl.get('cus_installer_fee').setValue(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
			amountsControl.get('ins_installer_fee').setValue(this.sp_value);
		}
		// this.netresult = this.voucheramount - amountsControl.get('ins_installer_fee').value - this.client_value;
		// this.installerpayment =
		// 	+amountsControl.get('ins_installer_fee').value + +amountsControl.get('ins_installer_incentive').value + +amountsControl.get('ins_installer_additional').value;
	}
	removeIncentiveAmountFromCustomerColumn(): void {
		const amountsControl = this.bf.getControl('amounts');
		if (amountsControl) {
			amountsControl.get('cus_installer_incentive').setValue(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
			amountsControl.get('ins_installer_incentive').setValue(this.installer_incentive);
		}
	}
	removeAdditonalAmountFromCustomerColumn(): void {
		const amountsControl = this.bf.getControl('amounts');
		if (amountsControl) {
			amountsControl.get('cus_installer_additional').setValue(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
			amountsControl.get('ins_installer_additional').setValue(this.installer_additional);
		}
	}

	getIncentiveAndAdditional() {
		this.incentiveAdditionalSub = this._store
			.select(getFullItemTwo)
			.pipe(
				skipWhile((iaa: any) => !iaa),
				take(1),
				map((result: any) => {
					console.log('incentices and addtional', result);
					const { installer_incentive_amount } = result?.job_information;
					const { additional_fees_amount } = result?.job_information;
					console.log('INSTALLER', typeof installer_incentive_amount);
					// if (installer_incentive_amount) {
					// 	this.installer_incentive = new DecimalPipe('en-US').transform(+data?.refundVariableObj?.sp_value, '1.2-2');
					// 	// this.installer_incentive = parseFloat(installer_incentive_amount);
					// 	// this.installer_incentive = 10.21;
					// 	console.log('THIS IS IT', typeof this.installer_incentive);
					// 	console.log('THIS IS IT', this.installer_incentive);
					// } else {
					// 	this.installer_incentive = new DecimalPipe('en-US').transform(Number(0), '1.2-2');
					// }
					if (additional_fees_amount) {
						this.installer_additional = new DecimalPipe('en-US').transform(Number(additional_fees_amount), '1.2-2');
					} else {
						this.installer_additional = parseFloat(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
					}
					console.log('incentive and additional', this.installer_additional, typeof this.installer_additional);
				})
			)
			.subscribe();
	}
	// updateInstallerAndRefundTotals(): void {
	// 	const amountsControl = this.bf.getControl('amounts');
	// 	const refundValueInInstallerAmount = +amountsControl.get('ins_installer_fee').value + +amountsControl.get('cus_installer_fee').value;
	// 	const refundValueInIncentive = +amountsControl.get('ins_installer_incentive').value + +amountsControl.get('cus_installer_incentive').value;
	// 	const refundValueInAdditional = +amountsControl.get('ins_installer_additional').value + +amountsControl.get('cus_installer_additional').value;
	// 	this.netresult = this.voucheramount - refundValueInInstallerAmount - refundValueInIncentive - refundValueInAdditional - this.client_value;
	// 	this.installerpayment =
	// 		amountsControl.get('ins_installer_fee').value + amountsControl.get('ins_installer_incentive').value + amountsControl.get('ins_installer_additional').value;
	// }
	ngOnDestroy() {
		if (this.refundSubscription) {
			this.refundSubscription.unsubscribe();
		}
		if (this.valueSub) {
			this.valueSub.unsubscribe();
		}
		if (this.incentiveAdditionalSub) {
			this.incentiveAdditionalSub.unsubscribe();
		}
	}
}
