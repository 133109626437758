import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { map, pluck, take, skipWhile, } from 'rxjs/operators';
import { SetNextNode, ModalService, ManifestController, BigFormService } from '@flexus/core';
import { ModalNavButton } from '@flexus/ui-elements';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'flx-manual-allocation',
	templateUrl: './sp-reply-list.component.html',
	styleUrls: ['./sp-reply-list.component.scss']
})
export class FLXSpReplyListComponent implements OnInit, OnDestroy {
	@Input() interestedParties$: Observable<any>;
	@Input() suitableSps$: Observable<any>;
	@Input() selectedItemTwo$: Observable<any>;
	@Input() replyListData$: Observable<any>;
	@Input() subTitle: string;

	hasSearch = true;
	selectSPNavigateToNode: string;
	assignSpStateList: Array<number>;
	state: number;
	selectedJob: any;
	selectedSP: string;
	cardData$: Observable<any>;
	templateSubTitle = ' ';

	private _dataCopy: any;
	private _subscription: Subscription;

	constructor(private controller: ManifestController<any>, private modalsService: ModalService, public _bfs: BigFormService) {}

	ngOnInit() {
		this.cardData$ = this.replyListData$.pipe(
			pluck('cardData'),
			map((data: { cardStateInfo; columns; entityId }[]) => data)
		);
		this.selectedItemTwo$.pipe(take(1)).subscribe(job => {
			this.state = job?.state;
			this.selectedJob = job;
		});
		// this.selectedItemTwo$.pipe(take(1)).subscribe(job => (this.selectedJob = job));

		if (this.hasSearch) {
			this.replyListData$
				.pipe(
					skipWhile(x => !x),
					pluck('cardData'),
					take(1)
				)
				.subscribe(cardData => {
					this._dataCopy = cardData;
				});

			let searchControl = this._bfs.bigForm.get('sp_list_search');
			if (!searchControl) {
				searchControl = new UntypedFormControl(null);
				this._bfs.bigForm.addControl('sp_list_search', searchControl);
			}
			this._subscription = searchControl.valueChanges
				.pipe(
					skipWhile(x => !x)
					//	debounceTime(350)
				)
				.subscribe(searchValue => {
					if (this._dataCopy) {
						this.cardData$ = of(
							this._dataCopy.filter(dataItem => {
								const foundItems = dataItem?.columns.filter(column => {
									const val = column?.title ? column?.title : column?.subTitle;
									const test = val?.toLowerCase()?.includes(searchValue.toLowerCase());
									return test;
								});
								return foundItems.length !== 0;
							})
						);
					}
				});
		}

		let spSelectedItem = this._bfs.bigForm.get('sp_selected_item');
		if (!spSelectedItem) {
			spSelectedItem = new UntypedFormControl(null);
			this._bfs.bigForm.addControl('sp_selected_item', spSelectedItem);
		}
	}

	selectSP(sp_id) {
		if (this.state === 20) {
			this.interestedParties$
				.pipe(
					take(1),
					map(interested_parties => {
						return interested_parties.find(interested_party => {
							if (interested_party.sp) {
								return interested_party.sp.id === sp_id;
							} else {
								return interested_party.id === sp_id;
							}
						});
					})
				)
				.subscribe(interested_party => {
					if (interested_party.sp) {
						this.selectedSP = interested_party.sp;
						this.selectedJob = { ...this.selectedJob, sp: interested_party.sp.id };
					} else {
						this.selectedSP = interested_party;
						this.selectedJob = { ...this.selectedJob, sp: interested_party.id };
					}
					const formControl = this._bfs.bigForm.get('sp_selected_item');
					formControl.setValue(this.selectedSP);
					if (this.selectSPNavigateToNode) {
						this.controller.dispatch(new SetNextNode(this.selectSPNavigateToNode));
					}
				});
		} else if (this.state === 311) {
			this.suitableSps$
				.pipe(
					take(1),
					map(suitable_sps => suitable_sps)
				)
				.subscribe(sps => {
					const formControl = this._bfs.bigForm.get('sp_selected_item');
					formControl.setValue(sp_id);
					console.log('selected sp: ', sp_id);
					console.log('Suitable sp: ', sps);
					this.controller.dispatch(new SetNextNode(this.selectSPNavigateToNode));
				});
		}
	}

	handleEntitySelection(e, data) {
		const {
			payload: { entityId }
		} = e;
		console.log('e data', e, data);
		// check state , warn then
		if (this.assignSpStateList && this.assignSpStateList.find(x => this.selectedJob.state === x)) {
			this.selectSP(entityId);
		} else {
			let modalOptions = { navButtons: [], message: [] };
			if (data.isTeamLeader) {
				modalOptions = {
					navButtons: [
						{ text: 'Take me back', linkType: 'close', color: 'alert' },
						{
							text: 'I understand',
							linkType: 'close',
							color: 'alert',
							clickHandler: () => {
								this.selectSP(entityId);
							}
						}
					],
					message: [
						`You are about to ${!!this.selectedSP ? 'change the appointed sp' : 'appoint an sp to this job'}`,
						'',
						'Understand that you are breaking the normal flow if you continue'
					]
				};
			} else {
				modalOptions = {
					navButtons: [{ text: 'I understand', linkType: 'close', color: 'alert' }],
					message: ['Manually appointing a service provider can only be done by a team leader', '', 'it is not possible to continue']
				};
			}
			this.setShowDialog(modalOptions);
		}
	}

	setShowDialog(modalOptions: { navButtons: ModalNavButton[]; message: string[]; hasCloseButton?: boolean }) {
		if (modalOptions.hasCloseButton === null || modalOptions.hasCloseButton === undefined) {
			modalOptions.hasCloseButton = false;
		}
		this.modalsService.openModalDirectly(inst => {
			inst.closeButton = modalOptions.hasCloseButton;
			inst.setMessage(modalOptions.message);
			inst.type = 'warning';
			inst.navButtons = modalOptions.navButtons;
		});
	}

	ngOnDestroy() {
		if (this._subscription) {
			this._subscription.unsubscribe();
		}
	}
}
