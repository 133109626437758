import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXListAndKeysInfoComponent } from './list-and-keys-info.component';
import { FLXGlowLineModule, FLXHeadingsModule, FLXListAtomModule } from '@flexus/ui-elements';
import { FLXDirectivesModule } from '@flexus/directives';

@NgModule({
	imports: [CommonModule, FLXHeadingsModule, FLXListAtomModule, FLXDirectivesModule, FLXGlowLineModule],
	declarations: [FLXListAndKeysInfoComponent],
	exports: [FLXListAndKeysInfoComponent]
})
export class FLXListAndKeysInfoModule {}
