export const DATA_CALLS = {
	getAllJobFiles: {
		serviceVariable: 'mulService',
		functionName: 'getJobAllFiles',
		responseSlice: 'payload',
		errorMessage: 'Could not get files from server!'
	},
	getAllClaimFiles: {
		serviceVariable: 'mulService',
		functionName: 'getAllClaimFiles',
		responseSlice: 'payload',
		errorMessage: 'Could not get files from server!'
	},
	logs: {
		serviceVariable: 'mulService',
		functionName: 'getCallLogs',
		errorMessage: 'No call logs could be found!'
	}
};

export const CONTEXT_DATA_CALLS = {
	files: {
		...DATA_CALLS.getAllClaimFiles
	}
};
