import { Flow_0_0_2, getFullItemOne, getSelectedItemTwo, getSelectedItem, getSelectedItemOne, getAllInfo } from '@flexus/core';
import { take, skipWhile, map, switchMap } from 'rxjs/operators';

import { UntypedFormControl } from '@angular/forms';
import { bindCallback, forkJoin } from 'rxjs';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';

export const BET_108: Flow_0_0_2 = {
	id: '108',
	name: 'potential_invalid_claim_pause',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					if (itemOne) {
						let title = 'Potential Invalid Claim';
						if (itemOne?.loan_information?.mavenclaimnumber) {
							title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
						}
						if (itemOne.applicant?.surname) {
							title += ` - ${itemOne.applicant?.surname}`;
						}

						return title;
					} else {
						return 'Potential Invalid Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['documents', 'notes']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	events: {},
	fetchLevel1And2: true,
	startNode: 'Summary',
	nodes: {
		Summary: {
			component: 'FLXKeyValueDisplayWithInstructionsComponent',
			navs: [{ text: 'Continue', nextNode: 'Approve' }],
			serverCalls: {
				keyValueList: {
					errorMessage: 'error in Summary node keyValueList',
					directCall: (http, store) => {
						return store
							.select(getFullItemOne)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								map((itemOne: any) => {
									return [
										{
											'Client Name': itemOne?.applicant
												? `${itemOne.applicant?.first_name ? itemOne?.applicant?.first_name : ''} ${itemOne.applicant?.surname ? itemOne?.applicant?.surname : ''}`
												: ''
										},
										{ Address: itemOne?.address ? itemOne?.address : 'NO ADDRESS FOUND' },
										{
											'Claim Type': itemOne?.loan_information?.claimtype ?? 'NO CLAIM TYPE FOUND'
										},
										{
											'Contact Number': itemOne?.loan_information?.contactnumber ?? 'NO CONTACT NUMBER FOUND'
										},
										{
											'Cell Number': itemOne?.loan_information?.cellnumber ?? 'NO CELL NUMBER FOUND'
										},
										{
											'What Matters': itemOne?.loan_information?.whatmatters ?? 'NO WHAT MATTERS FOUND'
										}
									];
								})
							);
					}
				}
			},
			inputs: {
				title: 'Potential Invalid Claim - Confirm Job',
				instructions: [
					'Some jobs on this claim were marked as invalid. Please check if this job is valid',
					'Notes and Documents for this job can be found on the action panel to the right.'
				]
			}
		},
		Approve: {
			initFormFields: (bf, item) => {
				bf.addControl('new_state', new UntypedFormControl(''));
				bf.addControl('new_claim_state', new UntypedFormControl(''));
				bf.addControl('current_state', new UntypedFormControl(''));
			},
			component: {
				children: [
					{
						component: 'FLXKeyValueDisplayWithInstructionsComponent',
						inputs: {
							title: 'Decline or Approve this Job',
							instructions: [
								'Confirm if this job is valid and the SP can continue with the work.',
								'Notes and Documents for this job can be found on the action panel to the right.'
							],
							keyValueList$: 'keyValueList'
						}
					}
				]
			},
			navs: [
				{
					text: 'Decline',
					color: 'danger',
					intersectData: (bf, store) => {
						store
							.select(getSelectedItemTwo)
							.pipe(take(1))
							.subscribe(job => {
								bf.patchValues({ current_state: job?.state });
								// bf.patchValues({ new_state: 25 });
							});
					},
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							errorMessage: 'Could not update job',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getAllInfo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any[])
									)
								]).pipe(
									switchMap(([job, info]) => {
										const job_id = job?.id;
										const current_state = bf.bigForm.get('current_state')?.value;
										// const new_state = bf.bigForm.get('new_state')?.value;
										const data = {
											job_id: job_id,
											current_state: current_state,
											new_state: 25
										};
										return http.post(`${environment.api_url}v1/job_action/update_job`, data);
									})
								);
							}
						}
					},
					nextNode: 'SubmissionSuccess'
					// nextState: 25
				},
				{
					text: 'Approve',
					color: 'success',
					intersectData: (bf, store) => {
						store
							.select(getSelectedItemTwo)
							.pipe(take(1))
							.subscribe(job => {
								bf.patchValues({ current_state: job?.state });
								// bf.patchValues({ new_state: 86 });
							});
					},
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							errorMessage: 'Could not update job',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getAllInfo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any[])
									)
								]).pipe(
									switchMap(([job, info]) => {
										const job_id = job?.id;
										const current_state = bf.bigForm.get('current_state')?.value;
										// const new_state = bf.bigForm.get('new_state')?.value;
										const data = {
											job_id: job_id,
											current_state: current_state,
											new_state: 86
										};
										return http.post(`${environment.api_url}v1/job_action/update_job`, data);
									})
								);
							}
						}
					},
					nextNode: 'SubmissionSuccess'
					// nextState: 86
				}
			]
			// serverCalls: {
			//   keyValueList: {
			//     errorMessage: 'Error at KeyValueList node Approve in state 108',
			//     directCall: (http, store) => {
			//       return store.select(getFullItemOne).pipe(
			//         skipWhile(x => !x),
			//         take(1),
			//         map(itemOne => [{ 'Job Card': itemOne?.loan_information.claimtype }])
			//       );
			//     }
			//   }
			// }
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	}
};
