import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { Store } from '@ngrx/store';
import { ModalService } from '../modal.service';
import { getActiveReminders } from '../../reminders';

@Injectable({ providedIn: 'root' })
export class IndexedDbService extends Dexie {
	keys: Dexie.Table<{ id: string; blob: Blob }, string>;
	claims: Dexie.Table<{ id: number; cipher: string }, number>;
	claimInDraft: Dexie.Table<any, string>;
	currentItem: Dexie.Table<any, string>;
	data_store: Dexie.Table<any, string>;
	scratch_pad: Dexie.Table<any, string>;
	reminders: Dexie.Table<any, string>;
	activeReminders: Dexie.Table<any, string>;
	recent_activity: Dexie.Table<any, string>;
	user: Dexie.Table<any, string>;
	job_responses: Dexie.Table<any, string>;
	releaseNotesVersion: Dexie.Table<{ id: number; versionNumber: string }, number>;

	constructor(private store: Store<any>, modal: ModalService) {
		super('FlexusDb');

		this.on('versionchange', function (event) {
			modal.openModalDirectly(instance => {
				instance.type = 'warning';
				instance.message = `There are multiple versions of this application currently open. This can cause issues when storing local data. Please close all other versions of the application and reload this page.`;
				instance.navButtons = [
					{
						text: 'Reload',
						clickHandler: () => window.location.reload(),
						linkType: 'submit',
						color: 'primary'
					}
				];
			});
		});

		this.version(1).stores({
			keys: 'id',
			claims: 'id',
			claimInDraft: 'tempKey',
			currentItem: '',
			data_store: 'id'
		});
		this.version(2).stores({
			scratch_pad: ''
		});
		this.version(3).stores({
			recent_activity: ''
		});
		this.version(4).stores({
			user: ''
		});
		this.version(5).stores({
			reminders: 'id'
		});
		this.version(6).stores({
			activeReminders: 'id'
		});
		this.version(7).stores({
			scratch_pad: null,
			recent_activity: null
		});
		this.version(8)
			.stores({
				scratch_pad: 'id, userID',
				recent_activity: 'id, userID',
				reminders: 'id, userID',
				activeReminders: 'id, userID'
			})
			.upgrade(tx => {
				return tx
					.table('reminders')
					.toCollection()
					.modify(rem => {
						rem.userID = 0;
					});
			});
		this.version(9).stores({
			job_responses: 'job_id, interested, spm, time_of_response'
		});
		this.version(10).stores({
			releaseNotesVersion: 'id, versionNumber'
		});
		this.version(11)
			.stores({
				job_responses: '++id, job, interest'
			})
			.upgrade(tx => {
				return tx.table('job_responses')?.clear();
			});
		// this.listenToChanges();
		this.initializeOnLoad();
	}

	initializeOnLoad() {
		this.on('ready', () => {
			this.store.dispatch(getActiveReminders());
		});
	}

	reCreate() {
		return this.delete().then(() => this.open());
	}
}
