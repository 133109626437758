import { Flow_0_0_2, getData, getCurrentUser, getFullItemOne } from '@flexus/core';
import { map, take, filter, skipWhile, first } from 'rxjs/operators';
import { getFullItemTwo } from '@flexus/core';
import gql from 'graphql-tag';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { PGG_27 } from './PGG_27';

export const PGG_48: Flow_0_0_2 = {
	id: '48',
	name: 'invoice_updated',
	itemType: 'flow',
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any, bf: any) => {
			return store.select(getFullItemOne).pipe(
				filter((x: any) => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first((itemOne: any) => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Invoice Updated : ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					} else {
						return 'Invoice Updated';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Review the invoice query'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: (instance: any) => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	fetchLevel1And2: true,
	serverCalls: {
		invoice: {
			serviceVariable: 'silService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		dataFiles: {
			errorMessage: 'No invoice found!',
			directCall: (http: any, store: any, sq: any) => {
				// return throwError('something went wrong');
				return sq
					.queryObject(
						gql`
							{
								invoice {
									data
								}
							}
						`,
						store.select(getData)
					)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1),
						map((data: any) => data)
					);
			}
		}
	},
	events: {
		invalidateQueryDataIfClaimValueIsSet: {
			triggerOn: 'claim_value',
			triggerWhen: rate => !!rate,
			dataMutations: bf => {
				bf.patchValues({
					query: null,
					new_state: 226
				});
			}
		},

		invalidatePaymentDataIfQueryIsSet: {
			triggerOn: 'query',
			triggerWhen: qr => !!qr,
			dataMutations: bf => {
				bf.patchValues({
					claim_value: null,
					invoice_number: null,
					new_state: 46
				});
			}
		}
	},
	startNode: 'PaymentPreview',
	nodes: {
		...PGG_27?.nodes,
		PaymentPreview: {
			component: 'FLXFileViewWithSideExtrasComponent',
			inputs: { inputHeading: 'Payment Preview', dataFiles: 'dataFiles' },
			serverCalls: {
				...PGG_27?.nodes?.PaymentPreview.serverCalls,
				keyValueList2: {
					errorMessage: '',
					directCall: (http: any, store: any) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile((x: any) => !x),
							take(1),
							map((job: any) => {
								const query =
									job?.job_information?.invoicequery && Array.isArray(job?.job_information?.invoicequery)
										? job?.job_information?.invoicequery
										: job?.job_information?.invoicequery
										? [job?.job_information?.invoicequery]
										: [];

								return query;
							})
						);
					}
				}
			},

			initFormFields: (bf: any, item: any, instance: any, sq: any, store: any) => {
				store
					.select(getCurrentUser)
					.pipe(
						skipWhile((x: any) => !x),
						take(1)
					)
					.subscribe((user: any) => {
						bf.patchValues({ paymentapproval1: user?.user?.id });
					});

				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									job_creator
								}
							}
						}
					`
				)
					.pipe(
						filter((x: any) => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe((job: any) => {
						bf.patchValues({ paymentapprovalhandler: job?.job_creator });
					});
			},

			navs: [
				{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'alert' },
				{
					text: 'Approve Invoice',
					nextNode: 'PaymentConfirm',
					color: 'primary'
				}
			]
		}
	},

	bigFormToStoreMapper: {
		current_state: 'current_state',
		new_state: 'new_state',
		claim_value: ['claim_value', 'job_information.claim_value'],
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		query: [
			(qr, storeObj) => {
				let iaq = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo &&
					storeObj['selectedContext']?.fullItemTwo.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery)) {
						iaq = storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery;
					} else {
						iaq = [
							{
								...storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
							}
						];
					}
				}
				const invoicequery = [...iaq, { query: qr, queryreply: '' }];
				return invoicequery;
			},
			'job_information.invoicequery'
		]
	}
};
