<section
	[ngClass]="classesArray"
	[style.maxWidth]="maxWidth"
	[style.minWidth]="minWidth"
	[style.margin]="margin"
	[style.alignItems]="alignItems"
	[style.width]="width"
	[style.justifyContent]="justifyContent"
>
	<ng-content></ng-content>
</section>
