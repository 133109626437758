import { Component, Input } from '@angular/core';

@Component({
	selector: 'flx-billing-header',
	templateUrl: './billing-header.component.html',
	styleUrls: ['./billing-header.component.scss']
})
export class BillingHeaderComponent {
	instructions: string[];
	@Input() docType: 'invoice' | 'quote';
}
