import { NodePoint_0_0_2, getFullItemTwo, getData, MakeServerCall, getAllInfo, VatService } from '@flexus/core';
import { map, skipWhile, take, pluck, filter } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';

export const voucherInfoNode = (state?, newState?): NodePoint_0_0_2 => {
	return {
		name: 'Voucher Details',
		component: {
			children: [
				{
					component: 'FLXKeyValueListComponent',
					inputs: { data$: 'voucherInfo', heading: 'Voucher Details', itemMargin: '0px auto 55px auto' }
				},
				{
					component: 'FLXKeyValueListComponent',
					inputs: { data$: 'additional_voucherInfo' }
				}
			]
		},
		showTabs: true,
		serverCalls: {
			voucherInfo: {
				errorMessage: 'Cannot get voucher summary',
				directCall: (http, store, sq, bf) => {
					return forkJoin([
						store.select(getData).pipe(
							skipWhile(x => !x.voucher),
							pluck('voucher'),
							take(1)
						),
						store.select(getFullItemTwo).pipe(
							filter(x => !!x),
							take(1)
						),
						store.select(getAllInfo).pipe(
							skipWhile(ai => !ai),
							take(1),
							map(res => res as any)
						)
					]).pipe(
						map(([data, job, info]) => {
							const primary_voucher = data?.voucher_number;
							bf.patchValues({ primary_voucher: primary_voucher });
							const list: any = [];
							const { job_information } = job;
							const sps: any[] = info?.sps;
							const sp = sps.find(serviceProvider => serviceProvider?.id === job?.sp);
							const vat_no: string = sp?.vat_no?.trim();
							const hasVatNo = vat_no && vat_no?.length > 0 && vat_no !== '';

							const voucherDetails: any = {
								'voucher number / decoder number': data?.voucher_number && data?.voucher_number !== '' ? data?.voucher_number : 'No decoder number found',
								voucher_price: data?.voucher?.price && data?.voucher?.price !== '' ? data?.voucher?.price : 'Price not found'
							};

							let totalOfInstallation = parseFloat(data?.voucher?.price);

							if (hasVatNo) {
								const vatAmount = VatService.getVatRate(data?.voucher?.price, VatService.getVatPercentageFromDate(job?.job_start));
								voucherDetails.vat_amount = vatAmount.toFixed(2);
								totalOfInstallation += vatAmount;
							}

							if (job_information?.extra_cost_total) {
								totalOfInstallation += parseFloat(job_information?.extra_cost_total);
							}

							voucherDetails.total_of_installation = totalOfInstallation.toFixed(2);

							list.push(voucherDetails);
							return list;
						})
					);
				}
			},
			additional_voucherInfo: {
				errorMessage: '',
				directCall: (http, store, sq, bf) => {
					return store
						.select(getData)
						.pipe(
							skipWhile(x => !x.voucher),
							pluck('voucher'),
							take(1)
						)
						.pipe(
							map((result: any) => {
								const primary = bf.getControl('primary_voucher').value;
								const { decoder_number } = result;
								let objToPush: any = {};
								if (decoder_number.length === 1) {
									return null;
								} else {
									const index = decoder_number.indexOf(primary);
									const arr = [...decoder_number];
									const list: any = [];
									if (index > -1) {
										arr.splice(index, 1);
									}
									for (let i = 0; i < arr.length; i++) {
										const extra_no = i + 2;
										const entry = ` ${arr[i]}`;
										list.push({ 'Additional Voucher / Decoder Number': entry });
									}
									return list;
								}
							})
						);
				}
			}
		},
		initFormFields: bf => {
			if (newState) {
				bf.addControl('new_state', new UntypedFormControl(newState, Validators.required));
			}
		},
		navs: [
			...(state === 176 || state === 188 || state === 187
				? [
						{
							text: 'Send to QA',
							nextNode: 'sendToQa',
							color: 'secondary'
						},
						{
							text: 'Send to support',
							nextNode: 'decline',
							color: 'secondary'
						},
						{
							text: 'Submit',
							nextNode: 'submissionSuccess',
							serverFirst: true,
							optIntoValidation: true,
							color: 'primary',
							linkType: 'submit' as any,
							serverCalls: {
								response: {
									serviceVariable: 'mulService',
									functionName: 'updateJob',
									errorMessage: 'Job could not be updated!!'
								}
							}
						}
				  ]
				: state === 177 || state === 233
				? [
						{
							text: 'Send to 4-sure support',
							nextNode: 'requestQA',
							color: 'secondary'
						},
						{
							text: 'Approve',
							nextNode: 'resolvedProblem'
						}
				  ]
				: [
						{
							text: 'SP Rework',
							nextNode: 'rework',
							color: 'secondary'
						},
						{
							text: 'QA Regional',
							nextNode: 'requestQA',
							color: 'secondary'
						},
						{
							text: state === 179 ? 'Fix Issue' : state === 183 ? 'Done' : 'Issue Fixed',
							nextNode: 'resolvedProblem',
							color: 'primary'
						}
				  ])
		]
	};
};
