import { OnInit, Component, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef, ElementRef, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, BehaviorSubject, of } from 'rxjs';
import { CollapseActionPanel, ExpandActionPanel, LoadActionPanelComponent } from '../../../app-shell-features/action-panel/store';
import { ModalService, getCurrentUser, RespondToJob, SelectAvailableJob, getSelectedJob } from '@flexus/core';

import { HttpClient } from '@angular/common/http';
import { skipWhile, take, map, filter, pluck, catchError } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { JOB_INTEREST } from '@flexus/utilities';

export interface JobLocationConfig extends google.maps.MapOptions {
	scriptId?: string;
	url?: string;
}

export class JobLocation {
	constructor(public lat: number, public lng: number) {}
}

@Component({
	selector: 'flx-job-request-ping-detail',
	templateUrl: './job-request-ping-detail.component.html',
	styleUrls: ['./job-request-ping-detail.component.scss']
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXAPJobRequestPingDetailComponent implements OnInit, OnDestroy {
	@ViewChild('countdown') countdownspan!: ElementRef;

	@Input() timeout!: number;
	@Input() intereststring!: string;

	public selectedJob: any;
	private selectedJobId;
	whatMattersData$: Observable<any>;
	wmSub: Subscription;

	public jobloco;

	private _config: JobLocationConfig;
	private _defaultConfig: JobLocationConfig;

	public generalArea: google.maps.Map;

	public _posiDataSub: Subscription;

	private currentUser: any;
	public indicatedinterest: number;

	displayGoBackButton: boolean;
	displayUndoButton: boolean;
	allowableUndoInterval;
	timer$: Observable<void>;
	undosub: Subscription;
	apiLoaded: Observable<boolean>;
	options: google.maps.MapOptions = {
		center: { lat: -26.2041, lng: 28.0473 },
		zoom: 8
	};

	constructor(private store: Store<any>, private modal: ModalService, private _http: HttpClient, private cdr: ChangeDetectorRef) {
		this.initMap();
		this.apiLoaded = _http.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.google_maps_api_key}&region=ZA&language=en`, 'callback').pipe(
			map(() => true),
			catchError(() => of(false))
		);
	}

	get config() {
		return this._config || this._defaultConfig;
	}

	ngOnInit() {
		this.displayGoBackButton = true;
		this.store
			.select(getCurrentUser)
			.pipe(skipWhile((res: any) => !res))
			.subscribe((res: any) => {
				const user = res as any;
				this.currentUser = user?.user;
			})
			.unsubscribe();

		this.store
			.select(getSelectedJob)
			.pipe(skipWhile((x: any) => !x))
			.subscribe((res: any) => {
				let skill: string;
				if (res?.claim_type_id === '37') {
					skill = `Own Stock ${res?.skill}`;
				} else {
					skill = `${res?.skill}`;
				}
				const appointment = {
					after_hours: `${res?.appointment?.after_hours}`,
					appointment_name: `${res?.appointment?.appointment_name}`,
					appointment_type_id: `${res?.appointment?.appointment_type_id}`,
					id: `${res?.appointment?.id}`,
					range_end: `${res?.appointment?.range_end}`,
					range_start: `${res?.appointment?.range_start}`
				};
				const modifiedJob: any = {
					...appointment,
					skill: `${skill}`,
					address: `${res?.address}`,
					area: `${res?.area}`,
					id: `${res?.id}`,
					location: `${res?.location},`,
					suburb: `${res?.suburb}`
				};
				this.selectedJob = modifiedJob;
				this.selectedJobId = this.selectedJob.id;
			})
			.unsubscribe();

		this.whatMattersData$ = this.getWhatMatters();
		this.wmSub = this.whatMattersData$.subscribe(wm => {});
	}

	cancelUndo() {
		this.undosub.unsubscribe();
		clearTimeout(this.allowableUndoInterval);
	}

	expandListViewWithFiltered() {
		this.cdr.detectChanges();
		this.store.dispatch(new ExpandActionPanel());
		const showPing = setTimeout(() => {
			this.store.dispatch(new LoadActionPanelComponent('FLXAPJobRequestPingComponent'));
		}, 350);
	}

	responseDispatch(interest: any) {
		this.store.dispatch(new RespondToJob({ job_id: this.selectedJobId, job: this.selectedJob, interested: interest }));
		this.store.dispatch(new CollapseActionPanel());
	}

	/**
	 * @param  {number} interest
	 */

	respondToJobHandler(interest: 'INTERESTED' | 'DECLINED' | 'IGNORED') {
		this.responseDispatch(JOB_INTEREST[interest]);
		this.expandListViewWithFiltered();
	}

	returnJobLocation(): Observable<JobLocation> {
		return this.store.select(getSelectedJob)?.pipe(
			skipWhile((x: any) => !x),
			take(1),
			map((res: any) => {
				const [latitude, longitude] = res?.location?.split(',');
				return new JobLocation(+latitude, +longitude);
			})
		);
	}

	initMap() {
		this._posiDataSub = this.returnJobLocation()
			.pipe(filter((x: any) => !!x))
			.subscribe(location => {
				this.jobloco = location;
			});
	}

	goBack() {
		this.store.dispatch(new LoadActionPanelComponent('FLXAPJobRequestPingComponent'));
		this.store.dispatch(new SelectAvailableJob(null));
	}

	getWhatMatters() {
		const job_id = parseInt(this.selectedJobId, 10);
		return this._http.post(`${environment.api_url}v1/job_action/get_wm/`, { job_id })?.pipe(
			skipWhile(x => !x),
			pluck('payload', 'whatmatters'),
			take(1),
			map(whatmatters => whatmatters)
		);
	}

	ngOnDestroy() {
		this.wmSub && this.wmSub.unsubscribe();
		this._posiDataSub && this._posiDataSub.unsubscribe();
	}
}
