import { BusinessRules } from './business-rules';

export const applyBuiVirtualStates = (claims: any[]) => claims.map(runLogic);

const runLogic = claim => {
	let jobCompleted = 0,
		paymentApproved = 0,
		repudiated = 0,
		watingRepudiation = 0,
		jobsCancelled = 0,
		j = 0;

	const checkAllJobsComplete = (job: any, statePool: number[]) => {
		const exists = statePool.includes(job.state);
		if (exists === false) {
			jobCompleted = -1; // job is not complete or cancelled
		}
	};

	const checkPaymentInitiated = (job: any, statePool: number[]) => {
		const exists = statePool.includes(job.state);
		if (exists === false) {
			if (job.supplier_type != 4) {
				paymentApproved = 1;
			}
		}
	};

	const checkJobException = (claim: any, statePool: number[]) => {
		const exists = claim?.jobs && claim?.jobs.every(job => statePool.includes(job.state));
		if (exists === true) {
			j = 1;
		}
	};

	const checkRepudiation = (job: any, statePool: number[]) => {
		const exists = statePool.includes(job.state);
		if (exists === true) {
			watingRepudiation = 1;
		}

		if (job.valid_job === 2) {
			repudiated = 1;
		}
	};

	const checkAllJobsCancelled = (job: any, statePool: number[]) => {
		const arr = [41, 45];
		const isItArray = Array.isArray(arr);
		if (!arr.includes(job.state)) {
			jobsCancelled = -1;
		}
	};

	//IA complete//////////////////////
	const iaCompleteSateCheck = (claim: any) => {
		if (BusinessRules.IACanComplete(claim?.state, claim?.jobs)) {
			claim.state = 109; //Ia can finish his claim
		}

		return claim;
	};
	//////////////////////////////////

	const jobStatesForCustomerRatingPool = [26, 27, 30, 34, 38, 40, 50, 51, 52];
	const jobCompletedStates = [28, 41, 45, 154];

	const jobExceptions = [45, 56, 58, 59, 60, 72, 89, 154];
	const claimExceptions = [12, 15, 18, 31, 55, 150, 197, 198];
	const paymentStates = [25, 26, 27, 28, 30, 34, 38, 40, 45, 50, 51, 52];
	const repudiationExceptions = [20, 21, 22, 23, 32, 37];
	const cancelledJobExceptions = [1, 2, 3, 15, 102];

	if (claim?.state === 7 || claim?.state === 9 || claim?.state === 169) {
		return claim;
	}

	//If no jobs then exit
	if (Array.isArray(claim?.jobs) && claim?.jobs?.length === 0) {
		return claim;
	}

	if (claim?.jobs) {
		iaCompleteSateCheck(claim);
		if (claim?.state === 109) {
			return claim;
		} // dont run futher tests as this is an IA job. exit here

		claim?.jobs?.map(job => {
			checkAllJobsComplete(job, jobCompletedStates),
				checkPaymentInitiated(job, paymentStates),
				checkRepudiation(job, repudiationExceptions),
				checkAllJobsCancelled(job, jobCompletedStates);
		});
	}

	if (paymentApproved === 0) {
		const exists = claimExceptions.includes(claim?.state);
		if (exists === false) {
			checkJobException(claim, jobExceptions);
			if (j === 0) {
				claim.state = 14;
			}
		}
	}

	if (jobCompleted === 0) {
		const state = claim?.state;

		switch (state) {
			case 3:
			case 5:
			case 102:
				claim.state = 12;
				break;
			case 15:
				claim.state = 55;
				break;
			case 73:
			case 74:
				if (claim?.valid_job === 0) {
					claim.state = 55;
				}
				break;
		}
	}

	if (Array.isArray(claim?.jobs) && claim?.jobs?.length === 1) {
		if (claim?.jobs[0]?.supplier_type === 2 || claim?.jobs[0]?.supplier_type === 3) {
			if (claim?.state !== 55) {
				if (claim?.jobs[0]?.state === 28) {
					claim.state = 12; //might be 14
				}
			}
		}
	}

	if (repudiated === 1 && watingRepudiation === 0) {
		claim.state = 71;
	}

	if (jobsCancelled === 0) {
		const exists = cancelledJobExceptions.includes(claim?.state);

		if (exists === true) {
			claim.state = 44;
		}
	}

	return claim;
};

// Test claim 20/003306
