import { Converters } from './converters';

/** Collection of static pure helper functions for cryptography.
 * @class
 * @example
 * const credentials = { username: 'john', password: 'pa$$w0rd' };
 * const value = 5;
 * const hash: PromiseLike<ArrayBuffer> = CryptoUtil.hashCredentialsToArrayBuffer(credentials);
 */
export class CryptoUtil {
	/**Create a password-based key derivative function. Key can only be used to derive a cryptoKey and cannot be extracted.
	 * @param {Uint8Array} bytes Bytes of the user's credentials (username and password object).
	 * @returns {PromiseLike<CryptoKey>} A promise or promise-like of the cryptography key.
	 */
	static createPBKDF2BaseKey(bytes: Uint8Array): PromiseLike<CryptoKey> {
		return window.crypto.subtle.importKey('raw', bytes, { name: 'PBKDF2', length: 256 }, false, ['deriveKey']);
	}

	/** Derive an AES-CBC key from a base key such as PBKDF2 key.
	 * @param {CryptoKey} baseKey The base key such as a PBKDF2 key.
	 * @param {any} saltBytes Bytes of the long string used as salt.
	 * @param {number} iterations Number of iterations to do. Longer is slower but stronger.
	 */
	static deriveAESCBCKeyFromBaseKey(baseKey: CryptoKey, saltBytes: any, iterations: number): PromiseLike<CryptoKey> {
		return window.crypto.subtle.deriveKey(
			// Firefox currently only supports SHA-1 with PBKDF2
			{ name: 'PBKDF2', salt: saltBytes, iterations: iterations, hash: 'SHA-1' },
			baseKey,
			{ name: 'AES-CBC', length: 256 }, // Resulting key type we want
			true,
			['encrypt', 'decrypt']
		);
	}

	/** Exporting a Cryptographic key as an ArrayBuffer to be used in Javascript
	 * @param {CryptoKey} key The cryptographic key to export from the window.crypto.subtle API to javascript.
	 * @returns {PromiseLike<ArrayBuffer>} ArrayBuffer type of exported Cryptographic key.
	 */
	static exportKeyToArrayBuffer(key: CryptoKey): PromiseLike<ArrayBuffer> {
		return window.crypto.subtle.exportKey('raw', key);
	}

	/** Create a SHA512 hash of the user's credentials
	 * @param {Object} credentials An object of the username and password of the user
	 * @returns ArrayBuffer type of the hash
	 */
	static hashCredentialsToArrayBuffer(credentials: Object): PromiseLike<ArrayBuffer> {
		const credentialsString = JSON.stringify(credentials);
		// Get byteArray of credentials string
		const credentialBytes = Converters.stringToByteArray(credentialsString);
		return window.crypto.subtle.digest({ name: 'SHA-512' }, credentialBytes);
	}

	/** Create a javascript Blob containing secrets */
	static createBlobContainer(buffer: ArrayBuffer, ivBytes: Uint8Array, keyBytes: Uint8Array): Blob {
		const hashBytes = new Uint8Array(buffer, 0, 16);

		// Build a Blob with the 16-byte IV followed by the ciphertext
		const blob = new Blob([ivBytes, keyBytes, hashBytes], { type: 'application/octet-stream' });
		return blob;
	}

	static decryptWithKeyFromBlob(key: CryptoKey, ivBytes: Uint8Array, cipherBytes: Uint8Array) {
		// Use the CryptoKey and IV to decrypt cipher
		return window.crypto.subtle.decrypt({ name: 'AES-CBC', iv: ivBytes }, key, cipherBytes);
	}

	static encryptWithKeyFromBlob(key: CryptoKey, ivBytes: Uint8Array, objectToEncryptBytes: Uint8Array) {
		// Use the CryptoKey to encrypt the plaintext
		return window.crypto.subtle.encrypt({ name: 'AES-CBC', iv: ivBytes }, key, objectToEncryptBytes);
	}
}
