<flx-flex-container [direction]="containerDirection">
	<div [class]="'call-log-container-' + location" class="call-log-container">
		<div class="logs-view">
			<div class="heading">
				<flx-heading type="page" size="medium">{{ location !== 'action-panel' ? 'Call Log History' : 'Call Logs' }} </flx-heading>
			</div>

			<ng-container *ngIf="newCallLogs$ | async as logs; else noLogs">
				<flx-scroll-container maxheight="80vh">
					<div *ngIf="logs.length > 0">
						<ng-container *ngFor="let log of logs; let i = index">
							<flx-call-log [location]="location" [log]="log"></flx-call-log>
							<!-- <flx-glow-line margin="1rem 0" *ngIf="logs.length - 1 !== i"></flx-glow-line> -->
						</ng-container>
					</div>
				</flx-scroll-container>
			</ng-container>
			<ng-template #noLogs> <flx-alert type="no-info" missingItem="call logs"></flx-alert> </ng-template>
		</div>
		<div class="add-new-log">
			<!-- <flx-glow-line margin="1rem 0"></flx-glow-line> -->
			<flx-heading type="page" size="medium" *ngIf="location !== 'action-panel'">Add Call Log</flx-heading>
			<div class="add-call-log">
				<form [formGroup]="bf.bigForm">
					<flx-form-field class="dynamic-inputs">
						<flx-label class="form-label">Message</flx-label>
						<flx-textarea [maxLength]="600" formControlName="message"></flx-textarea>
					</flx-form-field>
					<div class="add-call-log-direction">
						<flx-form-field class="dynamic-inputs">
							<flx-label class="form-label">Direction</flx-label>
							<div class="log-dropdown">
								<flx-dropdown
									formControlName="direction"
									[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
									[itemsOption]="direction"
									placeHolder="Select Direction"
									[searchEnabled]="false"
								>
								</flx-dropdown>
							</div>
						</flx-form-field>
						<flx-form-field class="dynamic-inputs">
							<flx-label class="form-label">Reason</flx-label>
							<div class="log-dropdown">
								<flx-dropdown
									formControlName="reason"
									[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
									[itemsOption]="reason"
									placeHolder="Select Reason"
									[searchEnabled]="false"
									[autoOpen]="bf.bigForm.value.direction !== null && bf.bigForm.value.reason === null"
									[isDisabled]="bf.bigForm.value.direction === null"
								>
								</flx-dropdown>
							</div>
						</flx-form-field>
						<flx-form-field class="dynamic-inputs">
							<flx-label class="form-label">Channel</flx-label>
							<div class="log-dropdown">
								<flx-dropdown
									formControlName="channel"
									[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
									[itemsOption]="channel"
									placeHolder="Select Channel"
									[searchEnabled]="false"
									[autoOpen]="bf.bigForm.value.reason !== null && bf.bigForm.value.channel === null"
									[isDisabled]="bf.bigForm.value.reason === null"
								>
								</flx-dropdown>
							</div>
						</flx-form-field>
					</div>
				</form>
			</div>
			<div class="logs-footer">
				<flx-button
					class="add-log-button"
					display="block"
					color="primary"
					[disabled]="
						bf.bigForm?.value?.channel?.length < 2 || bf.bigForm?.value?.direction?.length < 2 || bf.bigForm?.value?.message?.length < 2 || bf.bigForm?.value?.reason?.length < 2
					"
					(clicked)="submit()"
				>
					{{ location !== 'action-panel' ? 'Log Call' : 'Add New Call Log' }}
				</flx-button>
			</div>
		</div>
	</div>
</flx-flex-container>
