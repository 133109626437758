<div class="bottom-popup" [ngClass]="{ minimized: isMinimized$ | async, hidden: !(isPopupVisible$ | async) }">
	<div class="header">
		<flx-heading size="small" align="left" weight="bold" margin="0">
			<!-- margin="2rem 0 4rem 0"-->
			Query
			<flx-icon
				style="float: right; margin: 0 10px"
				[type]="'chevron-up'"
				[size]="'small'"
				[color]="'secondary'"
				*ngIf="isMinimized$ | async"
				(click)="onToggleMinimize()"
			></flx-icon>
			<flx-icon
				style="float: right; margin: 0 10px"
				[type]="'chevron-down'"
				[size]="'small'"
				[color]="'secondary'"
				*ngIf="(isPopupVisible$ | async) && !(isMinimized$ | async)"
				(click)="onToggleMinimize()"
			></flx-icon>
			<flx-icon style="float: right; margin: 0 10px" [type]="'remove'" [size]="'small'" [color]="'secondary'" (click)="onClosePopup()"></flx-icon>
		</flx-heading>
	</div>
	<div class="popup-content" *ngIf="(isPopupVisible$ | async) && !(isMinimized$ | async)">
		<div class="content-inner">
			<div class="row">
				<div class="columns col-8">
					<!-- key value list -->
					<flx-key-value-list *ngIf="keyValueList$ | async" [data$]="keyValueList$" align="left"></flx-key-value-list>
					<!-- flat dynamic form -->
					<flx-flat-dynamic-form *ngIf="formInputs$ | async" [formControls]="formInputs$ | async"></flx-flat-dynamic-form>
				</div>
				<div class="columns col-4">
					<div class="button-container">
						<flx-button [disabled]="bf?.bigForm?.get('ia_query')?.value?.length < 1" display="block" color="secondary" (click)="onSubmit()">SUBMIT QUERY</flx-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
