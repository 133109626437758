import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HoverClickCopyDirective } from './lib/hover-click-copy.directive';
import { SpeechBubbleDirective } from './lib/speech-bubble.directive';
import { InlineIconSvgDirective } from './lib/inline-icon-svg.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [HoverClickCopyDirective, InlineIconSvgDirective, SpeechBubbleDirective],
	exports: [HoverClickCopyDirective, InlineIconSvgDirective, SpeechBubbleDirective]
})
export class FLXDirectivesModule {}
