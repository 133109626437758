<flx-flex-container>
	<flx-heading [align]="headingAlignment" [size]="headingSize" [weight]="headingWeight">Note History</flx-heading>
	<flx-panel maxWidth="60vw" margin="auto">
		<div class="note-container">
			<div [ngClass]="locationClass" class="notes-view">
				<flx-note-list *ngIf="notes$ | async as notes" [notes]="notes" [currentUser]="username$ | async" maxHeight="100%" [location]="location"></flx-note-list>
			</div>
			<div class="add-note" *ngIf="submitFunction">
				<flx-flat-dynamic-form [formControls]="formInputs"></flx-flat-dynamic-form>
				<flx-button class="add-note-button" color="primary" [disabled]="bf.bigForm.get('message')?.invalid" (clicked)="submit()">Add Note</flx-button>
			</div>
		</div>
	</flx-panel>
</flx-flex-container>
