import * as fromGlobal from './global/global.reducer';
import { ActionReducerMap } from '@ngrx/store';
import * as fromAllInfo from './all-info/all-info.reducer';
import * as fromOffline from './offline/offline.reducers'
import * as fromIdentity from './identity/identity.reducer';
import * as fromSelectedContext from './selected-context/selected-context.reducer';
import * as fromSpJobPingContext from './sp-job-pings/sp-job-pings.reducer';
import * as fromSpOwnJobContext from './sp-own-job/sp-own-job.reducer';
import * as fromSpUserManagementContext from './sp-user-management/sp-users-management.reducer';
import * as fromMcAutopayInvoicingContext from './mc-autopay-invoicing/mc-autopay-invoicing.reducer';
import * as fromBilling from './billing/billing.reducer';
import * as fromReminders from './reminders/reminders.reducers';


export interface AppState {
	global: fromGlobal.GlobalState;
	allInfo: fromAllInfo.AllInfoState;
  offline: fromOffline.State;
  identity: fromIdentity.IdentityState;
  selectedContext: fromSelectedContext.SelectedContextState;
  jobPingsContext: fromSpJobPingContext.JobPingsContextState;
  spOwnJobContext: fromSpOwnJobContext.SPOwnJobContextState;
  spUsersManagementContext: fromSpUserManagementContext.SPUsersManagementContextState;
  mcAutopayInvoicingContext: fromMcAutopayInvoicingContext.MCAutopayInvoicingState;
  billing: fromBilling.iState;
  reminders: fromReminders.RemindersState;
}

export const appReducers: ActionReducerMap<AppState> = {
	global: fromGlobal.globalReducer,
	allInfo: fromAllInfo.allInfoReducer,
  offline: fromOffline.reducers,
  identity: fromIdentity.identityReducer,
  selectedContext: fromSelectedContext.selectedContextReducer,
  jobPingsContext: fromSpJobPingContext.jobPingsReducer,
  spOwnJobContext: fromSpOwnJobContext.spOwnJobReducer,
  spUsersManagementContext: fromSpUserManagementContext.spUsersManagementReducer,
  mcAutopayInvoicingContext: fromMcAutopayInvoicingContext.mcAutopayInvoicingReducer,
  billing: fromBilling.billingReducer,
  reminders: fromReminders.reducer,
};
