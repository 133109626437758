import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FlxLoaderService {
	#loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	setLoading = (loading: boolean) => this.#loading$.next(loading);

	getLoading = () => this.#loading$.asObservable();
}
