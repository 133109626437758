import { Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { map, pluck, skipWhile, take } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { BET_210 } from './BET_210';

export const BET_212: Flow_0_0_2 = {
	id: '212',
	name: 'desktop_assessor_query_response',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map(itemOne => {
					if (itemOne) {
						return `Desktop Assessor Query Response : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Desktop Assessor Query Response';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp']),
	serverCalls: {
		...BET_210.serverCalls,
		assessorSummary: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1),
						pluck('job_information')
					)
					.pipe(
						map((ji: any) => {
							if (ji) {
								const retMessage = {
									important_information: {
										assessors_decision: '',
										response: ''
									}
								};

								if (ji.da_query) {
									const query = !Array.isArray(ji.da_query) ? [{ ...ji.da_query }] : [...ji.da_query];
									retMessage.important_information.response = query[query.length - 1]?.queryreply;
								}

								let message = '';
								if (Array.isArray(ji.interstate_comments)) {
									message = ji.interstate_comments[ji?.interstate_comments?.length - 1]?.message?.toLowerCase();
								} else {
									message = ji.interstate_comments?.message?.toLowerCase();
								}

								switch (true) {
									case message.includes('approve'):
										retMessage.important_information.assessors_decision = 'Approval';
										break;
									case message.includes('recommendrejection'):
										retMessage.important_information.assessors_decision = 'Recommend Rejection';
										break;
									case message.includes('recommendcil'):
										retMessage.important_information.assessors_decision = 'Recommend CIL';
										break;
								}
								return retMessage;
							}
						})
					);
			}
		}
	},
	instructions: {
		editRoles: {
			0: 'Desktop Assessor Query Response'
		},
		viewRoles: {
			0: 'Desktop Assessor Query Response'
		}
	},
	startNode: 'ReviewAssessorDecision',
	nodes: { ...BET_210.nodes },
	bigFormToStoreMapper: {
		...BET_210.bigFormToStoreMapper
	}
};
