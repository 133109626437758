import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CircleIndicatorComponent } from './circle-indicator.component';

@NgModule({
	imports: [CommonModule],
	exports: [CircleIndicatorComponent],
	declarations: [CircleIndicatorComponent]
})
export class CircleIndicatorModule {}
