import { Component, Input, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BigFormService } from '@flexus/core';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
	selector: 'flx-context-menu-what-matters',
	templateUrl: './context-menu-what-matters.component.html',
	styleUrls: ['./context-menu-what-matters.component.scss']
})
export class FLXContextMenuWhatMattersComponent {
	@Input() whatMattersData$: Observable<any>;
	@Input() whatmatters: string;
	@Input() claimdescription: string;
	@Input() showHeading = true;

	maxDate = new Date();
	whatMattersSub: Subscription;
	@ViewChild('datepicker', { static: true }) datepicker;

	constructor(public bf: BigFormService, private _fb: UntypedFormBuilder) {}

	ngOnInit() {
		// this.whatMattersSub = this.whatMattersData$
		//   .pipe(
		//     skipWhile((data) => !data),
		//     map((data) => {
		//       return data;
		//     }),
		//   )
		//   .subscribe((wm) => {
		//     console.log(`wm`, wm.claim.loan_information);
		//     console.log('user', wm.user);
		//     const { whatmatters, claimdescription, dateofloss } = wm.claim.loan_information;
		//     this.whatmatters = whatmatters;
		//     this.claimdescription = claimdescription;
		//     this.dateofloss = dateofloss;
		//     const { full_name } = wm.user.user;
		//     console.log(`FULL NAME`, full_name);
		//     console.log(`WHAT MATTERS`, this.whatmatters);
		//     console.log(`CLAIMDESCRIPTION`, this.claimdescription);
		//     console.log(`DATE OF LOSS`, this.dateofloss);
		//     // this.bf.bigForm.setValue({
		//     //   whatmatters: this.whatmatters ? this.whatmatters : '',
		//     //   // claimdescription: this.claimdescription ? this.claimdescription : '',
		//     //   date_of_loss: this.dateofloss ? this.dateofloss : '',
		//     // });
		//   });
	}
}
