<ng-container *ngIf="objectInfo$ | async as objectInfo">
	<!-- heading -->
	<flx-heading *ngIf="headingConfig.title && !title" [size]="headingConfig.size" [color]="headingConfig.color" align="center" [type]="headingConfig.type">{{
		headingConfig.title
	}}</flx-heading>
	<flx-heading *ngIf="!headingConfig.title && title" [size]="headingConfig.size" [color]="headingConfig.color" align="center" [type]="headingConfig.type">{{ title }}</flx-heading>

	<flx-key-value-list *ngIf="fields$.value !== null" [data$]="fields$"></flx-key-value-list>

	<ng-container *ngFor="let obj of objects$.value">
		<!-- subheading -->
		<flx-heading
			*ngIf="subHeadingConfig.showHeading"
			[size]="subHeadingConfig.size"
			[color]="subHeadingConfig.color"
			align="center"
			[weight]="subHeadingConfig.weight"
			[type]="subHeadingConfig.type"
			>{{ obj.key | fsProperEnglish }}</flx-heading
		>
		<flx-key-value-list [data$]="obj.value$" [color]="keyValueHeadingConfig.color" [itemMargin]="keyValueHeadingConfig.margin"></flx-key-value-list>
	</ng-container>
</ng-container>
