import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FLXButtonsModule, FLXGlowLineModule, FLXHeadingsModule, FLXIconModule, FLXKeyValueListModule } from '@flexus/ui-elements';
import { PipesModule } from '@flexus/pipes';
import { FLXFlatDynamicFormModule } from '../forms';
import { FlxPopupComponent } from './popup';

@NgModule({
	imports: [CommonModule, FLXButtonsModule, FLXIconModule, FLXHeadingsModule, FLXKeyValueListModule, FLXFlatDynamicFormModule, FLXGlowLineModule, PipesModule],
	declarations: [FlxPopupComponent],
	exports: [FlxPopupComponent]
})
export class FLXPopupsModule {}
