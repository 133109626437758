<flx-search-banner></flx-search-banner>
<div
	class="workflow-shell"
	[ngClass]="{
		'local-workflow-push': opened,
		'search-panel-padding': hasSearchValues
	}"
>
	<flx-alert *ngIf="showNoClaimsFoundMessage" type="info" message="No claims found"></flx-alert>
	<ng-container> <flx-dynamic-loader dynamicLoaderHost></flx-dynamic-loader> </ng-container>
</div>
<!-- <flx-loader-component
  isFullScreen="true"
  *ngIf="(loading$ | async) || this.workflowItems.length === 0"
></flx-loader-component> -->
