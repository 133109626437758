import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

export const PGG_VIEW_CLAIM_DOCUMENTS: Flow_0_0_2 = {
	id: 'ViewClaimDocuments',
	itemType: 'flow',
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	excludeForOnlyStates: [169],
	excludeForOnlyRoles: [18],
	name: 'View Documents',
	header: {
		title: (store: Store) => {
			return store.select(getFullItemOne).pipe(
				map((itemOne: any) => {
					if (itemOne) {
						return `View Documents :  ${itemOne?.loan_information?.voucher_key}  -  ${itemOne?.applicant?.first_name}`;
					} else {
						return 'View Documents';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ViewDocuments',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'pggService',
			functionName: 'getAllClaimFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	nodes: {
		ViewDocuments: {
			component: 'ViewDocumentsComponent',
			inputs: {
				heading: 'View Documents',
				location: 'context-menu'
			},
			initFormFields: (bf, item, instance) => {},
			navs: []
		}
	}
};
