import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { LocationPickerResult, LocationPickerTheme } from '@flexus/ui-elements';
import { getActiveTheme } from '@flexus/ux';
import { BigFormService, NetworkService } from '@flexus/core';
import { intersection } from 'ramda';

@Component({
	selector: 'flx-address-confirmation',
	templateUrl: './address-confirmation.component.html',
	styleUrls: ['./address-confirmation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressConfirmationComponent implements OnInit, OnDestroy {
	// ===========================================  Variables ===========================================================
	// public addressConfirmationForm: FormGroup;
	public locationPickerForm: UntypedFormGroup;
	@Input() provinceList$: Observable<{ id: number; name: string }[]>;
	@Input() policyAddress$: Observable<any>;
	@Input() policyAddressObject$: Observable<any>;
	@Input() addressConfirmationText: string = 'Confirm Claimant’s Address';
	@Input() addressType: string = 'Policy Address';

	public address;
	public suburb;
	public city;
	public suburb_code;
	public province;
	public latitude;
	public longitude;

	public policyAddress;
	public isInSA: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public locationSearched: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public claimDetails$: Observable<LocationPickerResult>;
	private provinceListSubscription: Subscription;
	private addressDetailsSubscription: Subscription;
	private searchedAddressSubscription: Subscription;
	public activeTheme: Observable<LocationPickerTheme>;

	private networkCheckSubscription: Subscription;
	isOffline = false;
	offlineMessage = 'You are offline. Please come back to confirm the claimant’s address when you are back online.';

	public storeLocation: LocationPickerResult;
	public searchedLocation: LocationPickerResult;
	public screenData = {
		originalAddress: {
			showLatLng: false
		},
		verifiedAddress: {
			showLatLng: false
		}
	};

	initialPin: LocationPickerResult;

	constructor(public _store: Store<any>, private _fb: UntypedFormBuilder, public bf: BigFormService, private networkService: NetworkService) {
		this.notifyNetworkStatus();
	}

	ngOnInit() {
		this.activeTheme = this._store.select(getActiveTheme).pipe(
			map(at => {
				switch (at) {
					case 'four-sure-dark':
						return LocationPickerTheme.Dark;
					default:
						return LocationPickerTheme.Light;
				}
			})
		);

		const insured_address = this.bf.bigForm.get('insured_address');
		this.province = this.bf.bigForm.get('insured_address')?.value.province;

		if (insured_address?.value) {
			if (!this.locationPickerForm) {
				this.locationPickerForm = new UntypedFormGroup({});
			}
			const address_details_forms = this.locationPickerForm.get('address_details');
			if (!address_details_forms) this.locationPickerForm.addControl('address_details', new UntypedFormControl());

			this.locationPickerForm.patchValue({
				address_details: new LocationPickerResult(
					insured_address?.value?.street_address ?? '',
					insured_address?.value?.suburb ?? '',
					insured_address?.value?.city ?? '',
					this.bf.bigForm.get('suburb_code')?.value ?? '',
					this.bf.bigForm.get('province')?.value ?? '',
					'',
					this.bf.bigForm.get('latitude')?.value ?? null,
					this.bf.bigForm.get('longitude')?.value ?? null
				)
			});

			this.addressDetailsSubscription = this.locationPickerForm.get('address_details')?.valueChanges?.subscribe(address_details => {
				this.bf.bigForm.patchValue({
					latitude: address_details.latitude,
					longitude: address_details.longitude,
					suburb_code: address_details.suburb_code,
					province: address_details.province
				});
				this.updateAddressForm(address_details);
				this.findAndSetProvince();
			});

			this.locationPickerForm.addControl('searched_address', new UntypedFormControl(this.searchedLocation));

			this.searchedAddressSubscription = this.locationPickerForm.get('searched_address')?.valueChanges?.subscribe(searched_address => {
				this.searchedLocation = searched_address;
				this.findAndSetProvince();
			});
		}
	}

	updateAddressForm(loc: LocationPickerResult) {
		this.locationPickerForm.patchValue({ searched_address: loc });

		this.locationSearched.next(true);

		const iis = loc.country === 'South Africa';

		this.isInSA.next(iis);

		if (iis) this.locationPickerForm.patchValue({ addressconfirmation: 'MapChecked' });
	}

	policyAndFoundAddressPartsEqual(policyAddressPart: string, foundAddressPart: string) {
		const common = intersection(policyAddressPart?.split(' '), foundAddressPart?.split(' '));
		return common.length > 0;
	}

	private notifyNetworkStatus() {
		this.networkCheckSubscription = this.networkService.isOnline.subscribe(isOnline => {
			if (isOnline) {
				this.isOffline = false;
			} else {
				this.isOffline = true;
			}
		});
	}

	resetPin() {
		this.updateAddressForm(this.locationPickerForm?.value?.policy_address);
	}

	findAndSetProvince() {
		this.provinceListSubscription = this.provinceList$
			.pipe(
				skipWhile(res => !res),
				take(1)
			)
			.subscribe(res => {
				if (this.searchedLocation) {
					const area_code: number = res?.find(province => province?.name?.toLowerCase() === this.searchedLocation.province?.toLowerCase())?.id;

					const area_code_form = this.bf.bigForm.get('area_code');
					if (!area_code_form) this.bf.bigForm.addControl('area_code', new UntypedFormControl(null));
					this.bf.bigForm.patchValue({ area_code });
				}
			});
	}

	setColorIndicatorColor(entryOne: string, entryTwo: string) {
		if (!entryOne?.length || !entryTwo?.length) return 'red';

		const result = new RegExp(entryOne.replace(/\s+/g, ' '), 'gi')?.test(entryTwo.replace(/\s+/g, ' '));
		return result ? 'green' : 'red';
	}

	displayPropertyWithValue(property: string) {
		return property?.length ? property : 'Not Found';
	}

	ngOnDestroy() {
		this.findAndSetProvince();

		if (this.addressDetailsSubscription) this.addressDetailsSubscription.unsubscribe();
		if (this.searchedAddressSubscription) this.searchedAddressSubscription.unsubscribe();
		if (this.provinceListSubscription) this.provinceListSubscription.unsubscribe();
		if (this.networkCheckSubscription) this.networkCheckSubscription.unsubscribe();
	}
}
