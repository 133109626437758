import { ModuleWithProviders, NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appReducers } from './app.reducers';
import { appEffects } from './app.effects';
import { ENVIRONMENT } from './services';

@NgModule({
	declarations: [],
	imports: [
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(appEffects)
  ],
})
export class AppStoreModule {
  static forRoot(config: { environment: any}): ModuleWithProviders<AppStoreModule> {
    return {
      ngModule: AppStoreModule,
      providers: [
        {provide: ENVIRONMENT, useValue: config.environment },
      ]
    }
  }
}
