import { BigFormService, MakeServerCall, NodePoint_0_0_2 } from '@flexus/core';
import { take, map, filter, tap, pluck, skipWhile, mergeMap } from 'rxjs/operators';
import gql from 'graphql-tag';
import { flatten, values } from 'ramda';
import { environment } from 'apps/studio/src/environments/environment';
import { iif, of } from 'rxjs';
import { Store } from '@ngrx/store';

export const BET_BANK_DETAILS: NodePoint_0_0_2 = {
	component: 'BetBankDetailsComponent',
	hideTabItem: true,
	initFormFields: (bf, item, ins) => {
		bf.bigForm.addControl('avs_data', ins.bankDetailsForm);
	},
	serverCalls: {
		accountTypes: {
			errorMessage: 'Could not get account types!',
			directCall: (http, store, sq) => {
				return sq
					.queryObject(
						gql`
							{
								allInfo {
									account_type
								}
							}
						`,
						store
					)
					.pipe(
						filter(x => !!x),
						map(values),
						map<any, any>(flatten),
						take(1)
					);
			}
		},
		bankNames: {
			errorMessage: 'Could not get bank names!',
			dataKey: 'bankNames',
			directCall: (http, _s, _sq, _bf, _c, _m) => http.get(`${environment.api_url}v1/bank_action/list_banks`)
		}
	},
	inputs: {
		bankCodeLookupEvent: (bankId: string, store: Store, bf: BigFormService) => {
			store.dispatch(
				new MakeServerCall({
					directCall: http => {
						return http.get(`${environment.api_url}v1/branchcodes/${bankId}`).pipe(
							skipWhile(f => !f),
							take(1),
							pluck('payload'),
							mergeMap(payload => iif(() => Array.isArray(payload) && payload.length !== 0, of(payload[0].code), of(''))),
							tap(code => {
								const bankCodeControl = bf.bigForm.get('avs_data')?.get('branch_code');
								if (bankCodeControl) {
									bankCodeControl.setValue(code);
								}
							})
						);
					},
					dataKey: 'bankCode',
					errorMessage: 'No bank code found'
				})
			);
		}
	},
	navs: []
};
