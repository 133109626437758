import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { skipWhile, take, pluck } from 'rxjs/operators';
import { AmpEditClaim } from '../create-claim/amp-edit-claim';
import { Flow_0_0_2, getData } from '@flexus/core';

export const AMP_169: Flow_0_0_2 = {
	// From create Claim
	...AmpEditClaim,

	id: '169',
	name: 'edit local draft',

	header: {
		...AmpEditClaim.header,
		title: 'Edit Local Draft'
	},
	instructions: {
		editRoles: {
			0: 'Edit draft'
		},
		viewRoles: {
			0: '--'
		}
	},
	valuesToBigForm: (bf, store) => {
		const getItemOneStoreCall = store.select(getData).pipe(pluck('selectedItem'));

		getItemOneStoreCall
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe(itemOne => {
				// Initialize values
				const applicant = itemOne?.applicant;
				const loan_information = itemOne?.loan_information;
				const jobcardrequest = loan_information?.jobcardrequest;
				const createJob = (job?) => {
					if (job) {
						return bf.fb.group({
							id: job?.id,
							item_type: job?.item_type?.id ?? job?.item_type,
							make: job?.make && job?.make.id ? job?.make.id : job?.make,
							model: job?.model,
							photo: job?.photo,
							item_data: job?.item_data,
							policy: job?.policy,
							active: job?.active,
							created: job?.created,
							modified: job?.modified,
							skillrequested: job?.skillrequested,
							skillcatagory_id: job?.skillcatagory_id,
							skillcatagory: job?.skillcatagory, // string name
							skill_id: job?.skill_id,
							skill: job?.skill, // string name
							providertype_id: job?.providertype_id,
							providertype: job?.providertype, // string name
							appointmentDatePicker: new Date(job.requestedappointmentdate),
							appointmentTimePicker: {
								hour: job?.requestedappointmenttime?.split(':')[0] ?? '',
								minutes: job?.requestedappointmenttime?.split(':')[1] ?? ''
							},
							appointmentTime: job?.appointment_type_id,
							appointmentDateType: job?.appointmentdatetype,
							amount: job?.excess?.excess_amount,
							who_collects: job?.excess?.who_collects_excess,
							who_collects_description: job?.excess?.who_collects_excess_desc,
							is_cancelled: false,
							excess_description: job?.excess?.excess_description,
							payment_method: job?.excess?.excess_payment_method,
							payment_description: job?.excess?.excess_payment_method_desc
						});
					}
				};

				const mandatoryExcess = jobcardrequest?.[0]?.excess && Array.isArray(jobcardrequest[0]?.excess) && jobcardrequest[0]?.excess[0];

				const jobArray =
					jobcardrequest && jobcardrequest.length
						? jobcardrequest.map(job => {
								const theJob = createJob(job);
								return theJob;
						  })
						: [createJob()];

				// Form binding
				bf.addControl('jobs', new UntypedFormArray(jobArray));
				bf.addControl('latitude', new UntypedFormControl(+loan_information?.claimlatitude || 0));
				bf.addControl('longitude', new UntypedFormControl(+loan_information?.claimlongitude || 0));

				const applicant_information = applicant;

				bf.patchValues({
					new_state: 1,
					// Policy Lookup
					policy_lookup: applicant_information && applicant_information.claimantpoliceynum,
					// Policy Details
					plan_number: applicant_information && applicant_information.claimantpoliceynum ? applicant_information.claimantpoliceynum : '',
					client_details: {
						surname: (applicant_information && applicant_information.surname) || '',
						id_number: (applicant_information && applicant_information.id_number) || '',
						initials: (applicant_information && applicant_information.first_name) || '',
						plan_number: (applicant_information && applicant_information.claimantpoliceynum) || ''
						// bond_number: (applicant.bondnumber) || ''
					},
					contact_details: {
						// contact_number: loan_information?.contactnumber || '',
						cell_number: loan_information?.cellnumber || '',
						email: loan_information?.email || '',
						preferred_com_method: loan_information?.preferedcommethod || ''
					},
					insured_address: {
						complex: loan_information?.propertycomplex || '',
						street_address: loan_information?.propertystreetaddress || '',
						suburb: loan_information?.propertysuburb || '',
						city: loan_information?.propertycity || ''
					},

					handler: loan_information?.handler || '',
					handlercontact: loan_information?.handlercontact || '',
					handleremail: loan_information?.handleremail || '',

					// Policy Details
					market_segment_key: loan_information?.market_segment_key || '',
					local_file: loan_information?.local_file || '',
					is_joint_account: loan_information?.isjointaccount || '',
					clientcode: loan_information?.clientcode || '',

					// Address confirmation
					suburb_code: loan_information?.suburbcode || '',
					addressconfirmation: loan_information?.addressconfirmation || '',
					area_code: loan_information?.areacode || '',

					// Claim details
					claim_class: loan_information?.claimtype_id || '',
					claim_class_description: loan_information?.claimtype || '',

					// Claim Appointment
					on_site_contact: loan_information?.onsitecontact || '',
					on_site_notes: loan_information?.onsitenotes || '',
					on_site_person: loan_information?.onsiteperson || '',

					//Payment Details
					// reinsuranceexcess: loan_information?.reinsuranceexcess || '',
					// specialclause: loan_information?.specialclause || '',
					// voluntaryexcess: loan_information?.voluntaryexcess || '',
					// additionalexcess: loan_information?.additionalexcess,
					underwriter: loan_information?.underwriter || '',
					// sum_insured: loan_information?.suminsured || '',
					// additionalexcess: Number(additionalexcess),
					// reinsuranceexcess: Number(reinsuranceexcess),
					// voluntaryexcess: Number(voluntaryexcess),
					// specialclause: Number(specialclause),
					policy_excess: {
						is_cancelled: false,
						excess_description: mandatoryExcess ? mandatoryExcess?.excess_description : '',
						amount: mandatoryExcess.excess_amount,
						who_collects: mandatoryExcess ? mandatoryExcess.who_collects_excess : '',
						who_collects_description: mandatoryExcess ? mandatoryExcess.who_collects_excess_desc : '',
						payment_method: mandatoryExcess ? mandatoryExcess.excess_payment_method : '',
						payment_method_description: mandatoryExcess ? mandatoryExcess.excess_payment_method_desc : ''
					},

					// // Other
					// upfrontrepudiation: loan_information?.upfrontrepudiation || '',
					// upfrontrepudiationreason: loan_information?.upfrontrepudiationreason || '',
					// dontpingsp: loan_information?.dontpingsp || '',

					// What Matters Action Panel
					date_of_loss: loan_information?.dateofloss || '',
					incident_details: loan_information?.incident_details || '',
					whatmatters: loan_information?.whatmatters,
					claimdescription: loan_information?.claimdescription || ''

					// Claim Details context actions
					// _3rdpartyinsurancecompany: loan_information?._3rdpartyinsurancecompany || '',
					// _3rdpartyvechilereg: loan_information?._3rdpartyvechilereg || '',
					// _3rdpartyvechilemake: loan_information?._3rdpartyvechilemake || '',
					// _3rdpartydriverid: loan_information?._3rdpartydriverid || '',
					// _3rdpartydrivercell: loan_information?._3rdpartydrivercell || '',
					// _3rdpartydrivername: loan_information?._3rdpartydrivername || '',
					// firedamageextent: loan_information?.firedamageextent || '',
					// recomendedadjuster: loan_information?.recomendedadjuster || '',
					// policeclaimnumber: loan_information?.policeclaimnumber || ''
				});
			});
	},
	// startNode: 'PolicyDetails',
	dontLoadItemOneFromServer: true,
	nodes: {
		PlanLookup: {
			...AmpEditClaim?.nodes?.PlanLookup
		},
		PlanDetails: {
			...AmpEditClaim?.nodes?.PlanDetails
		},
		AddressConfirmation: {
			...AmpEditClaim?.nodes?.AddressConfirmation
		},
		ClaimSelection: {
			...AmpEditClaim?.nodes?.ClaimSelection
		},
		ClaimAppointments: {
			...AmpEditClaim?.nodes?.ClaimAppointments
		},
		ClaimPaymentDetails: {
			...AmpEditClaim?.nodes?.ClaimPaymentDetails
		},
		ClaimHistory: {
			...AmpEditClaim?.nodes?.ClaimHistory
		},
		ClaimSubmissionSummary: {
			...AmpEditClaim?.nodes?.ClaimSubmissionSummary,
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: instance => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, {
									component: 'FLXWhatMattersComponent',
									inputs: {
										datePickerTitle: 'Date of incident',
										additionalTextArea: {
											width: '280px',
											formControlName: 'incident_details',
											title: 'Description of Incident'
										}
									}
								})
							};
						}
					}
				},
				{
					text: 'Save as Draft',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						saveAsDraft: {
							errorMessage: 'Could not save the draft!',
							serviceVariable: 'ampService',
							functionName: 'checkNetworkAndUpdateDraft',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'ampService',
									functionName: 'deleteCurrentEditableItem'
								},
								deleteLocalDraft: {
									errorMessage: 'Could not delete the existing local draft.',
									serviceVariable: 'ampService',
									functionName: 'deleteLocalDraft'
								}
							}
						}
					}
				},
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'This claim could not be created!',
							serviceVariable: 'ampService',
							functionName: 'createClaim',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'ampService',
									functionName: 'deleteCurrentEditableItem'
								},
								deleteLocalDraft: {
									errorMessage: 'Could not delete the existing local draft.',
									serviceVariable: 'ampService',
									functionName: 'deleteLocalDraft'
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			...AmpEditClaim?.nodes?.SubmissionSuccess
		}
	}
};
