import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BigFormService } from '@flexus/core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'flexus-customer-details',
	templateUrl: './customer-details.component.html',
	styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit, AfterViewInit {
	provinceList: any[] = [];
	constructor(public _store: Store<any>, public bf: BigFormService, private cd: ChangeDetectorRef) {}

	ngOnInit(): void {
		const provinceList = this.bf?.storeObject?.selectedContext?.provinceList;
		this.provinceList = provinceList.map(province => ({ display: province?.name, value: province?.name, id: province?.id }));
	}

	setArea(event: string): void {
		const area = this.provinceList.find(province => province?.value === event)?.id;
		this.bf.bigForm.get('area').patchValue(area);
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.cd.detectChanges();
		}, 2000);
	}
}
