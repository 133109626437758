import { Flow_0_0_2, getFullItemOne, getCurrentUser } from '@flexus/core';
import { UntypedFormControl } from '@angular/forms';
import { map, take, filter, first, skipWhile, pluck } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const PGG_16: Flow_0_0_2 = {
	id: '16',
	name: 'claim_submitted_unallocated',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map(itemOne => {
					if (itemOne) {
						return 'Unmanaged MOBI Claim - ' + itemOne?.loan_information?.mavenclaimnumber + ' - ' + itemOne?.applicant?.surname;
					} else {
						return 'Unmanaged MOBI Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Claim Submitted Unallocated'
		},
		viewRoles: {
			0: 'Claim Submitted Unallocated'
		}
	},
	startNode: 'UnmanagedClaim',
	nodes: {
		UnmanagedClaim: {
			component: 'FileViewWithExtraComponent',
			initFormFields: (bf, item, inst, sq, store) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(1));
				store
					.select(getCurrentUser)
					.pipe(
						skipWhile(x => !x),
						pluck('user', 'id'),
						take(1)
					)
					.subscribe(id => {
						bf.bigForm.addControl('handler', new UntypedFormControl(id));
					});
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Cannot get retrieve data',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							// map((vals) => vals.claim),
							map((vals: any) => {
								const res = {
									'Claim Type': vals?.type ?? 'No Claim Type Found',
									Address:
										vals?.address ?? vals?.loan_information?.propertystreetaddress
											? vals?.loan_information?.propertystreetaddress + ', ' + vals?.loan_information?.propertysuburb + ', ' + vals?.loan_information?.propertycity
											: 'No Address found',
									'Client Name': vals?.applicant?.first_name + ' ' + vals.applicant?.surname ?? 'No Client Name Found',
									'Contact Number': vals?.applicant?.contact_number ?? 'No Contact Number Found',
									'Cell Number': vals?.applicant?.contact_number ?? 'No Contact Number Found',
									'What Matters': vals?.loan_information?.whatmatters ?? 'No What Matters Found'
								};
								return [res];
							})
						);
					}
				},
				copyText: {
					errorMessage: 'Cannot return installation number',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map((vals: any) => {
								return { key: 'INSTALLATION NO: ', value: vals.mid };
							})
						);
					}
				},
				files: {
					serviceVariable: 'pggService',
					functionName: 'getAllClaimFiles',
					responseSlice: 'payload',
					errorMessage: 'Could not get files from server!'
				},
				logs: {
					serviceVariable: 'pggService',
					functionName: 'getCallLogs',
					// responseSlice: 'payload',
					errorMessage: 'No call logs could be found!'
				}
			},
			inputs: {
				inputHeading: 'This Installation has been allocated to you ',
				subHeading: 'The installation was created after hours by the 4-Sure team.',
				fileDoesNotExistMessage: '',
				instructions: ['Start by allocation this installation to your workflow.'],
				containerWidth: '50vw'
			},
			navs: [
				{
					text: 'Acknowledge Claim',
					serverFirst: true,
					color: 'primary',
					linkType: 'submit',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							serviceVariable: 'pggService',
							functionName: 'updateClaim',
							errorMessage: 'Could not assign handler!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemOne.id': 'claim_id',
		// 'itemOne.state': 'current_state',
		handler: 'loan_information.handler'
	}
};
