import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, pluck, skipWhile, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { inlineRecursiveMerge } from '@flexus/utilities';
import { DraftBOQ } from '@flexus/models';
import { getFullItemTwo, getSelectedItemTwo, getSubmissionData } from '../../selected-context';

@Injectable({ providedIn: 'root' })
export class BillingService {
	constructor(@Inject('environment') private _environment: any, private http: HttpClient, private store: Store<any>) {}

	getSelectedItemTwo() {
		return this.store.select(getSelectedItemTwo).pipe(
			skipWhile(x => !x),
			take(1)
		);
	}

	updateJob() {
		return forkJoin([this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemTwo).pipe(take(1))]).pipe(
			map(([submissionData, job]) => {
				const { job_information } = job;
				const { job_information: ji, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(job_information ?? {}));
				inlineRecursiveMerge(result, ji);

				const response = {
					job_information: result,
					...rest,
					job
				};
				return response;
			}),
			switchMap(response => {
				const { job, ...data } = response;
				return this.http.post(`${this._environment.api_url}v1/job_action/update_job/`, {
					job_id: job?.id,
					...data
				});
			})
		);
	}

	public getBOQTemplates(
		id: number,
		type: string = '2',
		idType: 'skill' | 'claim_type' = 'skill',
		optionalInclusions?: 'includes_after_hours_items' | 'include_all'
	): Observable<any> {
		if (optionalInclusions) {
			return this.http.get<any>(`${this._environment.api_url}v2/boq_action/get_list_boq_templates?${idType}=${id}&type=${type}&${optionalInclusions}=1`);
		}
		return this.http.get<any>(`${this._environment.api_url}v2/boq_action/get_list_boq_templates?${idType}=${id}&type=${type}`);
	}

	getBoqItems(optionalInclusions?: 'include_all') {
		return this.store
			.select(getFullItemTwo)
			.pipe(take(1))
			.pipe(
				switchMap(fullItemTwo => {
					const data = { include_custom_items: 1 } as any;
					const isAfterHoursItem = fullItemTwo?.appointment && fullItemTwo[0] && !!fullItemTwo.appointment[0]?.after_hours;

					if (isAfterHoursItem) {
						data.optionalInclusions = 1;
						return this.http.post<any>(this._environment.api_url + 'v2/boq_action/get_boq_items', data);
					}
					return this.http.post<any>(this._environment.api_url + 'v2/boq_action/get_boq_items', data);
				})
			);
	}

	getBOQandExcessInfo() {
		return this.getSelectedItemTwo().pipe(
			pluck('id'),
			switchMap(id => {
				return forkJoin([this.getBOQ(id).pipe(skipWhile(boq => !boq)), this.getExcess(id).pipe(skipWhile(excess => !excess))]).pipe(
					map(([boq, excess]) => {
						return { boq: boq.payload, excess: excess.payload };
					})
				);
			}),
			take(1)
		);
	}

	getBOQInfo() {
		return this.getSelectedItemTwo().pipe(
			pluck('id'),
			switchMap(id => {
				return this.getBOQ(id).pipe(skipWhile(boq => !boq));
			}),
			take(1)
		);
	}

	getExcessInfo() {
		return this.getSelectedItemTwo().pipe(
			pluck('id'),
			switchMap(id => {
				return this.getExcess(id).pipe(skipWhile(excess => !excess));
			}),
			take(1)
		);
	}

	getDraftLocalStorageBOQ(): Observable<DraftBOQ> {
		return this.getSelectedItemTwo().pipe(
			pluck('id'),
			map(id => JSON.parse(localStorage.getItem(`DRAFT_${id}`))),
			take(1)
		);
	}

	getDraftBOQ(): Observable<DraftBOQ> {
		return this.getSelectedItemTwo().pipe(
			pluck('id'),
			switchMap(id => this.#fetchDraftBOQ(id)),
			take(1)
		);
	}

	getBOQ(job_id: number): Observable<any> {
		return this.http.post<any>(this._environment.api_url + 'v2/boq_action/get_boq', { job_id });
	}

	retrieveBOQ(): Observable<DraftBOQ> {
		return this.getDraftLocalStorageBOQ().pipe(
			switchMap((localStoreBOQ: DraftBOQ | null) => {
				if (localStoreBOQ) {
					return of(localStoreBOQ);
				} else {
					return this.getDraftBOQ().pipe(
						switchMap((draftBOQ: DraftBOQ | null) => {
							if (draftBOQ) {
								return of(draftBOQ);
							} else {
								// If neither localStoreBOQ nor draftBOQ is found, fetch from getBOQ
								return this.getSelectedItemTwo().pipe(
									pluck('id'),
									switchMap(id => this.getQuotation(id))
								);
							}
						})
					);
				}
			}),
			take(1)
		);
	}

	#fetchDraftBOQ = (job_id: number): Observable<DraftBOQ> => {
		const url = this._environment.api_url + 'v2/invoicing_action/get_draft_quote';
		const requestData = { job_id };

		return this.http.post<{ success: boolean; payload: DraftBOQ }>(url, requestData).pipe(map(res => res.payload));
	};

	getExcess(job_id: number) {
		return this.http.post<any>(this._environment.api_url + 'v2/invoicing_action/get_jobcard_excess', { job_id });
	}

	public getJobInvoicingStatus(): Observable<any> {
		return this.getSelectedItemTwo().pipe(
			pluck('id'),
			switchMap(job_id => {
				return this.http.post(this._environment.api_url + 'v2/invoicing_action/get_job_invoicing_status', { job_id }).pipe(
					mergeMap(f => {
						const payload: any = f['payload'];
						if (payload.invoice) {
							return this.getInvoice(payload.invoice.id).pipe(
								map(x => {
									x['docType'] = 'invoice';
									return x;
								})
							);
						} else if (payload.quotation) {
							return this.getQuotation(payload.quotation.id).pipe(
								map(t => {
									t['docType'] = 'quote';
									return t;
								})
							);
						} else {
							return of({ payload: { error: 'No invoice or quotation found!' } });
						}
					})
				);
			})
		);
	}

	getInvoice(invoice_id: string) {
		return this.http.post(this._environment.api_url + 'v2/invoicing_action/get_invoice', { invoice_id });
	}

	getQuotation(quotation_id: string): Observable<any> {
		return this.http.post(this._environment.api_url + 'v2/invoicing_action/get_quote', { quote_id: quotation_id });
	}

	getServiceProvider(): Observable<any> {
		return this.getSelectedItemTwo().pipe(
			pluck('sp'),
			switchMap(sp_id => {
				return this.http.get(`${this._environment.api_url}serviceprovider/${sp_id}/`).pipe(map(sp => ({ payload: sp, success: true })));
			})
		);
	}

	public getCustomTypesList() {
		return this.http.get(this._environment.api_url + 'v2/boq_action/get_item_types');
	}

	public getCustomYearsList(): Observable<any> {
		return this.http.get(this._environment.api_url + 'v2/boq_action/get_list_price_year');
	}

	public getSkillCategories(): Observable<any> {
		return this.http.get(this._environment.api_url + 'v2/boq_action/get_skill_categories');
	}

	public getSkillSubCategories(): Observable<any> {
		return this.http.get(this._environment.api_url + 'v2/boq_action/get_skill_subcategories');
	}

	public getUnitsOfMeasurement(): Observable<any> {
		return this.http.get(this._environment.api_url + 'v2/boq_action/get_units_of_measurement');
	}

	public createCustomLineItem(data: any): Observable<any> {
		return this.http
			.post(this._environment.api_url + 'v2/boq_action/create_custom_item/', { ...data, skill_categories: data.skill_categories.map(skill_category => skill_category.id) })
			.pipe(switchMap(() => this.getBoqItems()));
	}

	public updateCustomLineItem(data: any): Observable<any> {
		return this.http.post(this._environment.api_url + 'v2/boq_action/update_custom_item', data).pipe(switchMap(() => this.getBoqItems()));
	}

	getBanks() {
		return this.http.get(`${this._environment.api_url}bank/`);
	}

	getPermittedTravelDistance() {
		return this.getSelectedItemTwo().pipe(
			pluck('id'),
			switchMap(id => this.http.get(`${this._environment.api_url}v2/boq_action/get_permitted_travel_distance/?job_id=${id}`).pipe(skipWhile(travelDetails => !travelDetails))),
			take(1)
		);
	}

	getExcessChangeDetails() {
		return this.store.select(getFullItemTwo).pipe(
			skipWhile(x => !x),
			take(1),
			switchMap(job =>
				this.http
					.post(`${this._environment.api_url}v1/job_action/must_excess_change/`, { claim_type_id: job?.claim?.claim_type, skill_id: job.skill })
					.pipe(skipWhile(mustExcessChange => !mustExcessChange))
			),
			take(1)
		);
	}
}
