import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'flx-csv-item',
	templateUrl: 'csv-payment-item.component.html',
	styleUrls: ['csv-payment-item.component.scss']
})
export class CSVPaymentItemOrganism implements OnInit, OnDestroy {
	// variables
	CsvPaymentForm: UntypedFormGroup;
	private _payItem: any;
	public sp_name!: string;
	public bank_name!: string;
	public account_type!: string;
	public bank_branch!: string;
	public acc_number!: string;
	public amount;
	public statement_ref!: string;
	public sp_email!: string;
	public sps_sms!: string;

	// tslint:disable-next-line:no-output-on-prefix
	@Output() exludeItemAction = new EventEmitter<any>();
	checkValueSub: Subscription;

	@Input()
	set paymentitem(val: any) {
		this._payItem = val;
	}
	get paymentitem() {
		return this._payItem;
	}

	constructor(private fb: UntypedFormBuilder) {}

	ngOnInit() {
		this.CsvPaymentForm = this.fb.group({
			exclude_from_csv: [false]
		});
		this.checkValueSub = this.CsvPaymentForm.get('exclude_from_csv').valueChanges.subscribe((exclude: any) => {
			// this.exludeItemAction.emit('emitting');
			this.exludeItemAction.emit({ item: this.paymentitem, exclude });
		});
		this.sp_name = this.paymentitem['SP Name'];
		this.bank_name = this.paymentitem['SP Bank account name'];
		this.account_type = this.paymentitem['Bank account type\nCheque = 1\nSaving = 2'];
		this.bank_branch = this.paymentitem['Bank account branch'];
		this.acc_number = this.paymentitem['Bank account number'];
		this.amount = this.paymentitem['Amount'];
		this.statement_ref = this.paymentitem['Beneficiary statement reference'];
		this.sp_email = this.paymentitem['SP POP email'];
		this.sps_sms = this.paymentitem['SP SMS'];
	}
	ngOnDestroy() {
		if (this.checkValueSub) {
			this.checkValueSub.unsubscribe();
		}
	}
}
