import { Flow_0_0_2 } from '@flexus/core';

export const SIL_60: Flow_0_0_2 = {
	id: '60',
	name: 'off_panel_start_payment',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Obtain off panel invoice and upload'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: '',
	nodes: {}
};
