import { Flow_0_0_2 } from '@flexus/core';

export const SIL_69: Flow_0_0_2 = {
	id: '69',
	name: 'small_job_invoice_callout_fee',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Wait for SP to invoice call out fee'
		}
	},
	startNode: '',
	nodes: {}
};
