import * as fromActions from './sp-users-management.actions';

export interface SPUsersManagementContextState {
	UsersList: any[];
	usersLoaded: boolean;
}

const initialState: SPUsersManagementContextState = {
	UsersList: [],
	usersLoaded: false
};

export function spUsersManagementReducer(state: SPUsersManagementContextState = initialState, action: fromActions.SPUsersManagementActions) {
	switch (action.type) {
		case fromActions.GET_SP_USERS_LIST_SUCCESS: {
			const data = action.payload;

			return {
				...state,
				UsersList: data?.payload
			};
		}
		case fromActions.USERS_LOADED: {
			return {
				...state,
				usersLoaded: true
			};
		}
		default:
			return state;
	}
}

export const getUsersLoadedState = (state: SPUsersManagementContextState) => state.usersLoaded;
