
/** default filter to be used */
export const DefaultFilter: Filter = {
	field: 'quickFilter',
	description: 'default quick filter',
	filterValue: /(.|\n)+/,
	filt: obj => true
};



/**
 * Interface used to represent a filter to be appliad as well as some human readable information about it
 */
export interface Filter {
	field: string;
	filterValue: RegExp;
	description: string;
	filt: (entry: object) => boolean;
}
// bellow is the "v2" of the filters

export interface Filter2 {
	filterName: string;
	filterValue: Array<string>;
	filterType: string;
	allInfoName?: string;
	filt: (entry: object) => boolean;
}
/*
 * interface used to represent the data produced by the filter bar  and the events it can output
 */
export interface FilterEvent {
	filterText?: string;
	type: 'filterAdded' | 'filterUpdated' | 'quickFilter' | 'quickFilterCleared' | 'filterRemoved' | 'filtersReset';
	/**
	 * the filter this event is referring to
	 */
	filter: Filter;
	/**
	 * the list of all current filters
	 */
	filterList: Filter[];
}

/*
 * interface used to represent the data produced by the sort bar  and the events it can output
 */
export interface SortEvent {
	type: 'sortAltered' | 'sortCleared';

	/**
	 * the function that has been composed
	 */
	sortFunction: (a, b) => number;

	/**
	 * the fields being sorted by in order of descending magnitude
	 */
	fields: FillField[];
}

/**
 * Sort state, represents the direction of the sort,
 * inactive is 0 because thats the most neutral
 * ascending second because it starts with the lowst number
 * descending third as it starts with the largest
 */
export enum SortState {
	'inactive',
	'ascending',
	'descending'
}

/**
 * interface used to specify custom fields this component should allow the user to filter for.
 * it will also be used for auto-fill of input box options if this has been created
 */
export interface FillField {
	/**
	 * name to display for this filter
	 */
	displayName: string;

	/**
	 * the path to the field inside the object to be filtered in the same construct as the getByIndex methods path in the util lib
	 * @see getByIndex()
	 */
	path: string;

	/*
	 * an optional KV pair that can be used to display values that are associated to the data inside it captured as keys, this is expecting a KV pair
	 */
	context?: any;

	// TODO:
	/** NOT BEEN IMPLEMENTED YET. JUST MY THINKING
	 * a token to be associated with this field, ie $  or $state
	 * will look for the next word after the :
	 * and filter on that , with or's if there are ,'s separtng them
	 * ie $state => '$state:val1,val2,val3' => /(val1|val2|val3)/g.test(getbyId(path))
	 */
	// TODO:
	token?: string;
}

export enum FilterTypes {
	numericRange = 'numericRange',
	dateRange = 'dateRange',
	select = 'select',
	input = 'input'
}

export type FilterConfigs = InputPromptConfig | NumericRangePromptConfig | SelectPromptConfig | DateRangeConfig;

export interface MinSetComparator {
	type: FilterTypes;
	//  will be 1 for single types and 2 for range types
	value_selected: Array<string | number>;
	path;
}
// this is the config for the container, which aggregates the layers bellow it

export interface InputPromptConfig {
	type: FilterTypes.input;
	prompt: string;
}

export interface NumericRangePromptConfig {
	type: FilterTypes.numericRange;
	prompt: string;
	min?: number;
	max?: number;
}

export interface SelectPromptConfig {
	type: FilterTypes.select;
	prompt: string;
	context: { [displayName: string]: any };
}

export interface DateRangeConfig {
	type: FilterTypes.dateRange;
	prompt: string;
	startDate?: string;
	endDate?: string;
}
