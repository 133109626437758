import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[formatCurrency]'
})
export class FormatCurrencyDirective {
	constructor(private _elementRef: ElementRef, private _control: NgControl) {}

	@HostListener('blur', ['$event.target'])
	public onBlur(targetElement) {
		const num = Number(this._elementRef.nativeElement.value);
		if (!isNaN(num)) {
			this._control.control?.setValue(num.toFixed(2));
		}
	}
}
