import { Flow_0_0_2, getSelectedItemOne, getFullItemTwo } from '@flexus/core';
import { forkJoin } from 'rxjs';
import { skipWhile, take, map, switchMap, filter, first } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const BUI_43: Flow_0_0_2 = {
	id: '43',
	name: 'job_rework_complete',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				filter(x => !!x),
				skipWhile((itemTwo: any) => {
					return itemTwo === null || itemTwo === undefined;
				}),
				first(itemTwo => itemTwo !== null || itemTwo !== undefined),
				map((itemTwo: any) => {
					if (itemTwo) {
						return `Job Invoiced - Invalid Claim: ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Job Invoiced - Invalid Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Approve the rework'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	fetchLevel1And2: true,
	startNode: 'ReworkJob',
	nodes: {
		ReworkJob: {
			inputs: { inputHeading: 'Payment Details' },
			component: {
				children: [{ component: 'FLXKeyValueListComponent', inputs: { data$: 'keyValueList' } }]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getSelectedItemOne).pipe(
								skipWhile(x => !x),
								take(1)
							),
							store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1)
							)
						]).pipe(
							take(1),
							map(([claim, job]) => {
								const list = {
									'Client Name': claim?.applicant ? claim?.applicant?.first_name + ' ' + claim?.applicant?.surname : '',
									Address: job?.address ?? '',
									ClaimType: claim?.loan_information?.claimtype ?? '',
									'Contact number': claim?.applicant ? claim?.loan_information?.contactnumber : '',
									'Cell number': claim?.loan_information?.cellnumber ?? '',
									'Excess amount': job?.excess?.[0]?.amount ?? '',
									'What matter to the customer': claim?.loan_information?.whatmatters ?? '',
									'State job was in when rework was requested': job?.job_information?.prereworkstate ?? ''
								};

								return [list];
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Continue',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const { job_information, id } = job;
											// All these are work state. If returned to these then the job will get into a loop back to TL.
											const workState = ['22', '23', '24', '25', '29', '32', '33', '35', '36'];
											// Picking up the rework state from job_information.
											const preWorkState = job_information['prereworkstate'];
											// checking if the state is a valid number or not.
											//if (parseInt(preWorkState, 10)) return;
											// Creating new state variable and set the pre rework state
											let new_state = parseInt(preWorkState, 10);

											// check the saved return state to see if it must be returned to that state.
											if (workState.includes(preWorkState)) {
												new_state = 26;
											}

											const result = JSON.parse(JSON.stringify(job_information));
											result.prereworkstate = '';
											const subData = {
												job_information: result,
												job_id: id,
												new_state
											};
											return subData;
										}),
										switchMap(dataSub => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	}
};
