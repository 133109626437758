<flx-heading
	*ngIf="title"
	[size]="headingConfig.size"
	align="center"
	[type]="headingConfig.type"
	[weight]="headingConfig.weight"
	[color]="headingConfig.color"
	[margin]="headingConfig.itemMargin"
	>{{ title }}</flx-heading
>
<div class="instructions" *ngIf="instructions?.length > 0">
	<ng-container *ngFor="let line of instructions">
		<p>{{ line }}</p>
	</ng-container>
</div>
