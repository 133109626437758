import { Flow_0_0_2, getFullItemOne, getSelectedItemOne, StopLoader } from '@flexus/core';
import { Validators, UntypedFormArray, FormControl, FormGroup } from '@angular/forms';
import { skipWhile, take, map } from 'rxjs/operators';
import { MulEditClaim } from '../create-claim/mul-edit-claim';
import { UUID } from 'angular2-uuid';
import { checkForValidDate, CustomValidators, generateRange, stringToBoolean } from '@flexus/utilities';

export const MUL_169: Flow_0_0_2 = {
	// From create Claim
	...MulEditClaim,

	id: '169',
	name: 'edit local draft',

	header: {
		...MulEditClaim.header,
		title: 'Edit Local Draft'
	},
	instructions: {
		editRoles: {
			0: 'Edit draft'
		},
		viewRoles: {
			0: '--'
		}
	},
	valuesToBigForm: (bf, store) => {
		store
			.select(getFullItemOne)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe(itemOne => {
				// Initialize values
				const applicant = JSON.parse(JSON.stringify(itemOne?.applicant));
				const loan_information = JSON.parse(JSON.stringify(itemOne?.loan_information));
				const jobcardrequest = loan_information?.jobcardrequest;
				const createDeviceFormGroup = device => {
					return bf.fb.group({
						replacement_device: bf.fb.group({
							name: [device?.replacement_device?.name],
							id: [device?.replacement_device?.id, [Validators.required]]
						}),
						insured_device: bf.fb.group({
							serial_number: [device?.insured_device?.serial_number, [Validators.required]]
						})
					});
				};

				const createJob = (job?) => {
					if (job) {
						return bf.fb.group({
							id: UUID.UUID(),
							skillrequested: job?.skillrequested,
							skillcatagory_id: job?.skillcatagory_id,
							skillcatagory: job?.skillcatagory, // string name
							skill_id: job?.skill_id,
							skill: job?.skill, // string name
							providertype_id: job?.providertype_id,
							providertype: job?.providertype, // string name
							appointmentDatePicker: new Date(job?.requestedappointmentdate),
							appointmentTimePicker: {
								hour: job?.requestedappointmenttime?.split(':')[0] ?? '',
								minutes: job?.requestedappointmenttime?.split(':')[1] ?? ''
							},
							appointmentTime: job?.appointment_type_id,
							appointmentDateType: job?.appointmentdatetype,
							amount: [job?.amount, [Validators.minLength(4), CustomValidators.hardMaxLength(13), CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])]],
							...(Array.isArray(job?.device_details)
								? {
										device_details: new UntypedFormArray(
											job?.device_details?.map(device => {
												return createDeviceFormGroup(device);
											})
										)
								  }
								: {
										device_details: new UntypedFormArray([createDeviceFormGroup(job?.device_details)])
								  })
						});
					} else {
						return bf.fb.group({
							id: UUID.UUID(),
							skillrequested: [1, [Validators.required]], // signal to the server to create job
							skillcatagory_id: [null, [Validators.required]],
							skillcatagory: null, // string name
							skill_id: [null, [Validators.required]],
							skill: null, // string name
							providertype_id: [1],
							providertype: ['Request SP'], // string name
							appointmentDatePicker: null,
							appointmentTimePicker: null,
							appointmentTime: null,
							amount: [0, [Validators.minLength(4), CustomValidators.hardMaxLength(13), CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])]],
							who_collects: [' '],
							who_collects_description: null,
							is_cancelled: false,
							payment_method: [' '],
							payment_description: null,
							device_details: new UntypedFormArray([
								bf.fb.group({
									replacement_device: bf.fb.group({ name: [null], id: [null, [Validators.required]] }),
									insured_device: bf.fb.group({
										serial_number: [null, [Validators.required]]
									})
								})
							])
						});
					}
				};

				const jobArray = jobcardrequest?.length
					? jobcardrequest.map(job => {
							const theJob = createJob(job);
							return theJob;
					  })
					: [createJob()];

				if (typeof loan_information?.claimlatitude === 'undefined' ?? !loan_information?.claimlatitude) {
					loan_information.claimlatitude = undefined;
				}

				if (typeof loan_information?.claimlongitude === 'undefined' ?? !loan_information?.claimlongitude) {
					loan_information.claimlongitude = undefined;
				}

				// Form binding

				bf.addControl('jobs', new UntypedFormArray(jobArray));
				bf.getControl('address_details').patchValue({
					latitude: +loan_information?.claimlatitude ?? undefined,
					longitude: +loan_information?.claimlongitude ?? undefined
				});

				bf.getControl('client_details').patchValue({
					surname: applicant?.surname ?? '',
					id_number: applicant?.id_number ?? '',
					first_name: applicant?.first_name ?? '',
					policy_number: applicant?.information?.claimantpoliceynum ?? '',
					bond_number: applicant?.information?.bondnumber ?? '',
					dstv_account_number: applicant?.information?.dstv_account_number ?? '',
					mavenclaimnumber: loan_information?.mavenclaimnumber ?? ''
				});

				bf.getControl('current_environment').patchValue({
					no_view_environments: loan_information?.current_environment?.no_view_environments ?? null,
					dwelling_type: loan_information?.current_environment?.dwelling_type ?? null,
					no_of_tvs: loan_information?.current_environment?.no_of_tvs ?? null,
					type_of_tvs: loan_information?.current_environment?.type_of_tvs ?? null
				});

				bf.getControl('contact_details').patchValue({
					contact_number: loan_information?.contactnumber ?? '',
					cell_number: loan_information?.cellnumber ?? '',
					email: loan_information?.email ?? '',
					preferred_com_method: loan_information?.preferedcommethod ?? ''
				});

				bf.getControl('insured_address').patchValue({
					complex: loan_information?.propertycomplex ?? '',
					complex_unit_number: loan_information?.propertycomplexunitnumber || '',
					street_address: loan_information?.propertystreetaddress ?? '',
					suburb: loan_information?.propertysuburb ?? '',
					city: loan_information?.propertycity ?? ''
				});

				bf.patchValues({
					new_state: 1,
					checked_policy_details: true,
					checked_address_confirmation: true,
					checked_claim_details: true,
					checked_appointments: true,
					// Policy Lookup
					policy_lookup: applicant?.claimantpoliceynum,
					// Policy Details
					policy_number: applicant?.claimantpoliceynum ?? '',

					handler: loan_information?.handler ?? '',
					handlercontact: loan_information?.handlercontact ?? '',
					handleremail: loan_information?.handleremail ?? '',

					// Address confirmation
					suburb_code: loan_information?.suburbcode ?? '',

					area_code: loan_information?.areacode ?? '',

					// Claim details
					claim_class: loan_information?.claimtype_id ?? '',
					claim_class_description: loan_information?.claimtype ?? '',
					selectedClaimClass: loan_information?.claimtype_id ? [[loan_information.claimtype_id]] : '',

					// Claim Appointment
					on_site_contact: loan_information?.onsitecontact ?? '',
					on_site_notes: loan_information?.onsitenotes ?? '',
					on_site_person: loan_information?.onsiteperson ?? '',

					// // Other
					upfrontrepudiation: loan_information?.upfrontrepudiation ? stringToBoolean(loan_information?.upfrontrepudiation) : '',
					upfrontrepudiationreason: loan_information?.upfrontrepudiationreason ?? '',
					dontpingsp: loan_information?.dontpingsp ?? '',

					// What Matters Action Panel
					date_of_loss: loan_information?.dateofloss ? checkForValidDate(loan_information?.dateofloss) : '',
					whatmatters: loan_information?.whatmatters ?? '',
					claimdescription: loan_information?.claimdescription ?? '',
					...(loan_information?.market_segment_key ? { market_segment_key: loan_information?.market_segment_key } : {}),
					...(loan_information?.isjointaccount ? { isjointaccount: loan_information?.isjointaccount } : {}),
					...(loan_information?.local_file ? { local_file: loan_information?.local_file } : {}),
					...(loan_information?.clientcode ? { clientcode: loan_information?.clientcode } : {}),
					...(loan_information?.sum_insured ? { sum_insured: loan_information?.sum_insured } : {}),
					...(loan_information?.addressconfirmation ? { addressconfirmation: loan_information?.addressconfirmation } : {}),
					...(loan_information?.firedamageextent ? { firedamageextent: loan_information?.firedamageextent } : {}),
					...(loan_information?.recomendedadjuster ? { recomendedadjuster: loan_information?.recomendedadjuster } : {}),
					...(loan_information?.policeclaimnumber ? { policeclaimnumber: loan_information?.policeclaimnumber } : {}),
					...(loan_information?.underwriter ? { underwriter: loan_information?.underwriter } : {})
				});
			});
	},
	startNode: 'PolicyDetails',
	dontLoadItemOneFromServer: true,
	nodes: {
		PolicyDetails: {
			...MulEditClaim?.nodes?.PolicyDetails
		},
		AddressConfirmation: {
			...MulEditClaim?.nodes?.AddressConfirmation
		},
		ClaimDetails: {
			...MulEditClaim?.nodes?.ClaimDetails
		},
		DeviceDetails: {
			...MulEditClaim.nodes.DeviceDetails
		},
		ClaimAppointments: {
			...MulEditClaim?.nodes?.ClaimAppointments
		},
		CurrentSetup: {
			...MulEditClaim.nodes.CurrentSetup
		},
		ClaimSubmissionSummary: {
			...MulEditClaim?.nodes?.ClaimSubmissionSummary,
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'Save as Draft',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						saveAsDraft: {
							errorMessage: 'Could not save the draft!',
							serviceVariable: 'silService',
							functionName: 'checkNetworkAndUpdateDraft',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'silService',
									functionName: 'deleteCurrentEditableItem'
								},
								deleteLocalDraft: {
									errorMessage: 'Could not delete the existing local draft.',
									serviceVariable: 'silService',
									functionName: 'deleteLocalDraft'
								}
							}
						}
					}
				},
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'This claim could not be created!',
							serviceVariable: 'silService',
							functionName: 'createClaim',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'silService',
									functionName: 'deleteCurrentEditableItem'
								},
								deleteLocalDraft: {
									errorMessage: 'Could not delete the existing local draft.',
									serviceVariable: 'silService',
									functionName: 'deleteLocalDraft'
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			...MulEditClaim?.nodes?.SubmissionSuccess
		}
	}
};
