import { claimPermissions } from './configs/permissions/claim.permissions';
import { jobPermissions } from './configs/permissions/job.permissions';
import { ClaimWorkflow } from './configs/workflow/claim-workflow';
import { SilCreateClaim } from './configs/create-claim/sil-create-claim';
import * as States from './configs/flows';
import { Manifest_0_0_2, getCurrentUser } from '@flexus/core';
import { SilSearch } from './configs/search/sil-search';
import { applySilVirtualStates } from './sil-virtual-state.helper';
import { SilEditClaim } from './configs/create-claim/sil-edit-claim';
import { Store } from '@ngrx/store';
import { skipWhile, take, map, filter } from 'rxjs/operators';
import { FLXAppMenuComponent } from '../../app-shell-features/app-menu/app-menu.component';

export const sil: Manifest_0_0_2 = {
	version: '0.0.2',
	orgKey: 'sil',
	settings: {
		addActionPanel: true,
		addAppBar: true,
		addAppMenu: true,
		addFooter: true,
		addHeaderActions: true,
		addSearchBanner: true,
		addReminder: true
	},
	appMenu: (instance: FLXAppMenuComponent, store: Store<any>) => {
		return store.select(getCurrentUser).pipe(
			filter(user => !!user),
			map((currentUser: any) => {
				const createRoles = [1, 6, 18];
				const menuItems = [{ id: 1, name: 'Workflow', routerLink: '/workflow', iconType: 'app-menu-workflow' }];

				if (currentUser?.user?.roles?.some(role => createRoles.includes(role))) {
					menuItems.push({ id: 2, name: 'New Claim', routerLink: '/workflow/detail', iconType: 'app-menu-new-claim' });
				}

				return menuItems;
			})
		);
	},
	virtualStatesFunction: applySilVirtualStates,
	appSearch: SilSearch,
	permissions: {
		itemOne: claimPermissions,
		itemTwo: jobPermissions
	},
	manifestItems: {
		workflow: ClaimWorkflow,
		create_item_one: SilCreateClaim,
		// [8 || 172]: SilEditClaim,
		[States.SIL_1.id]: States.SIL_1,
		[States.SIL_2.id]: States.SIL_2,
		[States.SIL_3.id]: States.SIL_3,
		[States.SIL_4.id]: States.SIL_4,
		[States.SIL_5.id]: States.SIL_5,
		[States.SIL_6.id]: States.SIL_6,
		[States.SIL_7.id]: States.SIL_7,
		[States.SIL_8.id]: SilEditClaim,
		[States.SIL_9.id]: States.SIL_9,
		[States.SIL_10.id]: States.SIL_10,
		[States.SIL_11.id]: States.SIL_11,
		[States.SIL_12.id]: States.SIL_12,
		[States.SIL_13.id]: States.SIL_13,
		[States.SIL_14.id]: States.SIL_14,
		[States.SIL_15.id]: States.SIL_15,
		[States.SIL_16.id]: States.SIL_16,
		[States.SIL_17.id]: States.SIL_17,
		[States.SIL_18.id]: States.SIL_18,
		[States.SIL_19.id]: States.SIL_19,
		[States.SIL_20.id]: States.SIL_20,
		[States.SIL_21.id]: States.SIL_21,
		[States.SIL_22.id]: States.SIL_22,
		[States.SIL_23.id]: States.SIL_23,
		[States.SIL_24.id]: States.SIL_24,
		[States.SIL_25.id]: States.SIL_25,
		[States.SIL_26.id]: States.SIL_26,
		[States.SIL_27.id]: States.SIL_27,
		[States.SIL_28.id]: States.SIL_28,
		[States.SIL_29.id]: States.SIL_29,
		[States.SIL_30.id]: States.SIL_30,
		[States.SIL_31.id]: States.SIL_31,
		[States.SIL_32.id]: States.SIL_32,
		[States.SIL_33.id]: States.SIL_33,
		[States.SIL_34.id]: States.SIL_34,
		[States.SIL_35.id]: States.SIL_35,
		[States.SIL_36.id]: States.SIL_36,
		[States.SIL_37.id]: States.SIL_37,
		[States.SIL_38.id]: States.SIL_38,
		[States.SIL_39.id]: States.SIL_39,
		[States.SIL_40.id]: States.SIL_40,
		[States.SIL_41.id]: States.SIL_41,
		[States.SIL_42.id]: States.SIL_42,
		[States.SIL_43.id]: States.SIL_43,
		[States.SIL_44.id]: States.SIL_44,
		[States.SIL_45.id]: States.SIL_45,
		[States.SIL_46.id]: States.SIL_46,
		[States.SIL_47.id]: States.SIL_47,
		[States.SIL_48.id]: States.SIL_48,
		[States.SIL_49.id]: States.SIL_49,
		[States.SIL_50.id]: States.SIL_50,
		[States.SIL_51.id]: States.SIL_51,
		[States.SIL_52.id]: States.SIL_52,
		[States.SIL_53.id]: States.SIL_53,
		[States.SIL_54.id]: States.SIL_54,
		[States.SIL_55.id]: States.SIL_55,
		[States.SIL_56.id]: States.SIL_56,
		[States.SIL_57.id]: States.SIL_57,
		[States.SIL_58.id]: States.SIL_58,
		[States.SIL_59.id]: States.SIL_59,
		[States.SIL_60.id]: States.SIL_60,
		[States.SIL_61.id]: States.SIL_61,
		[States.SIL_62.id]: States.SIL_62,
		[States.SIL_63.id]: States.SIL_63,
		[States.SIL_64.id]: States.SIL_64,
		[States.SIL_65.id]: States.SIL_65,
		[States.SIL_66.id]: States.SIL_66,
		[States.SIL_67.id]: States.SIL_67,
		[States.SIL_68.id]: States.SIL_68,
		[States.SIL_69.id]: States.SIL_69,
		[States.SIL_70.id]: States.SIL_70,
		[States.SIL_71.id]: States.SIL_71,
		[States.SIL_72.id]: States.SIL_72,
		[States.SIL_73.id]: States.SIL_73,
		[States.SIL_74.id]: States.SIL_74,
		[States.SIL_75.id]: States.SIL_75,
		[States.SIL_76.id]: States.SIL_76,
		[States.SIL_77.id]: States.SIL_77,
		[States.SIL_78.id]: States.SIL_78,
		[States.SIL_79.id]: States.SIL_79,
		[States.SIL_80.id]: States.SIL_80,
		[States.SIL_81.id]: States.SIL_81,
		[States.SIL_82.id]: States.SIL_82,
		[States.SIL_83.id]: States.SIL_83,
		[States.SIL_84.id]: States.SIL_84,
		[States.SIL_85.id]: States.SIL_85,
		[States.SIL_86.id]: States.SIL_86,
		[States.SIL_87.id]: States.SIL_87,
		[States.SIL_88.id]: States.SIL_88,
		[States.SIL_89.id]: States.SIL_89,
		[States.SIL_90.id]: States.SIL_90,
		[States.SIL_91.id]: States.SIL_91,
		[States.SIL_92.id]: States.SIL_92,
		[States.SIL_93.id]: States.SIL_93,
		[States.SIL_94.id]: States.SIL_94,
		[States.SIL_95.id]: States.SIL_95,
		[States.SIL_96.id]: States.SIL_96,
		[States.SIL_97.id]: States.SIL_97,
		[States.SIL_98.id]: States.SIL_98,
		[States.SIL_99.id]: States.SIL_99,
		[States.SIL_100.id]: States.SIL_100,
		[States.SIL_101.id]: States.SIL_101,
		[States.SIL_102.id]: States.SIL_102,
		[States.SIL_103.id]: States.SIL_103,
		[States.SIL_104.id]: States.SIL_104,
		[States.SIL_105.id]: States.SIL_105,
		[States.SIL_106.id]: States.SIL_106,
		[States.SIL_107.id]: States.SIL_107,
		[States.SIL_108.id]: States.SIL_108,
		[States.SIL_109.id]: States.SIL_109,
		[States.SIL_110.id]: States.SIL_110,
		[States.SIL_154.id]: States.SIL_154,
		[States.SIL_156.id]: States.SIL_156,
		[States.SIL_161.id]: States.SIL_161,
		[States.SIL_162.id]: States.SIL_162,
		[States.SIL_169.id]: States.SIL_169,
		[States.SIL_170.id]: States.SIL_170,
		[States.SIL_172.id]: States.SIL_172,
		[States.SIL_197.id]: States.SIL_197,
		[States.SIL_198.id]: States.SIL_198,
		[States.SIL_227.id]: States.SIL_227,
		[States.SIL_237.id]: States.SIL_237,
		[States.SIL_275.id]: States.SIL_275,
		[States.SIL_276.id]: States.SIL_276
	}
};
