<flx-heading size="medium">Policy History</flx-heading>
<ng-container *ngIf="policyHistory$ | async as listData">
	<flx-accordion-template *ngIf="listData.length > 0; else noClaims">
		<flx-accordion-group *ngFor="let listItem of listData" [title]="listItem.title">
			<div class="policy-history-flex">
				<flx-heading size="small">Claim Status</flx-heading>
				<span>{{ listItem.additionalItems[0]?.value }}</span>
			</div>
			<div class="policy-history-flex">
				<flx-heading size="small">Claim Cost</flx-heading>
				<span>{{ listItem.additionalItems[1]?.value }}</span>
			</div>
			<div class="policy-history-description">
				<flx-heading size="small">Description</flx-heading>
				<p>{{ listItem.description.value }}</p>
			</div>
			<flx-glow-line margin="1rem 0"></flx-glow-line>
		</flx-accordion-group>
	</flx-accordion-template>
	<ng-template #noClaims> <flx-alert type="no-info" missingItem="claims"></flx-alert> </ng-template>
</ng-container>
