import { Flow_0_0_2 } from '@flexus/core';
import { setActionPanelItems } from '../../../../app-shell-features';
import { resolvedProblemNode } from '../reusable/RESOLVED_PROBLEM_NODE';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { getInterstateComments } from '../reusable/dataManipulations';
import { FormControl, Validators } from '@angular/forms';

export const MUL_186: Flow_0_0_2 = {
	id: '186',
	name: 'fsia_error',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'FSIA Error',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Unable to activate customer on FSIA'
		},
		viewRoles: {
			0: 'Unable to activate customer on FSIA'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		}
	},
	startNode: 'jobInformation',
	nodes: {
		jobInformation: {
			// showTabs: true,
			...jobInfoNode(186, 176)
		},
		resolvedProblem: {
			...resolvedProblemNode(186, 26)
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		message: [(message, storeObj, bf) => getInterstateComments(message, storeObj, bf), 'job_information.interstate_comments'],
		new_state: 'new_state',
		activation_fix: [() => 'Activated', 'job_information.unable_to_activate_reason']
	}
};
