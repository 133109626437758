import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FLXNoteListComponent } from './note-list.component';
import { FLXNotesModule, FLXAlertsModule, FLXHeadingsModule } from '@flexus/ui-elements';
import { FLXGlowLineModule } from '@flexus/ui-elements';

// import { FLXListAndKeysInfoComponent } from './list-and-keys-info.component';
// import { FLXHeadingsModule, FLXListAtomModule } from '@flexus/ui-elements';
// import { FLXDirectivesModule } from '@flexus/directives/src/directives.module';

@NgModule({
	imports: [CommonModule, FLXNotesModule, FLXAlertsModule, FLXHeadingsModule, FLXGlowLineModule],
	declarations: [FLXNoteListComponent],
	exports: [FLXNoteListComponent]
})
export class FLXNoteListModule {}
