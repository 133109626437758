import { CustomerRatingComponent } from './customer-rating/customer-rating.component';
import { PolicyLookupComponent } from './policy-lookup/policy-lookup.component';
import { AccountLookupComponent } from './account-lookup/account-lookup.component';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AddressConfirmationComponent } from './address-confirmation/address-confirmation.component';
import { ClaimDetailsComponent } from './claim-details/claim-details.component';
import { AmpProductSelectionComponent } from './amp-product-selection/amp-product-selection.component';
import { ClaimClassComponent } from './claim-class/claim-class.component';
import { AddJobComponent } from './add-job/add-job.component';
import { ClaimHistoryComponent } from './claim-history/claim-history.component';
import { AssignIAComponent } from './assign-ia/assign-ia.component';
import { AssignTLComponent } from './assign-tl/assign-team-leader.component';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {
	FLXFlatDynamicFormComponent,
	FLXListAndKeysInfoComponent,
	FLXKeyValueDisplayWithInstructionsComponent,
	FLXFileUploadWithPreviewComponent,
	FLXAlertMessagesComponent,
	FLXSetAppointmentComponent,
	FLXSetExcessComponent,
	FLXHeadingWithInstructionsComponent,
	FLXViewPhotosComponent,
	FLXHeadingWithULComponent
} from '@flexus/ui-composites';
import {
	FLXSuccessTickComponent,
	FLXKeyValueListComponent,
	FLXNestedObjectKeyValueListComponent,
	FLXLabelComponent,
	FLXFileBrowserComponent,
	FLXDynamicTableComponent,
	FLXSelectListComponent
} from '@flexus/ui-elements';
import { FLXInternalAssessorJobOverviewComponent } from './internal-assessor-job-overview/internal-assessor-job-overview.component';
import { FLXInternalAssessorJobsSummaryComponent } from './internal-assessor-jobs-summary';
import { FLXInternalAssessorOverviewComponent } from './internal-assessor-overview/internal-assessor-overview.component';
import { ClaimAppointmentsComponent } from './claim-appointments/claim-appointments.component';
import { ClaimPaymentDetailsComponent } from './claim-payment-details';
import { FileViewWithExtraComponent } from './file-view-with-extras/file-view-with-extra.component';
import { BankDetailsComponent } from './bank-details-template/bank-details.component';
import { CloseClaimComponent } from './close-claim/close-claim.component';
import { FLXSpReplyListComponent } from './sp-reply-list/sp-reply-list.component';
import { ClaimSummaryComponent } from './claim-summary/claim-summary.component';
import { FLXContextMenuWhatMattersComponent } from './context-menu-what-matters/context-menu-what-matters.component';
import { CallLogsComponent } from './call-logs/call-logs.component';
import { NotesComponent } from './notes/notes.component';
import { IANotesComponent } from './ia-notes/ia-notes.component';
import { FLXWarrantyVoidComponent } from './context-menu-job/warranty-void-jobcontext/warranty-void-jobcontext.component';
import { FLXSPDetailsJobContextComponent } from './sp-details/sp-details.component';
import { FLXCancelJobComponent } from './context-menu-job/cancel-job/cancel-job.component';
import { FLXCancelClaimComponent } from './context-menu-claim/cancel-claim/cancel-claim.component';
import { ViewDocumentsComponent } from './view-documents/view-documents.component';
import { FLXJobAppointmentComponent } from './job-appointment/job-appointment.component';
import { FLXJobExcessComponent } from './job-excess/job-excess.component';
import { AgreementOfLossComponent } from './agreement-of-loss/agreement-of-loss.component';
import { ViewPaymentsComponent } from './view-payments/view-payment.component';
import { FLXPolicyHistoryComponent } from './policy-history/policy-history.component';
import { FLXQueryHistoryComponent } from './query-history/query-history.component';
import { InternalAssessorSelectOtherJobsComponent } from './internal-assessor-select-other-jobs/internal-assessor-select-other-jobs.component';
import { FLXUploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { FLXTimeStampsComponent } from './time-stamps/time-stamps.component';
import { FLXSpEditTLReportComponent } from './sp-edit-tl-report/sp-edit-tl-report.component';
import { FLXExcessDetailsOnJobComponent } from './excess-details-on-job/excess-details-on-job.component';
import { FLXAuthoriserSelectComponent } from './authoriser-select/authoriser-select.component';
import { AmpDamageClassComponent } from './amp-damage-class/amp-damage-class.component';
import { FLXCommentsComponent } from './comments';
import { CloseClaimGeneralComponent } from './close-claim-general/close-claim-general.component';
import { FLXFileViewWithSideExtrasComponent } from './file-view-with-side-extras';
// import { FLXViewPhotosComponent } from './view-photos/view-photos.componet';
import { DynamicAnchorComponent } from './dynamic-anchor/dynamic-anchor.component';
import { FLXExcessManagementComponent } from './excess-management';
import { BetBankDetailsComponent } from './bet-bank-details-template';
import { DeviceDetailsComponent } from './device-details';
import { CurrentSetupComponent } from './current-setup';
import { TeamLeadLookupComponent } from './team-lead-lookup';
import { PingGoFakeModalComponent } from './pgg-fake-modal/pgg-fake-modal.component';
import { PinggoRefundBreakdownComponent } from './pgg-refund-breakdown/pgg-refund-breakdown.component';
import { PggBankDetailsTemplateComponent } from './pinggo-bank-details-template/pgg-bank-details-template.component';
import { PinggoVoucherRefundCalculatorComponent } from './pinggo-refund-calculator/pinggo-refund-calculator.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { SpAddJobComponent } from './sp-add-job/sp-add-job.component';
import { PinggoSPPaymentBreakdownComponent } from './pinggo-sp-payment-breakdown';
import { PinggoCSVPaymentComponent } from './pinggo-csv-payments';
import { FakeModalComponent } from './fake-modal';
import { IframeComponent } from './iframe.component';
import { FLXSpWithDistancesComponent } from './sp-with-distance-list';
import { FLXAutoPayFixerComponent } from './autopay-decline-fixer';
import { FLXAutopayAddEquipmentComponent } from './autopay-add-equipment';
import { McAgentAllocationComponent } from './mc-agent-allocation';
import { AgentMiniDashboardComponent } from './agent-mini-dashboard';
import { InstallerPaymentListComponent } from './installer-payment-list';
import { MCQRCodesFixesComponent } from './mc-qr-code-fixes';
import { FSIALoginComponent } from './fsia-login';
import { JobSummaryComponent } from './job-summary';
import { FLXReopenClaimComponent } from './reopen-claim';
import { FLXExcessDetailsOnClaimComponent } from './excess-details-on-claim';
import { FLXManageSPUsersComponent } from './manage-sp-users';
import { FLXManageSPUsersEditComponent } from './manage-sp-users-edit';
import { SPMiniDashboardComponent } from './sp-mini-dashboard';

export const screenMapper = {
	AgreementOfLossComponent,
	FLXAuthoriserSelectComponent,
	CustomerRatingComponent,
	PolicyLookupComponent,
	AccountLookupComponent,
	PolicyDetailsComponent,
	AccountDetailsComponent,
	AddressConfirmationComponent,
	ClaimDetailsComponent,
	ClaimClassComponent,
	AddJobComponent,
	ClaimHistoryComponent,
	ClaimAppointmentsComponent,
	FLXJobAppointmentComponent,
	FLXJobExcessComponent,
	ClaimPaymentDetailsComponent,
	FileViewWithExtraComponent,
	FLXInternalAssessorJobsSummaryComponent,
	FLXInternalAssessorOverviewComponent,
	BankDetailsComponent,
	BetBankDetailsComponent,
	FLXInternalAssessorJobOverviewComponent,
	FLXKeyValueListComponent,
	FLXNestedObjectKeyValueListComponent,
	FLXFlatDynamicFormComponent,
	FLXFileBrowserComponent,
	CloseClaimComponent,
	CloseClaimGeneralComponent,
	ClaimSummaryComponent,
	// UI Elements Included
	FLXFileUploadWithPreviewComponent,
	FLXLabelComponent,
	FLXSelectListComponent,
	FLXSuccessTickComponent,
	FLXListAndKeysInfoComponent,
	FLXKeyValueDisplayWithInstructionsComponent,
	FLXHeadingWithInstructionsComponent,
	FLXViewPhotosComponent,
	// SetAppointmentComponent,
	FLXSpReplyListComponent,
	FLXDynamicTableComponent,
	FLXContextMenuWhatMattersComponent,
	FLXAlertMessagesComponent,
	CallLogsComponent,
	FLXWarrantyVoidComponent,
	FLXCancelJobComponent,
	FLXCancelClaimComponent,
	NotesComponent,
	IANotesComponent,
	ViewDocumentsComponent,
	AssignIAComponent,
	AssignTLComponent,
	ViewPaymentsComponent,
	FLXSetAppointmentComponent,
	FLXSetExcessComponent,
	FLXPolicyHistoryComponent,
	InternalAssessorSelectOtherJobsComponent,
	FLXQueryHistoryComponent,
	FLXSPDetailsJobContextComponent,
	FLXUploadDocumentsComponent,
	FLXTimeStampsComponent,
	JobSummaryComponent,
	FLXSpEditTLReportComponent,
	FLXReopenClaimComponent,
	FLXExcessDetailsOnJobComponent,
	FLXExcessDetailsOnClaimComponent,
	FLXCommentsComponent,
	FLXManageSPUsersComponent,
	FLXManageSPUsersEditComponent,
	AmpProductSelectionComponent,
	AmpDamageClassComponent,
	FLXFileViewWithSideExtrasComponent,
	DynamicAnchorComponent,
	FLXExcessManagementComponent,
	DeviceDetailsComponent,
	CurrentSetupComponent,
	TeamLeadLookupComponent,
	PingGoFakeModalComponent,
	PinggoRefundBreakdownComponent,
	PggBankDetailsTemplateComponent,
	PinggoVoucherRefundCalculatorComponent,
	CustomerDetailsComponent,
	SpAddJobComponent,
	PinggoSPPaymentBreakdownComponent,
	PinggoCSVPaymentComponent,
	FakeModalComponent,
	IframeComponent,
	FLXSpWithDistancesComponent,
	FLXHeadingWithULComponent,
	FLXAutoPayFixerComponent,
	FLXAutopayAddEquipmentComponent,
	McAgentAllocationComponent,
	AgentMiniDashboardComponent,
	InstallerPaymentListComponent,
	MCQRCodesFixesComponent,
	FSIALoginComponent,
	SPMiniDashboardComponent
};

export const screens: any[] = [
	AgreementOfLossComponent,
	FLXAuthoriserSelectComponent,
	CustomerRatingComponent,
	PolicyLookupComponent,
	AccountLookupComponent,
	PolicyDetailsComponent,
	AccountDetailsComponent,
	AddressConfirmationComponent,
	ClaimDetailsComponent,
	ClaimClassComponent,
	AddJobComponent,
	ClaimAppointmentsComponent,
	FLXJobAppointmentComponent,
	FLXJobExcessComponent,
	ClaimHistoryComponent,
	ClaimPaymentDetailsComponent,
	// SetAppointmentComponent,
	FileViewWithExtraComponent,
	FLXInternalAssessorJobsSummaryComponent,
	FLXInternalAssessorOverviewComponent,
	FLXInternalAssessorJobOverviewComponent,
	BankDetailsComponent,
	BetBankDetailsComponent,
	CloseClaimComponent,
	CloseClaimGeneralComponent,
	FLXSpReplyListComponent,
	ClaimSummaryComponent,
	FLXContextMenuWhatMattersComponent,
	CallLogsComponent,
	FLXWarrantyVoidComponent,
	FLXCancelJobComponent,
	FLXCancelClaimComponent,
	NotesComponent,
	IANotesComponent,
	ViewDocumentsComponent,
	AssignIAComponent,
	AssignTLComponent,
	FLXSpEditTLReportComponent,
	ViewPaymentsComponent,
	FLXPolicyHistoryComponent,
	InternalAssessorSelectOtherJobsComponent,
	FLXQueryHistoryComponent,
	FLXSPDetailsJobContextComponent,
	FLXUploadDocumentsComponent,
	FLXTimeStampsComponent,
	JobSummaryComponent,
	FLXReopenClaimComponent,
	FLXExcessDetailsOnJobComponent,
	FLXExcessDetailsOnClaimComponent,
	FLXCommentsComponent,
	FLXManageSPUsersComponent,
	FLXManageSPUsersEditComponent,
	AmpProductSelectionComponent,
	AmpDamageClassComponent,
	FLXFileViewWithSideExtrasComponent,
	DynamicAnchorComponent,
	FLXExcessManagementComponent,
	DeviceDetailsComponent,
	CurrentSetupComponent,
	TeamLeadLookupComponent,
	PingGoFakeModalComponent,
	PinggoRefundBreakdownComponent,
	PggBankDetailsTemplateComponent,
	PinggoVoucherRefundCalculatorComponent,
	CustomerDetailsComponent,
	SpAddJobComponent,
	PinggoSPPaymentBreakdownComponent,
	PinggoCSVPaymentComponent,
	FakeModalComponent,
	IframeComponent,
	FLXSpWithDistancesComponent,
	FLXAutoPayFixerComponent,
	FLXAutopayAddEquipmentComponent,
	McAgentAllocationComponent,
	AgentMiniDashboardComponent,
	InstallerPaymentListComponent,
	MCQRCodesFixesComponent,
	FSIALoginComponent,
	SPMiniDashboardComponent
];
