import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { FilterConfigs } from '@flexus/models';

export enum TwoFrameDynamicSelectionFrames {
	closed,
	opened,
	valueEntry
}

export interface FrameConfigurations {
	[subFrameName: string]: FilterConfigs;
}

export type TwoFrameDynamicSelectionEvents = FilterSetEvent;
export class FilterSetEvent {
	readonly type = 'data-selection-made';
	constructor() {}
}
export interface CurrentFrameData {
	currentFrame: TwoFrameDynamicSelectionFrames;
	//  the bellow is most certainly not an any but im not sure how to type it at the current
	//  moment
	//  data inside obs, so can |async and still feed
	frameData?: any;
	formGroup?: UntypedFormGroup;
	framePrompt?: string;
	// frame type is used for frame 2, to switch between the types
	//  of selections. frame 1 is always a select list
	frameType?: string;
}

// export enum FilterTypes {
//   numericRange = 'numericRange',
//   dateRange = 'dateRange',
//   select = 'select',
//   input = 'input',
// }

// export interface WorkflowFilterConfig {
//   search: {
//     functionToRun: (store: Store<any>, searchValue: string, closed: boolean) => void;
//     clearFunctionToRun: () => void;
//   };
//   filters: [
//     {
//       [filterName: string]: {
//         type: FilterTypes;
//         configuration: FilterConfigs;
//         paths: Array<string>;
//       };
//     }
//   ];
// }

export type frameData = selectListData;

type selectListData = Observable<any>;

// export type FilterConfigs = InputPromptConfig | NumericRangePromptConfig | SelectPromptConfig;

// export interface MinSetComparator {
//   type: FilterTypes;
//   //  will be 1 for single types and 2 for range types
//   value_selected: Array<string | number>;
//   path;
// }
// // this is the config for the container, which agregates the layers bellow it

// // this is the config for the highlevel template
// export interface InputOrganismConfig {}

// export interface InputPromptConfig {
//   type: FilterTypes.input;
//   prompt: string;
// }

// export interface NumericRangePromptConfig {
//   type: FilterTypes.numericRange;
//   min?: number;
//   max?: number;
//   prompt: string;
// }

// export interface SelectPromptConfig {
//   type: FilterTypes.select;
//   context: { [displayName: string]: any };
// }
