import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BetClaimCardComponent } from './components/bet-claim-card/bet-claim-card.component';
import { BetJobCardComponent } from './components/bet-job-card/bet-job-card.component';
import { BetListJobCardComponent } from './components/bet-list-job-card/bet-list-job-card.component';
import { BetListClaimCardComponent } from './components/bet-list-claim-card/bet-list-claim-card.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { RouterModule } from '@angular/router';
import { FLXDetailsComponent } from './views/details-view/details-view.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FLXListViewComponent } from './views/list-view/list-view.component';
import { BetService, ClaimService, JobService } from './services';
import { UIElementsModule } from '@flexus/ui-elements';
import { ThemeModule } from '@flexus/ux';
import { BetWorkflowShellComponent } from './base-components/bet-workflow-shell/bet-workflow-shell.component';
import { BetFlowComponent } from './base-components/bet-flow/bet-flow.component';
import { environment } from '../../../environments/environment';
import { UICompositesModule } from '@flexus/ui-composites';
import { ErrorHandlerModule } from '@flexus/error-handler';
import { ScreensModule } from '@flexus/screens';
import { ActivateStateGuard } from './guards/activate-state.guard';
import { DeactivateStateGuard } from './guards/deactivate-state.guard';
import { LoadBetManifestService } from './services/load-bet-manifest.service';
import { MsalModule } from '@azure/msal-angular';
import { SearchBannerModule } from '../../app-shell-features';
import { PipesModule } from '@flexus/pipes';
import { PublicClientApplication } from '@azure/msal-browser';
import { DynamicLoaderModule } from '@flexus/engine';
import { IdentityModule } from '@flexus/identity';


export function loadConfig(manifest: LoadBetManifestService) {
	return function () {
		return manifest.load();
	};
}

export function tokenGetter() {
	return localStorage.getItem('flexus.web.jwtToken');
}
@NgModule({
	imports: [
		CommonModule,
		...environment.appShellFeatures,
		DynamicLoaderModule,
		ErrorHandlerModule,
		ScreensModule,
		InlineSVGModule,
		NgxPaginationModule,
		PipesModule,
		RouterModule,
		ThemeModule,
		...environment.plugins['global'],
		UIElementsModule.forRoot({ environment: environment }),
		UICompositesModule,
		environment['azure']
			? MsalModule.forRoot(
					new PublicClientApplication({
						auth: {
							clientId: environment['azure']['clientId'],
							postLogoutRedirectUri: `${environment.base_url}auth/login`
						}
					}),
					null,
					null
			  )
			: CommonModule,
		IdentityModule.forRoot({
			after_login_url: '/workflow',
			after_logout_url: '/auth/login',
			send_reset_link_endpoint: `${environment.api_url}v1/forgot_password/check-email`,
			login_endpoint: `${environment.api_url}v2/auth/login/`,
			no_auth_urls: ['/cons']
		}),
		SearchBannerModule
	],
	declarations: [
		BetClaimCardComponent,
		BetListClaimCardComponent,
		BetJobCardComponent,
		BetListJobCardComponent,
		FLXDetailsComponent,
		FLXListViewComponent,
		BetWorkflowShellComponent,
		BetFlowComponent
	],
	exports: [
		...environment.appShellFeatures,
		BetClaimCardComponent,
		BetJobCardComponent,
		BetListClaimCardComponent,
		BetListJobCardComponent,
		FLXDetailsComponent,
		FLXListViewComponent
	],
	providers: [
		ActivateStateGuard,
		DeactivateStateGuard,
		BetService,
		ClaimService,
		JobService,
		{
			provide: APP_INITIALIZER,
			useFactory: loadConfig,
			deps: [LoadBetManifestService],
			multi: true
		},
		{ provide: LOCALE_ID, useValue: 'en-GB' }
	]
})
export class BetManifestModule {}
