import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import moment from 'moment';
import { Store } from '@ngrx/store';

@Injectable()
export class ClaimService {
	id = Math.random();
	constructor(private http: HttpClient, private router: Router, private store: Store<any>) {}

	addCustomerRating(params): Observable<any> {
		return this.http.post(environment.api_url + 'v1/claim_action/add_customer_rating', params);
	}

	getAllClaims(): Observable<any> {
		return this.http.get<any[]>(
			environment.api_url + 'v1/staff_action/get_summary/'
			// { params: { page: '1' } }
		);
	}

	updateClaim(claim) {
		if (claim?.loan_information && claim?.loan_information.claimtype_id && Number(claim?.loan_information.claimtype_id) !== 28) {
			if (claim?.jobcardrequest && claim?.jobcardrequest[0] && Number(claim?.jobcardrequest[0]?.skill_id) !== 86) {
				const jobCardRequests = [this.addIncidentFeeJobCardRequest(), ...claim?.loan_information.jobcardrequest];
				const claimUpdated = { ...claim, loan_information: { ...claim?.loan_information, jobcardrequest: jobCardRequests }, jobcardrequest: jobCardRequests };
				return this.http.post(`${environment.api_url}v1/claim_action/update_claim/`, claimUpdated);
			}
		}

		return this.http.post(`${environment.api_url}v1/claim_action/update_claim/`, claim);
	}

	getClaimAppointments(params: { claim_id: number }): Observable<any> {
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim_appointments`, {
			claim_id: params.claim_id
		});
	}

	getFullClaim(claim_id: number): Observable<any> {
		// return this.http.get<any[]>(environment.api_url + `v1/claim/${claimId}/`);
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim/`, { claim_id, full: false });
	}

	getFullClaimWithJobs(claim_id: number): Observable<any> {
		// return this.http.get<any[]>(environment.api_url + `v1/claim/${claimId}/`);
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim/`, { claim_id, full: true });
	}

	private addIncidentFeeJobCardRequest() {
		const date = moment(Date.now());
		return {
			skillrequested: 1,
			skill: 'Incident Management Payment',
			skill_id: 86,
			skillcatagory_id: 8,
			skillcatagory: 'all',
			providertype: '4-Sure Claim Management',
			providertype_id: 9,
			requestedappointmentdate: date.format('YYYY-MM-DD'),
			requestedappointmenttime: date.format('HH:mm'),
			appointment_type_id: 6,
			appointment_type: 'At',
			appointmentdatetype: 'Today'
		};
	}

	createClaim(claim) {
		if (claim?.loan_information && claim?.loan_information.claimtype_id && Number(claim?.loan_information.claimtype_id) !== 28) {
			if (claim?.jobcardrequest && claim?.jobcardrequest[0] && Number(claim?.jobcardrequest[0]?.skill_id) !== 86) {
				const jobCardRequests = [this.addIncidentFeeJobCardRequest(), ...claim?.loan_information.jobcardrequest];
				const claimUpdated = { ...claim, loan_information: { ...claim?.loan_information, jobcardrequest: jobCardRequests }, jobcardrequest: jobCardRequests };
				return this.http.post(`${environment.api_url}v1/claim_action/create_claim/`, claimUpdated);
			}
		}

		return this.http.post(`${environment.api_url}v1/claim_action/create_claim/`, claim);
	}

	cancelClaim(data: any) {
		return this.http.post(`${environment.api_url}v1/claim_action/cancel_claim`, data);
	}

	reopenClaim(data: any) {
		return this.http.post(`${environment.api_url}v1/claim_action/reopen_claim`, data);
	}

	getClaimExtra(claimId: number): Observable<any> {
		const body = { claim_id: claimId };
		return this.http.post(environment.api_url + `v1/claim_action/get_summary_extras/`, body).pipe(
			take(1),
			map((extras: any) => extras.payload)
		);
	}

	getCloseClaimInfo(params: any): Observable<any> {
		return this.http.post(environment.api_url + `v1/claim_action/can_finalise_claim/`, params);
	}
	finaliseClaim(params: { claim_id: number }): Observable<any> {
		return this.http.post(environment.api_url + `v1/claim_action/finalise_claim/`, params);
	}

	policyHistoryLookup(data: { claim_id: string }) {
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim_history/`, {
			search: '',
			claim_id: data?.claim_id
		});
	}

	searchClaims(params: { search: string; active: boolean }) {
		return this.http.post<any>(environment.api_url + `v1/claim_action/search_claims/`, { ...params });
	}

	checkCatastrophe(param: { claim_type: number; postal_code: number; loss_date: string }) {
		return this.http.post(environment.api_url + `v1/cat_action/check_catastrophe/`, param);
	}

	getExcessDetailsService(data: { claim_id: number }) {
		return this.http.post<any>(`${environment.api_url}v1/claim_action/get_excess_details/`, data);
	}
}
