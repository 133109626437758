export function dynamicEvaluation(obj, prop, operator, value?): boolean {
	switch (operator) {
		case '>':
			return obj[prop] > value;
		case '<':
			return obj[prop] < value;
		case '>=':
			return obj[prop] >= value;
		case '<=':
			return obj[prop] <= value;
		case '===':
			return obj[prop] === value;
		case '!':
			return !obj[prop];
		case '!==':
			return obj[prop] !== value;
    default:
      return false;
	}
}
