import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'flx-accordion-group',
	templateUrl: './accordion-template.component.html',
	styleUrls: ['./accordion-template.component.scss']
})
export class AccordionGroupComponent {
	/**
	 * If the panel is opened or closed
	 */
	@Input() opened = false;

	/**
	 * Text to display in the group title bar
	 */
	@Input() title: string;

	/**
	 * Emitted when user clicks on group titlebar
	 * @type {EventEmitter<any>}
	 */
	@Output() toggle: EventEmitter<any> = new EventEmitter<any>();

	toggleOpen() {
		this.toggle.emit(!this.opened);
	}
}
