import { Flow_0_0_2, getAllInfo, getFullItemTwo, getSelectedItem, getSubmissionData } from '@flexus/core';
import { filter, map, pluck, skipWhile, switchMap, take } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import gql from 'graphql-tag';
import { forkJoin } from 'rxjs';
import moment from 'moment';
import { environment } from 'apps/studio/src/environments/environment';
import { humaniseDate } from '@flexus/utilities';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { JOB_INFO_NODE } from 'apps/studio/src/app/sp_globals/configs/reusable/JOB_INFO_NODE';

export const BET_SP_86: Flow_0_0_2 = {
	id: '86',
	name: 'quote_approved_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Quote Approved : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Quote Approved';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Wait for SP to set new appointment'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		claimDetailKeyValues: {
			errorMessage: 'Something went wrong claim details',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							const claimdetaillist = {
								'Claim Type ': itemTwo?.claim ? itemTwo?.claim?.type : '',
								'Skill required ': itemTwo?.office_use ? itemTwo?.office_use.skillcatagory : '',
								'Address ': itemTwo?.address ?? '',
								'Appointment Date ': itemTwo?.office_use?.requestedappointmentdate ?? 'No appointment date set',
								'Appointment Time ': itemTwo?.office_use
									? `${itemTwo.office_use?.appointment_type} ${itemTwo.office_use?.requestedappointmenttime}`
									: 'Appointment time not retrieved'
							};
							return [claimdetaillist];
						})
					);
			}
		},
		onsiteKeyValues: {
			errorMessage: "Couldn' retrieve on-site info",
			directCall: (http, store) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							const onsitelist = {
								'On site contact person ': itemTwo?.claim?.loan_information?.onsiteperson ?? 'Not retrieved',
								'Contact number ': itemTwo?.claim?.loan_information?.onsitecontact ?? 'On-site contact number not available',
								'Notes ': itemTwo?.claim?.loan_information?.whatmatters ?? 'What Matters Not Retrieved'
							};
							return [onsitelist];
						})
					);
			}
		},
		customerContactKeyValues: {
			errorMessage: 'Customer contact information could not be retrieved',
			directCall: (http, store, sq, bf) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile(f => !f),
						take(1),
						map(res => res as any)
					),
					store.select(getAllInfo).pipe(
						skipWhile(i => !i),
						take(1),
						map(res => res as any)
					)
				]).pipe(
					map(([itemTwo, info]) => {
						const job_id = itemTwo?.id;
						const job_excess = itemTwo?.excess;
						let excess_collection: string;
						let excess_amount: string;
						let excess_method: string;
						const excess_whoArr: any = info.excess_who;
						const excess_howArr: any = info.excess_how;
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_amount = job_excess[i]?.amount;
							}
						}
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_whoArr.forEach(element => {
									if (element.id === job_excess[i]?.who_collects) {
										excess_collection = element.name;
									}
								});
							}
						}
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_howArr.forEach(element => {
									if (element.id === job_excess[i]?.how_collect) {
										excess_method = element.name;
									}
								});
							}
						}
						const customercontactlist = {
							'Customer ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : 'Customer name not available',
							'Client cell ': itemTwo?.claim?.loan_information?.cellnumber ?? 'Cellular number not on record',
							'Excess Collection ': excess_collection ? `${excess_collection} to collect` : '-',
							'Excess Amount ': excess_amount ? `R${excess_amount}` : '-',
							'Excess payment ': excess_method
						};
						return [customercontactlist];
					})
				);
			}
		},
		whatMattersKeyValues: {
			errorMessage: `'What Matters' data could not be retrieved`,
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							const whatmatters = {
								'Inspection Fee Only': 'inspection fee',
								'Consignment stock used': 'consignment stock',
								'What matters to the customer ': itemTwo?.claim?.loan_information?.whatmatters ?? `'What matters' not provided`
							};
							return [whatmatters];
						})
					);
			}
		},
		keyValueListForCIL1: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1)
					)
					.pipe(
						take(1),
						map(job => {
							const list = {
								'Claim Type': job?.claim ? job?.claim?.type : '',
								'Client Name': job?.claim && job?.claim?.applicant ? job?.claim?.applicant?.first_name + ' ' + job?.claim?.applicant?.surname : '',
								'Contact number': job?.claim && job?.claim?.applicant ? job?.claim?.loan_information.contactnumber : '',
								'Client Alternative no': job?.claim && job?.claim?.loan_information ? job?.claim?.loan_information.cellnumber : '',
								Address: job?.claim ? job?.claim?.address : ''
							};
							return [list];
						})
					);
			}
		},
		keyValueListForCIL2: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getAllInfo).pipe(
						skipWhile(x => !x),
						take(1)
					)
				]).pipe(
					take(1),
					map(([job, allInfo]) => {
						const appointment = job?.appointment[job.appointment.length - 1];
						const list = {
							'Claim Type': job?.claim ? job?.claim?.type : '',
							Skill: job?.office_use ? job?.office_use.skill : '',
							'Client Name': job?.claim && job?.claim?.applicant ? job?.claim?.applicant?.first_name + ' ' + job?.claim?.applicant?.surname : '',
							'Contact number': job?.claim && job?.claim?.applicant ? job?.claim?.loan_information.contactnumber : '',
							'Client Alternative no': job?.claim && job?.claim?.loan_information ? job?.claim?.loan_information.cellnumber : '',
							Address: job?.claim ? job?.claim?.address : '',
							'Original Appointment Date': appointment.range_start ? `${humaniseDate(appointment.range_start)}` : '',
							'Original Appointment Time': appointment.range_start ? `${moment(appointment.range_start)?.format('HH:mm')}` : '',

							'Excess collection': ((): string => {
								let collection = '';
								if (job.excess[0]) {
									allInfo['excess_who']?.forEach(element => {
										if (element.id === job?.excess[0]?.who_collects) {
											collection = element.name;
										}
									});
								}
								return collection;
							})(),
							'Excess amount': job?.excess?.length !== 0 ? `R ${job.excess[0]?.amount}` : 0,
							'Excess Payment': ((): string => {
								let pay = '';
								if (job.excess[0]) {
									allInfo['excess_how']?.forEach(element => {
										if (element.id === job?.excess[0]?.how_collect) {
											pay = element.name;
										}
									});
								}
								return pay;
							})(),
							'Excess State': ((): string => {
								let state = '';
								if (job.excess[0]) {
									allInfo['excess_states']?.forEach(element => {
										if (element.id === job?.excess[0]?.state) {
											state = element.name;
										}
									});
								}
								return state;
							})(),
							'Inspection fee only': job?.claim?.inspection || 'No',
							'Consignment stock used': job?.claim?.consignment || 'No',
							'What mattered to the customer': job?.claim && job?.claim?.loan_information.whatmatters ? job?.claim?.loan_information.whatmatters : ''
						};

						return [list];
					})
				);
			}
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Quote Accepted',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'On-site details',
							data$: 'onsiteKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'What Matters',
							data$: 'whatMattersKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			// component: {
			//   children: [
			//     {
			//       component: 'FLXKeyValueDisplayWithInstructionsComponent',
			//       inputs: {
			//         title: 'Qoute accepted',
			//         instructions: ['Phone client to set a new appointment date and continue the work.'],
			//         keyValueList$: 'keyValueListForQouteAccepted',
			//         title$: 'title'
			//       }
			//     }
			//   ]
			// },
			navs: [
				{ text: 'Change to Cash-in-lieu', nextNode: 'RecommendCIL1' },
				{
					text: 'Continue',
					nextNode: 'SetAppointment'
				}
			]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(null),
						skillcatagory: new UntypedFormControl(null)
					})
				);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skill
										skillcatagory
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(values => {
						bf.bigForm.get('appointmentData').get('skill')?.patchValue(values.skill);
						bf.bigForm.get('appointmentData').get('skillcatagory')?.patchValue(values.skillcatagory);
					});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}

												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{
											'Client Name': `${queryData.first_name} ${queryData.surname}`
										},
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'spService',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				},
				jobLocationData: {
					errorMessage: 'The job location was not returned!',
					serviceVariable: 'spService',
					functionName: 'getJobLocation',
					responseSlice: 'payload'
				},
				teamleaderLocations: {
					errorMessage: 'Team leader locations not returned!',
					directCall: (http, store, sq, bf) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter(x => !!x),
							map((teamleaders: any[]) => {
								return teamleaders;
							})
						);
					}
				}
			},
			component: 'FLXJobAppointmentComponent',
			navs: [
				{ text: 'Change to Cash-in-lieu', nextNode: 'RecommendCIL2' },
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'AssignTeamLeader'
				}
			]
		},
		RecommendCIL2: {
			component: {
				children: [
					{
						component: 'FLXKeyValueDisplayWithInstructionsComponent',
						inputs: {
							title: 'Client has requested to be paid out in cash',
							instructions: ['No work was done. Invoice a call out fee.'],
							keyValueList$: 'keyValueListForCIL1',
							title$: 'title'
						}
					}
				]
			},
			navs: [
				{
					text: 'Request Cash In Lieu',
					nextNode: 'SubmissionSuccess',
					color: 'primary'
				}
			]
		},
		RecommendCIL1: {
			...JOB_INFO_NODE,
			// component: {
			//     children: [
			//         {
			//             component: 'FLXKeyValueDisplayWithInstructionsComponent',
			//             inputs: {
			//                 title: 'Client requests cash-in-lieu after quote approval',
			//                 instructions: ['This will request cash-in-lieu and allow you to charge a call out fee'],
			//                 keyValueList$: 'keyValueListForCIL2',
			//                 title$: 'title'
			//             }
			//         }
			//     ]
			// },
			navs: [
				{
					text: 'Request Cash In Lieu',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						// response: {
						// 	serviceVariable: 'spService',
						// 	functionName: 'recommendCil',
						// 	errorMessage: 'Job could not be moved to cil!!'
						// }
						response: {
							errorMessage: 'Job could not be moved to cil!!',
							directCall: (http, store, sq) => {
								return forkJoin([
									store.select(getSelectedItem).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([id, submit]) => {
										const job_id = id.id;
										const packet = {
											// job_id: job_id,
											effected_jobs: job_id,
											cil_state: 56
										};
										return packet;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/move_to_cil/`, data);
										// return of({});
									})
								);
							}
						}
					}
				}
			]
		},
		AssignTeamLeader: {
			initFormFields: bf => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl(null, [Validators.required]));
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter(x => !!x),
							map((teamleaders: any[]) =>
								teamleaders.map(teamLeader => {
									return {
										display: teamLeader.full_name,
										value: teamLeader.id,
										teamLeader,
										shouldHaveImage: true
									};
								})
							)
						);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXSelectListComponent',
						inputs: {
							heading: 'Select Team Leader',
							subheading: 'Please select a new team leader for the job',
							options$: 'tlListData',
							canFilter: true,
							maxWidth: '40%',
							collapseOnSelect: false,
							selectOptionFunc: instance => instance?.bf?.bigForm?.get('assign_teamleader_id')?.patchValue(instance.selectedOption.teamLeader.id)
						}
					},
					{
						component: 'FLXTeamleadPickerComponent',
						inputs: { joblocation$: 'jobLocationData', teamleadersPositions$: 'teamleaderLocations' }
					}
				]
			},
			navs: [
				{
					text: 'Update Map',
					color: 'secondary'
				},
				{
					text: 'Allocate Job',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							serviceVariable: 'spService',
							functionName: 'changeJobAppointmentFromContextMenu',
							followUpSuccessCalls: {
								response: {
									errorMessage: 'Error updating job',
									directCall: (http, store, sq, bf, controller) => {
										return forkJoin([
											store.select(getFullItemTwo).pipe(
												skipWhile(x => !x),
												take(1)
											)
										]).pipe(
											take(1),
											map(([job, user]: any) => {
												bf.patchValues({
													job_id: job?.id,
													current_state: job?.state,
													new_state: 87
												});
												return {
													job_id: job?.id,
													current_state: job?.state,
													new_state: 87,
													job_information: {
														...job.job_information,
														team_leader: bf.bigForm.get('assign_teamleader_id') ? bf.bigForm.get('assign_teamleader_id')?.value : ''
													}
												};
											}),
											switchMap(dataSub => http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub))
										);
									}
								}
							}
						}
					},
					location: 'right'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		current_state: 'current_state',
		new_state: [
			(state, storeObj, formValue, bf) => {
				return 87;
			},
			'new_state'
		],
		assign_teamleader_id: [
			[
				tlid => {
					if (tlid) {
						let id;
						tlid = tlid && Array.isArray(tlid) ? tlid : [];
						for (const lead of tlid) {
							id = parseInt(lead, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.team_leader'
			]
		]
	}
};
