import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { combineLatest, of, Subscription } from 'rxjs';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'flx-credit-note-line-items',
	templateUrl: './credit-note-line-items.component.html',
	styleUrls: ['./credit-note-line-items.component.scss']
})
export class CreditNoteLineItemsComponent implements OnInit, OnDestroy {
	@Input() actualLineItemsFormArray: FormArray;
	@Input() currentState: number;
	@Input() isNonVatSP: boolean = false;
	availableLineItems: any[] = [];

	private sub: Subscription;
	createLineItemForm: AbstractControl;
	constructor(private formBuilder: UntypedFormBuilder, private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		console.log({ actualLineItemsFormArray: this.actualLineItemsFormArray });

		const availableLineItems = of([...this.actualLineItemsFormArray.value] || []);
		const boq$ = availableLineItems.pipe(
			map(line_items => {
				const actualLineItems = [];

				// Mark if boq items exist so you update rather than create
				if (line_items?.length) {
					actualLineItems.forEach(actualLineItem => {
						const existingItem = this.actualLineItemsFormArray.value.find(it => it?.VoucherNumber === actualLineItem?.VoucherNumber);
						if (!existingItem) {
							this.addLineItem();
						}
					});
				} else {
					const itemFormGroup = this.buildLineItemForm({});
					this.actualLineItemsFormArray.push(itemFormGroup);
				}

				return actualLineItems;
			})
		);

		this.sub = combineLatest([boq$])
			.pipe(
				filter(([availableLineItems]) => availableLineItems !== undefined),
				map(([availableLineItems]) => ({
					availableLineItems
				}))
			)
			.subscribe(({ availableLineItems }) => {
				// TRIGGER FORM CHANGES
				const newVals = this.actualLineItemsFormArray.value.map(i => ({ ...i }));
				this.actualLineItemsFormArray.patchValue(newVals);
				this.cdr.detectChanges();
			});
	}

	// Reusable to create a line item
	private buildLineItemForm(lineItem) {
		return this.formBuilder.group({
			VoucherTypeandStatus: [lineItem?.VoucherTypeandStatus ?? '', [Validators.required]],
			VoucherNumber: [lineItem?.VoucherNumber ?? '', [Validators.required]],
			AmountExclVAT: [lineItem?.AmountExclVAT ?? '', [Validators.required]],
			TotalVAT: [lineItem?.TotalVAT ?? 0, [Validators.required]]
		});
	}

	// Reusable to remove line item
	public removeLineItem(itemIndex) {
		if (this.actualLineItemsFormArray?.controls?.length > 1) {
			this.actualLineItemsFormArray.removeAt(itemIndex);
		}
	}

	// Reusable to add line item
	public addLineItem() {
		const itemFormGroup = this.buildLineItemForm({});
		this.actualLineItemsFormArray.push(itemFormGroup);
	}

	convertControlToFormGroup(control: AbstractControl): UntypedFormGroup {
		return control as UntypedFormGroup;
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}
}
