<form [formGroup]="bf.bigForm" class="invoice-number-date-edit">
	<div class="invoice-number-date-edit__datepicker">
		<flx-label>Date</flx-label>
		<br />
		<input formControlName="invoiceDate" [flxDatepicker]="myDatePicker" id="dateOutput" autocomplete="off" /> <br />
		<flx-datepicker #myDatePicker></flx-datepicker>
	</div>
	<div class="invoice-number-date-edit__invoice-number">
		<flx-label>{{ numberLabel }} Number</flx-label>
		<!-- <flx-label *ngIf="client === 'pinggo'">SP's Own Invoice Number</flx-label> -->
		<flx-input-text type="text" formControlName="invoiceNumber"></flx-input-text>
	</div>
</form>
