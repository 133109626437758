import { Flow_0_0_2, getCurrentUser, getFullItemOne } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import moment from 'moment';

export const BET_ADD_TO_WHAT_MATTERS: Flow_0_0_2 = {
	id: 'AddToWhatMatters',
	itemType: 'flow',
	name: 'Add To What Matters',
	excludeForOnlyStates: [169],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `What matters to  ${itemOne.applicant?.first_name} ${itemOne.applicant?.surname}`;
					} else {
						return 'What matters to claimant';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'WhatMatters',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {
		whatMattersData: {
			serviceVariable: 'betService',
			errorMessage: `No data returned for 'What Matters'`,
			directCall: (http, store) => {
				return forkJoin([
					store.select(getFullItemOne).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getCurrentUser).pipe(
						skipWhile(y => !y),
						take(1)
					)
				]).pipe(
					take(1),
					map(([claim, user]) => {
						return { claim, user };
					})
				);
			}
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		WhatMatters: {
			component: 'FLXContextMenuWhatMattersComponent',
			inputs: {
				containerWidth: '50vw',
				showHeading: false
			},

			initFormFields: (bf, item, instance, storeQuery, store) => {
				let whatmatters, claimdescription, handler;
				bf.bigForm.addControl('whatmatters', new UntypedFormControl('', Validators.required));
				bf.bigForm.addControl('claim_description', new UntypedFormControl('', Validators.required));
				bf.addControl('handler', new UntypedFormControl(''));
				store
					.select(getFullItemOne)
					.pipe(
						skipWhile(data => !data),
						take(1)
					)
					.subscribe(data => {
						whatmatters = data?.loan_information.whatmatters;
						claimdescription = data?.loan_information.claimdescription;
						handler = data?.application_creator.full_name;
						let claimdescript;
						if (claimdescription) {
							const inscribe = claimdescription?.split('\n');
							const [mostrecent] = inscribe?.slice(-1);
							claimdescript = mostrecent;
						}
						bf.bigForm.patchValue({ whatmatters });
						bf.bigForm.patchValue({ claim_description: claimdescript });
						bf.bigForm.patchValue({ handler: handler });
					});
			},
			checkValidityForFields: ['whatmatters'],
			navs: [
				{
					text: 'submit',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateClaim',
							errorMessage: `Claim was not updated with 'What Matters'`
						}
					},
					color: 'primary'
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		whatmatters: 'loan_information.whatmatters',
		claim_description: [
			(cd, storeObj, formValue, bf) => {
				const { whatmatters, handler } = bf.bigForm.value;
				return `Date: ` + moment().format('YYYY/MM/d') + '\nStaff: ' + handler + '\n' + whatmatters + '.';
			},
			'loan_information.claimdescription'
		]
	}
};
