<ng-container>
	<flx-heading align="center" size="small" weight="normal">Service providers applicable for this job</flx-heading>

	<div *ngIf="suitableSPs$ | async as spList" class="sp-list-container">
		<!-- <flx-scroll-container height="400px" color="default" padding="0.5rem"> -->
		<!-- {{ spListData | json }} -->

		<div *ngFor="let listItem of spList" class="list-item">
			<div class="sp-name">{{ listItem.sp_name }}</div>
			<div class="sp-contact-number">{{ listItem.sp_contact }}</div>
			<div [ngClass]="listItem.sp_distance > 10 ? 'red' : 'green'">{{ listItem.sp_distance | number: '1.2-2' }}</div>
		</div>
		<!-- </flx-scroll-container> -->
	</div>
</ng-container>
