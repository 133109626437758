import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BigFormService, Flow_0_0_2, ManifestController, ModalService, SetNextNode, getFullItemTwo } from '@flexus/core';
import { Store } from '@ngrx/store';
import { setActionPanelItems, CollapseActionPanel } from 'apps/studio/src/app/app-shell-features';
import gql from 'graphql-tag';
import { EMPTY, of } from 'rxjs';
import { map,  } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { JOB_INFO_NODE } from '../reusable/JOB_INFO_NODE';
import { convertDateTimeToTimeStamp, getExtension } from '@flexus/utilities';
export const MUL_336: Flow_0_0_2 = {
	id: '336',
	name: 'payment_deferred_sp_query',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	instructions: {
		editRoles: {
			0: 'Respond to validation agent query'
		},
		viewRoles: {
			0: 'SP Responding to query'
		}
	},
	header: {
		title: (store: any, bf: any) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo?.claim) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Payment Deferred Query : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Payment Deferred Query';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'mulSpService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		files: {
			serviceVariable: 'mulSpService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},

	startNode: 'PaymentDeferredQueryInfo',
	nodes: {
		PaymentDeferredQueryInfo: {
			nodeType: 'singleView',
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => EMPTY
			},
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('new_state', new UntypedFormControl(334, []));
			},
			...JOB_INFO_NODE(336)
		},
		UploadMissingFile: {
			checkValidityForFields: ['fileQueue'],
			initFormFields: (bf, item, instance, sq, store) => {
				bf.bigForm.addControl('purpose', new UntypedFormControl('invoice'));
				bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
			},
			component: 'FLXFileUploadWithPreviewComponent',
			inputs: {
				heading: 'Upload Support File',
				multipleUploads: false
			},
			navs: [
				{
					text: 'next',
					nextNode: 'FileSubmissionSuccess',
					optIntoValidation: true,
					serverCalls: {
						response: {
							errorMessage: 'Could not upload support file.',
							directCall: (_http: HttpClient, _store: Store, sq, _bf: BigFormService, _controller: ManifestController<any>, _modal: ModalService) => {
								const fileQueue = _bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0] ? fileQueue[0]?.file.base64 : '',
									job_id: _bf.bigForm.get('itemTwo')?.value?.id,
									purpose: _bf.bigForm.get('purpose')?.value,
									filename: `${_bf.bigForm.get('purpose')?.value}-${_bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								let promise = new Promise((resolve, reject) => {
									const uploadfile = _http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
										observe: 'events',
										reportProgress: true
									});

									uploadfile
										.toPromise()
										.then(result => {
											_controller.dispatch(new SetNextNode('PaymentDeferredQuery'));
										})
										.catch(result => {
											_modal.openModalDirectly(instance => {
												instance.heading = 'File Upload Error';
												instance.type = 'warning';
												instance.message = `${result?.error?.reason}, please try again.`;
												instance.navButtons = [
													{
														text: `Close`,
														linkType: 'close',
														color: 'warn'
													}
												];
											});
										});
								});
								return of(promise);
							}
						}
					}
				}
			]
		},
		PaymentDeferredQuery: {
			checkValidityForFields: ['validation_query_response'],
			showTabs: false,
			hideTabItem: true,
			initFormFields: (_bf: any, item: any, instance: any, storeQuery: any, store: any) => {
				_bf.addControl('validation_query_response', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Add a Comment',
							formControls: {
								0: {
									label: 'Add a reply to the query',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'validation_query_response'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit',
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Could not submit query response',
							serviceVariable: 'mulSpService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		FileSubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			inputs: {
				altNextNode: 'PaymentDeferredQuery'
			}
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		validation_query_response: [
			(_reply, _storeobj, _bf) => {
				const stored_queries = _storeobj['selectedContext']?.fullItemTwo?.job_information?.autopay_validation_queries;
				let validation_query_responses = [];
				const date = new Date();
				const new_queryreply = {
					queryreply: _reply,
					author_id: _storeobj?.identity?.currentUser?.user?.id,
					author_name: _storeobj?.identity?.currentUser?.user?.full_name,
					currentState: _bf.current_state,
					timeStamp: convertDateTimeToTimeStamp(date.toDateString())
				};
				if (!Array.isArray(stored_queries)) {
					validation_query_responses.push(stored_queries, new_queryreply);
				}
				if (Array.isArray(stored_queries)) {
					validation_query_responses.push(...stored_queries, new_queryreply);
				}
				return validation_query_responses;
			},
			'job_information.autopay_validation_queries'
		]
	}
};
