import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetCurrentPage } from '@flexus/ux';

@Component({
	selector: 'app-footer',
	templateUrl: 'app-footer.component.html',
	styleUrls: ['app-footer.component.scss']
})
export class AppFooterComponent {
	@Input() manifestItem;
	@Input() navs;

	constructor(private _store: Store<any>) {}

	setCurrentPage(currentPage: number) {
		this._store.dispatch(new SetCurrentPage(currentPage));
	}
}
