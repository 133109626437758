import { Flow_0_0_2 } from '@flexus/core';
import { FormControl, Validators } from '@angular/forms';
import { voucherInfoNode } from '../reusable/VOUCHER_INFO_NODE';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { photoNode } from '../reusable/PHOTO_NODE';
import { setActionPanelItems } from '../../../../app-shell-features';
import { getInterstateComments } from '../reusable/dataManipulations';
import { requestQANode } from '../reusable/REQUEST_QA_NODE';
import { resolvedProblemNode } from '../reusable/RESOLVED_PROBLEM_NODE';

export const MUL_177: Flow_0_0_2 = {
	id: '177',
	name: 'validation_support_close_claim',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Validation Support',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Validation Support - Close Claim'
		},
		viewRoles: {
			0: 'Validation Support - Close Claim'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		}
	},
	startNode: 'jobInformation',
	nodes: {
		voucherData: {
			...voucherInfoNode(177)
		},
		requestQA: {
			...requestQANode(177, 232)
		},
		jobInformation: {
			showTabs: true,
			...jobInfoNode(177, 183)
		},
		photo: {
			...photoNode(177)
		},
		resolvedProblem: {
			...resolvedProblemNode(177)
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		message: [(message, storeObj, bf) => getInterstateComments(message, storeObj, bf), 'job_information.interstate_comments']
	}
};
