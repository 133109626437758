import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getSelectedItem } from '@flexus/core';
import { setActionPanelItems, CollapseActionPanel } from 'apps/studio/src/app/app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import { of } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

export const MUL_80: Flow_0_0_2 = {
	id: '80',
	name: 'job_cancelled_by_customer',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `No work - Customer cancelled : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'No work - Customer cancelled';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Job cancelled by customer'
		},
		viewRoles: {
			0: 'Job cancelled by customer'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		keyValueListForSummary: {
			errorMessage: '',
			directCall: (http: any, store: any, sq: any) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile((x: any) => !x),
						take(1)
					)
					.pipe(
						take(1),
						map((job: any) => {
							const list = {
								'Claim Type': job?.claim ? job?.claim.type : '',
								'Client Name': job?.claim && job?.claim?.applicant ? job?.claim?.applicant?.first_name + ' ' + job?.claim?.applicant?.surname : '',
								'Contact number': job?.claim && job?.claim?.applicant ? job?.claim?.loan_information.contactnumber : '',
								'Client Alternative no': job?.claim && job?.claim?.loan_information ? job?.claim?.loan_information.cellnumber : '',
								Address: job?.claim ? job?.claim?.address : ''
							};

							return [list];
						})
					);
			}
		}
	},
	startNode: 'CallOutFeeSummary',
	nodes: {
		CallOutFeeSummary: {
			initFormFields: (bf: any) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(null, Validators.required));
			},
			component: {
				children: [
					{
						component: 'FLXKeyValueDisplayWithInstructionsComponent',
						inputs: {
							title: 'Job cancelled by customer',
							instructions: ['No work was done. You can close the job.'],
							keyValueList$: 'keyValueListForSummary',
							title$: 'title'
						}
					}
				]
			},
			navs: [
				{
					text: 'continue',
					color: 'primary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (_inst: any, _store: any, _bf: any) => {
							_inst.type = 'warning';
							_inst.heading = 'Confirm closing of job';
							_inst.setMessage([`Clicking 'CONFIRM' will close this job and remove it from your workflow.`]);
							_inst.navButtons = [
								{
									text: 'back',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'CONFIRM',
									color: 'default',
									linkType: 'submitThenNext',
									serverFirst: true,
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										response: {
											directCall: (_http: any, _store: any) => {
												return _store
													.select(getSelectedItem)
													.pipe(
														skipWhile((response: any) => !response),
														take(1),
														map((res: any) => res)
													)
													.pipe(
														switchMap((selected: any) => {
															const job_id: number = selected?.id;
															const new_claim_state: number = 9;
															const new_state: number = 45;
															const data: any = {
																job_id: job_id,
																new_state: new_state,
																new_claim_state: new_claim_state
															};
															return _http.post(`${environment.api_url}v1/job_action/update_job/`, data);
														})
													);
											}
										}
									}
								}
							];
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
