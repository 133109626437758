import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { getIsAuthenticated } from '../identity';
import { JWT_TOKEN_KEY } from './engine.constants';
import { CloseAppMenu } from '../app-menu';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	authenticated$: Observable<boolean>;

	constructor(private _router: Router, private _store: Store<any>, @Inject(JWT_TOKEN_KEY) private tokenKey: string) {
		this.authenticated$ = _store.select(getIsAuthenticated);
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		let hasToken = true;
		if (Array.isArray(this.tokenKey)) {
			for (let i = 0; i < this.tokenKey.length; i++) {
				if (!localStorage.getItem(this.tokenKey[i]?.tokenName)) {
					hasToken = false;
					break;
				}
			}
		} else {
			hasToken = !!localStorage.getItem(this.tokenKey);
		}

		if (hasToken) {
			this._store.dispatch(new CloseAppMenu());
			return this.authenticated$.pipe(map(bool => bool));
		} else {
			// location.replace(`${location.origin}/auth/login`);
			this._router.navigate(['/auth/login']);
			return of(false);
		}
	}
}
