<div
	class="expanded-select-drop-container"
	ngFileDrop
	ngFileSelect
	[uploadInput]="uploadInput"
	[ngClass]="{ 'is-drop-over': dragOver }"
	(dragenter)="this.dragOver = true"
	(dragleave)="this.dragOver = false"
	(drop)="getFileFromDropEvent($event)"
>
	<p id="expanded-select-text">Drop your file here to upload</p>
	<label class="uploadButton" for="upload-button"> Select File </label>
	<input id="upload-button" type="file" ngFileSelect [uploadInput]="uploadInput" (change)="getFileFromInputSelection($event)" />
</div>

<!-- class="expanded-select-drop-container" -->
