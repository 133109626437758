<flx-heading [align]="'center'" [size]="'large'" color="default" margin="50px 0 30px 0" weight="extra-light">{{ heading }}</flx-heading>
<flx-heading [align]="'center'" [size]="'small'" color="default" margin="0 0 40px 0" weight="extra-light">{{ subtitle }}</flx-heading>
<flx-flex-container [alignItems]="'center'" [direction]="'column'" *ngIf="jobCards && jobCards.length > 0">
	<flx-glow-line [size]="'50vw'" [margin]="'0 0 15px 0'"></flx-glow-line>
	<div *ngFor="let job of jobCards">
		<span>{{ getJobType(job?.supplier_type) }}&nbsp;&nbsp;&nbsp; - </span> <span>{{ getSkillDescription(job?.skill) }}&nbsp;&nbsp;&nbsp; -</span>
		<span>State {{ job?.state }}&nbsp;&nbsp;&nbsp; -</span> <span>{{ getStateDescription(job?.state) }}</span>
	</div>
	<flx-glow-line [size]="'50vw'" margin="15px 0 0 0"></flx-glow-line>
</flx-flex-container>
<!-- <div class="key-value-list-container"><flx-key-value-list [data$]="keyvaldata$"></flx-key-value-list></div> -->
