<div class="container" [formGroup]="bf.bigForm">
	<div class="header-section"><flx-heading [size]="'medium'" [color]="'default'" align="center">Payments Preview</flx-heading></div>
	<div class="file-name-input">
		<div style="margin: 0 auto; width: 30vw">
			<flx-input-text type="text" formControlName="file_name_input" placeholder="Please enter your chosen file name" width="30vw" align="center"></flx-input-text>
		</div>
	</div>
	<ng-container *ngIf="csvPayments$ | async as payments; else noPayments">
		<div class="tabular-csv-data">
			<div class="column-headings">
				<div class="col-head">SP Name</div>
				<div class="col-head">Bank Name</div>
				<div class="col-head">Account Type</div>
				<div class="col-head">Bank Branch</div>
				<div class="col-head">Account Number</div>
				<div class="col-head">Amount</div>
				<div class="col-head">Statement Reference</div>
				<div class="col-head">SP Email Address</div>
				<div class="col-head">SP SMS</div>
				<div class="col-head">Exlude</div>
			</div>
			<div class="csv-line-items">
				<flx-csv-item *ngFor="let payment of payments" [paymentitem]="payment" (exludeItemAction)="handleExcludeItem($event)"></flx-csv-item>
			</div>
		</div>
	</ng-container>
	<ng-template #noPayments><flx-heading margin="45px 0 0 0" size="small" color="default" align="center">No payments to be made right now.</flx-heading></ng-template>
</div>
