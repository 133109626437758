import { Renderer2 } from '@angular/core';
import { Manifest_0_0_2 } from '@flexus/core';
import { ItemTwo_Permission_0_0_2 } from '@flexus/core';
import { jobStateLookupTable } from '../../models/indicator-lookup.model';
import moment from 'moment';
import { findName, humaniseDate } from '@flexus/utilities';

export const jobPermissions: ItemTwo_Permission_0_0_2 = {
	show_default_button: (job: any, claim: any, user: any, renderer: Renderer2, domElement: HTMLElement, activeOrg: Manifest_0_0_2, allInfo, instance) => {
		if (job) {
			const stateFlow = activeOrg?.manifestItems[job.state];
			const instructions = stateFlow?.itemType === 'flow' && stateFlow?.instructions;
			const editStates = user?.user?.edit_states;
			const userCanEdit = editStates?.includes(job.state);
			const userRole = user?.user?.roles[0];
			const instructionText = userCanEdit
				? instructions?.editRoles?.[userRole] || instructions?.editRoles?.[0] || ''
				: instructions?.viewRoles?.[userRole] || instructions?.viewRoles?.[0] || '';
			const instructionHolder = domElement.getElementsByClassName('take-action').item(0);
			const span = renderer.createElement('span');
			// renderer.addClass(but, buttonHtmlClassLookup[job.state]);
			// renderer.setStyle(but, 'margin-right', '-50px');
			const spanText = renderer.createText(instructionText);
			// if (!userCanEdit) {
			//   renderer.setAttribute(span, 'hidden', 'true');
			// }
			renderer.appendChild(span, spanText);
			renderer.appendChild(instructionHolder, span);
		}
	},
	get_indicator_color: (job: any, claim: any, user: any, renderer: Renderer2, domElement: HTMLElement, activeOrg: Manifest_0_0_2, allInfo, instance) => {
		const claimState = claim?.state;
		const jobState = job?.state;
		const editRoles = user?.user?.edit_states;
		let color = 'grey';
		switch (true) {
			case claimState === 109 && jobState === 97:
				break;
			case editRoles?.includes(jobState):
				color = jobStateLookupTable[job.state];
				break;
		}

		instance.indicator.color = color;
	},
	calculate_appointment_time: (job: any, claim: any, user: any, renderer: Renderer2, domElement: HTMLElement, activeOrg: Manifest_0_0_2, allInfo, instance) => {
		if (allInfo) {
			const appointmentInfoEl = domElement.getElementsByClassName('appointment-info').item(0);
			const appointment_type =
				allInfo.appointment_types && job?.appointment && job?.appointment.appointment_type ? findName(job.appointment.appointment_type, allInfo.appointment_types).name : '';
			let text = 'No Appointment';
			if (job.appointment.range_start !== null && appointment_type !== '') {
				text = `${humaniseDate(job.appointment.range_start)} ${appointment_type} ${moment(job.appointment.range_start)?.format('HH:mm')}`;
			} else {
				if (appointmentInfoEl) appointmentInfoEl.setAttribute('style', 'color: var(--input-placeholder);');
			}
			const appointment_text = renderer.createText(text);
			if (appointmentInfoEl) renderer.appendChild(appointmentInfoEl, appointment_text);
		}
	}
};
