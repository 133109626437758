import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { BigFormService } from "@flexus/core";



@Component({
  selector: 'add-user',
  templateUrl: 'add-user.component.html',
  styleUrls: ['add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  roles = {
		claim_handler: 1,
		insurer_assessor: 2,
		insurer_field_agent_sil: 3,
		insurer_field_agent_sp: 4,
		disbursement_officer: 5,
		frontline_manager: 6,
		central_management: 7,
		administrator: 8,
		sp_job_scheduler: 9,
		sp_team_leader: 10,
		payments_and_ratings: 11,
		supply_chain_manager: 12,
		loss_adjuster_tl: 13,
		verification_officer: 14,
		external_claim_handler: 18,
		authoriser: 34,
		dashboard: 35,
		dashboard_super_user: [false]
	};

	constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, public bf: BigFormService) {}

	ngOnInit(): void {
		this.bf.addControl('username', new FormControl(''));
		this.bf.addControl('first_name', new FormControl(''));
		this.bf.addControl('last_name', new FormControl(''));
		this.bf.addControl('password', new FormControl(''));
		this.bf.addControl('contact_number', new FormControl(''));
		this.bf.addControl('email_address', new FormControl(''));
		this.bf.addControl(
			'roles',
			this.fb.group(
				Object.keys(this.roles).reduce(
					(acc, k) => ({
						...acc,
						[k]: [false]
					}),
					{}
				)
			)
		);
	}

	transformRequest(vals: any) {
		const { roles, ...rest } = vals;
		const rs = Object.entries(roles).reduce((acc, [k, v]) => (v === true ? [...acc, this.roles[k]] : acc), []);
		const full_name = `${rest?.first_name} ${rest?.last_name}`;
		return { ...rest, full_name, roles: rs };
	}

	submit() {
		const data = this.transformRequest(this.bf.bigForm.value);
		console.log(data);
		// this.http.post(`${environment.api_url}v1/staff_register/staff_register/`, data)
		// .pipe(take(1))
		// .subscribe((res: {success: boolean; payload: any}) => {
		// 	if (res.success) {
		// 		this.router.navigate(['/workflow']);
		// 	}
		// });
	}
}
