import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, fromEvent, merge, of, combineLatest, forkJoin, NEVER } from 'rxjs';
import { skipWhile, take, map, bufferTime, filter, tap, distinctUntilChanged, pluck, switchMap, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
	ViewSet_0_0_2,
	SetView,
	getActiveViewData,
	getActiveManifestItem,
	getOrgKey,
	SetActiveManifestItem,
	NavService,
	ModalService,
	ManifestController,
	getActiveOrganization,
	getManifestSearchData,
	IndexedDbService,
	BigFormService,
	OfflineService,
	getCurrentUser,
	LogOut,
	getForcePingModalShow,
	PingModalShown,
	getForceJobNotificationModalShow,
	JobNotificationsModalShown,
  getFilterData,
  SearchOnServer,
  getServerSearchResult,
  getFilters,
  getSearchField
} from '@flexus/core';
import { cleanUpSub } from '@flexus/utilities';
import {
	FilterPipe,
	FLXSpPingWindowComponent,
	FLXSpJobNotificationsWindowComponent
} from '@flexus/ui-composites';
import { ToastrService } from 'ngx-toastr';
import { DynamicLoaderComponent } from '@flexus/engine';

@Component({
	selector: 'sp-workflow-shell',
	templateUrl: './sp-workflow-shell.component.html',
	styleUrls: ['./sp-workflow-shell.component.scss']
})
export class SpWorkflowShellComponent implements OnInit, OnDestroy, AfterViewInit {
	workflowManifest: ViewSet_0_0_2;

	checkForUpdateSubscription!: Subscription;
	searchResultSubscription!: Subscription;
	filterSubscription!: Subscription;
	serverSearchResult = [];
	runSub!: Subscription;
	itemsSubscription!: Subscription;
	pingWindowSubscription!: Subscription;
	jobNotificationWindowSubscription!: Subscription;
	searchSubscription!: Subscription;

	opened = false;
	mode: string;
	searching$: Observable<boolean>;
	filterPipe = new FilterPipe();
	views: {
		[id: string]: (
			http: HttpClient,
			controller: ManifestController<any>,
			indexedDbService,
			offlineService
		) => { id: string; dataSource: Function; template: any; storeBinding: any };
	};
	loadViewSubscription: Subscription;
	currentViewData;
	manifestSubscription: Subscription;
	searchData: { inSearch: string; filters: any[] } = { inSearch: '', filters: [] };
	hasSearchValues = false;
	workflowItemsLength: number;
	workflowItems: any[] = [];
	userStaffType = 0;

	@ViewChild(DynamicLoaderComponent, { static: true }) host!: DynamicLoaderComponent;
	@ViewChild(FLXSpPingWindowComponent, { static: true }) ping!: FLXSpPingWindowComponent;
	@ViewChild(FLXSpJobNotificationsWindowComponent, { static: true }) jobNotificationWindow!: FLXSpJobNotificationsWindowComponent;
	getForcePingModalShow$: Subscription;

	constructor(
		private store: Store<any>,
		private controller: ManifestController<any>,
		private http: HttpClient,
		public navService: NavService,
		public modalService: ModalService,
		private indexedDbService: IndexedDbService,
		private bf: BigFormService,
		public offlineService: OfflineService,
		private _toastr: ToastrService
	) {}

	ngOnInit() {
		this.runSub = this.run().subscribe();
	}

	ngAfterViewInit() {
		this.getForcePingModalShow$ = this.store
			.select(getForcePingModalShow)
			.pipe(
				switchMap(res =>
					this.store.select(getCurrentUser).pipe(
						filter(() => {
							return res === true;
						}),
						tap(currentUser => {
							if (!currentUser.user.roles.some((role: number) => [13].includes(role))) {
								this.ping.open();
								this.store.dispatch(new PingModalShown());
							}
						})
					)
				)
			)
			.subscribe();

		this.jobNotificationWindowSubscription = this.store
			.select(getForceJobNotificationModalShow)
			.pipe(
				tap(res => {
					if (res === true) {
						this.jobNotificationWindow.open();
						this.store.dispatch(new JobNotificationsModalShown());
					}
				})
			)
			.subscribe();
	}

	private run() {
		return this.store.select(getCurrentUser).pipe(
			filter(x => !!x),
			take(1),
			pluck('user'),
			switchMap((user: any) => {
				const INSURER_STAFF_TYPE = 1;
				const LOSS_ADJUSTER_TL = 13;
				const usersNotToLogIn = [INSURER_STAFF_TYPE];
				if (usersNotToLogIn.some(n => n === user.staff_type) && !user.roles.includes(LOSS_ADJUSTER_TL)) {
					this._toastr.warning('The login details you entered are not allowed on this platform');
					this.store.dispatch(new LogOut());
					return NEVER;
				} else {
					return this.loadWorkflow().pipe(
						mergeMap(() => this.getManifest()),
						tap(manifest => {
							this.views = manifest.views;

							this.checkForAppUpdate();
							this.checkAndDoSearch();

							// USING SAME DATA FOR ALL VIEWS HERE ELSE EACH VIEW SPECIFIES ITS OWN DATASOURCE
							if (window.navigator.onLine) {
								// Use an activeView if found, otherwise use default
								this.controller
									.select(getActiveViewData)
									.pipe(take(1))
									.subscribe(data => {
										const view = data || { func: this.views['default'], key: 'default', params: { reverse: true } };
										this.controller.dispatch(new SetView(view));
									});
							} else {
								this.controller.dispatch(new SetView({ func: this.views.defaultOffline, key: 'defaultOffline', params: { reverse: true } }));
							}
						}),
						mergeMap(manifest => this.loadViewToShow())
					);
				}
			})
		);
	}

	checkAndDoSearch() {
		this.searchSubscription = combineLatest([
			this.store.select(getFilterData).pipe(take(1), pluck('searchField')),
			this.controller.select(getManifestSearchData).pipe(take(1)),
			this.controller.select(getActiveOrganization).pipe(take(1)),
			this.controller.stateObservable.pipe(take(1)),
			this.store.pipe(take(1))
		])
			.pipe(take(1))
			.subscribe(([search, searchFunc, org, controller, store]: any) => {
				if (!!search && !!search['inSearch']) {
					const _configuration = searchFunc(store, controller);
					const { inSearch, checkClosed } = search;
					this.store.dispatch(
						new SearchOnServer({
							endpoint: _configuration.searchEndpoint,
							active: !checkClosed,
							search: inSearch,
							virtualStatesFunction: org.virtualStatesFunction
						})
					);
				}
			});
	}

	loadViewToShow() {
		return combineLatest([
			this.store.select(getServerSearchResult),
			this.store.select(getFilterData),
			this.store.select(getFilters),
			this.controller.select(getActiveViewData).pipe(
				filter(x => !!x),
				distinctUntilChanged()
			)
		]).pipe(
			map(([serverManualSearchResult, searchData, filters, activeView]: any) => {
				const currentViewData = activeView.func(this.http, this.controller, this.indexedDbService, this.offlineService, this.store);

				if (currentViewData) {
					if ((this.bf.bigForm.contains('inSearch') && this.bf.bigForm.get('search')) || !!searchData.searchField) {
						this.searchData.inSearch =
							(this.bf.bigForm.contains('inSearch') && this.bf.bigForm.get('search') ? this.bf.bigForm.get('search')?.value?.inSearch : '') ||
							(searchData.searchField && searchData.searchField.inSearch ? searchData.searchField.inSearch : '');
					} else {
						this.searchData.inSearch = '';
					}
					this.hasSearchValues = this.hasSearched(filters);
					this.itemsSubscription = currentViewData.storeBinding
						.pipe(
							skipWhile(x => !x)
							// take(1)
						)
						.subscribe(items => {
							if (this.searchData.inSearch) {
								this.workflowItems = serverManualSearchResult;
								this.host.loadWithInputs(currentViewData.template, { list$: of(this.workflowItems) }, null);
							} else if (filters.length !== 0) {
								// if filters has already been applied, filter after search
								const result = this._filterItems(items, filters);
								this.workflowItems = result;
								this.host.loadWithInputs(currentViewData.template, { list$: of(this.workflowItems) }, null);
							} else {
								this.workflowItems = items;
								this.host.loadWithInputs(currentViewData.template, { list$: of(this.workflowItems) }, null);
							}
						});
				}
			})
		);
	}

	private getManifest() {
		return this.controller.select(getActiveManifestItem).pipe(
			skipWhile(item => !item),
			take(1),
			map(manifestItem => {
				this.workflowManifest = manifestItem;
				if (this.workflowManifest && this.workflowManifest.onStateInit) {
					this.workflowManifest.onStateInit(this);
				}
				return manifestItem;
			})
		);
	}

	private loadWorkflow() {
		return this.controller.select(getOrgKey).pipe(
			take(1),
			tap(key => {
				this.controller.dispatch(new SetActiveManifestItem({ pathToFlows: ['manifestItems'], orgKey: key, itemId: 'workflow' }));
			})
		);
	}

	refetchSearchResultsIfTermExists() {
		forkJoin([
			this.controller.select(getActiveOrganization).pipe(take(1)),
			this.store.select(getSearchField).pipe(take(1)),
			this.controller.select(getManifestSearchData).pipe(take(1)),
			this.store.pipe(take(1)),
			this.controller.stateObservable.pipe(
				skipWhile(x => !x),
				take(1)
			)
		])
			.pipe(take(1))
			.subscribe(([activeOrg, searchField, searchFunc, store, controller]: any) => {
				const configuration = searchFunc(store, controller);
				if (searchField && searchField.inSearch) {
					this.store.dispatch(
						new SearchOnServer({
							endpoint: configuration.searchEndpoint,
							active: !searchField.checkClosed,
							search: searchField.inSearch,
							virtualStatesFunction: activeOrg.virtualStatesFunction
						})
					);
				}
			});
	}

	hasSearched(filters) {
		if (this.searchData && (this.searchData.inSearch !== '' || (filters && filters.length > 0))) return true;
		return false;
	}

	private _filterItems(list, filters) {
		if (list && filters) {
			return this.filterPipe.transform(list, filters).sort((a, b) => (a.id < b.id ? 1 : -1));
		}
		return list;
	}

	private _setIndexWorkflowItems() {
		this.currentViewData.storeBinding
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe(currentViewData => {
				this.workflowItems = currentViewData;
				this.workflowItemsLength = this.workflowItems.length;
			});
		this.host.loadWithInputs(this.currentViewData.template, { list$: this.currentViewData.storeBinding }, null);
	}

	loadView() {
		this.loadViewSubscription = this.controller
			.select(getActiveViewData)
			.pipe(
				filter(x => !!x),
				distinctUntilChanged()
			)
			.subscribe(data => {
				if (data) {
					this.currentViewData = data.func(this.http, this.controller, this.indexedDbService, this.offlineService, this.store);
					if (this.serverSearchResult.length > 0) {
						this.host.loadWithInputs(this.currentViewData.template, { list$: of(this.serverSearchResult) }, null);
					} else {
						this._setIndexWorkflowItems();
					}
				}
			});
	}

	getWorkflowViews() {
		this.views = this.workflowManifest.views;
	}

	checkForAppUpdate() {
		this.checkForUpdateSubscription = this.userIdleTime(300000).subscribe(() => {
			document.location.reload();
		});
	}

	userIdleTime(milliseconds: number): Observable<any> {
		const mergedStream = merge(fromEvent(document, 'click'), fromEvent(document, 'keydown'), fromEvent(document, 'mousemove'), fromEvent(document, 'scroll'));
		const idleStream = mergedStream.pipe(
			bufferTime(milliseconds),
			filter(arr => arr.length === 0)
		);
		return idleStream;
	}

	getScrollPosition() {
		// this._store
		//   .select(workflowListSelectors.getScrollPosition)
		//   .pipe(take(1))
		//   .subscribe((position) => {
		//     setTimeout(() => {
		//       this.viewportScroller.scrollToPosition(position);
		//     });
		//   });
	}

	setScrollPosition() {
		// const scrollPosition = this.viewportScroller.getScrollPosition();
		// this._store.dispatch(new workflowListActions.SetWorkflowScrollPosition(scrollPosition));
	}

	private getQuickFilterState() {
		// this._store
		//   .select(workflowListSelectors.getQuickFilter)
		//   .pipe(take(1))
		//   .subscribe(({ filterText, opened }) => {
		//     this.defaultfilterText = filterText;
		//     this.opened = opened;
		//   });
	}

	trackByFunc(idx, claim) {
		return claim?.id;
	}

	ngOnDestroy() {
		this.getForcePingModalShow$ && this.getForcePingModalShow$.unsubscribe();
		this.runSub && this.runSub.unsubscribe();
		this.setScrollPosition();
		if (this.checkForUpdateSubscription) {
			this.checkForUpdateSubscription.unsubscribe();
		}
		cleanUpSub(this.searchResultSubscription);

		if (this.loadViewSubscription) this.loadViewSubscription.unsubscribe();
		if (this.filterSubscription) this.filterSubscription.unsubscribe();

		if (this.workflowManifest && this.workflowManifest.onStateDestroy) {
			this.workflowManifest.onStateDestroy(this);
		}
		if (this.pingWindowSubscription) this.pingWindowSubscription.unsubscribe();
		if (this.jobNotificationWindowSubscription) this.jobNotificationWindowSubscription.unsubscribe();
		if (this.manifestSubscription) this.manifestSubscription.unsubscribe();
		if (this.searchSubscription) this.searchSubscription.unsubscribe();
	}
}
