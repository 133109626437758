import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { map } from 'rxjs/operators';

export const BET_VIEW_PAYMENTS: Flow_0_0_2 = {
	id: 'ViewPayments',
	itemType: 'flow',
	name: 'View Payments',
	excludeForOnlyStates: [169],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Payments on this claim : ${itemOne?.mid} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Payments on this claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ViewPayments',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		ViewPayments: {
			component: 'ViewPaymentsComponent',
			inputs: {
				heading: 'View Payments',
				paymentMadeTo: 'IUA'
			},
			initFormFields: () => {}
		},
		SubmissionSuccess: {
			component: 'FlXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {},
	fetchLevel1And2: true,
	serverCalls: {
		claimAppointments: {
			serviceVariable: 'betService',
			functionName: 'getClaimAppointments',
			// responseSlice: 'payload',
			errorMessage: 'No claim appointments could be found!'
		}
	}
};
