<!-- <ng-container *ngIf="isEdit"> <flx-user-card-edit [user]="selected_user" (returnToUserViewEvent)="setToView()"></flx-user-card-edit> </ng-container> -->
<div class="user-manage-container">
	<ng-container *ngIf="isView">
		<ng-container *ngIf="spUsers as users">
			<ng-container *ngIf="users && users.length > 0">
				<flx-user-card-view *ngFor="let user of users" (click)="setToEdit(user)" [user]="user" (setUserToEditID)="loadUserEdit(user)"></flx-user-card-view>
			</ng-container>
		</ng-container>
	</ng-container>
</div>
<div style="text-align: center; margin-top: 25px" *ngIf="isEdit"><flx-button color="success" (click)="setToView()">BACK</flx-button></div>
