import { Component, Output, EventEmitter, ViewContainerRef } from '@angular/core';

/** Component used as the host for dynamic loading. */
@Component({
	selector: 'flx-dynamic-loader',
	template: ''
})
export class DynamicLoaderComponent {
	/** An event that when trigger emits the component to be loaded. */
	@Output() invoke = new EventEmitter<any>();
	@Output() invokeWithInputs = new EventEmitter<any>();

	constructor(private vcr: ViewContainerRef) {}

	/** Method that triggers the invoke event*/
	load(component) {
		this.invoke.emit(component);
	}

	loadWithInputs(component, inputs, node) {
		this.invokeWithInputs.emit({ component, inputs, node });
	}
}
