import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { KeyValueListData } from '../key-value-display';

interface IHeadingConfig {
	size?: string;
	color?: string;
	weight?: string;
	title?: string;
	margin?: string;
	type?: string;
	showHeading?: boolean;
}

class HeadingConfig implements IHeadingConfig {
	size: string = 'small';
	color: string = 'secondary';
	weight: string = 'normal';
	title: string = '';
	margin: string = '';
	type: string = '';
	showHeading: boolean = true;

	constructor(data?: Partial<IHeadingConfig>) {
		Object.assign(this, data);
	}
}

@Component({
	selector: 'flx-nested-object-key-value-list',
	templateUrl: './nested-object-key-value-list.component.html',
	styleUrls: ['./nested-object-key-value-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXNestedObjectKeyValueListComponent implements OnInit, OnDestroy {
	private _headingConfig: HeadingConfig = new HeadingConfig();
	private _subHeadingConfig: HeadingConfig = new HeadingConfig();
	private _keyValueHeadingConfig: HeadingConfig = new HeadingConfig({ color: 'default' });

	@Input() title: string;
	@Input() objectInfo$: BehaviorSubject<any>;

	@Input()
	get headingConfig(): HeadingConfig {
		return this._headingConfig;
	}
	set headingConfig(value: HeadingConfig) {
		this._headingConfig = new HeadingConfig(value);
	}
	@Input()
	get subHeadingConfig(): HeadingConfig {
		return this._subHeadingConfig;
	}
	set subHeadingConfig(value: HeadingConfig) {
		this._subHeadingConfig = new HeadingConfig(value);
	}
	@Input()
	get keyValueHeadingConfig(): HeadingConfig {
		return this._keyValueHeadingConfig;
	}
	set keyValueHeadingConfig(value: HeadingConfig) {
		this._keyValueHeadingConfig = new HeadingConfig(value);
	}

	public objectInfoSub: Subscription;
	public objects$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
	public fields$: BehaviorSubject<KeyValueListData> = new BehaviorSubject<KeyValueListData>(null);

	objectIsEmpty(obj) {
		return Object.values(obj)?.every(x => x === null || x === '');
	}

	removeEmptyProperties(obj) {
		const tempObj = {};

		for (const [key, value] of Object.entries(obj)) {
			if (value) {
				tempObj[key] = value;
			}
		}

		return tempObj;
	}

	ngOnInit(): void {
		this.objectInfoSub = this.objectInfo$.pipe(skipWhile(x => !x)).subscribe((job: any) => {
			const tempFields = {};
			const tempObjects = [];

			for (const [key, value] of Object.entries(job)) {
				if (value instanceof Object) {
					if (!this.objectIsEmpty(job[key])) {
						const toPush = this.removeEmptyProperties(job[key]);
						tempObjects.push({ key: key, value$: new BehaviorSubject<object>([toPush]) });
					}
				} else {
					if (value) {
						tempFields[key] = value;
					}
				}
			}

			this.fields$.next(tempFields);
			this.objects$.next(tempObjects);
		});
	}

	ngOnDestroy(): void {
		if (this.objectInfoSub) {
			this.objectInfoSub.unsubscribe();
		}
	}
}
