import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, AfterViewInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, ManifestController, ModalService, SetNextNode } from '@flexus/core';
import { ERSingleFileSelectConfig, FileQueue } from '@flexus/ui-elements';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { skipWhile, take, map, switchMap } from 'rxjs/operators';

@Component({
	selector: 'flx-user-card-edit',
	templateUrl: './user-card-edit.component.html',
	styleUrls: ['./user-card-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXUserCardEditComponent implements OnInit, OnDestroy, AfterViewInit {
	private _user: any;
	pswEditForm: UntypedFormGroup;
	password: UntypedFormControl;
	password_verify: UntypedFormControl;
	title = 'Edit User';
	isPasswordEdit: boolean;
	isSubmitting: boolean;
	isUploading: boolean;
	encodedfile: string;

	staffMemberDetail$: Observable<any>;
	memberDataPack$: Observable<any>;
	imgForUpload$: Observable<any>;
	passwordToSubmit$: Observable<any>;
	contactnumber$: Observable<any>;
	membersub: Subscription;
	uploadSub: Subscription;
	pwdsubmitsub: Subscription;

	selectedUser;

	fileUploadConfig: ERSingleFileSelectConfig = {
		// maxFileSize: true,
		allowedFileTypes: ['image']
	};

	@Output() returnToUserViewEvent = new EventEmitter<string>();

	@Input()
	set user(val: any) {
		this._user = val;
	}
	get user() {
		return this._user;
	}

	constructor(
		private _fb: UntypedFormBuilder,
		private _http: HttpClient,
		private _cdr: ChangeDetectorRef,
		public _modal: ModalService,
		private _bf: BigFormService,
		private _controller: ManifestController<any>,
		@Inject('environment') private _environment: any
	) {}

	// get newPwd() {
	// 	return this.pswEditForm.get('password');
	// }
	// get confirmPwd() {
	// 	return this.pswEditForm.get('password_verify');
	// }

	ngOnInit() {
		this._bf.bigForm.addControl('fileQueue', new UntypedFormControl([]));
		this.createFormControls();

		this.selectedUser = this.user;
		const pakket = {
			staff_id: this.selectedUser.id
		};
		this.staffMemberDetail$ = this._http.post(`${this._environment.api_url}v1/sp_action/manage_get_staff_details/`, pakket);

		this.memberDataPack$ = forkJoin([
			this.staffMemberDetail$.pipe(
				skipWhile(x => !x),
				take(1),
				map(res => res['payload'])
			),
			of(this.user).pipe(
				skipWhile(x => !x),
				take(1),
				map(res => res as any)
			)
		]).pipe(
			switchMap(([detail, member]) => {
				return (this.contactnumber$ = of(detail.contact_number));
			})
		);
		this.membersub = this.memberDataPack$.subscribe();
		this._cdr.detectChanges();
		this.createForm();
	}
	ngAfterViewInit() {
		this._cdr.detectChanges();
	}

	setViewToEditPassword() {
		this.isPasswordEdit = true;
	}

	submitNewPassword() {
		const password_to_submit_v = this.pswEditForm?.value?.password_verify;
		const password_to_submit = this.pswEditForm?.value?.password;
		const data = of({
			staff_id: this.selectedUser.id,
			new_password: password_to_submit
		});
		if (password_to_submit !== password_to_submit_v) {
			this._modal.openModalDirectly(instance => {
				(instance.type = 'warning'),
					(instance.heading = 'Password mismatch!'),
					(instance.message = ['The passwords you entered do not match - Carefully enter both again.']),
					(instance.navButtons = [
						{
							text: 'OK',
							linkType: 'close'
						}
					]);
			});
			return;
		} else {
			this.passwordToSubmit$ = data?.pipe(
				map(user => user),
				switchMap(pakket => {
					let passwordpromise = new Promise((resolve, reject) => {
						const passwordupload = this._http.post(`${this._environment.api_url}v1/sp_action/manage_reset_password/`, pakket);
						passwordupload
							.toPromise()
							.then(result => {
								this._controller.dispatch(new SetNextNode('PasswordSubmissionSuccess'));
							})
							.catch(error => {
								console.log('password error', error);
								this._modal.openModalDirectly(instance => {
									instance.heading = error.statusText ? `${error.statusText}` : `Password wasn't updated!`;
									instance.type = 'warning';
									instance.message = `${error?.error?.reason}. Please try again or contact your system administrator.`;
									instance.navButtons = [
										{
											text: `Close`,
											linkType: 'close',
											color: 'danger'
										}
									];
								});
							});
					});
					return of(passwordpromise);
				})
			);
			this.pwdsubmitsub = this.passwordToSubmit$.subscribe();
		}
	}
	createFormControls() {
		this.password = new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]);
		this.password_verify = new UntypedFormControl('', [Validators.required, Validators.minLength(8)]);
	}

	createForm() {
		this.pswEditForm = this._fb.group({
			password: this.password,
			password_verify: this.password_verify
		});
	}

	returnToUsersView() {
		this.returnToUserViewEvent.next('view');
	}
	getImageForUpload(eventData) {
		this.encodedfile = eventData;

		const data = of({
			file: this.encodedfile,
			staff_id: this.selectedUser.id
		});

		this.imgForUpload$ = data.pipe(
			map(userandfile => {
				return userandfile;
			}),
			switchMap(pakket => {
				const purpose = 'DIGITAL_ID';
				const body: object = {
					// light: 'dark'
					staff_id: pakket.staff_id,
					data: pakket.file,
					purpose: purpose,
					filename: `${purpose} - ${this.selectedUser.id}`
				};
				let promise = new Promise((resolve, reject) => {
					const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data;charset=utf-8');
					const uploadImage = this._http.post(`${this._environment.api_url}v1/sp_action/manage_update_staff_image/`, body, {
						headers,
						responseType: 'text'
					});
					uploadImage
						.toPromise()
						.then(result => {
							this._controller.dispatch(new SetNextNode('ImageUploadSuccess'));
							console.log('image was successfully uploaded');
						})
						.catch(error => {
							this._modal.openModalDirectly(instance => {
								instance.heading = 'Image Upload Error';
								instance.type = 'warning';
								instance.message = `${error?.error?.reason}, please try again.`;
								instance.navButtons = [
									{
										text: `Close`,
										linkType: 'close',
										color: 'warn'
									}
								];
							});
						});
				});
				this._cdr.detectChanges();
				return of(promise);
			})
		);
		// this.showIsUploading();
		this.uploadSub = this.imgForUpload$.subscribe(data => {
			console.log('dtat', data);
			this._cdr.detectChanges();
		});
		this._cdr.detectChanges();
	}

	updateFileQueue(file: FileQueue) {
		const queue = this._bf.bigForm.get('fileQueue')?.value as Array<FileQueue>;
		queue.push(file);
		this._bf.bigForm.get('fileQueue')?.setValue(queue);
		this._cdr.detectChanges();
	}

	get fileQueue(): Array<FileQueue> {
		return this._bf.bigForm.get('fileQueue')?.value as Array<FileQueue>;
	}

	ngOnDestroy() {
		if (this.membersub) {
			this.membersub.unsubscribe();
		}
		if (this.uploadSub) {
			this.uploadSub.unsubscribe();
		}
		if (this.pwdsubmitsub) {
			this.pwdsubmitsub.unsubscribe();
		}
	}
}
