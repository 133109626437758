import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'flx-note-list',
	templateUrl: './note-list.component.html',
	styleUrls: ['./note-list.component.scss']
})
export class FLXNoteListComponent implements OnInit {
	@Input() notes: any;
	@Input() title: string;
	@Input() maxHeight = 'auto';
	@Input() currentUser: string;
	@Input() location: string;

	locationClass = 'note-list-container';

	ngOnInit() {
		switch (this.location) {
			case 'action-panel':
				this.locationClass = 'compact';
				break;
			case 'context-menu':
				this.locationClass = 'scattered';
				break;

			default:
				break;
		}
	}
}
