import { Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { take, map, skipWhile, pluck } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { BET_210 } from './BET_210';

export const BET_213: Flow_0_0_2 = {
	id: '213',
	name: 'review_ia_repudiate',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map(itemOne => {
					if (itemOne) {
						return `Review IA Repudiate : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Review IA Repudiate';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp']),
	serverCalls: {
		...BET_210.serverCalls,
		assessorSummary: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1),
						pluck('job_information')
					)
					.pipe(
						map((ji: any) => {
							if (ji) {
								const retMessage = {
									important_information: {
										assessors_decision: '',
										reason: ji.diagnosis
									}
								};

								if (ji?.interstate_comments) {
									let message = '';
									if (Array.isArray(ji?.interstate_comments)) {
										message = ji?.interstate_comments?.[ji.interstate_comments?.length - 1]?.message?.toLowerCase();
									} else {
										message = ji?.interstate_comments?.message?.toLowerCase();
									}
									switch (true) {
										case message?.includes('approve'):
											retMessage.important_information.assessors_decision = 'Approval';
											break;
										case message.includes('recommendrejection'):
											retMessage.important_information.assessors_decision = 'Recommend Rejection';
											break;
										case message.includes('recommendcil'):
											retMessage.important_information.assessors_decision = 'Recommend CIL';
											break;
									}
									return retMessage;
								}
							}
						})
					);
			}
		}
	},
	instructions: {
		editRoles: {
			0: 'Review IA Repudiate'
		},
		viewRoles: {
			0: 'Review IA Repudiate'
		}
	},
	startNode: 'ReviewAssessorDecision',
	nodes: { ...BET_210.nodes },
	bigFormToStoreMapper: { ...BET_210.bigFormToStoreMapper }
};
