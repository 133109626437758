<div class="edit-container">
	<div class="user-view-edit">
		<div class="user-id" (click)="returnToUsersView()">
			<div
				[ngClass]="{
					'user-photo': selectedUser.hasPhoto === true,
					'monogram-only': selectedUser.hasPhoto === false
				}"
			>
				<span *ngIf="!selectedUser.hasPhoto" class="monogram-only">{{ selectedUser.monogram }}</span>
				<img *ngIf="selectedUser.hasPhoto" class="user-photo" src="{{ 'data:image/png;base64,' + selectedUser.image }}" />
			</div>
		</div>
		<div class="button-holder">
			<div class="reset"><flx-button (clicked)="setViewToEditPassword()" [disabled]="isSubmitting">RESET PASSWORD</flx-button></div>
			<flx-compact-file-select [config]="fileUploadConfig" (outputB64)="getImageForUpload($event)"></flx-compact-file-select>
		</div>
		<div class="user-detail">
			<div class="user-name">
				<flx-heading size="large" weight="bold" margin="0 0 0 0 " align="center">{{ selectedUser.full_name }}</flx-heading>
			</div>
			<div class="user-phone">
				<flx-heading size="medium" weight="regular" margin="0 0 35px 0 " align="center">{{ contactnumber$ | async }}</flx-heading>
			</div>
		</div>
		<div *ngIf="isPasswordEdit" class="password-edit-inputs">
			<div [formGroup]="pswEditForm">
				<div class="password-edit">
					<flx-label class="small" color="default-color">Enter new password</flx-label>
					<flx-input-text type="password" size="normal" formControlName="password" [required]="true"></flx-input-text>
					<div class="form-feedback" *ngIf="password.errors && (password.dirty || password.touched)">
						<span *ngIf="password.errors.minlength">Password must be at least 8 characters</span><br />
						<span *ngIf="password.errors.required">Password is required</span>
						<span *ngIf="password.errors.pattern">Password must contain a mix of upper and lowercase letters, numbers and at least one special character </span>
					</div>

					<flx-label class="small" color="default-color">Re-enter new password</flx-label>
					<flx-input-text type="password" size="normal" formControlName="password_verify" [required]="true" margin="0 0 20px 0"></flx-input-text>
					<div class="form-feedback" *ngIf="password_verify.errors && (password_verify.dirty || password_verify.touched)">
						<span *ngIf="password_verify.errors.minlength">Minimum length 8 characters</span> <span *ngIf="password_verify.errors.required">Password re-entry is required</span>
					</div>
					<div class="reset-password-button">
						<div class="button_">
							<flx-button color="primary" align="center" (clicked)="submitNewPassword()" [disabled]="!pswEditForm.valid">RESET PASSWORD</flx-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
