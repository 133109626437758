import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordComponent, ResetPasswordComponent, LoginComponent, AzureCallbackComponent } from './components';
import { FLXAuthShellComponent } from './auth-shell.component';
import { NoAuthGuard } from '@flexus/core';

const identityRoutes: Routes = [
	{
		path: 'azure',
		children: [
			{
				path: 'callback',
				component: AzureCallbackComponent,
				canActivate: [NoAuthGuard]
			}
		]
	},
	{
		path: 'auth',
		component: FLXAuthShellComponent,
		children: [
			{
				path: 'login',
				component: LoginComponent,
				canActivate: [NoAuthGuard]
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
				canActivate: [NoAuthGuard]
			},
			{
				path: 'reset-password/:token',
				component: ResetPasswordComponent,
				canActivate: [NoAuthGuard]
			}
		]
	}
];
@NgModule({
	imports: [RouterModule.forChild(identityRoutes)],
	exports: [RouterModule]
})
export class AuthRoutingModule {}
