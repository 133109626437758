import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CSVPaymentItemOrganism } from './csv-payment-item.component';
import { UIElementsModule } from '@flexus/ui-elements';

@NgModule({
	declarations: [CSVPaymentItemOrganism],
	imports: [CommonModule, UIElementsModule],
	exports: [CSVPaymentItemOrganism]
})
export class CSVPaymentItemModule {}
