import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'flx-excess-details-on-job',
	templateUrl: './excess-details-on-job.component.html',
	styleUrls: ['./excess-details-on-job.component.scss']
})
export class FLXExcessDetailsOnJobComponent implements OnInit {
	@Input() excess_on_job$: Observable<any>;
	@Input() location = 'action-panel';

	headingOptions = {
		size: 'small',
		alignment: 'left'
	};

	gridOptions = {
		th: 'th-ap',
		tr: 'tr-ap',
		row: 'row-app'
	};

	constructor() {}

	ngOnInit() {
		if (this.location === 'action-panel') {
			this.headingOptions = {
				size: 'small',
				alignment: 'left'
			};
			this.gridOptions = {
				th: 'th-ap',
				tr: 'tr-ap',
				row: 'row-ap'
			};
		} else {
			this.headingOptions = {
				size: 'medium',
				alignment: 'center'
			};
			this.gridOptions = {
				th: 'th',
				tr: 'tr',
				row: 'row'
			};
		}
	}
}
