import { claimPermissions } from './configs/permissions/claim.permissions';
import { jobPermissions } from './configs/permissions/job.permissions';
import { ClaimWorkflow } from './configs/workflow/claim-workflow';
import { AmpCreateClaim } from './configs/create-claim/amp-create-claim';
import * as States from './configs/flows';
import { Manifest_0_0_2, getCurrentUser } from '@flexus/core';
import { AmpSearch } from './configs/search/amp-search';
import { applyAmpVirtualStates } from './amp-virtual-state.helper';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { FLXAppMenuComponent } from '../../app-shell-features/app-menu/app-menu.component';
import { AmpEditClaim } from './configs/create-claim/amp-edit-claim';

export const amp: Manifest_0_0_2 = {
	version: '0.0.2',
	orgKey: 'amp',
	settings: {
		addActionPanel: true,
		addAppBar: true,
		addAppMenu: true,
		addFooter: true,
		addHeaderActions: true,
		addSearchBanner: true,
		addReminder: true
	},
	appMenu: (instance: FLXAppMenuComponent, store: Store<any>) => {
		return store.select(getCurrentUser).pipe(
			filter(user => !!user),
			map(_ => [
				{ id: 1, name: 'Workflow', routerLink: '/workflow', iconType: 'app-menu-workflow' },
				{ id: 2, name: 'New Incident', routerLink: '/workflow/detail', iconType: 'app-menu-new-claim' }
			])
		);
	},
	virtualStatesFunction: applyAmpVirtualStates,
	appSearch: AmpSearch,
	permissions: {
		itemOne: claimPermissions,
		itemTwo: jobPermissions
	},
	manifestItems: {
		workflow: ClaimWorkflow,
		create_item_one: AmpCreateClaim,
		[8]: AmpEditClaim,
		[169]: States.AMP_169
		// [States.AMP_17.id]: States.AMP_17,
		// [States.AMP_19.id]: States.AMP_19,
		// [States.AMP_20.id]: States.AMP_20,
		// [States.AMP_27.id]: States.AMP_27,
		// [States.AMP_48.id]: States.AMP_48,
		// [States.AMP_50.id]: States.AMP_50,
		// [States.AMP_51.id]: States.AMP_51,
		// [States.AMP_55.id]: States.AMP_55,
		// [States.AMP_56.id]: States.AMP_56,
		// [States.AMP_58.id]: States.AMP_58,
		// [States.AMP_63.id]: States.AMP_63,
		// [States.AMP_64.id]: States.AMP_64,
		// [States.AMP_68.id]: States.AMP_68,
		// [States.AMP_69.id]: States.AMP_69,
		// [States.AMP_70.id]: States.AMP_70,
		// [States.AMP_71.id]: States.AMP_71,
		// [States.AMP_72.id]: States.AMP_72,
		// [States.AMP_73.id]: States.AMP_73,
		// [States.AMP_78.id]: States.AMP_78,
		// [States.AMP_81.id]: States.AMP_81,
		// [States.AMP_83.id]: States.AMP_83,
		// [States.AMP_84.id]: States.AMP_84,
		// [States.AMP_86.id]: States.AMP_86,
		// [States.AMP_87.id]: States.AMP_87,
		// [States.AMP_88.id]: States.AMP_88,
		// [States.AMP_89.id]: States.AMP_89,
		// [States.AMP_90.id]: States.AMP_90,
		// [States.AMP_91.id]: States.AMP_91,
		// [States.AMP_92.id]: States.AMP_92,
		// [States.AMP_93.id]: States.AMP_93,
		// [States.AMP_96.id]: States.AMP_96,
		// [States.AMP_97.id]: States.AMP_97,
		// [States.AMP_100.id]: States.AMP_100,
		// [States.AMP_103.id]: States.AMP_103,
		// [States.AMP_104.id]: States.AMP_104,
		// [States.AMP_105.id]: States.AMP_105,
		// [States.AMP_106.id]: States.AMP_106,
		// [States.AMP_107.id]: States.AMP_107,
		// [States.AMP_108.id]: States.AMP_108,
		// [States.AMP_109.id]: States.AMP_109,
		// [States.AMP_154.id]: States.AMP_154,
		// [States.AMP_156.id]: States.AMP_156,
		// [States.AMP_161.id]: States.AMP_161,
		// [States.AMP_162.id]: States.AMP_162,
		// [States.AMP_169.id]: States.AMP_169
		// [States.AMP_170.id]: States.AMP_170
	}
};
