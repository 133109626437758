import { Flow_0_0_2, getFullItemOne, GetFullItemTwo, getFullItemTwo, getSubmissionData } from '@flexus/core';
import { AVSResultFlag } from '@flexus/screens';
import { BUI_BANK_DETAILS } from './BANK_DETAILS';
import { getAllInfoIndex, CustomValidators } from '@flexus/utilities';
import { SINGLE_FILE_UPLOAD_SINGLE_PURPOSE } from './single_file_upload_single_purpose';
import { take, switchMap, map, skipWhile, filter, first } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { UntypedFormControl } from '@angular/forms';
import { of } from 'rxjs';

export const BUI_56: Flow_0_0_2 = {
	id: '56',
	name: 'awaiting_invoice_cil',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Cash in Lieu : ${itemOne.loan_information.mavenclaimnumber ? itemOne?.loan_information.mavenclaimnumber : itemOne?.loan_information.mavenclaimnumber} - ${
							itemOne.applicant?.surname
						}`;
					} else {
						return 'Cash in Lieu';
					}
				})
			);
		},
		controls: () => () => []
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Cash in Lieu - Upload Invoice'
		},
		viewRoles: {
			0: 'Cash in Lieu - Upload Invoice'
		}
	},
	serverCalls: {
		// Clear out key value list from previous flow
		keyValueList: {
			errorMessage: '',
			directCall: () => {
				return of([]);
			}
		}
	},
	startNode: 'ViewCurrentInvoice',
	nodes: {
		ViewCurrentInvoice: {
			component: 'FileViewWithExtraComponent',
			errorHandler: { displayFormat: 'none' },
			initFormFields: (bf, item, inst, sq, store) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(56));
				store
					.select(getFullItemTwo)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(fullJob => {
						let avsRes = 0;
						if (fullJob.office_use !== null) {
							//check for avs result
							if (fullJob.office_use['avsresult'] !== undefined && fullJob.office_use['avsresult'] !== null) {
								avsRes = Number(fullJob.office_use['avsresult']);
							}
						}
						// bf.bigForm.addControl('currAvsResult', new FormControl(`${avsRes}`, CustomValidators.contains(['1', '-2']))); // if bank details are needed before AOL
						bf.bigForm.addControl('currAvsResult', new UntypedFormControl(`${avsRes}`));
					});
			},
			serverCalls: {
				dataFiles: {
					errorMessage: 'No invoice found!',
					ignoreFalseError: true,
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							filter(x => !!x),
							first(itemTwo => itemTwo !== null || itemTwo !== undefined),
							switchMap(itemTwo => {
								return http
									.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
										job_id: itemTwo?.id,
										return_type: 1
									})
									.pipe(
										map(value => {
											bf.bigForm.addControl('invoice', new UntypedFormControl(true, CustomValidators.contains([true])));
											return value.payload;
										})
									);
							})
						);
					}
				},
				currBankDetails: {
					errorMessage: 'Could not pull bank details!',
					ignoreFalseError: true,
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							filter(x => !!x),
							skipWhile(y => y === null || y === undefined),
							switchMap(itemTwo => {
								if (itemTwo.office_use !== null && itemTwo?.office_use !== undefined) {
									const data = itemTwo?.office_use;
									if (data['avsresult'] !== undefined) {
										const res = {
											initials: data['initials'],
											account_holder_name: data['surname'],
											account_number: data['bank_acc_number'],
											identification_number: data['idnumber'],
											branch_code: data['branch_code'] === undefined || data['branch_code'] === null ? '' : data?.branch_code,
											bank_name: data['bank'] === undefined || data['bank'] === null ? '' : data?.bank,
											branch: data['branch'] === undefined || data['branch'] === null ? '' : data?.branch,
											acc_type: data['acc_type'],
											account_use: data['isnonpanel'] === true ? 'Company' : 'Individual'
										};

										bf.bigForm.patchValue({
											currAvsResult: `${data['avsresult']}`
										});

										return of(res);
									}
								}
								return of(null);
							})
						);
					}
				}
			},
			inputs: {
				fileDoesNotExistMessage: 'No invoice found. Please upload one.',
				inputHeading: 'Gather Cash in Lieu Details',
				subHeading: 'Confirm CIL invoice and bank details to continue',
				instructions: ['Upload and check invoice', 'Confirm customer bank details for AOL generation']
			},
			// checkValidityForFields: ['currAvsResult', 'invoice'], // Uncomment this if bank details are required before AOL generation
			checkValidityForFields: ['invoice'],
			navs: [
				{ text: 'Upload Invoice', nextNode: 'UploadDocument' },
				{ text: 'View / Edit Bank Details', nextNode: 'BankDetails' },
				{
					text: 'Continue',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					// portalData: {
					// 	type: 'modal',
					// 	paramFunc: instance => {
					// 		instance.type = 'warning';
					// 		instance.setMessage(['No valid bank details have been added.', '', 'Are you sure you want to continue?']);
					// 		instance.navButtons = [
					// 			{
					// 				text: 'Cancel',
					// 				color: 'alert',
					// 				linkType: 'close'
					// 			},
					// 			{
					// 				text: 'Continue',
					// 				color: 'primary',
					// 				linkType: 'submitThenNext',
					// 				serverCalls: {
					// 					response: {
					// 						errorMessage: 'Job could not be updated!!',
					// 						directCall: (http, store, sq, bf, controller) => {
					// 							return store.select(getSubmissionData).pipe(
					// 								take(1),
					// 								switchMap((data: any) => {
					// 									// bf.bigForm.get('new_state')?.setValue(107);
					// 									let { new_state, ...rest } = data;
					// 									new_state = 107;
					// 									return http.post(`${environment.api_url}v1/job_action/update_job/`, { ...rest, new_state });
					// 								})
					// 							);
					// 						}
					// 					}
					// 				}
					// 			}
					// 		];
					// 	}
					// },
					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq, bf, controller) => {
								return store.select(getSubmissionData).pipe(
									take(1),
									switchMap((data: any) => {
										// bf.bigForm.get('new_state')?.setValue(107);
										let { new_state, ...rest } = data;
										new_state = 107;
										return http.post(`${environment.api_url}v1/job_action/update_job/`, { ...rest, new_state });
									})
								);
							}
						}
					}
				}
			]
		},
		...SINGLE_FILE_UPLOAD_SINGLE_PURPOSE?.nodes,
		BankDetails: {
			...BUI_BANK_DETAILS,
			inputs: {
				...BUI_BANK_DETAILS.inputs,
				modalNavSuccess: {
					linkType: 'submitThenNext',
					serverCalls: {
						saveBankDetails: {
							serviceVariable: 'buiService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!',
							followUpSuccessCalls: {
								getJob: {
									errorMessage: 'Could not get job!',
									ignoreFalseError: true,
									directCall: (http, store, sq, bf) => {
										store.dispatch(new GetFullItemTwo({ id: bf.bigForm.get('itemTwo.id')?.value }));
										return of(true);
									}
								}
							}
						}
					},
					nextNode: 'ViewCurrentInvoice',
					text: 'Continue',
					color: 'primary'
				}
			},
			navs: [
				{
					text: 'Avs Check',
					serverFirst: true,
					serverCalls: {
						avsCheck: {
							errorMessage: 'Could not run AVS Check!',
							ignoreFalseError: true,
							directCall: (http, store, sq, bf) => {
								const avsData = bf.bigForm.get('avs_data')?.value;
								if (avsData && !!avsData.branch_code) {
									const avsLookup = {
										bank_acc_number: avsData.account_number,
										idnumber: avsData.identification_number,
										acc_type: avsData.acc_type,
										branch_code: avsData.branch_code,
										isnonpanel: avsData.account_use === 'Individual' ? false : true,
										initials: avsData.initials,
										surname: avsData.account_holder_name
									};
									return http.post(`${environment.api_url}v1/claim_action/check_account_details/`, avsLookup);
								} else {
									return of({
										success: false,
										reason: 'Please use the Bank and Branch Name fields to search and select a branch before performing an AVS check.'
									});
								}
							}
						}
					}
				}
			]
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemTwo.id': 'job_id',
		'itemTwo.state': 'current_state',

		//bank details
		'bank_details.initials': 'office_use.initials',
		'bank_details.account_holder_name': 'office_use.surname',
		'bank_details.account_number': 'office_use.bank_acc_number',
		'bank_details.identification_number': 'office_use.idnumber',
		'bank_details.branch_code': 'office_use.branch_code',
		'bank_details.bank_name': 'office_use.bank',
		'bank_details.branch': 'office_use.branch',
		'bank_details.acc_type': 'office_use.acc_type',
		acc_type_desc: [
			(desc, storeObj, formValue) => {
				if (formValue.bank_details !== undefined) {
					return getAllInfoIndex('account_type', 'mid', 'description', formValue.bank_details.acc_type, storeObj);
				} else {
					return null;
				}
			},
			'office_use.acc_type_desc'
		],
		'bank_details.account_use': [
			val => {
				return val === 'Individual' ? false : true;
			},
			'office_use.isnonpanel'
		],
		'bank_details.avsSuccess': 'office_use.avsresult',
		'bank_details.avsFailedReasons': [
			(val, storeObj, formVals) => {
				if (formVals.avsSuccess === AVSResultFlag.fail_force_payment) return val;
				else return null;
			},
			'office_use.avsinitialsfailreason'
		]
	}
};
