import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIElementsModule } from '@flexus/ui-elements';
import { FLXUserCardEditComponent } from './user-card-edit.component';

@NgModule({
	imports: [CommonModule, UIElementsModule],
	declarations: [FLXUserCardEditComponent],
	exports: [FLXUserCardEditComponent]
})
export class FLXUserCardEditModule {}
