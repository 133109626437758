import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { filterStore, FilterStoreEffects } from '@flexus/core';
import { EffectsModule } from '@ngrx/effects';


@NgModule({
	declarations: [],
	imports: [StoreModule.forFeature('filter', filterStore), EffectsModule.forFeature([FilterStoreEffects])],
	exports: [],
	providers: []
})
export class FilterStoreModule {}
