import { Flow_0_0_2, getFullItemOne, getSelectedItemOne, getSelectedItemTwo } from '@flexus/core';
import { map, take, skipWhile, switchMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { environment } from '../../environments/sil.environment.hmr';

export const SIL_93: Flow_0_0_2 = {
	id: '93',
	name: 'assessor_recommends_cil',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					if (itemOne) {
						let title = 'Recommended for Cash-in-Lieu Payout';
						if (itemOne?.loan_information?.mavenclaimnumber) {
							title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
						}
						if (itemOne.applicant?.surname) {
							title += ` - ${itemOne.applicant?.surname}`;
						}

						return title;
					} else {
						return 'Recommended for Cash-in-Lieu Payout';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Assessor recommended CIL'
		},
		viewRoles: {
			0: 'Assessor recommended CIL'
		}
	},
	events: {},
	serverCalls: {
		data: {
			serviceVariable: 'silService',
			functionName: '',
			errorMessage: 'No claim data returned',
			directCall: (http, store) => {
				return store.select(getFullItemOne).pipe(
					skipWhile((kvldata: any) => {
						return kvldata === null || kvldata === undefined;
					}),
					map(kvldata => {
						let claimInfo;
						const {
							applicant: { first_name = '', surname = '', id = '' },
							loan_information: { propertystreetaddress = '', propertysuburb = '', claimtype = '', cellnumber = '', contactnumber = '', email = '' }
						} = kvldata;
						claimInfo = {
							'claim type': `${claimtype}`,
							address: `${propertystreetaddress} ${propertysuburb}`,
							name: `${first_name} ${surname}`,
							'contact number': `${contactnumber}`,
							'cell number': `${cellnumber}`,
							email: `${email}`
						};
						return [claimInfo];
					})
				);
			}
		},
		files: {
			serviceVariable: 'silService',
			// functionName: 'getAllJobFiles',
			errorMessage: 'No reports returned',
			directCall: (http, store) => {
				return store.select(getSelectedItemTwo).pipe(
					skipWhile(item => !item),
					take(1),
					switchMap(itemOne => {
						return http
							.post<any>(environment.api_url + 'v1/file_action/get_files', {
								job_id: itemOne?.id,
								purpose: 'SP Report'
								// latest: 'true',
							})
							.pipe(
								map(data => {
									const files = data?.payload;
									return files;
								})
							);
					})
				);
			}
		}
	},
	startNode: 'AssessorRecommendsCIL',
	nodes: {
		AssessorRecommendsCIL: {
			name: 'Customer Info',
			component: 'FLXKeyValueListComponent',
			inputs: {
				heading: 'Assessor has changed the job to a cash-in-lieu payout',
				strapline: `Click 'Send for Payment' to advance job to payment.`
			},
			initFormFields: (bf, item, instance, storeQuery) => {
				bf.addControl('new_state', new UntypedFormControl('56'));
			},
			showTabs: true,
			navs: [
				{
					text: 'Send for Payment',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Job not updated',
							serviceVariable: 'silService',
							functionName: 'updateJob'
						}
					},
					color: 'primary'
				}
			]
		},
		ViewReports: {
			name: 'View Reports',
			component: 'FLXFileBrowserComponent',
			showTabs: true,
			inputs: {
				showFilters: false,
				paginationId: 'reports'
			}
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
