export interface StateLookupTable {
	[key: string]: string;
}
// from claim card
export const blue = 'blue';
export const green = 'green';
export const orange = 'orange';
export const purple = 'purple';
export const pink = 'pink';
export const red = 'red';
export const yellow = 'yellow';
export const grey = 'grey';

export const claimStateLookupTable: StateLookupTable = {
	1: grey,
	2: grey,
	3: grey,
	5: grey,
	8: blue,
	13: grey,
	12: blue,
	14: blue,
	15: grey,
	16: yellow,
	17: yellow,
	18: blue,
	19: red,
	21: green,
	44: grey,
	55: blue,
	63: yellow,
	64: blue,
	71: red,
	73: red,
	74: red,
	75: blue,
	79: blue,
	95: blue,
	100: grey,
	102: grey,
	172: purple
};

export const jobStateLookupTable: StateLookupTable = {
	9: grey,
	20: orange,
	21: green,
	22: grey,
	23: grey,
	24: grey,
	25: blue,
	26: blue,
	27: orange,
	28: grey,
	32: grey,
	33: grey,
	34: blue,
	35: grey,
	36: grey,
	37: grey,
	39: orange,
	40: orange,
	41: blue,
	42: red,
	45: grey,
	46: blue,
	47: blue,
	48: orange,
	50: green,
	51: green,
	52: orange,
	53: grey,
	54: orange,
	56: orange,
	57: orange,
	58: orange,
	59: orange,
	60: orange,
	62: blue,
	65: blue,
	66: blue,
	67: blue,
	68: grey,
	69: blue,
	70: orange,
	72: orange,
	76: orange,
	77: grey,
	80: blue,
	81: orange,
	82: blue,
	83: grey,
	85: blue,
	86: green,
	87: grey,
	88: blue,
	89: blue,
	90: grey,
	91: blue,
	92: blue,
	93: orange,
	94: blue,
	96: blue,
	97: blue,
	98: grey,
	99: grey,
	101: grey,
	104: blue,
	105: blue,
	106: grey,
	107: orange,
	108: orange,
	154: grey,
	156: blue,
	161: blue,
	162: blue,
	169: purple,
	170: blue,
	171: orange
};
