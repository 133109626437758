import { Flow_0_0_2 } from '@flexus/core';

export const BUI_57: Flow_0_0_2 = {
	id: '57',
	name: 'off_panel_load_invoice',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Upload Off Panel invoice'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: '',
	nodes: {}
};
