import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { getAllInfo, getAvailableJobs, getSelectedJob, RespondToJob, RespondToJobs, SelectAvailableJob } from '@flexus/core';
import { FLXModalComponent, ModalNavButton } from '@flexus/ui-elements';
import { Store } from '@ngrx/store';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { skipWhile, filter, map, take, switchMap } from 'rxjs/operators';
import moment from 'moment';
import { JOB_INTEREST } from '@flexus/utilities';
import { sort } from 'fast-sort';

export enum AlertState {
	IgnoreAllConfirm = 'IgnoreAllConfirm',
	ShowDetailMap = 'ShowDetailMap'
}

@Component({
	selector: 'flx-sp-ping-window',
	templateUrl: './sp-ping-window.component.html',
	styleUrls: ['./sp-ping-window.component.scss']
})
export class FLXSpPingWindowComponent implements OnInit, OnDestroy {
	// ====================================================== VARIABLES ======================================================
	alertStates = AlertState;
	currAlertState!: AlertState;

	modalButtons!: ModalNavButton[];
	ignoreModalButtons!: ModalNavButton[];

	receivedValue: any;

	availableJobs$!: Observable<any>;
	availableJobsSub!: Subscription;
	selectedJobSub!: Subscription;
	listview!: boolean;
	jobSelected!: boolean;
	expandedPingViewDetail$!: Observable<any>;
	@Input() sortOptions: any[] = [];
	sortOrder: 'asc' | 'desc' = 'desc';
	displayConfig = { displayKey: 'display', valueKey: 'value' };

	paginationVariables: any = {
		pageSize: 5,
		currentPage: 0
	};

	error: { triggered: boolean; message: string } = { triggered: false, message: '' };

	// ====================================================== INPUT / OUTPUTS ======================================================
	@Output() ignore = new EventEmitter<boolean>();

	@ViewChild(FLXModalComponent) modal: FLXModalComponent;
	public selectedJob!: any;
	public selectedJobId: any;
	filterSub!: Subscription;
	pingDetailSub!: Subscription;
	sortByFuncs: { [key: string]: (j: any) => any };
	currentSortByFunc: (j: any) => any;
	// @ViewChild(FLXModalComponent, { static: false }) ignoremodal: FLXModalComponent;
	// ====================================================== LIFE CYCLE METHODS ======================================================

	constructor(
    private _store: Store<any>,
    @Inject('environment') private environment: any,
     private _http: HttpClient
     ) {}

	ngOnInit() {
		this.sortOptions = [
			{ display: 'Skill', value: 'skill' },
			// { display: 'Pinged Time', value: 'pinged-time' },
			{ display: 'Appointment Time', value: 'appointment-time' },
			{ display: 'Suburb', value: 'suburb' }
		];

		this.sortByFuncs = {
			skill: j => j.skill,
			'appointment-time': j => j.appointment?.range_start,
			suburb: j => j.suburb
		};
		this.currentSortByFunc = this.sortByFuncs['appointment-time'];

		this.modalSetup();
		this.getAvailableJobs();
		this.sortAvailableJobs('appointment-time');

		this.selectedJobSub = this._store
			.select(getSelectedJob)
			.pipe(skipWhile(x => !x))
			.subscribe(res => {
				this.selectedJob = res as any;
				this.selectedJobId = this.selectedJob?.id;
			});

		this.jobSelected = false;
		this.listview = true;
	}

	// ====================================================== METHODS ======================================================
	open() {
		this.modal.navButtons = [
			{
				text: 'Close',
				linkType: 'close'
			}
		];
		// this.modal.heading = 'Indicate your availability for the following jobs';
		this.modal.open();
	}

	getAvailableJobs() {
		this.availableJobs$ = this._store.select(getAvailableJobs)?.pipe(
			filter(x => !!x && Array.isArray(x))
			// map((data: any[]) => {
			// 	const clone = data.slice();

			// 	const sortedByAppointmentRangeStart = clone.sort((a: any, b: any) => {
			// 		const dateA = moment(a?.appointment?.range_start);
			// 		const dateB = moment(b?.appointment?.range_start);
			// 		return dateA.diff(dateB);
			// 	});

			// 	return sortedByAppointmentRangeStart.reverse();
			// })
		);
	}

	page(event: any) {
		this.paginationVariables.currentPage = event;
	}

	modalSetup() {
		this.modalButtons = [
			{
				text: 'Close',
				linkType: 'close'
			}
		];
	}

	// ignoreAllJobs() {
	//   	this.availableJobsSub = this.availableJobs.subscribe((res: any) => {
	// 		res?.forEach((job: any) => {
	// 			this.respondToJob(job);
	// 		});
	// 	});
	// }
	ignoreAllJobs() {
		const time_of_response = moment().valueOf();
		this.availableJobsSub = this.availableJobs$
			.pipe(
				filter((jobs: any) => jobs.length > 0),
				take(1)
			)
			.subscribe((jobs: any) => {
				this.respondToJobs(jobs.map((job: any) => ({ job_id: job?.id, job: job, interest: JOB_INTEREST.IGNORED, time_of_response })));
			});
	}

	gleanJobID(id) {
		return id;
	}

	respondToJob(value: any) {
		console.log('value : ', value);
		setTimeout(() => {
			this._store.dispatch(new RespondToJob({ job_id: value.job.id, job: value.job, interested: value.interest, time_of_response: value.time_of_response }));
		}, 800);
	}

	respondToJobs(jobs: any) {
		// this.availableJobs$ = of([]);

		this._store.dispatch(new RespondToJobs(jobs));
	}
	getInterest($event) {
		this.receivedValue = $event;
	}

	showDetailView() {
		// this.availableJobs = null;
		this.getExpandedPingViewDetail();
		this.jobSelected = true;
		this.listview = false;
		this.modal.navButtons = [
			{
				text: 'Back',
				clickHandler: () => this.showListView()
			},
			{
				text: 'Ignore Job',
				color: 'alert',
				clickHandler: () => this.respondToJobHandlerDetailView(JOB_INTEREST.IGNORED, this.selectedJobId)
			},
			{
				text: 'Decline Job',
				color: 'alert',
				clickHandler: () => this.respondToJobHandlerDetailView(JOB_INTEREST.DECLINED, this.selectedJobId)
			},
			{
				text: 'Available for Job',
				color: 'primary',
				clickHandler: () => this.respondToJobHandlerDetailView(JOB_INTEREST.INTERESTED, this.selectedJobId)
			}
		];
	}

	showListView() {
		this.jobSelected = false;
		this.listview = true;
		this.modal.navButtons = [
			{
				text: 'Close',
				linkType: 'close'
			},
			{
				text: 'Ignore All',
				color: 'alert',
				confirmAction: {
					text: 'Are you sure you want to ignore all jobs?',
					type: 'alert',
					confirm: {
						text: 'Yes, Ignore Jobs',
						color: 'primary',
						clickHandler: () => this.ignoreAllJobs()
					}
				}
			}
		];
	}

	respondToJobHandler(interest: number) {
		switch (interest) {
			case JOB_INTEREST.DECLINED:
				this.modal.openModalDirectly(instance => {
					instance.type = 'warning';
					instance.heading = 'Decline Job Request';
					instance.message = 'Are you sure you want to decline this?';
					instance.navButtons = [
						{
							text: 'Cancel',
							linkType: 'close',
							color: 'default'
						},
						{
							text: 'Yes',
							clickHandler: () => {
								this.respondToJobHandlerDetailView(JOB_INTEREST.DECLINED, this.selectedJobId);
							},
							linkType: 'close'
						}
					];
				});
				break;
			case JOB_INTEREST.IGNORED:
				this.modal.openModalDirectly(instance => {
					instance.type = 'warning';
					instance.heading = 'Ignore Job Request';
					instance.message = 'Are you sure you want to ignore this?';
					instance.navButtons = [
						{
							text: 'Cancel',
							linkType: 'close',
							color: 'default'
						},
						{
							text: 'Yes',
							clickHandler: () => {
								this._store.dispatch(new RespondToJob({ job_id: this.selectedJobId, job: this.selectedJob, interested: JOB_INTEREST.IGNORED }));
							},
							linkType: 'close'
						}
					];
				});
				break;
			case JOB_INTEREST.INTERESTED: {
				this._store.dispatch(new RespondToJob({ job_id: this.selectedJobId, job: this.selectedJob, interested: JOB_INTEREST.INTERESTED }));
			}
		}
	}

	respondToJobHandlerDetailView(interest: number, job_id: any) {
		this._store.dispatch(new RespondToJob({ job_id: this.selectedJobId, job: this.selectedJob, interested: interest }));
		this.showListView();
	}

	selectAvailableJob(id: string) {
		this._store.dispatch(new SelectAvailableJob(id));
	}
	getExpandedPingViewDetail() {
		this.expandedPingViewDetail$ = forkJoin([
			this._http.post(`${this.environment.api_url}v1/job_action/get_ping_details/`, { job_id: this.selectedJobId })?.pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((rspns: any) => rspns)
			),
			this._store.select(getAllInfo)?.pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((rspns: any) => rspns)
			)
		])?.pipe(
			switchMap(([jobdetails, info]: any) => {
				const claimtypes = info?.claim_types;
				const jobclaimtype = jobdetails?.payload?.claim_type;
				const claimType = claimtypes.find(el => el.id === jobclaimtype)?.name;
				const detailsObj = {
					...jobdetails,
					claim_type_name: claimType
				};
				return of(detailsObj);
			})
		);

		this.pingDetailSub = this.expandedPingViewDetail$.subscribe();
	}

	ngOnDestroy(): void {
		if (this.availableJobsSub) {
			this.availableJobsSub.unsubscribe();
		}
		if (this.selectedJobSub) {
			this.selectedJobSub.unsubscribe();
		}

		this.listview = true;
		this.jobSelected = false;
	}

	sortAvailableJobs(selection: string) {
		console.log('FILTER SELECT', selection);
		this.currentSortByFunc = this.sortByFuncs[selection];
		this.availableJobs$ = this.availableJobs$.pipe(map((jobs: any[]) => sort(jobs)[this.sortOrder](this.currentSortByFunc)));
	}

	toggleJobsOrder() {
		if (this.sortOrder === 'desc') {
			this.sortOrder = 'asc';
		} else {
			this.sortOrder = 'desc';
		}
		console.log('reversing jobs: ', this.sortOrder);
		this.availableJobs$ = this.availableJobs$.pipe(map((jobs: any[]) => sort(jobs)[this.sortOrder](j => this.currentSortByFunc)));
	}
}
