import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ManifestController, SetNextNode, BigFormService } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { skipWhile, take, map, } from 'rxjs/operators';

@Component({
	selector: 'flx-manage-sp-users',
	templateUrl: './manage-sp-users.component.html',
	styleUrls: ['./manage-sp-users.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXManageSPUsersComponent implements OnInit, OnDestroy {
	@Input() spUsers$: Observable<any>; // keep this for future reference
	isEdit: boolean;
	isView: boolean;
	isPasswordEdit: boolean;
	usersub: Subscription;
	detailSub: Subscription;
	loadingSub: Subscription;
	selected_user: number;
	spUsers;
	isLoading$: Observable<any>;
	isLoading: boolean;

	spuserslist: Array<any> = [];
	spstaffimage: any;
	hasPhoto: boolean;

	constructor(
		public _store: Store<any>,
		private _controller: ManifestController<any>,
		private _http: HttpClient,
		@Inject('environment') private _environment: any,
		private _bf: BigFormService
	) {}

	ngOnInit() {
		this._bf.addControl('userToEdit', new UntypedFormControl(''));
		// this.isLoading$ = this._store.select(getLoadingState);

		// this.loadingSub = this.isLoading$.subscribe();

		// this.isEdit = false;
		this.isView = true;
		// this.isPasswordEdit = false;

		this.spUsers$
			.pipe(
				skipWhile(result => !result),
				take(1),
				map(data => data)
			)
			.subscribe(users => {
				const leadarr = [];
				const detailsArray = [];
				this.spuserslist = users[0];
				console.log('sp users list', this.spuserslist);
				for (let i = 0; i <= this.spuserslist.length - 1; i++) {
					if (this.spuserslist[i]?.image !== null) {
						this.hasPhoto = true;
					} else {
						this.hasPhoto = false;
					}
					const pakket = {
						id: this.spuserslist[i]?.id,
						full_name: this.spuserslist[i]?.full_name,
						monogram: this.compileMonogram(this.spuserslist[i]?.full_name),
						hasPhoto: this.hasPhoto,
						image: this.spuserslist[i]?.image
					};
					leadarr.push(pakket);
				}
				this.spUsers = leadarr;
			});
	}

	loadUserEdit(user: any) {
		this.selected_user = user;
		this._bf.patchValues({ userToEdit: user });
	}

	setToView() {
		this.isEdit = false;
		this.isView = true;
	}
	setToEdit(user: any) {
		this._controller.dispatch(new SetNextNode('EditUser'));
		this.selected_user = user;
		console.log('edit user selected', this.selected_user);
		this._bf.patchValues({ userToEdit: user });
		this.isEdit = true;
		this.isView = false;
	}

	compileMonogram(s1: string) {
		const namestring = s1?.split(' ');
		let mono1, mono2;
		if (!namestring[0]) {
			mono1 = ' ';
		} else {
			mono1 = namestring[0]?.slice(0, 1);
		}
		if (!namestring[1]) {
			mono2 = ' ';
		} else {
			mono2 = namestring[1]?.slice(0, 1).toUpperCase();
		}
		const monogram = mono1 + mono2;
		return monogram;
	}
	ngOnDestroy() {
		if (this.detailSub) {
			this.detailSub.unsubscribe();
		}
		if (this.usersub) {
			this.usersub.unsubscribe();
		}
	}
}
