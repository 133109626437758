import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BigFormService, MakeServerCall, SetNextNode, ManifestController, getLoadingIDsState, getLoadedIDsState } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
	selector: 'flx-account-lookup',
	templateUrl: './account-lookup.component.html',
	styleUrls: ['./account-lookup.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: []
})
export class AccountLookupComponent implements OnInit, OnDestroy {
	@Input() lookupName: string = 'Policy';
	@Input() searchFormControlName: string = 'policy_lookup';
	@Input() currentLookupformControlName: string = 'currentPolicyDetails';
	@Input() title: string;
	@Input() lookupEndpoint: any;
	@Input() nextNode: string = 'PolicyDetails';
	@Input() lookupDetails$: Observable<any>;
	@Input() isValidLookup: Function;
	@Input() getLookupValidStatus: Function;
	@Input() lookupEvent: Function;
	@Input() lookupSelectedEvent: Function;
	@Input() lookupItemColumns: { lookupDetailsProperty: string }[] = [];

	selectedLookup: number;
	isLoading$: Observable<boolean>;
	isLoaded$: Observable<boolean>;
	currentLookupDetails;
	hasDoneLookup = false;
	private _isLoading = false;

	// =================Constructor =======================
	constructor(public _store: Store<any>, public bf: BigFormService, private controller: ManifestController<any>) {}

	lookup() {
		if (window.navigator.onLine && !this._isLoading) {
			this._store.dispatch(
				new MakeServerCall({
					...this.lookupEndpoint,
					data: { search: this.bf.bigForm.value[this.searchFormControlName] },
					loaderID: this.searchFormControlName
				})
			);
			if (this.lookupEvent && typeof this.lookupEvent === 'function') {
				this.lookupEvent();
			}
			this.hasDoneLookup = true;
		}
	}
	// ----------------- Life-cycle methods -------------

	ngOnInit() {
		this.isLoading$ = this._store.select(getLoadingIDsState).pipe(map(isLoadingIDs => (this._isLoading = isLoadingIDs.includes(this.searchFormControlName))));
		this.isLoaded$ = this._store.select(getLoadedIDsState).pipe(map(isLoadedIDs => isLoadedIDs.includes(this.searchFormControlName)));
		if (!this.bf.bigForm.get(this.currentLookupformControlName)) this.bf.bigForm.addControl(this.currentLookupformControlName, new UntypedFormControl(null));
		this.bf.bigForm.get(this.currentLookupformControlName)?.addValidators(Validators.required);
	}

	selectLookup(chosenLookupIndex: number) {
		this.lookupDetails$.pipe(take(1)).subscribe(detailsArray => {
			this.currentLookupDetails = detailsArray[chosenLookupIndex];
			this.bf.bigForm.get(this.currentLookupformControlName)?.setValue(this.currentLookupDetails);

			if (this.lookupSelectedEvent && typeof this.lookupSelectedEvent === 'function') {
				this.lookupSelectedEvent(this.currentLookupDetails, this.bf, this._store);
			}
		});

		this.controller.dispatch(new SetNextNode(this.nextNode));
	}

	ngOnDestroy() {}
}
