<flx-modal type="ping" [navButtons]="modalButtons" [backgroundClose]="false" [messageOnly]="false">
	<div class="modal-container">
		<ng-container class="job-detail-container" *ngIf="jobSelected">
			<div class="detail-and-map-container">
				<div class="detail-and-map-content">
					<flx-sp-available-job-detail [expandedPingDetails$]="expandedPingViewDetail$" (bubbleJobId)="gleanJobID(selectedJobId)"></flx-sp-available-job-detail>
				</div>
			</div>
		</ng-container>
		<div *ngIf="listview">
			<flx-heading align="center" color="primary" margin="-40px 0 40px 0">Indicate your availability for the following jobs</flx-heading>
			<div class="filter-container">
				<div class="spacer"></div>
				<div class="filter-select">
					<!-- <div style="text-align: right; margin: -20px auto 20px auto"> -->
					<flx-dropdown
						[displayOptions]="displayConfig"
						[itemsOption]="sortOptions"
						[placeHolder]="'Appointment Time'"
						[searchEnabled]="false"
						placeHolder="Sort"
						(itemsSelected)="sortAvailableJobs($event)"
					></flx-dropdown>
					<!-- </div> -->
				</div>
				<div class="reverse-icon"><flx-icon hover="primary" class="ui-icon" [type]="'toggle-arrows'" (click)="toggleJobsOrder()"></flx-icon></div>
			</div>
			<ng-container
				*ngIf="
					availableJobs$
						| async
						| paginate
							: {
									itemsPerPage: paginationVariables.pageSize,
									currentPage: paginationVariables.currentPage,
									id: 'sp_ping_window'
							  } as jobs
				"
			>
				<ng-container *ngIf="(jobs | fsIsArray) && jobs.length > 0">
					<ng-container *ngFor="let job of jobs">
						<flx-sp-available-job-ping [job]="job" (setShowDetailView)="showDetailView()" (interestShown)="respondToJob($event)"></flx-sp-available-job-ping>
					</ng-container>
					<pagination-controls
						id="sp_ping_window"
						*ngIf="(availableJobs$ | async).length > paginationVariables.pageSize"
						class="flx-pagination"
						(pageChange)="page($event)"
						[autoHide]="false"
						previousLabel="←"
						nextLabel="→"
					>
					</pagination-controls>
				</ng-container>
				<ng-container *ngIf="!jobs || jobs.length === 0"> <p>No jobs available currently.</p> </ng-container>
			</ng-container>
		</div>

		<!-- Alert Container -->
		<div class="alert-container" *ngIf="currAlertState === alertStates.IgnoreAllConfirm">
			<div class="alert-content">
				<p>Are you sure you want to ignore all jobs?</p>
				<div>
					<flx-button color="primary" type="button" (click)="ignoreAllJobs()">Yes</flx-button>
					<flx-button color="primary" type="button" (click)="(currAlertState === null)">No</flx-button>
				</div>
			</div>
		</div>
	</div>
</flx-modal>
