import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { NewJobPayload } from '../../models/new-job.model';

@Injectable()
export class JobService {
	constructor(private http: HttpClient) {}

	newJob(data: NewJobPayload) {
		return this.http.post(`${environment.api_urls['amp']}v1/job_action/new_job/`, data);
	}

	updateJob(data: any) {
		return this.http.post(`${environment.api_urls['amp']}v1/job_action/update_job/`, data);
	}

	cancelJob(data: any) {
		return this.http.post(`${environment.api_urls['amp']}v1/job_action/cancel_job/`, data);
	}

	getClaimAppointments(params: { job_id: number }): Observable<any> {
		return this.http.post(`${environment.api_urls['amp']}v1/job_action/get_claim_appointments`, {
			job_id: params.job_id
		});
	}

	changeAppointment(params: {
		appointment: { appointment_type: number; range_start: string; reason: string };
		job_id: number;
		job_information: { return_to_state: number };
	}): Observable<any> {
		return this.updateJob(params);
	}

	getAllJobs(): Observable<any> {
		return this.http.get(environment.api_urls['amp'] + 'v1/job/');
	}

	getFullJob(job_id: number): Observable<any> {
		return this.http.post(`${environment.api_urls['amp']}v1/job_action/get_job`, { job_id });
	}

	getJobInvoice(job_id: number) {
		return this.http.post<any>(environment.api_urls['amp'] + `v1/file_action/get_invoice/`, {
			job_id,
			return_type: 1
		});
	}
}
