import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { UIElementsModule } from '@flexus/ui-elements';
import { ThemeModule } from '@flexus/ux';
import { DynamicLoaderModule } from '@flexus/engine';
import { environment } from '../../environments/environment';
import { UICompositesModule } from '@flexus/ui-composites';
import { ErrorHandlerModule } from '@flexus/error-handler';
import { ScreensModule } from '@flexus/screens';
import { ActivateStateGuard } from './guards/activate-state.guard';
import { DeactivateStateGuard } from './guards/deactivate-state.guard';
import { PipesModule } from '@flexus/pipes';
import { BillingService } from '@flexus/core';
import { SearchBannerModule } from '../app-shell-features/search-banner';
import { ClaimService, JobService, SpService } from './services';
import { SPDetailsViewComponent } from './views/details-view/details-view.component';
import { FLXSPListViewComponent } from './views/list-view/sp-list-view.component';
import { FLXListViewComponent } from './views/list-view/list-view.component';
import { SpWorkflowShellComponent } from './base-components/sp-workflow-shell/sp-workflow-shell.component';
import { SpFlowComponent } from './base-components/sp-flow/sp-flow.component';
import { SpListJobCardComponent } from './components/sp-list-job-card/sp-list-job-card.component';
import { SpDetailJobCardComponent } from './components/sp-detail-job-card/sp-detail-job-card.component';
import { StoreModule } from '@ngrx/store';
import { mcAutopayInvoicingReducer, MCAutopayInvoicingEffects } from '../organisations/mul-sp/configs/invoicing-payments/mc-autopay-invoicing/store';
import { EffectsModule } from '@ngrx/effects';
import { SpUserModule } from '../sp-user/sp-user.module';


const commonComponents = [
	SpListJobCardComponent,
	SpDetailJobCardComponent,
	SPDetailsViewComponent,
	FLXSPListViewComponent,
	FLXListViewComponent,
	SpWorkflowShellComponent,
	SpFlowComponent,
];
@NgModule({
	imports: [
		CommonModule,
		UIElementsModule.forRoot({ environment: environment }),
		StoreModule.forFeature('mcAutopayInvoicingContext', mcAutopayInvoicingReducer),
		EffectsModule.forFeature([MCAutopayInvoicingEffects]),
		UICompositesModule,
		NgxPaginationModule,
		DynamicLoaderModule,
		ErrorHandlerModule,
		ScreensModule,
		InlineSVGModule,
		SearchBannerModule,
    SpUserModule,
		PipesModule,
		RouterModule,
		ThemeModule
	],
	declarations: [...commonComponents],
	exports: [
		CommonModule,
		SpListJobCardComponent,
		SpDetailJobCardComponent,
		SPDetailsViewComponent,
		FLXSPListViewComponent,
		FLXListViewComponent,
		SpWorkflowShellComponent,
		SpFlowComponent,
		UICompositesModule,
		NgxPaginationModule,
		DynamicLoaderModule,
		ErrorHandlerModule,
		ScreensModule,
		InlineSVGModule,
		SearchBannerModule,
		PipesModule,
		RouterModule,
		ThemeModule,
    SpUserModule,
	],
	providers: [ActivateStateGuard, DeactivateStateGuard, SpService, ClaimService, JobService, BillingService]
})
export class SpGlobalModule {}
