import {
	SearchFilterComponent,
	SearchComponent,
	BucketFilterComponent,
	FLXWhatMattersComponent,
	FLXAPClaimSummaryComponent,
	FLXAPTimeStampsComponent,
	FLXAPViewDocumentsComponent,
	FLXAPJobSummaryComponent,
	FLXScratchPadComponent,
	FLXSPDetailsComponent,
	FLXRemindersComponent,
	FLXRecentActivityComponent,
	FLXAPJobRequestPingComponent,
	FLXAPJobRequestPingDetailComponent,
	FLXAPInstallationSummaryComponent,
	FLXSPJobDetailsComponent,
	FLXAPTeamMembersComponent
} from './components';

export const pluginComponentMapper = {
	SearchComponent,
	SearchFilterComponent,
	BucketFilterComponent,
	FLXWhatMattersComponent,
	FLXAPTimeStampsComponent,
	FLXAPClaimSummaryComponent,
	FLXAPViewDocumentsComponent,
	FLXAPJobSummaryComponent,
	FLXScratchPadComponent,
	FLXSPDetailsComponent,
	FLXRemindersComponent,
	FLXRecentActivityComponent,
	FLXAPJobRequestPingComponent,
	FLXAPJobRequestPingDetailComponent,
	FLXAPInstallationSummaryComponent,
	FLXSPJobDetailsComponent,
	FLXAPTeamMembersComponent
};

export const pluginComponents = [
	FLXWhatMattersComponent,
	BucketFilterComponent,
	SearchComponent,
	SearchFilterComponent,
	FLXScratchPadComponent,
	FLXAPTimeStampsComponent,
	FLXAPClaimSummaryComponent,
	FLXAPViewDocumentsComponent,
	FLXAPJobSummaryComponent,
	FLXSPDetailsComponent,
	FLXRemindersComponent,
	FLXRecentActivityComponent,
	FLXAPJobRequestPingComponent,
	FLXAPJobRequestPingDetailComponent,
	FLXAPInstallationSummaryComponent,
	FLXSPJobDetailsComponent,
	FLXAPTimeStampsComponent
];
