import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sort'
})
export class SortPipe implements PipeTransform {
	/**
	 * takes a comparator and uses it to sort the input value and then return it
	 */
	transform(value: any[], comparator?: (vala, valb) => number): any[] {
		let v = value;
		if (!!comparator) {
			v = value.slice(0).sort(comparator);
		}
		return v;
	}
}
