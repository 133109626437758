import { Flow_0_0_2, getData, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { take, switchMap, map, skipWhile, filter, first, pluck } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { empty, of } from 'rxjs';

export const BET_223: Flow_0_0_2 = {
	id: '223',
	name: 'approve_accidental_death',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					if (itemOne) {
						return `Approve Accidental Death : ${
							itemOne?.loan_information?.mavenclaimnumber ? itemOne?.loan_information?.mavenclaimnumber : itemOne?.loan_information?.mavenclaimnumber
						} - ${itemOne.applicant?.surname}`;
					} else {
						return 'Approve Accidental Death';
					}
				})
			);
		},
		controls: () => () => []
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Approve Accidental Death'
		},
		viewRoles: {
			0: 'Approve Accidental Death'
		}
	},
	serverCalls: {
		// Clear out key value list from previous flow
		keyValueList: {
			errorMessage: '',
			directCall: () => {
				return of([]);
			}
		},
		files: {
			serviceVariable: 'betService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		claimDetails: {
			errorMessage: 'Could get assessor details',
			serviceVariable: 'betService',
			functionName: 'getClaimDetailsFormatted'
		}
	},
	startNode: 'ReviewAccidentalDeath',
	nodes: {
		ReviewAccidentalDeath: {
			showTabs: true,
			name: 'Claim Details',
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Approve Accidental Death',
							headingConfig: { color: 'default', size: 'medium', weight: 'normal' },
							instructions: ['Please review and approve accidental death claim']
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							objectInfo$: 'claimDetails'
						}
					}
				]
			},
			navs: [
				{
					text: 'Decline',
					nextNode: 'SubmissionSuccess',
					color: 'secondary',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: '',
							functionName: '',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const { job_information, id } = job;
											const result = JSON.parse(JSON.stringify(job_information));
											const subData = {
												job_information: result,
												job_id: id,
												new_state: 209
											};
											return subData;
										}),
										switchMap(dataSub => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					}
				},
				{
					text: 'Approve',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: '',
							functionName: '',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const { job_information, id } = job;
											const result = JSON.parse(JSON.stringify(job_information));
											const subData = {
												job_information: result,
												job_id: id,
												new_state: 58
											};
											return subData;
										}),
										switchMap(dataSub => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					}
				}
			]
		},
		UploadIDDocument: {
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							dataFiles$: 'idFile',
							fileDoesNotExistMessage: 'No ID document found'
						}
					}
				]
			},
			initFormFields: bf => {},
			name: 'ID Document',
			showTabs: true,
			checkValidityForFields: [],
			serverCalls: {
				idFile: {
					errorMessage: 'No ID document found',
					serviceVariable: '',
					functionName: '',
					directCall: (http, store, sq, bf) => {
						return store.select(getData).pipe(
							pluck('files'),
							skipWhile(files => !files),
							take(1),
							switchMap(files => {
								// Identity document
								const file = files.filter(_file => _file.purpose?.toLowerCase().includes('id'));
								if (file.length === 1) {
									return http.post<any>(environment.api_url + `v1/file_action/get_file/`, {
										file_id: file[0]?.id,
										return_type: 1
									});
								}
								return empty();
							}),
							map(file => {
								if (file) {
									return file.payload;
								}
								return {};
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Decline',
					nextNode: 'SubmissionSuccess',
					color: 'secondary',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: '',
							functionName: '',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const { job_information, id } = job;
											const result = JSON.parse(JSON.stringify(job_information));
											const subData = {
												job_information: result,
												job_id: id,
												new_state: 209
											};
											return subData;
										}),
										switchMap(dataSub => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					}
				},
				{
					text: 'Approve',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: '',
							functionName: '',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const { job_information, id } = job;
											const result = JSON.parse(JSON.stringify(job_information));
											const subData = {
												job_information: result,
												job_id: id,
												new_state: 58
											};
											return subData;
										}),
										switchMap(dataSub => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					}
				}
			]
		},
		UploadDeathCertificateDocument: {
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							dataFiles$: 'idFile',
							fileDoesNotExistMessage: 'No death certificate found'
						}
					}
				]
			},
			initFormFields: bf => {},
			name: 'Death Certificate',
			showTabs: true,
			checkValidityForFields: [],
			serverCalls: {
				idFile: {
					errorMessage: 'No death certificate found',
					serviceVariable: '',
					functionName: '',
					directCall: (http, store, sq, bf) => {
						return store.select(getData).pipe(
							pluck('files'),
							skipWhile(files => !files),
							take(1),
							switchMap(files => {
								// Identity document
								const file = files.filter(_file => _file.purpose?.toLowerCase()?.includes('death certificate'));
								if (file.length === 1) {
									return http.post<any>(environment.api_url + `v1/file_action/get_file/`, {
										file_id: file[0]?.id,
										return_type: 1
									});
								}
								return empty();
							}),
							map(file => {
								if (file) {
									return file.payload;
								}
								return {};
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'Decline',
					nextNode: 'SubmissionSuccess',
					color: 'secondary',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: '',
							functionName: '',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const { job_information, id } = job;
											const result = JSON.parse(JSON.stringify(job_information));
											const subData = {
												job_information: result,
												job_id: id,
												new_state: 209
											};
											return subData;
										}),
										switchMap(dataSub => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					}
				},
				{
					text: 'Approve',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: '',
							functionName: '',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq) => {
								return store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const { job_information, id } = job;
											const result = JSON.parse(JSON.stringify(job_information));
											const subData = {
												job_information: result,
												job_id: id,
												new_state: 58
											};
											return subData;
										}),
										switchMap(dataSub => {
											return http.post(`${environment.api_url}v1/job_action/update_job/`, dataSub);
										})
									);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {}
};
