import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { BigFormService, NetworkService } from '@flexus/core';
import moment from 'moment';
import { ModalService } from '@flexus/core';

@Component({
	selector: 'flx-claim-history',
	templateUrl: './claim-history.component.html',
	styleUrls: ['./claim-history.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimHistoryComponent implements OnInit, OnDestroy {
	// =======================  Variables ==================== //

	claimHistoryData$: Observable<any>;
	filteredClaimHistory$: Observable<any>;
	isWarning = false;
	private _claimHistory$: Observable<any>;

	private networkCheckSubscription: Subscription;
	isOffline = false;
	offlineMessage = 'You are offline. Please come back to confirm the claimant history when you are back online.';

	claimHistorySub: Subscription;

	@Input() title = 'History';
	@Input() set claimHistory$(data: Observable<any>) {
		this._claimHistory$ = data;
		this.filteredClaimHistory$ = data.pipe(map(arr => arr?.slice()?.sort(this.sortByDate)));
	}
	get claimHistory$() {
		return this._claimHistory$;
	}

	@Input() warningFlags: {
		claimType: string;
		time: number;
		timePeriod: 'days' | 'years' | 'months';
		warningMessage: string;
	}[] = null;

	constructor(public _store: Store<any>, public bf: BigFormService, private modalsService: ModalService, private networkService: NetworkService) {}

	ngOnInit() {
		this.notifyNetworkStatus();
		this.claimHistory$.pipe(take(1)).subscribe(claimhistory => {
			if (claimhistory !== null && claimhistory !== undefined && claimhistory.length > 0) {
				this.checkClaimWarnings(claimhistory);
			}
		});
	}

	private notifyNetworkStatus() {
		this.networkCheckSubscription = this.networkService.isOnline.subscribe(isOnline => {
			if (isOnline) {
				this.isOffline = false;
			} else {
				this.isOffline = true;
			}
		});
	}

	sortByDate(a, b) {
		const one = a.LOSSDATE.substr(0, a.LOSSDATE.indexOf(' '));
		const two = b.LOSSDATE.substr(0, b.LOSSDATE.indexOf(' '));
		if (moment(one).isBefore(moment(two))) return 1;
		if (moment(one).isAfter(moment(two))) return -1;
	}

	checkClaimWarnings(history: any[]) {
		let messages: string[] = [];
		if (history[history.length - 1]?.LOSSDATE) {
			// Get latest claim
			let compareData = history[history.length - 1]?.LOSSDATE;
			compareData = compareData.substr(0, compareData.indexOf(' '));
			// let latestLoss = moment(compareData);
			// Get dates relative to today
			const monthAgo = moment().subtract(30, 'days');
			const yearAgo = moment().subtract(1, 'years');
			// Check for 2 pipe claims in 1 year period
			let pipeCount = 0;
			let latesClaimDates = [];
			let pipeDates = [];
			history.forEach(entry => {
				let currComp = entry.LOSSDATE;
				currComp = currComp.substr(0, currComp.indexOf(' '));
				const currLoss = moment(currComp);
				if (entry.CLAIMTYPE.match(/Pipes/g)) {
					// compare a year from now
					if (currLoss.isAfter(yearAgo)) {
						pipeCount++;
						pipeDates.push(currLoss.format('YYYY/MM/DD')?.toString() + ' - ' + entry.CLAIMTYPE);
					}
				}
				// Check for claim in last 30 days
				if (currLoss.isAfter(monthAgo)) {
					// Claim in last 30 days
					latesClaimDates.push(currLoss.format('YYYY/MM/DD')?.toString() + ' - ' + entry.CLAIMTYPE);
				}
			});

			if (this.bf.bigForm.get('claim_class_description')) {
				const claimType = this.bf.bigForm.get('claim_class_description')?.value;
				if (pipeCount >= 2 && claimType.match(/Pipes/g)) {
					if (pipeDates.length > 3) {
						pipeDates = pipeDates.slice(0, 3);
					}
					pipeDates.push('There has been ' + pipeCount + ' pipe claim' + (pipeCount > 1 ? 's' : '') + ' with in a year.');
					messages = pipeDates;
					this.isWarning = true;
				}
			}
			if (latesClaimDates.length > 0) {
				// Claim in last 30 days
				const claimCount = latesClaimDates.length;
				if (latesClaimDates.length > 8) {
					latesClaimDates = latesClaimDates.slice(0, 5);
				}
				const pastPresText = claimCount > 1 ? ' have been multiple claims (' + claimCount + ')' : 'has been a claim';
				latesClaimDates.push('There ' + pastPresText + ' in the past 30 days.');
				if (this.isWarning) {
					messages.push(' ');
					messages = messages.concat(latesClaimDates);
				} else {
					messages = latesClaimDates;
				}
				this.isWarning = true;
			}
		}
		if (this.isWarning && messages && messages.length > 0) {
			messages = messages.reverse();
			this.modalsService.openModalDirectly(inst => {
				inst.setMessage(messages);
				inst.type = 'warning';
				inst.closeButton = 'true';
				inst.navButtons = [];
			});
		}
	}

	formatDate(date: string): Date {
		return moment(date, 'MM-D-YYYY h mm ss a').toDate();
	}

	ngOnDestroy() {}
}
