import { Flow_0_0_2, getAllInfo, getFullItemTwo, getSubmissionData } from '@flexus/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { KVLHeading } from '@flexus/ui-elements';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { setActionPanelItems, CollapseActionPanel } from '../../../app-shell-features';

export const SP_GLOBAL_162: Flow_0_0_2 = {
	id: '162',
	name: 'work_paused_no_excess_no_appointment',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: store =>
			store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information)
							return `Set New Appointment / Move to CIL : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
						return 'Set New Appointment / Move to CIL';
					}
				})
			),
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Set new appointment / Change to CIL'
		},
		viewRoles: {
			0: 'Job Paused'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		keyValueListForCiL: {
			errorMessage: 'Cash-in-Lieu details could not be retrieved',
			directCall: (_h, store) =>
				store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					map((job: any) => [
						{
							'Claim Type': job?.claim?.type ?? '',
							'Client Name': job?.claim?.applicant ? job?.claim?.applicant?.first_name + ' ' + job?.claim?.applicant?.surname : '',
							'Contact number': job?.claim?.applicant ? job?.claim?.loan_information?.contactnumber : '',
							'Client Alternative no': job?.claim?.loan_information?.cellnumber ?? '',
							Address: job?.claim?.address ?? ''
						}
					])
				)
		}
	},
	startNode: 'SetNewAppointmentNotification',
	nodes: {
		SetNewAppointmentNotification: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Set new appointment',
							instructions: ['Your team leader has paused the job.', 'Please contact the customer and arrange a new appointment.'],
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							data$: 'importantInfoKeyValues',
							color: 'secondary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'On-site Details',
							data$: 'onsiteKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			showTabs: true,
			name: 'Overview',
			serverCalls: {
				importantInfoKeyValues: {
					errorMessage: '!',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map((itemTwo: any) => {
									const importantinfo = {
										'Paused reason ': itemTwo?.job_information?.paused_reason ?? 'No reason was provided for pausing the job.'
									};
									return [importantinfo];
								})
							);
					}
				},
				claimDetailKeyValues: {
					errorMessage: 'Something went wrong claim details',
					directCall: (_h, store) =>
						forkJoin([
							store.select(getFullItemTwo)?.pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo)?.pipe(
								skipWhile(ai => !ai),
								take(1),
								map(res => res as any)
							)
						]).pipe(
							map(([itemTwo, info]: any[]) => {
								const skill = info?.skills.find(el => el.id === itemTwo?.skill);
								const claimDetails = {
									'Claim Type ': itemTwo?.claim?.claim_type ?? '',
									'Skill required ': `${skill?.name}` ?? '',
									'Address ': itemTwo?.address ?? '',
									'Appointment Date ': itemTwo?.office_use?.requestedappointmentdate ?? 'No appointment date set',
									'Appointment Time ': itemTwo?.office_use
										? `${itemTwo.office_use.appointment_type} ${itemTwo.office_use.requestedappointmenttime}`
										: 'Appointment time not retrieved'
								};
								return [claimDetails];
							})
						)
				},
				onsiteKeyValues: {
					errorMessage: "Couldn' retrieve on-site info",
					directCall: (_h, store) =>
						store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map((itemTwo: any) => [
									{
										'On site contact person ': itemTwo?.claim?.loan_information?.onsiteperson ?? 'Not retrieved',
										'Contact number ': itemTwo?.claim?.loan_information?.onsitecontact ?? 'On-site contact number not available',
										'Notes ': itemTwo?.claim?.loan_information?.whatmatters ?? 'What Matters Not Retrieved'
									}
								])
							)
				},
				customerContactKeyValues: {
					errorMessage: 'Customer contact information could not be retrieved',
					directCall: (_h, store) =>
						store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map((itemTwo: any) => [
									{
										'Customer ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : 'Customer name not available',
										'Customer cell ': itemTwo?.claim?.loan_information?.cellnumber ?? 'Cellular number not on record',
										'Customer Alternative No': itemTwo?.claim?.loan_information?.contactnumber ?? 'Alternative number not on record'
									}
								])
							)
				}
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'SetAppointment'
				}
			]
		},
		SetAppointment: {
			showTabs: true,
			name: 'Set Appointment',
			initFormFields: (bf, _i, _in, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup(
						{
							appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
							appointmentTime: new UntypedFormControl(null, [Validators.required]),
							appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
							appointmentdatetype: new UntypedFormControl(null, [Validators.required]),
							skill: new UntypedFormControl(null),
							skillcatagory: new UntypedFormControl(null)
						},
						[Validators.required]
					)
				);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skill
										skillcatagory
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile((res: any) => !res || !res.skill || !res.skillcatagory),
						take(1)
					)
					.subscribe(({ skill, skillcatagory }) => {
						const apd = bf.getControl('appointmentData');
						if (apd) {
							apd.get('skill')?.patchValue(skill);
							apd.get('skillcatagory')?.patchValue(skillcatagory);
						}
					});
			},
			component: 'FLXJobAppointmentComponent',
			inputs: {
				minDate: new Date(),
				title: 'Set appointment',
				instructions: [' - placeholder - ']
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (_h, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo)?.pipe(
									skipWhile((fi: any) => !fi || !fi.state),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((customerDetails: any) => [
									{
										'Client Name': `${customerDetails.first_name} ${customerDetails.surname}`
									},
									{ 'Contact Number': `${customerDetails.contactnumber}` },
									{ 'Mobile Number': `${customerDetails.cellnumber}` }
								])
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (_h, store, sq) =>
						sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo)?.pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(map((queryData: any) => [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }]))
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Change to cash-in-lieu',
					nextNode: 'RecommendCIL',
					location: 'center',
					color: 'default',
					serverFirst: true
				},
				{
					text: 'Set Appointment',
					color: 'primary',
					optIntoValidation: true,
					disableOnLoad: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'The job could not be updated!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo)?.pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData)?.pipe(
										skipWhile(x => !x),
										take(1)
									)
								])?.pipe(
									map(([job, submissionData]: any[]) => {
										const job_id = job?.id;
										const current_state = 162;
										const new_state = 161;
										const appointment = bf.bigForm.get('appointmentData')?.value;
										const date = moment(appointment.appointmentDatePicker);
										date.hour(submissionData.appointmentData.appointmentTimePicker.hour);
										date.minutes(submissionData.appointmentData.appointmentTimePicker.minutes);

										const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
										return {
											job_id: job_id,
											current_state: current_state,
											new_state: new_state,
											appointment: {
												range_start: date_formatted,
												appointment_type: submissionData.appointmentData.appointmentTime,
												reason: ''
											}
										};
									}),
									switchMap(data => http.post(`${environment.api_url}v1/job_action/update_job/`, data))
								);
							}
						}
					}
				}
			]
		},

		RecommendCIL: {
			hideTabItem: true,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Client has requested to be paid out in cash',
							instructions: ['No work was done. Invoice a call-out fee']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueListForCiL' }
					}
				]
			},
			navs: [
				{
					text: 'Continue',
					color: 'primary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							(instance.type = 'info'), instance.setMessage(['Are you sure you want to move to CIL']);
							instance.navButtons = [
								{
									text: 'No!',
									color: 'default',
									linkType: 'nextNode',
									nextNode: 'SetAppointment'
								},
								{
									text: 'Yes, move to CIL',
									color: 'primary',
									linkType: 'submitThenNext',
									serverCalls: {
										response: {
											errorMessage: 'Job may not have been moved to CIL',
											directCall: (http, store) =>
												store.select(getFullItemTwo).pipe(
													take(1),
													switchMap((data: any) => {
														const effected_jobs: number[] = data?.id;
														const cil_state = 72;
														return http.post(`${environment.api_url}v1/job_action/move_to_cil/`, {
															effected_jobs: [effected_jobs],
															cil_state: cil_state
														});
													})
												)
										}
									},
									nextNode: 'SubmissionSuccess'
								}
							];
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		appointmentData: 'appointmentData',
		new_state: 'new_state'
	}
};
