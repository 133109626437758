import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BigFormService, MakeServerCall, ManifestController, SetNextNode, SetPreviousNode } from '@flexus/core';
import { cleanUpSub, returnOrDefault } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

export interface ModalNavButton {
	text: string;
	color?: 'alert' | 'primary' | 'default';
	linkType?: 'close' | 'nextNode' | 'submitThenNext';
	nextNode?: string;
	serverCalls?: object;
	clickHandler?: (ev: Event) => void;
	confirmAction?: ModalNavButtonConfirmation;
	disabled?: Observable<boolean>;
	isOnlineBtn?: boolean;
}

export interface ModalNavButtonConfirmation {
	text: string;
	type: 'alert' | 'primary' | 'default' | 'danger' | 'ping' | 'job-notifications';
	confirm: ModalNavButton;
}

@Component({
	selector: 'flx-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FLXModalComponent),
			multi: true
		}
	]
})
export class FLXModalComponent implements OnInit, OnDestroy {
	private _color = '';
	_type: string;
	_close = false;
	_submit = true;
	_heading!: string;
	align!: string;

	form: UntypedFormGroup;

	public actionToConfirm: ModalNavButtonConfirmation;

	styleClasses = {
		colorClass: 'modal--colour-warning',
		sizeClass: 'modal--size-default'
	};

	get arrClasses() {
		return Object.values(this.styleClasses);
	}

	overlayRef: OverlayRef;
	backdropSub: Subscription;
	@ViewChild('overlayTemplate') overlayTemplate: CdkPortal;
	@ViewChild('modalHeader') modalHeader: ElementRef;
	@ViewChild('modalContent') modalContent: ElementRef;

	@Input() backgroundClose = false; // if true, you can click the background to close
	@Input() message = '';
	@Input() submessage = '';
	@Input() heading: string = null;
	@Input() subheading: string = null;
	@Input() navButtons: ModalNavButton[] = null;
	@Input() displayHeading = true;
	@Input() messageOnly = true;
	@Input() hasCheck = false;
	@Input() imageSource?: string;

	@Input() set type(t: string) {
		this._type = t;
		switch (t) {
			case 'warning':
				this.heading = !!this.heading ? this.heading : 'Warning';
				this.color = 'alert';
				this.styleClasses.colorClass = 'modal--colour-warning';
				this.styleClasses.sizeClass = 'modal--size-default';
				break;
			case 'info':
				this.heading = !!this.heading ? this.heading : 'Information';
				this.styleClasses.colorClass = 'modal--colour-infomation';
				this.styleClasses.sizeClass = 'modal--size-default';
				this.color = 'primary';
				break;
			case 'success':
				this.heading = !!this.heading ? this.heading : 'Success';
				this.styleClasses.colorClass = 'modal--colour-infomation';
				this.styleClasses.sizeClass = 'modal--size-default';
				this.color = 'primary';
				break;
			case 'danger':
				this.heading = !!this.heading ? this.heading : 'Danger';
				this.styleClasses.colorClass = 'modal--colour-danger';
				this.styleClasses.sizeClass = 'modal--size-default';
				this.color = 'danger';
				break;
			case 'ping':
				// this.heading = !!this.heading ? this.heading : 'Indicate Your Availability For The Following Jobs!';
				this.styleClasses.colorClass = 'modal--colour-ping';
				this.styleClasses.sizeClass = 'modal--size-ping';
				this.color = 'primary';
				break;
			case 'job-notifications':
				this.heading = !!this.heading ? this.heading : 'Job Notifications!';
				this.styleClasses.colorClass = 'modal--colour-ping';
				this.styleClasses.sizeClass = 'modal--size-ping';
				this.color = 'primary';
				this.align = 'start';
				break;
			default:
				this._type = 'default';
				this.styleClasses.colorClass = 'modal--colour-warning';
				this.styleClasses.sizeClass = 'modal--size-default';
				this.heading = !!this.heading ? this.heading : 'alert';
				break;
		}
	}
	get type() {
		return returnOrDefault(this._type, 'default');
	}

	@Input() set color(c: string) {
		this._color = c;
		switch (c) {
			case 'alert':
				this.styleClasses.colorClass = 'modal--colour-warning';
				break;
			case 'primary':
				this.styleClasses.colorClass = 'modal--colour-infomation';
				break;
			case 'danger':
				this.styleClasses.colorClass = 'modal--colour-danger';
				break;
			default:
				this.styleClasses.colorClass = 'modal--colour-warning';
				break;
		}
	}
	get color() {
		return returnOrDefault(this._color, 'default');
	}

	@Input() set closeButton(t: any) {
		this._close = t === 'true' ? true : false;
	}
	get closeButton() {
		return returnOrDefault(this._close, false);
	}
	@Input() set submitButton(t: any) {
		this._submit = t === 'true' ? true : false;
	}
	get submitButton() {
		return returnOrDefault(this._submit);
	}

	showCalc = false;
	calculationLineItems: { display: string; value: number }[] = [];
	calcTotal = 0;
	calcTotalHeading = 'Total';
	control: {
		label: string;
		controlName: string;
		required: boolean;
	} = undefined;

	// ======================================== General Methods ========================================
	constructor(
		private el: ElementRef,
		private _store: Store<any>,
		public router: Router,
		private controller: ManifestController<any>,
		private overlay: Overlay,
		private cd: ChangeDetectorRef,
		public _sanitizer: DomSanitizer,
		public _bf: BigFormService
	) {}

	ngOnInit(): void {
		this.form = new UntypedFormGroup({
			modal_check: new UntypedFormControl(null, [Validators.required])
			// this._bf.bigForm.addControl('modal_check', new FormControl(null, [Validators.required]));
		});
	}

	ngOnDestroy(): void {
		this.close();
		cleanUpSub(this.backdropSub);
	}

	open(): void {
		if (this.overlayRef) {
			// keep only one modal open at a time
			this.overlayRef.dispose();
		}
		this.openTemplateOverlay();
	}

	close(): void {
		if (!!this.overlayRef) {
			this.overlayRef.dispose();
		}
		this.clearData();
	}

	// ======================================== CDK Methods ========================================
	openTemplateOverlay() {
		const positionStrategy = this.overlay.position().global()?.centerHorizontally()?.centerVertically();

		const overlayConfig = new OverlayConfig({
			positionStrategy
		});

		overlayConfig.hasBackdrop = true;

		this.overlayRef = this.overlay.create(overlayConfig);

		if (this.backgroundClose) {
			this.clickBackdrop();
		}

		this.overlayRef.attach(this.overlayTemplate);
	}

	clickBackdrop() {
		this.backdropSub = this.overlayRef.backdropClick().subscribe(() => {
			this.overlayRef.dispose();
		});
	}
	saveAttachmentToDownloads(stringDataForAttach: string, filename: string) {
		let temp = null;
		if (stringDataForAttach != null) {
			const blob = this.base64StringtoBlob(stringDataForAttach, 'application/pdf');
			temp = this._sanitizer?.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
		}
	}
	setDownloadLinkAttrs(element, attributes) {
		Object.entries(attributes).forEach(([key, value]) => element.setAttribute(key, value));
	}

	base64StringtoBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}
	// ========================================== CONTENT Methods ==========================================
	setMessage(content: any[]) {
		setTimeout(() => {
			if (!!this.modalContent) {
				this.modalContent.nativeElement.innerHTML = '';
				content.forEach(element => {
					this.modalContent.nativeElement.innerHTML += element + '<br />';
				});
			}
			this.cd.detectChanges();
		}, 0);
	}

	setHeading(heading: string) {
		this.subheading = heading;
	}

	setControl(control: { label: string; controlName: string; required: boolean }) {
		if (control) {
			this._bf.addControl(control.controlName, new UntypedFormControl('', control.required ? Validators.required : null));
			this.control = control;
		} else {
			this.control = undefined;
		}
	}

	removeControl = (controlName: string) => {
		this.control = undefined;
		this._bf.bigForm.removeControl(controlName);
	};

	showCalculations(lineItems: { display: string; value: number; type: 'add' | 'subtract' }[], totalHeading?: string) {
		this.showCalc = true;
		this.heading = 'Payment calculation';
		if (lineItems.length > 0) {
			this.calculationLineItems = lineItems;
			this.calcTotal = 0;
			// workout toal
			lineItems.forEach(item => {
				if (item?.type === 'add') {
					this.calcTotal += item?.value;
				} else {
					this.calcTotal -= item?.value;
				}
			});
			this.calcTotalHeading = totalHeading ? totalHeading : 'Total';
		}
	}

	takeButtonAction(button) {
		// append Click handler from navs manifest
		if (button.confirmAction) {
			this.actionToConfirm = button.confirmAction;
		}

		if (button.clickHandler) {
			button.clickHandler(button);
		}

		switch (button.linkType) {
			case 'close':
				if (this.actionToConfirm) {
					this.actionToConfirm = null;
				}
				this.close();
				break;
			case 'nextNode':
				if (button.nextNode !== null) {
					this.controller.dispatch(new SetNextNode(button.nextNode));
				} else {
					this.controller.dispatch(new SetPreviousNode());
				}
				this.close();
				break;
			case 'submitThenNext':
				if (button.serverCalls) {
					Object.entries(button.serverCalls).forEach(([dataKey, call]: any) => {
						this._store.dispatch(new MakeServerCall({ dataKey, ...call, nextNode: button.nextNode }));
					});
					this.close();
				}
				break;
		}
	}

	clearData() {
		// this.navButtons = null;
		this.showCalc = false;
		this.calculationLineItems = [];
		this.calcTotal = 0;
		this.calcTotalHeading = 'Total';
		this.heading = null;
		this.imageSource = null;
		this.navButtons = null;
		if (!!this.modalContent) {
			this.modalContent.nativeElement.innerHTML = '';
			this.message = '';
			this.subheading = null;
		}
	}

	openModalDirectly(arg0: (instance: any) => void) {
		throw new Error('Method not implemented.');
	}
}
