<ng-container *ngIf="claimSummary$ | async">
	<flx-tabs [tabs]="tabs" [currentStep$]="currentTab$" [externalPages]="true" (outputEventStream)="handleTab($event)">
		<!-- <flx-heading size="medium" align="center" weight="extra-light">Claim Summary</flx-heading> -->
		<flx-nested-object-key-value-list
			id="claim_information"
			class="tab-content"
			*ngIf="(currentTab$ | async) === 'claim_information'"
			[title]="title"
			[objectInfo$]="$any(claimSummary$)"
		>
		</flx-nested-object-key-value-list>

		<ng-container *ngFor="let job of jobInformation">
			<flx-nested-object-key-value-list id="{{ getViewJobID(job) }}" class="tab-content" *ngIf="(currentTab$ | async) === getViewJobID(job)" [objectInfo$]="jobObject$">
			</flx-nested-object-key-value-list>
		</ng-container>
	</flx-tabs>
</ng-container>
