<flx-modal [displayHeading]="false" [navButtons]="modalButtons" [backgroundClose]="false" type="info">
	<flx-heading size="large" color="primary" align="center">
		<span class="add-rem-heading">{{ modificationType === 'create' ? 'Create Custom Item' : 'Update Custom Item' }}</span>
	</flx-heading>

	<form [formGroup]="modifyForm" class="add-reminder-form">
		<div class="message-details">
			<flx-label>Name of item</flx-label>
			<flx-input-text formControlName="item_name" [maxLength]="50"></flx-input-text
			><flx-reactive-form-error-display [control]="$any(modifyForm)?.controls['item_name']"></flx-reactive-form-error-display>
		</div>
		<div class="message-details message-details--margin-bottom">
			<flx-label>Type</flx-label>
			<flx-dropdown
				formControlName="item_type"
				[searchEnabled]="false"
				placeHolder="Select type"
				[itemsOption]="boqTypes | async"
				[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
			></flx-dropdown>
			<flx-reactive-form-error-display [control]="$any(modifyForm)?.controls['item_type']"></flx-reactive-form-error-display>
		</div>
		<div class="message-details message-details--margin-bottom">
			<flx-label>Measurement</flx-label>
			<flx-dropdown
				formControlName="measurement"
				[searchEnabled]="false"
				placeHolder="Select type"
				[itemsOption]="boqUnitsOfMeasurement | async"
				[displayOptions]="{ displayKey: 'display', valueKey: 'key' }"
			></flx-dropdown>
			<flx-reactive-form-error-display [control]="$any(modifyForm)?.controls['measurement']"></flx-reactive-form-error-display>
		</div>
		<div class="flex flex--space-between">
			<div class="message-details message-details--margin-right message-details--margin-bottom">
				<flx-label>Price</flx-label>
				<input formControlName="price" type="number" min="0.00" step="0.01" class="form-control message-details__input" formatCurrency allowOnlyAbsoluteNumbers />
			</div>
			<div class="message-details year message-details--margin-left message-details--margin-bottom">
				<flx-label>Year</flx-label>
				<flx-dropdown
					formControlName="year"
					[searchEnabled]="false"
					placeHolder="Select year"
					[itemsOption]="boqYears | async"
					[displayOptions]="{ displayKey: 'year', valueKey: 'year' }"
				></flx-dropdown>
			</div>
		</div>
		<div class="flex flex--space-between">
			<div class="flex__grow text-left"><flx-reactive-form-error-display [control]="$any(modifyForm)?.controls['price']"></flx-reactive-form-error-display></div>
			<div class="flex__grow text-left"><flx-reactive-form-error-display [control]="$any(modifyForm)?.controls['year']"></flx-reactive-form-error-display></div>
		</div>
		<div class="message-details message-details--margin-bottom">
			<flx-label>Skill Sub Category</flx-label>
			<flx-dropdown
				*ngIf="autoSelectedSkills"
				[searchEnabled]="true"
				formControlName="skill_categories"
				placeHolder="Select skill"
				[itemsOption]="boqSubCategories | async"
				[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
				[multiple]="true"
				(itemsSelected)="modifyForm.get('skill_categories')?.value"
				[upperItemCountLimit]="10"
				[autoSelectedItems]="autoSelectedSkills"
				[optionsThatCannotBeDeleted]="['Custom']"
			></flx-dropdown>
		</div>
	</form>
</flx-modal>
