import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { getLoadingState } from '@flexus/core';
import { Store } from '@ngrx/store';
@Component({
	selector: 'flx-workflow-app',
	templateUrl: './workflow-app.component.html',
	styleUrls: ['./workflow-app.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXWorkflowAppComponent implements OnInit {
	loading$: Observable<boolean>;
	constructor(private _store: Store<any>) {}

	ngOnInit() {
		this.loading$ = this._store.select(getLoadingState);
	}
}
