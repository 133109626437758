import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BigFormService } from '@flexus/core';
import { Observable } from 'rxjs';
import { DynamicFormInputs } from '@flexus/ui-composites';

@Component({
	selector: 'flx-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesComponent implements OnInit {
	@Input() notes$: Observable<any>;
	@Input() title$: Observable<string>;
	@Input() submitFunction: Function;
	@Input() formInputs: DynamicFormInputs;
	@Input() username$: Observable<string>;
	@Input() location: string;

	headingAlignment = 'center';
	headingWeight = 'normal';
	headingSize = 'small';

	locationClass = 'note-edit';
	// headingClass = 'heading';

	constructor(public _store: Store<any>, public bf: BigFormService) {}

	ngOnInit() {
		switch (this.location) {
			case 'action-panel':
				this.locationClass = 'compact';
				this.headingAlignment = 'left';
				this.headingWeight = '';
				this.headingSize = 'medium';
				break;
			case 'context-menu':
				this.locationClass = 'scattered';
				this.headingAlignment = 'center';
				this.headingWeight = 'extra-light';
				this.headingSize = 'medium';

				break;
			default:
				break;
		}
	}

	submit() {
		this.submitFunction(this);
	}
}
