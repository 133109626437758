<flx-panel maxWidth="40vw" margin="auto">
	<flx-heading size="medium" weight="extra-light" [align]="headingOptions.alignment">Claim Excess </flx-heading>
	<ng-container *ngIf="excess_on_claim$ | async as excessDetails">
		<div>
			<ng-container *ngIf="excessDetails.jobs?.length > 0; else noJobs">
				<ng-container *ngFor="let j of excessDetails.jobs">
					<div class="excess-container">
						<flx-heading size="small" weight="bold" margin="0 0 0.5rem 0">{{ j.skill }}</flx-heading>
						<div class="table" *ngIf="j.excess.length > 0">
							<ng-container *ngIf="location === 'screen'">
								<div [class]="gridOptions.row">
									<div [class]="gridOptions.th"><span>Reason</span> <span>Collected by</span> <span>Collection method</span> <span>Amount</span></div>
									<div [class]="gridOptions.tr" *ngFor="let e of j.excess">
										<span>{{ e.reason || '-' }}</span> <span>{{ e.who_collects || '-' }}</span> <span>{{ e.how_collect || '-' }}</span> <span>{{ e.amount || '-' }}</span>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="location === 'action-panel'">
								<div *ngFor="let e of j.excess">
									<div [class]="gridOptions.row">
										<div [class]="gridOptions.th"><span>Reason</span> <span>Collected by</span> <span>Collection method</span> <span>Amount</span></div>
										<div [class]="gridOptions.tr">
											<span>{{ e.reason || '-' }}</span> <span>{{ e.who_collects || '-' }}</span> <span>{{ e.how_collect || '-' }}</span> <span>{{ e.amount || '-' }}</span>
										</div>
									</div>
									<flx-glow-line *ngIf="j.excess.length > 1"></flx-glow-line>
								</div>
							</ng-container>
						</div>
						<div *ngIf="j.excess.length < 1" class="no-excess"><flx-alert type="no-info" missingItem="excess"></flx-alert></div>
					</div>
				</ng-container>
			</ng-container>
			<ng-template #noJobs> <flx-alert type="no-info" missingItem="jobs"></flx-alert> </ng-template>
		</div>
	</ng-container>
</flx-panel>
