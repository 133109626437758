import { Flow_0_0_2, getFullItemOne, getAllInfo, getRelatedItemTwos, getSkills } from '@flexus/core';
import { skipWhile, take, pluck, map, switchMap, filter } from 'rxjs/operators';
import gql from 'graphql-tag';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin, of } from 'rxjs';
import { ASSESSOR_OVERVIEW } from '../reusable/ASSESSOR_OVERVIEW';

export const BET_109: Flow_0_0_2 = {
	id: '109',
	name: 'Complete Assessment',
	itemType: 'flow',
	fetchLevel1And2: true,
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					let title = 'Complete Assessment';
					if (itemOne?.loan_information?.mavenclaimnumber) {
						title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
					}
					if (itemOne.loan_information.surname) {
						title += ` - ${itemOne.applicant?.surname}`;
					}

					return title;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Complete Assessment'
		},
		viewRoles: {
			0: 'Awaiting IA Review'
		}
	},
	startNode: 'JobSummary',
	nodes: {
		JobSummary: {
			...ASSESSOR_OVERVIEW.JobSummary,
			navs: [
				{
					text: 'Complete Assessment',
					linkType: 'portal',
					color: 'alert',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'warning';
							instance.setMessage(['Are you sure you want to complete your assessment?', '', 'The claim will be updated, and removed from your workflow']);
							instance.navButtons = [
								{
									text: 'Cancel',
									color: 'alert',
									linkType: 'close'
								},
								{
									text: 'Complete',
									color: 'alert',
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										updateClaim: {
											errorMessage: 'ERROR UPDATING CLAIM. STATE 109',
											directCall: (http, store) => {
												return forkJoin([
													store.select(getFullItemOne).pipe(
														skipWhile(x => !x),
														take(1),
														map(res => res as any)
													),
													store.select(getRelatedItemTwos).pipe(
														skipWhile(x => !x),
														take(1)
													),
													store.select(getSkills).pipe(
														skipWhile(x => !x),
														take(1)
													)
												]).pipe(
													map(([claim, allJobs, skills]: any) => ({ claim, allJobs, skills })),
													switchMap(({ claim, allJobs, skills }) => {
														let iaJob;

														const assessorSkills: any[] = (skills as any[])?.filter(res => res?.name?.toLowerCase().includes('assessor'));
														if (assessorSkills.length > 0) {
															iaJob = (allJobs as any[])?.find(job => job?.skill === assessorSkills[0]?.id);
														}

														if (iaJob) {
															const data = {
																job_information: iaJob.job_information,
																job_id: iaJob.id,
																new_state: 104
															};
															return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
														} else {
															return of({});
														}
													})
												);
											}
										}
									}
								}
							];
						}
					}
				}
			]
		},
		ContactDirectory: {
			...ASSESSOR_OVERVIEW.ContactDirectory,
			navs: [
				{
					text: 'Complete Assessment',
					linkType: 'portal',
					color: 'alert',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'warning';
							instance.setMessage(['Are you sure you want to complete your assessment?', '', 'The claim will be updated, and removed from your workflow']);
							instance.navButtons = [
								{
									text: 'Cancel',
									color: 'alert',
									linkType: 'close'
								},
								{
									text: 'Complete',
									color: 'alert',
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										updateClaim: {
											errorMessage: 'ERROR UPDATING CLAIM. STATE 109',
											directCall: (http, store) => {
												return forkJoin([
													store.select(getFullItemOne).pipe(
														skipWhile(x => !x),
														take(1),
														map(res => res as any)
													),
													store.select(getRelatedItemTwos).pipe(
														skipWhile(x => !x),
														take(1)
													),
													store.select(getSkills).pipe(
														skipWhile(x => !x),
														take(1)
													)
												]).pipe(
													map(([claim, allJobs, skills]: any) => ({ claim, allJobs, skills })),
													switchMap(({ claim, allJobs, skills }) => {
														let iaJob;

														const assessorSkills: any[] = (skills as any[])?.filter(res => res?.name?.toLowerCase().includes('assessor'));
														if (assessorSkills.length > 0) {
															iaJob = (allJobs as any[])?.find(job => job?.skill === assessorSkills[0]?.id);
														}

														if (iaJob) {
															const data = {
																job_information: iaJob.job_information,
																job_id: iaJob.id,
																new_state: 104
															};
															return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
														} else {
															return of({});
														}
													})
												);
											}
										}
									}
								}
							];
						}
					}
				}
			]
		},
		// AssessorOverview: {
		// 	mockContextData: {},
		// 	name: 'Assessor Overview',
		// 	component: 'FLXInternalAssessorOverviewComponent',
		// 	navs: [
		// 		{
		// 			text: 'Complete Assessment',
		// 			linkType: 'portal',
		// 			color: 'alert',
		// 			portalData: {
		// 				type: 'modal',
		// 				paramFunc: instance => {
		// 					instance.type = 'warning';
		// 					instance.setMessage(['Are you sure you want to complete your assessment?', '', 'The claim will be updated, and removed from your workflow']);
		// 					instance.navButtons = [
		// 						{
		// 							text: 'Cancel',
		// 							color: 'alert',
		// 							linkType: 'close'
		// 						},
		// 						{
		// 							text: 'Complete',
		// 							color: 'alert',
		// 							linkType: 'submitThenNext',
		// 							nextNode: 'SubmissionSuccess',
		// 							serverCalls: {
		// 								updateClaim: {
		// 									errorMessage: 'ERROR UPDATING CLAIM. STATE 109',
		// 									directCall: (http, store) => {
		// 										return forkJoin([
		// 											store.select(getFullItemOne).pipe(
		// 												skipWhile(x => !x),
		// 												take(1),
		// 												map(res => res as any)
		// 											),
		// 											store.select(getRelatedItemTwos).pipe(
		// 												skipWhile(x => !x),
		// 												take(1)
		// 											),
		// 											store.select(getSkills).pipe(
		// 												skipWhile(x => !x),
		// 												take(1)
		// 											)
		// 										]).pipe(
		// 											map(([claim, allJobs, skills]) => ({ claim, allJobs, skills })),
		// 											switchMap(({ claim, allJobs, skills }) => {
		// 												let iaJob;

		// 												const assessorSkills: any[] = (skills as any[])?.filter(res => res?.name?.toLowerCase().includes('assessor'));
		// 												if (assessorSkills.length > 0) {
		// 													iaJob = (allJobs as any[])?.find(job => job?.skill === assessorSkills[0]?.id);
		// 												}

		// 												if (iaJob) {
		// 													const data = {
		// 														job_information: iaJob.job_information,
		// 														job_id: iaJob.id,
		// 														new_state: 104
		// 													};
		// 													return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
		// 												} else {
		// 													return of({});
		// 												}
		// 											})
		// 										);
		// 									}
		// 								}
		// 							}
		// 						}
		// 					];
		// 				}
		// 			}
		// 		}
		// 	],
		// 	serverCalls: {
		// 		claimSummary: {
		// 			errorMessage: '',
		// 			directCall: (http, store, sq) => {
		// 				return sq
		// 					.queryObject(
		// 						gql`
		// 							{
		// 								fullItemOne {
		// 									loan_information {
		// 										ia_requested_reason
		// 										propertystreetaddress
		// 										propertysuburb
		// 										claimprovince
		// 										suburbcode
		// 										claimlatitude
		// 										claimlongitude
		// 										claimtype
		// 										dateofloss
		// 									}
		// 								}
		// 							}
		// 						`,
		// 						store.select(getFullItemOne).pipe(
		// 							skipWhile(f => !f),
		// 							take(1),
		// 							map(res => {
		// 								return { fullItemOne: res };
		// 							})
		// 						)
		// 					)
		// 					.pipe(
		// 						map((res: any) => {
		// 							return {
		// 								ia_requested_reason: res?.ia_requested_reason,
		// 								claim_details: [{ claim_type: res?.claimtype }, { date_of_loss: res?.dateofloss }],
		// 								address: [
		// 									{ street: res?.propertystreetaddress },
		// 									{ suburb: res?.propertysuburb },
		// 									{ province: res?.claimprovince },
		// 									{ suburb_code: res?.propertysuburb },
		// 									{ latitude: res?.claimlatitude },
		// 									{ longitude: res?.claimlongitude }
		// 								]
		// 							};
		// 						})
		// 					);
		// 			}
		// 		},
		// 		allInfo: {
		// 			errorMessage: '',
		// 			directCall: (http, store, sq) => {
		// 				return store
		// 					.select(getAllInfo)
		// 					.pipe(
		// 						skipWhile(x => !x),
		// 						take(1),
		// 						map((res: any) => {
		// 							const { states, skills, sps, supplier_type, appointment_types } = res;
		// 							return { states, skills, sps, supplier_type, appointment_types };
		// 						})
		// 					)
		// 					.pipe(
		// 						map((queryData: any) => {
		// 							return {
		// 								states: queryData.states,
		// 								skills: queryData.skills,
		// 								sps: queryData.sps,
		// 								supplier_type: queryData.supplier_type,
		// 								appointment_types: queryData.appointment_types
		// 							};
		// 						})
		// 					);
		// 			}
		// 		},
		// 		allJobs: {
		// 			errorMessage: '',
		// 			directCall: (http, store, sq) => {
		// 				return sq
		// 					.queryObject(
		// 						gql`
		// 							{
		// 								relatedItemTwos
		// 							}
		// 						`,
		// 						store.select(getRelatedItemTwos).pipe(
		// 							skipWhile(f => !f),
		// 							take(1),
		// 							map(res => {
		// 								return { relatedItemTwos: res };
		// 							})
		// 						)
		// 					)
		// 					.pipe(map((queryData: any) => queryData?.relatedItemTwos));
		// 			}
		// 		}
		// 	}
		// },
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	}
};
