import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BigFormService } from '@flexus/core';
import { of } from 'rxjs';

@Component({
	selector: 'flx-image-upload-card',
	templateUrl: 'image-upload-card.component.html',
	styleUrls: ['image-upload-card.component.scss']
})
export class FLXImageUploadCardComponent implements OnInit {
	// variables

	@Input() image$: any;
	imageSrcString;
	image_thumbnail;
	image_name;
	image_size;
	image_type;
	image_base64;
	image_purpose;
	image_icon;
	sourceString;
	thumbnail;

	purposeOptions = [
		{ display: 'Decoder number', value: 'decoder_number', disabled: false },
		{ display: 'LNB QR code', value: 'lnb_qr_code', disabled: false },
		{ display: 'Dish QR code', value: 'dish_qr_code', disabled: false },
		{ display: 'Bracket QR code', value: 'bracket_qr_code', disabled: false }
	];

	displayConfig = { displayKey: 'display', valueKey: 'value' };

	constructor(private _cdr: ChangeDetectorRef, private _sanitiser: DomSanitizer, public _bf: BigFormService) {}

	ngOnInit() {
		// this._bf.addControl(
		// 	'upload_image_array',
		// 	new UntypedFormArray([
		// 		new UntypedFormGroup(
		// 			{
		// 				imagename: new UntypedFormControl(null, []),
		// 				imagesize: new UntypedFormControl(null, []),
		// 				imagepurpose: new UntypedFormControl(null, [])
		// 			},
		// 			[],
		// 			[]
		// 		)
		// 	])
		// );
		this.handleFile();
		// this._bf.bigForm.addControl('')
	}
	handleFile() {
		this.image_thumbnail = this.image$?.thumbnail;
		this.thumbnail = this._sanitiser.bypassSecurityTrustUrl(this.image_thumbnail);
		this.image_name = this.image$?.name;
		this.image_size = this.image$?.size;
		this.image_type = this.image$?.type;
	}
	ngAfterViewInit() {
		this._cdr.detectChanges();
	}
}
