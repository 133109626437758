import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as SPUsersManagementActions from './sp-users-management.actions';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ENVIRONMENT } from '../services/engine.constants';

@Injectable()
export class SPUsersManagementEffects {
	constructor(@Inject(ENVIRONMENT) private _environment: any, private actions$: Actions, private _http: HttpClient, private _store: Store) {}

	getSPUsersList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SPUsersManagementActions.GET_SP_USERS_LIST),
			switchMap(() => {
				return this._http.post(`${this._environment.api_url}v1/sp_action/manage_list_staff/`, { role_id: 10, image: true });
			}),
			map(data => {
				this._store.dispatch(new SPUsersManagementActions.UsersLoaded(true));
				return new SPUsersManagementActions.GetSPUsersListSuccess(data);
			}),
			catchError(error => of(new SPUsersManagementActions.GetSPUsersListFail(error)))
		)
	);
}
