import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as fromActions from '../actions/theme.actions';
import { map, tap } from 'rxjs/operators';
import { ThemeService } from '../../theming/theme.service';

@Injectable()
export class ThemeEffects {
	constructor(private actions$: Actions, private _theme: ThemeService) {}

	SetTheme$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.ThemeActionTypes.SET_THEME),
				tap((action: fromActions.SetTheme) => this._theme.setTheme(action.payload.name))
			),
		{ dispatch: false }
	);
}
