import { createAction, props } from '@ngrx/store';
import { Reminder } from '@flexus/models';

export const getReminders = createAction('[REMINDERS] GET REMINDERS');
export const getRemindersSuccess = createAction('[REMINDERS] GET REMINDERS SUCCESS', props<{ reminders: Reminder[] }>());
export const getRemindersFail = createAction('[REMINDERS] GET REMINDERS FAIL', props<{ errors: any }>());

export const addReminder = createAction('[REMINDERS] ADD REMINDER', props<{ reminder: Reminder }>());

export const updateReminder = createAction('[REMINDERS] UPDATE REMINDER', props<{ reminder: Reminder }>());

export const deleteReminder = createAction('[REMINDERS] DELETE REMINDER', props<{ id: string }>());

export const showNotification = createAction('[REMINDERS] SHOW NOTIFICATION', props<{ reminder: Reminder }>());

export const clearReminders = createAction('[REMINDERS] CLEAR REMINDERS');

// Deal with currently active reminders
export const getActiveReminders = createAction('[REMINDERS] GET ACTIVE REMINDERS');
export const getActiveRemindersSuccess = createAction('[REMINDERS] GET ACTIVE REMINDERS SUCCESS', props<{ reminders: Reminder[] }>());

export const addActiveReminder = createAction('[REMINDERS] ADD ACTIVE REMINDER', props<{ reminder: Reminder }>());

export const deleteActiveReminder = createAction('[REMINDERS] DELETE ACTIVE REMINDER', props<{ id: string }>());
