import { Flow_0_0_2 } from '@flexus/core';
import { MUL_183 } from './MUL_183';
import { MUL_180 } from './MUL_180';
import { photoNode } from '../reusable/PHOTO_NODE';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { REWORK_NODE } from '../reusable/REWORK_NODE';
import { requestQANode } from '../reusable/REQUEST_QA_NODE';
import { resolvedProblemNode } from '../reusable/RESOLVED_PROBLEM_NODE';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { GENERIC_BFTSMAPPER } from '../reusable/GENERIC_BFTSMAPPER';

export const MUL_185: Flow_0_0_2 = {
	id: '185',
	name: 'problem_raised_by_validation',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Problem Raised By Validation',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Problem Raised By Validation'
		},
		viewRoles: {
			0: 'Problem Raised By Validation'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'jobInformation',
	nodes: {
		jobInformation: {
			showTabs: true,
			...jobInfoNode(185)
		},
		saptal: {
			...MUL_180?.nodes?.satpal
		},
		photos: {
			...photoNode()
		},
		requestQA: {
			...requestQANode()
		},
		resolvedProblem: {
			...resolvedProblemNode()
		},
		rework: {
			...REWORK_NODE
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		...GENERIC_BFTSMAPPER
	}
};
