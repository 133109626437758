<div class="container">
	<form *ngIf="bf.bigForm" [formGroup]="bf.bigForm">
		<div class="refund-calculator" formGroupName="amounts">
			<div class="heading"><flx-heading size="medium" color="default" align="center">Refund Allocation</flx-heading></div>
			<div *ngIf="subheading">
				<flx-heading size="extra-small" weight="light" color="secondary" align="center">{{ subheading }}</flx-heading>
			</div>
			<!-- <div class="calculator-screen"> -->

			<div
				[ngClass]="{
					'calculator-screen': isAuthorise === true,
					'calculator-screen-minimis': isAuthorise === false
				}"
			>
				<div class="variable-heading-row">
					<div class="spacer"></div>
					<div class="screen-heading" *ngIf="isAuthorise">Installer payment</div>
					<div class="screen-heading">Customer Refund</div>
				</div>
				<div class="bottom-glow-line" style="margin-top: -1px !important"><flx-glow-line></flx-glow-line></div>
				<div class="voucher-amount-row">
					<div>
						<div style="font-weight: 600">Voucher amount</div>
						<div style="font-size: smaller">Excl. VAT</div>
					</div>
					<div style="margin-right: 65px; font-weight: 600">{{ voucheramount | currency: 'R' }}</div>
				</div>
				<div class="dynamic-breakdown-rows">
					<div class="descriptors">
						<div class="descriptor">Partner Margin</div>
						<div>
							<div speechBubble="This is the fee our partner charges for the sale of the installation voucher." style="padding: 2px 0 0 5px; width: 15px; height: 15px">
								<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5.61852 10V5.62165H6.27527V10H5.61852ZM5.61852 4.63214V3.87031H6.27527V4.63214H5.61852Z" fill="#A5A5A5" />
									<path
										d="M11.6755 7.33784C11.6755 10.562 9.06188 13.1757 5.83777 13.1757C2.61366 13.1757 0 10.562 0 7.33784C0 4.11369 2.61366 1.5 5.83777 1.5C9.06188 1.5 11.6755 4.11369 11.6755 7.33784ZM0.70857 7.33784C0.70857 10.1706 3.00499 12.4671 5.83777 12.4671C8.67055 12.4671 10.967 10.1706 10.967 7.33784C10.967 4.50503 8.67055 2.20858 5.83777 2.20858C3.00499 2.20858 0.70857 4.50503 0.70857 7.33784Z"
										fill="#A5A5A5"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div class="does-not-apply">Does not apply</div>
					<div class="i-breakdown-panel">
						<div class="currency">R</div>
						<div class="input-text">
							<flx-input-text formControlName="cus_takealot_fee" type="decimal" width="85px" align="right" textAlign="right"></flx-input-text>
						</div>
						<div class="icon"><flx-icon [type]="'remove'" (click)="removeTakealotAmount()"></flx-icon></div>
					</div>
				</div>
				<div class="dynamic-breakdown-rows">
					<div class="descriptors">
						<div class="descriptor">Installer Fee</div>
						<div>
							<div
								speechBubble="This is the base fee the Service Provider receives for the installation."
								style="padding: 2px 0 0 5px"
								style="padding: 2px 0 0 5px; width: 15px; height: 15px"
							>
								<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5.61852 10V5.62165H6.27527V10H5.61852ZM5.61852 4.63214V3.87031H6.27527V4.63214H5.61852Z" fill="#A5A5A5" />
									<path
										d="M11.6755 7.33784C11.6755 10.562 9.06188 13.1757 5.83777 13.1757C2.61366 13.1757 0 10.562 0 7.33784C0 4.11369 2.61366 1.5 5.83777 1.5C9.06188 1.5 11.6755 4.11369 11.6755 7.33784ZM0.70857 7.33784C0.70857 10.1706 3.00499 12.4671 5.83777 12.4671C8.67055 12.4671 10.967 10.1706 10.967 7.33784C10.967 4.50503 8.67055 2.20858 5.83777 2.20858C3.00499 2.20858 0.70857 4.50503 0.70857 7.33784Z"
										fill="#A5A5A5"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div class="i-breakdown-panel">
						<div class="currency">R</div>
						<div class="input-text"><flx-input-text formControlName="ins_installer_fee" type="decimal" width="85px" textAlign="right"></flx-input-text></div>
						<div class="icon"><flx-icon [type]="'remove'" (click)="removeInstallerAmountFromInstallerColumn()"></flx-icon></div>
					</div>
					<div class="i-breakdown-panel">
						<div class="currency">R</div>
						<div class="input-text" style="text-align: right">
							<flx-input-text formControlName="cus_installer_fee" type="decimal" width="85px" align="right" textAlign="right"></flx-input-text>
						</div>
						<div class="icon"><flx-icon [type]="'remove'" (click)="removeInstallerFeeFromCustomerColumn()"></flx-icon></div>
					</div>
				</div>
				<div class="dynamic-breakdown-rows">
					<div class="descriptors">
						<div class="descriptor">Installer Incentive</div>
						<div>
							<div
								speechBubble="This is an incentive requested by the claim handler during manual allocation. Pre-job SP Extras Fee."
								style="padding: 2px 0 0 5px"
								style="padding: 2px 0 0 5px; width: 15px; height: 15px"
							>
								<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5.61852 10V5.62165H6.27527V10H5.61852ZM5.61852 4.63214V3.87031H6.27527V4.63214H5.61852Z" fill="#A5A5A5" />
									<path
										d="M11.6755 7.33784C11.6755 10.562 9.06188 13.1757 5.83777 13.1757C2.61366 13.1757 0 10.562 0 7.33784C0 4.11369 2.61366 1.5 5.83777 1.5C9.06188 1.5 11.6755 4.11369 11.6755 7.33784ZM0.70857 7.33784C0.70857 10.1706 3.00499 12.4671 5.83777 12.4671C8.67055 12.4671 10.967 10.1706 10.967 7.33784C10.967 4.50503 8.67055 2.20858 5.83777 2.20858C3.00499 2.20858 0.70857 4.50503 0.70857 7.33784Z"
										fill="#A5A5A5"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div class="i-breakdown-panel">
						<div class="currency">R</div>
						<div class="input-text"><flx-input-text formControlName="ins_installer_incentive" type="decimal" width="85px" textAlign="right"></flx-input-text></div>
						<div class="icon"><flx-icon [type]="'remove'" (click)="removeIncentiveAmountFromInstallerColumn()"></flx-icon></div>
					</div>
					<div class="i-breakdown-panel">
						<div class="currency">R</div>
						<div class="input-text"><flx-input-text formControlName="cus_installer_incentive" type="currency" width="85px" textAlign="right"></flx-input-text></div>
						<div class="icon"><flx-icon [type]="'remove'" (click)="removeIncentiveAmountFromCustomerColumn()"></flx-icon></div>
					</div>
				</div>
				<div class="dynamic-breakdown-rows">
					<div class="descriptors">
						<div class="descriptor">Installer Additional</div>
						<div>
							<div
								speechBubble="This is the additional fees that the Service Provider deemed necessary. Post-job SP Extras Fee."
								style="padding: 2px 0 0 5px"
								style="padding: 2px 0 0 5px; width: 15px; height: 15px"
							>
								<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5.61852 10V5.62165H6.27527V10H5.61852ZM5.61852 4.63214V3.87031H6.27527V4.63214H5.61852Z" fill="#A5A5A5" />
									<path
										d="M11.6755 7.33784C11.6755 10.562 9.06188 13.1757 5.83777 13.1757C2.61366 13.1757 0 10.562 0 7.33784C0 4.11369 2.61366 1.5 5.83777 1.5C9.06188 1.5 11.6755 4.11369 11.6755 7.33784ZM0.70857 7.33784C0.70857 10.1706 3.00499 12.4671 5.83777 12.4671C8.67055 12.4671 10.967 10.1706 10.967 7.33784C10.967 4.50503 8.67055 2.20858 5.83777 2.20858C3.00499 2.20858 0.70857 4.50503 0.70857 7.33784Z"
										fill="#A5A5A5"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div class="i-breakdown-panel">
						<div class="currency">R</div>
						<div class="input-text"><flx-input-text formControlName="ins_installer_additional" type="decimal" width="85px" textAlign="right"></flx-input-text></div>
						<div class="icon"><flx-icon [type]="'remove'" (click)="removeIncentiveAmountFromInstallerColumn()"></flx-icon></div>
					</div>
					<div class="i-breakdown-panel">
						<div class="currency">R</div>
						<div class="input-text"><flx-input-text formControlName="cus_installer_additional" type="currency" width="85px" textAlign="right"></flx-input-text></div>
						<div class="icon"><flx-icon [type]="'remove'" (click)="removeIncentiveAmountFromCustomerColumn()"></flx-icon></div>
					</div>
				</div>
				<div class="dynamic-breakdown-rows">
					<div class="descriptors">
						<div class="descriptor">4-Sure</div>
						<div>
							<div
								speechBubble="This is the fee 4-Sure charges for facilitating the installation."
								style="padding: 2px 0 0 5px"
								style="padding: 2px 0 0 5px; width: 15px; height: 15px"
							>
								<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5.61852 10V5.62165H6.27527V10H5.61852ZM5.61852 4.63214V3.87031H6.27527V4.63214H5.61852Z" fill="#A5A5A5" />
									<path
										d="M11.6755 7.33784C11.6755 10.562 9.06188 13.1757 5.83777 13.1757C2.61366 13.1757 0 10.562 0 7.33784C0 4.11369 2.61366 1.5 5.83777 1.5C9.06188 1.5 11.6755 4.11369 11.6755 7.33784ZM0.70857 7.33784C0.70857 10.1706 3.00499 12.4671 5.83777 12.4671C8.67055 12.4671 10.967 10.1706 10.967 7.33784C10.967 4.50503 8.67055 2.20858 5.83777 2.20858C3.00499 2.20858 0.70857 4.50503 0.70857 7.33784Z"
										fill="#A5A5A5"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div class="does-not-apply">Does not apply</div>
					<div class="i-breakdown-panel">
						<div class="currency">R</div>
						<div class="input-text"><flx-input-text formControlName="cus_sure_fee" type="currency" width="85px" textAlign="right"></flx-input-text></div>
						<div class="icon"><flx-icon [type]="'remove'"></flx-icon></div>
					</div>
				</div>

				<div class="bottom-glow-line"><flx-glow-line></flx-glow-line></div>
				<div class="total-summary">
					<div class="subtotal-row">
						<div class="spacer"></div>
						<div *ngIf="isAuthorise">
							<div class="r-breakdown-panel" style="margin: 2px 23px 0 0">
								<div class="summary-descriptor" style="padding-top: 4px">Installer Subtotal</div>
								<div class="summary-amount" style="padding-top: 4 px">{{ payment_to_installer_ex_vat | currency: 'R' }}</div>
							</div>
						</div>
						<div class="center-descriptors">Sub Total</div>
						<div class="refund-due">
							<div class="r-breakdown-panel">
								<div class="summary-descriptor">Customer Subtotal</div>
								<div class="summary-amount">{{ payment_to_customer_ex_vat | currency: 'R' }}</div>
							</div>
						</div>
					</div>
					<div class="subtotal-row">
						<div class="spacer"></div>
						<div *ngIf="isAuthorise">
							<div class="r-breakdown-panel" style="margin: 2px 23px 0 0">
								<div class="summary-descriptor" style="padding-top: 4px">VAT Amount - Registered</div>
								<div class="summary-amount" style="padding-top: 4 px">{{ vat_for_display_sp | currency: 'R' }}</div>
							</div>
						</div>
						<div class="center-descriptors">VAT</div>
						<div class="refund-due">
							<div class="r-breakdown-panel">
								<div class="summary-descriptor">VAT Amount</div>
								<div class="summary-amount">{{ vat_for_display_customer | currency: 'R' }}</div>
							</div>
						</div>
					</div>
					<div class="subtotal-row">
						<div class="spacer"></div>
						<div *ngIf="isAuthorise">
							<div class="r-breakdown-panel" style="margin: 2px 23px 0 0">
								<div class="summary-descriptor" style="padding-top: 4px">Installer Total Due</div>
								<div class="summary-amount" style="padding-top: 4 px">{{ installerpayment | currency: 'R' }}</div>
							</div>
						</div>
						<div class="center-descriptors">Total</div>
						<div class="refund-due">
							<div class="r-breakdown-panel">
								<div class="summary-descriptor boldprimary">Customer Amount Due</div>
								<div class="summary-amount boldprimary">{{ refund_amount_due | currency: 'R' }}</div>
							</div>
						</div>
					</div>
					<!-- <div class="vat-row">vat</div> -->
					<!-- <div class="totals-due-row">totals</div> -->
					<div class="full-subtotal-row">
						<div class="4-sure-net-result">4-Sure Net Result Incl VAT</div>
						<div class="4-sure-net-result">{{ netresult | currency: 'R' }}</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
<!-- <div style="width: 500px; margin: 0 auto; background-color: aquamarine"><flx-input-text type="number" width="85px"></flx-input-text></div> -->
<!-- <div class="dynamic-summary-columns">
	<div class="spacer"></div>
	<div *ngIf="isAuthorise">
		<div class="r-breakdown-panel" style="margin: 2px 23px 0 0">
			<div class="summary-descriptor" style="padding-top: 4px">Installer payment due</div>
			<div class="summary-amount" style="padding-top: 4 px">{{ installerpayment | currency: 'R' }}</div>
		</div>
	</div>
	<div class="refund-due">
		<div class="r-breakdown-panel">
			<div class="summary-descriptor boldprimary">Refund amount due</div>
			<div class="summary-amount boldprimary">{{ refund_amount_due | currency: 'R' }}</div>
		</div>
	</div>
</div>
<div>hello</div>
<div class="sure-net-result">
	<div
		[ngClass]="{
			'r-breakdown-panel-authorise': isAuthorise === true,
			'r-breakdown-panel': isAuthorise === false
		}"
	>
		<div class="summary-descriptor">4-Sure Profit/Loss</div>
		<div class="summary-amount">{{ netresult | currency: 'R' }}</div>
	</div>
</div>
-->
<!--

<div class="dynamic-breakdown-columns">
					<div class="descriptors">
						<div class="descriptor">Partner Margin</div>
						<div class="descriptor">Approved SP Job Fee</div>
						<div class="descriptor">Pre-job SP Extras Fee</div>
						<div class="descriptor">Post-job SP Extras Fee</div>
						<div class="descriptor">4-Sure</div>
					</div>

					<div class="installer-payment-breakdown" *ngIf="isAuthorise">
						<div class="does-not-apply">Does not apply</div>
						<div class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text"><flx-input-text formControlName="ins_installer_fee" type="decimal" width="85px" textAlign="right"></flx-input-text></div>
							<div class="icon"><flx-icon [type]="'remove'" (click)="removeInstallerAmountFromInstallerColumn()"></flx-icon></div>
						</div>
						<div class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text"><flx-input-text formControlName="ins_installer_incentive" type="decimal" width="85px" textAlign="right"></flx-input-text></div>
							<div class="icon"><flx-icon [type]="'remove'" (click)="removeIncentiveAmountFromInstallerColumn()"></flx-icon></div>
						</div>
						<div class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text">
								<flx-input-text formControlName="ins_installer_additional" type="currency" width="85px" textAlign="right"></flx-input-text>
							</div>
							<div class="icon"><flx-icon [type]="'remove'" (click)="removeAdditonalAmountFromInstallerColumn()"></flx-icon></div>
						</div>
						<div class="does-not-apply">Does not apply</div>
					</div>
					<div class="customer-refund-breakdown">
						<div class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text">
								<flx-input-text formControlName="cus_takealot_fee" type="decimal" width="85px" align="right" textAlign="right"></flx-input-text>
							</div>
							<div class="icon"><flx-icon [type]="'remove'" (click)="removeTakealotAmount()"></flx-icon></div>
						</div>
						<div class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text" style="text-align: right">
								<flx-input-text formControlName="cus_installer_fee" type="decimal" width="85px" align="right" textAlign="right"></flx-input-text>
							</div>
							<div class="icon"><flx-icon [type]="'remove'" (click)="removeInstallerFeeFromCustomerColumn()"></flx-icon></div>
						</div>
						<div class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text"><flx-input-text formControlName="cus_installer_incentive" type="currency" width="85px" textAlign="right"></flx-input-text></div>
							<div class="icon"><flx-icon [type]="'remove'" (click)="removeIncentiveAmountFromCustomerColumn()"></flx-icon></div>
						</div>
						<div class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text"><flx-input-text formControlName="cus_installer_additional" type="currency" width="85px" textAlign="right"></flx-input-text></div>
							<div class="icon"><flx-icon [type]="'remove'" (click)="removeAdditonalAmountFromCustomerColumn()"></flx-icon></div>
						</div>
						<div class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text"><flx-input-text formControlName="cus_sure_fee" type="currency" width="85px" textAlign="right"></flx-input-text></div>
							<div class="icon"><flx-icon [type]="'remove'"></flx-icon></div>
						</div>
					</div>
				</div>

-->
