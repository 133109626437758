import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { skipWhile, map, take, pluck } from 'rxjs/operators';
import { Validators } from '@angular/forms';

export const BET_SP_289: Flow_0_0_2 = {
	id: '289',
	name: 'repudiation_review_query',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) =>
			store.select(getFullItemTwo).pipe(
				skipWhile((itemTwo: any) => !itemTwo),
				take(1),
				map((itemTwo: any) => {
					if (itemTwo) {
						return `Repudiation Review Query : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Repudiation Review Query';
					}
				})
			),
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		claimDetails: {
			errorMessage: 'Could get assessor details',
			serviceVariable: 'betService',
			functionName: 'getClaimDetailsFormatted'
		},
		assessorDetails: {
			errorMessage: 'Could get assessor details',
			serviceVariable: 'betService',
			functionName: 'getAssessorDetailsformatted'
		},
		assessorSummary: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					pluck('job_information', 'da_query'),
					map((da_query: any) => {
						if (da_query) {
							const query = !Array.isArray(da_query) ? [{ ...da_query }] : [...da_query];
							return {
								important_information: {
									"Assessor's Query": query[query.length - 1]?.query
								}
							};
						}
					})
				);
			}
		}
	},

	instructions: {
		editRoles: {
			0: 'Reply to desktop Assessor Query'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'ReviewAssessorQuery',
	nodes: {
		ReviewAssessorQuery: {
			name: 'Assessor Details',
			component: {
				children: [
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							headingConfig: { title: 'Assessor Summary', color: 'default', size: 'medium' },
							subHeadingConfig: { size: 'extra-small', weight: 'normal', color: 'secondary' },
							keyValueHeadingConfig: { color: 'secondary' },
							objectInfo$: 'assessorSummary'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'claimDetails'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'assessorDetails'
						}
					}
				]
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'QueryResponse',
					color: 'primary'
				}
			]
		},
		QueryResponse: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Respond to Assessor'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: "Please respond to the assessor's query",
									inputType: 'textarea',
									formControlName: 'da_query_response'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.patchValues({ da_query_response: bf.getControl('da_query_response')?.value || '', new_state: '290' });
				bf.bigForm.get('da_query_response')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			checkValidityForFields: ['da_query_response'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!'
						}
					},
					color: 'primary'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		da_query_response: [
			(qr, storeObj) => {
				let daq = [];
				const FI2 = storeObj['selectedContext']?.fullItemTwo;
				if (FI2?.job_information?.da_query) {
					if (Array.isArray(FI2?.job_information?.da_query)) {
						daq = FI2?.job_information?.da_query.map(_daQuery => ({ ..._daQuery }));
					} else {
						daq = [{ ...(<object>FI2?.job_information?.da_query) }];
					}
					if (qr) daq[daq.length - 1].queryreply = qr;
				}
				return daq;
			},
			'job_information.da_query'
		]
	}
};
