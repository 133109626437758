<div class="line-items-table">
	<div class="line-items-table__heading">
		<div class="line-items-table__heading__item line-items-table__heading__item--description">Description</div>
		<div class="line-items-table__heading__item">Quantity</div>
		<div class="line-items-table__heading__item">Unit Price</div>
		<div class="line-items-table__heading__item">Total</div>
	</div>
	<ng-container>
		<div class="line-items-table__item">
			<!-- <ng-container *ngFor="let lineItemFormGroup of actualLineItemsFormArray.controls; let index = index">
				<flx-line-item-display *ngIf="!lineItemFormGroup.value.can_edit" [icons]="getLineItemIcons(lineItemFormGroup.value)" [lineItemFormGroup]="$any(lineItemFormGroup)"></flx-line-item-display>
			</ng-container> -->
			<!-- edit -->
			<ng-container *ngFor="let lineItemFormGroup of actualLineItemsFormArray.controls; let i = index">
				<flx-line-item-modify
					[icons]="getLineItemIcons(lineItemFormGroup.value)"
					[lineItemFormGroup]="convertControlToFormGroup(lineItemFormGroup)"
					[availableLineItems]="filterAvailableLineItems()"
					[actualLineItemsFormArray]="actualLineItemsFormArray"
					(removeItem)="removeLineItem(i)"
					(startCreateCustomItem)="getCustomLineData()"
					(startUpdateCustomItem)="getUpdateCustomLineData($event)"
				></flx-line-item-modify>
			</ng-container>
		</div>
		<div *ngIf="availableLineItems.length > 0; else loader" class="line-items-table__item">
			<flx-line-item-generator
				*ngIf="showLineItemGenerator"
				[availableLineItems]="filterAvailableLineItems()"
				[actualLineItemsFormArray]="actualLineItemsFormArray"
				[allow_custom_items]="allow_custom_items"
				(startCreateCustomItem)="getCustomLineData()"
				(startUpdateCustomItem)="getUpdateCustomLineData($event)"
			></flx-line-item-generator>
		</div>
	</ng-container>
	<flx-line-item-add-edit
		*ngIf="modalOpen"
		(modificationComplete)="boqModifyCall($event)"
		[modificationType]="modificationType"
		[editItem]="itemToEdit"
		[clientName]="clientName"
	></flx-line-item-add-edit>
</div>

<ng-template #loader>
	<flx-loader-component></flx-loader-component>
</ng-template>

<!--Confirm Change Excess Modal-->
<flx-modal #changeExcessModal [displayHeading]="false" [navButtons]="excessChangeModalButtons" [backgroundClose]="false" type="info" class="modal fade">
	<flx-heading size="large" color="primary" align="center"> <span class="add-rem-heading">Warning excess change</span> </flx-heading>
	<p style="text-align: center" [innerHtml]="excessChangeModalStatement"></p>
</flx-modal>

<!--Confirm Travel Costs Modal-->
<flx-modal #travelCostsModal [displayHeading]="false" [navButtons]="modalButtons" [backgroundClose]="false" type="info" class="modal fade">
	<flx-heading size="large" color="primary" align="center"> <span class="add-rem-heading">Charge for travel costs?</span> </flx-heading>

	<p style="text-align: center">
		This job is eligible to charge travel costs for {{ maximumTravelDistance }}km. <br />
		<br />
		Do you want to include travel costs on this {{ isInvoiceOnlyState ? 'invoice' : 'quotation' }}
	</p>
</flx-modal>
