import { UntypedFormControl, Validators } from '@angular/forms';
import { filter, map, mergeMap, skipWhile, switchMap, take } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { EMPTY, forkJoin } from 'rxjs';
import gql from 'graphql-tag';
import { Flow_0_0_2 } from '@flexus/core';


const rolesKVP = {
	claim_handler: 1,
	insurer_assessor: 2,
	insurer_field_agent_sil: 3,
	insurer_field_agent_sp: 4,
	disbursement_officer: 5,
	frontline_manager: 6,
	central_management: 7,
	administrator: 8,
	sp_job_scheduler: 9,
	sp_team_leader: 10,
	payments_and_ratings: 11,
	supply_chain_manager: 12,
	loss_adjuster_tl: 13,
	verification_officer: 14,
	external_claim_handler: 18,
	authoriser: 34,
	dashboard: 35,
	dashboard_super_user: [false]
};

const transformRoles = (roles: any) => {
	const rs = roles ? Object.entries(roles).reduce((acc, [k, v]) => (v === true ? [...acc, rolesKVP[k]] : acc), []) : [];
	return rs;
};

const transformRequest = (vals: any) => {
	const { roles, ...rest } = vals;
	const rs = Object.entries(roles).reduce((acc, [k, v]) => (v === true ? [...acc, rolesKVP[k]] : acc), []);
	const full_name = `${rest?.first_name} ${rest?.last_name}`;
	return { ...rest, full_name, roles: rs };
}


export const SP_GLOBAL_ADD_USER: Flow_0_0_2 = {
	id: 'add_user',
	name: 'Add User',
	itemType: 'flow',
	header: {
		title: 'Add User',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},

	startNode: 'add_user',
	nodes: {
		add_user: {
			component: 'AddUserComponent',
			name: 'Add User',
			navs: [
				{
					text: 'Register',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							errorMessage: 'User could not be created!',
							directCall: (http, store, sq, bf) => {
								if (bf.bigForm.value) {
									const data = transformRequest(bf.bigForm.value);
									return http.post(`${environment.api_url}v1/staff_register/staff_register/`, data);
								} else {
									return EMPTY;
								}
								// return store.select(getSubmissionData)?.pipe(
								// 	skipWhile(x => !x),
								// 	take(1),
								// )?.pipe(
								// 	switchMap(data => http.post(`${environment.api_url}v1/staff_register/staff_register/`, data))
								// );
							}
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		username: 'username',
		first_name: 'first_name',
		last_name: 'last_name',
		password: 'password',
		contact_number: 'contact_number',
		email_address: 'email_address',
		roles: [transformRoles, 'roles'],
	}
};
