import { NgModule } from '@angular/core';
import { FLXAPJobSummaryComponent } from './job-summary.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UIElementsModule } from '@flexus/ui-elements';
import { UICompositesModule } from '@flexus/ui-composites';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { PipesModule } from '@flexus/pipes';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [FLXAPJobSummaryComponent],
	exports: [FLXAPJobSummaryComponent],
	imports: [CommonModule, ReactiveFormsModule, UIElementsModule, UICompositesModule, NgSlimScrollModule, PipesModule]
})
export class JobSummaryModule {}
