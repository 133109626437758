import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { getIdentitySubmitting, getLoginError, IndexedDbService, Login, NetworkService, ResetForgotPassword } from '@flexus/core';
import { getErrors, RemoveError } from '@flexus/error-handler';
import { CustomValidators } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import { environment } from 'apps/studio/src/environments/environment';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
	selector: 'local-login',
	templateUrl: './local-login.component.html',
	styleUrls: ['local-login.component.scss']
})
export class LocalLoginComponent implements OnInit, OnDestroy {
	loginForm: UntypedFormGroup;
	isOnline = true;
	formSub: Subscription;
	networkSub: Subscription;
	error$: Observable<any>;
	@Input() hideFormInitially: boolean;

	isSubmitting$: Observable<boolean>;
	constructor(
		private _fb: UntypedFormBuilder,
		private _store: Store<any>,
		private route: ActivatedRoute,
		private _router: Router,
		private networkService: NetworkService,
		private indexedDbService: IndexedDbService
	) {}
	ngOnInit() {
		this.isSubmitting$ = this._store.select(getIdentitySubmitting);
		this.error$ = this._store.select(getLoginError);
		this.loginForm = this._fb.group({
			email: ['', [Validators.required, CustomValidators.cleanOnEmpty]],
			password: ['', [Validators.required, CustomValidators.cleanOnEmpty]]
		});
		this.networkCheck();
	}

	login() {
		this._store.dispatch(new Login({ ...this.loginForm.value, client_version: environment.version }));
	}

	showLoginForm() {
		this.hideFormInitially = false;
	}

	goToForgotPassword() {
		this._store.dispatch(new ResetForgotPassword());
		this._router.navigate(['/auth/forgot-password']);
	}

	removeErrors() {
		// Remove errors from the error stack so that they dont randomly pop up
		this._store
			.select(getErrors)
			.pipe(
				filter(x => !!x),
				take(1)
			)
			.subscribe(errors => {
				const keys = Object.keys(errors);
				keys.forEach(element => {
					this._store.dispatch(new RemoveError({ dataKey: element }));
				});
			});
	}

	networkCheck() {
		this.networkSub = this.networkService.isOnline.subscribe(status => {
			this.isOnline = status;
		});
	}

	ngOnDestroy() {
		if (this.formSub) this.formSub.unsubscribe();
		if (this.networkSub) this.networkSub.unsubscribe();
		this.removeErrors();
	}
}
