export function removeOnKeyMatchFromArray(removeArr: any[], fromArr: any[], key: any) {
	let newArr = fromArr;
	removeArr.forEach(val => {
		const index = fromArr.findIndex(item => item[key] === val);
		if (index !== -1) {
			newArr.splice(index, 1);
		}
	});

	return fromArr;
}
