<ng-container>
	<ng-container *ngIf="theList$ | async as claims">
		<ng-container *ngIf="claims?.length >= 1; else noResults">
			<div *ngFor="let claim of claims | paginate: { itemsPerPage: pageSize, currentPage: currentPage, id: 'list' }; index as i; trackBy: trackByFunc">
				<!-- CARD -->
				<ng-container *ngFor="let job of claim?.jobs">
					<flx-amp-list-claim-card
						[claim]="claim"
						[job]="job"
						[itemOneContextMenuList]="itemOneContextMenuList"
						[itemTwoContextMenuList]="itemTwoContextMenuList"
						[itemOnePermissions]="itemOnePermissions"
						[itemTwoPermissions]="itemTwoPermissions"
						[allInfo]="allInfo$ | async"
						[activeOrg]="activeOrg$ | async"
						[user]="user$ | async"
						[isOnline]="isOnline$ | async"
					>
					</flx-amp-list-claim-card>
				</ng-container>

				<!-- CARD END -->
			</div>
		</ng-container>
	</ng-container>
</ng-container>
<div class="center-load">
	<ng-template #noResults>
		<flx-no-results [message]="'No results returned : please try a different filter or refresh the data.'"> </flx-no-results>
	</ng-template>
</div>
