import { Flow_0_0_2, getData, getFullItemOne, getCurrentUser } from '@flexus/core';
import { map, take, filter, skipWhile, switchMap } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { getFullItemTwo } from '@flexus/core';
import { forkJoin, EMPTY } from 'rxjs';
import gql from 'graphql-tag';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { BET_27 } from './BET_27';
import { environment } from 'apps/studio/src/environments/environment';

export const BET_49: Flow_0_0_2 = {
	id: '49',
	name: 'invoice_query_update_invalid_claim',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: store => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => !itemOne),
				map((itemOne: any) => `Invoice Updated - Invalid Claim : ${itemOne?.mid} - ${itemOne?.applicant?.surname}`)
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Review the invoice query'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	events: {
		invalidateQueryDataIfVatRateIsSet: {
			triggerOn: 'vat_rate',
			triggerWhen: rate => !!rate,
			dataMutations: bf => {
				bf.patchValues({
					query: null,
					new_state: 50
				});
			}
		},
		invalidatePaymentDataIfQueryIsSet: {
			triggerOn: 'query',
			triggerWhen: qr => !!qr,
			dataMutations: bf => {
				bf.patchValues({
					claim_value: null,
					update_excess: null,
					invoice_number: null,
					vat_rate: null,
					new_state: 47
				});
			}
		}
	},
	serverCalls: {
		invoice: {
			serviceVariable: 'betService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		dataFiles: {
			errorMessage: 'No invoice found!',
			directCall: (_h, store, sq) =>
				sq
					.queryObject(
						gql`
							{
								invoice {
									data
								}
							}
						`,
						store.select(getData)
					)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1),
						map(data => data['data'])
					)
		}
	},
	startNode: 'PaymentPreview',
	nodes: {
		PaymentPreview: {
			inputs: { inputHeading: 'Payment Preview' },
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'keyValueList2',
							numbering: true,
							heading: 'Query History'
						}
					}
				]
			},
			serverCalls: {
				...BET_27?.nodes?.PaymentPreview?.serverCalls,
				keyValueList2: {
					errorMessage: '',
					directCall: (_h, store) =>
						store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							map(job =>
								job?.job_information?.invoicequery && Array.isArray(job?.job_information?.invoicequery)
									? job?.job_information?.invoicequery
									: job?.job_information?.invoicequery
									? [job?.job_information?.invoicequery]
									: []
							)
						)
				}
			},
			initFormFields: (bf, _i, _in, _s, store) => {
				forkJoin([
					store.select(getFullItemOne).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getCurrentUser).pipe(
						skipWhile(x => !x),
						take(1)
					)
				])
					.pipe(take(1))
					.subscribe(([claim, user]) => {
						bf.patchValues({
							paymentapprovalhandler: claim?.application_creator?.id ?? '',
							paymentapproval1: user?.user?.id
						});
					});
			},
			navs: [
				{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'secondary' },
				{ text: 'Approve Invoice', nextNode: 'PaymentConfirm', color: 'primary' }
			]
		},
		PaymentConfirm: {
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'claim_value',
									inputType: 'currency',
									label: 'Invoice amount to be paid'
								},
								1: {
									formControlName: 'invoice_number',
									inputType: 'input',
									label: 'Confirm invoice number'
								},
								2: {
									formControlName: 'update_excess',
									inputType: 'currency',
									label: 'Enter excess amount'
								}
							},
							formLayout: 'three-column',
							containerWidth: '50vw'
						}
					}
				]
			},
			initFormFields: (bf, _i, _in, sq) => {
				bf.addControl('new_state', new UntypedFormControl(50));
				bf.addControl('vat_rate', new UntypedFormControl(15));
				bf.patchValues({
					update_excess: '',
					invoice_number: '',
					claim_value: ''
				});
				bf.bigForm.get('claim_value')?.setValidators([Validators.required]);
				bf.bigForm.get('update_excess')?.setValidators([Validators.required]);
				bf.bigForm.get('invoice_number')?.setValidators([Validators.required]);

				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(values => {
						bf.patchValues({
							current_state: values.state
						});
					});
			},
			navs: [{ text: 'Approve', nextNode: 'AuthorizerSelect', color: 'primary', optIntoValidation: true }]
		},
		QueryInvoice: {
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'query',
									inputType: 'textarea',
									label: 'Query Invoice',
									maxWidth: '50vw'
								}
							}
						}
					}
				]
			},
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			inputs: {
				inputHeading: 'Query Invoice'
			},
			checkValidityForFields: ['query'],
			initFormFields: (bf, _i, _in, sq) => {
				bf.patchValues({ new_state: 47, query: '' });
				bf.bigForm.get('query')?.setValidators([Validators.required, Validators.minLength(3)]);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(values => {
						bf.patchValues({ current_state: values.state });
					});
			},
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		AuthorizerSelect: {
			component: 'FLXAuthoriserSelectComponent',
			serverCalls: {
				authorisers: {
					errorMessage: 'No authorizers could be found!',
					directCall: (http, store, _s, bf) => {
						const claimVal = bf.bigForm.get('claim_value')?.value;
						return store.select(getCurrentUser).pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(curUser =>
								http.get(environment.api_url + 'v1/staff_action/get_authorizers').pipe(
									take(1),
									map(response => response['payload']),
									map(arr => arr.filter(x => x.max_auth >= claimVal && x.id !== curUser?.user.id)),
									map((obj: { [key: string]: { id: number; full_name: string } }) =>
										Object.values(obj).map(entry => ({
											display: entry.full_name,
											value: entry.id
										}))
									)
								)
							)
						);
					}
				}
			},
			inputs: {
				heading: 'Select who will continue the payment process',
				subheading: 'Scroll and select a name or start typing to limit the list',
				canFilter: true,
				maxWidth: '300px'
			},
			initFormFields: bf => {
				bf.patchValues({ nextApprovalStaffName: '' });
				bf.bigForm.get('nextApprovalStaffName')?.setValidators([Validators.required]);
			},
			checkValidityForFields: ['nextApprovalStaffName'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		nextApprovalStaffName: [
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.nextApprovalStaffName'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return null;
				},
				'job_information.authid'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return null;
				},
				'authid'
			]
		],
		paymentapprovalhandler: 'job_information.paymentapprovalhandler',
		paymentapproval1: 'job_information.paymentapproval1',
		current_state: 'current_state',
		new_state: 'new_state',
		vat_rate: 'vat_rate',
		claim_value: ['claim_value', 'job_information.claim_value'],
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		update_excess: ['update_excess', 'job_information.update_excess'],
		query: [
			(qr, storeObj) => {
				let iaq = [];
				if (storeObj['selectedContext']?.fullItemTwo?.job_information && storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery)) {
						iaq = storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery;
					} else {
						iaq = [
							{
								...storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
							}
						];
					}
				}
				const invoicequery = [...iaq, { query: qr, queryreply: '' }];
				return invoicequery;
			},
			'job_information.invoicequery'
		]
	}
};
