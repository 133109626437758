import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BigFormService } from '@flexus/core';
import { Observable } from 'rxjs/internal/Observable';

@Component({
	selector: 'flx-close-claim-general',
	templateUrl: './close-claim-general.component.html',
	styleUrls: ['./close-claim-general.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseClaimGeneralComponent implements OnInit {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() summaryItems: { title: string; isPositiveCheck?: boolean; value$?: Observable<string>; value?: string; formControlName?: string }[];

	constructor(public bf: BigFormService) {}

	ngOnInit() {}
}
