<flx-heading type="page" size="medium">Job Summary</flx-heading>

<ng-container>
	<flx-accordion-template *ngIf="dataJobs$ | async as data">
		<flx-accordion-group *ngFor="let job of allInfoPack" [title]="job.skillsName">
			<flx-heading size="small" color="secondary">Details</flx-heading>
			{{ data?.fullClaim?.loan_information?.claimtype }}<br />
			{{ getDate(job?.appointment?.range_start) }}<br />
			{{ job?.appointmentName }} {{ getTime(job.appointment?.range_start) }}<br />
			{{ job?.state }} : {{ job?.stateName }}<br />
			<flx-heading size="small" color="secondary">SP Details</flx-heading>
			{{ job?.spsName }} <br />
			<flx-heading size="small" color="secondary">Contact Details</flx-heading>

			Onsite Person: {{ data?.fullClaim?.loan_information?.onsiteperson }} <br />
			Contact Number: {{ data?.fullClaim?.loan_information?.contactnumber }}<br />
			Cell Number: {{ data?.fullClaim?.loan_information?.cellnumber }}
		</flx-accordion-group>
	</flx-accordion-template>
</ng-container>
