import { Component, OnInit, Input } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TabsData } from '@flexus/ui-elements';

export interface SilFileSummaryShape {
	claim: number;
	created: string;
	description: unknown;
	filename: string;
	id: number;
	job: number;
	on_maven: boolean;
	purpose: string;
	thumbnail: string; //b64
	token: string;
}

@Component({
	selector: 'flx-view-documents',
	templateUrl: './view-documents.component.html',
	styleUrls: ['./view-documents.component.scss']
})
export class ViewDocumentsComponent implements OnInit {
	// selectedJobId: number;
	@Input() location: string;
	@Input() files$: Observable<SilFileSummaryShape[]>;
	@Input() quotations$: Observable<SilFileSummaryShape[]>;
	@Input() photos$: Observable<SilFileSummaryShape[]>;
	@Input() reports$: Observable<SilFileSummaryShape[]>;
	@Input() latestDocuments$: Observable<SilFileSummaryShape[]>;
	@Input() invoices$: Observable<SilFileSummaryShape[]>;
	@Input() selectDocsToView: string[];
	currentTab$: Observable<any>;
	tabs: TabsData[];
	// tabs: TabsData[] = [
	// 	{
	// 		display: 'Photos',
	// 		targetId: 'photos',
	// 		show: true
	// 	},
	// 	{
	// 		display: 'Quotations',
	// 		targetId: 'quotations',
	// 		show: true
	// 	},
	// 	{
	// 		display: 'Invoices',
	// 		targetId: 'invoices',
	// 		show: true
	// 	},
	// 	{
	// 		display: 'Reports',
	// 		targetId: 'reports',
	// 		show: true
	// 	},
	// 	{
	// 		display: 'Latest Documents',
	// 		targetId: 'latest-documents',
	// 		show: true
	// 	}
	// ];

	constructor() {}

	ngOnInit() {
		if (this.selectDocsToView) {
			const inputArray = this.selectDocsToView;
			const outputArray = [];
			for (let i = 0; i <= inputArray.length - 1; i++) {
				const tabtopush = {
					display: inputArray[i].charAt(0).toUpperCase() + inputArray[i].slice(1),
					targetId: inputArray[i],
					show: true
				};
				outputArray.push(tabtopush);
			}
			this.tabs = outputArray;
		} else {
			this.tabs = [
				{
					display: 'Photos',
					targetId: 'photos',
					show: true
				},
				{
					display: 'Quotations',
					targetId: 'quotations',
					show: true
				},
				{
					display: 'Invoices',
					targetId: 'invoices',
					show: true
				},
				{
					display: 'Reports',
					targetId: 'reports',
					show: true
				},
				{
					display: 'Latest Documents',
					targetId: 'latest-documents',
					show: true
				}
			];
		}
		this.currentTab$ = of('photos');
		// this.invoices$ = this.files$.pipe(
		//   filter(value => Array.isArray(value)),
		//   map((files: Array<SilFileSummaryShape>) => files.filter(entry => entry.purpose.toLowerCase() === 'invoice'))
		// );

		this.reports$ = this.reports$.pipe(
			filter(value => Array.isArray(value)),
			switchMap((reports: Array<SilFileSummaryShape>) => {
				if (reports.length > 0) {
					return of(reports);
				} else {
					return this.files$.pipe(
						filter(value => Array.isArray(value)),
						map((files: Array<SilFileSummaryShape>) =>
							files.filter(entry => entry.purpose.toLowerCase() === 'report').map(report => ({ ...report, filename: `${report.filename}.pdf` }))
						)
					);
				}
			})
		);
	}

	handleTab(tabInfo) {
		this.currentTab$ = of(tabInfo.target);
	}
}
