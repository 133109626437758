import { Directive, Input, HostBinding, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
	selector: '[speechBubble]'
})
export class SpeechBubbleDirective {
	@Input() speechBubble: string = '';
	private bubble: HTMLDivElement | null = null;
	// @HostBinding('style.position') position: string = 'relative';
	// @HostBinding('style.display') display: string = 'inline-block';

	constructor(private el: ElementRef, private _rend: Renderer2) {}

	@HostListener('mouseenter')
	onMouseEnter() {
		this.createSpeechBubble();
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.removeSpeechBubble();
	}
	private createSpeechBubble() {
		if (!this.bubble) {
			this.bubble = this._rend.createElement('div');
			this._rend.addClass(this.bubble, 'speech-bubble');
			this._rend.appendChild(this.bubble, this._rend.createText(this.speechBubble));
			this._rend.appendChild(this.el.nativeElement, this.bubble);
			//   const bubble = document.createElement('div');
			// bubble.className = 'speech-bubble';
			// bubble.textContent = this.text;
			// this.el.nativeElement.appendChild(bubble);
		}
	}

	private removeSpeechBubble() {
		// const bubble = this.el.nativeElement.querySelector('.speech-bubble');
		if (this.bubble) {
			this._rend.removeChild(this.el.nativeElement, this.bubble);
			this.bubble = null;
			// this.el.nativeElement.removeChild(bubble);
		}
	}
}
