import { NodePoint_0_0_2, getFullItemTwo, MakeServerCall, SetNextNode, ServerCall_0_0_2 } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { skipWhile, take, map, switchMap, catchError, pluck } from 'rxjs/operators';
import { FetchTemplates, getAvailableTemplates, MakeBillingServerCall } from '@flexus/core';
import { EMPTY, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import moment from 'moment';

const callOutStates = [25, 65, 66, 67, 69, 80, 94, 105, 171, 206, 207];
const invoicingStates = [26, 46, 47];

let isHomeAssistItem;
let jobState;
let jobSkill;
let usedConsignment;
let isInvoiceOnlyState;

export const templateSkillAndStateCheck: ServerCall_0_0_2 = {
	errorMessage: '',
	directCall: (http, store, sq) => {
		return store.select(getFullItemTwo).pipe(
			skipWhile(res => !res),
			take(1),
			map(res => {
				const {
					state,
					skill,
					appointment,
					claim: { claim_type }
				} = res;
				const isAfterHoursItem = appointment?.[0]?.after_hours;
				jobState = state;
				jobSkill = skill;
				usedConsignment = res?.job_information?.used_consignment;
				usedConsignment = res?.job_information?.used_consignment;

				let type = 'call_out';
				let callOutIdType: 'skill' | 'claim_type' = 'skill';

				if (claim_type === 28) {
					isHomeAssistItem = true;
					jobSkill = 28;
					callOutIdType = 'claim_type';
					if (state === 26) {
						type = 'labour';
					}
				} else if (callOutStates.includes(state)) {
					type = 'call_out';
				} else if (skill === 1 && usedConsignment?.includes('1')) {
					type = 'consignment';
				}

				isInvoiceOnlyState = callOutStates.includes(jobState) || invoicingStates.includes(jobState);
				OWN_BILLING_INVOICE.navs[0].text = `Generate ${isInvoiceOnlyState ? 'Invoice' : 'Quote'}`;
				// OWN_BILLING_INVOICE.inputs.billingTemplates.children[0].inputs.isInvoiceOnlyState = isInvoiceOnlyState;

				return store.dispatch(new FetchTemplates({ callOutSkill: jobSkill, callOutIdType, type, isAfterHoursItem }));
			})
		);
	}
};

export const billingServerCall = (functionName, responseSlice, errorMessage, dataKey, loaderID?, ignoreFalseError?) => {
	return {
		errorMessage: '',
		directCall: (http, store) => {
			// This is a trick to hide the display of the error message
			const x: any = { displayError: false, ignoreFalseError: ignoreFalseError };

			return of(
				store.dispatch(
					new MakeBillingServerCall({
						functionName,
						responseSlice,
						errorMessage,
						dataKey,
						loaderID: loaderID ?? functionName,
						...x
					})
				)
			);
		}
	} as ServerCall_0_0_2;
};

export const billingServerCalls = {
	templateSkillAndStateCheck,
	lineItems: billingServerCall('getBoqItems', 'payload.items', 'No BOQ items could be retrieved', 'lineItems'),
	sp: billingServerCall('getServiceProvider', 'payload', 'No SP could be retrieved', 'sp'),
	invoice: billingServerCall('getJobInvoicingStatus', 'payload', 'No invoice could be retrieved', 'invoice'),
	getCustomTypesList: billingServerCall('getCustomTypesList', 'payload', 'No customTypesList could be retrieved', 'customTypesList'),
	customYearsList: billingServerCall('getCustomYearsList', 'payload.Year', 'No customYearsList could be retrieved', 'customYearsList'),
	skillCategories: billingServerCall('getSkillCategories', 'payload', 'No skillCategories could be retrieved', 'skillCategories'),
	skillSubCategories: billingServerCall('getSkillSubCategories', 'payload', 'No skillSubCategories could be retrieved', 'skillSubCategories'),
	unitsOfMeasurement: billingServerCall('getUnitsOfMeasurement', 'payload', 'No unitsOfMeasurement could be retrieved', 'unitsOfMeasurement'),
	banks: billingServerCall('getBanks', '', 'No banks could be retrieved', 'banks'),
	boq: billingServerCall('getBOQInfo', 'payload', 'No boq could be retrieved', 'boq', null, true),
	excessInfo: billingServerCall('getExcessInfo', 'payload', 'No excessInfo could be retrieved', 'excess'),
	travelDetails: billingServerCall('getPermittedTravelDistance', 'payload', 'no travel details could be retrieved', 'travelDetails'),
	excessChangeDetails: billingServerCall('getExcessChangeDetails', 'payload', 'no excess details could be retrieved', 'excessChangeDetails')
};

// Billing Nodes
export const SELECT_CALLOUT_TEMPLATE: NodePoint_0_0_2 = {
	hideTabItem: true,
	serverCalls: {
		availableTemplates: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return store.select(getAvailableTemplates).pipe(
					skipWhile(x => !x),
					take(1)
				);
			}
		}
	},
	component: {
		children: [
			{
				component: 'FLXSelectListComponent',
				inputs: {
					heading: 'What inspection was done?',
					options$: 'availableTemplates',
					selectOptionFunc: instance => instance.bf.bigForm.get('selectedTemplate').patchValue(instance.selectedOption.template),
					collapseOnSelect: false,
					maxWidth: '40%'
				}
			}
		]
	},
	initFormFields: bf => bf.bigForm.addControl('selectedTemplate', new UntypedFormControl('', [])),
	checkValidityForFields: ['selectedTemplate'],
	navs: [
		{ text: 'Edit Report', nextNode: 'EditReport' },
		{
			text: 'Continue',
			optIntoValidation: false,
			nextNode: 'CHECK_IF_ATTENDED_AFTER_HOURS'
		}
	]
};

export const AUTO_SELECT_TEMPLATE_DECISION: NodePoint_0_0_2 = {
	hideTabItem: true,
	nodeType: 'decision',
	errorHandler: {
		displayFormat: 'dialog',
		retryPolicy: 'manual',
		onRetryComplete: () => {
			return EMPTY;
		}
	},
	decisions: {
		checkIfMoreThanOneTemplate: (navs, store) => {
			return store.dispatch(
				new MakeServerCall({
					errorMessage: 'Could not go to next node!',
					directCall: (http, s, sq, bf, controller) => {
						return store.select(getAvailableTemplates).pipe(
							skipWhile(x => x === null),
							take(1),
							switchMap(availableTemplates => {
								bf.bigForm.addControl('selectedTemplate', new UntypedFormControl(null, []));

								if (!isHomeAssistItem && !callOutStates.includes(jobState) && (jobSkill !== 1 || usedConsignment === undefined || !usedConsignment.includes('1'))) {
									// then dont show template screen
									controller.dispatch(new SetNextNode('CHECK_IF_ATTENDED_AFTER_HOURS'));
									return of({});
								} else if (availableTemplates.length <= 1) {
									bf.bigForm.get('selectedTemplate').patchValue(availableTemplates?.[0]?.['template'] ?? null);
									controller.dispatch(new SetNextNode('CHECK_IF_ATTENDED_AFTER_HOURS'));
									return of({});
								} else if (availableTemplates.length > 1) {
									controller.dispatch(new SetNextNode('SELECT_CALLOUT_TEMPLATE'));
									return of();
								} else {
									controller.dispatch(new SetNextNode('CHECK_IF_ATTENDED_AFTER_HOURS'));
									return of();
								}
							}),
							catchError(() => of(controller.dispatch(new SetNextNode('CHECK_IF_ATTENDED_AFTER_HOURS'))))
						);
					}
				})
			);
		}
	},
	navs: []
};
export const OWN_BILLING_INVOICE: NodePoint_0_0_2 = {
	hideTabItem: true,
	component: 'BillingComponent',
	initFormFields: bf => {
		bf.bigForm.addControl('invoiceDate', new UntypedFormControl(new Date(), Validators.required));
		bf.bigForm.addControl('invoiceNumber', new UntypedFormControl('', Validators.required));
		bf.bigForm.addControl('current_state', new UntypedFormControl(''));
		bf.bigForm.addControl('invoiceNotes', new UntypedFormControl(''));
		bf.bigForm.addControl('total', new UntypedFormControl(0));
		bf.bigForm.addControl('new_state', new UntypedFormControl(27));
		bf.bigForm.addControl('vatPercentage', new UntypedFormControl(0));
		bf.bigForm.addControl('vatRate', new UntypedFormControl(0));
		bf.bigForm.addControl('hasBoq', new UntypedFormControl(false));
		bf.bigForm.addControl('actualLineItemsFormArray', new UntypedFormControl([], Validators.required));
	},
	inputs: {
		billingTemplates: {
			children: [
				{ component: 'BillingHeaderComponent', inputs: { isInvoiceOnlyState: false } },
				{ component: 'InvoiceNumberDateEditComponent' },
				{
					component: 'LineItemsComponent',
					name: 'Billing Line Items',
					inputs: {
						sp$: 'sp',
						createOrUpdateCustomLineItem: (instance, data, functionName) => {
							instance.store.dispatch(
								new MakeBillingServerCall({
									dataKey: 'lineItems',
									responseSlice: 'payload.items',
									functionName,
									errorMessage: 'Could not add custom line item',
									data
								})
							);
						},
						boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
						boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Standard Bank Insurance Limited',
						clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL'
					}
				},
				{ component: 'InvoiceSummaryComponent', inputs: { editing: true } }
			],
			layout: 'horizontal'
		}
	},
	navs: [
		{
			text: '',
			nextNode: 'SAVE_TO_DESKTOP',
			location: 'right',
			linkType: 'submit',
			optIntoValidation: true,
			serverFirst: true,
			serverCalls: {
				postInvoice: {
					errorMessage: 'An error occurred while trying to create or update BOQ!',
					serviceVariable: 'spService',
					functionName: 'createOrUpdateBOQ',
					followUpSuccessCalls: {
						response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
					}
				}
			}
		}
	]
};

export const CHECK_IF_ATTENDED_AFTER_HOURS: NodePoint_0_0_2 = {
	name: 'CheckIfAttendedAfterHours',
	nodeType: 'decision',
	decisions: {
		isAttendedAfterHoursSuccess: (navs, store, modal, controller) => {
			return store.dispatch(
				new MakeServerCall({
					errorMessage: 'Could not go to next node!',
					directCall: (http, s, sq, bf, controller) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(job =>
								http
									.post(`${environment.api_url}v1/job_action/get_on_site_details/`, {
										job_id: job.id
									})
									.pipe(
										pluck('payload'),
										map((response: any) => {
											const isFirstOnSiteAfterAppointment = moment(response.first_onsite, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(response.first_appointment, 'YYYY-MM-DD HH:mm:ss'));
											const isLastOnSiteAfterAppointment = moment(response.last_onsite, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(response.last_appointment, 'YYYY-MM-DD HH:mm:ss'));

											if (!isHomeAssistItem && !callOutStates.includes(jobState) && (jobSkill !== 1 || usedConsignment === undefined || !usedConsignment.includes('1'))) {
												controller.dispatch(new SetNextNode('OWN_BILLING_INVOICE'));
												return of({});
											}

											if (!isFirstOnSiteAfterAppointment || !isLastOnSiteAfterAppointment) {
												const control = {
													label: 'Comment',
													controlName: 'comment',
													required: true
												};
												modal.openModalDirectly(instance => {
													instance.type = 'warning';
													instance.title = 'Attended after-hours';
													instance.message = 'This was an after-hours job but that they attended to the job during office hours';
													instance.setControl(control);
													instance.navButtons = [
														{
															text: 'Back to workflow',
															clickHandler: event => {
																instance.router.navigate(['/workflow']);
															},
															linkType: 'close',
															color: 'alert'
														},
														{
															text: 'Submit',
															color: 'default',
															linkType: 'close',
															clickHandler: event => {
																store
																	.select(getFullItemTwo)
																	?.pipe(
																		skipWhile(x => !x),
																		take(1),
																		map(res => res as any)
																	)
																	.subscribe((job: any) => {
																		const data = {
																			job_id: job.id,
																			new_state: 26,
																			job_information: { ...job?.job_information, reason_homeassist_during_day: bf.bigForm?.value[control.controlName] ?? '' }
																		};

																		store.dispatch(
																			new MakeServerCall({
																				directCall: () => http.post(`${environment.api_url}v1/job_action/update_job/`, data),
																				errorMessage: 'Job could not be updated',
																				responseSlice: 'payload'
																			})
																		);
																		instance.removeControl(control.controlName);
																		return;
																	});
															}
														}
													];
												});
											}

											controller.dispatch(new SetNextNode('OWN_BILLING_INVOICE'));
											return of({});
										})
									)
							),
							catchError(() => of(controller.dispatch(new SetNextNode('OWN_BILLING_INVOICE'))))
						);
					}
				})
			);
		}
	},
	navs: []
};
