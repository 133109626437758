<div class="flx-calendar-header">
	<div class="flx-calendar-controls">
		<button [disabled]="!_previousEnabled()" (click)="_previousClicked()" [attr.aria-label]="_prevButtonLabel" class="btn-with-icon date-left">
			<flx-icon [type]="'left-arrow'"></flx-icon>
		</button>

		<button class="btn-period" (click)="_currentPeriodClicked()" [attr.aria-label]="_periodButtonLabel">
			{{ _periodButtonText }}
			<div class="flx-calendar-arrow" [class.flx-calendar-invert]="_currentView != 'month'"></div>
		</button>

		<button [disabled]="!_nextEnabled()" (click)="_nextClicked()" [attr.aria-label]="_nextButtonLabel" class="btn-with-icon date-right">
			<flx-icon [type]="'right-arrow'"></flx-icon>
		</button>
	</div>
</div>

<div class="flx-calendar-content" (keydown)="_handleCalendarBodyKeydown($event)" [ngSwitch]="_currentView" cdkMonitorSubtreeFocus tabindex="-1">
	<flx-month-view
		*ngSwitchCase="'month'"
		[activeDate]="_activeDate"
		[selected]="selected"
		[dateFilter]="_dateFilterForViews"
		(selectedChange)="_dateSelected($event)"
		(_userSelection)="_userSelected()"
	>
	</flx-month-view>

	<flx-year-view *ngSwitchCase="'year'" [activeDate]="_activeDate" [selected]="selected" [dateFilter]="_dateFilterForViews" (selectedChange)="_goToDateInView($event, 'month')">
	</flx-year-view>

	<flx-multi-year-view
		*ngSwitchCase="'multi-year'"
		[activeDate]="_activeDate"
		[selected]="selected"
		[dateFilter]="_dateFilterForViews"
		(selectedChange)="_goToDateInView($event, 'year')"
	>
	</flx-multi-year-view>
</div>
