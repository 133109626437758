import { Injectable, Inject } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
// import { JWT_TOKEN_KEY } from '../../constants/services';
// import { LogOut } from '../store';
import { Router } from '@angular/router';
import { LogOut } from '../identity';
import { JWT_TOKEN_KEY } from './engine.constants';

@Injectable({ providedIn: 'root' })
export class LocalAuthInterceptorService implements HttpInterceptor {
	headersConfig = {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	};

	constructor(
		@Inject(JWT_TOKEN_KEY) private tokenKey: string,
		// private authService: LocalAuthenticationService,
		// @Inject(LOCAL_IDENTITY_CONFIG) private identityConfig: LocalIdentityConfig,
		private router: Router,
		private store: Store<any>
	) {}

	getToken(tokenName: string): string {
		return localStorage.getItem(tokenName);
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const url = request.url;

		let tokenName;
		if (Array.isArray(this.tokenKey)) {
			const tokenNameArr = this.tokenKey.filter(token => url.includes(token.url));
			if (tokenNameArr && tokenNameArr.length === 1) {
				tokenName = tokenNameArr[0]?.tokenName;
			}
		} else {
			tokenName = this.tokenKey;
		}

		// the bellow should work
		let localHeadersConf = JSON.parse(JSON.stringify(this.headersConfig));

		if (this.getToken(tokenName)) {
			localHeadersConf = { ...localHeadersConf, ['Authorization']: `Token ${this.getToken(tokenName)}` };
		} else {
			// If dont have token, remove authorization from header
			const { Authorization: deleted, ...rest } = localHeadersConf;
			localHeadersConf = rest;
		}

		if (request.body instanceof FormData) {
			const { 'Content-Type': deleted, ...rest } = localHeadersConf;
			localHeadersConf = rest;
		}

		if (request.url.includes('/auth/login/') || request.url.includes('v1/auth/azure/') || request.url.includes('v1/auth/azure/logout/')) {
			const { Authorization: deleted, ...rest } = localHeadersConf;
			localHeadersConf = rest;
		} else {
			localHeadersConf = { ...localHeadersConf, ['Authorization']: `Token ${this.getToken(tokenName)}` };
		}

		const newRequest: HttpRequest<any> = request.clone({
			setHeaders: localHeadersConf
		});

		return next.handle(newRequest).pipe(
			tap(
				(event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {
						// console.log({ routerURL: this.router.url });
						// if (!this.identityConfig.no_auth_urls.includes(this.router.url) && !this.authService.userIsAuthenticated) {
						//   // this.store.dispatch(new LogOut());
						// }
					}
				},
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 403 || err.status === 401) {
							this.store.dispatch(new LogOut());
						}
					}
				}
			)
		);
	}
}

export const requestOptionsProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: LocalAuthInterceptorService,
	multi: true
};
