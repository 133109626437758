<label class="flx-button-only"> <input type="file" ngFileSelect [uploadInput]="uploadInput" (change)="getFileFromInputSelection($event)" /> <span>UPLOAD PHOTO</span> </label>
<!--
// LEAVE BELOW CODE FOR FUTURE REFERENCE
<div
class="add-new-file compact-select-drop-container"
ngFileDrop
[uploadInput]="uploadInput"
[ngClass]="{ 'is-drop-over': dragOver }"
(dragenter)="this.dragOver = true"
(dragleave)="this.dragOver = false"
(drop)="getFileFromDropEvent($event)"
>
<div class="compact-select-center-me">
  <label class="compact-select-upload-button">
    <!-- project the bellow |? -->
<!-- <input type="file" ngFileSelect [uploadInput]="uploadInput" (change)="getFileFromInputSelection($event)" />
      <svg id="compact-select-svg" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="white" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </label>
    <p>Add file</p>
  </div>
</div>

-->
<!--

  // LEAVE ABOVE CODE FOR FUTURE REFERENCE, PLEASE
-->
