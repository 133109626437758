import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetLoggedInUser, ManifestController, LoadManifest, ENVIRONMENT, CheckAvailability, GetJobResponses } from '@flexus/core';

@Injectable({
	providedIn: 'root'
})
export class LoadSpManifestService implements OnDestroy {
	private getJobsInterval;

	constructor(private store: Store<any>, private controller: ManifestController<any>, @Inject(ENVIRONMENT) private environment: any) {}

	load(spManifest) {
		this.store.dispatch(new GetLoggedInUser(`${this.environment.api_url}v2/auth/user/`));
		this.controller.dispatch(new LoadManifest(spManifest));

		//this should be triggered on load so that reusability is
		const initialInterval = setInterval(() => {
			this.store.dispatch(new GetJobResponses());
			this.store.dispatch(new CheckAvailability());
			clearInterval(initialInterval);
		}, 3000);

		this.getJobsInterval = setInterval(() => {
			this.store.dispatch(new CheckAvailability());
		}, 300000);
	}

	ngOnDestroy() {
		clearInterval(this.getJobsInterval);
	}
}
