import { AVSResultFlag } from '@flexus/screens';
import { take, map, filter } from 'rxjs/operators';
import gql from 'graphql-tag';
import { flatten, values } from 'ramda';
import { environment } from 'apps/studio/src/environments/environment';
import { of } from 'rxjs';
import { NodePoint_0_0_2 } from '@flexus/core';

export const AMP_BANK_DETAILS: NodePoint_0_0_2 = {
	component: 'BankDetailsComponent',
	hideTabItem: true,
	initFormFields: (bf, item, ins) => {
		bf.bigForm.addControl('avs_data', ins.bankDetailsForm);
	},
	serverCalls: {
		accountTypes: {
			errorMessage: 'Could not get account types!',
			directCall: (http, store, sq) => {
				return sq
					.queryObject(
						gql`
							{
								allInfo {
									account_type
								}
							}
						`,
						store
					)
					.pipe(
						filter(x => !!x),
						map(values),
						map<any, any>(flatten),
						take(1)
					);
			}
		}
	},
	inputs: {
		manualAvs: false,
		branchFlag: true,
		avsSuccess: AVSResultFlag.not_tested,
		deflectErrors: [{ match: 'esb failure', message: 'ESB FAILURE: Maven Error. Cannot perform AVS check.' }],
		errorFieldMapper: {
			account_holder_name: ['Error Account Surname does not match the one supplied', 'Account surname cannot be empty.'],
			initials: 'Error Account Initials do not match the ones supplied ',
			acc_type: 'Error Account Type does not match the one supplied',
			identification_number: ['Error Account Identity number does not match the one supplied', 'Registration/Identity number cannot be empty.'],
			branch: ['Branch code cannot be empty.']
			// :'Error Account Phone number does not match the one supplied' ,
			// :'Error Account Email number does not match the one supplied',
		},
		avsByPassList: [
			'Error Account Surname does not match the one supplied',
			'Error Account Initials do not match the ones supplied',
			'Error Account Type does not match the one supplied',
			'Error Account Phone number does not match the one supplied',
			'Error Account Email number does not match the one supplied'
		],
		avsIgnoreList: ['Error Account Phone number does not match the one supplied', 'Error Account Email number does not match the one supplied'],
		branchLookupEndpoint: {
			dataKey: 'branchLookup',
			serviceVariable: 'ampService',
			functionName: 'getBranchLookupResults',
			responseSlice: 'payload',
			errorMessage: 'No branches found!'
		},
		modalNavSuccess: {
			linkType: 'submitThenNext',
			serverCalls: {
				saveBankDetails: {
					serviceVariable: 'ampService',
					functionName: 'updateJob',
					errorMessage: 'Job could not be updated!!'
				}
			},
			nextNode: 'PREVIOUS',
			text: 'Continue',
			color: 'primary'
		}
	},
	// checkValidityForFields: [
	//   'account_holder_name',
	//   'acc_type',
	//   'account_use',
	//   'branch',
	//   'branch_code',
	//   'initials',
	//   'identification_number',
	//   'account_number',
	//   'bank_name'
	// ],
	navs: [
		{
			text: 'Avs Check',
			serverCalls: {
				avsCheck: {
					errorMessage: 'Could not run AVS Check!',
					ignoreFalseError: true,
					directCall: (http, store, sq, bf) => {
						const avsData = bf.bigForm.get('avs_data')?.value;
						if (!!avsData.branch_code) {
							const avsLookup = {
								bank_acc_number: avsData ? avsData.account_number : '',
								idnumber: avsData ? avsData.identification_number : '',
								acc_type: avsData ? avsData.acc_type : '',
								branch_code: avsData ? avsData.branch_code : '',
								isnonpanel: avsData && avsData.account_use === 'Individual' ? false : true,
								initials: avsData ? avsData.initials : '',
								surname: avsData ? avsData.account_holder_name : ''
							};
							return http.post(`${environment.api_url}v1/claim_action/check_account_details/`, avsLookup);
						} else {
							return of({
								success: false,
								reason: 'Please use the Bank and Branch Name fields to search and select a branch before performing an AVS check.'
							});
						}
					}
				}
			}
		}
	]
};
