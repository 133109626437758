import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGeneratorModule } from '@flexus/form-generator';
import { UIElementsModule, FLXLoadingSpinnerModule, FLXPanelsModule, FLXHeadingsModule, FLXLoadingSpinnerInlineModule } from '@flexus/ui-elements';
import { UICompositesModule } from '@flexus/ui-composites';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipesModule } from '@flexus/pipes';
import { screens } from './screens';
import { ErrorHandlerModule } from '@flexus/error-handler';
import { FLXDirectivesModule } from '@flexus/directives';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
	declarations: [...screens],
	imports: [
		CommonModule,
		FormGeneratorModule,
		UIElementsModule,
		UICompositesModule,
		PipesModule,
		NgxPaginationModule,
		FLXHeadingsModule,
		FLXPanelsModule,
		ErrorHandlerModule,
		FLXDirectivesModule,
		FLXLoadingSpinnerModule,
		FLXLoadingSpinnerInlineModule,
		NgSlimScrollModule,
		DragDropModule
	],
	exports: [UIElementsModule, UICompositesModule]
})
export class ScreensModule {
	static forRoot(env): ModuleWithProviders<ScreensModule> {
		return {
			ngModule: ScreensModule,
			providers: [
				{ provide: 'environment', useValue: env }
				// {provide: ANALYZE_FOR_ENTRY_COMPONENTS, useValue: components, multi: true}
			]
		};
	}
}
