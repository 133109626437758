import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { bet } from '../bet.manifest';
import { environment } from 'apps/studio/src/environments/environment';
import { LoadManifest, GetLoggedInUser, GetAllInfo, ManifestController } from '@flexus/core';

@Injectable({
	providedIn: 'root'
})
export class LoadBetManifestService {
	constructor(private store: Store<any>, private controller: ManifestController<any>) {}

	load() {
		this.store.dispatch(new GetLoggedInUser(`${environment.api_url}v2/auth/user/`));
		this.controller.dispatch(new LoadManifest(bet));
	}
}
