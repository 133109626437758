import { Manifest_0_0_2, getCurrentUser } from '@flexus/core';
import { SilSpSearch } from './configs/search/sil-sp-search';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { FLXAppMenuComponent } from '../../app-shell-features/app-menu/app-menu.component';
import { environment } from '../../../environments/environment';

// Orgs
import { SP_GLOBAL_STATES } from '../../sp_globals/configs/flows';
import { SpClaimWorkflow } from '../../sp_globals/configs/workflow/sp-claim-workflow';
import { jobPermissions } from '../../sp_globals/configs/permissions/job.permissions';
import { applySilVirtualStates } from '../../sp_globals/sp-virtual-state.helper';
import * as SilSpStates from './configs/flows';

export const silSp: Manifest_0_0_2 = {
	version: '0.0.2',
	orgKey: environment.client,
	settings: {
		addActionPanel: true,
		addAppBar: true,
		addAppMenu: true,
		addFooter: true,
		addHeaderActions: true,
		addSearchBanner: true,
		addReminder: true
	},
	appMenu: (instance: FLXAppMenuComponent, store: Store<any>) => {
		return store.select(getCurrentUser).pipe(
			filter(user => !!user),
			map(res => {
				const createRoles = [1, 6];
				const menuItems = [
					{ id: 1, name: 'Workflow', routerLink: '/workflow', iconType: 'app-menu-workflow' },
					{ id: 10, name: 'Add User', routerLink: '/workflow/detail', iconType: 'app-menu-workflow' }
				];

				if (res?.user.roles.some(role => createRoles.includes(role))) {
					menuItems.push({
						id: 2,
						name: 'New Claim',
						routerLink: '/workflow/detail',
						iconType: 'app-menu-new-claim'
					});
				}

				return menuItems;
			})
		);
	},
	virtualStatesFunction: applySilVirtualStates,
	appSearch: SilSpSearch,
	permissions: {
		itemTwo: jobPermissions
	},
	manifestItems: {
		...SP_GLOBAL_STATES,
		25: SilSpStates.SIL_SP_25,
		26: SilSpStates.SIL_SP_26,
		69: SilSpStates.SIL_SP_69,
		// 21: SPStates.SP_21,
		// TODO: export const SP_21_OVERRIDES: Partial<Flow_0_0_2> {}
		// TODO:  21: _.merge(SP_GOBAL_STATE.SP_21, SP_21_OVERRIDES);
		workflow: SpClaimWorkflow
	}
};

/*
	organizations: {
		sil: {
			manifestItems: {
				...pickFromStates(sil.manifestItems, [27, 21]),
				...cheripickFromStates(sil.manifestItems, [14, 15], ['header', 'instructions']),
				...cheripickFromStates(sil.manifestItems, [24, 25], ['footer'])
			}
		},
		sp: {
			manifestItems: {
				...pickFromStates(sil.manifestItems, [27, 21]),
				...cheripickFromStates(sil.manifestItems, [14, 15], ['header', 'instructions']),
				...cheripickFromStates(sil.manifestItems, [24, 25], ['footer'])
			}
		}
	}
*/
