import { getSelectedItemTwo, NodePoint_0_0_2 } from '@flexus/core';
import { environment } from 'apps/studio/src/environments/environment';
import { of } from 'rxjs';
import { skipWhile, take, switchMap, map } from 'rxjs/operators';

export const viewProofNode = (state?, new_state?): NodePoint_0_0_2 => {
	return {
		name: 'Proof of Purchase',
		showTabs: true,
		hideTabItem: false,
		initFormFields: bf => {
			bf.patchValues({ new_state: null });
		},
		serverCalls: {
			proofOP: {
				errorMessage: 'not getting files',
				directCall: (http, store, sq, bf) => {
					return store
						.select(getSelectedItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1),
							switchMap((res: any) => {
								const job_id = res?.id;
								return http.post<any>(`${environment.api_url}v1/file_action/get_files`, { job_id: job_id, latest: true, purpose: 'proof_of_purchase' });
							})
						)
						.pipe(
							skipWhile(x => !x),
							take(1),
							switchMap((d: any) => {
								const returned = d?.payload;
								return of(returned);
							})
						);
				}
			}
		},
		component: {
			children: [
				{
					component: 'FLXFileViewWithSideExtrasComponent',
					inputs: {
						dataFiles$: 'proofOP',
						mimeType: ['pdf', 'image']
					}
				}
			]
		},

		navs: [
			...(state === 247 || state === 248 || state === 271
				? [
						{
							text: 'request proof of purchase',
							nextNode: 'RequestProofOfPurchase',
							color: 'secondary'
						},
						{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'alert' },
						{
							text: 'Approve',
							nextNode: 'submissionSuccess',
							color: 'primary',
							serverCalls: {
								response: {
									serviceVariable: 'mulService',
									functionName: 'updateJob',
									errorMessage: 'Job could not be updated!!'
								}
							}
						}
				  ]
				: [
						{
							text: 'SP Rework',
							nextNode: 'rework',
							color: 'secondary'
						},
						{
							text: 'QA Regional',
							nextNode: 'requestQA',
							color: 'secondary'
						},
						{
							text: state === 179 ? 'Fix Issue' : 'Issue Fixed',
							nextNode: 'resolvedProblem',
							color: 'primary'
						}
				  ])
		]
	};
};
