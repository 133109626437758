import { Flow_0_0_2 } from '@flexus/core';

export const SIL_22: Flow_0_0_2 = {
	id: '22',
	name: 'TL_assigned',
	itemType: 'flow',
	header: {
		title: 'Claim Submitted',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Wait for Team Leader to respond'
		}
	},
	startNode: '',
	nodes: {}
};
