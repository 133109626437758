import { Flow_0_0_2, getCurrentUser, getFullItemTwo, getSelectedItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';
import { skipWhile, map, take, filter, pluck, switchMap } from 'rxjs/operators';
import { humaniseDate } from '@flexus/utilities';
import moment from 'moment';
import gql from 'graphql-tag';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { environment } from '../../../organisations/sil/environments/sil.environment.hmr';

export const SP_GLOBAL_170: Flow_0_0_2 = {
	id: '170',
	name: 'warranty_void',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Warranty Void: ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Warranty Void';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		}
	},

	instructions: {
		editRoles: {
			0: 'Upload assessment report'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'UploadAssessmentReportNotification',
	nodes: {
		UploadAssessmentReportNotification: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'The Warranty is Void',
							instructions: ['Phone client to set a new appointment date and continue the work.']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'keyValueList'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'keyValueList2'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'keyValueList3'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'keyValueList4'
						}
					}
				]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Something went wrong with client info',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(kvl => !kvl),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									const claiminfo = {
										'Claim Type : ': itemTwo?.claim?.type ?? '',
										'Address : ': itemTwo?.address ?? ''
									};
									return [claiminfo];
								})
							);
					}
				},
				keyValueList2: {
					errorMessage: 'Something went wrong with the appointment info',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(kvl => !kvl),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									const appointment = job?.appointment[job.appointment.length - 1];
									const appointmentinfo = {
										'Appointment Date': appointment.range_start ? `${humaniseDate(appointment.range_start)}` : '',
										'Appointment Time': appointment.range_start ? `${moment(appointment.range_start)?.format('HH:mm')}` : ''
										// 'Appointment Date : ':
										//   job && job?.office_use ? job?.office_use.requestedappointmentdate : 'No appointment date set',
										// 'Appointment Time : ':
										//   job && job?.office_use
										//     ? `${job.office_use.appointment_type} ${job.office_use.requestedappointmenttime}`
										//     : 'Appointment time not retrieved'
									};
									return [appointmentinfo];
								})
							);
					}
				},
				keyValueList3: {
					errorMessage: "Couldn't determine previous team leader",
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(kvl => !kvl),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									return [
										{
											'Previously allocated team leader : ': job ? `${job.team_leader.full_name} ` : ''
										}
									];
								})
							);
					}
				}
			},
			navs: [{ text: 'Continue', linkType: 'submit', color: 'primary', nextNode: 'ChangeAppointment' }]
		},
		ChangeAppointment: {
			component: 'FLXJobAppointmentComponent',
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(null),
						skillcatagory: new UntypedFormControl(null)
					})
				);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skillcatagory
										skill
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(x => !x || !x.skill || !x.skillcatagory),
						take(1)
					)
					.subscribe(({ skill, skillcatagory }) => {
						const apd = bf.getControl('appointmentData');
						if (apd) {
							apd.get('skill')?.setValue(skill);
							apd.get('skillcatagory')?.setValue(skillcatagory);
						}
					});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f || !f.state),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'ChangeTeamLeader',
					serverFirst: true,
					serverCalls: {
						repingSP: {
							errorMessage: 'An error occurred while changing the job appointment',
							serviceVariable: 'ampService',
							functionName: 'changeJobAppointmentFromContextMenu'
						}
					}
				}
			]
		},
		ChangeTeamLeader: {
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: (bf, item, instance, sq, store) => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl('', Validators.required));
				store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(fullItemTwo => {
						bf.addControl('assign_teamleader_id', new UntypedFormControl('', Validators.required));
						if (fullItemTwo.team_leader.id) {
							bf.bigForm.get('assign_teamleader_id')?.patchValue([fullItemTwo.team_leader.id]);
						}
					});
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getSelectedItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map((res: any) => res)
							)
							.pipe(
								switchMap(job => {
									const { id } = job;
									const job_id = id;
									return http.post(`${environment.api_url}v1/sp_action/manage_list_staff/`, { role_id: 10, image: true, job_id: job_id }).pipe(
										pluck('payload'),
										filter(x => !!x),
										map((teamleaders: any[]) =>
											teamleaders.map(teamLeader => {
												const namestringarr = teamLeader.full_name?.split(' ');
												let mono1, mono2;
												if (!namestringarr[0]) {
													mono1 = ' ';
												} else {
													mono1 = namestringarr[0]?.slice(0, 1);
												}
												if (!namestringarr[1]) {
													mono2 = '';
												} else {
													mono2 = namestringarr[1]?.slice(0, 1);
												}
												const monogram = mono1 + mono2;
												return {
													display: teamLeader.full_name,
													value: teamLeader.id,
													image: teamLeader.image,
													monogram: monogram,
													teamLeader,
													shouldHaveImage: true
												};
											})
										)
									);
								})
							);
					}
				}
			},
			component: 'AssignTLComponent',
			inputs: {
				disableOptionWhen: {
					message: 'Upload profile picture to select team leader',
					evaluationCriteria: {
						property: 'image',
						operator: '!'
					}
				},
				includeUserDisplay: true,
				joblocation$: 'jobLocationData',
				teamleadersPositions$: 'teamleaderLocations'
			},
			navs: [
				{
					text: 'Submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't update job!",
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getCurrentUser).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									of(bf.bigForm.get('assign_teamleader_id')?.value).pipe(
										skipWhile(x => !x),
										take(1),
										map(rest => rest)
									)
								]).pipe(
									switchMap(([j, uid, lead]) => {
										const job = j as any;
										const user = uid as any;
										const job_id = job?.id;
										const staffmember = user.id;
										const team = lead[0];

										const data = {
											job_id: job_id,
											staffmember: staffmember,
											team_id: team
										};
										return http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
									})
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		assign_teamleader_id: [
			tlid => {
				if (tlid) {
					let id;
					tlid = tlid && Array.isArray(tlid) ? tlid : [];
					for (const lead of tlid) {
						id = parseInt(lead, 10);
					}
					return id;
				}
				return 0;
			},
			'job_information.team_leader'
		]
	}
};
