import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	Renderer2,
	ElementRef,
	AfterViewInit,
	OnChanges,
	SimpleChanges,
	ViewChild,
	ChangeDetectionStrategy,
	NgZone,
	HostListener
} from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

import { getOrgKey, InitializeTempData, SelectItemOne, SetActiveManifestItem, IndexedDbService, StoreQuery, ManifestController, DeleteLocalDraft } from '@flexus/core';
import { Subscription } from 'rxjs';
import { FLXIndicatorComponent } from '@flexus/ui-elements';
import { FLXContextMenuComponent } from '@flexus/ui-elements';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'flx-bui-claim-card',
	templateUrl: './bui-claim-card.component.html',
	styleUrls: ['./bui-claim-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuiClaimCardComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
	@Input() itemOne: any;
	@Input() itemOneContextMenuList = [];
	@Input() itemTwoContextMenuList = [];
	@Input() itemOnePermissions: Function[] = [];
	@Input() itemTwoPermissions: Function[] = [];
	@Input() allInfo: any;
	@Input() itemOneExtras: any;
	@Input() activeOrg: any;
	@Input() user: any;
	@Input() expanded = false;
	@Input() selectedItemOne;
	@Input() isOnline: boolean;
	@Output() select = new EventEmitter();
	@Output() fseExpansion = new EventEmitter();
	@Output() fseSendJobMessage = new EventEmitter<number>();
	@Output() fseTakeJobAction = new EventEmitter<number>();
	@Input() stateDescriptions: any[];
	@Input() claimTypeDescriptions: any[];

	subs: any[] = [];

	// fse = Four Sure Event
	engState: string;
	engClaimType: string;
	@ViewChild(FLXIndicatorComponent, { static: true }) indicator: FLXIndicatorComponent;
	@ViewChild(FLXContextMenuComponent) contextMenu: FLXContextMenuComponent;

	// This preload class is used to guard against the hiding animation on load.
	preload = true;
	isOfflineSub: Subscription;
	isOffline = '';
	indicatorClass = '';

	constructor(
		private _store: Store<any>,
		private controller: ManifestController<any>,
		private sq: StoreQuery,
		private router: Router,
		private db: IndexedDbService,
		private renderer: Renderer2,
		private el: ElementRef,
		private ngZone: NgZone,
		private _toastr: ToastrService
	) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['itemOnePermissions']) {
			this.ngZone.runOutsideAngular(() => {
				this.subs.forEach(sub => {
					if (sub) sub.unsubscribe();
				});
				if (this.itemOne && this.user && this.activeOrg && this.allInfo) {
					this.itemOnePermissions.forEach(fn => {
						const res = fn(this.itemOne, this.user, this.renderer, this.el.nativeElement, this.activeOrg, this.allInfo, this);
						if (res) {
							this.subs.push(res);
						}
					});
				}
			});
		}
		this.engState = this.stateDescriptions ? (this.itemOne.state === 169 ? 'Local Draft' : this.stateDescriptions[this.itemOne.state]) : '';
		this.engClaimType = this.claimTypeDescriptions ? this.claimTypeDescriptions[this.itemOne?.claim_type_id] : '';
	}

	ngAfterViewInit() {}

	@HostListener('mouseenter')
	onMouseOver() {
		const state = this.itemOne.state;
		const editRoles = this.user && this.user.user && this.user.user.edit_states;

		let claimCardElement = this.el.nativeElement.querySelector('.claim-card');
		if (editRoles && editRoles.includes(state)) {
			this.renderer.setStyle(claimCardElement, 'cursor', 'pointer');
		} else {
			this.renderer.setStyle(claimCardElement, 'cursor', 'default');
		}
	}

	selectMenuItem(itemOne, menuItem) {
		if (itemOne.state === 169) {
			switch (menuItem.id) {
				case 'DeleteLocalDraft': {
					this.controller.dispatch(new DeleteLocalDraft(itemOne.tempKey));
					break;
				}
				default:
					break;
			}
			if (this.contextMenu) {
				this.contextMenu.close();
			}
		} else if (this.isOnline) {
			// TODO Setup data and stuff needed by this context menu for this item
			this.controller.dispatch(new InitializeTempData());
			this._store.dispatch(new SelectItemOne({ itemOne }));
			this.controller
				.select(getOrgKey)
				.pipe(take(1))
				.subscribe(key => {
					this.controller.dispatch(
						new SetActiveManifestItem({
							pathToFlows: ['manifestItems', 'workflow', 'contextMenu', 'itemOne'],
							orgKey: key,
							itemId: menuItem.key,
							itemOne: this.itemOne
						})
					);
				});
			this.router.navigate(['/workflow/detail']);
			if (this.contextMenu) {
				this.contextMenu.close();
			}
		}
	}

	selectItem(itemOne) {
		this.select.emit(itemOne);
	}

	expandContent(): void {
		this.preload = false;
		this.expanded = !this.expanded;

		if (this.expanded) {
			this.fseExpansion.emit();
		}
	}

	openSendMessage(jobId: number): void {
		this.fseSendJobMessage.emit(jobId);
	}

	takeClaimAction(): void {
		if (this.user?.user?.edit_states.includes(this.itemOne.state) || this.itemOne.state === 169) {
			if (this.isOnline || this.itemOne.state === 169) {
				this.router.navigate(['/workflow/detail']);
				const func = () => {
					this.controller
						.select(getOrgKey)
						.pipe(take(1))
						.subscribe(key => {
							this.controller.dispatch(
								new SetActiveManifestItem({
									pathToFlows: ['manifestItems'],
									orgKey: key,
									itemId: this.itemOne.state,
									itemOne: this.itemOne
								})
							);
						});
				};
				func();
			}
		}
	}

	copyClaimNo(mid) {
		const selBox = document.createElement('textarea');
		selBox.id = 'myCopyBox';
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = mid;
		document.body.appendChild(selBox);
		selBox.focus();

		try {
			selBox.select();
			document.execCommand('copy');
			this._toastr.success('Copied to clipboard', mid, { timeOut: 2000 });
		} catch (e) {
			this._toastr.warning('Could not copy to clipboard', 'Error', { timeOut: 2000 });
		}

		const elem = document.getElementById('myCopyBox');
		elem.parentNode.removeChild(elem);
	}

	checkPermission(menuItem: any): boolean {
		const isVisible = menuItem.isVisible;

		if (typeof isVisible === 'function' && !isVisible(this.itemOne)) {
			return false;
		}

		if (
			(this.user?.user && this.checkArrays(this.user?.user?.roles, menuItem?.excludeForOnlyRoles)) ||
			(menuItem?.includeForOnlyRoles?.length > 0 && !this.checkArrays(this.user?.user?.roles, menuItem.includeForOnlyRoles))
		) {
			return false;
		} else if (menuItem?.id !== 'AddPoliceCaseNumber' && menuItem?.excludeForOnlyStates?.length > 0 && !menuItem?.excludeForOnlyStates?.includes(this.itemOne?.state)) {
			return true;
		} else if (menuItem?.id !== 'AddPoliceCaseNumber' && !menuItem?.excludeForOnlyStates?.includes(this.itemOne?.state) && menuItem?.includeForOnlyStates?.includes('all')) {
			return true;
		} else if (menuItem?.includeForOnlyStates?.includes(this.itemOne?.state)) {
			return true;
		} else return false;
	}

	checkArrays(arr1: Array<any>, arr2: Array<any>) {
		let res = false;
		if (!!arr1 && !!arr2) {
			arr1.forEach(val => {
				if (arr2.includes(val)) {
					res = true;
				}
			});
		}
		return res;
	}

	deleteLocalDraft(key: string) {
		this.db.claimInDraft.delete(key).then(() => {});
	}

	ngOnDestroy() {
		this.subs.forEach(sub => {
			if (sub) sub.unsubscribe();
		});
	}
}
