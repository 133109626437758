import { Action } from '@ngrx/store';

export enum appMenuActionTypes {
	OPEN_APP_MENU = '[App Menu] Open App Menu',
	CLOSE_APP_MENU = '[App Menu] Close App Menu'
}

export class OpenAppMenu implements Action {
	readonly type = appMenuActionTypes.OPEN_APP_MENU;
}

export class CloseAppMenu implements Action {
	readonly type = appMenuActionTypes.CLOSE_APP_MENU;
}

export type AppMenuActions = OpenAppMenu | CloseAppMenu;
