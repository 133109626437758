<div class="container">
	<div class="fake-modal">
		<div class="heading"><flx-heading size="medium" color="primary" align="center">Refund for Voucher Cancellation</flx-heading></div>
		<div class="message">
			<div style="text-align: center">Please select customer's preference:</div>
			<div>1. Customer enters the banking details on PingGo.</div>
			<div>2. Customer provides you with the banking details now.</div>
		</div>
		<div class="buttons">
			<flx-button color="default" align="left" (click)="customerEntersDetails()"> customer enters details</flx-button>
			<div style="width: 50px"></div>
			<flx-button color="default" align="right" (click)="proceedCustomerDetails()">enter details now</flx-button>
		</div>
	</div>
</div>
