import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';

export const BET_79: Flow_0_0_2 = {
	id: '79',
	name: 'enter_stansure_claim_number',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Enter a Stansure claim number for this claim on policy : ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Enter Stansure Claim Number';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Enter Stansure claim number'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'StansureClaimNumber',
	nodes: {
		StansureClaimNumber: {
			name: 'Enter Stansure Claim Number',
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				formControls: { 0: { inputType: 'input', formControlName: 'stansure_claim_number' } },
				heading: 'Enter Stansure Claim Number'
			},
			initFormFields: (bf, item, instance) => {
				bf.bigForm.addControl('stansure_claim_number', new UntypedFormControl('', Validators.required));
				bf.bigForm.addControl('new_state', new UntypedFormControl('1'));
			},
			navs: [
				{
					text: 'submit',
					optIntoValidation: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Claim not updated with Stansure claim number',
							serviceVariable: 'betService',
							functionName: 'updateClaim'
						}
					},
					color: 'primary'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		stansure_claim_number: 'loan_information.mavenclaimnumber'
	}
};
