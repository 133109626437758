import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXKeyValueListModule, FLXHeadingsModule, FLXButtonsModule } from '@flexus/ui-elements';
import { FLXHeadingWithInstructionsModule } from '../heading-with-instructions';
import { FLXButtonListWithInstructionsComponent } from './button-list-with-instructions.component';

@NgModule({
	imports: [CommonModule, FLXKeyValueListModule, FLXHeadingsModule, FLXButtonsModule, FLXHeadingWithInstructionsModule],
	declarations: [FLXButtonListWithInstructionsComponent],
	exports: [FLXButtonListWithInstructionsComponent]
})
export class FLXButtonListWithInstructionsModule {}
