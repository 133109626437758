import { Flow_0_0_2, getFullItemTwo, getSubmissionData, getData } from '@flexus/core';
import { empty, forkJoin } from 'rxjs';
import { skipWhile, take, map, switchMap, filter } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { AUTO_SELECT_TEMPLATE_DECISION, BILLING_INVOICE, billingServerCalls, SELECT_CALLOUT_TEMPLATE, SAVE_TO_DESKTOP } from '../reusable';
import { JOB_INFO_NODE } from '../reusable/JOB_INFO_NODE';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';
import { getExtension } from '@flexus/utilities';

SELECT_CALLOUT_TEMPLATE.navs = [
	{
		text: 'Continue',
		optIntoValidation: false,
		nextNode: 'BILLING_INVOICE'
	}
];

export const SP_GLOBAL_25: Flow_0_0_2 = {
	id: '25',
	name: 'invalid_claim_report',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map((itemTwo: any) => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Review Report - Upload Invoice : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Review Report - Upload Invoice';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		...billingServerCalls
	},
	instructions: {
		editRoles: {
			0: 'Invoice call-out fee'
		},
		viewRoles: {
			0: 'Wait for SP to invoice call-out fee'
		}
	},
	startNode: 'InvalidClaimReportNotice',
	nodes: {
		InvalidClaimReportNotice: {
			...JOB_INFO_NODE,
			showTabs: true,
			name: 'Overview',
			initFormFields: bf => {
				// bf.patchValues({ circumstance_of_loss: bf.getControl('circumstance_of_loss')?.value | '' });
				bf.bigForm.addControl('circumstance_of_loss', new UntypedFormControl());
				bf.bigForm.addControl('description_of_claim', new UntypedFormControl('this is description'));
				bf.bigForm.addControl('diagnosis', new UntypedFormControl('this is diagnosis'));
				bf.bigForm.addControl('recommendations', new UntypedFormControl('this is recommendations'));
				// bf.bigForm.addControl('onsitereport', new FormControl(''));
				bf.bigForm.addControl('purpose', new UntypedFormControl('SP Report', [Validators.required]));
				bf.bigForm.addControl('new_state', new UntypedFormControl('39', [Validators.required]));
				bf.bigForm.addControl('current_state', new UntypedFormControl('25', [Validators.required]));
			},

			navs: [
				{
					text: 'Review Report',
					nextNode: 'ReviewReport',
					color: 'primary'
				}
			]
		},
		ReviewReport: {
			serverCalls: {
				dataFile: {
					errorMessage: 'No file could be found!',
					directCall: (http, store, sq, bf) => {
						return store.select(getData)?.pipe(
							map((filedata: any) => {
								const spSiteReport = [];
								if (filedata?.reports) {
									filedata.reports?.forEach(report => {
										if (report.purpose === 'SP Report') {
											spSiteReport.push({ ...report });
										}
									});
									spSiteReport.sort((a, b) => (a.created < b.created ? 1 : -1));
								}
								return spSiteReport;
							}),
							switchMap(spSiteReport => {
								if (Array.isArray(spSiteReport) && spSiteReport?.length > 0) {
									return http
										.post(`${environment.api_url}v1/file_action/get_file`, {
											file_id: spSiteReport[0]?.id,
											return_type: 1
										})
										.pipe(
											skipWhile(doc => !doc),
											take(1),
											map((report: any) => {
												return report.payload;
											})
										);
								}
								return empty();
							})
						);
					}
				}
			},
			name: 'Review / Edit',
			showTabs: true,
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							dataFiles$: 'dataFile',
							inputHeading: 'Edit Team Leader Report'
						}
					},
					{
						component: 'FLXInputTextComponent',
						inputs: {
							formControlNamme: 'circumstance',
							width: '250px',
							label: 'Circumstance'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'description_of_claim',
									inputType: 'textarea',
									rows: 2,
									label: 'Description',
									width: '50vw'
								},
								1: {
									formControlName: 'circumstance_of_loss',
									inputType: 'textarea',
									rows: 2,
									label: 'Circumstance',
									width: '50vw'
								},
								2: {
									formControlName: 'recommendations',
									inputType: 'textarea',
									rows: 2,
									label: 'Recommend',
									width: '50vw'
								},
								3: {
									formControlName: 'diagnosis',
									inputType: 'textarea',
									rows: 2,
									label: 'Diagnosis',
									width: '50vw'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw'
						}
					}
				]
			},
			initFormFields: (bf, item, instance, sq, store) => {
				store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(x => !x),
						take(1),
						map(pipe => pipe as any)
					)
					.pipe(
						filter(x => !!x && Object.keys(x)?.length !== 0),
						take(1)
					)
					.subscribe(value => {
						bf.patchValues({ description_of_claim: value?.job_information?.description_of_claim || '' });
						bf.patchValues({ circumstance_of_loss: value?.job_information?.circumstance_of_loss || '' });
						bf.patchValues({ diagnosis: value?.job_information?.diagnosis || '' });
						bf.patchValues({ recommendations: value?.job_information?.recommendations || '' });
						if (value?.job_information?.purpose) {
							bf.patchValues({ purpose: value?.job_information?.purpose });
						}
					});
			},

			navs: [
				{
					text: 'Upload Custom Report',
					color: 'primary',

					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'warning';
							instance.setMessage(['Do you want to save your changes first?', '', "Clicking 'no' will discard your changes and allow you to upload a new document"]);
							instance.navButtons = [
								{
									text: 'No',
									color: 'alert',
									linkType: 'nextNode',
									nextNode: 'UploadOnsiteAssessmentReport'
								},
								{
									text: 'Yes, Save',
									color: 'Alert',
									linkType: 'submitThenNext',
									nextNode: 'UploadOnsiteAssessmentReport',
									serverCalls: {
										updateJob: {
											errorMessage: 'Job was not updated',
											directCall: (http, store, sq, bf) => {
												return forkJoin([
													store.select(getFullItemTwo)?.pipe(
														filter(x => !!x && Object.keys(x)?.length !== 0),
														take(1),
														map(res => res as any)
													),
													store.select(getSubmissionData)?.pipe(
														skipWhile(x => !x),
														take(1)
													)
												])?.pipe(
													switchMap(([job, submissiondata]: any) => {
														const thisjob = job as any;
														const sub = submissiondata as any;
														if (job && submissiondata) {
															const job_id = thisjob.id;
															const create_sp_report = 1;
															const decsriptionstring = thisjob?.job_information?.description_of_claim + ' ' + sub?.job_information?.description_of_claim;
															const circumstancestring = thisjob?.job_information?.circumstance_of_loss + '' + sub?.job_information?.circumstance_of_loss;
															const recommendstring = thisjob?.job_information?.recommendations + '' + sub?.job_information?.recommendations;
															const diagnosisstring = thisjob?.job_information?.diagnosis + '' + sub?.job_information?.diagnosis;
															const current_state = 25;
															const new_state = 25;
															const data = {
																job_id: job_id,
																create_sp_report: create_sp_report,
																job_information: {
																	circumstance_of_loss: circumstancestring,
																	description_of_claim: decsriptionstring,
																	recommendations: recommendstring,
																	diagnosis: diagnosisstring
																},
																current_state: current_state,
																new_state: new_state,
																purpose: 'SP Report'
															};
															console.log(`for 'update_job' ENDPOINT ==> :`, data);
															return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
														}
														// return empty();
													})
												);
											}
										}
									}
								}
							];
						}
					}
				},
				{
					text: 'Invoice',
					color: 'primary',
					linkType: 'portal',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
					optIntoValidation: false
				}
			]
		},
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		BILLING_INVOICE,
		SAVE_TO_DESKTOP,
		DynamicAnchor: {
			component: 'DynamicAnchorComponent'
		},
		SubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: { heading: 'Your document has been successfully saved. You can return to the workflow.' }
		},
		UploadOnsiteAssessmentReport: {
			hideTabItem: true,
			initFormFields: (bf, item, instance, sq, store) => {
				bf.bigForm.addControl('purpose', new UntypedFormControl('SP Report', [Validators.required]));
			},
			component: 'FLXFileUploadWithPreviewComponent',
			// name: 'Upload Report',
			// showTabs: true,
			inputs: {
				purposes: [{ display: 'Onsite Assessment', value: 'report' }],
				heading: 'Upload TL Report',
				multipleUploads: false,
				// allowedFileTypes: ['pdf'],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			checkValidityForFields: ['purpose'],
			navs: [
				{
					text: 'Upload Report',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					optIntoValidation: false,
					color: 'primary',
					linkType: 'portal',
					serverCalls: {
						uploadReport: {
							errorMessage: "Couldn't upload report",
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								console.log('FILE QUEUE', { fileQueue });
								const body: object = {
									data: fileQueue[0]?.file.base64,
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: 'SP Report',
									filename: `SP Report-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
									// filename: `${bf.bigForm.get('purpose').value}-${bf.bigForm.get('itemTwo').value.id}`
								};
								return http
									.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
										observe: 'events',
										reportProgress: true
									})
									.pipe();
							},
							followUpSuccessCalls: {
								response: {
									errorMessage: 'Job could not be updated',
									// serviceVariable: 'spService',
									directCall: (http, store, sq, bf) => {
										return store.select(getFullItemTwo).pipe(
											skipWhile(x => !x),
											take(1),
											map(job => {
												const spJob = job as any;
												return {
													job_information: spJob.job_information,
													job_id: spJob.id,
													current_state: '25'
													// new_state: '26'
												};
											}),
											switchMap(data => {
												return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
											})
										);
									}
								}
							}
						}
					}
				}
			]
		}

		// EditSuccess: {
		//   component: 'FLXSuccessTickComponent',
		//   inputs: { autoClose: false, heading: 'Edits successfully saved' },
		//   navs: [
		//     {
		//       text: 'Continue',
		//       nextNode: 'UploadOnsiteAssessmentReport'
		//     }
		//   ]
		// },

		// SubmissionSuccessContinue: {
		//   component: 'FLXSuccessTickComponent',
		//   navs: []
		// }
	},

	bigFormToStoreMapper: {
		new_state: [() => 39, 'new_state'],
		current_state: 'current_state',
		description_of_claim: 'job_information.description_of_claim',
		circumstance_of_loss: 'job_information.circumstance_of_loss',
		recommendations: 'job_information.recommendations',
		diagnosis: 'job_information.diagnosis',
		purpose: 'job_information.purpose',
		total: [
			(a, b, bf) => {
				return bf.total + bf.vatRate;
			},
			'job_information.quote_amount'
		],
		invoice_number: 'job_information.quote_number'
	}
};
