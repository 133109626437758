import { claimPermissions } from './configs/permissions/claim.permissions';
import { jobPermissions } from './configs/permissions/job.permissions';
import { ClaimWorkflow } from './configs/workflow/claim-workflow';
import { BuiCreateClaim } from './configs/create-claim/bui-create-claim';
import * as States from './configs/flows';
import { Manifest_0_0_2, getCurrentUser } from '@flexus/core';
import { BuiSearch } from './configs/search/bui-search';
import { applyBuiVirtualStates } from './bui-virtual-state.helper';
import { BuiEditClaim } from './configs/create-claim/bui-edit-claim';
import { Store } from '@ngrx/store';
import { skipWhile, take, map, filter } from 'rxjs/operators';
import { FLXAppMenuComponent } from '../../app-shell-features/app-menu/app-menu.component';

export const bui: Manifest_0_0_2 = {
	version: '0.0.2',
	orgKey: 'bui',
	settings: {
		addActionPanel: true,
		addAppBar: true,
		addAppMenu: true,
		addFooter: true,
		addHeaderActions: true,
		addSearchBanner: true,
		addReminder: true
	},
	appMenu: (instance: FLXAppMenuComponent, store: Store<any>) => {
		return store.select(getCurrentUser).pipe(
			filter(user => !!user),
			map((currentUser: any) => {
				const createRoles = [1, 6, 18];
				const menuItems = [{ id: 1, name: 'Workflow', routerLink: '/workflow', iconType: 'app-menu-workflow' }];

				if (currentUser?.user?.roles?.some(role => createRoles.includes(role))) {
					menuItems.push({ id: 2, name: 'New Claim', routerLink: '/workflow/detail', iconType: 'app-menu-new-claim' });
				}

				return menuItems;
			})
		);
	},
	virtualStatesFunction: applyBuiVirtualStates,
	appSearch: BuiSearch,
	permissions: {
		itemOne: claimPermissions,
		itemTwo: jobPermissions
	},
	manifestItems: {
		workflow: ClaimWorkflow,
		create_item_one: BuiCreateClaim,
		// [8 || 172]: SilEditClaim,
		[States.BUI_1.id]: States.BUI_1,
		[States.BUI_2.id]: States.BUI_2,
		[States.BUI_3.id]: States.BUI_3,
		[States.BUI_4.id]: States.BUI_4,
		[States.BUI_5.id]: States.BUI_5,
		[States.BUI_6.id]: States.BUI_6,
		[States.BUI_7.id]: States.BUI_7,
		[States.BUI_8.id]: BuiEditClaim,
		[States.BUI_9.id]: States.BUI_9,
		[States.BUI_10.id]: States.BUI_10,
		[States.BUI_11.id]: States.BUI_11,
		[States.BUI_12.id]: States.BUI_12,
		[States.BUI_13.id]: States.BUI_13,
		[States.BUI_14.id]: States.BUI_14,
		[States.BUI_15.id]: States.BUI_15,
		[States.BUI_16.id]: States.BUI_16,
		[States.BUI_17.id]: States.BUI_17,
		[States.BUI_18.id]: States.BUI_18,
		[States.BUI_19.id]: States.BUI_19,
		[States.BUI_20.id]: States.BUI_20,
		[States.BUI_26.id]: States.BUI_26,
		[States.BUI_27.id]: States.BUI_27,
		[States.BUI_28.id]: States.BUI_28,
		[States.BUI_29.id]: States.BUI_29,
		[States.BUI_30.id]: States.BUI_30,
		[States.BUI_31.id]: States.BUI_31,
		[States.BUI_32.id]: States.BUI_32,
		[States.BUI_33.id]: States.BUI_33,
		[States.BUI_34.id]: States.BUI_34,
		[States.BUI_35.id]: States.BUI_35,
		[States.BUI_36.id]: States.BUI_36,
		[States.BUI_37.id]: States.BUI_37,
		[States.BUI_38.id]: States.BUI_38,
		[States.BUI_39.id]: States.BUI_39,
		[States.BUI_40.id]: States.BUI_40,
		[States.BUI_41.id]: States.BUI_41,
		[States.BUI_42.id]: States.BUI_42,
		[States.BUI_43.id]: States.BUI_43,
		[States.BUI_44.id]: States.BUI_44,
		[States.BUI_45.id]: States.BUI_45,
		[States.BUI_46.id]: States.BUI_46,
		[States.BUI_47.id]: States.BUI_47,
		[States.BUI_48.id]: States.BUI_48,
		[States.BUI_49.id]: States.BUI_49,
		[States.BUI_50.id]: States.BUI_50,
		[States.BUI_51.id]: States.BUI_51,
		[States.BUI_52.id]: States.BUI_52,
		[States.BUI_53.id]: States.BUI_53,
		[States.BUI_54.id]: States.BUI_54,
		[States.BUI_55.id]: States.BUI_55,
		[States.BUI_56.id]: States.BUI_56,
		[States.BUI_57.id]: States.BUI_57,
		[States.BUI_58.id]: States.BUI_58,
		[States.BUI_59.id]: States.BUI_59,
		[States.BUI_60.id]: States.BUI_60,
		[States.BUI_61.id]: States.BUI_61,
		[States.BUI_62.id]: States.BUI_62,
		[States.BUI_63.id]: States.BUI_63,
		[States.BUI_64.id]: States.BUI_64,
		[States.BUI_65.id]: States.BUI_65,
		[States.BUI_66.id]: States.BUI_66,
		[States.BUI_67.id]: States.BUI_67,
		[States.BUI_68.id]: States.BUI_68,
		[States.BUI_69.id]: States.BUI_69,
		[States.BUI_70.id]: States.BUI_70,
		[States.BUI_71.id]: States.BUI_71,
		[States.BUI_72.id]: States.BUI_72,
		[States.BUI_73.id]: States.BUI_73,
		[States.BUI_74.id]: States.BUI_74,
		[States.BUI_75.id]: States.BUI_75,
		[States.BUI_76.id]: States.BUI_76,
		[States.BUI_77.id]: States.BUI_77,
		[States.BUI_78.id]: States.BUI_78,
		[States.BUI_79.id]: States.BUI_79,
		[States.BUI_80.id]: States.BUI_80,
		[States.BUI_81.id]: States.BUI_81,
		[States.BUI_82.id]: States.BUI_82,
		[States.BUI_83.id]: States.BUI_83,
		[States.BUI_84.id]: States.BUI_84,
		[States.BUI_85.id]: States.BUI_85,
		[States.BUI_86.id]: States.BUI_86,
		[States.BUI_87.id]: States.BUI_87,
		[States.BUI_88.id]: States.BUI_88,
		[States.BUI_89.id]: States.BUI_89,
		[States.BUI_90.id]: States.BUI_90,
		[States.BUI_91.id]: States.BUI_91,
		[States.BUI_92.id]: States.BUI_92,
		[States.BUI_93.id]: States.BUI_93,
		[States.BUI_94.id]: States.BUI_94,
		[States.BUI_95.id]: States.BUI_95,
		[States.BUI_96.id]: States.BUI_96,
		[States.BUI_97.id]: States.BUI_97,
		[States.BUI_98.id]: States.BUI_98,
		[States.BUI_99.id]: States.BUI_99,
		[States.BUI_100.id]: States.BUI_100,
		[States.BUI_101.id]: States.BUI_101,
		[States.BUI_102.id]: States.BUI_102,
		[States.BUI_103.id]: States.BUI_103,
		[States.BUI_104.id]: States.BUI_104,
		[States.BUI_105.id]: States.BUI_105,
		[States.BUI_106.id]: States.BUI_106,
		[States.BUI_107.id]: States.BUI_107,
		[States.BUI_108.id]: States.BUI_108,
		[States.BUI_109.id]: States.BUI_109,
		[States.BUI_110.id]: States.BUI_110,
		[States.BUI_154.id]: States.BUI_154,
		[States.BUI_156.id]: States.BUI_156,
		[States.BUI_161.id]: States.BUI_161,
		[States.BUI_162.id]: States.BUI_162,
		[States.BUI_169.id]: States.BUI_169,
		[States.BUI_170.id]: States.BUI_170,
		[States.BUI_172.id]: States.BUI_172,
		[States.BUI_197.id]: States.BUI_197,
		[States.BUI_198.id]: States.BUI_198
	}
};
