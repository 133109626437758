import { convertDateTimeToTimeStamp } from '@flexus/utilities';

export function GetDecoderSerialNumberDisplay(decoderNumber) {
	if (decoderNumber) {
		if (Array.isArray(decoderNumber)) {
			const numberOfDecoders = decoderNumber.length;
			const decoderDetails = decoderNumber?.join(', ');

			switch (numberOfDecoders) {
				case 0:
					return { 'Decoder Serial Number': 'No serial number available' };
				case 1:
					return { 'Decoder Serial Number': decoderDetails };
				default:
					return { 'Decoder Serial Numbers': decoderDetails };
			}
		}
	} else {
		return { 'Decoder Serial Number': 'No serial number available' };
	}
}

export const getInsuranceInvoiceQueries = (querynote, storeObj, bf) => {
	let returnqueries = [];
	const date = new Date();

	let insurinvoicetemp = storeObj?.['selectedContext']?.fullItemTwo?.job_information.insurance_invoice_queries;
	if (insurinvoicetemp) {
		if (Array.isArray(insurinvoicetemp)) {
			const lastquery = insurinvoicetemp[insurinvoicetemp.length - 1].query;
			const updateQueryObject = { query: lastquery, queryreply: querynote };
			insurinvoicetemp = insurinvoicetemp.slice(0, insurinvoicetemp.length - 1);
			returnqueries = [...insurinvoicetemp, updateQueryObject];
		} else {
			returnqueries = [
				{
					...insurinvoicetemp,
					queryreply: querynote
				}
			];
		}
	}
	return returnqueries;
};

export const getAdditionalCostsQueries = (querynote, storeObj, bf) => {
	let returnqueries = [];
	const date = new Date();
	// CAN ADD THIS BACK IN IF ALL THIS INFO IS MEANT TO BE PASSED AROUND
	// const addcost_query = {
	// 	message: bf?.additional_costs_query ? bf?.additional_costs_query : querynote,
	// 	author_id: bf.author,
	// 	author_name: bf.author_name,
	// 	current_state: bf.current_state,
	// 	next_state: bf.new_state,
	// 	time_stamp: convertDateTimeToTimeStamp(date.toDateString())
	// };
	let additionalcoststemp = storeObj['selectedContext']?.fullItemTwo?.job_information?.additionalcosts_queries;
	if (additionalcoststemp) {
		if (Array.isArray(additionalcoststemp)) {
			const lastquery = additionalcoststemp[additionalcoststemp.length - 1].query;
			const updatedQueryObject = { query: lastquery, queryreply: querynote };
			additionalcoststemp = additionalcoststemp.slice(0, additionalcoststemp.length - 1);
			returnqueries = [...additionalcoststemp, updatedQueryObject];
		} else {
			returnqueries = [
				{
					...additionalcoststemp,
					queryreply: querynote
				}
			];
		}
		return returnqueries;
	}
};
