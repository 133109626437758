import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { BigFormService, PopupService, getFullItemTwo } from '@flexus/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { skipWhile, take, map, switchMap } from 'rxjs/operators';

@Component({
	selector: 'flx-popup',
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.scss']
})
export class FlxPopupComponent {
	isMinimized$ = this.popupService.isMinimized$;
	isPopupVisible$ = this.popupService.isPopupVisible$;

	get formInputs$() {
		return this.popupService.formControlContent$;
	}

	get keyValueList$() {
		return this.popupService.keyValueList$;
	}

	constructor(
    private popupService: PopupService,
    public bf: BigFormService,
    private store: Store<any>,
    @Inject('environment') private environment: any,
    private http: HttpClient, private toastr: ToastrService
    ) {}

	onShowPopup = () => this.popupService.showPopup();

	onToggleMinimize = () => this.popupService.toggleMinimize();

	onClosePopup = () => this.popupService.closePopUp();

	onSubmit = () => {
		if (this.bf?.bigForm?.get('ia_query')?.value?.length < 1) {
			return;
		}

		const query = this.bf.bigForm.get('ia_query').value;

		const job$ = this.store.select(getFullItemTwo).pipe(
			skipWhile(x => !x),
			take(1),
			map(res => res as any)
		);

		job$
			.pipe(
				switchMap((job: any) => {
					const queries = job?.job_information?.ia_query;

					const ia_query = Array.isArray(queries) ? [...queries] : queries && typeof queries === 'object' && Object.keys(queries).length > 0 ? [{ ...queries }] : [];

					const updatedJob = {
						current_state: job.state,
						new_state: 91,
						job_id: job.id,
						job_information: {
							...job.job_information,
							ia_query: [...ia_query, { id: ia_query.length + 1, query, query_datetime: new Date().toISOString(), queryreply: '', queryreply_datetime: '' }]
						}
					};

					return this.http.post(`${this.environment.api_url}v1/job_action/update_job/`, updatedJob);
				})
			)
			.subscribe(() => this.toastr.success('Query response submitted successfully'));
		this.popupService.onSubmitClosePopup();
	};
}
