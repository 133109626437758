import { Injectable, ComponentFactoryResolver, ViewContainerRef, Renderer2, RendererFactory2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { clone } from 'ramda';
import { BigFormService, ManifestController, ManuallySetMockContextData, StoreQuery, getActiveOrganization, getCurrentUser, getData, getFullItemOne, getFullItemTwo } from '@flexus/core';

/** The service that actually places a component in its host. */
@Injectable()
export class DynamicLoaderService {
	static previousComp: any = null;
	static previousNode: any = null;
	static currentComp: any = null;
	static currentNode: any = null;
	renderer: Renderer2;
	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private rendererFactory: RendererFactory2,
		private store: Store<any>,
		private controller: ManifestController<any>,
		private storeQuery: StoreQuery,
		private bf: BigFormService
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	/** A pure function for loading a component into a viewContainerRef */
	loadComponent(component, vcr: ViewContainerRef, componentInputs?: { [key: string]: any }, node?: any) {
		const factory = this.componentFactoryResolver.resolveComponentFactory(component);
		vcr.clear();
		// ONDESTROY
		const prevSubs: Subscription[] = [];
		if (DynamicLoaderService.previousComp && DynamicLoaderService.previousNode) {
			this.intersectDataOnDestroy(DynamicLoaderService.previousComp, DynamicLoaderService.previousNode, prevSubs);
		}

		// CREATE COMPONENT
		const comp = vcr.createComponent(factory);
		DynamicLoaderService.currentNode = node;
		DynamicLoaderService.currentComp = comp;
		DynamicLoaderService.previousComp = DynamicLoaderService.currentComp;
		DynamicLoaderService.previousNode = DynamicLoaderService.currentNode;

		// ASSIGN INPUTS
		const subs: Subscription[] = [];
		this.autoAssignInputs(factory, DynamicLoaderService.currentComp, componentInputs, DynamicLoaderService.currentNode, subs);

		// ONINIT
		this.runPermissionsOnInit(DynamicLoaderService.currentComp, DynamicLoaderService.currentNode, subs);

		// ON COMP DESTROY
		comp.onDestroy(() => {
			//
			// console.log('cleaning up subssss');
			subs.forEach(sub => sub.unsubscribe());
			// comp.destroy();
		});
	}

	private runPermissionsOnInit(comp, node: any, subs: Subscription[]) {
		const compOnInit = (<any>comp.instance).ngOnInit;
		if (compOnInit) {
			(<any>comp.instance).ngOnInit = () => {
				compOnInit.call(comp.instance);
				if (node && node.permissions) {
					const permissionsSub = combineLatest(
						this.store.select(getFullItemOne),
						this.store.select(getFullItemTwo),
						this.store.select(getCurrentUser),
						this.controller.select(getActiveOrganization).pipe(take(1))
					).subscribe(([itemOne, itemTwo, user, activeOrg]) => {
						this.bf.addObjectToFormModel('itemOne', clone(itemOne));
						this.bf.addObjectToFormModel('itemTwo', clone(itemTwo));
						//
						Object.values(node.permissions).forEach((fn: Function) => {
							const res = fn(itemOne, itemTwo, user, this.renderer, comp.location.nativeElement, activeOrg, comp.instance);
							if (res) {
								subs.push(res);
							}
						});
					});
					subs.push(permissionsSub);
				}
			};
		} else {
			const ngOnInit = () => {
				if (node && node.permissions) {
					const permissionsSub = combineLatest(
						this.store.select(getFullItemOne),
						this.store.select(getFullItemTwo),
						this.store.select(getCurrentUser),
						this.controller.select(getActiveOrganization).pipe(take(1))
					).subscribe(([itemOne, itemTwo, user, activeOrg]) => {
						this.bf.addObjectToFormModel('itemOne', clone(itemOne));
						this.bf.addObjectToFormModel('itemTwo', clone(itemTwo));
						//
						Object.values(node.permissions).forEach((fn: Function) => {
							const res = fn(itemOne, itemTwo, user, this.renderer, comp.location.nativeElement, activeOrg, comp.instance);
							if (res) {
								subs.push(res);
							}
						});
					});
					subs.push(permissionsSub);
				}
			};
			ngOnInit();
		}
	}

	private intersectDataOnDestroy(comp, node: any, subs: Subscription[]) {
		// const compOnDestroy = (<any>comp.instance).ngOnDestroy;
		if (node && node.intersectData) {
			node.intersectData(this.bf, comp.instance);
		}
		// if (compOnDestroy) {
		//   (<any>comp.instance).ngOnDestroy = () => {
		//     if (node && node.intersectData) {
		//       console.log('running intersector...');
		//       node.intersectData(this.bf, comp.instance);
		//     }
		//     compOnDestroy.call(comp.instance);
		//     subs.forEach((s) => {
		//       if (s) s.unsubscribe();
		//     });
		//   };
		// } else {
		//   comp.onDestroy(() => {
		//     if (node && node.intersectData) {
		//       console.log('running intersector2...');
		//       node.intersectData(this.bf, comp.instance);
		//     }
		//   });
		// }
	}

	private autoAssignInputs(factory, comp, componentInputs: { [key: string]: any }, node: any, subs: Subscription[]) {
		const propNames = factory.inputs.map(input => input.propName);
		const observableInputs = propNames.filter(name => name.endsWith('$'));

		// USING SERVER MOCKS
		if (componentInputs && componentInputs.useMockContextData && observableInputs.length) {
			if (node.mockContextData) {
				this.store.dispatch(new ManuallySetMockContextData(node.mockContextData));
			}
		}

		// SERVICE OBSERVABLE INPUTS
		if (observableInputs.length) {
			observableInputs.forEach(input => {
				const key = input.split('$')[0];
				comp.instance[input] = this.store.select(getData).pipe(map(data => data?.[key]));
			});
		}

		// ASSIGN FORM FIELD VALUES IF ANY
		if (node && node.initFormFields) {
			// console.log('running init form');
			// comp.instance['formFields'] = node.initFormFields(componentInputs.editableItem);
			const initFormSubscription = node.initFormFields(this.bf, componentInputs.editableItem, comp.instance, this.storeQuery, this.store);
			if (initFormSubscription) {
				subs.push(initFormSubscription);
			}
		}

		// SCALAR INPUTS
		if (componentInputs) {
			Object.entries(componentInputs).forEach(([key, value]) => {
				comp.instance[key] = value;
			});
		}
	}

	// getter for the current component
	get component() {
		return DynamicLoaderService.currentComp;
	}
}
