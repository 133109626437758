import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  EventEmitter,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  AnimationEvent,
} from '@angular/animations';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getActiveTheme, SetTheme } from '@flexus/ux';
import {
  getActiveOrganization,
  LogOut,
  getCurrentUser,
  SetActiveManifestItem,
  getOrgKey,
  ManifestController,
  NavService,
  InitializeTempData,
  BigFormService,
  CloseAppMenu,
  getUsersLoaded,
} from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { RIGHT_ARROW } from '@angular/cdk/keycodes';
// import { AppMenuOverlayService } from './app-menu.service';

const ESCAPE = 27;
const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  selector: 'flx-app-menu',
  templateUrl: 'app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideContent', [
      state('void', style({ transform: 'translate3d(-25% ,0,0)', opacity: 0 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state(
        'leave',
        style({ transform: 'translate3d(-25%, 0,0)', opacity: 0 })
      ),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ]),
  ],
})
export class FLXAppMenuComponent implements OnInit {
  @Input() opened = false;
  version: string;
  currentUser$: Observable<any>;
  themeCheckedStatus: boolean;
  theme = '';
  navItems;
  logo_url: string;
  second_logo_url: string;
  animationState: 'void' | 'enter' | 'leave' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();
  client;
  isMC: boolean = false;
  usersLoaded$: Observable<boolean>;
  loadedSub: Subscription;

  @HostListener('document:click', ['$event.target'])
  listenToClickOutsideMenu(targetElement) {}

  // @HostListener('document:keydown', ['$event']) private handleKeyDown(event: KeyboardEvent) {
  //   const key = event.key;
  //   if (key === 'Escape') {
  //     this.appMenuOverlayService.appMenuRef.close();
  //   }
  // }

  constructor(
    private router: Router,
    private controller: ManifestController<any>,
    private _store: Store<any>,
    private cdr: ChangeDetectorRef,
    private navService: NavService,
    private bf: BigFormService // public appMenuOverlayService: AppMenuOverlayService
  ) {}

  ngOnInit() {
    this.usersLoaded$ = this._store.select(getUsersLoaded);
    this.usersLoaded$
      .pipe(
        skipWhile((data) => !data),
        take(1),
        map((result) => result)
      )
      .subscribe((user) => console.log('here is user loaded state', user));
    // .pipe(
    // 	skipWhile(loadstatus => !loadstatus),x
    // 	take(1),
    // 	map(result => result)
    // );
    // this.loadedSub = this.usersLoaded$.subscribe();
    if (environment.client) {
      this.client = environment.client;
      console.log('client', this.client);
    }
    if (environment.branding) {
      this.logo_url = environment.branding.logo_url;
      if (environment.client === 'mul' || environment.client === 'mul_sp') {
        this.second_logo_url = '';
        this.isMC = true;
      } else {
        this.second_logo_url = '';
        this.isMC = false;
      }
    } else {
      this.logo_url = 'assets/images/4-sure-logo.svg';
    }
    this.getOrg();
    this.currentUser$ = this._store.select(getCurrentUser);
    this.version = environment.version;

    if (environment.branding) {
      this.logo_url = environment.branding.logo_url;
    }

    this._store
      .select(getActiveTheme)
      .pipe(take(1))
      .subscribe((value) => {
        this.theme = value;
        // This should be done better. Need to look at making
        // more flexible in the future
        switch (value) {
          case 'midday':
            this.themeCheckedStatus = true;
            break;
          case 'blackout':
            this.themeCheckedStatus = false;
            break;
          default:
            this.themeCheckedStatus = false;
            break;
        }
      });
  }

  getOrg() {
    this.controller
      .select(getActiveOrganization)
      .pipe(
        skipWhile((org) => !org),
        take(1),
        map((org) => org)
      )
      .subscribe((org) => {
        org.appMenu(this, this._store).subscribe((menuItems) => {
          // if (environment.client === 'mul_sp') {
          for (let i = 0; i < menuItems.length; i++) {
            if (menuItems[i]?.name === 'Chart') {
              menuItems.splice(1, i);
            }
          }
          // }
          this.navItems = menuItems;
        });
      });
  }

  // no more router link, this will let you do checks before navigating. which will eventually fold into actions which are dispatched to the router store
  redirect(navItem) {
    this._store.dispatch(new CloseAppMenu());
    switch (navItem.routerLink) {
      case '/workflow/detail': {
        switch (navItem.name) {
          case 'New Incident':
          case 'New Claim':
            {
              this.bf.bigForm.reset();
              this._store.dispatch(new InitializeTempData());
              this.controller
                .select(getOrgKey)
                .pipe(take(1))
                .subscribe((key) => {
                  this.controller.dispatch(
                    new SetActiveManifestItem({
                      pathToFlows: ['manifestItems'],
                      orgKey: key,
                      itemId: 'create_item_one',
                    })
                  );
                  this.router.navigate([navItem.routerLink], {
                    skipLocationChange: true,
                  });
                });
            }
            break;
            case 'Add User':
						{
							this.bf.bigForm.reset();
							// this._store.dispatch(new InitializeTempData());
							this.controller
								.select(getOrgKey)
								.pipe(take(1))
								.subscribe(key => {
									this.controller.dispatch(
										new SetActiveManifestItem({
											pathToFlows: ['manifestItems'],
											orgKey: key,
											itemId: 'add_user'
										})
									);
									this.router.navigate([navItem.routerLink], { skipLocationChange: true });
								});
						}
						break;
          case 'Staff':
            {
              this.controller
                .select(getOrgKey)
                .pipe(take(1))
                .subscribe((key) => {
                  this.controller.dispatch(
                    new SetActiveManifestItem({
                      pathToFlows: ['manifestItems'],
                      orgKey: key,
                      itemId: 'manage_users',
                    })
                  );
                  this.router.navigate([navItem.routerLink], {
                    skipLocationChange: true,
                  });
                });
            }
            break;
          // ADD ICON BACK IN FOR POST RELEASE 4.1.4 PURPOSES
          case 'Team':
            {
              this.controller
                .select(getOrgKey)
                .pipe(take(1))
                .subscribe((key) => {
                  this.controller.dispatch(
                    new SetActiveManifestItem({
                      pathToFlows: ['manifestItems'],
                      orgKey: key,
                      itemId: 'team_management',
                    })
                  );
                  this.router.navigate([navItem.routerLink], {
                    skipLocationChange: true,
                  });
                });
            }
            break;
          // ADD ICON FOR POST RELEASE 4.1.4 PURPOSES
          // case 'Invoices':
          //   {
          //     this.controller
          //       .select(getOrgKey)
          //       .pipe(take(1))
          //       .subscribe((key) => {
          //         this.controller.dispatch(
          //           new SetActiveManifestItem({
          //             pathToFlows: ['manifestItems'],
          //             orgKey: key,
          //             itemId: 'invoices_payments',
          //           })
          //         );
          //         this.router.navigate([navItem.routerLink], {
          //           skipLocationChange: true,
          //         });
          //       });
          //   }
          //   break;
          case 'ISOI':
            {
              this.controller
                .select(getOrgKey)
                .pipe(take(1))
                .subscribe((key) => {
                  this.controller.dispatch(
                    new SetActiveManifestItem({
                      pathToFlows: ['manifestItems'],
                      orgKey: key,
                      itemId: 'add_new_jobcard',
                    })
                  );
                  this.router.navigate([navItem.routerLink], {
                    skipLocationChange: true,
                  });
                });
            }
            break;

          default:
            {
              this.router.navigate([navItem.routerLink], {
                skipLocationChange: true,
              });
            }
            break;
        }

        break;
      }
      default: {
        this.router.navigate([navItem.routerLink]);
        break;
      }
    }
  }

  toggleEditable(event) {
    if (event.target.checked) {
      this._store.dispatch(new SetTheme({ name: 'midday' }));
    } else {
      this._store.dispatch(new SetTheme({ name: 'blackout' }));
    }
  }

  signout(event) {
    event.preventDefault();
    this.navService.portalActions.next({ call: '', paramFunc: () => {} });
    this._store.dispatch(new LogOut());
    this.cdr.detectChanges();
  }

  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation() {
    this.animationState = 'leave';
  }
}
