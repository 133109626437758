import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromServer } from '../from-server';
import { builders_stock_manager } from '../builders-stock-manager.manifest';
import { environment } from 'apps/studio/src/environments/environment';
import { LoadManifest, GetLoggedInUser, GetAllInfo, ManifestController } from '@flexus/core';

const companies = { builders_stock_manager };

@Injectable({
	providedIn: 'root'
})
export class LoadBuildersStockManagerManifestService {
	private manifest: any;
	constructor(private store: Store<any>, private controller: ManifestController<any>) {}

	load() {
		this.store.dispatch(new GetLoggedInUser(`${environment.api_url}v2/auth/user/`));
		// this.controller.dispatch(new LoadManifest(a));
	}
}
