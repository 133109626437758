import {
	BigFormService,
	Flow_0_0_2,
	MakeServerCall,
	ManifestController,
	ModalService,
	SetNextNode,
	SetPreviousNode,
	getAllInfo,
	getCurrentUser,
	getData,
	getFullItemOne,
	getFullItemTwo,
	getSelectedItemTwo,
	getSubmissionData
} from '@flexus/core';

import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { filter, skipWhile, first, map, take, pluck, switchMap } from 'rxjs/operators';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import moment from 'moment';
import { KVLHeading } from '@flexus/ui-elements';
import { GetDecoderSerialNumberAndVoucerDetailDisplay } from '../reusable/dataManipulations';
import { photoNode } from '../reusable/PHOTO_NODE';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { JobLocation } from '@flexus/ui-elements';
import { MUL_299 } from './MUL_299';
import gql from 'graphql-tag';
import { addObjectProperty, convertDateTimeToTimeStamp } from '@flexus/utilities';
import { AUTOPAY_CLAIM_STATUSES } from '@flexus/utilities';

export const MUL_331: Flow_0_0_2 = {
	id: '331',
	name: 'autopay_payment_deffered_validation_agent',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne)?.pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map((itemOne: any) => {
					let headerStr = 'Autopay deferred';
					if (itemOne) {
						headerStr += ` : ${itemOne?.mid} - ${itemOne?.applicant?.surname}`;
					}
					return headerStr;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	instructions: {
		editRoles: {
			0: 'Resubmit for payment'
		},
		viewRoles: {
			0: 'Job with Multichoice Validation Agent'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		},
		teamleaderLocations: {
			errorMessage: 'Team leader locations not returned!',
			directCall: (http, store, sq, bf) => {
				return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
					pluck('payload'),
					filter(x => !!x),
					map((teamleaders: any[]) => {
						return teamleaders;
					})
				);
			}
		},
		claim_status_fix_nodename: {
			errorMessage: 'Could not get claim status',
			directCall: (_http, _store: Store, sq, _f: BigFormService) => {
				return _store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					map((mapped_job: any) => {
						_f.addControl('fix_nodename', new UntypedFormControl('', []));
						const { office_use } = mapped_job;
						const { claim_status } = office_use;
						switch (claim_status) {
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_02:
								_f.patchValues({ fix_nodename: 'FixVoucherNumber' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_03:
								_f.patchValues({ fix_nodename: 'LinkDevice' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_04:
								_f.patchValues({ fix_nodename: 'FSIALogin' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_05:
								_f.patchValues({ fix_nodename: 'FixVoucherNumber' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_06:
								_f.patchValues({ fix_nodename: 'FixVoucherNumber' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_09:
								_f.patchValues({ fix_nodename: 'InstallerNotAccredited' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_10:
								_f.patchValues({ fix_nodename: 'TechnicianNotQualified' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_18:
								_f.patchValues({ fix_nodename: 'SchedulerTrainingRequired' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_19:
								_f.patchValues({ fix_nodename: 'SchedulerTrainingRequired' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_20:
								_f.patchValues({ fix_nodename: 'SchedulerTrainingRequired' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_21:
								_f.patchValues({ fix_nodename: 'SchedulerTrainingRequired' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_24:
								_f.patchValues({ fix_nodename: 'OldClaim' });
								break;
						}
					})
				);
				return of({});
			}
		}
	},
	startNode: 'JobInformation',
	nodes: {
		JobInformation: {
			initFormFields: () => {},
			showTabs: true,
			...jobInfoNode(331, 341),
			navs: [
				{
					text: 'send query to installer',
					nextNode: 'SendQueryToSP',
					color: 'default',
					location: 'center'
				},

				{
					text: 'problem resolved manually',
					color: 'secondary',

					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated!',
							directCall: (_httP: HttpClient, _store: Store, sq, _bf: BigFormService, ctrl, _modal: ModalService) => {
								_bf.patchValues({ new_state: 349 });
								_modal.openModalDirectly((instance: any) => {
									instance.type = 'warning';
									instance.heading = 'How did you solve the problem?';
									instance.setMessage([
										'Please indicate how the problem was resolved.',
										'Payment could have been made by directly interacting with the Multichoice payment systems.'
									]);
									instance.navButtons = [
										{
											text: 'no payment was required',
											linkType: 'close',
											clickHandler: () => {
												_store.dispatch(
													new MakeServerCall({
														dataKey: 'updateJob',
														errorMessage: 'Could not update job',
														directCall: (_http: HttpClient, _store: Store, sq: any, _f: BigFormService) => {
															return _store
																.select(getFullItemTwo)
																.pipe(
																	skipWhile(x => !x),
																	take(1)
																)
																.pipe(
																	map((job: any) => {
																		const date = new Date();
																		_f.addControl(
																			'payment_required',
																			new UntypedFormControl({ payment_required: 0, timeStamp: convertDateTimeToTimeStamp(date.toDateString()) }, [])
																		);
																		const { job_information, id } = job;
																		const { payments_manually_closed } = job_information;
																		const manually_closed_payments = [];
																		if (!payments_manually_closed) {
																			manually_closed_payments.push(_f.getControl('payment_required').value);
																		} else if (payments_manually_closed) {
																			if (Array.isArray(payments_manually_closed)) {
																				manually_closed_payments.push(...payments_manually_closed, _f.getControl('payment_required').value);
																			}
																		}
																		const job_info_json = JSON.parse(JSON.stringify(job_information));
																		const new_job_information = addObjectProperty('payments_manually_closed', manually_closed_payments, job_info_json);

																		const request_object = {
																			job_id: id,
																			new_state: 349,
																			job_information: new_job_information
																		};
																		return request_object;
																	}),
																	switchMap(data => {
																		return _http.post(`${environment.api_url}v1/job_action/update_job/`, data);
																	})
																);
														}
													})
												);
											},
											nextNode: 'SubmissionSuccess'
										},
										{
											text: 'payment made outside of CSIA ',
											// nextNode: 'SubmissionSuccess',
											linkType: 'close',
											serverFirst: true,
											clickHandler: () => {
												_store.dispatch(
													new MakeServerCall({
														dataKey: 'updateJob',
														errorMessage: 'Could not update job',
														serviceVariable: 'mulService',
														functionName: 'updateJob'
													})
												);
											},
											nextNode: 'SubmissionSuccess'
										}
									];
								});
								return of({});
							}
						}
					}
				},
				{
					text: 'proceed to fix',
					color: 'primary',
					serverFirst: true,
					nextNode: 'DetailsNode'
				}
			]
		},
		DetailsNode: {
			name: 'Job details',
			initFormFields: (_bf: BigFormService) => {
				_bf.patchValues({ new_state: 341 });
			},
			serverCalls: {
				job_details: {
					errorMessage: 'Could not retrieve job details',
					directCall: (_http: HttpClient, _store: Store, _sq, bf, cntrol, modal) => {
						return forkJoin([
							_store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1),
								map((res: any) => res)
							),
							_store.select(getAllInfo).pipe(
								skipWhile(x => !x),
								take(1),
								map((res: any) => res)
							)
						]).pipe(
							map(([result, info]: any) => {
								let appointmentname;
								const { claim, appointment } = result;
								const { appointment_types } = info;

								const installationid = result?.id;
								const claimid = claim?.id;
								const address = claim?.address;
								const appointmentdate = appointment[0]?.range_start;
								const appointmenttype = appointment[0]?.appointment_type;
								for (let i = 0; i < appointment_types?.length; i++) {
									if (appointmenttype === appointment_types[i]?.id) {
										appointmentname = appointment_types[i]?.name;
									}
								}
								const installation_details = {
									Id: `${installationid}`,
									'Claim Id': `${claimid}`,
									Address: `${address}`,
									'Appointment Date': `${moment(appointmentdate).format(moment.HTML5_FMT.DATE)}`,
									'Appointment Time': `${appointmentname} ${moment(appointmentdate).format(moment.HTML5_FMT.TIME)}`
								};
								return [installation_details];
							})
						);
					}
				},
				decoder: {
					errorMessage: 'Cannot get decoder details',
					directCall: (http, store) => {
						return store
							.select(getData)
							.pipe(
								skipWhile(x => !x.voucher),
								pluck('voucher'),
								take(1)
							)
							.pipe(
								map((voucher: any) => {
									const list: any = [];
									const decoder_serial_voucher_detail = GetDecoderSerialNumberAndVoucerDetailDisplay(voucher?.decoder_number, voucher);

									list.push(decoder_serial_voucher_detail);
									return list;
								})
							);
					}
				},
				extra_equipment: {
					errorMessage: 'Extra equipment detail could not be retrieved',
					directCall: () => {
						return of([
							{
								'Dish QR': 'OB1234566',
								'Bracket Code': 'BR09093_U09',
								'LNB QR Code': 'IO_6773_sE',
								'Additional Equipment': '...additional equipment'
							}
						]);
					}
				}
			},
			showTabs: true,
			component: {
				children: [
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Installation Details', 'secondary'),
							// color: 'secondary',
							data$: 'job_details',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Decoder', 'secondary'),
							data$: 'decoder',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Additional Equipment', 'secondary'),
							data$: 'extra_equipment',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'send query to installer',
					location: 'center',
					color: 'default',
					nextNode: 'SendQueryToSP'
				},

				{
					text: 'problem resolved manually',
					color: 'secondary',

					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated!',
							directCall: (_httP: HttpClient, _store: Store, sq, _bf: BigFormService, ctrl, _modal: ModalService) => {
								_bf.patchValues({ new_state: 349 });
								_modal.openModalDirectly((instance: any) => {
									instance.type = 'warning';
									instance.heading = 'How did you solve the problem?';
									instance.setMessage([
										'Please indicate how the problem was resolved.',
										'Payment could have been made by directly interacting with the Multichoice payment systems.'
									]);
									instance.navButtons = [
										{
											text: 'no payment was required',
											linkType: 'close',
											clickHandler: () => {
												_store.dispatch(
													new MakeServerCall({
														dataKey: 'updateJob',
														errorMessage: 'Could not update job',
														directCall: (_http: HttpClient, _store: Store, sq: any, _f: BigFormService) => {
															return _store
																.select(getFullItemTwo)
																.pipe(
																	skipWhile(x => !x),
																	take(1)
																)
																.pipe(
																	map((job: any) => {
																		const date = new Date();
																		_f.addControl(
																			'payment_required',
																			new UntypedFormControl({ payment_required: 0, timeStamp: convertDateTimeToTimeStamp(date.toDateString()) }, [])
																		);
																		const { job_information, id } = job;
																		const { payments_manually_closed } = job_information;
																		const manually_closed_payments = [];
																		if (!payments_manually_closed) {
																			manually_closed_payments.push(_f.getControl('payment_required').value);
																		} else if (payments_manually_closed) {
																			if (Array.isArray(payments_manually_closed)) {
																				manually_closed_payments.push(...payments_manually_closed, _f.getControl('payment_required').value);
																			}
																		}
																		const job_info_json = JSON.parse(JSON.stringify(job_information));
																		const new_job_information = addObjectProperty('payments_manually_closed', manually_closed_payments, job_info_json);

																		const request_object = {
																			job_id: id,
																			new_state: 349,
																			job_information: new_job_information
																		};
																		return request_object;
																	}),
																	switchMap(data => {
																		return _http.post(`${environment.api_url}v1/job_action/update_job/`, data);
																	})
																);
														}
													})
												);
											},
											nextNode: 'SubmissionSuccess'
										},
										{
											text: 'payment made outside of CSIA ',
											// nextNode: 'SubmissionSuccess',
											linkType: 'close',
											serverFirst: true,
											clickHandler: () => {
												_store.dispatch(
													new MakeServerCall({
														dataKey: 'updateJob',
														errorMessage: 'Could not update job',
														serviceVariable: 'mulService',
														functionName: 'updateJob'
													})
												);
											},
											nextNode: 'SubmissionSuccess'
										}
									];
								});
								return of({});
							}
						}
					}
				},
				{
					text: 'proceed to fix',
					color: 'primary',
					serverFirst: true,
					nextNode: 'PhotoNode'
				}
			]
		},
		PhotoNode: {
			name: 'Photos',
			initFormFields: () => {},
			showTabs: true,
			...photoNode(331, 341),
			navs: [
				{
					text: 'send query to installer',
					location: 'center',
					nextNode: 'SendQueryToSP',
					color: 'default'
				},

				{
					text: 'problem resolved manually',
					color: 'secondary',

					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated!',
							directCall: (_httP: HttpClient, _store: Store, sq, _bf: BigFormService, ctrl, _modal: ModalService) => {
								_bf.patchValues({ new_state: 349 });
								_modal.openModalDirectly((instance: any) => {
									instance.type = 'warning';
									instance.heading = 'How did you solve the problem?';
									instance.setMessage([
										'Please indicate how the problem was resolved.',
										'Payment could have been made by directly interacting with the Multichoice payment systems.'
									]);
									instance.navButtons = [
										{
											text: 'no payment was required',
											linkType: 'close',
											clickHandler: () => {
												_store.dispatch(
													new MakeServerCall({
														dataKey: 'updateJob',
														errorMessage: 'Could not update job',
														directCall: (_http: HttpClient, _store: Store, sq: any, _f: BigFormService) => {
															return _store
																.select(getFullItemTwo)
																.pipe(
																	skipWhile(x => !x),
																	take(1)
																)
																.pipe(
																	map((job: any) => {
																		const date = new Date();
																		_f.addControl(
																			'payment_required',
																			new UntypedFormControl({ payment_required: 0, timeStamp: convertDateTimeToTimeStamp(date.toDateString()) }, [])
																		);
																		const { job_information, id } = job;
																		const { payments_manually_closed } = job_information;
																		const manually_closed_payments = [];
																		if (!payments_manually_closed) {
																			manually_closed_payments.push(_f.getControl('payment_required').value);
																		} else if (payments_manually_closed) {
																			if (Array.isArray(payments_manually_closed)) {
																				manually_closed_payments.push(...payments_manually_closed, _f.getControl('payment_required').value);
																			}
																		}
																		const job_info_json = JSON.parse(JSON.stringify(job_information));
																		const new_job_information = addObjectProperty('payments_manually_closed', manually_closed_payments, job_info_json);

																		const request_object = {
																			job_id: id,
																			new_state: 349,
																			job_information: new_job_information
																		};
																		return request_object;
																	}),
																	switchMap(data => {
																		return _http.post(`${environment.api_url}v1/job_action/update_job/`, data);
																	})
																);
														}
													})
												);
											},
											nextNode: 'SubmissionSuccess'
										},
										{
											text: 'payment made outside of CSIA ',
											// nextNode: 'SubmissionSuccess',
											linkType: 'close',
											serverFirst: true,
											clickHandler: () => {
												_store.dispatch(
													new MakeServerCall({
														dataKey: 'updateJob',
														errorMessage: 'Could not update job',
														serviceVariable: 'mulService',
														functionName: 'updateJob'
													})
												);
											},
											nextNode: 'SubmissionSuccess'
										}
									];
								});
								return of({});
							}
						}
					}
				},
				{
					text: 'proceed to fix',
					color: 'primary',
					serverFirst: true,
					nextNode: 'FixProblem'
				}
			]
		},

		FixProblem: {
			name: 'Fix Problem',
			showTabs: true,
			initFormFields: () => {},
			serverCalls: {
				autopay_fail_reason: {
					errorMessage: 'Could not get the decline reasons',
					directCall: (_http: HttpClient, _store: Store, _sQuery: any) => {
						return _sQuery
							.queryObject(
								gql`
									{
										fullItemTwo {
											office_use {
												claim_status
											}
										}
									}
								`,
								_store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									console.log('QUERY DATA', queryData);
									return Object.values(queryData)[0];
								})
							);
					}
				}
			},
			component: 'FLXAutoPayFixerComponent',
			inputs: {
				autopay_fail_reason: 'autopay_fail_reason'
			},

			// inputs: { title: 'Fix Problem component', instructions: ['develop component to take payment failure reasons for display '] },
			navs: [
				{ text: 'Send query to installer', color: 'default', location: 'center', nextNode: 'SendQueryToSP' },

				{
					text: 'problem resolved manually',
					color: 'secondary',

					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated!',
							directCall: (_httP: HttpClient, _store: Store, sq, _bf: BigFormService, ctrl, _modal: ModalService) => {
								_bf.patchValues({ new_state: 349 });
								_modal.openModalDirectly((instance: any) => {
									instance.type = 'warning';
									instance.heading = 'How did you solve the problem?';
									instance.setMessage([
										'Please indicate how the problem was resolved.',
										'Payment could have been made by directly interacting with the Multichoice payment systems.'
									]);
									instance.navButtons = [
										{
											text: 'no payment was required',
											linkType: 'close',
											clickHandler: () => {
												_store.dispatch(
													new MakeServerCall({
														dataKey: 'updateJob',
														errorMessage: 'Could not update job',
														directCall: (_http: HttpClient, _store: Store, sq: any, _f: BigFormService) => {
															return _store
																.select(getFullItemTwo)
																.pipe(
																	skipWhile(x => !x),
																	take(1)
																)
																.pipe(
																	map((job: any) => {
																		const date = new Date();
																		_f.addControl(
																			'payment_required',
																			new UntypedFormControl({ payment_required: 0, timeStamp: convertDateTimeToTimeStamp(date.toDateString()) }, [])
																		);
																		const { job_information, id } = job;
																		const { payments_manually_closed } = job_information;
																		const manually_closed_payments = [];
																		if (!payments_manually_closed) {
																			manually_closed_payments.push(_f.getControl('payment_required').value);
																		} else if (payments_manually_closed) {
																			if (Array.isArray(payments_manually_closed)) {
																				manually_closed_payments.push(...payments_manually_closed, _f.getControl('payment_required').value);
																			}
																		}
																		const job_info_json = JSON.parse(JSON.stringify(job_information));
																		const new_job_information = addObjectProperty('payments_manually_closed', manually_closed_payments, job_info_json);

																		const request_object = {
																			job_id: id,
																			new_state: 349,
																			job_information: new_job_information
																		};
																		return request_object;
																	}),
																	switchMap(data => {
																		return _http.post(`${environment.api_url}v1/job_action/update_job/`, data);
																	})
																);
														}
													})
												);
											},
											nextNode: 'SubmissionSuccess'
										},
										{
											text: 'payment made outside of CSIA ',
											// nextNode: 'SubmissionSuccess',
											linkType: 'close',
											serverFirst: true,
											clickHandler: () => {
												_store.dispatch(
													new MakeServerCall({
														dataKey: 'updateJob',
														errorMessage: 'Could not update job',
														serviceVariable: 'mulService',
														functionName: 'updateJob'
													})
												);
											},
											nextNode: 'SubmissionSuccess'
										}
									];
								});
								return of({});
							}
						}
					}
				},
				{
					text: 'proceed to fix',
					color: 'primary',
					serverCalls: {
						response: {
							errorMessage: 'Could not set fix node name',
							directCall: (_http, _store, _sq, _f: BigFormService, _cntrllr: ManifestController<any>) => {
								const nextnode = _f.getControl('fix_nodename').value;
								_cntrllr.dispatch(new SetNextNode(`${nextnode}`));
								return of({});
							}
						}
					}
				}
			]
		},
		QRCodeFixesToFSIA: {
			// showTabs: true,
			hideTabItem: true,
			initFormFields: (_f: BigFormService) => {
				_f.patchValues({ new_state: 341 });
			},
			serverCalls: {
				hardware_qrcodes: {
					errorMessage: 'Could not get the decline reasons',
					directCall: (_http: HttpClient, _store: Store, _sQuery: any) => {
						return _store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(fi => !fi),
								take(1),
								map((res: any) => res)
							)
							.pipe(
								map((job: any) => {
									const qrcodeobj = {
										LNB: 'LK0J89K',
										Switch: 'BF0009I_78',
										Dish: 'WW000_787',
										Bracket: '77778987'
									};
									return [qrcodeobj];
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Please launch FSIA to correct the QR Code and resubmit for payment',
							headingConfig: {
								itemMargin: '35px 0 50px 0'
							}
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Supplied Codes',
							data$: 'hardware_qrcodes'
						}
					}
				]
			},
			navs: [
				{
					text: 'resubmit for payment',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						updatejob: {
							errorMessage: 'Could not update job',
							serviceVariable: 'mulService',
							functionName: 'updateJob'
						}
					}
				}
			]
			// component: 'MCQRCodesFixesComponent',
			// inputs: {
			// 	autopay_fail_reason: 'autopay_fail_reason'
			// }
		},
		FSIALogin: {
			component: 'FSIALoginComponent',
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('qrCodeInputItems', new UntypedFormArray([]));
			}
		},
		SendQueryToSP: {
			checkValidityForFields: ['validation_query_installer'],
			showTabs: false,
			initFormFields: (_bf: any, item: any, instance: any, storeQuery: any, store: any) => {
				_bf.patchValues({ new_state: 333, current_state: 331 });
				forkJoin([store.select(getCurrentUser).pipe(filter(x => !!x, take(1))), store.select(getFullItemTwo).pipe(filter(x => !!x, take(1)))])
					.pipe(take(1))
					.subscribe(([author, job]: any) => {
						_bf.patchValues({ author: author.user.id });
						_bf.patchValues({ auhor_name: author.user.full_name });
						_bf.patchValues({ current_state: job?.state });
					});
				_bf.addControl('validation_query_installer', new UntypedFormControl('', [Validators.required]));
			},

			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Send a Query to the Installer',
							formControls: {
								0: {
									label: 'Enter your query',
									inputType: 'textarea',
									placeholder: '',
									// rows: 5,
									formControlName: 'validation_query_installer'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,

					linkType: 'portal',
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Could not update the job.'
						}
					}
				}
			]
		},
		FixDecoderNumber: {
			checkValidityForFields: ['decoder_number'],
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('decoder_number', new UntypedFormControl('', []));
			},
			hideTabItem: true,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: { title: 'Please Enter Correct Decoder Number' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Decoder number',
									inputType: 'input',
									disabled: false,
									rows: 5,
									formControlName: 'decoder_number'
								}
							},
							formLayout: 'stacked',
							containerWidth: '25vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Decoder number could not be updated`,
							directCall: (_http: HttpClient, _store: Store, _storeQuery: any, _bf: BigFormService) => {
								return _storeQuery.queryObject(
									gql`
										{
											fullItemTwo {
												id
											}
										}
									`,

									_store
										.select(getFullItemTwo)
										.pipe(
											skipWhile((data: any) => !data),
											take(1),
											map(data => data)
										)
										.pipe(
											switchMap((result: any) => {
												const job_id = result?.id;
												const voucher_number = _bf.getControl('voucher_number').value;
												return _http.post(`${environment.api_url}v1/mc_actions/update_voucher_number?${voucher_number}&${job_id}`, {
													job_id: job_id,
													voucher_number: voucher_number
												});
											})
										)
								);
							},
							followUpSuccessCalls: {
								displaySuccess: {
									errorMessage: '',
									directCall: (_http, _store, _storeQuery, _bf: BigFormService, con, modal) => {
										return _storeQuery.queryObject(
											gql`
												{
													fullItemTwo {
														id
													}
												}
											`,
											_store
												.select(getFullItemTwo)
												.pipe(
													skipWhile((data: any) => !data),
													take(1),
													map(data => data)
												)
												.pipe(
													switchMap((result: any) => {
														const job_id = result?.id;
														const new_state = 341;
														return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
													})
												)
										);
									}
								}
							},
							followUpFailCalls: {
								displayFail: {
									errorMessage: '',
									directCall: (http, store, sq, bf, con, modal) => {
										con.dispatch(new SetPreviousNode());
										return of(false);
									}
								}
							}
						}
					}
					// nextNode: 'SubmissionSuccess'
				}
			]
		},

		LinkDevice: {
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('device_to_link', new UntypedFormControl('', []));
			},
			hideTabItem: true,
			component: {
				children: [
					{ component: 'FLXHeadingWithInstructionsComponent', inputs: { title: 'Please Enter the Device to Link' } },
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Link device',
									align: 'left',
									inputType: 'input',
									disabled: false,
									rows: 5,
									formControlName: 'device_to_link'
								}
							},
							formLayout: 'stacked',
							containerWidth: '25vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Device could not be linked.`,
							directCall: (_http: HttpClient, _store: Store) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile((data: any) => !data),
										take(1),
										map(data => data)
									)
									.pipe(
										switchMap((result: any) => {
											const job_id = result?.id;
											// const job_id = 111111111111111111111;
											const decoderNumber = 1212;
											// TODO: check endpoint
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: 331 });
											// return _http.post(`${environment.api_url}v1/job_action/link_installed_decoder_to_job/`, { job_id: job_id, decoder_number: decoderNumber });
											// return result;
										})
									);
							},
							followUpSuccessCalls: {
								displaySucces: {
									errorMessage: '',
									directCall: (http, store, sq, bf, con, modal) => {
										bf.getControl('link_device_fixed').setValue(true);

										return of({});
									}
								}
							},
							followUpFailCalls: {
								displayFail: {
									errorMessage: '',
									directCall: (http, store, sq, bf, con, modal) => {
										bf.getControl('link_device_fixed').setValue(false);
										con.dispatch(new SetPreviousNode());
										return of(false);
									}
								}
							}
						}
					}
					// nextNode: 'SubmissionSuccess'
				}
			]
		},

		FixVoucherNumber: {
			checkValidityForFields: ['voucher_number'],
			hideTabItem: true,
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('voucher_number', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{ component: 'FLXHeadingWithInstructionsComponent', inputs: { title: 'Please Enter the Voucher Number' } },
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Voucher Number',
									align: 'left',
									inputType: 'input',
									disabled: false,
									rows: 5,
									formControlName: 'voucher_number'
								}
							},
							formLayout: 'stacked',
							containerWidth: '25vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Voucher number could not be updated`,
							directCall: (_http: HttpClient, _store: Store, _storeQuery: any, _bf: BigFormService, _cntrllr: ManifestController<any>, _modal: ModalService) => {
								return _storeQuery.queryObject(
									gql`
										{
											fullItemTwo {
												id
											}
										}
									`,

									_store
										.select(getFullItemTwo)
										.pipe(
											skipWhile((data: any) => !data),
											take(1),
											map(data => data)
										)
										.pipe(
											switchMap((result: any) => {
												const job_id = result?.id;
												_bf.addControl('job_id', new UntypedFormControl(job_id, []));
												const voucher_number = _bf.getControl('voucher_number').value;
												return _http.post(`${environment.api_url}v1/mc_actions/update_voucher_number?${voucher_number}&${job_id}`, {
													job_id: job_id,
													voucher_number: voucher_number
												});
											})
										)
										.pipe(
											skipWhile(x => !x),
											map((response: any) => {
												if (!response.success) {
													_bf.patchValues({ new_state: 331 });
													_modal.openModalDirectly((instance, store, bf) => {
														instance.type = 'warning';
														instance.color = 'alert';

														instance.heading = 'Invalid Voucher Number';
														instance.setMessage(['The number you entered is not a valid voucher number', 'Please correct it and try again']);
														instance.navButtons = [
															{
																text: 'try again',
																color: 'default',
																linkType: 'close',
																clickHandler: ev => {
																	_cntrllr.dispatch(new SetNextNode('FixVoucherNumber'));
																	store.dispatch(
																		new MakeServerCall({
																			dataKey: 'updateJob',
																			functionName: 'updateJob',
																			serviceVariable: 'mulSpService',
																			errorMessage: 'Could not update job!'
																		})
																	);
																	_bf.patchValues({ voucher_number: '' });
																	return 0;
																}
															}
														];
													});
												} else if (response.success) {
													const job_id = _bf.getControl('job_id')?.value;
													const new_state = 341;
													return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
												}
											})
										)
								);
							}
						}
					}
					// nextNode: 'SubmissionSuccess'
				}
			]
		},

		UploadPhotos: {
			hideTabItem: true,
			initFormFields: (_bf: BigFormService) => {},
			component: 'FLXFileUploadWithPreviewComponent',
			inputs: {
				// configurations
				purposes: [{ display: 'Validation Agent Photo Upload', value: 'Validation Agent Photo Upload' }],
				heading: 'Upload all Missing Photos',
				multipleUploads: false,
				allowedFileTypes: ['pdf', 'image'],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Missing photos not successfully uploaded`,
							directCall: (_http: HttpClient, _store: Store) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile((data: any) => !data),
										take(1),
										map(data => data)
									)
									.pipe(
										switchMap((result: any) => {
											console.log('RESULT', result);
											const job_id = result?.id;
											// const job_id = 111111111111111111111;
											const decoderNumber = 1212;
											// TODO: check endpoint
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: 331 });
											// return _http.post(`${environment.api_url}v1/job_action/link_installed_decoder_to_job/`, { job_id: job_id, decoder_number: decoderNumber });
											// return result;
										})
									);
							},
							followUpSuccessCalls: {
								displaySucces: {
									errorMessage: '',
									directCall: (http, store, sq, bf, con, modal) => {
										bf.getControl('upload_photos_fixed').setValue(true);

										return of({});
									}
								}
							},
							followUpFailCalls: {
								displayFail: {
									errorMessage: '',
									directCall: (http, store, sq, bf, con, modal) => {
										bf.getControl('upload_photos_fixed').setValue(false);
										con.dispatch(new SetPreviousNode());
										return of(false);
									}
								}
							}
						}
					}
					// nextNode: 'SubmissionSuccess'
				}
			]
		},

		InstallerNotAccredited: {
			checkValidityForFields: ['accreditation_number'],
			hideTabItem: true,
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('accreditation_number', new UntypedFormControl('', [Validators.required]));
				_bf.patchValues({ new_state: 341 });
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Please Enter The Correct Accreditation Number'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Accreditation Number',
									align: 'left',
									inputType: 'input',
									disabled: false,
									rows: 5,
									formControlName: 'accreditation_number'
								}
							},
							formLayout: 'stacked',
							containerWidth: '25vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Installer accreditation number could not be updated`,
							directCall: (_http: HttpClient, _store: Store, sq, _f: BigFormService, _cntrllr: ManifestController<any>, _modal: ModalService) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile((data: any) => !data),
										take(1),
										map(data => data)
									)
									.pipe(
										switchMap((result: any) => {
											const job_id = result?.id;
											_f.addControl('job_id', new UntypedFormControl(job_id, []));

											const { sp } = result?.team_leader;
											const sp_id = sp?.id;
											const accreditation_number = _f.getControl('accreditation_number').value;
											return _http.post(`${environment.api_url}v1/sp_action/update_mc_installer_details/`, { sp_id: sp_id, accreditation_number: accreditation_number });
										})
									)
									.pipe(
										skipWhile(res => !res),
										map((response: any) => {
											if (response.success) {
												const job_id = _f.getControl('job_id')?.value;
												const new_state = 341;
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
											} else if (!response.success) {
												// console.log
												_f.patchValues({ new_state: 331 });
												_modal.openModalDirectly(instance => {
													instance.type = 'warning';
													instance.color = 'alert';

													instance.heading = 'Invalid Accreditation Number';
													instance.setMessage(['The number you entered is not a valid accreditation number', 'Please correct it and try again']);
													instance.navButtons = [
														{
															text: 'try again',
															color: 'default',
															linkType: 'close',
															clickHandler: ev => {
																_cntrllr.dispatch(new SetNextNode('InstallerNotAccredited'));
																_store.dispatch(
																	new MakeServerCall({
																		dataKey: 'updateJob',
																		functionName: 'updateJob',
																		serviceVariable: 'mulSpService',
																		errorMessage: 'Could not update job!'
																	})
																);
																_f.patchValues({ accreditation_number: '' });
																return 0;
															}
														}
													];
												});
											}
										})
									);
							}
						}
					}
					// nextNode: 'SubmissionSuccess'
				}
			]
		},
		TechnicianNotQualified: {
			checkValidityForFields: ['edited_voucher_number'],
			hideTabItem: true,
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('technician_number', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: { title: 'Please Enter The Correct Technician Number' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Technician Number',
									align: 'left',
									inputType: 'input',
									disabled: false,
									rows: 5,
									formControlName: 'technician_number'
								}
							},
							formLayout: 'stacked',
							containerWidth: '25vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Technician qualification number could not be updated`,
							directCall: (_http: HttpClient, _store: Store, sq, _f: BigFormService, _cntrllr: ManifestController<any>, _modal: ModalService) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile((data: any) => !data),
										take(1),
										map(data => data)
									)
									.pipe(
										switchMap((result: any) => {
											const job_id = result?.id;
											_f.addControl('job_id', new UntypedFormControl(job_id, []));
											const teamleader = result?.team_leader;
											const tl_id = teamleader?.id;
											const tl_name = teamleader?.full_name;
											// const qualification_number = teamleader?.qualification_number;

											return _http.post(`${environment.api_url}v1/sp_action/update_mc_installer_details/`, {
												tl_id: tl_id,
												tl_name: tl_name,
												qualification_number: _f.getControl('technician_number').value
											});
										})
									)
									.pipe(
										skipWhile(res => !res),
										map((response: any) => {
											if (response.success) {
												const job_id = _f.getControl('job_id')?.value;
												const new_state = 341;
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
											} else if (!response.success) {
												// console.log
												_f.patchValues({ new_state: 331 });
												_modal.openModalDirectly(instance => {
													instance.type = 'warning';
													instance.color = 'alert';

													instance.heading = 'Invalid Qualification Number';
													instance.setMessage(['The number you entered is not a valid qualification number', 'Please correct it and try again']);
													instance.navButtons = [
														{
															text: 'try again',
															color: 'default',
															linkType: 'close',
															clickHandler: ev => {
																_cntrllr.dispatch(new SetNextNode('TechnicianNotQualified'));
																_store.dispatch(
																	new MakeServerCall({
																		dataKey: 'updateJob',
																		functionName: 'updateJob',
																		serviceVariable: 'mulSpService',
																		errorMessage: 'Could not update job!'
																	})
																);
																_f.patchValues({ accreditation_number: '' });
																return 0;
															}
														}
													];
												});
											}
										})
									);
							}
						}
					}
				}
			]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			checkValidityForFields: ['appointmentData'],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, Validators.required),
						appointmentTime: new UntypedFormControl(null, Validators.required),
						appointmentTimePicker: new UntypedFormControl(null, Validators.required),
						appointmentDateType: new UntypedFormControl(null, Validators.required),
						skill: new UntypedFormControl('Dish Installation')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				// bf.patchValues({ new_state: 21 });
			},
			serverCalls: {
				claimaintKeyValues: {
					errorMessage: 'Claimant detail could not be retrieved',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									const claimantinfo = {
										'Claimant Details ': {
											'Full name ': `${job.claim?.applicant?.first_name} ` + `${job.claim?.applicant?.surname}`,
											'Contact Number': job?.claim?.loan_information.ContactNumber,
											'Mobile Number ': job?.claim?.loan_information.cellnumber,
											'On-site contact name ': job?.claim?.loan_information.onsiteperson
										}
									};
									return [claimantinfo];
								})
							);
					}
				},
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									return [
										{
											'Client Name': `${qdat.first_name} ${qdat.surname}`
										},
										{ 'Contact Number': `${qdat.contactnumber}` },
										{ 'Mobile Number': `${qdat.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Set Appointment',
					optIntoValidation: true,
					linkType: 'portal',
					color: 'primary',
					nextNode: 'AssignTLNode',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submit]) => {
										const job_id = job?.id;
										const current_state = job?.state;
										const appointment = submit?.appointment;
										const data = {
											job_id: job_id,
											current_state: current_state,
											appointment: appointment
											// new_state: 21
										};
										return data;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							},
							followUpSuccessCalls: {
								response: {
									errorMessage: `Couldn't assign team leader`,
									directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService, _ctrl: ManifestController<any>) => {
										_ctrl.dispatch(new SetNextNode('AssignTLNode'));
										return of({});
									}
								}
							}
						}
					}
				}
			],
			component: 'FLXJobAppointmentComponent'
		},
		AssignTLNode: {
			hideTabItem: true,
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: bf => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl([41], Validators.required));
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getSelectedItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map((res: any) => res)
							)
							.pipe(
								switchMap(job => {
									const { id } = job;
									const job_id = id;
									return http.post(`${environment.api_url}v1/sp_action/manage_list_staff/`, { role_id: 10, image: true, job_id: job_id }).pipe(
										pluck('payload'),
										filter(x => !!x),
										map((teamleaders: any[]) =>
											teamleaders.map(teamLeader => {
												const namestringarr = teamLeader.full_name?.split(' ');
												let mono1, mono2;
												if (!namestringarr[0]) {
													mono1 = ' ';
												} else {
													mono1 = namestringarr[0]?.slice(0, 1);
												}
												if (!namestringarr[1]) {
													mono2 = '';
												} else {
													mono2 = namestringarr[1]?.slice(0, 1);
												}
												const monogram = mono1 + mono2;
												return {
													display: teamLeader.full_name,
													value: teamLeader.id,
													image: teamLeader.image,
													monogram: monogram,
													teamLeader,
													shouldHaveImage: true
												};
											})
										)
									);
								})
							);
					}
				},
				jobLocationData: {
					errorMessage: 'The job location was not returned!',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(j => {
								const job = j as any;
								return http
									.post(`${environment.api_url}v1/job_action/get_job/`, { job_id: job?.id })
									.pipe(
										skipWhile(x => !x),
										take(1),
										map(result => result as any)
									)
									.pipe(
										map(jobloc => {
											console.log('getting job locaton');
											const locationString = jobloc['payload']?.claim?.location;
											const locArray = locationString?.split(',');
											const latitude = +locArray[0];
											const longitude = +locArray[1];
											return new JobLocation(latitude, longitude);
										})
									);
							})
						);
					}
				},
				teamleaderLocations: {
					errorMessage: 'Team leader locations not returned!',
					directCall: (http, store, sq, bf) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
							pluck('payload'),
							filter(x => !!x),
							map((teamleaders: any[]) => {
								console.log('returning team leaders');
								return teamleaders;
							})
						);
					}
				}
			},
			component: 'AssignTLComponent',
			navs: [
				{
					text: 'submit',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't assign team leader!",
							directCall: (http, store, sq, bf, ctrl) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getCurrentUser).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									)
								]).pipe(
									switchMap(([j, uid]) => {
										const job = j as any;
										const job_id = job?.id;
										const team = bf.bigForm.get('assign_teamleader_id')?.value;

										const data = {
											job_id: job_id,
											tl_id: team[0]
										};
										return http.post(`${environment.api_url}v1/mc_action/update_team_leader/`, data);
									})
								);
							},
							followUpSuccessCalls: {
								response: {
									errorMessage: 'Job state could not be updated',
									serviceVariable: 'mulSpService',
									functionName: 'updateJob'
								}
							}
						}
					},

					location: 'right'
				}
			]
		},
		OldClaim: {
			serverCalls: {
				installer_details: {
					errorMessage: 'Cannot get job info summary',
					directCall: (http, store) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								take(1),
								map(info => {
									const { claim, team_leader } = info;
									const list: any = [];
									// Installer details

									list.push({
										company_name: team_leader?.sp?.name ?? '',
										accreditation_number: team_leader?.sp?.accreditation_number ?? '',
										'Installation technician qualification number': team_leader?.qualification_number ?? '',
										'Installer/Technician name': team_leader?.full_name ?? '',
										contact_person: team_leader?.sp?.contact_person ?? '',
										contact_number: team_leader?.sp?.contact_primary ?? ''
									});

									return list;
								})
							);
					}
				}
			},
			initFormFields: (_bf: BigFormService) => {
				_bf.patchValues({ new_state: 341 });
			},
			hideTabItem: true,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Old Claim'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'installer_details'
						}
					}
				]
			},
			navs: [
				{
					text: 'proceed to fix',
					color: 'primary',
					serverCalls: {
						response: {
							errorMessage: 'Could not set fix node name',
							directCall: (_http, _store, _sq, _f: BigFormService, _cntrllr: ManifestController<any>) => {
								const nextnode = _f.getControl('fix_nodename').value;
								_cntrllr.dispatch(new SetNextNode(`${nextnode}`));
								return of({});
							}
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: {},
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		validation_query_installer: [
			(query, storeobj, _bf) => {
				let validation_query_responses = [];
				const date = new Date();
				const newquery = {
					query: query,
					author_id: _bf.author,
					author_name: _bf.author_name,
					currentState: _bf.current_state,
					timeStamp: convertDateTimeToTimeStamp(date.toDateString())
				};
				const stored_queries = storeobj['selectedContext']?.fullItemTwo?.job_information?.autopay_validation_queries;
				if (!stored_queries) {
					validation_query_responses.push(newquery);
				}
				if (Array.isArray(stored_queries)) {
					if (stored_queries.length === 0) {
						validation_query_responses.push(newquery);
					} else {
						validation_query_responses.push(...stored_queries, newquery);
					}
				}

				return validation_query_responses;
			},
			'job_information.autopay_validation_queries'
		]
	}
};
