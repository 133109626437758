import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BigFormService, Flow_0_0_2, getFullItemOne, getFullItemTwo, } from '@flexus/core';
import { CustomValidators } from '@flexus/utilities';
import { select, Store } from '@ngrx/store';
import { setActionPanelItems, CollapseActionPanel } from 'apps/studio/src/app/app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { skipWhile, take, map, switchMap } from 'rxjs/operators';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { PGG_261 } from './PGG_261';
import { deleteObjectProperty, addObjectProperty } from '@flexus/utilities';
import { HttpClient } from '@angular/common/http';

export const PGG_273: Flow_0_0_2 = {
	id: '273',
	name: 'excessive_additional_fees',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),

	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Request SP Incentive';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		...PGG_261.serverCalls
	},
	instructions: {
		editRoles: {
			0: 'Approval of Installer Incentive'
		},
		viewRoles: {
			0: 'Approve Installer Incentive'
		}
	},
	startNode: 'IncentiveApprovalNotification',
	nodes: {
		IncentiveApprovalNotification: {
			initFormFields: (_bf: any, item: any, _inst: any, _sq: any, _store: Store) => {
				_store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(cb => !cb),
						take(1)
					)
					.subscribe((result: any) => {
						const values = result?.claim?.loan_information?.values;
						const jobinfo = result?.job_information;
						const incentive = +jobinfo?.installer_incentive_amount;

						const client_value = values?.client_value;
						const sp_value = values?.sp_value;
						_bf.addControl(
							'amounts',
							new UntypedFormGroup({
								ins_takealot_fee: new UntypedFormControl('', []),
								ins_installer_fee: new UntypedFormControl('', []),
								ins_installer_incentive: new UntypedFormControl(null, [CustomValidators.numeric]),
								ins_installer_additional: new UntypedFormControl(null, [CustomValidators.numeric]),
								ins_sure_fee: new UntypedFormControl('', []),
								cus_takealot_fee: new UntypedFormControl(client_value, [CustomValidators.numeric]),
								cus_installer_fee: new UntypedFormControl(sp_value, [CustomValidators.currency]),
								cus_installer_incentive: new UntypedFormControl(incentive, [CustomValidators.currency]),
								// cus_installer_additional: new FormControl(null, [CustomValidators.currency]),
								cus_installer_additional: new UntypedFormControl(null, []),
								cus_sure_fee: new UntypedFormControl(0, [])
							})
						);
					});
				_bf.addControl('new_state', new UntypedFormControl(null, []));
				_bf.addControl('incentive_amount', new UntypedFormControl(null, []));
				_bf.addControl('incentive_reason', new UntypedFormControl(null, []));
				_bf.addControl('incentive_comments', new UntypedFormControl(null, []));
				_bf.addControl('claim_value', new UntypedFormControl(null, []));
			},
			name: 'Job Information',
			showTabs: true,
			...jobInfoNode(273, 'Approval of Installer Incentive'),
			navs: [
				{
					text: 'decline',
					color: 'secondary',
					linkType: 'portal',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not update job!',
							directCall: (_http: any, _st: any, _sq: any, _bf: any) => {
								return _st
									.select(getFullItemTwo)
									.pipe(
										skipWhile((selected: any) => !selected),
										take(1),
										map((res: any) => res)
									)
									.pipe(
										switchMap((selectedjob: any) => {
											_bf.patchValues({ new_state: 20 });
											_bf.patchValues({ incentive_amount: 0 });
											const { job_information } = selectedjob;
											const temp_job_info = deleteObjectProperty('installer_incentive_amount', job_information);
											const new_job_info = addObjectProperty('installer_incentive_amount', _bf.bigForm.get('incentive_amount').value, temp_job_info);
											const job_id = selectedjob?.id;
											const state = _bf.getControl('new_state')?.value;
											const request = {
												job_id: job_id,
												new_state: state,
												job_information: new_job_info
											};
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
										})
									);
							}
						}
					}
				},
				{
					text: 'approve',
					color: 'primary',
					linkType: 'portal',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Couldn't update job!`,
							directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
								return _sq
									.queryObject(
										gql`
											{
												fullItemTwo {
													job_information
													sp
													id
												}
											}
										`,
										_store.select(getFullItemTwo).pipe(
											skipWhile((fj: any) => !fj),
											take(1),
											map((result: any) => ({ fullItemTwo: result }))
										)
									)
									.pipe(
										switchMap((query_data: any) => {
											const { job_information, id, sp } = query_data;
											const incentive = +job_information?.installer_incentive_amount;
											const reason = job_information?.installer_incentive_reason;
											const sp_id = job_information?.chosen_sp?.id;
											const request: {} = {
												job_id: id,
												sp_id: sp_id,
												incentive_amount: incentive,
												reason: reason
											};
											// console.log('request 273', incentive);
											// return of({});
											return _http.post(`${environment.api_url}v1/job_action/add_incentive_sp_to_job/`, request);
										})
									);
							}
						}
					}
				}
			]
		},
		PaymentDetails: {
			showTabs: true,
			name: 'Payment Details',
			serverCalls: {
				...PGG_261?.nodes?.RefundQueryCalculator?.serverCalls
			},
			component: {
				children: [
					{
						component: 'PinggoSPPaymentBreakdownComponent',
						inputs: {
							paymentVariables$: 'refundVariablesFromSource'
						}
					}
				]
			},
			navs: [
				{
					text: 'decline',
					color: 'secondary',
					linkType: 'portal',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not update job!',
							directCall: (_http: any, _st: any, _sq: any, _bf: any) => {
								return _st
									.select(getFullItemTwo)
									.pipe(
										skipWhile((selected: any) => !selected),
										take(1),
										map((res: any) => res)
									)
									.pipe(
										switchMap((selectedjob: any) => {
											_bf.patchValues({ new_state: 20 });
											_bf.patchValues({ incentive_amount: 0, incentive_reason: 'overwritten' });
											const { job_information } = selectedjob;
											const temp_job_info = deleteObjectProperty('installer_incentive_amount', job_information);
											const new_job_info = addObjectProperty('installer_incentive_amount', _bf.bigForm.get('incentive_amount').value, temp_job_info);
											const job_id = selectedjob?.id;
											const state = _bf.getControl('new_state')?.value;
											const request = {
												job_id: job_id,
												new_state: state,
												job_information: new_job_info
											};
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
										})
									);
							}
						}
					}
				},
				{
					text: 'approve',
					color: 'primary',
					linkType: 'portal',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Couldn't update job!`,
							directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
								return _sq
									.queryObject(
										gql`
											{
												fullItemTwo {
													job_information
													id
												}
											}
										`,
										_store.select(getFullItemTwo).pipe(
											skipWhile((fj: any) => !fj),
											take(1),
											map((result: any) => ({ fullItemTwo: result }))
										)
									)
									.pipe(
										switchMap((query_data: any) => {
											const { job_information, id } = query_data;
											_bf.patchValues({ incentive_amount: _bf.getControl('amounts.cus_installer_incentive').value });
											const temp_job_info = { ...job_information, installer_incentive_amount: _bf.getControl('amounts.cus_installer_incentive').value };

											console.log('job information', temp_job_info);
											const request: {} = {
												job_id: id,
												job_information: temp_job_info
											};
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
										})
									);
							},
							followUpSuccessCalls: {
								response: {
									errorMessage: `Couldn't update job!`,
									directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
										return _sq
											.queryObject(
												gql`
													{
														fullItemTwo {
															job_information
															id
														}
													}
												`,
												_store.select(getFullItemTwo).pipe(
													skipWhile((fj: any) => !fj),
													take(1),
													map((result: any) => ({ fullItemTwo: result }))
												)
											)
											.pipe(
												switchMap((query_data: any) => {
													const { job_information, id } = query_data;
													const incentive = +_bf.getControl('amounts.cus_installer_incentive').value;
													const reason = job_information?.installer_incentive_reason;
													const sp_id = job_information?.chosen_sp?.id;
													const request: {} = {
														job_id: id,
														sp_id: sp_id,
														incentive_amount: incentive,
														reason: reason
													};
													return _http.post(`${environment.api_url}v1/job_action/add_incentive_sp_to_job/`, request);
												})
											);
									}
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		incentive_amount: [
			(inc_amount: any) => {
				return inc_amount;
			},
			'job_information.installer_incentive_amount'
		]
	}
};
