export enum silRoles {
	ALL = 0,
	CLAIM_HANDLER = 1,
	INSURER_ASSESSOR = 2,
	INSURER_FIELD_AGENT_SIL = 3,
	INSURER_FIELD_AGENT_SP = 4,
	DISBURSEMENT_OFFICER = 5,
	FRONTLINE_MANAGER = 6,
	CENTRAL_MANAGEMENT = 7,
	ADMINISTRATOR = 8,
	SP_JOB_SCHEDULER = 9,
	SP_TEAM_LEADER = 10,
	PAYMENTS_AND_RATINGS = 11,
	SUPPLY_CHAIN_MANAGER = 12,
	LOSS_ADJUSTER = 13,
	VERIFICATION_OFFICER = 14,
	RISK_FRAUD_OFFICER = 15
}
