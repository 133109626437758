import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FLXTeamleadPickerComponent } from './teamlead-picker.component';
import { ScriptLoaderDirective } from './script-loader.directive';
import { TeamleadPickerService } from './teamlead-picker.service';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	declarations: [FLXTeamleadPickerComponent, ScriptLoaderDirective],
	exports: [FLXTeamleadPickerComponent],
	providers: [TeamleadPickerService]
})
export class FLXTeamleadPickerModule {}
