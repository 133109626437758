import { Flow_0_0_2, getData, getCurrentUser } from '@flexus/core';
import { map, take, filter, skipWhile, first, pluck } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { getSelectedItemOne, getFullItemTwo, getAllInfo } from '@flexus/core';
import { empty, forkJoin, EMPTY } from 'rxjs';
import gql from 'graphql-tag';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { BUI_27 } from './BUI_27';

export const BUI_48: Flow_0_0_2 = {
	id: '48',
	name: 'invoice_updated',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				filter(x => !!x),
				skipWhile((itemTwo: any) => {
					return itemTwo === null || itemTwo === undefined;
				}),
				first(itemTwo => itemTwo !== null || itemTwo !== undefined),
				map((itemTwo: any) => {
					if (itemTwo) {
						return `Invoice Updated : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Invoice Updated';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Review the invoice query'
		},
		viewRoles: {
			0: '--'
		}
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	fetchLevel1And2: true,
	serverCalls: {
		invoice: {
			serviceVariable: 'buiService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		dataFiles: {
			errorMessage: 'No invoice found!',
			directCall: (http, store, sq) => {
				// return throwError('something went wrong');
				return sq
					.queryObject(
						gql`
							{
								invoice {
									data
								}
							}
						`,
						store.select(getData)
					)
					.pipe(
						filter(x => !!x && Object.keys(x)?.length !== 0),
						take(1),
						map(data => data['data'])
					);
			}
		}
	},
	events: {
		invalidateQueryDataIfVatRateIsSet: {
			triggerOn: 'vat_rate',
			triggerWhen: rate => !!rate,
			dataMutations: bf => {
				bf.patchValues({
					query: null,
					new_state: 50
				});
			}
		},

		invalidatePaymentDataIfQueryIsSet: {
			triggerOn: 'query',
			triggerWhen: qr => !!qr,
			dataMutations: bf => {
				bf.patchValues({
					claim_value: null,
					update_excess: null,
					invoice_number: null,
					vat_rate: null,
					nextApprovalStaffName: null,
					new_state: 46
				});
			}
		}
	},

	startNode: 'PaymentPreview',

	nodes: {
		...BUI_27?.nodes,
		PaymentPreview: {
			inputs: { inputHeading: 'Payment Preview' },
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList2', heading: 'Queries' }
					}
				]
			},
			serverCalls: {
				...BUI_27?.nodes?.PaymentPreview?.serverCalls,
				keyValueList2: {
					errorMessage: '',
					directCall: (http, store) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							map(job => {
								const query =
									job?.job_information?.invoicequery && Array.isArray(job?.job_information?.invoicequery)
										? job?.job_information?.invoicequery
										: job?.job_information?.invoicequery
										? [job?.job_information?.invoicequery]
										: [];

								return query;
							})
						);
					}
				}
			},

			initFormFields: (bf, item, instance, sq, store) => {
				store
					.select(getCurrentUser)
					.pipe(
						skipWhile(x => !x),
						take(1)
					)
					.subscribe(user => {
						bf.patchValues({ paymentapproval1: user?.user.id });
					});

				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									job_creator
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x)?.length !== 0),
						take(1)
					)
					.subscribe(job => {
						bf.patchValues({ paymentapprovalhandler: job?.job_creator });
					});
			},

			navs: [
				{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'alert' },
				{
					text: 'Approve Invoice',
					nextNode: 'PaymentConfirm',
					color: 'primary'
				}
			]
		}
	},

	bigFormToStoreMapper: {
		nextApprovalStaffName: [
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.nextApprovalStaffName'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'job_information.authid'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return 0;
				},
				'authid'
			]
		],
		current_state: 'current_state',
		new_state: 'new_state',
		vat_rate: 'vat_rate',
		claim_value: ['claim_value', 'job_information.claim_value'],
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		update_excess: ['update_excess', 'job_information.update_excess'],
		query: [
			(qr, storeObj) => {
				let iaq = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo &&
					storeObj['selectedContext']?.fullItemTwo.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery)) {
						iaq = storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery;
					} else {
						iaq = [
							{
								...storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
							}
						];
					}
				}
				const invoicequery = [...iaq, { query: qr, queryreply: '' }];
				return invoicequery;
			},
			'job_information.invoicequery'
		]
	}
};
