import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { map } from 'rxjs/operators';
import { DisplayTypes } from '../../../models/display-types';

export const BET_VIEW_TIME_STAMPS: Flow_0_0_2 = {
	id: 'ViewTimeStamps',
	itemType: 'flow',
	name: 'View Time Stamps',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `Time Stamps on this claim : ${itemOne.mid} - ${itemOne.applicant?.surname}`;
					} else {
						return 'Time Stamps on this claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ViewTimeStamps',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		ViewTimeStamps: {
			component: 'FLXTimeStampsComponent',
			inputs: {
				heading: 'Time Stamps',
				// config: { DisplayType: DisplayTypes.FullScreen, showHeading: true }
				config: { DisplayType: DisplayTypes.FullScreen }
			},
			initFormFields: () => {},
			navs: []
		}
	},
	excludeForOnlyStates: [169],
	bigFormToStoreMapper: {},
	serverCalls: {}
};
