<div class="dynamic-component">
	<ng-template #loader> </ng-template>

	<div class="content">
		<flx-tabs class="tabs" [tabs]="tabs" [currentStep$]="currentTab$" [externalPages]="true" (outputEventStream)="handleTab($event)">
			<flx-heading size="medium" align="center" weight="extra-light"> {{ location === 'flow-photos' ? 'Photos' : 'Documents' }}</flx-heading>

			<div id="photos" class="tab-content" contents="photos" *ngIf="(currentTab$ | async) === 'photos'">
				<ng-container *ngIf="photos$ | async as photos; else loader">
					<flx-file-browser
						[showFilters]="false"
						paginationId="photos"
						*ngIf="photos?.length > 0; else noPhotos"
						[files$]="photos$"
						display="image-grid"
						[location]="location"
					></flx-file-browser>
				</ng-container>
				<ng-template #noPhotos> <flx-alert type="no-info" missingItem="photos"></flx-alert> </ng-template>
			</div>

			<div id="quotations" class="tab-content" contents="quotations" *ngIf="(currentTab$ | async) === 'quotations'">
				<ng-container *ngIf="quotations$ | async as quotations; else loader">
					<flx-file-browser
						[showFilters]="false"
						paginationId="quotations"
						*ngIf="quotations?.length > 0; else noQuotations"
						[files$]="quotations$"
						display="file-grid"
						[location]="location"
					></flx-file-browser>
				</ng-container>
				<ng-template #noQuotations> <flx-alert type="no-info" missingItem="quotations"></flx-alert> </ng-template>
			</div>

			<div id="invoices" class="tab-content" contents="invoices" *ngIf="(currentTab$ | async) === 'invoices'">
				<ng-container *ngIf="invoices$ | async as invoices; else loader">
					<flx-file-browser
						[showFilters]="false"
						paginationId="invoices"
						*ngIf="invoices?.length > 0; else noInvoices"
						[files$]="invoices$"
						display="file-grid"
						[location]="location"
					></flx-file-browser>
				</ng-container>
				<ng-template #noInvoices> <flx-alert type="no-info" missingItem="invoices"></flx-alert> </ng-template>
			</div>

			<div id="reports" class="tab-content" contents="reports" *ngIf="(currentTab$ | async) === 'reports'">
				<ng-container *ngIf="reports$ | async as reports; else loader">
					<flx-file-browser
						[showFilters]="false"
						paginationId="reports"
						*ngIf="reports?.length > 0; else noReports"
						[files$]="reports$"
						display="file-grid"
						[location]="location"
					></flx-file-browser>
				</ng-container>
				<ng-template #noReports> <flx-alert type="no-info" missingItem="reports"></flx-alert> </ng-template>
			</div>
			<div id="latest-documents" class="tab-content" contents="latest-documents" *ngIf="(currentTab$ | async) === 'latest-documents'">
				<flx-heading size="extra-small" align="center" weight="extra-light">Latest Quotation, Invoice and Report </flx-heading>
				<ng-container *ngIf="latestDocuments$ | async as latestDocuments; else loader">
					<flx-file-browser
						[showFilters]="false"
						paginationId="latest-documents"
						*ngIf="latestDocuments?.length > 0; else noLatestDocuments"
						[files$]="latestDocuments$"
						display="file-grid"
						[location]="location"
					></flx-file-browser>
				</ng-container>
				<ng-template #noLatestDocuments> <flx-alert type="no-info" missingItem="documents"></flx-alert> </ng-template>
			</div>
		</flx-tabs>
	</div>
</div>
