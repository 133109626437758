import { Component, OnInit, OnDestroy, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, of, Observable } from 'rxjs';
import { take, filter, pluck, map, tap, skipWhile, switchMap } from 'rxjs/operators';
import { BigFormService, getFullItemOne, getClaimTypes } from '@flexus/core';
import { Store } from '@ngrx/store';
import { CurrencyPipe } from '@angular/common';

@Component({
	selector: 'flx-assign-ia',
	templateUrl: './assign-ia.component.html',
	styleUrls: ['./assign-ia.component.scss']
})
export class AssignIAComponent implements OnInit, AfterViewInit, OnDestroy {
	// ===========================================  Variables ===========================================================
	@Input()
	iaListData$;
	@Input()
	fullClaim$;
	displayConfig = { displayKey: 'display', valueKey: 'value' };
	@Input() IAreasons$: Observable<any>;
	changeReason = false;

	public assignIAControl = new UntypedFormControl('assign_assessor_id', [Validators.required]);

	public formattedData$;
	public selectedInternalAssessorId: number;
	public selectedInternalAssessorItem$;

	private _formControlSubscription: Subscription;
	private _dataSubscription: Subscription;
	private _internalAssessors: any[];
	private _claimSubscription: Subscription;
	private _claimAddress: string;
	private _sumInsured: string;
	private _reason: string;
	private _claimType: string;
	private _claimName: string;
	private _contactNumber: string;

	// ============================================ Constructor =========================================================
	constructor(private cdr: ChangeDetectorRef, public bf: BigFormService, private store: Store<any>) {}

	// ============================================= Methods ============================================================
	// ----------------------------------------- Life-cycle methods -----------------------------------------
	ngOnInit() {}

	ngAfterViewInit() {
		this.formattedData$ = this.iaListData$.pipe(
			filter(x => !!x),
			map(
				(internalAssessors: any[]) =>
					internalAssessors &&
					internalAssessors.map(iaItem => {
						return {
							display: iaItem.full_name,
							value: iaItem.id
						};
					})
			)
		);
		this.cdr.detectChanges();

		this._dataSubscription = this.iaListData$
			.pipe(
				filter(x => !!x),
				take(1)
			)
			.subscribe(data => {
				this._internalAssessors = data;
			});

		this._claimSubscription = this.store
			.select(getFullItemOne)
			.pipe(
				skipWhile(x => !x),
				take(1),
				switchMap(itemOne =>
					this.store.select(getClaimTypes).pipe(
						map(types => types?.filter(type => type?.id === itemOne?.claim_type)),
						map(claimType => {
							if (claimType?.length === 1) {
								return { ...itemOne, claim_type: claimType[0] };
							}
							return itemOne;
						}),
						take(1)
					)
				)
			)
			.subscribe(claim => {
				this._claimType = claim?.claim_type.name;
				this._claimAddress = claim?.address;
				this._sumInsured = new CurrencyPipe('en-US').transform(Number(claim?.loan_information.suminsured), 'R', true);
				this._reason = claim?.loan_information.ia_requested_reason;
				this.bf.bigForm.get('ia_request_reason').patchValue(this._reason);
				this._claimName = `${claim?.applicant?.first_name} ${claim?.applicant?.surname}`;
				this._contactNumber = claim?.loan_information.contactnumber;
				this.selectedInternalAssessorItem$ = of([
					{ 'Claim Type': this._claimType },
					{ Address: this._claimAddress },
					{ 'Client Name': this._claimName },
					{ 'Contact Number': this._contactNumber },
					{},
					{ 'Sum Insured': this._sumInsured },
					{ 'Reason Assessor was requested': this._reason }
				]);
			});

		this._formControlSubscription = this.assignIAControl?.valueChanges?.subscribe(values => {
			if (values && values.length !== 0) {
				this.selectedInternalAssessorId = values[0];
				this.bf.bigForm.get('assign_assessor_id').patchValue(this.selectedInternalAssessorId);
			}
		});
	}

	getInternalAssessor(id: number): any {
		if (this._internalAssessors) {
			return this._internalAssessors.find(iaData => iaData.id === id);
		}
		return null;
	}

	toggleReason() {
		this.changeReason = true;
	}

	ngOnDestroy() {
		if (this._formControlSubscription) {
			this._formControlSubscription.unsubscribe();
		}
		if (this._dataSubscription) {
			this._dataSubscription.unsubscribe();
		}
		if (this._claimSubscription) {
			this._claimSubscription.unsubscribe();
		}
	}
}
