import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbsoluteNumberPipe } from '@flexus/pipes';

@Directive({
	selector: '[allowOnlyAbsoluteNumbers]'
})
export class AllowOnlyAbsoluteNumbersDirective {
	constructor(private _elementRef: ElementRef, private _absoluteNumberPipe: AbsoluteNumberPipe, private _control: NgControl) {}

	@HostListener('blur', ['$event.target'])
	public onBlur(targetElement) {
		if (this._elementRef.nativeElement.value) {
			this._control.control?.setValue(this._absoluteNumberPipe.transform(this._elementRef.nativeElement.value));
		}
	}
}
