import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { map, skipWhile } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const BET_219: Flow_0_0_2 = {
	id: '219',
	name: 'home_assist_payment',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map(itemOne => {
					if (itemOne) {
						return `Home Assist Payment : ${itemOne?.mid} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Home Assist Payment';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance =>
		setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	instructions: {
		editRoles: {
			0: 'Home Assist Payment'
		},
		viewRoles: {
			0: 'Home Assist Payment'
		}
	},
	startNode: 'NoInvoiceDetails',
	nodes: {
		NoInvoiceDetails: {
			component: 'FLXHeadingWithInstructionsComponent',
			name: 'Home Assist Payment',
			inputs: {
				title: 'Home Assist Payment',
				headingConfig: { size: 'medium', weight: 'normal' },
				instructions: ['4-Sure to pay the service amount']
			},
			serverCalls: {},
			initFormFields: bf => {
				bf.patchValues({ new_state: '28' });
			},
			navs: [
				{
					text: 'Payment Done',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						response: {
							serviceVariable: 'betspService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!'
						}
					}
				}
			]
		},
		//submission Nodes ************
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},

	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
