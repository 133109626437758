import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import {
	Flow_0_0_2,
	getFullItemTwo,
	getSelectedItemTwo,
	getCurrentUser,
	getSubmissionData,
	MakeServerCall,
	SetNextNode,
	BigFormService,
	ManifestController,
	SetPreviousNode
} from '@flexus/core';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin, of, EMPTY } from 'rxjs';
import { skipWhile, take, map, pluck, filter, switchMap } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { JobLocation } from '@flexus/ui-elements';
import gql from 'graphql-tag';
import moment from 'moment';
import { values } from 'ramda';
import { SP_GLOBAL_STATES } from 'apps/studio/src/app/sp_globals/configs/flows';
import { convertDateTimeToTimeStamp } from '@flexus/utilities';

// GRAB THE RESPECTIVE STATE OBJECT FROM SP GLOBAL
const fromGlobal = SP_GLOBAL_STATES[21];

export const MUL_21: Flow_0_0_2 = {
	// MAKE AVAILABLE ALL DEFAULT PROPERTIES TO BE OVERWRITTEN
	...fromGlobal,

	// FROM HERE YOU CAN OVERRIDE PROPERTIES

	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),
	startNode: 'AssignTeamLeader',
	header: {
		title: 'Assign Team Leader',
		controls: () => () => []
	},
	serverCalls: {
		...fromGlobal.serverCalls,
		jobLocationData: {
			errorMessage: 'The job location was not returned!',
			directCall: (http, store, sq, bf) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					switchMap(j => {
						const job = j as any;
						return http
							.post(`${environment.api_url}v1/job_action/get_job/`, { job_id: job?.id })
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(result => result as any)
							)
							.pipe(
								map(jobloc => {
									const locationString = jobloc['payload']?.claim?.location;
									const locArray = locationString?.split(',');
									const latitude = +locArray[0];
									const longitude = +locArray[1];
									return new JobLocation(latitude, longitude);
								})
							);
					})
				);
			}
		}
	},
	instructions: {
		editRoles: {
			0: 'Allocate a team leader to the job'
		},
		viewRoles: {
			0: 'Wait for SP to start job'
		}
	},
	nodes: {
		Decision: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				isAppointment: (navs, store, modal) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1),
							map(res => {
								const appointmentString = res?.appointment[0]?.range_start;
								const dateTimeNowString = new Date().toISOString()?.split('.')[0];
								const appointment = new Date(appointmentString);
								const now = new Date(dateTimeNowString);

								switch (true) {
									case now > appointment:
										{
											// alert('you have missed the appointment');
											store.dispatch(
												new MakeServerCall({
													errorMessage: '',
													directCall: (http, stor, sq, bf, controller) => {
														controller.dispatch(new SetNextNode('SetAppointment'));
														return of({});
													}
												})
											);
										}
										break;
									case res?.appointment !== null || res?.apppointment !== undefined:
										{
											store.dispatch(
												new MakeServerCall({
													errorMessage: '',
													directCall: (http, stor, sq, bf, controller) => {
														controller.dispatch(new SetNextNode('AssignTeamLeader'));
														return of({});
													}
												})
											);
										}
										break;
									case res?.appointment === null: {
										store.dispatch(
											new MakeServerCall({
												errorMessage: '',
												directCall: (http, stor, sq, bf, controller) => {
													controller.dispatch(new SetNextNode('SetAppointment'));
													return of({});
												}
											})
										);
									}
								}
							})
						)
						.subscribe();
				}
			},
			navs: [{ text: 'Success', nextNode: '' }]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			checkValidityForFields: ['appointmentData'],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, Validators.required),
						appointmentTime: new UntypedFormControl(null, Validators.required),
						appointmentTimePicker: new UntypedFormControl(null, Validators.required),
						appointmentDateType: new UntypedFormControl(null, Validators.required),
						skill: new UntypedFormControl('Dish Installation')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				bf.patchValues({ new_state: 21 });
			},
			serverCalls: {
				claimaintKeyValues: {
					errorMessage: 'Claimant detail could not be retrieved',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									const claimantinfo = {
										'Claimant Details ': {
											'Full name ': `${job.claim?.applicant?.first_name} ` + `${job.claim?.applicant?.surname}`,
											'Contact Number': job?.claim?.loan_information.ContactNumber,
											'Mobile Number ': job?.claim?.loan_information.cellnumber,
											'On-site contact name ': job?.claim?.loan_information.onsiteperson
										}
									};
									return [claimantinfo];
								})
							);
					}
				},
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									return [
										{
											'Client Name': `${qdat.first_name} ${qdat.surname}`
										},
										{ 'Contact Number': `${qdat.contactnumber}` },
										{ 'Mobile Number': `${qdat.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Set Appointment',
					optIntoValidation: true,
					linkType: 'portal',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submit]) => {
										const job_id = job?.id;
										const current_state = job?.state;
										const appointment = submit.appointment;
										const data = {
											job_id: job_id,
											current_state: current_state,
											appointment: appointment,
											new_state: 21
										};
										return data;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							},
							followUpSuccessCalls: {
								response: {
									errorMessage: `Couldn't assign team leader`,
									directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService) => {
										return forkJoin([
											_store.select(getSelectedItemTwo).pipe(
												skipWhile(x => !x),
												take(1),
												map(res => res as any)
											),
											_store.select(getCurrentUser).pipe(
												skipWhile(x => !x),
												take(1),
												map(res => res as any)
											),
											of(_bf.bigForm.get('assign_teamleader_id')?.value).pipe(
												skipWhile(x => !x),
												take(1),
												map(rest => rest)
											)
										]).pipe(
											switchMap(([j, uid, lead]) => {
												const job = j as any;
												const user = uid as any;
												const job_id = job?.id;
												const staffmember = user.id;
												const team = lead[0];

												const data = {
													job_id: job_id,
													staffmember: staffmember,
													team_id: team
												};

												return _http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
											})
										);
									}
								}
							}
						}
					}
				}
			],
			component: 'FLXJobAppointmentComponent'
		},
		AssignTeamLeader: {
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: bf => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl('', Validators.required));
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getSelectedItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map((res: any) => res)
							)
							.pipe(
								switchMap(job => {
									const { id } = job;
									const job_id = id;
									return http.post(`${environment.api_url}v1/sp_action/manage_list_staff/`, { role_id: 10, image: true, job_id: job_id, authorized: true }).pipe(
										pluck('payload'),
										filter(x => !!x),
										map((teamleaders: any[]) =>
											teamleaders.map(teamLeader => {
												const namestringarr = teamLeader.full_name?.split(' ');
												let mono1, mono2;
												if (!namestringarr[0]) {
													mono1 = ' ';
												} else {
													mono1 = namestringarr[0]?.slice(0, 1);
												}
												if (!namestringarr[1]) {
													mono2 = '';
												} else {
													mono2 = namestringarr[1]?.slice(0, 1);
												}
												const monogram = mono1 + mono2;
												return {
													display: teamLeader.full_name,
													value: teamLeader.id,
													image: teamLeader.image,
													monogram: monogram,
													teamLeader,
													shouldHaveImage: true
												};
											})
										)
									);
								})
							);
					}
				}
			},
			component: 'AssignTLComponent',
			inputs: {
				disableOptionWhen: {
					message: 'Upload profile picture to select team leader',
					evaluationCriteria: {
						property: 'image',
						operator: '!'
					}
				},
				// installationDetail$: 'installationdetails',
				includeUserDisplay: true,
				joblocation$: 'jobLocationData',
				teamleadersPositions$: 'teamleaderLocations'
			},
			navs: [
				{
					text: 'reschedule',
					location: 'center',
					color: 'secondary',
					optIntoValidation: true,
					nextNode: 'ProvideRescheduleReason'
				},
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't update job!",
							directCall: (http, store, sq, bf, ctrl) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getCurrentUser).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									of(bf.bigForm.get('assign_teamleader_id')?.value).pipe(
										skipWhile(x => !x),
										take(1),
										map(rest => rest)
									)
								]).pipe(
									switchMap(([j, uid, lead]) => {
										const job = j as any;
										const user = uid as any;
										const job_id = job?.id;
										const staffmember = user.id;
										const team = lead[0];

										const data = {
											job_id: job_id,
											staffmember: staffmember,
											team_id: team
										};
										// return of({});
										return http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
									})
								);
							}
						}
					},

					location: 'right'
				}
			]
		},
		ProvideRescheduleReason: {
			serverCalls: {
				selectOptions: {
					errorMessage: 'Could not get reschedule reasons',
					directCall: (_http: HttpClient, _store: Store, _sq: any) => {
						return _sq
							.queryStore(
								gql`
									{
										allInfo {
											config_options {
												general {
													reschedule_reasons
												}
											}
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x)?.length !== 0),
								take(1),
								map(values),
								map(value => value?.[0]),
								map(vals => ({ rescheduleReasons: vals }))
							);
					}
				}
			},
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				heading: 'Reason for rescheduling',
				subtitle: 'Reason for rescheduling',
				formControls: {
					0: {
						label: 'Reason for rescheduling',
						inputType: 'select',
						selectConfig: {
							displayOptions: { displayKey: 'name', valueKey: 'name' },
							itemsOption: 'rescheduleReasons',
							placeholder: 'Reason',
							addConditionalForm: {
								onControlSelectedValue: 'Other',
								inputType: 'textarea',
								formControlName: 'reschedule_reason_description',
								width: '200px',
								// label: 'Reason for rescheduling',
								placeholder: 'Please detail reason for rescheduling'
							}
						},
						formControlName: 'reschedule_reason'
					}
				},
				formLayout: 'stacked',
				containerWidth: '30vw',
				headingSize: 'medium',
				headingWeight: 'light',
				headingType: 'creation',
				headingMargin: '20px 0 35px 0'
			},
			navs: [
				{
					text: 'reschedule',
					color: 'primary',
					nextNode: 'SetAppointment',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (_instance: any, _store: Store) => {
							_instance.messageOnly = true;
							_instance.type = 'warning';
							_instance.heading = 'Customer Service is Key';
							_instance.setMessage([
								`The need to reschedule can arise for a number of reasons, but the customer's experience should always be kept in mind.`,
								'',
								'Please confirm that the customer has been contacted and they have consented to, or requested, the change in appointment.',
								,
								'',
								''
							]),
								(_instance.navButtons = [
									{
										text: 'back',
										color: 'default',
										clickHandler: event => {
											_store.dispatch(
												new MakeServerCall({
													dataKey: 'response',
													errorMessage: '',
													directCall: (_http: HttpClient, _store: Store, sq: any, bf: BigFormService, _controller: ManifestController<any>) => {
														_controller.dispatch(new SetPreviousNode());
														return of({});
													}
												})
											);
										},
										linkType: 'close'
									},
									{
										text: 'confirm',
										color: 'default',
										linkType: 'submitThenNext',
										serverCalls: {
											response: {
												serviceVariable: 'mulSpService',
												functionName: 'updateJob',
												errorMessage: 'Could not update job!'
											}
										}
									}
								]);
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		...fromGlobal.bigFormToStoreMapper,
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					const appointment_formatted = {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
					return appointment_formatted;
				}
			},
			'appointment'
		],
		reschedule_reason: [
			(_reason: string, _store: any, _bf: BigFormService) => {
				const reschedule_reason = _store['selectedContext']?.fullItemTwo?.job_information?.reschedule_reason;
				if (_reason) {
					const return_reasons = [];
					const date = new Date();
					const new_reason = {
						reason: _reason,
						author_name: _store?.identity?.currentUser?.user?.full_name,
						time_stamp: convertDateTimeToTimeStamp(date.toDateString())
					};
					if (reschedule_reason) {
						Array.isArray(reschedule_reason) ? return_reasons?.push(...reschedule_reason, new_reason) : return_reasons.push(reschedule_reason, new_reason);
					} else {
						return_reasons.push(new_reason);
					}
					return return_reasons;
				} else {
					return reschedule_reason;
				}
			},
			'job_information.reschedule_reason'
		],
		reschedule_reason_description: [
			(raison_desc, stor, bf) => {
				if (raison_desc) {
					return raison_desc;
				} else {
					return 'no other';
				}
			},
			'job_information.reschedule_reason_description'
		]
	}
};
