import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { skipWhile, map } from 'rxjs/operators';
import { BigFormService } from '@flexus/core';

@Component({
	selector: 'flx-cancel-claim',
	templateUrl: './cancel-claim.component.html',
	styleUrls: ['./cancel-claim.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXCancelClaimComponent implements OnInit, OnDestroy {
	@Input() cancelclaimdata$: Observable<any>;
	@Input() heading: any;
	@Input() subtitle: string;

	jobCards;

	sub: Subscription;
	jobtype: string;
	jobstates: any;
	suppliertypes: any;
	skills: any;
	statedescription: any;

	constructor(private bf: BigFormService, private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this.sub = this.cancelclaimdata$
			.pipe(
				skipWhile(data => !data),
				map(data => {
					return data;
				})
			)
			.subscribe(res => {
				const { claimjob, stor } = res;
				if (claimjob) {
					this.jobCards = claimjob.jobs;
					this.heading = `Warning! ${this.jobCards?.length} job card${this.jobCards?.length > 1 ? 's' : ''} will be cancelled`;
					this.suppliertypes = stor?.allInfo?.supplier_type;
					this.skills = stor?.allInfo?.skills;
					this.jobstates = stor?.allInfo?.states;
					this.cdr.detectChanges();
				}
			});
	}

	getJobType(supplierTypeId: number) {
		const supplierTypes = this.suppliertypes.find(f => f.id === supplierTypeId);
		if (supplierTypes) {
			return supplierTypes.name;
		}
		return '';
	}

	getSkillDescription(skillId: number) {
		const sKills = this.skills.filter(x => x.id === skillId);
		if (sKills && sKills.length > 0) {
			return sKills[0]?.name;
		}
	}

	getStateDescription(stateId: number) {
		const sTates = this.jobstates.filter(x => x.id === stateId);
		if (sTates && sTates.length > 0) {
			return sTates[0]?.description;
		}
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}
}
