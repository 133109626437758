import { Flow_0_0_2, getAllInfo, getSelectedItemOne, ChangeManifestState, getCurrentUser, getFullItemTwo, getData } from '@flexus/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import gql from 'graphql-tag';
import { take, map, tap, withLatestFrom, switchMap, debounceTime, skipWhile, filter, pluck } from 'rxjs/operators';
import { flatten, values } from 'ramda';
import moment from 'moment';
import { empty, of, from, Observable, forkJoin } from 'rxjs';
import { MUL_BANK_DETAILS } from '../flows/MUL_BANK_DETAILS';
import { environment } from '../../../../../environments/environment';
// import { transformJobsToJobCardRequest, checkBusinessRules } from './transform.functions';
import { CustomValidators, generateRange } from '@flexus/utilities';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { RemoveError } from '@flexus/error-handler';
import { FLXHeaderActionsComponent } from 'apps/studio/src/app/app-shell-features/header-actions/header-actions.component';
import { HttpClient } from '@angular/common/http';

const today = new Date();
const tomorrow = new Date(today);
const isAfterOne = today.getHours() > 12;
tomorrow.setDate(tomorrow.getDate() + 1);

export const MulTeamManagement: Flow_0_0_2 = {
	addHeaderActions: true,
	id: 'team_management',
	name: 'team management',
	deactivateStateGuard: (guardService: any, comp, router, currentRoute, currentState, nextState) => {
		return from(guardService.indexedDbService.currentItem.get('currentItem')).pipe(
			withLatestFrom(guardService.canGo$),
			switchMap(([currentItem, canGo]: any) => {
				return <Observable<any>>guardService.store.select(getSelectedItemOne).pipe(
					take(1),
					map((itemOne: any) => {
						if (!canGo && currentItem?.applicant && currentItem?.loan_information) {
							if (itemOne?.tempKey && guardService?.bf?.bigForm?.touched) {
								// Local draft has been edited
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this local draft.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Save Changes Locally',
											color: 'primary',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												const localDraft = {
													...currentItem,
													tempKey: itemOne?.tempKey,
													state: 169
												};
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.claimInDraft.put(localDraft).then(() => {
														guardService.indexedDbService.currentItem.delete('currentItem');
													});
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (itemOne?.tempKey && !guardService?.bf?.bigForm?.touched) {
								// Local draft opened but not edited yet
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this local draft.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne?.tempKey && !guardService?.bf?.bigForm?.touched) {
								// Any Server Draft not edited
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from your current view.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne?.tempKey && guardService?.bf?.bigForm?.touched) {
								// Any server draft that is edited
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this draft.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne && !guardService.bf.bigForm.touched) {
								// Creating New Claim but forms has not been touched yet
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving from your current view.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne && guardService.bf.bigForm.touched) {
								// In Claim creation and form has been touched
								guardService.modalService.openModalDirectly((inst, store, bf) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									// inst.message = '';
									inst.setMessage(['You are moving from your current view.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: ev => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							}
						} else {
							return true;
						}
					})
				);
			})
		);
	},
	activateStateGuard: () => {
		return of(true);
	},
	onStateInit: instance => {
		instance.store.dispatch(new CollapseActionPanel());
		// const paramFunc = (inst, mapper) => {
		// 	return {
		// 		id: '1',
		// 		icon: 'what-matters',
		// 		command: inst.loadComponent.bind(inst, mapper['FLXWhatMattersComponent'])
		// 	};
		// };
		// instance.navService.portalActions.next({ call: 'onClick', paramFunc });
	},
	onStateDestroy: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['what-matters']),
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	header: {
		title: 'Manage Team',
		controls: (instance: FLXHeaderActionsComponent) => {
			return () => {
				const controls = [];
				return controls;
			};
		}
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'AgentAllocationList',
	nodes: {
		AgentAllocationList: {
			serverCalls: {
				agentList: {
					errorMessage: '',
					directCall: (_http: HttpClient) => {
						return _http.get(`${environment.api_url}v1/mc_actions/get_validation_agents/?roles=40`).pipe(
							skipWhile(x => !x),
							pluck('payload'),
							take(1),
							map((response: any) => {
								console.log('AGENT RESPONSE ', response);
								return response;
							})
						);
					}
					// return of({})
				}
			},
			component: 'McAgentAllocationComponent',
			inputs: {
				agentList: 'agentList'
			}
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			// serverCalls: {
			// 	copyText: {
			// 		errorMessage: 'Could not retrieve new claim number!',
			// 		directCall: (http, store, sq, bf) => {
			// 			return sq
			// 				.queryObject(
			// 					gql`
			// 						{
			// 							selectedContext {
			// 								response {
			// 									payload {
			// 										mid
			// 									}
			// 								}
			// 							}
			// 						}
			// 					`,
			// 					store
			// 				)
			// 				.pipe(
			// 					skipWhile(f => !f),
			// 					take(1),
			// 					map(values),
			// 					map<any, any>(flatten),
			// 					switchMap((val: any) => {
			// 						if (!!val) return val;
			// 						else return null;
			// 					}),
			// 					tap(() => {
			// 						store.dispatch(new CollapseActionPanel());
			// 					})
			// 				);
			// 		}
			// 	}
			// },
			inputs: {
				copyTextHeading: 'Manage Team',
				autoClose: false
			},
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
