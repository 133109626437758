import { FormArray, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { BigFormService, Flow_0_0_2, getAllInfo, getFullItemTwo, getSelectedItemTwo, MakeServerCall, SetNextNode } from '@flexus/core';
import { setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import { EMPTY, of } from 'rxjs';
import { skipWhile, take, map, switchMap, pluck, tap } from 'rxjs/operators';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { photoNode } from '../reusable/PHOTO_NODE';

export const MUL_238: Flow_0_0_2 = {
	id: '238',
	name: 'integration-triggers-failed',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		// display dynamic header based on failure reason
		title: 'Autopay deferred',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'SUD - Integration Triggers'
		},
		viewRoles: {
			0: 'SUD - Integration Triggers'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		},
		teamleaderinfo: {
			errorMessage: 'Team lead detail could not be retrieved',
			directCall: (http, store, sq, bf) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					map((res: any) => {
						const list = [];
						const { team_leader } = res;
						const teamleadname = team_leader?.full_name;
						const returnkeyvalue = {
							' Problem Installer/Technician': `${teamleadname}`
						};
						list.push(returnkeyvalue);

						return list;
					})
				);
			}
		},
		installationInfo: {
			errorMessage: 'Could not retrieve installation detail',
			directCall: (http, store, sq, bf) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					map((res: any) => {
						const list = [];
						const { team_leader } = res;
						const { sp } = team_leader;
						const sp_name = sp?.name;
						const sp_number = sp?.contact_primary;
						const returnkeyvalue = {
							'Company name': `${sp_name}`,
							'Company number': `${sp_number}`
						};
						list.push(returnkeyvalue);
						return list;
					})
				);
			}
		},
		sps: {
			errorMessage: 'Could not get list of sps',
			directCall: (http, store, sq, bf) => {
				return store.select(getAllInfo).pipe(
					skipWhile(x => !x),
					take(1),
					map((result: any) => {
						// const spArray = [];
						// let selectOption;
						const serviceProvs = result?.['sps'];
						// serviceProvs.forEach(company => {
						// 	selectOption = { display: company?.name, value: company?.id };
						// 	spArray.push(selectOption);
						// });
						return serviceProvs;
					})
				);
			}
		},
		jobdecoders: {
			errorMessage: 'Could not get decoders',
			directCall: (http, store, sq, bf) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					pluck('decoder_number'),
					map((res: any[]) => res?.map(item => ({ id: item, selected: false })))
				);
			}
		},
		decoderNumber: {
			errorMessage: 'Could not get decoder number',
			directCall: (http, store, sq, bf) => {
				return store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					map(result => {
						const list = [];
						const { decoder_number } = result;
						const inputObj = {
							'Decoder number': `${decoder_number[0]}`
						};
						console.log('DECODER NUMBER', `${decoder_number[0]}`);
						list.push(inputObj);
						return list;
					})
				);
			}
		}
	},
	startNode: 'InstallationJobSummary',
	nodes: {
		InstallationJobSummary: {
			initFormFields: (_f: BigFormService) => {
				_f.bigForm.addControl('new_state', new UntypedFormControl(26));
			},
			showTabs: true,
			...jobInfoNode(238),
			name: 'Job Summary'
		},
		JobPhotos: {
			initFormFields: (_f: BigFormService) => {
				_f.bigForm.addControl('new_state', new UntypedFormControl(26));
			},
			...photoNode(238)
		},
		StaffIssueDecision: {
			hideTabItem: true,
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				is_staffissue: (navs, store, modal) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(fulljob => !fulljob),
							take(1),
							map((result: any) => {
								const { job_information } = result;
								const { state_trigger_problems } = job_information;
								const failure_reasons_array = state_trigger_problems.split(', ');
								const isstaffissue =
									failure_reasons_array.includes('TL has empty name') ||
									failure_reasons_array.includes('TL has empty qualification number') ||
									failure_reasons_array.includes('SP has empty accreditation number');
								switch (isstaffissue) {
									case true:
										{
											store.dispatch(
												new MakeServerCall({
													errorMessage: '',
													directCall: (http, stor, sq, bf, controller) => {
														controller.dispatch(new SetNextNode('CorrectJobFinalisationFail_StaffIssue'));
														return of({});
													}
												})
											);
										}
										break;
									case false: {
										store.dispatch(
											new MakeServerCall({
												errorMessage: '',
												directCall: (http, stor, sq, bf, controller) => {
													controller.dispatch(new SetNextNode('CorrectJobFinalisationFail_DecoderIssue'));
													return of({});
												}
											})
										);
									}
								}
								console.log('FLAG', isstaffissue);
								console.log('triggers', failure_reasons_array);
							})
						)
						.subscribe();
				}
			},
			navs: []
		},
		CorrectJobFinalisationFail_StaffIssue: {
			hideTabItem: true,
			initFormFields: (bf, item, instance, sq, store) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(26));
				bf.bigForm.addControl('select_company_list', new UntypedFormControl('', [Validators.required]));
				bf.bigForm.addControl('select_teamlead_list', new UntypedFormControl('', [Validators.required]));
				bf.bigForm.addControl('teamlead_accreditation_number', new UntypedFormControl('', [Validators.required]));
				bf.bigForm.addControl('company_accreditation_number', new UntypedFormControl('', [Validators.required]));
				bf.bigForm.addControl('teamlead_name', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{
						component: 'FLXStaffIssueFixerComponent',
						inputs: {
							importantTLInfo$: 'teamleaderinfo',
							installationInfo$: 'installationInfo',
							serviceProviders$: 'sps'
						}
					}
				]
			}
		},
		CorrectJobFinalisationFail_DecoderIssue: {
			checkValidityForFields: ['select_decoder_number'],
			hideTabItem: true,
			initFormFields: (bf, item, instance, sq, store) => {
				bf.bigForm.addControl('voucherthis', new UntypedFormControl(null, Validators.required));
				bf.bigForm.addControl('vouchernumber', new UntypedFormControl(null, Validators.required));
				bf.bigForm.addControl('select_decoder_number', new UntypedFormControl(null, Validators.required));

				bf.bigForm.addControl('new_state', new UntypedFormControl(26));
				bf.bigForm.addControl('nonvouchered_decoder_fix', new UntypedFormControl('', [Validators.required]));
				bf.bigForm.addControl('no_valid_decoder_fix', new UntypedFormControl('', [Validators.required]));
			},
			serverCalls: {
				jobImages: {
					directCall: (http, store, sq, bf) => {
						return store.select(getSelectedItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							switchMap((itemTwo: any) => {
								return http
									.post<any>(environment.api_url + `v1/file_action/get_files`, {
										job_id: itemTwo?.id
									})
									.pipe(
										switchMap(payload => {
											const payLoad = payload['payload'];
											const decoderImageObj = payLoad.find(element => element?.purpose === 'Information Central');
											console.log('IMAGE OBJ', decoderImageObj);

											return of([decoderImageObj]);
										})
									);
							})
						);
					},
					errorMessage: 'Could not get images'
				}
			},
			component: {
				children: [
					{
						component: 'FLXDecoderIssueFixerComponent',
						inputs: {
							verificationImageSource$: 'jobImages',
							// importantTLInfo$: 'teamleaderinfo',
							installationInfo$: 'installationInfo',
							serviceProviders$: 'sps',
							installedDecoders$: 'jobdecoders',
							decoderKeyValues$: 'decoderNumber'
						}
					}
				]
			}
			// navs: [{ text: 'don mind this' }]
		},
		FixedStaffReasonsSummary: {
			hideTabItem: true,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'You are about to submit fixes to company/installer details on this job',
							instructions: ['Are you sure that all your entries were accurate?'],
							headingConfig: {
								color: 'warning'
							}
						}
					}
				]
			},
			navs: [
				{
					text: 'Save and re-trigger',
					color: 'primary',
					nextNode: 'DecoderIssueDecision'
				}
			]
		},
		FixedDecoderReasonsSummary: {
			hideTabItem: true,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'You are about to submit fixes to the decoder on to this job',
							instructions: ['Are you sure that all your entries were accurate?'],
							headingConfig: {
								color: 'warning'
							}
						}
					}
				]
			},
			navs: [
				{
					text: 'Save and re-trigger',
					color: 'primary',
					nextNode: 'FileSubmissionSuccess'
				}
			]
		},
		DecoderIssueDecision: {
			hideTabItem: true,
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				is_decoderissue: (navs, store, modal) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(fulljob => !fulljob),
							take(1),
							map((result: any) => {
								const { job_information } = result;
								const { state_trigger_problems } = job_information;
								const failure_reasons_array = state_trigger_problems.split(', ');
								const isdecoderissue =
									failure_reasons_array.includes('Job has no voucher linked') ||
									failure_reasons_array.includes('Job has non-vouchered decoder linked') ||
									failure_reasons_array.includes('Job has multiple valid decoders');
								switch (isdecoderissue) {
									case true:
										{
											store.dispatch(
												new MakeServerCall({
													errorMessage: '',
													directCall: (http, stor, sq, bf, controller) => {
														controller.dispatch(new SetNextNode('CorrectJobFinalisationFail_DecoderIssue'));
														return of({});
													}
												})
											);
										}
										break;
									case false: {
										store.dispatch(
											new MakeServerCall({
												errorMessage: '',
												directCall: (http, stor, sq, bf, controller) => {
													controller.dispatch(new SetNextNode('FileSubmissionSuccess'));
													return of({});
												}
											})
										);
									}
								}
								console.log('FLAG', isdecoderissue);
								console.log('triggers', failure_reasons_array);
							})
						)
						.subscribe();
				}
			},
			navs: []
		},
		FileSubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'Your changes were saved and you can proceed' },
			navs: [
				{
					text: 'Continue',
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Could not update job'
						}
					},
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		SubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
		// select_company_list: 'job_information.changed_company_name',
	}
};
