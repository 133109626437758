import { Action } from '@ngrx/store';
import { ServerCall_0_0_2 } from '../manifest-versions';

export const FETCH_TEMPLATES = '[BILLING] Fetch Templates';
export const FETCH_TEMPLATES_SUCCESS = '[BILLING] Fetch Templates Success';
export const FETCH_TEMPLATES_FAIL = '[BILLING] Fetch Templates Fail';

export const MAKE_BILLING_SERVER_CALL = '[BILLING] MAKE SERVER CALL';
export const MAKE_BILLING_SERVER_CALL_WITH_LOADER = '[BILLING] MAKE SERVER CALL WITH LOADER';
export const MAKE_BILLING_SERVER_CALL_WITHOUT_LOADER = '[BILLING] MAKE SERVER CALL WITHOUT LOADER';
export const MAKE_BILLING_SERVER_CALL_SUCCESS = '[BILLING] MAKE SERVER CALL SUCCESS';
export const MAKE_BILLING_SERVER_CALL_FAIL = '[BILLING] MAKE SERVER CALL FAIL';
export const WRITE_DATA_TO_BILLING_STORE = '[BILLING] WRITE DATA TO STORE';

export const SAVE_DRAFT_QUOTE = '[BILLING] SAVE DRAFT QUOTE';
export const SAVE_DRAFT_QUOTE_SUCCESS = '[BILLING] SAVE DRAFT QUOTE SUCCESS';
export const SAVE_DRAFT_QUOTE_FAIL = '[BILLING] SAVE DRAFT QUOTE FAIL';
export const SAVE_DRAFT_QUOTE_NO_ACTION = '[BILLING] SAVE DRAFT QUOTE NO ACTION';

export class FetchTemplates implements Action {
	readonly type = FETCH_TEMPLATES;

	constructor(
		public payload: {
			callOutSkill: number;
			type: string;
			callOutIdType: 'skill' | 'claim_type';
			isAfterHoursItem: boolean;
		}
	) {}
}

export class FetchTemplatesSuccess implements Action {
	readonly type = FETCH_TEMPLATES_SUCCESS;

	constructor(public payload: { availableTemplates: any }) {}
}

export class FetchTemplatesFail implements Action {
	readonly type = FETCH_TEMPLATES_FAIL;

	constructor(public payload: { key: string; error: any }) {}
}

export class MakeBillingServerCall implements Action {
	readonly type = MAKE_BILLING_SERVER_CALL;
	constructor(public payload: ServerCall_0_0_2) {}
}

export class MakeBillingServerCallWithLoader implements Action {
	readonly type = MAKE_BILLING_SERVER_CALL_WITH_LOADER;
	constructor(public payload: ServerCall_0_0_2) {}
}

export class MakeBillingServerCallWithoutLoader implements Action {
	readonly type = MAKE_BILLING_SERVER_CALL_WITHOUT_LOADER;
	constructor(public payload: ServerCall_0_0_2) {}
}

export class MakeBillingServerCallFail implements Action {
	readonly type = MAKE_BILLING_SERVER_CALL_FAIL;
	constructor(
		public payload: {
			followUpFailCalls?: { [id: string]: ServerCall_0_0_2 };
			dataKey: string;
			error: any;
			errorMessage: string;
			retryCall: any;
			data?: any;
			loaderID?: string;
		}
	) {}
}

export class MakeBillingServerCallSuccess implements Action {
	readonly type = MAKE_BILLING_SERVER_CALL_SUCCESS;
	constructor(
		public payload: {
			followUpSuccessCalls?: { [id: string]: ServerCall_0_0_2 };
			dataKey: string;
			result: any;
			loaderID?: string;
		}
	) {}
}

export class WriteDataToBillingStore implements Action {
	readonly type = WRITE_DATA_TO_BILLING_STORE;
	constructor(public payload: any) {}
}

export class SaveDraftQuote implements Action {
	readonly type = SAVE_DRAFT_QUOTE;
}

export class SaveDraftQuoteSuccess implements Action {
	readonly type = SAVE_DRAFT_QUOTE_SUCCESS;
}

export class SaveDraftQuoteNoAction implements Action {
	readonly type = SAVE_DRAFT_QUOTE_NO_ACTION;
}

export class SaveDraftQuoteFail implements Action {
	readonly type = SAVE_DRAFT_QUOTE_FAIL;
	constructor(public error: any) {}
}

export type billingActions =
	| FetchTemplates
	| FetchTemplatesSuccess
	| FetchTemplatesFail
	| MakeBillingServerCall
	| MakeBillingServerCallWithLoader
	| MakeBillingServerCallWithoutLoader
	| MakeBillingServerCallSuccess
	| MakeBillingServerCallFail
	| WriteDataToBillingStore
	| SaveDraftQuote
	| SaveDraftQuoteSuccess
	| SaveDraftQuoteNoAction
	| SaveDraftQuoteFail;
