import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilterStoreState } from '../reducer/filter-store.reducer';

const getFilterStoreState = createFeatureSelector<FilterStoreState>('filter');

export const getFilterData = createSelector(getFilterStoreState, state => state && state.filterData);
export const getFilters = createSelector(getFilterStoreState, state => state && state.filters);
export const getServerSearchResult = createSelector(getFilterStoreState, state => state && state.serverSearchResults);

export const getSearchField = createSelector(getFilterStoreState, state => state && state.filterData.searchField);
//  to do more detailed selectors one would need to use the param based selectors
