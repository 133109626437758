import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { skipWhile, take, map } from 'rxjs/operators';

@Component({
	selector: 'pgg-refund-breakdown',
	templateUrl: 'pgg-refund-breakdown.component.html',
	styleUrls: ['pgg-refund-breakdown.component.scss']
})
export class PinggoRefundBreakdownComponent implements OnInit {
	@Input() subheading!: string;
	@Input() calcBreakdownInputs$!: any;
	@Input() voucherAmount$: Observable<number>;
	amountsub!: Subscription;

	constructor(private _store: Store) {}

	public voucher_amount!: any;
	public calloutfee: any = 650;
	refund_due!: any;

	calculateRefundAmount() {
		this.refund_due = this.voucher_amount - this.calloutfee;
	}

	ngOnInit() {
		this.amountsub = this.voucherAmount$
			.pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((rest: any) => rest)
			)
			.subscribe((response: any) => {
				console.log('VOUCHER', response);
				this.voucher_amount = response.total_value;
			});

		this.calculateRefundAmount();
	}
}
