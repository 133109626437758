import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { combineLatest, Observable, of, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, filter, take, switchMap } from 'rxjs/operators';
import { getFullItemOne, StoreQuery, getSelectedItem, getAllInfo } from '@flexus/core';
import gql from 'graphql-tag';
import { TabsData } from '@flexus/ui-elements';
import { findName } from '@flexus/utilities';
import { CurrencyPipe } from '@angular/common';

@Component({
	selector: 'flx-claim-summary',
	templateUrl: './claim-summary.component.html',
	styleUrls: ['./claim-summary.component.scss']
})
export class ClaimSummaryComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() assessorName$: Observable<any>;
	claimSummary$: Observable<any>;
	allInfo;
	jobObject$: BehaviorSubject<any> = new BehaviorSubject<any>({});
	currentTab$: Observable<any>;
	tabs: TabsData[];

	jobInformation: any;

	constructor(private _store: Store<any>, private sq: StoreQuery) {}

	ngOnInit() {
		this.currentTab$ = of('claim_information');

		this.claimSummary$ = combineLatest([
			this._store.select(getSelectedItem),
			this._store.select(getAllInfo),
			this._store.select(getFullItemOne),
			this.assessorName$.pipe(filter(x => !!x))
		]).pipe(
			//filter(val => !!val[2]),
			map(([selectedItem, allInfo, claim, assessor]: any) => ({ selectedItem, allInfo, claim, assessor })),
			take(1),
			switchMap(val => {
				const claimInfo = JSON.parse(JSON.stringify(val.claim));
				this.allInfo = val?.allInfo;
				const { jobs } = val?.selectedItem;
				const { states } = val?.allInfo;
				const assessor = val?.assessor;
				const fixedJobs = [];
				jobs.forEach((job: any) => fixedJobs.push(this.getRequiredJobInfo(job, assessor)));

				this.populateTabs(fixedJobs);
				this.jobInformation = fixedJobs;

				claimInfo.state = typeof val.claim.state === 'number' ? findName(val?.claim?.state, states)['description'] : claimInfo?.state;
				//claimInfo.application_creator.roles = this.getRoleStrings(claimInfo.application_creator.roles);
				claimInfo.loan_information.selectedskills = this.getSelectedSkillsString(claimInfo?.loan_information?.selectedskills);

				if (claimInfo.loan_information.suminsured && !isNaN(Number(claimInfo?.loan_information?.suminsured))) {
					claimInfo.loan_information.suminsured = new CurrencyPipe('en-US').transform(Number(claimInfo?.loan_information?.suminsured || 0), 'R', true);
				}

				if (claimInfo.assessor !== null) claimInfo.assessor = assessor;

				const { loan_information, ...rest } = claimInfo;
				const { applicant, jobcardrequest, ...information } = loan_information;

				return of({ ...rest, information });
			})
		);
	}

	getRoleStrings(selectedRoles: number[] | string) {
		let toReturn = '';

		if (typeof selectedRoles === 'string') {
			toReturn = selectedRoles;
		} else {
			selectedRoles.forEach(role => {
				if (toReturn !== '') toReturn += typeof role === 'number' ? ', ' + findName(role, this.allInfo?.roles)['description'] : role;
				else toReturn += typeof role === 'number' ? findName(role, this.allInfo?.roles)['description'] : role;
			});
		}

		return toReturn;
	}

	getSelectedSkillsString(selectedSkills: Array<object> | object) {
		let toReturn = '';

		if (selectedSkills) {
			const skillsString = [];
			const claimSkills: any = selectedSkills;
			if (!(typeof claimSkills === 'string')) {
				if (claimSkills instanceof Array) {
					claimSkills.forEach(skill => (skill ? skillsString.push(`(${skill?.category}) ${skill?.subcategory}`) : ''));

					toReturn = skillsString?.join(', ');
				} else {
					const skillsObj = { ...claimSkills };

					toReturn = skillsObj ? `(${skillsObj?.category}) ${skillsObj?.subcategory}` : '';
				}
			}
		}
		return toReturn;
	}

	getRequiredJobInfo(jobInfo, name): any {
		const { token, area, claim, ping_count, job_creator, ...job } = jobInfo;
		const { states, skills, sps, supplier_type, appointment_types } = this.allInfo;
		const clonedJob = JSON.parse(JSON.stringify(job));

		Object.keys(job).map(key => {
			if (key === 'state') {
				clonedJob.state = typeof job?.state === 'number' ? findName(job?.state, states)['description'] : job?.state;
			} else if (key === 'skill') {
				clonedJob.skill = typeof job?.skill === 'number' ? findName(job?.skill, skills)['name'] : job?.skill;
			} else if (key === 'sp') {
				clonedJob.sp = typeof job?.sp === 'number' ? (findName(job?.sp, sps)['name'] ? findName(job?.sp, sps)['name'] : 'sp not found') : job?.sp;
			} else if (key === 'supplier_type') {
				clonedJob.supplier_type = typeof job?.supplier_type === 'number' ? findName(job?.supplier_type, supplier_type)['name'] : job?.supplier_types;
			}
		});

		if (job.assessor !== null) clonedJob.assessor = name;

		if (job.appointment) {
			if (typeof job?.appointment?.appointment_type === 'number') {
				clonedJob.appointment.appointment_type = findName(job?.appointment?.appointment_type, appointment_types).name;
			} else if (job?.appointment?.appointment_type instanceof Object) {
				clonedJob.appointment.appointment_type = job?.appointment?.appointment_type?.name;
			} else {
				clonedJob.appointment.appointment_type = null;
			}
			clonedJob.appointment.state = typeof job?.appointment?.state === 'number' ? findName(job?.appointment?.state, states)?.description : job?.appointment.state;

			const clonedJobAppointment = job?.appointment ?? {};
			const { ['job']: removed, ...appointment } = clonedJobAppointment;
			return { ...clonedJob, appointment };
		}
		return null;
	}

	handleTab(tabInfo) {
		this.currentTab$ = of(tabInfo.target);
		if (this.jobInformation) {
			if (tabInfo.target !== 'claim_information') {
				this.jobObject$.next(this.jobInformation.find(x => `job-${x.id}` === tabInfo.target));
			}
		}
	}

	populateTabs(jobs) {
		this.tabs = [
			{
				display: 'Claim Information',
				targetId: 'claim_information',
				show: true
			}
		];

		for (const job of jobs) {
			this.tabs.push({
				display: job?.skill,
				targetId: `job-${job.id}`,
				show: true
			});
		}
	}

	getViewJobID(job) {
		return `job-${job.id}`;
	}

	ngOnDestroy(): void {}
}
