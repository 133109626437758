import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, of } from 'rxjs';
import { DynamicFormInputs } from '../forms';
import { FileItem, ERSingleFileSelectEvent, FileSizeUnit, FileQueue } from '@flexus/ui-elements';
import { BigFormService } from '@flexus/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MultiFileLoadingHandlerService } from '../multi-file-loading-handler.service';
import { UntypedFormControl } from '@angular/forms';
import { map, skipWhile } from 'rxjs/operators';

@Component({
	selector: 'flx-file-upload-preview',
	templateUrl: './file-upload-with-preview.component.html',
	styleUrls: ['./file-upload-with-preview.component.scss']
})
export class FLXFileUploadWithPreviewComponent implements OnInit, OnDestroy, AfterViewInit {
	// ================================================== Variables ==================================================
	fileRejected = false;
	rejectReason = '';
	filesubscribe: Subscription;

	errorSubject = new BehaviorSubject<string>(null);

	uploadQueueSub: Subscription;
	uploadQueue$: Observable<any>;

	claimExcess: any = null;

	files$: Observable<Array<FileItem>> = of();
	files: any[] = [];
	images: Observable<any>;
	fileSelectConfig = {
		allowedFileTypes: ['pdf'],
		maxFileSize: {
			size: 10,
			unit: FileSizeUnit.MegaBytes
		}
	};
	// ================================================== Inputs ==================================================
	@Input() heading: string;
	@Input() subheading = 'You can upload jpg, png, pdf or tiff files';
	@Input() multipleUploads = false;
	@Input() purposes: any[] = [];
	@Input() formControls: DynamicFormInputs = null;
	@Input()
	set allowedFileTypes(value: string[]) {
		if (value) {
			this.fileSelectConfig.allowedFileTypes = value;
		}
	}
	get allowedFileTypes() {
		return this.fileSelectConfig.allowedFileTypes;
	}

	// ================================================== Selecting File Methods ==================================================
	handleFileSelection(e: ERSingleFileSelectEvent) {
		const reader = new FileReader();
		reader.onload = event => {
			const res = (event.target as FileReader).result as string;
			const fileItem: FileItem = { nativeFile: e.file, base64: btoa(res), type: e.file.type };
			if (this.multipleUploads === false) {
				if (e.type === 'fileAdded') {
					this.updateFileQueue({ file: fileItem, fileKey: 'file' });
				} else if (e.type === 'fileRejected') {
					this.errorSubject.next(`File Rejected : ${e.reason}`);
					return;
				}
				return;
			} else {
				switch (true) {
					case e.type === 'fileRejected':
						this.errorSubject.next(`File Rejected : ${e.reason}`);
						break;
					case this.fileQueue.findIndex(({ file }) => file.nativeFile.name === e.file.name && file.nativeFile.size === e.file.size) > -1:
						this.errorSubject.next(`File Rejected : Duplicate file`);
						break;
					case this.fileQueue.findIndex(({ file }) => file.nativeFile.type !== fileItem.nativeFile.type) > -1:
						this.errorSubject.next(`File Rejected : Multiple file types not allowed`);
						break;
					default:
						this.files = [];
						this.updateFileQueue({ file: fileItem, fileKey: 'file' });
						for (let i = 0; i < this.fileQueue.length; i++) {
							console.log('this.fileQueue[i]', this.fileQueue[i]);
							this.files.push(this.fileQueue[i]);
						}
					// this.fileQueue.map(element => {
					// 	this.files.push(element);
					// });

					// break;
				}

				this._data.passImageData(of(this.files));
			}
		};
		this.files$ = of(this.files).pipe(
			skipWhile(x => !x),
			map((data: any) => {
				return data;
			})
		);
		this.files$.subscribe();
		reader.readAsBinaryString(e.file);
	}
	/*--------------- Methods to work with files ---------------*/
	updateFileQueue(file: FileQueue) {
		const queue = this.bf.bigForm.get('fileQueue')?.value as Array<FileQueue>;
		queue.push(file);
		this.bf.bigForm.get('fileQueue')?.setValue(queue);
		this.cdr.detectChanges();
	}

	get fileQueue(): Array<FileQueue> {
		return this.bf.bigForm.get('fileQueue')?.value as Array<FileQueue>;
	}

	removeFile(idx: string, fileToRemove: FileItem): void {}

	removeAllFiles(): void {
		this.bf.bigForm.get('fileQueue')?.setValue([]);
	}

	// ================================================== Life Cycle Methods ==================================================
	constructor(private _data: MultiFileLoadingHandlerService, private bf: BigFormService, private cdr: ChangeDetectorRef, private _fB: FormBuilder) {}

	ngOnInit() {
		this.filesubscribe = this.files$.subscribe();
		this.bf.bigForm.addControl('fileQueue', new UntypedFormControl([]));
		// Catch drag and drop files outside the drop zone
		window.addEventListener(
			'dragover',
			e => {
				e && e.preventDefault();
			},
			false
		);
		window.addEventListener(
			'drop',
			e => {
				e && e.preventDefault();
			},
			false
		);
	}

	ngAfterViewInit() {
		this.cdr.detectChanges();
		// this._data.currentImages.subscribe((images: any) => {
		// 	this.images = images;
		// 	console.log('THE IMAGES', this.images);
		// });
	}
	createImageListItem(image?): UntypedFormGroup {
		console.log('create image list item');
		const image_name: string = 'jpeg.jpg';
		if (image) {
			return this._fB.group({
				imagename: image_name
			});
		} else {
			return this._fB.group({
				image_name: image_name,
				image_purpose: 'decoder'
			});
		}
		// return {controls: []}
		return;
	}

	ngOnDestroy(): void {
		window.removeEventListener(
			'dragover',
			e => {
				e && e.preventDefault();
			},
			false
		);
		window.removeEventListener(
			'drop',
			e => {
				e && e.preventDefault();
			},
			false
		);
	}
}
