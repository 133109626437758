<div style="margin-bottom: 6rem">
	<flx-heading size="medium" weight="extra-light" [align]="'center'"> Excess Management </flx-heading>
	<flx-heading size="small" weight="extra-light" [align]="'center'"> Drag and drop excess cards to job cards </flx-heading>
	<div class="add-icon-size add-icon-color add-icon-position" (click)="addExcess()">
		<svg
			_ngcontent-sbp-c12=""
			xml:space="preserve"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			style="enable-background: new 0 0 24 24"
			version="1.1"
			viewBox="0 0 24 24"
			x="0px"
			xmlns="http://www.w3.org/2000/svg"
			y="0px"
		>
			<title _ngcontent-sbp-c12="">Add Excess</title>
			<g _ngcontent-sbp-c12="">
				<path
					d="M12,17.25c-0.414,0-0.75-0.336-0.75-0.75v-3.75H7.5c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h3.75V7.5
			c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v3.75h3.75c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75h-3.75v3.75
			C12.75,16.914,12.414,17.25,12,17.25z"
				></path>
				<path
					d="M12,24C5.383,24,0,18.617,0,12C0,5.383,5.383,0,12,0c6.617,0,12,5.383,12,12C24,18.617,18.617,24,12,24z M12,1.5
			C6.21,1.5,1.5,6.21,1.5,12c0,5.79,4.71,10.5,10.5,10.5c5.79,0,10.5-4.71,10.5-10.5C22.5,6.21,17.79,1.5,12,1.5z"
				></path>
			</g>
		</svg>
	</div>
</div>

<div cdkDropListGroup class="example-list" (cdkDropListDropped)="drop($event)">
	<div class="card-container" *ngFor="let excess of displayExcess">
		<div class="claim-card">
			<div class="claim-info">
				<p>{{ excess?.skill }}</p>
				<p>{{ excess?.sp }}</p>
				<p>{{ excess?.state?.name }}</p>
			</div>
		</div>

		<form [formGroup]="excessForm">
			<div class="job-cards-container">
				<div cdkDropList [cdkDropListData]="excess?.excess" (cdkDropListDropped)="drop($event)">
					<div *ngFor="let ex of excess?.excess" cdkDrag [cdkDragData]="ex" [cdkDragDisabled]="ex?.disabled">
						<div class="example-box">
							<div class="job-card-section">
								<div class="job-card-input">
									<flx-dropdown
										[itemsOption]="excessType$ | async"
										[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
										placeHolder="Excess type"
										[formControlName]="ex?.controls.ftype"
										size="normal"
									></flx-dropdown>
								</div>
								<div class="job-card-input">
									<flx-input-text placeholder="amount" size="normal" [formControlName]="ex?.controls.famount"></flx-input-text>
								</div>
								<div class="job-card-input">
									<flx-dropdown
										[itemsOption]="paymentMethod$ | async"
										[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
										placeHolder="Payment method"
										[formControlName]="ex?.controls.fmethod"
										size="normal"
									></flx-dropdown>
								</div>
								<div></div>

								<div class="icon-position icon-size icon-color" (click)="removeExcess(ex)">
									<svg
										_ngcontent-jxs-c12=""
										xml:space="preserve"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										style="enable-background: new 0 0 24 24"
										version="1.1"
										viewBox="0 0 24 24"
										x="0px"
										xmlns="http://www.w3.org/2000/svg"
										y="0px"
									>
										<title _ngcontent-jxs-c12=""></title>
										<g _ngcontent-jxs-c12="">
											<path
												d="M12,23.999c-6.617,0-12-5.383-12-12c0-6.617,5.383-12,12-12c6.617,0,12,5.383,12,12C24,18.616,18.617,23.999,12,23.999z
										M12,1.499c-5.79,0-10.5,4.71-10.5,10.5c0,5.79,4.71,10.5,10.5,10.5c5.79,0,10.5-4.71,10.5-10.5C22.5,6.209,17.79,1.499,12,1.499z"
											></path>
											<path
												d="M16.5,17.249c-0.2,0-0.389-0.078-0.53-0.22L12,13.06l-3.97,3.97c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22
										c-0.292-0.292-0.292-0.768,0-1.061l3.97-3.97l-3.97-3.97c-0.142-0.141-0.22-0.33-0.22-0.53s0.078-0.389,0.22-0.53
										c0.141-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22l3.97,3.97l3.97-3.97c0.142-0.142,0.33-0.22,0.53-0.22
										s0.389,0.078,0.53,0.22c0.142,0.141,0.22,0.33,0.22,0.53s-0.078,0.389-0.22,0.53l-3.97,3.97l3.97,3.97
										c0.292,0.292,0.292,0.768,0,1.061C16.889,17.171,16.7,17.249,16.5,17.249z"
											></path>
										</g>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div style="opacity: 0" *ngIf="excess?.excess.length === 0"><span style="opacity: 0">No excess found</span></div>
				</div>
			</div>
		</form>
	</div>
</div>

<flx-modal type="info" [displayHeading]="false" [navButtons]="modalButtons" [backgroundClose]="false">
	<flx-heading size="large" color="primary" align="center">
		<span class="add-rem-heading">Add Excess</span>
	</flx-heading>
	<form [formGroup]="addExcessForm" class="add-reminder-form">
		<div class="message-details">
			<div class="add-excess-fields">
				<div>
					<div class="add-excess-field">
						<flx-dropdown
							formControlName="job_id"
							[searchEnabled]="false"
							placeHolder="Job"
							[itemsOption]="jobs_on_claim$ | async"
							[displayOptions]="{ displayKey: 'display', valueKey: 'id' }"
						></flx-dropdown>
					</div>
					<div class="add-excess-field">
						<flx-input-text formControlName="amount" [maxLength]="30" placeholder="Amount"></flx-input-text>
					</div>
				</div>

				<div>
					<div class="add-excess-field">
						<flx-dropdown
							formControlName="who_collects"
							[searchEnabled]="false"
							placeHolder="Who collects"
							[itemsOption]="excessType$ | async"
							[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
						></flx-dropdown>
					</div>

					<div class="add-excess-field">
						<flx-dropdown
							formControlName="how_collect"
							placeHolder="Payment method"
							[itemsOption]="paymentMethod$ | async"
							[displayOptions]="{ displayKey: 'name', valueKey: 'id' }"
						></flx-dropdown>
					</div>
				</div>
			</div>
		</div>
		<br />
	</form>
</flx-modal>
