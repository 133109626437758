import { FileSize, FileSizeUnit } from './models';

/**
 * Function to convert a size struct to the number of bytes behind it
 * @param fileSize
 */
export function convertSizeToNumber(fileSize: FileSize): number {
	let { size, unit = FileSizeUnit.Bytes } = fileSize;
	let sizeMult;
	switch (unit) {
		case FileSizeUnit.Bytes:
			sizeMult = 1;
			break;
		case FileSizeUnit.KiloBytes:
			sizeMult = 1 * 1000;
			break;
		case FileSizeUnit.MegaBytes:
			sizeMult = 1 * 1000 * 1000;
			break;
		case FileSizeUnit.GigaBytes:
			sizeMult = 1 * 1000 * 1000 * 1000;
			break;
		case FileSizeUnit.TeraBytes:
			sizeMult = 1 * 1000 * 1000 * 1000 * 1000;
			break;

		case FileSizeUnit.KibiBytes:
			sizeMult = 1 * 1024;
			break;
		case FileSizeUnit.MebiBytes:
			sizeMult = 1 * 1024 * 1024;
			break;
		case FileSizeUnit.GibiBytes:
			sizeMult = 1 * 1024 * 1024 * 1024;
			break;
		case FileSizeUnit.TebiBytes:
			sizeMult = 1 * 1024 * 1024 * 1024 * 1024;
			break;

		default:
			sizeMult = 1;
			break;
	}
	return size * sizeMult;
}
/**
 *
 * @param num the number of bytes to convert
 * @param bi @optional set to work with biBytes @default false
 */
export function convertNumberToSize(num: number, bi: boolean = false) {
	// 1 - 999
	const divisor = bi === false ? 1000 : 1024;
	let divCount = 0;
	while (num > divisor) {
		num = num / divisor;
		divCount++;
	}
	num = Math.trunc(num * 100) / 100;
	// let ret = {divCount ,num :  (Math.trunc(num * 100 ))/100 , divisor}
	switch (divCount) {
		case 0: {
			return { size: num, unit: FileSizeUnit.Bytes };
		}
		case 1: {
			return { size: num, unit: bi ? FileSizeUnit.KibiBytes : FileSizeUnit.KiloBytes };
		}
		case 2: {
			return { size: num, unit: bi ? FileSizeUnit.MebiBytes : FileSizeUnit.MegaBytes };
		}
		case 3: {
			return { size: num, unit: bi ? FileSizeUnit.GibiBytes : FileSizeUnit.GigaBytes };
		}
		case 4: {
			return { size: num, unit: bi ? FileSizeUnit.TebiBytes : FileSizeUnit.TeraBytes };
		}
	}
}
/**
 * comparing two sizes, returns a diff of the two
 * @param num : number
 * @param size : FileSize
 *
 * < 1 if num is bigger , 0 if equal, > 1 if size
 */
export function compareNumberToSize(num: number, size: FileSize): number {
	return num - convertSizeToNumber(size);
}
/**
 * comparing two sizes, returns a diff of the two
 * @param size : FileSize
 * @param num : number
 *
 * < 1 if size is bigger , 0 if equal, > 1 if num
 */
export function compareSizeToNumber(size: FileSize, num: number): number {
	return compareNumberToSize(num, size) * -1;
}
/**
 * comparing two sizes, returns a diff of the two
 * @param size1 : FileSize
 * @param size2 :FileSize
 * < 1 if 1 is bigger , 0 if equal, > 1 if size2
 */
export function compareSizeToSize(size1: FileSize, size2: FileSize): number {
	return convertSizeToNumber(size1) - convertSizeToNumber(size2);
}
