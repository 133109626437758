<flx-flex-container alignItems="center">
	<div class="logo-display"><span class="sb-logo" [inlineSVG]="logo_url"></span></div>
	<flx-heading size="small" align="center" weight="bold" margin="2rem 0 4rem 0">{{ byline }}</flx-heading>
	<flx-panel class="glow-panel" hasBackground="true" density="cozy" width="400px" maxWidth="400px" margin="auto">
		<flx-heading size="extraLarge" type="page">Forgot Password</flx-heading>
		<flx-inline-error *ngIf="error$ | async as error" [errorMessage]="error"></flx-inline-error>
		<form [formGroup]="forgotForm" novalidate (submit)="sendResetLink()">
			<fieldset [disabled]="isSubmitting" class="form-group">
				<flx-label> Enter your username or email address</flx-label>
				<flx-input-text formControlName="email" placeholder="Email Address"></flx-input-text>
				<flx-button class="reset-button" [disabled]="forgotForm.invalid" color="primary" type="submit">Send Password Reset Link</flx-button>
				<a routerLink="/auth/login">Back to Login</a>
			</fieldset>
		</form>
	</flx-panel>
	<div class="sure-logo"><span>Powered by</span> <img src="/assets/images/4-sure-logo-new.svg" /></div>
</flx-flex-container>
