import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { of } from 'rxjs';

@Component({
  selector: 'flx-sp-mini-dashboard',
  templateUrl: './sp-mini-dashboard.component.html',
  styleUrl: './sp-mini-dashboard.component.scss',
})
export class SPMiniDashboardComponent implements OnInit {
  chartData = { graph_data: [{ counts: [], label: '' }] };
	@ViewChild(BaseChartDirective) chart?: BaseChartDirective;
	colors = ['#29a845', '#a33c02', 'blue', 'navy', 'orange', 'purple', 'red', 'silver', 'teal', 'white', 'yellow'];

	public barChartOptions: ChartConfiguration['options'] = {
		responsive: true,
		scales: {
			x: {
				stacked: true, // Enable stacking for x-axis
				grid: {
					display: false // Hide vertical grid lines for x-axis
				},
				ticks: {
					color: '#bababa', // Set the font color of labels
					minRotation: 45 // Rotate labels by 45 degrees (diagonally)
				}
			},
			y: {
				min: 0,
				stacked: true, // Enable stacking for y-axis
				ticks: {
					display: false // Hide y-axis ticks
				}
			}
		},
		plugins: {
			legend: {
				display: false
			}
		}
	};
	public barChartType: ChartType = 'bar';

	public barChartData: ChartData<'bar'> = {
		labels: [],
		datasets: []
	};

	status_this_month = of({
		jobs_in_progress: 12,
		submitted_for_payment: 3,
		payment_so_far_this_month: '12 560',
		awaiting_payment: '2 450',
		my_current_score_card: 67
	})

	constructor(private http: HttpClient) {}

	ngOnInit(): void {
		this.fetchChartData();
	}

	fetchChartData(): void {
		this.chartData = {
			graph_data: [
				{
					label: 'Straight Through Payment',
					counts: [{ state: 176, value: 987 }]
				},
				{
					label: 'Problem On Validation',
					counts: [
						{ state: 177, value: 187 },
						{ state: 232, value: 670 },
						{ state: 233, value: 900 }
					]
				},
				{
					label: 'Problem With Installation',
					counts: [
						{ state: 185, value: 575 },
						{ state: 180, value: 457 },
						{ state: 181, value: 200 },
						{ state: 185, value: 654 }
					]
				}
			]
		};

		const { graph_data = [] } = this.chartData;
		if (!graph_data?.length) return;

		const countsObjLength = graph_data.map(item => item?.counts?.length ?? 0); // Get number of each entry's state count
		const longestArrLength = Math.max(...countsObjLength); // used to make the data length the same for every stack dynamically
		const tempData = [];

		this.barChartData['labels'] = graph_data?.map(item => item.label);

		// Create Dynamic Stacks Objects Based On The Longest Array Length
		for (let i = 0; i < longestArrLength; i++) {
			tempData.push(Array(longestArrLength).fill(0, 0, longestArrLength));
			const colors =
				this.barChartData['labels'][i] === 'Straight Through Payment'
					? [this.colors[0]]
					: this.barChartData['labels'][i] === 'Problem On Validation'
					? [...this.colors.slice(1)]
					: [...this.colors.slice(4)];
			this.barChartData['datasets'].push({
				data: [],
				label: '',
				backgroundColor: [...colors],
				barPercentage: 0.2
			});
		}

		this.setChartDataView(graph_data, tempData);
	}

	setChartDataView(graph_data, tempData): void {
		graph_data.forEach((item, i) => {
			item?.counts?.forEach((obj, j) => {
				tempData[j][i] = obj['value'] ?? 0;
				this.barChartData['datasets'][j]['data'] = [...tempData[j]];
				this.barChartData['datasets'][j]['label'] = `State - ${obj?.state} Count`;
			});
		});
	}
}
