import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BigFormService } from '@flexus/core';
import { Observable, Subscription } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';

@Component({
	selector: 'flx-pinggo-csv',
	templateUrl: 'pinggo-csv-payments.component.html',
	styleUrls: ['pinggo-csv-payments.component.scss']
})
export class PinggoCSVPaymentComponent implements OnInit, OnDestroy {
	// variables

	@Input() csvPayments$!: Observable<any>;
	csv_payments$!: Observable<any>;
	paymentSub: Subscription;
	paymentitems;
	included_payments_array: any[] = [];
	excluded_payments_array: any[] = [];

	constructor(public bf: BigFormService) {}
	ngOnInit() {
		this.paymentSub = this.csvPayments$
			.pipe(
				skipWhile((csvlist: any) => !csvlist),
				take(1),
				map((result: any) => {
					this.paymentitems = result;
					this.included_payments_array = [...this.paymentitems];
					this.bf.patchValues({ included_payments: this.included_payments_array });
					this.handleExcludeItem(null);
				})
			)
			.subscribe();
	}
	handleExcludeItem(event: any) {
		if (event) {
			const { item, exclude } = event;
			if (exclude) {
				this.excluded_payments_array.push(item);
				this.bf.patchValues({ excluded_payments: this.excluded_payments_array });
				this.bf.getControl('all_excluded').setValue(true);
			} else {
				this.excluded_payments_array = this.excluded_payments_array.filter((it: any) => it.job_id !== item.job_id);
				this.bf.patchValues({ excluded_payments: this.excluded_payments_array });
				this.bf.getControl('all_excluded').setValue(true);
			}
		}
		this.included_payments_array = this.paymentitems.filter((it: any) => {
			return !this.excluded_payments_array.map((res: any) => res.job_id).includes(it.job_id);
		});
		this.bf.patchValues({ included_payments: this.included_payments_array });
		if (this.included_payments_array.length === 0) {
			this.bf.getControl('all_excluded').setValue(false);
			this.bf.bigForm.updateValueAndValidity();
		}
	}
	ngOnDestroy(): void {
		if (this.paymentSub) {
			this.paymentSub.unsubscribe();
		}
	}
}
