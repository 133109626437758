import { SP_GLOBAL_21 } from './SP_GLOBAL_21';
import { SP_GLOBAL_25 } from './SP_GLOBAL_25';
import { SP_GLOBAL_26 } from './SP_GLOBAL_26';
import { SP_GLOBAL_34 } from './SP_GLOBAL_34';
import { SP_GLOBAL_41 } from './SP_GLOBAL_41';
import { SP_GLOBAL_42 } from './SP_GLOBAL_42';
import { SP_GLOBAL_46 } from './SP_GLOBAL_46';
import { SP_GLOBAL_47 } from './SP_GLOBAL_47';
import { SP_GLOBAL_62 } from './SP_GLOBAL_62';
import { SP_GLOBAL_65 } from './SP_GLOBAL_65';
import { SP_GLOBAL_66 } from './SP_GLOBAL_66';
import { SP_GLOBAL_67 } from './SP_GLOBAL_67';
import { SP_GLOBAL_69 } from './SP_GLOBAL_69';
import { SP_GLOBAL_80 } from './SP_GLOBAL_80';
import { SP_GLOBAL_82 } from './SP_GLOBAL_82';
import { SP_GLOBAL_85 } from './SP_GLOBAL_85';
import { SP_GLOBAL_86 } from './SP_GLOBAL_86';
import { SP_GLOBAL_91 } from './SP_GLOBAL_91';
import { SP_GLOBAL_94 } from './SP_GLOBAL_94';
import { SP_GLOBAL_98 } from './SP_GLOBAL_98';
import { SP_GLOBAL_101 } from './SP_GLOBAL_101';
import { SP_GLOBAL_105 } from './SP_GLOBAL_105';
import { SP_GLOBAL_156 } from './SP_GLOBAL_156';
import { SP_GLOBAL_162 } from './SP_GLOBAL_162';
import { SP_GLOBAL_170 } from './SP_GLOBAL_170';
import { SP_GLOBAL_171 } from './SP_GLOBAL_171';
import { SP_GLOBAL_201 } from './SP_GLOBAL_201';
import { SP_GLOBAL_206 } from './SP_GLOBAL_206';
import { SP_GLOBAL_207 } from './SP_GLOBAL_207';
import { SP_GLOBAL_326 } from './SP_GLOBAL_326';
import { SP_GLOBAL_ADD_USER } from './SP_GLOBAL_ADD_USER';


export const SP_GLOBAL_STATES = {
	[SP_GLOBAL_21.id]: SP_GLOBAL_21,
	[SP_GLOBAL_25.id]: SP_GLOBAL_25,
	[SP_GLOBAL_26.id]: SP_GLOBAL_26,
	[SP_GLOBAL_34.id]: SP_GLOBAL_34,
	[SP_GLOBAL_41.id]: SP_GLOBAL_41,
	[SP_GLOBAL_42.id]: SP_GLOBAL_42,
	[SP_GLOBAL_46.id]: SP_GLOBAL_46,
	[SP_GLOBAL_47.id]: SP_GLOBAL_47,
	[SP_GLOBAL_62.id]: SP_GLOBAL_62,
	[SP_GLOBAL_65.id]: SP_GLOBAL_65,
	[SP_GLOBAL_66.id]: SP_GLOBAL_66,
	[SP_GLOBAL_67.id]: SP_GLOBAL_67,
	[SP_GLOBAL_69.id]: SP_GLOBAL_69,
	[SP_GLOBAL_80.id]: SP_GLOBAL_80,
	[SP_GLOBAL_82.id]: SP_GLOBAL_82,
	[SP_GLOBAL_85.id]: SP_GLOBAL_85,
	[SP_GLOBAL_86.id]: SP_GLOBAL_86,
	[SP_GLOBAL_91.id]: SP_GLOBAL_91,
	[SP_GLOBAL_94.id]: SP_GLOBAL_94,
	[SP_GLOBAL_98.id]: SP_GLOBAL_98,
	[SP_GLOBAL_101.id]: SP_GLOBAL_101,
	[SP_GLOBAL_105.id]: SP_GLOBAL_105,
	[SP_GLOBAL_156.id]: SP_GLOBAL_156,
	[SP_GLOBAL_162.id]: SP_GLOBAL_162,
	[SP_GLOBAL_170.id]: SP_GLOBAL_170,
	[SP_GLOBAL_171.id]: SP_GLOBAL_171,
	[SP_GLOBAL_201.id]: SP_GLOBAL_201,
	[SP_GLOBAL_206.id]: SP_GLOBAL_206,
	[SP_GLOBAL_207.id]: SP_GLOBAL_207,
	[SP_GLOBAL_326.id]: SP_GLOBAL_326,
  [SP_GLOBAL_ADD_USER.id]: SP_GLOBAL_ADD_USER,
};
