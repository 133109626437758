import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GetInvoicesService {
	private invoicesLoadingsubject = new BehaviorSubject<any>(null);

	invoicesLoading$ = this.invoicesLoadingsubject.asObservable();

	constructor() {}

	setUILoadingStatus(_new_status: any) {
		this.invoicesLoadingsubject.next(_new_status);
	}
}
