import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap, skipWhile } from 'rxjs/operators';
import dinoql, { addResolvers } from 'dinoql';

// type QueryOption = { where: { queries: Object | Object[]; options?: { deep?: boolean } } };

// const runOptions = (data, queryOption: QueryOption) => {
//   if (queryOption && queryOption['where']) {
//     return where(data, queryOption['where']);
//   } else {
//     return data;
//   }
// };

@Injectable({ providedIn: 'root' })
export class StoreQuery {
	//

	constructor(private store: Store<any>) {}

	queryStore(gql, queryOptions?) {
		return this.store.pipe(
			skipWhile(store => !store),
			map(store => {
				return dinoql(store)(gql);
			})
			// map((data) => runOptions(data, queryOptions)),
		);
	}

	queryObject(gql, data$) {
		return data$.pipe(
			skipWhile(store => !store),
			map(data => {
				return dinoql(data)(gql);
			})
		);
	}

	querySimpleObject(gql, obj) {
		return dinoql(obj)(gql);
	}
}
