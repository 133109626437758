import { Flow_0_0_2, NodePoint_0_0_2, getData, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';

import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import { of } from 'rxjs';
import { filter, map, pluck, skipWhile, switchMap, take } from 'rxjs/operators';
import { AUTO_SELECT_TEMPLATE_DECISION, BILLING_INVOICE, SELECT_CALLOUT_TEMPLATE, billingServerCalls } from './BILLING';

export const SP_GLOBAL_91: Flow_0_0_2 = {
	id: '91',
	name: 'quote_query',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Internal Assessor Query : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Internal Assessor Query';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		IAFiles: {
			serviceVariable: 'spService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		claimDetails: {
			errorMessage: 'Could get assessor details',
			serviceVariable: 'spService',
			functionName: 'getQuoteQueryClaimDetails'
		},
		latestQuotationFile: {
			errorMessage: 'Could not get latest quote',
			directCall: (http, store, sq) => {
				return store.select(getData).pipe(
					pluck('latestQuotation'),
					skipWhile(x => !x),
					take(1),
					switchMap((latestQuotation: any) =>
						http
							.post(environment.api_url + 'v1/file_action/get_file', {
								file_id: latestQuotation.id,
								return_type: 1
							})
							.pipe(map((file: any) => file.success && file.payload && { file: { base64: file.payload?.data, type: 'pdf' } }))
					)
				);
			}
		},
		...billingServerCalls
	},

	instructions: {
		editRoles: {
			0: 'Reply to quote query'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'QuoteQueryNotification',
	nodes: {
		QuoteQueryNotification: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Quote Query',
							instructions: ['There is a query on the quote']
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: {
								showHeading: false
							},
							objectInfo$: 'claimDetails'
						}
					}
				]
			},
			navs: [{ text: 'Continue', linkType: 'submit', color: 'primary', nextNode: 'ViewDocumentsModule' }]
		},
		ViewDocumentsModule: {
			name: 'Documents',
			showTabs: true,
			component: 'FLXFileBrowserComponent',
			inputs: {
				files: 'dataFile',
				showFilters: 'false',
				display: 'image-grid',
				location: 'context-menu'
			},
			serverCalls: {
				files: {
					serviceVariable: 'silService',
					functionName: 'getAllJobFiles',
					responseSlice: 'payload',
					errorMessage: 'Could not get files from server!'
				},
				dataFile: {
					serviceVariable: '',
					functionName: '',
					errorMessage: 'No file could be found!',
					loaderID: 'dataFile',
					directCall: (http, store, sq) => {
						return sq
							.queryStore(
								gql`
									{
										selectedContext {
											IAFiles {
												id
											}
										}
									}
								`
							)
							.pipe(
								skipWhile(result => !result),
								take(1),
								switchMap(({ IAFiles }) => {
									if (IAFiles.length > 0) {
										IAFiles.forEach(file => {
											return http
												.post<any>(environment.api_url + `v1/file_action/get_file/`, {
													file_id: file.id,
													return_type: 1
												})
												.pipe(
													filter(x => !!x && Object.keys(x).length !== 0),
													take(1),
													map(value => {
														return value.payload;
													})
												);
										});
									}

									//Returning of empty object as when returning empty the loader isn't being removed - tried EMPTY and it does not work either.
									return of({});
								})
							);
					}
				}
			}
		},
		ViewPreviousQuote: {
			name: 'Previous Quote',
			showTabs: true,
			initFormFields: (bf, _i, _in, _sq, store) => {
				store
					.select(getData)
					.pipe(take(1), pluck('latestQuotationFile'))
					.subscribe(latestQuote => {
						const latestQuoteControl = new UntypedFormControl([latestQuote]);
						if (!bf.bigForm.get('fileQueue')) {
							bf.bigForm.addControl('fileQueue', latestQuoteControl);
						} else {
							bf.bigForm.setControl('fileQueue', latestQuoteControl);
						}
					});
			},
			component: 'FLXFileUploadWithPreviewComponent',
			inputs: {
				purposes: [],
				heading: 'Quotation',
				multipleUploads: false,
				allowedFileTypes: [],
				containerWidth: '50vw',
				maxWidth: '50vw'
			}
		},
		Response: {
			name: 'Response',
			showTabs: true,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Respond to Assessor',
							headingConfig: { weight: 'normal' }
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: `Please respond to the assessor's query`,
									inputType: 'textarea',
									formControlName: 'queryreply'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: (bf, item, instance, storeQuery, store) => {
				bf.patchValues({ queryreply: bf.getControl('queryreply')?.value || '', new_state: '92' });
				bf.bigForm.get('queryreply')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			checkValidityForFields: ['queryreply'],
			navs: [
				{
					text: 'Adjust Quote',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
					color: 'secondary',
					location: 'center'
				},
				{
					text: 'Continue',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'spService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!'
						}
					},
					color: 'primary'
				}
			]
		},
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'quote',
				docTitle: 'Quotation',
				numberLabel: 'Quote',
				currentState: 91,
				newState: 27,
				showLineItemGenerator: true,
				canEditLineItems: true,
				boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
				boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Standard Bank Insurance Limited',
				clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL'
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'Response',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		queryreply: [
			(queryreply, storeObject) => {
				const fullItemTwo = storeObject['selectedContext']?.fullItemTwo;

				const queries = fullItemTwo?.job_information?.ia_query;
				const ia_query = Array.isArray(queries) ? [...queries] : queries && typeof queries === 'object' && Object.keys(queries).length > 0 ? [{ ...queries }] : [];

				const lastIndex = ia_query.length - 1;

				if (lastIndex >= 0) {
					ia_query[lastIndex] = {
						...ia_query[lastIndex],
						queryreply: queryreply, // Update the queryreply
						queryreply_datetime: new Date().toISOString()
					};
				}

				return ia_query;
			},
			'job_information.ia_query'
		],
		total: [
			(a, b, bf) => {
				return bf.total + bf.vatRate;
			},
			'job_information.quote_amount'
		],
		invoiceNumber: 'job_information.quote_number'
	}
};
