import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
// import { getClaimExtra } from 'apps/studio/src/app/workflow/store';
import { map, take, skipUntil, tap, skipWhile } from 'rxjs/operators';
import { getCurrentUser, NetworkService, StoreQuery, getItemTwoContextMenu, getItemOneContextMenu, ManifestController } from '@flexus/core';
import { getActiveOrganization, getPermissions, getAllInfo } from '@flexus/core';
import { getCurrentPage } from '@flexus/ux';
import gql from 'graphql-tag';
import { flatten, values } from 'ramda';
import { User } from '@flexus/models';

@Component({
	selector: 'flx-list-view',
	templateUrl: './details-view.component.html',
	styleUrls: ['./details-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXDetailsComponent implements OnInit, OnDestroy {
	@Input() list$: Observable<any>;
	currentPage: number;
	pageSize = 30;
	itemOneContextMenuList = [];
	itemTwoContextMenuList = [];
	itemOnePermissions: Function[] = [];
	itemTwoPermissions: Function[] = [];
	allInfo$: Observable<any>;
	stateDescriptions$: Observable<any>;
	claimTypeDescriptions$: Observable<any>;
	user$: Observable<User>;
	isOnline$: Observable<boolean>;
	activeOrg$: Observable<any>;
	// loading$;

	currentPageSubscription: Subscription;

	constructor(private _store: Store<any>, private controller: ManifestController<any>, private sq: StoreQuery, private network: NetworkService, private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this.getAllInfo();
		this.getStateDescriptions();
		this.getClaimTypeDescriptions();
		this.getActiveOrg();
		this.getNetworkState();
		this.getUser();
		this.getPermissions();
		this.getItemOneContextMenuList();
		this.getItemTwoContextMenuList();
		this.getCurrentPage();
	}

	get theList$() {
		return this.list$.pipe(skipUntil(this.allInfo$), skipUntil(this.activeOrg$));
	}

	private getActiveOrg() {
		this.activeOrg$ = this.controller.select(getActiveOrganization);
	}

	private getNetworkState() {
		this.isOnline$ = this.network.isOnline;
	}

	trackByFunc(idx, claim) {
		return claim?.id;
	}

	private getUser() {
		this.user$ = this._store.select(getCurrentUser);
	}

	getCurrentPage() {
		this.currentPageSubscription = this._store.select(getCurrentPage).subscribe(pageNum => {
			this.currentPage = pageNum;
			this.cdr.detectChanges();
		});
	}

	getItemOneContextMenuList() {
		this.controller
			.select(getItemOneContextMenu)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe((contextMenu: any) => {
				Object.entries(contextMenu).forEach(([key, value]) => {
					this.itemOneContextMenuList.push({
						id: value['id'],
						name: value['name'],
						key,
						includeForOnlyStates: value['includeForOnlyStates'] || ['all'],
						excludeForOnlyStates: value['excludeForOnlyStates'] || [],
						excludeForOnlyRoles: value['excludeForOnlyRoles'] || [],
						includeFilterFunction: value['includeFilterFunction'],
						isVisible: value['isVisible']
					});
				});
			});
	}

	getItemTwoContextMenuList() {
		this.controller
			.select(getItemTwoContextMenu)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe((contextMenu: any) => {
				Object.entries(contextMenu).forEach(([key, value]) => {
					this.itemTwoContextMenuList.push({
						name: value['name'],
						key,
						includeForOnlyStates: value['includeForOnlyStates'] || ['all'],
						excludeForOnlyStates: value['excludeForOnlyStates'] || [],
						includeFilterFunction: value['includeFilterFunction']
					});
				});
			});
	}

	getPermissions() {
		this.controller
			.select(getPermissions)
			.pipe(take(1))
			.subscribe(perm => {
				if (perm) {
					this.itemOnePermissions = Object.values(perm['itemOne']);
					this.itemTwoPermissions = Object.values(perm['itemTwo']);
				}
			});
	}

	private getAllInfo() {
		this.allInfo$ = this._store.pipe(select(getAllInfo));
	}

	private getStateDescriptions() {
		this.stateDescriptions$ = this.sq
			.queryStore(
				gql`
					{
						allInfo {
							states {
								id
								description
							}
						}
					}
				`
			)
			.pipe(
				map(values),
				map<any, any>(flatten),
				map((data: any[]) => data?.reduce((acc, val: any) => ({ ...acc, [val.id]: val.description }), {}))
			);
	}

	private getClaimTypeDescriptions() {
		this.claimTypeDescriptions$ = this.sq
			.queryStore(
				gql`
					{
						allInfo {
							claim_types {
								id
								name
							}
						}
					}
				`
			)
			.pipe(
				map(values),
				map<any, any>(flatten),
				map((data: any[]) => data?.reduce((acc, val: any) => ({ ...acc, [val.id]: val.name }), {}))
			);
	}

	ngOnDestroy() {
		if (this.currentPageSubscription) {
			this.currentPageSubscription.unsubscribe();
		}
	}
}
