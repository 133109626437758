import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicChartComponent } from './dynamic-chart.component';
import { NgChartsModule, NgChartsConfiguration } from 'ng2-charts';

@NgModule({
	declarations: [DynamicChartComponent],
	imports: [CommonModule, NgChartsModule],
	exports: [DynamicChartComponent],
	providers: [{ provide: NgChartsConfiguration, useValue: { generateColors: false } }]
})
export class FLXDynamicChartModule {}
