import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getByIndex, returnOrDefault } from '@flexus/utilities';
import { directionalGenericComparator, FillField, SortEvent, SortState } from '@flexus/models';

@Component({
	selector: 'flx-sort-bar',
	templateUrl: './sort-bar.component.html',
	styleUrls: ['./sort-bar.component.scss']
})
// TODO: finish this component
export class SortBarComponent implements OnInit {
	// ============================= Private Variables ================================
	private _disabled: boolean;
	// ============================= Public Variables =================================
	_sortFields: FillField[];
	_direction: { [key: string]: SortState } = {};
	_sortStates = SortState;
	// ============================= Inputs ===========================================
	@Input()
	set sortFields(fields: FillField[]) {
		// console.log('fields');
		if (fields && fields.length > 0) {
			fields.forEach(field => {
				this._direction[field.displayName] = SortState.inactive;
			});
			this._sortFields = fields;
		}

		// console.log({ direction: this._direction, sort: this._sortFields });
	}

	@Input()
	set disabled(dis: boolean) {
		this._disabled = dis;
	}

	get disabled() {
		return returnOrDefault(this._disabled, false);
	}
	// ============================= Outputs ==========================================
	@Output() sortEvents: EventEmitter<SortEvent>;
	// ============================= Private Functions ================================
	// ============================= Public Functions =================================
	cycleField(fieldName: string, value?: string) {
		if (this.disabled === true) {
			return;
		}
		this._direction[fieldName] = (this._direction[fieldName] + 1) % 3;
		this.emitSorts();
	}

	emitSorts() {
		const sortArr = Object.keys(this._direction)
			.filter((directionKey: string) => this._direction[directionKey] !== SortState.inactive)
			.map(directionKey => ({
				comp: directionalGenericComparator(this._direction[directionKey]),
				path: this._sortFields[this._sortFields.findIndex(fill => fill.displayName === directionKey)]?.path
			}));

		this.sortEvents.emit({
			type: 'sortAltered',
			fields: this._sortFields,
			sortFunction: (x, y) =>
				sortArr.reduce((re, entry) => {
					// console.log('hi', entry.path);
					// console.log({
					//   x: getByIndex(x, entry.path, undefined),
					//   y: getByIndex(y, entry.path, undefined),
					//   res: entry.comp(
					//     getByIndex(x, entry.path, undefined),
					//     getByIndex(y, entry.path, undefined)
					//   ),
					//   current: re
					// });
					return re === 0 ? entry.comp(getByIndex(x, entry.path, undefined), getByIndex(y, entry.path, undefined)) : re;
				}, 0)
		});
	}
	// ============================= Constructor ======================================
	constructor() {
		this.sortEvents = new EventEmitter<SortEvent>();
	}

	// ============================= Life-Cycle Hooks =================================
	ngOnInit() {
		// this._direction = {};
	}
}
