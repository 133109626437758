import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YesNoComponent } from './yes-no.component';

@NgModule({
	imports: [CommonModule],
	declarations: [YesNoComponent],
	exports: [YesNoComponent]
})
export class FLXYesNoModule {}
