<div class="invoice-summary">
	<ng-container>
		<div *ngIf="!isNonVatSP" class="invoice-summary__totals align-right">
			<div class="invoice-summary__totals-descriptors">
				<div>Subtotal</div>
				<div>{{ vat || 0 }}% VAT Price</div>
				<div>Credit Note Total</div>
			</div>
			<div class="invoice-summary__totals-amounts">
				<div class="right-margined">
					<ng-container> R {{ subTotal || 0 | number: '1.2-2' }} </ng-container>
				</div>
				<div class="right-margined">
					<ng-container>
						<ng-container> R {{ vatAmount || 0 | number: '1.2-2' }} </ng-container>
					</ng-container>
				</div>
				<div class="right-margined">
					<ng-container>
						<ng-container> R {{ creditNoteTotal || 0 | number: '1.2-2' }} </ng-container>
					</ng-container>
				</div>
			</div>
		</div>

		<div *ngIf="isNonVatSP" class="invoice-summary__totals align-right">
			<div class="invoice-summary__totals-descriptors">
				<div>Credit Note Total</div>
			</div>
			<div class="invoice-summary__totals-amounts">
				<div class="right-margined">
					<ng-container>
						<ng-container> R {{ creditNoteTotal || 0 | number: '1.2-2' }} </ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</div>
