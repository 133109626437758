import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UploadInput } from 'ngx-uploader';
import { Observable } from 'rxjs';
import { returnOrDefault } from '@flexus/utilities';
import { ERSingleFileSelectConfig, FileSizeUnit } from '../models';
import { compareNumberToSize } from '../file_utils';

@Component({
	selector: 'flx-expanded-file-select',
	templateUrl: './expanded-file-select.component.html',
	styleUrls: ['./expanded-file-select.component.scss']
})
export class FLXExpandedFileSelectComponent {
	uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
	dragOver = false;

	data$: Observable<void>;
	private _config: ERSingleFileSelectConfig;

	@Output() outputData: EventEmitter<any> = new EventEmitter();

	@Input() set config(c: ERSingleFileSelectConfig) {
		if (!c.allowedFileTypes) {
			c.allowedFileTypes = ['pdf', 'image'];
		}
		if (!c.maxFileSize) {
			c.maxFileSize = { size: 10, unit: FileSizeUnit.MegaBytes };
		}
		// console.log(c);
		this._config = c;
	}
	get config() {
		return returnOrDefault(this._config, { allowedFileTypes: ['pdf', 'image'], maxFileSize: { size: 5, unit: FileSizeUnit.MegaBytes } });
	}

	ngOnInit() {
		console.log('expande file select');
	}

	checkFileAllowedTypes(f: File, allowedTypes: string[]): boolean {
		// console.log({ f });
		const type = f.type;
		for (const allowedType of allowedTypes) {
			if (new RegExp(allowedType).test(type) === true) {
				return true;
			}
		}
		return false;
	}

	getFileFromDropEvent(e: DragEvent) {
		this.dragOver = false;
		const selectedFile = e.dataTransfer.files[0];
		this.processFile(selectedFile);
	}

	processFile(selectedFile: File) {
		if (!!selectedFile) {
			if (this.checkFileAllowedTypes(selectedFile, this.config.allowedFileTypes) === true) {
				if (compareNumberToSize(selectedFile.size, this.config.maxFileSize) > 0) {
					this.outputData.emit({
						type: 'fileRejected',
						file: selectedFile,
						reason: `Wrong size please upload a file smaller than ${this.config.maxFileSize.size + this.config.maxFileSize.unit}`
					});
				} else {
					this.outputData.emit({ type: 'fileAdded', file: selectedFile });
				}
			} else {
				this.outputData.emit({ type: 'fileRejected', file: selectedFile, reason: `Wrong type please upload ${this.config.allowedFileTypes?.join()}` });
			}
		}
		this.uploadInput.emit({ type: 'removeAll' });
		// console.log({ selectedFile });
	}

	getFileFromInputSelection(e: Event): void {
		const selectedFile = (e.target as HTMLInputElement)?.files[0];
		this.processFile(selectedFile);
	}

	ngOnDestroy(): void {}
}
