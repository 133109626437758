import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXReminderCardComponent } from './reminder-card/reminder-card.component';
import { FLXAddReminderComponent } from './add-reminder/add-reminder.component';
import {
	FLXIconModule,
	FLXFormsModule,
	FLXDatepickerModule,
	FLXTimePickerModule,
	FLXHeadingsModule,
	FLXTextareaModule,
	FLXModalsModule,
	FLXButtonsModule,
	FLXContextMenuModule,
	UIElementsModule
} from '@flexus/ui-elements';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FLXDisplayReminderComponent } from './display-reminder/display-reminder.component';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { FLXSearchBarModule } from '../search-bar';

@NgModule({
	declarations: [FLXReminderCardComponent, FLXAddReminderComponent, FLXDisplayReminderComponent],
	imports: [
		CommonModule,
		FLXIconModule,
		ReactiveFormsModule,
		FormsModule,
		FLXFormsModule,
		FLXDatepickerModule,
		FLXTimePickerModule,
		FLXHeadingsModule,
		FLXTextareaModule,
		FLXModalsModule,
		FLXButtonsModule,
		PortalModule,
		OverlayModule,
		FLXContextMenuModule,
		FLXSearchBarModule,
		UIElementsModule
	],
	exports: [FLXReminderCardComponent, FLXAddReminderComponent, FLXDisplayReminderComponent]
})
export class FLXRemindersModule {}
