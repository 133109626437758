import { NgModule } from '@angular/core';
import { FLXActionPanelComponent } from './action-panel.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicLoaderModule } from '@flexus/engine';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store';
import { UIElementsModule } from '@flexus/ui-elements';
import { UICompositesModule } from '@flexus/ui-composites';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { PipesModule } from '@flexus/pipes';
import {
	APClaimSummaryModule,
	APTimeStampsModule,
	APViewDocumentsModule,
	BucketFilterModule,
	JobSummaryModule,
	RecentActivityModule,
	RemindersPluginModule,
	ScratchPadModule,
	SearchFilterModule,
	SearchModule,
	SPDetailsModule,
	TeamMembersModule,
	WhatMattersModule
} from '../../plugins/components';

// const pluginsModule = getPluginsFor('action-panel');
const action_panel_modules = [
	APClaimSummaryModule,
	BucketFilterModule,
	JobSummaryModule,
	RecentActivityModule,
	ScratchPadModule,
	SearchModule,
	SearchFilterModule,
	SPDetailsModule,
	APTimeStampsModule,
	APViewDocumentsModule,
	WhatMattersModule,
	RemindersPluginModule,
	TeamMembersModule
];
@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('actionPanel', reducer),
		...action_panel_modules,
		DynamicLoaderModule,
		ReactiveFormsModule,
		UIElementsModule,
		UICompositesModule,
		NgSlimScrollModule,
		PipesModule
	],
	declarations: [
		FLXActionPanelComponent
		// environment.plugins.includes(pluginComponentMapper['BucketFilterComponent']) ? pluginComponentMapper['BucketFilterComponent'] : null,
		// ...panelComponents
	],
	exports: [FLXActionPanelComponent, ...action_panel_modules]
})
export class ActionPanelModule {}
