import { NgModule } from '@angular/core';
import { FLXAPJobRequestPingComponent } from './job-request-ping.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UIElementsModule } from '@flexus/ui-elements';
import { UICompositesModule } from '@flexus/ui-composites';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { PipesModule } from '@flexus/pipes';
import { CommonModule } from '@angular/common';
import { ScriptLoaderDirective } from './script-loader.directive';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
	declarations: [FLXAPJobRequestPingComponent, ScriptLoaderDirective],
	exports: [FLXAPJobRequestPingComponent],
	imports: [CommonModule, ReactiveFormsModule, UIElementsModule, UICompositesModule, NgSlimScrollModule, PipesModule, NgxPaginationModule]
})
export class FLXAPJobRequestPingModule {}
