import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ModalService } from './modal.service';
import { Store } from '@ngrx/store';
import { BigFormService } from './big-form.service';

@Injectable({ providedIn: 'root' })
export class AddressService {
	provinceCodes: string[] = ['GP', 'MP', 'LP', 'WC', 'EC', 'NC', 'NW', 'FS', 'KZN'];
	constructor(private bf: BigFormService, private modalService: ModalService, private store: Store) {}

	validateAddressFormControl(ctrl: AbstractControl, config?: { onValidFn?: () => void; onInvalidFn?: () => void }) {
		if (ctrl?.value) {
			this.validateCityOrAddress(ctrl.value, isInSouthAfrica => {
				if (isInSouthAfrica) {
					ctrl.setErrors(null);
					if (config?.onValidFn) {
						config.onValidFn();
					}
				} else {
					ctrl.setErrors({ mustBeInSouthAfrica: true });
					this.showErrorModal();
					if (config?.onInvalidFn) {
						config.onInvalidFn();
					}
				}
			});
		}
	}

	private validateCityOrAddress(cityOrAddress: string, callback: (isinSouthAfrica: boolean) => void): void {
		console.log('In validateAddress func');
		const addressToMatch = `${cityOrAddress}, South Africa`;

		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ address: addressToMatch }, (results, status) => {
			console.log({ results, status });
			if (results?.length > 0) {
				const matchedAddress = results[0];
				const province = matchedAddress?.address_components?.find(component => component.types.includes('administrative_area_level_1'));
				const shortName = this.provinceCodes?.includes(province?.short_name) ? 'ZA' : 'Other';
				console.log({ shortName });
				callback(shortName === 'ZA');
			} else {
				callback(false);
			}
		});
	}

	showErrorModal() {
		this.modalService.openModalDirectly(instance => {
			instance.type = 'warning';
			instance.color = 'alert';
			instance.setMessage(['This address appears to be outside of South Africa. Please enter the correct address details.']);
			instance.navButtons = [
				{
					text: 'Okay',
					color: 'default',
					linkType: 'close',
					clickHandler: ev => {
						instance.close();
					}
				}
			];
		});
	}
}
