import { Flow_0_0_2, getFullItemTwo, getAllInfo, getData, BigFormService } from '@flexus/core';
import { KVLHeading } from '@flexus/ui-elements';
import { map, pluck, skipWhile, switchMap, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';

export const MUL_SP_CONTEXT_MENU_VIEW_JOB_SUMMARY: Flow_0_0_2 = {
	id: 'JobSummary',
	itemType: 'flow',
	includeForOnlyStates: ['all'],
	name: 'Job Summary',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant) {
							return `Summary for this job on installation: ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'View Notes:';
						}
					}
				})
			);
		},
		controls: () => () => []
	},

	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	startNode: 'JobSummary',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	nodes: {
		JobSummary: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Information'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Installer Details', 'secondary'),
							data$: 'installerKeyValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Customer Info', 'secondary'),
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Decoder Details', 'secondary'),
							data$: 'decoderInfoKeyValues'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Site Details', 'secondary'),
							data$: 'siteDetails'
						}
					}
				]
			},
			serverCalls: {
				installerKeyValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile(ai => !ai),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([itemTwo, info, teamleaders]) => {
								const sp = itemTwo?.sp;
								const sps = info.sps;
								let companyname;
								let accreditation_number;
								let qualification_number;
								for (let i = 0; i < sps.length; i++) {
									if (sp === sps[i]?.id) {
										companyname = sps[i]?.name;
									}
								}
								const tleads = teamleaders as any;
								let teamleadname = 'Team leader not assigned yet';
								for (let i = 0; i < tleads?.length; i++) {
									if (!itemTwo.team_leader) {
										teamleadname = 'Team leader not assigned yet';
									} else if (tleads[i]?.id === itemTwo?.team_leader.id) {
										teamleadname = tleads[i]?.full_name;
									}
								}
								// const accreditation_number =
								if (!itemTwo.team_leader || !itemTwo.team_leader.sp) {
									accreditation_number = 'There is no accreditation number available';
									qualification_number = 'No qualification number for the technician';
								} else {
									accreditation_number = itemTwo?.team_leader.sp.accreditation_number;
									qualification_number = itemTwo?.team_leader.qualification_number;
								}

								const installerinfo = {
									'Company name ': companyname,
									'Accreditation number ': accreditation_number,
									'Installation technician qualification number': qualification_number,
									'Installer/Technician name ': teamleadname
								};
								return [installerinfo];
							})
						);
					}
				},
				customerContactKeyValues: {
					errorMessage: 'Something went wrong customer contact info',
					directCall: (http, store, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res['payload'])
							)
						]).pipe(
							map(([itemTwo, teamleaders]) => {
								let claimtype = itemTwo?.claim?.type;
								let joblocation = 'The customer misses data';
								let newAddress;

								const {
									identities: { policy_number, subscriber_number },
									claim: {
										address,
										applicant,
										loan_information: {
											ContactNumber,
											Email,
											claimlatitude,
											claimlongitude,
											contactnumber,
											email,
											onSiteContact,
											cellnumber,
											onsitecontact,
											onsitecontact_c,
											onsitecontact_w,
											propertystreetaddress_2,
											propertysuburb,
											whatmatters
										},
										suburb
									},
									team_leader
								} = itemTwo;

								if (!itemTwo?.claim?.loan_information) {
									joblocation = 'The customer misses data';
								}
								if (!claimlatitude || !claimlongitude) {
									joblocation = 'At least one coordinate not supplied!';
								}
								if (claimlatitude && claimlongitude) {
									joblocation = `${claimlatitude}, ${claimlongitude}`;
								}

								if (claimtype !== 'Pricelock Installation') {
									newAddress = `${address}, ` + `${suburb}`;
								} else {
									newAddress = `${propertystreetaddress_2}, ` + `${propertysuburb}`;
								}

								const contacts = [onSiteContact, onsitecontact_c, onsitecontact_w, onsitecontact];
								const alt = contacts.filter(x => x && x !== ContactNumber);
								const altno = alt.length > 0 ? alt.join(', ') : ContactNumber;

								const customerContactData = {
									'Full name ': `${applicant?.first_name} ` + `${applicant?.surname}`,
									'Subscriber Number': subscriber_number ? subscriber_number : policy_number ? policy_number : 'No subscriber number found!',
									'Installation Address': newAddress,
									'Email address ': Email ? Email : email ? email : 'No email address returned!',
									'Contact number ': ContactNumber ? ContactNumber : contactnumber ? contactnumber : 'No contact number provided/available',
									'Cell number ': cellnumber ?? 'No cell number provided/available',
									'Alternate contact numbers': altno ?? 'No alternative numbers found',
									// 'GPS Coordinates ': itemTwo ? itemTwo?.location : '--'
									'GPS Coordinates ': joblocation,
									'What matters to the customer': whatmatters
								};
								return [customerContactData];
							})
						);
					}
				},
				siteDetails: {
					errorMessage: `Couldn't retrieve decoder type`,
					directCall: (_h: any, _store: any, _sq: any, _bf: BigFormService) => {
						return _store.select(getFullItemTwo).pipe(
							skipWhile((item: any) => !item),
							take(1),
							switchMap((result: any) => {
								const claimtype = result?.claim?.claim_type;
								let current_environment;
								const insuranceclaimtypes = [20, 10, 8, 7, 32, 34, 35];
								let device_type;
								let tv_type;
								let dwelling_type;
								let existing_decoders;
								let no_of_tvs;
								let wall_type;
								let teescees;
								let siteDateObj;

								if (claimtype === 36) {
									current_environment = result?.office_use?.current_environment;
									// teescees = result?.office_use?.device_details?.replacement_device?.description;
									device_type = result?.office_use?.device_details?.customer_device?.cust_device_type;
									tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs : 'Data not available';
									dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type : 'Data not available';
									wall_type = result?.claim?.loan_information?.current_environment?.wall_type ?? 'Data not avaiable';
									existing_decoders = current_environment?.existing_decoder === undefined ? 'No' : 'Yes';
									no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs : 'Data not available';
									siteDateObj = {
										'Decoder Installation': `${device_type}`,
										'Kind of TVs': `${tv_type}`,
										'Home Type': `${dwelling_type}`,
										'Existing Decoder?': `${existing_decoders}`,
										'Number of TVs': `${no_of_tvs}`,
										'Wall Type': `${wall_type}`
									};
								} else if (claimtype === 37) {
									current_environment = result?.office_use?.current_environment;
									teescees = result?.office_use?.device_details?.replacement_device?.description;
									device_type = result?.office_use?.office_use?.device_details?.customer_device?.cust_device_type;
									tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs : 'Data not available';
									dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type : 'Data not available';
									wall_type = current_environment?.wall_type ? current_environment?.wall_type : 'Data not avaiable';
									existing_decoders = current_environment?.existing_decoder === undefined ? 'No' : 'Yes';
									no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs : 'Data not available';
									const device_details = result?.office_use?.device_details;
									if (device_details instanceof Array) {
										const decodersArray: string[] = [];
										for (let i = 0; i < device_details.length; i++) {
											const decodername: string = device_details[i].replacement_device?.type;
											decodersArray.push(decodername);
										}
										if (decodersArray.length === 2) {
											device_type = decodersArray.join(' and ');
										} else if (decodersArray.length > 2) {
											const last_in_list = decodersArray.pop();
											const others_in_list: string[] = [];
											for (let i = 0; i < device_details.length - 1; i++) {
												const decodername: string = device_details[i].replacement_device?.type;
												others_in_list.push(decodername);
											}
											const prelist: string = others_in_list.join(', ');
											device_type = prelist.concat(' and ', last_in_list);
										}
									} else {
										device_type = result?.office_use?.device_details?.replacement_device?.type;
									}
									siteDateObj = {
										'Decoder Installation': `${device_type}`,
										'Ts & Cs': `${teescees}`,
										'Kind of TVs': `${tv_type}`,
										'Home Type': `${dwelling_type}`,
										'Existing Decoder?': `${existing_decoders}`,
										'Number of TVs': `${no_of_tvs}`,
										'Wall Type': `${wall_type}`
									};
								} else if (insuranceclaimtypes.includes(claimtype)) {
									device_type = result?.office_use?.device_details?.replacement_device?.name;
									// teescees = '-';
									current_environment = result?.claim?.loan_information?.current_environment;
									tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs?.description : 'Data not available';
									dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type?.description : 'Data not available';
									wall_type = 'Data not avaiable';
									existing_decoders = 'Data not avaiable';
									no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs?.description : 'Data not available';
									siteDateObj = {
										'Decoder Installation': `${device_type}`,
										'Kind of TVs': `${tv_type}`,
										'Home Type': `${dwelling_type}`,
										'Existing Decoder?': `${existing_decoders}`,
										'Number of TVs': `${no_of_tvs}`,
										'Wall Type': `${wall_type}`
									};
								} else if (claimtype === 26) {
									device_type = result?.job_information?.vouchers?.title;
									// teescees = result?.claim?.loan_information?.vouchers?.terms;
									current_environment = result?.claim?.loan_information?.current_environment;
									tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs : 'Data not available';
									dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type : 'Data not available';
									wall_type = 'Data not avaiable';
									existing_decoders = 'Data not avaiable';
									no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs : 'Data not available';
									siteDateObj = {
										'Decoder Installation': `${device_type}`,
										'Kind of TVs': `${tv_type}`,
										'Home Type': `${dwelling_type}`,
										'Existing Decoder?': `${existing_decoders}`,
										'Number of TVs': `${no_of_tvs}`,
										'Wall Type': `${wall_type}`
									};
								} else if (claimtype === 40) {
									// const arrayed: any = [...result];
									// teescees = '-';
									current_environment = result?.office_use?.current_environment;
									device_type = result?.office_use?.device_details?.replacement_device?.type;
									tv_type = current_environment?.type_of_tvs ? current_environment?.type_of_tvs?.description : 'Data not available';
									dwelling_type = current_environment?.dwelling_type ? current_environment?.dwelling_type?.description : 'Data not available';
									wall_type = result?.claim?.loan_information?.current_environment?.wall_type ?? 'Data not avaiable';
									existing_decoders = current_environment?.existing_decoder === undefined ? 'No' : 'Yes';
									no_of_tvs = current_environment?.no_of_tvs ? current_environment?.no_of_tvs?.description : 'Data not available';
									siteDateObj = {
										'Decoder Installation': `${device_type}`,
										'Kind of TVs': `${tv_type}`,
										'Home Type': `${dwelling_type}`,
										'Existing Decoder?': `${existing_decoders}`,
										'Number of TVs': `${no_of_tvs}`,
										'Wall Type': `${wall_type}`
									};
								} else if (claimtype === 39) {
									// teescees = '-';
									current_environment = result?.claim?.loan_information?.current_environment;
									device_type = result?.office_use?.device_details?.replacement_device?.name;
									tv_type = current_environment?.type_of_tvs?.description;
									no_of_tvs = current_environment?.no_of_tvs?.description;
									dwelling_type = current_environment?.dwelling_type?.description;
									wall_type = '-';
									teescees = '-';
									existing_decoders = '-';
									siteDateObj = {
										'Decoder Installation': `${device_type}`,
										'Kind of TVs': `${tv_type}`,
										'Home Type': `${dwelling_type}`,
										'Existing Decoder?': `${existing_decoders}`,
										'Number of TVs': `${no_of_tvs}`,
										'Wall Type': `${wall_type}`
									};
								}

								console.log('SITE', siteDateObj);

								return [siteDateObj];
							})
						);
					}
				}
			}
		}
	}
};
