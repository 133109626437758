import { Flow_0_0_2, getSelectedItemOne, getFullItemTwo, getFullItemOne, ModalService, ManifestController, getSelectedItem } from '@flexus/core';
import { skipWhile, map, switchMap, take, filter } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { findJobLog } from '../reusable/state-utils';
import { getAllInfoIndex } from '@flexus/utilities';
import { PGG_261 } from './PGG_261';
import { KVLHeading } from '@flexus/ui-elements';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

export const PGG_400x: Flow_0_0_2 = {
	id: '400x',
	name: 'workmanship_installation_query',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Review Refund Request';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	serverCalls: {
		...PGG_261.serverCalls,

		timeStamps: {
			errorMessage: 'Could not get time stamps',
			directCall: (http, store) => {
				return forkJoin([
					store.select(getSelectedItemOne).pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getFullItemTwo).pipe(
						skipWhile(x => !x),
						take(1)
					)
				]).pipe(
					filter(x => !!x),
					switchMap(([claim, job]: any) => {
						return http.post(`${environment.api_url}v1/claim_action/get_time_logs/`, { [`claim_id`]: claim?.id }).pipe(
							skipWhile(logs => !logs),
							switchMap((res: any) => {
								const setsOfJobLogs: any[] = res?.payload['jobs'];
								const autoPayJobLogForState27Or39 = findJobLog(setsOfJobLogs, job, [27, 39]);
								const applicationCreatorId = claim?.application_creator?.id;
								const paymentapproval1 = job?.job_information?.paymentapproval1;
								return http
									.post(environment.api_url + 'v1/staff_action/get_staff_member_names', {
										ids: [+applicationCreatorId, +paymentapproval1]
									})
									.pipe(
										map((data: any) => data?.payload),
										map((mapper: any) => ({ payArroval1: autoPayJobLogForState27Or39?.modifier || mapper[paymentapproval1], payHandler: mapper[applicationCreatorId] }))
									);
							})
						);
					})
				);
			}
		},
		customer_query: {
			errorMessage: 'no query',
			directCall: () => {
				return of({});
			}
		}
	},

	instructions: {
		editRoles: {
			0: 'Workmanship Installation Query'
		},
		viewRoles: {
			0: 'Workmanship Installation Query'
		}
	},

	startNode: 'WorkmanshipInstallationQuery',

	nodes: {
		WorkmanshipInstallationQuery: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Workmanship Installation Query'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'customer_details',
							colouredHeading: new KVLHeading('Customer Details', 'secondary')
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'customer_details',
							colouredHeading: new KVLHeading('Voucher Information', 'secondary')
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'installation_summary',
							colouredHeading: new KVLHeading('Installation Summary', 'secondary')
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'installer_details',
							colouredHeading: new KVLHeading('Installer Details', 'secondary')
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'timeStamps',
							colouredHeading: new KVLHeading(`Customer's Query`, 'secondary')
						}
					}
				]
			},
			navs: [
				{
					text: 'adjust customer details',
					nextNode: 'RecordReinstatementReason',
					color: 'secondary',
					location: 'center'
				},
				{
					text: 're-instate job',
					color: 'secondary',
					serverCalls: {
						reinstate: {
							errorMessage: 'Could not re-instate job.',
							directCall: (http, store, sq, _f, _cntrllr, _modal: ModalService) => {
								_modal.openModalDirectly(instance => {
									instance.type = 'warning';
									instance.heading = 'Confirmation of Re-instated installation';
									instance.message = 'Please confirm that you would like to re-instate this job.';
									instance.navButtons = [
										{
											text: 'back',
											linkType: 'close'
										},
										{
											text: 'confirm',
											color: 'secondary',
											linkType: 'close',
											clickHandler: ev => {
												console.log('Clicking the motherfuckker');
												return 0;
											}
										}
									];
								});
								return of({});
							}
						}
					}
				},
				{
					text: 'initiate refund',
					color: 'secondary',
					serverCalls: {
						initiate_refund: {
							errorMessage: 'Could not initiate refund',
							directCall: (_http, _store, _sq, _f, _cntrllr: ManifestController<any>) => {
								return _store.select(getFullItemTwo).pipe(
									skipWhile(x => !x),
									take(1),
									map((result: any) => {
										console.log('JOB RESULT', result);
										const new_state: any = '';
										const request = {
											job_id: result?.id,
											new_state: new_state
										};
										return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
									})
								);
							}
						}
					},
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		RecordReinstatementReason: {
			hideTabItem: true,
			showTabs: false,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: {
								weight: 'normal',
								size: 'medium'
							},
							title: 'Reason for Re-Instated Installation'
						}
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: {
								weight: 'bold',
								size: 'small',
								color: 'secondary'
							},
							title: `Customer's Query`,
							instructions: [`My unit has stopped working and I only had it installed yesterday`]
						}
					},

					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							// heading: 'Reason for Re-instated Installation',
							formControls: {
								0: {
									formControlName: 'reinstallation_reason',
									inputType: 'textarea',
									rows: 5,
									label:
										'Please provide the SP with information on what is to be done and why the installation should be relooked at (The Customer’s Query will not be shown the the SP)'
								}
							},
							formLayout: 'stacked',
							containerWidth: '40vw'
						}
					}
				]
			},
			navs: [
				{
					text: 'assign to new sp',
					color: 'secondary',
					// TODO: THIS WILL ACTUALLY HAVE TO ROUTE HANDLER TO SCREEN to APPOINT NEW SP
					serverCalls: {
						assign_new_sp: {
							errorMessage: 'Could not assign new SP',
							directCall: (_http: HttpClient, _store: Store) => {
								return _store
									.select(getSelectedItem)
									.pipe(
										skipWhile(x => !x),
										take(1),
										map(mapped => mapped)
									)
									.pipe(
										switchMap(result => {
											const job_id = result?.id;
											const sp_id = 9999;
											// const sp_id = result?.sp?.id
											return _http.post(`${environment.api_url}v1/job_action/appoint_sp/`, { job_id: job_id, sp_id: sp_id });
										})
									)
									.pipe(
										map((serverResponse: any) => {
											if (serverResponse) {
												const request = {};
												console.log('There is a server respon');
												// return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
											}
										})
									);

								return of({});
							}
						}
					}
				},
				{
					text: 'assign to existing sp',
					color: 'primary',
					serverCalls: {
						assign_new_sp: {
							errorMessage: 'Could not assign new SP',
							directCall: (_http: HttpClient, _store: Store) => {
								return _store
									.select(getSelectedItem)
									.pipe(
										skipWhile(x => !x),
										take(1),
										map(mapped => mapped)
									)
									.pipe(
										switchMap(result => {
											const job_id = result?.id;
											const sp_id = 9999;
											// const sp_id = result?.sp?.id
											return _http.post(`${environment.api_url}v1/job_action/appoint_sp/`, { job_id: job_id, sp_id: sp_id });
										})
									)
									.pipe(
										map((serverResponse: any) => {
											if (serverResponse) {
												const request = {};
												console.log('There is a server respon', serverResponse);
												// return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
											}
										})
									);

								return of({});
							}
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		current_state: 'current_state',
		new_state: 'new_state',
		reinstallation_reason: 'job_information.reinstallation_reason',
		decline_reason: [
			(dr, storeObj, bf) => {
				let pd: any[] = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo?.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline)) {
						pd = storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline;
					} else {
						pd = [{ ...(<object>storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline) }];
					}
				}

				const payment_decline = [...pd, { reason: dr, comment: bf.decline_notes }];
				return payment_decline;
			},
			'job_information.payment_decline'
		],

		decline_reason_string: [
			(dr, storeObj, bf) => {
				return !!bf.decline_reason ? getAllInfoIndex('cancel_reasons', 'id', 'name', bf.decline_reason, storeObj) : null;
			},
			'job_information.declinereason'
		]
	}
};
