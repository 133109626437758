import { Flow_0_0_2, getFullItemOne, MakeServerCall, getCurrentUser } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';

export const BET_CONTEXT_MENU_JOB_NOTES: Flow_0_0_2 = {
	id: 'JobNotes',
	itemType: 'flow',
	name: 'Notes',
	excludeForOnlyStates: [169],
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				map(itemOne => {
					if (itemOne) {
						return `View notes  :  ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'View notes';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'Notes',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		Notes: {
			component: 'NotesComponent',
			inputs: {
				title: 'Add Note',
				location: 'context-menu',
				formInputs: {
					0: {
						formControlName: 'message',
						inputType: 'textarea',
						label: 'Add Note',
						placeholder: 'Type a note'
					}
				},
				submitFunction: instance => {
					instance._store.dispatch(
						new MakeServerCall({
							dataKey: 'notes',
							serviceVariable: 'betService',
							functionName: 'addJobNote',
							errorMessage: 'Could not add note'
						})
					);
					instance?.bf?.bigForm?.patchValue({ message: '' });
					instance?.bf?.bigForm?.markAsPristine();
				}
			},
			initFormFields: (bf, item, instance) => {
				bf.bigForm.addControl('message', new UntypedFormControl('', Validators.required));
			}
		}
	},
	serverCalls: {
		username: {
			errorMessage: 'No user found.',
			directCall: (http, store) =>
				store.select(getCurrentUser).pipe(
					skipWhile(x => !x),
					take(1),
					map((user: any) => {
						return user?.user?.full_name ?? '';
					})
				)
		},
		notes: {
			serviceVariable: 'betService',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No job notes could be found!'
		}
	}
};
