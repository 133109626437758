import { Flow_0_0_2 } from '@flexus/core';

import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { photoNode } from '../reusable/PHOTO_NODE';
import { setActionPanelItems } from '../../../../app-shell-features';
import { getAdditionalCostsComments, getInterstateComments } from '../reusable/dataManipulations';
import { EMPTY } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import gql from 'graphql-tag';
import { filter, take } from 'rxjs/operators';
import { requestProofNode } from '../reusable/REQUEST_PROOF_PURCHASE';
import { viewProofNode } from '../reusable/VIEW_PROOF_PURCHASE';

export const MUL_248: Flow_0_0_2 = {
	id: '248',
	name: 'approve_additional_costs_reply',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Approve additional costs reply',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Approve additional costs reply'
		},
		viewRoles: {
			0: 'Approve additional costs reply'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		},
		dataFiles: {
			serviceVariable: 'mulService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'jobInformation',
	nodes: {
		jobInformation: {
			showTabs: true,
			...jobInfoNode(248, 250)
		},
		photo: {
			...photoNode(248, 250)
		},

		InvoicePreview: {
			initFormFields: (bf, item, instance, sq, store) => {
				bf.bigForm.patchValue({ new_state: 250 });
			},
			inputs: { inputHeading: 'Invoice Preview' },
			name: 'Invoice',
			showTabs: true,
			errorHandler: {
				displayFormat: 'none',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			component: {
				children: [
					{
						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: { dataFiles$: 'dataFiles', keyValueList$: 'keyValueList', inputHeading: 'Payment Preview' }
					}
				]
			},
			navs: [
				{
					text: 'request proof of purchase',
					nextNode: 'RequestProofOfPurchase',
					color: 'secondary'
				},
				{ text: 'Query Invoice', nextNode: 'QueryInvoice', color: 'alert' },
				{
					text: 'Approve',
					nextNode: 'submissionSuccess',
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		ViewProofOfPurchase: {
			...viewProofNode(248, 250)
		},
		RequestProofOfPurchase: {
			...requestProofNode(248)
		},
		QueryInvoice: {
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('query', new UntypedFormControl('', [Validators.required, Validators.minLength(3)]));
				bf.addControl('new_state', new UntypedFormControl(249));
				bf.patchValues({ new_state: 249 });
			},
			showTabs: false,
			hideTabItem: true,
			checkValidityForFields: ['query'],
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Reply to Installer Scheduler',
							headingMargin: '30px 0 75px 0',
							headingSize: 'medium',
							formControls: {
								0: {
									formControlName: 'query',
									inputType: 'textarea',
									label: "Comment why you're returning this to the Installer Scheduler",
									maxWidth: '50vw'
								}
							}
						}
					}
				]
			},

			inputs: {
				inputHeading: 'Reply to Installer Scheduler '
			},

			navs: [
				{
					text: 'Submit',
					nextNode: 'submissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'mulService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		submissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		query: [(query, storeObj) => getAdditionalCostsComments(query, storeObj), 'job_information.additionalcosts_queries'],
		request_proof_purchase: [
			(pop, stor, dev) => {
				let proof_of_purchase_queries = [];
				let tempqueries = stor?.['selectedContext']?.fullItemTwo?.job_information?.purchase_proof_requests;
				if (tempqueries) {
					if (Array.isArray(tempqueries)) {
						proof_of_purchase_queries = tempqueries;
					} else {
						proof_of_purchase_queries = [
							{
								...tempqueries
							}
						];
					}
				}
				const purchaseproofquery = [...proof_of_purchase_queries, { query: pop, queryreply: '' }];
				return purchaseproofquery;
			},
			'job_information.purchase_proof_requests'
		]
	}
};
