import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, map, pluck, skipWhile, filter, tap, catchError } from 'rxjs/operators';
import { combineLatest, Observable, from, forkJoin, empty, of, throwError } from 'rxjs';
import moment from 'moment';
import { environment } from 'apps/studio/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
	getSelectedItemOne,
	getSelectedItemTwo,
	getAllInfo,
	getFullItemOne,
	BigFormService,
	getSubmissionData,
	getFullItemTwo,
	getCurrentUser,
	getAppointmentTypes,
	IndexedDbService,
	getData,
	ModalService
} from '@flexus/core';
import { inlineRecursiveMerge, transformCallLog, findName } from '@flexus/utilities';
import { UUID } from 'angular2-uuid';

@Injectable()
export class BuildersStockManagerService {
	internalAssessorJobStates = [
		96, // internal_assessment
		97 // internal_assessor_job_report_uploaded
	];

	constructor(private store: Store<any>, private http: HttpClient, private bf: BigFormService, private indexedDbService: IndexedDbService, private modalsService: ModalService) {}
}
