import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { BET_97 } from './BET_97';
import { UntypedFormControl } from '@angular/forms';
import { skipWhile, take, map } from 'rxjs/operators';

export const BET_89: Flow_0_0_2 = {
	id: '89',
	name: 'internal_assessor_job',
	itemType: 'flow',
	fetchLevel1And2: true,
	header: {
		title: (store, bf) => {
			bf.bigForm.addControl('workflow_current_state', new UntypedFormControl(89));
			return store.select(getFullItemOne).pipe(
				skipWhile(res => !res),
				take(1),
				map(itemOne => {
					let title = 'Review Claim';
					if (itemOne?.loan_information?.mavenclaimnumber) {
						title += ` : ${itemOne?.loan_information?.mavenclaimnumber}`;
					}
					if (itemOne.loan_information.surname) {
						title += ` - ${itemOne.applicant?.surname}`;
					}

					return title;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		...BET_97.footer
	},
	instructions: {
		...BET_97.instructions
	},
	actionPanel: BET_97.actionPanel,
	serverCalls: {
		...BET_97.serverCalls
	},
	startNode: 'JobSummary',
	nodes: {
		...BET_97.nodes
	},
	bigFormToStoreMapper: {
		...BET_97.bigFormToStoreMapper
	}
};
