<div class="upload-documents-container">
	<flx-button-list-with-instructions class="flex-grow" [title]="title" [instructions]="instructions" [buttons]="buttons"></flx-button-list-with-instructions>
	<flx-file-upload-preview
		*ngIf="showUpload"
		class="flex-grow"
		[multipleUploads]="false"
		[allowedFileTypes]="allowedFileTypes"
		[formControls]="formControls"
	></flx-file-upload-preview>
</div>
