import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemTwo, getCurrentUser, NavButton_0_0_2, getFullItemOne } from '@flexus/core';
import { getExtension } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import {forkJoin, } from 'rxjs';
import { skipWhile, take, map, switchMap } from 'rxjs/operators';

const SP_GLOBAL_98_NAVS: NavButton_0_0_2[] = [
	// {
	// 	text: 'Visit Site',
	// 	color: 'primary',
	// 	nextNode: 'SetAppointment'
	// },
	{
		text: 'Close of Job',
		color: 'alert',
		nextNode: 'SubmissionSuccess',
		serverCalls: {
			response: {
				errorMessage: `Couldn't update this job?.`,
				directCall: (http: any, store: any, sq: any, bf: any) => {
					return forkJoin([
						store.select(getFullItemTwo)?.pipe(
							skipWhile(x => !x),
							take(1),
							map(res => res as any)
						),
						store.select(getCurrentUser)?.pipe(
							skipWhile(x => !x),
							take(1),
							map(res => res as any)
						)
					]).pipe(
						switchMap(([job, user]: any) => {
							const data = {
								job_id: job.id,
								staffmember: user.id,
								new_state: 103,
								job_information: job?.job_information
							};
							return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
						})
					);
				}
			}
		}
	}
];

export const SP_GLOBAL_98: Flow_0_0_2 = {
	id: '98',
	name: 'la_managing_job',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Cash In Lieu Query Reply'
		},
		viewRoles: {
			0: 'Cash In Lieu - Query'
		}
	},
	serverCalls: {
		notes: {
			serviceVariable: 'spService',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No claim notes could be found!'
		},
		appointmentTypes: {
			serviceVariable: 'spService',
			functionName: 'getAppointmentTypes',
			errorMessage: 'No Appointment Types could be found!'
		}
	},

	startNode: 'JobDocuments',
	nodes: {
		JobDocuments: {
			component: 'FLXFileBrowserComponent',
			showTabs: true,
			name: 'Documents',
			inputs: {
				heading: 'Attachments related to job',
				showFilters: false,
				paginationId: 'reports'
			},
			serverCalls: {
				files: {
					serviceVariable: 'spService',
					functionName: 'getAllJobFiles',
					responseSlice: 'payload',
					errorMessage: 'Could not get files from server!'
				}
			},
			navs: [{ text: 'Upload Report', color: 'primary', nextNode: 'UploadReport' }, ...SP_GLOBAL_98_NAVS]
		},

		JobNotes: {
			name: 'Notes',
			component: 'NotesComponent',
			inputs: {},
			initFormFields: (bf, item, instance) => {
				bf.bigForm.addControl('message', new UntypedFormControl('', Validators.required));
			},
			showTabs: true,
			serverCalls: {
				notes: {
					serviceVariable: 'spService',
					functionName: 'getNotes',
					responseSlice: 'payload',
					errorMessage: 'No job notes could be found!'
				}
			},
			navs: SP_GLOBAL_98_NAVS
		},

		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			initFormFields: bf => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentDateType: new UntypedFormControl(null),
						skill: new UntypedFormControl('Onsite Visit'),
						skillcatagory: new UntypedFormControl('Assessor')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												surname
											}
											loan_information {
												contactnumber
												cellnumber
											}
										}
									}
								`,
								store.select(getFullItemOne)?.pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											loan_information {
												onsiteperson
												onsitecontact
											}
										}
									}
								`,
								store.select(getFullItemOne)?.pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				}
			},
			component: 'FLXJobAppointmentComponent',

			checkValidityForFields: ['appointmentData'],
			navs: [
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					optIntoValidation: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'The new job could not be created!',
							serviceVariable: 'spService',
							functionName: 'assessorVisitSite'
						}
					}
				}
			]
		},
		UploadReport: {
			showTabs: false,
			hideTabItem: true,
			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ new_state: 271 });
				bf.bigForm.addControl('purpose', new UntypedFormControl('loss_adjuster_team_lead_report'));
				bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
			},
			checkValidityForFields: ['purpose', 'fileQueue'],
			component: {
				children: [
					{
						component: 'FLXFileUploadWithPreviewComponent',
						inputs: {
							purposes: [{ display: 'Loss Adjuster Team Lead Report', value: 'loss_adjuster_team_lead_report' }],
							heading: 'Upload loss adjuster team lead report',
							multipleUploads: false,
							allowedFileTypes: ['pdf'],
							containerWidth: '30vw',
							maxWidth: '30vw'
						}
					}
				]
			},
			navs: [
				{
					text: 'Submit',
					optIntoValidation: true,
					nextNode: 'FileSubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not upload loss adjuster team lead report',
							directCall: (http, store, sq, bf) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: any = {
									data: fileQueue[0] ? fileQueue[0]?.file.base64 : '',
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								if (bf.bigForm.get('purpose')?.value === 'report') {
									body.thumbnail = fileQueue[0]?.file.base64;
								}
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
							}
						}
					}
				}
			]
		},
		FileSubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'File Successfully uploaded' },
			navs: [
				{
					text: 'Continue',
					serverCalls: {
						response: {
							serviceVariable: 'spService',
							functionName: 'updateJob',
							errorMessage: 'Could not update job!'
						}
					},
					nextNode: 'JobDocuments'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	}
};
