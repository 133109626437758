import * as fromActions from './sp-job-pings.actions';
import { removeOnKeyMatchFromArray } from '@flexus/utilities';

export interface JobPingsContextState {
	AvailableJobPings: any[];
	filteredJobPings: any[];
	SelectedJobPing: any;
	ForcePingModalShow: boolean;
	ForceJobNotificationModalShow: boolean;
	AvailabilityChecks: {
		serverJobCount: number;
		jobsMeetingCriteria: number;
		timestampCheck: string;
	};
	JobResponses: any[];
	JobsAwarded: any[];
	JobsLost: any[];
}

const initialState: JobPingsContextState = {
	AvailableJobPings: null,
	filteredJobPings: null,
	SelectedJobPing: null,
	ForcePingModalShow: false,
	ForceJobNotificationModalShow: false,
	AvailabilityChecks: {
		serverJobCount: 0,
		jobsMeetingCriteria: 0,
		timestampCheck: null
	},
	JobResponses: null as any,
	JobsAwarded: null as any,
	JobsLost: null as any
};

export function jobPingsReducer(state: JobPingsContextState = initialState, action: fromActions.JobPingsActions) {
	switch (action.type) {
		case fromActions.CHECK_AVAILABILITY_STORE_RESULT: {
			const data = action.payload;
			return {
				...state,
				AvailabilityChecks: data
			};
		}
		case fromActions.GET_AVAILABLE_JOBS_SUCCESS: {
			const data = action.payload;

			return {
				...state,
				ForcePingModalShow: true,
				AvailableJobPings: data?.availableJobPings,
				filteredJobPings: data?.filteredJobs
			};
		}
		case fromActions.GET_JOB_RESPONSES_SUCCESS: {
			return {
				...state,
				JobResponses: action.payload
			};
		}
		case fromActions.CHECK_FOR_JOBS_AWARDED_SUCCESS: {
			return {
				...state,
				ForceJobNotificationModalShow: action.payload.length > 0,
				JobsAwarded: action.payload
			};
		}
		case fromActions.CHECK_FOR_JOBS_LOST_SUCCESS: {
			return {
				...state,
				ForceJobNotificationModalShow: action.payload.length > 0,
				JobsLost: action.payload
			};
		}
		case fromActions.GET_FILTERED_JOBS_SUCCESS: {
			const data = action.payload;

			return {
				...state,
				AvailableJobPings: data?.availableJobPings,
				filteredJobPings: data?.filteredJobs
			};
		}
		case fromActions.SELECT_AVAILABLE_JOB: {
			const selectedJobPing = state.AvailableJobPings.find(res => (res as any).id === action.payload);

			return {
				...state,
				SelectedJobPing: selectedJobPing
			};
		}
		case fromActions.SHOW_PING_MODAL: {
			return {
				...state,
				ForcePingModalShow: true
			};
		}
		case fromActions.PING_MODAL_SHOWN: {
			return {
				...state,
				ForcePingModalShow: false
			};
		}
		case fromActions.REMOVE_ALL_JOB_RESPONSES_SUCCESS:
		case fromActions.REMOVE_JOB_RESPONSE_SUCCESS: {
			const newJobResponses = removeOnKeyMatchFromArray(action.payload, JSON.parse(JSON.stringify(state.JobResponses)), 'id');
			const newJobsAwarded = removeOnKeyMatchFromArray(action.payload, JSON.parse(JSON.stringify(state.JobsAwarded)), 'id');
			const newJobsLost = removeOnKeyMatchFromArray(action.payload, JSON.parse(JSON.stringify(state.JobsLost)), 'id');

			return {
				...state,
				JobResponses: newJobResponses,
				JobsAwarded: newJobsAwarded,
				JobsLost: newJobsLost
			};
		}
		case fromActions.SHOW_JOB_NOTIFICATION_MODAL: {
			return {
				...state,
				ForceJobNotificationModalShow: true
			};
		}
		case fromActions.JOB_NOTIFICATION_MODAL_SHOWN: {
			return {
				...state,
				ForceJobNotificationModalShow: false
			};
		}
		default:
			return state;
	}
}
