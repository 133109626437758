import { NodePoint_0_0_2 } from '@flexus/core';

export const PHOTO_NODE: NodePoint_0_0_2 = {
	name: 'Photos',

	component: 'FLXViewPhotosComponent',
	inputs: {
		heading: 'Job Photos',
		location: 'context-menu'
	},
	showTabs: true,
	serverCalls: {
		photos: {
			serviceVariable: 'spService',
			functionName: 'getAllJobFiles',
			errorMessage: 'Could not get files from server!',
			responseSlice: 'payload'
		}
	}
};
