<flx-heading size="small" align="center">{{ title }}</flx-heading>
<div [ngClass]="locationClass">
	<flx-glow-line></flx-glow-line>
	<ng-container *ngIf="notes; else noNotes">
		<div *ngIf="notes.length > 0; else noNotes" class="flx-note-list" [style.maxHeight]="maxHeight">
			<flx-general-note
				*ngFor="let note of notes"
				[note]="note"
				[ngClass]="currentUser === note.safe_name ? 'indentLeft' : 'indentRight'"
				[location]="location"
				[currentUser]="currentUser"
			></flx-general-note>
		</div>
	</ng-container>
	<ng-template #noNotes> <flx-alert type="no-info" missingItem="notes"></flx-alert> </ng-template>
	<flx-glow-line></flx-glow-line>
</div>
