import { BigFormService, Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { setActionPanelItems } from '../../../../app-shell-features';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';

export const MUL_285: Flow_0_0_2 = {
	id: '285',
	name: 'autopay-submit-failed',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Autopay failed',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Check payment status'
		},
		viewRoles: {
			0: 'Validation agent working on issue'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'autpaySubmitFailed',
	nodes: {
		autpaySubmitFailed: {
			...jobInfoNode(285)
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
