import { Overlay, OverlayConfig, OverlayRef, ScrollStrategy, OverlayContainer } from '@angular/cdk/overlay';

import { ComponentPortal, ComponentType, PortalInjector, TemplatePortal } from '@angular/cdk/portal';

import { Location } from '@angular/common';

import { ComponentRef, Inject, Injectable, InjectionToken, Injector, Optional, SkipSelf, TemplateRef } from '@angular/core';

import { Observable, defer, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { DialogConfig } from './helpers/dialog-config';
import { FLXDialogContainerComponent } from './container/dialog-container';
import { DialogRef } from './helpers/dialog-ref';

/** Injection token that can be used to access the data that was passed in to a dialog. */
export const DIALOG_DATA = new InjectionToken<any>('DialogData');

/** Injection token that can be used to specify default dialog options. */
export const DIALOG_DEFAULT_OPTIONS = new InjectionToken<DialogConfig>('dialog-default-options');

/** Injection token that determines the scroll handling while the dialog is open. */
export const DIALOG_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('dialog-scroll-strategy');

/** @docs-private */
export function DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay: Overlay): () => ScrollStrategy {
	return () => overlay.scrollStrategies.block();
}

/** @docs-private */
export const DIALOG_SCROLL_STRATEGY_PROVIDER = {
	provide: DIALOG_SCROLL_STRATEGY,
	deps: [Overlay],
	useFactory: DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY
};

/**
 * Service to opening modal dialogs.
 */
@Injectable()
export class Dialog {
	private _openDialogsAtThisLevel: DialogRef<any>[] = [];
	private _ariaHiddenElements = new Map<Element, string | null>();
	private readonly _afterAllClosedAtThisLevel = new Subject<void>();
	private readonly _afterOpenAtThisLevel = new Subject<DialogRef<any>>();

	/** Keeps track of the currently-open dialogs. */
	get openDialogs(): DialogRef<any>[] {
		return this._parentDialog ? this._parentDialog.openDialogs : this._openDialogsAtThisLevel;
	}

	/** Stream that emits when a dialog has been opened. */
	get afterOpen(): Subject<DialogRef<any>> {
		return this._parentDialog ? this._parentDialog.afterOpen : this._afterOpenAtThisLevel;
	}

	get _afterAllClosed() {
		const parent = this._parentDialog;
		return parent ? parent._afterAllClosed : this._afterAllClosedAtThisLevel;
	}
	get position() {
		return this._overlay.position();
	}

	readonly afterAllClosed: Observable<any> = defer<any>(() => (this.openDialogs.length ? this._afterAllClosed : this._afterAllClosed.pipe(startWith(undefined))));

	constructor(
		private _overlay: Overlay,
		private _injector: Injector,
		@Optional() private _location: Location,
		@Optional()
		@Inject(DIALOG_DEFAULT_OPTIONS)
		private _defaultOptions,
		@Inject(DIALOG_SCROLL_STRATEGY) private _scrollStrategy,
		@Optional()
		@SkipSelf()
		private _parentDialog: Dialog,
		private _overlayContainer: OverlayContainer
	) {}

	open<T, D = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: DialogConfig<D>): DialogRef<T> {
		config = _applyConfigDefaults(config, this._defaultOptions || new DialogConfig());

		if (config.id && this.getDialogById(config.id)) {
			throw Error(`Dialog with id "${config.id}" exists already. The dialog id must be unique.`);
		}

		const overlayRef = this._createOverlay(config);
		const dialogContainer = this._attachDialogContainer(overlayRef, config);
		const dialogRef = this._attachDialogContent<T>(componentOrTemplateRef, dialogContainer, overlayRef, config);

		// If this is the first dialog that we're opening, hide all the non-overlay content.
		if (!this.openDialogs.length) {
			this._hideNonDialogContentFromAssistiveTechnology();
		}

		this.openDialogs.push(dialogRef);
		dialogRef.afterClosed().subscribe(() => this._removeOpenDialog(dialogRef));
		this.afterOpen.next(dialogRef);

		return dialogRef;
	}

	closeAll(): void {
		let i = this.openDialogs.length;

		while (i--) {
			// The `_openDialogs` property isn't updated after close until the rxjs subscription
			// runs on the next microtask, in addition to modifying the array as we're going
			// through it. We loop through all of them and call close without assuming that
			// they'll be removed from the list instantaneously.
			this.openDialogs[i]?.close();
		}
	}

	getDialogById(id: string): DialogRef<any> | undefined {
		return this.openDialogs.find(dialog => dialog.id === id);
	}

	private _createOverlay(config: DialogConfig): OverlayRef {
		const overlayConfig = this._getOverlayConfig(config);
		return this._overlay.create(overlayConfig);
	}

	private _getOverlayConfig(dialogConfig: DialogConfig): OverlayConfig {
		const state = new OverlayConfig({
			positionStrategy: this._overlay.position().global(),
			scrollStrategy: dialogConfig.scrollStrategy || this._scrollStrategy(),
			panelClass: dialogConfig.panelClass,
			hasBackdrop: dialogConfig.hasBackdrop,
			minWidth: dialogConfig.minWidth,
			minHeight: dialogConfig.minHeight,
			maxWidth: dialogConfig.maxWidth,
			maxHeight: dialogConfig.maxHeight
		});

		if (dialogConfig.backdropClass) {
			state.backdropClass = dialogConfig.backdropClass;
		}

		return state;
	}

	private _attachDialogContainer(overlay: OverlayRef, config: DialogConfig): FLXDialogContainerComponent {
		const containerPortal = new ComponentPortal(FLXDialogContainerComponent, config.viewContainerRef);
		const containerRef: ComponentRef<FLXDialogContainerComponent> = overlay.attach(containerPortal);
		containerRef.instance._config = config;

		return containerRef.instance;
	}

	private _attachDialogContent<T>(
		componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
		dialogContainer: FLXDialogContainerComponent,
		overlayRef: OverlayRef,
		config: DialogConfig
	): DialogRef<T> {
		const dialogRef = new DialogRef<T>(overlayRef, dialogContainer, this._location, config.id);

		// When the dialog backdrop is clicked, we want to close it.
		if (config.hasBackdrop) {
			overlayRef.backdropClick().subscribe(() => {
				if (!dialogRef.disableClose) {
					dialogRef.close();
				}
			});
		}

		if (componentOrTemplateRef instanceof TemplateRef) {
			dialogContainer.attachTemplatePortal(
				new TemplatePortal<T>(componentOrTemplateRef, null as any, <any>{
					$implicit: config?.data,
					dialogRef
				})
			);
		} else {
			const injector = this._createInjector<T>(config, dialogRef, dialogContainer);
			const contentRef = dialogContainer.attachComponentPortal<T>(new ComponentPortal(componentOrTemplateRef, undefined, injector));
			dialogRef.componentInstance = contentRef.instance;
		}

		dialogRef.updateSize(config.width, config.height).updatePosition(config.position);

		return dialogRef;
	}

	private _createInjector<T>(config: DialogConfig, dialogRef: DialogRef<T>, dialogContainer: FLXDialogContainerComponent): PortalInjector {
		const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
		const injectionTokens = new WeakMap();

		injectionTokens.set(DialogRef, dialogRef);
		injectionTokens.set(FLXDialogContainerComponent, dialogContainer);
		injectionTokens.set(DIALOG_DATA, config?.data);

		return new PortalInjector(userInjector || this._injector, injectionTokens);
	}

	private _removeOpenDialog(dialogRef: DialogRef<any>) {
		const index = this.openDialogs.indexOf(dialogRef);

		if (index > -1) {
			this.openDialogs.splice(index, 1);

			// If all the dialogs were closed, remove/restore the `aria-hidden`
			// to a the siblings and emit to the `afterAllClosed` stream.
			if (!this.openDialogs.length) {
				this._ariaHiddenElements.forEach((previousValue, element) => {
					if (previousValue) {
						element.setAttribute('aria-hidden', previousValue);
					} else {
						element.removeAttribute('aria-hidden');
					}
				});

				this._ariaHiddenElements.clear();
				this._afterAllClosed.next();
			}
		}
	}

	private _hideNonDialogContentFromAssistiveTechnology() {
		const overlayContainer = this._overlayContainer.getContainerElement();

		// Ensure that the overlay container is attached to the DOM.
		if (overlayContainer.parentElement) {
			const siblings = overlayContainer.parentElement.children;

			for (let i = siblings.length - 1; i > -1; i--) {
				const sibling = siblings[i];

				if (sibling !== overlayContainer && sibling.nodeName !== 'SCRIPT' && sibling.nodeName !== 'STYLE' && !sibling.hasAttribute('aria-live')) {
					this._ariaHiddenElements.set(sibling, sibling.getAttribute('aria-hidden'));
					sibling.setAttribute('aria-hidden', 'true');
				}
			}
		}
	}
}

function _applyConfigDefaults(config?: DialogConfig, defaultOptions?: DialogConfig): DialogConfig {
	return { ...defaultOptions, ...config };
}
