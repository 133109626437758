<div class="reminder-container">
	<div class="reminder-time" (click)="takeAction('open', reminderInstance)">
		<div class="rem-icon"><flx-icon [type]="reminderInstance.type" hover="none"></flx-icon></div>
		<div class="rem-time-date">{{ formatDate(reminderInstance?.date) }}</div>
		<div class="rem-time-display">{{ reminderInstance?.time }}</div>
	</div>
	<div class="reminder-details">
		<div class="reminder-heading" (click)="takeAction('open', reminderInstance)">{{ reminderInstance?.title }}</div>
		<div class="reminder-message" (click)="takeAction('open', reminderInstance)">{{ reminderInstance?.message }}</div>
		<div *ngIf="reminderInstance?.claimNo" class="reminder-claim-no" (click)="takeAction('openToClaim', reminderInstance)">#{{ reminderInstance?.claimNo }}</div>
	</div>
	<div class="reminder-actions">
		<!-- <div class="edit-reminder">
      <flx-icon type="settings" size="small" instruction="edit" (click)="takeAction('edit')"></flx-icon>
    </div> -->
		<div class="delete-reminder"><flx-icon [type]="'scratch-pad-trash'" size="small" instruction="delete" (click)="takeAction('delete', reminderInstance)"></flx-icon></div>
	</div>
</div>
