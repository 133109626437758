import { Route } from '@angular/router';
import { AuthGuard } from '@flexus/core';
import { ActivateStateGuard } from 'apps/studio/src/app/sp_globals/guards/activate-state.guard';
import { DeactivateStateGuard } from 'apps/studio/src/app/sp_globals/guards/deactivate-state.guard';
import { FLXWorkflowAppComponent } from 'apps/studio/src/app/application-modules';
import { SpWorkflowShellComponent } from 'apps/studio/src/app/sp_globals/base-components/sp-workflow-shell/sp-workflow-shell.component';
import { SpFlowComponent } from 'apps/studio/src/app/sp_globals/base-components/sp-flow/sp-flow.component';

export const routes: { [id: string]: Route[] } = {
	core: [
		{
			path: '',
			redirectTo: '/workflow',
			pathMatch: 'full'
		}
	],
	flowChartApp: [],
	workflowApp: [
		{
			path: 'workflow',
			component: FLXWorkflowAppComponent,
			canActivate: [AuthGuard],
			children: [
				{ path: '', component: SpWorkflowShellComponent }, // As outlet list,
				{
					path: 'detail',
					component: SpFlowComponent,
					canActivate: [ActivateStateGuard],
					canDeactivate: [DeactivateStateGuard],
					runGuardsAndResolvers: 'always'
				} // As Workflow detail,
			]
		}
	]
};
