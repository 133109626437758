import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getSelectedItem } from '@flexus/core';
import { KVLHeading } from '@flexus/ui-elements';
import { CollapseActionPanel, setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin, of } from 'rxjs';
import { map, mergeMap, skipWhile, switchMap, take } from 'rxjs/operators';

export const PGG_272: Flow_0_0_2 = {
	id: '272',
	name: 'ratings_callback',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	instructions: {
		editRoles: { 0: '' },
		viewRoles: { 0: '' }
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((itemOne: any) => {
					// console.log('hittinng header');
					let headingstring = 'Ratings Callback';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'RatingsFeedback',
	nodes: {
		RatingsFeedback: {
			initFormFields: (bf: any) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(null));
			},
			name: 'Ratings Feedback',
			showTabs: true,
			hideTabItem: false,
			serverCalls: {
				important_information: {
					errorMessage: 'no important information',
					directCall: () => {
						return of([{ 'Callback Request': 'Yes' }]);
					}
				},
				ratings_feeback: {
					errorMessage: '',
					directCall: () => {
						return of([
							{
								'How would you rate PingGo experience?': 'Definitely',
								'Additional Comments': 'not sure about this thing from the start',
								'Would you use PingGo again? ': 'Unlikely',
								'Additional comments': 'The app was shite',
								'Overall comments': 'Can you come remove the barf bag',
								'Call back request': 'Yes'
							}
						]);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'PingGo Ratings',
							instructions: []
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							data$: 'important_information',
							color: 'secondary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Ratings Feedback',
							data$: 'ratings_feeback',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Request Refund',
					color: 'secondary',
					serverFirst: true,
					linkType: 'submit',
					serverCalls: {
						response: {
							errorMessage: 'Refund request failed',
							directCall: (http: any, store: any, sq: any, bf: any) => {
								bf.patchValues({ new_state: 258 });
								return forkJoin([
									store.select(getSelectedItem).pipe(
										skipWhile((x: any) => !x),
										take(1),
										map((job: any) => job)
									),
									of(bf.getControl('new_state')?.value).pipe(
										skipWhile((x: any) => !x),
										take(1),
										map((state: any) => state)
									)
								]).pipe(
									switchMap(([job, new_state]: any) => {
										const data = {
											job_id: job?.id,
											new_state: new_state
										};
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					},
					nextNode: 'SubmissionSuccess'
				},
				{
					text: 'Request rework',
					color: 'secondary',
					serverFirst: true,
					linkType: 'portal',
					nextNode: 'RequestRework'
				},
				{
					text: 'dealt with callback',
					color: 'primary',
					linkType: 'submit',
					serverCalls: {
						response: {
							errorMessage: 'Job was not completed',
							directCall: (http: any, store: any, sq: any, bf: any) => {
								bf.patchValues({ new_state: 28 });
								return forkJoin([
									store.select(getSelectedItem).pipe(
										skipWhile((x: any) => !x),
										take(1),
										map((job: any) => job)
									),
									of(bf.getControl('new_state')?.value).pipe(
										skipWhile((x: any) => !x),
										take(1),
										map((state: any) => state)
									)
								]).pipe(
									switchMap(([job, new_state]: any) => {
										const data = {
											job_id: job?.id,
											new_state: new_state
										};
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					},
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		JobInfoNode: {
			name: 'Job Information',
			showTabs: true,
			hideTabItem: false,
			serverCalls: {
				customer_details: {
					errorMessage: 'could not get the pinggo job',
					directCall: (http: any, store: any) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile((x: any) => !x),
							take(1),
							map((res: any) => {
								console.log('RES', res);
								const { job_information, claim } = res;
								const name: string = `${claim?.applicant?.first_name} ${claim?.applicant?.surname}`;
								const address = `24a 8th Avenue, Melville`;
								const number = '0828936920';

								const list: any[] = [];

								list.push({
									'Customer name': name,
									'Contact Number ': `${number}`,
									'Address ': `${address}`
								});
								return list;
							})
						);
					}
				},
				installation_summary: {
					errorMessage: 'could not get the pinggo installation summary',
					directCall: (http: any, store: any) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile((x: any) => !x),
							take(1),
							map((res: any) => {
								const { job_information, claim } = res;
								const name: string = `${claim?.applicant?.name} ${claim?.applicant?.surname}`;
								const vouchernumber = `3XC9-FD09`;
								const product = 'Air Conditioner';
								const estimatedtime = '1 hour 30 min';

								const list: any[] = [];

								list.push({
									'Voucher number': vouchernumber,
									'Product ': `${product}`,
									'Estimated time to complete installation ': `${estimatedtime}`,
									'Product comments': 'some random comments here'
								});
								return list;
							})
						);
					}
				},
				installer_details: {
					errorMessage: 'could not get the pinggo installer details',
					directCall: (http: any, store: any) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile((x: any) => !x),
							take(1),
							map((res: any) => {
								const { job_information, claim } = res;
								const name: string = 'Piet Pompies';
								const address = `24a 8th Avenue, Melville`;
								const number = '065936998';

								const list: any[] = [];

								list.push({
									'Installer name': name,
									'Installer Contact Number ': `${number}`
								});
								return list;
							})
						);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Information',
							instructions: null
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer details',
							data$: 'customer_details',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Summary',
							data$: 'installation_summary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installer Details',
							data$: 'customer_details',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Request Refund',
					color: 'secondary',
					serverFirst: true,
					linkType: 'submit',
					serverCalls: {
						response: {
							errorMessage: 'Refund request failed',
							directCall: (http: any, store: any, sq: any, bf: any) => {
								bf.patchValues({ new_state: 258 });
								return forkJoin([
									store.select(getSelectedItem).pipe(
										skipWhile((x: any) => !x),
										take(1),
										map((job: any) => job)
									),
									of(bf.getControl('new_state')?.value).pipe(
										skipWhile((x: any) => !x),
										take(1),
										map((state: any) => state)
									)
								]).pipe(
									switchMap(([job, new_state]: any) => {
										const data = {
											job_id: job?.id,
											new_state: new_state
										};
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					},
					nextNode: 'SubmissionSuccess'
				},
				{
					text: 'Request rework',
					color: 'secondary',
					serverFirst: true,
					linkType: 'portal',
					nextNode: 'RequestRework'
				},
				{
					text: 'dealt with callback',
					color: 'primary',
					linkType: 'portal',
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		RequestRework: {
			checkValidityForFields: ['request_rework_reason'],
			initFormFields: (bf: any) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(null, Validators.required));
				bf.bigForm.addControl('request_rework_reason', new UntypedFormControl('', Validators.required));
				bf.patchValues({ new_state: 42 });
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Request for Rework',
							formControls: {
								0: {
									label: 'Please enter the reason for request for rework',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'request_rework_reason'
								}
							},
							formLayout: 'stacked',
							containerWidth: '30vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit',
					color: 'primary',
					optIntoValidation: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Rework request could not be submitted',
							serviceVariable: 'pggService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		request_rework_reason: [
			(reworkreason: any) => {
				return reworkreason;
			},
			'job_information.request_rework_reason'
		]
	}
};
