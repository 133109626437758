export class BusinessRules {
	public static IACanComplete(claimState: Number, jobs: any[]): boolean {
		if (claimState === 95 || claimState === 109) {
			let iaState = 0; // IA must be in 97
			let excludeStates = 0; //no other job may be in 91,92,96

			const IAJob = jobs.find(job => job?.skill === 44 && job?.state !== 45);
			if (IAJob && IAJob.state === 97) {
				iaState = 1;
			}

			jobs
				.filter(job => job?.skill !== 44)
				.forEach(job => {
					const exists = [91, 92, 96]?.indexOf(job.state);

					if (exists !== -1) {
						excludeStates = 1;
					}
				});

			return iaState === 1 && excludeStates === 0;
		}

		return false;
	}
}
