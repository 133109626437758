import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { getData } from '@flexus/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Component({
	selector: 'flexus-template',
	templateUrl: './template.component.html',
	styleUrls: ['./template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateComponent implements OnInit {
	// testComps = { TestComponent };

	@Input() templateDefinition;
	@Input() mapper;
	@Input() nodeInputs;
	organisms = [
		// {
		//   component: TestComponent,
		//   inputs: {},
		// },
	];
	constructor(private store: Store<any>) {}

	ngOnInit() {
		this.organisms = this.templateDefinition?.children.map(def => {
			const localInputs = Object.entries(def.inputs).reduce((acc, [key, value]: [string, any]) => {
				if (!key.endsWith('$')) {
					return {
						...acc,
						[key]: value
					};
				}
				return {
					...acc,
					[key]: this.store.select(getData).pipe(map(data => data[value]))
				};
			}, {});
			return {
				component: this.mapper[def.component],
				inputs: { ...this.nodeInputs, ...localInputs }
			};
		});
	}
}
