import { Flow_0_0_2 } from '@flexus/core';

export const BET_23: Flow_0_0_2 = {
	id: '23',
	name: 'TL_accepted',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Wait for Team Leader to travel to job'
		}
	},
	startNode: '',
	nodes: {}
};
