import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BigFormService,  } from '@flexus/core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'flx-invoice-number-date-edit',
	templateUrl: './invoice-number-date-edit.component.html',
	styleUrls: ['./invoice-number-date-edit.component.scss']
})
export class InvoiceNumberDateEditComponent implements OnInit {
	@Input() docType: 'invoice' | 'quote';
	@Input() numberLabel: string;
	@Input() currentState: number;
	@ViewChild('myDatePicker', { static: true }) myDatePicker;
	// isInvoiceOnlyState;
	// callOutStates = [25, 65, 66, 67, 69, 80, 94, 105, 171, 206, 207];
	// invoicingStates = [26, 46, 47];
	// client: string;

	constructor(public store: Store<any>, public bf: BigFormService) {}

	ngOnInit() {
		// forkJoin([
		// 	this.store.select(getFullItemTwo).pipe(
		// 		filter(x => !!x),
		// 		take(1)
		// 	),
		// 	this.store.select(getCurrentUser).pipe(
		// 		skipWhile((usr: any) => !usr),
		// 		take(1)
		// 	)
		// ]).subscribe(([i2, result]) => {
		// 	// this.isInvoiceOnlyState = this.callOutStates.indexOf(i2.state) !== -1 || this.invoicingStates.indexOf(i2.state) !== -1;
		// 	// this.bf.bigForm.get('currentState')?.setValue(i2.state);
		// 	// this.client = result?.client;
		// 	// console.log('client number date', this.client);
		// });
	}

	setCustom() {
		this.myDatePicker.open();
	}
}
