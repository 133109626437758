<div class="actions-container">
  <!-- <div class="outlet-action">

      <flx-icon type="refresh" (click)="refresh()"></flx-icon>
    </div>
    <div class="outlet-action">
      <flx-icon type="default-view" [isActive]="currentView === 'Default' ? true : false" (click)="toggleDefaultView()"></flx-icon>
    </div>
    <div class="outlet-action">
      <flx-icon type="details-view" [isActive]="currentView === 'List' ? true : false" (click)="toggleListView()"></flx-icon>
    </div> -->

  <ng-container *ngIf="isOffline">
    <div class="workflow-action">
      <flx-icon
        [type]="isOffline ? 'newOffline' : 'newOnline'"
        use="app-bar"
        active="danger"
        [isActive]="isOffline"
        hover="none"
        [isClickable]="false"
        [instruction]="isOffline ? 'Offline' : 'Online'"
        class="action-panel-button"
        size="medium"
      >
      </flx-icon>
    </div>
    <div class="notification">
      <span style="font-size: 20px; width: 280px; font-style: normal"
        >Network Connection Lost.</span
      >
      <span style="font-size: 12px; width: 280px"
        >Please check your network</span
      >
    </div>
  </ng-container>

  <ng-container *ngIf="!isOffline">
    <ng-container *ngIf="showMessage">
      <div class="workflow-action">
        <flx-icon
          [type]="isOffline ? 'newOffline' : 'newOnline'"
          use="app-bar"
          active="success"
          [isActive]="!isOffline"
          hover="none"
          [isClickable]="false"
          [instruction]="!isOffline ? 'Offline' : 'Online'"
          class="restored"
          size="medium"
        >
        </flx-icon>
      </div>
      <div class="restored">
        <span style="font-size: 20px; font-style: normal"
          >Network Restored.</span
        >
        <span style="font-size: 12px">Let's keep going!</span>
      </div>
    </ng-container>

    <ng-container *ngIf="!showMessage && !isOffline">
      <div class="workflow-action">
        <a
          *ngIf="isSp === true"
          class="need-help-button"
          href="https://4-sure.atlassian.net/servicedesk/customer/portal/15"
          target="_blank"
          rel="noopener"
        > Need help <span>?</span> </a>
      </div>

      <div class="workflow-action" *ngFor="let action of headerActions">

        <flx-icon
          *ngIf="action?.type === 'standalone'"
          [type]="action?.icon"
          [isActive]="clickedAction?.id === action.id ? true : false"
          active="none"
          class="action-panel-button"
          (click)="onClick(action)"
          [instruction]="action.instruction"
          >{{ action?.icon }}</flx-icon
        >
        <flx-context-menu
          *ngIf="action?.type === 'dropdown'"
          icon="view"
          use="app-bar"
          [state]="state"
        >
          <ul>
            <li
              *ngFor="let childAction of action.children"
              (click)="onClick(childAction)"
            >
              {{ childAction?.text }}
            </li>
          </ul>
        </flx-context-menu>
      </div>
    </ng-container>
  </ng-container>
</div>
