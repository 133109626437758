<table class="flx-calendar-table year">
	<thead class="flx-calendar-table-header">
		<tr>
			<th class="flx-calendar-table-header-divider" colspan="4"></th>
		</tr>
	</thead>
	<tbody
		flx-calendar-body
		[allowDisabledSelection]="true"
		[rows]="_years"
		[todayValue]="_todayYear"
		[selectedValue]="_selectedYear"
		[numCols]="4"
		[cellAspectRatio]="4 / 7"
		[activeCell]="_getActiveCell()"
		(selectedValueChange)="_yearSelected($event)"
	></tbody>
</table>
