import { Flow_0_0_2 } from '@flexus/core';

export const SIL_26: Flow_0_0_2 = {
	id: '26',
	name: 'TL_work_complete',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Wait for SP invoice'
		}
	},
	startNode: '',
	nodes: {
		Billing_Invoice: {
			component: 'BillingComponent',
			inputs: {
				billingTemplates: {
					children: [
						{ component: 'HeaderComp', inputs: { foo: 'bar', test: [] } },
						{ component: 'MiddleComp', inputs: {} }
						// { component: 'FooterComp', inputs: {} }
					],
					layout: 'horizontal'
				}
			}
		}
	}
};
