<div class="dropdown" [ngStyle]="{ margin: margin, width: width }">
  <div
    *ngIf="multiple === true"
    id="button-content"
    class="dropbtn multiple"
    (click)="open()"
    [ngClass]="{ 'is-disabled': disabled }"
  >
    <div class="select-body">
      <span
        *ngIf="lstSelectedItem === undefined || lstSelectedItem.length === 0"
      >
        {{ value }}
      </span>
      <span *ngIf="lstSelectedItem" class="wrapper">
        <span
          *ngFor="let selected of lstSelectedItem"
          class="selected-items"
          [class.selected]="selected"
        >
          <div class="ms_select">
            <span class="ms_select__label">{{
              selected[displayOptions.displayKey]
            }}</span>
            <flx-icon
              class="choices__icon"
              *ngIf="canBeRemoved(selected[displayOptions.displayKey])"
              (click)="removeSelectedItem($event, selected)"
              color="warning"
              [type]="'remove'"
              size="small"
            ></flx-icon>
          </div>
        </span>
      </span>
    </div>
    <div class="select-icon">
      <flx-icon [type]="'chevron-down'" size="small"></flx-icon>
    </div>
  </div>
  <div
    (click)="open()"
    *ngIf="multiple === false"
    id="button-content"
    class="dropbtn"
    [ngClass]="{ 'is-disabled': disabled }"
  >
    <div class="select-text" [class.selected]="selected || isItemSelected">
      {{ value }}
    </div>
    <flx-icon
      class="down-arrow-btn"
      size="small"
      [type]="'chevron-down'"
      fill="var(--primary)"
    ></flx-icon>
  </div>
  <ng-container *ngIf="!disabled">
    <flx-popup-picker
      [opened]="visible"
      (closed)="visible = false"
      #mySelectOptions
    >
      <div class="dropdown-content">
        <form [formGroup]="filterForm" *ngIf="searchEnabled">
          <flx-input-text
            #inputEl
            type="text"
            placeholder="Type to filter"
            [ngClass]="arrClasses"
            formControlName="searchFilter"
            [autofocus]="autoFocus"
          ></flx-input-text>
        </form>

        <ul>
          <ng-container *ngFor="let data of lstItemDataSet">
            <li
              (click)="onItemSelected(data)"
              class="select-item"
              [class.selected]="[data] | arrayContains : lstSelectedItem"
            >
              <span>{{ data[displayOptions.displayKey] }}</span>
            </li>
          </ng-container>
        </ul>
      </div>
    </flx-popup-picker>
    <!-- <div *ngIf="visible" id="myDropdown" class="dropdown-content" [ngStyle]="styles.dropdown_contant">
      <form [formGroup]="filterForm" *ngIf="searchEnabled">
        <input
          #inputEl
          type="text"
          placeholder="Type to filter"
          [ngClass]="arrClasses"
          class="dropdown-content__filter"
          formControlName="searchFilter"
          autofocus="autoFocus"
        />
      </form>

      <ul *ngFor="let data of lstItemDataSet">
        <li (click)="onItemSelected(data)">
          <span>{{ data[displayOptions?.displayKey] }}</span>
        </li>
      </ul>
    </div> -->
  </ng-container>
</div>
