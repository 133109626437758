import { UntypedFormControl, Validators } from '@angular/forms';
import { NodePoint_0_0_2, getFullItemTwo, getAllInfo, getCurrentUser, getData } from '@flexus/core';

export const requestProofNode = (state?, new_state?): NodePoint_0_0_2 => {
	return {
		name: 'Request Proof of Purchase',
		showTabs: false,
		hideTabItem: true,
		initFormFields: (bf, item, instance, storeQuery, store) => {
			bf.patchValues({ new_state: 270 });
			bf.addControl('request_proof_purchase', new UntypedFormControl('', [Validators.required]));
		},
		checkValidityForFields: ['request_proof_purchase'],
		component: {
			children: [
				{
					component: 'FLXFlatDynamicFormComponent',
					inputs: {
						heading: 'Request for Proof of Purchase',
						formControls: {
							0: {
								label: 'Add any note with regards to requesting a proof of purchase',
								inputType: 'textarea',
								rows: 5,
								formControlName: 'request_proof_purchase'
							}
						},
						formLayout: 'stacked',
						containerWidth: '50vw',
						headingSize: 'medium',
						headingWeight: 'light',
						headingType: 'creation',
						headingMargin: '30px 0 75px 0'
					}
				}
			]
		},
		navs: [
			{
				text: 'Submit',
				nextNode: 'submissionSuccess',
				color: 'primary',
				serverCalls: {
					response: {
						serviceVariable: 'mulService',
						functionName: 'updateJob',
						errorMessage: 'Job could not be updated!!'
					}
				}
			}
		]
	};
};
