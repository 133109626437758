<flx-heading [align]="'center'" size="medium" color="secondary">SP Details</flx-heading>
<flx-flex-container alignItems="center" maxWidth="100%" *ngIf="sp; else nospdetail">
	<div class="sp-detail-container">
		<div class="layout-screen">
			<flx-panel align="center">
				<span class="sp-name">{{ spname }}</span>
				<div *ngIf="spemail" hoverClickCopy class="sp-email">{{ spemail }}</div>

				{{ spcontact }}&nbsp;&nbsp;&nbsp;&nbsp;{{ spcontactnumber }}
			</flx-panel>
		</div>
	</div>
</flx-flex-container>
<ng-template #nospdetail> <flx-alert type="no-info" missingItem="SP details"></flx-alert> </ng-template>
