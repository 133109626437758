import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, fromEvent, merge, timer, interval } from 'rxjs';
import { bufferTime, filter, map, take, skipWhile } from 'rxjs/operators';
import moment from 'moment';
import { Store } from '@ngrx/store';

@Injectable()
export class AccountLockoutService {
	jwtHelper = new JwtHelperService();
	constructor(private store: Store<any>) {}

	userIdleTime(milliseconds: number): Observable<any> {
		const mergedStream = merge(fromEvent(document, 'click'), fromEvent(document, 'keydown'), fromEvent(document, 'mousemove'), fromEvent(document, 'scroll'));
		const idleStream = mergedStream.pipe(
			// tap(v => console.log('Monitoring idle time: ', v)),
			bufferTime(milliseconds),
			filter(arr => arr.length === 0)
		);
		return idleStream;
	}

	get isTokenExpired() {
		return this.jwtHelper.isTokenExpired();
	}

	getToken() {
		return interval(1000)?.pipe(
			map(() => localStorage.getItem('flexus.web.jwtToken')),
			skipWhile(token => !token),
			map(token => ({
				expirationDate: this.jwtHelper.getTokenExpirationDate(token),
				expirationWarningDate: moment(this.jwtHelper.getTokenExpirationDate(token)).subtract(4, 'minutes').toDate(),
				isExpired: this.jwtHelper.isTokenExpired(token)
			}))
		);
	}

	countdownTimer(start) {
		return timer(0, 1000)?.pipe(
			map(i => start - i),
			take(start + 1)
			// delay(fireWhen),
			// tap(counter => {
			//   if (counter === 0) {
			//     // When counter gets to 0 log user out
			//     this.store.dispatch(new InitializeTempData());
			//     setTimeout(() => {
			//       this.store.dispatch(new LogOut());
			//     }, 1000);
			//   }
			// })
		);
	}
}
