import { Component, OnInit, Input, OnDestroy, Inject, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { getFullItemTwo, getAllInfo } from '@flexus/core';
import { Observable, of, forkJoin } from 'rxjs';
import { filter, take, skipWhile, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { humaniseDate } from '@flexus/utilities';
import moment from 'moment';

@Component({
	selector: 'flx-sp-job-details',
	templateUrl: 'sp-job-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXSPJobDetailsComponent implements OnInit, OnDestroy {
	jobDetails$: Observable<any>;
	customerDetails$: Observable<any>;

	constructor(private _store: Store<any>, private _http: HttpClient, @Inject('environment') private _environment: any) {}

	setupJobObservable() {
		return forkJoin([
			this._store.select(getFullItemTwo)?.pipe(
				filter(x => !!x),
				take(1),
				map(res => res as any)
			),
			this._store.select(getAllInfo)?.pipe(
				filter(x => !!x),
				take(1),
				map(res => res as any)
			),
			this._http.get(`${this._environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
				skipWhile(x => !x),
				take(1),
				map(res => res['payload'])
			)
		])?.pipe(
			map(([job, info, leads]) => {
				const appointment = job?.appointment[job.appointment.length - 1];
				const { job_information, claim } = job;
				const sp = job?.sp;
				const sps = info.sps;
				let spname;
				for (let i = 0; i < sps.length; i++) {
					if (sp === sps[i]?.id) {
						spname = sps[i]?.name;
					}
				}
				const teamleads = leads as any;
				let teamleadname;
				for (let i = 0; i < leads.length; i++) {
					if (!job.team_leader) {
						teamleadname = 'No team leader assigned currently';
					} else if (teamleads[i]?.id === job?.team_leader.id) {
						teamleadname = teamleads[i]?.full_name;
					}
				}

				return of([
					{
						'Claim type': claim?.type ?? '',
						'Skill required ': job?.office_use?.skillcatagory ?? '',
						'Address ': job?.address ?? '',
						'Appointment Date': appointment.range_start ? `${humaniseDate(appointment.range_start)}` : '',
						'Appointment Time': appointment.range_start ? `${moment(appointment.range_start)?.format('HH:mm')}` : ''
					}
				]);
			})
		);
	}

	setupCustomerObservable() {
		return this._store.select(getFullItemTwo)?.pipe(
			filter(x => !!x),
			take(1),
			map(res => {
				const itemTwo = res as any;
				const customerkeyvalues = {
					'Customer ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : 'Customer name not available',
					'Client cell ': itemTwo?.claim?.loan_information?.cellnumber ?? 'Cellular number not on record'
				};
				return [customerkeyvalues];
			})
		);
	}

	ngOnInit() {
		this.jobDetails$ = this.setupJobObservable();
		this.customerDetails$ = this.setupCustomerObservable();
	}

	ngOnDestroy() {}
}
