import { Observable, from, empty, Subscription } from 'rxjs';
import { map, tap, switchMap, take } from 'rxjs/operators';
import { Injectable, Inject, OnDestroy } from '@angular/core';
import { NetworkService } from './network.service';
import { Store } from '@ngrx/store';
import { ManifestController } from '../controllers/manifest.controller';
import { CryptoService, IndexedDbService, cryptoBlobKey } from './storage-services';

@Injectable({ providedIn: 'root' })
export class OfflineService implements OnDestroy {
	storeSnapshotSubscription: Subscription;

	constructor(
		private indexedDbService: IndexedDbService,
		private cryptoService: CryptoService,
		private networkService: NetworkService,
		@Inject(cryptoBlobKey) private CRYPTO_BLOB_KEY: string,
		private store: Store<any>,
		private controller: ManifestController<any>
	) {
		this.keepStoreSnapshotWhenOffline();
	}

	getAllClaims(): Observable<any> {
		// return from(this.indexedDbService.data_store.get('store'))
		return from(this.cryptoService.decryptWithAESCBCKey(this.CRYPTO_BLOB_KEY, 'store')).pipe(
			switchMap(() => this.cryptoService.getStore()),
			tap(store => console.log({ THESTORE: store })),
			map(store => store['manifest']['viewData']['default']),
			map(data => ({ payload: data }))
		);
	}

	getAllInfo(): Observable<any> {
		return from(this.cryptoService.decryptWithAESCBCKey(this.CRYPTO_BLOB_KEY, 'store')).pipe(
			switchMap(() => this.cryptoService.getStore()),
			map(store => store['allInfo'])
		);
	}

	createClaim(claim: any) {
		// All browser local drafts in state 169
		return this.indexedDbService.claimInDraft.put({ ...claim, state: 169 }, claim?.id);
	}

	deleteCurrentItem() {
		return this.indexedDbService.currentItem.delete('currentItem');
	}

	// AUTH OFFLINE AREA

	login(credentials: { email: string; password: string }): Observable<any> {
		// console.log({ credentials });
		return from(this.cryptoService.decryptWithAESCBCKey(this.CRYPTO_BLOB_KEY, 'store')).pipe(
			switchMap(() => this.cryptoService.getStore()),
			// tap(store => console.log({ THESTORE: store })),
			map(store => store['identity']['currentUser']),
			map(user => ({ user, success: true })),
			map(currentUser => {
				if (currentUser?.user?.user?.username === credentials.email) {
					return currentUser;
				}
			})
			// tap((u) => console.log({ u })),
		);
	}

	getLoggedInUserFromServer(): Observable<any> {
		// console.log('Getting logged in user');
		return from(this.cryptoService.decryptWithAESCBCKey(this.CRYPTO_BLOB_KEY, 'store')).pipe(
			switchMap(() => this.cryptoService.getStore()),
			map(store => store['identity']['login']['currentUser'])
		);
	}

	keepStoreSnapshotWhenOffline() {
		this.storeSnapshotSubscription = this.networkService.isOnline
			.pipe(
				switchMap(online => {
					if (!online) {
						return this.store.pipe(
							take(1),
							switchMap(store => this.controller.stateObservable.pipe(map(manifest => ({ ...store, manifest })))),
							map(store => {
								// const storeClone = clone(store);
								this.cryptoService.encryptStateStore(this.CRYPTO_BLOB_KEY, store);
								// this.indexedDbService.data_store.put({ id: 'store', store });
								// console.log({ store });
							})
						);
					} else {
						return empty();
					}
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		if (this.storeSnapshotSubscription) {
			this.storeSnapshotSubscription.unsubscribe();
		}
	}
}
