import { getInterstateComments } from './dataManipulations';

export const GENERIC_BFTSMAPPER: { [id: string]: string | string[] | [Function, string] | [Function, string][] } = {
	new_state: [
		(new_state, storeObj, bf) => {
			let newState = new_state;
			if (newState === 188) {
				const payment_validated = storeObj?.['selectedContext']?.fullItemTwo?.job_information?.payment_validated?.toLowerCase();
				storeObj['selectedContext']?.fullItemTwo.job_information && payment_validated === 'true' ? (newState = 28) : (newState = 188);
			}

			return newState;
		},
		'new_state'
	],
	message: [(message, storeObj, bf) => getInterstateComments(message, storeObj, bf), 'job_information.interstate_comments']
};
