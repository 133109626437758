import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIElementsModule } from '@flexus/ui-elements';
import { FLXHeaderActionsComponent } from './header-actions.component';
import { UICompositesModule } from '@flexus/ui-composites';

@NgModule({
	declarations: [FLXHeaderActionsComponent],
	imports: [CommonModule, UIElementsModule, UICompositesModule],
	exports: [FLXHeaderActionsComponent]
})
export class HeaderActionsModule {}
