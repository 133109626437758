import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemTwo, SetPreviousNode } from '@flexus/core';
import { setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { jobInfoNode_insurance } from '../reusable/JOB_INFO_NODE_INSURANCE';
import { photoNode } from '../reusable/PHOTO_NODE';
import { invoiceNode } from '../reusable/VIEW_INVOICE_NODE';

export const MUL_252: Flow_0_0_2 = {
	id: '252',
	name: 'insurance-invoice-query-reply',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Insurance Invoice - Query reply ',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Insurance Invoice Query - Address SP Reply'
		},
		viewRoles: {
			0: 'Insurance Invoice Query - Reply being assessed'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		}
	},
	startNode: 'JobInformation',
	nodes: {
		JobInformation: {
			...jobInfoNode_insurance(252)
		},
		Photos: {
			...photoNode(252, 28)
		},
		Invoice: {
			...invoiceNode(252, 28)
		},
		QueryInvoice: {
			showTabs: false,
			hideTabItem: true,
			checkValidityForFields: ['invoice_query'],
			initFormFields: (bf, item, instance, sq, store) => {
				bf.patchValues({ new_state: 251 });
				bf.addControl('invoice_query', new UntypedFormControl('', [Validators.required]));
				// for possible complete message
				/*
        	combineLatest([store.select(getCurrentUser).pipe(filter(x => !!x, take(1))), store.select(getFullItemTwo).pipe(filter(x => !!x, take(1)))])
					.pipe(take(1))
					.subscribe(([author, job]) => {
						bf.patchValues({ author: author.user.id });
						bf.patchValues({ author_name: author.user.full_name });
						bf.patchValues({ current_state: job.state });
					});


        */
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Reply to Installer Scheduler',
							formControls: {
								0: {
									label: 'Comment why you are returning this to the Installer Scheduler',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'invoice_query'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'SUBMIT',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: '',
							serviceVariable: 'mulService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},
		Decision: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual'
			},
			decisions: {
				makePayment: (navs, store, modal, controller) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(jobber => {
								modal.openModalDirectly(instance => {
									instance.type = 'warning';
									instance.heading = 'Confirm Payment Approval';
									instance.setMessage(['You are about to confirm a payment', 'This can not be undone', '', 'Please confirm that you have completed payment']);
									instance.navButtons = [
										{
											text: 'back',
											clickHandler: event => {
												controller.dispatch(new SetPreviousNode());
											},
											color: 'default',
											linkType: 'close'
										},
										{
											text: 'confirm payment',
											color: 'default',
											linkType: 'submitThenNext',
											serverFirst: true,
											nextNode: 'SubmissionSuccess',
											serverCalls: {
												response: {
													serviceVariable: 'mulService',
													functionName: 'updateJob',
													errorMessage: 'job could not be updated'
												}
											}
										}
									];
								});
								return 'Decision';
							})
						)
						.subscribe();
				}
			},
			navs: []
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		current_state: 'current_state',
		invoice_query: [
			(iq, storObj, bf) => {
				let validation_queries = [];
				let tempQueries = storObj?.['selectedContext']?.fullItemTwo?.job_information?.insurance_invoice_queries;
				if (tempQueries) {
					if (Array.isArray(tempQueries)) {
						validation_queries = tempQueries;
					} else {
						validation_queries = [
							{
								...tempQueries
							}
						];
					}
				}
				const validationquery = [...validation_queries, { query: iq, queryreply: '' }];
				return validationquery;
			},
			'job_information.insurance_invoice_queries'
		]
	}
};
