import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { routes } from '../routes/routes';

@NgModule({
	imports: [RouterModule.forRoot(routes.core)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
