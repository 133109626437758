import {
	BigFormService,
	Flow_0_0_2,
	MakeServerCall,
	MakeServerCallWithLoader,
	ManifestController,
	ModalService,
	SetNextNode,
	SetPreviousNode,
	getAllInfo,
	getCurrentUser,
	getData,
	getFullItemTwo,
	getSelectedItem,
	getSelectedItemTwo,
	getSubmissionData
} from '@flexus/core';

import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { filter, skipWhile, first, map, take, pluck, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { EMPTY, forkJoin, of } from 'rxjs';
import moment from 'moment';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { JobLocation } from '@flexus/ui-elements';
import { JOB_INFO_NODE } from '../reusable/JOB_INFO_NODE';
import gql from 'graphql-tag';
import { AUTOPAY_CLAIM_STATUSES, addObjectProperty, convertDateTimeToTimeStamp } from '@flexus/utilities';

export const MUL_339: Flow_0_0_2 = {
	id: '339',
	name: 'autopay_payment_deffered_validation_agent',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				filter(x => !!x),
				skipWhile((itemTwo: any) => {
					return itemTwo === null || itemTwo === undefined;
				}),
				first(itemTwo => itemTwo !== null || itemTwo !== undefined),
				map((itemTwo: any) => {
					let headerStr = 'Autopay declined';
					if (itemTwo) {
						headerStr += ` : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
					}
					return headerStr;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	instructions: {
		editRoles: {
			0: 'Resubmit for payment'
		},
		viewRoles: {
			0: '--'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		},
		teamleaderLocations: {
			errorMessage: 'Team leader locations not returned!',
			directCall: (http, store, sq, bf) => {
				return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
					pluck('payload'),
					filter(x => !!x),
					map((teamleaders: any[]) => {
						return teamleaders;
					})
				);
			}
		},
		claim_status_fix_nodename: {
			errorMessage: 'Could not get claim status',
			directCall: (_http, _store: Store, sq, _f: BigFormService) => {
				return _store.select(getFullItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					map((mapped_job: any) => {
						_f.addControl('fix_nodename', new UntypedFormControl('', []));
						const { office_use } = mapped_job;
						const { claim_status } = office_use;
						switch (claim_status) {
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_02:
								_f.patchValues({ fix_nodename: 'FixVoucherNumber' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_03:
								_f.patchValues({ fix_nodename: 'FSIALogin' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_04:
								_f.patchValues({ fix_nodename: 'FSIALogin' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_05:
								_f.patchValues({ fix_nodename: 'FixVoucherNumber' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_06:
								_f.patchValues({ fix_nodename: 'FixVoucherNumber' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_09:
								_f.patchValues({ fix_nodename: 'InstallerNotAccredited' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_10:
								_f.patchValues({ fix_nodename: 'TechnicianNotQualified' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_18:
								_f.patchValues({ fix_nodename: 'SchedulerTrainingRequired' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_19:
								_f.patchValues({ fix_nodename: 'SchedulerTrainingRequired' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_20:
								_f.patchValues({ fix_nodename: 'SchedulerTrainingRequired' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_21:
								_f.patchValues({ fix_nodename: 'SchedulerTrainingRequired' });
								break;
							case AUTOPAY_CLAIM_STATUSES.CLAIMSTATUS_24:
								_f.patchValues({ fix_nodename: 'OldClaim' });
								break;
						}
					})
				);
				return of({});
			}
		}
	},
	startNode: 'JobInformation',
	nodes: {
		JobInformation: {
			initFormFields: () => {},
			showTabs: true,
			...JOB_INFO_NODE(339, 341)
		},

		FixProblem: {
			name: 'Fix Problem',
			showTabs: true,
			initFormFields: () => {},
			serverCalls: {
				autopay_fail_reason: {
					errorMessage: 'Could not get the decline reasons',
					directCall: (_http: HttpClient, _store: Store, _sQuery: any) => {
						return _sQuery
							.queryObject(
								gql`
									{
										fullItemTwo {
											office_use {
												claim_status
											}
										}
									}
								`,
								_store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(map((queryData: any) => Object.values(queryData)[0]));
					}
				}
			},
			component: 'FLXAutoPayFixerComponent',
			inputs: {
				autopay_fail_reason: 'autopay_fail_reason'
			},
			// TODO: navigation commented out: may be required in future
			// inputs: { title: 'Fix Problem component', instructions: ['develop component to take payment failure reasons for display '] },
			navs: []
		},
		SendQueryToSP: {
			hideTabItem: true,
			checkValidityForFields: ['validation_query_installer'],
			showTabs: true,
			initFormFields: (_bf: any, item: any, instance: any, storeQuery: any, store: any) => {
				_bf.addControl('validation_query_installer', new UntypedFormControl('', [Validators.required]));
			},

			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Send a Query to the Installer',
							formControls: {
								0: {
									label: 'Enter your query',
									inputType: 'textarea',
									placeholder: '',
									// rows: 5,
									formControlName: 'validation_query_installer'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [{ text: 'submit', color: 'primary', nextNode: 'SubmissionSuccess', optIntoValidation: true, linkType: 'portal' }]
		},
		FixDecoderNumber: {
			checkValidityForFields: ['decoder_number'],
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('decoder_number', new UntypedFormControl('', [Validators.required]));
			},
			hideTabItem: true,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: { title: 'Please Enter Correct Decoder Number' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Decoder number',
									inputType: 'input',
									disabled: false,
									rows: 5,
									formControlName: 'decoder_number'
								}
							},
							formLayout: 'stacked',
							containerWidth: '25vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Decoder number could not be updated`,
							directCall: (_http: HttpClient, _store: Store, _storeQuery: any, _bf: BigFormService) => {
								return _storeQuery.queryObject(
									gql`
										{
											fullItemTwo {
												id
											}
										}
									`,

									_store
										.select(getFullItemTwo)
										.pipe(
											skipWhile((data: any) => !data),
											take(1),
											map(data => data)
										)
										.pipe(
											switchMap((result: any) => {
												const job_id = result?.id;
												const voucher_number = _bf.getControl('voucher_number').value;
												return _http.post(`${environment.api_url}v1/mc_actions/update_voucher_number?${voucher_number}&${job_id}`, {
													job_id: job_id,
													voucher_number: voucher_number
												});
											})
										)
								);
							},
							followUpSuccessCalls: {
								displaySuccess: {
									errorMessage: '',
									directCall: (_http, _store, _storeQuery, _bf: BigFormService, con, modal) => {
										return _storeQuery.queryObject(
											gql`
												{
													fullItemTwo {
														id
													}
												}
											`,
											_store
												.select(getFullItemTwo)
												.pipe(
													skipWhile((data: any) => !data),
													take(1),
													map(data => data)
												)
												.pipe(
													switchMap((result: any) => {
														const job_id = result?.id;
														const new_state = 341;
														return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
													})
												)
										);
									}
								}
							},
							followUpFailCalls: {
								displayFail: {
									errorMessage: '',
									directCall: (http, store, sq, bf, con, modal) => {
										con.dispatch(new SetPreviousNode());
										return of(false);
									}
								}
							}
						}
					}
					// nextNode: 'SubmissionSuccess'
				}
			]
		},

		LinkDevice: {
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('device_to_link', new UntypedFormControl('', [Validators.required]));
				_bf.bigForm.addControl('voucher_number', new UntypedFormControl('', [Validators.required]));
			},
			checkValidityForFields: ['device_to_link', 'voucher_number'],
			hideTabItem: true,
			component: {
				children: [
					{ component: 'FLXHeadingWithInstructionsComponent', inputs: { title: 'Please Enter Details to Link Device' } },
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Link/Activate Device',
									align: 'left',
									inputType: 'input',
									disabled: false,
									rows: 5,
									formControlName: 'device_to_link'
								}
								// 1: {
								// 	label: 'Voucher Number',
								// 	align: 'left',
								// 	inputType: 'input',
								// 	disabled: false,
								// 	rows: 5,
								// 	formControlName: 'voucher_number'
								// }
							},
							formLayout: 'stacked',
							containerWidth: '25vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Device could not be linked.`,
							directCall: (_http: HttpClient, _store: Store, sq: any, _f: BigFormService, _cntrllr: ManifestController<any>, _modal: ModalService) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile((data: any) => !data),
										take(1),
										map(data => data)
									)
									.pipe(
										switchMap((result: any) => {
											const job_id = result?.id;
											const voucher_number = _f.getControl('device_to_link').value;
											console.log('IN HERE', job_id);
											const request = {
												job_id: job_id,
												voucher_number: voucher_number
											};
											// TODO: check endpoint
											return _http.post(`${environment.api_url}v1/mc_actions/check_voucher_number/`, request);
										})
									)
									.pipe(
										skipWhile(x => !x),
										switchMap((response: any) => {
											if (!response.success) {
												console.log('getting a fail here', response);
												_f.patchValues({ new_state: 339 });
												_modal.openModalDirectly((instance, store, bf) => {
													instance.type = 'warning';
													instance.color = 'alert';

													instance.heading = 'Invalid Voucher Number';
													instance.setMessage(['The number you entered is not a valid voucher number', 'Please correct it and try again']);
													instance.navButtons = [
														{
															text: 'try again',
															color: 'default',
															linkType: 'close',
															clickHandler: ev => {
																_cntrllr.dispatch(new SetNextNode('LinkDevice'));
																store.dispatch(
																	new MakeServerCall({
																		dataKey: 'updateJob',
																		functionName: 'updateJob',
																		serviceVariable: 'mulSpService',
																		errorMessage: 'Could not update job!'
																	})
																);
																_f.patchValues({ device_to_link: '' });
																return 0;
															}
														}
													];
												});
											} else if (response.success) {
												// console.log('getting a success here !!!!!!!', response);
												const job_id = _f.getControl('job_id')?.value;
												const new_state = 341;
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
											}
										})
									);
							}
							// followUpSuccessCalls: {
							// 	displaySucces: {
							// 		errorMessage: '',
							// 		directCall: (http, store, sq, bf, con, modal) => {
							// 			return of({});
							// 		}
							// 	}
							// },
							// followUpFailCalls: {
							// 	displayFail: {
							// 		errorMessage: '',
							// 		directCall: (http, store, sq, bf, con, modal) => {
							// 			con.dispatch(new SetPreviousNode());
							// 			return of(false);
							// 		}
							// 	}
							// }
						}
					}
				}
			]
		},

		FixVoucherNumber: {
			checkValidityForFields: ['voucher_number'],
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			hideTabItem: true,
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('voucher_number', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{ component: 'FLXHeadingWithInstructionsComponent', inputs: { title: 'Please Enter the Voucher Number' } },
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Voucher Number',
									align: 'left',
									inputType: 'input',
									disabled: false,
									rows: 5,
									formControlName: 'voucher_number'
								}
							},
							formLayout: 'stacked',
							containerWidth: '25vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Voucher number could not be updated`,
							directCall: (_http: HttpClient, _store: Store, _storeQuery: any, _bf: BigFormService, _cntrllr: ManifestController<any>, _modal: ModalService) => {
								return _storeQuery.queryObject(
									gql`
										{
											fullItemTwo {
												id
											}
										}
									`,

									_store
										.select(getFullItemTwo)
										.pipe(
											skipWhile((data: any) => !data),
											take(1),
											map(data => data)
										)
										.pipe(
											switchMap((result: any) => {
												const job_id = result?.id;
												_bf.addControl('job_id', new UntypedFormControl(job_id, []));
												const voucher_number = _bf.getControl('voucher_number').value;
												return _http.post(`${environment.api_url}v1/mc_actions/update_voucher_number?${voucher_number}&${job_id}`, {
													job_id: job_id,
													voucher_number: voucher_number
												});
											})
										)
										.pipe(
											skipWhile(x => !x),
											switchMap((response: any) => {
												if (response.success) {
													const job_id = _bf.getControl('job_id')?.value;
													const new_state = 341;
													return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
												} else if (!response.success) {
													_bf.patchValues({ new_state: 339 });
													_modal.openModalDirectly((instance, store, bf) => {
														instance.type = 'warning';
														instance.color = 'alert';

														instance.heading = 'Invalid Voucher Number';
														instance.setMessage(['The number you entered is not a valid voucher number', 'Please correct it and try again']);
														instance.navButtons = [
															{
																text: 'try again',
																color: 'default',
																linkType: 'close',
																clickHandler: ev => {
																	_cntrllr.dispatch(new SetNextNode('FixVoucherNumber'));
																	store.dispatch(
																		new MakeServerCall({
																			dataKey: 'updateJob',
																			functionName: 'updateJob',
																			serviceVariable: 'mulSpService',
																			errorMessage: 'Could not update job!'
																		})
																	);
																	_bf.patchValues({ voucher_number: '' });
																	return 0;
																}
															}
														];
													});
												}
											})
										)
								);
							}
						}
					}
				}
			]
		},
		FSIALogin: {
			component: 'FSIALoginComponent',
			hideTabItem: true,
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('qrCodeInputItems', new UntypedFormArray([]));
			}
		},
		UploadPhotos: {
			hideTabItem: true,
			initFormFields: (_bf: BigFormService) => {},
			component: 'FLXFileUploadWithPreviewComponent',
			inputs: {
				purposes: [{ display: 'Validation Agent Photo Upload', value: 'Validation Agent Photo Upload' }],
				heading: 'Upload all Missing Photos',
				multipleUploads: false,
				allowedFileTypes: ['pdf', 'image'],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Missing photos not successfully uploaded`,
							directCall: (_http: HttpClient, _store: Store) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile((data: any) => !data),
										take(1),
										map(data => data)
									)
									.pipe(
										switchMap((result: any) => {
											const job_id = result?.id;
											// TODO: check endpoint
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: 339 });
											// return _http.post(`${environment.api_url}v1/job_action/link_installed_decoder_to_job/`, { job_id: job_id, decoder_number: decoderNumber });
											// return result;
										})
									);
							},
							followUpSuccessCalls: {
								displaySucces: {
									errorMessage: '',
									directCall: (http, store, sq, bf, con, modal) => {
										bf.getControl('upload_photos_fixed').setValue(true);

										return of({});
									}
								}
							},
							followUpFailCalls: {
								displayFail: {
									errorMessage: '',
									directCall: (http, store, sq, bf, con, modal) => {
										bf.getControl('upload_photos_fixed').setValue(false);
										con.dispatch(new SetPreviousNode());
										return of(false);
									}
								}
							}
						}
					}
					// nextNode: 'SubmissionSuccess'
				}
			]
		},

		InstallerNotAccredited: {
			serverCalls: {
				get_accreditation_number: {
					errorMessage: 'Could not retrieve accreditation number',
					directCall: (_http: HttpClient, _store: Store, _storeQuery: any, _bf: BigFormService) => {
						return _store
							.select(getFullItemTwo)
							.pipe(
								skipWhile((data: any) => !data),
								take(1),
								map(res => res)
							)
							.pipe(
								map((result: any) => {
									const { sp } = result?.team_leader;
									const accreditation_number = sp?.accreditation_number;
									const accreditationobject = { 'Current Accreditation Number': `${accreditation_number}` };
									return [accreditationobject];
								})
							);
						// );
					}
				}
			},
			checkValidityForFields: ['accreditation_number'],
			hideTabItem: true,
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('accreditation_number', new UntypedFormControl('', [Validators.required]));
				_bf.patchValues({ new_state: 341 });
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Please Enter The Correct Accreditation Number'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'get_accreditation_number'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Accreditation Number',
									align: 'left',
									inputType: 'input',
									disabled: false,
									rows: 5,
									formControlName: 'accreditation_number'
								}
							},
							formLayout: 'stacked',
							containerWidth: '25vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Installer accreditation number could not be updated`,
							directCall: (_http: HttpClient, _store: Store, sq, _f: BigFormService, _cntrllr: ManifestController<any>, _modal: ModalService) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile((data: any) => !data),
										take(1),
										map(data => data)
									)
									.pipe(
										switchMap((result: any) => {
											const job_id = result?.id;
											_f.addControl('job_id', new UntypedFormControl(job_id, []));

											const { sp } = result?.team_leader;
											const sp_id = sp?.id;
											const accreditation_number = _f.getControl('accreditation_number').value;
											return _http.post(`${environment.api_url}v1/sp_action/update_mc_installer_details/`, { sp_id: sp_id, accreditation_number: accreditation_number });
										})
									)
									.pipe(
										filter(res => !!res),
										switchMap((response: any) => {
											if (response.success) {
												const job_id = _f.getControl('job_id')?.value;
												const new_state = 341;
												console.log('hitting here', response, job_id);

												return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
											} else if (!response.success) {
												// console.log
												_f.patchValues({ new_state: 339 });
												_modal.openModalDirectly(instance => {
													instance.type = 'warning';
													instance.color = 'alert';

													instance.heading = 'Invalid Accreditation Number';
													instance.setMessage(['The number you entered is not a valid accreditation number', 'Please correct it and try again']);
													instance.navButtons = [
														{
															text: 'try again',
															color: 'default',
															linkType: 'close',
															clickHandler: ev => {
																_cntrllr.dispatch(new SetNextNode('InstallerNotAccredited'));
																_store.dispatch(
																	new MakeServerCall({
																		dataKey: 'updateJob',
																		functionName: 'updateJob',
																		serviceVariable: 'mulSpService',
																		errorMessage: 'Could not update job!'
																	})
																);
																_f.patchValues({ accreditation_number: '' });
																return 0;
															}
														}
													];
												});
											}
											return of({});
										})
									);
							}
						}
					}
				}
			]
		},
		TechnicianNotQualified: {
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			checkValidityForFields: ['technician_number'],
			hideTabItem: true,
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('technician_number', new UntypedFormControl('', [Validators.required]));
				_bf.patchValues({ new_state: 341 });
			},
			serverCalls: {
				get_qualification_number: {
					errorMessage: 'Could not retrieve technician qualification number',
					directCall: (_http: HttpClient, _store: Store, _storeQuery: any, _bf: BigFormService) => {
						return _store
							.select(getFullItemTwo)
							.pipe(
								skipWhile((data: any) => !data),
								take(1),
								map(res => res)
							)
							.pipe(
								map((result: any) => {
									const teamleader = result?.team_leader;
									const qualification_number = teamleader.qualification_number;
									const qualificationobject = { 'Current Accreditation Number': `${qualification_number}` };
									return [qualificationobject];
								})
							);
						// );
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: { title: 'Please Enter The Correct Technician Number' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'get_qualification_number'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Technician Number',
									align: 'left',
									inputType: 'input',
									disabled: false,
									rows: 5,
									formControlName: 'technician_number'
								}
							},
							formLayout: 'stacked',
							containerWidth: '25vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '20px 0 25px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'submit fix',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Technician qualification number could not be updated`,
							directCall: (_http: HttpClient, _store: Store, sq, _f: BigFormService, _cntrllr: ManifestController<any>, _modal: ModalService) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile((data: any) => !data),
										take(1),
										map(data => data)
									)
									.pipe(
										switchMap((result: any) => {
											const job_id = result?.id;
											_f.addControl('job_id', new UntypedFormControl(job_id, []));
											const teamleader = result?.team_leader;
											const tl_id = teamleader?.id;
											const tl_name = teamleader?.full_name;
											// const qualification_number = teamleader?.qualification_number;

											return _http.post(`${environment.api_url}v1/sp_action/update_mc_installer_details/`, {
												tl_id: tl_id,
												tl_name: tl_name,
												qualification_number: _f.getControl('technician_number').value
											});
										})
									)
									.pipe(
										skipWhile(res => !res),
										switchMap((response: any) => {
											if (response.success) {
												const job_id = _f.getControl('job_id')?.value;
												const new_state = 341;
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
											} else if (!response.success) {
												// console.log
												_f.patchValues({ new_state: 339 });
												_modal.openModalDirectly(instance => {
													instance.type = 'warning';
													instance.color = 'alert';

													instance.heading = 'Invalid Qualification Number';
													instance.setMessage(['The number you entered is not a valid qualification number', 'Please correct it and try again']);
													instance.navButtons = [
														{
															text: 'try again',
															color: 'default',
															linkType: 'close',
															clickHandler: ev => {
																_cntrllr.dispatch(new SetNextNode('TechnicianNotQualified'));
																_store.dispatch(
																	new MakeServerCall({
																		dataKey: 'updateJob',
																		functionName: 'updateJob',
																		serviceVariable: 'mulSpService',
																		errorMessage: 'Could not update job!'
																	})
																);
																_f.patchValues({ accreditation_number: '' });
																return 0;
															}
														}
													];
												});
											}
										})
									);
							}
						}
					}
				}
			]
		},

		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			hideTabItem: true,
			checkValidityForFields: ['appointmentData'],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, Validators.required),
						appointmentTime: new UntypedFormControl(null, Validators.required),
						appointmentTimePicker: new UntypedFormControl(null, Validators.required),
						appointmentDateType: new UntypedFormControl(null, Validators.required),
						skill: new UntypedFormControl('Dish Installation')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				// bf.patchValues({ new_state: 21 });
			},
			serverCalls: {
				claimaintKeyValues: {
					errorMessage: 'Claimant detail could not be retrieved',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									const claimantinfo = {
										'Claimant Details ': {
											'Full name ': `${job.claim?.applicant?.first_name} ` + `${job.claim?.applicant?.surname}`,
											'Contact Number': job?.claim?.loan_information.ContactNumber,
											'Mobile Number ': job?.claim?.loan_information.cellnumber,
											'On-site contact name ': job?.claim?.loan_information.onsiteperson
										}
									};
									return [claimantinfo];
								})
							);
					}
				},
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									return [
										{
											'Client Name': `${qdat.first_name} ${qdat.surname}`
										},
										{ 'Contact Number': `${qdat.contactnumber}` },
										{ 'Mobile Number': `${qdat.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Set Appointment',
					optIntoValidation: true,
					linkType: 'portal',
					color: 'primary',
					nextNode: 'AssignTLNode',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submit]) => {
										const job_id = job?.id;
										const current_state = job?.state;
										const appointment = submit?.appointment;
										const data = {
											job_id: job_id,
											current_state: current_state,
											appointment: appointment
											// new_state: 21
										};
										return data;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							},
							followUpSuccessCalls: {
								response: {
									errorMessage: `Couldn't assign team leader`,
									directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService, _ctrl: ManifestController<any>) => {
										_ctrl.dispatch(new SetNextNode('AssignTLNode'));
										return of({});
									}
								}
							}
						}
					}
				}
			],
			component: 'FLXJobAppointmentComponent'
		},
		AssignTLNode: {
			hideTabItem: true,
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: bf => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl(null, Validators.required));
				bf.patchValues({ new_state: 345 });
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http, store, sq, bf) => {
						return store
							.select(getSelectedItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map((res: any) => res)
							)
							.pipe(
								switchMap(job => {
									const { id } = job;
									const job_id = id;
									// return of({});
									return http.post(`${environment.api_url}v1/sp_action/manage_list_staff/`, { role_id: 10, image: true, job_id: job_id }).pipe(
										pluck('payload'),
										filter(x => !!x),
										map((teamleaders: any[]) =>
											teamleaders.map(teamLeader => {
												const namestringarr = teamLeader.full_name?.split(' ');
												let mono1, mono2;
												if (!namestringarr[0]) {
													mono1 = ' ';
												} else {
													mono1 = namestringarr[0]?.slice(0, 1);
												}
												if (!namestringarr[1]) {
													mono2 = '';
												} else {
													mono2 = namestringarr[1]?.slice(0, 1);
												}
												const monogram = mono1 + mono2;
												return {
													display: teamLeader.full_name,
													value: teamLeader.id,
													image: teamLeader.image,
													monogram: monogram,
													teamLeader,
													shouldHaveImage: true
												};
											})
										)
									);
								})
							);
					}
				},
				jobLocationData: {
					errorMessage: 'The job location was not returned!',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(j => {
								const job = j as any;
								return http
									.post(`${environment.api_url}v1/job_action/get_job/`, { job_id: job?.id })
									.pipe(
										skipWhile(x => !x),
										take(1),
										map(result => result as any)
									)
									.pipe(
										map(jobloc => {
											const locationString = jobloc['payload']?.claim?.location;
											const locArray = locationString?.split(',');
											const latitude = +locArray[0];
											const longitude = +locArray[1];
											return new JobLocation(latitude, longitude);
										})
									);
							})
						);
					}
				},
				teamleaderLocations: {
					errorMessage: 'Team leader locations not returned!',
					directCall: (http, store, sq, bf) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
							pluck('payload'),
							filter(x => !!x),
							map((teamleaders: any[]) => {
								return teamleaders;
							})
						);
					}
				}
			},
			component: 'AssignTLComponent',
			navs: [
				{
					text: 'submit',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't assign team leader!",
							directCall: (http, store, sq, bf, ctrl, modal) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getCurrentUser).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									)
								]).pipe(
									switchMap(([j, uid]) => {
										const job = j as any;
										const job_id = job?.id;
										const team = bf.bigForm.get('assign_teamleader_id')?.value;

										const data = {
											job_id: job_id,
											tl_id: team[0]
											// new_state: 339
										};
										return http.post(`${environment.api_url}v1/mc_actions/update_team_leader/`, data).pipe(
											skipWhile(x => !x),
											switchMap((response: any) => {
												const new_state = 345;
												const request = {
													job_id: job_id,
													new_state: new_state
												};
												if (response?.success) {
													return http.post(`${environment.api_url}v1/job_action/update_job/`, request);
												} else if (!response.success) {
													modal.openModalDirectly(instance => {
														instance.type = 'warning';
														instance.heading = 'Something went wrong!';
														instance.setMessage(['Your request could not be completed.', 'Please try again or return contact your system administrator.']);
														instance.navButtons = [
															{
																text: 'close',
																linkType: 'close'
															}
														];
													});
												}
											})
										);
									})
								);
							}
						}
					},

					location: 'right'
				}
			]
		},
		PeripheralFixDecision: {
			hideTabItem: true,
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => EMPTY
			},
			decisions: {
				selectPeripheralFixFlow: (navs: any, _store: Store) => {
					return _store
						.select(getFullItemTwo)
						.pipe(
							skipWhile(x => !x),
							take(1),
							map((response: any) => {
								const { job_information } = response;
								console.log();
								const { additional_hardware } = job_information;
								if (additional_hardware) {
									const arrayFromObject = Object.keys(additional_hardware);
									console.log('arr', arrayFromObject);
									if (Array.isArray(arrayFromObject) && arrayFromObject.length > 1) {
										console.log('PERIPHERAL');
										_store.dispatch(
											new MakeServerCall({
												errorMessage: 'Could not advance to fixnode 1',
												directCall: (_http, _store, _sq, _f, _cntrllr: ManifestController<any>) => {
													_cntrllr.dispatch(new SetNextNode('PeripheralFailAcceptDecline'));
													return of({});
												}
											})
										);
									} else if (Array.isArray(arrayFromObject) && arrayFromObject.length === 1) {
										_store.dispatch(
											new MakeServerCall({
												errorMessage: 'Could not advance to fixnode 2',
												directCall: (_http, _store, _sq, _f, _cntrllr: ManifestController<any>) => {
													_cntrllr.dispatch(new SetNextNode('PeripheralFailAddPeripherals'));
													return of({});
												}
											})
										);
									}
								}
								return response;
							})
						)
						.subscribe();
				}
			},
			navs: []
		},
		PeripheralFailAcceptDecline: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Peripheral Device Declined',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: ' A Peripheral device has been added to the job which is not allowed for this type of installation.',
							headingConfig: {
								size: 'small',
								color: 'secondary'
							}
						}
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Either accept that no payment will happen or edit the list and resubmit.',
							headingConfig: {
								size: 'small',
								color: 'secondary'
							}
						}
					}
				]
			},
			navs: [
				{
					text: 'accept no payment',
					color: 'primary',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						accept_no_payment: {
							errorMessage: 'Could not submit acceptance',
							directCall: (_http: HttpClient, _store: Store, sq: any, _f: BigFormService) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const date = new Date();
											_f.addControl('payment_required', new UntypedFormControl({ payment_required: 0, timeStamp: convertDateTimeToTimeStamp(date.toDateString()) }, []));
											const { job_information, id } = job;
											const { payments_manually_closed } = job_information;
											const manually_closed_payments = [];
											if (!payments_manually_closed) {
												manually_closed_payments.push(_f.getControl('payment_required').value);
											} else if (payments_manually_closed) {
												if (Array.isArray(payments_manually_closed)) {
													manually_closed_payments.push(...payments_manually_closed, _f.getControl('payment_required').value);
												}
											}
											const job_info_json = JSON.parse(JSON.stringify(job_information));
											const new_job_information = addObjectProperty('payments_manually_closed', manually_closed_payments, job_info_json);

											const request_object = {
												job_id: id,
												new_state: 349,
												job_information: new_job_information
											};
											return request_object;
										}),
										switchMap(data => {
											// return of({});
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, data);
										})
									);
							}
						}
					}
				}
			]
		},
		PeripheralFailAddPeripherals: {
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('alt_next_node', new UntypedFormControl('FixProblem', []));
				_bf.bigForm.addControl('dishplate_aluminium', new UntypedFormControl(0));
				_bf.bigForm.addControl('dishplate_steel', new UntypedFormControl(0));
				_bf.bigForm.addControl('dishplate_fibre', new UntypedFormControl(0));
				_bf.bigForm.addControl('wificonnector', new UntypedFormControl(0));
				_bf.bigForm.addControl('rfmodulator', new UntypedFormControl(0));
				_bf.bigForm.addControl('diplexer', new UntypedFormControl(0));
			},
			hideTabItem: true,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Peripheral Devices Declined',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Peripheral devices have been added to the job which are not allowed for this type of installation.',
							headingConfig: {
								size: 'small',
								color: 'secondary'
							}
						}
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Either accept that no payment will happen or edit the list and resubmit.',
							headingConfig: {
								size: 'small',
								color: 'secondary'
							}
						}
					},
					{
						component: 'FLXAutopayAddEquipmentComponent',
						inputs: {}
					}
				]
			},
			navs: [
				{
					text: 'submit changes',
					color: 'secondary',
					optIntoValidation: true,
					serverFirst: true,
					nextNode: 'FixSubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: `Decoder claim issue could not be updated`,
							directCall: (_http: HttpClient, _store: Store, sq, bf: BigFormService) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile((data: any) => !data),
										take(1),
										map(data => data)
									)
									.pipe(
										switchMap((result: any) => {
											// equipment payload

											const equipmentNames = [
												{
													category: 'adhoc',
													id: 'dishplate_aluminium',
													item: 'Dishplate / Aluminium',
													serial: null
												},
												{
													category: 'adhoc',
													id: 'dishplate_steel',
													item: 'Dish Plate / Steel',
													serial: null
												},
												{
													category: 'adhoc',
													id: 'dishplate_fibre',
													item: 'Dishplate / Fibre',
													serial: null
												},
												{
													category: 'adhoc',
													id: 'wificonnector',
													item: 'Wi-Fi Connector',
													serial: null
												},
												{
													category: 'adhoc',
													id: 'rfmodulator',
													item: 'RF Modulator',
													serial: null
												},
												{
													category: 'adhoc',
													id: 'diplexer',
													item: 'Diplexer',
													serial: null
												}
											];

											const equipments = equipmentNames.map((equipment: any) => {
												equipment.qty = bf.getControl(equipment.id).value;
												return equipment;
											});

											// Create the equipment data structure
											const equipmentData = {
												job_id: result?.id,
												installed_items: equipments
											};

											return _http.post(`${environment.api_url}v1/mc_actions/edit_hardware/`, equipmentData);
										})
									);
							}

							/**
						 *
						 * LEFTOVER FROM THE INITIAL UNDERSTANDING OF LIST DECLINE ERRORS AND 'ONE-TIME-FIX-ALL' SOLUTION
						 	followUpSuccessCalls: {
								displaySucces: {
									errorMessage: 'Could not fix decoder number',
									directCall: (http, store, sq, bf, con, modal) => {
										bf.getControl('additional_equipment_invalid_fixed').setValue(true);

										return of({});
									}
								}
							},
							followUpFailCalls: {
								displayFail: {
									errorMessage: 'Error fixing decoder number',
									directCall: (http, store, sq, bf, con, modal) => {
										bf.getControl('additional_equipment_invalid_fixed').setValue(false);
										con.dispatch(new SetPreviousNode());
										return of(false);
									}
								}
							}
						 */
						}
					}
					// nextNode: 'SubmissionSuccess'
				},
				{
					text: 'accept no payment',
					color: 'primary',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						accept_no_payment: {
							errorMessage: 'Could not submit acceptance',
							directCall: (_http: HttpClient, _store: Store, sq: any, _f: BigFormService) => {
								return _store
									.select(getFullItemTwo)
									.pipe(
										skipWhile(x => !x),
										take(1)
									)
									.pipe(
										map((job: any) => {
											const date = new Date();
											_f.addControl('payment_required', new UntypedFormControl({ payment_required: 0, timeStamp: convertDateTimeToTimeStamp(date.toDateString()) }, []));
											const { job_information, id } = job;
											const { payments_manually_closed } = job_information;
											const manually_closed_payments = [];
											if (!payments_manually_closed) {
												manually_closed_payments.push(_f.getControl('payment_required').value);
											} else if (payments_manually_closed) {
												if (Array.isArray(payments_manually_closed)) {
													manually_closed_payments.push(...payments_manually_closed, _f.getControl('payment_required').value);
												}
											}
											const job_info_json = JSON.parse(JSON.stringify(job_information));
											const new_job_information = addObjectProperty('payments_manually_closed', manually_closed_payments, job_info_json);

											const request_object = {
												job_id: id,
												new_state: 349,
												job_information: new_job_information
											};
											return request_object;
										}),
										switchMap(data => {
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, data);
										})
									);
							}
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: {},
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		validation_query_installer: [
			(query, store, bf) => {
				return query ? query : null;
			},
			'job_information.validation_query_installer'
		],
		payment_required: [],
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					const appointment_formatted = {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
					return appointment_formatted;
				}
			},
			'appointment'
		]
	}
};
