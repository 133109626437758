export * from './lib/ui-elements.module';
import { FLXKeyValueListComponent } from './lib/key-value-display';
import { FLXFileViewerComponent } from './lib/files/file-viewer/file-viewer.component';
import { FLXButtonComponent } from './lib/buttons';
import { FLXTeamleadPickerComponent } from './lib/teamlead-picker';

export * from './lib/buttons';
export * from './lib/datepicker';
export * from './lib/glow-line';
export * from './lib/headings';
export * from './lib/inline-icons';
export * from './lib/labels';
export * from './lib/layouts';
export * from './lib/list';
export * from './lib/loader';
export * from './lib/loading-spinner';
export * from './lib/loading-spinner-inline';
export * from './lib/panels';
export * from './lib/progress';
export * from './lib/select-list';
export * from './lib/forms/textarea';
export * from './lib/key-value-display';
export * from './lib/forms';
export * from './lib/reactive-form-error-display';
export * from './lib/location-picker';
export * from './lib/alerts';
export * from './lib/popup-overlay-picker';
export * from './lib/tabs';
export * from './lib/context-menu';
export * from './lib/key-value-display';
export * from './lib/file-handler';
export * from './lib/success-tick';
export * from './lib/timepicker';
export * from './lib/indicator';
export * from './lib/nested-object-key-value-list';
export * from './lib/tables';
export * from './lib/modals';
export * from './lib/files';
export * from './lib/notes';
export * from './lib/cards';
export * from './lib/glow-line';
export * from './lib/account-lockout';
export * from './lib/teamlead-picker';
export * from './lib/call-log';
export * from './lib/circle-indicator';
export * from './lib/yes-no';
export * from './lib/glow-line';
export * from './lib/teamlead-picker';

//TODO: move these to ui-composite when done
export const uiCompMapper = {
	FLXKeyValueListComponent,
	FLXFileViewerComponent,
	FLXButtonComponent,
	FLXTeamleadPickerComponent
};
