import { Flow_0_0_2 } from '@flexus/core';

import { of } from 'rxjs';
import { setActionPanelItems } from '../../../../../app-shell-features';

export const SIL_ADD_REMINDER: Flow_0_0_2 = {
	id: 'SIL_ADD_REMINDER',
	itemType: 'flow',
	name: 'Add Reminder To This Claim',
	actionPanel: instance => {
		instance?.toggleActionPanel();
		instance?.onClickReminders();

		setTimeout(() => {
			instance?.dynamicLoaderService?.component?.instance?.addRem?.open(true);
		}, 0);
		return setActionPanelItems(instance, []);
	},
	activateStateGuard: () => of(false)
};
