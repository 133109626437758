import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BigFormService, MakeServerCall, getData, SetNextNode, ManifestController, getLoadingIDsState, getLoadedIDsState } from '@flexus/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { addObjectProperty, cleanUpSub } from '@flexus/utilities';

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'flx-policy-lookup',
	templateUrl: './policy-lookup.component.html',
	styleUrls: ['./policy-lookup.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: []
})
export class PolicyLookupComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() lookupEndpoint: any;
	@Input() historyLookupEndpoint: any;
	@Input() policies$: Observable<any>;
	@Input() validPolicyStates: string[];
	@Input() silcodes: Array<string>;
	@Input() stansurecodes: Array<string>;
	@Input() claimHistory$: any;
	@Input() commercialcodes: Array<string>;
	additionalExcessFlag: number;
	selectedPolicy: number;
	selectedPolicyData: any;
	isLoading$: Observable<boolean>;
	isLoaded$: Observable<boolean>;
	currentPolicyDetails;
	claimHistorySub: Subscription;
	claimHistory;
	valid: number;
	claim;
	hasDoneLookup = false;
	policies: any[] = [];
	errorMessage = 'No policies found';
	policySub: Subscription;
	private _isLoading = false;

	// =================Constructor =======================
	constructor(public _store: Store<any>, public bf: BigFormService, private controller: ManifestController<any>) {}

	lookup() {
		this.bf.bigForm.patchValue({
			insured_address: {},
			suburb_code: null,
			area_code: null,
			province: null,
			latitude: null,
			logitude: null
		});

		if (window.navigator.onLine && !this._isLoading) {
			this.policies = [];
			this._store.dispatch(
				new MakeServerCall({
					...this.lookupEndpoint,
					data: { search: this.bf.bigForm.value.policy_lookup },
					loaderID: 'policy_lookup'
				})
			);
			this.bf.patchValues({ additionalexcessflag: 1 });
			this.hasDoneLookup = true;
		}
	}
	// ----------------- Life-cycle methods -------------

	ngOnInit() {
		this.isLoading$ = this._store.select(getLoadingIDsState).pipe(map(isLoadingIDs => (this._isLoading = isLoadingIDs.includes('policy_lookup'))));
		this.isLoaded$ = this._store.select(getLoadedIDsState).pipe(map(isLoadedIDs => isLoadedIDs.includes('policy_lookup')));
		this.policySub = this._store
			.select(getData)
			.pipe(map(data => data['policies']))
			.subscribe((response: any) => {
				if (!!response) {
					if (response.success) {
						this.policies = response.payload.policies;
						if (!!this.policies === false) {
							this.errorMessage = 'No policies found';
							this.policies = [];
						}
					} else {
						if (response.payload.reason?.toLowerCase()?.includes('esb') || response.payload?.reason?.includes('System.Web.Services.Protocols.SoapException')) {
							this.errorMessage = 'Error contacting Maven server';
						} else {
							this.errorMessage = 'Error running policy look up.';
						}
						this.policies = [];
					}
				}
			});
		// this.setHandlerDetails();
		if (!this.bf.bigForm.get('previousPolicyDetails')) {
			this.bf.addControl('previousPolicyDetails', new UntypedFormGroup({ ACCOUNTNUMBER: new UntypedFormControl(null) }));
		}
	}

	selectPolicy(chosenPolicyIndex: number) {
		this.policies$
			.pipe(
				map(val => val.payload.policies),
				take(1)
			)
			.subscribe(detailsArray => {
				this.currentPolicyDetails = detailsArray[chosenPolicyIndex];
				this.bf.patchValues({ currentPolicyDetails: this.currentPolicyDetails });
				if (!this.validPolicyStates.includes(this.currentPolicyDetails.POLICYNUMBER.slice(0, 3))) {
					alert('This does not appear to be a policy that is handled by this system');
					this.valid = 0;
					return;
				} else {
					this.valid = 1;
				}
				if (this.currentPolicyDetails.ITEMCATEGORY !== 'Building') {
					alert('This does not appear to be a policy type supported by 4-SURE');
					return;
				}
				if (this.silcodes.includes(this.currentPolicyDetails.POLICYNUMBER.slice(0, 3))) {
					this.currentPolicyDetails = addObjectProperty('UNDERWRITERCODE', 'SIL', this.currentPolicyDetails);
					if (this.currentPolicyDetails.CANCLAIM === 'N') {
						alert('This policy is flagged as unclaimable');
						return;
					}
				}
				if (this.stansurecodes.includes(this.currentPolicyDetails.POLICYNUMBER.slice(0, 3))) {
					this.currentPolicyDetails = addObjectProperty('UNDERWRITERCODE', 'StanSure', this.currentPolicyDetails);
				}
				if (this.commercialcodes.includes(this.currentPolicyDetails.POLICYNUMBER.slice(0, 3))) {
					this.currentPolicyDetails = addObjectProperty('UNDERWRITERCODE', 'Commercial', this.currentPolicyDetails);
				}
				if (window.navigator.onLine) {
					this._store.dispatch(
						new MakeServerCall({
							...this.historyLookupEndpoint,
							data: { search: this.currentPolicyDetails.POLICYNUMBER }
						})
					);
				}

				if (this.currentPolicyDetails.MARKETSEGMENTATIONREF_KEY !== '') {
					this.bf.patchValues({ privatebankingflag: 1 });
				}
			});
		this.claimHistory$.pipe(take(1)).subscribe(claim => {
			this.claimHistory = claim;
			this.bf.patchValues({ historyfetchedflag: 1 });
		});
		this.controller.dispatch(new SetNextNode('PolicyDetails'));
	}

	// setHandlerDetails() {
	//   this._store
	//     .select(getCurrentUser)
	//     .pipe(take(1))
	//     .subscribe(user => {
	//       let { full_name, email_address, contact_number } = user.staff_member;
	//       let isSilAgent = true;
	//       if (user.staff_member.roles.includes(18) && user.staff_member.roles.length === 1) {
	//         console.log('After hours agent');
	//         full_name = null;
	//         contact_number = null;
	//         email_address = null;
	//         isSilAgent = false;
	//       }
	//       this.bf.bigForm.addControl('sil_agent', new FormControl(isSilAgent));
	//       this.bf.bigForm.addControl('handler', new FormControl(full_name));
	//       this.bf.bigForm.addControl('handlercontact', new FormControl(contact_number));
	//       this.bf.bigForm.addControl('handleremail', new FormControl(email_address));
	//     });
	// }

	ngOnDestroy() {
		//TODO: Delete the commented out code below in future version. Current version is: 5.25
		//      Should be safe to delete at version 5.30
		// Do this to be able to keep things in sync when user edits fields
		// if (!this.currentPolicyDetails) {
		//   // this._store.dispatch(new MakeServerCall({ ...this.lookupEndpoint, data: { search: this.bf.bigForm.value.policy_lookup } }));
		//   if (window.navigator.onLine && this.bf.bigForm.value.policy_lookup !== '') {
		//     this._store.dispatch(
		//       new MakeServerCall({ ...this.historyLookupEndpoint, data: { search: this.bf.bigForm.value.policy_lookup } })
		//     );
		//   }
		// }

		// if (window.navigator.onLine && this.bf.bigForm.value.policy_lookup !== '') {
		// 	this._store
		// 		.select(state => state.selectedContext)
		// 		.pipe(
		// 			take(1),
		// 			map(ctx => hasPath(['claimHistory'], ctx))
		// 		)
		// 		.subscribe(historyExists => {
		// 			if (!historyExists) {
		// 				this._store.dispatch(
		// 					new MakeServerCall({
		// 						...this.historyLookupEndpoint,
		// 						data: { search: this.bf.bigForm.value.policy_lookup }
		// 					})
		// 				);
		// 			}
		// 		});
		// }

		if (this.claimHistorySub) this.claimHistorySub.unsubscribe();
		cleanUpSub(this.policySub);
	}

	isValidPolicy(policy: any) {
		const canClaim = policy.CANCLAIM?.toLowerCase();
		const itemCategory = policy.ITEMCATEGORY?.toLowerCase();

		return canClaim === 'y' && itemCategory === 'building';
	}

	checkSumInsuredValue(value: any) {
		if (isNaN(value)) {
			return 0;
		} else {
			return value;
		}
	}
}
