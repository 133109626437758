import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { BigFormService } from './big-form.service';

@Injectable({ providedIn: 'root' })
export class ModalService {
	modalActions = new BehaviorSubject<Function>(null);

	openModalDirectly(func: (instance, store?: Store<any>, bf?: BigFormService) => void) {
		this.modalActions.next(func);
	}
}
