import { Injectable, Inject } from '@angular/core';
import { Converters } from '../storage-utilities/converters';
import { IndexedDbService } from './indexed-db.service';
import { DataDestination } from '../storage-utilities/data-destination';
import { CryptoUtil } from '../storage-utilities/crypto-util';
import { cryptoBlobKey } from './constants';
import stringify from 'json-stringify-safe';
import { interval, BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CryptoService {
	private store: BehaviorSubject<any>;
	constructor(private db: IndexedDbService, @Inject(cryptoBlobKey) private crytoBlobKey: string) {
		this.store = new BehaviorSubject<any>(null);
	}

	/** Create CryptoKey in a blob object and store in indexedDB
	 * @param {Object} credentialsObject An object of the username and password of user.
	 *
	 * @return {void}
	 */
	createKeyAsBlobAndStore(credentialsObject: any): void {
		const iterations = 1000000; // Longer is slower... hence stronger
		const saltString = 'This is my salt. I need more pepper and tomatoes to spice up..';
		const saltBytes = Converters.stringToByteArray(saltString);

		// Get byteArray of credentials, IV, and Hash
		const credentialsString = JSON.stringify(credentialsObject);
		const credentialBytes = Converters.stringToByteArray(credentialsString);
		const ivBytes = <Uint8Array>window.crypto.getRandomValues(new Uint8Array(16));

		CryptoUtil.createPBKDF2BaseKey(credentialBytes)
			.then(baseKey => CryptoUtil.deriveAESCBCKeyFromBaseKey(baseKey, saltBytes, iterations))
			.then(aesCbcKey => CryptoUtil.exportKeyToArrayBuffer(aesCbcKey))
			.then(keyBuffer => this.hashAndStore(this.db, keyBuffer, credentialsObject, ivBytes));
	}

	/** Encrypt data with AESCBC CryptoKey
	 * @param {string} keyId ID of the CryptoKey Blob stored in indexedDB
	 * @param {object} object The javascript object to be encrypted
	 */
	encryptWithAESCBCKey(keyId: string, object: any) {
		const objectString = JSON.stringify(object);
		const objectBytes = Converters.stringToByteArray(objectString);

		// using IndexedDb
		return this.getKeyFromIndexedDb(keyId).then(keyBlobObject => {
			this.readBlobContentAndEncryptToStorage(keyBlobObject, objectBytes, object.id);
		});
	}

	/** Decrypt data with AES-CBC CryptoKey
	 * @param {string} keyId ID of the CryptoKey Blob stored in indexedDB
	 * @param { number} objectId Id of the cipher to retrieve and decrypt
	 */
	decryptWithAESCBCKey(keyId: string, objectId: string) {
		return this.getClaimCipherFromIndexedDb(objectId).then(cipherBytes => {
			// using IndexedDb
			return this.getKeyFromIndexedDb(keyId).then(keyBlobObject => {
				this.readBlobContentAndDecryptFromStorage(keyBlobObject, cipherBytes);
			});
		});
	}

	/** Encrypt data with AESCBC CryptoKey
	 * @param {string} keyId ID of the CryptoKey Blob stored in indexedDB
	 * @param {object} object The javascript object to be encrypted
	 */
	encryptStateStore(keyId: string, object: any) {
		const objectString = stringify(object);
		if (objectString) {
			const objectBytes = Converters.stringToByteArray(objectString);
			// using IndexedDb
			this.getKeyFromIndexedDb(keyId).then(keyBlobObject => {
				// create reader to read blob contents
				const reader = new FileReader();
				reader.onload = () => {
					const ivBytes = new Uint8Array(<ArrayBuffer>reader.result.slice(0, 16));
					const keyBytes = new Uint8Array(<ArrayBuffer>reader.result.slice(16, 32));

					this.importKeyFromBlobForEncryption(keyBytes)
						.then(key => CryptoUtil.encryptWithKeyFromBlob(key, ivBytes, objectBytes))
						.then(cipherBuffer => {
							// Encode cipherBuffer to base64 to be put in IndexedDB
							const cipherBytes = new Uint8Array(cipherBuffer);
							const base64Ciphertext = Converters.byteArrayToBase64(cipherBytes);

							// TO FINISH
							this.db.data_store.put({ id: 'store', cipher: base64Ciphertext });
						});
				};

				// ultimately we read from the blob
				if (keyBlobObject) {
					reader.readAsArrayBuffer(keyBlobObject.blob);
				}
			});
		}
	}

	// HELPER FUNCTIONS
	private hashAndStore(indexedDb: IndexedDbService, keyBuffer: ArrayBuffer, credentialObject: any, ivBytes: Uint8Array) {
		const keybytes = new Uint8Array(keyBuffer, 0, 16);
		CryptoUtil.hashCredentialsToArrayBuffer(credentialObject).then(credentialsBuffer => {
			const blob = CryptoUtil.createBlobContainer(credentialsBuffer, ivBytes, keybytes);

			// can swap to any other storage mechanism
			this.storeBlobInIndexedDB(indexedDb, blob);
		});
	}

	private storeBlobInIndexedDB(indexedDb: any, blob: Blob) {
		// Store keys blob in indexedDB
		indexedDb.keys.put({ id: this.crytoBlobKey, blob: blob });
	}

	private getKeyFromIndexedDb(keyId: string): Promise<{ id: string; blob: Blob }> {
		return this.db.keys.get(keyId);
	}

	private readBlobContentAndEncryptToStorage(keyBlobObject: { id: string; blob: Blob }, objectToEncryptBytes: any, objectId: number) {
		// create reader to read blob contents
		const reader = new FileReader();
		reader.onload = () => {
			const ivBytes = new Uint8Array(<ArrayBuffer>reader.result?.slice(0, 16));
			const keyBytes = new Uint8Array(<ArrayBuffer>reader.result?.slice(16, 32));

			this.importKeyFromBlobForEncryption(keyBytes)
				.then(key => CryptoUtil.encryptWithKeyFromBlob(key, ivBytes, objectToEncryptBytes))
				.then(cipherBuffer => this.storeCipherInIndexedDB(this.db, cipherBuffer, objectId));
		};

		// ultimately we read from the blob
		reader.readAsArrayBuffer(keyBlobObject.blob);
	}

	private readBlobContentAndDecryptFromStorage(keyBlobObject: { id: string; blob: Blob }, cipherBytes: Uint8Array) {
		// console.log('Here in decrypt');
		// create reader to read blob contents
		const reader = new FileReader();
		reader.onload = () => {
			const ivBytes = new Uint8Array(<ArrayBuffer>reader.result?.slice(0, 16));
			const keyBytes = new Uint8Array(<ArrayBuffer>reader.result?.slice(16, 32));

			this.importKeyFromBlobForDecryption(keyBytes)
				.then(key => CryptoUtil.decryptWithKeyFromBlob(key, ivBytes, cipherBytes))
				.then(cipherBuffer => {
					const result = <{ data: any }>{};
					this.getDecryptedData(cipherBuffer, result);
					this.showDecryptedData(result?.data, DataDestination.store);
				});
		};

		// ultimately we read from the blob
		reader.readAsArrayBuffer(keyBlobObject.blob);
	}

	// Restricting key usage to just Encrypting
	private importKeyFromBlobForEncryption(keyBytes: Uint8Array) {
		// Make a CryptoKey from the Key bytes
		return window.crypto.subtle.importKey('raw', keyBytes, { name: 'AES-CBC', length: 256 }, false, ['encrypt']);
	}

	// Restricting key usage to just decryption
	private importKeyFromBlobForDecryption(keyBytes: Uint8Array) {
		// Make a CryptoKey from the Key bytes
		return window.crypto.subtle.importKey('raw', keyBytes, { name: 'AES-CBC', length: 256 }, false, ['decrypt']);
	}

	private storeCipherInIndexedDB(indexedDb: IndexedDbService, cipherBuffer: ArrayBuffer, objectId: number) {
		// Encode cipherBuffer to base64 to be put in IndexedDB
		const cipherBytes = new Uint8Array(cipherBuffer);
		const base64Ciphertext = Converters.byteArrayToBase64(cipherBytes);

		indexedDb.claims.put({ id: objectId, cipher: base64Ciphertext });
	}

	private getDecryptedData(cipherBuffer: ArrayBuffer, result: any) {
		const plaintextBytes = new Uint8Array(cipherBuffer);
		const plaintextString = Converters.byteArrayToString(plaintextBytes);
		const data = JSON.parse(plaintextString);
		result.data = data;
	}

	private getClaimCipherFromIndexedDb(id: string) {
		return this.db.data_store.get(id).then(obj => {
			const cipherBytes = Converters.base64ToByteArray(obj.cipher);
			return cipherBytes;
		});
	}

	private showDecryptedData(data: any, dest: DataDestination) {
		switch (dest) {
			case DataDestination.console:
				break;

			case DataDestination.localStorage:
				localStorage['store'] = JSON.stringify(data);
				break;

			case DataDestination.store: {
				this.setStore(data);
				break;
			}

			default:
				break;
		}
	}

	private clearKeyBlob() {
		this.db.keys.clear();
	}

	setStore(data) {
		this.store.next(data);
	}

	getStore() {
		return interval(10).pipe(
			map(() => this.store.value),
			distinctUntilChanged()
		);
	}
}
