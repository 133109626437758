import { Action } from '@ngrx/store';
import { ServerCall_0_0_2 } from '../manifest-versions';

export const MAKE_SERVER_CALL = '[Context] MAKE SERVER CALL';
export const MAKE_SERVER_CALL_SUCCESS = '[Context] MAKE SERVER CALL SUCCESS';
export const MAKE_SERVER_CALL_FAIL = '[Context] MAKE SERVER CALL FAIL';
export const MAKE_SERVER_CALL_WITH_LOADER = '[CONTEXT] MAKE SERVER CALL WITH LOADER';
export const MAKE_SERVER_CALL_WITHOUT_LOADER = '[CONTEXT] MAKE SERVER CALL WITHOUT LOADER';

export const WRITE_DATA_TO_STORE = '[Context] WRITE DATA TO STORE';

export const UPDATE_INFLOW_WITH_FORM_DATA = '[Context] UPDATE INFLOW WITH FORM DATA';
export const INITIALIZE_TEMP_DATA = '[Context] INITIALIZE TEMP DATA';

export const GET_FULL_ITEM_ONE = '[Context] Get Full Item One';
export const GET_FULL_ITEM_ONE_SUCCESS = '[Context] Get Full Item One Success';
export const GET_FULL_ITEM_ONE_FAIL = '[Context] Get Full Item One Fail';

export const GET_FULL_ITEM_ONE_WITH_FULL_ITEM_TWOS = '[Context] Get Full Item One With Item Twos';
export const GET_FULL_ITEM_ONE_WITH_FULL_ITEM_TWOS_SUCCESS = '[Context] Get Full Item One With Item Twos Success';
export const GET_FULL_ITEM_ONE_WITH_FULL_ITEM_TWOS_FAIL = '[Context] Get Full Item One With Item Twos Fail';

export const GET_FULL_ITEM_TWO = '[Context] Get Full Item Two';
export const GET_FULL_ITEM_TWO_SUCCESS = '[Context] Get Full Item Two Success';
export const GET_FULL_ITEM_TWO_FAIL = '[Context] Get Full Item Two Fail';

export const SELECT_ITEM_ONE = '[Context] Select Item One';
export const SELECT_ITEM_ONE_SUCCESS = '[Context] Select Item One Success';

export const SELECT_ITEM_TWO = '[Context] Select Item Two';
export const SELECT_ITEM_TWO_SUCCESS = '[Context] Select Item Two Success';

export const SET_RELATED_ITEMS = '[Context] Set Related Items';

//
export const MANUALLY_SET_MOCK_CONTEXT_DATA = '[Context] Manually Set Mock Context Data';
// export const MANUALLY_ADD_TO_CONTEXT_DATA = '[Context] Manually Add To Context Data';

export const ADD_TO_WORK_TIMELINE = '[Context] Add To Work Timeline';
export const ADD_TO_WORK_TIMELINE_SUCCESS = '[Context] Add To Work Timeline Success';
export const ADD_TO_WORK_TIMELINE_FAIL = '[Context] Add To Work Timeline Fail';

export const FETCH_TEAM_LEADER_LOCATIONS = '[Context] Fetch Team Leader Locations';

export class MakeServerCall implements Action {
	readonly type = MAKE_SERVER_CALL;
	constructor(public payload: ServerCall_0_0_2) {}
}

export class MakeServerCallFail implements Action {
	readonly type = MAKE_SERVER_CALL_FAIL;
	constructor(
		public payload: {
			followUpFailCalls?: { [id: string]: ServerCall_0_0_2 };
			dataKey: string;
			error: any;
			errorMessage: string;
			retryCall: any;
			data?: any;
			loaderID?: string;
		}
	) {}
}

export class MakeServerCallWithoutLoader implements Action {
	readonly type = MAKE_SERVER_CALL_WITHOUT_LOADER;
	constructor(public payload: ServerCall_0_0_2) {}
}

export class MakeServerCallSuccess implements Action {
	readonly type = MAKE_SERVER_CALL_SUCCESS;
	constructor(
		public payload: {
			followUpSuccessCalls?: { [id: string]: ServerCall_0_0_2 };
			nextNode?: string;
			dataKey: string;
			result: any;
			loaderID?: string;
		}
	) {}
}

export class MakeServerCallWithLoader implements Action {
	readonly type = MAKE_SERVER_CALL_WITH_LOADER;
	constructor(public payload: ServerCall_0_0_2) {}
}

export class WriteDataToStore implements Action {
	readonly type = WRITE_DATA_TO_STORE;
	constructor(public payload: any) {}
}

export class UpdateInflowWithFormData implements Action {
	readonly type = UPDATE_INFLOW_WITH_FORM_DATA;
	constructor(public payload: { storeKey: string; data: any }) {}
}

export class InitializeTempData implements Action {
	readonly type = INITIALIZE_TEMP_DATA;
}

export class GetFullItemOne implements Action {
	readonly type = GET_FULL_ITEM_ONE;

	constructor(public payload: { id: number }) {}
}

export class GetFullItemOneSuccess implements Action {
	readonly type = GET_FULL_ITEM_ONE_SUCCESS;

	constructor(public payload: { fullItemOne: any }) {}
}

export class GetFullItemOneFail implements Action {
	readonly type = GET_FULL_ITEM_ONE_FAIL;

	constructor(public payload: { key: string; error: any }) {}
}

export class GetFullItemOneWithFullItemTwos implements Action {
	readonly type = GET_FULL_ITEM_ONE_WITH_FULL_ITEM_TWOS;

	constructor(public payload: { oneId: number; twoId?: number }) {}
}

export class GetFullItemOneWithFullItemTwosSuccess implements Action {
	readonly type = GET_FULL_ITEM_ONE_WITH_FULL_ITEM_TWOS_SUCCESS;

	constructor(public payload: { fullItemOne: any }) {}
}

export class GetFullItemOneWithFullItemTwosFail implements Action {
	readonly type = GET_FULL_ITEM_ONE_WITH_FULL_ITEM_TWOS_FAIL;

	constructor(public payload: { key: string; error: any }) {}
}

export class SelectItemOne implements Action {
	readonly type = SELECT_ITEM_ONE;
	constructor(public payload: { itemOne: any }) {}
}

export class SelectItemOneSuccess implements Action {
	readonly type = SELECT_ITEM_ONE_SUCCESS;
	constructor(public payload: { selectedItemOne: any }) {}
}

export class SelectItemTwo implements Action {
	readonly type = SELECT_ITEM_TWO;

	constructor(
		public payload: {
			itemTwo: any;
			itemOne?: any;
			setRelated?: boolean;
		}
	) {}
}

export class SelectItemTwoSuccess implements Action {
	readonly type = SELECT_ITEM_TWO_SUCCESS;

	constructor(public payload: any) {}
}

export class GetFullItemTwo implements Action {
	readonly type = GET_FULL_ITEM_TWO;

	constructor(public payload: { id: number }) {}
}

export class GetFullItemTwoSuccess implements Action {
	readonly type = GET_FULL_ITEM_TWO_SUCCESS;

	constructor(public payload: { fullItemTwo: any }) {}
}

export class GetFullItemTwoFail implements Action {
	readonly type = GET_FULL_ITEM_TWO_FAIL;

	constructor(public payload: { key: string; error: any }) {}
}

export class SetRelatedItems implements Action {
	readonly type = SET_RELATED_ITEMS;

	constructor(public payload: { relatedItemTwos: any }) {}
}

export class ManuallySetMockContextData implements Action {
	readonly type = MANUALLY_SET_MOCK_CONTEXT_DATA;
	constructor(public payload: any) {}
}

export class AddToWorkTimeline implements Action {
	readonly type = ADD_TO_WORK_TIMELINE;
	constructor(public payload: any) {}
}

export class AddToWorkTimelineSuccess implements Action {
	readonly type = ADD_TO_WORK_TIMELINE_SUCCESS;
	constructor(public payload: any) {}
}

export class AddToWorkTimelineFail implements Action {
	readonly type = ADD_TO_WORK_TIMELINE_FAIL;
	constructor(public payload: any) {}
}

export class FetchTeamLeaderLocations implements Action {
	readonly type = FETCH_TEAM_LEADER_LOCATIONS;
	constructor(public payload: any) {}
}

// export class ManuallyAddToContextData implements Action {
//   readonly type = MANUALLY_ADD_TO_CONTEXT_DATA;
//   constructor(public payload: any) {}
// }

export type selectedContextActions =
	| MakeServerCall
	| MakeServerCallSuccess
	| MakeServerCallFail
	| UpdateInflowWithFormData
	| InitializeTempData
	| GetFullItemOne
	| GetFullItemOneSuccess
	| GetFullItemOneFail
	| GetFullItemOneWithFullItemTwos
	| GetFullItemOneWithFullItemTwosSuccess
	| GetFullItemOneWithFullItemTwosFail
	| SelectItemOneSuccess
	| SelectItemTwoSuccess
	| GetFullItemTwo
	| GetFullItemTwoSuccess
	| GetFullItemTwoFail
	| SelectItemOne
	| SelectItemTwo
	| ManuallySetMockContextData
	| WriteDataToStore
	| AddToWorkTimeline
	| AddToWorkTimelineSuccess
	| AddToWorkTimelineFail
	| FetchTeamLeaderLocations;
