import { createFeatureSelector, createSelector } from '@ngrx/store';

const SelectedContextState = createFeatureSelector('selectedContext');

export const getData = createSelector(SelectedContextState, (state: any) => state && state);

// export const getContextualData = createSelector(
//   getContextState,
//   (state: any) => state && state.contextualData,
// );

export const getSelectedContextEngine = (slice: string) => createSelector(SelectedContextState, (state: any) => state[slice] ?? undefined);

export const getSubmissionData = createSelector(SelectedContextState, (state: any) => state && state.submissionData);

export const getSelectedItem = createSelector(SelectedContextState, (state: any) => state && state.selectedItem);

export const getSelectedItemOne = createSelector(SelectedContextState, (state: any) => state && state.selectedItemOne);

export const getSelectedItemTwo = createSelector(SelectedContextState, (state: any) => state && state.selectedItemTwo);

export const getFullItemOne = createSelector(SelectedContextState, (state: any) => state && state.fullItemOne);

export const getFullItemTwo = createSelector(SelectedContextState, (state: any) => state && state.fullItemTwo);

export const getRelatedItemTwos = createSelector(SelectedContextState, (state: any) => state && state.relatedItemTwos);

export const getContextErrors = createSelector(SelectedContextState, (state: any) => state && state.errors);

export const getContextInvoiceData = createSelector(getData, (state: any) => state && state.invoice);

export const getCloseClaim = createSelector(SelectedContextState, (state: any) => state && state.closeClaim);

export const getCloseClaimSuccess = createSelector(getCloseClaim, (state: any) => state && state.claimInfo);
export const getCloseClaimFail = createSelector(getCloseClaim, (state: any) => state && state.claimError);

///
export const getContextData = createSelector(SelectedContextState, (state: any, props) => state && state[props.key]);

export const getOutflows = createSelector(SelectedContextState, (state: any) => state.outflow);

export const getCurrentFormValidState = createSelector(SelectedContextState, (state: any) => state.currentFormValidState);

export const getFetchedTeamLeaderLocations = createSelector(SelectedContextState, (state: any) => state && state.teamleaderLocations);

///
