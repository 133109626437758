import { Flow_0_0_2, GetSPUsersList, getUserList } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import { empty, forkJoin } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';

export const ManageSPUsers: Flow_0_0_2 = {
	id: 'manage_users',
	name: 'manage users',
	startNode: 'ManageUsers',
	onStateInit: instance => {
		instance.store.dispatch(new CollapseActionPanel());
		instance.store.dispatch(new GetSPUsersList());
	},
	onStateDestroy: instance => instance.store.dispatch(new CollapseActionPanel()),
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, []),
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	header: {
		title: 'User Management',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		userData: {
			errorMessage: 'SP User data not returned',
			directCall: (http, store, sq, bf) => {
				return forkJoin([
					store.select(getUserList).pipe(
						skipWhile(x => !x),
						take(1),
						map(users => {
							console.log('manifest users', users);
							return users;
						})
					)
				]);
			}
		}
	},
	events: {},
	nodes: {
		ManageUsers: {
			name: 'Manage Users',
			showTabs: false,
			errorHandler: {
				retryPolicy: 'manual',
				displayFormat: 'none',
				onRetryComplete: () => {
					return empty();
				}
			},
			initFormFields: () => {},
			hasLoader: true,
			component: {
				children: [
					{
						component: 'FLXManageSPUsersComponent',
						inputs: {
							spUsers$: 'userData'
						}
					}
				]
			}
		},
		EditUser: {
			name: 'Edit User',
			showTabs: false,
			errorHandler: {
				retryPolicy: 'manual',
				displayFormat: 'none',
				onRetryComplete: () => {
					return empty();
				}
			},
			initFormFields: () => {},
			hasLoader: true,
			component: 'FLXManageSPUsersEditComponent',

			navs: []
		},
		PasswordSubmissionSuccess: {
			name: '',
			showTabs: false,
			component: 'FLXSuccessTickComponent',
			inputs: {
				autoClose: false,
				heading: 'Continue managing your team leader profiles or return to the workflow.'
			}
		},
		ImageUploadSuccess: {
			name: '',
			showTabs: false,
			component: 'FLXSuccessTickComponent',
			inputs: {
				autoClose: false,
				heading: 'Please refresh browser and access this feature again to verify photo upload success.'
			}
		}
	},
	bigFormToStoreMapper: {}
};
