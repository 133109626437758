<div class="container">

	<div class="wrapper">
		<div class="status-this-month">
			<div class="heading-container">
				<flx-heading align="center" size="medium">Status this month</flx-heading>
			</div>
			<ng-container *ngIf="status_this_month | async as statusData; else noStatus">
				<div class="stats">
					<div class="column">
						<div class="label">Received</div>
						<div class="statistic-value">{{ statusData?.jobs_in_progress }}</div>
					</div>

					<div class="column">
						<div class="label">Submitted for payment</div>
						<div class="statistic-value">{{ statusData?.submitted_for_payment }}</div>
					</div>

					<div class="column">
						<div class="label">Payment so far this month</div>
						<div class="statistic-value">R{{ statusData?.payment_so_far_this_month }}</div>
					</div>

					<div class="column">
						<div class="label">Awaiting payment</div>
						<div class="statistic-value">R{{ statusData?.awaiting_payment }}</div>
					</div>

					<div class="column">
						<div class="label">My current score card</div>
						<div class="statistic-value">{{ statusData?.my_current_score_card }} out of 100</div>
					</div>
				</div>
			</ng-container>
			<ng-template #noStatus>
				<flx-heading align="center">No <b>status this month</b> data found</flx-heading>
			</ng-template>
		</div>

		<div class="workflow-status">
			<div class="heading-container">
				<flx-heading align="center" size="medium">Workflow Status</flx-heading>
			</div>
			<ng-container *ngIf="chartData?.graph_data && chartData.graph_data.length > 0; else noGraph">
				<div class="states-completion-graph">
					<flx-dynamic-chart [width]="'600px'" [height]="'300px'" [data]="barChartData"
						[options]="barChartOptions" [type]="barChartType"></flx-dynamic-chart>
				</div>
			</ng-container>
			<ng-template #noGraph>
				<flx-heading align="center">No graph data found</flx-heading>
			</ng-template>
		</div>
	</div>