<flx-flex-container>
	<flx-heading [size]="'large'" [align]="'center'" [weight]="'extra-light'">{{ title }}</flx-heading>
	<flx-heading [size]="'medium'" [align]="'center'" [weight]="'extra-light'">{{ subtitle }}</flx-heading>
	<flx-panel maxWidth="500px" alignItems="center" justifyContent="center" margin="auto">
		<div class="close-claim-info">
			<div class="row" *ngFor="let claimItem of summaryItems">
				<span>{{ claimItem?.title }}</span> <span>:</span>
				<span *ngIf="claimItem.isPositiveCheck; else displayValue" [ngClass]="claimItem?.isPositiveCheck ? 'positive' : 'negative'">{{
					claimItem?.isPositiveCheck ? 'Yes' : 'No'
				}}</span>
				<ng-template #displayValue>
					<span *ngIf="claimItem?.value; else controlValue">{{ claimItem?.value }}</span>
					<ng-template #controlValue>
						<form [formGroup]="bf.bigForm"><input type="text" [name]="claimItem?.formControlName" [formControlName]="claimItem?.formControlName" [disabled]="true" /></form>
					</ng-template>
				</ng-template>
			</div>
		</div>
	</flx-panel>
</flx-flex-container>
