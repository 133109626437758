import { HttpClient } from '@angular/common/http';
import { Flow_0_0_2, StoreQuery, BigFormService } from '@flexus/core';
import { KVLHeading } from '@flexus/ui-elements';
import { Store } from '@ngrx/store';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { map, skipWhile, take } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import * as XLSX from 'xlsx/xlsx';
import { CurrencyPipe as cp, CurrencyPipe } from '@angular/common';

export const PggGenerateExcel: Flow_0_0_2 = {
	id: 'generate_bulk_excel',
	name: 'generate bulk excel',
	startNode: 'BulkPayment',
	onStateInit: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['what-matters']),
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	header: {
		title: 'Generate Bulk Payment Excel Document',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		copyText: {
			errorMessage: '',
			directCall: () => {
				return of('false');
			}
		}
	},
	nodes: {
		BulkPayment: {
			name: 'Bulk Payment',
			showTabs: false,
			initFormFields: (_bf: any, item: any, _inst: any, sq: any, _store: any) => {
				_bf.bigForm.addControl('include_in_csv', new UntypedFormControl(null));
				_bf.bigForm.addControl('issue_list', new UntypedFormControl(null));
				_bf.bigForm.addControl('payment_errors', new UntypedFormControl(null));
				_bf.bigForm.addControl('has_issues', new UntypedFormControl(null, [Validators.requiredTrue]));
				// _bf.bigForm.addControl('has_payments', new FormControl(null, [Validators.requiredTrue]));
			},
			checkValidityForFields: ['has_issues'],
			serverCalls: {
				jobsToPay: {
					errorMessage: 'Not able to get complete bulk payments',
					directCall: (_http: HttpClient, _store: Store, sq: StoreQuery, _bf: BigFormService) => {
						return _http.post(`${environment.api_url}v1/job_action/get_payment_information`, {}).pipe(
							skipWhile((data: any) => !data),
							map((result: any) => {
								const { correct_payments } = result?.payload;
								const { payment_errors } = result?.payload;
								let issue_string: string = '';
								if (correct_payments.length == 0 && payment_errors.length == 0) {
									issue_string = 'No payments; no issues';
									_bf.patchValues({ issue_list: issue_string });
									_bf.patchValues({ has_issues: false });
								} else if (correct_payments.length == 0 && payment_errors.length > 0) {
									_bf.patchValues({ has_issues: true });
									for (let i = 0; i < payment_errors.length; i++) {
										let tempstring = `${payment_errors[i].job_ref} :  ${payment_errors[i].error},\n`;
										issue_string = issue_string.concat(tempstring);
									}
									_bf.patchValues({ issue_list: issue_string });
									_bf.patchValues({ payment_errors: payment_errors });
								} else if (correct_payments.length > 0 && payment_errors.length == 0) {
									issue_string = 'No payment issues';

									_bf.patchValues({ issue_list: issue_string });
									_bf.patchValues({ payment_errors: payment_errors });
									_bf.patchValues({ has_issues: false });
								} else if (correct_payments.length > 0 && payment_errors.length > 0) {
									_bf.patchValues({ has_issues: true });
									for (let i = 0; i < payment_errors.length; i++) {
										let tempstring = `${payment_errors[i].job_ref} :  ${payment_errors[i].error},\n`;
										issue_string = issue_string.concat(tempstring);
									}
									_bf.patchValues({ issue_list: issue_string });
									_bf.patchValues({ payment_errors: payment_errors });
								}
								let totalamount: number = 0;
								let totaljobs: number = 0;
								for (let i = 0; i < correct_payments.length; i++) {
									totalamount += correct_payments[i].Amount;
									totaljobs++;
								}

								const totalDisplayAmount = new CurrencyPipe('en_ZA').transform(totalamount, 'R');
								const jobpay_Obj = {
									'Jobs added to the Excel payment document': totaljobs,
									'Total payment amount': `${totalDisplayAmount}`
								};
								return [jobpay_Obj];
							})
						);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Bulk Payment'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Results', 'secondary'),
							data$: 'jobsToPay',
							color: 'secondary'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Here is a list of the issues found',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'issue_list',
									placeholder: 'There are no issues'
								}
							},
							formLayout: 'stacked',
							containerWidth: '30vw'
						}
					}
				]
			},
			navs: [
				{
					text: 're-generate bulk payment excel',
					color: 'default',
					nextNode: 'BulkPayment',
					location: 'center'
				},
				{
					text: 'download issue list',
					optIntoValidation: true,
					color: 'default',
					location: 'center',
					serverCalls: {
						response: {
							errorMessage: 'Could not download the issues',
							directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
								const { payment_errors } = _bf.bigForm.value;
								const worksheet = XLSX.utils.json_to_sheet(payment_errors);
								const workbook = XLSX.utils.book_new();
								const max_width = payment_errors.reduce((w, r) => Math.max(w, r.job_ref.length), 10);
								worksheet['!cols'] = [{ wch: max_width }];

								XLSX.utils.book_append_sheet(workbook, worksheet, 'Test');
								XLSX.utils.sheet_add_aoa(worksheet, [['Job Reference', 'Error on Payment']], { origin: 'A1' });
								XLSX.writeFile(workbook, 'Payment_Issues.xlsx');

								return of({});
							}
						}
					}
				},
				{
					text: 'view payments list',
					color: 'primary',
					// optIntoValidation: true,
					nextNode: 'PaymentsPreview'
				}
			]
		},
		PaymentsPreview: {
			checkValidityForFields: ['file_name_input', 'all_excluded'],
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('file_name_input', new UntypedFormControl('', [Validators.required]));
				_bf.addControl('all_excluded', new UntypedFormControl(true, [Validators.requiredTrue]));
			},
			serverCalls: {
				csvPayments: {
					errorMessage: `Couldn't compile the Excel table data`,
					directCall: (_http: HttpClient, _store: Store, sq: StoreQuery, _bf: BigFormService) => {
						return _http.post(`${environment.api_url}v1/job_action/get_payment_information`, {}).pipe(
							skipWhile((data: any) => !data),
							map((result: any) => {
								const { correct_payments } = result?.payload;
								if (Array.isArray(correct_payments) && correct_payments.length === 0) {
									return null;
								} else {
									return correct_payments;
								}
							})
						);
					}
				}
			},
			component: 'PinggoCSVPaymentComponent',
			inputs: {
				csvPayments: 'csvPayments',
				heading: 'Payments Preview',
				subheading: 'Proceed with Excel in Cells'
			},
			navs: [
				{
					text: 'download excel',
					color: 'primary',
					linkType: 'portal',
					optIntoValidation: true,
					// nextNode: 'PinggoCSVPaymentComponent',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any, _store: any, _bf: BigFormService, _http: HttpClient) => {
							instance.type = 'warning';
							instance.heading = 'Proceed with the downloaded Excel document?';
							instance.setMessage(['If you proceed:', '1. We ask that you save the file locally.', '2. All jobs in the Excel document will be moved to a new state']);
							instance.navButtons = [
								{ text: 'back', color: 'default', linkType: 'close' },
								{
									text: 'save file locally',
									color: 'default',
									linkType: 'nextNode',
									nextNode: 'FileApproval',
									clickHandler: () => {
										const filename = _bf.bigForm.get('file_name_input').value.replace(/ /g, '_');
										const { included_payments } = _bf.bigForm.value;
										return _http
											.post(
												`${environment.api_url}v1/job_action/get_payment_information`,
												{ as_csv: true, docType: 'xlsx', job_ids: included_payments.map(el => el.job_id) },
												{
													headers: { 'Content-Type': 'application/octet-stream' },
													responseType: 'arraybuffer'
												}
											)
											.pipe(take(1))
											.subscribe((response: any) => {
												const url = URL.createObjectURL(new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
												const link = document.createElement('a');
												link.href = url;
												link.download = filename.replace(/ /g, '_');
												document.body.appendChild(link);
												link.click();
												document.body.removeChild(link);
											});
									}
								}
							];
						}
					}
				}
			]
		},
		FileApproval: {
			// nodeType: 'decision',
			component: 'FakeModalComponent',

			navs: [
				{
					text: 'saved',
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			inputs: {
				autoClose: false,
				heading: 'Issues with submission at this point may lead to double payments.',
				headingcolour: 'alert',
				copyTextHeading: 'Contact Dev Team in case of problems'
			}
		}
	}
};
