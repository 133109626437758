import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FLXMultiFileUploadViewerComponent } from './multi-file-upload-viewer.component';
import { FLXHeadingsModule, FLXGlowLineModule, FLXFormsModule, FLXIconModule } from '@flexus/ui-elements';

@NgModule({
	declarations: [FLXMultiFileUploadViewerComponent],
	imports: [CommonModule, FLXHeadingsModule, FLXGlowLineModule, FLXFormsModule, FLXIconModule],
	exports: [FLXMultiFileUploadViewerComponent],
	// entryComponents: [FLXMultiFileUploadViewerComponent]
})
export class FLXMultiFileUploadViewerModule {}
