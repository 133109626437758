import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, Output } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BigFormService } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'dexie';
import { iLineItem } from '@flexus/models';

@Component({
	selector: 'flx-credit-note-line-item-modify',
	templateUrl: './line-item-modify.component.html',
	styleUrls: ['./line-item-modify.component.scss']
})
export class LineItemModifyComponent implements OnDestroy {
	public showDropDown = false;
	public isItemHover = false;
	public isItemActive = false;

	@Input() index: number;
	@Input() lineItemFormGroup!: UntypedFormGroup;
	@Input() availableLineItems: iLineItem[];
	@Input() icons: { src: string; alt: string }[] = [];
	@Input() allow_custom_items: boolean;
	@Input() actualLineItemsFormArray: FormArray;
	@Input() isNonVatSP: boolean = false;
	@Output() removeItem = new EventEmitter();
	@Output() addItem = new EventEmitter();
	@Output() startCreateCustomItem = new EventEmitter();
	@Output() startUpdateCustomItem = new EventEmitter();

	searchField = this.fb.control('');
	subscription: Subscription;

	client;

	constructor(public bf: BigFormService, @Inject('environment') environment: any, private fb: UntypedFormBuilder, private store: Store<any>) {
		this.client = environment?.client;
	}

	ngOnDestroy(): void {
		this.subscription && this.subscription.unsubscribe();
	}

	@HostListener('mouseenter') onMouseEnter() {
		this.isItemHover = true;
	}

	@HostListener('mouseleave') onMouseLeave() {
		this.isItemHover = false;
	}

	public updateActualLineItem(availableLineItem) {
		this.lineItemFormGroup.patchValue({
			VoucherTypeandStatus: availableLineItem.VoucherTypeandStatus,
			VoucherNumber: availableLineItem.VoucherNumber,
			AmountExclVAT: availableLineItem.AmountExclVAT,
			TotalVAT: availableLineItem.TotalVAT || 0,
			vatPercentage: availableLineItem.vatPercentage
		});
		this.showDropDown = false;
	}

	updateCustomItem(event, item) {
		this.showDropDown = false;
		this.startUpdateCustomItem.emit(item);
		event.stopPropagation();
	}

	createCustomItem(event) {
		this.showDropDown = false;
		this.startCreateCustomItem.emit(true);
	}

	public openDropdown() {
		this.showDropDown = true;
		this.isItemActive = true;
	}

	// get quantityUntypedFormControl() {
	// 	return this.lineItemFormGroup.get('quantity') as UntypedFormControl;
	// }
}
