import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AccountLockoutService } from './account-lockout.service';
import { switchMap, tap, takeUntil, delay, take, map, withLatestFrom } from 'rxjs/operators';
import { Subscription, Observable, timer, from, EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { LogOut, GetLoggedInUserSilently, InitializeTempData, IndexedDbService, NetworkService, ModalService, NavService } from '@flexus/core';
import { Actions, ofType } from '@ngrx/effects';
import { cleanUpSub } from '@flexus/utilities';
import { OverlayConfig, OverlayRef, Overlay } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { UUID } from 'angular2-uuid';
import { BackFromOfflineService } from './back-from-offline.service';

@Component({
	selector: 'flx-account-lockout',
	templateUrl: 'account-lockout.component.html',
	styleUrls: ['account-lockout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountLockoutComponent implements OnInit, OnDestroy {
	show: boolean;
	counter$: Observable<any>;
	counter = 0;
	refreshTokenSubscription: Subscription;
	refreshIfBusySubscription: Subscription;
	offlineTokenLogoutSub: Subscription;
	counterSub: Subscription;

	overlayRef: OverlayRef;
	backdropSub: Subscription;

	// Timer variables
	countdownTime = 30;
	timeLeft = 0;
	interval;

	@ViewChild('lockoutTemplate') overlayTemplate: CdkPortal;

	constructor(
		private accountLockoutService: AccountLockoutService,
		private actions$: Actions,
		private store: Store<any>,
		private overlay: Overlay,
		private cd: ChangeDetectorRef,
		private indexedDbService: IndexedDbService,
		private networkService: NetworkService,
		private modal: ModalService,
		private navService: NavService,
		private backFromOffline: BackFromOfflineService,
		@Inject('environment') private environment: any
	) {}

	ngOnInit() {
		this.counter$ = this.userIdleAccountLockCounter();
		this.offlineTokenLogoutSub = this.throwOfflineTokenWarning();

		this.refreshTokenSubscription = this.onReceiveTokenCheckExpirationAndRefreshSilently().subscribe();
		this.refreshIfBusySubscription = this.refreshUserTokenBeforeExpireIfBusy().subscribe();
	}

	refreshUserTokenBeforeExpireIfBusy() {
		return this.accountLockoutService.getToken()?.pipe(
			takeUntil(this.actions$.pipe(ofType('LOGOUT_SUCCESS'))),
			switchMap(obj => {
				return timer(0, 1000)?.pipe(
					take(1),
					delay(obj.expirationWarningDate),
					tap(() => {
						console.log('called for new user token');
						this.store.dispatch(new GetLoggedInUserSilently(`${this.environment.api_url}v2/auth/user/`));
					}),
					delay(5000), // Wait a bit to avoid race conditions
					tap(() => {
						console.log('resubscribed again');
						// After refreshing, reset listener again
						this.refreshIfBusySubscription = this.refreshUserTokenBeforeExpireIfBusy().subscribe();
					})
				);
			}),
			takeUntil(this.networkService.isOnline.pipe(map(online => !online)))
		);
	}

	onReceiveTokenCheckExpirationAndRefreshSilently() {
		return this.accountLockoutService.getToken()?.pipe(
			takeUntil(this.actions$.pipe(ofType('LOGOUT_SUCCESS'))),
			switchMap(obj => {
				return timer(0, 1000)?.pipe(
					take(1),
					delay(obj.expirationWarningDate),
					tap(() => {
						this.refreshToken();
					})
				);
			})
		);
	}

	userIdleAccountLockCounter() {
		const idleTime = 1500000;
		// const idleTime = 120000;
		// const idleTime = 10000;
		return this.accountLockoutService.userIdleTime(idleTime)?.pipe(
			take(1),
			tap(val => console.log('account lock counter: ', val)),
			// takeUntil(this.actions$.pipe(ofType('LOGOUT_SUCCESS'))),
			tap(() => {
				this.open();
				this.startTimer();
			}),
			switchMap(val => {
				return this.accountLockoutService.countdownTimer(this.countdownTime);
			})
		);
	}

	// If offline and token expired
	throwOfflineTokenWarning() {
		const navService = this.navService;
		return this.backFromOffline.backFromOffline$
			.pipe(withLatestFrom(this.accountLockoutService.getToken()?.pipe(map(obj => obj.isExpired))))
			.subscribe(([fromOffline, tokenExpired]) => {
				console.log({ fromOffline, tokenExpired });
				if (fromOffline) {
					if (tokenExpired) {
						this.modal.openModalDirectly((inst, store) => {
							inst.heading = 'Network back online';
							inst.message = 'Session expired while offline. Please log in again to continue.';
							inst.type = 'danger';
							inst.navButtons = [
								{
									text: 'Log Out',
									color: 'danger',
									linkType: 'close',
									clickHandler: ev => {
										navService.portalActions.next({ call: '', paramFunc: () => {} });
										store.dispatch(new LogOut());
										location.href = '/auth/login';
									}
								}
							];
						});
					} else {
						this.store.dispatch(new GetLoggedInUserSilently(`${this.environment.api_url}v2/auth/user/`));
					}
				}
			});
	}

	refreshToken() {
		this.store.dispatch(new GetLoggedInUserSilently(`${this.environment.api_url}v2/auth/user/`));
		this.counter$ = this.userIdleAccountLockCounter();
		this.close();
	}

	ngOnDestroy() {
		if (this.refreshTokenSubscription) this.refreshTokenSubscription.unsubscribe();
		cleanUpSub(this.counterSub);
		this.close();
		cleanUpSub(this.offlineTokenLogoutSub);
	}

	// =============================================== MODAL Methods ===============================================
	open(): void {
		// this.cd.detectChanges();
		this.timeLeft = this.countdownTime;
		if (this.overlayRef) {
			// keep only one modal open at a time
			this.overlayRef.dispose();
		}
		this.openTemplateOverlay();
		this.counter$ = null;
	}

	close(): void {
		if (!!this.overlayRef) {
			this.overlayRef.dispose();
		}
		this.pauseTimer();
	}

	// ======================================== CDK Methods ========================================
	openTemplateOverlay() {
		const positionStrategy = this.overlay.position().global()?.centerHorizontally()?.centerVertically();

		const overlayConfig = new OverlayConfig({
			positionStrategy
		});

		overlayConfig.hasBackdrop = true;

		this.overlayRef = this.overlay.create(overlayConfig);

		this.overlayRef.attach(this.overlayTemplate);
	}

	startTimer() {
		const navService = this.navService;
		this.pauseTimer();
		this.interval = setInterval(() => {
			if (this.timeLeft > 0) {
				this.timeLeft--;
				if (this.timeLeft === 0) {
					this.createLocalDraft()?.pipe(take(1)).subscribe();
					this.store.dispatch(new InitializeTempData());
					navService.portalActions.next({ call: '', paramFunc: () => {} });
					this.store.dispatch(new LogOut());
					location.href = '/auth/login';
				}
				this.cd.detectChanges();
			} else {
				this.pauseTimer();
			}
		}, 1000);
	}

	createLocalDraft() {
		return from(this.indexedDbService.currentItem.get('currentItem'))?.pipe(
			map(currentItem => {
				if (currentItem && currentItem.applicant && currentItem.loan_information) {
					const localDraft = {
						...currentItem,
						tempKey: UUID.UUID(),
						state: 169
					};
					this.indexedDbService.claimInDraft.put(localDraft).then(() => {
						this.indexedDbService.currentItem.delete('currentItem');
					});
				}
				return EMPTY;
			})
		);
	}

	// createLocalDraft() {
	//   return from(this.indexedDbService.currentItem.get('currentItem'))?.pipe(
	//     mergeMap(currentItem => {
	//       if (currentItem && currentItem.applicant && currentItem.loan_information) {
	//         return this.store.select(getSelectedItemOne)?.pipe(
	//           skipWhile(x => !x),
	//           map(itemOne => {
	//             let localDraft;
	//             console.log({ itemOne });
	//             if (itemOne && itemOne?.tempKey) {
	//               localDraft = {
	//                 ...currentItem,
	//                 tempKey: itemOne?.tempKey,
	//                 state: 169
	//               };
	//             } else {
	//               localDraft = {
	//                 ...currentItem,
	//                 tempKey: UUID.UUID(),
	//                 state: 169
	//               };
	//             }
	//             this.indexedDbService.claimInDraft.put(localDraft).then(() => {
	//               this.indexedDbService.currentItem.delete('currentItem');
	//             });
	//           })
	//         );
	//       }
	//       return EMPTY;
	//     })
	//   );
	// }

	pauseTimer() {
		clearInterval(this.interval);
	}
}
