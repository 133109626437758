import { Flow_0_0_2, getFullItemOne, getData, getFullItemTwo, getSelectedItemTwo, getAllInfo, SetNextNode, MakeServerCall } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { take, map, skipWhile, switchMap, pluck, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import moment from 'moment';
import { PHOTO_NODE } from 'apps/studio/src/app/sp_globals/configs/reusable/PHOTO_NODE';
import { findName } from '@flexus/utilities';

export const BET_288: Flow_0_0_2 = {
	id: '288',
	name: 'review_sp_repudiation',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map((itemOne: any) => {
					if (itemOne) {
						return `Review SP Repudiation : ${itemOne?.mid} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Review SP Repudiation';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], { filesFunctionName: 'getAllClaimFiles' }),
	serverCalls: {
		summaryDetails: {
			errorMessage: 'Could get summary details',
			serviceVariable: '',
			directCall: (http, store, sq) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						skipWhile((itemTwo: any) => !itemTwo),
						take(1)
					),
					store.select(getAllInfo).pipe(
						skipWhile((allInfo: any) => !allInfo),
						map((allInfo: any) => allInfo.appointment_types),
						take(1)
					)
				]).pipe(
					take(1),
					map(([itemTwo, appointmentTypes]: any[]) => {
						const date = moment(itemTwo?.appointment?.[0]?.range_start);
						return {
							'Claim Details': {
								'Claim Type': itemTwo?.claim?.type,
								'Skill Required': itemTwo?.office_use?.skill,
								Address: itemTwo?.address,
								'Appointment Date': date?.format('YYYY-MM-DD'),
								'Appointment Time': `${findName(itemTwo?.appointment?.[0]?.appointment_type, appointmentTypes)?.name} ${date.format('HH:mm')}${
									itemTwo?.appointment?.[0]?.range_end ? ' - ' + moment(itemTwo?.appointment?.[0]?.range_end).format('HH:mm') : ''
								}`
							},
							'Team Leader Details': {
								'Contact Number': itemTwo?.team_leader?.contact_number,
								'Email Address': itemTwo?.team_leader?.email_address
							},
							'Service Provider Details': {
								'Contact Person': itemTwo?.team_leader?.sp?.contact_person,
								'Contact Number': itemTwo?.team_leader?.sp?.contact_primary,
								'Email Address': itemTwo?.team_leader?.sp?.email_receiving
							}
						};
					})
				);
			}
		},
		files: {
			serviceVariable: 'betService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		latestReportFile: {
			errorMessage: 'Could not get latest report',
			directCall: (http, store, sq) => {
				return store.select(getData).pipe(
					pluck('latestReport'),
					skipWhile(x => !x),
					take(1),
					switchMap((latestReport: any) =>
						http
							.post(environment.api_url + 'v1/file_action/get_file', {
								file_id: latestReport.id,
								return_type: 1
							})
							.pipe(map((file: any) => file.success && file.payload && { file: { base64: file.payload?.data, type: 'pdf' } }))
					)
				);
			}
		},
		latestQuotationFile: {
			errorMessage: 'Could not get latest quote',
			directCall: (http, store, sq) => {
				return store.select(getData).pipe(
					pluck('latestQuotation'),
					skipWhile(x => !x),
					take(1),
					switchMap((latestQuotation: any) =>
						http
							.post(environment.api_url + 'v1/file_action/get_file', {
								file_id: latestQuotation.id,
								return_type: 1
							})
							.pipe(map((file: any) => file.success && file.payload && { file: { base64: file.payload?.data, type: 'pdf' } }))
					)
				);
			}
		},
		aboveMandate: {
			errorMessage: '',
			directCall: (http, store) =>
				store.select(getSelectedItemTwo).pipe(
					skipWhile(x => !x),
					take(1),
					switchMap(job => http.post(environment.api_url + 'v1/job_action/check_requires_ia', { job_id: job?.id }).pipe(pluck('payload', 'above_mandate')))
				)
		}
	},
	instructions: {
		editRoles: {
			0: 'Review SP Repudiation'
		},
		viewRoles: {
			0: 'Review SP Repudiation'
		}
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			name: 'Summary',
			showTabs: true,
			initFormFields: bf => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(316));
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary',
							instructions: ['This job has been repudiated. Please review the validity of the job quotation.'],
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXNestedObjectKeyValueListComponent',
						inputs: {
							subHeadingConfig: { size: 'extra-small', weight: 'bold', color: 'default' },
							objectInfo$: 'summaryDetails'
						}
					}
				]
			},
			navs: [
				{
					text: 'Convert to CIL',
					nextNode: 'SubmissionSuccess',
					color: 'default',
					location: 'center',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							directCall: (http, _s, _sq, bf) => {
								bf.patchValues({
									cil_state: 93,
									effected_jobs: [bf.bigForm.get('itemTwo')?.value?.id]
								});

								return http.post(`${environment.api_url}v1/job_action/move_to_cil`, {
									cil_state: 93,
									effected_jobs: [bf.bigForm.get('itemTwo')?.value?.id]
								});
							}
						}
					}
				},
				{
					text: 'Query SP',
					nextNode: 'QueryServiceProvider',
					color: 'default'
				},
				{
					text: 'Disagree with Decision',
					nextNode: 'OverturnRepudiationDecision',
					color: 'secondary'
				},
				{
					text: 'Agree with Decision',
					color: 'primary',
					serverCalls: {
						takeNoteModal: {
							errorMessage: '',
							directCall: (_h, store, _sq, _bf, _c, modal) => {
								modal.openModalDirectly(instance => {
									instance.type = 'info';
									instance.heading = 'Please Take Note';
									instance.message = 'Please contact MFRF and confirm this repudiation.';
									instance.navButtons = [
										{
											text: 'Okay',
											clickHandler: _e => {
												store.dispatch(
													new MakeServerCall({
														dataKey: 'response',
														errorMessage: 'Job could not be updated!!',
														serviceVariable: 'betService',
														functionName: 'updateJob',
														nextNode: 'SubmissionSuccess'
													})
												);
											},
											linkType: 'close',
											color: 'default'
										}
									];
								});
								return of();
							}
						}
					}
				}
			]
		},
		AssessorReport: {
			name: "Assessor's Report",
			showTabs: true,
			initFormFields: (bf, _i, _in, _sq, store) => {
				store
					.select(getData)
					.pipe(take(1), pluck('latestReportFile'))
					.subscribe(latestReport => {
						const latestReportControl = new UntypedFormControl([latestReport]);
						if (!bf.bigForm.get('fileQueue')) {
							bf.bigForm.addControl('fileQueue', latestReportControl);
						} else {
							bf.bigForm.setControl('fileQueue', latestReportControl);
						}
					});
			},
			component: 'FLXFileUploadWithPreviewComponent',
			inputs: {
				purposes: [],
				heading: 'Repudiation Report',
				multipleUploads: false,
				allowedFileTypes: [],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			navs: [
				{
					text: 'Convert to CIL',
					nextNode: 'SubmissionSuccess',
					color: 'default',
					location: 'center',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							directCall: (http, _s, _sq, bf) => {
								bf.patchValues({
									cil_state: 93,
									effected_jobs: [bf.bigForm.get('itemTwo')?.value?.id]
								});

								return http.post(`${environment.api_url}v1/job_action/move_to_cil`, {
									cil_state: 93,
									effected_jobs: [bf.bigForm.get('itemTwo')?.value?.id]
								});
							}
						}
					}
				},
				{
					text: 'Query SP',
					nextNode: 'QueryServiceProvider',
					color: 'default'
				},
				{
					text: 'Disagree with Decision',
					nextNode: 'OverturnRepudiationDecision',
					color: 'secondary'
				},
				{
					text: 'Agree with Decision',
					color: 'primary',
					serverCalls: {
						takeNoteModal: {
							errorMessage: '',
							directCall: (_h, store, _sq, _bf, _c, modal) => {
								modal.openModalDirectly(instance => {
									instance.type = 'info';
									instance.heading = 'Please Take Note';
									instance.message = 'Please contact MFRF and confirm this repudiation.';
									instance.navButtons = [
										{
											text: 'Okay',
											clickHandler: _e => {
												store.dispatch(
													new MakeServerCall({
														dataKey: 'response',
														errorMessage: 'Job could not be updated!!',
														serviceVariable: 'betService',
														functionName: 'updateJob',
														nextNode: 'SubmissionSuccess'
													})
												);
											},
											linkType: 'close',
											color: 'default'
										}
									];
								});
								return of();
							}
						}
					}
				}
			]
		},
		Quote: {
			name: 'Quote',
			showTabs: true,
			initFormFields: (bf, _i, _in, _sq, store) => {
				store
					.select(getData)
					.pipe(take(1), pluck('latestQuotationFile'))
					.subscribe(latestQuote => {
						const latestQuoteControl = new UntypedFormControl([latestQuote]);
						if (!bf.bigForm.get('fileQueue')) {
							bf.bigForm.addControl('fileQueue', latestQuoteControl);
						} else {
							bf.bigForm.setControl('fileQueue', latestQuoteControl);
						}
					});
			},
			component: 'FLXFileUploadWithPreviewComponent',
			inputs: {
				purposes: [],
				heading: 'Quotation',
				multipleUploads: false,
				allowedFileTypes: [],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			navs: [
				{
					text: 'Convert to CIL',
					nextNode: 'SubmissionSuccess',
					color: 'default',
					location: 'center',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							directCall: (http, _s, _sq, bf) => {
								bf.patchValues({
									cil_state: 93,
									effected_jobs: [bf.bigForm.get('itemTwo')?.value?.id]
								});

								return http.post(`${environment.api_url}v1/job_action/move_to_cil`, {
									cil_state: 93,
									effected_jobs: [bf.bigForm.get('itemTwo')?.value?.id]
								});
							}
						}
					}
				},
				{
					text: 'Query SP',
					nextNode: 'QueryServiceProvider',
					color: 'default'
				},
				{
					text: 'Disagree with Decision',
					nextNode: 'OverturnRepudiationDecision',
					color: 'secondary'
				},
				{
					text: 'Agree with Decision',
					color: 'primary',
					serverCalls: {
						takeNoteModal: {
							errorMessage: '',
							directCall: (_h, store, _sq, _bf, _c, modal) => {
								modal.openModalDirectly(instance => {
									instance.type = 'info';
									instance.heading = 'Please Take Note';
									instance.message = 'Please contact MFRF and confirm this repudiation.';
									instance.navButtons = [
										{
											text: 'Okay',
											clickHandler: _e => {
												store.dispatch(
													new MakeServerCall({
														dataKey: 'response',
														errorMessage: 'Job could not be updated!!',
														serviceVariable: 'betService',
														functionName: 'updateJob',
														nextNode: 'SubmissionSuccess'
													})
												);
											},
											linkType: 'close',
											color: 'default'
										}
									];
								});
								return of();
							}
						}
					}
				}
			]
		},
		JobPhotosNode: {
			showTabs: true,
			...PHOTO_NODE,
			navs: [
				{
					text: 'Convert to CIL',
					nextNode: 'SubmissionSuccess',
					color: 'default',
					location: 'center',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							directCall: (http, _s, _sq, bf) => {
								bf.patchValues({
									cil_state: 93,
									effected_jobs: [bf.bigForm.get('itemTwo')?.value?.id]
								});

								return http.post(`${environment.api_url}v1/job_action/move_to_cil`, {
									cil_state: 93,
									effected_jobs: [bf.bigForm.get('itemTwo')?.value?.id]
								});
							}
						}
					}
				},
				{
					text: 'Query SP',
					nextNode: 'QueryServiceProvider',
					color: 'default'
				},
				{
					text: 'Disagree with Decision',
					nextNode: 'OverturnRepudiationDecision',
					color: 'secondary'
				},
				{
					text: 'Agree with Decision',
					color: 'primary',
					serverCalls: {
						takeNoteModal: {
							errorMessage: '',
							directCall: (_h, store, _sq, _bf, _c, modal) => {
								modal.openModalDirectly(instance => {
									instance.type = 'info';
									instance.heading = 'Please Take Note';
									instance.message = 'Please contact MFRF and confirm this repudiation.';
									instance.navButtons = [
										{
											text: 'Okay',
											clickHandler: _e => {
												store.dispatch(
													new MakeServerCall({
														dataKey: 'response',
														errorMessage: 'Job could not be updated!!',
														serviceVariable: 'betService',
														functionName: 'updateJob',
														nextNode: 'SubmissionSuccess'
													})
												);
											},
											linkType: 'close',
											color: 'default'
										}
									];
								});
								return of();
							}
						}
					}
				}
			]
		},
		QueryServiceProvider: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Query Service Provider'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Query to Service Provider',
									inputType: 'textarea',
									formControlName: 'da_query'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: bf => {
				bf.patchValues({ da_query: bf.getControl('da_query')?.value || '', new_state: 289 });
				bf.bigForm.get('da_query')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			checkValidityForFields: ['da_query'],
			navs: [
				{
					text: 'Submit To SP',
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		OverturnRepudiationDecision: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Overturn Repudiation',
							instructions: ['You are about to send the job back to the service provider to complete the job.']
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: 'Reason for Overturning Repudiation',
									inputType: 'textarea',
									formControlName: 'repudiation_overturned_reason'
								}
							},
							containerWidth: '700px'
						}
					}
				]
			},
			initFormFields: bf => {
				bf.patchValues({ repudiation_overturned_reason: bf.getControl('repudiation_overturned_reason')?.value || '', repudiation_overturned: 1, new_state: 294 });
				bf.bigForm.get('repudiation_overturned_reason')?.setValidators([Validators.required, Validators.minLength(3)]);
			},
			checkValidityForFields: ['repudiation_overturned_reason'],
			navs: [
				{
					text: 'Submit',
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						mandateCheck: {
							errorMessage: '',
							directCall: (_h, store, _sq, bf, controller, modal) =>
								store.select(getData).pipe(
									pluck('aboveMandate'),
									skipWhile(x => x === null || x === undefined),
									take(1),
									tap(aboveMandate => {
										bf.patchValues({ new_state: 294 });
										if (aboveMandate) {
											modal.openModalDirectly(instance => {
												instance.type = 'warning';
												instance.message = 'This job is above mandate. Do you want to send it to the internal assessor or proceed to overwrite?';
												instance.navButtons = [
													{
														text: 'Send to IA',
														clickHandler: _e => {
															bf.patchValues({ new_state: 319 });
															store.dispatch(
																new MakeServerCall({
																	dataKey: 'response',
																	serviceVariable: 'betService',
																	functionName: 'updateJob',
																	errorMessage: 'Could not update Job',
																	nextNode: 'SubmissionSuccess'
																})
															);
														},
														linkType: 'close',
														color: 'default'
													},
													{
														text: 'Proceed to Overwrite',
														clickHandler: _e => {
															store.dispatch(
																new MakeServerCall({
																	dataKey: 'response',
																	serviceVariable: 'betService',
																	functionName: 'updateJob',
																	errorMessage: 'Could not update Job',
																	nextNode: 'SubmissionSuccess'
																})
															);
														},
														linkType: 'close',
														color: 'default'
													}
												];
											});
										} else {
											store.dispatch(
												new MakeServerCall({
													dataKey: 'response',
													serviceVariable: 'betService',
													functionName: 'updateJob',
													errorMessage: 'Could not update Job',
													nextNode: 'SubmissionSuccess'
												})
											);
										}
									})
								)
						}
					}
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		reasonField: 'job_information.diagnosis',
		da_agree: 'job_information.da_agree',
		new_state: 'new_state',
		current_state: [() => 288, 'current_state'],
		da_query: [
			(qr, storeObj) => {
				let daq = [];
				const FI2 = storeObj['selectedContext']?.fullItemTwo;

				if (FI2?.job_information?.da_query) {
					if (Array.isArray(FI2?.job_information?.da_query)) {
						daq = FI2?.job_information?.da_query;
					} else {
						daq = [{ ...(<object>FI2?.job_information?.da_query) }];
					}
				}
				const ia_query = [...daq, { query: qr, queryreply: '' }];
				return ia_query;
			},
			'job_information.da_query'
		],
		repudiation_overturned: 'job_information.repudiation_overturned',
		repudiation_overturned_reason: 'job_information.repudiation_overturned_reason'
	}
};
