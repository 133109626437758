import { Flow_0_0_2 } from '@flexus/core';

export const BET_8: Flow_0_0_2 = {
	id: '8',
	name: 'draft',
	itemType: 'flow',
	header: {
		title: 'Draft Completed',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	startNode: '',
	nodes: {
		//this might need to use edit-cliam.ts
		//EditClaim:{}
	}
};
