import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLXSetAppointmentComponent } from './set-appointment.component';
import { FLXDatepickerModule, FLXFormsModule, FLXTimePickerModule, FLXHeadingsModule } from '@flexus/ui-elements';

@NgModule({
	declarations: [FLXSetAppointmentComponent],
	imports: [CommonModule, FLXDatepickerModule, FLXFormsModule, FLXTimePickerModule, FLXHeadingsModule],
	exports: [FLXSetAppointmentComponent]
})
export class FLXSetAppointmentModule {}
