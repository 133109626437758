import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, Validators, UntypedFormBuilder } from '@angular/forms';
import { Observable, Subscription, } from 'rxjs';
import { Store } from '@ngrx/store';
import { BigFormService } from '@flexus/core';
import { skipWhile, startWith } from 'rxjs/operators';
export interface InvoiceRowItem {
	item: string;
	price: number;
}
@Component({
	selector: 'flx-agreement-of-loss',
	templateUrl: './agreement-of-loss.component.html',
	styleUrls: ['./agreement-of-loss.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgreementOfLossComponent implements OnInit, OnDestroy {
	currentJob: any;
	errorSub: Subscription;
	errorMessage: string;
	invoiceRowItems: InvoiceRowItem[];
	configSubscription: Subscription;
	// =========================================================== INPUTS ===========================================================
	@Input() invoice$: Observable<any>;
	constructor(private _store: Store<any>, public bf: BigFormService, private fb: UntypedFormBuilder) {}
	ngOnInit() {
		// this.AOLData$ = this._store.select(getAOLData).pipe(
		//   skipWhile((x) => !x),
		//   take(1),
		// );
		// this.errorSub = this._store
		//   .select(getAOLErrors)
		//   .pipe(skipWhile((x) => !x))
		//   .subscribe((x) => {
		//     if (x['createAOLLetter'] && x['createAOLLetter'].message) {
		//       this.errorMessage = x['createAOLLetter'].message + ', please try again!';
		//     }
		//   });
		this.bf.bigForm.addControl('agreementOfLossDetail', this.fb.array([this.initRowFormGroup(null, null)]));

		// The startWith operator is for re-initializing the form when values are changed programmatically
		this.configSubscription = this.bf.bigForm?.valueChanges
			.pipe(
				startWith(this.bf.bigForm.getRawValue()),
				skipWhile(() => !this.bf.bigForm.get('invoiceRowItems'))
			)
			.subscribe(event => {
				// console.log('im here!');
				this.invoiceRowItems = event.agreementOfLossDetail;
				this.bf.bigForm.get('invoiceRowItems').patchValue(this.invoiceRowItems, { emitEvent: false });
				const total = this.invoiceRowItems ? this.invoiceRowItems?.reduce((a, b) => a + Number(b.price), 0) : 0;
				this.bf.bigForm.get('total').patchValue(total, { emitEvent: false });
			});
	}
	ngOnDestroy(): void {
		if (this.errorSub) {
			this.errorSub.unsubscribe();
		}
		if (this.configSubscription) {
			this.configSubscription.unsubscribe();
		}
	}
	// ================================================= TEMPLATE METHODS ====================================================
	// ===========================================  Variables ===========================================================
	// ===========================================  Inputs ===========================================================
	get agreementOfLossDetailDynamic() {
		return this.bf.bigForm.get('agreementOfLossDetail') as UntypedFormArray;
	}
	removeRow(n: number) {
		this.agreementOfLossDetailDynamic.removeAt(n);
	}
	addRow(item: string, price: number) {
		this.agreementOfLossDetailDynamic.push(this.initRowFormGroup(item, price));
	}
	initRowFormGroup(item: string, price: number): UntypedFormGroup {
		return this.fb.group({
			item: [item, [Validators.required, Validators.pattern(new RegExp(`^[^&]*$`))]],
			price: [price, Validators.required]
		});
	}
}
