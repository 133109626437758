import { Flow_0_0_2, getAllInfo, getFullItemOne } from '@flexus/core';
import { findName } from '@flexus/utilities';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { DATA_CALLS } from '../../reusable/DATA_CALLS';

export const VIEW_CLAIM_EXCESS: Flow_0_0_2 = {
	id: 'ViewClaimExcessOnClaim',
	itemType: 'flow',
	fetchLevel1And2: true,
	name: 'View Excess On Claim',
	excludeForOnlyStates: [169],
	excludeForOnlyRoles: [18],
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				map((itemOne: any) => {
					if (itemOne && itemOne?.applicant && itemOne?.applicant?.information) {
						return `View Claim Excess : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'View Claim Excess';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ExcessManagement',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: (): any => {
		return () => {
			return [{}];
		};
	},

	serverCalls: {
		excess_options: {
			...DATA_CALLS.excess_options
		},
		excess_on_jobs: {
			...DATA_CALLS.excess_on_jobs_api
		},
		jobs_on_claim: {
			errorMessage: 'Failed to load jobs on claim',
			directCall: (http, store) => {
				return forkJoin([
					store.select(getFullItemOne).pipe(
						filter(x => !!x),
						take(1)
					),
					store.select(getAllInfo).pipe(
						filter(x => !!x),
						take(1)
					)
				]).pipe(
					map(([claim, allInfo]) => {
						let jobsArr: any[] = [];
						claim?.jobs.forEach(job => {
							jobsArr.push({ skill: job?.skill, display: findName(job?.skill, allInfo?.skills)?.name, id: job?.id });
						});
						return jobsArr;
					})
				);
			}
		}
	},
	nodes: {
		ExcessManagement: {
			component: 'FLXExcessManagementComponent',
			inputs: {
				excludeStates: [27, 28, 50, 51],
				containerWidth: '400px',
				centered: true
			},
			initFormFields: () => {},
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						response: {
							errorMessage: 'Could not update excess on claim',
							directCall: (http, store, sq, bf) => {
								const details = bf.bigForm.value.subData?.data;
								return store.select(getFullItemOne).pipe(
									filter(x => !!x),
									take(1),
									switchMap(claim => {
										const jobs: any[] = [];
										details.forEach((job: any) => {
											let temp = {
												job_id: job?.job_id,
												who_collects: Object.entries(job).find(([key]) => key.split('-')[1] === 'type')?.[1],
												how_collect: Object.entries(job).find(([key]) => key.split('-')[1] === 'method')?.[1],
												amount: Object.entries(job).find(([key]) => key.split('-')[1] === 'amount')?.[1],
												reason: job?.reason,
												excess_id: job?.excess_id,
												new: job?.new ? job?.new : false
											};
											jobs.push(temp);
										});
										let data = {
											delete: bf.bigForm.value.removeExcess,
											claim_id: claim?.id,
											jobs: jobs
										};
										return http.post<any>(environment.api_url + 'v1/claim_action/update_excess/', data).pipe(
											map(response => {
												if (response.payload?.failed && response.payload?.failed.length > 0) {
													throw Error(
														(response.payload.failed as { job_id: number; reason: string }[])
															.map(data => data.reason)
															.filter((value, index, array) => array.indexOf(value) === index)
															.join(', ')
													);
												}
												return response;
											})
										);
									})
								);
							}
						}
					},
					color: 'primary'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	}
};
