import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemTwo, getSelectedItemTwo, getSubmissionData } from '@flexus/core';
import { filter, map, mergeMap, skipWhile, switchMap, take } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

export const SP_GLOBAL_101: Flow_0_0_2 = {
	id: '101',
	name: 'awaiting_loss_adjuster_decision',
	itemType: 'flow',
	header: {
		title: '',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},

	startNode: 'ViewJobDocuments',
	nodes: {
		ViewJobDocuments: {
			component: 'FLXFileBrowserComponent',
			showTabs: true,
			name: 'Documents',
			inputs: {
				heading: 'View support documents for Loss Adjuster Job',
				showFilters: false,
				paginationId: 'reports'
			},
			serverCalls: {
				files: {
					serviceVariable: 'spService',
					functionName: 'getAllJobFiles',
					responseSlice: 'payload',
					errorMessage: 'Could not get files from server!'
				}
			}
		},

		ReviewReport: {
			serverCalls: {
				dataFile: {
					errorMessage: 'No file could be found!',
					directCall: (_http: HttpClient, store, sq, bf) => {
						return store.select(getSelectedItemTwo).pipe(
							skipWhile((selectedItem: any) => !selectedItem),
							take(1),
							mergeMap((selected: any) => {
								const job_id = selected?.id;
								return forkJoin([
									_http.post<any>(environment.api_url + 'v1/file_action/get_files', { job_id: job_id }).pipe(
										skipWhile((files: any) => !files),
										take(1),
										map(files => {
											return files?.payload;
										})
									)
								]).pipe(
									switchMap(array => {
										const docs = array[0];
										const spSiteReport = docs.filter(el => el.purpose === 'SP Report');
										spSiteReport.sort((a, b) => (a.created < b.created ? 1 : -1));
										if (Array.isArray(spSiteReport) && spSiteReport.length > 0) {
											return _http
												.post(`${environment.api_url}v1/file_action/get_file`, {
													file_id: spSiteReport[0]?.id,
													return_type: 1
												})
												.pipe(
													skipWhile(doc => !doc),
													take(1),
													map((report: any) => {
														if (report.payload < 1) {
															throw new Error('No file could be found!');
														}
														return report.payload;
													})
												);
										}
									})
								);
							})
						);
					}
				}
			},
			name: 'SP Report',
			showTabs: true,
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							dataFiles$: 'dataFile',
							inputHeading: 'SP Report'
						}
					}
				]
			}
		},

		// ViewJobNotes: {
		// 	name: 'Notes',
		// 	component: 'FLXFlatDynamicFormComponent',
		// 	initFormFields: (bf, item, instance, sq, store) => {
		// 		bf.bigForm.addControl('notes', new UntypedFormControl('', [Validators.minLength(3)]));
		// 	},
		// 	inputs: {
		// 		heading: '',
		// 		formControls: {
		// 			0: {
		// 				label: '',
		// 				inputType: 'textarea',
		// 				width: '100%',
		// 				formControlName: 'notes',
		// 				rows: 5
		// 			}
		// 		},
		// 		containerWidth: '80%'
		// 	},
		// 	showTabs: true,
		// 	navs: [
		// 		{
		// 			text: 'Add Note',
		// 			color: 'primary',
		// 			nextNode: 'ViewDecision',
		// 			serverCalls: {
		// 				addNote: {
		// 					errorMessage: 'Could not add note!',
		// 					directCall: (http, store) =>
		// 						store.select(getFullItemTwo)?.pipe(
		// 							filter(job => !!job),
		// 							take(1),
		// 							switchMap(job =>
		// 								http.post(`${environment.api_url}v1/job_action/update_job/`, {
		// 									job_information: job.job_information,
		// 									job_id: job?.id,
		// 									new_state: 94
		// 								})
		// 							)
		// 						)
		// 				}
		// 			}
		// 		}
		// 	]
		// },

		ViewDecision: {
			name: 'Decision',
			component: 'FLXHeadingWithInstructionsComponent',
			inputs: {
				title: 'Approve or decline the job',
				itemMargin: '0 0 35px 0'
			},
			showTabs: true,
			navs: [
				{
					text: 'Decline',
					color: 'danger',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						declineJob: {
							errorMessage: 'Could not decline job!',
							directCall: (http, store) =>
								store.select(getFullItemTwo)?.pipe(
									filter(job => !!job),
									take(1),
									switchMap(job =>
										http.post(`${environment.api_url}v1/job_action/update_job/`, {
											job_information: job.job_information,
											job_id: job?.id,
											new_state: 94
										})
									)
								)
						}
					}
				},
				{
					text: 'Approve',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						declineJob: {
							errorMessage: 'Could not decline job!',
							directCall: (http, store) =>
								store.select(getFullItemTwo)?.pipe(
									filter(job => !!job),
									take(1),
									switchMap(job =>
										http.post(`${environment.api_url}v1/job_action/update_job/`, {
											job_information: job.job_information,
											job_id: job?.id,
											new_state: 86
										})
									)
								)
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	}
};
