import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BigFormService, MakeServerCall, ManifestController, ModalService, NodePoint_0_0_2, SetNextNode } from '@flexus/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

export const resolvedProblemNode = (state?: number, newState?: number): NodePoint_0_0_2 => {
	return {
		component: 'FLXFlatDynamicFormComponent',
		name: state === 186 ? 'FSIA Error ' : state === 177 ? 'Validation Issue Fixed' : 'Resolved Issue',
		hideTabItem: true,
		inputs: {
			heading: state === 186 ? 'FSIA Error Comment' : 'Resolved Problem Raised by Validation',
			subheading: 'Please write a report before submission',
			formControls: {
				0: {
					label: state === 177 ? 'Report' : 'Please comment how you resolved the issue',
					inputType: 'textarea',
					rows: 5,
					formControlName: 'message'
				},
				1: {
					label: `What is the activation status?`,
					inputType: 'select',
					width: '30vw',
					selectConfig: {
						displayOptions: { displayKey: 'display', valueKey: 'value' },
						itemsOption: [
							{ display: 'Activated', value: 'Activated' },
							{ display: 'Activation failed', value: 'Activation failed' }
						],
						placeholder: 'Update activation status'
					},
					formControlName: 'activation_fix'
				}
			},
			formLayout: 'stacked',
			containerWidth: '50vw'
		},
		checkValidityForFields: ['message', 'activation_fix'],
		initFormFields: (bf, item, instance) => {
			bf.patchValues({ new_state: newState ?? 188 });
			bf.addControl('message', new UntypedFormControl('', Validators.required));
			bf.addControl('activation_fix', new UntypedFormControl('', [Validators.required]));
		},
		navs: [
			{
				text: 'Submit',
				disableOnLoad: true,
				serverFirst: true,
				// nextNode: 'submissionSuccess',
				color: 'primary',
				optIntoValidation: true,
				linkType: 'submit',
				serverCalls: {
					response: {
						directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService, _ctrl: ManifestController<any>, _modal: ModalService) => {
							_modal.openModalDirectly(inst => {
								inst.type = 'warning';
								inst.heading = 'Have you resolved the FSIA Error?';
								inst.message = 'Confirming that you have resolved the FSIA error will move this out of your workflow.';
								inst.navButtons = [
									{
										text: 'cancel',
										linkType: 'close',
										color: 'default'
									},
									{
										text: 'yes',
										serverFirst: true,
										linkType: 'close',
										color: 'default',
										// nextNode: 'submissionSuccess',
										clickHandler: () => {
											_store.dispatch(
												new MakeServerCall({
													errorMessage: 'Could not update job',
													dataKey: 'response',
													serviceVariable: 'mulService',
													functionName: 'updateJob'
												})
											);
											_ctrl.dispatch(new SetNextNode('submissionSuccess'));
										}
									}
								];
							});
							return of({});
						},
						errorMessage: ''
					}
					// {
					// 	serviceVariable: 'mulService',
					// 	functionName: 'updateJob',
					// 	errorMessage: 'Job could not be updated!!'
					// }
				}
			}
		]
	};
};
