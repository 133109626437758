import { Flow_0_0_2 } from '@flexus/core';

export const BET_156: Flow_0_0_2 = {
	id: '156',
	name: 'on_site_assessment_complete',
	itemType: 'flow',
	header: {
		title: 'Onsite Assessment Complete',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Wait for assessment report'
		}
	},
	startNode: '',
	nodes: {}
};
