import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { map, filter, switchMap, first } from 'rxjs/operators';
import { getAllInfoIndex } from '@flexus/utilities';
import { BET_BANK_DETAILS } from '../../flows/BANK_DETAILS';
import { of } from 'rxjs';

export const BET_VIEW_BANK_DETAILS: Flow_0_0_2 = {
	id: 'ViewBankDetails',
	itemType: 'flow',
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	name: 'View/Edit Bank Details',
	includeForOnlyStates: [28, 52, 56, 58, 59, 72, 107],
	includeFilterFunction: itemTwo => {
		if (itemTwo?.state === 52 && itemTwo?.supplier_type !== 2) return false;
		return true;
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo) {
						return `View/Edit Bank Details : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'View/Edit Bank Details';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		currBankDetails: {
			errorMessage: 'Could not pull bank details!',
			ignoreFalseError: true,
			directCall: (http, store, sq, bf) => {
				return store.select(getFullItemTwo).pipe(
					filter(x => !!x),
					first(itemTwo => itemTwo !== null || itemTwo !== undefined),
					switchMap(itemTwo => {
						const officeUse = itemTwo?.office_use;
						const policyDetails = itemTwo?.claim?.loan_information?.policydetails;
						const res = {
							initials: officeUse?.initials || policyDetails?.Initials || '',
							account_holder_name: officeUse?.surname || policyDetails?.Surname || '',
							account_number: officeUse?.bank_acc_number || '',
							identification_number: officeUse?.idnumber || policyDetails?.IdNumber || '',
							branch_code: officeUse?.branch_code || '',
							bank_name: officeUse?.bank || '',
							branch: officeUse?.branch || '',
							acc_type: officeUse?.acc_type || '',
							account_use: officeUse?.isnonpanel === true ? 'Company' : 'Individual'
						};
						return of(res);
					})
				);
			}
		}
	},
	startNode: 'ViewBankDetails',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	nodes: {
		ViewBankDetails: {
			...BET_BANK_DETAILS,
			inputs: {
				...BET_BANK_DETAILS.inputs
			},
			navs: [
				...BET_BANK_DETAILS.navs,
				{
					text: 'Bank details confirmed',
					location: 'right',
					color: 'secondary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						saveBankDetails: {
							serviceVariable: 'betService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemTwo.id': 'job_id',
		'itemTwo.state': 'current_state',

		//bank details
		'bank_details.initials': 'office_use.initials',
		'bank_details.account_holder_name': 'office_use.surname',
		'bank_details.account_number': 'office_use.bank_acc_number',
		'bank_details.identification_number': 'office_use.idnumber',
		'bank_details.branch_code': 'office_use.branch_code',
		'bank_details.bank_name': 'office_use.bank',
		'bank_details.branch': 'office_use.branch',
		'bank_details.acc_type': 'office_use.acc_type',
		acc_type_desc: [
			(desc, storeObj, formValue) => {
				if (formValue.bank_details !== undefined) {
					return getAllInfoIndex('account_type', 'mid', 'description', formValue.bank_details.acc_type, storeObj);
				} else {
					return null;
				}
			},
			'office_use.acc_type_desc'
		],
		'bank_details.account_use': [
			val => {
				return val === 'Individual' ? false : true;
			},
			'office_use.isnonpanel'
		]
	}
};
