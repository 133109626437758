import { Action } from '@ngrx/store';

// Action Panel Actions

export enum actionPanelActionTypes {
	EXPAND_ACTION_PANEL = '[Action Panel] Expand Action Panel',
	EXPAND_ADDITIONAL_ACTION_PANEL = '[Action Panel] Expand Additional Action Panel',
	COLLAPSE_ACTION_PANEL = '[Action Panel] Collapse Action Panel',
	COLLAPSE_ADDITIONAL_ACTION_PANEL = '[Action Panel] Collapse Additional Action Panel',
	HIDE_ACTION_PANEL = '[Action Panel] Hide Action Panel',
	HIDE_ADDITIONAL_ACTION_PANEL = '[Action Panel] Hide Additional Action Panel',
	SHOW_ACTION_PANEL = '[Action Panel] Show Action Panel',
	SHOW_ADDITIONAL_ACTION_PANEL = '[Action Panel] Show Additional Action Panel',
	LOAD_ACTION_PANEL_COMPONENT = '[Action Panel] Load Action Panel Component'
}

export class ExpandActionPanel implements Action {
	readonly type = actionPanelActionTypes.EXPAND_ACTION_PANEL;
}

export class CollapseActionPanel implements Action {
	readonly type = actionPanelActionTypes.COLLAPSE_ACTION_PANEL;
}

export class HideActionPanel implements Action {
	readonly type = actionPanelActionTypes.HIDE_ACTION_PANEL;
}

export class ShowActionPanel implements Action {
	readonly type = actionPanelActionTypes.SHOW_ACTION_PANEL;
}

export class ExpandAdditionalActionPanel implements Action {
	readonly type = actionPanelActionTypes.EXPAND_ADDITIONAL_ACTION_PANEL;
}

export class CollapseAdditionalActionPanel implements Action {
	readonly type = actionPanelActionTypes.COLLAPSE_ADDITIONAL_ACTION_PANEL;
}

export class HideAdditionalActionPanel implements Action {
	readonly type = actionPanelActionTypes.HIDE_ADDITIONAL_ACTION_PANEL;
}

export class ShowAdditionalActionPanel implements Action {
	readonly type = actionPanelActionTypes.SHOW_ADDITIONAL_ACTION_PANEL;
}

export class LoadActionPanelComponent implements Action {
	readonly type = actionPanelActionTypes.LOAD_ACTION_PANEL_COMPONENT;
	constructor(public payload: string) {}
}

export type ActionPanelActions =
	| ExpandActionPanel
	| CollapseActionPanel
	| HideActionPanel
	| ShowActionPanel
	| ExpandAdditionalActionPanel
	| CollapseAdditionalActionPanel
	| HideAdditionalActionPanel
	| ShowAdditionalActionPanel
	| LoadActionPanelComponent;
