import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormArray } from '@angular/forms';
import { BigFormService, ModalService } from '@flexus/core';
import moment from 'moment';
import { skipWhile, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'flx-claim-appointments',
	templateUrl: './claim-appointments.component.html',
	styleUrls: ['./claim-appointments.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimAppointmentsComponent implements OnInit, OnDestroy {
	currentJobControls: any;

	displayOptions: { displayKey: string; valueKey: string };
	selectedType: { id: number; name: string };
	isCustomDate = false;

	timeFormat = 'hh:mm:ss';
	defaultAfterHoursTime = { start: moment('07:00', this.timeFormat), end: moment('17:00', this.timeFormat) }; // Anything after this time will be considered after hour call out

	@Input() minHour = 7;
	@Input() maxHour = 18;
	@Input() alternateMaxHour = 16;
	@Input() alternateMaxHourDays: { days: number[]; alsoForPHs: boolean };

	@Input() checkAfterHours = true;
	@Input() afterHoursSkills: number[] = []; // Skills that will not trigger a warning about setting a time after hours

	@Input() appointmentTypes$: Observable<{ id: number; name: string }[]>;
	@Input() showCurrentJobs = false;
	@Input() minDate;
	@Input() allowSundays = true;
	@Input() allowPublicHolidays = true;
	@Input() publicHolidays$: Observable<{ date: string; name: string }[]>;
	publicHolidays: { date: string; name: string }[];
	datePickerFilterCopy;

	// @Input() checkAfterHoursEndpoint: ServerCall_0_0_2 = {
	//   errorMessage: 'Could not check after hours',
	//   dataKey: 'aa',
	//   serviceVariable: 'silService',
	//   functionName: 'is_after_hours',
	//   ignoreFalseError: true
	// };

	constructor(public bf: BigFormService, private modal: ModalService, private http: HttpClient, @Inject('environment') private _environment: any) {}

	ngOnInit() {
		this.datePickerFilterCopy = this.datePickerFilter.bind(this);
		this.currentJobControls = this.bf.bigForm?.get('jobs') as UntypedFormArray;
		// this.checkReleventTime();
		this.publicHolidays$
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe(x => (this.publicHolidays = x));
	}

	getAppointmentsArray(): UntypedFormArray {
		return this.bf.bigForm?.get('jobs') as UntypedFormArray;
	}

	getAppointmentFormArrayControls() {
		return this.getAppointmentsArray()?.value;
	}

	personOnSite() {
		const { client_details: { first_name = '', surname = '' } = {}, contact_details: { cell_number = '' } = {} } = this.bf.bigForm.value;
		this.bf.bigForm.patchValue({
			on_site_person: `${first_name}${first_name && surname ? ' ' : ''}${surname}`,
			on_site_contact: cell_number
		});
	}

	checkForAfterHours(appData: any, skillId: number) {
		if (this.checkAfterHours) {
			const appointment = `${moment(appData.date).format('YYYY-MM-DD')} ${appData.time.hour}:${appData.time.minutes}`;
			this.isAfterHours(appointment)
				.pipe(take(1))
				.subscribe((data: any) => {
					if (data?.success) {
						if (data?.payload) {
							this.triggerAfterHoursWarning(skillId);
						}
					} else {
						this.defaultCheck(appData, skillId);
					}
					return data;
				});
		}

		// TODO check if offline... if so do default checks
	}

	defaultCheck(appData: any, skillId: number) {
		const selectedTime = moment(`${appData.time.hour}:${appData.time.minutes}`, this.timeFormat);
		if (selectedTime.isAfter(this.defaultAfterHoursTime.end) || selectedTime.isBefore(this.defaultAfterHoursTime.start)) {
			console.log('Is after hours');
			this.triggerAfterHoursWarning(skillId);
		}
	}

	triggerAfterHoursWarning(skillId) {
		if (!this.afterHoursSkills.includes(skillId)) {
			// Not an afterhours skill -- Show warning
			this.modal.openModalDirectly(instance => {
				instance.type = 'warning';
				instance.heading = 'Are you sure you selected the correct time?';
				instance.subheading = 'The SP will charge an After Hours rate.';
				instance.message = 'The time you have entered falls out of normal working hours';
				instance.closeButton = false;
				instance.navButtons = [
					{
						text: 'Close',
						linkType: 'close',
						color: 'alert'
					}
				];
			});
		}
	}

	isAfterHours(data): Observable<any> {
		console.log('hitting endpoint');
		return this.http.post<any>(this._environment.api_url + 'v1/claim_action/is_after_hours', { appointment: data });
	}

	datePickerFilter(d?: Date) {
		const day = d.getDay();
		const date = moment(d).format('YYYY-MM-DD');

		if (!this.allowSundays && day === 0) {
			return false;
		} else return !(!this.allowPublicHolidays && this.publicHolidays.some(x => x.date === date));
	}

	ngOnDestroy(): void {}
}
