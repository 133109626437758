import { Flow_0_0_2, getFullItemOne, getCurrentUser } from '@flexus/core';
import { UntypedFormControl } from '@angular/forms';
import { map, take, filter, first, skipWhile, pluck } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const SIL_16: Flow_0_0_2 = {
	id: '16',
	name: 'claim_submitted_unallocated',
	itemType: 'flow',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x),
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				first(itemOne => itemOne !== null || itemOne !== undefined),
				map(itemOne => {
					if (itemOne) {
						return 'Unmanaged MOBI Claim - ' + itemOne?.loan_information?.mavenclaimnumber + ' - ' + itemOne?.applicant?.surname;
					} else {
						return 'Unmanaged MOBI Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Claim Submitted Unallocated'
		},
		viewRoles: {
			0: 'Claim Submitted Unallocated'
		}
	},
	startNode: 'UnmanagedClaim',
	nodes: {
		UnmanagedClaim: {
			component: 'FileViewWithExtraComponent',
			initFormFields: (bf, item, inst, sq, store) => {
				bf.bigForm.addControl('new_state', new UntypedFormControl(1));
				store
					.select(getCurrentUser)
					.pipe(
						skipWhile(x => !x),
						pluck('user', 'id'),
						take(1)
					)
					.subscribe(id => {
						bf.bigForm.addControl('handler', new UntypedFormControl(id));
					});
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Cannot get retrieve data',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							// map((vals) => vals.claim),
							map((vals: any) => {
								const res = {
									Claimant: vals && vals.applicant ? vals.applicant?.first_name + ' ' + vals.applicant?.surname : '',
									'Claim Type': vals && vals.loan_information ? vals.loan_information.claimtype : '',
									Address:
										vals && vals.loan_information && vals.loan_information.propertystreetaddress
											? vals.loan_information.propertystreetaddress + ', ' + vals.loan_information.propertysuburb + ', ' + vals.loan_information.propertycity
											: '',
									'Contact Number': vals && vals.loan_information ? vals.loan_information.contactnumber : '',
									'Cell Number': vals && vals.loan_information ? vals.loan_information.cellnumber : '',
									'What Matters': vals && vals.loan_information ? vals.loan_information.whatmatters : ''
								};
								return [res];
							})
						);
					}
				},
				copyText: {
					errorMessage: 'Cannot return maven claim number!',
					directCall: (http, store, sq, bf) => {
						return store.select(getFullItemOne).pipe(
							filter(x => !!x && Object.keys(x).length !== 0),
							take(1),
							map((vals: any) => {
								return { key: 'MAVEN CLAIM NO: ', value: vals.mid };
							})
						);
					}
				},
				files: {
					serviceVariable: 'silService',
					functionName: 'getAllClaimFiles',
					responseSlice: 'payload',
					errorMessage: 'Could not get files from server!'
				},
				logs: {
					serviceVariable: 'silService',
					functionName: 'getCallLogs',
					// responseSlice: 'payload',
					errorMessage: 'No call logs could be found!'
				}
			},
			inputs: {
				inputHeading: 'Unmanaged Claim',
				subHeading: 'Copy claim number and use in Maven',
				fileDoesNotExistMessage: '',
				instructions: ['This is not a claim or province that is managed by 4-Sure.', 'The claim is already opened in Maven but needs to be manually managed.'],
				containerWidth: '50vw'
			},
			navs: [
				{
					text: 'Allocate Claim',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.type = 'info';
							instance.setMessage(['This claim MUST be managed on Maven!', '', 'Allocate this claim to your workflow']);
							instance.navButtons = [
								{
									text: 'Back',
									color: 'alert',
									linkType: 'close'
								},
								{
									text: 'Allocate Claim',
									color: 'primary',
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										allocate_claim_response: {
											errorMessage: 'Claim could not be updated!!',
											serviceVariable: 'silService',
											functionName: 'updateClaim'
										}
									}
								}
							];
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		'itemOne.id': 'claim_id',
		// 'itemOne.state': 'current_state',
		handler: 'loan_information.handler'
	}
};
