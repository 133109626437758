import { NgModule } from '@angular/core';
import { DateAdapter, FLX_DATE_LOCALE_PROVIDER } from './date-adapter';
import { DATE_FORMATS } from './date-formats';
import { NativeDateAdapter } from './native-date-adapter';
import { FLX_NATIVE_DATE_FORMATS } from './native-date-formats';

export * from './date-adapter';
export * from './date-formats';
export * from './native-date-adapter';
export * from './native-date-formats';

@NgModule({
	providers: [{ provide: DateAdapter, useClass: NativeDateAdapter }, FLX_DATE_LOCALE_PROVIDER]
})
export class FLXNativeDateModule {}

@NgModule({
	imports: [FLXNativeDateModule],
	providers: [{ provide: DATE_FORMATS, useValue: FLX_NATIVE_DATE_FORMATS }]
})
export class NativeDateModule {}
