import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getClaimTypes, getSubmissionData, getAppointmentTypes, getSkills } from '@flexus/core';
import { forkJoin } from 'rxjs';
import { map, skipWhile, take, filter, first, switchMap } from 'rxjs/operators';
import gql from 'graphql-tag';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NewJobPayload, JobCardRequest, NewJobUtilities } from '../../models/new-job.model';
import moment from 'moment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { environment } from 'apps/studio/src/environments/environment';

export const SIL_33: Flow_0_0_2 = {
	id: '33',
	name: 'incorrect_skill',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				filter(x => !!x),
				skipWhile((itemTwo: any) => {
					return itemTwo === null || itemTwo === undefined;
				}),
				first(itemTwo => itemTwo !== null || itemTwo !== undefined),
				map((itemTwo: any) => {
					if (itemTwo) {
						return `Incorrect Skill : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					} else {
						return 'Incorrect Skill';
					}
				})
			);
		},
		controls: () => () => []
	},
	fetchLevel1And2: true,
	serverCalls: {
		skills: {
			serviceVariable: 'silService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills could be found!'
		},
		appointmentTypes: {
			serviceVariable: 'silService',
			functionName: 'getAppointmentTypes',
			errorMessage: 'No Appointment Types could be found!'
		}
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Allocate new skill to job'
		},
		viewRoles: {
			0: '--'
		}
	},
	startNode: 'IncorrectSkill',
	nodes: {
		IncorrectSkill: {
			serverCalls: {
				keyValueList: {
					errorMessage: 'No claim or job data could be found',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											claim_type
											applicant {
												first_name
												surname
												contact_number
											}
											loan_information {
												whatmatters
											}
										}
										fullItemTwo {
											address
											job_information {
												skills_needed
											}
										}
										claim_types
									}
								`,
								forkJoin([
									store.select(getFullItemOne).pipe(
										skipWhile(f => !f),
										take(1)
									),
									store.select(getFullItemTwo).pipe(
										skipWhile(f => !f),
										take(1)
									),
									store.select(getClaimTypes).pipe(
										skipWhile(f => !f),
										take(1)
									)
								]).pipe(
									map(res => ({
										fullItemOne: res[0],
										fullItemTwo: res[1],
										claim_types: res[2]
									}))
								)
							)
							.pipe(
								map((queryData: any) => {
									const filteredClaimTypes = (queryData.claim_types as any[])?.filter(x => x?.id === queryData.claim_type);
									let claimType = '';
									if (filteredClaimTypes && filteredClaimTypes.length > 0) {
										claimType = filteredClaimTypes[0]?.description;
									}
									return [
										{ 'New skill needed': queryData.skills_needed },
										{ 'Claim Type': claimType },
										{ Address: queryData.address },
										{
											'Client Name': `${queryData.first_name} ${queryData.surname}`
										},
										{ 'Contact number': queryData.contact_number },
										{ 'Cell number': queryData.contact_number },
										{ 'What mattered to the customer': queryData.whatmatters }
									];
								})
							);
					}
				}
			},
			inputs: {
				title: 'Allocate appointment for the new skill',
				instructions: [
					'The Service Provider Team has indicated that additional skills are required to complete the work.',
					'Please phone the customer and set a new appointment for the requested skill.'
				]
			},
			component: 'FLXKeyValueDisplayWithInstructionsComponent',
			navs: [
				{
					text: 'Request New Job',
					color: 'primary',
					nextNode: 'SetAppointment'
				}
			]
		},
		SetAppointment: {
			component: 'FLXJobAppointmentComponent',
			inputs: {
				minDate: new Date()
			},
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(null, [Validators.required]),
						skillcatagory: new UntypedFormControl(null)
					})
				);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									office_use {
										skillcatagory
									}
									job_information {
										skills_needed
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(values => {
						const skills = values.skills_needed?.split(',');

						let required_skill = '';
						if (skills && skills.length > 0) {
							for (const skill of skills) {
								skill?.trim();
							}
							required_skill = skills[0];
						}

						bf.bigForm.get('appointmentData').get('skill')?.patchValue(required_skill);
						bf.bigForm.get('appointmentData').get('skillcatagory')?.patchValue(values.skillcatagory);
					});
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												surname
											}
											loan_information {
												contactnumber
												cellnumber
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{
											'Client Name': `${queryData.first_name} ${queryData.surname}`
										},
										{ 'Contact Number': `${queryData.contactnumber}` },
										{ 'Mobile Number': `${queryData.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											loan_information {
												onsiteperson
												onsitecontact
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				}
			},
			navs: [
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'The new job could not be created!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemOne).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getAppointmentTypes).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSkills).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([claim, job, submissionData, appointmentTypes, skills]) => {
										const skillsInfo = {
											...NewJobUtilities.getSkillObjectByProperty(skills, 'name', job?.job_information?.skills_needed?.replace(/,/gi, '')),
											skillrequested: job?.office_use.skillrequested,
											skillcatagory: job?.office_use.skillcatagory
										};

										const appointmentDetails = submissionData.new_job_data;
										const { jobcardrequest, selectedskills } = claim?.loan_information;

										let newJobCardRequest = [];
										if (Array.isArray(jobcardrequest)) {
											newJobCardRequest = [...jobcardrequest, skillsInfo];
										} else {
											newJobCardRequest = [jobcardrequest, skillsInfo];
										}

										let newSelectedSkills = [];
										if (Array.isArray(selectedskills)) {
											newSelectedSkills = [
												...selectedskills,
												{
													category: skillsInfo.skillcatagory,
													subcategory: skillsInfo.skill
												}
											];
										} else {
											newSelectedSkills = [
												selectedskills,
												{
													category: skillsInfo.skillcatagory,
													subcategory: skillsInfo.skill
												}
											];
										}
										const test = appointmentTypes.find(type => type?.id === appointmentDetails.appointmentTime);
										console.log({ test });
										console.log({ P: appointmentDetails.appointmentTime, appointmentTypes });
										const JobRequest: JobCardRequest = {
											...skillsInfo,
											appointment_type: appointmentDetails?.appointmentTime ? test?.name : null,
											appointment_type_id: appointmentDetails?.appointmentTime ?? null,
											appointmentdatetype: appointmentDetails?.appointmentDateType ?? null,
											providertype: job?.office_use?.providertype ?? null,
											providertype_id: job?.office_use?.providertype_id ?? null,
											requestedappointmentdate: appointmentDetails?.appointmentDatePicker ? moment(appointmentDetails.appointmentDatePicker)?.format(moment.HTML5_FMT.DATE) : null,
											requestedappointmenttime: appointmentDetails?.appointmentTimePicker
												? `${appointmentDetails.appointmentTimePicker.hour}:${appointmentDetails.appointmentTimePicker.minutes}:00`
												: null
										};
										const packet: NewJobPayload = {
											claim: claim?.id,
											application: {
												...claim?.loan_information,
												jobcardrequest: newJobCardRequest,
												selectedskills: newSelectedSkills
											},
											area: job?.area,
											jobcardrequest: [JobRequest]
										};
										console.log({ packet });
										return packet;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/new_job/`, data);
									})
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		appointmentData: 'new_job_data'
	}
};
