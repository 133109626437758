import { Component, OnInit, Input, Inject, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntypedFormControl } from '@angular/forms';
import { Observable, EMPTY, iif, of } from 'rxjs';
import { MakeServerCall, BigFormService, getAllInfo, getCurrentUser } from '@flexus/core';
import { DynamicFormInputs } from '@flexus/ui-composites';
import { filter, take, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'flx-call-logs',
	templateUrl: './call-logs.component.html',
	styleUrls: ['./call-logs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallLogsComponent implements OnInit {
	// @Input() logs$: Observable<any>; // NOTE: not necessary
	@Input() containerDirection = 'row';
	@Input() location: 'action-panel' | 'context' = 'action-panel';
	formInputs: DynamicFormInputs;

	direction: any[];
	reason: any[];
	channel: any[];

	addLogToggle = false;
	newCallLogs$: Observable<any>;
	namesMapper = null;

	constructor(private _store: Store<any>, public bf: BigFormService, private http: HttpClient, @Inject('environment') private _environment: any) {}

	ngOnInit() {
		this.bf.bigForm.addControl('message', new UntypedFormControl(''));
		this.bf.bigForm.addControl('reason', new UntypedFormControl(null));
		this.bf.bigForm.addControl('direction', new UntypedFormControl(null));
		this.bf.bigForm.addControl('channel', new UntypedFormControl(null));
		this._store
			.select(getAllInfo)
			.pipe(
				filter(x => !!x),
				take(1)
			)
			.subscribe((allInfo: any) => {
				this.direction = allInfo.call_direction;
				this.reason = allInfo.call_reason;
				this.channel = allInfo.call_channel;
				this.setUpAddLogsForm();
			});

		this.newCallLogs$ = this._store
			.select(state => state.selectedContext.logs)
			.pipe(
				switchMap((logs: any[]) => {
					if (logs) {
						return this._store.select(getCurrentUser).pipe(
							take(1),
							switchMap((data: any) => {
								return iif(
									() => !!this.namesMapper,
									of(this.namesMapper),
									this.http
										.post(this._environment.api_url + 'v1/staff_action/get_staff_member_names', {
											ids: [...logs.map(lg => lg.author), data.user.id]
										})
										.pipe(map((data: any) => data.payload))
								).pipe(
									map((mapper: any) => {
										this.namesMapper = mapper;
										const newLogs = logs.map((log: any) => {
											return {
												message: log.message,
												direction: log.new_direction,
												reason: log.new_reason,
												channel: log.new_channel,
												date: log.date,
												time: log.time,
												state: log.state,
												authorDetails: {
													name: this.namesMapper[log.author]
													// TODO: email here
													// TODO: contact_number here
												}
											};
										});
										return newLogs;
									})
								);
							})
						);
					} else {
						return EMPTY;
					}
				})
			);
	}

	getStaffMember(id: number): Observable<any> {
		return this.http.post(`${this._environment.api_url}v1/staff_action/get_staffmember/`, id);
	}

	setUpAddLogsForm() {
		this.formInputs = {
			0: { formControlName: 'message', inputType: 'textarea', label: 'Message', defaultValue: '' },
			1: {
				label: 'Direction',
				inputType: 'select',
				defaultValue: '',
				selectConfig: {
					displayOptions: { displayKey: 'name', valueKey: 'id' },
					itemsOption: this.direction,
					searchEnabled: false,
					placeHolder: 'Direction'
				},
				width: '100%',
				formControlName: 'direction'
			},
			2: {
				label: 'Reason',
				inputType: 'select',
				defaultValue: '',
				selectConfig: {
					displayOptions: { displayKey: 'name', valueKey: 'id' },
					itemsOption: this.reason,
					searchEnabled: false,
					placeHolder: 'Reason'
				},
				width: '100%',
				formControlName: 'reason'
			},
			3: {
				label: 'Channel',
				inputType: 'select',
				defaultValue: '',
				selectConfig: {
					displayOptions: { displayKey: 'name', valueKey: 'id' },
					itemsOption: this.channel,
					searchEnabled: false,
					placeHolder: 'Channel'
				},
				width: '100%',
				formControlName: 'channel'
			}
		};
	}

	submit() {
		this._store.dispatch(
			new MakeServerCall({
				dataKey: 'logs',
				serviceVariable: 'silService',
				functionName: 'addCallLog',
				errorMessage: 'Could not add call log'
			})
		);
		this.addLogToggle = false;
	}

	toggleAddCallLog() {
		this.addLogToggle = !this.addLogToggle;
	}
}
