import { Flow_0_0_2, getSelectedItemOne, getFullItemOne } from '@flexus/core';
import { UntypedFormControl, Validators, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { take, skipWhile, map, filter } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const BET_12: Flow_0_0_2 = {
	id: '12',
	name: 'customer_rating',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x), // applicant of undefined error when going back to workflow
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map(itemOne => {
					if (itemOne) {
						return 'Customer Rating - ' + itemOne?.loan_information?.mavenclaimnumber + ' - ' + itemOne?.applicant?.surname;
					} else {
						return 'Customer Rating';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},

	actionPanel: instance => setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], { filesFunctionName: 'getAllClaimFiles' }),

	instructions: {
		editRoles: {
			0: 'Rating'
		},
		viewRoles: {
			0: 'Rating'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'betService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills could be found.'
		},
		serviceProviders: {
			serviceVariable: 'betService',
			functionName: 'getServiceProvidersOnClaim',
			errorMessage: 'No service providers could be found.'
		}
	},
	startNode: 'CustomerRating',
	nodes: {
		CustomerRating: {
			component: 'CustomerRatingComponent',
			inputs: {
				organisationName: 'Bet Centre'
			},
			checkValidityForFields: ['customerrating', 'sp_ratings'],
			initFormFields: (bf, item, instance, storeQuery) => {
				bf.addControl('new_state', new UntypedFormControl('55'));
				instance.selectedItem$
					.pipe(
						skipWhile(c => !c),
						take(1)
					)
					.subscribe(claim => {
						bf.addControl('claim_id', new UntypedFormControl(claim?.id));
					});
				bf.addControl('customerrating', new UntypedFormControl('', Validators.required));
				bf.addControl('whatmattersachieved', new UntypedFormControl(false));
				bf.addControl('failedwhatmattersreason', new UntypedFormControl('', Validators.required));
				const initSPForm = (serviceProviders: any[]): any[] => {
					return serviceProviders.map(sp => {
						return new UntypedFormGroup({
							id: new UntypedFormControl(sp.jobId, Validators.required),
							customerrating: new UntypedFormControl('', Validators.required),
							whatmatters: new UntypedFormControl(false),
							whatmattersfailreason: new UntypedFormControl('', Validators.required)
						});
					});
				};
				instance.serviceProviders$
					.pipe(
						skipWhile(x => !x),
						take(1)
					)
					.subscribe(sps => {
						if (sps) {
							bf.bigForm.addControl('sp_ratings', new UntypedFormArray(initSPForm(sps)));
						}
					});
			},

			intersectData: (bf, instance) => {
				bf.bigForm.removeControl('customerratingdone');
				bf.patchValues({ customerrating: bf.bigForm?.value?.customerrating });
				bf.patchValues({ failedwhatmattersreason: bf.bigForm?.value?.failedwhatmattersreason });
			},
			navs: [
				{
					text: 'Continue Without Rating',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					color: 'alert',
					intersectData: (bf, store) => {
						bf.patchValues({
							sp_ratings: [],
							customerrating: null,
							whatmattersachieved: null,
							failedwhatmattersreason: null
						});
						bf.addControl('new_state', new UntypedFormControl('15'));
						store
							.select(getSelectedItemOne)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.subscribe(claim => {
								bf.addControl('claim_id', new UntypedFormControl(claim?.id));
							});
						bf.addControl('customerratingdone', new UntypedFormControl('0'));
					},
					serverCalls: {
						response: {
							errorMessage: 'Error updating the customer rating',
							serviceVariable: 'betService',
							functionName: 'addCustomerRating'
						}
					}
				},
				{
					text: 'Continue',
					nextNode: 'WhatMatters',
					optIntoValidation: true,
					color: 'primary'
				}
			]
		},
		WhatMatters: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				formControls: {
					0: {
						label: 'What did we do well?',
						inputType: 'textarea',
						rows: 5,
						width: '48%',
						formControlName: 'how_we_did'
					},
					1: {
						label: 'What can we improve on?',
						inputType: 'textarea',
						rows: 5,
						width: '48%',
						formControlName: 'how_can_do'
					}
				},
				formLayout: 'stacked',
				containerWidth: '50vw'
			},
			checkValidityForFields: ['how_we_did', 'how_can_do'],
			initFormFields: (bf, item, instance) => {
				bf.addControl('how_we_did', new UntypedFormControl('', Validators.required));
				bf.addControl('how_can_do', new UntypedFormControl('', Validators.required));
			},
			navs: [
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					optIntoValidation: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Error updating the customer rating',
							serviceVariable: 'betService',
							functionName: 'addCustomerRating'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		claim_id: 'claim_id',
		sp_ratings: [
			sp_ratings => {
				return sp_ratings
					? sp_ratings.map((obj: any) => {
							const answer = obj.whatmatters === true ? '1' : '0';
							return {
								...obj,
								customerrating: obj.customerrating?.toString(),
								whatmatters: answer
							};
					  })
					: null;
			},
			'sp_rating'
		],
		customerrating: 'claim_rating.customerrating',
		how_we_did: 'claim_rating.ratingwhatwedidwell',
		how_can_do: 'claim_rating.ratingwecanimprove',
		whatmattersachieved: [
			(val: any) => {
				return val === true ? '1' : '0';
			},
			'claim_rating.whatmattersachieved'
		],
		failedwhatmattersreason: 'claim_rating.failedwhatmattersreason',
		customerratingdone: 'claim_rating.customerratingdone'
	}
};
