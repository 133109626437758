import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { HideActionPanel } from '../../../app-shell-features';

@Component({
	selector: 'flx-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
	// changeDetection: ChangeDetectionStrategy.OnPush, // causing error message to misbehave
})
export class LoginComponent implements OnInit {
	logo_url: string;
	second_logo_url: string;
	byline: string;
	azureLoginUrl: string;
	useMultipleLogins: boolean;

	constructor(private cdr: ChangeDetectorRef, private _store: Store<any>) {}

	ngOnInit() {
		this.azureLoginUrl = !!environment['azure'] ? environment['azure']['loginUrl'] : null;
		this.useMultipleLogins = !!environment['useMultipleLogins'];
		if (environment.branding) {
			this.logo_url = environment.branding.logo_url;
			if (environment.client === 'mul' || environment.client === 'mul_sp') {
				this.second_logo_url = '';
			} else {
				this.second_logo_url = '';
			}
			this.byline = environment.branding.byline;
		} else {
			this.logo_url = 'assets/images/4-sure-logo.svg';
		}

		this._store.dispatch(new HideActionPanel());
		this.cdr.detectChanges();
	}
}
