import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemOne, getFullItemTwo, getSelectedItem } from '@flexus/core';
import { CustomValidators, humaniseDate } from '@flexus/utilities';
import { setActionPanelItems, CollapseActionPanel } from 'apps/studio/src/app/app-shell-features';
import { SP_GLOBAL_STATES } from 'apps/studio/src/app/sp_globals/configs/flows';
import { environment } from 'apps/studio/src/environments/environment';
import gql from 'graphql-tag';
import moment from 'moment';
import { of } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

const fromGlobal = SP_GLOBAL_STATES[21];

export const PGG_244: Flow_0_0_2 = {
	id: '244',
	name: 'job_assigned_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	instructions: {
		editRoles: { 0: '' },
		viewRoles: { 0: '' }
	},
	header: {
		title: (store: any, bf: any) => {
			return store.select(getFullItemOne).pipe(
				map((itemOne: any) => {
					if (itemOne?.claim) {
						if (itemOne?.claim?.applicant && itemOne?.claim?.loan_information) {
							return `Job Awarded : ${itemOne?.claim?.loan_information?.voucher_key} - ${itemOne?.claim?.applicant?.first_name} `;
						} else {
							return 'Job Awarded - Allocate team leader';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		...fromGlobal.serverCalls,
		customerdetails: {
			errorMessage: 'Could not find the customer detail',
			directCall: (http, store) => {
				const customerobject = {
					'Customer Name': 'John',
					'Customer Contact Number': '0828936920'
				};
				return of([customerobject]);
			}
		},
		installationsummary: {
			errorMessage: 'Could not return Installation Summary',
			directCall: () => {
				const ins_summary_object = {
					'Voucher number': 'PLX90S',
					Product: 'Air Conditioner',
					'Estimated time to complete installation': '1h 30min',
					'Product comments': `doesn't always work as expected`
				};
				return of([ins_summary_object]);
			}
		}
	},
	startNode: 'IncorrectCancellationNotification',
	nodes: {
		IncorrectCancellationNotification: {
			initFormFields: bf => {
				bf.addControl('new_state', new UntypedFormControl(null));
				bf.addControl('no_refund', new UntypedFormControl(false, [CustomValidators.contains(['refund'])]));
			},
			checkValidityForFields: ['no_refund'],
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Incorrect Cancellation of Installation',
							instructions: ['The installer has cancelled the installation', 'Contact the customer to re-schedule']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Cutomer Details',
							data$: 'customerdetails',
							itemMargin: '0 0 35px 0 '
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Summary',
							data$: 'installationsummary'
						}
					}
				]
			},
			showTabs: false,
			navs: [
				{
					text: 'cancel installation',
					optIntoValidation: true,
					color: 'secondary',
					serverCalls: {
						response: {
							errorMessage: 'Could not update job',
							directCall: (http, store, sq, bf) => {
								return store.select(getSelectedItem).pipe(
									skipWhile(x => !x),
									take(1),
									switchMap((res: any) => {
										console.log('SELECTED ID', res?.id);
										const data = {
											job_id: res?.id,
											new_state: 80
										};
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				},
				{
					text: 'set new appointment',
					linkType: 'portal',
					color: 'primary',
					nextNode: 'SetAppointment'
				}
			]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date(),
				showExistingAppointment: true
			},
			checkValidityForFields: ['appointmentData'],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, Validators.required),
						appointmentTime: new UntypedFormControl(null, Validators.required),
						appointmentTimePicker: new UntypedFormControl(null, Validators.required),
						appointmentDateType: new UntypedFormControl(null, Validators.required),
						skill: new UntypedFormControl('Dish Installation')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				bf.patchValues({ new_state: 21 });
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									console.log('QDAT', qdat);
									return [
										{
											'Client Name': `${qdat.first_name} ${qdat.surname}`
										},
										{ 'Contact Number': `${qdat.contactnumber}` },
										{ 'Mobile Number': `${qdat.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				existing_appointment: {
					errorMessage: `Couldn't finde existing appointment details`,
					directCall: (http, store) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map((response: any) => response)
							)
							.pipe(
								switchMap(res => {
									console.log('RESPONSE DATE', res);
									const appointment = res?.appointment[res?.appointment.length - 1];
									const keyvaluelist = {
										'Appointmnet Date': appointment.range_start ? `${humaniseDate(appointment.range_start)}` : 'no appointment data exists',
										'Appointment Time': appointment.range_start ? `${moment(appointment.range_start)?.format('HH:mm')}` : 'no appointment time exists'
									};
									console.log('APPOINTMENT', keyvaluelist);
									return of([keyvaluelist]);
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'pggService',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			component: 'FLXJobAppointmentComponent'
		}
	}
};
