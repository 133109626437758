import { Component, OnInit, Input, OnDestroy, Inject, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { getFullItemTwo, getAllInfo } from '@flexus/core';
import { Observable, of, forkJoin, Subscription } from 'rxjs';
import { filter, take, skipWhile, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'flx-ap-installation-summary',
	templateUrl: 'ap-installation-summary.component.html',
	styleUrls: ['ap-installation-summary.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXAPInstallationSummaryComponent implements OnInit, OnDestroy {
	installationDetails$!: Observable<any>;
	clientDetails$!: Observable<any>;
	addressDetails$!: Observable<any>;
	whatmattersData$!: Observable<any>;

	installsub!: Subscription;
	clientsub!: Subscription;
	addresssub!: Subscription;
	wmsub!: Subscription;

	private fullJob;
	private allInfo;
	private teamLeaders;

	constructor(private _store: Store<any>, private _http: HttpClient, @Inject('environment') private _environment: any) {}

	private whatmatters;

	ngOnInit() {
		this.installationDetails$ = this.setupInstallationObservable();
		this.clientDetails$ = this.setupCustomerObservable();

		this.installsub = this.installationDetails$.subscribe();
		this.clientsub = this.clientDetails$.subscribe();
	}

	setupInstallationObservable() {
		return forkJoin([
			this._store.select(getFullItemTwo)?.pipe(
				filter((x: any) => !!x),
				take(1),
				map(res => res as any)
			),
			this._store.select(getAllInfo)?.pipe(
				filter((x: any) => !!x),
				take(1),
				map(res => res as any)
			),
			this._http.get(`${this._environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
				skipWhile((x: any) => !x),
				take(1),
				map(res => res['payload'])
			)
		])?.pipe(
			map(([fulljob, allinfo, leads]: any) => {
				const { job_information, claim } = fulljob;
				const sp = fulljob?.sp;
				const sps = allinfo?.sps;
				let companyname;
				for (let i = 0; i < sps.length; i++) {
					if (sp === sps[i]?.id) {
						companyname = sps[i]?.name;
					}
				}
				const tleads = leads as any;
				let teamleadname;
				for (let i = 0; i < tleads.length; i++) {
					if (!fulljob?.team_leader) {
						teamleadname = 'Team leader not assigned yet';
					} else if (tleads[i]?.id === fulljob.team_leader.id) {
						teamleadname = tleads[i]?.full_name;
					}
				}

				const accreditation_number = fulljob && fulljob?.team_leader && fulljob.team_leader.sp ? fulljob.team_leader.sp.accreditation_number : '--';
				const qualification_number = fulljob && fulljob.team_leader ? fulljob.team_leader.qualification_number : '--';
				const voucherdetail = job_information?.vouchers ? job_information?.vouchers?.title : 'Non-vouchered job';

				return [
					{
						// 'Company name ': companyname,
						// 'Accreditation number ': accreditation_number,
						'Voucher detail': voucherdetail,
						'Installation type': claim?.type,
						'Installer/Technician name ': teamleadname
					}
				];
			})
		);
	}
	setupCustomerObservable() {
		return forkJoin([
			this._store.select(getFullItemTwo)?.pipe(
				filter((x: any) => !!x),
				take(1),
				map(res => res as any)
			),
			this._store.select(getAllInfo)?.pipe(
				filter((x: any) => !!x),
				take(1),
				map(res => res as any)
			)
		])?.pipe(
			map(([fulljob, allinfo]: any) => {
				const { claim, identities } = fulljob;
				const { loan_information } = claim;
				const customerFirstName = claim?.applicant?.first_name;
				const customerSurname = claim?.applicant?.surname;
				const claimtype = claim?.type;
				let subscriber_no;
				let address;
				let email;
				let number;
				if (claimtype !== 'Pricelock Installation') {
					number = loan_information?.contactnumber ?? 'No number supplied';
					email = loan_information?.email ?? 'No email address captured';
					subscriber_no = identities?.policy_number ?? 'No subscriber number';
					address = `${fulljob?.address}, ` + `${fulljob?.suburb}`;
				} else {
					number = loan_information?.ContactNumber ?? 'No number supplied';
					email = loan_information?.Email ?? 'No email address captured';
					subscriber_no = identities?.subscriber_number ?? 'No subscriber number';
					address = `${loan_information?.propertystreetaddress_2}, ` + `${loan_information?.propertysuburb}` ?? 'No address captured';
				}
				return [
					{
						Customer: `${customerFirstName} ${customerSurname}`,
						'Subscriber Number': `${subscriber_no}`,
						'Installation address ': `${address}`,
						'Email address': `${email}`,
						'Contact number': `${number}`
					}
				];
			})
		);
	}

	ngOnDestroy() {
		if (this.installsub) {
			this.installsub.unsubscribe();
		}
		if (this.clientsub) {
			this.clientsub.unsubscribe();
		}
		if (this.wmsub) {
			this.wmsub.unsubscribe();
		}
	}
}
